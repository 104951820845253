import React from 'react';
import { Link } from 'react-router-dom';
import { staticMessages, formLayoutType, entityTypes, dateFormat, statusType } from './CommonConstants';
import Routes from './Routes';
import {
  copyVoucherCode,
  editPageAction,
  momentDateFormat,
  voucherStatusUpdate,
  voucherResend,
  updateLoggedInUser,
  downloadCompletedForm,
} from '../Utilities/commonFunctions';
import { SelectTag } from '../App/Admin/Components/SelectTag';
import { EditSwitchUser } from '../App/Admin/Components/EditSwitchUser';
import { TableCellTimer } from '../App/Shared/Components/TableCellTimer';
import CurrencyFormat from 'react-currency-format';
import Enums from './Enums';
import { PatternFormat } from 'react-number-format';
import { PropertyAssignedSelect } from '../App/Admin/Components/PropertyAssignedSelect';
import { Tag } from 'antd';
import { TablecellWithFallback } from '../App/Admin/Components/TablecellWithFallback';

const active_text = 'active-text';
const in_activetext = 'inactive-text';
const search_voucher = 'Search Voucher';

export const userTableConfigs = {
  columns: [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name, record) => <Link to={editPageAction(record, entityTypes.users)}>{name}</Link>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: (email, record) => <Link to={editPageAction(record, entityTypes.users)}>{email}</Link>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: true,
      render: (company, record) => <Link to={editPageAction(record, entityTypes.company)}>{company}</Link>,
    },
    {
      title: 'Role',
      dataIndex: 'userTypeName',
      key: 'role',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      render: (isActive) => (
        <React.Fragment>
          <span className={isActive ? active_text : in_activetext}>{isActive ? 'Active' : 'Inactive'}</span>
        </React.Fragment>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      sorter: true,
      render: (dateCreated) => <span>{momentDateFormat(dateCreated, dateFormat.DATEMODIFIEDFROMAT)}</span>,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: true,
    },
    {
      title: 'Verified',
      dataIndex: 'emailConfirmed',
      key: 'emailConfirmed',
      sorter: true,
      render: (emailConfirmed) => (
        <>
          <span className={emailConfirmed ? active_text : in_activetext}>{emailConfirmed ? 'Yes' : 'No'}</span>
        </>
      ),
    },
  ],
  filters: ['roles', 'status', 'company'],
  deleteTitle: 'User',
  deleteMessage: `${staticMessages.deleteConfirmation} user?`,
  activateMessage: `${staticMessages.activateConfirmation} user?`,
  deactivateMessage: `${staticMessages.deactivateConfirmation} user?`,
  addPageUrl: `${Routes.ADMIN_ADD_EDIT_USER.replace(':userId', 0)}`,
  addButtonText: 'Add User',
  searchPlaceHolder: 'Search Users',
};

export const networkUserTableConfigs = {
  columns: [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: true,
    },
    {
      title: 'Role',
      dataIndex: 'userTypeName',
      key: 'role',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: false,
      render: (isActive) => (
        <React.Fragment>
          <span className={isActive ? active_text : in_activetext}>{isActive ? 'Active' : 'Inactive'}</span>
        </React.Fragment>
      ),
    },
  ],
  filters: [],
  deleteTitle: 'User',
  deleteMessage: `${staticMessages.deleteConfirmation} user?`,
  activateMessage: `${staticMessages.activateConfirmation} user?`,
  deactivateMessage: `${staticMessages.deactivateConfirmation} user?`,
  searchPlaceHolder: 'Search Network Users',
};

export const categoriesTableConfigs = {
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name, record) => <Link to={editPageAction(record, entityTypes.categories)}>{name}</Link>,
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      sorter: false,
      render: (isEnabled) => (
        <React.Fragment>
          <span className={isEnabled ? active_text : in_activetext}>{isEnabled ? 'Active' : 'Inactive'}</span>
        </React.Fragment>
      ),
    },
  ],
  filters: [],
  deleteTitle: 'Category',
  deleteMessage: `${staticMessages.deleteConfirmation} category?`,
  activateMessage: `${staticMessages.activateConfirmation} category?`,
  deactivateMessage: `${staticMessages.deactivateConfirmation} category?`,
  addPageUrl: `${Routes.ADD_EDIT_MLS_CATEGORIES.replace(':mlsId', 0)}`,
  addButtonText: 'Add Category',
  searchPlaceHolder: 'Search Category',
};

export const companyTableConfigs = {
  columns: [
    {
      title: 'Title',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: true,
      render: (companyName, record) => <Link to={editPageAction(record, entityTypes.company)}>{companyName}</Link>,
    },
    {
      title: 'Managers',
      dataIndex: 'companyManager',
      key: 'companyManager',
      render: (companyManager) => (
        <div className="remove-last-commoa">
          {companyManager &&
            companyManager.length > 0 &&
            companyManager.map((actionElement) => {
              return (
                <Link
                  key={`${actionElement.fullName})_${actionElement.userId}`}
                  to={editPageAction(actionElement, entityTypes.users)}
                >
                  {actionElement.fullName}
                  <span>,&nbsp;</span>
                </Link>
              );
            })}
        </div>
      ),
    },

    {
      title: 'Customers',
      dataIndex: 'companyEmployee',
      key: 'companyEmployee',
      render: (companyEmployee) => (
        <div className="remove-last-commoa">
          {companyEmployee &&
            companyEmployee.length > 0 &&
            companyEmployee.map((actionElement) => {
              return (
                <Link
                  key={`${actionElement.fullName})_${actionElement.userId}`}
                  to={editPageAction(actionElement, entityTypes.users)}
                >
                  {actionElement.fullName}
                  <span>,&nbsp;</span>
                </Link>
              );
            })}
        </div>
      ),
    },
  ],
  filters: [],
  deleteTitle: 'Delete Company',
  deleteMessage: `${staticMessages.deleteConfirmation} company?`,
  addPageUrl: `${Routes.ADD_EDIT_COMPANY.replace(':companyId', 0)}`,
  addButtonText: 'Add Company',
  searchPlaceHolder: 'Search Company',
};

export const abbreviationTableConfigs = {
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name, record) => <Link to={editPageAction(record, entityTypes.abbreviation)}>{name}</Link>,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: 'Logged In User',
      dataIndex: 'loggedInUsers',
      key: 'loggedInUsers',
      render: (loggedInUsers, record) => (
        <select
          value={loggedInUsers?.find((loggedUser) => loggedUser.key === record.loggedInUserId)?.value || ''}
          onChange={(e) => updateLoggedInUser(e, loggedInUsers, record.mlsId)}
        >
          <option value="null" default>
            Logged Out
          </option>
          {loggedInUsers?.map((user, index) => (
            <option key={`${user.value}_${index}`} value={user.value}>
              {user.value}
            </option>
          ))}
        </select>
      ),
    },
    {
      title: 'Abbreviation',
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      sorter: true,
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      sorter: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
    },
    {
      title: 'Closing Fee',
      dataIndex: 'closingFee',
      key: 'closingFee',
      sorter: true,
    },
    {
      title: 'Restrictions',
      dataIndex: 'restrictionTitles',
      key: 'restrictionTitles',
      sorter: true,
      render: (restrictionTitles) => <span>{restrictionTitles?.join(',')}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModifiedOn',
      key: 'lastModifiedOn',
      sorter: true,
      render: (dateModified) => <span>{momentDateFormat(dateModified, dateFormat.DATEMODIFIEDFROMAT)}</span>,
    },
  ],
  filters: ['loggedInUser'],
  deleteTitle: 'Delete MLS INFO',
  deleteMessage: `${staticMessages.deleteConfirmation} MLS INFO?`,
  addPageUrl: `${Routes.ADMIN_ADD_EDIT_MLS_ABBREVIATION.replace(':mlsId', 0)}`,
  addButtonText: 'Add MLS INFO',
};

export const ListingFormTableConfigs = {
  columns: [
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name, record) => <Link to={editPageAction(record, entityTypes.listings)}>{name}</Link>,
    },
    {
      title: 'State',
      dataIndex: 'states',
      key: 'states',
      sorter: false,
      render: (states) => (
        <div className="remove-last-commoa">
          {states &&
            states.length > 0 &&
            states.map((actionElement) => {
              return (
                <span key={actionElement.label} className="anchorLink">
                  {actionElement.label}
                  <span>,&nbsp;</span>
                </span>
              );
            })}
        </div>
      ),
    },
    {
      title: 'MLS Abbreviation.',
      dataIndex: 'mls',
      key: 'mls',
      sorter: false,
      render: (mls) => (
        <div className="remove-last-commoa">
          {mls &&
            mls.length > 0 &&
            mls.map((actionElement) => {
              return (
                <Link key={actionElement.label} to={editPageAction(actionElement, entityTypes.abbreviation)}>
                  {actionElement.label}
                  <span>,&nbsp;</span>
                </Link>
              );
            })}
        </div>
      ),
    },
    {
      title: 'MLS Category',
      dataIndex: 'mlsCategories',
      key: 'mlsCategories',
      sorter: false,
      render: (mlsCategories) => (
        <div className="remove-last-commoa">
          {mlsCategories &&
            mlsCategories.length > 0 &&
            mlsCategories.map((actionElement) => {
              return (
                <Link key={actionElement.label} to={editPageAction(actionElement, entityTypes.categories)}>
                  {actionElement.label}
                  <span>,&nbsp;</span>
                </Link>
              );
            })}
        </div>
      ),
    },
    {
      title: 'Form Type',
      dataIndex: 'formType',
      key: 'formType',
      sorter: true,
    },
    {
      title: 'Ordering',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Section Type',
      dataIndex: 'sectionType',
      key: 'sectionType',
      sorter: true,
      render: (sectionType) => (
        <React.Fragment>
          <span>{sectionType === 'HTML' ? formLayoutType.HTML : formLayoutType.Image}</span>
        </React.Fragment>
      ),
    },
    {
      title: 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      sorter: false,
      render: (companies) => (
        <div className="remove-last-commoa">
          {companies &&
            companies.length > 0 &&
            companies.map((actionElement) => {
              return (
                <span className="anchorLink" key={actionElement.label}>
                  {actionElement.label}
                  <span>,&nbsp;</span>
                </span>
              );
            })}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'publishStatus',
      key: 'publishStatus',
    },
  ],
  filters: ['mls', 'formTypeId', 'sectionTypeId', 'state'],
  deleteMessage: `${staticMessages.deleteConfirmation} listing?`,
  addPageUrl: `${Routes.ADMIN_ADD_EDIT_FORM.replace(':formId', 0)}`,
  addButtonText: 'Add New',
  deleteTitle: 'Delete Listing',
  searchPlaceHolder: 'Search Title',
};

export const MLSSubmissionTableConfigs = {
  columns: [
    {
      title: 'Order ID',
      dataIndex: 'orderId',
      key: 'orderId',
      sorter: true,
      width: 110,
    },
    {
      title: 'Type',
      dataIndex: 'formTypeName',
      key: 'formTypeName',
      sorter: true,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      render: (address, record) => <Link to={editPageAction(record, entityTypes.properties)}>{address}</Link>,
    },
    {
      title: 'User',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: (email, record) => <Link to={editPageAction(record, entityTypes.users)}>{email}</Link>,
    },
    {
      title: 'Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
      sorter: true,
      render: (createdOn) => <span>{momentDateFormat(createdOn, dateFormat.DATEMODIFIEDFROMAT)}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'formSubmissionStatus',
      key: 'formSubmissionStatus',
      sorter: true,
      render: (formSubmissionStatus, { userFormId, submissionStatuses }) => (
        <SelectTag selectedItem={formSubmissionStatus} options={submissionStatuses} userFormId={userFormId} />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, mls) => <EditSwitchUser mls={mls} />,
    },
    {
      title: 'Download',
      dataIndex: 'record',
      key: 'record',
      render: (_, record) => (
        <>
          <a
            href="javascript:void(0)"
            className="anchorLink"
            onClick={() => downloadCompletedForm(record.pdfPath, false, true, record.formTypeName)}
          >
            PDF
          </a>
          {record?.logPath && (
            <a
              href="javascript:void(0)"
              className="anchorLink"
              onClick={() => downloadCompletedForm(record.logPath, false, true, `${record.formTypeName}-Log`)}
            >
              {` - Log Files`}
            </a>
          )}
        </>
      ),
    },
  ],
  filters: ['submissionStatuses', 'officeAssign', 'officeStatus', 'company', 'formTypes', 'orderId'],
  deleteMessage: '',
  addPageUrl: '',
  addButtonText: '',
  searchPlaceHolder: 'Search email address and address',
};

export const userAuditLogTableConfigs = {
  columns: [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <span>{momentDateFormat(date, dateFormat.SUBMISSION)}</span>,
    },
    {
      title: 'Changed By',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
  ],
};

export const mlsCoverage = {
  columns: [
    {
      title: 'MLS Coverage',
      dataIndex: 'coverageName',
      key: 'coverageName',
      sorter: false,
      render: (coverageName, record) => (
        <Link to={editPageAction(record, entityTypes.mlsCoverage)}>{coverageName}</Link>
      ),
    },
    {
      title: 'MLS Info',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name, record) => <Link to={editPageAction(record, entityTypes.abbreviation)}>{name}</Link>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: true,
      render: (company, record) => <Link to={editPageAction(record, entityTypes.company)}>{company}</Link>,
    },
    {
      title: 'State',
      dataIndex: 'stateName',
      key: 'stateName',
      sorter: true,
    },
    {
      title: 'County',
      dataIndex: 'county',
      key: 'county',
      sorter: true,
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
      sorter: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (price, item) => (
        <TablecellWithFallback overWrittenValue={price}>
          <CurrencyFormat
            value={price || item?.mlsPrice}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </TablecellWithFallback>
      ),
    },
    {
      title: 'Closing Fee',
      dataIndex: 'closingFee',
      key: 'closingFee',
      sorter: true,
      render: (closingFee, item) => (
        <TablecellWithFallback overWrittenValue={closingFee}>
          <CurrencyFormat
            value={closingFee || item?.mlsClosingFee}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </TablecellWithFallback>
      ),
    },
    {
      title: 'Restrictions',
      dataIndex: 'restrictions',
      key: 'restrictions',
      sorter: false,
      render: (restrictions, item) => (
        <TablecellWithFallback overWrittenValue={restrictions}>
          <div className="remove-last-commoa">
            {(
              (restrictions && restrictions.length > 0 && restrictions) ||
              (item.mlsRestrictions && item.mlsRestrictions.length > 0 && item.mlsRestrictions) ||
              []
            ).map((actionElement) => {
              return (
                <span key={actionElement.label} className="anchorLink">
                  {actionElement.label}
                  <span>,&nbsp;</span>
                </span>
              );
            })}
          </div>
        </TablecellWithFallback>
      ),
    },
  ],
  filters: ['mlsId', 'state', 'inputZip', 'company', 'county'],
  deleteMessage: '',
  addPageUrl: `${Routes.ADD_EDIT_COVERAGE.replace(':mlsLocationId', 0)}`,
  addButtonText: 'Add MLS Coverage',
  searchPlaceHolder: 'Search MLS Coverage',
};

export const voucherTemplateTableConfigs = {
  columns: [
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name, record) => <Link to={editPageAction(record, entityTypes.template)}>{name}</Link>,
    },
    {
      title: 'Listing Product',
      dataIndex: 'products',
      key: 'products',
      sorter: false,
      render: (products) => (
        <div className="remove-last-commoa">
          {products &&
            products.length > 0 &&
            products.map((actionElement) => {
              if (actionElement.productTypeId === Enums.NUMERIC_VALUE.ONE) {
                return (
                  <Link key={actionElement.title} to={editPageAction(actionElement, entityTypes.products)}>
                    {actionElement.title}
                    <span>,&nbsp;</span>
                  </Link>
                );
              }
              return null;
            })}
        </div>
      ),
    },
    {
      title: 'Non Listing Products',
      dataIndex: 'products',
      key: 'nonProducts',
      sorter: false,
      render: (products) => (
        <div className="remove-last-commoa">
          {products &&
            products.length > 0 &&
            products.map((actionElement) => {
              if (actionElement.productTypeId > Enums.NUMERIC_VALUE.ONE) {
                return (
                  <Link key={actionElement.title} to={editPageAction(actionElement, entityTypes.products)}>
                    {actionElement.title}
                    <span>,&nbsp;</span>
                  </Link>
                );
              }
              return null;
            })}
        </div>
      ),
    },
    {
      title: 'Companies',
      dataIndex: 'companies',
      key: 'companies',
      sorter: false,
      render: (companies) => (
        <div className="remove-last-commoa">
          {companies &&
            companies.length > 0 &&
            companies.map((actionElement) => {
              return (
                <Link key={actionElement.companyName} to={editPageAction(actionElement, entityTypes.company)}>
                  {actionElement.companyName}
                  <span>,&nbsp;</span>
                </Link>
              );
            })}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: false,
      render: (isActive) => (
        <React.Fragment>
          <span className={!isActive ? in_activetext : active_text}>{isActive ? 'Active' : 'Inactive'}</span>
        </React.Fragment>
      ),
    },
  ],
  filters: ['company'],
  deleteMessage: '',
  deleteTitle: 'Template',
  addPageUrl: `${Routes.ADD_EDIT_VOUCHER_TEMPLATE.replace(':templateId', 0)}`,
  addButtonText: 'Add Voucher Template',
  searchPlaceHolder: search_voucher,
  activateMessage: `${staticMessages.activateConfirmation} template?`,
  deactivateMessage: `${staticMessages.deactivateConfirmation} template?`,
};

export const wholesaleVoucherListingTableConfigs = {
  columns: [
    {
      title: 'Voucher Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name, record) => <Link to={editPageAction(record, entityTypes.addVoucher)}>{name}</Link>,
    },
    {
      title: 'Voucher Code',
      dataIndex: 'voucherURL',
      key: 'voucherURL',
      sorter: false,
      render: (voucherURL) => (
        <span className="v-code">
          {voucherURL}
          <a
            href="javascript:void(0)"
            onClick={() => copyVoucherCode(voucherURL)}
            className="voucher-code-copy-icon"
            title="Copy to clipboard"
          >
            <i className="fa fa-clone" aria-hidden="true"></i>
          </a>
        </span>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
    },
    {
      title: 'Voucher Template',
      dataIndex: 'voucherTemplate',
      key: 'template',
      sorter: true,
    },
    {
      title: 'Redeemed By',
      dataIndex: 'redeemedBy',
      key: 'redeemedBy',
      sorter: true,
      render: (redeemedBy) => <span>{redeemedBy || 'Not Redeemed'}</span>,
    },
    {
      title: 'Redeemed Date',
      dataIndex: 'voucherRedemptionDate',
      key: 'redeemDate',
      sorter: true,
      render: (voucherRedemptionDate) => (
        <span>{momentDateFormat(voucherRedemptionDate, dateFormat.DATE_WITH_YEAR) || 'N/A'}</span>
      ),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'voucherExpiryDate',
      key: 'expiryDate',
      sorter: true,
      render: (voucherExpiryDate) => <span>{momentDateFormat(voucherExpiryDate, dateFormat.DATE_WITH_YEAR)}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isVoucherActive',
      key: 'isVoucherActive',
      sorter: false,
      render: (isVoucherActive) => (
        <React.Fragment>
          <span className={!isVoucherActive ? in_activetext : active_text}>
            {isVoucherActive ? 'Active' : 'Inactive'}
          </span>
        </React.Fragment>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'isVoucherActive',
      key: 'Actions',
      sorter: false,
      render: (isVoucherActive, record) => (
        <span className="v-code">
          <a
            href="javascript:void(0)"
            onClick={() => voucherResend(record, entityTypes.resendInvite, isVoucherActive)}
            className="voucher-code-copy-icon ui-fix"
            title="Copy to clipboard"
            disabled={!isVoucherActive ? 'disabled' : ''}
          >
            Resend Invite
          </a>
        </span>
      ),
    },
  ],
  filters: ['templateType'],
  deleteMessage: '',
  deleteTitle: 'Voucher',
  addPageUrl: '',
  addButtonText: '',
  searchPlaceHolder: search_voucher,
  activateMessage: `${staticMessages.activateConfirmation} voucher?`,
  deactivateMessage: `${staticMessages.deactivateConfirmation} voucher?`,
};

export const wholesalecoverageListingTableConfigs = {
  columns: [
    {
      title: 'MLS Coverage',
      dataIndex: 'coverageName',
      key: 'coverageName',
      sorter: true,
    },
    {
      title: 'MLS Info',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      sorter: true,
    },
    {
      title: 'State',
      dataIndex: 'stateName',
      key: 'stateName',
      sorter: true,
    },
    {
      title: 'County',
      dataIndex: 'county',
      key: 'county',
      sorter: true,
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
      sorter: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (price) => (
        <CurrencyFormat
          value={price || 0}
          decimalScale={2}
          fixedDecimalScale={true}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      ),
    },
    {
      title: 'Closing Fee',
      dataIndex: 'closingFee',
      key: 'state',
      sorter: true,
    },
    {
      title: 'Restrictions',
      dataIndex: 'restrictions',
      key: 'restrictions',
      sorter: false,
      render: (restrictions) => (
        <div className="remove-last-commoa">
          {restrictions &&
            restrictions.length > 0 &&
            restrictions.map((actionElement) => {
              return (
                <span key={actionElement.label} className="anchorLink">
                  {actionElement.label}
                  <span>,&nbsp;</span>
                </span>
              );
            })}
        </div>
      ),
    },
  ],
  filters: ['mlsId', 'state', 'inputZip', 'company', 'county'],
  deleteMessage: '',
  addPageUrl: '',
  addButtonText: '',
  searchPlaceHolder: 'Search MLS Coverage',
};

export const voucherListingTableConfigs = {
  columns: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      render: (address, record) => {
        return (
          <>
            <React.Fragment>
              <span>
                {record?.redeemedBy ? (
                  <Link target="_blank" to={editPageAction(record, entityTypes.voucherProperties)}>
                    {address}
                  </Link>
                ) : (
                  address
                )}
              </span>
            </React.Fragment>
          </>
        );
      },
    },
    {
      title: 'Voucher Code',
      dataIndex: 'voucherURL',
      key: 'voucherURL',
      sorter: false,
      render: (voucherURL, record) => (
        <span className="v-code">
          {voucherURL}
          <a
            href="javascript:void(0)"
            onClick={() => copyVoucherCode(voucherURL)}
            className="voucher-code-copy-icon"
            title="Copy to clipboard"
          >
            <i className="fa fa-clone" aria-hidden="true"></i>
          </a>
        </span>
      ),
    },
    {
      title: 'Template',
      dataIndex: 'voucherTemplate',
      key: 'template',
      sorter: true,
      render: (voucherTemplate, record) => (
        <Link to={editPageAction(record, entityTypes.template)}>{voucherTemplate}</Link>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: true,
      render: (createdBy, record) => (
        <>
          <Link to={editPageAction(record, entityTypes.users)}>{createdBy}</Link>
          {record?.companyId && (
            <Link to={editPageAction(record, entityTypes.company)}>{` - ${record.voucherCompany}`}</Link>
          )}
        </>
      ),
    },
    {
      title: 'Redeemed By',
      dataIndex: 'redeemedBy',
      key: 'redeemedBy',
      sorter: true,
      render: (redeemedBy, record) => (
        <React.Fragment>
          <span>
            {redeemedBy ? <Link to={editPageAction(record, entityTypes.users)}>{redeemedBy}</Link> : 'Not Redeemed'}
          </span>
        </React.Fragment>
      ),
    },
    {
      title: 'Redeemed Date',
      dataIndex: 'voucherRedemptionDate',
      key: 'redeemDate',
      sorter: true,
      render: (voucherRedemptionDate) => (
        <span>{momentDateFormat(voucherRedemptionDate, dateFormat.DATE_WITH_YEAR) || 'N/A'}</span>
      ),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'voucherExpiryDate',
      key: 'expiryDate',
      sorter: true,
      render: (voucherExpiryDate) => <span>{momentDateFormat(voucherExpiryDate, dateFormat.DATE_WITH_YEAR)}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isVoucherActive',
      key: 'isVoucherActive',
      sorter: false,
      render: (isVoucherActive, record) => (
        <React.Fragment>
          {new Date(record?.voucherExpiryDate) > new Date() &&
          !momentDateFormat(record?.voucherRedemptionDate, dateFormat.DATE_WITH_YEAR) ? (
            <select value={isVoucherActive} onChange={() => voucherStatusUpdate(entityTypes.voucherListing, record)}>
              {statusType.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.key}
                </option>
              ))}
            </select>
          ) : (
            <select value={isVoucherActive} onChange={() => voucherStatusUpdate(entityTypes.voucherListing, record)}>
              <option value="Inactive">Inactive</option>
            </select>
          )}
        </React.Fragment>
      ),
    },
  ],
  filters: ['createdBy', 'template'],
  deleteMessage: '',
  addPageUrl: '',
  addButtonText: '',
  searchPlaceHolder: search_voucher,
  activateMessage: `${staticMessages.activateConfirmation} voucher?`,
  deactivateMessage: `${staticMessages.deactivateConfirmation} voucher?`,
};
