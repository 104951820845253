import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Card, Col, Row, Table } from 'antd';
import { adminOrdersSelector, ordersFiltersSelector, adminOrdersCountSelector, fetchingAverageContactTimeSelector, averageContactTimeSelector } from '../../../../../Reducers/V2/AdminOrdersReducer.js';
import { useEffect } from 'react';
import { ordersCreators } from '../../../../../Actions/V2/Admin/OrdersActions';
import { dateFormat, pageSizeOptions, staticMessages, tableItemsPerPage } from '../../../../../Constants/CommonConstants';
import SearchPanel from '../Shared/SearchPanel.js';
import OrdersFilters from './OrdersFilters.js';
import { momentDateFormat } from '../../../../../Utilities/commonFunctions.js';
import { PatternFormat } from 'react-number-format';
import Routes from '../../../../../Constants/Routes.js';
import { AssignedSelect } from './AssignedSelect.js';
import { DraftListingContactedCheckbox } from './DraftListingContactedCheckbox.js';
import { ArchivedSelect } from './ArchivedSelect.js';

export const tableConfigs = {
    columns: [
      {
        title: 'Draft ID',
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        render: (id, item) => (
          <Link
            to={`${Routes.ONBOARDING_V2}?listing_id=${id}`}>
            {id}
          </Link>
        ),
      },
      {
        title: 'User Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        render: (email, item) => (
            <Link
              to={`${Routes.ADMIN_ADD_EDIT_USER.replace(':userId', item.userId)}`}>
              {email}
            </Link>
        ),
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        sorter: true,
        render: (phone) => (
          phone ? <PatternFormat value={phone} displayType="text" format="###-###-####" /> : '-'
        ),
      },
      {
        title: 'Contacted',
        dataIndex: 'dateContacted',
        key: 'dateContacted',
        sorter: true,
        align: 'center',
        render: (contacted, { id }) => (
          <DraftListingContactedCheckbox contacted={contacted} id={id} />
      ),
      },
      {
        title: 'Company',
        dataIndex: 'companyName',
        key: 'companyName',
        sorter: true,
      },
      {
        title: 'Property Address',
        dataIndex: 'address',
        key: 'address',
        sorter: true,
      },
      {
        title: 'Voucher',
        dataIndex: 'voucherCode',
        key: 'voucherCode',
        sorter: true,
      },
      {
        title: 'Plan Type',
        dataIndex: 'plan',
        key: 'plan',
        sorter: true,
      },
      {
        title: 'Current Step',
        dataIndex: 'currentStep',
        key: 'currentStep',
        sorter: true,
      },
      {
        title: 'Assigned to',
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        sorter: true,
        render: (assignedTo, { id }) => (
          <AssignedSelect
            selectedOption={assignedTo}
            orderId={id}
          />
        ),
      },
      {
        title: 'Date & Time',
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        sorter: true,
        render: createdOn => (
            <span>{momentDateFormat(createdOn, dateFormat.DATEMODIFIEDFROMAT)}</span>
        ),
      },
      {
        title: 'Last Modified',
        dataIndex: 'dateModified',
        key: 'dateModified',
        sorter: true,
        render: updatedOn => (
            <span>{momentDateFormat(updatedOn, dateFormat.DATEMODIFIEDFROMAT)}</span>
        ),
      },
      {
        title: 'Archived status',
        dataIndex: 'isArchived',
        key: 'isArchived',
        sorter: true,
        render: (isArchived, { id }) => (
          <ArchivedSelect
            selectedOption={isArchived}
            orderId={id}
          />
        ),
      },
    ],
    filters: [],
    deleteTitle: 'Order',
    deleteMessage: `${staticMessages.deleteConfirmation} order?`,
    activateMessage: `${staticMessages.activateConfirmation} order?`,
    deactivateMessage: `${staticMessages.deactivateConfirmation} order?`,
    searchPlaceHolder: 'Search Orders',
};

const OrdersList = () => {
    const dispatch = useDispatch();
    const orders = useSelector(adminOrdersSelector);
    const filters = useSelector(ordersFiltersSelector);
    const totalCount = useSelector(adminOrdersCountSelector);
    const fetchingAverageContactTime = useSelector(fetchingAverageContactTimeSelector);
    const averageContactTime = useSelector(averageContactTimeSelector);

    useEffect(() => {
        dispatch(ordersCreators.getOrders(filters));
    }, [
      filters.search,
      filters.page,
      filters.limit,
      filters.sortBy,
      filters.desc,
    ]);

    useEffect(() => {
      dispatch(ordersCreators.getAverageTimeToContact());
  }, []);

    const onTableChange = (pagination, tablefilters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const sorterParams = {};

        if (field && order) {
          sorterParams.sortBy = field;
          sorterParams.desc = order === 'descend';
        }
        
        dispatch(ordersCreators.setOrdersFilters({
          ...filters,
          ...sorterParams,
          page: current,
          limit: pageSize,
          
        }));
    }

    const showTotal = (total, range) => {
        return `Showing ${range[0]} - ${range[1]} of ${total}`;
    }
    const onSearch = (value) => dispatch(ordersCreators.setOrdersFilters({...filters, search: value}));

    return (<>
        <SearchPanel
          onSearch={onSearch}
          averageTimePlaceholder={'Average time to contact'}
          averageTime={averageContactTime}
          fetchingAverageTime={fetchingAverageContactTime}
        />
        <OrdersFilters />
        <Card>
            <Row style={{ marginTop: 20 }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}>
                        <Table
                            className="admin-custom-table"
                            size={'default'}
                            columns={tableConfigs.columns}
                            dataSource={orders}
                            onChange={onTableChange}
                            pagination={{
                                total: totalCount,
                                defaultPageSize: tableItemsPerPage,
                                defaultCurrent: 1,
                                showTotal: showTotal,
                                pageSizeOptions: pageSizeOptions,
                                showSizeChanger: totalCount > tableItemsPerPage
                            }} />
                </Col>
            </Row>
      </Card>
      </>
    );
}

export default (OrdersList);
