import React from 'react';
import { Link } from 'react-router-dom';
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../../../../Assets/Images/media_coverage/', false, /\.(png|jpe?g|svg)$/));
const MediaCoverageList = (props) => {
    const {
        PdfUrls
    } = props;
    return (
      <>
        <p>
        We’re making headlines with our innovative services and customer-centric business model. Read all
        about it! Want to include USRealty.com in your next story? <Link to="/press">
        Contact us today</Link> for more information.
            </p>
            <img src={images['press1.jpg']} alt="press"/>
            <p>
              <a href="https://www.washingtonpost.com/news/where-we-live/wp/2017/07/25/inside-the-d-c-areas-most-expensive-homes-for-sale-6/">
                Inside the D.C. Area’s Most Expensive Homes for Sale</a>,
                July 25, 2017, The Washington Post Download the story: <a
                  href={PdfUrls.PRESS_1} target="_blank">
                  Washington Post – Inside the D.C.-area’s most expensive homes for sale – The Washington Post
                </a>
            </p>
            <img src={images['press2.png']} alt="press" height="100px"/>
            <p>
              <a href="https://www.ai-ciodigital.com/ai-cio/june_2017?pg=NaN#pgNaN">
                Real Estate – What’s Hot & What’s Not
              </a>, June 2017, Chief Investment Officer Download the story: <a
                href={PdfUrls.PRESS_2} target="_blank">
                June 2017- Chief Investment Officer – Real Estate Trends
              </a>
            </p>
            <img src={images['press3.png']} alt="press" height="100px"/>
            <p>
              <a href="https://www.consumerreports.org/selling-a-home/tips-for-selling-your-home-without-an-agent/">
                Five Tips for Selling Your Home Without an Agent
              </a>
              , March 26, 2017  Download the story: <a
                href={PdfUrls.PRESS_3} target="_blank">
                5 Tips for Selling Your Home Without an Agent – Consumer Reports
              </a>
            </p>
            <img src={images['press4.jpg']} alt="press" height="30px"/>
            <p>
              <a href="https://www.mortgageloan.com/how-much-home-can-you-afford">
                How Much Home Can You Afford?
              </a> February 13, 2017  Download the story: <a
                href={PdfUrls.PRESS_4} target="_blank">
                MortgageLoan Feb. 13, 2017 How much home can you afford?
              </a>
            </p>
            <img src={images['press5.jpg']} alt="press" height="100px"/>
            <p>
              <a href="https://www.realtor.com/advice/sell/reasons-sell-your-home-2017/">
                4 Urgent Reasons You Should Sell Your Home in 2017
              </a>, January 24, 2017</p>
            <p>Download the story: <a href={PdfUrls.PRESS_5} target="_blank">
                4 Urgent Reasons You Should Sell Your Home in 2017 | realtor.com®
              </a>
            </p>
            <img src={images['press6.png']} alt="press" height="100px"/>
            <p>
              <a href="https://www.reuters.com/article/us-money-housing-failedsales-idUSKBN14V0DX">
                Impatience and desperation derail home sales in tight market
              </a> January 11, 2017</p>
            <p>
              Download the story: <a
                href={PdfUrls.PRESS_6} target="_blank">
                Reuters Jan 11 2017 Impatience and desperation derail home sales in tight market | Reuters
              </a>
            </p>
            <img src={images['press7.png']} alt="press" height="100px"/>
            <p>
              <a href="https://www.thestreet.com/personal-finance/a-basketful-of-big-economic-predictions-for-2017-13938710">
                Here are the Big Economic Predictions for 2017 You Have to Know
              </a>
            </p>
            <p>
              January 3, 2017 Download the story: <a
               href={PdfUrls.PRESS_7} target="_blank">
                The-street-jan-3-2017-here-are-the-big-economic-predictions-for-2017-you-have-to-know-thestreet
              </a>
            </p>
            <img src={images['press8.jpg']} alt="press" height="100px"/>
            <p>Curbed’s <a href="https://www.curbed.com/article/real-estate-development-2017-predictions.html"> 2017 predictions</a>,
                January 3, 2017. Download the story: <a
                 href={PdfUrls.PRESS_8_1} target="_blank">what-city-real-estate-markets-can-expect-in-2017-curbed
              </a>
            </p>
            <p>CTW Feature Syndicate <a href="http://www.gmnews.com/2016/12/06/home-equity-wealth-is-on-the-rise/"> “Home equity wealth is on the rise,”
              </a> December 13, 2016. Download the story: <a href={PdfUrls.PRESS_8_2} target="_blank">
                Home-equity-wealth-is-on-the-rise-greater-media-newspapers
              </a>
            </p>
            <img src={images['press9.png']} alt="press" height="80px"/>
            <p>November 1, 2016: <a href="https://usrealty.com/">
                USRealty.com</a>, a New York City-based online real estate company, has raised an undisclosed amount <a
                 href="https://fortune.com/tag/term-sheet/">new funding from Third Prime Capital.</a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_9} target="_blank">term-sheet-fortune-nov-1-2016</a></p>
            <img src={images['press10.png']} alt="press" height="80px"/>
            <p>
              October 20, 2016: “
              <a href="https://www.pehub.com/2016/10/usrealty-com-nabs-funding-from-third-prime/">
                USRealty.com nabs funding from Third Prime
              </a>”
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_10} target="_blank">
              pe-hub-usrealty-com-nabs-funding-from-third-prime-pe-hub
              </a>
            </p>
            <img src={images['press11.png']} alt="press" height="20px"/>
            <p>October 20, 2016: <a href="https://www.finsmes.com/2016/10/usrealty-com-closes-financing-with-third-prime-capital.html">
                “USRealty.com Closes Financing with Third Prime Capital”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_11} target="_blank">
                usrealty-com-closes-financing-with-third-prime-capital
              </a>
            </p>
            <img src={images['press12.png']} alt="press" height="100px"/>
            <p>October 12, 2016: <a href="https://themortgagereports.com/22472/housing-market-forecast-experts-predict-2017-real-estate">
                “Housing Market Forecast: Experts Weigh in On 2017 Real Estate”
              </a>
            </p>
            <p>Download the story: <a
              href={PdfUrls.PRESS_12} target="_blank">
                housing-market-forecast-the-mortgage-reports
              </a>
            </p>
            <img src={images['press13.png']} alt="press" height="100px"/>
            <p>September 9, 2016: <a href="https://issuu.com/tsaxton9/docs/rt_0916news/6">
                ”Is the Market Ripe for New Online Models?”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_13} target="_blank">
                september-2016-real-trends
              </a>
            </p>
            <img src={images['press14.png']} alt="press" height="100px"/>
            <p>September 8, 2016: <a href="https://www.thestreet.com/personal-finance/mortgages/5-ways-you-re-sabotaging-your-home-sale-13692041">
                “5 Ways You’re Sabotaging Your Home Sale”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_14_1} target="_blank">
                5-ways-youre-sabotaging-your-home-sale-pg-2-thestreet
              </a>
            </p>
            <p>August 31, 2016 <a href="https://www.thestreet.com/personal-finance/mortgages/home-shoppers-seeking-leverage-in-a-tight-market-13690550">
                “Home Shoppers Seeking Leverage in a Tight Market”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_14_2} target="_blank">
              Home Shoppers Seeking Leverage in a Tight Market – Pg.2 – TheStreet</a>
            </p>
            <img src={images['press15.png']} alt="press" height="50px"/>
            <p>August 30, 2016 <a href="https://realestate.usnews.com/real-estate/articles/what-to-do-when-you-cant-sell-your-house">
                “What to Do When You Can’t Sell Your House”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_15} target="_blank">
                What to Do When You Can’t Sell Your House | US News Real Estate
              </a>
            </p>
            <img src={images['press16.jpg']} alt="press" height="100px"/>
            <p>August 16, 2016 <a href="https://finance.yahoo.com/news/5-things-arent-worth-much-103000292.html?guccounter=1">
                “Five Things that Aren’t Worth as Much as You Think”</a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_16} target="_blank">
                “Yahoo Finance 5 Things That Aren’t Worth As Much Money As You Think”
              </a>
            </p>
            <img src={images['press17.jpg']} alt="press" height="70px"/>
            <p>August 11, 2016, <a href="http://www.gmnews.com/2016/08/11/housing-peaks-and-valleys/">
                “Housing Peaks and Valleys”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_17} target="_blank">
                Housing peaks and valleys – Greater Media Newspapers
              </a>
            </p>
            <img src={images['press18.png']} alt="press"/>
            <p>USRealty.com’s CEO Colby Sambrotto discussed the real estate market with Lauren Young of Reuters Money. Watch the video
              <a href="https://www.facebook.com/ReutersMoney/?ref=br_rs"> here.</a>
            </p>
            <img src={images['press19.jpg']} alt="press" height="100px"/>
            <p>August 11, 2016, <a href="http://www.cheatsheet.com/money-career/things-not-worth-much-money.html/?a=viewall">
              “5 Things that are Not Worth As Much Money as You Think”</a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_19_1} target="_blank">
              5 Things That Are Not Worth as Much Money as You Think</a></p>
            <p>July 19, 2016 <a href="http://www.cheatsheet.com/money-career/bad-home-renovations-lower-homes-value.html/?a=viewall">
                “5 Bad Home Renovations That Lower Your Home’s Value”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_19_2} target="_blank">
                “5 Bad Home Renovations That Lower Your Home’s Value”
              </a>
            </p>
            <img src={images['press20.jpeg']} alt="press" height="100px"/>
            <p>Fox News/Realtor.com June 21, 2016 <a href="http://www.foxnews.com/leisure/2016/06/21/11-budget-friendly-staging-ideas-thatll-wow-buyers/">
                “11 Budget-Friendly Staging Ideas That’ll Wow Buyers”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_20} target="_blank">
                “11 Budget-Friendly Staging Ideas That’ll Wow Buyers” | Fox News
              </a>
            </p>
            <img src={images['press21.png']} alt="press" />
            <p>June 27, 2016 <a href="http://www.inman.com/2016/06/27/homeowners-can-now-sell-mls-no-listing-fee/">
                “Homeowners Can Now Sell on the MLS For No Listing Fee”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_21} target="_blank">
                “Homeowners Can Now Sell on MLS for No Listing Fee”
              </a>
            </p>
            <img src={images['press22.png']} alt="press" height="110px"/>
            <p>June 6th, 2016 <a href="https://www.marketwatch.com/story/want-to-sell-your-house-by-yourself-read-this-first-2016-06-06">
                ”Want to sell your house by yourself? Read this first”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_22} target="_blank">
                “Want to sell your house by yourself? Read this first”
              </a>
            </p>
            <img src={images['press23.png']} alt="press" height="100px"/>
            <p>April 20, 2016 TheStreet <a href="https://www.thestreet.com/story/13537591/1/usrealtycom-launches-new-online-service-revolutionizing-the-home-selling-process.html">
                “USRealty.com Launches New Online Service Revolutionizing Homeselling Process”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_23} target="_blank">
              USRealty.com Launches New Online Service Revolutionizing The Home-Selling Process – TheStreet
              </a>
            </p>
            <img src={images['press24.png']} alt="press" />
            <p>September 24, 2014 <a href="https://www.housingwire.com/articles/31446-usrealtycom-acquires-national-real-estate-brokerage/">
                “USRealty.com Acquires National Real Estate Brokerage”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_24} target="_blank">
                “USRealty.com Acquires National Real Estate Brokerage”
              </a>
            </p>
            <img src={images['press25.png']} alt="press"/>
            <p>September 3, 2013 <a href="https://www.inman.com/2013/09/03/forsalebyowner-com-founder-launches-discount-listing-service/">
                “ForSaleByOwner.com Founder Launches Discount Listing Service”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_25} target="_blank">
                “ForSaleByOwner.com Founder Launches Discount Listing Service”
              </a>
            </p>
            <img src={images['press26.png']} alt="press" height="60px"/>
            <p>April 7, 2013 <a href="https://www.mymove.com/blog/2013/04/green-to-green-cheap-energy-efficiency-upgrades-to-raise-your-property-value.html">
                “Green to Green: Cheap Energy Efficiency Upgrades to Raise Your Property Value”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_26} target="_blank">
                “Green to Green: Cheap Energy Efficiency Upgrades to Raise Your Property Value”
              </a>
            </p>
            <img src={images['press27.jpg']} alt="press" height="120px"/>
            <p>March 28, 2013 <a href="http://www.baltimoresun.com/features/home-garden/bs-re-selling-season-20130328-story.html">
                “With online technology, buying and selling options abound”
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_27} target="_blank">
                “With online technology, buying and selling options abound”
              </a>
            </p>
            <img src={images['press28.jpg']} alt="press" height="80px"/>
            <p>
              <a href="http://www.bobvila.com/articles/real-estate-commissions/#.V1g3EvkrJhE">
                Fee for All: How to Keep More Equity.
              </a>
            </p>
            <p>Download the story: <a href={PdfUrls.PRESS_28} target="_blank">
                BobVila – Real Estate Commissions
              </a>
            </p>
            <img src={images['press29.png']} alt="press" height="80px"/>
            <p>May 5, 2012 <a href="https://www.economist.com/business/2012/05/05/the-great-realtor-rip-off">
              The Great Realtor Ripoff</a>
            </p>
      </>
    );
  };
export default MediaCoverageList;
