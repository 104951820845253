import React from 'react';
import { ImagesUrl } from "../../../../../Constants/Images";
import AppConsts from '../../../../../Constants/AppConsts';

class HomeSellingGuide extends React.Component {
  render() {
    return (
      <>
        <div className={`klaviyo-form-${AppConsts.klaviyoForms.homeSellingFormId}`} />
        <h2>A Guide to Selling your Home</h2>
        <p>
          As you work your way through your marketing and pricing research and decisions, you also need to be preparing the house for sale.
          Be sure to use our handy checklists to keep track of what you have accomplished and what you still have left to do.
        </p>
        <p>
          Potential buyers will see your house two ways: online and in person. That means that you must first prepare the house
          for photos that you’ll use in your USRealty.com listing; other ads, both online and in print; and in printed flyers.
          Once that’s done, prepare the house for showing potential buyers in person.
        </p>
        <a href={ImagesUrl.HOME_SELLING_GUIDE} target="_blank" className="download-link">Download the Ready to Sell Checklist</a>
      </>
    )
  }
}

export default HomeSellingGuide;
