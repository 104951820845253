import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { adminActionCreators } from '../../../Actions/AdminActions';

const SelectTagComponent = ({
  selectedItem,
  options = [],
  userFormId,
  updateMlsSubmissionStatus,
}) => {

  const [selectOption, setOptions] = useState('');

  useEffect(() => {
    setOptions(selectedItem);
  }, [selectedItem]);

  const handleChange = e => {
    const { value } = e.target;
    const selectedOptions = options.find(item => item.value === value);
    setOptions(value);
    updateMlsSubmissionStatus('MlsSubmission', {
      userFormId,
      formSubmissionStatusId: selectedOptions.key,
    });
  };

  return (
    <select value={selectOption} onChange={handleChange}>
      {options.map((item, index) => (
        <option key={`${item.value} ${index}`} value={item.value}>{item.value}</option>
      ))}
    </select>
  );
};

export const SelectTag = connect(null, { ...adminActionCreators })(
  SelectTagComponent,
);
