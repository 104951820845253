import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {
  plansFeaturesSelector,
  plansSelector,
  draftListingIdSelector,
  propertyPriceSelector,
  mlsCategoryIdSelector,
  propertyTypeIdSelector,
  selectedAddressSelector,
  redeemedVoucherSelector,
  productTypeSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { propertyTypesSelector, mlsCategoriesSelector } from '../../../Reducers/LookupReducer';
import { UIActionsCreators } from '../../../Actions/UIActions';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import ComparePlansModal from '../Components/ComparePlansModal';
import { gTag } from '../../../Utilities/utils';
import CashOfferPanel from '../Components/CashOfferPanel';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import ListingPreviewMobileContainer from '../Components/ListingPreviewMobileContainer';
import { EmbeddedYoutubeHintsPlayer } from '../Components/EmbeddedYoutubeHintsPlayer';
import { LYFSteps } from '../../../Constants/CommonConstants';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    padding: 0;
    margin-right: 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 1.5rem;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 1rem;
  line-height: 1.5rem;
  color: #5b605c;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 60%;
  }
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    padding: 0;
    justify-content: flex-start;
  }
`;

const PlanItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 15rem;
  height: 36rem;
  padding: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 8px;
  border-width: 1.5px;
  border-style: dashed;
`;

const PlanTitle = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const PlanPrice = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 2rem;
  padding-top: 0.5rem;
`;

const PlanDescription = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem
  line-height: 1.25rem;
  color: #5B605C;
  padding-top: 0.75rem;
`;

const PlanFeatureText = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  padding-top: 1.5rem;
`;

const PlanAndMoreButton = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  position: absolute;
  bottom: 6rem;
  align-self: center;
`;

const ComparePlanButton = styled.button`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25.rem;
  text-decoration-line: underline;
  background: none;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: absolute;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 10rem;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  bottom: 2.8rem;
  right: 2.3rem;
`;

const SubtitleContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Divider = styled.div`
  margin-top: 5rem;
  background-color: #f4f4f4;
  height: 2px;
  margin-right: 0.5rem;
`;

const FooterText = styled.div`
  margin-top: 2rem;
  color: #919592;
  font-size: 0.75rem;
  font-family: 'National-Regular';
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const BackButton = styled(SubmitButton)`
  position: static;
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const PlanSelector = () => {
  const dispatch = useDispatch();
  const plans = useSelector(plansSelector);
  const plansFeatures = useSelector(plansFeaturesSelector);
  const draftListingId = useSelector(draftListingIdSelector);
  const propertyTypeId = useSelector(propertyTypeIdSelector);
  const mlsCategoryId = useSelector(mlsCategoryIdSelector);
  const propertyPrice = useSelector(propertyPriceSelector);
  const propertyTypes = useSelector(propertyTypesSelector);
  const mlsCategories = useSelector(mlsCategoriesSelector);
  const selectedAddress = useSelector(selectedAddressSelector);
  const redeemedVoucher = useSelector(redeemedVoucherSelector);
  const productType = useSelector(productTypeSelector);
  const [showListingPreview, setShowListingPreview] = useState(false);
  const isListingNetworkPlanOrder = productType === 'Network';

  useEffect(() => {
    if (redeemedVoucher) {
      dispatch(
        onboardingActionCreatorsV2.createListingV2({
          draftListingId: draftListingId,
          isOrderByVoucher: true,
        })
      );

      return;
    }
    dispatch(onboardingActionCreatorsV2.getListingProductsV2(draftListingId));
  }, []);

  const handSelectPlan = (plan) => {
    dispatch(onboardingActionCreatorsV2.setPlanV2(plan));

    if (plan.priceUpFront == 0) {
      const payment = {
        draftListingId: draftListingId,
        productId: plan.productId,
        cardHolderName: '',
        cardToken: '',
        isOrderByVoucher: false,
        isOrderByInstitutionalUser: false,
        isOrderByUnbounceUser: false,
        zip: '',
        country: '',
      };

      dispatch(
        onboardingActionCreatorsV2.createListingV2(payment, (paymentSuccess) => {
          gTag({
            event: 'purchase',
            ecommerce: {
              checkout: {
                actionField: {
                  revenue: plan.priceUpFront,
                },
                products: [
                  {
                    name: selectedAddress?.formatted,
                    price: propertyPrice,
                    payment_status: paymentSuccess ? 'accepted' : 'reject',
                    plan_price: plan.priceUpFront,
                  },
                ],
              },
            },
          });
        })
      );
    } else {
      const category = mlsCategories.find((o) => Number(o.id) === Number(mlsCategoryId))?.name;
      const name = propertyTypes.find((o) => Number(o.id) === Number(propertyTypeId))?.name;
      gTag({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 7,
            },
            products: [
              {
                name,
                category,
                price: propertyPrice,
              },
            ],
          },
        },
      });
      dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_BILLING));
    }
  };

  const getPlanDescription = (plan) =>
    plan?.productDescription?.length > 200 ? `${plan?.productDescription?.slice(0, 200)}...` : plan?.productDescription;

  const getPlanPriceDescription = (plan) =>
    plan?.priceDescription?.length > 100 ? `${plan?.priceDescription?.slice(0, 100)}...` : plan?.priceDescription;

  const formatPrice = (num) => num.toFixed(2);
  return (
    <Container>
      {showListingPreview ? (
        <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
      ) : (
        <FormContainer>
          <TitleContainer>
            <SubTilteText>List your property</SubTilteText>
            <StepsText>8/9</StepsText>
          </TitleContainer>
          <TitleText>Our Plans & Pricing</TitleText>
          <SubtitleContainer>
            <Text>Select a plan below.</Text>
            <ComparePlanButton
              disabled={!plansFeatures.length}
              onClick={() => dispatch(UIActionsCreators.openComparePlansModal())}
            >
              Compare Plans
            </ComparePlanButton>
          </SubtitleContainer>
          <EmbeddedYoutubeHintsPlayer mobile={true} width="296" height="536" />
          <PlanContainer>
            {plans.map((plan) => (
              <PlanItem key={plan.productId}>
                <PlanTitle>{plan.title}</PlanTitle>
                <PlanPrice>{formatPrice(plan.priceUpFront)}</PlanPrice>
                <PlanDescription dangerouslySetInnerHTML={{ __html: getPlanPriceDescription(plan) }} />
                <PlanFeatureText dangerouslySetInnerHTML={{ __html: getPlanDescription(plan) }} />
                {plan.productDescription?.length > 200 && (
                  <>
                    <ReactTooltip className="tooltip" multiline={true} id={'pd' + plan.productId + ''}>
                      <span dangerouslySetInnerHTML={{ __html: plan.productDescription }} />
                    </ReactTooltip>
                    <PlanAndMoreButton data-tip data-for={'pd' + plan.productId + ''}>
                      And more...
                    </PlanAndMoreButton>
                  </>
                )}
                <SubmitButton type="button" onClick={() => handSelectPlan(plan)}>
                  Select
                </SubmitButton>
              </PlanItem>
            ))}
          </PlanContainer>
          <Divider />
          <CashOfferPanel
            listindAddressDetails={{
              ...(selectedAddress || {})
            }}
            draftListingId={draftListingId}
            price={propertyPrice}
          />
          <FooterText>
            There is a small compliance fee of 0.5% of your property’s gross purchase price that will apply only when
            your property sells which covers our cost of ensuring compliance with local/state laws and MLS rules.
            The amount or rate of real estate commissions is not fixed by law. They are set by each broker individually and may be negotiable between Owner and Broker.
          </FooterText>
          <ButtonsContainer>
            <MobilePreviewButton
              onClick={() => {
                setShowListingPreview(true);
              }}
            >
              <EyeButton />
            </MobilePreviewButton>
            {!isListingNetworkPlanOrder && (
              <BackButton type="button" onClick={() => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_TITLE))}>
                Back
              </BackButton>
            )}
          </ButtonsContainer>
        </FormContainer>
      )}
      <ListingPreviewDesktopContainer />
      <ComparePlansModal />
    </Container>
  );
};

export default PlanSelector;
