import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import ApiUrls from '../Constants/ApiUrls';
import { contentType, apiMethodType } from '../Constants/CommonConstants';

class OnboardingServiceV2 {

  constructor() {

    let urls = ((window.config || {}).urls || {}).onboarding || {};

    urls.base = urls.base || "/";

    this.urls = urls;

  }

  setLocal(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  getLocal(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  clearLocal(key) {
    localStorage.removeItem(key);
  }

  nonServiceableAreaRequestV2(params) {
    const url = ApiUrls.nonServiceableAreaRequest;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  getInactiveListingsV2(market, query) {
    const url = `/api/v2/markets/${market}/listings/inactive?${query}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getInactiveListingV2(id, market) {
    const url = `/api/v2/markets/${market}/listings/inactive/${id}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getAveragePriceRangeV2(market, query) {
    const url = `/api/v2/markets/${market}/listings/price/avg?${query}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getEstimatedPriceV2(query) {
    const url = `${ApiUrls.estimatePrice}?${query}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  validateVoucher(voucherCode) {
    const url = `/api/v2/vouchers/${voucherCode}/validate`;
    return fetch(url, {
      method: apiMethodType.POST,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  sendContact(contact) {
    const url = `/api/v2/draftlistings/contacts`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: contact,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  checkAddressV2(query) {
    const url = `${ApiUrls.checkAddress}?${query}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getListingProductsV2(draftListingId) {
    const url = `/api/v2/draftlistings/${draftListingId}/products`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  createDraftListingV2(data) {
    const url = `${ApiUrls.createDraftListing}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  createListingV2(data) {
    const url = `${ApiUrls.createListing}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  updateDraftListingV2(data) {
    const url = `${ApiUrls.updateDraftListing}`;
    return fetch(url, {
      method: apiMethodType.PUT,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getDraftListingById(id) {
    const url = `${ApiUrls.getDraftListingById}/${id}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  addListingPhotoThumb(draftListingId, fileName, fileContent) {
    const data = {
      draftListingId,
      fileContent,
      fileName,
    };

    const url = `${ApiUrls.addListingPhotoThumb}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  addListingPhoto(draftListingId, fileName, fileContent, order) {
    const data = {
      draftListingId,
      order,
      fileContent,
      fileName,
    };

    const url = `${ApiUrls.addListingPhoto}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  reorderListingPhoto(data) {
    const url = `${ApiUrls.reorderListingPhoto}`;
    return fetch(url, {
      method: apiMethodType.PUT,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  reorderListingPhotos(data) {
    const url = `${ApiUrls.reorderListingPhotos}`;
    return fetch(url, {
      method: apiMethodType.PUT,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }


  deleteListingPhoto(data) {
    const url = `${ApiUrls.deleteListingPhoto}`;
    return fetch(url, {
      method: apiMethodType.DELETE,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getDraftListingPhotos(listingId) {
    const url = `api/v2/draftlistings/${listingId}/images`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
  
  getPropertyDetailsForm(draftListingId) {
    const url = `/api/v2/draftlistings/${draftListingId}/forms/propertydetails`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  saveFormFieldV2(userFormId, formFieldId, value) {

    const url = this.urls.saveField || ApiUrls.saveFormField;

    return fetch(url, {
        method: apiMethodType.POST,
        data: { userFormId, formFieldId, value: value ? `${value}` : null },
        headers: {
            'Content-Type': contentType.applicationJson,
            ...authHeader()
        }
    });
}

submitMLSFormV2(draftListingId) {
  const url = `/api/v2/listings/${draftListingId}/sign`;
  return fetch(url, {
      method: apiMethodType.PUT,
      headers: {
          'Content-Type': contentType.applicationJson,
          ...authHeader()
      }
  },10 * 60 * 10000);
}

validateAddressV2(userId, address){
  const url = `/api/v2/listings/users/${userId}/address/validate`;
  return fetch(url, {
    method: apiMethodType.POST,
    data: address,
    headers: {
      'Content-Type': contentType.applicationJson,
      ...authHeader()
  }
  });
}

resubmitMLSFormV2(listingId) {
  const url = `/api/v2/listings/${listingId}/resign`;
  return fetch(url, {
      method: apiMethodType.PUT,
      headers: {
          'Content-Type': contentType.applicationJson,
          ...authHeader()
      }
  },10 * 60 * 10000);
}

savePreviewFormFieldV2(reqObj) {

  const url = this.urls.saveField || ApiUrls.savePreviewFormField;

  return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
          'Content-Type': contentType.applicationJson,
          ...authHeader()
      }
  });
}

getListingFormsV2(listingId) {
  const url = `/api/v2/listings/${listingId}/forms`;

  return fetch(url, {
      method: apiMethodType.GET,
      headers: {
          'Content-Type': contentType.applicationJson,
          ...authHeader()
      }
  });
}

}

export let onboardingServiceV2 = new OnboardingServiceV2();
