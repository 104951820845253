import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { RowFlex, InputFlex, CustomEditor, FormHeading } from '../../../Assets/Style/commonStyleComponents';
import CurrencyInput from 'react-currency-input-field';
const Wrapper = styled.div``;

const DetailsWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #f9f9f9;
`;

class BasicFormView extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef(null);
  }

  render() {
    const { initialState, handleFieldChanged, handleFieldBlur, handleEditorChange } = this.props;
    const isPropertyTypeLand = Number(initialState?.propertyType?.value) === 11;
    return (
      <React.Fragment>
        <Wrapper>
          <FormHeading>Let's start out with some basic information</FormHeading>
          <DetailsWrapper>
            <form onSubmit={this.handleSaveAccountDetails} autoComplete="off">
              <RowFlex>
                <InputFlex
                  options={initialState.propertyType.options}
                  name={initialState.propertyType.name}
                  title={initialState.propertyType.label}
                  value={initialState.propertyType.value}
                  error={initialState.propertyType.error}
                  onChange={(e) => handleFieldChanged(e)}
                  onBlur={(e) => handleFieldBlur(e)}
                  placeholder={initialState.propertyType.placeholder}
                  className="inputgap"
                />
                <div className="sc-bdVaJa cdSvZM sc-eHgmQL hPeqfu inputgap">
                  <label for="listPrice" className="sc-bwzfXH fSojND">
                    Asking Price
                  </label>
                  <div className={'sc-ifAKCX enoIGR ' + (initialState.listPrice.error ? 'input-invalid' : '')}>
                    <CurrencyInput
                      decimalsLimit={2}
                      intlConfig={{ locale: 'en-US', currency: 'USD' }}
                      value={initialState.listPrice.value || ''}
                      name={initialState.listPrice.name}
                      onValueChange={(e) => handleFieldChanged(e, initialState.listPrice.name)}
                      onBlur={(e) => handleFieldBlur(e)}
                      className="sc-EHOje ciHboa"
                    />
                    {initialState.listPrice.error && <div className="error-txt">Please enter a list price</div>}
                  </div>
                </div>

                <InputFlex
                  type="number"
                  name={initialState.bedrooms.name}
                  title={initialState.bedrooms.label}
                  value={initialState.bedrooms.value}
                  error={initialState.bedrooms.error}
                  onChange={(e) => handleFieldChanged(e)}
                  disabled={isPropertyTypeLand}
                  className="inputgap"
                />
              </RowFlex>
              <RowFlex>
                <>
                  <InputFlex
                    type="number"
                    name={initialState.bathrooms.name}
                    title={initialState.bathrooms.label}
                    value={initialState.bathrooms.value}
                    error={initialState.bathrooms.error}
                    onChange={(e) => handleFieldChanged(e)}
                    disabled={isPropertyTypeLand}
                    className="inputgap"
                  />
                  <InputFlex
                    type="number"
                    name={initialState.squareFeet.name}
                    title={initialState.squareFeet.label}
                    value={initialState.squareFeet.value}
                    error={initialState.squareFeet.error}
                    onChange={(e) => handleFieldChanged(e)}
                    disabled={isPropertyTypeLand}
                    className="inputgap"
                  />
                </>

                <InputFlex
                  type="number"
                  name={initialState.lotAcreage.name}
                  title={initialState.lotAcreage.label}
                  value={initialState.lotAcreage.value}
                  error={initialState.lotAcreage.error}
                  onChange={(e) => handleFieldChanged(e)}
                  className="inputgap"
                />
              </RowFlex>
              <CustomEditor>
                <label>{initialState.description.label}</label>
                <Editor
                tinymceScriptSrc={process.env.REACT_APP_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => {
                  this.editorRef.current = editor;
                }}
                  value={initialState.description.value}
                  init={{
                    height: 'calc(100vh - 156px)',
                    width: '100%',
                    menubar: false,
                    toolbar:
                      'bold italic underline forecolor alignleft aligncenter alignright numlist insertfile image link | a11ycheck ltr rtl |',
                  }}
                  onEditorChange={handleEditorChange}
                />
              </CustomEditor>
            </form>
          </DetailsWrapper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.user.isFetching,
  };
}

export default connect(mapStateToProps)(BasicFormView);
