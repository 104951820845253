import React, { useState } from "react";
import logo from "../../../Assets/Svg/usr-logo.svg";
import "../../../Assets/Style/home-listing.css";
import LandingRightSide from './LandingRightSide';
import { Elements } from 'react-stripe-elements';
import UnbounceFromView from './UnbounceFromView';

import {
  SignUpHomeListingFormWrapper,
  ModalDiv,
  Container,
  SignUpHomeListing,
  SignUpHomeListingWrap,
  SignUpHomeListingHeading,
  LogoWrap,
  MobileView
} from "../../../Assets/Style/commonStyleComponents";

export default function LandingFormView() {
  const [isOpen, setIsPopUp] = useState(false);
  const openModal = () => {
    setIsPopUp(true);
  };
  const closeModal = () => {
    setIsPopUp(false);
  };

  return (
    <>
      <SignUpHomeListing>
        <Container>
          <MobileView>
            <LogoWrap>
              <img src={logo} alt="USRealty.com" />
            </LogoWrap>
            <SignUpHomeListingHeading>
              Sign up to create your home’s listing
            </SignUpHomeListingHeading>
          </MobileView>
          <SignUpHomeListingWrap>
            <SignUpHomeListingFormWrapper>
              <Elements fonts={[{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:200,400,700" }]}>
                <UnbounceFromView
                  openModal={openModal}>
                </UnbounceFromView>
              </Elements>
            </SignUpHomeListingFormWrapper>

            <LandingRightSide></LandingRightSide>
          </SignUpHomeListingWrap>
        </Container>
      </SignUpHomeListing>
      <ModalDiv
        visible={isOpen}
        onCancel={closeModal}
        onOk={closeModal}
        okText="No"
        cancelText="No"
        closable={true}
        destroyOnClose={true}
        className="beyondCustomerSupport-modal"
      >
        <div className="beyondCustomerSupportWrapp">
          <h4>Enjoy peace of mind that goes beyond customer support</h4>
          <p>
            With our premium Agent Concierge package, you’ll have access to licensed agent
            <strong> Penny Whillhite and her team of expert Texas real estate professionals.
              </strong> They’ll support and guide you every step of
              the way during the sale of your home for an 
              <strong> upfront one-time fee.</strong>
          </p>
          <p>
            From creating the perfect listing to attract the most offers
           (and highest price for your home)… To dotting the i’s and crossing the t’s on all the confusing paperwork that comes with selling your home.
            Our team is here to make the entire process as effortless and stress-free as possible.
          </p>
          <h5>What you’ll get with our Agent Concierge service:</h5>
          <ul>
            <li>Chat, email, and phone access to the Penny Willhite team for guidance and answers to your toughest real estate questions, xxx days per week</li>
            <li>MLS data home pricing report</li>
            <li>Photos of your home by a professional photographer</li>
            <li>ShowingTime automated showing app & text tool to receive appointment requests and feedback </li>
            <li>Guidance on staging your home</li>
            <li>Offer review and negotiation support</li>
            <li>Transaction support (tracking forms and deadlines support your home sale).</li>
          </ul>
          <div className="modal-divider"></div>
          <div className="modal-custom-footer">
            <strong className="seller-review">“Very helpful. They patiently guided me through the process.”</strong>
            <span className="seller-name">Evelyn Smith - Home seller</span>
          </div>
        </div>
      </ModalDiv>
    </>
  );
}
