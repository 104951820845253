import React from 'react';
import styled from 'styled-components';
import ApiUrls from '../../../Constants/ApiUrls';
import { gTag } from '../../../Utilities/utils';
import { FacebookIcon } from './Icons/FacebookIcon';
import { GoogleIcon } from './Icons/GoogleIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
`;

const Button = styled.a`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 100%;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 0.85rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.light};
  margin-top: 0.5rem;
  &:hover {
    color: ${({ theme }) => theme.light};
  }
`;

const Google = styled(GoogleIcon)`
  position: absolute;
  left: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
`;

const Facebook = styled(FacebookIcon)`
  position: absolute;
  left: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
`;
const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  hr {
    opacity: 1;
    border-width: 0px 0px 1px;
    border-image: initial;
    border-color: inherit;
    border-style: solid;
    width: 100%;
    border-color: ${({ theme }) => theme.colors.mouseballgray[200]}
  }
  span {
    font-size: 10px;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
  }
`;

export const SocialNetworksAuthButtons = () => {

  return (
      <Container>
        <Button
            color="#4285f4"
            href={`${ApiUrls.googleAuth}${encodeURIComponent(window.location.href)}`}
            onClick={() => gTag({ 'event': 'sign_in', 'method': 'google' })}
        >
          <Google/>
          Continue with Google
        </Button>
        <Button
          color="#4267B2"
          href={`${ApiUrls.facebookAuth}${encodeURIComponent(window.location.href)}`}
          onClick={() => gTag({ 'event': 'sign_in', 'method': 'facebook' })}
        >
          <Facebook />
          Continue with Facebook
        </Button>
        <DividerContainer>
          <hr/>
          <span>or</span>
          <hr/>
        </DividerContainer>
      </Container>
  )
}