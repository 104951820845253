import usrLogo from '../Assets/Images/wordmark_green.png';

export const themes = {
    usRealty : {
        primary: '#203f8f',
        secondary: '#B01F24',
        dark: '#242B26',
        lavender: '#B698D9',
        lightLavender: '#dbcceb',
        lightGreen: '#DBE8E0',
        light: '#ffffff',
        // new color scheme 
        suburbanSlate: '#2E292B',
        moveInMauve: '#CCB3EF',
        bayWindowBlue: '#DDE8E0',
        craftsmanCream: '#FFFCEF',
        rebelYellowGreen: '#E7E977',
        brooklynBrownstone: '#8A7E7A',
        primeLime: '#E1FE60',
        unrealEcru: '#E5DFC3',
        logo: usrLogo,
        breakpoints: {
            sm: '30em', // 480px
            md: '48em', // 768px
            lg: '62em', // 992px
            xl: '80em', // 1280px
            '2xl': '96em', // 1536px
        },
        colors: {
            red: {
              500: "#E53E3E",
            },
            green: {
              50: "#eaf7f0",
              100: "#d0e1d7", // Light green
              200: "#b3ccbd",
              300: "#96b8a3",
              400: "#78a588",
              500: "#5e8b6f",
              600: "#4a6c57",
              700: "#344e3e",
              800: "#1e2f24",
              900: "#242B26", // Dark green
            },
            darkGreen: {
              100: "#1E2321",
            },
            violet: {
              50: "#f4ebfe",
              100: "#d8c7ec",
              200: "#b698d9", // Soft violet
              300: "#a37dce",
              400: "#8858bf",
              500: "#6f3fa6",
              600: "#563081",
              700: "#3d225d",
              800: "#25143a",
              900: "#0e0518",
            },
            mouseballgray: {
              50: "#fceff2",
              100: "#d3dada",
              200: "#bdc0bf",
              300: "#a7aaa8",
              400: "#8b8e8c",
              500: "#727573",
              600: "#585b59",
              700: "#3e413e",
              800: "#242824",
              900: "#16090d",
            },
            grayBackground: "#F4F4F4",
            borderColor: "#E9EAE9",
        }
    }
}


