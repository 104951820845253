import React from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import publicIp from 'public-ip';
import styled, { withTheme } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import v8n from 'v8n';
import { DatePicker } from 'antd';
import moment from 'moment';
import Cookies from 'js-cookie';
import { uniqBy } from 'lodash';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { userActionCreators } from '../../../Actions/UserActions';
import { history } from '../../../Utilities/History';
import enums from '../../../Constants/Enums';
import PhotoManager from '../Components/PhotoManager';
import BasicFormView from '../Components/BasicFormView';
import ShowMap from '../Components/ShowMap';
import { editListingValue } from '../../../Constants/CommonConstants';
import { dataService } from '../../../Services/DataService';
import {
  ModalDiv,
  CustomButton,
  FormHeading,
  StepsWrapper,
  StepBox,
  MainWrapper,
  Container,
} from '../../../Assets/Style/commonStyleComponents';
import { smoothScrollTop, goBack, checkIsEmpty } from '../../../Utilities/commonFunctions';
import Routes from '../../../Constants/Routes';
import { formType, formLabelValue, validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import CurrencyInput from 'react-currency-input-field';
import Navbar from '../../Shared/Components/Navbar';
import Footer from '../../Shared/Components/Footer';
import { TitleAndEscrowForm } from '../Components/TitleAndEscrowForm';

const FormWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  > h1,
  > h2,
  > h3 {
    font-weight: 100;
    margin: 0;
  }

  > h3 {
    font-size: 12px;
    color: ${({ theme }) => theme.secondary};
  }

  > h3 a {
    font-size: 12px;
  }
  .ant-steps-item-finish {
    .ant-steps-icon {
      top: -3px;
    }
  }
  .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const Form = styled.article`
  flex: 2;
  display: block;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  overflow: auto;
  height: 100%;
  border: 1px solid #dadada;
  border-radius: 6px;
  ul,
  ol {
    margin-left: 17px;
    ul,
    ol {
      margin-left: 0;
    }
  }
  h1 {
    font-size: 26px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    color: #242b26;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0;
  }
  h2 {
    font-size: 16px;
    color: #242b26;
    text-align: center;
    margin-bottom: 25px;
  }
  li {
  }
`;

const Group = styled.section``;

const Section = styled.div`
  display: block;
  margin: 0 0 20px;

  font-size: 14px;
  line-height: 26px;

  p {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 26px;
  }

  strong {
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: 2px;
    padding: 5px;
    margin: 0 2px;
  }
  select {
    height: 38px;
    min-width: 140px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px auto 30px;
  &.for-flex {
    display: flex;
    button {
      &:first-child {
        margin-right: 7px;
      }
      &:last-child {
        margin-left: 7px;
      }
    }
  }
  &.button-gap {
    margin: 0 auto 15px;EditListing
  }
`;

const FormDatePicker = styled.div`
  display: inline;
  padding-left: 3px;
  input {
    border-radius: 0;
    border: 1px solid #b698d9;
    font-size: 15px;
    &:focus {
      box-shadow: none;
    }
  }
`;

const StepWrap = styled.header``;
const inputErrorClass = 'error-input';
const inputErrorClassOption = 'error-input-option';
const inputErrorClassChecbox = 'error-input-checkbox';
const disabled_input = 'disabled-input';
const disabled_input_overlay = 'disabled-input-overlay';
let overlaySignFlag = enums.NUMERIC_VALUE.ZERO;
let showOwner = enums.NUMERIC_VALUE.TWO;

function disabledDate(current) {
  return current && current < moment().add(-1, 'days');
}

class EditListing extends React.Component {
  constructor(props) {
    super(props);
    this.ipAddress = '';
    (async () => {
      this.ipAddress = await publicIp.v4();
    })();
    this.previewArr = [];
    this.state = {
      originalFormFieldValue: null,
      refErrorname: '',
      step: 0,
      preStep: 0,
      isError: false,
      showSign1: false,
      showSign2: false,
      showSingCount: 2,
      isFormInstructionRead: false,
      isPhotoLimitReached: false,
      isMLSFormSubmitted: false,
      manipulatedFormField: {},
      linkedFormArr: {},
      linkedValState: {},
      linkedChangeValue: {},
      PreFillFormFilled: {},
      notInLinkedChangeValue: {},
      targetValues: {},
      handleLinked: false,
      changeBasicFormField: true,
      errors: {},
      filterSellerSigId: {},
      filterEmail: {},
      showErrorModalPopup: false,
      latitude: '',
      longitude: '',
      checkForLastForm: false,
      fields: {
        propertyType: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a property type.',
            },
          ],
          question: 'What type of property are you selling?',
          value: props.listing.propertyType || '',
          options: props.listing.propertyTypes || [],
          placeholder: '- Select a property type -',
          label: 'Property Type',
          name: 'propertyType',
          isValueChange: false,
        },
        listPrice: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a List price',
            },
          ],
          question: 'What price would you like to list your property for?',
          value: props.listing.listPrice || '',
          label: 'Asking Price',
          name: 'listPrice',
          isValueChange: false,
        },
        bedrooms: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter the number of bedrooms',
            },
            {
              rule: v8n().numeric(),
              message: validationMessages.valueRequired,
            },
          ],
          question: 'How many bedrooms does the property have?',
          value: props.listing.bedrooms || '',
          label: 'Bedrooms',
          name: 'bedrooms',
          isValueChange: false,
        },
        bathrooms: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter the number of bathrooms',
            },
            {
              rule: v8n().numeric(),
              message: validationMessages.valueRequired,
            },
          ],
          question: 'How many bathrooms does the property have?',
          value: props.listing.bathrooms || '',
          label: 'Bathrooms',
          name: 'bathrooms',
          isValueChange: false,
        },
        squareFeet: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter the square footage of your property',
            },
            {
              rule: v8n().numeric().not.empty(),
              message: validationMessages.valueRequired,
            },
          ],
          question: 'What is the square footage of your property?',
          value: props.listing.squareFeet || '',
          label: 'Square Feet',
          name: 'squareFeet',
          isValueChange: false,
        },
        lotAcreage: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter the lot acreage',
            },
            {
              rule: v8n().numeric().not.empty(),
              message: validationMessages.valueRequired,
            },
          ],
          question: 'What is the total lot acreage?',
          value: props.listing.lotAcreage || '',
          label: 'Lot Acreage',
          name: 'lotAcreage',
          isValueChange: false,
        },
        description: {
          rules: [],
          value: props.listing.description || '',
          label: 'Description',
          name: 'description',
          isValueChange: false,
        },
      },
    };
    this.isLastFormId = false;
    this.isFirstFormValid = false;
    this.stepCount = 0;
    this.listingForm = {};
    this.listingFormArrList = [];
    this.prevTitle = '';
  }

  componentDidMount() {
    this.props.getListing(this.props.match.params.listingId);
    dataService.clearLocal('isLinkedArray');
    this.prevTitle = document.title;
    document.title = 'Edit My Listing | Unreal Estate';
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  static getDerivedStateFromProps(props, prevState) {
    const fields = { ...prevState.fields };
    if (fields.propertyType.options !== props.listing.propertyTypes) {
      const queryStep = history.location?.state?.formStep;
      let step = queryStep ? Number(queryStep) : enums.NUMERIC_VALUE.ONE;
      let isFormInstructionRead = false;
      const currentListId = props.match.params.listingId;
      const cookieslistArr = Cookies.get('listngIdArr') ? JSON.parse(Cookies.get('listngIdArr')) : [];

      if (!cookieslistArr.includes(currentListId)) {
        isFormInstructionRead = true;
      } else {
        isFormInstructionRead = false;
      }

      fields.propertyType.options = props.listing.propertyTypes || [];

      for (const fieldName in fields) {
        fields[fieldName].value = props.listing[fieldName];
      }
      return { ...prevState, fields, step, isFormInstructionRead };
    }
    return null;
  }

  handleEditorChange = (content) => {
    const fields = { ...this.state.fields };

    fields['description'].value = content;

    this.setState({ fields: { ...fields } });
    this.props.handleFieldChange({
      listingId: this.props.listing.listingId,
      fieldName: 'description',
      value: content,
    });
  };

  handleFieldChanged = (e, fieldname) => {
    const fields = { ...this.state.fields };

    let isCheck = false;
    let newFieldName = '';
    if (fieldname) {
      //e.target.name=fieldname;
      fields[fieldname].value = e;
      fields[fieldname].isValueChange = true;
      newFieldName = fieldname;
    } else {
      fields[e.target.name].value = e.target.value;
      fields[e.target.name].isValueChange = true;
      newFieldName = e.target.name;
    }

    let linkName;
    if (newFieldName === 'propertyType') {
      isCheck = true;
    } else if (newFieldName === 'listPrice') {
      linkName = Enums.NUMERIC_VALUE.TWENTY;
      isCheck = true;
    } else if (
      newFieldName === 'bedrooms' &&
      (e.target.value === '' || e.target.value.match(/^\d{1,}(\.\d{0,1})?$/)) &&
      e.target.value.length < Enums.NUMERIC_VALUE.NINE
    ) {
      linkName = Enums.NUMERIC_VALUE.TWENTY_ONE;
      isCheck = true;
    } else if (
      newFieldName === 'bathrooms' &&
      (e.target.value === '' || e.target.value.match(/^\d{1,}(\.\d{0,1})?$/)) &&
      e.target.value.length < Enums.NUMERIC_VALUE.NINE
    ) {
      linkName = Enums.NUMERIC_VALUE.TWENTY_FOUR;
      isCheck = true;
    } else if (
      newFieldName === 'squareFeet' &&
      (e.target.value === '' || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) &&
      e.target.value.length < Enums.NUMERIC_VALUE.NINETEEN
    ) {
      linkName = Enums.NUMERIC_VALUE.TWENTY_TWO;
      isCheck = true;
    } else if (
      newFieldName === 'lotAcreage' &&
      (e.target.value === '' || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) &&
      e.target.value.length < Enums.NUMERIC_VALUE.NINETEEN
    ) {
      linkName = Enums.NUMERIC_VALUE.TWENTY_ONE;
      isCheck = true;
    }
    if (isCheck) {
      let linkedArr = dataService.getLocal('isLinkedArray');

      if (linkedArr) {
        var myNewArray = linkedArr.filter(function (item) {
          return item.formPrefillTypeId !== linkName;
        });
        dataService.setLocal('isLinkedArray', myNewArray);
      }

      this.setState({ fields: { ...fields } });
    }
  };

  handleFieldBlur = (e) => {
    if (!this.validateField(e.target.name, e.target.value)) {
      this.props.handleFieldChange({
        listingId: this.props.listing.listingId,
        fieldName: e.target.name,
        value: e.target.value,
      });
    }
  };

  getCheckboxLabelLength = (node) => {
    let val = 0;
    if (node?.prev?.name === editListingValue.strong) {
      val = node?.prev?.children?.[0]['data'].length;
      return val;
    }
    if (!node?.prev) {
      return val;
    }
    return this.getCheckboxLabelLength(node?.prev);
  };

  validateField = (key, value) => {
    const fields = { ...this.state.fields };

    let isError = false;

    for (let i = 0; i < fields[key].rules.length; i++) {
      const rule = fields[key].rules[i];
      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }
    if (!isError) {
      fields[key].error = null;
    }

    this.setState({
      fields: { ...fields },
      isError: Object.keys(fields).some((k) => fields[k].error !== undefined && fields[k].error !== null),
    });

    return isError;
  };

  handleBasicFieldChange = (e) => {
    this.props.handleFieldChange({
      listingId: this.props.listing.listingId,
      fieldName: e.target.name,
      value: e.target.value,
    });
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;
    const fieldData = { ...this.state.manipulatedFormField };
    const currentStep = this.state.step;

    Object.keys(fieldData).forEach((key) => {
      let checkForValidation = true;
      if (
        (fieldData[key].displayName == editListingValue.sellersig2 ||
          fieldData[key].displayName == editListingValue.date2) &&
        this.state.showSingCount === Enums.NUMERIC_VALUE.ONE
      ) {
        checkForValidation = false;
      }
      if (
        (fieldData[key].displayName == editListingValue.sellersig3 ||
          fieldData[key].displayName == editListingValue.date3) &&
        (this.state.showSingCount === 1 || this.state.showSingCount === Enums.NUMERIC_VALUE.TWO)
      ) {
        checkForValidation = false;
      }
      if (this.state.targetValues.length > 0 && this.state.targetValues.includes(fieldData[key].displayName)) {
        checkForValidation = false;
      }
      if (
        checkForValidation &&
        fieldData[key].isRequired &&
        !fieldData[key].value &&
        currentStep === fieldData[key].step
      ) {
        if (!this.state.filterSellerSigId.hasOwnProperty(key) && !this.state.filterEmail.hasOwnProperty(key)) {
          formIsValid = false;
          errors[key] = '*';
          this.setState({ refErrorname: fieldData[key].displayName });
        }
      }
    });
    this.setState({ errors });

    console.log(errors);

    return formIsValid;
  }

  checkAllForms = () => {
    let abort = false;
    const errors = {};
    for (let a = 0; a < this.listingFormArrList.length && !abort; a++) {
      const errFormNumber = a + enums.NUMERIC_VALUE.ONE;
      for (let b = 0; b < this.listingFormArrList[a].groups.length && !abort; b++) {
        for (let c = 0; c < this.listingFormArrList[a].groups[b].sections.length && !abort; c++) {
          for (let d = 0; d < this.listingFormArrList[a].groups[b].sections[c].fields.length && !abort; d++) {
            let fieldValue = this.listingFormArrList[a].groups[b].sections[c].fields[d];
            const checkForvalue = fieldValue.value ? fieldValue.value : fieldValue.prefillText || '';

            const fieldId = fieldValue.formFieldId ? fieldValue.formFieldId : '';
            const displayName = fieldValue.formFieldId ? fieldValue.displayName : '';
            const isFound = this.handleCheck(fieldId, displayName);
            if (isFound) {
              if (
                fieldValue.required &&
                checkIsEmpty(checkForvalue) &&
                !this.state.filterSellerSigId.hasOwnProperty(fieldId) &&
                !this.state.filterEmail.hasOwnProperty(fieldId)
              ) {
                abort = true;
                errors[fieldId] = '*';
                this.setState({ errors });
                this.setState({ checkForLastForm: this.isLastFormId });

                this.setState({ step: errFormNumber + enums.NUMERIC_VALUE.TWO }, () => {
                  this.showErrorAlertModal();
                });
              }
            }
          }
        }
      }
    }
    return abort;
  };

  handleCheck(val, displayName) {
    const fieldData = { ...this.state.manipulatedFormField };
    if (fieldData.hasOwnProperty(val)) {
      let retuenVal = true;

      if (
        (fieldData[val].displayName == editListingValue.sellersig2 ||
          fieldData[val].displayName == editListingValue.date2) &&
        this.state.showSingCount === Enums.NUMERIC_VALUE.ONE
      ) {
        retuenVal = false;
      }
      if (
        (fieldData[val].displayName == editListingValue.sellersig3 ||
          fieldData[val].displayName == editListingValue.date3) &&
        (this.state.showSingCount === Enums.NUMERIC_VALUE.ONE || this.state.showSingCount === Enums.NUMERIC_VALUE.TWO)
      ) {
        retuenVal = false;
      }
      if (this.state.targetValues.length > 0 && this.state.targetValues.includes(displayName)) {
        retuenVal = false;
      }

      return retuenVal;
    } else {
      return false;
    }
  }

  handleContinue = (e, index) => {
    let isError = false;
    if (this.state.step < enums.NUMERIC_VALUE.TWO) {
      const isPropertyTypeLand = Number(this.state.fields?.propertyType?.value) === 11;
      const fields = { ...this.state.fields };

      if (isPropertyTypeLand) {
        delete fields.bathrooms;
        delete fields.bedrooms;
        delete fields.squareFeet;
      }

      Object.keys(fields).forEach((keyName) => {
        if (fields[keyName].value === undefined) {
          fields[keyName].value = '';
        }
        const isErrorExist = this.validateField(keyName, fields[keyName].value);
        if (isErrorExist) {
          isError = true;
        }
      });
      if (!isError) {
        const basicFormObj = {
          listingId: this.props.listing.listingId,
          propertyType: Number(fields.propertyType.value),
          listPrice: Number(fields.listPrice.value),
          bedrooms: Number(`${this.state.fields?.bedrooms?.value ?? 0}`.trim()),
          bathrooms: Number(this.state.fields.bathrooms.value || 0),
          squareFeet: Number(`${this.state.fields?.squareFeet?.value ?? ''}`.trim()),
          lotAcreage: Number(`${fields?.lotAcreage?.value ?? ''}`.trim()),
          description: fields.description.value,
        };
        this.props.saveBasicField(basicFormObj, ({ err }) => {
          if (!err) {
            this.setState({ changeBasicFormField: true });

            this.setState({ preStep: this.state.step });
            this.setState({
              step: index ? index : this.state.step + enums.NUMERIC_VALUE.ONE,
            });
          }
        });
      }
    } else if (this.state.step > enums.NUMERIC_VALUE.TWO) {
      const fieldData = { ...this.state.manipulatedFormField };
      if (this.handleValidation()) {
        if (!this.isLastFormId) {
          this.savePrefilledvalue(this.isLastFormId);
        }
        this.saveLinkedData();

        this.isFirstFormValid = true;
        this.stepCount = this.stepCount === enums.NUMERIC_VALUE.ZERO ? enums.NUMERIC_VALUE.ZERO : this.stepCount;
        smoothScrollTop();
        if (this.isLastFormId && !this.checkAllForms()) {
          this.savePrefilledvalue(this.isLastFormId);
        } else if (!this.isLastFormId) {
          this.setState({ preStep: this.state.step });
          this.setState({ step: this.state.step + enums.NUMERIC_VALUE.ONE });
          this.setState({ changeBasicFormField: true });
        }
      } else {
        this.showErrorAlertModal();
      }
    }
    e.preventDefault();
  };

  saveLinkedData() {
    let linkedObj = dataService.getLocal('isLinkedArray');
    let preFilledArr = [];
    let formIndex = this.state.step - enums.NUMERIC_VALUE.THREE;
    let stepFormArray = this.listingFormArrList[formIndex];

    if (stepFormArray && linkedObj) {
      let listingFormField = stepFormArray?.groups || [];
      for (let h = 0; h < listingFormField.length; h++) {
        let listingSection = listingFormField?.[h].sections;
        for (let i = 0; i < listingSection.length; i++) {
          let sectionArr = listingSection?.[i].fields;
          for (let j = 0; j < sectionArr.length; j++) {
            let displayName = sectionArr[j].displayName;
            for (let k = 0; k < linkedObj.length; k++) {
              if (linkedObj[k].hasOwnProperty(displayName)) {
                preFilledArr.push({
                  formFieldId: sectionArr[j].formFieldId,
                  value: linkedObj[k][displayName],
                });
              }
            }
          }
        }
      }
    }

    if (preFilledArr.length > 0) {
      let arrIndex = this.state.step - enums.NUMERIC_VALUE.THREE;
      let PreFillArr = this.listingFormArrList[arrIndex];
      let userFormId = PreFillArr?.userFormId;
      const prefillReqObj = {
        userFormId: userFormId,
        previewFields: preFilledArr,
      };
      this.props.savePreviewFormField(prefillReqObj, () => {});
    }
  }

  savePrefilledvalue = (isLastForm) => {
    const arrIndex = this.state.step - enums.NUMERIC_VALUE.THREE;
    const PreFillArr = this.listingFormArrList[arrIndex];
    let preFilledArr = [];
    if (PreFillArr) {
      let listingFormField = PreFillArr?.groups || [];

      for (let h = 0; h < listingFormField.length; h++) {
        let listingSection = listingFormField?.[h].sections;

        for (let i = 0; i < listingSection.length; i++) {
          let sectionArr = listingSection?.[i].fields;
          for (let j = 0; j < sectionArr.length; j++) {
            if (this.state.PreFillFormFilled.hasOwnProperty(sectionArr[j].formFieldId)) {
              if (sectionArr[j].readOnly && !sectionArr[j].isLinked) {
                sectionArr[j].value = sectionArr[j].prefillText;
              }
              preFilledArr.push({
                formFieldId: sectionArr[j].formFieldId,
                value: sectionArr[j].value ? `${sectionArr[j].value}` : '',
              });
            }
          }
        }
      }
    }

    if (preFilledArr.length > 0) {
      const prefillReqObj = {
        userFormId: PreFillArr.userFormId,
        previewFields: preFilledArr,
      };
      this.props.savePreviewFormField(prefillReqObj, () => {
        if (isLastForm) {
          this.submitMLSForm();
        }
      });
    } else {
      if (isLastForm) {
        this.submitMLSForm();
      }
    }
  };

  handleBack = () => {
    smoothScrollTop();
    if (this.state.step > 0) {
      this.setState({ preStep: this.state.step });
      if(this.state.step === enums.NUMERIC_VALUE.TWO_POINT_FIVE) {
        this.setState({ step: enums.NUMERIC_VALUE.TWO });
      } else if (this.state.step === enums.NUMERIC_VALUE.THREE) {
        this.setState({ step: enums.NUMERIC_VALUE.TWO_POINT_FIVE });
      } else {
        this.setState({ step: this.state.step - enums.NUMERIC_VALUE.ONE });
      }
    }
    this.setState({
      manipulatedFormField: {},
      errors: {},
    });
  };

  handleRedirectDasboard = () => {
    history.push(`${Routes.DASHBOARD}`);
  };

  handleNewPhotoAdded = (photo, maxPriority) => {
    this.props.uploadListingPhoto(this.props.listing.listingId, maxPriority, photo);
  };

  handleOrderChanged = (oldIndex, newIndex) => {
    this.props.reorderPhotos(this.props.listing.listingId, {
      oldIndex,
      newIndex,
    });
  };

  replacer = (orig, target, prifix) => {
    var x = 0;
    var re = new RegExp(target, 'g');
    var ret = orig.replace(re, function (match) {
      return "<span class='signaclass' id='" + prifix + +(++x) + "'>" + match + '</span>';
    });
    return ret;
  };

  processForm = (content, fields, processNewlines, groupType, formPriority, isFormSubmitted, userFormID, formId) => {
    if (groupType.formGroupType === enums.NUMERIC_VALUE.THREE) {
      return this.getImageFieldControl(content, fields, isFormSubmitted, userFormID, formId, formPriority);
    }
    const re = new RegExp(/Owner's Signature:{FormField:(\d+)}/, 'g');
    const re1 = new RegExp(/Owner Signature:{FormField:(\d+)}/, 'g');
    let x = 1;
    let inStyles;
    const nff = this.state.showSingCount;
    content = content.replace(re, function (match) {
      if (x > nff) {
        inStyles = 'display:none';
      }
      return "<span style='" + inStyles + "' class='signaclass' id='sign_" + ++x + "'>" + match + '</span>';
    });

    content = content.replace(re1, function (match) {
      if (x > nff) {
        inStyles = 'display:none';
      }
      return "<span style='" + inStyles + "' class='signaclass' id='sign_" + ++x + "'>" + match + '</span>';
    });

    const datere = new RegExp(/Date:{FormField:(\d+)}/, 'g');
    let y = 1;
    let inStyle;
    content = content.replace(datere, function (match) {
      if (y > nff && y < Enums.NUMERIC_VALUE.FOUR) {
        inStyle = 'display:none';
      } else {
        inStyle = '';
      }
      return "<span style='" + inStyle + "' class='signaclass' id='date_" + ++y + "'>" + match + '</span>';
    });

    for (let j = enums.NUMERIC_VALUE.THREE; j > this.state.showSingCount; j--) {
      const regexp = new RegExp(
        ['emailaddr' + j, 'seller' + j + 'email']
          .map(function (r) {
            return (r + '').replace(/\//g, '');
          })
          .join('|'),
        'g'
      );

      const Sellernameregexp = new RegExp('sellername' + j, 'i');
      const Initialregexp = new RegExp('initial' + j, 'i');

      let Displayindex = fields.findIndex((x) => regexp.test(x.displayName));
      let initalIndex = fields.findIndex((x) => Initialregexp.test(x.displayName));
      let sellerNameindex = fields.findIndex((x) => Sellernameregexp.test(x.displayName));
      if (Displayindex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterEmail[fields[Displayindex].formFieldId] = true;
        fields = fields.filter((x) => !regexp.test(x.displayName));
      }
      if (initalIndex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterEmail[fields[initalIndex].formFieldId] = true;
        let replaceDataInitial = '{FormField:' + [fields[initalIndex].formFieldId] + '}';
        content = content.replace(replaceDataInitial, '');
      }
      if (sellerNameindex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterEmail[fields[sellerNameindex].formFieldId] = true;
        let replaceDataSeller = '{FormField:' + [fields[sellerNameindex].formFieldId] + '}';
        content = content.replace(replaceDataSeller, '');
      }
    }

    content = content.replace(/{FormField:(\d+)}/g, '<field>$1</field>');

    content = content.replace(/\[row.*?\]/g, '<div class="row-modify">');
    content = content.replace(/\[\/row]/g, '</div>');
    content = content.replace(/\[column.*?\]/g, '<div class="medium-4">');
    content = content.replace(/\[\/column]/g, '</div>');
    content = content.replace(/\[page-break]/g, '<br />');
    content = content.replace(/\[required]/g, '');

    if (processNewlines) content = content.replace(/\r\n/g, '<br />');
    return ReactHtmlParser(content, {
      transform: (node, index) =>
        this.transform(node, index, fields, formPriority, isFormSubmitted, userFormID, formId),
    });
  };

  transform = (node, index, fields, formPriority, isFormSubmitted, userFormID, formId) => {
    if (node.type === 'tag' && node.name === 'field') {
      const field = fields.filter((f) => f.formFieldId === parseInt(node.children[0].data))[0];
      if (field && !this.state.manipulatedFormField[field.formFieldId]) {
        let valueForSign = field.value ? field.value : field.prefillText || '';
        if (
          valueForSign &&
          typeof valueForSign === editListingValue.string &&
          valueForSign.toLowerCase() === editListingValue.oneOwner
        ) {
          this.setState({ showSingCount: Enums.NUMERIC_VALUE.ONE });
        }
        if (
          valueForSign &&
          typeof valueForSign === editListingValue.string &&
          valueForSign.toLowerCase() === editListingValue.twoOwner
        ) {
          this.setState({ showSingCount: Enums.NUMERIC_VALUE.TWO });
        }
        if (
          valueForSign &&
          typeof valueForSign === editListingValue.string &&
          valueForSign.toLowerCase() === editListingValue.threeOwner
        ) {
          this.setState({ showSingCount: Enums.NUMERIC_VALUE.THREE });
        }

        this.state.manipulatedFormField[field.formFieldId] = {
          displayName: field.displayName,
          isRequired: field.required,
          value: field.value ? field.value : field.prefillText || '',
          step: this.state.step ? this.state.step : Enums.NUMERIC_VALUE.THREE,
          formPriority: formPriority,
          isLinked: field.isLinked,
          formPrefillTypeId: field.formPrefillTypeId,
        };
      }
      if (field) {
        return this.getFieldControl(field, index, isFormSubmitted, node, userFormID, formId, formPriority);
      }
    }
  };

  getImageFieldControl = (node, fields, isFormSubmitted, userFormID, formId, formPriority) => {
    if (fields) {
      fields.sort(function (a, b) {
        return parseInt(b.inputDataType) - parseInt(a.inputDataType);
      });
    }

    let matches = fields
      .filter((a) => a.value !== null && a.value && typeof a.value === editListingValue.string)
      .filter(
        (a) =>
          a.value.toLowerCase().includes(editListingValue.twoOwner) ||
          a.value.toLowerCase().includes(editListingValue.oneOwner) ||
          a.value.toLowerCase().includes(editListingValue.threeOwner)
      );

    if (matches.length > enums.NUMERIC_VALUE.ZERO) {
      overlaySignFlag = enums.NUMERIC_VALUE.ONE;
      let ownerVal = matches[0]['value'].toLowerCase();
      if (ownerVal == editListingValue.oneOwner) {
        showOwner = Enums.NUMERIC_VALUE.ONE;
      }
      if (ownerVal == editListingValue.twoOwner) {
        showOwner = Enums.NUMERIC_VALUE.TWO;
      }
      if (ownerVal == editListingValue.threeOwner) {
        showOwner = Enums.NUMERIC_VALUE.THREE;
      }
    } else {
      if (overlaySignFlag == enums.NUMERIC_VALUE.ZERO) {
        showOwner = this.state.showSingCount;
      }
    }
    for (let j = enums.NUMERIC_VALUE.THREE; j > showOwner; j--) {
      const regexp = new RegExp('sellersig' + j, 'i');
      const regexpDate = new RegExp('date' + j, 'i');
      const regexpSellername = new RegExp('sellername' + j, 'i');
      const regexpInitial = new RegExp(
        ['initial' + j, 'initials' + j]
          .map(function (r) {
            return (r + '').replace(/\//g, '');
          })
          .join('|'),
        'g'
      );

      const regexpEmail = new RegExp(
        ['emailaddr' + j, 'seller' + j + 'email']
          .map(function (r) {
            return (r + '').replace(/\//g, '');
          })
          .join('|'),
        'g'
      );
      let Displayindex = fields.findIndex((x) => regexp.test(x.displayName));
      let Dareindex = fields.findIndex((x) => regexpDate.test(x.displayName));
      let Emailindex = fields.findIndex((x) => regexpEmail.test(x.displayName));
      let Sellernameindex = fields.findIndex((x) => regexpSellername.test(x.displayName));
      let Initialindex = fields.findIndex((x) => regexpInitial.test(x.displayName));
      if (Displayindex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterSellerSigId[fields[Displayindex].formFieldId] = true;
      }
      if (Dareindex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterSellerSigId[fields[Dareindex].formFieldId] = true;
      }
      if (Emailindex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterSellerSigId[fields[Emailindex].formFieldId] = true;
      }
      if (Sellernameindex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterSellerSigId[fields[Sellernameindex].formFieldId] = true;
      }
      if (Initialindex > enums.NUMERIC_VALUE.ZERO) {
        this.state.filterSellerSigId[fields[Initialindex].formFieldId] = true;
      }
      fields = fields.filter((x) => !regexp.test(x.displayName));
      fields = fields.filter((x) => !regexpDate.test(x.displayName));
      fields = fields.filter((x) => !regexpEmail.test(x.displayName));
      fields = fields.filter((x) => !regexpSellername.test(x.displayName));
      fields = fields.filter((x) => !regexpInitial.test(x.displayName));
    }
    return (
      <div className="image-with-form">
        <img src={node} />

        {fields.map((field, index) => {
          if (!this.state.manipulatedFormField[field.formFieldId]) {
            this.state.manipulatedFormField[field.formFieldId] = {
              displayName: field.displayName,
              isRequired: field.required,
              value: field?.value ? field.value : field.prefillText || '',
              step: this.state.step ? this.state.step : Enums.NUMERIC_VALUE.THREE,
              isLinked: field.isLinked,
              formPrefillTypeId: field.formPrefillTypeId,
            };
          }

          if (this.state.linkedChangeValue.hasOwnProperty(field.formFieldId)) {
            field.prefillText = '';
          } else if (!field.value && field.prefillText) {
            //field.value=field.prefillText;
          }

          if (field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN && field.targetFields) {
            let obj = [];
            let optionValue = field.targetFields.replace(/\u21b5|\r\n|\n/g, '@#$').split('@#$');

            Object.keys(optionValue).forEach((key) => {
              obj[key] = { isCheckd: field.value, displayName: optionValue[key] };
            });
            this.state.targetValues[field.formFieldId] = obj;
          }

          let targetClass = '';

          if (Object.keys(this.state.targetValues).length > 0) {
            Object.keys(this.state.targetValues).forEach((Statekey) => {
              let singleArr = this.state.targetValues[Statekey];

              Object.keys(singleArr).forEach((key) => {
                if (
                  singleArr[key]['displayName'] === field.displayName &&
                  (singleArr[key]['isCheckd'] == 'true' || singleArr[key]['isCheckd'] === true)
                ) {
                  field.readOnly = false;
                }
                if (
                  singleArr[key]['displayName'] === field.displayName &&
                  (singleArr[key]['isCheckd'] == 'false' || singleArr[key]['isCheckd'] === false)
                ) {
                  targetClass = disabled_input;
                  field.value = '';
                  field.readOnly = true;
                }
              });
            });
          }
          if (
            field.displayName === editListingValue.brokerupfrontfee ||
            field.displayName === editListingValue.broker_upfront_fee
          ) {
            field.value = field.value ? field.value : field.prefillText;
            if (!field.value.includes('$')) {
              field.value = '$' + field.value;
            }
          }

          if (field.displayName === 'date1' || field.displayName === 'date2' || field.displayName === 'date3') {
            field.readOnly = true;
            field.value = field.value || moment().format('MM/DD/YYYY');
            field.prefillText = field.value;
          }

          if (field.readOnly) {
            return (
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                    width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  readOnly
                  value={field.value || ''}
                  className={disabled_input}
                />
              </div>
            );
          }

          if (field.options && field.inputDataType === Enums.NUMERIC_VALUE.THIRTEEN) {
            if (field.value === null) {
              field.value = '';
            }
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                  }}
                >
                  <select
                    style={{
                      height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                      width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                      padding: 0,
                    }}
                    key={`${field.formFieldId}_${index}`}
                    name={field.formFieldId}
                    ref={field.formFieldId}
                    value={field.value}
                    onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId)}
                    onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
                    onFocus={this.handleFormFieldFocus}
                    disabled={isFormSubmitted}
                    className={
                      this.state.errors[field.formFieldId]
                        ? inputErrorClassOption
                        : isFormSubmitted
                        ? disabled_input_overlay
                        : targetClass
                    }
                  >
                    <option key={Math.random()} value="">
                      {' '}
                      Select Value{' '}
                    </option>
                    {field.options
                      .replace(/\u21b5|\r\n|\n/g, '@#$')
                      .split('@#$')
                      .map((o) => (
                        <option key={`${field.formFieldId}_${index}_${o}`} value={o}>
                          {o}
                        </option>
                      ))}
                  </select>
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }

          if (!field.readOnly && field.inputDataType === Enums.NUMERIC_VALUE.ONE) {
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                  }}
                >
                  <input
                    style={{
                      height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                      width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                    }}
                    key={`${field.formFieldId}_${index}`}
                    type="text"
                    name={field.formFieldId}
                    value={field.value || ''}
                    onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId)}
                    onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
                    onFocus={this.handleFormFieldFocus}
                    required={field.required && !field.value}
                    ref={field.formFieldId}
                    className={
                      this.state.errors[field.formFieldId]
                        ? inputErrorClass
                        : isFormSubmitted
                        ? disabled_input_overlay
                        : targetClass
                    }
                  />
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }

          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.THREE || field.inputDataType === Enums.NUMERIC_VALUE.FOUR)
          ) {
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                  }}
                >
                  <input
                    style={{
                      height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                      width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                    }}
                    key={`${field.formFieldId}_${index}`}
                    type="text"
                    name={field.formFieldId}
                    value={field.value || ''}
                    onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId, field.inputDataType)}
                    onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
                    onFocus={this.handleFormFieldFocus}
                    required={field.required && !field.value}
                    ref={field.formFieldId}
                    className={
                      this.state.errors[field.formFieldId]
                        ? inputErrorClass
                        : isFormSubmitted
                        ? disabled_input_overlay
                        : targetClass
                    }
                  />
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }

          if (!field.readOnly && field.inputDataType === Enums.NUMERIC_VALUE.SEVEN) {
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                  }}
                >
                  <CurrencyInput
                    style={{
                      height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                      width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                    }}
                    key={`${field.formFieldId}_${index}`}
                    decimalsLimit={2}
                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                    defaultValue={field?.value ? field.value : field.prefillText || ''}
                    name={field.formFieldId}
                    onValueChange={(e) =>
                      this.handleFormFieldChangedCurrency(e, field.formFieldId, formId, field.inputDataType)
                    }
                    onBlur={(e) => this.handleFormFieldBlur(e, userFormID, field.inputDataType)}
                    onFocus={this.handleFormFieldFocus}
                    required={field.required && !field.value}
                    ref={field.formFieldId}
                    className={
                      this.state.errors[field.formFieldId]
                        ? inputErrorClass
                        : isFormSubmitted
                        ? disabled_input_overlay
                        : targetClass
                    }
                  />
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }

          if (!field.readOnly && field.inputDataType === Enums.NUMERIC_VALUE.TWO) {
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                  }}
                >
                  <textarea
                    style={{
                      height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                      width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                    }}
                    key={`${field.formFieldId}_${index}`}
                    type="text"
                    name={field.formFieldId}
                    defaultValue={field.value ? field.value : field.prefillText || ''}
                    onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId)}
                    onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
                    onFocus={this.handleFormFieldFocus}
                    ref={field.formFieldId}
                    className={
                      this.state.errors[field.formFieldId]
                        ? inputErrorClass + ' mx-100'
                        : isFormSubmitted
                        ? 'disabled-input-overlay mx-100'
                        : targetClass + ' mx-100'
                    }
                  />
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.FIVE) {
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                    zIndex: 1,
                    height: field.overlayHeight + 'px',
                    width: field.overlayWidth + 'px',
                  }}
                >
                  <input
                    style={{
                      height: field.overlayHeight + 'px',
                      width: field.overlayWidth + 'px',
                    }}
                    key={`${field.formFieldId}_${index}`}
                    type="checkbox"
                    name={field.formFieldId}
                    defaultChecked={field?.value ? JSON.parse(field.value) : false}
                    onChange={(e) => this.handleFormCheckboxField(e, field.displayName, field.formFieldId)}
                    onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
                    onFocus={this.handleFormFieldFocus}
                    disabled={isFormSubmitted}
                    ref={field.formFieldId}
                    className={this.state.errors[field.formFieldId] ? inputErrorClassChecbox : targetClass}
                  />
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN) {
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                    zIndex: 1,
                  }}
                >
                  <input
                    style={{
                      height: field.overlayHeight + 'px',
                      width: field.overlayWidth + 'px',
                    }}
                    key={`${field.formFieldId}_${index}`}
                    type="checkbox"
                    name={field.formFieldId}
                    defaultChecked={field?.value ? JSON.parse(field.value) : false}
                    onChange={(e) => {
                      this.handleFormTargetField(e, field.targetFields);
                      this.handleFormCheckboxField(e, field.displayName, field.formFieldId);
                    }}
                    onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
                    onFocus={this.handleFormFieldFocus}
                    disabled={isFormSubmitted}
                    ref={field.formFieldId}
                    className={this.state.errors[field.formFieldId] ? inputErrorClassChecbox : ''}
                  />
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }

          if (field.inputDataType === Enums.NUMERIC_VALUE.EIGHT) {
            return (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                    zIndex: 1,
                  }}
                >
                  <FormDatePicker>
                    {field.value ? (
                      <DatePicker
                        size="small"
                        style={{
                          height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                          width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                        }}
                        defaultValue={moment(field.value)}
                        onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, true)}
                        ref={field.formFieldId}
                        inputReadOnly
                        format="YYYY-MM-DD"
                        disabledDate={disabledDate}
                        key={`${field.formFieldId}_${index}`}
                        name={field.formFieldId}
                        allowClear={false}
                        className={
                          this.state.errors[field.displayName] ? inputErrorClass : isFormSubmitted ? disabled_input : ''
                        }
                      />
                    ) : (
                      <DatePicker
                        size="small"
                        style={{
                          height: field.overlayHeight ? field.overlayHeight : 20 + 'px',
                          width: field.overlayWidth ? field.overlayWidth : 100 + 'px',
                        }}
                        onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, true)}
                        ref={field.formFieldId}
                        inputReadOnly
                        format="YYYY-MM-DD"
                        disabledDate={disabledDate}
                        key={`${field.formFieldId}_${index}`}
                        name={field.formFieldId}
                        allowClear={false}
                        className={
                          this.state.errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''
                        }
                      />
                    )}
                    <span className="text-error">{this.state.errors[field.displayName]}</span>
                  </FormDatePicker>
                </div>
                {field.required && (
                  <label
                    style={{
                      right: -192 + 'px',
                      top: field.overlayStartY + 'px',
                    }}
                    className="additional-label"
                  >
                    Required
                  </label>
                )}
              </>
            );
          }
        })}
      </div>
    );
  };

  getFieldControl = (field, index, isFormSubmitted, node, userFormID, formId, formPriority) => {
    let fieldWidth;
    if (field.value?.length >= enums.NUMERIC_VALUE.THIRTY) {
      fieldWidth = enums.NUMERIC_VALUE.TWOHUNDREDTWENTY + enums.NUMERIC_VALUE.TWO * field.value?.length;
    }
    if (field.displayName === editListingValue.sellername) {
      fieldWidth = enums.NUMERIC_VALUE.TWOHUNDREDSEVENTEEN;
    }
    if (
      (field.displayName === editListingValue.brokerupfrontfee ||
        field.displayName === editListingValue.broker_upfront_fee) &&
      field.value.indexOf('$') === -1
    ) {
      field.value = '$' + field.value;
    }

    if (field.displayName === 'date1' || field.displayName === 'date2' || field.displayName === 'date3') {
      field.readOnly = true;
      field.value = field.value || moment().format('MM/DD/YYYY');
      field.prefillText = field.value;
    }

    if (field.readOnly) {
      return (
        <>
          <input
            type="text"
            key={`${field.formFieldId}_${index}`}
            value={field.value || ''}
            className={disabled_input}
          />
          <label className="for-readonly">Read Only</label>
        </>
      );
    }
    if (field.options) {
      let labelLength = '';
      if (
        field.inputDataType === Enums.NUMERIC_VALUE.FIVE &&
        node?.parent?.name === editListingValue.div &&
        node?.parent?.parent !== null
      ) {
        labelLength = this.getCheckboxLabelLength(node);
      }
      if (!field.value || field.value === null) {
        field.value = '';
      }
      return (
        <>
          {field.inputDataType === Enums.NUMERIC_VALUE.FIVE ? (
            <>
              {field.required && (
                <label
                  style={
                    labelLength
                      ? { top: -25 + 'px', left: 2 * labelLength + 70 + 'px', marginLeft: '12px', marginBottom: '0px' }
                      : {}
                  }
                >
                  Required
                </label>
              )}

              {field.optionTypeId === Enums.NUMERIC_VALUE.ONE ? (
                <>
                  {field.options.split('\n').map((o) => (
                    <div className="checkbox-default">
                      <input
                        key={`${field.formFieldId}_${index}_${o}`}
                        type="checkbox"
                        value={o}
                        name={field.formFieldId}
                        checked={this.isMSultiChecked(o, field.value)}
                        onChange={(e) => this.handleFormSingleCheckboxField(e, field.formFieldId)}
                        ref={field.formFieldId}
                        disabled={isFormSubmitted}
                        className={
                          this.state.errors[field.formFieldId]
                            ? inputErrorClassChecbox
                            : isFormSubmitted
                            ? disabled_input
                            : ''
                        }
                      />
                      <span>{o}</span>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {field.options.split('\n').map((o) => (
                    <div className="checkbox-default">
                      <input
                        key={`${field.formFieldId}_${index}_${o}`}
                        type="checkbox"
                        value={o}
                        name={field.formFieldId}
                        defaultChecked={this.isMultiChecked(o, field.value)}
                        onChange={(e) => this.handleFormMultileCheckboxField(e, field.formFieldId)}
                        ref={field.formFieldId}
                        disabled={isFormSubmitted}
                        className={
                          this.state.errors[field.formFieldId]
                            ? inputErrorClassChecbox
                            : isFormSubmitted
                            ? disabled_input
                            : ''
                        }
                      />
                      <span>{o}</span>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <select
                key={`${field.formFieldId}_${index}`}
                name={field.formFieldId}
                onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, false)}
                value={field.value}
                ref={field.formFieldId}
                disabled={isFormSubmitted}
                className={
                  this.state.errors[field.formFieldId] ? inputErrorClassOption : isFormSubmitted ? disabled_input : ''
                }
              >
                {field.required && <label>Required</label>}
                <option key={Math.random()} value="">
                  {' '}
                  Select Value{' '}
                </option>
                {field.options.split('\n').map((o) => (
                  <option key={`${field.formFieldId}_${index}_${o}`} value={o}>
                    {o}
                  </option>
                ))}
              </select>
              {field.required && <label>Required</label>}
            </>
          )}
        </>
      );
    }

    switch (field.inputDataType) {
      case enums.InputTypes.TEXT_SHORT:
        return (
          <>
            <input
              style={fieldWidth ? { width: fieldWidth + 'px' } : {}}
              key={`${field.formFieldId}_${index}`}
              type="text"
              name={field.formFieldId}
              value={field.value || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId)}
              onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
              onFocus={this.handleFormFieldFocus}
              required={field.required && !field.value}
              ref={field.formFieldId}
              className={this.state.errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </>
        );
      case enums.InputTypes.TEXT_LONG:
        return (
          <div style={{ minWidth: '650px', display: 'flex', alignItems: 'flex-end' }}>
            <textarea
              key={`${field.formFieldId}_${index}`}
              type="text"
              name={field.formFieldId}
              defaultValue={field.value ? field.value : field.prefillText || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId)}
              onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
              onFocus={this.handleFormFieldFocus}
              ref={field.formFieldId}
              className={this.state.errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </div>
        );
      case enums.InputTypes.WHOLE_NUMBER:
        return (
          <>
            <input
              key={`${field.formFieldId}_${index}`}
              type="number"
              name={field.formFieldId}
              defaultValue={field?.value ? field.value : field.prefillText || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId)}
              onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
              onFocus={this.handleFormFieldFocus}
              ref={field.formFieldId}
              className={this.state.errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </>
        );
      case enums.InputTypes.DECIMAL:
        return (
          <>
            <input
              key={`${field.formFieldId}_${index}`}
              type="number"
              name={field.formFieldId}
              defaultValue={field?.value ? field.value : field.prefillText || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, formId)}
              onBlur={(e) => this.handleFormFieldBlur(e, userFormID)}
              onFocus={this.handleFormFieldFocus}
              ref={field.formFieldId}
              className={this.state.errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </>
        );
      case enums.InputTypes.CURRENCY:
        return (
          <>
            <CurrencyInput
              style={fieldWidth ? { width: fieldWidth + 'px' } : {}}
              key={`${field.formFieldId}_${index}`}
              decimalsLimit={2}
              intlConfig={{ locale: 'en-US', currency: 'USD' }}
              defaultValue={field?.value ? field.value : field.prefillText || ''}
              name={field.formFieldId}
              onValueChange={(e) =>
                this.handleFormFieldChangedCurrency(e, field.formFieldId, formId, field.inputDataType)
              }
              onBlur={(e) => this.handleFormFieldBlur(e, userFormID, field.inputDataType)}
              onFocus={this.handleFormFieldFocus}
              required={field.required && !field.value}
              ref={field.formFieldId}
              className={this.state.errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </>
        );
      case enums.InputTypes.BOOLEAN:
        return (
          <>
            {field.required && <label>Required</label>}
            <input
              key={`${field.formFieldId}_${index}`}
              type="checkbox"
              name={field.formFieldId}
              defaultChecked={field?.value ? JSON.parse(field.value) : false}
              onChange={(e) => this.handleFormCheckboxField(e, field.displayName, field.formFieldId)}
              ref={field.formFieldId}
              disabled={isFormSubmitted}
              className={
                this.state.errors[field.formFieldId] ? inputErrorClassChecbox : isFormSubmitted ? disabled_input : ''
              }
            />
          </>
        );

      case enums.InputTypes.DATE_TIME:
        return (
          <>
            <FormDatePicker>
              {field.value ? (
                <DatePicker
                  size="large"
                  defaultValue={moment(field.value)}
                  onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, true)}
                  ref={field.formFieldId}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    this.state.errors[field.displayName] ? inputErrorClass : isFormSubmitted ? disabled_input : ''
                  }
                />
              ) : (
                <DatePicker
                  size="large"
                  onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, true)}
                  ref={field.formFieldId}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    this.state.errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''
                  }
                />
              )}
              {field.required && <label>Required</label>}
              <span className="text-error">{this.state.errors[field.displayName]}</span>
            </FormDatePicker>
          </>
        );
      default:
        return '';
    }
  };

  DownloadZip = async () => {
    const { listing } = this.props;
    let listingId = listing?.listingId;
    let reqObj = { listingId: listingId };
    this.props.DownloadZipByAPI(reqObj);
  };

  handleFormFieldChangedCurrency = (e, field, formId, inputDataType) => {
    const re = /^[0-9\b]+$/;
    let isCheck = true;

    if (isCheck) {
      const { manipulatedFormField } = this.state;
      const errors = { ...this.state.errors };
      manipulatedFormField[field].value = e;

      if (manipulatedFormField[field].isLinked) {
        Object.keys(manipulatedFormField).forEach((key) => {
          if (manipulatedFormField[field].displayName === manipulatedFormField[key].displayName) {
            manipulatedFormField[key].value = e;
            this.state.linkedChangeValue[key] = e;
          }
        });

        let linkedArr = dataService.getLocal('isLinkedArray');

        if (Object.keys(this.state.notInLinkedChangeValue).length > 0) {
          let notLinkedarray = { ...this.state.notInLinkedChangeValue };
          let linkedname = manipulatedFormField[field].displayName;
          for (const [key, value] of Object.entries(this.state.notInLinkedChangeValue)) {
            if (value === linkedname) {
              delete notLinkedarray[key];
              this.setState({ notInLinkedChangeValue: notLinkedarray });
            }
          }
        }

        if (linkedArr) {
          let linkedname = manipulatedFormField[field].displayName;
          let formPrefillTypeId = manipulatedFormField[field].formPrefillTypeId;

          let LinkedObj = { [linkedname]: e, formPrefillTypeId: formPrefillTypeId };
          let index = linkedArr.findIndex((it) => Object.keys(it)[0] === linkedname);
          if (index === -1) {
            linkedArr.push(LinkedObj);
          } else {
            linkedArr[index] = LinkedObj;
          }
          dataService.setLocal('isLinkedArray', linkedArr);
        } else {
          let linkedArr = [];
          let linkedname = manipulatedFormField[field].displayName;
          let formPrefillTypeId = manipulatedFormField[field].formPrefillTypeId;
          let LinkedObj = { [linkedname]: e, formPrefillTypeId: formPrefillTypeId };
          linkedArr.push(LinkedObj);
          dataService.setLocal('isLinkedArray', linkedArr);
        }
      } else {
        let linkedArr = dataService.getLocal('isLinkedArray');
        let linkedname = manipulatedFormField[field].displayName;
        if (linkedArr) {
          let index = linkedArr.findIndex((it) => Object.keys(it)[0] === linkedname);
          if (index >= 0) {
            this.state.notInLinkedChangeValue[field] = linkedname;
          }
        }
      }
      errors[field] = '';
      if (!formId) {
        formId = this.listingForm.formId;
      }
      this.setState({ manipulatedFormField, errors });

      this.props.handleFieldChange({
        listingId: this.props.listing.listingId,
        formId: formId,
        formFieldId: field,
        value: e,
      });
    }
  };

  handleFormFieldChanged = (e, field, formId, inputDataType) => {
    const re = /^[0-9\b]+$/;
    let isCheck = false;
    if (inputDataType === 3 && (e.target.value === '' || re.test(e.target.value))) {
      isCheck = true;
    }
    if (inputDataType === 4 && (e.target.value === '' || Number(e.target.value))) {
      isCheck = true;
    }
    if (!inputDataType) {
      isCheck = true;
    }
    if (isCheck) {
      if (
        typeof e.target.value === editListingValue.string &&
        e.target.value.toLowerCase() == editListingValue.oneOwner
      ) {
        this.setState({ showSingCount: Enums.NUMERIC_VALUE.ONE });
        this.state.filterEmail = {};
      } else if (
        typeof e.target.value === editListingValue.string &&
        e.target.value.toLowerCase() == editListingValue.twoOwner
      ) {
        this.setState({ showSingCount: Enums.NUMERIC_VALUE.TWO });
        this.state.filterEmail = {};
      } else if (
        typeof e.target.value === editListingValue.string &&
        e.target.value.toLowerCase() == editListingValue.threeOwner
      ) {
        this.setState({ showSingCount: Enums.NUMERIC_VALUE.THREE });
        this.state.filterEmail = {};
      }

      const { manipulatedFormField } = this.state;
      const errors = { ...this.state.errors };
      if (inputDataType === Enums.InputTypes.CURRENCY) {
        manipulatedFormField[field].value = e;
      } else {
        manipulatedFormField[field].value = e.target.value;
      }

      if (manipulatedFormField[field].isLinked) {
        Object.keys(manipulatedFormField).forEach((key) => {
          if (manipulatedFormField[field].displayName === manipulatedFormField[key].displayName) {
            manipulatedFormField[key].value = e.target.value;
            this.state.linkedChangeValue[key] = e.target.value;
          }
        });

        let linkedArr = dataService.getLocal('isLinkedArray');

        if (Object.keys(this.state.notInLinkedChangeValue).length > 0) {
          let notLinkedarray = { ...this.state.notInLinkedChangeValue };
          let linkedname = manipulatedFormField[field].displayName;
          for (const [key, value] of Object.entries(this.state.notInLinkedChangeValue)) {
            if (value === linkedname) {
              delete notLinkedarray[key];
              this.setState({ notInLinkedChangeValue: notLinkedarray });
            }
          }
        }

        if (linkedArr) {
          let linkedname = manipulatedFormField[field].displayName;
          let formPrefillTypeId = manipulatedFormField[field].formPrefillTypeId;

          let LinkedObj = { [linkedname]: e.target.value, formPrefillTypeId: formPrefillTypeId };
          let index = linkedArr.findIndex((it) => Object.keys(it)[0] === linkedname);
          if (index === -1) {
            linkedArr.push(LinkedObj);
          } else {
            linkedArr[index] = LinkedObj;
          }
          dataService.setLocal('isLinkedArray', linkedArr);
        } else {
          let linkedArr = [];
          let linkedname = manipulatedFormField[field].displayName;
          let formPrefillTypeId = manipulatedFormField[field].formPrefillTypeId;
          let LinkedObj = { [linkedname]: e.target.value, formPrefillTypeId: formPrefillTypeId };
          linkedArr.push(LinkedObj);
          dataService.setLocal('isLinkedArray', linkedArr);
        }
      } else {
        let linkedArr = dataService.getLocal('isLinkedArray');
        let linkedname = manipulatedFormField[field].displayName;
        if (linkedArr) {
          let index = linkedArr.findIndex((it) => Object.keys(it)[0] === linkedname);
          if (index >= 0) {
            this.state.notInLinkedChangeValue[field] = linkedname;
          }
        }
      }
      errors[field] = '';
      if (!formId) {
        formId = this.listingForm.formId;
      }
      this.setState({ manipulatedFormField, errors });
      this.props.handleFieldChange({
        listingId: this.props.listing.listingId,
        formId: formId,
        formFieldId: e.target.name,
        value: e.target.value,
      });
    }
  };

  handleFormCheckboxField = (e, field, fieldId) => {
    const { manipulatedFormField } = this.state;
    const errors = { ...this.state.errors };
    manipulatedFormField[fieldId].value = e.target.checked;
    errors[fieldId] = '';
    this.setState({ manipulatedFormField, errors });

    this.props.saveFormField(this.listingForm.userFormId, fieldId, e.target.checked);
    this.props.handleFieldChange({
      listingId: this.props.listing.listingId,
      formId: this.listingForm.formId,
      formFieldId: fieldId,
      value: e.target.checked,
    });
  };

  savetargetData = (Optionsval) => {
    let preFilledArr = [];
    let formIndex = this.state.step - enums.NUMERIC_VALUE.THREE;
    let stepFormArray = this.listingFormArrList[formIndex];
    if (stepFormArray && Optionsval) {
      let listingFormField = stepFormArray?.groups || [];
      for (let h = 0; h < listingFormField.length; h++) {
        let listingSection = listingFormField?.[h].sections;
        for (let i = 0; i < listingSection.length; i++) {
          let sectionArr = listingSection?.[i].fields;
          for (let j = 0; j < sectionArr.length; j++) {
            let displayName = sectionArr[j].displayName;

            for (let k = 0; k < Optionsval.length; k++) {
              if (displayName === Optionsval[k]) {
                preFilledArr.push({
                  formFieldId: sectionArr[j].formFieldId,
                  value: '',
                });
              }
            }
          }
        }
      }
    }

    if (preFilledArr.length > 0) {
      let arrIndex = this.state.step - enums.NUMERIC_VALUE.THREE;
      let PreFillArr = this.listingFormArrList[arrIndex];
      let userFormId = PreFillArr?.userFormId;
      const prefillReqObj = {
        userFormId: userFormId,
        previewFields: preFilledArr,
      };
      this.props.savePreviewFormField(prefillReqObj, () => {});
    }
  };

  handleFormTargetField = (e, options, fieldId) => {
    if (options) {
      let optionValue = options.replace(/\u21b5|\r\n|\n/g, '@#$').split('@#$');
      let stateObj = [];
      if (!e.target.checked) {
        this.savetargetData(optionValue);
      }
    }
  };

  removeValue = (list, value, separator) => {
    separator = separator || ',';
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (values[i] == value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  };

  isMultiChecked = (value, string) => {
    if (string && value) {
      const is_checked = string.indexOf(value) != -1;
      return is_checked;
    }
    return false;
  };

  isMSultiChecked = (value, string) => {
    if (string === value) {
      return true;
    } else {
      return false;
    }
  };
  handleFormMultileCheckboxField = (e, fieldId) => {
    const { manipulatedFormField } = this.state;
    const old_value = manipulatedFormField[fieldId]['value'];
    let newval = '';
    if (old_value) {
      if (e.target.checked) {
        newval = old_value + ',' + e.target.value;
      } else {
        newval = this.removeValue(old_value, e.target.value, ',');
      }
    } else {
      newval = e.target.value;
    }
    const errors = { ...this.state.errors };
    manipulatedFormField[fieldId].value = newval;
    errors[fieldId] = '';
    this.setState({ manipulatedFormField, errors });
    this.props.saveFormField(this.listingForm.userFormId, fieldId, newval);
    this.props.handleFieldChange({
      listingId: this.props.listing.listingId,
      formId: this.listingForm.formId,
      formFieldId: fieldId,
      value: newval,
    });
  };

  handleFormSingleCheckboxField = (e, fieldId) => {
    const { manipulatedFormField } = this.state;
    let newval = '';
    if (e.target.checked) {
      newval = e.target.value;
    }

    const errors = { ...this.state.errors };
    manipulatedFormField[fieldId].value = newval;
    errors[fieldId] = '';
    this.setState({ manipulatedFormField, errors });
    this.props.saveFormField(this.listingForm.userFormId, fieldId, newval);
    this.props.handleFieldChange({
      listingId: this.props.listing.listingId,
      formId: this.listingForm.formId,
      formFieldId: fieldId,
      value: newval,
    });
  };

  handleFormFieldSelect = (field, fieldId, isTypeDate, e) => {
    const { manipulatedFormField } = this.state;
    const errors = { ...this.state.errors };
    const selectedValue = isTypeDate ? e._d : e.target.value;

    if (typeof selectedValue === editListingValue.string && selectedValue.toLowerCase() == editListingValue.oneOwner) {
      this.setState({ showSingCount: Enums.NUMERIC_VALUE.ONE });
      this.state.filterEmail = {};
    } else if (
      typeof selectedValue === editListingValue.string &&
      selectedValue.toLowerCase() == editListingValue.twoOwner
    ) {
      this.setState({ showSingCount: Enums.NUMERIC_VALUE.TWO });
      this.state.filterEmail = {};
    } else if (
      typeof selectedValue === editListingValue.string &&
      selectedValue.toLowerCase() == editListingValue.threeOwner
    ) {
      this.setState({ showSingCount: Enums.NUMERIC_VALUE.THREE });
    }

    manipulatedFormField[fieldId].value = selectedValue;
    errors[fieldId] = '';
    this.setState({ manipulatedFormField, errors });
    this.props.saveFormField(this.listingForm.userFormId, fieldId, selectedValue);
    this.props.handleFieldChange({
      listingId: this.props.listing.listingId,
      formId: this.listingForm.formId,
      formFieldId: fieldId,
      value: selectedValue,
    });
  };

  handleFormFieldFocus = (e) => {
    this.setState({ originalFormFieldValue: e.target.value });
  };

  handleFormFieldBlur = (e, userFormID, inputDataType) => {
    if (inputDataType === enums.InputTypes.CURRENCY && e.target.value) {
      e.target.value = parseFloat(e.target.value.replace(/\$|,/g, ''));
    }

    if (this.state.originalFormFieldValue !== e.target.value) {
      const fieldData = { ...this.state.manipulatedFormField };
      if (fieldData[e.target.name].isLinked) {
        this.saveLinkedData();
      }
      this.props.saveFormField(userFormID, e.target.name, e.target.value);
    }

    this.setState({ originalFormFieldValue: null });
  };

  showErrorAlertModal() {
    this.setState({
      showErrorModalPopup: true,
    });
  }

  showFormInstructionModal = () => {
    this.setState({
      isFormInstructionRead: true,
    });
  };

  handleCloseModal = () => {
    if (this.isLastFormId && this.stepCount > enums.NUMERIC_VALUE.ZERO) {
      this.setState({ preStep: this.stepCount });
      this.setState({ step: this.stepCount + enums.NUMERIC_VALUE.ONE }, () => {
        this.handleBack();
      });
    }
    this.setState({
      showErrorModalPopup: false,
    });

    if (Object.keys(this.state.errors).length > 0) {
      let topNext = 0;
      Object.keys(this.state.errors).forEach((keyName) => {
        let tesNode = ReactDOM.findDOMNode(this.refs[keyName]);
        if (tesNode) {
          let top = tesNode.getBoundingClientRect().top + window.scrollY - enums.NUMERIC_VALUE.THIRTY;
          if (top) {
            if (!topNext || top < topNext) {
              topNext = top;
            }
          }
        }
      });

      window.scroll({ top: topNext, left: 0, behavior: 'smooth' });
    } else {
      smoothScrollTop();
    }
  };

  handleWillComeBack = () => {
    if (this.isLastFormId || this.state.checkForLastForm) {
      this.setState({
        showErrorModalPopup: false,
        manipulatedFormField: {},
        errors: {},
        step: enums.NUMERIC_VALUE.THREE,
        checkForLastForm: this.isLastFormId,
      });
      smoothScrollTop();
    } else {
      if (this.stepCount === 0) {
        this.stepCount = this.state.step;
      }
      this.setState({
        showErrorModalPopup: false,
        errors: {},
        step: this.state.step + enums.NUMERIC_VALUE.ONE,
      });
      smoothScrollTop();
    }
  };

  handleMlsPopupSubmission = () => {
    this.setState({
      isMLSFormSubmitted: false,
    });
    history.push(`${Routes.VIEW_LISTING.replace(':listingId', this.props.match.params.listingId)}`, {
      isMLSFormPendinng: false,
    });
    smoothScrollTop();
  };

  submitMLSForm = () => {
    const listingUserForms = [];
    uniqBy(this.listingFormArrList, 'formId').forEach((list) => {
      listingUserForms.push({
        formId: list.formId,
        userFormId: list.userFormId,
      });
    });
    const reqObj = {
      listingId: this.props.match.params.listingId,
      listingUserForms,
      ipAddress: this.ipAddress,
    };
    this.props.submitMLSFormV2(reqObj, () => {
      this.setState({
        isMLSFormSubmitted: true,
      });
    });
  };

  handleFormInstructions = () => {
    const cookieslistArr = Cookies.get('listngIdArr') ? JSON.parse(Cookies.get('listngIdArr')) : [];
    const listingId = this.props.match.params.listingId;
    if (!cookieslistArr.includes(listingId)) {
      cookieslistArr.push(listingId);
      Cookies.set('listngIdArr', cookieslistArr);
    }
    this.setState({
      isFormInstructionRead: false,
    });
    smoothScrollTop();
  };

  imageLimitCrossed = () => {
    this.setState({
      isPhotoLimitReached: true,
    });
  };

  formSteps = (index) => {
    this.setState({ preStep: this.state.step });
    this.setState({
      step: index + enums.NUMERIC_VALUE.ONE + enums.NUMERIC_VALUE.TWO,
    });

    this.setState({ changeBasicFormField: true });
    this.savePrefilledvalue(false);
  };
  handleStepClick = (e, index) => {
    const { step } = this.state;
    if (step === enums.NUMERIC_VALUE.ONE) {
      this.handleContinue(e, index);
    } else {
      this.setState({ step: index });
    }
    this.setState({ changeBasicFormField: true });
  };

  handlePrefilledValue = () => {
    const { listing, product } = this.props;
    const { listPrice, lotAcreage, squareFeet, bathrooms, bedrooms } = this.state.fields;
    const updatedListingForm = { ...this.listingForm };
    const formList = updatedListingForm?.groups;

    for (let j = 0; j < formList?.length; j++) {
      for (let k = 0; k < formList[j].sections.length; k++) {
        for (let l = 0; l < formList[j].sections[k].fields.length; l++) {
          let fieldValue = formList[j].sections[k].fields[l];

          let parseArray = dataService.getLocal('isLinkedArray');
          if (parseArray) {
            let index = parseArray.findIndex((it) => Object.keys(it)[0] === fieldValue.displayName);
            if (index > -1) {
              if (this.state.notInLinkedChangeValue.hasOwnProperty(fieldValue.formFieldId)) {
                formList[j].sections[k].fields[l].value = fieldValue.value;
              } else {
                formList[j].sections[k].fields[l].value = parseArray[index][fieldValue.displayName];
              }
            }
          }
          if (!fieldValue.value && fieldValue.prefillText) {
            this.state.PreFillFormFilled[fieldValue.formFieldId] = fieldValue.prefillText;
            if (
              Object.keys(this.state.manipulatedFormField).length > 0 &&
              this.state.manipulatedFormField?.[fieldValue.formFieldId]?.['value'] === fieldValue.prefillText
            ) {
              formList[j].sections[k].fields[l].value = fieldValue.prefillText;
            } else if (Object.keys(this.state.manipulatedFormField).length === 0) {
              formList[j].sections[k].fields[l].value = fieldValue.prefillText;
            }
          }
          if (
            fieldValue.readOnly &&
            !fieldValue.isLinked &&
            fieldValue.value &&
            fieldValue.prefillText !== fieldValue.value
          ) {
            this.state.PreFillFormFilled[fieldValue.formFieldId] = fieldValue.prefillText;
            formList[j].sections[k].fields[l].value = fieldValue.prefillText;
          }
          if (this.state.changeBasicFormField) {
            switch (fieldValue.formPrefillTypeId) {
              case Enums.NUMERIC_VALUE.TEN:
                const fullAddress = `${listing?.address1} ${listing.address2 ? listing.address2 : ''} ${
                  listing.unit ? +' ' + listing.unit : ''
                } ${listing.city ? listing.city + ',' : ''} ${listing.state} ${listing.zip} `;

                formList[j].sections[k].fields[l].value = fullAddress;
                formList[j].sections[k].fields[l].prefillText = fullAddress;
                this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = fullAddress;
                break;
              case Enums.NUMERIC_VALUE.THIRTEEN:
                formList[j].sections[k].fields[l].value = listing?.city;
                formList[j].sections[k].fields[l].prefillText = listing?.city;
                this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = listing?.city;
                break;
              case Enums.NUMERIC_VALUE.FOURTEEN:
                formList[j].sections[k].fields[l].value = listing?.county;
                formList[j].sections[k].fields[l].prefillText = listing?.county;
                this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = listing?.county;
                break;
              case Enums.NUMERIC_VALUE.FIFTEEN:
                formList[j].sections[k].fields[l].value = listing?.state;
                formList[j].sections[k].fields[l].prefillText = listing?.state;
                this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = listing?.state;
                break;
              case Enums.NUMERIC_VALUE.SIXTEEN:
                formList[j].sections[k].fields[l].value = listing?.zip;
                formList[j].sections[k].fields[l].prefillText = listing?.zip;
                this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = listing?.zip;
                break;
              case Enums.NUMERIC_VALUE.TWENTY:
                if (listPrice.isValueChange || !formList[j].sections[k].fields[l].value) {
                  formList[j].sections[k].fields[l].value = listPrice?.value;
                  this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = listPrice?.value;
                  this.previewArr.push({
                    formFieldId: formList[j].formFieldId,
                    value: formList[j].value,
                  });
                }
                break;
              case Enums.NUMERIC_VALUE.TWENTY_THREE:
                if (lotAcreage.isValueChange || !formList[j].sections[k].fields[l].value) {
                  formList[j].sections[k].fields[l].value = lotAcreage?.value;
                  this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = lotAcreage?.value;
                  this.previewArr.push({
                    formFieldId: formList[j].formFieldId,
                    value: formList[j].value,
                  });
                }
                break;
              case Enums.NUMERIC_VALUE.TWENTY_EIGHT:
                if (squareFeet.isValueChange || !formList[j].sections[k].fields[l].value) {
                  formList[j].sections[k].fields[l].value = squareFeet?.value;
                  this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = squareFeet?.value;
                  this.previewArr.push({
                    formFieldId: formList[j].formFieldId,
                    value: formList[j].value,
                  });
                }
                break;
              case Enums.NUMERIC_VALUE.THIRTY_TWO:
                if (bathrooms.isValueChange || !formList[j].sections[k].fields[l].value) {
                  formList[j].sections[k].fields[l].value = bathrooms?.value;
                  this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = bathrooms?.value;
                  this.previewArr.push({
                    formFieldId: formList[j].formFieldId,
                    value: formList[j].value,
                  });
                }
                break;
              case Enums.NUMERIC_VALUE.SEVENTEEN:
                if (bedrooms.isValueChange || !formList[j].sections[k].fields[l].value) {
                  formList[j].sections[k].fields[l].value = bedrooms?.value;
                  this.state.PreFillFormFilled[formList[j].sections[k].fields[l].formFieldId] = bedrooms?.value;
                  this.previewArr.push({
                    formFieldId: formList[j].formFieldId,
                    value: formList[j].value,
                  });
                }
                break;
              default:
                break;
            }
            this.setState({ changeBasicFormField: false });
          }
        }
      }
    }

    return updatedListingForm;
  };

  render() {
    const { step, showErrorModalPopup, fields, isFormInstructionRead, isPhotoLimitReached, isMLSFormSubmitted } =
      this.state;
    const {
      order,
      listing,
      userDetails,
      photoProgress,
      deletePhoto,
      removePhotosListing,
      isUploading,
      theme,
      photoDetailObj,
    } = this.props;
    const listingFormArr = [];
    this.previewArr = [];
    const forms = (listing || {}).forms || [];

    const pArray = [];
    const countObj = [];
    forms.forEach((form) => {
      let formGroups = form.groups;
      let filterGroups = {};
      let filterSectipons = {};
      let formId = form.formId;
      let userFormId = form.userFormId;

      formGroups.forEach((group) => {
        if (group.formGroupId === 1) {
          let formSections = group.sections;
          let m = 0;
          formSections.forEach((sections) => {
            if (sections.priority) {
              m = Math.floor(sections.priority / 1000);

              if (!filterSectipons[m]) {
                filterSectipons[m] = [];
              }
              filterSectipons[m].push(sections);
            }
          });

          Object.keys(filterSectipons).forEach((key) => {
            let newGroup = {
              formGroupId: group.formGroupId,
              formGroupType: group.formGroupType,
              name: group.name,
              priority: filterSectipons[key][0].priority,
              sections: filterSectipons[key],
              formId: formId,
              userFormId: userFormId,
            };

            if (!filterGroups[key]) {
              filterGroups[key] = [];
            }
            filterGroups[key].push(newGroup);
          });
        } else {
          let p = 0;
          if (group.priority) {
            p = Math.floor(group.priority / 1000);
          } else {
            group.priority = form.priority;
          }
          group.formId = formId;
          group.userFormId = userFormId;
          if (!filterGroups[p]) {
            filterGroups[p] = [];
          }

          filterGroups[p].push(group);
        }
      });

      Object.entries(filterGroups).forEach(function ([key, value]) {
        Object.entries(value).forEach(function ([key1, value1]) {
          let formData = JSON.parse(JSON.stringify(form));
          if (formData.priority) {
            let p = Math.floor(value1.priority / 1000);
            if (!pArray[p]) {
              pArray[p] = [];
            }
            pArray[p].push(value1);
          }
        });
      });

      for (var key in pArray) {
        if (!countObj.includes(key)) {
          countObj.push(key);
          let formData = JSON.parse(JSON.stringify(form));
          formData.groups = pArray[key];
          if (formData.priority) {
            listingFormArr.push(formData);
          }
        }
      }
    });

    this.listingForm = listingFormArr[step - enums.NUMERIC_VALUE.THREE];
    this.isLastFormId = listingFormArr.length + enums.NUMERIC_VALUE.TWO === step;
    this.listingFormArrList = listingFormArr;

    if (forms.length > 1 && step > 2) {
      this.listingForm = this.handlePrefilledValue();
    }
    const isFormSubmitted = listing.listingStatus !== 'Incomplete';

    const createMarkup = (description) => ({ __html: description });
    const renderMLSFormsSteps = () => {
      return (
        this.listingFormArrList.length > 0 &&
        this.listingFormArrList.map((form, index) => {
          let labelName = formLabelValue.additional;
          const priorityCount = Math.floor(form.groups[0]?.priority / enums.NUMERIC_VALUE.THOUSAND) || 0;
          if (form.groups[0].name.toLowerCase().includes(formType.listingForm) && priorityCount == 0) {
            labelName = formLabelValue.listingContract;
          } else if (priorityCount === Enums.NUMERIC_VALUE.TWO) {
            labelName = formLabelValue.agencyDisclosure;
          } else if (priorityCount === Enums.NUMERIC_VALUE.THREE) {
            labelName = formLabelValue.listingContract;
          } else if (priorityCount === Enums.NUMERIC_VALUE.FOUR) {
            labelName = formLabelValue.additional;
          } else if (priorityCount === Enums.NUMERIC_VALUE.FIVE) {
            labelName = formLabelValue.propertyDisclosure;
          } else if (
            priorityCount === Enums.NUMERIC_VALUE.ONE ||
            form.groups[0].name.toLowerCase().includes(formType.inputSheet)
          ) {
            labelName = formLabelValue.propertyData;
          }
          return (
            <span
              key={Math.random()}
              className={this.state.step === index + enums.NUMERIC_VALUE.ONE + enums.NUMERIC_VALUE.TWO ? 'active' : ''}
              onClick={() => this.formSteps(index)}
            >
              {labelName}
            </span>
          );
        })
      );
    };
    return (
      <React.Fragment>
        <Navbar />
        {listing && (
          <>
            <MainWrapper className="for-top-gap">
              <Container>
                <a className="back-btn bottom-gap" href="javascript:void(0)" onClick={() => goBack()}>
                  <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                </a>

                <StepsWrapper
                  className={'for-bottom-gap ' + (listing.productTypeId === enums.NUMERIC_VALUE.FIVE ? 'two-step' : '')}
                >
                  <StepBox
                    className={step < enums.NUMERIC_VALUE.TWO ? 'active' : ''}
                    isActive={step < enums.NUMERIC_VALUE.TWO}
                  >
                    <span className="step-box" onClick={() => this.setState({ step: enums.NUMERIC_VALUE.ONE })}>
                      {enums.NUMERIC_VALUE.ONE}
                    </span>
                    <strong>Basics</strong>
                  </StepBox>
                  <StepBox
                    className={step === enums.NUMERIC_VALUE.TWO ? 'active' : ''}
                    isActive={step === enums.NUMERIC_VALUE.TWO}
                  >
                    <span className="step-box" onClick={(e) => this.handleStepClick(e, enums.NUMERIC_VALUE.TWO)}>
                      {enums.NUMERIC_VALUE.TWO}
                    </span>
                    <strong>Photos</strong>
                  </StepBox>
                  {listing.productTypeId !== enums.NUMERIC_VALUE.FIVE && (
                    <StepBox
                      className={step === enums.NUMERIC_VALUE.TWO_POINT_FIVE ? 'active' : ''}
                      isActive={step === enums.NUMERIC_VALUE.TWO_POINT_FIVE}
                    >
                      <span className="step-box" onClick={(e) => this.handleStepClick(e, enums.NUMERIC_VALUE.TWO_POINT_FIVE)}>
                        {enums.NUMERIC_VALUE.THREE}
                      </span>
                      <strong>Title and Escrow</strong>
                    </StepBox>
                  )}
                  {listing.productTypeId !== enums.NUMERIC_VALUE.FIVE && (
                    <StepBox
                      className={step > enums.NUMERIC_VALUE.TWO_POINT_FIVE ? 'active' : ''}
                      isActive={step > enums.NUMERIC_VALUE.TWO_POINT_FIVE}
                    >
                      <span className="step-box" onClick={(e) => this.handleStepClick(e, enums.NUMERIC_VALUE.THREE)}>
                        {enums.NUMERIC_VALUE.FOUR}
                      </span>
                      <strong>Details</strong>
                    </StepBox>
                  )}
                </StepsWrapper>
                <div className="listing-address-order-wrapper">
                  <span className="listing-address-text">
                    {listing.address1} {listing.address2} {listing.unit} {listing?.city ? listing?.city + ',' : ''}{' '}
                    {listing.state} {listing.zip}
                  </span>
                  <span className="listing-order-id">
                    <span>Order Id:</span>
                    <strong>{order && order.orderId}</strong>
                  </span>
                </div>
                {step < enums.NUMERIC_VALUE.TWO && (
                  <FormWrapper>
                    <BasicFormView
                      initialState={fields}
                      handleFieldChanged={this.handleFieldChanged}
                      handleFieldBlur={this.handleFieldBlur}
                      handleEditorChange={this.handleEditorChange}
                    ></BasicFormView>
                    <ButtonWrapper>
                      <CustomButton href="#" onClick={this.handleContinue}>
                        Continue
                      </CustomButton>
                    </ButtonWrapper>
                  </FormWrapper>
                )}

                {step === enums.NUMERIC_VALUE.TWO && (
                  <FormWrapper>
                    <PhotoManager
                      photos={listing.photos}
                      photoProgress={photoProgress}
                      removePhotos={removePhotosListing}
                      onOrderChanged={this.handleOrderChanged}
                      onNewPhotoAdded={this.handleNewPhotoAdded}
                      deletePhoto={deletePhoto}
                      imageLimitCrossed={this.imageLimitCrossed}
                      photoDetailObj={photoDetailObj}
                      onDownloadZip={this.DownloadZip}
                    />

                    <ButtonWrapper className="for-flex">
                      <CustomButton href="#" onClick={this.handleBack} className="small faded">
                        Back
                      </CustomButton>
                      {listing.productTypeId !== enums.NUMERIC_VALUE.FIVE && (
                        <CustomButton
                          href="#"
                          className="small"
                          onClick={() => {
                            this.setState({
                              step: enums.NUMERIC_VALUE.TWO_POINT_FIVE,
                            });
                          }}
                        >
                          Continue
                        </CustomButton>
                      )}
                    </ButtonWrapper>
                  </FormWrapper>
                )}

                {step === enums.NUMERIC_VALUE.TWO_POINT_FIVE && (
                  <FormWrapper>
                    <TitleAndEscrowForm
                      handleContinue={() => {
                            this.setState({
                              step: enums.NUMERIC_VALUE.THREE,
                            });
                          }}
                      handleBack={this.handleBack}
                    />
                  </FormWrapper>
                )}

                {step > enums.NUMERIC_VALUE.TWO_POINT_FIVE && this.listingForm && (
                  <FormWrapper>
                    <ButtonWrapper className="for-flex button-gap">
                      <CustomButton className="small faded" onClick={this.handleBack}>
                        Back
                      </CustomButton>
                      {isFormSubmitted && this.isLastFormId ? (
                        ''
                      ) : (
                        <CustomButton
                          className="small"
                          disabled={isUploading}
                          style={{ opacity: isUploading ? '.5' : '1' }}
                          onClick={this.handleContinue}
                        >
                          {this.isLastFormId ? 'Click To E-Sign' : 'Continue'}
                        </CustomButton>
                      )}
                    </ButtonWrapper>
                    <div className="form-tabs">{renderMLSFormsSteps()}</div>
                    <StepWrap>
                      <FormHeading className="with-tooltip">
                        Fill in the blanks and review your listing contract below
                        <div className="info-tooltip-box" onClick={this.showFormInstructionModal}>
                          <span className="info-tooltip-icon">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                          </span>
                        </div>
                      </FormHeading>
                    </StepWrap>

                    <Form>
                      {this.listingForm.groups.map((g, i) => {
                        return (
                          <Group key={i}>
                            {g.sections.map((s, j) => {
                              return (
                                <div
                                  className={`${
                                    g.formGroupType === enums.NUMERIC_VALUE.THREE &&
                                    this.listingFormArrList.length > 1 &&
                                    'dynamic-form'
                                  } ${g.formGroupType !== enums.NUMERIC_VALUE.THREE && 'dynamic-form-before'}`}
                                >
                                  <Section key={j}>
                                    {this.processForm(
                                      s.content,
                                      s.fields,
                                      this.listingForm.formType === enums.FormTypes.MLS_LISTING_FORM,
                                      g,
                                      s.priority,
                                      isFormSubmitted,
                                      g.userFormId,
                                      g.formId,
                                      s.formGroupSectionId
                                    )}
                                  </Section>
                                </div>
                              );
                            })}
                          </Group>
                        );
                      })}
                    </Form>

                    <ButtonWrapper className="for-flex">
                      <CustomButton onClick={this.handleBack} className="faded small">
                        Back
                      </CustomButton>
                      {isFormSubmitted && this.isLastFormId ? (
                        ''
                      ) : (
                        <CustomButton
                          className="small"
                          disabled={isUploading}
                          style={{ opacity: isUploading ? '.5' : '1' }}
                          onClick={this.handleContinue}
                        >
                          {this.isLastFormId ? 'Click To E-Sign' : 'Continue'}
                        </CustomButton>
                      )}
                    </ButtonWrapper>
                  </FormWrapper>
                )}

                {step > enums.NUMERIC_VALUE.TWO && forms.length === 0 && (
                  <>
                    <div className="listing-forms-not-available">
                      <h3>Listing Forms not available. Please contact the administrator.</h3>
                    </div>
                    <CustomButton className="small faded btn-center" onClick={this.handleRedirectDasboard}>
                      GO Back
                    </CustomButton>
                  </>
                )}
                <ShowMap
                  listingId={listing.listingId}
                  address1={listing.address1}
                  address2={listing.address2}
                  unit={listing.unit}
                  city={listing.city}
                  state={listing.state}
                  zip={listing.zip}
                  latitude={listing.latitude}
                  longitude={listing.longitude}
                  theme={theme}
                ></ShowMap>
              </Container>
            </MainWrapper>
          </>
        )}
        <ModalDiv
          title="Required"
          visible={showErrorModalPopup}
          onCancel={this.handleCloseModal}
          onOk={this.handleWillComeBack}
          okText="I'll Come Back Later"
          cancelText="Go Back"
          destroyOnClose={true}
          className="confirm-modal"
        >
          <span className="modal-icon">
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </span>
          <p className="static-text">
            You missed some required fields. <br></br>Fill them out now or come back later.
          </p>
        </ModalDiv>
        <ModalDiv
          title="Photo Limit"
          cancelButtonProps={{ style: { display: 'none' } }}
          visible={isPhotoLimitReached}
          onOk={() => this.setState({ isPhotoLimitReached: false })}
          okText="OK"
          closable={false}
          destroyOnClose={true}
          className="confirm-modal"
        >
          <p className="static-text">Maximum Photo upload limit reached!</p>
        </ModalDiv>
        <ModalDiv
          title="Submission to MLS Successful"
          cancelButtonProps={{ style: { display: 'none' } }}
          visible={isMLSFormSubmitted}
          onOk={this.handleMlsPopupSubmission}
          okText="OK"
          closable={false}
          destroyOnClose={true}
          className="confirm-modal"
        >
          <p className="static-text">Your Listing has been submitted successfully!</p>
        </ModalDiv>

        {forms.length > 1 && step === enums.NUMERIC_VALUE.THREE && (
          <ModalDiv
            title={`MLS ${this.listingForm?.name || ''}`}
            cancelButtonProps={{ style: { display: 'none' } }}
            visible={isFormInstructionRead}
            onOk={this.handleFormInstructions}
            onCancel={() => {
              this.setState({ isFormInstructionRead: false });
              smoothScrollTop();
            }}
            okText="I have read the above instructions"
            destroyOnClose={true}
            className="text-modal listing-contract-modal"
          >
            <div>
              <div
                dangerouslySetInnerHTML={createMarkup(this.listingFormArrList[0]?.formInstruction?.description)}
              ></div>
            </div>
          </ModalDiv>
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { listing, isFetching, photos, order, product, photoProgress } = state.dashboard;
  const photoDetailObj =
    product?.productFeatureTypes?.find((obj) => obj.productFeatureTypeId === Enums.NUMERIC_VALUE.TWO) || {};
  return {
    s3Identity: state.user.s3Identity,
    appSettings: state.user.appSettings,
    userDetails: state.user.userDetails,
    listing: listing,
    isFetching: isFetching,
    photos: photos,
    photoProgress: photoProgress,
    isUploading: photoProgress.some((p) => p < 1.0),
    photoDetailObj: photoDetailObj,
    order: order,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...dashboardActionCreators, ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(EditListing));