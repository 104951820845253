import React from 'react';
import { Button, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import Select, { createFilter } from 'react-select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statesSelector, companiesSelector, propertyTypesSelector, officeUsersSelector, listingStatusesSelector, formStatusesSelector, officeStatusesSelector, titleCompaniesSelector } from '../../../../../Reducers/LookupReducer';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { cursorStyles, reactSelectFilters } from '../../../../../Constants/CommonConstants';
import { AdminUserSearchInput } from '../../../Components/AdminUserSearchInput';
import AnnotatedInput from '../../../../Shared/Components/AnnotatedInput';
import { propertiesFiltersSelector } from '../../../../../Reducers/V2/AdminPropertiesReducer';
import { propertiesCreators } from '../../../../../Actions/V2/Admin/PropertiesActions';

const { RangePicker } = DatePicker;

const RangePickerWrapper = styled.div`
.ant-calendar-picker {
    margin-right: 10px;
}
.ant-input {
    height: 40px;
    display: inline-flex;
}
.ant-calendar-range-picker-separator {
    padding-top: 5px;
    color: #808080;
}
.ant-calendar-range-picker-input::placeholder {
    color: #808080;
}
`;

export const InputFlex = styled(AnnotatedInput)`
  ${props => `
  flex: ${props.flex ? props.flex : '.5'};
  input {
    padding: 0 10px;
    height: 45px;
    border-radius: 6px;
    font-size: 15px;
  }
`}
`;

const PropertiesFilters = () => {
    const dispatch = useDispatch();
    const filters = useSelector(propertiesFiltersSelector);

    const companies = useSelector(companiesSelector);
    const listingStatuses = useSelector(listingStatusesSelector);
    const officeUsers = useSelector(officeUsersSelector);
    const formStatuses = useSelector(formStatusesSelector);
    const officeStatuses = useSelector(officeStatusesSelector);
    const titleCompanies = useSelector(titleCompaniesSelector);
    const states = useSelector(statesSelector);

    const listingStatusesOptions = listingStatuses.map(x => ({ value: x.id, label: x.name }));
    const statesOptions = states.map(x => ({ value: x.id, label: x.name }));
    const companiesOptions = companies.map(x => ({ value: x.id, label: x.name }));
    const formStatusesOptions = formStatuses.map(x => ({ value: x.id, label: x.name }));
    const officeStatusesOptions = officeStatuses.map(x => ({ value: x.id, label: x.name }));
    const titleCompaniesOptions = titleCompanies.map(x => ({ value: x.id, label: x.name }));
    const cashOfferOptions = [
        { value: null, label: 'All' },
        { value: false, label: 'Without offer' },
        { value: true, label: 'Has offer' },
    ];
    const officeUsersOptions = [
        { value: null, label: 'All' },
        ...officeUsers
    ];

    useEffect(() => {
        dispatch(lookupActionsCreators.getListingStatuses());
        dispatch(lookupActionsCreators.getOfficeUsers());
        dispatch(lookupActionsCreators.getFormStatuses());
        dispatch(lookupActionsCreators.getOfficeStatuses());
        dispatch(lookupActionsCreators.getStates());
        dispatch(lookupActionsCreators.getCompanies());
        dispatch(lookupActionsCreators.getTitleCompanies());
    }, []);

    const handleChange = (value) => dispatch(propertiesCreators.setPropertiesFilters(value));

    const applyFilters = () => dispatch(propertiesCreators.getProperties(filters));

    const clearFilters = () => {
        dispatch(propertiesCreators.clearPropertiesFilters());
        dispatch(propertiesCreators.getProperties());
    }

    return (
        <div className={`admin-filter-wrapper more-filter`}>
            <div className={`filter-bar`}>
                <div className="filter-bar-left">
                    {/* <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Username or Email</label>
                        <AdminUserSearchInput
                          placeholder='Enter User Name or Email'
                          value={user?.label || user}
                          predictions={usersList}
                          className='admin-filter-user-search'
                          handleUserSelected={e => this.handleUserFilterSelected(e)}
                          onKeyDown={e => this._handleKeyDown(e)}
                          onChange={e => this.onUserSearch(e)}
                        />
                    </div> */}
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Property Status</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Property Status'
                            value={listingStatusesOptions.find(x => x.value === filters.listingStatusId) || null}
                            onChange={e => handleChange({ ...filters, listingStatusId: e.value })}
                            options={listingStatusesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Company</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Company'
                            value={companiesOptions.find(x => x.value === filters.companyId) || null}
                            onChange={e => handleChange({ ...filters, companyId: e.value })}
                            options={companiesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Assigned</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Assigned'
                            value={officeUsersOptions.find(x => x.value === filters.assignedToId) || null}
                            onChange={e => handleChange({ ...filters, assignedToId: e.value })}
                            options={officeUsersOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Office Status</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Office Status'
                            value={officeStatusesOptions.find(x => x.value === filters.officeStatusId) || null}
                            onChange={e => handleChange({ ...filters, officeStatusId: e.value })}
                            options={officeStatusesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by State</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select State'
                            value={statesOptions.find(x => x.value === filters.state) || null}
                            onChange={e => handleChange({ ...filters, state: e.value })}
                            options={statesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Form Status</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Form Status'
                            value={formStatusesOptions.find(x => x.value === filters.formStatusId) || null}
                            onChange={e => handleChange({ ...filters, formStatusId: e.value })}
                            options={formStatusesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Title Company</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Title Company'
                            value={titleCompaniesOptions.find(x => x.value === filters.titleCompanyId) || null}
                            onChange={e => handleChange({ ...filters, titleCompanyId: e.value })}
                            options={titleCompaniesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <div>
                        <label>Filter by Cash Offer</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Title Company'
                            value={cashOfferOptions.find(x => x.value === filters.hasCashOffers) || null}
                            onChange={e => handleChange({ ...filters, hasCashOffers: e.value })}
                            options={cashOfferOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <RangePickerWrapper className="for-custom-select">
                        <label>Filter by Last Modified Date</label>
                        <RangePicker
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            format="YYYY/MM/DD"
                            value={filters.dateModifiedFrom && filters.dateModifiedTo
                                ? [moment(filters.dateModifiedFrom), moment(filters.dateModifiedTo)]
                                : null}
                            onChange={dates => handleChange({ ...filters, dateModifiedFrom: dates[0].format('YYYY/MM/DD'), dateModifiedTo: dates[1].format('YYYY/MM/DD') })}
                        />
                    </RangePickerWrapper>
                    <RangePickerWrapper className="for-custom-select">
                        <label>Filter by Order Date</label>
                        <RangePicker
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            format="YYYY/MM/DD"
                            value={filters.dateCreatedFrom && filters.dateCreatedTo
                                ? [moment(filters.dateCreatedFrom), moment(filters.dateCreatedTo)]
                                : null}
                            onChange={dates => handleChange({ ...filters, dateCreatedFrom: dates[0].format('YYYY/MM/DD'), dateCreatedTo: dates[1].format('YYYY/MM/DD') })}
                        />
                    </RangePickerWrapper>
                </div>
                <div className='product-group search-buttons'>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => applyFilters()}>
                        Apply Filters
                    </Button>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => clearFilters()}>
                        Reset Filters
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default (PropertiesFilters);
