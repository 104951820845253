import React from 'react'
import { useDispatch } from 'react-redux';

import { Checkbox } from 'antd';
import { ordersCreators } from '../../../../../Actions/V2/Admin/OrdersActions';

export const DraftListingContactedCheckbox = ({ contacted, id }) => {
  const dispatch = useDispatch();
  const onChange = () => dispatch(ordersCreators.markAsContacted(id, !contacted));
  return (<Checkbox onChange={onChange} defaultChecked={!!contacted} checked={!!contacted} />);
};

