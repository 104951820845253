import React from 'react';
import { connect } from 'react-redux';
import { RowFlex, InputFlex, DivWrapper, DivDetailWrapper } from '../../../../Assets/Style/commonStyleComponents';

class BrokerInfo extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { initialState, handleFieldChanged, handleFieldBlur } = this.props;
		return (
			<React.Fragment>
				<DivWrapper>
					<div className="broker-info-wrapper">
						<RowFlex>
							<InputFlex
								type="text"
								name={initialState.brokerName.name}
								title={initialState.brokerName.label}
								value={initialState.brokerName.value}
								error={initialState?.brokerName?.error}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
								className="inputgap"
							/>
							<InputFlex
								type="text"
								name={initialState.brokerEmail.name}
								title={initialState.brokerEmail.label}
								value={initialState.brokerEmail.value}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
								error={initialState?.brokerEmail?.error}
								className={`inputgap ${initialState?.brokerEmail?.error ? 'item-text-red' : ''}`}
							/>
							<InputFlex
								type="text"
								name={initialState.brokerPhone.name}
								title={initialState.brokerPhone.label}
								mask={initialState?.brokerPhone?.mask}
								value={initialState.brokerPhone.value}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
								className="inputgap"
							/>
							<InputFlex
								type="text"
								name={initialState.brokerWebsite.name}
								title={initialState.brokerWebsite.label}
								value={initialState.brokerWebsite.value}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
								error={initialState?.brokerWebsite?.error}
								className={`inputgap ${initialState?.brokerWebsite?.error ? 'item-text-red' : ''}`}
							/>
						</RowFlex>
					</div>
				</DivWrapper>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isFetching: state.admin.isFetching,
	};
}

export default connect(mapStateToProps)(BrokerInfo);
