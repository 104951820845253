import { sellerNotificationActions } from "../Actions/SellerNotificationActions";


const initialState = {
    mlsId: null,
    address: '',
    state: null,
    isError: false,
    pdfLinks: [],
    pdfLinkError: false,
    checkingMls: false,
    uploadingOfferPdf: false,
    formSubmitting: false,
    pdfUploadProgress: {},
};

export const SellerNotificationReducer = (state = initialState, action = {}) => {

    switch (action.type) {
        case sellerNotificationActions.CHECK_MLS_REQUESTED:
            return {
                ...state,
                checkingMls: true,
                mlsId: action.payload,
                address: '',
                state: null,
            };
        case sellerNotificationActions.CHECK_MLS_RESOLVED:
            return {
                ...state,
                checkingMls: false,
                mlsId: action.payload.mlsNumber,
                address: action.payload.address,
                state: action.payload.state,
            };
        case sellerNotificationActions.CHECK_MLS_FAILURE:
            return {
                ...state,
                checkingMls: false,
                isError: true,
                mlsId: null,
                state: null,
            };
        case sellerNotificationActions.REQUEST_SHOWING_REQUESTED:
        case sellerNotificationActions.SUBMIT_OFFER_REQUESTED:
            return {
                ...state,
                formSubmitting: true,
            };
        case sellerNotificationActions.REQUEST_SHOWING_FAILURE:
        case sellerNotificationActions.REQUEST_SHOWING_RESOLVED:
        case sellerNotificationActions.SUBMIT_OFFER_FAILURE:
            return {
                ...state,
                formSubmitting: false,
            };
        case sellerNotificationActions.SUBMIT_OFFER_RESOLVED:
            return initialState;
        case sellerNotificationActions.UPLOAD_OFFER_PDF_REQUESTED:
            return {
                ...state,
                uploadingOfferPdf: true,
                pdfLinkError: false,
            };
        case sellerNotificationActions.UPLOAD_OFFER_PDF_FAILURE:
            const pdfUploadProgress = state.pdfUploadProgress;
            delete pdfUploadProgress[action.payload.id];
            return {
                ...state,
                uploadingOfferPdf: false,
                pdfUploadProgress,
            };
        case sellerNotificationActions.UPLOAD_OFFER_PDF_RESOLVED:
            return {
                ...state,
                uploadingOfferPdf: false,
                pdfLinkError: false,
                pdfLinks: [
                    ...state.pdfLinks,
                    {
                        link: action.payload.data.link,
                        id: action.payload.id,
                    }
                ],
                pdfUploadProgress: {
                    ...state.pdfUploadProgress,
                    [action.payload.id]: 100,
                },
            };
        case sellerNotificationActions.SET_PDF_LINK_ERROR:
            return {
                ...state,
                pdfLinkError: action.payload,
            };
        case sellerNotificationActions.SET_PDF_UPLOAD_PROGRESS:
            return {
                ...state,
                pdfUploadProgress: {
                    ...state.pdfUploadProgress,
                    [action.payload.id]: action.payload.progress,
                },
            };

        case sellerNotificationActions.REMOVE_PDF_LINK:
            return {
                ...state,
                pdfLinks:  action.payload,
            };



        default: return state;

    }
};

export const mlsIdSelector = state => state.sellerNotification.mlsId;
export const addressSelector = state => state.sellerNotification.address;
export const stateSelector = state => state.sellerNotification.state;
export const pdfLinkErrorSelector = state => state.sellerNotification.pdfLinkError;
export const pdfLinksSelector = state => state.sellerNotification.pdfLinks;
export const checkingMlsSelector = state => state.sellerNotification.checkingMls;
export const uploadingOfferPdfSelector = state => state.sellerNotification.uploadingOfferPdf;
export const formSubmittingSelector = state => state.sellerNotification.formSubmitting;
export const pdfUploadProgressSelector = state => state.sellerNotification.pdfUploadProgress;