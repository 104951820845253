import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import v8n from 'v8n';
import { adminActionCreators } from '../../../Actions/AdminActions';
import { RowFlex, InputFlex, CustomTextarea } from '../../../Assets/Style/commonStyleComponents';
import { entityTypes, phoneMask, regexPattern, validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import {
  generateRandomPassword,
  goBack,
  getFocusInput,
  statusChangeModalPopup,
  getLocalUserData,
  convertPhone,
} from '../../../Utilities/commonFunctions';
import { Button, Icon } from 'antd';
import AuditLog from './AuditLog';
import { userTableConfigs } from '../../../Constants/TableConstants';
import AddressComponent from './AddressComponent';
import Select from 'react-select';
import { cursorStyles } from '../../../Constants/CommonConstants';
import { history } from '../../../Utilities/History';
import { Spinner } from 'react-bootstrap';
import { themes } from '../../../Constants/Themes';
import styled from 'styled-components';

const ConfirmedLabel = styled.span`
  display: flex;
  color: green;
  font-family: 'National-Semi-Bold';
  border: solid 1px #52c41a;
  border-radius: 4px;
  padding: 0.5rem;
  margin-left: 1rem;
  height: 40px;
  align-items: center;
`;

const UnconfirmedLabel = styled(ConfirmedLabel)`
  color: #ebb127;
  font-family: 'National-Semi-Bold';
  border: solid 1px #ebb127;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const { ZERO } = Enums.NUMERIC_VALUE;
const disabled_input = 'disabled-input';
class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.isNewUser = props.isNewUser;
    this.location = history.location.pathname;
    this.isSmallerRole = Number(getLocalUserData()?.userTypeId) <= props.userProfileData?.userTypeId;
    this.state = {
      isSendMail: props.isNewUser,
      isPasswordGenerate: false,
      isAddressSame: !props.isNewUser ? props.userProfileData?.isAddressSame : true,
      addUserDetail: {
        firstName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.firstName,
            },
          ],
          value: props?.userProfileData?.firstName || '',
        },
        lastName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.lastName,
            },
          ],
          value: props.userProfileData?.lastName || '',
        },
        email: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.email,
            },
            {
              rule: v8n().pattern(regexPattern.EMAIL),
              message: validationMessages.validEmail,
            },
          ],
          value: props?.userProfileData?.email || '',
        },
        managingBrokerLicense: {
          rules: [],
          value: props?.userProfileData?.managingBrokerLicense || '',
        },
        phoneNumber: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.phoneNumber,
            },
            {
              rule: v8n().pattern(regexPattern.PHONE),
              message: validationMessages.phoneNumber,
            },
          ],
          mask: phoneMask,
          value: props?.userProfileData?.phoneNumber || '',
        },
        newPassword: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
            {
              rule: v8n().pattern(regexPattern.PASSWORD),
              message: validationMessages.validPassword,
            },
          ],
          value: '',
        },
        companies: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a user company.',
            },
          ],
          value: props?.userProfileData?.companies || '',
        },
        userTypeId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a user role.',
            },
          ],
          value: props?.userProfileData?.userTypeId || '',
        },
        note: {
          rules: [],
          value: props.userProfileData?.note,
        },
      },
      billingAddress: {
        billingFirstName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.firstName,
            },
          ],
          value: props?.userProfileData?.billingInfo?.firstName || '',
          name: 'billingFirstName',
          label: 'First Name',
        },
        billingLastName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.lastName,
            },
          ],
          value: props.userProfileData?.billingInfo?.lastName || '',
          name: 'billingLastName',
          label: 'Last Name',
        },
        billingAddress1: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your street address',
            },
          ],
          name: 'billingAddress1',
          label: 'Address 1',
          value: props.userProfileData?.billingInfo?.address1 || '',
        },
        billingAddress2: {
          rules: [],
          name: 'billingAddress2',
          label: 'Address 2',
          value: props.userProfileData?.billingInfo?.address2 || '',
        },
        billingCity: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your city',
            },
          ],
          name: 'billingCity',
          label: 'City',
          value: props.userProfileData?.billingInfo?.city || '',
        },
        billingState: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select your state',
            },
          ],
          name: 'billingState',
          label: 'State',
          value: props.userProfileData?.billingInfo?.state || '',
        },
        billingZip: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your zipcode',
            },
            {
              rule: v8n().minLength(5),
              message: 'Zipcode required minimum five character',
            },
          ],
          name: 'billingZip',
          label: 'Zip',
          value: props.userProfileData?.billingInfo?.zip || '',
        },
        billingUnit: {
          rules: [],
          name: 'billingUnit',
          label: 'Unit',
          value: props.userProfileData?.billingInfo?.unit || '',
        },
      },
      shippingAddress: {
        shippingFirstName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.firstName,
            },
          ],
          value: props?.userProfileData?.shippingInfo?.firstName || '',
          name: 'shippingFirstName',
          label: 'First Name',
        },
        shippingLastName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.lastName,
            },
          ],
          value: props.userProfileData?.shippingInfo?.lastName || '',
          name: 'shippingLastName',
          label: 'Last Name',
        },
        shippingAddress1: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your street address',
            },
          ],
          name: 'shippingAddress1',
          label: 'Address 1',
          value: props.userProfileData?.shippingInfo?.address1 || '',
        },
        shippingAddress2: {
          rules: [],
          name: 'shippingAddress2',
          label: 'Address 2',
          value: props.userProfileData?.shippingInfo?.address2 || '',
        },
        shippingCity: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your city',
            },
          ],
          name: 'shippingCity',
          label: 'City',
          value: props.userProfileData?.shippingInfo?.city || '',
        },
        shippingState: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select your state',
            },
          ],
          name: 'shippingState',
          label: 'State',
          value: props.userProfileData?.shippingInfo?.state || '',
        },
        shippingZip: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your zipcode',
            },
            {
              rule: v8n().minLength(5),
              message: 'Zipcode required minimum five character',
            },
          ],
          name: 'shippingZip',
          label: 'Zip',
          value: props.userProfileData?.shippingInfo?.zip || '',
        },
        shippingUnit: {
          rules: [],
          name: 'shippingUnit',
          label: 'Unit',
          value: props.userProfileData?.shippingInfo?.unit || '',
        },
      },
    };
  }
  componentDidMount() {
    this.generatePassword(false);
  }

  handleFieldChanged = (e) => {
    let addUserDetail = { ...this.state.addUserDetail };
    const { name, value } = e.target;
    if (name === 'userTypeId' && Number(value) === Enums.NUMERIC_VALUE.ONE) {
      this.handleSingleSelectChange({ value, label: 'US Realty' }, 'companies');
    }
    addUserDetail[name].value = value;
    this.setState({ addUserDetail: { ...addUserDetail } });
  };

  handleAddress(e, addressType) {
    const { name, value } = e.target;
    let updateAddress = { ...this.state[addressType] };
    updateAddress[name].value = value;
    updateAddress[name].error = !value ? updateAddress[name].rules[ZERO]?.message : '';
    this.setState({ [addressType]: { ...updateAddress } });
  }

  selectStateAndCounty(val, addressType, stateName) {
    const updatedValues = { ...this.state[addressType] };
    updatedValues[stateName].value = val;
    updatedValues[stateName].error = '';
    this.setState({ [addressType]: updatedValues });
  }

  handleFieldBlur = (e) => {
    this.validateFormField(e.target.name, e.target.value, 'addUserDetail');
  };

  handleAddressFieldBlur(e, addressType) {
    this.validateFormField(e.target.name, e.target.value, addressType);
  }

  validateFormField = (key, value, stateName) => {
    let addUserDetail = { ...this.state[stateName] };
    let isError = false;
    if (typeof value === 'string') {
      value = value.trim();
    }
    for (let i = 0; i < addUserDetail[key].rules.length; i++) {
      let rule = addUserDetail[key].rules[i];
      if (!rule.rule.test(value)) {
        addUserDetail[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (Number(this.state.addUserDetail?.userTypeId.value) === Enums.NUMERIC_VALUE.THIRTEEN) {
      if (!addUserDetail?.managingBrokerLicense?.value && stateName === 'addUserDetail') {
        addUserDetail['managingBrokerLicense'].error = validationMessages.managingBrokerLicense;
        isError = true;
      }
    }

    if (!isError) {
      addUserDetail[key].error = null;
    }

    this.setState({
      [stateName]: { ...addUserDetail },
      isError: Object.keys(addUserDetail).some(
        (k) => addUserDetail[k].error !== undefined && addUserDetail[k].error !== null
      ),
    });

    return isError;
  };

  getRequestOb(stateType) {
    const stateDetail = this.state[stateType];
    return {
      ...Object.keys(stateDetail).reduce((retVal, key) => {
        if (stateDetail[key].value) {
          retVal[key] = stateDetail[key].value;
        }
        return retVal;
      }, {}),
    };
  }
  
  updatedReqObj = (isBillingInfo, reqObj) => {
    return {
      firstName: isBillingInfo ? reqObj['billingFirstName'] : reqObj['shippingFirstName'],
      lastName: isBillingInfo ? reqObj['billingLastName'] : reqObj['shippingLastName'],
      address1: isBillingInfo ? reqObj['billingAddress1'] : reqObj['shippingAddress1'],
      address2: isBillingInfo ? reqObj['billingAddress2'] : reqObj['shippingAddress2'],
      city: isBillingInfo ? reqObj['billingCity'] : reqObj['shippingCity'],
      state: isBillingInfo ? reqObj['billingState'] : reqObj['shippingState'],
      zip: isBillingInfo ? reqObj['billingZip'] : reqObj['shippingZip'],
      unit: isBillingInfo ? reqObj['billingUnit'] : reqObj['shippingUnit'],
    };
  };

  handleAddUpdateUserDetails = () => {
    const { isSendMail, isPasswordGenerate, isAddressSame } = this.state;
    const { isNewUser, userProfileData, addUpdateNewUser } = this.props;
    const accountDetails = { ...this.getRequestOb('addUserDetail') };
    const billingAddress = this.getRequestOb('billingAddress');
    const shippingAddress = this.getRequestOb('shippingAddress');
    accountDetails['billingInfo'] = this.updatedReqObj(true, billingAddress);
    accountDetails['shippingInfo'] = !isAddressSame ? this.updatedReqObj(false, shippingAddress) : null;
    accountDetails['isAddressSame'] = isAddressSame;

    if (!this.isNewUser && !isPasswordGenerate) {
      delete accountDetails.newPassword;
    }

    if (!this.isNewUser) {
      accountDetails['IsPasswordReset'] = isPasswordGenerate;
      accountDetails['userId'] = userProfileData.userId;
      accountDetails['isActive'] = userProfileData.isActive;
      accountDetails['userId'] = userProfileData.userId;
      accountDetails['billingInfo']['id'] = userProfileData?.billingInfo?.id;
      if (!isAddressSame) {
        accountDetails['shippingInfo']['id'] = userProfileData?.shippingInfo?.id;
      }
    }
    accountDetails['sendMail'] = isSendMail;
    accountDetails.phoneNumber = convertPhone(accountDetails.phoneNumber);

    const userId = isNewUser ? Enums.NUMERIC_VALUE.ZERO : userProfileData.userId;
    addUpdateNewUser(userId, accountDetails, () => {
      goBack();
    });
  };

  checkErrorOnButtonClick(stateType) {
    let error;
    const obj = this.state[stateType];
    Object.keys(obj).forEach((keyName) => {
      const isErrorExist = this.validateFormField(keyName, obj[keyName].value, stateType);

      if (isErrorExist) {
        error = true;
      }
    });

    return error;
  }
  validateAndUpdateAccountDetails = (e) => {
    e.preventDefault();

    let isError = false;
    let isBillingError = false;
    let isShippingError = false;
    let isPasswordError = false;

    isError = this.checkErrorOnButtonClick('addUserDetail');
    isBillingError = this.checkErrorOnButtonClick('billingAddress');
    if (!this.state.isAddressSame) {
      isShippingError = this.checkErrorOnButtonClick('shippingAddress');
    }

    if (!isError && !isBillingError && !isShippingError && !isPasswordError) {
      this.handleAddUpdateUserDetails();
    }
    const { addUserDetail, shippingAddress, billingAddress } = this.state;

    if (addUserDetail?.userTypeId.value != 13) {
      Object.entries(addUserDetail).filter(([key]) => key !== 'managingBrokerLicense');
    }
    if (isShippingError) {
      getFocusInput(shippingAddress);
    }
    if (isBillingError) {
      getFocusInput(billingAddress);
    }
    if (isError) {
      getFocusInput(addUserDetail);
    }
  };

  generatePassword = (isClicked) => {
    if (isClicked) {
      this.setState({ isPasswordGenerate: true });
    }
    const addUserDetail = { ...this.state.addUserDetail };
    addUserDetail['newPassword'].value = generateRandomPassword(12);
    addUserDetail['newPassword'].error = null;
    this.setState({
      addUserDetail: { ...addUserDetail },
    });
  };

  handleSingleSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.addUserDetail };
    if (selectedOption) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = [selectedOption];
    this.setState({ addUserDetail: updatedValues });
  };

  switchToCustomerPortal = (userId) => this.props.switchCustomerPortal(userId, this.location);

  handleShippingCheckbox = () => {
    const { shippingAddress } = this.state;
    shippingAddress['shippingFirstName'].value = '';
    shippingAddress['shippingLastName'].value = '';
    shippingAddress['shippingAddress1'].value = '';
    shippingAddress['shippingAddress2'].value = '';
    shippingAddress['shippingCity'].value = '';
    shippingAddress['shippingState'].value = '';
    shippingAddress['shippingZip'].value = '';
    shippingAddress['shippingUnit'].value = '';
    this.setState({ isAddressSame: !this.state.isAddressSame, shippingAddress });
  };

  resetUserPasword = (email) => {
    this.props.resetUserPasword(email);
  };

  resendEmailConfirmation = (email) => {
    this.props.resendEmailConfirmation(email);
  };

  render() {
    const { userLookup, userProfileData, toggleStatus, resettingPassword, sendingConfirmation } = this.props;
    const { addUserDetail, isSendMail, billingAddress, shippingAddress, isAddressSame } = this.state;
    const updatedRoles =
      userLookup?.roles &&
      userLookup?.roles.length &&
      userLookup?.roles.filter((role) => role.key < Number(getLocalUserData()?.userTypeId));

    return (
      <React.Fragment>
        <div className="admin-inner-wrapper">
          <div className="admin-heading-wrapper with-right-buttons">
            <TitleContainer>
              <h2>{this.isNewUser ? 'Add New User' : 'Edit User'}</h2>
              {userProfileData.emailConfirmed ? (
                <ConfirmedLabel>
                  Email confirmed
                  <Icon style={{ marginLeft: '0.5rem' }} type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                </ConfirmedLabel>
              ) : (
                <UnconfirmedLabel>
                  Email unconfirmed
                  <Icon
                    style={{ marginLeft: '0.5rem' }}
                    type="exclamation-circle"
                    theme="twoTone"
                    twoToneColor="#ebb127"
                  />
                </UnconfirmedLabel>
              )}
            </TitleContainer>
            <div className="admin-heading-wrapper-right">
              {!this.isNewUser && (
                <>
                  {!userProfileData?.emailConfirmed && (
                    <button
                      className="ant-btn btn btn-primary admin-primary-btn"
                      onClick={() => this.resendEmailConfirmation(userProfileData.email)}
                    >
                      {sendingConfirmation ? (
                        <Spinner animation="border" size="sm" color={themes.usRealty.light} />
                      ) : (
                        'Resend email confirmation'
                      )}
                    </button>
                  )}
                  {userProfileData?.email && (
                    <button
                      className="ant-btn btn btn-primary admin-primary-btn"
                      onClick={() => this.resetUserPasword(userProfileData.email)}
                    >
                      {resettingPassword ? (
                        <Spinner animation="border" size="sm" color={themes.usRealty.light} />
                      ) : (
                        'Reset password'
                      )}
                    </button>
                  )}
                  {!this.isSmallerRole && (
                    <button
                      className="ant-btn btn btn-primary admin-primary-btn"
                      onClick={() => this.switchToCustomerPortal(userProfileData?.userId)}
                    >
                      Switch to Customer
                    </button>
                  )}
                  {!this.isSmallerRole && (
                    <button
                      className={`ant-btn btn btn-primary admin-primary-btn ${
                        userProfileData?.isActive ? 'delete-btn' : ''
                      }`}
                      id="edit-mode"
                      onClick={() =>
                        statusChangeModalPopup(userProfileData, userTableConfigs, () => {
                          toggleStatus(entityTypes.users, userProfileData?.userId);
                        })
                      }
                    >
                      {userProfileData?.isActive ? 'Deactivate' : 'Activate'}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={`admin-add-form-wrapper ${
              Number(getLocalUserData()?.userTypeId) <= userProfileData?.userTypeId ? 'disabled-input-form' : ''
            }`}
          >
            <form autoComplete="off">
              <RowFlex>
                <InputFlex
                  name="firstName"
                  title="First Name"
                  className="inputgap"
                  value={addUserDetail?.firstName?.value}
                  error={addUserDetail?.firstName?.error || (this.props?.errors?.firstName || [])[0]}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  name="lastName"
                  title="Last Name"
                  className="inputgap"
                  value={addUserDetail?.lastName?.value}
                  error={addUserDetail?.lastName?.error || (this.props?.errors?.lastName || [])[0]}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <RowFlex>
                <InputFlex
                  name="email"
                  title="Email Address"
                  className={`inputgap ${!this.isNewUser ? disabled_input : ''}`}
                  type="email"
                  value={addUserDetail?.email?.value}
                  error={addUserDetail?.email?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                ></InputFlex>
                <InputFlex
                  name="phoneNumber"
                  title="Phone Number"
                  className="inputgap"
                  type="tel"
                  value={addUserDetail?.phoneNumber.value}
                  error={addUserDetail?.phoneNumber.error}
                  mask={addUserDetail?.phoneNumber.mask}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <InputFlex
                options={
                  Number(getLocalUserData()?.userTypeId) <= userProfileData?.userTypeId
                    ? userLookup?.roles
                    : updatedRoles
                }
                name="userTypeId"
                title="Role"
                type="select"
                value={addUserDetail?.userTypeId.value}
                error={addUserDetail?.userTypeId?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                placeholder="Select a user role"
                className={`inputgap ${!this.isNewUser && this.isSmallerRole ? disabled_input : ''}`}
                isKeyPaired={true}
              />
              {Number(addUserDetail?.userTypeId.value) === Enums.NUMERIC_VALUE.THIRTEEN && (
                <InputFlex
                  name="managingBrokerLicense"
                  title="Managing Broker License"
                  className="inputgap"
                  value={addUserDetail?.managingBrokerLicense.value}
                  error={addUserDetail?.managingBrokerLicense?.error}
                  type="text"
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              )}
              <div className="admin-input-and-data-wrapper">
                <div className="admin-w-50">
                  <div className="admin-checkbox-wrapper">
                    <div className="admin-checkbox-label">Send User Notification</div>
                    <div className="admin-custom-checkbox">
                      <label>
                        <input
                          className="inputgap"
                          type="checkbox"
                          checked={isSendMail}
                          onChange={() =>
                            this.setState({
                              isSendMail: !this.state.isSendMail,
                            })
                          }
                        />
                        <span>Send the new user an email about their account.</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="for-custom-select user-company-select">
                <label>Source Company</label>
                <Select
                  styles={cursorStyles}
                  name="companies"
                  placeholder="Select Company"
                  options={userLookup?.companies}
                  value={addUserDetail?.companies?.value}
                  onChange={(e) => this.handleSingleSelectChange(e, 'companies')}
                  className={`admin-react-select ${addUserDetail?.companies?.error ? 'admin-react-select-error' : ''} ${
                    Number(addUserDetail?.userTypeId?.value) === Enums.NUMERIC_VALUE.ONE ? disabled_input : ''
                  }`}
                />
                {addUserDetail?.companies?.error && (
                  <span className="select-text-red">{validationMessages.companyRequired}</span>
                )}
              </div>
              <h3 className="admin-form-inner-heading">Billing Address Info</h3>
              <div className="add-edit-property-address address-margin-bottom">
                <AddressComponent
                  initialState={billingAddress}
                  isBillingAddress={true}
                  handleFieldChanged={(e) => this.handleAddress(e, 'billingAddress')}
                  handleFieldBlur={(e) => this.handleAddressFieldBlur(e, 'billingAddress')}
                  selectStateAndCounty={(val) => this.selectStateAndCounty(val, 'billingAddress', 'billingState')}
                />
              </div>
              <div className="admin-w-50">
                <div className="admin-checkbox-wrapper">
                  <div className="admin-checkbox-label">Shipping Address</div>
                  <div className="admin-custom-checkbox">
                    <label>
                      <input
                        className="inputgap"
                        type="checkbox"
                        name="isAddressSame"
                        checked={isAddressSame}
                        onChange={this.handleShippingCheckbox}
                      />
                      <span>Same as billing address</span>
                    </label>
                  </div>
                </div>
              </div>
              {!isAddressSame && (
                <>
                  <h3 className="admin-form-inner-heading">Shipping Address Info</h3>
                  <div className="add-edit-property-address address-margin-bottom">
                    <AddressComponent
                      initialState={shippingAddress}
                      handleFieldChanged={(e) => this.handleAddress(e, 'shippingAddress')}
                      handleFieldBlur={(e) => this.handleAddressFieldBlur(e, 'shippingAddress')}
                      selectStateAndCounty={(val) => this.selectStateAndCounty(val, 'shippingAddress', 'shippingState')}
                    />
                  </div>
                </>
              )}
              {userProfileData?.userId && !this.isNewUser && <AuditLog />}
              <CustomTextarea className="full-width-textarea">
                <label>Notes</label>
                <textarea
                  name="note"
                  type="textarea"
                  value={addUserDetail?.note?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </CustomTextarea>
              <div className="admin-form-footer">
                <div className="admin-btn-row">
                  <Button
                    type="submit"
                    className="btn btn-primary admin-primary-btn secondary-btn"
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn btn-primary admin-primary-btn"
                    onClick={this.validateAndUpdateAccountDetails}
                    disabled={this.isSmallerRole}
                  >
                    {this.isNewUser ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default connect(null, mapDispatchToProps)(AddUser);
