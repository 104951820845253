import React, { Fragment } from 'react';
import { NotFoundPage } from './notFoundPage';

class NotFoundContainer extends React.Component {

  render() {
    return (
      <Fragment>
        <NotFoundPage/>
      </Fragment>
    );
  }
}
export { NotFoundContainer };
