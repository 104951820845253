import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';


const AddressWrapper = styled.div`
  margin:0 0 25px;
  display:flex;
  flex:0.5;
  flex-direction:column;
  text-align:left;
`;

const InputWrapper = styled.div`
  margin: 0;
  border: 1px solid ${props => props.error ? "red" : "#dadada"};
  border-radius:6px;
  transition:border .25s;
  background:#fff;
  height: 47px;
  box-shadow: 0 2px 4px 0 rgba(4,66,96,.06);
  &:focus-within {
      border:1px solid ${props => props.error ? "red" : props.theme.primary};
  }
  &.error-form {
      & + .error-text {
          display: block;
      }
  }
  .select-arrow-box {
    position: relative;
    .select-down-arrow {
      position: absolute;
      right: 3px;
      top: 10px;
    }
  }
`;

const Input = styled.input`
  width:100%;
  font-size:16px;
  padding:0 8px;
  margin-top: 10px;
`;

const Results = styled.div`
  background:#fff;
  display:flex;
  flex-direction:column;
  text-align:left;
  position:absolute;
  width:100%;
  border:1px solid #f0f0f0;
  border-radius:0 0 4px 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .3);
`;

const Result = styled.a`
    padding:18px 20px;
    color:#242B26;
    z-index:1;
    border-bottom:1px solid #f0f0f0;
    ${props => props.isActive ? "background-color:#C9E2F3;" : ""}
    &:last-child {
        border:none;
    }
    &:hover {
        background:#f0f0f0;
        color:#242B26;
    }
    @media(max-width: 768px) {
      padding: 12px 10px;
      line-height: 24px;
      font-size: 15px;
    }
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
`;

const ErrorDiv = styled.div`
  font-size:14px;
  color: red;
  margin: 0;
  font-weight:400;
  flex:1;
`;

const DescriptionContent = styled.div`
  margin: 0;
`

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  text-transform: capitalize;
  margin-bottom: 10px;
  color:#242B26;
`;

const Description = styled.div`
  margin: 10px 0 0 0;
  font-size:12px;
  font-weight:200;
  flex:1;
  display: none;
`;


const google = window.google;
const autoService = new google.maps.places.AutocompleteService();
const placesService = new google.maps.places.PlacesService(document.createElement('div'));

export const BillingAddressSelector = ({
  handleAddressSelected,
  className,
  descriptionChildren,
  ...inputProps
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const timeout = useRef(null);

  const handleAddressChanged = (e) => {
    inputProps.onChange(e);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(searchAddresses, 500);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
      e.preventDefault();
    } else if (e.keyCode === 40 && currentIndex < predictions.length - 1) {
      setCurrentIndex(currentIndex + 1);
      e.preventDefault();
    } else if (e.keyCode === 13 || e.keyCode === 9) {
      if (predictions.length > 0) {
        onAddressSelected(e, currentIndex);
      }
      e.preventDefault();
    }
  }

  const onAddressSelected = (e, i) => {
    e.preventDefault();
    setIsLoading(true);
    inputProps.onChange({ 
      target: {
        name: inputProps.name,
        value: predictions[i].description
      }
    });
    placesService.getDetails({
      placeId: predictions[i].place_id
    }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        handleAddressSelected(place);
      } else {
        handleAddressSelected({});
      }
      setPredictions([])
      setIsLoading(false);
    });
  }

  const searchAddresses = async () => {
    setIsLoading(true);
    await autoService.getPlacePredictions({
      input: inputProps.value,
      types: ["address"],
    },
      (predictions, status) => {
        if (status === "OK" && predictions.length > 0) {
          setPredictions(predictions)
        } else {
          setPredictions([])
        }
      });
      setIsLoading(false);
  }

  return (
        <AddressWrapper className={className}>
          <div className="custom-address-selector">
            <Label htmlFor={inputProps.name}>{inputProps.title}</Label>
            <InputWrapper error={inputProps.error} className={inputProps.error ? "error-form" : ""}>
              <Input
                {...inputProps}          
                onChange={handleAddressChanged}
                onKeyDown={handleKeyDown}
              />
              <Description id={`${inputProps.name}-hint`} className="error-text">
                <DescriptionContent>
                  {descriptionChildren}
                </DescriptionContent>
                {inputProps.error &&
                  <ErrorDiv>{inputProps.error}</ErrorDiv>
                }
            </Description>
            </InputWrapper>
          {predictions.length > 0 ? (
            <Results>
              {predictions.map((p, i) => (<Result key={i} href="#"
                isActive={currentIndex === i}
                onClick={(e) => onAddressSelected(e, i)}>{p.description}</Result>))}
            </Results>
          ):(isLoading && (
              <Results>
                <Loader animation="border"/>
              </Results>
          ))}
        </div>
      </AddressWrapper>
  );
}
