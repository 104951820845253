import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Card, Col, Row, Table } from 'antd';
import { useEffect } from 'react';
import {
  dateFormat,
  entityTypes,
  pageSizeOptions,
  staticMessages,
  tableItemsPerPage,
} from '../../../../../Constants/CommonConstants';
import SearchPanel from '../Shared/SearchPanel.js';
import Routes from '../../../../../Constants/Routes.js';
import { editPageAction, momentDateFormat } from '../../../../../Utilities/commonFunctions';
import CurrencyInput from 'react-currency-input-field';
import { cashOfferCreators } from '../../../../../Actions/V2/CashOfferActions';
import {
  cashOffersSelector,
  cashOffersTotalCountSelector,
  cashOfferFiltersSelector,
} from '../../../../../Reducers/V2/CashOfferReducer';
import { useTitle } from '../../../../../Utilities/Hooks';

const tableConfigs = {
  columns: [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      render: (address, record) => (
        <Link
          to={
            record.draftListingId
              ? `${Routes.ONBOARDING_V2}?listing_id=${record.draftListingId}`
              : editPageAction(record, entityTypes.properties)
          }
        >
          {address}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      render: (email, record) => <Link to={editPageAction(record, entityTypes.users)}>{email}</Link>,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: true,
    },
    {
      title: 'User',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (price) => (
        <CurrencyInput
          value={price || 0}
          decimalScale={0}
          fixedDecimalScale={true}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      ),
    },
    {
      title: 'Property Type',
      dataIndex: 'propertyType',
      key: 'propertyType',
      sorter: true,
    },
    {
      title: 'Timeline',
      dataIndex: 'timeline',
      key: 'timeline',
      sorter: true,
    },
    {
      title: 'Reason for selling',
      dataIndex: 'sellReason',
      key: 'sellReason',
      sorter: true,
    },
    {
      title: 'Year built',
      dataIndex: 'builtPeriod',
      key: 'builtPeriod',
      sorter: true,
    },
    {
      title: 'Property Condition',
      dataIndex: 'condition',
      key: 'condition',
      sorter: true,
    },
    {
      title: 'Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      sorter: true,
      render: (dateCreated) => <span>{momentDateFormat(dateCreated, dateFormat.DATEMODIFIEDFROMAT)}</span>,
    },
  ],
  filters: [],
  deleteTitle: 'Delete Product',
  deleteMessage: `${staticMessages.deleteConfirmation} product?`,
  addPageUrl: `${Routes.ADMIN_ADD_PRODUCT}`,
  addButtonText: 'Add Cash Offer',
  searchPlaceHolder: 'Search Cash Offers',
};

const CashOfferList = () => {
  const dispatch = useDispatch();
  const cashOffers = useSelector(cashOffersSelector);
  const filters = useSelector(cashOfferFiltersSelector);
  const totalCount = useSelector(cashOffersTotalCountSelector);

  useEffect(() => {
    dispatch(cashOfferCreators.getCashOffers(filters));
  }, [filters.search, filters.page, filters.limit, filters.sortBy, filters.desc]);

  useTitle('Cash-Offers-Admin | Unreal Estate');

  const onTableChange = (pagination, tablefilters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const sorterParams = {};

    if (field && order) {
      sorterParams.sortBy = field;
      sorterParams.desc = order === 'descend';
    }

    dispatch(
      cashOfferCreators.setCashOfferFilters({
        ...filters,
        ...sorterParams,
        page: current,
        limit: pageSize,
      })
    );
  };

  const showTotal = (total, range) => {
    return `Showing ${range[0]} - ${range[1]} of ${total}`;
  };
  const onSearch = (value) => dispatch(cashOfferCreators.setCashOfferFilters({ ...filters, search: value }));

  return (
    <>
      <SearchPanel placeholder={'Search by Email'} onSearch={onSearch}></SearchPanel>
      <Card>
        <Row style={{ marginTop: 20 }}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 24, offset: 0 }}
          >
            <Table
              className="listing-product-table"
              size={'default'}
              columns={tableConfigs.columns}
              dataSource={cashOffers}
              onChange={onTableChange}
              pagination={{
                total: totalCount,
                defaultPageSize: tableItemsPerPage,
                defaultCurrent: 1,
                showTotal: showTotal,
                pageSizeOptions: pageSizeOptions,
                showSizeChanger: totalCount > tableItemsPerPage,
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CashOfferList;
