import { gql } from "graphql-request";
import { request } from "graphql-request";

import AppConsts from "../Constants/AppConsts";
const  { graphqlApiUrl } = AppConsts;

export async function graphqlRequest({ query, variables }){
  if (!graphqlApiUrl) {
    throw new Error("No CMS URI provided");
  }

  return request(graphqlApiUrl, query, variables);
}

export const navbarFragment = gql`
  fragment NavbarFragment on Navbar {
    announcementBarText
    navItems {
      name
      url
    }
  }
`;

export const footerFragment = gql`
  fragment FooterFragment on Footer {
    email
    phoneNumber
    sections {
      title
      links {
        name
        url
      }
    }
    footerLegal {
      html
    }
    copyright
    companyName
    socialIcons {
      image {
        asset {
          url
        }
        altTag
      }
      link {
        url
        name
      }
    }
  }
`;


const NAVBAR_AND_FOOTER_QUERY = gql`
query NavbarAndFooterQuery($type: String!) {
  navbar(where: { type: $type }) {
      ...NavbarFragment
    }
    
    footer(where: { type: $type }) {
      ...FooterFragment
    }
  }
    
  ${navbarFragment}
  ${footerFragment}
`;

export const getNavbarAndFooterContent = async () => {
  const data = (await graphqlRequest({ query: NAVBAR_AND_FOOTER_QUERY, variables: { type: "main" } }));
  return data;
};