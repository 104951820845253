import { buyerLeadsActions } from '../../Actions/V2/Admin/BuyerLeadsActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const buyerLeadsFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    sortBy: null,
    desc: null,
    dateCreatedFrom: null,
    dateCreatedTo: null,
    state: null,
    PropertyTypeId: null,

}

const initialState = {
    buyerLeads: [],
    totalCount: 0,
    filters: buyerLeadsFiltersDefault
};

export const buyerLeadsReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case buyerLeadsActions.SET_BUYER_LEADS_FILTERS:
            return { ...state, filters: action.payload };
        case buyerLeadsActions.GET_BUYER_LEADS_RESOLVED:
        case buyerLeadsActions.GET_BUYER_LEADS_FAILED:
            return { ...state, buyerLeads: action.payload.results, totalCount: action.payload.total };
        case buyerLeadsActions.CLEAR_BUYER_LEADS_FILTERS:
                return { ...state, filters: { ...buyerLeadsFiltersDefault } };
        default:
            return state;
    }
};

export const buyerLeadsFiltersSelector = state => state.buyerLeads.filters;
export const buyerLeadsSelector = state => state.buyerLeads.buyerLeads;
export const buyerLeadsTotalCountSelector = state => state.buyerLeads.totalCount;