import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { listingStatuses } from '../../../Constants/CommonConstants';
import { themes } from '../../../Constants/Themes';
import { RemovePhotoIcon } from '../../Shared/Components/Icons/RemovePhotoIcon';
import { FiltersIcon } from '../../Shared/Components/Icons/FiltersIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 1.5rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    flex-direction: row;
    padding-left: 0;
  }
`;

const SearchInputContainer = styled.div`
      width: 19rem;
      min-width: 0px;
      position: relative;
      font-size: 1rem;
      height: 2.5rem;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      color: ${({ theme }) => theme.dark};
      margin-bottom: 1rem;
      flex-grow: 1;

    input {
      width: 88%;
    }  

    input::placeholder {
      color: ${({ theme }) => theme.colors.mouseballgray[200]};
    }

    i {
      left: 1px;
      top: 1px;
      height: 38px;
      background-color: white;
      width: 35px;
      border-radius: 4px 0 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.mouseballgray[100]};
    }

    @media(min-width: ${({ theme }) => theme.breakpoints.md}){
      margin-bottom: 0rem;
      margin-right: 1rem;
      padding-left: 0.25rem;
    }
  `;

  const InputClearButton = styled.button`
  right: 0.5rem;
  position: absolute;
  width: 2rem;
  height: 2.8rem;
  top: 0;
  background-color: inherit;
`;

const StatusFilterStyles = {
  option: (styles) => {
    return {
      ...styles,
      cursor: "pointer",
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      borderRadius: '1.5rem',
    };
  },
  control: (styles) => ({
    ...styles,
    color: themes.usRealty.dark,
    border: `1px dashed ${themes.usRealty.dark}`,
    width: '15rem',
    minHeight: '2.5rem',
    borderRadius: '1.5rem',
    paddingLeft: '8px',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    paddingRight: '8px',
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FiltersIcon />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator = () => null;

export const ListingsListFilters = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');
  const [statusFilters, setStatusFilters] = useState([]);

  const setSearch = (e) => {
    const value = e.target.value.trim();
    setSearchString(value);
    dispatch(dashboardActionCreators.setListingListFilters({
      listingListSearch: value,
      statusFilters,
    }))
  };

  const handleSearchInputKeyDown = (e) => {
    if (e.keycode === 13) {
      dispatch(dashboardActionCreators.setListingListFilters({
        listingListSearch: searchString,
        statusFilters,
      }))
    }
  };

  const handleSetetStatusFilters = (val) => {
    setStatusFilters(val)
    dispatch(dashboardActionCreators.setListingListFilters({
      listingListSearch: searchString,
      statusFilters: val,
    }))
  };

  const clearSearch = () => {
    setSearchString('');
    dispatch(dashboardActionCreators.setListingListFilters({
      listingListSearch: '',
      statusFilters,
    }))
  }


  return (
    <Wrapper>
      <SearchInputContainer>
        {searchString && (
          <InputClearButton onClick={clearSearch}>
            <RemovePhotoIcon />
          </InputClearButton>
        )}
        <input
          type="search"
          id="searchinput"
          value={searchString}
          placeholder="Search City, Address, State, ZIP"
          onChange={setSearch}
          onKeyDown={handleSearchInputKeyDown}
        />
        <i className="fa fa-search" aria-hidden="true"></i>
      </SearchInputContainer>
      <Select
        styles={StatusFilterStyles}
        placeholder='Filter by Status'
        value={statusFilters}
        isMulti
        components={{
          DropdownIndicator,
          IndicatorSeparator,
        }}
        onChange={handleSetetStatusFilters}
        options={listingStatuses}
        menuPosition='fixed'
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={true}
      />
    </Wrapper>
  );

};