import React from 'react';
import styled from 'styled-components';
import v8n from 'v8n';
import { injectStripe, CardElement } from 'react-stripe-elements';
import CurrencyFormat from 'react-currency-format';
import { RegionDropdown } from 'react-country-region-selector';
import ShippingAddress from './ShippingAddress';
import { PRow, InputFlex, CustomButton, HeadTextWrap, Heading, HeadText, DetailsWrapper } from '../../../Assets/Style/commonStyleComponents';
import { roleType, validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import { onboardingService } from '../../../Services/OnboardingService';
import { getCurrentUserData } from '../../../Utilities/commonFunctions';
import AppConsts from '../../../Constants/AppConsts';
import { gTag } from '../../../Utilities/utils';

const Label = styled.label`
  display: block;
  font-size: 15px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  text-transform: capitalize;
  margin-bottom: 24px;
  @media(max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Span = styled.span`
  display:block;
  font-size:15px;
  font-weight:400;
  @media(max-width: 768px) {
    margin-bottom: 25px;
  }
`;

const CardInput = styled.div`
  display:block;
  margin-bottom: 25px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-transform: capitalize;
    margin-bottom: 10px;
  }
`;

const CardElementStyled = styled(CardElement)`
  margin:0 0 10px;
  border: 1px solid ${props => props.error ? "#FF7178" : "#dadada"};
  border-radius:6px;
  padding:14px;
  transition:border .25s;
  background:#fff;
  height: 46px;
  &:focus-within {
      border:1px solid ${props => props.error ? "#FF7178" : props.theme.primary};
  }
`;

const ErrorDiv = styled.div`
  font-size: 14px;
  color: red;
  margin: 0;
  font-weight: 400;
  display: block;
`;

class BillingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.isVoucherApplied = !!onboardingService.getLocal("selectedPlan")?.voucherApplied;
    this.isInstitutionalRole = getCurrentUserData().role === roleType.INSTITUTIONAL;
    this.isConciergeRole = getCurrentUserData().role === roleType.ConciergeFree || getCurrentUserData().role === roleType.ConciergePaid;
    this.isPriceZero=this.props.selectedPlan.priceUpFront===Enums.NUMERIC_VALUE.ZERO;
    this.state = {
      isCardUntouched: !this.isVoucherApplied || !this.isInstitutionalRole || !this.isPriceZero || !this.isConciergeRole,
      cardError: undefined,
      isAddressSame: true,
      isSameAsPropertyAddress: true,
      fields: {
        cardholderName: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter the full name on the credit card"
          }],
          value: `${props.firstName} ${props.lastName}`
        },
        address1: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter your street address"
          }],
          value:  this.isVoucherApplied ? props.addressFromLocal.addressAddress1:props.addressFromLocal.address1  || ""
        },
        address2: {
          rules: [],
          value:  this.isVoucherApplied ? props.addressFromLocal.addressAddress2:props.addressFromLocal.address2  || ""
        },
        city: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter your billing city"
          }],
          value:  this.isVoucherApplied ? props.addressFromLocal.addressCity:props.addressFromLocal.city || ""
        },
        country: {
          rules: [],
          value:  this.isVoucherApplied ? "US":props.addressFromLocal.country || "US" 
        },
        state: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter your billing state"
          },
          {
            rule: v8n().length(2),
            message: "Please enter a state abberviation"
          }],
          mask: [/[a-zA-Z]/, /[a-zA-Z]/],
          pipe: (c) => c.toUpperCase(),
          value:  this.isVoucherApplied ? props.addressFromLocal.addressState:props.addressFromLocal.state || ""
        },
        zip: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter your billing zip"
          }],
          value:  this.isVoucherApplied ? props.addressFromLocal.addressZip:props.addressFromLocal.zip  || ""
        },
        shippingFirstName: {
          rules: [],
          value: props.shippingAddress.firstName || ""
        },
        shippingLastName: {
          rules: [],
          value: props.shippingAddress.lastName || ""
        },
        shippingAddress1: {
          rules: [],
          value: props.shippingAddress.address1 || ""
        },
        shippingCity: {
          rules: [],
          value: props.shippingAddress.city || ""
        },
        shippingAddress2: {
          rules: [],
          value: props.shippingAddress.address2 || ""
        },
        shippingCountry: {
          rules: [],
          value: props.shippingAddress.country || "US"
        },
        shippingState: {
          rules: [],
          value: props.shippingAddress.state || ""
        },
        shippingZip: {
          rules: [],
          value: props.shippingAddress.zip || ""
        }
      }
    };

    
  }

  handleFieldChanged = (e) => {
    let billingFields = { ...this.state.fields };
    billingFields[e.target.name].value = e.target.value;
    const { name, value } = e.target;
    if (!this.state.isAddressSame) {
      billingFields['shippingFirstName'].error = this.checkIfValueExist(
        'shippingFirstName',
        billingFields['shippingFirstName'].value,
      );
      billingFields['shippingLastName'].error = this.checkIfValueExist(
        'shippingLastName',
        billingFields['shippingLastName'].value,
      );
      billingFields['shippingAddress1'].error = this.checkIfValueExist(
        'shippingAddress1',
        billingFields['shippingAddress1'].value,
      );
      billingFields['shippingCity'].error = this.checkIfValueExist(
        'shippingCity',
        billingFields['shippingCity'].value,
      );
      billingFields['shippingCountry'].error = this.checkIfValueExist(
        'shippingCountry',
        billingFields['shippingCountry'].value,
      );
      billingFields['shippingState'].error = this.checkIfValueExist(
        'shippingState',
        billingFields['shippingState'].value,
      );
      billingFields['shippingZip'].error = this.checkIfValueExist(
        'shippingZip',
        billingFields['shippingZip'].value,
      );
    }
    billingFields[name].value = value;
    this.setState({ fields: { ...billingFields } });
  }

  handleFieldBlur = (e) => {
    this.validateBillingField(e.target.name, e.target.value);
  }

  validateBillingField = (key, value) => {
    let fields = { ...this.state.fields };

    let isError = false;

    for (let i = 0; i < fields[key].rules.length; i++) {
      let rule = fields[key].rules[i];

      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!this.state.isAddressSame) {
      if (!fields['shippingFirstName'].value) {
        fields['shippingFirstName'].error = validationMessages.required;
        isError = true;
      }
      if (!fields['shippingLastName'].value) {
        fields['shippingLastName'].error = validationMessages.required;
        isError = true;
      }
      if (!fields['shippingAddress1'].value) {
        fields['shippingAddress1'].error = validationMessages.required;
        isError = true;
      }
      if (!fields['shippingCity'].value) {
        fields['shippingCity'].error = validationMessages.required;
        isError = true;
      }
      if (!fields['shippingState'].value) {
        fields['shippingState'].error = validationMessages.required;
        isError = true;
      }
      if (fields['shippingZip'].value) {
        const isZipValid = v8n().minLength(Enums.NUMERIC_VALUE.FIVE).test(fields['shippingZip'].value);
        if (!isZipValid) {
          fields['shippingZip'].error = 'Zipcode required minimum five character';
          isError = true;
        }
      } else {
        fields['shippingZip'].error = validationMessages.required;
        isError = true;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    this.setState({ fields: { ...fields }, isError: Object.keys(fields).some(k => fields[k].error !== undefined && fields[k].error !== null) });

    return isError;
  }

  handleCardDetailsChanged = (e) => {
    if (e.error || e.empty) {
      this.setState({ cardError: e.error || "Please enter your credit card information", isCardUntouched: false });
    } else {
      this.setState({ cardError: undefined, isCardUntouched: false });
    }
  }

  handleSaveBillingDetails = (e) => {
    e.preventDefault();

    let isError = false;

    //Validate our fields
    Object.keys(this.state.fields).forEach((key) => {
      const isErrorExist = this.validateBillingField(key, this.state.fields[key].value);
      if (isErrorExist) {
        isError = true;
      }
    })

    let data = {
      ...(Object.keys(this.state.fields).reduce((retVal, key) => {
        retVal[key] = this.state.fields[key].value;
        return retVal;
      }, {}))
    };

    if (!isError) {
      //Send conversion info to gtag
       gTag('event', 'conversion', {
        send_to: AppConsts.gtagCodes.conversionId,
        value: 1.00,
        currency: 'USD',
        transaction_id: ''
      })
    }

    if (!isError && this.isVoucherApplied) {
      const voucherDetail = onboardingService.getLocal("redeemVoucherDetail");
      const voucherObj = {
        voucherId: voucherDetail.voucherId,
        voucherCode: voucherDetail.voucherCode,
      }
      this.props.handleSaveBillingDetails({ ...data, isOrderByVoucher: this.isVoucherApplied, isComplete: true, isAddressSame: this.state.isAddressSame, voucherInfo: { ...voucherObj } })
    } else if (!isError && this.isInstitutionalRole || this.isConciergeRole) {
      this.props.handleSaveBillingDetails({ ...data, IsOrderByInstitutionalUser: this.isInstitutionalRole || this.isConciergeRole, IsOrderByUnbounceUser: this.isConciergeRole, isComplete: true, isAddressSame: this.state.isAddressSame})
    }else if (!isError && this.props.selectedPlan.priceUpFront===Enums.NUMERIC_VALUE.ZERO) {
      this.props.handleSaveBillingDetails({ ...data, IsOrderByInstitutionalUser: this.isInstitutionalRole, IsOrderByUnbounceUser: this.isConciergeRole, isComplete: true, isAddressSame: this.state.isAddressSame})
    } else if (!isError && this.state.cardError === undefined && !this.state.isCardUntouched) {
      //Get payment token
      this.props.stripe.createToken({
        name: data.cardholderName,
        address_line1: data.address1,
        address_line2: data.address2,
        address_city: data.city,
        address_state: data.state,
        address_zip: data.zip,
        address_country: "US"
      })
        .then((result) =>
          this.props.handleSaveBillingDetails({ ...data, cardToken: result.token.id, isComplete: true, isAddressSame: this.state.isAddressSame })
        );

    }
    else {
      if (this.state.isCardUntouched) {
        this.setState({ cardError: { message: "Please enter your credit card information" } });
      }
    }
  }

  selectCountry = (val, fieldName) => {
    const updatedValues = { ...this.state.fields };
    updatedValues[fieldName].value = val;
    if (fieldName === 'shippingCountry') {
      updatedValues['shippingState'].value = '';
    } else {
      updatedValues['state'].value = '';
    }
    updatedValues[fieldName].error = '';
    this.setState({ fields: updatedValues });
  }

  selectStateAndCounty = (val, fieldName) => {
    const updatedValues = { ...this.state.fields };
    updatedValues[fieldName].value = val;
    updatedValues[fieldName].error = '';
    this.setState({ fields: updatedValues });
  }

  checkIfValueExist = (type, value) => {
    let updatedValues = { ...this.state.fields };
    if (!value) {
      return (updatedValues[type].error = validationMessages.required);
    }
    return null;
  };

  handlePropertyCheckbox = (e) => {
    let updateValues = { ...this.state.fields };
    const { addressFromLocal, billingAddress } = this.props;
    const { checked } = e.target;
    if (checked) {
      updateValues['address1'].value = this.isVoucherApplied ? addressFromLocal.addressAddress1:addressFromLocal.address1;
      updateValues['address2'].value = this.isVoucherApplied ? addressFromLocal.addressAddress2:addressFromLocal.address2;
      updateValues['state'].value = this.isVoucherApplied ? addressFromLocal.addressState:addressFromLocal.state;
      updateValues['city'].value = this.isVoucherApplied ? addressFromLocal.addressCity:addressFromLocal.city;
      updateValues['zip'].value = this.isVoucherApplied ? addressFromLocal.addressZip:addressFromLocal.zip;
      updateValues['country'].value = this.isVoucherApplied ? "US":addressFromLocal.country || 'US';
      updateValues['address1'].error = '';
      updateValues['address2'].error = '';
      updateValues['country'].error = '';
      updateValues['state'].error = '';
      updateValues['city'].error = '';
      updateValues['zip'].error = '';
    } else {
      updateValues['address1'].value = billingAddress?.address1 || '';
      updateValues['address2'].value = billingAddress?.address2 || '';
      updateValues['state'].value = billingAddress?.state || '';
      updateValues['city'].value = billingAddress?.city || '';
      updateValues['country'].value = billingAddress?.country || 'US';
      updateValues['zip'].value = billingAddress?.zip || '';
    }
    this.setState({ fields: updateValues, isSameAsPropertyAddress: checked });
  };

  handleShippingCheckbox = (e) => {
    let updateValues = { ...this.state.fields };
    const { checked } = e.target;
    if (checked) {
      updateValues['shippingFirstName'].error = '';
      updateValues['shippingLastName'].error = '';
      updateValues['shippingAddress1'].error = '';
      updateValues['shippingCity'].error = '';
      updateValues['shippingCountry'].error = '';
      updateValues['shippingState'].error = '';
      updateValues['shippingZip'].error = '';
    }
    this.setState({ fields: updateValues, isAddressSame: checked });
  };

  render() {
    const { fields, isAddressSame, isSameAsPropertyAddress } = this.state;
    return (
      <React.Fragment>
        <HeadTextWrap>
          <Heading>{this.isConciergeRole ? "Checkout Info" : "Payment Info"}</Heading>
          <HeadText>
            Once you add your payment information, you will be able to enter a full descriptiom of your home, upload photos, and enter any other property details.
          </HeadText>
        </HeadTextWrap>
        <DetailsWrapper className={(this.isVoucherApplied || this.isInstitutionalRole) ? "after-redeem-payment-info" : ''}>
          <form onSubmit={this.handleSaveBillingDetails} autoComplete="off">
            <PRow className="payment-top-row">
              {
                (!this.isVoucherApplied && !this.isInstitutionalRole && !this.isConciergeRole) &&
                <div className="pcol-6">
                  <InputFlex
                    name="cardholderName"
                    title="Cardholder Name"
                    value={fields.cardholderName.value}
                    error={fields.cardholderName.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap"
                  />
                </div>
              }
              {
                (!this.isVoucherApplied && !this.isInstitutionalRole && !this.isConciergeRole) &&
                <div className="pcol-6">
                  <PRow>
                    <div className="pcol-6">
                      <Label>Product</Label>
                      <Span>{this.props.selectedPlan.title} Plan</Span>
                    </div>
                    <div className="pcol-6">
                      <Label>Price</Label>
                      <Span>
                        <CurrencyFormat
                          value={this.props.selectedPlan.priceUpFront}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </Span>
                    </div>
                    <div className="pcol-6 desk-none">
                      <Label>Total</Label>
                      <Span>
                        <CurrencyFormat
                          value={this.props.selectedPlan.priceUpFront}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </Span>
                    </div>
                  </PRow>
                </div>
              }
            </PRow>
            <div className='admin-w-50'>
              <div className='admin-checkbox-wrapper'>
                <div className='admin-checkbox-label'>
                  Billing Address
                </div>
                <div className='admin-custom-checkbox'>
                  <label>
                    <input
                      className='inputgap'
                      type='checkbox'
                      name='isSameAsPropertyAddress'
                      checked={isSameAsPropertyAddress}
                      onChange={this.handlePropertyCheckbox}
                    />
                    <span>Same as property address</span>
                  </label>
                </div>
              </div>
            </div>
            <PRow>
              <div className="pcol-6">
                <PRow>
                  <InputFlex
                    name="address1"
                    title="Address"
                    value={fields.address1.value}
                    error={fields.address1.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap pcol-100"
                  />
                  <InputFlex
                    name="address2"
                    title="Address 2"
                    value={fields.address2.value}
                    error={fields.address2.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap pcol-100"
                  />
                </PRow>
              </div>
              {
                (!this.isVoucherApplied && !this.isInstitutionalRole && !this.isConciergeRole) &&
                <div className="pcol-6 res-none">
                  <Label>Total</Label>
                  <Span>
                    <CurrencyFormat
                      value={this.props.selectedPlan.priceUpFront}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </Span>
                </div>
              }
            </PRow>
            <PRow>
              <div className="pcol-6">
                <PRow>
                   <InputFlex
                    name="city"
                    title="City"
                    value={fields.city.value}
                    error={fields.city.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap pcol-50"
                  />
                  <div className="pcol-6 for-custom-select">
                    <label>State</label>
                    <RegionDropdown
                      blankOptionLabel="No country selected."
                      defaultOptionLabel="Now select a region."
                      countryValueType="short"
                      valueType="short"
                      country='US'
                      blacklist={{US:['FM','GU','MH','MP','PW','PR','VI','AA','AE','AP']}}
                      value={fields.state.value || fields.state}
                      onChange={(e) => this.selectStateAndCounty(e, 'state')}
                      error={fields.state.error}
                      className={fields.state.error ? "custom-select error-input" : 'custom-select'}
                    />
                  </div>
                </PRow>
                <PRow>
                 
                  <InputFlex
                    name="zip"
                    title="Zip"
                    value={fields.zip.value}
                    error={fields.zip.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap pcol-50"
                  />
                </PRow>
                <div className='admin-w-50'>
                  <div className='admin-checkbox-wrapper'>
                    <div className='admin-checkbox-label'>
                      Shipping Address
                    </div>
                    <div className='admin-custom-checkbox'>
                      <label>
                        <input
                          className='inputgap'
                          type='checkbox'
                          name='isAddressSame'
                          checked={isAddressSame}
                          onChange={this.handleShippingCheckbox}
                        />
                        <span>Same as billing address</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {
                (!this.isVoucherApplied && !this.isInstitutionalRole && !this.isConciergeRole) && this.props.selectedPlan.priceUpFront!==Enums.NUMERIC_VALUE.ZERO &&
                <div className="pcol-6">
                  <CardInput flex={1}>
                    <Label className="for-card-label">Credit Card</Label>
                    <CardElementStyled
                      style={{ base: { fontWeight: 400, fontSize: "16px", fontFamily: 'Montserrat' } }}
                      onChange={this.handleCardDetailsChanged}
                    />
                    {this.state.cardError &&
                      <ErrorDiv>{this.state.cardError.message}</ErrorDiv>
                    }
                  </CardInput>
                </div>
              }
            </PRow>
            {
              !isAddressSame &&
              <>
                <PRow>
                  <InputFlex
                    name="shippingFirstName"
                    title="First Name"
                    value={fields.shippingFirstName.value}
                    error={fields.shippingFirstName.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap pcol-50"
                  />
                  <InputFlex
                    name="shippingLastName"
                    title="Last Name"
                    value={fields.shippingLastName.value}
                    error={fields.shippingLastName.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap pcol-50"
                  />
                </PRow>
                <ShippingAddress
                  initialState={fields}
                  handleFieldChanged={this.handleFieldChanged}
                  handleFieldBlur={this.handleFieldBlur}
                  selectCountry={this.selectCountry}
                  selectStateAndCounty={this.selectStateAndCounty}
                />
              </>
            }
            <CustomButton type="submit" className="block" onClick={this.handleSaveBillingDetails}>Place Order</CustomButton>
          </form>
        </DetailsWrapper>
      </React.Fragment>
    );
  }
}

export default injectStripe(BillingDetails);
