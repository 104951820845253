import { actions } from '../Actions/AdminActions';
import { entityTypes } from '../Constants/CommonConstants';
import { Entity } from '../Constants/EntityTypes';
import { userService } from '../Services/UserService';
import { getLocalUserData } from '../Utilities/commonFunctions';

const initialState = {
  isFetching: false,
  activeEntity: Entity.Types.ORDER,
  editingEntity: {},
  activeSet: [],
  photoProgress: [],
  totalSet: 0,
  activeConfig: Entity.Config[Entity.Types.ORDER],
  entities: {
    orders: [],
    'order-products': [],
    users: [],
    mls: [],
    listings: [],
    products: [],
    categories: [],
    company: [],
  },
  isError: false,
  lookup: {
    users: [],
    MlsCategory: [],
    ProductFeatureType: [],
    ProductType: [],
    PropertyType: {},
    UserCreationType: [],
    UserType: [],
    Softwares: [],
    ManagingBrokers: [],
    companies: [],
    fieldPrefillTypes: [],
    formTypes: [],
    layoutType: [],
    mlsAbbreviations: [],
    mlsCategories: [],
    states: [],
    mlsFormTypes: [],
    submissionStatusTypes: [],
    formStatuses: [],
  },
  selectedItemData: {
    photos: [],
  },
  uploadFormFileProgress: 0,
  isFormFileUploading: false,
  uploadedFormFileDetail: {},
  overlayFormDetail: {},
  userDetails: { ...(userService.getLocalUser() || {}) },
  auditLogs: {},
  landingPage: [],
  mlsFormPreviewDetail: {},
  collapseMenu: false,
  resettingPassword: false,
  sendingConfirmation: false,
  matchedUsersList: [],
  fetchingAverageTimeToList: false,
  averageTimeToList: null,
};

export const adminReducer = (state = initialState, action) => {
  state = state || initialState;

  let tmpState, item, photos, newPhoto, photoProgress;
  switch (action.type) {
    case actions.GETALL_REQUESTED:
      state.activeEntity = action.payload;
      state.activeSet = state.entities[action.payload];
      return { ...state, activeEntity: action.payload, isFetching: true };

    case actions.GETALL_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isFetching: false };

      tmpState = action.payload.value;
      state.entities[action.payload.entity] = tmpState.results.slice();
      const totalSet = tmpState.total;
      return {
        ...state,
        activeSet: tmpState.results,
        totalSet,
        isFetching: false,
      };

    case actions.GET_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isFetching: false };

      return {
        ...state,
        editingEntity: action.payload.value,
        isFetching: false,
      };

    case actions.UPDATE_REQUESTED:
      return {
        ...state,
        activeEntity: action.payload,
        totalSet,
        isFetching: true,
      };

    case actions.UPDATE_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isFetching: false };

      tmpState = state.entities[state.activeEntity].slice();
      item = action.payload.value;
      tmpState = tmpState.map(x => {
        if (x.key === item.key) x = item;
        return x;
      });
      state.editingEntity = {};
      state.entities[state.activeEntity] = tmpState.slice();
      return { ...state, activeSet: tmpState.slice(), isFetching: false };

      case actions.REORDER_ADMIN_PHOTOS_REQUESTED:

            photos = state.selectedItemData.photos.slice();

            photos = photos.filter((item, index) => index !== action.payload.oldIndex);

            photos = [
                ...photos.slice(0, action.payload.newIndex),
                state.selectedItemData.photos[action.payload.oldIndex],
                ...photos.slice(action.payload.newIndex)
            ];
            state.selectedItemData.photos = photos;
            

            return {
              ...state,
              
              errors: {},
              isFetching: false,
            };

    case actions.GET_REQUESTED:
    case actions.API_REQUESTED:
    case actions.CREATE_REQUESTED:
    case actions.DELETE_REQUESTED:
    case actions.TOGGLE_STATUS_REQUESTED:
    case actions.VOUCHER_TOGGLE_STATUS_REQUESTED:
      return { ...state, activeEntity: action.payload, isFetching: true };

    case actions.DELETE_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isFetching: false };
      tmpState = state.entities[state.activeEntity].slice();
      const { id } = action.payload;
      const findIndex = tmpState.findIndex(item => item.userId === +id);
      findIndex > -1 && tmpState.splice(findIndex, 1);
      const totalRecords = +state.totalSet - 1;
      return {
        ...state,
        activeSet: tmpState.slice(),
        totalSet: totalRecords,
        isFetching: false,
      };

    case actions.CREATE_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isFetching: false };

      return { ...state, item: action.payload, isFetching: false };

    case actions.LOOKUP_REQUESTED:
      return { ...state, activeEntity: action.payload, isRequesting: true };

    case actions.LOOKUP_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isRequesting: false };

      state.lookup[action.payload.lookupType] = action.payload.value;
      return { ...state, lookup: action.payload.value, isRequesting: false };

    case actions.API_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isFetching: false };

      return { ...state, api: action.payload, totalSet, isFetching: false };

    case 'SET_COLLAPSE_MENU' :
      return {
        ...state,
        collapseMenu: action.payload
      };
    case actions.GET_USER_LOOK_UP_REQUESTED:
    case actions.ADD_UPDATE_NEW_USER_REQUESTED:
    case actions.ADD_UPDATE_CATEGORY_REQUESTED:
    case actions.ADD_UPDATE_ABBREVIATION_REQUESTED:
    case actions.ADD_UPDATE_BASIC_FORM_REQUESTED:
    case actions.ADD_UPDATE_PROPERTY_REQUESTED:
    case actions.ADD_UPDATE_PRODUCT_REQUESTED:
    case actions.ADD_UPDATE_COMPANY_REQUESTED:
    case actions.SWITCH_PORTAL_REQUESTED:
    case actions.ADD_UPDATE_VOUCHER_TEMPLATE_REQUESTED:
      return { ...state, isFetching: true, selectedItemData: { photos: [] } };

    case actions.ADD_UPDATE_PROPERTY_RESET:
      return { ...state, isFetching: false, selectedItemData: { photos: [] } };

    case actions.GET_USER_DETAIL_REQUESTED:
      return { ...state, isDetailFetching: true, selectedItemData: {} };

    case actions.GET_PRODUCT_TYPE_LOOK_UP_REQUESTED:
      return { ...state, isFetching: true, lookupData: {} };

    case actions.GET_USER_LOOK_UP_RESOLVED:
      return {
        ...state,
        userLookup: action.payload.value,
        errors: {},
        isFetching: false,
      };

    case actions.GET_USER_DETAIL_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isDetailFetching: false,
        };
      }
      return {
        ...state,
        selectedItemData: { ...action.payload.value },
        errors: {},
        isDetailFetching: false,
      };

    case actions.GET_PRODUCT_TYPE_LOOK_UP_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      return {
        ...state,
        lookupData: { ...action.payload.value },
        errors: {},
        isFetching: false,
      };

    case actions.ADD_UPDATE_NEW_USER_RESOLVED:
    case actions.ADD_UPDATE_CATEGORY_RESOLVED:
    case actions.ADD_UPDATE_ABBREVIATION_RESOLVED:
    case actions.ADD_UPDATE_PROPERTY_RESOLVED:
    case actions.ADD_UPDATE_PRODUCT_RESOLVED:
    case actions.ADD_UPDATE_COMPANY_RESOLVED:
    case actions.ADD_UPDATE_VOUCHER_TEMPLATE_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      const selectedData = action.payload?.isFromProduct ? {...action.payload.value } : {...action.payload.reqObj } ;
      return { ...state, errors: {}, isFetching: false, selectedItemData: selectedData };
      case actions.UPDATE_PROPERTY_STATUS_RESOLVED:
      return { ...state, errors: {}, isFetching: false, activeSet: action.payload };
    case actions.ADD_UPDATE_BASIC_FORM_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      return {
        ...state,
        errors: {},
        isFetching: false,
        selectedItemData: action.payload.value,
      };
    case actions.PHOTO_UPLOAD_PROGRESS_UPDATED:
      photoProgress = state.photoProgress.slice();
      photoProgress[action.payload.order] = action.payload.progress;

      return {
        ...state,
        photoProgress: photoProgress,
        isError: action.isError,
      };

    case actions.REMOVE_PHOTO_RESOLVED:
      state.selectedItemData.photos.splice(action.payload.index, 1);
      return { ...state, isError: action.isError };

    case actions.UPLOAD_PHOTO_RESOLVED:
      photoProgress = state.photoProgress.slice();

      if (!action.isError) {
        newPhoto = action.payload.photo;
        photos = state.selectedItemData.photos.slice();

        photos[newPhoto.order] = newPhoto;
        photoProgress[newPhoto.order] = 1;

        state.selectedItemData.photos.push(newPhoto);
      }

      return {
        ...state,
        isFetching: false,
        photos: photos,
        photoProgress: photoProgress,
        isError: action.isError,
      };

    case actions.DELETE_PHOTOS_RESOLVED:
      // If error, return errors
      const photoListing = state.selectedItemData.photos;
      const filteredListings = photoListing
        .map(selectedItemData => {
          if (
            Number(selectedItemData.listingPhotoId) !== action.payload.value
          ) {
            return selectedItemData;
          }
          return null;
        })
        .filter(x => x);

      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
        };
      }
      return {
        ...state,
        selectedItemData: {
          ...state.selectedItemData,
          photos: filteredListings,
        },
        errors: {},
        isFetching: false,
      };

    case actions.TOGGLE_STATUS_RESOLVED:
      if (action.isError) {
        return { ...state, errors: { ...action.payload }, isFetching: false };
      }
      let updatedState = { ...state };
      updatedState['selectedItemData']['isActive'] = !updatedState['selectedItemData']['isActive'];
      updatedState['selectedItemData']['isVoucherActive'] = !updatedState['selectedItemData']['isVoucherActive'];
      updatedState['selectedItemData']['isEnabled'] = !updatedState['selectedItemData']['isEnabled'];
      return { ...state, selectedItemData: updatedState['selectedItemData'], isFetching: false };
    case actions.SUBMISSION_STATUS_REQUESTED:
      return { ...state, isFetching: true };
    case actions.SUBMISSION_STATUS_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }

      return {
        ...state,
        errors: {},
        isFetching: false,
        activeSet: action.newSubmissionList,
      };
    case actions.UPLOAD_FORM_PHOTO_PROGRESS:
      return { ...state, uploadFormFileProgress: action.payload.progress };
    case actions.UPLOAD_FORM_PHOTO_REQUESTED:
      return {
        ...state,
        isFormFileUploading: true,
        uploadedFormFileDetail: {},
      };
    case actions.UPLOAD_FORM_PHOTO_RESOLVED:
      if (action.payload.isError) {
        return {
          ...state,
          isFormFileUploading: false,
          uploadFormFileProgress: 0,
        };
      } else {
        return {
          ...state,
          isFormFileUploading: false,
          uploadedFormFileDetail: { ...action.payload },
          uploadFormFileProgress: 0,
        };
      }
    case actions.OVERLAY_FORM_RESOLVED:
    case actions.UPDATE_OVERLAY_FORM_RESOLVE:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      return {
        ...state,
        overlayFormDetail: action.payload.value,
        errors: {},
        isFetching: false,
      };

    case actions.SWITCH_PORTAL_RESOLVED:

      const userObj = getLocalUserData();
      // If error, return errors
      if (action.isError) {
          return {
              ...state,
              errors: { ...action.payload },
              isFetching: false
          };
      }
      return { ...state, userDetails: { ...userObj }, errors: {}, isFetching: false, isLoggedIn: true };
    case actions.GET_AUDIT_LOG_RESOLVE:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isFetching: false };

      tmpState = action.payload.value;
      state.auditLogs[action.payload.entity] = tmpState.results.slice();
      return {
        ...state,
        totalSet: tmpState.total,
        isFetching: false,
      };
    case actions.GET_LANDING_LIST_REQUEST:
      return {
        ...state,
        landingPage: [],
        errors: {},
      };
    case actions.GET_LANDING_LIST_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      return {
        ...state,
        landingPage: action.payload?.value?.forms || [],
        errors: {},
        isFetching: false,
      };

    case actions.VOUCHER_TOGGLE_STATUS_RESOLVED:
      if (action.isError) {
        return { ...state, errors: { ...action.payload }, isFetching: false };
      }
      let updatedStateValue = { ...state };
      updatedStateValue = updatedStateValue['activeSet'].map(el => {
        if (el['id'] === action.payload['id']) {
          el['isVoucherActive'] = !el['isVoucherActive'];
        }
        return el;
      });
      return { ...state, activeSet: updatedStateValue, isFetching: false };
    case actions.MLS_PREVIEW_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      return {
        ...state,
        mlsFormPreviewDetail: action.payload.value,
        errors: {},
        isFetching: false,
      };
    case actions.UPDATE_LOGGED_IN_USER_RESOLVE:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      const { entity, requestObj } = action.data;
      const { activeSet } = state;
      let newData = activeSet;
      if(entity === entityTypes.abbreviation) {
        newData = activeSet.map(item => {
          if (item.mlsId === requestObj.mlsId) {
            return {
              ...item,
              loggedInUserId: requestObj.userId,
            };
          }
          return item;
        });
      }
      return {
        ...state,
        activeSet: newData,
        errors: {},
        isFetching: false,
      };
    case actions.COMPLETE_PAYMENT_RESOLVE:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      let updatedSelectedState = { ...state };
      if(action.payload.value){
        updatedSelectedState['selectedItemData']['isPaymentCompleted'] = true;
        updatedSelectedState['selectedItemData']['paymentCompletionDate'] = new Date();
      }
      updatedSelectedState['selectedItemData']['isPaymentCompletedMSG'] = action.payload.message;
   
      return {
        ...state,
        selectedItemData: updatedSelectedState['selectedItemData'],
        errors: {},
        isFetching: false,
      };
    case actions.RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        resettingPassword: true,
      };
    
    case actions.RESET_PASSWORD_RESOLVED:
    case actions.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resettingPassword: false,
      };
    case actions.RESEND_EMAIL_CONFIRMATION_REQUESTED:
      return {
        ...state,
        sendingConfirmation: true,
      };
    
    case actions.RESEND_EMAIL_CONFIRMATION_RESOLVED:
    case actions.RESEND_EMAIL_CONFIRMATION_FAILED:
      return {
        ...state,
        sendingConfirmation: false,
      };

    case actions.SEARCH_USERS_REQUESTED:
    return {
      ...state,
      searchingUsers: true,
    };

    case actions.SEARCH_USERS_RESOLVED:
      return {
        ...state,
        searchingUsers: false,
        matchedUsersList: action.payload,
      };

    case actions.SEARCH_USERS_FAILED:
      return {
        ...state,
        searchingUsers: false,
      };

    case actions.CLEAR_SEARCH_USERS:
      return {
        ...state,
        matchedUsersList: null,
      };  
      
    default:
      return state;
  }
};

export const searchingUsersSelector = state => state.admin.searchingUsers;