import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { titleCompaniesCreators } from '../../../Actions/V2/Admin/TitleCompaniesActions';
import { adminTitleCompaniesSelector } from '../../../Reducers/V2/AdminTitleCompaniesReducer';
import TitleCompanyCard from './TitleCompanyCard';

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel
        ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
        : ''}
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const SelectContainer = styled.div`
  min-width: 20rem;
  position: relative;
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    margin-top: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    border-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#242B26')};
  }
  select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }

  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 24rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 26rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 28rem;
  }
`;

export const titleCompanyPreferenceOptions = {
  other: "other",
  wantKnowMore: "wantKnowMore",
  openToSuggestion: "openToSuggestion",
};

const TitleCompanyPanel = ({
  state,
  titleCompanyId,
  titleCompanyName,
  titleCompanyEmail,
  titleCompanyPreference,
  handleChange,
  disabled = false,
  error = false,
  onFocus = null,
}) => {
  const dispatch = useDispatch();
  const titleCompanies = useSelector(adminTitleCompaniesSelector);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isOtherCompany, setIsOtherCompany] = useState( titleCompanyPreference === titleCompanyPreferenceOptions.openToSuggestion ||
    titleCompanyPreference === titleCompanyPreferenceOptions.other ||
    titleCompanyPreference === titleCompanyPreferenceOptions.wantKnowMore);

  const titleCompaniesOptions = titleCompanies?.map((x) => ({ value: x.id, label: x.companyName })) || [];

  useEffect(() => {
    dispatch(titleCompaniesCreators.getTitleCompanies({ state, limit: 100 }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!titleCompanyId) {
      return;
    }

    const titleCompany = titleCompanies.find((x) => x.id === titleCompanyId);
    setSelectedCompany(titleCompany);
  }, [titleCompanyId, titleCompanies]);

  useEffect(() => {
    if (!titleCompanyName) {
      return;
    }

    setIsOtherCompany(true);
  }, [titleCompanyName]);

  const changeTitleCompany = (e) => {
    if (!e.target.value) {
      setSelectedCompany(null);
      setIsOtherCompany(false);
      handleChange({
        titleCompanyId: null,
        titleCompanyName: null,
        titleCompanyEmail: null,
        titleCompanyPreference: null,
    });
      return;
    }

    
    if (
      e.target.value === titleCompanyPreferenceOptions.openToSuggestion ||
      e.target.value === titleCompanyPreferenceOptions.other ||
      e.target.value === titleCompanyPreferenceOptions.wantKnowMore
    ) {
      setSelectedCompany(null);
      setIsOtherCompany(true);
      handleChange({
        titleCompanyId: null,
        titleCompanyName,
        titleCompanyEmail: null,
        titleCompanyPreference: e.target.value,
      });
      return;
    }

    const titleCompany = titleCompanies.find((x) => x.id === +e.target.value);
    setSelectedCompany(titleCompany);
    setIsOtherCompany(false);

    handleChange({
      titleCompanyId: titleCompany.id,
      titleCompanyName: null,
      titleCompanyEmail: null,
      titleCompanyPreference: null,
    });
  };

  return (
    <div>
      <div style={{ color: '#626262', fontSize: '16px', lineHeight: '20px' }}>
        <p>
        To facilitate a smooth closing, we believe it’s important to select a title and settlement service provider at the same time that you list your property.
        </p>
        <p>What are title and settlement services and why choose a provider now?</p>

        <p>
        Title and settlement providers act as neutral third parties to verify your property ownership,
        record the property transfer and handle funds distribution at the closing of your property sale. 
        Best of all, the costs of these services are only paid at closing from the proceeds of your property sale. 
        Choosing a provider now will set you up for a faster and more efficient closing. 
        Doing this will enable the provider to run a preliminary title report, 
        establish a file number and allow us to notify your potential buyers of your selection before they draft an offer on your property.
        </p>

        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.unrealestate.com/blog/unleashing-the-power-of-transaction-and-settlement-services-in-home-sales"
          >
            Click here{' '}
          </a>
          to read our recent article explaining how settlement services can reduce disruptions to your home sale
          process.
        </p>

        <p>
        In order for us to be able to inform potential buyers that you have a preferred title 
        and settlement service provider please either select the service provider that we have an integration with 
        in your local market (who can open a file and provide you with a preliminary title report) 
        or tell us the name of another service provider you would prefer using by selecting Other and typing in the name of such provider.
        By selecting below, I want more information on what title options are available through the title provider. 
        </p>
      </div>
      <div>
        <SelectContainer className={error ? 'invalid' : ''}>
          <select
            name="titleCompany"
            value={isOtherCompany ? titleCompanyPreference : selectedCompany?.id || ''}
            onChange={changeTitleCompany}
            onFocus={onFocus}
            disabled={disabled}
          >
            <option key={-1} value="">
              Select Title Preference
            </option>
            <option key={titleCompanyPreferenceOptions.wantKnowMore} value={titleCompanyPreferenceOptions.wantKnowMore}>
              I want to know more
            </option>
            <option key={titleCompanyPreferenceOptions.openToSuggestion} value={titleCompanyPreferenceOptions.openToSuggestion}>
              I’m open to a suggestion
            </option>
            {titleCompaniesOptions.map((x, idx) => (
              <option key={idx} value={x.value}>
                {x.label}
              </option>
            ))}
            <option key={titleCompanyPreferenceOptions.other} value={titleCompanyPreferenceOptions.other}>
              Other
            </option>
          </select>
        </SelectContainer>
        {isOtherCompany && (
          <>
            <InputContainer className={error ? 'invalid' : ''}>
              <input
                placeholder=""
                disabled={disabled}
                type="text"
                name="titleCompanyName"
                id="titleCompanyName"
                value={titleCompanyName}
                onFocus={onFocus}
                onChange={(e) => {
                  handleChange({ titleCompanyEmail, titleCompanyName: e.target.value, titleCompanyId: null, titleCompanyPreference });
                }}
              />
              <InputLabel smallLabel={titleCompanyName} htmlFor="titleCompanyName">
                Title Company Name *
              </InputLabel>
            </InputContainer>
          </>
        )}
      </div>
      {titleCompanies?.[0] && <TitleCompanyCard titleCompany={titleCompanies[0]} />}
    </div>
  );
};

export default TitleCompanyPanel;
