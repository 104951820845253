import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import v8n from 'v8n';
import { userActionCreators } from '../../../Actions/UserActions';
import { RowFlex, InputFlex, MidBoxWrapper, MidBox, CustomButton, HeadTextWrap, Heading } from '../../../Assets/Style/commonStyleComponents';
import { regexPattern, validationMessages, passwordType, phoneMask } from '../../../Constants/CommonConstants';
import { validatePasswordFields } from '../../../Utilities/commonFunctions';

const fa_eye_slash='fa fa-eye-slash';
const InnerHeading = styled.h3`
  font-size: 18px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  margin-top: 10px;
  margin-bottom: 27px;
`;

class ProfileView extends React.Component {
  state = {
    passwordVisible: true,
    newPasswordVisible: true,
    confirmPasswordVisible: true,
    userAccountInfo: {
      firstName: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.firstName,
        }],
        value: this.props.userDetails.firstName || ""
      },
      lastName: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.lastName,
        }],
        value: this.props.userDetails.lastName || ""
      },
      phoneNumber: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.phoneNumber,
        },
        {
          rule: v8n().pattern(regexPattern.PHONE),
          message: validationMessages.phoneNumber,
        }
        ],
        mask: phoneMask,
        value: this.props.userDetails.phoneNumber || ""
      },
      email: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.email,
        },
        {
          rule: v8n().pattern(regexPattern.EMAIL),
          message: validationMessages.validEmail,
        }],
        value: this.props.userDetails.email || ""
      },

      oldPassword: {
        rules: [],
        value: ""
      },

      newPassword: {
        rules: [],
        value: ""
      },

      confirmPassword: {
        rules: [],
        value: ""
      },
    }
  };

  handleFieldChanged = (e) => {
    let userAccountInfo = { ...this.state.userAccountInfo };
    userAccountInfo[e.target.name].value = e.target.value;
    this.setState({ userAccountInfo: { ...userAccountInfo } });
  }

  handleFieldBlur = (e) => {
    this.validateFormField(e.target.name, e.target.value);
    if (e.target.name === passwordType.oldPassword || e.target.name === passwordType.newPassword || e.target.name === passwordType.confirmPassword) {
      validatePasswordFields(e.target.name, e.target.value, this.state.userAccountInfo)
    }
  }

  validateFormField = (key, value) => {
    let userAccountInfo = { ...this.state.userAccountInfo };
    let isError = false;
    for (let i = 0; i < userAccountInfo[key].rules.length; i++) {
      let rule = userAccountInfo[key].rules[i];
      if (userAccountInfo.confirmPassword.value) {
        isError = userAccountInfo.newPassword.value !== userAccountInfo.confirmPassword.value;
        if (isError) {
          userAccountInfo[passwordType.confirmPassword].error = validationMessages.passwordNotMatch;
          break;
        }
      }
      if (!rule.rule.test(value)) {
        userAccountInfo[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      userAccountInfo[key].error = null;
    }

    this.setState({ userAccountInfo: { ...userAccountInfo }, isError: Object.keys(userAccountInfo).some(k => userAccountInfo[k].error !== undefined && userAccountInfo[k].error !== null) });

    return isError;
  }

  handleUpdateAccountDetails = (accountDetails) => {
    this.props.updateProfile(accountDetails, () => {
      this.setState({
        userAccountInfo: {
          ...this.state.userAccountInfo,
          oldPassword: {
            rules: [],
            value: ""
          },

          newPassword: {
            rules: [],
            value: ""
          },

          confirmPassword: {
            rules: [],
            value: ""
          },
        }
      })
    });
  }

  validateAndUpdateAccountDetails = (e) => {
    e.preventDefault();

    let isError = false;
    let isPasswordError = false;

    Object.keys(this.state.userAccountInfo).forEach((keyName) => {
      const isErrorExist = this.validateFormField(keyName, this.state.userAccountInfo[keyName].value);
      if (isErrorExist) {
        isError = true;
      }
      if (keyName === passwordType.oldPassword || keyName === passwordType.newPassword || keyName === passwordType.confirmPassword) {
        isPasswordError = validatePasswordFields(keyName, this.state.userAccountInfo[keyName].value, this.state.userAccountInfo);
      }
    })
    if (!isError && !isPasswordError) {
      this.handleUpdateAccountDetails(
        {
          ...(Object.keys(this.state.userAccountInfo).reduce((retVal, key) => {
            if (this.state.userAccountInfo[key].value) {
              retVal[key] = this.state.userAccountInfo[key].value;
            }
            return retVal;
          }, {}))
        });
    }
  }

  render() {
    const {passwordVisible,confirmPasswordVisible,newPasswordVisible} = this.state;
    return (
      <React.Fragment>
        <MidBoxWrapper className="profile-wrapper">
          <MidBox>
            <HeadTextWrap>
              <Heading>My Profile</Heading>
            </HeadTextWrap>
            <form onSubmit={this.handleSaveAccountDetails} autoComplete="off">
              <RowFlex>
                <InputFlex
                  name="firstName"
                  title="First Name"
                  className="inputgap"
                  value={this.state?.userAccountInfo?.firstName?.value}
                  error={this.state?.userAccountInfo?.firstName?.error || (this.props?.errors?.firstName || [])[0]}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  name="lastName"
                  title="Last Name"
                  className="inputgap"
                  value={this.state?.userAccountInfo?.lastName?.value}
                  error={this.state?.userAccountInfo?.lastName?.error || (this.props?.errors?.lastName || [])[0]}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <RowFlex>
                <InputFlex
                  name="email"
                  title="Email Address"
                  className="inputgap disabled-input"
                  type="email"
                  value={this.state?.userAccountInfo?.email?.value}
                  error={this.state?.userAccountInfo?.email?.error || (this.props?.errors?.email || [])[0]}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                >
                </InputFlex>
                <InputFlex
                  name="phoneNumber"
                  title="Phone Number"
                  className="inputgap"
                  type="tel"
                  value={this.state.userAccountInfo.phoneNumber.value}
                  error={this.state.userAccountInfo.phoneNumber.error || (this.props.errors.phoneNumber || [])[0]}
                  mask={this.state.userAccountInfo.phoneNumber.mask} onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <InnerHeading>Password Change</InnerHeading>
              <div className='admin-input-and-data-wrapper'>
              <div className='admin-w-100'>
              <div className='admin-input-with-icon with-bottom-text'>
              <InputFlex
                name="oldPassword"
                title="Current Password"
                type={passwordVisible ? 'password' : 'text'}
                className="inputgap"
                value={this.state?.userAccountInfo?.oldPassword?.value}
                error={this.state?.userAccountInfo?.oldPassword?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <i className={passwordVisible ? 'fa fa-eye' : fa_eye_slash}
                aria-hidden='true'
                onClick={() =>
                  this.setState({
                    passwordVisible: !this.state.passwordVisible,
                  })
                }
              ></i></div></div></div>
              <div className='admin-input-and-data-wrapper'>
              <div className='admin-w-100'>
              <div className='admin-input-with-icon with-bottom-text'>
              <InputFlex
                name="newPassword"
                title="New Password"
                type={newPasswordVisible ? 'password' : 'text'}
                className="inputgap"
                value={this.state?.userAccountInfo?.newPassword?.value}
                error={this.state?.userAccountInfo?.newPassword?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <i className={newPasswordVisible ? 'fa fa-eye' : fa_eye_slash}
                aria-hidden='true'
                onClick={() =>
                  this.setState({
                    newPasswordVisible: !this.state.newPasswordVisible,
                  })
                }
              ></i></div></div></div>
              <div className='admin-input-and-data-wrapper'>
              <div className='admin-w-100'>
              <div className='admin-input-with-icon with-bottom-text'>
              <InputFlex
                name="confirmPassword"
                title="Confirm Password"
                type={confirmPasswordVisible ? 'password' : 'text'}
                className="inputgap"
                value={this.state?.userAccountInfo?.confirmPassword?.value}
                error={this.state?.userAccountInfo?.confirmPassword?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <i className={confirmPasswordVisible ? 'fa fa-eye' : fa_eye_slash}
                aria-hidden='true'
                onClick={() =>
                  this.setState({
                    confirmPasswordVisible: !this.state.confirmPasswordVisible,
                  })
                }
              ></i></div></div></div>
              <CustomButton type="submit" className="block" onClick={this.validateAndUpdateAccountDetails}>Save Changes</CustomButton>
            </form>
          </MidBox>
        </MidBoxWrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.user.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
