import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import { map } from 'lodash';
import v8n from 'v8n';
import BrokerInfo from './BrokerInfo';
import FieldOverrideInfo from './FieldOverrideInfo';
import { adminActionCreators } from '../../../../Actions/AdminActions';
import {
  RowFlex,
  InputFlex,
} from '../../../../Assets/Style/commonStyleComponents';
import {
  phoneMask,
  regexPattern,
  validationMessages,
} from '../../../../Constants/CommonConstants';
import Enums from '../../../../Constants/Enums';
import {
  addHttpInUrl,
  goBack,
  getFocusInput,
  isListingProcessorRole
} from '../../../../Utilities/commonFunctions';
import { cursorStyles } from '../../../../Constants/CommonConstants';

function rearrangeFilledOverrideFileds(companyFieldOverrides) {
 const fieldData = [];
  map(companyFieldOverrides, (item, index) => {
    if (item) {
      fieldData.push({
        [`companyFieldOverrideId-${index}`]: item.companyFieldOverrideId,
        [`fieldName-${index}`]: item.fieldName,
        [`overrideValue-${index}`]: item.overrideValue
      });
    }
  });
  return fieldData;
}

class AddEditCompany extends React.Component {
  constructor(props) {
    super(props);
    this.isNewCompany = props.isNewCompany;
    this.overrideFieldData = [];
    if (props.selectedCompanyData.companyFieldOverrides) {
      const companyFieldOverrides = props.selectedCompanyData.companyFieldOverrides;
      this.overrideFieldData = rearrangeFilledOverrideFileds(companyFieldOverrides);
    }
    this.state = {
      companyFieldOverrides:this.overrideFieldData,
      companyDetail: {
        companyName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.companyName,
            },
          ],
          value: props?.selectedCompanyData?.companyName || '',
        },
        companyTypeId: {
          rules: [],
          value: props?.selectedCompanyData?.companyTypeId || '',
        },
        userCreationTypeId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          name: 'userCreationTypeId',
          label: 'User Creation Type',
          value: props.selectedCompanyData.userCreationTypeId || '',
        },
        brokerEmail: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props.selectedCompanyData.brokerEmail || '',
          label: 'Email',
          name: 'brokerEmail',
        },
        brokerName: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props.selectedCompanyData.brokerName || '',
          label: 'Name',
          name: 'brokerName',
        },
        brokerPhone: {
          rules: [],
          value: props.selectedCompanyData.brokerPhone || '',
          mask: phoneMask,
          label: 'Phone',
          name: 'brokerPhone',
        },
        brokerWebsite: {
          rules: [],
          value: props.selectedCompanyData.brokerWebsite || '',
          label: 'Website',
          name: 'brokerWebsite',
        },
      },
    };
  }

  handleAddRow = () => {
    const values = [...this.state.companyFieldOverrides];
    const addFieldsLength = values.length;
    values.push({
      [`companyFieldOverrideId-${addFieldsLength}`]:0,
      [`fieldName-${addFieldsLength}`]: '',
      [`overrideValue-${addFieldsLength}`]: '',
    });
    this.setState({
      companyFieldOverrides: values,
    });
  }

  reIntializeOverrideFormFields = listArr => {
    const { length } = listArr;
    const upatedArr = [];
    for (let i = 0; i < length; i += 1) {
      const obj = listArr[i];
      const updatedObj = {};
      Object.keys(obj).forEach(key => {
        const splitValue = key.split('-')[0];
        const updateKey = splitValue.replace(splitValue, `${splitValue}-${i}`);
        updatedObj[updateKey] = obj[key];
      });
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  };

  handleRemoveRow = idx => {
    const companyFieldOverrides = [...this.state.companyFieldOverrides];
    companyFieldOverrides.splice(idx, 1);
    const updatedValues = this.reIntializeOverrideFormFields(companyFieldOverrides);
    this.setState({ companyFieldOverrides: updatedValues });
  };

  handleOverrideFormFields = (idx, e) => {
    const { name, value } = e.target;
    const companyFieldOverrides = [...this.state.companyFieldOverrides];
    companyFieldOverrides[idx][name] = value;
    //companyFieldOverrides[idx][`errors-${idx}`][`${name}`] = false;
    this.setState({ companyFieldOverrides: companyFieldOverrides });
  };

  handleFieldChanged = e => {
    const { name, value } = e.target;
    let companyDetail = { ...this.state.companyDetail };
    companyDetail[name].value = value;
    if (name === 'brokerEmail' && value !== '') {
      const isEmailValid = v8n().pattern(regexPattern.EMAIL).test(value);
      if (!isEmailValid) {
        companyDetail['brokerEmail'].error = validationMessages.validEmail;
      } else {
        companyDetail['brokerEmail'].error = null;
      }
    }
    if (name === 'brokerWebsite' && value !== '') {
      const isUrlValid = v8n().pattern(regexPattern.WEBSITE).test(value);
      if (!isUrlValid) {
        companyDetail['brokerWebsite'].error = validationMessages.validWebsite;
      } else {
        companyDetail['brokerWebsite'].error = null;
      }
    }
    this.setState({ companyDetail: { ...companyDetail } });
  };

  handleFieldBlur = e => {
    this.validateCompanyFormField(e.target.name, e.target.value, false);
  };

  handleOverrideFieldBlur = (idx, e) => {
    this.validateOvwerrideFormField(idx, e.target.value, false);
  };

  validateCompanyFormField = (key, value, isSubmitted) => {
    let companyDetail = { ...this.state.companyDetail };
    let isError = false;
    for (let i = 0; i < companyDetail[key].rules.length; i++) {
      let rule = companyDetail[key].rules[i];
      if (!rule.rule.test(value)) {
        companyDetail[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (companyDetail['brokerEmail'].value) {
      const isEmailValid = v8n()
        .pattern(regexPattern.EMAIL)
        .test(companyDetail['brokerEmail'].value);
      if (!isEmailValid) {
        companyDetail['brokerEmail'].error = validationMessages.validEmail;
        isError = true;
      }
    }

    if (companyDetail['brokerWebsite'].value) {
      const isUrlValid = v8n()
        .pattern(regexPattern.WEBSITE)
        .test(companyDetail['brokerWebsite'].value);
      if (!isUrlValid) {
        companyDetail['brokerWebsite'].error = validationMessages.validWebsite;
        isError = true;
      }
    }

    if (!isError) {
      companyDetail[key].error = null;
    }

    this.setState({
      companyDetail: { ...companyDetail },
      isError: Object.keys(companyDetail).some(
        k =>
          companyDetail[k].error !== undefined &&
          companyDetail[k].error !== null,
      ),
    });

    return isError;
  };

  validateOvwerrideFormField = (idx, value, isSubmitted) => {
    const companyFieldOverrides = [...this.state.companyFieldOverrides];
    let isError=false;
      var found = Object.keys(companyFieldOverrides).filter(function(key) {
       return (key!=idx && companyFieldOverrides[key][`fieldName-${key}`]==value);
      });
      
      if (companyFieldOverrides[idx][`fieldName-${idx}`] === '') {
        isError=true;
        companyFieldOverrides[idx][`errors-fieldName-${idx}`]= {'required':true,'msg':validationMessages.required};
      }else if(found.length){
        isError=true;
        companyFieldOverrides[idx][`errors-fieldName-${idx}`]= {'duplicate':true,'msg':'Display name already exist'};
      }else{
        companyFieldOverrides[idx][`errors-fieldName-${idx}`]='';
      }
      if (companyFieldOverrides[idx][`overrideValue-${idx}`] === '') {
        isError=true;
        companyFieldOverrides[idx][`errors-overrideValue-${idx}`]= {'required':true,'msg':validationMessages.required};
      }else{
        companyFieldOverrides[idx][`errors-overrideValue-${idx}`]='';
      }
    this.setState({ companyFieldOverrides: companyFieldOverrides });
    return isError;
  }
  handleAddUpdateCompanyDetails = companyDetails => {
    if (!this.isNewCompany) {
      companyDetails['companyId'] = this.props.selectedCompanyData.companyId;
    }
    if (companyDetails['brokerWebsite']) {
      companyDetails['brokerWebsite'] = addHttpInUrl(
        companyDetails['brokerWebsite'],
      );
    }

    companyDetails['companyFieldOverrides']=this.removeIndexFromArr(this.state.companyFieldOverrides);
    const companyId = this.props.isNewCompany
      ? Enums.NUMERIC_VALUE.ZERO
      : this.props.selectedCompanyData.companyId;
    this.props.addUpdateCompany(companyId, companyDetails, () => {
      goBack();
    });
  };

  removeIndexFromArr = reqArr => {
    const { length } = reqArr;
    const upatedArr = [];
    for (let i = 0; i < length; i += 1) {
      const obj = reqArr[i];
      const updatedObj = {};
      Object.keys(obj).forEach(key => {
        const updateKey = key.split('-')[0];
        updatedObj[updateKey] = obj[key];
      });
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  };

  validateAndUpdateAccountDetails = e => {
    e.preventDefault();

    let isError = false;

    Object.keys(this.state.companyDetail).forEach(keyName => {
      const isErrorExist = this.validateCompanyFormField(
        keyName,
        this.state.companyDetail[keyName].value,
        true,
      );
      if (isErrorExist) {
        isError = true;
      }
    });

   Object.keys(this.state.companyFieldOverrides).forEach(keyName => {
      const isErrorExist = this.validateOvwerrideFormField(
        keyName,
        this.state.companyFieldOverrides[keyName][`fieldName-${keyName}`],
        true,
      );
      if (isErrorExist) {
        isError = true;
      }
    });
    
    if (!isError) {
      this.handleAddUpdateCompanyDetails({
        ...Object.keys(this.state.companyDetail).reduce((retVal, key) => {
          if (this.state.companyDetail[key].value) {
            retVal[key] = this.state.companyDetail[key].value;
          }
          return retVal;
        }, {}),
      });
    }

    const { companyDetail } = this.state;
    getFocusInput(companyDetail);
  };

  handleMultiSelectChange = (selectedOption, type) => {
    const updatedCompanyObj = { ...this.state.companyDetail };
    if (selectedOption && selectedOption.length) {
      updatedCompanyObj[type].error = '';
    } else {
      updatedCompanyObj[type].error = '*';
    }
    updatedCompanyObj[type].value = selectedOption;
    this.setState({ companyDetail: updatedCompanyObj });
  };

  render() {
    const { companyDetail } = this.state;
    const { lookup } = this.props;
    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>{this.isNewCompany ? 'Add Company' : 'Edit Company'}</h2>
            <div className="admin-heading-wrapper-right">                
            </div>
          </div>
          <div className={`admin-add-form-wrapper ${isListingProcessorRole() ? 'disabled-input-form' : ''}`}>
            <form onSubmit={this.handleSaveAccountDetails} autoComplete='off'>
              <RowFlex>
                <InputFlex
                  name='companyName'
                  title='Company Name'
                  className='inputgap'
                  value={companyDetail?.companyName?.value}
                  error={companyDetail?.companyName?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  options={lookup?.companyTypes}
                  name='companyTypeId'
                  title='Company Type'
                  type='text'
                  value={companyDetail?.companyTypeId?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                  placeholder='Select Company Type'
                  className='inputgap'
                  isKeyPaired={true}
                />
              </RowFlex>
              <RowFlex>
                <div className='pcol-6 for-custom-select'>
                  <InputFlex
                    options={lookup.userCreationTypes}
                    name={companyDetail.userCreationTypeId.name}
                    title={companyDetail.userCreationTypeId.label}
                    value={companyDetail.userCreationTypeId.value}
                    error={companyDetail.userCreationTypeId.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    placeholder='Select User Creation Type'
                    isKeyPaired={true}
                  />
                </div>
              </RowFlex>
              <h3 className='admin-form-inner-heading'>Public Contact</h3>
              <BrokerInfo
                initialState={companyDetail}
                handleFieldChanged={this.handleFieldChanged}
                handleFieldBlur={this.handleFieldBlur}
              />
              <h3 className='admin-form-inner-heading'>OVERRIDE FIELD</h3>
              <FieldOverrideInfo
                companyFieldOverrides={this.state.companyFieldOverrides}
                handleFieldChanged={this.handleOverrideFormFields}
                handleFieldBlur={this.handleFieldBlur}
                handleAddRow={this.handleAddRow}
                handleRemoveRow={this.handleRemoveRow}
                handleOverrideFieldBlur={this.handleOverrideFieldBlur}
              />
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    disabled={isListingProcessorRole()}
                    onClick={this.validateAndUpdateAccountDetails}
                   >
                    {this.isNewCompany ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCompany);
