import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import { DatePicker } from "antd";
import moment from "moment";
import enums from "../../../Constants/Enums";
import { editListingValue, formPrefillTypes } from '../../../Constants/CommonConstants';
import { useEffect } from 'react';
import { draftFormsFieldsSelector } from '../../../Reducers/OnboardingReducerV2';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import Enums from '../../../Constants/Enums';

function disabledDate(current) {
  return current && current < moment().add(-1, "days");
}

const Form = styled.div`
  flex: 2;
  display: block;
  padding: 20px;
  overflow: auto;
  height: 100%;
  ul,
  ol {
    margin-left: 17px;
    ul,
    ol {
      margin-left: 0;
    }
  }
  h1 {
    font-size: 26px;
    font-weight: 600;
    color: #242B26;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0;
  }
  h2 {
    font-size: 16px;
    color:#242B26;
    text-align: center;
    margin-bottom: 25px;
  }
  
  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
    padding: 0;
  }
`;

const Group = styled.section``;

const Section = styled.div`

.row-modify {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}


  display: block;
  margin: 0 0 20px;

  font-size: 14px;
  line-height: 26px;

  p {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 26px;
  }

  strong {
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    display: inline-block;
    border: 1px dashed #242B26;
    border-radius: 8px;
    padding: 5px;
    margin: 0 2px;
  }
  select {
    height: 38px;
    min-width: 140px;
  }

  @media(max-width: ${({ theme }) => theme.breakpoints.md}){
    width: 500px;
    .medium-4-v2 {
      min-width: 200px !important;
      input, select {
        width: 90% !important;
      }
    }
  }

  @media(max-width: 37em){
    width: 400px;
  }

  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
    width: 300px;
  }
`;

const FormDatePicker = styled.div`
  display: inline;
  padding-left: 3px;
  input {
    border-radius: 0;
    border: 1px solid #B698D9;
    font-size: 15px;
    &:focus {
      box-shadow: none;
    }
  }
`;

const inputErrorClass = "error-input";
const inputErrorClassOption = "error-input-option";
const inputErrorClassChecbox = "error-input-checkbox";
const disabled_input='disabled-input';
const disabled_input_overlay='disabled-input-overlay';

export const FormImageView = ({ form, onFieldBlur, errors }) => {
  const draftFormsFields = useSelector(draftFormsFieldsSelector);

  useEffect(() => {
    if(!form){
      return;
    }

    const prefilledFields = [];
    form.groups.forEach(group => {
      group.sections.forEach(section => {
        section.fields.forEach(field => {
          const draftFieldValue = getDraftField(field)?.fieldValue;
          if (field.value && (!draftFieldValue || field.value !== draftFieldValue)) {
            prefilledFields.push({
              formFieldId: field.formFieldId,
              formGroupId: section.formGroupId,
              formGroupSectionId: section.formGroupSectionId,
              formId: form.formId,
              formPrefillTypeId: field.formPrefillTypeId,
              fieldValue: field.value,
              readOnly: field.readOnly,
            });
          }
        })
      })
    })
    
    if(prefilledFields.length ){
      prefilledFields.forEach(filled => {
        const index = draftFormsFields.findIndex(i => i.formFieldId === filled.formFieldId);
        if (index !== -1) {
          draftFormsFields[index] = filled;
        } else {
          draftFormsFields.push(filled);
        }
      });
      
      dispatch(onboardingActionCreatorsV2.updateDraftFormFieldsV2([...draftFormsFields]))
      dispatch(onboardingActionCreatorsV2.updateDraftListingV2())
    }
  }, [form])

  const dispatch = useDispatch();

  const processForm = (group, section) => {
    let { content, fields } = section;

    return (<>
        <div className="image-with-form">
            <img src={content} />

            {fields.map(field => getFieldControl(section, field))}
        </div>
    </>);
  };

  const getDraftField = (field) => {
    return draftFormsFields.find(x => x.formFieldId === field.formFieldId);
  }

  const onFieldChange = (section, field, value) => {
    let existingField = getDraftField(field);

    if (!existingField) {
      existingField = {
        formFieldId: field.formFieldId,
        formGroupId: section.formGroupId,
        formGroupSectionId: section.formGroupSectionId,
        formId: form.formId,
        fieldValue: value
      };

      dispatch(onboardingActionCreatorsV2.updateDraftFormFieldsV2([...draftFormsFields, existingField]))
      return;
    }

    existingField.fieldValue = value;
    dispatch(onboardingActionCreatorsV2.updateDraftFormFieldsV2([...draftFormsFields]))
  }

  const onSelectFieldChange = (section, field, e, isDate) => {
    const selectedValue = isDate ? e._d : e.target.value;
   
    onFieldChange(section, field, selectedValue);
  }

  const onCheckboxFieldChange = (section, field, checked, value) => {
    onFieldChange(section, field, checked ? 'true' : '');
  }

  const getCheckboxLabelLength = (node) => {
    if(node?.prev?.name===editListingValue.strong){
      return node?.prev?.children?.[0]['data'].length;
    }
    if(!node?.prev){
      return 0;
    }

    return getCheckboxLabelLength(node?.prev);
  }
 
  const getFieldControl = (section, field) => {
    const isFormSubmitted = false;
    const targetClass = '';

    if (field.readOnly || field.displayName == "date1"
        || field.displayName == "date2"
        || field.displayName == "date3") {
        return (
          <div
            style={{
              position: "absolute",
              top: field.overlayStartY + "px",
              left: field.overlayStartX + "px",
            }}>
            <input
              style={{
                height: field.overlayHeight?field.overlayHeight:20 + "px",
                width: field.overlayWidth?field.overlayWidth:100 + "px",
              }}
              key={`${field.formFieldId}_read`}
              type="text"
              name={field.formFieldId}
              readOnly
              value={field.value || ''}
              className={disabled_input}
            />
          </div>
        );
    }

    if (field.options && field.inputDataType === Enums.InputTypes.DROPDOWN) {
        if (field.value===null){
          field.value='';
        }

        return (<>
          <div
            style={{
              position: "absolute",
              top: field.overlayStartY + "px",
              left: field.overlayStartX + "px",
            }}>
            <select
                style={{
                height: field.overlayHeight?field.overlayHeight: 20 + "px",
                width: field.overlayWidth?field.overlayWidth: 100 + "px",
                padding: 0,
                }}
                key={`${field.formFieldId}`}
                name={field.formFieldId}
                value={getDraftField(field)?.fieldValue || ''}
                onChange={(e) => onSelectFieldChange(section, field, e, false)}
                onBlur={(e) => onFieldBlur(field)}
                disabled={isFormSubmitted}
                className={errors[field.formFieldId] ? inputErrorClassOption :  isFormSubmitted ? disabled_input_overlay : targetClass}>
            
                <option key={`${field.formFieldId}_default`} value=''>
                    {' '}
                    Select Value{' '}
                </option>
                {field.options
                    .replace(/\u21b5|\r\n|\n/g, "@#$")
                    .split("@#$")
                    .map((o, index) => (
                    <option
                        key={`${field.formFieldId}_${index}_${o}`}
                        value={o}>
                        {o}
                    </option>
                    ))}
            </select>
          </div>
          {field.required &&
            <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
                }}
                className="additional-label">
                    Required
            </label>}
          </>
        );
      }

    if (!field.readOnly && field.inputDataType === Enums.InputTypes.TEXT_SHORT) { 
        return (
          <>
          <div
            style={{
              position: "absolute",
              top: field.overlayStartY + "px",
              left: field.overlayStartX + "px",
            }}>
            <input
              style={{
                height: field.overlayHeight?field.overlayHeight:20 + "px",
                width: field.overlayWidth?field.overlayWidth:100 + "px",
              }}
              key={`${field.formFieldId}`}
              type="text"
              name={field.formFieldId}
              value={getDraftField(field)?.fieldValue || ''}
              onChange={(e) => onFieldChange(section, field, e.target.value)}
              onBlur={(e) => onFieldBlur(field)}
              required={field.required && !field.value}
              className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input_overlay : targetClass}
            />
           
          </div>
          {
           field.required &&
           <label 
           style={{
            right: -192 + "px",
            top: field.overlayStartY + "px",
          }}
           className="additional-label">Required</label>
           }
          </>
        );
      }

      if (!field.readOnly && (field.inputDataType === Enums.InputTypes.WHOLE_NUMBER || field.inputDataType === Enums.InputTypes.DECIMAL)) { 
        return (<>
            <div
                style={{
                    position: "absolute",
                    top: field.overlayStartY + "px",
                    left: field.overlayStartX + "px",
                }}>
                <input
                    style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                    }}
                    key={`${field.formFieldId}`}
                    type="text"
                    name={field.formFieldId}
                    value={getDraftField(field)?.fieldValue || ''}
                    onChange={(e) => onFieldChange(section, field, e.target.value)}
                    onBlur={(e) => onFieldBlur(field)}
                    required={field.required && !field.value}
                    ref={field.formFieldId}
                    className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input_overlay : targetClass}
                />
            </div>
            {field.required &&
                <label 
                    style={{
                        right: -192 + "px",
                        top: field.overlayStartY + "px",
                    }}
                    className="additional-label">
                        Required
                </label>}
          </>
        );
      }

      if (!field.readOnly && (field.inputDataType === Enums.InputTypes.CURRENCY)) { 
        return (
          <>
          <div
            style={{
              position: "absolute",
              top: field.overlayStartY + "px",
              left: field.overlayStartX + "px",
            }}
          >
            <CurrencyInput
                style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                }}
                key={`${field.formFieldId}`}
                decimalsLimit={2}
                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                value={getDraftField(field)?.fieldValue || ''}
                name={field.formFieldId}
                onValueChange={(value) => onFieldChange(section, field, value)}
                onBlur={(e) => onFieldBlur(field)}
                required={field.required && !field.value}
                className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input_overlay : targetClass}/>
        </div>
        {field.required &&
            <label 
                style={{
                    right: -192 + "px",
                    top: field.overlayStartY + "px",
                }}
                className="additional-label">
                Required
            </label>}
        </>);
      }

    if (!field.readOnly && field.inputDataType === Enums.InputTypes.TEXT_LONG) { 
        return (
          <>
            <div
                style={{
                    position: 'absolute',
                    top: field.overlayStartY + 'px',
                    left: field.overlayStartX + 'px',
                }}>
                <textarea
                    style={{
                        height: field.overlayHeight?field.overlayHeight:20 + "px",
                        width: field.overlayWidth?field.overlayWidth:100 + "px",
                    }}
                    key={`${field.formFieldId}`}
                    type='text'
                    name={field.formFieldId}
                    defaultValue={getDraftField(field)?.fieldValue || ''}
                    onChange={(e) => onFieldChange(section, field, e.target.value)}
                    onBlur={(e) => onFieldBlur(field)}
                    className={errors[field.formFieldId] ? inputErrorClass+' mx-100' : isFormSubmitted ? 'disabled-input-overlay mx-100': targetClass+' mx-100'}/>
            </div>
            {field.required &&
                <label 
                    style={{
                        right: -192 + "px",
                        top: field.overlayStartY + "px",
                    }}
                    className="additional-label">Required</label>}
          </>
        );
    }

    if (field.inputDataType === Enums.InputTypes.BOOLEAN) {
        field.value && console.log(field)
        return (
          <>
          <div
            style={{
              position: "absolute",
              top: field.overlayStartY + "px",
              left: field.overlayStartX + "px",
              zIndex: 1,
              height: field.overlayHeight + "px",
              width: field.overlayWidth + "px",
            }}
          >
            <input
              style={{
                height: field.overlayHeight + "px",
                width: field.overlayWidth + "px",
              }}
              key={`${field.formFieldId}`}
              type="checkbox"
              name={field.formFieldId}
              defaultChecked={getDraftField(field)?.fieldValue}
              onChange={(e) => onCheckboxFieldChange(section, field, e.target.checked, e.target.value)}
              onBlur={(e) => onFieldBlur(field)}
              disabled={isFormSubmitted}
              className={errors[field.formFieldId] ? inputErrorClassChecbox : targetClass}
            />
            
          </div>
            {field.required &&
                <label 
                style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
                }}
                className="additional-label">Required</label>}
            </>
        );
    }

    if (field.inputDataType === Enums.InputTypes.ON_OFF) {
        return (
          <>
          <div
            style={{
              position: "absolute",
              top: field.overlayStartY + "px",
              left: field.overlayStartX + "px",
              zIndex: 1,
            }}
          >
            <input
              style={{
                height: field.overlayHeight + "px",
                width: field.overlayWidth + "px",
              }}
              key={`${field.formFieldId}`}
              type="checkbox"
              name={field.formFieldId}
              defaultChecked={field?.value ? JSON.parse(field.value) : false}
              onBlur={(e) => onFieldBlur(field)}
              disabled={isFormSubmitted}
              className={errors[field.formFieldId] ? inputErrorClassChecbox : ''}
            />
            
          </div>
          {
           field.required &&
           <label 
           style={{
            right: -192 + "px",
            top: field.overlayStartY + "px",
          }}
           className="additional-label">Required</label>
           }
          </>
        );
      }

      if (field.inputDataType === Enums.InputTypes.DATE_TIME) {
        return (
          <>
          <div
            style={{
              position: "absolute",
              top: field.overlayStartY + "px",
              left: field.overlayStartX + "px",
              zIndex: 1,
            }}
          >
           <FormDatePicker>
          {field.value ? (
            <DatePicker
              size="small"
              style={{
                height: field.overlayHeight?field.overlayHeight:20 + "px",
                width: field.overlayWidth?field.overlayWidth:100 + "px",
              }}
              defaultValue={moment(getDraftField(field)?.fieldValue || '')}
              onChange={e => onSelectFieldChange(section, field, e, true)}
              inputReadOnly
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
              key={`${field.formFieldId}`}
              name={field.formFieldId}
              allowClear={false}
              className={
                errors[field.displayName] ? inputErrorClass : isFormSubmitted ? disabled_input : ""
              }
            />
          ) : (
            <DatePicker
              size="small"
              style={{
                height: field.overlayHeight?field.overlayHeight:20 + "px",
                width: field.overlayWidth?field.overlayWidth:100 + "px",
              }}
              onChange={e => onSelectFieldChange(section, field, e, true)}
              inputReadOnly
              format="YYYY-MM-DD"
              disabledDate={disabledDate}
              key={`${field.formFieldId}`}
              name={field.formFieldId}
              allowClear={false}
              className={
                errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input: ""
              }
            />
          )}
          <span className="text-error">
            {errors[field.displayName]}
          </span>
        </FormDatePicker>
          
          </div>
          {
           field.required &&
           <label style={{
            right: -192 + "px",
            top: field.overlayStartY + "px"}}
           className="additional-label">Required</label>
           } 
          </>
        );
      }
  };

  return (
    <Form>
      {form && form.groups.map((g, i) => {
        return (
          <Group key={i}>
            {g.sections.map((s, j) => {
              return (
                <div className={"dynamic-form-before"}>
                  <Section key={j}>
                    {processForm(g, s)}
                  </Section>
                </div>
              );
            })}
          </Group>
        );
      })}
    </Form>
  );
};