import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { userActionCreators } from '../../../Actions/UserActions';
import { adminActionCreators } from '../../../Actions/AdminActions';
import { goBack } from '../../../Utilities/commonFunctions';
import AddEditVoucher from '../Components/AddEditVoucher';
import Enums from '../../../Constants/Enums';
import { entityTypes } from '../../../Constants/CommonConstants';
import Navbar from '../../Shared/Components/Navbar';
import Footer from '../../Shared/Components/Footer';
import AddEditVoucherV2 from '../Components/AddEditVoucherV2';


class VoucherConatiner extends React.Component {
  componentDidMount() {
    this.props.getLookupData(entityTypes.addVoucher, 'voucherTemplates');
    if (this.props.match?.params?.voucherId > 0) {
      this.props.getSelectedItemDetail(Number(this.props.match.params.voucherId), entityTypes.addVoucher)
    }
  }

  render() {
    const { lookup, userDetails, userErrors, selectedItemData, isDetailFetching, isFetching, isRequesting } = this.props;
    return (
      <React.Fragment>
        <Navbar />
        <div className="profile-back-container">
          <a className="back-btn" href="javascript:void(0)" onClick={() => goBack()}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
              Back
            </a>
        </div>
        {
          //TODO: Change to V1 until PROD release
          !isRequesting && !isDetailFetching && !isFetching &&
          <AddEditVoucherV2
            errors={userErrors}
            isNewVoucher={this.props.match?.params?.voucherId < 1}
            lookup={lookup}
            voucherDetail={selectedItemData}
            userDetails={userDetails}
          />
        }
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path, params } = ownProps.match;
  const { userDetails, errors } = state.user;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[Enums.NUMERIC_VALUE.ONE],
    selectedItemData: params?.voucherId > 0 ? state.admin.selectedItemData || {} : {},
    userDetails: userDetails.isAdminSwitchUser ? userDetails.switchedUser : userDetails || {},
    userErrors: errors || {},
    lookup: state.user.lookup || {},
    isDetailFetching: state.admin.isDetailFetching,
    isFetching: state.admin.isFetching,
    isRequesting: state.user.isRequesting
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators, ...adminActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherConatiner);
