import React from 'react';
import { ImagesUrl } from "../../../../../Constants/Images";
import AppConsts from '../../../../../Constants/AppConsts';

class ContractsClosing extends React.Component {
  render() {
    return (
      <>
        <div className={`klaviyo-form-${AppConsts.klaviyoForms.buyerQualificationFormId}`} />
        <h2>Negotiate and Close the Sale</h2>
        <p>As a buyer’s interest rises, negotiation begins. Here’s how to navigate from the first call to the close.</p>
        <p>First, <strong>know your terms.</strong></p>
        <p>What is the minimum you will accept for the house?</p>
        <p>Can you negotiate time as well as money? If you can be flexible on a move-out or closing date, can you accept more or less money?</p>
        <p>Can you negotiate on extras, such as a backyard grill, furniture, or other amenities that might be worth more to the buyer than to you?</p>
        <p>Do you have a real estate lawyer lined up to review the contract?
          Do you have the correct contract for your state? Are you familiar with local negotiating and closing customs?</p>
        <p>Can you back up your asking price with credible, independent market data? (See the Guide to Marketing Your Home for details on setting a smart price.)</p>
        <p>Next, be sure that <strong>buyers actually can afford</strong> your house. There’s no point in negotiating with someone who doesn’t lacks the income,
          down payment, credit rating or other quaifications. (See below details about how to qualify a buyer.)</p>
        <p>Negotiations are a back-and-forth, with each side giving a bit until the two sides reach agreement. Chances are that your buyer is represented by a buyer’s agent.
          If you have chosen (as USRealty.com recommends) to pay the traditional 3 percent buyer’s agent commission, you can expect a professional process.</p>
        <p>Once you have agreed on a price, draw up the contract and take earnest money from the buyer. This earnest money goes into an escrow account,
          typically set up by the buyer’s agent and the title company, to ensure that the buyer has a commitment to buying the house.</p>
        <p>It’s up to the buyer to set up the inspection, appraisal and all the other steps required to close the deal.
          The buyer and his agent will have a list of requirements from the buyer’s lender.</p>
        <strong>Expect to cooperate with:</strong>
        <ul>
          <li>A home inspector</li>
          <li>An appraiser</li>
          <li>A surveyor</li>
          <li>The title company, which may want legal documents</li>
          <li>The buyer’s agent and lawyer</li>
        </ul>
        <p>Meanwhile, you must prepare to not own or live in the house any more. <strong>For your transition</strong>, expect to:</p>
        <p>Work with your mortage lender and the title company to pay off your mortgage and any other loans for which the house is collateral</p>
        <strong>Move</strong>
        <ul>
          <li>Contact the utility companies to switch accounts to the new owners</li>
          <li>Close your insurance policy on that house and open it on your new dwelling</li>
          <li>Arrange for the final walk-through the day before or morning of closing</li>
          <li>Send change-of-address notifications to your contacts</li>
          <li>Spend a day closing, as this process can be time-consuming</li>
        </ul>
        <strong>Can That ‘Buyer’ Afford Your House? How to Qualify a Buyer</strong>
        <p>They come.</p>
        <p>They look.</p>
        <p>They love.</p>
        <p>They can’t afford your house.</p>
        <p>When you’re selling your house, you don’t have time to waste with people who can’t afford to make a serious offer on your house.
          They might not have a sufficient down payment. They might have a seriously bad credit score. They might want to make an offer that depends on their
          ability to sell their current house, while tying up yours in the meantime.</p>
        <p>Figuring out if a buyer can actually afford your house is called ‘qualifying’ the buyer. Just because someone is ready and willing
          to buy your house doesn’t mean they are able. Here’s how to identify lookie-loos (that’s the real-estate equivalent of tire-kickers) from serious prospects.</p>
        <p>First, find out how far the potential buyer has gotten in the mortgage approval process.
          The more the buyer has invested in that process, the more serious that buyer is about buying a house. Ask the buyer what stage he or she has reached:
          prequalification, preapproval, or letter of commitment.</p>
        <p>
          <strong>Pre-Qualification:</strong> Generally this means that the buyer and a lender have only discussed the details of a potential mortgage over the phone.
          The lender gives the buyer a general idea of what he or she can probably afford, but the buyer has not filled out a loan application.
          And, the buyer has not undergone a credit check. Little or nothing is in writing. It’s all in the exploratory stage.
        </p>
        <p>
          <strong>Pre-Approval:</strong> At this point, the buyer has filled out paperwork, provided proof of the down payment and income, and OK’d the lender to do a credit check.
          The lender typically tells the buyer how much mortgage he or she can afford. The mortgage plus the down payment adds to up to the total
          purchase price, or, how much house the buyer can afford. You don’t need to see all the details, but if the buyer claims to be pre-approved,
          ask to see the top-line paperwork that proves that he or she can afford your house.
        </p>
        <p>
          <strong>Commitment Letter:</strong> This letter confirms that the buyer has proven to the lender that he or she can take on a mortgage of a certain amount,
          assuming that no other financial factors change. Now, the buyer has to find the right house and negotiate a price that will also survive the scrutiny of a
          real estate appraiser (all lenders require independent appraisals of houses to ensure that the purchase price is in line with the local market).
        </p>
        <strong>Buyer’s Financial Information Form</strong>
        <p>
          Chances are that your buyer is working with a local real estate agent. Agents are familiar with buyer’s information forms, and a good agent will have collected
          the data from her client in advance.
        </p>
        <p>
          Use the buyer’s information form below to request qualifying financial data from the potential buyer. The buyer should complete the form and return it to you with a
          copy of prequalification (if there is proof), preapproval, or the commitment letter.
        </p>
        <p>
          It costs time and money all around when a deal falls through because of a misunderstanding about a buyer’s actual resources. Collecting proof of ability to pay saves
          everyone time and, potentially, disappointment.
        </p>
        <a className="download-link" href={ ImagesUrl.BUYER_QUALIFICATION } target="_blank" rel="noopener noreferrer">Download Buyer Qualification Form</a>
      </>
    )
  }
}

export default ContractsClosing;
