import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import CurrencyFormat from 'react-currency-format';
import LogRocket from 'logrocket';
import {
  activeListingFormIndexSelector,
  fetchingListingFormsSelector,
  listingFormsFieldsSelector,
  listingFormsSelector,
  submitingListingformsSelector,
  totalSavingSelector,
  draftListingIdSelector,
  listingProgressSelector,
  listingIdSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import { CheckMarkIcon } from '../../Shared/Components/Icons/CheckMarkIcon';
import { Spinner } from 'react-bootstrap';
import ContractSignForm from '../Components/ContractsSignForm';
import { smoothScrollTop } from '../../../Utilities/commonFunctions';
import { ModalDiv } from '../../../Assets/Style/commonStyleComponents';
import { LYFSteps, editListingValue, formLabelValue, formType } from '../../../Constants/CommonConstants';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import ListingPreviewMobileContainer from '../Components/ListingPreviewMobileContainer';
import Routes from '../../../Constants/Routes';
import { history } from '../../../Utilities/History';
import { gTag, scrollToFirstErroField } from '../../../Utilities/utils';
import { EmbeddedYoutubeHintsPlayer } from '../Components/EmbeddedYoutubeHintsPlayer';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 1.5rem;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  color: #5b605c;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const TotoalSavingValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalSavingText = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
  padding-bottom: 0;
  align-self: center;
`;

const InfoText = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
  font-weight: 300;
`;

const AddresText = styled(InfoText)`
  font-size: 12px;
  line-height: 16px;
`;

const TotolSavingPercentText = styled(AddresText)`
  padding-bottom: 0;
`;

const TotoalSavingValueText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  margin-left: 0.5rem;
`;

const AveragePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  justify-content: center;
`;

const TotalSavingContainer = styled(AveragePriceContainer)`
  background: ${({ theme }) => theme.lightGreen};
  justify-content: space-between;
  padding: 1.5rem;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const MobileSkipButton = styled(SubmitButton)`
  background: none;
  min-width: 8rem;
  display: none;
  align-self: center;
  border-width: 1px;
  border-style: dashed;
  font-weight: 800;
  width: 100%;
  margin-right: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

const SkipButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const TopSkipButton = styled(SkipButton)`
  align-self: flex-start;
  font-size: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

const FormsContainer = styled.div`
  border-width: 1px;
  border-style: dashed;
  border-radius: 8px;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  margin-bottom: 3rem;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

const Separator = styled.div`
  height: 0px;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #e9eae9;
  margin-left: 10%;
  margin-right: 10%;
`;
const FormIconContainer = styled.div`
  padding-right: 0.75rem;
`;

const FormTitle = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
`;

const FormSignInButton = styled(SubmitButton)`
  height: 2.75rem;
  background-color: #242b26;
  margin-top: 0rem;
  color: #ffffff;
  &:disabled {
    background-color: #e9eae9;
    color: #242b26;
  }
`;

const FormTitleContainer = styled.div`
  display: flex;
`;

const Loader = styled(Spinner)`
  color: ${({ theme }) => theme.dark};
  align-self: center;
  margin: 4px;
`;

const BackButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const PopUpContent = styled.div`
  ul {
    margin-left: 2rem;
    margin-top: 1rem;
  }
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const { date1, sellersig2, date2, sellersig3, date3, initials2 } = editListingValue;

const ContractsSign = () => {
  const draftListingId = useSelector(draftListingIdSelector);
  const listingId = useSelector(listingIdSelector);
  const submitingListingform = useSelector(submitingListingformsSelector);
  const [showListingPreview, setShowListingPreview] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormsFullfilled, setIsFormsFullfilled] = useState([]);
  const [showSingCount, setShowSignCount] = useState(2);
  const formFields = useSelector(listingFormsFieldsSelector);
  const listingForms = useSelector(listingFormsSelector);
  const activeListingFormIndex = useSelector(activeListingFormIndexSelector);
  const fetchingListingForms = useSelector(fetchingListingFormsSelector);
  const dispatch = useDispatch();
  const totalSaving = useSelector(totalSavingSelector);
  const [formInstructionsAccepted, setFormInstructionsAccepted] = useState(false);
  const [showDescriptionSuggestionsModal, setShowDescriptionSuggestionsModal] = useState(false);
  const listingProgress = useSelector(listingProgressSelector);
  const submitFormsButtonDisabled = !listingProgress?.step4?.completed || !listingProgress?.step5?.completed;
  const isFormSigned = listingForms.some(
    (item) => (item['formId'] === 1 || item['formId'] === 6) && item['formStatus'] === 3 // formStatus === 3 means that form already submitted/signed ??
  );

  useEffect(() => {
    dispatch(onboardingActionCreatorsV2.getLisitngProgress());
    dispatch(onboardingActionCreatorsV2.getListingFormsV2(listingId));
  }, []);

  useEffect(() => {
    if (Number.isInteger(activeListingFormIndex) || !listingForms.length) {
      return;
    }
    let formsReadyForSubmit = [];
    listingForms.forEach((form, index) => {
      formsReadyForSubmit.push(!validateForm(form));
    });
    setIsFormsFullfilled(formsReadyForSubmit);
  }, [listingForms, activeListingFormIndex]);

  useEffect(() => {
    if (!Number.isInteger(activeListingFormIndex) || !listingForms.length) {
      return;
    }

    listingForms[activeListingFormIndex].groups.forEach((group) => {
      group.sections.forEach((section) => {
        section.fields.forEach(({ value }) => {
          if (typeof value === 'string' && value.toLowerCase() === editListingValue.oneOwner) {
            setShowSignCount(1);
          } else if (typeof value === 'string' && value.toLowerCase() === editListingValue.twoOwner) {
            setShowSignCount(2);
          } else if (typeof value === 'string' && value.toLowerCase() === editListingValue.threeOwner) {
            setShowSignCount(3);
          }
        });
      });
    });
  }, [listingForms, activeListingFormIndex]);

  const submitMLSForm = async () => {
    dispatch(
      onboardingActionCreatorsV2.submitMLSFormV2(draftListingId, () => {
        gTag({ event: 'listing contracts signed' });
        history.push(Routes.DASHBOARD);
      })
    );
  };

  const handleFinishListing = () => {
    if (Number.isInteger(activeListingFormIndex)) {
      if (validateForm()) { return; }
      dispatch(onboardingActionCreatorsV2.setActiveListingFormIndex(null));
    } else {
      if (submitFormsButtonDisabled || !isFormsFullfilled.every((i) => i)) {
        setShowDescriptionSuggestionsModal(true);
        return;
      }
      if (isFormsFullfilled.every((i) => i)) {
        submitMLSForm();
      }
    }
  };

  const validateForm = (form = null) => {
    const tempErrors = {};
    (form || listingForms[activeListingFormIndex]).groups.forEach((group) => {
      group.sections.forEach((section) => {
        section.fields.forEach(({ displayName, formFieldId, required }) => {
          if (displayName === date1 || displayName === date2 || displayName === date3) {
            return;
          }

          if (
            (showSingCount < 2 && displayName === sellersig2) ||
            (showSingCount < 3 && displayName === sellersig3) ||
            (showSingCount < 2 && displayName === initials2)
          ) {
            return;
          }

          if (required && !formFields[formFieldId]?.value && !formFields[formFieldId]?.prefillText) {
            tempErrors[formFieldId] = true;

            const fieldNode = document.querySelector(`[name="${formFieldId}"]`);
            if (!fieldNode && Number.isInteger(activeListingFormIndex)) {
              LogRocket.captureException(new Error('broken_property_details_form_detected'), {
                tags: {
                  errorType: 'broken_property_listing_form_detected',
                },
                extra: {
                  type: 'PROPERTY LISTING FORM VALIDATION FAILED',
                  formId: (form || listingForms[activeListingFormIndex]).formId,
                  formFieldId,
                  formFieldName: displayName,
                  formGroupSectionId: section.formGroupSectionId,
                },
              });
            }
          }
        });
      });
    });
    setErrors(tempErrors);
    const errorsArr = Object.keys(tempErrors);

    if (Number.isInteger(activeListingFormIndex) && errorsArr.length > 0) {
      scrollToFirstErroField(errorsArr);
    }
    
    return errorsArr.length > 0;
  };

  const openForm = (formIndex) => {
    setErrors({});
    dispatch(onboardingActionCreatorsV2.setActiveListingFormIndex(formIndex));
  };

  const closeFormInstruction = () => {
    setFormInstructionsAccepted(true);
    smoothScrollTop();
  };

  const handleFinishRequiredSteps = () => {
    if (!listingProgress?.step4?.completed) {
      dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_DETAILS));
      return;
    }
    if (!listingProgress?.step5?.completed) {
      dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_PHOTOS));
      return;
    }

    setShowDescriptionSuggestionsModal(false);

  };

  const getFormName = (form) => {
    let labelName = formLabelValue.additional;
    const priorityCount = Math.floor(form.groups[0]?.priority / 1000) || 0;

    if (form.groups[0].name.toLowerCase().includes(formType.listingForm) && priorityCount === 0) {
      labelName = formLabelValue.listingContract;
    } else if (priorityCount === 2) {
      labelName = formLabelValue.agencyDisclosure;
    } else if (priorityCount === 3) {
      labelName = formLabelValue.listingContract;
    } else if (priorityCount === 4) {
      labelName = formLabelValue.additional;
    } else if (priorityCount === 5) {
      labelName = formLabelValue.propertyDisclosure;
    } else if (priorityCount === 1 || form.groups[0].name.toLowerCase().includes(formType.inputSheet)) {
      labelName = formLabelValue.propertyData;
    }

    return labelName;
  };

  return (
    <Container>
      {showListingPreview ? (
        <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
      ) : (
        <>
          <FormContainer>
            <TitleContainer>
              <SubTilteText>List your property</SubTilteText>
              <StepsText>9/9</StepsText>
            </TitleContainer>
            <TitleText>Sign your contracts</TitleText>
            {!Number.isInteger(activeListingFormIndex) ? (
              <Text>One last step, before your property goes live. Please sign the following documents.</Text>
              ) : (
                <TopSkipButton
                type="button"
                onClick={() => dispatch(onboardingActionCreatorsV2.setActiveListingFormIndex(null))}
                >
                {'< Back'}
              </TopSkipButton>
            )}
            {!Number.isInteger(activeListingFormIndex) && (<EmbeddedYoutubeHintsPlayer mobile={true} width="296" height="536" />)}
            {Number.isInteger(activeListingFormIndex) && (
              <ContractSignForm
                openFormInstruction={() => setFormInstructionsAccepted(false)}
                errors={errors}
                setErrors={setErrors}
                showSingCount={showSingCount}
                setShowSignCount={setShowSignCount}
              />
            )}
            {!listingForms.length && fetchingListingForms && <Loader animation="border" size="sm" />}
            {listingForms.length > 0 && !Number.isInteger(activeListingFormIndex) && (
              <FormsContainer>
                {listingForms.map((form, index) => (
                  <div key={`${form.formId}_${index}`}>
                    <FormItem>
                      <FormTitleContainer>
                        <FormIconContainer>
                          <CheckMarkIcon color={isFormsFullfilled[index] ? '#242B26' : '#E9EAE9'} />
                        </FormIconContainer>
                        <FormTitle>{getFormName(form)}</FormTitle>
                      </FormTitleContainer>
                      <FormSignInButton onClick={() => openForm(index)}>Sign the Contract</FormSignInButton>
                    </FormItem>
                    {index !== listingForms.length - 1 && <Separator />}
                  </div>
                ))}
              </FormsContainer>
            )}
            {totalSaving > 0 && (
              <TotalSavingContainer>
                <TotalSavingText>Your potential savings by listing with Unreal Estate</TotalSavingText>
                <TotoalSavingValueContainer>
                  <TotolSavingPercentText>3%</TotolSavingPercentText>
                  <TotoalSavingValueText>
                    <CurrencyFormat
                      prefix="$"
                      value={totalSaving}
                      thousandSeparator=","
                      decimalSeparator="."
                      displayType="text"
                      suffix=".00"
                    />
                  </TotoalSavingValueText>
                </TotoalSavingValueContainer>
              </TotalSavingContainer>
            )}
            {!Number.isInteger(activeListingFormIndex) && (
              <MobileSkipButton
                type="button"
                onClick={() => history.push(Routes.DASHBOARD)}
                disabled={submitingListingform}
              >
                Skip for now
              </MobileSkipButton>
            )}
            <ButtonsContainer>
              {!Number.isInteger(activeListingFormIndex) ? (
                <>
                  <MobilePreviewButton
                    onClick={() => {
                      setShowListingPreview(true);
                    }}
                  >
                    <EyeButton />
                  </MobilePreviewButton>
                  <SkipButton
                    type="button"
                    onClick={() => history.push(Routes.DASHBOARD)}
                    disabled={submitingListingform}
                  >
                    Skip for now
                  </SkipButton>
                  <BackButton
                    disabled={submitingListingform}
                    type="button"
                    onClick={() => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_TITLE))}
                  >
                    Back
                  </BackButton>
                </>
              ) : (
                <SkipButton
                  type="button"
                  onClick={() => dispatch(onboardingActionCreatorsV2.setActiveListingFormIndex(null))}
                >
                  Back
                </SkipButton>
              )}
              <SubmitButton type="button" disabled={fetchingListingForms || isFormSigned} onClick={handleFinishListing}>
                {submitingListingform ? <Loader animation="border" size="sm" /> : 'Continue'}
              </SubmitButton>
            </ButtonsContainer>
          </FormContainer>
          {listingForms.length && !formInstructionsAccepted && listingForms[0]?.formInstruction?.description && (
            <ModalDiv
              title={`MLS ${listingForms[0]?.name || ''}`}
              cancelButtonProps={{ style: { display: 'none' } }}
              visible={!formInstructionsAccepted}
              closable={false}
              maskClosable={false}
              onOk={closeFormInstruction}
              onCancel={closeFormInstruction}
              okText="I have read the above instructions"
              destroyOnClose={true}
              className="text-modal listing-contract-modal"
            >
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: listingForms[0]?.formInstruction?.description,
                  }}
                ></div>
              </div>
            </ModalDiv>
          )}
          {!Number.isInteger(activeListingFormIndex) && (
            <ListingPreviewDesktopContainer />
          )}
        </>
      )}
      <Modal
        title="Incomplete Listing"
        visible={showDescriptionSuggestionsModal}
        onCancel={handleFinishRequiredSteps}
        cancelText="Finish Now"
        cancelButtonProps={{ type: 'primary' }}
        onOk={() => setShowDescriptionSuggestionsModal(false)}
        destroyOnClose={true}
      >
        <PopUpContent>
          <strong>
            Signing the contract is the last step. We will enable signing once all prior data is collected on your
            listing. This is necessary because most MLSs fine a listing if not active on MLS within a limited time after
            the timestamp of the signature on the contract.
          </strong>
          <p>
            <strong>Required steps:</strong>
          </p>
          <ul>
            {!listingProgress?.step4?.completed && (
              <li>
                <strong>Provide property details </strong>
              </li>
            )}
            {!listingProgress?.step5?.completed && (
              <li>
                <strong>Provide Property photos </strong>
              </li>
            )}
            {!isFormsFullfilled.every((i) => i) && (
              <li>
                <strong>Sign your contracts </strong>
              </li>
            )}
          </ul>
        </PopUpContent>
      </Modal>
    </Container>
  );
};

export default ContractsSign;
