import React from 'react';
import { StaticContentWrapper, Container, Quote } from '../../../../Assets/Style/commonStyleComponents';
import MediaCoverageList from './MediaCoverageList';

import { PdfUrls } from '../../../../Constants/Images';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

class MediaCoverage extends React.Component {
  render() {
    return (
      <>
        <Navbar />
        <StaticContentWrapper className="press-media-coverage-text">
          <Container>
            <Quote>
              Latest Media Coverage of USRealty.com
            </Quote>
             <strong>Follow us @USRealtydotCom!</strong>
            <MediaCoverageList
                PdfUrls={PdfUrls}
            />
            <p>Download the story: <a href={PdfUrls.PRESS_29} target="_blank">
              The-great-realtor-rip-off-the-economist</a>
            </p>
          </Container>
        </StaticContentWrapper>
        <Footer />
      </>
    )
  }
}

export default MediaCoverage;
