import React from 'react';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { FooterLogo } from './Icons/FooterLogo';
import { CopyrightIcon } from './Icons/CopyrightIcon';
import { useSelector } from 'react-redux';
import { footerContentSelector, loadingFooterAndHeaderContentSelector } from '../../../Reducers/UIReducer';
import AppConsts from '../../../Constants/AppConsts';
import { normalizeId } from '../../../Utilities/normalizeId';

const  { buySideUrl } = AppConsts;

const Main = styled.div`
background-color: #252223;
`
const FooterWrapper = styled.div`
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  width: 100%;
  max-width: 80rem;
  margin-inline: auto;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5rem;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  @media(max-width: ${({ theme }) => theme.breakpoints.md}){
    flex-direction: column;
  }
`;

const SocialIcon = styled.a`
  &:not(:first-child) {
    margin-left: 2rem;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 3rem;
  margin-right: 3rem;
  width: 9rem;
  flex: 1 1 0%;
`;

const SocialIconsContainer = styled.div`
opacity: 0.6;
display: ${({ mobile }) => !mobile ? 'none' : 'flex'};
@media(min-width: ${({ theme }) => theme.breakpoints.lg}){
  display: ${({ mobile }) => mobile ? 'none' : 'flex'};
}

${({ mobile }) => mobile ? 
`
flex-direction: row;
margin-bottom: 3rem;
margin-top: calc(2rem * 1);
` : `
flex-direction: row;
margin-left: auto;
`}
`;

const SectionsWrapper = styled.div`
display: flex;
margin-left: unset;
flex: 3 1 0%;
flex-direction: column;
width: 100%;
@media(min-width: ${({ theme }) => theme.breakpoints.lg}){
  margin-left: auto;
  flex-direction: row;
  width: unset;
}
`;

const SectionsContainer = styled.div`
  display: grid;
  flex: 2 1 0%;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  width: 100%;
  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    grid-gap: 6rem;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    width: unset;
  }
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    grid-gap: 3rem;
  }
`;

const ContactsContainer = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: white;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-right: 0;
  padding-top: 3rem;
  font-weight: normal;
  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    margin-right: 6rem;
  }
`;

const SectionContainer = styled.div`
  flex-direction: column; 
`;

const SectionTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: white;
  margin-bottom: 1rem;
  @media(max-width: ${({ theme }) => theme.breakpoints.md}){
    margin-bottom: 1.5rem;
  }
`;

const SectionLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 22px;
  color: white;
  `;

const SectionExternalLink = styled.a`
  margin-inline: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  text-decoration: none;
  outline: transparent solid 2px;
  outline-offset: 2px;
  color: inherit;
  opacity: 0.6;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  &:hover {
    color: ${({ theme }) => theme.light} !important;
    text-decoration: underline;
  }
  `;
  
const Divider = styled.div`
width: 100%;
border-bottom-width: 1px;
border-bottom-style: dashed;
border-color: white;
opacity: 0.2;
`;

const BottomSectionWrapper = styled.div`
padding-top: 2rem;
padding-bottom: 2rem;
`;

const BottomSectionContainer = styled.div`
display: flex;
color: white;
`;

const CopyrightContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
font-size: 14px;
line-height: 22px;
opacity: 0.6;
@media(min-width: ${({ theme }) => theme.breakpoints.lg}){
  flex-direction: row;
  align-items: center;
}
`;

const Copyright = styled.div`
  color: white;
  margin-right: 0;
  margin-top: 0.75rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    margin-right: 1.5rem;
    margin-top: 0;
  }
`;

const CompanyNameContainer = styled.div`
    display: flex;
    align-items: center;
    order: -1;
  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    order: unset;
  }
`;

const CompanyName = styled.div`
    color: white;
    margin-left: 0.5rem;
`;

const ContactsLink = styled(Link)`
  color: ${({ theme }) => theme.light}
  font-family: National-Regular;
  &:not(:first-child) {
    margin-top: 1rem;
  }
  &:hover {
    color: ${({ theme }) => theme.light} !important;
    text-decoration: none;
  }
`;

const LegalContainer = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  color: white;
  opacity: 0.6;
  margin-top: 2rem;
  margin-bottom: 2rem;
    a {
      font-size: 12px;
      line-height: 18px;
      color: white;
      text-decoration-line: underline;
      &:hover {
        color: white;
        text-decoration-line: underline;
      }
    }

    p {
      margin-bottom: 0;
      padding-top: 20px;
    }
`;

  const SocialIcons = ({ socialIcons, mobile }) => {
    if (!socialIcons)  { return null }
    return (
      <SocialIconsContainer mobile={mobile}>
      {socialIcons.map((singleIcon) => {
        if (!singleIcon.link || !singleIcon.image) {
          return null;
        }

        return (
          <SocialIcon href={singleIcon.link?.url} aria-label={singleIcon.link?.name} key={singleIcon.link?.url}>
            <img src={singleIcon.image?.asset.url} alt={singleIcon.image?.altTag} width={24} height={24} />
          </SocialIcon>
        );
      })}
      </SocialIconsContainer>
    );
  }

const gertUrl = (url) => url?.includes('http') ? url : `${buySideUrl}${url}`

const Footer = () => {
  const footerContent = useSelector(footerContentSelector);
  const footerContentLoading = useSelector(loadingFooterAndHeaderContentSelector);

  return (
    <Main>
      <FooterWrapper>
          <ContentContainer>
            <LogoContainer>
              <FooterLogo/>
              <ContactsContainer>
                <ContactsLink
                  to="#"
                  href={`mailto:${footerContent?.email}`}
                >
                  {footerContent?.email}
                </ContactsLink>
                <ContactsLink
                  to="#"
                  href={`tel:${footerContent?.phoneNumber}`}
                >
                  {footerContent?.phoneNumber}
                </ContactsLink>
              </ContactsContainer>
            </LogoContainer>
            <SectionsWrapper>
              {!footerContentLoading && (
                <SectionsContainer>
                  {footerContent?.sections?.map((singleSection) => (
                    <SectionContainer key={singleSection.title}>
                    <SectionTitle>
                      {singleSection.title}
                    </SectionTitle>
                    <SectionLinksContainer>
                      {singleSection.links.map((singleLink) => (
                        <SectionExternalLink
                          aria-label={singleLink.name}
                          href={gertUrl(singleLink.url)}
                          key={singleLink.name}
                          id={normalizeId(singleLink.name, "bott")}
                        >	
                          {singleLink.name}
                        </SectionExternalLink>
                      ))}
                      </SectionLinksContainer>
                    </SectionContainer>
                    ))}
                </SectionsContainer>
              )}
            </SectionsWrapper>
        </ContentContainer>
        {footerContent?.footerLegal?.html && (
        <LegalContainer dangerouslySetInnerHTML={{ __html: footerContent.footerLegal.html }}>
        </LegalContainer>
        )}
          <SocialIcons mobile socialIcons={footerContent?.socialIcons} />
          <Divider/>
          <BottomSectionWrapper>
            <BottomSectionContainer>
              <CopyrightContainer>
                <Copyright >
                  {footerContent?.copyright}
                </Copyright>
                <CompanyNameContainer>
                <CopyrightIcon/>
                <CompanyName>
                  {footerContent?.companyName}
                </CompanyName>
                </CompanyNameContainer>
              </CopyrightContainer>
              <SocialIcons socialIcons={footerContent?.socialIcons}/>
            </BottomSectionContainer>
          </BottomSectionWrapper>
      </FooterWrapper>
    </Main>
  );
};

export default withTheme(Footer);