import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions';
import AdminSiderMenu from '../Components/AdminSiderMenu';
import AdminHeader from '../Components/AdminHeader';
import AddEditForm from '../Components/ListingForm/AddEditForm';
import Enums from '../../../Constants/Enums';
import { userActionCreators } from '../../../Actions/UserActions';
import { history } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import { adminPageTitles } from '../../../Constants/CommonConstants';
const { Content } = Layout;

export class AddEditFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      entity: '',
      api: null,
    };
    this.prevTitle = '';
  }

  componentDidMount() {
    this.props.getLookup(this.props.activeEntity);
    this.getSelectedItem(false);
    const pageType = this.props.match?.params?.formId < 1
    ? 'addForm'
    : 'editForm';
    this.prevTitle = document.title;
    document.title = adminPageTitles[pageType]
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  getSelectedItem = (isClone) => {
    const paramsValue = new URLSearchParams(window.location.search);
    const formSectionId = paramsValue.get('formSectionId');
    if (this.props.match?.params?.formId > 0) {
      this.props.getSelectedItemDetail(
        Number(this.props.match.params.formId),
        this.props.activeEntity,
        formSectionId,
        isClone, (data) => {
          if(data.formGroupSectionId)
          {
            history.push(`${Routes.ADMIN_ADD_EDIT_FORM.replace(':formId',data?.formGroupId)}?formSectionId=${data?.formGroupSectionId}`);
          }else{
            history.push(`${Routes.ADMIN_ADD_EDIT_FORM.replace(':formId',data?.formGroupId)}`);
          }
          this.props.getSelectedItemDetail(
            Number(data?.formGroupId),
            this.props.activeEntity,
            data?.formGroupSectionId)
        }
      );
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  cloneAction = () => {
    this.getSelectedItem(true);
  }

  render() {
    const {
      history,
      lookup,
      isFetching,
      isDetailFetching,
      selectedFormDetails,
      match,
    } = this.props;

    const { path } = match;
    const { collapsed } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <AdminSiderMenu
          path={path}
          onCollapse={this.onCollapse}
          history={history}
          collapsed={collapsed}
        />
        <Layout>
          <Layout.Header
            style={{ background: '#fff', minHeight: 52, padding: 0 }}
          >
            <AdminHeader
              collapsed={this.state.collapsed}
              toggle={this.toggle}
            />
          </Layout.Header>
          <Content>
            {!isDetailFetching && !isFetching && (
              <AddEditForm
                selectedFormDetails={selectedFormDetails}
                errors={this.props.userErrors}
                lookup={lookup}
                isNewForm={parseInt(match?.params?.formId) < 1}
                cloneAction={this.cloneAction}
              />
            )}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path, params } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[Enums.NUMERIC_VALUE.TWO],
    selectedFormDetails: params?.formId > 0 ? state.admin.selectedItemData || {} : {},
    userErrors: state.admin.errors || {},
    lookup: state.admin.lookup || {},
    isDetailFetching: state.admin.isDetailFetching,
    isFetching: state.admin.isFetching,
    s3Identity: state.user.s3Identity,
    appSettings: state.user.appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...adminActionCreators, ...userActionCreators },
    dispatch,
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEditFormContainer),
);
