import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Helmet } from "react-helmet";

import { sellerNotificationActionCreators } from '../../../Actions/SellerNotificationActions';
import { checkingMlsSelector, mlsIdSelector } from '../../../Reducers/SellerNotificationReducer';
import { useTitle } from '../../../Utilities/Hooks';
import Footer from '../../Shared/Components/Footer';
import Navbar from '../../Shared/Components/Navbar';
import MlsIdForm from '../Components/MlsIdForm';
import PersonalInfoForm from '../Components/PersonalInfoForm';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  align-items: baseline;
  justify-content: flex-start;
  padding-right: 0.5rem
  padding-left: 0.5rem
  min-height:400px;

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    padding-right: 1.5rem
    padding-left: 1.5rem
  }
`;

const Text = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0.25rem;
  color: ${({ error }) => error ? '#E53E3E': 'inherit'};
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
`;

const OfferSubmit = () => {
  const dispatch = useDispatch();
  const mlsId = useSelector(mlsIdSelector);
  const checkingMls = useSelector(checkingMlsSelector);

  const { mlsid: paramsMlsid } = useParams();
  useTitle('Submit an offer | Unreal Estate');
  
  useEffect(() => {
    if (paramsMlsid && paramsMlsid !== mlsId) {
      dispatch(sellerNotificationActionCreators.сheckMls(paramsMlsid));
    }
  }, [paramsMlsid])

  return (
    <>
        <Helmet>
          <meta name="description" content="We’re making it easier to buy or sell unreal homes. Submit an offer on a property listed by Unreal Estate today."/>
				</Helmet>
      <Navbar/>
      <ContentContainer>
      <h1>Submit an offer</h1>
        {!paramsMlsid ? (
          <>
            <Text>Enter the MLS ID of the property you wish to submit an offer on.</Text>
            <MlsIdForm />
          </>
        ) : (
          <>
            {mlsId ? (
              <Text>
                {checkingMls ? (
                  <Loader animation="border" size="sm" />
                ) : (
                  <Text>MLS id: {mlsId}</Text>
                )}
              </Text>
            ) : (
              <Text error>MLS not found</Text>
            )}
            <PersonalInfoForm />
          </>
        )}
      </ContentContainer>
      <Footer/>
    </>
  );
}; 

export default OfferSubmit;