import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import v8n from 'v8n';
import { userActionCreators } from '../../../Actions/UserActions';
import {MidBoxWrapper, MidBox, HeadTextWrap, Heading, HeadText, InputFlex, CustomButton, Tick
} from '../../../Assets/Style/commonStyleComponents';
import { regexPattern, validationMessages } from '../../../Constants/CommonConstants';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

class ForgotPassword extends React.Component {

    state = {
        email: {
            rules: [{
            rule: v8n().not.empty().not.null(),
            message: validationMessages.email,
            },
            {
            rule: v8n().pattern(regexPattern.EMAIL),
            message: validationMessages.validEmail,
            }],
            value: ""
        },
        isEmailSent: false,
    };

    handleFieldChanged = (e) => {
        let email = { ...this.state.email };
        email.value = e.target.value;
        this.setState({ email: { ...email } });
      }

    handleFieldBlur = (e) => {
        this.validateEmailField(e.target.value);
    }

    validateEmailField = (value) => {
        let email = { ...this.state.email };
        let isError = false;
        if (value) {
            const  isEmailValid = v8n().pattern(regexPattern.EMAIL).test(value);
            if(!isEmailValid){
                email.error = validationMessages.validEmail;
                isError = true;
            } else {
                email.error = null;
            }

        } else {
            email.error = validationMessages.email;
            isError = true;
        }
    
        this.setState({ email });
    
        return isError;
      }

    submitFormData = (email) => {
        
        this.props.forgotPasswordRequest({email}, () => {
            const emailValue = { ...this.state.email };
            emailValue.value = '';
            this.setState({ 
                isEmailSent:  true,
                email: {...emailValue}
            })
        });
    }

    validateAndSubmit = (e) => {
        e.preventDefault();
        if (!this.validateEmailField(this.state.email.value)) {
            this.submitFormData(this.state.email.value);
        }
    }


    render() {
        const { isEmailSent } = this.state;
        return (
          <React.Fragment>
            <Navbar />
            <MidBoxWrapper>
              <MidBox>
                {
                  !isEmailSent
                  ?
                <div>
                  <HeadTextWrap>
                    <Heading>Forgot Password</Heading>
                    <HeadText>Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.</HeadText>
                  </HeadTextWrap>
                  <InputFlex
                    name="email"
                    title="Email"
                    type="email"
                    value={this.state?.email?.value}
                    error={this.state?.email?.error}
                    onBlur={this.handleFieldBlur}
                    onChange={this.handleFieldChanged}
                  />
                  <CustomButton type="submit" className="block" onClick={this.validateAndSubmit}>Submit</CustomButton>
                </div>
                :
                <div>
                  <HeadTextWrap className="email-sent-wrapper">
                    <Tick><i className="fa fa-check" aria-hidden="true"></i></Tick>
                    <Heading>Email Sent Successfully</Heading>
                    <HeadText>A password reset email has been sent to the email address on file for your account, but may take several minutes to show up in your inbox. Please wait at least 10 minutes before attempting another reset.</HeadText>
                  </HeadTextWrap>
                </div> 
                }
              </MidBox>
            </MidBoxWrapper>
            <Footer />
          </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
  return {
      userDetails: state.user.userDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
