import LogRocket from 'logrocket';
import { startLoading, endLoading } from '../../LoaderActions.js';
import { removeNullUndefined } from '../../../Utilities/commonFunctions.js';
import { buyerLeadsService } from '../../../Services/V2/buyerLeadsService.js';
import { buyerLeadsFiltersDefault } from '../../../Reducers/V2/BuyerLeadsReducer.js';

export const buyerLeadsActions = {
    GET_BUYER_LEADS_RESOLVED: 'GET_BUYER_LEADS_RESOLVED',
    GET_BUYER_LEADS_FAILED: 'GET_BUYER_LEADS_FAILED',
    SET_BUYER_LEADS_FILTERS: 'SET_BUYER_LEADS_FILTERS',
    CLEAR_BUYER_LEADS_FILTERS: 'CLEAR_BUYER_LEADS_FILTERS',
};

export const buyerLeadsCreators = {
    setbuyerLeadsFilters: (filters) => async (dispatch) => {
        dispatch({ type: buyerLeadsActions.SET_BUYER_LEADS_FILTERS, payload: filters });
    },
    getbuyerLeads: (params = buyerLeadsFiltersDefault) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const paramsObj = params;
            const { data } = await buyerLeadsService.getBuyerLeads(removeNullUndefined(paramsObj));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: buyerLeadsActions.GET_BUYER_LEADS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_titlecompany_action',
                },
                extra: {
                    type: buyerLeadsActions.GET_BUYER_LEADS_FAILED,
                },
            });
        }
    },
    clearPropertiesFilters: () => async (dispatch) => {
        dispatch({ type: buyerLeadsActions.CLEAR_BUYER_LEADS_FILTERS });
        dispatch(buyerLeadsCreators.getbuyerLeads());
    },
};
