import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TablecellWithFallback = ({ overWrittenValue, children }) => {
  const overWritten = Array.isArray(overWrittenValue)
    ? overWrittenValue.length > 0
    : !!overWrittenValue;

  return (
    <Container>
      {children}
      {overWritten && <Tag>Overwritten</Tag>}
    </Container>
  );
};
