import { TitleCompaniesActions } from '../../Actions/V2/Admin/TitleCompaniesActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const titleCompaniesFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    sortBy: null,
    desc: null,
}

const initialState = {
    titleCompanies: [],
    titleCompany: null,
    totalCount: 0,
    filters: titleCompaniesFiltersDefault
};

export const adminTitleCompaniesReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case TitleCompaniesActions.SET_TITLE_COMPANIES_FILTERS:
            return { ...state, filters: action.payload };
        case TitleCompaniesActions.CLEAR_TITLE_COMPANIES_FILTERS:
            return { ...state, filters: { ...titleCompaniesFiltersDefault } };
        case TitleCompaniesActions.TITLE_COMPANIES_RESOLVED:
        case TitleCompaniesActions.TITLE_COMPANIES_FAILED:
            return { ...state, titleCompanies: action.payload.results, totalCount: action.payload.total };
        case TitleCompaniesActions.TITLE_COMPANY_RESOLVED:
            return { ...state, titleCompany: action.payload };
        case TitleCompaniesActions.CLEAR_TITLE_COMPANY:
            return { ...state, titleCompany: null };
        default:
            return state;
    }
};

export const adminTitleCompaniesSelector = state => state.adminTitleCompanies.titleCompanies;
export const adminTitleCompaniesCountSelector = state => state.adminTitleCompanies.totalCount;
export const titleCompaniesFiltersSelector = state => state.adminTitleCompanies.filters;
export const titleCompanySelector = state => state.adminTitleCompanies.titleCompany;