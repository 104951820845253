import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import v8n from 'v8n';
import styled from 'styled-components';

import ListingPreviewDesktopContainer from './ListingPreviewDesktopContainer';
import { ModalDiv } from '../../../Assets/Style/commonStyleComponents';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { requestedVoucherSelector, voucherNotFoundSelector } from '../../../Reducers/OnboardingReducerV2';
import VoucherRedeemConfirmation from '../Modals/VoucherRedeemConfirmation';
import { setStateToUrl } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import { useTitle } from '../../../Utilities/Hooks';
import { gTag } from '../../../Utilities/utils';

const Container = styled.div`
  display: flex;
  width: auto; 
  height: 100%;
  display:flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background:  'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    min-width: 20rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    margin-right: 2rem;
    margin-left: 4rem;
  }
  
  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const InputContainer = styled.div`
  min-width: 20rem;
  position: relative;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: auto;

  input, textarea, select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    border-color: #242B26;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  textarea {
    padding-top: 0.75rem;
    height: auto;
  }
  input:disabled {
    background-color:  ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  input:disabled+label {
    background-color:  ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }
  i {
    color: #242B26;
    opacity: 0.75;
  }
  input::placeholder, textarea::placeholder, select {
    color: #242B26;
    opacity: 0.75;
  }
  &.invalid {
    input, textarea, select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label, select {
      color: #F56565;
    }
  }
  
  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    min-width: 24rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    min-width: 26rem;
    margin-right: 2rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-width: 28rem;
  }
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) => smallLabel ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `: ''}

    ${({ select }) => select ? `
    display: none;
    &:has(+ (select(select:focus)) {
    display: block;
    }
    `: ''}
`;

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[500]};
    margin-top: 0.5rem;
    font-size: 0.875rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }
  
  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 10rem;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    justify-content: flex-end;
  }
`;

const BackButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;
`;

const google = window.google;
const placesService = new google.maps.places.PlacesService(document.createElement('div'));

const ListingVoucher = () => {
  const dispatch = useDispatch();
  const [showVoucherRedeemModal, setShowVoucherRedeemModal] = useState(false);
  const [voucherCode, setVoucherCode] = useState({
    rules: [{
      rule: v8n().not.empty().not.null(),
      message: "Please enter voucher code."
    }],
    value: new URLSearchParams(window.location.search).get('code') || '',
    error: '',
  });
  const userDetails = useSelector(userDetailsSelector);
  const voucher = useSelector(requestedVoucherSelector);
  const voucherNotFound = useSelector(voucherNotFoundSelector);

  useTitle('Claim Your Voucher | Unreal Estate');

  useEffect(() => {
    if (!voucher && !voucherNotFound) {
      return;
    }

    setShowVoucherRedeemModal(true);
  }, [voucher, voucherNotFound])

  const validateField = () => {
    let fields = { ...voucherCode };
    let isError = false;
    for (let i = 0; i < voucherCode.rules.length; i++) {
      let rule = voucherCode.rules[i];
      if (!rule.rule.test(voucherCode.value)) {
        fields.error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields.error = null;
    }

    setVoucherCode(pr => ({ ...pr, ...fields }));

    return isError;
  }

  const validateVoucher = () => {
    if (!validateField()) {
      dispatch(onboardingActionCreatorsV2.validateVoucherV2(voucherCode.value));
      gTag({
        'event': 'checkout',
        'pageName': 'Voucher'
      })
    }
  }

  const redeemVoucher = () => {
    setStateToUrl(Routes.ONBOARDING_V2, {
      placeId: voucher?.placeId,
      byVoucher: true
    });

    placesService.getDetails({
      placeId: voucher?.placeId,
    }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        dispatch(onboardingActionCreatorsV2.selectAddressV2(place))
        dispatch(onboardingActionCreatorsV2.redeemVoucherV2({ ...voucher }));
    }});
  }

  const handleFieldChanged = (e) => {
    e.persist();
    setVoucherCode(pr => ({
      ...pr,
      value: e.target.value,
    }));
  }

  const goBack = () => {
    setStateToUrl(Routes.ONBOARDING_V2, {});
    dispatch(onboardingActionCreatorsV2.setVoucherEnterV2(false));
  }

  const okButtonProps = (voucher?.isVoucherValid && (!userDetails?.email || userDetails?.email === voucher.email))
    ? {} : { style: { display: 'none' } };

  return (
    <Container>
      <>
        {showVoucherRedeemModal && <ModalDiv
          visible={true}
          okButtonProps={okButtonProps}
          title={voucherNotFound ? "Voucher Not Found" : "Voucher Redeem Confirmation"}
          onCancel={() => setShowVoucherRedeemModal(false)}
          maskClosable={false}
          className="text-modal redeem-modal"
          onOk={redeemVoucher}
          okText={'Redeem'}>
          <VoucherRedeemConfirmation
            voucher={voucher}
            userDetails={userDetails}
            voucherNotFound={voucherNotFound}
          >
          </VoucherRedeemConfirmation>
        </ModalDiv>}
        <FormContainer>
          <TitleContainer>
            <SubTilteText>
              Claim your voucher
            </SubTilteText>
          </TitleContainer>
          <TitleText>
            Vouchers
          </TitleText>
          <Text>
            Enter your voucher code below to claim your voucher.
          </Text>
          <>
            <InputContainer className={voucherCode.error ? 'invalid' : ''}>
              <input
                placeholder=" "
                type="text"
                name="city"
                id="city"
                value={voucherCode.value}
                onChange={handleFieldChanged}
              />
              <InputLabel smallLabel={voucherCode.value}>
                Voucher
              </InputLabel>
              {voucherCode.error && (
                <ErrorMessage>
                  {voucherCode.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <ButtonsContainer>
              <BackButton
                type="button"
                onClick={goBack}>
                Back
              </BackButton>
              <SubmitButton
                type="button"
                onClick={validateVoucher}>
                Continue
              </SubmitButton>
            </ButtonsContainer>
          </>
        </FormContainer>
        <ListingPreviewDesktopContainer />
      </>
    </Container>
  );

};

export default ListingVoucher;