import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../Constants/CommonConstants';
import ApiUrls from '../Constants/ApiUrls';

class DashboardService {

    constructor() {

        let urls = ((window.config || {}).urls || {}).dashboard || {};

        urls.base = urls.base || "/";

        this.urls = urls;

    }

    getListings() {
        const url = ApiUrls.getUserListings;
        return fetch(url, {
          method: apiMethodType.GET,
          headers: {
            'Content-Type': contentType.applicationJson,
            ...authHeader()
          }
        });
      }

    getListing(listingId) {

        const url = this.urls.getListing || ApiUrls.getListing;

        return fetch(url, {
            method: apiMethodType.POST,
            data: { listingId },
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    saveBasicField(reqObj) {

        const url = this.urls.saveField || ApiUrls.saveBasicField;

        return fetch(url, {
            method: apiMethodType.POST,
            data: reqObj,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    saveFormField(userFormId, formFieldId, value) {

        const url = this.urls.saveField || ApiUrls.saveFormField;

        return fetch(url, {
            method: apiMethodType.POST,
            data: { userFormId, formFieldId, value: value ? `${value}` : null },
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    setLocal(key, object) {
        localStorage.setItem(key, JSON.stringify(object));
    }

    getLocal(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    clearLocal(key) {
        localStorage.removeItem(key);
    }

    submitMLSForm(reqObj) {

        const url = ApiUrls.submitMLSForm;
        return fetch(url, {
            method: apiMethodType.POST,
            data: reqObj,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        },10*60*10000);
    }

    publicViewListingDetail(listingId) {

        const url = ApiUrls.publicViewListingDetail;

        return fetch(url, {
            method: apiMethodType.POST,
            data: { listingId },
            headers: {
                'Content-Type': contentType.applicationJson,
            }
        });
    }

    gettingFormListing(reqObj) {

        const url = ApiUrls.getFormListing;

        return fetch(url, {
            method: apiMethodType.POST,
            data: reqObj,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    getWholeSaleListings(userId, reqObj) {

        const url = `${ApiUrls.getWholeSaleListings}${userId}`;

        return fetch(url, {
            method: apiMethodType.POST,
            data: { ...reqObj },
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    getNetworkUser(userId, reqObj) {

        const url = `${ApiUrls.getWholeSaleNetworkUser}${userId}`;

        return fetch(url, {
            method: apiMethodType.POST,
            data: { ...reqObj },
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    savePreviewFormField(reqObj) {

        const url = this.urls.saveField || ApiUrls.savePreviewFormField;

        return fetch(url, {
            method: apiMethodType.POST,
            data: reqObj,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    saveLatLong(reqObj) {

        const url = `${ApiUrls.updateLocation}`;
        return fetch(url, {
            method: apiMethodType.POST,
            data: reqObj,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }
    getLatLong(address) {

        const url = `${ApiUrls.getLatLong}/address=${address}`;
        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    deleteDraftListings(id) {
        const url = `${ApiUrls.deleteDraftListing}?id=${id}`;
        return fetch(url, {
            method: apiMethodType.DELETE,
            headers: {
            'Content-Type': contentType.applicationJson,
            ...authHeader()
            }
        });
    }

    updateTitleData(id, data) {
        const url = `${ApiUrls.updateTitleData}/${id}/title-company`;
        return fetch(url, {
            method: apiMethodType.PUT,
            data: data,
            headers: {
            'Content-Type': contentType.applicationJson,
            ...authHeader()
            }
        });
    }
}

export default DashboardService;
