import React, { memo } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { useDrop, useDrag } from 'react-dnd'

import { ItemTypes } from '../Containers/PropertyPhotos'
import { RemovePhotoIcon } from '../../Shared/Components/Icons/RemovePhotoIcon';


const PhotoGalleryPhotocontainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  min-height: 11rem;
  color: ${({ theme, error }) => error ? theme.colors.red[500] : theme.colors.green[900]};
  background-color:  ${({ theme }) => theme.gray};
  margin-top: 1rem;
  transition: opacity 0.2s;
  
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    min-height: 11rem;
    width: 47%;
    
    :nth-child(odd) {
      margin-right: 1.5rem;
    }
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    min-height: 12rem;
    width: 47%;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-height: 15rem;
    width: 48%;
  }
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
    position: absolute;
`

const RemovePhotoIconContainer = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    align-self: flex-end;
    opacity: ${({ disabled }) => disabled ? 0.4 : 1};
`;

const MakeCoverButton = styled.button`
    padding: 4px;
    position: absolute;
    top: 15px;
    left: 15px;
    align-self: flex-start;
    font-family: 'National-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: #1E2420;
    background: #DBE8E0;
    border-radius: 4px;
    opacity: ${({ disabled }) => disabled ? 0.4 : 1};
`;

const PhotoGalleryPhoto = styled.img`
  width: 100%;
  height: 11rem;
  object-fit: cover;
  border-radius: 8px;

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    min-height: 11rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    min-height: 12rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-height: 15rem;
  }
`;

export const PropertyPhoto = memo(({
  id,
  moveCard,
  findCard,
  imagesUploadingProgress,
  photo,
  removePhoto,
  deletingImages,
  changingImagesOrder,
  index,
  changeOrder,
}) => {
  if (index === 0) return null;

  const originalIndex = findCard(id).index
  
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveCard(droppedId, originalIndex)
        }
      },
    }),
    [id, originalIndex, moveCard],
    )
    const [, drop] = useDrop(
      () => ({
        accept: ItemTypes.CARD,
        hover({ id: draggedId }) {
          if (draggedId !== id) {
            const { index: overIndex } = findCard(id)
            moveCard(draggedId, overIndex)
          }
        },
        drop({ id: draggedId, originalIndex }) {
          const { index: overIndex } = findCard(id)
          if (originalIndex !== overIndex) {
            changeOrder(draggedId, overIndex)
          }
      },
    }),
    [findCard, moveCard],
    )

    
    return (
      <PhotoGalleryPhotocontainer
      ref={(node) => drag(drop(node))}
      draggable={true}
    >
      {!imagesUploadingProgress[index] && photo?.id && (
        <RemovePhotoIconContainer
          onClick={() => removePhoto(photo?.id)}
          disabled={deletingImages || changingImagesOrder}
        >
          <RemovePhotoIcon />
        </RemovePhotoIconContainer>
      )}
      {!imagesUploadingProgress[index ] && photo?.id && (
        <MakeCoverButton
          onClick={() => changeOrder(photo?.id, 0)}
          disabled={deletingImages || changingImagesOrder}
        >
          make cover Photo
        </MakeCoverButton>
      )}
      <PhotoGalleryPhoto
        style={{ opacity: imagesUploadingProgress[index] ? 0.5 : 1 }}
        src={photo?.url || null}
      />
      {imagesUploadingProgress[index] && (
        <Loader animation="border" />
      )}
    </PhotoGalleryPhotocontainer>
  );
});