import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { officeUsersSelector } from '../../../Reducers/LookupReducer';
import { propertiesCreators } from '../../../Actions/V2/Admin/PropertiesActions';

export const PropertyAssignedSelect = ({
  selectedOption,
  propertyId,
  propertyStatusId,
}) => {

  const dispatch = useDispatch();
  const officeUsers = useSelector(officeUsersSelector);

  const handleChange = e => {
    const { value } = e.target;

    const reqObj = {
      assignedListingStatusId : +value,
      propertyStatusId: propertyStatusId
    };


    dispatch(propertiesCreators.updatePropertyStatus(propertyId, reqObj));
  };

  return (
    <select value={selectedOption || undefined} onChange={handleChange}>
      {officeUsers.map((item, index) => (
        <option key={`${item.value} ${index}`} value={item.value}>{item.label}</option>
      ))}
    </select>
  );
};