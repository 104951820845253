import React from 'react';
import { Container, AccordionHeading, CustomAccordion, HeadTextWrap, Heading, HeadText } from "../../../../Assets/Style/commonStyleComponents";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

class Faq extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <CustomAccordion>
          <Container className="faq-container">
            <HeadTextWrap className="for-left">
              <Heading>FAQs</Heading>
              <HeadText>Frequently Asked Questions.</HeadText>
            </HeadTextWrap>
            <AccordionHeading><span>Buyers</span></AccordionHeading>
            <Accordion defaultActiveKey="0" className="faq-accordion">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Am I responsible for paying any part of the commission upon the purchase of a USRealty.com listing?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    No. The seller pays the commission at time of closing.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  Can I see a listed home without a realty agent?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    Yes. If you have not yet signed an agreement with a real estate agent, and are not currently
                    working with anyone you may communicate directly with the seller of the property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  How do I schedule to see a home listed at USRealty.com?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    When viewing a listing, click on the calendar icon above the photo of one of the listings in your search results and then
                    “request appointment.” On the calendar, click on the word “request” to select one of the available dates/times.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <AccordionHeading><span>General</span></AccordionHeading>
            <Accordion className="faq-accordion">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  Is the MLS the same Multi List that REALTORS use?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    Yes it is. Your listing goes into the same system.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  What’s the difference between USRealty.com and the MLS?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    USRealty.com is a website and broker that everyone on the internet can see and where all search engines will find your listing.
                    That is separate from your local Multiple Listing Service, which is a paid subscription service that real estate agents use to
                    look for properties to show buyers.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  What is a Multiple Listing Service, or MLS?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    A Multiple Listing Service (MLS) is a closed database of property listings in a certain geographic
                    area. This service makes it possible to share information about your listing to a broad base of
                    real estate brokers who may represent potential buyers interested in your home. Only licensed
                    member REALTORS® are allowed to add listings to any MLS, and USRealty.com is its customers’
                    listing broker for this purpose. When you put your home in an MLS, you reach a far broader range
                    of buyers than just posting a listing on the internet. Nationally known real estate listing sites
                    such as Zillow, Trulia, Realtor.com and others get their listings from continual feeds from
                    multiple listing services around the country.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  Will I know when it is there?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    Yes
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <AccordionHeading><span>Sellers</span></AccordionHeading>
            <Accordion className="faq-accordion">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="7">
                  What if my photos are not MLS approved?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    When we’re ready to Activate your listing on the MLS we may get notified by the MLS there is a
                    copyright claim preventing the use of professional photos or the need to have an exterior front
                    photo of the property and Activation is available once MLS approved photos are available. In this
                    case, you either have photos with a possible copyright or are lacking a front exterior photo
                    required by the MLS.
                    <h3>Here’s our recommendation:</h3>
                    <ul>
                      <li>
                        1. Consider emailing us a quick temporary photo of the front exterior from your phone to allow
                        us to activate your listing until you get the preferred final photos.
                      </li>
                      <li>
                        2. Email the party that took the photos you’ve submitted requesting they email you back
                        permission to use those photos in this listing. We can forward that permission on to the MLS
                        to gain approval to list the property with those professionally taken photos
                      </li>
                    </ul>
                    Did this advice help? If not, submit your question and get it answered,
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeBsHsZgSzNhCzhEx7JYsLqfQjXtAM9NI_WPaaCsUXbuAwxyw/viewform" target="_blank">here.</a>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="8">
                  Can the USRealty.com model work in conjunction with an auction?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    Yes, provided that you, the seller, follow MLS rules and do the following:
                    <ul>
                      <li>The list price must not be lower than your reserve price.</li>
                      <li>You must clearly in the remarks fully list the date bids are do by and any conditions.</li>
                      <li>The list price must include the commission that you are offering.</li>
                      <li>The agent procuring cause rules and our listing contract prevail over any auctioneer’s
                        conditions for whether a commission is owed to the broker for the buyer.</li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="9">
                  Can you help me list my house with an auction?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="9">
                  <Card.Body>
                    Yes! If you have a listing with us, we will be happy to work with you to also put your house through auction. Call our call center for more details.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="10">
                  Why do some houses not sell?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="10">
                  <Card.Body>
                    The top four deal-killers are: the asking price is too high; the offered commission is too low;
                    the seller is not available for showings and is unresponsive to buyers’ inquiries; or there is an
                    unaddressed material defect on the property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="11">
                  What are the market conditions in my area?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="11">
                  <Card.Body>
                    <a href="https://usrealty.com/?source=housepad" target="_blank">Click</a> here for free current market research in the top metro markets around the country.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="12">
                  Why do you direct agents to submit offers at www.2offer.us?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="12">
                  <Card.Body>
                    State regulators require that listing brokers present all offers, even for listing concepts such
                    as ours. This site provides an automated way for both you as the seller and us as the broker to
                    immediately receive a copy of the offer. This avoids delays which would be required for manual
                    processing on offers faxed or emailed to us.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="13">
                  Why must the buyer’s broker submit a W-9?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="13">
                  <Card.Body>
                    The buyer’s broker needs to submit his firm’s W-9 to our office sometime prior to closing, so that
                    the agent’s commission can be reported to the IRS for tax purposes.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="14">
                  I have some legal questions. Do you have any references to lawyers?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="14">
                  <Card.Body>
                    We recommend the site freeadvice.com for starters, as it contains the most extensive collection of
                    free legal advice that we have seen, as well as links to attorneys. Please note that we are not
                    attorneys nor affiliated with this website. We offer or take no responsibility for its content.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="15">
                  Why am I not receiving your emails?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="15">
                  <Card.Body>
                    You probably have email settings that are causing our emails to go to your junk or bulk folders.
                    Our customers with Yahoo and AOL have reported this problem the most. When you place an order
                    you’ll get our email address. Be sure to add our email to your address book to ensure that you
                    receive our communications.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="16">
                  How do I return the listing papers to you?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="16">
                  <Card.Body>
                    Using Internet Explorer with the Adobe Reader plugin on a PC, you will be able to electronically
                    sign and submit the documents. Otherwise, please complete offline, and either e-sign with DocuSign
                    or Adobe Reader. All other signatures need to be traditional with a pen. If not submitted on our
                    website, fax, mail, or scan and email the forms back to our Pennsylvania office.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="17">
                  How do I change my listing’s price?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="17">
                  <Card.Body>
                    Submit the listing change form under the “my account” section.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="18">
                  Whose phone number shows up with my listing at Realtor.com and Zillow?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="18">
                  <Card.Body>
                    Realtor.com always requires that the broker’s phone number be published with each listing. With
                    the standard USRealty.com package, our phone system plays your phone number for callers. For an
                    optional fee, our phone system can automatically forward calls to your phone number.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="19">
                  In plain English what do “seller’s agent” and “transaction licensee” mean?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="19">
                  <Card.Body>
                    A “seller’s agent” represents the seller’s interests. A”transaction licensee” does not represent the seller or the buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="20">
                  What commission rate to you recommend?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="20">
                  <Card.Body>
                    USRealty.com recommends offering a 2.5 percent to 3 percent commission for properties over $100,
                    000. Traditionally, buy-side agents receive a commission of 3 percent. When you offer the
                    commission they expect to earn, there is no barrier to showing your house and working with you.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="21">
                  Do agents contact USRealty.com customers trying to get them to sign a full-commission listing?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="21">
                  <Card.Body>
                    Yes, some agent troll USRealty.com listings to contact sellers and claim that agents won’t show
                    your house. This is unethical and ineffective, because USRealty.com is a licensed broker, with all
                    rights and responsibilities of all brokers who contribute to MLS systems. And, ultimately, agents
                    want to sell houses. Smart agents realize that houses come on the market through many types of
                    agencies. Househunters usually find houses on their own through the online listing sites that
                    showcase all MLS listings, including USRealty.com listings. A househunter doesn’t care what kind
                    of agency listed a house he wants to see, and smart agents realize that it doesn’t matter to them,
                    either, as long as they earn a commission for managing the buyer’s side of the transaction.
                    Finally, USRealty.com tracks the source of home sales, and has verified that many of those sales
                    are transacted with name-brand agencies and brokerages.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="22">
                  Will real estate agents want to show my house, knowing that it is listed through USRealty.com?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="22">
                  <Card.Body>
                    Agents need houses to sell, and USRealty.com streams thousands of houses onto local markets,
                    fueling sales for hundreds of agents. Agents and brokers will show your house if you offer a
                    competitive buy-side commission, typically 3 percent, and if your house is reasonably priced.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="23">
                  What if we want to pull our home off the market?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="23">
                  <Card.Body>
                    Even during the contract period, this is no problem. After all, it is your house! Simply send
                    USRealty.com a written cancellation request with all owners’ signatures and we will promptly
                    cancel your listing contract. In Georgia, a cancellation fee applies. And, please note that
                    USRealty.com respects the efforts of real estate professionals and will not cancel a listing for
                    the purposes of avoiding paying an agent an earned commission.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="24">
                  What if we later decide we want to list our house with a realty agent?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="24">
                  <Card.Body>
                    To shift to a different selling model, all
                    you have to do is cancel your USRealty.com listing before signing with a full-commission agent. We
                    can also refer you to an experienced agent, if you wish.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="25">
                  How many photos can I add to my listing?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="25">
                  <Card.Body>
                    You can upload up to 25 photos in your listing. The more photos you upload, the better! Photos
                    help homes sell.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="26">
                  How long will my listing be on the MLS?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="26">
                  <Card.Body>
                    Your property will remain visible on your local MLS for six months or until your home sells
                    (whichever comes first). At the end of six months, you may relist your home at a reduced listing
                    fee.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="27">
                  How do I communicate with potential buyers?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="27">
                  <Card.Body>
                    Emails from any interested parties will be delivered directly to your email inbox. From there, you
                    may respond directly to the prospective buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="28">
                  Will my contact information be visible on USRealty.com or any of my listings?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="28">
                  <Card.Body>
                    Your contact information will be visible on the MLS listing, but not at USRealty.com. However,
                    only agents with access to the MLS will be able to see that contact information. Agents who see
                    your house on the MLS will have your contact information and can contact you directly.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="29">
                  If there isn’t a traditional seller’s agent, who shows my home?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="29">
                  <Card.Body>
                    Once your listing is posted, to the extent your state permits, you will be primarily responsible
                    for the logistics of your sale. This includes: scheduling and showing your home to prospective
                    buyers, preparing and presenting any supplemental marketing materials, negotiating your deal and
                    cooperating in all closing procedures. You will have access to our showing scheduling software and
                    we also offer a concierge service that will manage your scheduling for you. Some states require
                    the listing broker to perform additional services, which we do,and are billable to you according
                    to the listing contract.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="30">
                  Will real estate agents want to show my house if I list it with USRealty.com?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="30">
                  <Card.Body>
                    They will if you  you are offering a competitive commission for the buyer’s
                    agent and the listing price is competitive. It is important for your house to be priced competitively.
                    A listing price that is too high will dramatically reduce the number of showings. Any agent that is going to
                    earn a buyer’s agent commission will be willing to show your home. For them it is no different than if it is listed with a competitor.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="31">
                  How much total commission will I owe if I sell the house I have listed at USRealty.com, without the
                  involvement of a buyer’s agent?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="31">
                  <Card.Body>
                    None! With one exception: in the Atlanta metro area, customers must pay a 0.12% (.0012) fee at
                    settlement for FMLS.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="32">
                  What do I get with a free listing?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="32">
                  <Card.Body>
                    A free listing includes a house listing that is posted on USRealty.com, as well as a listing in
                    your local MLS. This listing will be internet searchable from all search engines.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="33">
                  How much total commission will I owe if another broker or agent sells the house I have listed with
                  USRealty.com?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="33">
                  <Card.Body>
                    You decide the commission, from 1 percent to 4 percent. USRealty.com recommends offering buyers’
                    agents the traditional 2.5 or 3 percent buy-side commission. That means that you keep the 2.5 or 3
                    percent commission that usually goes to a listing agent, retaining that 3 percent of home equity
                    for yourself.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="34">
                  What commission rate to you recommend?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="34">
                  <Card.Body>
                    USRealty.com recommends offering a 2.5% commission for properties over $100,000. The more or less
                    you offer, the more or less incentive agents have to show your home. We have found that most
                    agents are comfortable with a 2.5% commission.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="35">
                  What happens after the contract expires?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="35">
                  <Card.Body>
                    Listings may be extended beyond the six months for a reduced fee.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="36">
                  Why is your price so low?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="36">
                  <Card.Body>
                    In most areas, USRealty.com does not need to an affiliate broker to list your house in your local
                    MLS, because USRealty.com is a member of that MLS. This means you pay only once to list your house
                    in the industry’s ‘secret weapon’ for selling homes. As well, USRealty.com has the advantage of
                    scale. As one of the largest flat-fee brokers in the country, we have an efficient operation that
                    handles thousands of listings annually…and we pass the savings on to you.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="37">
                  What is USRealty.com’s success rate?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="37">
                  <Card.Body>
                    Here’s how we calculate our success rate: We do not include sellers who cancel prior to six months
                    for two reasons. One is that it is hard to calculate because many early cancellations end up
                    relisting with us later. Secondly, it is necessary to make our success rate a more equal
                    comparison to traditional listing contracts that typically do not allow early cancellation and
                    tend to last six months. As of December 2015, our sellers had a success rate of 75%, compared to
                    an industry average of 81% in the same markets.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="38">
                  How long does it take for my listing to appear on USRealty.com?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="38">
                  <Card.Body>
                    Your listing will appear on USRealty.com immediately once the listing is completed.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="39">
                  How will I know when my listing goes public?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="39">
                  <Card.Body>
                    USRealty.com Customer Service will e-mail you your MLS sheet.  This sheet will include your
                    listing number. Two to 48 business hours after you’ve submitted your listing and MLS form to
                    USRealty.com, you will be able to view your listing at Realtor.com.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="40">
                  How long does it take to get my listing on the MLS?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="40">
                  <Card.Body>
                    Your listing will appear the same day or on the next business day, after you return the completed
                    forms to USRealty.com. Some MLS systems manually enter listings they receive from all sources,
                    including USRealty.com, a process that takes two extra days for activation. USRealty.com will
                    notify you if your MLS is in a region the requires extra time to activate listings.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <AccordionHeading><span>Unanswered</span></AccordionHeading>
            <Accordion>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="41">
                  Have a question that needs answered?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="41">
                  <Card.Body>
                    Submit your question and get it answered, <a target="_blank"
                     href="https://docs.google.com/forms/d/e/1FAIpQLSeBsHsZgSzNhCzhEx7JYsLqfQjXtAM9NI_WPaaCsUXbuAwxyw/viewform">here.</a>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Container>
        </CustomAccordion>
        <Footer />
      </div>
    )
  }
}

export default Faq;
