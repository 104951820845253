import React from 'react';
import {HeadTextWrap, Heading, HeadText, MidBoxWrapper, MidBox, Tick,
} from '../../../Assets/Style/commonStyleComponents';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

class ZipNotSupported extends React.Component {
    render() {
        return (
          <React.Fragment>
            <Navbar />
            <MidBoxWrapper>
              <MidBox>
                {
                    <div>
                      <HeadTextWrap className="email-sent-wrapper">
                        <Heading>Zip is not supported</Heading>
                        <HeadText>Provided zip is not supported in our system.</HeadText>
                      </HeadTextWrap>
                    </div>
                }
                </MidBox>
            </MidBoxWrapper>
            <Footer />
          </React.Fragment>
        );
    }
}

export default ZipNotSupported;
