import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ImagesUrl } from '../../../Constants/Images';
import Routes from '../../../Constants/Routes';
import { ListingItemSteps } from './ListingItemSteps';
import { NoImage } from '../../Shared/Components/Icons/NoImage';
import CashOfferButton from '../../Admin/V2/Components/CashOffer/CashOfferButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1.5rem;
  background-color: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  width: 18rem;
  height: min-content;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
    width: 25.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 50rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  border-radius: 10px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.light};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 2rem;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 0;
  }
`;

const AdressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceAndButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1.5rem;
  align-items: flex-end;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
  padding-bottom: 0.25rem;
`;

const Text = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 0;
  }
`;

const BoldText = styled(Text)`
  font-weight: 600;
`;

const Image = styled.img`
  object-fit: cover;
  width: 240px;
  height: 240px;
  border-radius: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 360px;
    height: 360px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-radius: 0;
    width: 320px;
    height: 320px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const MobileButtonsContainer = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 0.75rem;
  }
`;

const EditButton = styled(Link)`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 6rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.25rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  margin-left: 0.5rem;
  background-color: ${({ theme, plain }) => (plain ? theme.light : theme.dark)};
  color: ${({ theme, plain }) => (!plain ? theme.light : theme.dark)};
  border: ${({ theme, plain }) => (plain ? '1px dashed ' + theme.dark : 'none')};
  align-self: flex-end;

  &:hover {
    color: ${({ theme, plain }) => (!plain ? theme.light : theme.dark)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0.25rem;
  }
`;

const DeleteButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 6rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.25rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme, plain }) => (plain ? theme.light : theme.dark)};
  color: ${({ theme, plain }) => (!plain ? theme.light : theme.dark)};
  border: ${({ theme, plain }) => (plain ? '1px dashed ' + theme.dark : 'none')};
  align-self: flex-end;
  margin-left: 0.5rem;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayBackground};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 0.25rem;
  }
`;

const Loader = styled(Spinner)`
  color: ${({ theme }) => theme.dark};
  align-self: center;
  margin: 4px;
`;

const ImagePlaceHolderContainer = styled.div`
  width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// styles for grid type view

const WrapperGrid = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  background-color: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  width: 18rem;
  height: min-content;
`;

const ContentContainerGrid = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.light};
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

const DetailsContainerGrid = styled.div`
  display: flex;
  padding-top: 2rem;
  width: 100%;
  flex-direction: column-reverse;
  padding-left: 0;
  padding-bottom: 7.25rem;
  min-height: 20rem;
  position: relative;
  justify-content: flex-end;
`;

const TextGrid = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 0;
  margin-bottom: 0rem;
`;

const GridPlanContainer = styled.div`
  margin-bottom: 2.25rem;
  display: flex;
  flex-direction: column-reverse;
`;

const AddressTextGrid = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 0;
  margin-bottom: 0.5rem;
`;

const ImageGrid = styled.img`
  object-fit: cover;
  width: 240px;
  height: 240px;
  border-radius: 10px;
`;

const ButtonsContainerGrid = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1rem;
  align-items: flex-start;
`;

const BottomButtonsContainerGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const EditButtonGrid = styled(EditButton)`
  margin-top: 1rem;
`;

const DeleteButtonGrid = styled(DeleteButton)`
  margin-top: 1rem;
`;

const ViewButton = styled(EditButton)`
  margin-left: 0.5rem;
  min-width: 6rem;
`;

const GridImagePlaceHolderContainer = styled.div`
  width: 240px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CashOfferApprovedBadge = styled.span``;

const CashOfferApprovedBadgeGrid = styled.span`
  position: relative;
  left: 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0.5rem;
  }
`;

const getListingOverview = (listing) => {
  let overview = '';
  if (listing?.bedrooms) {
    overview += listing?.bedrooms + ' Beds · ';
  }
  if (listing?.bathrooms) {
    overview += listing?.bathrooms + ' Baths · ';
  }
  if (listing?.squareFeet && !listing?.acres) {
    overview += listing?.squareFeet + ' Sq. Ft.';
  }
  if (listing?.acres) {
    overview += listing?.acres + ' Acres';
  }

  return overview;
};

export const ListingItem = ({ listing, onDelete, deletingListingId, gridView }) => {
  const isListingDraft = listing.draftId;
  const isListingNetworkPlanOrder = listing?.productType === 'Network';
  const [offerSubmitted, setOfferSubmitted] = useState(false);

  //
  const Buttons = () => {
    if (gridView)
      return isListingDraft ? (
        <>
          {!listing.cashOfferId && !offerSubmitted ? (
            <CashOfferButton
              grid
              listindAddressDetails={listing.address}
              draftListingId={listing.draftId}
              price={listing.price}
              />
          ) : (
            <CashOfferApprovedBadgeGrid>Cash Offer Approved</CashOfferApprovedBadgeGrid>
          )}
          <BottomButtonsContainerGrid>
            <DeleteButtonGrid
              plain="true"
              disabled={deletingListingId === listing.draftId}
              onClick={() => onDelete(listing.draftId)}
            >
              {deletingListingId === listing.draftId ? <Loader animation="border" size="sm" /> : 'Delete Listing'}
            </DeleteButtonGrid>
            <ViewButton to={`${Routes.ONBOARDING_V2}?listing_id=${listing.draftId}`}>Finish Listing</ViewButton>
          </BottomButtonsContainerGrid>
        </>
      ) : (
        <>
          {!listing.cashOfferId && !offerSubmitted ? (
            <CashOfferButton
              listindAddressDetails={listing.address}
              listingId={listing.id}
              price={listing.price}
              />
          ) : (
            <CashOfferApprovedBadge />
          )}
          <BottomButtonsContainerGrid>
            <EditButtonGrid plain="true" to={Routes.EDIT_LISTING.replace(':listingId', listing.id)}>
              Edit
            </EditButtonGrid>
            <ViewButton to={Routes.VIEW_LISTING.replace(':listingId', listing.id)}>View Details</ViewButton>
          </BottomButtonsContainerGrid>
        </>
      );

    return isListingDraft ? (
      <>
        {!listing.cashOfferId && !offerSubmitted ? (
          <CashOfferButton
            listindAddressDetails={listing.address}
            draftListingId={listing.draftId}
            price={listing.price}
            />
        ) : (
          <CashOfferApprovedBadge />
        )}
        <BottomButtonsContainerGrid>

        <DeleteButton
          plain="true"
          disabled={deletingListingId === listing.draftId}
          onClick={() => onDelete(listing.draftId)}
          >
          <>{deletingListingId === listing.draftId ? <Loader animation="border" size="sm" /> : 'Delete Listing'}</>
        </DeleteButton>
        <ViewButton to={`${Routes.ONBOARDING_V2}?listing_id=${listing.draftId}`}>Finish Listing</ViewButton>
          </BottomButtonsContainerGrid>
      </>
    ) : (
      <>
        {!listing.cashOfferId && !offerSubmitted ? (
          <CashOfferButton
            listindAddressDetails={listing.address}
            listingId={listing.id}
            price={listing.price}
            />
        ) : (
          <CashOfferApprovedBadge />
        )}
        <EditButton plain="true" to={Routes.EDIT_LISTING.replace(':listingId', listing.id)}>
          Edit
        </EditButton>
        <ViewButton to={Routes.VIEW_LISTING.replace(':listingId', listing.id)}>View Details</ViewButton>
      </>
    );
  };

  if (gridView)
    return (
      <WrapperGrid>
        <Container>
          <ContentContainerGrid>
            {isListingNetworkPlanOrder ? (
              <GridImagePlaceHolderContainer>
                <NoImage />
              </GridImagePlaceHolderContainer>
            ) : (
              <ImageGrid src={listing.photos?.[0] ? listing.photos[0].url : ImagesUrl.DEFAULT_LISTING_PHOTO} />
            )}
            <DetailsContainerGrid>
              {!(isListingNetworkPlanOrder && !isListingDraft) && (
                <ButtonsContainerGrid>
                  <Buttons />
                </ButtonsContainerGrid>
              )}
              {listing?.productTitle && (
                <GridPlanContainer>
                  <TextGrid>{listing?.productTitle}</TextGrid>
                  <TitleText>Plan</TitleText>
                </GridPlanContainer>
              )}
              <TextGrid>{listing.status}</TextGrid>
              <TitleText>Status</TitleText>
              <AddressTextGrid>
                {`${listing?.address?.address1 || ''} ${listing?.address?.address2 || ''} ${
                  listing?.address?.city || ''
                } ${listing?.address?.county || ''} ${listing?.address?.state || ''} ${listing?.address?.zip || ''}`}
              </AddressTextGrid>
              <TitleText>Address</TitleText>
              {listing?.bedrooms || listing?.bathrooms || listing?.squareFeet || listing?.acres ? (
                <AdressContainer>
                  <TitleText>Overview</TitleText>
                  <Text>{getListingOverview(listing)}</Text>
                </AdressContainer>
              ) : null}
              {listing?.price ? (
                <PriceContainer>
                  <TitleText>Price</TitleText>
                  <BoldText>
                    <CurrencyFormat
                      value={listing?.price || 0}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      suffix=".00"
                    />
                  </BoldText>
                </PriceContainer>
              ) : null}
            </DetailsContainerGrid>
          </ContentContainerGrid>
          <ListingItemSteps listing={listing} />
        </Container>
      </WrapperGrid>
    );

  return (
    <Wrapper>
      <Container>
        <ContentContainer>
          {isListingNetworkPlanOrder ? (
            <ImagePlaceHolderContainer>
              <NoImage />
            </ImagePlaceHolderContainer>
          ) : (
            <Image src={listing.photos[0] ? listing.photos[0].url : ImagesUrl.DEFAULT_LISTING_PHOTO} />
          )}
          <DetailsContainer>
            {listing?.price ? (
              <PriceContainer>
                <TitleText>Price</TitleText>
                <BoldText>
                  <CurrencyFormat
                    value={listing?.price || 0}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    suffix=".00"
                  />
                </BoldText>
              </PriceContainer>
            ) : null}
            <AdressContainer>
              <TitleText>Address</TitleText>
              <Text>
                {`${listing?.address?.address1 || ''} ${listing?.address?.address2 || ''} ${
                  listing?.address?.city || ''
                } ${listing?.address?.county || ''} ${listing?.address?.state || ''} ${listing?.address?.zip || ''}`}
              </Text>
            </AdressContainer>
            {listing?.bedrooms || listing?.bathrooms || listing?.squareFeet || listing?.acres ? (
              <AdressContainer>
                <TitleText>Overview</TitleText>
                <Text>{getListingOverview(listing)}</Text>
              </AdressContainer>
            ) : null}
            <PriceAndButtonsContainer>
              <div>
                <StatusContainer>
                  <TitleText>Status</TitleText>
                  <Text>{listing.status}</Text>
                </StatusContainer>
                {listing?.productTitle && (
                  <StatusContainer>
                    <TitleText>Plan</TitleText>
                    <Text>{listing?.productTitle}</Text>
                  </StatusContainer>
                )}
              </div>
              {!(isListingNetworkPlanOrder && !isListingDraft) && (
                <ButtonsContainer>
                  <Buttons />
                </ButtonsContainer>
              )}
            </PriceAndButtonsContainer>
            {!(isListingNetworkPlanOrder && !isListingDraft) && (
              <MobileButtonsContainer>
                <Buttons />
              </MobileButtonsContainer>
            )}
          </DetailsContainer>
        </ContentContainer>
        <ListingItemSteps listing={listing} />
      </Container>
    </Wrapper>
  );
};
