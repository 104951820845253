import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../Constants/CommonConstants';
import { getCustomEntityPath, getCustomLookupPath } from '../Utilities/commonFunctions';

class LookupService {

  constructor() {

    let urls = {
      base: ''
    };

    urls.base = urls.base || "/";

    this.urls = urls;
  }

  get(entity) {
    const url = `${getCustomEntityPath(entity)}${getCustomLookupPath(entity)}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getPropertyTypes() {
    const url = `api/v2/lookup/propertytypes`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getMlsCategories() {
    const url = `api/v2/lookup/mlscategories`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getCompanies() {
    const url = `api/v2/lookup/companies`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getTitleCompanies(state = null) {
    const url = `api/v2/lookup/title-companies`;

    if (state) {
      url = `${url}?state=${state}`;
    }

    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getProducts(productType) {
    const url = `api/v2/lookup/products`;
    return fetch(url, {
      method: apiMethodType.GET,
      params: { productType },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getProductTypes() {
    const url = `api/v2/lookup/product-types`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getOfficeUsers() {
    const url = `api/v2/lookup/officeusers`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getMlsAbbreviations(params) {
    const query = new URLSearchParams(params).toString();
    const url = `api/v2/lookup/mls-abbreviations?${query}`;

    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getStates() {
    const url = `api/v2/lookup/states`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getFormTypes() {
    const url = `api/v2/lookup/formtypes`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getFormStatuses() {
    const url = `api/v2/lookup/formstatuses`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getOfficeStatuses() {
    const url = `api/v2/lookup/office-statuses`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getListingStatuses() {
    const url = `api/v2/lookup/listingstatuses`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getVoucherTemplates(userId) {
    const url = `api/v2/lookup/users/${userId}/voucher-templates`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getCashOfferBatch() {
    const url = `api/v2/lookup/cash-offer/batch`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
}



export let lookupService = new LookupService();
