import React from 'react';
import styled from 'styled-components';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';

const StaticWrapper = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 50px 15px;
  font-family: 'National-Regular',sans-serif;
  h2 {
    color: #242B26;
    font-size: 2.65rem;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    margin-bottom: 28px;
  }
  h3 {
    margin-bottom: 28px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    font-size: 16px;
    color: #0a0a0a;
  }
  p {
    font-size: 16px;
    margin-bottom: 28px;
    line-height: 1.6;
    color: #0a0a0a;
    a {
      color: #242B26;
      &:hover {
        color: #207FD7;
      }
    }
  }
  strong {
    display: block;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-transform: uppercase;
    margin-bottom: 28px;
    font-size: 16px;
    color: #0a0a0a;
  }
  ul {
    margin-left: 18px;
    margin-bottom: 30px;
    li {
      font-size: 16px;
      line-height: 1.6;
      color: #0a0a0a;
      margin-bottom: 12px;
      p {
        margin-bottom: 0;
      }
    }
    &.for-alpha-list {
      list-style-type: upper-alpha;
    }
  }
  .static-info-box {
    margin-bottom: 30px;
    span {
      font-size: 16px;
      line-height: 1.6;
      color: #0a0a0a;
      display: block;
      margin-bottom: 2px;
    }
  }
`;

export default class TermAndCondition extends React.Component {
  render() {
        return (
          <React.Fragment>
           <Navbar />
            <StaticWrapper>
              <h2>Terms of Conditions and Use</h2>
              <h3>This only governs the use of the website and is not your listing agreement.</h3>
              <p>Unreal Estate provides this website and the services offered on it (collectively, the “Services”) under the following terms and conditions of service and use (the “Terms”). Please read them carefully. Please also note that this is not your listing agreement (“Listing Agreement”), which must used by any user seeking to sell a property; in the event of a conflict between the provisions of a Listing Agreement you enter into and the provisions of these Terms and Conditions, the Listing Agreement shall in all respects be controlling. You understand and acknowledge that the Services are provided to you exclusively under these Terms. By visiting this website or any of its sub-domains and accessing and using the Services, you agree that you have read and understand the Terms and that you agree to be bound by them, without limitation or qualification and acknowledge that no signatures are required in connection with the terms for you to be fully bound by them. As used on this website or any of its sub-domains, the terms “you” refers to all users of this website, the terms “we”, “us”, or “our” refer to Unreal Estate or its affiliates, agents, or strategic partners and other third parties that it engages with (which may also be referred to as “USR”). “Sellers” refers to users who list their properties on this website. “Buyers” refers to users who register to utilize Unreal Estate to consider or purchase your property or schedule a home visit appointment with a “Seller.” “Customers” refers to all users of this website who purchase any product or Service on this website, including without limitation listing their properties on the website or purchasing products or services from the store on the website.</p>
              <p>The purpose of this website is to assist homeowners with selling their home without paying a full commission. These Terms apply to all users of this website. If you do not agree to these terms and conditions, do not access this website or post any listings on this website. This website is owned and operated by Unreal Estate Corporation, a Delaware corporation.</p>
              <strong>1. MODIFICATIONS TO THE TERMS</strong>
              <p>Unreal Estate may change the Terms from time to time. We will notify you of any such changes via email and/or by posting the changes on the Unreal Estate website. Each time you access the website, you acknowledge and agree to the Terms then present on the website and in effect. If you object to any such changes, your sole recourse shall be to cease using the Services. Continued use of the Services following notice of any such changes shall indicate your acknowledgment of such changes and agreement to be bound by the terms and conditions of such changes, without limitation or qualification.</p>
              <strong>2. PRIVACY</strong>
              <p>As part of the registration process, you will be asked to provide certain personal information to us. All uses of your personal information will be in accordance with the provisions of our Privacy Policy, which is available on our website (link policy). Given the nature of the services provided by Unreal Estate, all property listing information submitted by you will be treated in a non-confidential manner.</p>
              <p>Use of Information: We request information from you for our own legitimate business purposes and not for the benefit of any nonaffiliated party. Therefore, we will not release your information to nonaffiliated parties except: (1) as necessary for us to provide the product or service you have requested of us; or (2) as permitted by law. We may, however, store such information indefinitely, including the period after which any customer relationship has ceased. Such information may be used for any internal purpose, such as quality control efforts or customer analysis. We may also provide all of the types of nonpublic personal information (including your: phone number, email address, property address, mailing address, and transaction information or other such information that you have provided) to USR. Affiliated companies or strategic partners as included under the definition of USR may include but are not limited to financial service providers, such as title insurers, property and casualty insurers, and trust and investment advisory companies, or companies involved in real estate services, such as but not limited to appraisal companies, traditional full service real estate brokers, home warranty companies, staging companies, furniture companies, home renovation stores and sites, storage companies, escrow companies among others. Furthermore, we may also provide all the information we collect, as described above, to third party companies with whom we or our affiliated companies have joint marketing agreements.</p>
              <p>You should be aware that USR may use your email address to contact you in the future. We may share your contact details with third party companies who may contact you regarding the marketing, financing or sale of your property.</p>
              <strong>3. YOUR ACCOUNT, PASSWORD AND SECURITY</strong>
              <p>You are responsible for maintaining the confidentiality and integrity of your username and password, and you are solely responsible for all activities that occur under your username and password. We recommend that you memorize this information, and not write it down. You agree to immediately notify us of any unauthorized use of your username and password or any other breach of security related to the Services. WE CANNOT AND WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE ARISING FROM YOUR FAILURE TO ADEQUATELY SAFEGUARD YOUR USERNAME AND/OR PASSWORD, YOUR SHARING OF SAME WITH OTHERS, A BREACH OF SUCH INFORMATION BY A THIRD PARTY, OR YOUR FAILURE TO OTHERWISE COMPLY WITH THIS SECTION.</p>
              <strong>4. REGISTRATION</strong>
              <p>Registration consists of either selecting a plan on the Unreal Estate list of plans and creating a listing (whether or not the listing is completed and a plan is purchased) or completing the ‘Buyer Sign Up or any substitute URL that may be provided in the future by USR. If you register as a Unreal Estate user (Seller or Buyer), you accept responsibility for all activities that occur under your username and password, and agree not to sell, transfer or assign your rights. By registering and/or posting Materials (as defined below), you hereby understand and agree that you have established a business relationship between you and Unreal Estate. As such, you agree that Unreal Estate may contact you using the information you provided with information and offers of services available through USRealty.com and any of its affiliates and related entities, as well as all third-party affiliates and partners. If you believe someone has accessed this website using your username and password without your authorization, email us immediately at info@usrealty.com. Because email or phone is used to provide certain notifications and services, you must consent to receive email or to receive telephone calls (even if your phone number is or later appears on any DO NOT CALL list) from USR.</p>
              <strong>5. USER INFORMATION</strong>
              <p>In providing us with information during the registration process, you agree to provide true, accurate, current and complete information as prompted and to timely maintain and update such information to keep it true, accurate, current and complete at all times. If any information you provide is untrue, inaccurate, not current or incomplete you are solely responsible for such errors or inaccuracies and any consequences arising from the lack of accurate information. If we reasonably believe that any information you have provided is not currently accurate and complete, we may suspend or terminate your access to and use of the Services, and refuse to provide any current or future Services to you. You acknowledge that you are over 18 years of age. You are responsible for obtaining and maintaining all telephone, computer hardware and other equipment needed for access to and use of the Services and all charges related thereto. You are solely responsible for all acts or omissions that occur under your account or password, including for all User Content (as defined below) that you (or anyone using your account) submit, post or transmit through the Services. You agree and acknowledge that your access to and/or use of the Services are for personal, non-commercial purposes only and that your use of the Services is to advertise your own property or to find information related to the purchase or potential purchase by you of a home listed on our website, rather than as a commercial enterprise for a third party.</p>
              <p>You agree not to use the Services to do any of the following:</p>
              <ul>
                <li>Post images from other websites.</li>
                <li>Insert links to other websites.</li>
                <li>Use any software or computer code to alter the design, layout, or any other visual elements of any of our web pages.</li>
                <li>Use our website to promote or advertise anything except the sale of your property advertised in our listing service pages.</li>
                <li>Collect information (such as name, address, and contact information) about Sellers or their properties by any manual or automatic means for any purpose other than to consider making an offer to buy the relevant property.</li>
                <li>Violate any local, state, national or international law.</li>
                <li>Stalk, harass or harm another individual.</li>
                <li>Collect or store personal data about other users.</li>
                <li>Impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity.</li>
                <li>Interfere with or disrupt the Services or servers or networks connected to the Unreal Estate website or the other Services, or disobey any requirements, procedures, policies or regulations of networks connected to the USRealty.com website.</li>
                <li>Submit, post, store or transmit any (a) User Content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, racist, sexist, homophobic, or ethnically or otherwise objectionable; (b) User Content that you do not have a right to transmit under any law or under contractual or fiduciary relationships; ( c ) User Content that infringes the intellectual property rights of a third party; or (d) material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.</li>
              </ul>
              <strong>6. USER-PROVIDED INFORMATION AND CONTENT</strong>
              <p>For purposes hereof, “User Content” means materials and information you provide to USR hereunder, including any real estate listing data, photographs or other related materials, and all text, audio, artwork, pictures, logos or images you provide, including without limitation, any materials relating to you or your property. By providing information to, communicating with, and/or placing material on this website, including, for example, communication during registration, communication on any USRealty.com bulletin board, message or chat area, blog, posting any listing or other User Content (including without limitation written, graphical or video content), posting information on the personal home visit scheduler, placing any classified advertisement, entering any sweepstakes, etc., you represent and warrant: (i) you own or otherwise have all necessary rights to the User Content you provide and the rights to use it as provided in these Services; (ii) all information you provide is true, accurate, current and complete, and does not violate these Terms; (iii) the User Content will not cause injury to any person or entity; and (iv) if you post a property listing, that you are the owner of the property and have the consent of any and all co-owners of the property, and that all such co-owners collectively possess all necessary rights, title, and interests in such property to list the property for sale.</p>
              <p>For all such information and material provided, you grant USR, a royalty-free, perpetual, irrevocable, non-exclusive right and license to use, copy, modify, display, archive, store, distribute, reproduce and create derivative works from such information and material, in any form, media, software or technology of any kind now existing or developed in the future.</p>
              <p>You also grant USRealty.com the right to use any material, information, ideas, concepts, know-how or techniques contained in any communication you send to us for any purpose whatsoever, including but not limited to developing, manufacturing and marketing products using such information. All rights in this paragraph are granted without the need for additional compensation of any sort to you.</p>
              <p>Please note that USRealty.com does not accept unsolicited materials or ideas for use or publication, and is not responsible for the similarity of any of its content or programming in any media to materials or ideas transmitted to USRealty.com. Should you send any unsolicited materials or ideas, you do so with the understanding that no additional consideration of any sort will be provided to you, and you are waiving any claim against USR regarding the use of such materials and ideas, even if material or an idea is used that is substantially similar to the material or idea you sent.</p>
              <strong>7. HOME LISTING PLAN FEATURES AND RESPONSIBILITIES</strong>
              <p>Your selection of the various plans available through US Realty.com shall be subject to the terms and conditions of the Listing Agreement you enter into with the company.</p>
              <strong>8. USREALTY.COM REPRESENTATION AND RESPONSIBILITIES</strong>
              <p>USRealty.com will use its best efforts to complete your listing on other online listing platforms that are part of our program offerings. Because we do not control those services or sites, we cannot guarantee that your listing will remain on any of those sites or services or that users will not flag or otherwise seek to remove them.</p>
              <p>If you sign up for one of our plans, we will authorize your card for the amount specified at the time you sign up, and we will wait to charge the amount specified until your property is listed in MLS.  If the property is in Nebraska or Kansas, you will be charged only after the listing is concluded (sold/expired/cancelled). You have 30 days from your initial order to fully complete and return the forms to us to complete the MLS listing of your property.  If you subsequently don’t complete the listing process within this 30 days, the listing fee will not be charged, but a $29 processing fee will be charged. If you complete the order after the 30 days, the listing fee will be reduced by this $29 processing fee.  Once your listing becomes live on the MLS, the money is nonrefundable.</p>
              <p>Seller acknowledges that the USRealty.com website listing and MLS listings, REALTOR.com Service, and individual online listing services are activated separately and that the listing dates and expiration dates thereof may be different. Changes to property listing information, cancellation or removal of the USRealty.com online listing are independent of changes to property information, cancellation or removal of the listing on the Realtor.com Service and MLS, which are contracted separately between the Seller and an independent real estate agent/broker. Changes to property listing information on the Realtor.com Service will not incur additional charges. USRealty.com is a licensed real estate broker/agent. The terms of any agreement between Seller and any independent real estate broker/agent are not endorsed, recommended or otherwise known to or by USRealty.com and do not change, alter or modify any of the terms agreed to between Seller and USR. There may be a delay (usually 2-3 business days) in the activation of any REALTOR.com Service listing. Seller should consult the listing real estate broker/agent for further details.</p>
              <p>All MLS Service listings are handled by a licensed real estate broker/agent who will contact Seller in order to complete the MLS listing portion of the service, and that broker/agent will activate MLS listings for Seller. The terms of any agreement between Seller and any independent real estate broker/agent are not endorsed, recommended or otherwise known to or by USRealty.com and do not change, alter or modify any of the terms agreed to between Seller and USR. It is recommended that Seller carefully review and approve all MLS property listing details and information prior to publication. All MLS Service listings require a buyer’s agency commission if a real estate broker assists Seller in finding a buyer. Neither the Services nor these Terms are or should be construed as a solicitation for a listing broker if the property is already listed with a broker. All Sellers who purchase an MLS listing are responsible for reviewing, understanding and correctly completing all paperwork and complying with any terms and conditions of such agreements which are necessary and applicable to their local MLS prior to their advertisement becoming viewable in their local MLS or REALTOR.com®.</p>
              <p>Activation of the MLS and REALTOR.com® online property listings may take up to 2-3 business days following submission of the MLS Service listing agreement to the local broker/agent. Seller should consult the listing real estate broker/agent for further details about MLS rules and regulations in Seller’s area. The MLS Service is subject to modification without notice to Seller. If Seller objects to any modification to the MLS Service, the sole and exclusive recourse shall be to cease using the MLS Service. Continued use of the MLS Service following notice of and/or posting as part of these terms of any such modifications shall indicate Seller acknowledgment of such modifications and agreement with the MLS Service as so modified. The MLS Service is considered a separate purchase, even when purchased in a package, and is treated as a separate service for refunds. The MLS Service may be subject to additional fees and/or restrictions based on, among other things, changes in law, regulations, MLS rules or the third parties with which USRealty.com may contract. In addition, depending on the MLS listing agreement, there may be restrictions on use of signs, including signs provided as part of any USRealty.com listing plans. The MLS Service expires after six months. Seller will receive an email notification prior to the expiration date of the USRealty.com online listing with a reminder to call the Home Sales Hotline (866) 534-3726 to purchase a six (6)-month MLS listing plan renewal for a one-time payment of from $79 to $319 depending upon the property listing zip code. Seller will receive a separate email notification of the expiration of the MLS listing from the MLS listing agent and Seller is responsible for renewing the MLS listing with that agent. There may be additional fees that apply depending on the geographic location of the property listing and the local MLS rules and regulations.</p>
              <p>Under no circumstances will USRealty.com be responsible or liable for the behavior or conduct of any Seller or any Buyer arising from or attributable to any listing or posting on this website.</p>
              <strong>9. TECHNICAL PROBLEMS OR COMPUTER/BROWSER REQUIREMENTS</strong>
              <p>No refunds will be provided as the result of temporary technical problems with our website (including without limitation not being able to log in to your account or not being able to upload photos) which cause your property listing to be removed, changed, or unable to be displayed. You are not entitled to any refund based on a temporary service failure or based on a failure to view your property listing that is due to your computer’s or your browser’s failure to meet minimum browser and operating system requirements of the USRealty.com website. You are also not entitled to any refund based on any technical problems arising, in whole or in part, from the lack of capabilities of your computer or peripheral device. You are responsible for all fees and charges incurred to access our website through an Internet service provider or other third party service.</p>
              <strong>10. REGULAR PRICING, SPECIAL PRICING, FREE TRIAL OFFERS</strong>
              <p>USRealty.com has several listing plans, each of which is available at regular pricing and available in most states and the District of Columbia. Regular pricing rates are available at our website, may vary by the property listing zip code, and may change without notice. However, Sellers currently registered with one of our listing plans will not be subject to any rate changes until such time as their current plan expires and/or is renewed. From time to time, USRealty.com may offer special pricing discounts from regular prices. These discounts may vary by location, may be available to elect Sellers only or otherwise offered on a limited basis at the sole discretion of USRealty.com, and may be discontinued at any time. Additionally, Sellers may be offered a “free” trial of the Services under conditions dictated by USRealty.com. Sellers are bound to all Terms and Conditions of Service and Use during this free trial period. There are no refunds associated with any products or services received during a free trial.</p>
              <strong>11. PRODUCT PURCHASES FROM USREALTY.COM STORE</strong>
              <p>Some items purchased from the online store at USRealty.com are made pursuant to a shipment contract. This means that the risk of loss and title for such items passes to you upon our delivery to the carrier. USRealty.com, in its sole discretion, may reship an item to you even if you bear the risk of loss for such item. However, under no circumstances will a refund be issued for an item not delivered to you. USRealty.com will attempt to be as accurate as possible. However, we do not warrant that product descriptions in the online store at USRealty.com are accurate, complete, reliable, current, or error-free. If a product offered by USRealty.com is not as described, your sole and exclusive remedy is to return the product in unused condition. No product ordered from the store that has been used may be returned by a Customer. To receive a refund of any payments made for products purchased from the store, you must contact Customer Service within 7 days from the receipt of your item at info@usrealty.com to request a refund. You will receive an email confirming your refund request, which contains a link to acknowledge receipt of the email. After acknowledging receipt of the email you will receive via email a “Return Authorization” identification number and instructions for returning the item to USRealty.com. If USRealty.com approves the return, then a Seller who returns such product must pay return shipping, and a 15% restocking fee will be charged to your credit card. If any product you purchase from the USRealty.com store is delayed or does not arrive from USRealty.com, your sole and exclusive remedy is for USRealty.com to replace the product free of any shipping and handling charges.</p>
              <strong>12. THIRD-PARTY CONTENT, SERVICES AND MONITORING</strong>
              <p>We are an intermediary for content and services supplied by third parties for display on the USRealty.com website and viewed and used by users of the Services. Accordingly, we have no editorial control over such content and services. Any opinions, advice, statements, services, offers, or other information or content expressed or made available by third parties, including information provided by other users of the Services, are those of the respective author(s) or distributor(s) of that information and not of USRealty.com. We neither endorse nor are responsible for the accuracy or reliability of any opinion, advice, information, or statement made on the USRealty.com website or by anyone utilizing the Services other than authorized USRealty.com spokespersons while acting in their official capacities. We have the right, but not the obligation, to monitor and review the content and services available on the USRealty.com website and provided through use of the Services and your account to determine compliance with these Terms and any other operating rules established by us, to satisfy any law, regulation or authorized government request, or for other purposes. You understand and acknowledge that we do not pre-screen or monitor content for accuracy or services reliability, but that we and our designees shall have the right (but not the obligation) in our sole discretion to remove any content and services from the Services, without liability to you for any reason. Without limiting the foregoing, we and our designees shall have the right to remove any content and services that violates the Terms that we believe may create liability for us, or that we deem otherwise objectionable.</p>
              <strong>13. COPYRIGHT INFRINGEMENT</strong>
              <p>USRealty.com respects the intellectual property rights of others and expects the users of this website to do the same. It is USRealty.com’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others. In accordance with the Digital Millennium Copyright Act of 1998 (“DMCA”), USRealty.com will respond expeditiously to claims of copyright infringement committed using this website that are reported to USRealty.com’s Designated Copyright Agent. If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through this website by completing a DMCA Notice of Alleged Infringement (“Notice”) and delivering it to USRealty.com ‘s Designated Copyright Agent. Upon receipt of the Notice, USRealty.com will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged material from the website. If USRealty.com removes or disables access to content in response to a Notice, we may notify the user with details about the Notice so that the user can contest the removal or disablement.</p>
              <p>In order to report alleged copyright infringement, please provide the following information to our Designated Copyright Agent:</p>
              <ul className="for-alpha-list">
                <li>Identify the copyrighted work that you claim has been infringed, or – if multiple copyrighted works are covered by the Notice – you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
                <li>Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the Site where such material may be found.</li>
                <li>Provide your mailing address, telephone number, and, if available, email address.</li>
                <li>Include both of the following statements in the body of the Notice:
                  <p>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material or reference or link to such material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).”</p>
                  <p>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</p>
                </li>
                <li>Provide your full legal name and your electronic or physical signature.</li>
              </ul>
              <p>Deliver the Notice, with all items completed, to USRealty.com’s Designated Copyright Agent:</p>
              <div className="static-info-box">
                <span>USRealty.com Copyright Agent</span>
                <span>1500 Conrad Weiser Parkway</span>
                <span>Womelsdorf, PA. 19567</span>
                <span>Telephone: (866) 807-9087</span>
                <span>Facsimile: (610) 589-4792</span>
                <span>Email: info@usrealty.com</span>
              </div>
              <strong>14. TRANSACTIONS</strong>
              <p>On occasion, a product or service may not be available at the time or the price as it appears in an advertisement. In such event, or in the event a product is listed at an incorrect price or with incorrect information due to typographical error, technology error, error in the date or length of publication, or error in pricing or product information received from our advertisers or suppliers, you agree that USRealty.com is not responsible for such errors or discrepancies or any consequences arising from such errors or discrepancies.</p>
              <strong>15. PAYMENT RESPONSIBILITY</strong>
              <p>You agree to remit all payments when due. The deadlines for all payments will be based on the USRealty.com payment schedule in effect at the time you register or, if different, at the time you renew a listing. USRealty.com reserves the right to delete any User Content you have submitted if you fail to make any payment to USRealty.com when due. You hereby authorize us to process all of these charges to your payment method of record and process any adjustments that may be required unless we have been specifically directed otherwise. You also agree to maintain current information on your payment methods in our records and update any expired or closed credit card numbers prior to the effective date of such expiration or closing. Upon registering as a Seller with USRealty.com, you will provide USRealty.com a valid credit card number. You authorize USRealty.com to make any charge against such credit card for services provided hereunder in accordance with the terms herein.</p>
              <strong>16. CONSENT TO COMMUNICATIONS FROM USREALTY.COM</strong>
              <p>From time to time, USRealty.com will communicate with you via email or telephone about announcements, home visit appointments, home listing expiration warnings, new features, products and other services offered by USRealty.com, affiliates, partners, advertisers, and related companies. By purchasing, using or enjoying the services of USRealty.com, you expressly consent and opt-in to receive communication via email or telephone from USRealty.com, affiliates, partners, advertisers, and related companies.</p>
              <strong>17. DEALINGS WITH PARTNERS, ADVERTISERS, INDEPENDENT CONTRACTORS AND SPONSOR</strong>
              <p>Your correspondence or business dealings with, participation in promotions of, or purchase of goods and/or services from our partners, advertisers, independent contractors or sponsors (including real estate brokers or agents) found on or through the Services, and any terms, conditions, warranties or representations associated with such dealings, are solely between you and such partner(s), advertiser(s), independent contractor(s) or sponsor(s). YOU ACKNOWLEDGE, UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES SHALL USREALTY.COM BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS THE RESULT OF ANY SUCH DEALINGS RELATED TO ANY GOODS, SERVICES, RESOURCES OR CONTENT, OR AS THE RESULT OF THE PRESENCE OF SUCH PARTNERS, ADVERTISERS, INDEPENDENT CONTRACTORS OR SPONSORS ON OR THROUGH THE SERVICES OR FOR ANY INFORMATION THAT YOU PROVIDE OR FOR ANY HARM RELATED THERETO. You understand and acknowledge it is solely your responsibility to determine whether you have purchased a service through a partner, advertiser, independent contractor or sponsor as opposed to through USRealty.com directly.</p>
              <strong>18. EXTERNAL LINKS</strong>
              <p>Our provision of a link to any other website or location is for your convenience and does not signify our endorsement of such other website or location or its contents. Any concerns you may have regarding any external link should be directed to that link’s website administrator or webmaster. USREALTY.COM SHALL NOT BE LIABLE FOR ANY INFORMATION, SOFTWARE, OR LINKS FOUND AT ANY OTHER WEBSITE, INTERNET LOCATION, OR SOURCE OF INFORMATION, OR FOR YOUR USE OF SUCH INFORMATION.</p>
              <strong>19. NO SPAM</strong>
              <p>We do not condone or allow spam by Sellers. We prohibit you from using the Services to promote your own website or any business, product or service through the sending of unsolicited commercial email. We reserve the right to investigate and terminate your rights under this Agreement in the event we reasonably believe a Seller to be violating this provision.</p>
              <strong>20. USE AND STORAGE</strong>
              <p>You acknowledge and agree that we may establish general practices and limits concerning use of the Services, including, without limitation, the maximum number of days that information, data, account history or other uploaded User Content will be retained by the Services and the maximum number of times (and the maximum duration for which) you may access the Services in a given period of time.</p>
              <strong>21. NO RESALE OF SERVICE</strong>
              <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the Services, use of the Services or access to the Services. The Services are provided for your personal, non-commercial use only.</p>
              <strong>22. TERMINATION</strong>
              <p>You agree that we, in our sole discretion, may terminate your password, account (or any part thereof) or access to and use of the Services, and remove and discard any User Content posted by you on or through the Services, for any reason or no reason, with or without notice and effective immediately, including, without limitation, if we believe that you have violated or acted inconsistently with the letter or spirit of the Terms. You acknowledge and agree that we may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Services if you have violated or acted inconsistently with the Terms. You agree that we may terminate your account if it remains inactive for a period of one (1) year. FURTHER, YOU AGREE THAT WE SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY TERMINATION OF YOUR ACCESS TO THE SERVICES OR FOR ANY CONSEQUENCES ARISING FROM SUCH TERMINATION. You may discontinue your participation in and access to the Services at any time.</p>
              <strong>23. MINIMUM BROWSER REQUIREMENTS FOR USERS OF OUR WEBSITE</strong>
              <ul>
                <li>Browser (with cookies enabled and Adobe Flash plugin installed)</li>
                <li>Microsoft Internet Explorer 7 or higher</li>
                <li>Mozilla Firefox 2.0 or higher</li>
                <li>Google Chrome</li>
                <li>Safari 2.0 or higher</li>
                <li>Opera 9.0 or higher</li>
              </ul>
              <strong>24. DISCLAIMER OF WARRANTIES</strong>
              <p>YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, OR AS TO NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO THE SERVICES. WE MAKE NO WARRANTY THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (B) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; (C) THE ACCURACY, RELIABILITY OR QUALITY OF ANY INFORMATION, PRODUCTS, SERVICES OR OTHER MATERIAL OBTAINED THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS; (D) DEFECTS IN THE SERVICES WILL BE CORRECTED; OR (E) THE USREALTY.COM WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU UNDERSTAND AND AGREE THAT ANY MATERIAL AND/OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR OR ANY OTHER AFFECTED COMPUTER SYSTEM(S) OR FOR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR INFORMATION. WE DO NOT CONTROL IN ANY RESPECT ANY INFORMATION, PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES, AND WE MAKE NO WARRANTY REGARDING ANY GOODS OR SERVICES PURCHASED OR OBTAINED THROUGH THE SERVICES OR ANY TRANSACTIONS ENTERED INTO THROUGH OR AS THE RESULT OF THE SERVICES.</p>
              <p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM USREALTY.COM OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</p>
              <strong>25. LIMITATION OF LIABILITY</strong>
              <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL USREALTY.COM OR ITS OFFICERS, EMPLOYEES, CONTRACTORS, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES, SUPPLIERS, PARTNERS, AGENTS OR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH PARTIES WERE ADVISED OF, KNEW OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF OR IN ANY WAY RELATED TO:</p>
              <ul>
                <li>The use or the inability to use the Services.</li>
                <li>The cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or transactions entered into through or from the Services.</li>
                <li>The unauthorized access to or alteration of your transmissions or data.</li>
                <li>Statements or conduct of any third party on or through the Services, including threatening, defamatory, obscene, offensive or illegal conduct or any infringement of another’s rights, including intellectual property rights.</li>
                <li>Your failure to adequately safeguard your password or access to the Services.</li>
                <li>Any Services Content you access through the services, including, but not limited to, any errors or omissions in any Services Content, or your use of or reliance on any Services Content posted, emailed or otherwise transmitted through the Services.</li>
                <li>The termination of your access to or use of the Services as provided herein.</li>
                <li>The deletion or failure to store any information or data, communications or User Content maintained or transmitted by or through the Services.</li>
                <li>Any modification, suspension or discontinuance of the Services (or any part thereof) or these Terms.</li>
                <li>The use of or reliance on any Services Content, goods or services available on any other websites that you access through the Services.</li>
                <li>Any actual or anticipated real estate transaction.</li>
                <li>Any other matter relating to the Services or these Terms.</li>
              </ul>
              <strong>26. EXCLUSIONS AND LIMITATIONS</strong>
              <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent that we may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of our liability shall be the minimum permitted under such applicable law.</p>
              <strong>27. INDEMNIFICATION</strong>
              <p>You agree to indemnify, defend and hold harmless USRealty.com, its affiliates, officers, directors, employees, shareholders, co-branders or other partners, employees, consultants and agents from and against any and all third-party claims (including but not limited to, claims for defamation, trade disparagement, privacy and intellectual property infringement), liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys’ fees) that such parties may incur as a result of or arising from or relating to any allegation regarding:(1) any information that you (or anyone using your account) submits, posts or transmits through the Services and used by USRealty.com, (2) your (or anyone using your account’s) use of the Services, (3) your (or anyone using your account’s) violation of these Terms, (4) your (or anyone using your account’s) violation of any rights of any other person or entity, (5) any viruses, Trojan horses, worms, time bombs, cancel bots or other similar harmful or deleterious programming routines input by you into the USRealty.com website or other Services, or (6) your use of USRealty.com.</p>
              <strong>28. TRADEMARKS</strong>
              <p>Certain of the names, logos, and other materials displayed on the USRealty.com website constitute trademarks, trade names, service marks or logos (“Marks”) of USRealty.com or other entities. You are not authorized to use any such Marks. Ownership of all such Marks and the goodwill associated therewith remains with us or those other entities.</p>
              <strong>29. COPYRIGHTS; RESTRICTIONS ON USE</strong>
              <p>The content of the Services, including without limitation, text, software, music, sound, photos, graphics, video, page layout and design and other material contained in the Services or information presented through the Services by USRealty.com or its licensors (the “Services Content”), is copyrighted by USRealty.com and/or its licensors under United States and international copyright laws, may be subject to other intellectual property and proprietary rights and laws, including trademark and patent laws, and is owned by USRealty.com or its licensors. The Services Content may not be copied, modified, reproduced, republished, uploaded, posted, transmitted, sold, offered for sale, or redistributed in any way without the prior written permission of USRealty.com and our applicable licensors. You must abide by all copyright notices, information, or restrictions contained in or attached to any Services Content. You may use the Services Content online only, and solely for your personal, non-commercial use, and you may download or print a single copy of any portion of the Services Content solely for your personal, non-commercial use, provided you do not remove any trademark, copyright or other notice from such Services Content. If you operate a website and wish to link to this website, you may not do so without advanced written authorization from USRealty.com.. No other use of this website is permitted without prior written permission of USRealty.com. The permitted use described in this Paragraph is contingent on your compliance at all times with these Terms of Service</p>
              <p>You may not, for example, republish any portion of the Services Content on any Internet, intranet or extranet site or incorporate the Services Content in any database, compilation, archive or cache. You may not distribute any Services Content to others, whether or not for payment or other consideration, and you may not modify, copy, frame, cache, reproduce, sell, publish, transmit, display or otherwise use any portion of the Services Content. You may not scrape or otherwise copy our Services Content without express written permission. You agree not to decompile, reverse engineer or disassemble any software or other products or processes accessible through USRealty.com, not to insert any code or product or manipulate the Services Content of USRealty.com in any way that affects the user’s experience, and not to use any data mining, robots, data gathering or extraction method in connection with the website. You may not use any meta tags or any other “hidden text” utilizing our name or trademarks without our express written consent.</p>
              <strong>30. LICENSE</strong>
              <p>You hereby grant to USRealty.com and its successors and assigns, a worldwide, perpetual, irrevocable, royalty-free, sub-licensable right, in any media now known or currently known, to exercise all copyright and other intellectual property rights with respect to the Content you provide to us to be published on the USRealty.com website or on or through the Services or otherwise through the Services, to use, distribute, display, reproduce, modify and create derivative works from such material, in any and all media, in any manner, in whole or in part, without any duty to account to you. The foregoing does not apply to Content contained on hyperlinked pages or any other Content you do not submit to us.</p>
              <strong>31. MISCELLANEOUS</strong>
              <p>The Terms constitute the entire and exclusive and final statement of the agreement between you and USR with respect to the subject matter hereof, and govern your use of the Services, superseding any prior agreements or negotiations between you and USRealty.com with respect to the subject matter hereof. The Terms and the relationship between you and USRealty.com shall be governed by the laws of the State of Delaware, with the exception of conflict of law principles, as applied to agreements made, entered into and performed, notwithstanding your actual place of residence. All lawsuits arising out of the Terms or out of your use of the Services shall be brought in the federal or state courts in the state of Delaware, and you and USRealty.com hereby irrevocably submit to the exclusive personal jurisdiction of and acknowledge the appropriate venue of such courts for such purpose. Our failure to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and that the other provisions of the Terms remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Services or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. The section titles in the Terms are for convenience only and have no legal or contractual effect.</p>
              <strong>32. MODIFICATION OF SERVICES</strong>
              <p>We reserve the right to modify or temporarily discontinue the Services (or any part thereof) or to permanently discontinue operation of the website with or without notice to you. Modification of the Services can include, but is not limited to, any change to the website or content contained therein, changes to the administrative tools or functionality of the website, modification of the “look and feel” of the website, changes to the format of a property listing or any other part of the website, and the inclusion of advertising links in association with a property listing or other content available on the website. Temporary discontinuance of the Services could be for a variety of reasons, including, without limitation, technical issues that require or result in short-term Service outages. We shall not be liable to you or any third party if we exercise our right to modify or temporarily discontinue the Services or permanently discontinue operation of the website. IF YOU OBJECT TO ANY MODIFICATIONS TO THE SERVICES, YOUR SOLE AND EXCLUSIVE RECOURSE SHALL BE TO CEASE USING THE SERVICES. CONTINUED USE OF THE SERVICES FOLLOWING NOTICE OF AND/OR POSTING AS PART OF THESE TERMS OF ANY SUCH MODIFICATIONS SHALL INDICATE YOUR ACKNOWLEDGMENT OF SUCH MODIFICATIONS AND SATISFACTION WITH THE SERVICES AS SO MODIFIED.</p>
              <strong>33. SURVIVAL</strong>
              <p>USRealty.com’s (and its licensors’) proprietary rights (including any and all intellectual property rights) in and to the Services Content and the Services shall survive the expiration or earlier termination of the Terms for any reason.</p>
              <strong>34. EQUAL HOUSING OPPORTUNITY</strong>
              <p>All real estate property advertised on our website is subject to the Federal Fair Housing Act, which makes it illegal to advertise any preference, limitations or discrimination based on race, color, religion, sex, handicap, familial status, or national origin, or an intention to make any such preference, limitation or discrimination. We will not knowingly accept any advertising for real estate, which is in violation of the law.</p>
              <p>For more information, see: <div>
                <a href="http://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp">http://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp</a>
                </div>
              </p>
              <strong>35. VIOLATIONS</strong>
              <p>Please report any violations of the Terms by emailing info@usrealty.com</p>
              <strong>36. CONTACT US</strong>
              <p>For more information, please contact us at info@usrealty.com</p>
            </StaticWrapper>
            <Footer />
          </React.Fragment>
        );
    }
}
