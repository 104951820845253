import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingActionCreators } from '../../../Actions/OnboardingActions';
import { MidBox, HeadTextWrap, HeadText, MidBoxWrapper, CustomButton, Heading, RowFlex, InputFlex, CustomTextarea } from '../../../Assets/Style/commonStyleComponents';
import { validationMessages } from '../../../Constants/CommonConstants';
import { intialContactUsEmptyState } from '../../../Utilities/commonFunctions';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


class ContactUs extends React.Component {

  state = {
    contactUsForm: intialContactUsEmptyState()
  };

  submitFormData = (formData) => {
    this.props.contactUsUserRequest(formData, () => {
      this.setState({ 
        contactUsForm:  intialContactUsEmptyState()
      })
    });
  }

  handleFieldChanged = (e) => {
    let contactUsForm = { ...this.state.contactUsForm };
    contactUsForm[e.target.name].value = e.target.value;
    this.setState({ contactUsForm: { ...contactUsForm } });
  }

  handleFieldBlur = (e) => {
    this.validateField(e.target.name, e.target.value);
  }

  validateField = (key, value) => {
    let contactUsForm = { ...this.state.contactUsForm };
    let isError = false;
    for (let i = 0; i < contactUsForm[key].rules.length; i++) {
      let rule = contactUsForm[key].rules[i];
      if (!rule.rule.test(value)) {
        contactUsForm[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      contactUsForm[key].error = null;
    }

    this.setState({ contactUsForm: { ...contactUsForm }, isError: Object.keys(contactUsForm).some(k => contactUsForm[k].error !== undefined && contactUsForm[k].error !== null) });

    return isError;
  }

  validateAndSubmit = (e) => {
    e.preventDefault();

      let isError = false;

      Object.keys(this.state.contactUsForm).forEach((k) => {
        const isErrorExist = this.validateField(k, this.state.contactUsForm[k].value);
        if (isErrorExist) {
          isError = true;
        }
      })

      if (!isError) {
        this.submitFormData(
          { ...(Object.keys(this.state.contactUsForm).reduce((retVal, key) => {
            retVal[key] = this.state.contactUsForm[key].value;
            return retVal;
          }, {})) });
      }
  }
    render() {
        return (
          <React.Fragment>
            <Navbar />
            <MidBoxWrapper>
              <MidBox>
                <HeadTextWrap>
                  <Heading>Contact Us</Heading>
                  <HeadText>Use the form below or call us at <a tel="tel:+18665343726">(866) 534.3726</a>.</HeadText>
                </HeadTextWrap>
                <RowFlex>
                  <InputFlex
                    name="firstName"
                    title="First Name"
                    className="inputgap"
                    value={this.state?.contactUsForm?.firstName?.value}
                    error={this.state?.contactUsForm?.firstName?.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                  <InputFlex
                    name="lastName"
                    title="Last Name"
                    className="inputgap"
                    value={this.state?.contactUsForm?.lastName?.value}
                    error={this.state?.contactUsForm?.lastName?.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                </RowFlex>
                <InputFlex
                  name="email"
                  title="Email"
                  type="email"
                  value={this.state?.contactUsForm?.email?.value}
                  error={this.state?.contactUsForm?.email?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <CustomTextarea>
                  <label>Message</label>
                  <textarea
                    name="message"
                    type="textarea"
                    value={this.state?.contactUsForm?.message?.value}
                    error={this.state?.contactUsForm?.message?.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className={`${this.state?.contactUsForm?.message?.error ? 'item-text-red' : ''}`}
                  />
                  {
                    this.state?.contactUsForm?.message?.error &&
                    <span className="textarea-error">{validationMessages.required}</span>

                  }
                </CustomTextarea>
                <CustomButton type="submit" className="block" onClick={this.validateAndSubmit}>Submit</CustomButton>
              </MidBox>
            </MidBoxWrapper>
            <Footer />
          </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
  return {
      userDetails: state.user.userDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...onboardingActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
