import React from 'react'

export const StripeLogoIcon = (iconProps) => (
  <svg width="34" height="14" viewBox="0 0 34 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_7316_50107)">
  <path fillRule="evenodd" clipRule="evenodd" d="M34.045 7.23345C34.045 4.84094 32.8724 2.95312 30.6311 2.95312C28.3803 2.95312 27.0186 4.84094 27.0186 7.21476C27.0186 10.0278 28.6262 11.4483 30.9337 11.4483C32.0591 11.4483 32.9102 11.1959 33.5532 10.8408V8.97169C32.9102 9.28949 32.1725 9.4857 31.2363 9.4857C30.319 9.4857 29.5057 9.16797 29.4017 8.06519H34.0261C34.0261 7.94367 34.045 7.45773 34.045 7.23345ZM29.3733 6.34558C29.3733 5.28953 30.0258 4.85029 30.6216 4.85029C31.1984 4.85029 31.8132 5.28953 31.8132 6.34558H29.3733Z" fill="#8792A2"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M23.368 2.95312C22.4412 2.95312 21.8455 3.38302 21.5145 3.68208L21.3916 3.10265H19.311V13.9997L21.6752 13.5043L21.6847 10.8595C22.0252 11.1025 22.5264 11.4483 23.3586 11.4483C25.0514 11.4483 26.5928 10.1025 26.5928 7.14C26.5834 4.42973 25.023 2.95312 23.368 2.95312ZM22.8006 9.39225C22.2427 9.39225 21.9117 9.19597 21.6847 8.953L21.6752 5.48579C21.9211 5.21477 22.2616 5.02785 22.8006 5.02785C23.6612 5.02785 24.257 5.98111 24.257 7.20538C24.257 8.45768 23.6707 9.39225 22.8006 9.39225Z" fill="#8792A2"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M16.0576 2.40183L18.4313 1.89716V0L16.0576 0.495319V2.40183Z" fill="#8792A2"/>
  <path d="M18.4313 3.11133H16.0576V11.2887H18.4313V3.11133Z" fill="#8792A2"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M13.5138 3.80381L13.3625 3.11223H11.3198V11.2897H13.684V5.74769C14.242 5.02808 15.1877 5.15892 15.4809 5.26172V3.11223C15.1782 3.00008 14.0718 2.79448 13.5138 3.80381Z" fill="#8792A2"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M8.78572 1.08398L6.47821 1.56996L6.46875 9.05577C6.46875 10.439 7.51844 11.4576 8.91811 11.4576C9.69352 11.4576 10.261 11.3175 10.5731 11.1493V9.25205C10.2705 9.37357 8.77623 9.80344 8.77623 8.42031V5.10261H10.5731V3.11199H8.77623L8.78572 1.08398Z" fill="#8792A2"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M2.39259 5.4858C2.39259 5.12131 2.69522 4.98113 3.19642 4.98113C3.91515 4.98113 4.82301 5.19608 5.54174 5.57924V3.38302C4.75681 3.07462 3.98135 2.95312 3.19642 2.95312C1.27668 2.95312 0 3.94376 0 5.59794C0 8.17733 3.59362 7.76615 3.59362 8.87824C3.59362 9.30818 3.21534 9.44832 2.68576 9.44832C1.90083 9.44832 0.898407 9.13059 0.104026 8.70072V10.925C0.983514 11.2988 1.87247 11.4577 2.68576 11.4577C4.65279 11.4577 6.00512 10.495 6.00512 8.82217C5.99567 6.03718 2.39259 6.53251 2.39259 5.4858Z" fill="#8792A2"/>
  </g>
  <defs>
  <clipPath id="clip0_7316_50107">
  <rect width="34" height="14" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  
);
