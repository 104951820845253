import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, Router } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import ScrollToTop from 'react-router-scroll-top';
import { DndProvider } from 'react-dnd'
// import { TouchBackend } from 'react-dnd-touch-backend' //  TO DO: research using TouchBackend for mobile devices
import { HTML5Backend } from 'react-dnd-html5-backend'

import Layout from './App/Shared/Components/Layout';
import { PrivateRoutes, PublicRoutes } from './Routes';
import { alertActions } from './Actions/ToastActions';
import { userActionCreators } from './Actions/UserActions';
import { history } from './Utilities/History';
import { getLocalUserData } from './Utilities/commonFunctions';
import { roleType } from './Constants/CommonConstants';
import AppConsts from './Constants/AppConsts';
import Cookies from 'js-cookie';
import LogRocket from 'logrocket';
import { userDetailsSelector } from './Reducers/UserReducer';
import { themes } from './Constants/Themes';
import ApiUrls from './Constants/ApiUrls';
import { UIActionsCreators } from './Actions/UIActions';

// disable LogRocket for dev & stage & local environments. 
if (AppConsts.useLogRocket) {

  LogRocket.init(AppConsts.logRocketApId, {
    network: {
      requestSanitizer: request => {
        if (request.url.includes(ApiUrls.login) || request.url.includes(ApiUrls.createUser)) {
          request.body = {
            ...JSON.parse(request?.body || null),
            password: null,
          }
        }
        return request;
      },
    }
  });
}

const App = ({ isLoading, loadingText, getExternalUser, createUserFromExternal }) => {
  const dispatch = useDispatch();
  const userData = useSelector(userDetailsSelector);

  const [resolvingExternalUser, setResolvingExternalUser] = useState(true);

  const handleCurrentUser = async () => {
    const userData = getLocalUserData();
    const externalCookies = Cookies.get(AppConsts.authorization.externalCookiesName);

    if (userData && !externalCookies && !AppConsts.appBaseUrl.includes('http://localhost')) {
      dispatch(userActionCreators.logout(userData.userId, userData.token))
      setResolvingExternalUser(false)
      return;
    }

    if (!userData && externalCookies) {
      await getExternalUser()
      const externalUser = getLocalUserData();
      if (externalUser) {
        setResolvingExternalUser(false)
        return;
      }
      await createUserFromExternal();
    }

    if (userData && (userData?.role !== roleType.ADMIN && userData?.role !== roleType.WHOLESALE)) {
      dispatch(alertActions.clear()); // clear alert on location change
    }
    setResolvingExternalUser(false)
  }

  useEffect(() => {
    handleCurrentUser();

    history.listen(() => {
      handleCurrentUser();
    });
  }, []);

  useEffect(() => {
    dispatch(UIActionsCreators.getNavbarAndFooterContent());
  }, []);



  useEffect(() => {
    // disable LogRocket for dev & stage & local environments. 
    // triger LogRocket.identify() only in case if userData?.token is presented
    if (!AppConsts.useLogRocket || !userData?.token) { return }

    LogRocket.identify(userData.userId, {
      name: `${userData.firstName} ${userData.lastName}`,
      email: userData.email,
      role: userData.role,
    });

  }, [userData]);

  if (resolvingExternalUser) {
    return <LoadingOverlay active spinner text='' />
  }

  return (
      /* PR with fix of error caused by 'react-loading-overlay-ts' package,
      https://github.com/derrickpelletier/react-loading-overlay/pull/57
      */
    <DndProvider backend={HTML5Backend}>
      <LoadingOverlay
        active={isLoading}
        spinner
        fadeSpeed={0}
        text={loadingText || ''}
        styles={{
          overlay: (base) => ({
            ...base,
            opacity: 0.6,
          }),
          spinner: (base) => ({
            ...base,
            '& svg circle': {
              stroke: themes.usRealty.lavender
            }
          })
        }}
      >
        <Layout>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div>
            <div className={`klaviyo-form-${AppConsts.klaviyoForms.homePageFormId}`} />
            <Router history={history}>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/dashboard' component={PrivateRoutes} />
                  <Route exact path='/my-profile' component={PrivateRoutes} />
                  <Route path='/edit-listing' component={PrivateRoutes} />
                  <Route path='/listing' component={PrivateRoutes} />
                  <Route path='/voucher' component={PrivateRoutes} />
                  <Route path='/mls-category/:listingId' component={PrivateRoutes} />
                  <Route
                    path='/property-listing-form'
                    component={PrivateRoutes}
                  />
                  <Route
                    path='/edit-shipping-address'
                    component={PrivateRoutes}
                  />
                  <Route
                    path='/edit-billing-address'
                    component={PrivateRoutes}
                  />
                  <Route path='/admin' component={PrivateRoutes} />
                  <Route path='/' component={PublicRoutes} />
                </Switch>
              </ScrollToTop>
            </Router>
          </div>
        </Layout>
      </LoadingOverlay>
    </DndProvider>
  );
}

function mapStateToProps(state) {
  const { loggedIn } = state.user;
  const isLoading = state?.loader?.MAIN_LOADER?.isLoading;
  const loadingText = state?.loader?.MAIN_LOADER?.text;

  return {
    loggedIn,
    isLoading,
    loadingText
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
