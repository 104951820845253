import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import v8n from 'v8n';
import { Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import CurrencyFormat from 'react-currency-format';

import {
  bathroomsSelector,
  bedroomsSelector,
  propertyTypeIdSelector,
  mlsCategoryIdSelector,
  squareFeetSelector,
  totalSavingSelector,
  descriptionListingformsSelector,
  typeAndCategorySelectDisabledSelector,
  propertyPriceSelector,
  fetchingDraftListingSelector,
  acresSelector,
  draftListingIdSelector,
  selectedAddressSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { propertyTypesSelector, mlsCategoriesSelector } from '../../../Reducers/LookupReducer';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import ListingPreviewMobileContainer from '../Components/ListingPreviewMobileContainer';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { lookupActionsCreators } from '../../../Actions/V2/LookupActions';
import { MinusIcon } from '../../Shared/Components/Icons/MinusIcon';
import { PlusIcon } from '../../Shared/Components/Icons/PlusIcon';
import { PenIcon } from '../../Shared/Components/Icons/PenIcon';
import { gTag } from '../../../Utilities/utils';
import magicwand from '../../../Assets/Images/magic-wand.png';
import CashOfferPanel from '../Components/CashOfferPanel';
import { EmbeddedYoutubeHintsPlayer } from '../Components/EmbeddedYoutubeHintsPlayer';
import { LYFSteps } from '../../../Constants/CommonConstants';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DescriptionLabel = styled.div`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const InstructionText = styled(Text)`
  margin-top: 1rem;
  max-width: 100%;
  background-color: #ae97c81a;
  border-radius: 10px;
  padding: 1.5rem;
`;

const DescriptionCountText = styled(Text)`
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.dark)};
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const BackButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#D3D5D4')};
`;

const SelectContainer = styled.div`
  min-width: 20rem;
  position: relative;
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    margin-top: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    border-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#242B26')};
  }
  select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }

  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 24rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 26rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 28rem;
  }
`;

const SelectInputLabel = styled.label`
  opacity: 1;
  top: 0px;
  left: 0px;
  z-index: 2;
  position: absolute;
  background-color: white;
  pointer-events: none;
  margin-inline-start: 0.75rem;
  margin-inline-end: 0.75rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 0.75rem;
  transform-origin: left top;
  transition: scale 0.2s, translateY 0.2s, transform 0.2s;
  transition-timing-function: linear;
  display: block;
  text-align: start;
  font-weight: 500;
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.mouseballgray[100] : theme.colors.light)};

  ${({ smallLabel }) =>
    smallLabel
      ? `
    transform: scale(0.85) translateY(-22px);
    font-size: 12px;
    opacity: 1;
    `
      : ''}

  ${({ select }) =>
    select
      ? `
    display: none;
    &:has(+ (select(select:focus)) {
    display: block;
    }
    `
      : ''}
`;

const InputLabel = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
`;

const TotoalSavingValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalSavingText = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
  padding-bottom: 0;
  align-self: center;
`;

const TotoalSavingValueText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  margin-left: 0.5rem;
`;

const AveragePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  justify-content: center;
`;

const TotalSavingContainer = styled(AveragePriceContainer)`
  background: ${({ theme }) => theme.lightGreen};
  justify-content: space-between;
  padding: 1.5rem;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const InputButtonContainer = styled.div`
  display: flex;
`;

const InputNumberContainer = styled.div`
  display: flex;
`;

const InputButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  svg {
    path {
      fill: ${({ disabled }) => (disabled ? '#D3D5D4' : '#242B26')};
    }
  }
`;

const InputValue = styled.span`
  display: flex;
  width 5.5rem;
  align-items: center;
  justify-content: center;
`;

const SquareFeetSuffix = styled.span`
  display: flex;
  width 3rem;
  align-items: center;
  justify-content: center;
`;

const AcreSuffix = styled.span`
  display: flex;
  width 4.5rem;
  align-items: center;
  justify-content: center;
`;

const SquareFeetsInput = styled.input`
  text-align: end;
`;

const StyledEditorContainer = styled.section`
  min-width: 20rem;
  position: relative;
  width: auto;
  margin-bottom: 1rem;

  .tox-tinymce {
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    border-color: #242b26;
  }

  .tox.tox-tinymce {
    height: 600px !important;
  }

  .tox-statusbar {
    display: none !important;
  }
`;

const InfoButton = styled.button`
  background: white;
  border-radius: 16px;
  border-width: 1.5px;
  border-style: dashed;
  color: ${({ theme }) => theme.colors.green[900]};
  padding: 0.25rem;
  padding-inline-end: 1rem;
  padding-inline-start: 1rem;
  border-color: #242b26;
`;

const GenerateDescriptionButton = styled.button`
  display: flex;
  border-radius: 16px;
  border-width: 1.5px;
  border-style: dashed;
  color: ${({ theme }) => theme.colors.green[900]};
  border-color: #242b26;
  background: white;
  padding: 0.25rem;
  padding-inline-end: 1rem;
  padding-inline-start: 1rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  width: 60%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 64%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 68%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 78%;
  }

  img {
    margin-right: 1rem;
  }
`;

const DescriptionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const DESCRIPTION_MAX_LENGHT = 4000;

const PropertyBasics = () => {
  const [showListingPreview, setShowListingPreview] = useState(false);
  const [showDescriptionSuggestionsModal, setShowDescriptionSuggestionsModal] = useState(false);
  const [errors, setErrors] = useState({
    bedrooms: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter the number of bedrooms',
        },
      ],
      error: '',
    },
    bathrooms: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter the number of bathrooms',
        },
      ],
      error: '',
    },
    squareFeets: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter the area of the property',
        },
      ],
      error: '',
    },
    propertyTypeId: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select property type',
        },
      ],
      error: '',
    },
    mlsCategoryId: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select MLS category',
        },
      ],
      error: '',
    },
    acres: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter the area of the land',
        },
      ],
      error: '',
    },
    description: {
      rules: [
        {
          rule: v8n().maxLength(DESCRIPTION_MAX_LENGHT),
          message: '4000 characters is maximum allowed lenght',
        },
      ],
      error: '',
    },
  });

  const squareFeetsInputRef = useRef(null);
  const acresInputRef = useRef(null);
  const dispatch = useDispatch();
  const totalSaving = useSelector(totalSavingSelector);
  const bedrooms = useSelector(bedroomsSelector);
  const bathrooms = useSelector(bathroomsSelector);
  const squareFeets = useSelector(squareFeetSelector);
  const acres = useSelector(acresSelector);
  const propertyTypes = useSelector(propertyTypesSelector);
  const mlsCategories = useSelector(mlsCategoriesSelector);
  const description = useSelector(descriptionListingformsSelector) || '';
  const typeAndCategorySelectDisabled = useSelector(typeAndCategorySelectDisabledSelector);
  const fetchingDraftListing = useSelector(fetchingDraftListingSelector);
  const propertyTypeId = useSelector(propertyTypeIdSelector);
  const mlsCategoryId = useSelector(mlsCategoryIdSelector);
  const propertyPrice = useSelector(propertyPriceSelector);
  const draftListingId = useSelector(draftListingIdSelector);
  const selectedAddress = useSelector(selectedAddressSelector);

  const timeout = useRef(null);
  const editorRef = useRef(null);

  const propertyTypesOptions = propertyTypes.map((x) => ({ value: x.id, label: x.name }));
  const mlsCategoriesOptions = mlsCategories.map((x) => ({ value: x.id, label: x.name }));
  const isPropertyTypeLand = Number(propertyTypeId) === 11;

  useEffect(() => {
    dispatch(lookupActionsCreators.getPropertyTypes());
    dispatch(lookupActionsCreators.getMlsCategories());
  }, []);

  const validateField = (key, value) => {
    let fields = { ...errors };
    let isError = false;
    for (let i = 0; i < errors[key].rules.length; i++) {
      let rule = errors[key].rules[i];
      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setErrors((pr) => ({ ...pr, ...fields }));

    return isError;
  };

  const handleContinue = () => {
    let isError = false;
    const formValues = Object.entries({
      propertyTypeId,
      mlsCategoryId,
      description,
    });

    if (isPropertyTypeLand) {
      formValues.push(['acres', acres], ['squareFeets', squareFeets], ['bedrooms', 0], ['bathrooms', 0]);
    } else {
      formValues.push(['bedrooms', bedrooms], ['bathrooms', bathrooms], ['squareFeets', squareFeets]);
    }

    formValues.forEach(([key, value]) => {
      const error = validateField(key, value);
      if (error) {
        isError = true;
      }
    });
    if (!isError) {
      const category = mlsCategoriesOptions.find((o) => Number(o.value) === Number(mlsCategoryId))?.label;
      const name = propertyTypesOptions.find((o) => Number(o.value) === Number(propertyTypeId))?.label;
      gTag({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
            },
            products: [
              {
                name,
                category,
                price: propertyPrice,
              },
            ],
          },
        },
      });
      dispatch(onboardingActionCreatorsV2.setTypeAndCategorySelectDisabledV2(true));
      dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_DETAILS));
    }
  };

  const focusOnSquareInput = () => {
    squareFeetsInputRef.current && squareFeetsInputRef.current.focus();
  };

  const focusOnAcrereInput = () => {
    acresInputRef.current && acresInputRef.current.focus();
  };

  const decreaseBedrooms = () => {
    if (bedrooms <= 0) {
      return;
    }
    dispatch(onboardingActionCreatorsV2.setBedroomsV2(bedrooms - 1));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('bedrooms', bedrooms - 1);
  };

  const increaseBedRooms = () => {
    if (bedrooms > 99) {
      return;
    }
    dispatch(onboardingActionCreatorsV2.setBedroomsV2((bedrooms || 0) + 1));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('bedrooms', (bedrooms || 0) + 1);
  };

  const decreaseBathrooms = () => {
    if (bathrooms <= 0) {
      return;
    }
    dispatch(onboardingActionCreatorsV2.setBathroomsV2(bathrooms - 0.25));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('bathrooms', bathrooms - 0.25);
  };

  const increaseBathrooms = () => {
    if (bathrooms > 99) {
      return;
    }
    dispatch(onboardingActionCreatorsV2.setBathroomsV2((bathrooms || 0) + 0.25));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('bathrooms', (bathrooms || 0) + 0.25);
  };

  const changeSquareFeets = (e) => {
    e.persist();
    const numberRegex = /^\d+$/;
    if (Number(e.target.value) && !numberRegex.test(Number(e.target.value))) {
      setErrors((pr) => ({ ...pr, squareFeets: { ...pr.squareFeets, error: 'Symbols not allowed' } }));
      return;
    }
    dispatch(onboardingActionCreatorsV2.setSquaresV2(e.target.value));
    if (isPropertyTypeLand) {
      const acres = (Number(e.target.value) / 43560).toFixed(2);
      dispatch(onboardingActionCreatorsV2.setAcresV2(acres));
    }
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('squareFeets', e.target.value);
  };

  const changeAcres = (e) => {
    e.persist();
    const value = e.target.value;
    dispatch(onboardingActionCreatorsV2.setAcresV2(value));
    dispatch(onboardingActionCreatorsV2.setSquaresV2((Number(value) * 43560).toFixed()));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('acres', value);
    validateField('squareFeets', e.target.value);
  };

  const changePropertyType = (e) => {
    e.persist();
    dispatch(onboardingActionCreatorsV2.setPropertyTypeV2(e.target.value));
    // clean square acres if property type was changed from 'Land' to other
    if (Number(e.target.value) !== 11 && acres) {
      dispatch(onboardingActionCreatorsV2.setAcresV2(null));
    }
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('propertyTypeId', e.target.value);
  };

  const changeMlsCategory = (e) => {
    e.persist();
    dispatch(onboardingActionCreatorsV2.setMlsCategoryV2(e.target.value));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    validateField('mlsCategoryId', e.target.value);
  };

  const onSquareFeetKeyDown = (e) => {
    // prevent user from entering "-" or "." or "," (only whole positive numbers)
    if (
      !(
        (e.keyCode !== 189 && e.keyCode !== 190 && e.keyCode !== 188) ||
        (e.keyCode !== 8 && e.keyCode === 0) ||
        (e.keyCode >= 48 && e.keyCode <= 57)
      )
    ) {
      e.preventDefault();
    }
  };

  const onDescriptionChange = (val) => {
    dispatch(onboardingActionCreatorsV2.setDescriptionV2(val));
    validateField('description', val);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (val) {
      timeout.current = setTimeout(() => dispatch(onboardingActionCreatorsV2.updateDraftListingV2()), 1000);
    }
  };

  const generateDescription = () => {
    dispatch(onboardingActionCreatorsV2.generateDescription());
  };

  return (
    <Container>
      {showListingPreview ? (
        <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
      ) : (
        <>
          <FormContainer>
            <TitleContainer>
              <SubTilteText>List your property</SubTilteText>
              <StepsText>3/9</StepsText>
            </TitleContainer>
            <TitleText>Property Basics</TitleText>
            <Text>
              Please select the appropriate property category. In this category, your property will also be shown to
              potential buyers.
            </Text>
            <EmbeddedYoutubeHintsPlayer mobile={true} width="296" height="536" />
            <SelectContainer error={errors.propertyTypeId.error}>
              <SelectInputLabel
                smallLabel={propertyTypes?.length !== 0}
                error={errors.propertyTypeId.error}
                disabled={typeAndCategorySelectDisabled}
              >
                Property Type
              </SelectInputLabel>
              <select
                name="propertyType"
                value={propertyTypeId || ''}
                onChange={changePropertyType}
                disabled={typeAndCategorySelectDisabled}
              >
                <option key={-1} value="">
                  Select value
                </option>
                {propertyTypesOptions.map((x, idx) => (
                  <option key={idx} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </select>
              {errors.propertyTypeId.error && <ErrorMessage>{errors.propertyTypeId.error}</ErrorMessage>}
            </SelectContainer>

            <SelectContainer error={errors.mlsCategoryId.error}>
              <SelectInputLabel
                smallLabel={mlsCategories?.length !== 0}
                error={errors.mlsCategoryId.error}
                disabled={typeAndCategorySelectDisabled}
              >
                MLS Category
              </SelectInputLabel>
              <select
                name="mlsCategory"
                value={mlsCategoryId || ''}
                onChange={changeMlsCategory}
                disabled={typeAndCategorySelectDisabled}
              >
                <option key={-1} value="">
                  Select value
                </option>
                {mlsCategoriesOptions.map((x, idx) => (
                  <option key={idx} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </select>
              {errors.mlsCategoryId.error && <ErrorMessage>{errors.mlsCategoryId.error}</ErrorMessage>}
            </SelectContainer>

            <CashOfferPanel
              listindAddressDetails={{
                ...(selectedAddress || {}),
              }}
              draftListingId={draftListingId}
              price={propertyPrice}
            />

            <InstructionText>
              Property Type & Category will lock when you proceed to the next page and generate the fields and documents
              required by the MLS. Any change in Type or Category will require starting over with a new listing.
            </InstructionText>
            <>
              <InputContainer error={errors.bedrooms.error}>
                <InputLabel error={errors.bedrooms.error}>Bedrooms</InputLabel>
                <InputButtonContainer>
                  <>
                    <InputButton disabled={bedrooms === 0 || isPropertyTypeLand} onClick={decreaseBedrooms}>
                      <MinusIcon />
                    </InputButton>
                    <InputValue>{bedrooms} Beds</InputValue>
                    <InputButton disabled={bedrooms > 99 || isPropertyTypeLand} onClick={increaseBedRooms}>
                      <PlusIcon />
                    </InputButton>
                  </>
                </InputButtonContainer>
              </InputContainer>
              {errors.bedrooms.error && <ErrorMessage>{errors.bedrooms.error}</ErrorMessage>}
              <InputContainer error={errors.bathrooms.error}>
                <InputLabel error={errors.bathrooms.error}>Bathrooms</InputLabel>
                <InputButtonContainer>
                  <>
                    <InputButton disabled={bathrooms === 0 || isPropertyTypeLand} onClick={decreaseBathrooms}>
                      <MinusIcon />
                    </InputButton>
                    <InputValue>{bathrooms} Baths</InputValue>
                    <InputButton disabled={bathrooms > 99 || isPropertyTypeLand} onClick={increaseBathrooms}>
                      <PlusIcon />
                    </InputButton>
                  </>
                </InputButtonContainer>
              </InputContainer>
              {errors.bedrooms.error && <ErrorMessage>{errors.bedrooms.error}</ErrorMessage>}
              <InputContainer error={errors.squareFeets.error}>
                <InputLabel error={errors.squareFeets.error}>
                  {isPropertyTypeLand ? 'Lot Square feet' : 'Square feet'}
                </InputLabel>
                <InputNumberContainer onClick={focusOnSquareInput}>
                  <SquareFeetsInput
                    type="number"
                    onKeyDown={onSquareFeetKeyDown}
                    onChange={changeSquareFeets}
                    onBlur={() => dispatch(onboardingActionCreatorsV2.updateDraftListingV2())}
                    value={squareFeets || ''}
                    disabled={isPropertyTypeLand}
                    ref={squareFeetsInputRef}
                  />
                  <SquareFeetSuffix>Sq.Ft</SquareFeetSuffix>
                  <PenIcon />
                </InputNumberContainer>
              </InputContainer>
              {errors.squareFeets.error && <ErrorMessage>{errors.squareFeets.error}</ErrorMessage>}
            </>
            {isPropertyTypeLand && (
              <>
                <InputContainer error={errors.acres.error}>
                  <InputLabel error={errors.acres.error}>Acres</InputLabel>
                  <InputNumberContainer onClick={focusOnAcrereInput}>
                    <SquareFeetsInput
                      type="number"
                      onChange={changeAcres}
                      onBlur={() => dispatch(onboardingActionCreatorsV2.updateDraftListingV2())}
                      value={acres || ''}
                      ref={acresInputRef}
                    />
                    <AcreSuffix>Acres</AcreSuffix>
                    <PenIcon />
                  </InputNumberContainer>
                </InputContainer>
                {errors.acres.error && <ErrorMessage>{errors.acres.error}</ErrorMessage>}
              </>
            )}

            <DescriptionLabel>Description</DescriptionLabel>
            <StyledEditorContainer>
              <Editor
                tinymceScriptSrc={process.env.REACT_APP_APP_BASE_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                value={description}
                disabled={fetchingDraftListing}
                init={{
                  height: 600,
                  width: '100%',
                  menubar: false,
                  toolbar:
                    'bold italic underline forecolor alignleft aligncenter alignright numlist insertfile link | a11ycheck ltr rtl |',
                  content_style:
                    '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { color: #919592 }',
                }}
                onEditorChange={onDescriptionChange}
              />
            </StyledEditorContainer>
            <DescriptionCountText error={(description || '').trimStart().length > DESCRIPTION_MAX_LENGHT}>
              {(description || '').trimStart().length}/{DESCRIPTION_MAX_LENGHT}
            </DescriptionCountText>
            {errors.description.error && <ErrorMessage>{errors.description.error}</ErrorMessage>}
            <DescriptionButtonsContainer>
              <InfoButton onClick={() => setShowDescriptionSuggestionsModal(true)}>Need help?</InfoButton>
              {/* Uncomment GenerateDescriptionButton when AI description generation fixed. */}
              {/* <GenerateDescriptionButton onClick={() => generateDescription()}>
                <img src={magicwand} alt="magic-wand" height={24} width={24} />
                I'm feeling lucky. Generate a description for me.
              </GenerateDescriptionButton> */}
            </DescriptionButtonsContainer>

            {totalSaving > 0 && (
              <TotalSavingContainer>
                <TotalSavingText>Your potential savings by listing with Unreal Estate</TotalSavingText>
                <TotoalSavingValueContainer>
                  <TotoalSavingValueText>
                    <CurrencyFormat
                      prefix="$"
                      value={totalSaving}
                      thousandSeparator=","
                      decimalSeparator="."
                      displayType="text"
                      suffix=".00"
                    />
                  </TotoalSavingValueText>
                </TotoalSavingValueContainer>
              </TotalSavingContainer>
            )}
            <ButtonsContainer>
              <MobilePreviewButton
                onClick={() => {
                  setShowListingPreview(true);
                }}
              >
                <EyeButton />
              </MobilePreviewButton>
              <BackButton type="button" onClick={() => dispatch(LYFSteps.PRICE_AND_COMISSIONS)}>
                Back
              </BackButton>
              <SubmitButton type="button" onClick={handleContinue}>
                Continue
              </SubmitButton>
            </ButtonsContainer>
          </FormContainer>
          <ListingPreviewDesktopContainer />
        </>
      )}
      <Modal
        title="How to write an unreal description"
        visible={showDescriptionSuggestionsModal}
        onCancel={() => setShowDescriptionSuggestionsModal(false)}
        onOk={() => setShowDescriptionSuggestionsModal(false)}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>
          <em>
            <u>
              MLS Description can be written in three sections. Be mindful most MLSs have a limit of under 1,000
              characters.
            </u>
          </em>
        </p>
        <p>
          <strong>HEADLINE:</strong>
        </p>
        <p>(Stylish/New/Remodeled) (3-story/Colonial/Townhouse) (Brick/Stone) (2,000 sq ft)</p>
        <p>
          <strong>Walking Tour focused on what&rsquo;s appealing:</strong>
        </p>
        <p>
          (Enter the grand foyer with vaulted ceiling flanked by a study/office and private dining room with French
          doors. Continue forward to the living area with oversized fireplace adjacent to the kitchen with 42" cabinets,
          granite countertops and island, along with stainless steel appliances. Moving upstairs are 3 bedrooms, a full
          hall bath and owner&rsquo;s spacious bedroom and en suite.
        </p>
        <p>
          Moving to the lower level is the expansive den with wet bar and walk-out patio great for entertaining guests.)
        </p>
        <p>
          <strong>Why I love it:</strong> You&rsquo;ll enjoy the relaxing evenings watching the sunset over the lake and
          entertaining guests with the ample room for outdoor dining and space for entertainment indoors.
        </p>
      </Modal>
    </Container>
  );
};

export default PropertyBasics;
