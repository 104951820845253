import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { Container, FormSection, Form } from '../../../Assets/Style/commonStyleComponents';
import { adminActionCreators } from '../../../Actions/AdminActions';
import enums from './../../../Constants/Enums';
import AdminHeader from '../Components/AdminHeader';

const inputErrorClass = "error-input";

class BasicFormPreviewContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const paramsValue = new URLSearchParams(window.location.search);
    const formSectionId = paramsValue.get("formSectionId");
      this.props.getSelectedItemDetail(
        Number(this.props.match.params.formId),
        this.props.activeEntity,
        formSectionId
      );
  }

  processBasicForm = (content, fields) => {
    content = content.replace(/{FormField:(\d+)}/g, '<field>$1</field>');

    content = content.replace(/\[row.*?\]/g, '<div class="row-modify">');
    content = content.replace(/\[\/row]/g, '</div>');
    content = content.replace(/\[column.*?\]/g, '<div class="medium-4">');
    content = content.replace(/\[\/column]/g, '</div>');

    content = content.replace(/\[page-break]/g, '<br />');

    content = content.replace(/\r\n/g, '<br />');

    return ReactHtmlParser(content, {
      transform: (node, index) => this.transform(node, index, fields),
    });
  };

  transform = (node, index, fields) => {
    if (node.type === 'tag' && node.name === 'field') {
      const field = fields.filter((f) => f.formFieldId === parseInt(node.children[0].data))[0];
      return this.getFormLayout(field, index);
    }
  };

  getFormLayout = (field, index) => {
    if (field.readOnly) {
      return (
        <>
          <input
            type="text"
            key={`${field.formFieldId}_${index}`}
            value={field.prefillText}
            className="disabled-input"
          />
          <label className="for-readonly">ReadOnly</label>
        </>
      );
    }
    if (field.options) {
      return (
        <>
          <select
            key={`${field.formFieldId}_${index}`}
            name={field.formFieldId}
            onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, false)}
            defaultValue={field.value || ''}
            className={this.state.errors[field.displayName] ? inputErrorClass : ''}
          >
            {
              field.required &&
              <label>Required</label>
            }
            <option key={Math.random()} disabled value=""> Select Value </option>
            {field.options.split('\r\n').map((o) => (
              <option key={`${field.formFieldId}_${index}_${o}`} value={o}>
                {o}
              </option>
            ))}
          </select>
        </>
      );
    }

    switch (field.inputDataType) {
      case enums.InputTypes.TEXT_SHORT:
        return (
          <>
            <input
              key={`${field.formFieldId}_${index}`}
              type="text"
              name={field.formFieldId}
              defaultValue={field.value || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.displayName)}
              onBlur={this.handleFormFieldBlur}
              onFocus={this.handleFormFieldFocus}
              required={field.required && !field.value}
              ref={field.displayName}
              className={this.state.errors[field.displayName] ? inputErrorClass : ''}
            />
            {
              field.required &&
              <label>Required</label>
            }
          </>
        );
      case enums.InputTypes.TEXT_LONG:
        return (
          <div>
            <textarea
              key={`${field.formFieldId}_${index}`}
              type="text"
              name={field.formFieldId}
              defaultValue={field.value || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.displayName)}
              onBlur={this.handleFormFieldBlur}
              onFocus={this.handleFormFieldFocus}
              ref={field.displayName}
              className={this.state.errors[field.displayName] ? inputErrorClass : ''}
            />
            {
              field.required &&
              <label>Required</label>
            }
          </div>
        );
      case enums.InputTypes.DECIMAL:
        return (
          <>
            <input
              key={`${field.formFieldId}_${index}`}
              type="number"
              name={field.formFieldId}
              defaultValue={field.value || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.displayName)}
              onBlur={this.handleFormFieldBlur}
              onFocus={this.handleFormFieldFocus}
              ref={field.displayName}
              className={this.state.errors[field.displayName] ? inputErrorClass : ''}
            />
            {
              field.required &&
              <label>Required</label>
            }
          </>
        );
      case enums.InputTypes.BOOLEAN:
        return (
          <>
            <input
              key={`${field.formFieldId}_${index}`}
              type="checkbox"
              name={field.formFieldId}
              defaultChecked={field.value}
              onChange={(e) => this.handleFormCheckboxField(e, field.displayName, field.formFieldId)}
              ref={field.displayName}
              className={this.state.errors[field.displayName] ? inputErrorClass : ''}
            />
            {
              field.required &&
              <label>Required</label>
            }
          </>
        );
      default:
        return '';
    }
  };

  render() {
    const { listingForm } = this.props;
    return (
      <React.Fragment>
        <Layout.Header
          style={{ background: '#fff', minHeight: 52, padding: 0 }}
        >
          <AdminHeader />
        </Layout.Header>
        <Container>
            <Form>
                {listingForm?.sections && listingForm?.sections.length && listingForm?.sections[0].fields.map((s, j) => {
                    return (
                    <FormSection key={j}>
                        {this.processBasicForm(
                        s.content,
                        s.fields,
                        )}
                    </FormSection>
                    );
                })}
            </Form>
        </Container>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[enums.NUMERIC_VALUE.TWO],
    listingForm: state.admin.selectedItemData || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicFormPreviewContainer),
);
