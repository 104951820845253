import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

import {
    inactiveListingsSelector,
    manualAddressEnterSelector,
    voucherEnterSelector,
    showInactiveListingsListModalSelector,
    showDuplicateListingAddressModalSelector,
    fetchingDraftListingSelector,
  } from '../../../Reducers/OnboardingReducerV2';
import ListingAddressForm from '../Components/ListingAddressForm';
import ListingVoucher from '../Components/ListingVoucher';
import ListingAddressSearch from '../Components/ListingAddressSearch';
import { onboardingActionCreatorsV2, onboardingActions } from '../../../Actions/OnboardingActionsV2';
import { ModalDiv } from '../../../Assets/Style/commonStyleComponents';
import DidYouMeanListingPopUp from '../Modals/DidYouMeanListingPopUp';
import ListingAddressChangeForm from '../Components/ListingAddressChangeForm';
import { history } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';

const Loader = styled(Spinner)`
    position: fixed;
    top: 45%;
    left: 50%;
    color: ${({ theme }) => theme.dark};
}
`;

const ListingAddress = () => {
  const dispatch = useDispatch();
  const manualAddressEnter = useSelector(manualAddressEnterSelector);
  const showDidYouMeanModal = useSelector(showInactiveListingsListModalSelector);
  const inactiveListings = useSelector(inactiveListingsSelector);
  const voucherEnter = useSelector(voucherEnterSelector);
  const fetchingDraftListing = useSelector(fetchingDraftListingSelector);
  const showDuplicateListingAddressModal = useSelector(showDuplicateListingAddressModalSelector);

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const voucherCode = params.get('code');

  const zip = params.get('zip');
  const address1 = params.get('address1');
  const address2 = params.get('address2');
  const city = params.get('city');
  const state = params.get('state');
  const manualEnteredAddress =  (zip || address1 || address2 || city || state);
  const listingId = params.get('listing_id');

  useEffect(() => {
    if (voucherCode) {
      dispatch(onboardingActionCreatorsV2.setVoucherEnterV2(true));
      return;
    }

    if (manualEnteredAddress) {
      dispatch(onboardingActionCreatorsV2.setManualAddressEnterV2(true))
      return;
    }
    
  }, []);

  const onSelectInactiveListing = (listing) => dispatch(onboardingActionCreatorsV2.setInactiveListingV2(listing));

  if (fetchingDraftListing) {
    return <Loader animation="border" size="sm" />;
  }
  
  if (voucherEnter) {
    return <ListingVoucher />;
  }

  if (listingId) {
    return <ListingAddressChangeForm />;
  }

  return (
    <>
      {manualAddressEnter ? <ListingAddressForm /> : <ListingAddressSearch />}
      {showDidYouMeanModal &&
        <ModalDiv
          visible={true}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          title="Did you mean">
          <DidYouMeanListingPopUp
            onSelect={onSelectInactiveListing}
            listings={inactiveListings}
          />
        </ModalDiv>
      }
      <ModalDiv
        width='620px'
        visible={showDuplicateListingAddressModal}
        okText="Take me to my Dashboard"
        title="There is already a listing with this address associated with your account"
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => {
          dispatch({ type: onboardingActions.SET_SHOW_LISTING_DUPLICATE_ADDRESS_MODAL, payload: false })
        }}
        onOk={() => {
          history.push(Routes.DASHBOARD);
        }}
        bodyStyle={{ display: 'none' }}>
      </ModalDiv>
    </>
  );
};

export default ListingAddress;