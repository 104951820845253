import LogRocket from 'logrocket';

import { onboardingServiceV2 } from '../Services/OnboardingServiceV2';
import { alertActions } from './ToastActions';
import { LYFSteps, ownerTypes, staticMessages } from '../Constants/CommonConstants';
import { startLoading, endLoading } from './LoaderActions';
import { checkResponse } from '../Utilities/commonFunctions';
import {
    convertSelectedAddress,
    getDraftListingData,
    getListingFomsFieldsObject,
    getPropertyOwnerFields,
    gTag,
    splitListingForms,
} from '../Utilities/utils';
import { history, setStateToUrl } from '../Utilities/History';
import Routes from '../Constants/Routes';
import FileService from '../Services/FileService';
import { UIActionsCreators } from './UIActions';
import { userActionCreators } from './UserActions';
import { companyTypes } from '../Constants/CommonConstants';
import { draftListingService } from '../Services/V2/DraftListingService';
import { titleCompanyService } from '../Services/V2/TitleCompanyService';
import { cashOfferService } from '../Services/V2/CashOfferService';
const filrService = new FileService();

export const onboardingActions = {
    SELECT_ADDRESS_V2: 'SELECT_ADDRESS_V2',
    CLEAR_ADDRESS_V2: 'CLEAR_ADDRESS_V2',
    CLOSE_MODAL_POPUP_V2: 'CLOSE_MODAL_POPUP_V2',
    CHECK_ADDRESS_COVERAGE_REQUESTED_V2: 'CHECK_ADDRESS_COVERAGE_REQUESTED_V2',
    CHECK_ADDRESS_COVERAGE_RESOLVED_V2: 'CHECK_ADDRESS_COVERAGE_RESOLVED_V2',
    CHECK_ADDRESS_COVERAGE_FAILED_V2: 'CHECK_ADDRESS_COVERAGE_FAILED_V2',
    SET_MANUAL_ADDRESS_ENTER_V2: 'SET_MANUAL_ADDRESS_ENTER_V2',
    SET_VOUCHER_ENTER_V2: 'SET_VOUCHER_ENTER_V2',
    AVERAGE_PRICE_REQUESTED_V2: 'AVERAGE_PRICE_REQUESTED_V2',
    AVERAGE_PRICE_RESOLVED_V2: 'AVERAGE_PRICE_RESOLVED_V2',
    AVERAGE_PRICE_FAILED_V2: 'AVERAGE_PRICE_FAILED_V2',
    INACTIVE_LISTINGS_REQUESTED_V2: 'INACTIVE_LISTINGS_REQUESTED_V2',
    INACTIVE_LISTINGS_RESOLVED_V2: 'INACTIVE_LISTINGS_RESOLVED_V2',
    INACTIVE_LISTINGS_FAILED_V2: 'INACTIVE_LISTINGS_FAILED_V2',
    INACTIVE_LISTING_REQUESTED_V2: 'INACTIVE_LISTING_REQUESTED_V2',
    INACTIVE_LISTING_RESOLVED_V2: 'INACTIVE_LISTING_RESOLVED_V2',
    INACTIVE_LISTING_FAILED_V2: 'INACTIVE_LISTING_FAILED_V2',
    SET_PROPERTY_PRICE_V2: 'SET_PROPERTY_PRICE_V2',
    SET_PROPERTY_TYPE_V2: 'SET_PROPERTY_TYPE_V2',
    SET_INACTIVE_LISTING_V2: 'SET_INACTIVE_LISTING_V2',
    SET_AGENT_COMISSION_V2: 'SET_AGENT_COMISSION_V2',
    SET_MLS_CATEGORY_V2: 'SET_MLS_CATEGORY_V2',
    SET_BATHROOMS_V2: 'SET_BATHROOMS_V2',
    SET_BEDROOMS_V2: 'SET_BEDROOMS_V2',
    SET_SQUARES_V2: 'SET_SQUARES_V2',
    SET_ACRES_V2: 'SET_ACRES_V2',
    ESTIMATED_PRICE_REQUESTED_V2: 'ESTIMATED_PRICE_REQUESTED_V2',
    ESTIMATED_PRICE_RESOLVED_V2: 'ESTIMATED_PRICE_RESOLVED_V2',
    ESTIMATED_PRICE_FAILED_V2: 'ESTIMATED_PRICE_FAILED_V2',
    CREATE_DRAFT_LISTING_REQUESTED_V2: 'CREATE_DRAFT_LISTING_REQUESTED_V2',
    CREATE_DRAFT_LISTING_RESOLVED_V2: 'CREATE_DRAFT_LISTING_RESOLVED_V2',
    CREATE_DRAFT_LISTING_FAILED_V2: 'CREATE_DRAFT_LISTING_FAILED_V2',
    UPDATE_DRAFT_LISTING_REQUESTED_V2: 'UPDATE_DRAFT_LISTING_REQUESTED_V2',
    UPDATE_DRAFT_LISTING_RESOLVED_V2: 'UPDATE_DRAFT_LISTING_RESOLVED_V2',
    UPDATE_DRAFT_LISTING_FAILED_V2: 'UPDATE_DRAFT_LISTING_FAILED_V2',
    UPDATE_DRAFT_FORM_FIELDS_V2: 'UPDATE_DRAFT_FORM_FIELDS_V2',
    GET_DRAFT_LISTING_BY_ID_REQUESTED_V2: 'GET_DRAFT_LISTING_BY_ID_REQUESTED_V2',
    GET_DRAFT_LISTING_BY_ID_RESOLVED_V2: 'GET_DRAFT_LISTING_BY_ID_RESOLVED_V2',
    GET_DRAFT_LISTING_BY_ID_FAILED_V2: 'GET_DRAFT_LISTING_BY_ID_FAILED_V2',
    SET_LISTING_STEP_V2: 'SET_LISTING_STEP_V2',
    ADD_LISTING_PHOTO_BY_ID_REQUESTED_V2: 'ADD_LISTING_PHOTO_BY_ID_REQUESTED_V2',
    ADD_LISTING_PHOTO_BY_ID_RESOLVED_V2: 'ADD_LISTING_PHOTO_BY_ID_RESOLVED_V2',
    ADD_LISTING_PHOTO_BY_ID_FAILED_V2: 'ADD_LISTING_PHOTO_BY_ID_FAILED_V2',
    REORDER_LISTING_PHOTO_BY_ID_REQUESTED_V2: 'REORDER_LISTING_PHOTO_BY_ID_REQUESTED_V2',
    REORDER_LISTING_PHOTO_BY_ID_RESOLVED_V2: 'REORDER_LISTING_PHOTO_BY_ID_RESOLVED_V2',
    REORDER_LISTING_PHOTO_BY_ID_FAILED_V2: 'REORDER_LISTING_PHOTO_BY_ID_FAILED_V2',
    DELETE_LISTING_PHOTO_BY_ID_REQUESTED_V2: 'DELETE_LISTING_PHOTO_BY_ID_REQUESTED_V2',
    DELETE_LISTING_PHOTO_BY_ID_RESOLVED_V2: 'DELETE_LISTING_PHOTO_BY_ID_RESOLVED_V2',
    DELETE_LISTING_PHOTO_BY_ID_FAILED_V2: 'DELETE_LISTING_PHOTO_BY_ID_FAILED_V2',
    SET_PROPERTY_HELD_V2: 'SET_PROPERTY_HELD_V2',
    GET_LISTING_PRODUCTS_RESOLVED_V2: 'GET_LISTING_PRODUCTS_RESOLVED_V2',
    GET_LISTING_PRODUCTS_REQUESTED_V2: 'GET_LISTING_PRODUCTS_REQUESTED_V2',
    GET_LISTING_PRODUCTS_FAILEDV2: 'GET_LISTING_PRODUCTS_FAILED_V2',
    SET_SELECTED_PLAN_V2: 'SET_SELECTED_PLAN_V2',
    GET_LISTING_PHOTO_BY_ID_REQUESTED_V2: 'GET_LISTING_PHOTO_BY_ID_REQUESTED_V2',
    GET_LISTING_PHOTO_BY_ID_FAILED_V2: 'GET_LISTING_PHOTO_BY_ID_FAILED_V2',
    GET_LISTING_PHOTO_BY_ID_RESOLVED_V2: 'GET_LISTING_PHOTO_BY_ID_RESOLVED_V2',
    CREATE_LISTING_RESOLVED_V2: 'CREATE_LISTING_RESOLVED_V2',
    CREATE_LISTING_REQUESTED_V2: 'CREATE_LISTING_REQUESTED_V2',
    CREATE_LISTING_FAILED_V2: 'CREATE_LISTING_FAILED_V2',
    GET_LISTING_FORMS_REQUESTED_V2: 'GET_LISTING_FORMS_REQUESTED_V2',
    GET_LISTING_FORMS_RESOLVED_V2: 'GET_LISTING_FORMS_RESOLVED_V2',
    GET_LISTING_FORMS_FAILED_V2: 'GET_LISTING_FORMS_FAILED_V2',
    GET_PROPERTY_DETAILS_FORM_REQUESTED_V2: 'GET_PROPERTY_DETAILS_FORM_REQUESTED_V2',
    GET_PROPERTY_DETAILS_FORM_RESOLVED_V2: 'GET_PROPERTY_DETAILS_FORM_RESOLVED_V2',
    GET_PROPERTY_DETAILS_FORM_FAILED_V2: 'GET_PROPERTY_DETAILS_FORM_FAILED_V2',
    SET_ACTIVE_LISTING_FORM_V2: 'SET_ACTIVE_LISTING_FORM_V2',
    SAVE_FORM_FIELD_REQUESTED_V2: 'SAVE_FORM_FIELD_REQUESTED_V2',
    SAVE_FORM_FIELD_RESOLVED_V2: 'SAVE_FORM_FIELD_RESOLVED_V2',
    SAVE_FORM_FIELD_FAILED_V2: 'SAVE_FORM_FIELD_FAILED_V2',
    SUBMIT_MLS_FORM_REQUESTED_V2: 'SUBMIT_MLS_FORM_REQUESTED_V2',
    SUBMIT_MLS_FORM_RESOLVED_V2: 'SUBMIT_MLS_FORM_RESOLVED_V2',
    SUBMIT_MLS_FORM_FAILED_V2: 'SUBMIT_MLS_FORM_FAILED_V2',
    VOUCHER_REDEEM_V2: 'VOUCHER_REDEEM_V2',
    VALIDATE_VOUCHER_REQUESTED_V2: 'VALIDATE_VOUCHER_REQUESTED_V2',
    VALIDATE_VOUCHER_RESOLVED_V2: 'VALIDATE_VOUCHER_RESOLVED_V2',
    VALIDATE_VOUCHER_ERROR_V2: 'VALIDATE_VOUCHER_ERROR_V2',
    SAVE_PREVIEW_FORM_FIELD_REQUESTED_V2: 'SAVE_PREVIEW_FORM_FIELD_REQUESTED_V2',
    SAVE_PREVIEW_FORM_FIELD_RESOLVED_V2: 'SAVE_PREVIEW_FORM_FIELD_RESOLVED_V2',
    SAVE_PREVIEW_FORM_FIELD_FAILED_V2: 'SAVE_PREVIEW_FORM_FIELD_FAILED_V2',
    GET_LISTING_REQUESTED_V2: 'GET_LISTING_REQUESTED_V2',
    GET_LISTING_RESOLVED_V2: 'GET_LISTING_RESOLVED_V2',
    GET_LISTING_FAILED_V2: 'GET_LISTING_FAILED_V2',
    UPDATE_LISTING_FORMS_FIELDS_V2: 'UPDATE_LISTING_FORMS_FIELDS_V2',
    SET_DESCRIPTION_V2: 'SET_DESCRIPTION_V2',
    RESET_IS_ADDRESS_CHECKED_V2: 'RESET_IS_ADDRESS_CHECKED_V2',
    SET_TYPE_AND_CATEGORY_SELECT_DISABLED_V2: 'SET_TYPE_AND_CATEGORY_SELECT_DISABLED_V2',
    SET_TOTAL_SAVING_V2: 'SET_TOTAL_SAVING_V2',
    CLEAR_LISTING_DATA_V2: 'CLEAR_LISTING_DATA_V2',
    SET_SEARCH_INPUT_VALUE_V2: 'SET_SEARCH_INPUT_VALUE_V2',
    SET_SEARCH_INPUT_ERROR_V2: 'SET_SEARCH_INPUT_ERROR_V2',
    SET_SHOW_INACTIVE_LISTINGS_LIST_MODAL_V2: 'SET_SHOW_INACTIVE_LISTINGS_LIST_MODAL_V2',
    SET_DRAFT_LISTING_DEFAULT_VALUES_V2: 'SET_DRAFT_LISTING_DEFAULT_VALUES_V2',
    SET_SHOW_NETWORK_PLAN_INFO_POPUP_V2: 'SET_SHOW_NETWORK_PLAN_INFO_POPUP_V2',
    CHANGE_ADDRESS_V2: 'CHANGE_ADDRESS_V2',
    GET_LISTING_PROGRESS_RESOLVED_V2: 'GET_LISTING_PROGRESS_RESOLVED_V2',
    SET_SHOW_LISTING_DUPLICATE_ADDRESS_MODAL: 'SET_SHOW_LISTING_DUPLICATE_ADDRESS_MODAL',
    SET_PROPERTY_OWNERS: 'SET_PROPERTY_OWNERS',
    SEND_CONTACT_REQUESTED_V2: 'SEND_CONTACT_REQUESTED_V2',
    SEND_CONTACT_RESOLVED_V2: 'SEND_CONTACT_RESOLVED_V2',
    SEND_CONTACT_FAILED_V2: 'SEND_CONTACT_FAILED_V2',
    GET_TITLE_COMPANY: 'GET_TITLE_COMPANY',
    SET_PROPERTY_TITLE_COMANY: 'SET_PROPERTY_TITLE_COMANY',
    GET_UNASSIGNED_CASH_OFFER_REQUESTED: 'GET_UNASSIGNED_CASH_OFFER_REQUESTED',
    GET_UNASSIGNED_CASH_OFFER_RESOLVED: 'GET_UNASSIGNED_CASH_OFFER_RESOLVED',
    GET_UNASSIGNED_CASH_OFFER_FAILED: 'GET_UNASSIGNED_CASH_OFFER_FAILED',
    UPDATE_DRAFT_LISTING_CASH_OFFER_STATUS: 'UPDATE_DRAFT_LISTING_CASH_OFFER_STATUS',
};

const google = window.google;
const placesService = new google.maps.places.PlacesService(document.createElement('div'));

export const onboardingActionCreatorsV2 = {
    selectAddressV2: (address) => async (dispatch, getState) => {
        const manualAddressEnter = getState().propertyListing.manualAddressEnter;
        if (!manualAddressEnter) {
            address = convertSelectedAddress(address);
        } else {
            address = {
                ...address,
                formatted: `${address.address1} ${address.address2} ${address.city} ${address.county} ${address.state} ${address.zip}`,
            };
        }
        dispatch({ type: onboardingActions.SELECT_ADDRESS_V2, payload: address });
    },
    setSearchInputValue: (val) => ({ type: onboardingActions.SET_SEARCH_INPUT_VALUE_V2, payload: val }),
    setSearchInputError: (val) => ({ type: onboardingActions.SET_SEARCH_INPUT_ERROR_V2, payload: val }),
    closeModalV2: () => ({ type: onboardingActions.CLOSE_MODAL_POPUP_V2 }),
    nonServiceableAreaRequestV2: (userInfo) => async (dispatch) => {
        try {
            const { data } = await onboardingServiceV2.nonServiceableAreaRequestV2(userInfo);
            checkResponse(data);
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: 'onboarding actions nonServiceableAreaRequestV2 FAILED',
                    userInfo,
                },
            });
        }
    },
    getUnassignedCashOffer: (id) => async (dispatch) => {
        dispatch({ type: onboardingActions.GET_UNASSIGNED_CASH_OFFER_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));

        try {
            const { data } = await cashOfferService.getCashOffer(id);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            dispatch({ type: onboardingActions.GET_UNASSIGNED_CASH_OFFER_RESOLVED, payload: data });
        } catch (ex) {
            dispatch({ type: onboardingActions.GET_UNASSIGNED_CASH_OFFER_FAILED });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_getcashoffer_action',
                },
                extra: {
                    type: onboardingActions.GET_UNASSIGNED_CASH_OFFER_FAILED,
                },
            });
        }
    },
    getInactiveListings:
        (marketId = null) =>
            async (dispatch, getState) => {
                const market = marketId || getState().propertyListing.market;
                const selectedAddress = getState().propertyListing.selectedAddress;
                const manualAddressEnter = getState().propertyListing.manualAddressEnter;

                if (!selectedAddress) {
                    return;
                }

                dispatch(startLoading('MAIN_LOADER', staticMessages.searchingInactiveListings));
                dispatch({ type: onboardingActions.INACTIVE_LISTINGS_REQUESTED_V2 });
                try {
                    const params = {};
                    if (marketId) {
                        params.market = marketId;
                    }
                    if (selectedAddress.zip) {
                        params.zip = selectedAddress.zip;
                    }
                    if (selectedAddress.state) {
                        params.state = selectedAddress.state;
                    }
                    if (selectedAddress.county) {
                        params.county = selectedAddress.county;
                    }
                    if (selectedAddress.city) {
                        params.city = selectedAddress.city;
                    }
                    if (selectedAddress.name) {
                        params.address = selectedAddress.name;
                    } else if (selectedAddress.address1) {
                        params.address = selectedAddress.address1;
                    }

                    const query = new URLSearchParams(params).toString();
                    const { data: inactiveListings } = await onboardingServiceV2.getInactiveListingsV2(marketId, query);
                    dispatch(endLoading('MAIN_LOADER', staticMessages.searchingInactiveListings));
                    dispatch({ type: onboardingActions.INACTIVE_LISTINGS_RESOLVED_V2, payload: inactiveListings });

                    const externalParams = new URLSearchParams(window.location.search);
                    const externalCode = externalParams.get('externalCode');
                    const cashOfferId = externalParams.get('cashOfferId');

                    if (inactiveListings.length > 1) {
                        dispatch({ type: onboardingActions.SET_SHOW_INACTIVE_LISTINGS_LIST_MODAL_V2, payload: true });
                    }

                    if (inactiveListings.length === 0) {
                        dispatch(alertActions.successAlert('No prior listings were found.'));
                        dispatch(onboardingActionCreatorsV2.getAveragePriceV2(null, market));
                        setStateToUrl(Routes.ONBOARDING_V2, {
                            placeId: selectedAddress?.placeId,
                            market: market,
                            ...(externalCode && { externalCode }),
                            ...(cashOfferId && { cashOfferId }),
                        });
                    }

                    if (manualAddressEnter && inactiveListings.length === 0) {
                        dispatch(onboardingActionCreatorsV2.finishManualAddressEnter());
                    }

                    if (inactiveListings.length === 1) {
                        dispatch(alertActions.successAlert('We found your property.'));
                        setStateToUrl(Routes.ONBOARDING_V2, {
                            placeId: selectedAddress?.placeId,
                            inactiveListingId: inactiveListings[0].id,
                            market: market,
                            ...(externalCode && { externalCode }),
                            ...(cashOfferId && { cashOfferId }),
                        });

                        dispatch(onboardingActionCreatorsV2.setInactiveListingV2(inactiveListings[0]));
                        dispatch(onboardingActionCreatorsV2.getAveragePriceV2(null, market));
                    }

                    return inactiveListings;
                } catch (ex) {
                    dispatch({ type: onboardingActions.INACTIVE_LISTINGS_FAILED_V2, payload: [] });
                    dispatch(endLoading('MAIN_LOADER', staticMessages.searchingInactiveListings));
                    const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                    dispatch(alertActions.errorAlert(errorMessage));

                    LogRocket.captureException(ex, {
                        tags: {
                            errorType: 'failed_onboarding_action',
                        },
                        extra: {
                            type: onboardingActions.INACTIVE_LISTINGS_FAILED_V2,
                            marketId,
                        },
                    });
                }
            },

    getInactiveListing: (id, market) => async (dispatch) => {
        dispatch(startLoading('AVERAGE_PRICE_LOADING', 'Loading'));
        dispatch({ type: onboardingActions.INACTIVE_LISTING_REQUESTED_V2 });
        try {
            const { data } = await onboardingServiceV2.getInactiveListingV2(id, market);
            dispatch(onboardingActionCreatorsV2.getAveragePriceV2(id, market));
            dispatch(endLoading('AVERAGE_PRICE_LOADING', 'Loading'));
            dispatch({ type: onboardingActions.INACTIVE_LISTING_RESOLVED_V2, payload: data });
            dispatch(onboardingActionCreatorsV2.setInactiveListingV2(data));
        } catch (ex) {
            dispatch({ type: onboardingActions.INACTIVE_LISTING_FAILED_V2, payload: null });
            dispatch(endLoading('AVERAGE_PRICE_LOADING', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.INACTIVE_LISTING_FAILED_V2,
                    id,
                    market,
                },
            });
        }
    },

    getAveragePriceV2:
        (inactiveListingId = null, marketId = null) =>
            async (dispatch, getState) => {
                const market = marketId || getState().propertyListing.market;
                const currentInactiveListingId = inactiveListingId || getState().propertyListing.currentInactiveListing?.id;
                const selectedAddress = getState().propertyListing.selectedAddress;
                if (!selectedAddress || !market) {
                    return;
                }

                dispatch(startLoading('AVERAGE_PRICE_LOADING', 'Loading'));
                dispatch({ type: onboardingActions.AVERAGE_PRICE_REQUESTED_V2 });
                try {
                    const params = { market };

                    if (currentInactiveListingId) {
                        params.id = currentInactiveListingId;
                    }
                    if (selectedAddress.zip) {
                        params.zip = selectedAddress.zip;
                    }
                    if (selectedAddress.state) {
                        params.state = selectedAddress.state;
                    }
                    if (selectedAddress.county) {
                        params.county = selectedAddress.county;
                    }
                    if (selectedAddress.city) {
                        params.city = selectedAddress.city;
                    }
                    if (selectedAddress.name) {
                        params.address = selectedAddress.name;
                    } else if (selectedAddress.address1) {
                        params.address = selectedAddress.address1;
                    }

                    const query = new URLSearchParams(params).toString();
                    const { data } = await onboardingServiceV2.getAveragePriceRangeV2(params.market, query);
                    dispatch(endLoading('AVERAGE_PRICE_LOADING', 'Loading'));
                    dispatch({ type: onboardingActions.AVERAGE_PRICE_RESOLVED_V2, payload: data });
                } catch (ex) {
                    dispatch({ type: onboardingActions.AVERAGE_PRICE_FAILED_V2 });
                    dispatch(endLoading('AVERAGE_PRICE_LOADING', 'Loading'));
                    const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                    dispatch(alertActions.errorAlert(errorMessage));

                    LogRocket.captureException(ex, {
                        tags: {
                            errorType: 'failed_onboarding_action',
                        },
                        extra: {
                            type: onboardingActions.AVERAGE_PRICE_FAILED_V2,
                            inactiveListingId,
                            marketId,
                        },
                    });
                }
            },
    setPropertyPriceV2: (price = 0) => ({ type: onboardingActions.SET_PROPERTY_PRICE_V2, payload: { price } }),
    setTotalSaving: () => (dispatch, getState) => {
        const { agentCommission, propertyPrice } = getState().propertyListing;
        let totalSavingPercent = 30; // 30 means 3%, 40 means 4% etc.
        if (agentCommission < 30) {
            totalSavingPercent = 30 - agentCommission + 30;
        }
        const totalSaving = ((Number(propertyPrice) * totalSavingPercent) / 1000).toFixed(0);
        dispatch({ type: onboardingActions.SET_TOTAL_SAVING_V2, payload: { totalSaving } });
    },
    setAgentCommisionV2: (commission) => ({ type: onboardingActions.SET_AGENT_COMISSION_V2, payload: commission }),
    setBathroomsV2: (bathrooms) => ({ type: onboardingActions.SET_BATHROOMS_V2, payload: bathrooms }),
    setBedroomsV2: (bedrooms) => ({ type: onboardingActions.SET_BEDROOMS_V2, payload: bedrooms }),
    setSquaresV2: (squares) => ({ type: onboardingActions.SET_SQUARES_V2, payload: squares }),
    setAcresV2: (acres) => ({ type: onboardingActions.SET_ACRES_V2, payload: acres }),
    setPropertyTypeV2: (propertyType) => ({ type: onboardingActions.SET_PROPERTY_TYPE_V2, payload: propertyType }),
    setMlsCategoryV2: (mlsCategory) => ({ type: onboardingActions.SET_MLS_CATEGORY_V2, payload: mlsCategory }),
    setTypeAndCategorySelectDisabledV2: (val) => ({
        type: onboardingActions.SET_TYPE_AND_CATEGORY_SELECT_DISABLED_V2,
        payload: val,
    }),
    setVoucherEnterV2: (isVoucherEnter) => ({ type: onboardingActions.SET_VOUCHER_ENTER_V2, payload: isVoucherEnter }),
    redeemVoucherV2: (data) => async (dispatch) => {
        dispatch({ type: onboardingActions.VOUCHER_REDEEM_V2, payload: data });
        if (data.marketId) {
            await dispatch(onboardingActionCreatorsV2.getInactiveListings(data.marketId)); // requires 'marketId' && address
        }
    },

    validateVoucherV2: (voucherCode) => async (dispatch) => {
        dispatch({ type: onboardingActions.VALIDATE_VOUCHER_REQUESTED_V2 });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));

        try {
            const { data } = await onboardingServiceV2.validateVoucher(voucherCode);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            dispatch({ type: onboardingActions.VALIDATE_VOUCHER_RESOLVED_V2, payload: data });
        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            if (ex?.response?.status === 404) {
                dispatch({ type: onboardingActions.VALIDATE_VOUCHER_ERROR_V2, payload: true });
            } else {
                const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                dispatch(alertActions.errorAlert(errorMessage));

                LogRocket.captureException(ex, {
                    tags: {
                        errorType: 'failed_onboarding_action',
                    },
                    extra: {
                        type: onboardingActions.VALIDATE_VOUCHER_ERROR_V2,
                    },
                });
            }
        }
    },

    sendContactV2: (contact) => async (dispatch) => {
        dispatch({ type: onboardingActions.SEND_CONTACT_REQUESTED_V2 });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));

        try {
            const { data } = await onboardingServiceV2.sendContact(contact);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            dispatch({ type: onboardingActions.SEND_CONTACT_RESOLVED_V2, payload: data });
        } catch (ex) {
            dispatch({ type: onboardingActions.SEND_CONTACT_FAILED_V2 });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_sendcontact_action',
                },
                extra: {
                    type: onboardingActions.SEND_CONTACT_FAILED_V2,
                },
            });
        }
    },

    setListingStepV2: (step) => async (dispatch, getState) => {
        const { draftListingId } = getState().propertyListing;
        try {
            if (!draftListingId) {
                const userId = getState().user.userDetails.userId;
                const address = getState().propertyListing.selectedAddress;

                const validateAddressResponse = await onboardingServiceV2.validateAddressV2(userId, address);

                if (validateAddressResponse.data?.isAddressExists) {
                    dispatch({ type: onboardingActions.SET_SHOW_LISTING_DUPLICATE_ADDRESS_MODAL, payload: true });
                    return;
                }

                await dispatch(onboardingActionCreatorsV2.createDraftListingV2());
                dispatch({ type: onboardingActions.SET_LISTING_STEP_V2, payload: step });
            } else {
                dispatch({ type: onboardingActions.SET_LISTING_STEP_V2, payload: step });
                dispatch(onboardingActionCreatorsV2.updateDraftListingV2());

                const email = getState().user.userDetails.email;
                const data = getDraftListingData();
                const { address1, city, zip, state } = data.listingData;
                dispatch(
                    onboardingActionCreatorsV2.sendContactV2({
                        email: email,
                        step: data.step,
                        address: `${address1}, ${city}, ${state}, ${zip}`,
                    })
                );
            }
        } catch (ex) {
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: 'onboarding actions setListingStepV2 FAILED',
                },
            });
        }
    },
    getEstimatePriceV2: () => async (dispatch, getState) => {
        const selectedAddress = getState().propertyListing.selectedAddress;
        if (!selectedAddress) {
            return;
        }
        dispatch({ type: onboardingActions.ESTIMATED_PRICE_REQUESTED_V2 });
        try {
            const address = {};
            if (selectedAddress.zip) {
                address.zip = selectedAddress.zip;
            }
            if (selectedAddress.state) {
                address.state = selectedAddress.state;
            }
            if (selectedAddress.county) {
                address.county = selectedAddress.county;
            }
            if (selectedAddress.address1) {
                address.address = selectedAddress.address1;
            }
            if (selectedAddress.city) {
                address.city = selectedAddress.city;
            }
            const query = new URLSearchParams(address).toString();
            const { data } = await onboardingServiceV2.getEstimatedPriceV2(query);
            dispatch({ type: onboardingActions.ESTIMATED_PRICE_RESOLVED_V2, payload: data.estimatedPrice });
        } catch (ex) {
            dispatch({ type: onboardingActions.ESTIMATED_PRICE_FAILED_V2 });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.ESTIMATED_PRICE_FAILED_V2,
                },
            });
        }
    },
    checkAddressV2:
        (address = null) =>
            async (dispatch, getState) => {
                const selectedAddress = address || getState().propertyListing.selectedAddress;
                const manualAddressEnter = getState().propertyListing.manualAddressEnter;

                dispatch({ type: onboardingActions.CHECK_ADDRESS_COVERAGE_REQUESTED_V2 });
                try {
                    const address = {};
                    if (selectedAddress.zip) {
                        address.zip = selectedAddress.zip;
                    }
                    if (selectedAddress.state) {
                        address.state = selectedAddress.state;
                    }
                    if (selectedAddress.county) {
                        address.county = selectedAddress.county;
                    }

                    const externalParams = new URLSearchParams(window.location.search);
                    const externalCode = externalParams.get('externalCode');
                    const cashOfferId = externalParams.get('cashOfferId');

                    const query = new URLSearchParams(address).toString();
                    const {
                        data: { hasMls, marketId, companyType, mlsId },
                    } = await onboardingServiceV2.checkAddressV2(query);
                    if (hasMls && marketId) {
                        await dispatch(onboardingActionCreatorsV2.getInactiveListings(marketId)); // requires 'marketId' && address
                    }
                    if (!hasMls) {
                        setStateToUrl(Routes.ONBOARDING_V2, {
                            ...(externalCode && { externalCode }),
                            ...(cashOfferId && { cashOfferId }),
                        });
                    }
                    if (manualAddressEnter && hasMls && !marketId) {
                        dispatch(onboardingActionCreatorsV2.finishManualAddressEnter());
                    }
                    dispatch({
                        type: onboardingActions.CHECK_ADDRESS_COVERAGE_RESOLVED_V2,
                        payload: {
                            isAddressCoverageable: hasMls,
                            market: marketId,
                            companyType: companyType,
                            mlsId: mlsId,
                        },
                    });
                } catch (ex) {
                    const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                    dispatch(alertActions.errorAlert(errorMessage));
                    dispatch({
                        type: onboardingActions.CHECK_ADDRESS_COVERAGE_FAILED_V2,
                        payload: { isAddressCoverageable: false, market: null, companyType: null, mlsId: null },
                    });

                    LogRocket.captureException(ex, {
                        tags: {
                            errorType: 'failed_onboarding_action',
                        },
                        extra: {
                            type: onboardingActions.CHECK_ADDRESS_COVERAGE_FAILED_V2,
                            selectedAddress,
                        },
                    });
                }
            },
    getListingProductsV2: (draftListingId) => async (dispatch) => {
        dispatch({ type: onboardingActions.GET_LISTING_PRODUCTS_REQUESTED_V2 });
        try {
            const { data } = await onboardingServiceV2.getListingProductsV2(draftListingId);
            dispatch({ type: onboardingActions.GET_LISTING_PRODUCTS_RESOLVED_V2, payload: data });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.GET_LISTING_PRODUCTS_FAILEDV2 });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.GET_LISTING_PRODUCTS_FAILEDV2,
                },
            });
        }
    },
    clearAddressV2: () => async (dispatch) => {
        onboardingServiceV2.clearLocal('selectedPlan');
        onboardingServiceV2.clearLocal('selectedAddress');
        dispatch({ type: onboardingActions.CLEAR_ADDRESS_V2 });
    },

    setInactiveListingV2: (inactiveListing) => async (dispatch, getState) => {
        const market = getState().propertyListing.market;
        const selectedAddress = getState().propertyListing.selectedAddress;
        const manualAddressEnter = getState().propertyListing.manualAddressEnter;

        const externalParams = new URLSearchParams(window.location.search);
        const externalCode = externalParams.get('externalCode');
        const cashOfferId = externalParams.get('cashOfferId');

        let params = {
            inactiveListingId: inactiveListing.id,
            market: market,
        };

        if (selectedAddress?.placeId) {
            params.placeId = selectedAddress?.placeId;
        } else {
            params = {
                ...params,
                address1: selectedAddress.address1,
                address2: selectedAddress.address2,
                county: selectedAddress.county,
                city: selectedAddress.city,
                state: selectedAddress.state,
                zip: selectedAddress.zip,
                ...(externalCode && { externalCode }),
                ...(cashOfferId && { cashOfferId }),
            };
        }

        setStateToUrl(Routes.ONBOARDING_V2, params);

        dispatch({ type: onboardingActions.SET_INACTIVE_LISTING_V2, payload: inactiveListing });
        dispatch(onboardingActionCreatorsV2.getAveragePriceV2(inactiveListing.id, market)); // requires marketId && address // inactiveListingId (optional)
        if (manualAddressEnter) {
            dispatch(onboardingActionCreatorsV2.finishManualAddressEnter());
        }
    },

    setManualAddressEnterV2: (manualAddressEnter) => (dispatch) => {
        dispatch({ type: onboardingActions.SET_MANUAL_ADDRESS_ENTER_V2, payload: manualAddressEnter });
        dispatch({ type: onboardingActions.CLEAR_ADDRESS_V2 });
    },
    setPropertyHeldV2: (val) => ({ type: onboardingActions.SET_PROPERTY_HELD_V2, payload: val }),

    createListingV2: (model, callback) => async (dispatch, getState) => {
        const companyType = getState().propertyListing?.companyType;
        const brokerInfo = getState().propertyListing?.planData?.brokerInfo;
        try {
            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: onboardingActions.CREATE_LISTING_REQUESTED_V2 });
            const result = await onboardingServiceV2.createListingV2(model);
            dispatch({ type: onboardingActions.CREATE_LISTING_RESOLVED_V2, payload: result.data.listingId });
            if (companyType === companyTypes.NETWORK && (brokerInfo?.brokerEmail || brokerInfo?.companyPhoneNumber)) {
                dispatch({ type: onboardingActions.SET_SHOW_NETWORK_PLAN_INFO_POPUP_V2, payload: true });
            } else {
                dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.CONTRACTS_SIGN));
            }
            if (callback) {
                callback(true);
            }
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            if (callback) {
                callback(false);
            }
            dispatch({ type: onboardingActions.CREATE_LISTING_FAILED_V2 });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.CREATE_LISTING_FAILED_V2,
                    model,
                },
            });
        }
    },
    createDraftListingV2: () => async (dispatch, getState) => {
        const userId = getState().user.userDetails.userId;
        const address = getState().propertyListing.selectedAddress;
        const externalParams = new URLSearchParams(window.location.search);
        const cashOfferId = externalParams.get('cashOfferId');

        try {
            dispatch({ type: onboardingActions.CREATE_DRAFT_LISTING_REQUESTED_V2 });
            dispatch(startLoading('MAIN_LOADER', 'Loading'));

            const {
                data: { id: listingId },
            } = await onboardingServiceV2.createDraftListingV2({ userId, cashOfferId, address });
            history.replace({
                pathname: Routes.ONBOARDING_V2,
                search: new URLSearchParams({ listing_id: listingId }).toString(),
            });
            dispatch({ type: onboardingActions.CREATE_DRAFT_LISTING_RESOLVED_V2, payload: listingId });
            dispatch({ type: onboardingActions.UPDATE_DRAFT_LISTING_REQUESTED_V2 });
            const data = getDraftListingData();

            if (data.companyType === companyTypes.NETWORK) {
                const defaultValues = {
                    price: 0,
                    bathrooms: 0,
                    bedrooms: 0,
                    squareFeet: 0,
                    propertyTypeId: 1,
                    mlsCategoryId: 1,
                    acres: 0,
                };
                dispatch({ type: onboardingActions.SET_DRAFT_LISTING_DEFAULT_VALUES_V2, payload: defaultValues });
                data.listingData = { ...data.listingData, ...defaultValues };
            }

            await onboardingServiceV2.updateDraftListingV2(data);
            dispatch({ type: onboardingActions.UPDATE_DRAFT_LISTING_RESOLVED_V2 });

            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            if (data.companyType === companyTypes.NETWORK) {
                dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PLAN_SELECTOR));
            }
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.CREATE_DRAFT_LISTING_FAILED_V2 });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.CREATE_DRAFT_LISTING_FAILED_V2,
                },
            });
        }
    },
    updateDraftListingV2: () => async (dispatch) => {
        const data = getDraftListingData();

        try {
            dispatch({ type: onboardingActions.UPDATE_DRAFT_LISTING_REQUESTED_V2 });
            const { data: { productType } } = await onboardingServiceV2.updateDraftListingV2(data);
            dispatch({ type: onboardingActions.UPDATE_DRAFT_LISTING_RESOLVED_V2, payload: productType });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.UPDATE_DRAFT_LISTING_FAILED_V2 });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.UPDATE_DRAFT_LISTING_FAILED_V2,
                },
            });
        }
    },
    updateDraftFormFieldsV2: (draftFormsFields) => async (dispatch) => {
        dispatch({ type: onboardingActions.UPDATE_DRAFT_FORM_FIELDS_V2, payload: draftFormsFields });
    },
    updateListingFormsFieldsV2: (formsFields) => async (dispatch) => {
        dispatch({ type: onboardingActions.UPDATE_LISTING_FORMS_FIELDS_V2, payload: formsFields });
    },
    getDraftListingByIdV2: (id) => async (dispatch) => {
        try {
            dispatch({ type: onboardingActions.GET_DRAFT_LISTING_BY_ID_REQUESTED_V2 });
            const { data } = await onboardingServiceV2.getDraftListingById(id);

            if (data.listingData?.ownershipType?.ownersData?.length) {
                data.listingData.ownershipType.ownersData = data.listingData.ownershipType.ownersData.map((owner) =>
                    getPropertyOwnerFields(owner.ownerType, owner)
                );
            } else {
                data.listingData.ownershipType.ownersData = [getPropertyOwnerFields(ownerTypes.individual)];
            }

            if (data.listingData?.placeId) {
                placesService.getDetails(
                    {
                        placeId: data.listingData?.placeId,
                    },
                    (place, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            data.listingData.address = {
                                ...convertSelectedAddress(place),
                                address1: data.listingData?.address1 ?? '',
                                address2: data.listingData?.address2 ?? '',
                                formatted: data.listingData?.formatted ?? '',
                            };
                        } else {
                            dispatch(alertActions.errorAlert(staticMessages.apiError));
                        }
                        dispatch({ type: onboardingActions.GET_DRAFT_LISTING_BY_ID_RESOLVED_V2, payload: data });
                    }
                );
            } else {
                dispatch({ type: onboardingActions.GET_DRAFT_LISTING_BY_ID_RESOLVED_V2, payload: data });
            }
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.GET_DRAFT_LISTING_BY_ID_FAILED_V2 });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.GET_DRAFT_LISTING_BY_ID_FAILED_V2,
                    id,
                },
            });
        }
    },

    getDraftListingPhotoV2: () => async (dispatch, getState) => {
        const draftListingId = getState().propertyListing.draftListingId;
        try {
            dispatch({ type: onboardingActions.GET_LISTING_PHOTO_BY_ID_REQUESTED_V2 });
            const { data } = await onboardingServiceV2.getDraftListingPhotos(draftListingId);
            dispatch({ type: onboardingActions.GET_LISTING_PHOTO_BY_ID_RESOLVED_V2, payload: data });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.GET_LISTING_PHOTO_BY_ID_FAILED_V2 });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.GET_LISTING_PHOTO_BY_ID_FAILED_V2,
                },
            });
        }
    },

    addListingPhotoV2:
        ({ file, filePath, url, thumbFile, thumbFilePath }) =>
            async (dispatch, getState) => {
                const draftListingId = getState().propertyListing.draftListingId;
                const order = getState().propertyListing.images.length;
                try {
                    dispatch({ type: onboardingActions.ADD_LISTING_PHOTO_BY_ID_REQUESTED_V2, payload: { url, order } });
                    const fileContent = await filrService.blobToBase64(file);
                    const { data } = await onboardingServiceV2.addListingPhoto(draftListingId, filePath, fileContent, order);

                    const thumbFileContent = await filrService.blobToBase64(thumbFile);
                    await onboardingServiceV2.addListingPhotoThumb(draftListingId, thumbFilePath, thumbFileContent);

                    dispatch({ type: onboardingActions.ADD_LISTING_PHOTO_BY_ID_RESOLVED_V2, payload: { order, data } });
                } catch (ex) {
                    const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                    dispatch(alertActions.errorAlert(errorMessage));
                    dispatch({ type: onboardingActions.ADD_LISTING_PHOTO_BY_ID_FAILED_V2, payload: order });

                    LogRocket.captureException(ex, {
                        tags: {
                            errorType: 'failed_onboarding_action',
                        },
                        extra: {
                            type: onboardingActions.ADD_LISTING_PHOTO_BY_ID_FAILED_V2,
                        },
                    });
                }
            },
    reorderListingPhotoIdV2:
        (imageId, newPosition = 0) =>
            async (dispatch, getState) => {
                const draftListingId = getState().propertyListing.draftListingId;
                try {
                    dispatch({ type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_REQUESTED_V2 });
                    await onboardingServiceV2.reorderListingPhoto({ draftListingId, order: newPosition, imageId });
                    dispatch({ type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_RESOLVED_V2 });
                } catch (ex) {
                    const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                    dispatch(alertActions.errorAlert(errorMessage));
                    dispatch({ type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_FAILED_V2 });

                    LogRocket.captureException(ex, {
                        tags: {
                            errorType: 'failed_onboarding_action',
                        },
                        extra: {
                            type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_FAILED_V2,
                            imageId,
                        },
                    });
                }
            },
    reorderListingPhotos: (imageIdsArr) => async (dispatch, getState) => {
        const draftListingId = getState().propertyListing.draftListingId;
        try {
            dispatch({ type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_REQUESTED_V2 });
            const { data: photos } = await onboardingServiceV2.reorderListingPhotos({
                draftListingId,
                imageIds: imageIdsArr,
            });
            dispatch({ type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_RESOLVED_V2, payload: photos });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_FAILED_V2 });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.REORDER_LISTING_PHOTO_BY_ID_FAILED_V2,
                },
            });
        }
    },
    deleteListingPhotoIdV2: (id) => async (dispatch, getState) => {
        const draftListingId = getState().propertyListing.draftListingId;
        const images = getState().propertyListing.images;
        const filtredImages = images.filter((i) => i.id !== id);
        try {
            dispatch({ type: onboardingActions.DELETE_LISTING_PHOTO_BY_ID_REQUESTED_V2 });
            await onboardingServiceV2.deleteListingPhoto({
                draftListingId,
                imageId: id,
            });
            dispatch({ type: onboardingActions.DELETE_LISTING_PHOTO_BY_ID_RESOLVED_V2, payload: filtredImages });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.DELETE_LISTING_PHOTO_BY_ID_FAILED_V2 });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.DELETE_LISTING_PHOTO_BY_ID_FAILED_V2,
                },
            });
        }
    },
    setPlanV2: (id) => ({ type: onboardingActions.SET_SELECTED_PLAN_V2, payload: id }),
    getPropertyDetailsFormV2: (listingId) => async (dispatch) => {
        try {
            dispatch({ type: onboardingActions.GET_PROPERTY_DETAILS_FORM_REQUESTED_V2 });
            const { data } = await onboardingServiceV2.getPropertyDetailsForm(listingId);
            dispatch({ type: onboardingActions.GET_PROPERTY_DETAILS_FORM_RESOLVED_V2, payload: data });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: onboardingActions.GET_PROPERTY_DETAILS_FORM_FAILED_V2 });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.GET_PROPERTY_DETAILS_FORM_FAILED_V2,
                    listingId,
                },
            });
        }
    },
    setActiveListingFormIndex: (formIndex) => ({
        type: onboardingActions.SET_ACTIVE_LISTING_FORM_V2,
        payload: formIndex,
    }),
    saveFormFieldV2: (userFormId, formFieldId, value) => async (dispatch) => {
        dispatch({ type: onboardingActions.SAVE_FORM_FIELD_REQUESTED_V2 });

        try {
            const { data } = await onboardingServiceV2.saveFormFieldV2(userFormId, formFieldId, value);
            checkResponse(data);
            dispatch({ type: onboardingActions.SAVE_FORM_FIELD_RESOLVED_V2, payload: formFieldId });
        } catch (ex) {
            dispatch({ type: onboardingActions.SAVE_FORM_FIELD_FAILED_V2, payload: formFieldId });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.SAVE_FORM_FIELD_FAILED_V2,
                    userFormId,
                    formFieldId,
                    value,
                },
            });
        }
    },
    submitMLSFormV2: (draftListingId, cb) => async (dispatch, getState) => {
        dispatch({ type: onboardingActions.SUBMIT_MLS_FORM_REQUESTED_V2 });

        try {
            await onboardingServiceV2.submitMLSFormV2(draftListingId);
            cb();
            dispatch({ type: onboardingActions.SUBMIT_MLS_FORM_RESOLVED_V2 });
        } catch (ex) {
            dispatch({ type: onboardingActions.SUBMIT_MLS_FORM_FAILED_V2 });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.SUBMIT_MLS_FORM_FAILED_V2,
                },
            });
        }
    },
    savePreviewFormFieldV2: (reqObj, cb) => async (dispatch) => {
        dispatch({ type: onboardingActions.SAVE_PREVIEW_FORM_FIELD_REQUESTED_V2 });

        let dataJson;

        try {
            const { data } = await onboardingServiceV2.savePreviewFormFieldV2(reqObj);
            dataJson = data;
            checkResponse(data);
            cb();
            dispatch({ type: onboardingActions.SAVE_PREVIEW_FORM_FIELD_RESOLVED_V2, payload: dataJson });
        } catch (ex) {
            dispatch({ type: onboardingActions.SAVE_PREVIEW_FORM_FIELD_FAILED_V2 });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.SAVE_PREVIEW_FORM_FIELD_FAILED_V2,
                    reqObj,
                },
            });
        }
    },
    getListingFormsV2: (listingId) => async (dispatch, getState) => {
        dispatch({ type: onboardingActions.GET_LISTING_REQUESTED_V2 });
        try {
            const { data } = await onboardingServiceV2.getListingFormsV2(listingId);
            const forms = splitListingForms(data);
            const formsFields = getListingFomsFieldsObject(forms);
            dispatch({
                type: onboardingActions.GET_LISTING_RESOLVED_V2,
                payload: {
                    forms: forms || [],
                    formsFields,
                },
            });
        } catch (ex) {
            dispatch({ type: onboardingActions.GET_LISTING_FAILED_V2 });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: onboardingActions.GET_LISTING_FAILED_V2,
                    listingId,
                },
            });
        }
    },
    setDescriptionV2: (val) => ({ type: onboardingActions.SET_DESCRIPTION_V2, payload: val }),
    resetIsAddressCheckedV2: () => ({ type: onboardingActions.RESET_IS_ADDRESS_CHECKED_V2 }),
    clearListingData: () => ({ type: onboardingActions.CLEAR_LISTING_DATA_V2 }),
    finishManualAddressEnter: () => async (dispatch, getState) => {
        const userLoggedIn = getState().user.userDetails?.token;
        const address = getState().propertyListing.selectedAddress;
        const companyType = getState()?.propertyListing?.companyType;

        if (userLoggedIn) {
            gTag({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: 1,
                        },
                        products: [
                            {
                                address: `${address?.address1 || ''} ${address?.address2 || ''}`,
                            },
                        ],
                    },
                },
            });
            const step = companyType === companyTypes.NETWORK ? LYFSteps.PLAN_SELECTOR : LYFSteps.PRICE_AND_COMISSIONS;
            dispatch(onboardingActionCreatorsV2.setListingStepV2(step));
            return;
        }

        dispatch(UIActionsCreators.openSignUpModal());
        dispatch(
            userActionCreators.setLoginCallBackAction({
                type: onboardingActions.SET_LISTING_STEP_V2,
                payload: LYFSteps.PRICE_AND_COMISSIONS,
            })
        );
    },
    generateDescription: () => async (dispatch, getState) => {
        try {
            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            const draftListingId = getState().propertyListing.draftListingId;
            const result = await draftListingService.generateDescription(draftListingId);
            dispatch(onboardingActionCreatorsV2.setDescriptionV2(result.data));
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: 'onboarding actions generateDescription FAILED',
                },
            });
        }
    },
    changeAddress:
        ({ address1 = '', address2 = '' }) =>
            async (dispatch, getState) => {
                const address = getState().propertyListing.selectedAddress;

                dispatch({
                    type: onboardingActions.CHANGE_ADDRESS_V2,
                    payload: {
                        address1,
                        address2,
                        formatted: `${address1} ${address2} ${address.city} ${address.county} ${address.state} ${address.zip}`,
                    },
                });
            },
    getLisitngProgress: () => async (dispatch, getState) => {
        try {
            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            const draftListingId = getState().propertyListing.draftListingId;
            const { data: result } = await draftListingService.getLisitngProgress(draftListingId);
            dispatch({ type: onboardingActions.GET_LISTING_PROGRESS_RESOLVED_V2, payload: result });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: 'onboarding actions getLisitngProgress FAILED',
                },
            });
        }
    },
    setPropertyOwners: (val) => ({
        type: onboardingActions.SET_PROPERTY_OWNERS,
        payload: val,
    }),
    getTitleCompany: () => async (dispatch, getState) => {
        try {
            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            //todo and companyId
            const state = getState().propertyListing.selectedAddress.state;
            const { data: result } = await titleCompanyService.getTitleCompany(state, null);
            dispatch({ type: onboardingActions.GET_TITLE_COMPANY, payload: result });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: 'onboarding actions getLisitngProgress FAILED',
                },
            });
        }
    },
    setTitleCompany: (val) => ({ type: onboardingActions.SET_PROPERTY_TITLE_COMANY, payload: val }),
    updateDraftListingCashOfferStatus: (cashOfferId) => ({
        type: onboardingActions.UPDATE_DRAFT_LISTING_CASH_OFFER_STATUS,
        payload: cashOfferId,
    }),
};
