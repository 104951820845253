

import React from 'react'

export const InputMapMarker = () => (
  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.35938 5.83623C9.35059 6.8238 8.77461 7.75113 7.86359 8.0799L7.7607 8.11127C7.70047 8.12884 7.63521 8.1439 7.57749 8.15645C7.51976 8.16899 7.62015 8.15017 7.54988 8.15645L7.44447 8.17025C7.37287 8.17948 7.30082 8.18492 7.22864 8.18656C7.0017 8.18676 6.77595 8.15378 6.55855 8.08868C6.10368 7.94705 5.70104 7.67368 5.40157 7.30315C5.0851 6.91193 4.89421 6.43423 4.8539 5.93265C4.8136 5.43107 4.92576 4.92901 5.1757 4.49228C5.4032 4.1037 5.74015 3.79077 6.14445 3.59256C6.33716 3.50115 6.54364 3.44222 6.75556 3.41813C6.98315 3.39023 7.21378 3.39998 7.4382 3.44699C7.97911 3.57197 8.46243 3.87485 8.81075 4.30714C9.15908 4.73942 9.35227 5.27609 9.35938 5.83121C9.35938 6.00187 9.54007 6.03951 9.68312 6.02571C9.80233 6.01316 10.0922 5.92156 10.0909 5.75717C10.0886 5.17228 9.90488 4.60253 9.56504 4.12649C9.22521 3.65045 8.74603 3.29159 8.19362 3.0994C7.59911 2.90156 6.95945 2.88354 6.35475 3.0476C5.75005 3.21167 5.20721 3.55052 4.79423 4.02171C4.40976 4.47084 4.17633 5.02955 4.12701 5.61871C4.07769 6.20786 4.21497 6.7976 4.51942 7.3044C5.16943 8.3773 6.47071 8.82528 7.67035 8.58184C9.00802 8.31079 10.0797 7.14253 10.0909 5.75717C10.0909 5.58651 9.90774 5.54887 9.76595 5.56267C9.64548 5.57648 9.36063 5.67059 9.35938 5.83623Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4"/>
  <path d="M7.35726 21.3875L7.75379 20.5091L8.74638 18.3244L10.0339 15.4897L11.3163 12.6663L12.2951 10.5117L12.5774 9.89053C12.6233 9.81295 12.6556 9.72806 12.6728 9.63956C12.7029 9.20413 12.6728 8.75489 12.6728 8.31695V0.462841C12.6728 0.381276 12.5021 0.375 12.4557 0.375H1.82713C1.67153 0.375 1.31641 0.451546 1.31641 0.657341V9.72865C1.31641 9.93696 1.45319 10.1315 1.53726 10.3172L2.4006 12.2158L3.63161 14.9262L4.95046 17.8187L6.04092 20.2205C6.23166 20.6383 6.41737 21.0587 6.61062 21.4753C6.6194 21.4929 6.62819 21.513 6.63572 21.5318C6.73862 21.7576 7.45011 21.5255 7.36353 21.336L6.96574 20.4576L5.97441 18.2742L4.69321 15.4545L3.4095 12.6299L2.42319 10.4703L2.13959 9.84285C2.10119 9.77532 2.06927 9.70431 2.04423 9.63078C2.03215 9.50558 2.03215 9.37952 2.04423 9.25432V0.462841L1.5335 0.746438H12.1621L11.945 0.657341V9.72489C11.9481 9.76037 11.9481 9.79607 11.945 9.83155C11.96 9.7412 11.935 9.81524 11.9224 9.84034C11.8496 9.98214 11.7894 10.1327 11.7241 10.2783L10.8646 12.1706L9.62603 14.8949L8.31345 17.781L7.22801 20.1652C7.03853 20.5819 6.83022 20.9947 6.6583 21.4201C6.65078 21.4389 6.64199 21.4577 6.63321 21.4766C6.56795 21.6209 6.87665 21.6121 6.93939 21.602C7.0837 21.5908 7.28949 21.528 7.35726 21.3875Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4"/>
  </svg>
);

