import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { withTheme } from 'styled-components';
import { userActionCreators } from '../../../Actions/UserActions';
import EditBillingAddress from '../Components/EditBillingAddress';
import { Container, PublicListingWrapper } from '../../../Assets/Style/commonStyleComponents';
import { goBack } from '../../../Utilities/commonFunctions';
import Routes from '../../../Constants/Routes';
import Footer from '../../Shared/Components/Footer';
import Navbar from '../../Shared/Components/Navbar';

export class EditBillingAddressContainer extends Component {

  componentDidMount() {
    this.props.getDefaultBillingAddress();
  }

  render() {
    let { billingAddress, match, shippingAddress } = this.props;
    if (match.url.indexOf(Routes.EDIT_SHIPPING_ADDRESS) >= 0) {
      billingAddress = shippingAddress;
    }

    return (
      <React.Fragment>
        <Navbar />
        <LoadingOverlay
          active={this.props.isFetching}
          spinner
          text=""
        >
          <PublicListingWrapper className="for-top-gap">
            <Container>
              <a className="back-btn" href="javascript:void(0)" onClick={() => goBack()}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</a>
                <EditBillingAddress
                  billingAddress={billingAddress}
                  matchURL={match.url}
                />
            </Container>
          </PublicListingWrapper>
          <Footer />
        </LoadingOverlay>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userDetails: state.user.userDetails,
    billingAddress: state.user.defaultBillingAddress || {},
    shippingAddress: state.user.defaultShippingAddress || {},
    isFetching: state.dashboard.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(EditBillingAddressContainer));
