import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from "react-tooltip";

import { Grid, Cell } from "styled-css-grid";
import Check from "../../../Assets/Svg/Check";
import { ModalDiv } from "../../../Assets/Style/commonStyleComponents";
import {
  plansFeaturesSelector,
  plansSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { showComparePlansModalSelector } from '../../../Reducers/UIReducer';
import { UIActionsCreators } from '../../../Actions/UIActions';

const Detail = styled(Cell)`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
  text-align: center;
`;

const StyledCheck = styled(Check)`
  width: 16px;
  fill: ${({ theme }) => theme.secondary};
`;

const HeaderCell = styled(Detail)`
  border: none;
  font-weight: 700;
`;

const Feature = styled(Cell)`
  flex: 1;
  padding: 10px 20px 10px 0;
  border-bottom: 1px solid #f0f0f0;
`;

const ComparePlansModal = () => {
  const dispatch = useDispatch();
  const plans = useSelector(plansSelector);
  const planFeatures = useSelector(plansFeaturesSelector);
  const showModal = useSelector(showComparePlansModalSelector);

  let extraClass = "";
    if (plans.length === 1) {
      extraClass = "compare-1-features";
    } else if (plans.length === 2) {
      extraClass = "compare-2-features";
    } else if (plans.length === 3) {
      extraClass = "compare-3-features";
    } else {
      extraClass = "compare-4-features";
    }

  return (
    <ModalDiv
      title="Compare Features"
      cancelButtonProps={{ style: { display: "none" } }}
      visible={showModal}
      onCancel={() => dispatch(UIActionsCreators.closeComparePlansModal())}
      destroyOnClose={true}
      className={"text-modal plan-selector-modal " + extraClass}
      footer={false}
    >
      <Grid gap="0" columns="1fr auto auto auto auto">
        <HeaderCell></HeaderCell>
        {plans.map((p) => (
          <HeaderCell key={p.productId}>{p.title}</HeaderCell>
        ))}
        {planFeatures.map((pf, row) => (
          <React.Fragment key={row}>
            <Feature className="tooltip-for-title-description">
              <span className="title">{pf.title}</span>
              <span className="feature-info" data-tip data-for={row}>
                <i className="fa fa-info-circle" aria-hidden="true"></i>
              </span>
              <ReactTooltip className="tooltip" multiline={true} id={"" + row + ""}>
                <span dangerouslySetInnerHTML={{ __html: pf.description }} />
              </ReactTooltip>
            </Feature>
            <React.Fragment>
              {plans.map((p, col) => (
                <Detail key={col}>
                  {pf.type.toLowerCase() === "boolean" ? (
                    pf?.values[p.productId]?.toLowerCase() === "true" ? (
                      <StyledCheck />
                    ) : (
                      ""
                    )
                  ) : (
                    pf.values[p.productId]
                  )}
                </Detail>
              ))}
            </React.Fragment>
          </React.Fragment>
        ))}
      </Grid>
    </ModalDiv>
  );

};

export default ComparePlansModal;