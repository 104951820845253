import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions';
import AdminHeader from '../Components/AdminHeader';
import OverlayFormRow from '../Components/OverlayFormRow';
import enums from './../../../Constants/Enums';
import { overlayImage } from '../../../Constants/CommonConstants';
import routes from '../../../Constants/Routes';

const { Content } = Layout;
let $ = window.jQuery;

const inputObject = {
  description: '',
  displayName: '',
  formFieldId: 0,
  inputDataType: 0,
  inputDataTypeId: 1,
  options: '',
  isLinked: false,
  formPrefillTypeId: null,
  formPrefillType: null,
  overlayHeight: 20,
  overlayStartX: 0,
  overlayStartY: 0,
  overlayWidth: 258,
  priority:0,
  readOnly: false,
  required: false,
};
const listingFormImage = '#listing_form_image';
export class EditFormSectionContainer extends Component {
  constructor(props) {
    super(props);
     this.state = {
      entity: '',
      url: '',
      className: '',
      stackStyle: [],
      stackId: [],
      counter: -1,
      noOfCopy: 1,
      fieldIndex: '',
      highlightedIndex: null,
      formFields: [],
      checkCustomState:true,
    };
  }
  updatePosition = (ui, formId, type) => {
    const { formFields } = this.state;
    const index = Number(formId.split('_')[enums.NUMERIC_VALUE.TWO]);
    const obj = formFields[index];
    if (type === 'DRAG') {
      obj.overlayStartX = Math.round(ui.position.left);
      obj.overlayStartY = Math.round(ui.position.top);
    } else if (type === 'RESIZE') {
      obj.overlayWidth = Math.round(ui.size.width);
      obj.overlayHeight = Math.round(ui.size.height);
    }
    this.setState({ formFields });
    
  };
  doSomethingWith = (style, id) => {
    const { stackStyle, counter } = this.state;
    //Check if make buttons undo/redo disabled or enabled
    if (counter <= -enums.NUMERIC_VALUE.ONE) {
      $('#undo').addClass('disabled');
      $('#redo').removeClass('disabled');
      return;
    } else {
      $('#undo').removeClass('disabled');
    }

    if (counter == stackStyle.length) {
      $('#redo').addClass('disabled');
      $('#undo').removeClass('disabled');
      return;
    } else {
      $('#redo').removeClass('disabled');
    }

    $('#' + id).attr('style', style);
  };
  redo = async () => {
    const { counter, stackStyle, stackId } = this.state;
    await this.setState({ counter: counter + enums.NUMERIC_VALUE.ONE });
    this.doSomethingWith(
      stackStyle[this.state.counter],
      stackId[this.state.counter],
    );
  };
  undo = async () => {
    const { counter, stackStyle, stackId } = this.state;
    await this.setState({ counter: counter - enums.NUMERIC_VALUE.ONE });
    this.doSomethingWith(
      stackStyle[this.state.counter],
      stackId[this.state.counter],
    );
  };

  add = async (style, id) => {
    const { counter, stackStyle, stackId } = this.state;
    await this.setState({ counter: counter + enums.NUMERIC_VALUE.ONE });
    stackStyle[this.state.counter] = style;
    stackId[this.state.counter] = id;
    await this.setState({ stackStyle, stackId });
    this.doSomethingWith(style, id);
  };

  stopHandlerDrag = (event, ui) => {
    const style = $(ui.helper).attr('style');
    const id = $(ui.helper).attr('id');
    this.add(style, id);
    this.updatePosition(ui, id, 'DRAG');
  };

  //Star Handler Drag
  startHandlerDrag = (event, ui) => {
    const style = $(ui.helper).attr('style');
    const id = $(ui.helper).attr('id');
    this.add(style, id);
  };

  //Stop Handler Resize
  stopHandlerResize = (event, ui) => {
    const style = $(ui.helper).attr('style');
    const id = $(ui.helper).attr('id');
    this.add(style, id);
    this.updatePosition(ui, id, 'RESIZE');
  };

  //Star Handler Resize
  startHandlerResize = (event, ui) => {
    const style = $(ui.helper).attr('style');
    const id = $(ui.helper).attr('id');
    this.add(style, id);
  };

  inputPlaceholder = () =>
    this.state.formFields.map((item, i) => (
      <div
        id={`placeholder_field_${i}`}
        className={`field-placeholder ${
          this.state.highlightedIndex === i ? 'highlight' : ''
        }`}
        style={{
          top: `${
            item.overlayStartY ? item.overlayStartY : enums.NUMERIC_VALUE.ZERO
          }px`,
          left: `${
            item.overlayStartX ? item.overlayStartX : enums.NUMERIC_VALUE.ZERO
          }px`,
          width: `${item.overlayWidth ? item.overlayWidth : 258.4}px`,
          height: `${item.overlayHeight ? item.overlayHeight : 20.4}px`,
          zIndex: 200,
          position: 'absolute',
        }}
        onClick={e => this.handleFocusInput(e, i)}
      >
        <span className='name highlight' style={{ zIndex: 300 }}>
          {item.displayName}
        </span>
      </div>
    ));

  update_field_placeholders($_field) {
    const $_overlay_container = $(listingFormImage);
    const $_fields_container = $('#listing_form_fields');
    const $_fields = $_fields_container.find('.field');
    if ($_field) {
      $_fields = $_fields_container.find('#' + $_field.attr('id'));
    }
    const self = this;
    $_fields.each(function (index, el) {
      const field_id = $(this).attr('id');
      let placeholder = false;
      placeholder = $_overlay_container.find('#placeholder_' + field_id);

      if (placeholder && placeholder.length > enums.NUMERIC_VALUE.ZERO) {
        placeholder.draggable({
          containment: listingFormImage,
          stop: (e, ui) => self.stopHandlerDrag(e, ui),
          start: (e, ui) => self.startHandlerDrag(e, ui),
        });
        placeholder.resizable({
          stop: (e, ui) => self.stopHandlerResize(e, ui),
          start: (e, ui) => self.startHandlerResize(e, ui),
        });
      }
    });
    $_overlay_container
      .find('.field-placeholder')
      .draggable({ containment: listingFormImage })
      .resizable();
  }

  loadImage = () => {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');
    const img = this.refs.image;
    img.onload = () => {
      ctx.drawImage(img, enums.NUMERIC_VALUE.ZERO, enums.NUMERIC_VALUE.ZERO);
    };
  };
  componentDidMount() {
    
    this.loadImage();
    this.update_field_placeholders();
    this.props.getLookup(this.props.activeEntity);
    if (this.props.match?.params?.sectionId) {
      this.props.getOverlayFormDetail(
        this.props.activeEntity,
        Number(this.props.match.params.sectionId),
      );
    }
  }

  componentDidUpdate(prevProps,nextProps) {
    const { overlayFormDetail } = this.props;
    if (
      prevProps.overlayFormDetail !== overlayFormDetail &&
      overlayFormDetail?.fields?.length
    ) {
      this.setState(
        {
          formFields: overlayFormDetail.fields,
          url: overlayFormDetail?.content
            ? overlayFormDetail.content
            : overlayImage,
        },
        () => {
          this.update_field_placeholders();
        },
      );
    }
    if(this.state.checkCustomState){
      let i=0;
      for(let result of this.state.formFields) {
      let customeStatus=false;
      if(result['formPrefillTypeId']===100){
        customeStatus=true;
      }
      this.state[`${i}IsShown`] = customeStatus;
        i++;
      }
  }
    
  }
  handleFocusInput = (e, index) => {
    this.setState({ highlightedIndex: index });
  };
  isHighlighted = classId => {
    const { className } = this.state;
    if (className !== classId) {
      return '';
    }

    return 'highlight';
  };

  getRandomInt = (min, max) => {
    return Math.floor(
      Math.random() * (max - min + enums.NUMERIC_VALUE.ONE) + min,
    );
  };
  handleRemoveField = index => {
    const { formFields } = this.state;
    const newForm = formFields.filter((_, i) => i !== index);
    this.setState({ formFields: newForm }, () => {
      this.update_field_placeholders();
    });
  };
  handleCopyField = (e, i) => {
    const revealcontainer = $('#copy');
    $(document).foundation();
    revealcontainer.foundation('open');
    this.setState({ fieldIndex: i });
  };

  handleModalCopyField = () => {
    const { formFields, noOfCopy, fieldIndex } = this.state;
    const copyFieldObject = formFields[fieldIndex];
    const newForm = [...formFields];
    for (let i = 1; i <= parseInt(noOfCopy); i++) {
      newForm.splice(fieldIndex + i, enums.NUMERIC_VALUE.ZERO, {
        ...copyFieldObject,
        formFieldId: enums.NUMERIC_VALUE.ZERO,
        displayName: `${copyFieldObject.displayName}-${i}`,
        inputDataTypeId: copyFieldObject.inputDataTypeId,
        overlayStartX: copyFieldObject.overlayStartX,
        overlayStartY: copyFieldObject.overlayStartY,
        overlayWidth: copyFieldObject.overlayWidth,
        overlayHeight: copyFieldObject.overlayHeight,
      });
    }

    this.setState(
      {
        formFields: newForm,
        noOfCopy: enums.NUMERIC_VALUE.ONE,
        fieldIndex: '',
      },
      () => {
        this.update_field_placeholders();
      },
    );
  };
  handleUndo = () => {
    this.undo();
  };
  handleRedo = () => {
    this.redo();
  };
  onChangeInput = (e, index, name) => {
    const { value } = e.target;
    const { formFields } = this.state;
    const newForm = [...formFields];
    newForm[index][name] = value;
    this.setState({ formFields: newForm }, () => {
      this.update_field_placeholders();
    });
  };

  changeCustomText=(e,idx)=>{
    const { value } = e.target;
    let customeStatus=false;
    if(value==100){
      this.setState({checkCustomState:false});
      customeStatus=true;
    }
    let key = `${idx}IsShown`;
    this.setState({[key]: customeStatus});
    
  }
  handleCheckbox = (e, index, name) => {
    const { value } = e.target;
    const { formFields } = this.state;
    const newForm = [...formFields];
    newForm[index][name] = !newForm[index][name];
    this.setState({ formFields: newForm }, () => {
      this.update_field_placeholders();
    });
  };

  handleOnDragStop = (e, ui) => {
    const field = $('#' + $(e.target).data('field'));
    if (field) {
      field.find('input[name="pos_x"]').val(ui.position.left);
      field.find('input[name="pos_y"]').val(ui.position.top);
    }
  };

  handleClickOnListImage = (e, ui) => {
    const element = e.target;
    this.clear_highlighted();
    $(element).css('z-index', '300');
    $(element).addClass('highlight');

    const field = $('#' + $(element).data('field'));
    field.addClass('highlight');

    this.clear_saved_notice();
  };
  handleAddInput = index => {
    const { formFields } = this.state;
    let newForm = [...formFields];
    if (index >= 0) {
      newForm.splice(
        index + enums.NUMERIC_VALUE.ONE,
        enums.NUMERIC_VALUE.ZERO,
        { ...inputObject },
      );
    } else {
      newForm.push({ ...inputObject });
    }
    this.setState({ formFields: newForm }, () => {
      this.update_field_placeholders();
    });
  };
  selectInputType = (e, index) => {
    const { value } = e.target;
    const { formFields } = this.state;
    const newForm = [...formFields];
    newForm[index].inputDataTypeId = Number(value);
    if(newForm[index].inputDataTypeId===5 || newForm[index].inputDataTypeId===13){
      newForm[index].formPrefillType='';
      newForm[index].prefillText='';
    }
    this.setState({ formFields: newForm });
  };
  handleNoOfCopy = e => {
    const { value } = e.target;
    this.setState({ noOfCopy: value });
  };
  handleSaveForm = () => {
    const {
      activeEntity,
      overlayFormDetail,
      updateOverlayFormDetail,
    } = this.props;
    const { formFields } = this.state;
    const reqObj = { ...overlayFormDetail };
    reqObj.fields = formFields;
   updateOverlayFormDetail(
      activeEntity,
      Number(this.props.match.params.sectionId),
      reqObj,
    );
  };
  render() {
    const { url, formFields, noOfCopy, highlightedIndex } = this.state;
    const { fieldPrefillTypes } = this.props;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Layout>
          <Layout.Header
            style={{ background: '#fff', minHeight: 52, padding: 0 }}
          >
            <AdminHeader
              collapsed={this.state.collapsed}
              toggle={this.toggle}
            />
          </Layout.Header>
          <Content className='overlay-main-content'>
            <h2 className='overlay-heading'>
              <span>Listing Form Setting</span> Change Form (GA - MLS min serv)
            </h2>
            <h3 className='overlay-page-count'>
              <span>Overlay Page 1 1</span> 1 (of 2)
            </h3>
            <div className='undo-redo-btn'>
              <a
                id='undo'
                className='btn btn-primary admin-primary-btn secondary-btn'
                onClick={this.handleUndo}
              >
                Undo
              </a>
              <a
                id='redo'
                className='btn btn-primary admin-primary-btn secondary-btn'
                onClick={this.handleRedo}
              >
                Redo
              </a>
            </div>
            <div className='overlay-form-inner'>
              <div className='image-with-form'>
                <div className='overlay-pdf-box' id='listing_form_image'>
                  {this.inputPlaceholder()}
                  <canvas
                    className='hiddenImage'
                    ref='canvas'
                    width={800}
                    height={1000}
                  />
                  <img
                    ref='image'
                    src={url}
                    className='hiddenImage'
                    style={{ display: 'none' }}
                    alt=''
                  />
                </div>
              </div>
              <div className='overlay-form-inner-right'>
                <div className='overlay-fields-wrapper'>
                  <div className='overlay-save-cancel-btn'>
                    <a
                      className='button large update-fields-code'
                      onClick={this.handleSaveForm}
                      disabled={
                        formFields.filter(
                          item =>
                            item.inputDataTypeId ===
                              enums.NUMERIC_VALUE.THIRTEEN && !item.options,
                        ).length
                      }
                    >
                      Save
                    </a>{' '}
                    <a
                      href={routes.PREVIEW.replace(
                        ':sectionId',
                        this.props.match.params.sectionId,
                      )}
                      target='_blank'
                      className='button hollow add-listing-form-field'
                    >

                      
                      Preview
                    </a>
                  </div>
                  <h2 className='overlay-field-heading'>Fields</h2>
                  <form id='listing_form_fields_form' data-abide='0x7bvq-abide'>
                    <div
                      id='listing_form_fields'
                      ref='listing_form_fields'
                      className='ui-sortable'
                    >
                      <div className='field-columns row overlay-fields-head'>
                        <h3 className='column medium-2'>Type</h3>
                        <h3 className='column medium-3'>Name</h3>
                        <h3 className='column medium-6'>Settings</h3>
                        <h3 className='column medium-1'>Priority</h3>
                        <h3 className='column medium-2'>&nbsp;</h3>
                      </div>
                      {formFields.map(
                        (item, i) =>
                          item && (
                            <OverlayFormRow
                              item={item}
                              isShoown={this.state[`${i}IsShown`]}
                              highlightedIndex={highlightedIndex}
                              index={i}
                              onChangeInput={e =>
                                this.onChangeInput(e, i, 'displayName')
                              }
                              onInputKeyUp={e => this.onChangeInput(e, i)}
                              onFocus={e => this.handleFocusInput(e, i)}
                              onChangeInputType={e =>
                                this.selectInputType(e, i)
                              }
                              onChangeOptions={e =>
                                this.onChangeInput(e, i, 'options')
                              }
                              onAddInput={() => this.handleAddInput(i)}
                              onRemoveInput={() => this.handleRemoveField(i)}
                              onCopyInput={e => this.handleCopyField(e, i)}
                              onRequireCheckbox={e =>
                                this.handleCheckbox(e, i, 'required')
                              }
                              onReadOnlyCheckbox={e =>
                                this.handleCheckbox(e, i, 'readOnly')
                              }
                              onLinkedCheckbox={e =>
                                this.handleCheckbox(e, i, 'isLinked')
                              }
                              fieldPrefillTypes={fieldPrefillTypes}
                              onChangePrefill={e => {this.onChangeInput(e, i, 'formPrefillTypeId');this.changeCustomText(e,i)}}
                              onChangePrefillText={e => this.onChangeInput(e, i, 'prefillText')}
                              onChangeTargets={e =>
                                this.onChangeInput(e, i, 'targetFields')
                              }
                              onChangePriority={e =>
                                this.onChangeInput(e, i, 'priority')
                              }
                            />
                          ),
                      )}

                      <input type='hidden' name='fid' defaultValue={494648} />
                      <input type='hidden' name='opid' defaultValue={0} />
                    </div>
                  </form>
                </div>
                <div className='overlay-save-cancel-btn save-and-add-field'>
                  <a
                    className='button large update-fields-code'
                    onClick={this.handleSaveForm}
                    disabled={
                      formFields.filter(
                        item =>
                          item.inputDataTypeId ===
                            enums.NUMERIC_VALUE.THIRTEEN && !item.options,
                      ).length
                    }
                  >
                    Save
                  </a>{' '}
                  <a
                    className='button hollow add-listing-form-field'
                    onClick={this.handleAddInput}
                  >
                    Add Field
                  </a>
                </div>
              </div>
              <div className='reveal-overlay' style={{ display: 'none' }}>
                <div
                  className='reveal'
                  id='copy'
                  data-reveal='4ggioh-reveal'
                  role='dialog'
                  aria-hidden='true'
                  data-yeti-box='copy'
                  data-resize='copy'
                  data-events='resize'
                  tabindex='-1'
                  style={{ top: 63, display: 'none' }}
                >
                  <p className='lead'>Makin' Copies</p>

                  <label className='saved-modal-label'>
                    <strong className='saved-modal-label-text'>
                      How many copies?
                    </strong>
                    <input
                      type='number'
                      name='copies'
                      value={noOfCopy}
                      onChange={this.handleNoOfCopy}
                      className='form-control'
                    />
                  </label>
                  <div className='reveal-modal-btn-wrapper'>
                    <button
                      className='ant-btn admin-primary-btn'
                      data-close=''
                      aria-label='Close modal'
                      type='button'
                      onClick={this.handleModalCopyField}
                    >
                      Go
                    </button>
                  </div>
                  <button
                    className='close-button'
                    data-close=''
                    aria-label='Close modal'
                    type='button'
                  >
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[enums.NUMERIC_VALUE.TWO],
    overlayFormDetail: state.admin.overlayFormDetail || {},
    fieldPrefillTypes: state.admin.lookup?.fieldPrefillTypes || [],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditFormSectionContainer),
);
