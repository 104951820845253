import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';

class MlsService {

    constructor() {

        let urls = ((window.config || {}).urls || {}).dashboard || {};

        urls.base = urls.base || "/";

        this.urls = urls;

    }

    addMls(mls) {
        const url = `/api/v2/mls`;
        return fetch(url, {
            method: apiMethodType.POST,
            data: mls,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }

    updateMls(mlsId, mls) {
        const url = `/api/v2/mls/${mlsId}`;
        return fetch(url, {
            method: apiMethodType.PUT,
            data: mls,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }
}

export const mlsService = new MlsService();
