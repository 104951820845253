import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { ownerTypes } from '../../../Constants/CommonConstants';

const MainContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
`;

const InputContainer = styled.div`
  min-width: 20rem;
  position: relative;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    border-color: #242b26;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  textarea {
    padding-top: 0.75rem;
    height: auto;
  }
  input:disabled,
  select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  input:disabled + label,
  select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }
  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 24rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 26rem;
    margin-right: 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 28rem;
  }
`;

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[500]};
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel
        ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
        : ''}
  }
`;

const SelectContainer = styled.div`
  min-width: 20rem;
  position: relative;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    margin-top: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    border-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#242B26')};
  }
  select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }

  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 24rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 26rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 28rem;
  }
`;

const SelectInputLabel = styled.label`
  opacity: 1;
  top: 0px;
  left: 0px;
  z-index: 2;
  position: absolute;
  background-color: white;
  pointer-events: none;
  margin-inline-start: 0.75rem;
  margin-inline-end: 0.75rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 0.75rem;
  transform-origin: left top;
  transition: scale 0.2s, translateY 0.2s, transform 0.2s;
  transition-timing-function: linear;
  display: block;
  text-align: start;
  font-weight: 500;
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.mouseballgray[100] : theme.colors.light)};

  ${({ smallLabel }) =>
    smallLabel
      ? `
    transform: scale(0.85) translateY(-22px);
    font-size: 12px;
    opacity: 1;
    `
      : ''}

  ${({ select }) =>
    select
      ? `
    display: none;
    &:has(+ (select(select:focus)) {
    display: block;
    }
    `
      : ''}
`;

const FormTitleOwnerForm = ({
  onChange,
  onFocus,
  ownerFields,
  typeSelectorDisabled,
  changeOwnerType,
  index,
}) => {
  const ownerType = ownerFields.ownerType.value;

  const handleFieldChanged = (e) => onChange(e.target.name, e.target.value, index);
  const handleFieldFocus = (e) => onFocus(e.target.name, index);
  return (
    <MainContainer>
      <SelectContainer error={ownerFields.ownerType.error}>
        <SelectInputLabel smallLabel error={ownerFields.ownerType.error} disabled={typeSelectorDisabled}>
          Owner Type
        </SelectInputLabel>
        <select
          name="ownerType"
          value={ownerFields.ownerType.value || ''}
          onChange={changeOwnerType}
          disabled={typeSelectorDisabled}
        >
          <option key={1} value={ownerTypes.individual}>
            Individual
          </option>
          <option key={2} value={ownerTypes.company}>
            Company
          </option>
        </select>
        {ownerFields.ownerType.error && <ErrorMessage>{ownerFields.ownerType.error}</ErrorMessage>}
      </SelectContainer>
      {ownerType === ownerTypes.individual ? (
        <>
          <InputContainer className={ownerFields.email.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              value={ownerFields.email.value}
              onChange={handleFieldChanged}
              onFocus={handleFieldFocus}
            />
            <InputLabel smallLabel={ownerFields.email.value} htmlFor="email">
              Email address *
            </InputLabel>
            {ownerFields.email.error && <ErrorMessage>{ownerFields.email.error}</ErrorMessage>}
          </InputContainer>
          <InputContainer className={ownerFields.name.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="text"
              name="name"
              id="name"
              value={ownerFields.name.value}
              onChange={handleFieldChanged}
              onFocus={handleFieldFocus}

            />
            <InputLabel smallLabel={ownerFields.name.value} htmlFor="name">
              Owner's Name *
            </InputLabel>
            {ownerFields.name.error && <ErrorMessage>{ownerFields.name.error}</ErrorMessage>}
          </InputContainer>
          <InputContainer className={ownerFields.phone.error ? 'invalid' : ''}>
            <MaskedInput
              mask={ownerFields.phone.mask}
              type="tel"
              id="phone"
              name="phone"
              value={ownerFields.phone.value}
              onFocus={handleFieldFocus}
              onChange={handleFieldChanged}
              autoComplete="off"
            />
            <InputLabel smallLabel={ownerFields.phone.value} htmlFor="phone">
              Phone number *
            </InputLabel>
            {ownerFields.phone.error && <ErrorMessage>{ownerFields.phone.error}</ErrorMessage>}
          </InputContainer>
        </>
      ) : (
        <>
          <InputContainer className={ownerFields.companyName.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="text"
              name="companyName"
              id="companyName"
              value={ownerFields.companyName.value}
              onFocus={handleFieldFocus}
              onChange={handleFieldChanged}
            />
            <InputLabel smallLabel={ownerFields.companyName.value} htmlFor="companyName">
              Business Entity Name *
            </InputLabel>
            {ownerFields.companyName.error && <ErrorMessage>{ownerFields.companyName.error}</ErrorMessage>}
          </InputContainer>
          <InputContainer className={ownerFields.principalFirstName.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="text"
              name="principalFirstName"
              id="principalFirstName"
              value={ownerFields.principalFirstName.value}
              onFocus={handleFieldFocus}
              onChange={handleFieldChanged}
            />
            <InputLabel smallLabel={ownerFields.principalFirstName.value} htmlFor="principalFirstName">
              Business Entity Signer / Principal First Name *
            </InputLabel>
            {ownerFields.principalFirstName.error && (
              <ErrorMessage>{ownerFields.principalFirstName.error}</ErrorMessage>
            )}
          </InputContainer>
          <InputContainer className={ownerFields.principalLastName.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="text"
              name="principalLastName"
              id="principalLastName"
              value={ownerFields.principalLastName.value}
              onFocus={handleFieldFocus}
              onChange={handleFieldChanged}
            />
            <InputLabel smallLabel={ownerFields.principalLastName.value} htmlFor="principalLastName">
              Business Entity Principal Signer / Last Name *
            </InputLabel>
            {ownerFields.principalLastName.error && <ErrorMessage>{ownerFields.principalLastName.error}</ErrorMessage>}
          </InputContainer>
          <InputContainer className={ownerFields.positionInCompany.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="text"
              name="positionInCompany"
              id="positionInCompany"
              value={ownerFields.positionInCompany.value}
              onFocus={handleFieldFocus}
              onChange={handleFieldChanged}
            />
            <InputLabel smallLabel={ownerFields.positionInCompany.value} htmlFor="positionInCompany">
              Business Entity Title / Position in company *
            </InputLabel>
            {ownerFields.positionInCompany.error && <ErrorMessage>{ownerFields.positionInCompany.error}</ErrorMessage>}
          </InputContainer>
          <InputContainer className={ownerFields.email.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              value={ownerFields.email.value}
              onFocus={handleFieldFocus}
              onChange={handleFieldChanged}
            />
            <InputLabel smallLabel={ownerFields.email.value} htmlFor="email">
              Business Entity Signer / Principal Email *
            </InputLabel>
            {ownerFields.email.error && <ErrorMessage>{ownerFields.email.error}</ErrorMessage>}
          </InputContainer>
          <InputContainer className={ownerFields.phone.error ? 'invalid' : ''}>
            <MaskedInput
              mask={ownerFields.phone.mask}
              type="tel"
              id="phone"
              name="phone"
              value={ownerFields.phone.value}
              onFocus={handleFieldFocus}
              onChange={handleFieldChanged}
              autoComplete="off"
            />
            <InputLabel smallLabel={ownerFields.phone.value} htmlFor="phone">
              Business Entity Phone Number/Principal Phone Number *
            </InputLabel>
            {ownerFields.phone.error && <ErrorMessage>{ownerFields.phone.error}</ErrorMessage>}
          </InputContainer>
        </>
      )}
    </MainContainer>
  );
};

export default FormTitleOwnerForm;
