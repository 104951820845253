import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UIActionsCreators } from '../Actions/UIActions';
import routes from '../Constants/Routes';

export default function (ComposedComponent) {
  class Authentication extends Component {
    componentWillMount() {
      if (!this.props.authenticated) {
        this.props.setAccessDenied();
        this.props.openLoginModal();
        this.props.history.push(routes.HOME);
      }
    }
    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) {
        this.props.setAccessDenied();
        this.props.openLoginModal();
        this.props.history.push(routes.HOME);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ...UIActionsCreators }, dispatch);
  }

  function mapStateToProps(state) {
    return { authenticated: state.user.userDetails.token };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}
