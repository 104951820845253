import { PropertiesActions } from '../../Actions/V2/Admin/PropertiesActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const propertiesFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    listingStatusId: null,
    assignedToId: null,
    state: null,
    officeStatusId: null,
    companyId: null,
    formStatusId: null,
    titleCompanyId: null,
    dateCreatedFrom: null,
    dateCreatedTo: null,
    dateModifiedFrom: null,
    dateModifiedTo: null,
    hasCashOffers: null,
    zip: null,
    sortBy: null,
    desc: null,
}

const initialState = {
    properties: [],
    totalCount: 0,
    averageListTime: null,
    filters: propertiesFiltersDefault,
    fetchingAverageTimeToList: false,
};

export const adminPropertiesReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case PropertiesActions.SET_PROPERTIES_FILTERS:
            return { ...state, filters: action.payload };
        case PropertiesActions.CLEAR_PROPERTIES_FILTERS:
            return { ...state, filters: { ...propertiesFiltersDefault } };
        case PropertiesActions.PROPERTIES_RESOLVED:
        case PropertiesActions.PROPERTIES_FAILED:
            return { ...state, properties: action.payload.results, totalCount: action.payload.total };
        case PropertiesActions.UPDATE_PROPERTY_STATUS_RESOLVED:
            return { ...state, properties: action.payload };
            
        case PropertiesActions.GET_AVERAGE_TIME_TO_LIST_REQUESTED:
            return { ...state, fetchingAverageTimeToList: true };
        case PropertiesActions.GET_AVERAGE_TIME_TO_LIST_RESOLVED:
            return { ...state, averageListTime: action.payload, fetchingAverageTimeToList: false };
        default:
            return state;
    }
};

export const adminPropertiesSelector = state => state.adminProperties.properties;
export const adminPropertiesCountSelector = state => state.adminProperties.totalCount;
export const propertiesFiltersSelector = state => state.adminProperties.filters;
export const averageListTimeSelector = state => state.adminProperties.averageListTime;
export const fetchingAverageTimeToListSelector = state => state.adminProperties.fetchingAverageTimeToList;
