import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { Button } from 'antd';
import { history } from '../../../Utilities/History';
import { isListingProcessorAccess } from '../../../Utilities/commonFunctions';
import { entityTypes } from '../../../Constants/CommonConstants';

const AverageCard = styled.span`
  font-size: 1.5rem;
  margin-left: 2rem;
  @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
    font-size: 1rem;
  }
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
    margin-bottom: 2px;
    margin-left: 2px;
`;
class SearchComponent extends React.Component {
  state = {
    title: '',
  };

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} e
   * @param fields {String} key
   */
  handleChange = (e, key) => {
    this.setState({ ...this.state, [key]: e.target.value.trimStart() });
  };

  applySearch = (key) => {
    const { title } = this.state;
    if (title.trim()) {
      this.props.applySearch(key, title.trim());
    }
  }

 _handleKeyDown = (e) => {
  const { title } = this.state;
    if (e.key === 'Enter' && title) {
      this.applySearch('title');
    }
  }

  clearSearch = () => this.setState({ ...this.state, title: '' }, () => this.props.clearSearch());

  render() {
    const { pageData, activeEntity, fetchingAverageTimeToList, averageTimeToList } = this.props
    const { title } = this.state;
    return (
      <React.Fragment>
        <div className="admin-search-btn-wrapper">
          <div className="left-side">
            <div className="custom-search form-group">
              <input type="search" id="searchinput" value={title} placeholder={pageData.placeholder ? pageData.placeholder : `Search by Keywords, Title`}
                onChange={e => this.handleChange(e, 'title')} onKeyDown={e => this._handleKeyDown(e)} className="form-control" />
              <i className="fa fa-search" aria-hidden="true"></i>
            </div>
            <div className="form-group">
              <Button
                className="btn btn-primary admin-primary-btn small-btn"
                onClick={() => this.applySearch('title')}
              >
                Apply Search
            </Button>

              <Button
                className="btn btn-primary admin-primary-btn small-btn"
                onClick={() => this.clearSearch()}
                disabled={!title.length}
              >
                Clear Search
            </Button>
            </div>
            {activeEntity === entityTypes.properties && (
              <AverageCard>
                Average time to list: {
                fetchingAverageTimeToList ? <Loader animation="border" size="sm" /> : averageTimeToList
                }
              </AverageCard>
            )}
          </div>
          {pageData.title && <div className="btn-filter-wrapper">
              <Button
                variant="primary"
                disabled={isListingProcessorAccess(activeEntity)}
                onClick={() => pageData.path ? history.push(pageData.path) : ''}
                className="admin-primary-btn">
                {pageData.title}
              </Button>
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

export { SearchComponent };
