import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import v8n from 'v8n';
import { adminActionCreators } from '../../../Actions/AdminActions';
import {
  RowFlex,
  InputFlex,
} from '../../../Assets/Style/commonStyleComponents';
import { entityTypes, validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import { goBack, getFocusInput, isListingProcessorRole, statusChangeModalPopup } from '../../../Utilities/commonFunctions';
import { categoriesTableConfigs } from '../../../Constants/TableConstants';

class AddEditCategories extends React.Component {
  constructor(props) {
    super(props);
    this.isNewCategory = props.isNewCategory;
    this.state = {
      isEnabled: false,
      categoryDetail: {
        name: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.categoryName,
            },
          ],
          value: props?.selectedItemData?.name || '',
        },
      },
    };
  }

  handleFieldChanged = e => {
    let categoryDetail = { ...this.state.categoryDetail };
    categoryDetail[e.target.name].value = e.target.value;
    this.setState({ categoryDetail: { ...categoryDetail } });
  };

  handleFieldBlur = e => {
    this.validateFormField(e.target.name, e.target.value);
  };

  validateFormField = (key, value) => {
    let categoryDetail = { ...this.state.categoryDetail };
    let isError = false;
    for (let i = 0; i < categoryDetail[key].rules.length; i++) {
      let rule = categoryDetail[key].rules[i];
      if (!rule.rule.test(value)) {
        categoryDetail[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      categoryDetail[key].error = null;
    }

    this.setState({
      categoryDetail: { ...categoryDetail },
      isError: Object.keys(categoryDetail).some(
        k =>
          categoryDetail[k].error !== undefined &&
          categoryDetail[k].error !== null,
      ),
    });

    return isError;
  };

  handleAddUpdateCategoryDetails = categoryDetails => {
    const { isEnabled } = this.state;

    if (!this.isNewCategory) {
      categoryDetails['categoryId'] = this.props.selectedItemData.mlsCategoryId;
    }
    // categoryDetails['isEnabled'] = isEnabled;
    const mlsCategoryId = this.props.isNewCategory
      ? Enums.NUMERIC_VALUE.ZERO
      : this.props.selectedItemData.mlsCategoryId;
    this.props.addUpdateCategory(mlsCategoryId, categoryDetails, () => {
      goBack();
    });
  };

  validateAndUpdateCategoryDetails = e => {
    e.preventDefault();

    let isError = false;

    Object.keys(this.state.categoryDetail).forEach(keyName => {
      const isErrorExist = this.validateFormField(
        keyName,
        this.state.categoryDetail[keyName].value,
      );
      if (isErrorExist) {
        isError = true;
      }
    });
    if (!isError) {
      this.handleAddUpdateCategoryDetails({
        ...Object.keys(this.state.categoryDetail).reduce((retVal, key) => {
          if (this.state.categoryDetail[key].value) {
            retVal[key] = this.state.categoryDetail[key].value;
          }
          return retVal;
        }, {}),
      });
    }

    const { categoryDetail } = this.state;
    getFocusInput(categoryDetail);
  };
  render() {
    const { selectedItemData, toggleStatus } = this.props;
    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>{this.isNewCategory ? 'Add Category' : 'Edit Category'}</h2>
            <div className="admin-heading-wrapper-right">                
              {!this.isNewCategory &&
                <>
                  <button
                    disabled={isListingProcessorRole()}
                    className={`ant-btn btn btn-primary admin-primary-btn ${selectedItemData?.isEnabled ? 'delete-btn' : ''}`}
                    id="edit-mode"
                    onClick={() => statusChangeModalPopup( selectedItemData, categoriesTableConfigs, () => {
                      toggleStatus(entityTypes.categories, selectedItemData?.mlsCategoryId)})
                    }  
                  >
                    {selectedItemData?.isEnabled ? 'Deactivate' : "Activate"}
                  </button>
                </>
              }
            </div>
          </div>
          <div className={`admin-add-form-wrapper ${isListingProcessorRole() ? 'disabled-input-form' : ''}`}>
            <form autoComplete='off'>
              <RowFlex>
                <InputFlex
                  name='name'
                  title='Category Name'
                  className='inputgap'
                  value={this.state?.categoryDetail?.name?.value}
                  error={
                    this.state?.categoryDetail?.name?.error ||
                    (this.props?.errors?.name || [])[0]
                  }
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              {/* Note This code is used in future
                <div className="admin-w-50">
                <div className="admin-checkbox-wrapper">
                  <div className="admin-custom-checkbox">
                    <label>
                      <input
                        className="inputgap"
                        type="checkbox"
                        checked={this.state?.isEnabled}
                        onChange={() => this.setState({ isEnabled: !this.state.isEnabled })}
                      />
                      <span>Category Enabled.</span>
                    </label>
                  </div>
                </div>
              </div> */}
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    onClick={this.validateAndUpdateCategoryDetails}
                    disabled={isListingProcessorRole()}
                  >
                    {this.isNewCategory ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategories);
