import React from 'react'

export const Logo = () => (
  <svg height="40" viewBox="0 0 1194 201" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M212.583 189.049C211.981 190.363 211.083 191.52 209.959 192.429C208.836 193.338 207.517 193.974 206.106 194.288V197.06H248.352V194.288C246.991 193.941 245.728 193.288 244.656 192.381C243.584 191.473 242.733 190.334 242.166 189.049C240.931 186.174 240.313 181.962 240.313 176.414V137.357H240.447V75.9212L196.229 38.1789H185.284C178.92 42.9939 162.085 58.3015 158.776 61.3206L158.691 60.1894L157.765 37.9668L124.746 43.2131V45.9919C127.908 47.8952 130.308 50.8402 131.534 54.3208C132.773 57.6204 133.391 63.0741 133.386 70.6819V176.223C133.386 181.78 132.665 185.999 131.223 188.879C130.62 190.193 129.722 191.35 128.598 192.26C127.475 193.17 126.157 193.809 124.746 194.126V196.897H167.02V194.126C165.663 193.772 164.404 193.116 163.335 192.208C162.266 191.299 161.416 190.162 160.848 188.879C159.618 185.994 159.003 181.776 159.003 176.223V78.6362C160.629 72.8761 162.936 68.1295 165.925 64.3963C167.961 61.8509 177.039 54.4976 183.657 49.216L214.591 75.9212V137.314H214.775V176.372C214.751 181.929 214.02 186.155 212.583 189.049Z" fill="black"/>
    <path d="M305.392 194.146C299.844 192.501 296.04 190.031 293.981 186.736C292.128 183.234 291.202 177.578 291.202 169.767V78.7487C292.842 72.1496 295.31 66.802 298.605 62.7058C301.899 58.6096 305.296 56.552 308.793 56.5332C311.056 56.5332 312.804 57.047 314.039 58.0746C315.454 58.8948 317.532 59.3119 320.212 59.3119C323.092 59.3119 325.355 58.3857 327 56.5332C328.681 54.5701 329.562 52.0458 329.467 49.4627C329.467 45.9605 328.437 43.2855 326.377 41.4377C324.53 39.3779 321.753 38.3503 318.048 38.355C312.694 38.355 307.551 41.0276 302.621 46.3729C297.883 51.5202 293.973 58.7227 290.891 67.9803L289.964 38.0439L256.945 43.2902V46.0689C260.047 47.9037 262.438 50.7312 263.733 54.0939C264.973 57.3935 265.59 62.8448 265.585 70.4479V176.3C265.585 181.858 264.864 186.076 263.422 188.956C262.819 190.27 261.921 191.427 260.798 192.337C259.674 193.247 258.356 193.886 256.945 194.203V196.974H305.392V194.146Z" fill="black"/>
    <path d="M8.28658 154.333C8.28658 169.143 11.2704 180.456 17.2378 188.271C23.2053 196.087 32.5666 199.997 45.3218 200.001C52.771 200.048 60.0693 197.901 66.307 193.829C72.7097 189.497 77.8364 183.53 81.155 176.549L82.0812 196.912H115.1V194.14C111.973 192.479 109.559 189.733 108.313 186.419C107.28 182.926 106.764 177.475 106.764 170.065V37.9883L72.5149 43.2346V46.0133C75.6491 47.871 77.9632 50.847 78.9914 54.3423C80.2311 57.6419 80.8486 63.0956 80.8439 70.7034V172.518C78.0727 176.537 74.3647 179.82 70.0402 182.085C65.9213 184.145 61.3742 185.203 56.7689 185.175C48.7557 185.175 42.8943 182.396 39.1846 176.838C35.6871 171.083 33.9383 162.547 33.9383 151.229V37.9883L0 43.2346V46.0133C3.13639 47.8702 5.45304 50.8462 6.48366 54.3423C7.72335 57.6419 8.34084 63.0956 8.33613 70.7034L8.28658 154.333Z" fill="black"/>
    <path d="M581.736 189.206C580.501 186.741 579.884 182.935 579.884 177.787V0L545.945 1.54848V4.32005C547.668 5.29562 549.163 6.62575 550.334 8.22246C551.504 9.81916 552.322 11.646 552.733 13.5824C553.641 18.4777 554.054 23.4523 553.963 28.4304V177.823C553.963 183.177 553.348 187.087 552.118 189.553C551.461 190.706 550.574 191.712 549.512 192.509C548.451 193.305 547.236 193.876 545.945 194.184V196.955H588.22V194.184C586.863 193.826 585.59 193.202 584.477 192.348C583.364 191.493 582.432 190.425 581.736 189.206V189.206Z" fill="black"/>
    <path d="M420.576 164.826L418.724 163.9C412.125 172.747 404.408 177.171 395.575 177.171C386.741 177.171 378.728 173.777 371.535 166.989C364.545 160.197 358.888 150.937 354.566 139.209C350.244 127.482 347.981 114.008 347.778 98.7874H420.604V91.7169C420.604 80.6021 418.856 70.9391 415.358 62.7279C411.861 54.5167 406.923 48.1227 400.545 43.5457C394.168 39.0112 386.76 36.7463 378.323 36.751C368.424 36.751 359.68 40.2485 352.091 47.2436C344.691 54.2433 338.827 63.9063 334.5 76.2325C330.182 88.3749 328.023 102.469 328.023 118.514C328.023 135.182 330.182 149.686 334.5 162.026C338.818 174.168 344.887 183.53 352.707 190.11C360.72 196.709 370.081 200.008 380.79 200.008C390.689 200.008 398.919 196.82 405.481 190.442C412.042 184.065 417.074 175.526 420.576 164.826ZM356.383 57.1211C361.53 48.0709 368.115 43.5457 376.138 43.5457C379.103 43.4562 382.043 44.1162 384.685 45.4645C387.328 46.8129 389.587 48.8061 391.255 51.2596C395.162 56.1948 397.119 62.9849 397.123 71.6297C397.123 78.6201 395.271 83.7626 391.566 87.0575C388.068 90.1402 381.896 91.889 373.048 92.3038L347.743 93.23C348.361 78.0096 351.241 65.9733 356.383 57.1211V57.1211Z" fill="black"/>
    <path d="M532.764 172.538L520.419 78.4161C516.714 50.6385 501.394 36.7497 474.46 36.7497C467.466 36.6661 460.53 38.0336 454.09 40.7658C448.454 42.993 443.467 46.601 439.589 51.2584C436.183 55.3213 434.325 60.4583 434.343 65.7599C434.246 68.3433 435.127 70.868 436.81 72.8304C437.738 73.7498 438.843 74.4704 440.059 74.9482C441.275 75.4259 442.575 75.6509 443.881 75.6091C449.226 75.6091 452.207 72.2153 452.825 65.4276C453.65 57.8151 455.398 52.26 458.071 48.7625C460.956 45.0575 465.175 43.2051 470.727 43.2051C476.487 43.2051 481.116 45.5619 484.614 50.2755C488.116 54.8007 490.791 62.1068 492.639 72.194C471.427 85.9721 455.79 99.8585 445.726 113.853C435.653 127.641 430.614 142.253 430.609 157.69C430.609 165.92 432.358 173.227 435.856 179.609C439.414 185.838 444.515 191.046 450.668 194.733C457.303 198.295 464.739 200.101 472.269 199.979C479.862 200.061 487.259 197.569 493.254 192.908C499.429 187.973 504.378 181.183 508.102 172.538C509.954 181.593 512.629 187.971 516.127 191.671C519.832 195.173 525.078 196.922 531.866 196.917H542.358V194.146C537.814 192.911 534.616 185.708 532.764 172.538ZM496.655 180.252C492.344 183.073 487.305 184.576 482.153 184.579C474.333 184.579 468.161 181.388 463.636 175.006C459.11 168.624 456.848 160.087 456.848 149.397C456.821 136.549 459.778 123.871 465.488 112.361C471.456 100.427 480.713 88.6989 493.261 77.1788L507.147 167.603L507.458 168.529C505.058 173.394 501.307 177.464 496.655 180.252Z" fill="black"/>
    <path d="M870.205 12.1406H868.035C868.035 15.4638 864.613 20.3494 860.109 26.7836C859.67 27.4058 859.218 28.0492 858.758 28.7138C855.435 33.4864 844.349 41.7872 835.906 43.4275V46.1992C839.814 48.4523 842.171 51.6317 842.977 55.7373C844.005 59.6402 844.518 64.5659 844.518 70.5145V175.78C844.518 181.12 843.797 185.226 842.355 188.097C841.795 189.471 840.915 190.69 839.787 191.654C838.658 192.617 837.316 193.296 835.871 193.633V196.404H885.301V193.668C879.541 192.028 875.534 189.464 873.281 185.976C871.221 182.275 870.191 176.426 870.191 168.427V51.4313H889.656V43.4275H870.191L870.205 12.1406Z" fill="black"/>
    <path d="M807.984 111.654C803.045 106.943 797.666 102.717 791.92 99.0336C786.358 95.3428 781.001 91.7534 775.849 88.2653C771.253 84.9005 767.1 80.9688 763.489 76.5636C760.397 72.2553 758.853 67.0232 758.858 60.8671C758.858 54.9185 760.609 50.0964 764.112 46.4009C767.816 42.7101 772.657 40.8647 778.634 40.8647C786.247 40.8647 793.044 44.4542 799.026 51.6331C805.007 58.812 810.258 69.889 814.779 84.8643H817.557L813.852 35.9225H811.689C810.661 38.5857 808.5 39.9197 805.205 39.9244C803.099 39.8003 801.019 39.3864 799.026 38.6941C796.213 37.5586 793.32 36.6322 790.371 35.9225C786.659 34.8379 782.805 34.3182 778.938 34.3811C771.896 34.2854 764.958 36.0931 758.858 39.6132C753.179 42.8088 748.478 47.4925 745.262 53.1603C741.962 58.9015 740.312 65.5713 740.312 73.1697C740.312 81.3715 741.858 88.442 744.951 94.3812C748.242 100.124 752.409 105.317 757.303 109.774C762.455 114.077 767.812 118.18 773.374 122.083C778.963 125.651 784.328 129.559 789.438 133.785C794.469 137.748 798.668 142.664 801.797 148.251C805.097 153.582 806.747 160.045 806.747 167.639C806.747 174.615 804.79 180.271 800.878 184.608C796.961 188.709 791.811 190.759 785.429 190.759C775.955 190.759 767.611 186.451 760.4 177.834C753.188 169.218 746.907 156.085 741.557 138.437H739.082L743.098 195.708H744.958C746.603 193.243 749.485 192.01 753.605 192.01C757.102 192.01 761.427 192.833 766.579 194.478C772.462 196.404 778.624 197.338 784.814 197.243C793.053 197.243 800.159 195.397 806.131 191.706C812.09 187.817 816.891 182.395 820.032 176.01C823.53 169.236 825.281 161.336 825.285 152.31C825.285 142.882 823.636 134.982 820.336 128.609C817.371 122.202 813.174 116.441 807.984 111.654V111.654Z" fill="black"/>
    <path d="M706.118 41.1788C699.731 36.6678 692.315 34.41 683.868 34.4053C673.969 34.4053 665.216 37.8934 657.608 44.8696C650.193 51.8505 644.322 61.497 639.995 73.8091C635.678 85.9137 633.516 99.9699 633.512 115.977C633.512 132.593 635.673 147.059 639.995 159.376C644.322 171.481 650.401 180.816 658.23 187.382C666.243 193.953 675.614 197.236 686.342 197.231C696.241 197.231 704.49 194.052 711.089 187.693C717.688 181.335 722.734 172.819 726.227 162.148L724.374 161.221C717.775 170.045 710.052 174.457 701.205 174.457C692.357 174.457 684.322 171.071 677.101 164.297C670.101 157.528 664.445 148.294 660.132 136.595C655.819 124.896 653.552 111.462 653.33 96.2932H726.248V89.2228C726.248 78.1409 724.497 68.4968 720.995 60.2903C717.493 52.0838 712.534 45.7133 706.118 41.1788ZM697.153 84.5774C693.656 87.6601 687.478 89.4042 678.621 89.8096L653.281 90.7358C653.903 75.5484 656.788 63.5451 661.935 54.7258C667.082 45.6992 673.681 41.1835 681.732 41.1788C684.699 41.0872 687.64 41.744 690.286 43.0884C692.931 44.4328 695.196 46.4216 696.87 48.8715C700.778 53.7972 702.734 60.5707 702.739 69.192C702.734 76.1635 700.877 81.292 697.167 84.5774H697.153Z" fill="black"/>
    <path d="M1139.54 41.1788C1133.15 36.6678 1125.74 34.41 1117.3 34.4053C1107.4 34.4053 1098.64 37.8934 1091.03 44.8696C1083.62 51.8505 1077.75 61.497 1073.42 73.8091C1069.1 85.9137 1066.93 99.9699 1066.93 115.977C1066.93 132.593 1069.1 147.059 1073.42 159.376C1077.74 171.481 1083.82 180.816 1091.65 187.382C1099.67 193.953 1109.04 197.236 1119.76 197.231C1129.66 197.231 1137.91 194.052 1144.51 187.693C1151.11 181.335 1156.16 172.819 1159.65 162.148L1157.8 161.221C1151.2 170.045 1143.47 174.457 1134.63 174.457C1125.78 174.457 1117.74 171.071 1110.52 164.297C1103.52 157.528 1097.87 148.294 1093.55 136.595C1089.24 124.896 1086.98 111.462 1086.76 96.2932H1159.67V89.2228C1159.67 78.1409 1157.92 68.4968 1154.42 60.2903C1150.91 52.0838 1145.96 45.7133 1139.54 41.1788ZM1130.58 84.5774C1127.08 87.6601 1120.9 89.4042 1112.04 89.8096L1086.71 90.7358C1087.33 75.5484 1090.21 63.5451 1095.36 54.7258C1100.5 45.6992 1107.1 41.1835 1115.15 41.1788C1118.12 41.0872 1121.06 41.744 1123.71 43.0884C1126.35 44.4328 1128.62 46.4216 1130.29 48.8715C1134.21 53.7972 1136.17 60.5707 1136.16 69.192C1136.13 76.1635 1134.26 81.292 1130.55 84.5774H1130.58Z" fill="black"/>
    <path d="M1044.72 9.89453H1042.56C1042.56 13.2177 1039.14 18.1105 1034.63 24.5376L1034.58 24.6153L1033.29 26.4607C1029.96 31.2333 1018.88 39.534 1010.43 41.1743V43.946C1014.35 46.2038 1016.7 49.3832 1017.5 53.4841C1018.53 57.387 1019.05 62.3128 1019.05 68.2614V173.527C1019.05 178.863 1018.33 182.968 1016.89 185.844C1016.32 187.217 1015.44 188.437 1014.31 189.4C1013.18 190.363 1011.84 191.042 1010.39 191.38V194.151H1059.83V191.38C1054.06 189.739 1050.06 187.175 1047.81 183.687C1045.75 179.996 1044.72 174.149 1044.72 166.145V49.1853H1064.19V41.1814H1044.72V9.89453Z" fill="black"/>
    <path d="M996.303 169.84L983.951 75.9581C980.242 48.256 964.896 34.4049 937.915 34.4049C930.915 34.3226 923.974 35.685 917.524 38.4068C911.886 40.6287 906.893 44.226 903.001 48.8711C899.595 52.9207 897.734 58.0459 897.748 63.3373C897.647 65.9226 898.532 68.4497 900.223 70.4078C901.156 71.3287 902.268 72.0494 903.49 72.5259C904.712 73.0025 906.018 73.2248 907.328 73.1794C912.678 73.1794 915.664 69.7926 916.287 63.0191C917.107 55.4254 918.858 49.8845 921.54 46.3964C924.425 42.7056 928.646 40.8602 934.203 40.8602C939.973 40.8602 944.609 43.2171 948.111 47.9307C951.613 52.4417 954.291 59.7267 956.143 69.7856C934.932 83.5306 919.278 97.3817 909.181 111.339C899.084 125.084 894.038 139.651 894.043 155.041C894.043 163.248 895.792 170.533 899.289 176.896C902.86 183.116 907.974 188.31 914.137 191.978C920.784 195.533 928.229 197.334 935.766 197.21C943.365 197.29 950.768 194.798 956.772 190.139C962.952 185.218 967.901 178.447 971.62 169.826C973.473 178.853 976.15 185.216 979.652 188.916C983.357 192.4 988.608 194.144 995.405 194.148H1005.91V191.377C1001.36 190.151 998.156 182.972 996.303 169.84ZM960.159 177.533C955.838 180.346 950.793 181.845 945.636 181.846C937.807 181.846 931.627 178.664 927.097 172.301C922.568 165.937 920.303 157.422 920.303 146.755C920.278 133.938 923.239 121.292 928.95 109.819C934.927 97.9167 944.196 86.2198 956.758 74.7279L970.666 164.912L970.97 165.838C968.566 170.695 964.812 174.755 960.159 177.533V177.533Z" fill="black"/>
  </svg>
);
