import React from 'react';
import styled from 'styled-components';
import { ListingItem } from '../Components/ListingItem';

export const ModalMainWrapper = styled.div`
    display: block;
`;

const DidYouMeanListingPopUp = ({
    onSelect,
    listings
}) => {
    return (
        <ModalMainWrapper>
            <div>
                {listings.map((listing, index) =>
                    <ListingItem
                        key={`listingItem-${index}`}
                        listing={listing}
                        onClick={(listing) => onSelect(listing)}
                    />)}
            </div>
        </ModalMainWrapper>
    );
}

export default DidYouMeanListingPopUp;
