import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { themes } from '../../../Constants/Themes.js';
import { sellerNotificationActionCreators as notificationActions } from '../../../Actions/SellerNotificationActions';
import { BasicText } from '../../../Assets/Style/commonStyleComponents';
import { pdfLinkErrorSelector, pdfUploadProgressSelector, uploadingOfferPdfSelector } from '../../../Reducers/SellerNotificationReducer';
import { CheckMarkIcon } from '../../Shared/Components/Icons/CheckMarkIcon.js';
import { RemovePhotoIcon } from '../../Shared/Components/Icons/RemovePhotoIcon';

const Wrapper = styled.div`
  max-width: 20rem;
  width: auto; 
  height: 100%;
  display:flex;
  align-items: center;
  flex-direction: column;
  padding:20px;
  text-align: center;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  border: dashed 1.5px ${({ theme }) => theme.colors.darkGreen[100]};
  background:  'transparent';
  border-radius: 6px;
  margin-bottom: 1rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    min-width: 24rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    min-width: 26rem;
    margin-right: 2rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-width: 28rem;
  }

  ${({ invalid }) => invalid ? `
    border-color: rgb(229,62,62);
    background-color: #e5161608;
    color: rgb(229,62,62);
  `: ''}
`;

const Outlined = styled.label`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  cursor: pointer;
  width: auto
  white-space: pre-wrap;
  min-width: 6rem;
  line-height: 1.2;
  border-radius: 64px;
  border-color: ${({ theme }) => theme.colors.violet[200]};
  border-width: ${({ plain }) => plain ? '1px' : '0'};
  border-style: ${({ plain }) => plain ? 'solid' : 'none'};
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.mouseballgray[100] : theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-right: 1rem;
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
    margin-bottom: 1rem;
`;

const ProgressContainer = styled.div`
  min-height: 2rem;
  width: 100%;
  padding-bottom: 1rem;
`;

const FilesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

const FileTitle = styled(BasicText)`
  text-align: start;
  padding-bottom: 1rem;
  margin-bottom: 0;
  color: ${({ theme, error }) => error ? theme.colors.red[500] : theme.colors.green[900]};
`;

const ErrorText = styled(FileTitle)`
color: ${({ theme }) => theme.colors.red[500]};
`;

const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 6px;
  background-color: ${({ theme, error }) => error ? '#e5161614' : theme.light};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormIconContainer = styled.div`
  padding-left: 0.75rem;
`;

const InputClearButton = styled.button`
    width: 2rem;
    height: 2.8rem;
    background: none;
    margin-left: 1rem;
`;

const PdfUploadManager = () => {
  const dispatch = useDispatch();
  const offerPdfLinkError = useSelector(pdfLinkErrorSelector);
  const uploadingOfferPdf = useSelector(uploadingOfferPdfSelector);
  const pdfUploadProgress = useSelector(pdfUploadProgressSelector);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [pdfValidationError, setPdfValidationError] = useState({});

  const validateFiles = (files) => {
    return files.map((f, idx) => {
      f.id = `${Date.now()}_${idx}_${f.name}`;
      f.error = false;

      if (!f.type.includes('pdf')) {
        setPdfValidationError(pr => ({
          ...pr,
          [f.id]: 'Please select only valid pdf files.',
        }));
        f.error = true;
        return f;
      }

      if (f.size > 7340032) { // max file size 7 mb
        setPdfValidationError(pr => ({
          ...pr,
          [f.id]: 'File is to big.',
        }));
        f.error = true;
        return f;
      }

      return f;
    });
  };

  const onPdfAdded = async (e) => {
    let files = Array.from(e.target.files);

    if (!files.length) { return }

    const totalFilesCount = files.length + pdfFiles.length;
    if (totalFilesCount > 5) {
      files = Array.prototype.slice.call(files, 0, (5 - pdfFiles.length));
    }

    const validatedFiles = validateFiles(files);
    const filesWithAbortController = validatedFiles.map(f => {
      f.controller = new AbortController(); 
      return f;
    });

    filesWithAbortController.filter(f => !f.error).forEach(f => dispatch(notificationActions.uploadPdfOffer(f)));
    setPdfFiles(pr => ([...pr, ...filesWithAbortController]));
    e.target.value = "";
  }

  const removeFile = (file) => {
    file.controller.abort()
    setPdfFiles(pr => pr.filter(f => f.id !== file.id));
    setPdfValidationError(pr => {
      delete pr[file.id];
      return pr;
    });
    dispatch(notificationActions.removePdfLink(file.id));
  };

  return (
    <Wrapper invalid={offerPdfLinkError}>
      <BasicText className="for-photo-upload">
        Please attach your offer
      </BasicText>
      <FilesListContainer className="for-photo-upload">
        {pdfFiles.length === 0 ?
          'No files selected...' :
          pdfFiles.map(f => (
            <FileContainer error={f.error} key={f.id}>
              <TitleContainer>
                <ProgressContainer>
                  {pdfUploadProgress?.[f.id] < 100 && (
                    <Progress
                      strokeColor={themes.usRealty.lavender}
                      percent={pdfUploadProgress[f.id]}
                      status="active"
                    />
                  )}
                </ProgressContainer>
                <FileTitle error={pdfValidationError?.[f.id]}>
                  {f.name}
                </FileTitle>
                {pdfValidationError?.[f.id] && (
                  <ErrorText className="for-photo-upload">
                    {pdfValidationError[f.id]}
                  </ErrorText>
                )}
              </TitleContainer>
              <FormIconContainer>
                {(pdfUploadProgress?.[f.id] === 100) && (<CheckMarkIcon color="#242B26" />)}
                {(pdfUploadProgress?.[f.id] < 100 && !f.error) && (<Loader animation="border" size="sm" />)}
              </FormIconContainer>
              <InputClearButton type='button' onClick={() => removeFile(f)}>
                <RemovePhotoIcon />
              </InputClearButton>
            </FileContainer>
          ))
        }
      </FilesListContainer>
      {uploadingOfferPdf && <Loader animation="border" size="sm" />}
      <Outlined disabled={uploadingOfferPdf || pdfFiles.length > 4} htmlFor="pdfUpload">
        Click here to {pdfFiles.length > 0 ? 'add another' : 'select'} files <br/> (Pdf only, max. file size 7MB, max file count 5)
      </Outlined>
      <input
        type="file"
        accept="application/pdf"
        multiple
        id="pdfUpload"
        name="pdfUpload"
        disabled={uploadingOfferPdf || pdfFiles.length > 4}
        onChange={onPdfAdded}
        style={{ display: "none" }}
      />
    </Wrapper>
  );
}

export default PdfUploadManager;
