import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import v8n from 'v8n';
import {
  InputFlex, RowFlex, ModalSubmitButton, ModalSubmitButtonWrapper, ModalMainWrapper, ModalAddressHeading, ModalLabelHeading
} from '../../../Assets/Style/commonStyleComponents';
import { validationMessages, regexPattern } from '../../../Constants/CommonConstants';
import { Logo } from '../Components/Icons/Logo';
import Routes from '../../../Constants/Routes';

const ReplayText = styled.p`
  font-family: 'National-Regular',sans-serif;
  font-weight: 300;
  color:#242B26;
  font-size: 14px;
  margin-bottom: 25px;
`;

const CancelButton = styled.button`
  padding: 7px 20px;
  background-color: #fffbfb;
  border: 1px solid #B698D9;
  font-size: 1.2em;
  color: #242B26;
  margin: 4px;
  border-radius: 5px;
  outline: none !important;
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px 0 0;
`;

class AddressNotFoundPopUp extends React.Component {

  state = {
    formData: {
      name: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: validationMessages.name,
          }],
          value: ""
      },
      email: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: validationMessages.email,
          },
          {
            rule: v8n().pattern(regexPattern.EMAIL),
            message: validationMessages.validEmail,
          }],
          value: ""
      },
    }
  };

  handleFieldChanged = (e) => {
    let formData = { ...this.state.formData };
    formData[e.target.name].value = e.target.value;
    this.setState({ formData: { ...formData } });
  }

  handleFieldBlur = (e) => {
    this.validateField(e.target.name, e.target.value);
  }

  validateField = (key, value) => {
    let formData = { ...this.state.formData };
    let isError = false;

    for (let i = 0; i < formData[key].rules.length; i++) {
        let rule = formData[key].rules[i];
        if (!rule.rule.test(value)) {
          formData[key].error = rule.message;
          isError = true;
          break;
        }
    }

    if (!isError) {
        formData[key].error = null;
    }

    this.setState({ formData: { ...formData }, isError: Object.keys(formData).some(k => formData[k].error !== undefined && formData[k].error !== null) });

    return isError;
  }

  submitDetail = (e) => {

    e.preventDefault();

    let isError = false;

    Object.keys(this.state.formData).forEach((k) => isError = this.validateField(k, this.state.formData[k].value));

    if (!isError) {
      const { name, email} = this.state.formData;
      const payload = {
        address: this.props.selectedAddress,
        email: email.value,
        name: name.value,
        county: this.props.county
      }
      this.props.handleSubmitRequest(payload);
    }

  }
  render() {
    return (
      <React.Fragment>
        <ModalMainWrapper>
          <a href={Routes.BUY_SIDE}>
            <Logo/>
          </a>
          <ModalAddressHeading>{this.props.selectedAddress}</ModalAddressHeading>
          <ModalLabelHeading>We aren't aware of your neighborhood yet.</ModalLabelHeading>
          <ReplayText>
            Please contact us at <a href="mailto:info@usrealty.com"> info@usrealty.com</a> or call us at <br/>
            <a href="tel:+18665343726"><i className="fas fa-phone-alt"></i> (866) 534-3726 </a>
            to serve you better.</ReplayText>
          <div>
            <RowFlex>
              <InputFlex
                name="name"
                title="Name"
                value={this.state.formData.name.value}
                error={this.state.formData.name.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                className="inputgap"
              />
              <InputFlex
                name="email"
                title="Email Address"
                type="email"
                value={this.state.formData.email.value}
                error={this.state.formData.email.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                className="inputgap"
              />
            </RowFlex>
          </div>
          <ModalSubmitButtonWrapper>
            <CancelButton onClick={() => this.props.handleCloseModal()}>Cancel</CancelButton>
            <ModalSubmitButton onClick={this.submitDetail}>Submit</ModalSubmitButton>
          </ModalSubmitButtonWrapper>
        </ModalMainWrapper>
      </React.Fragment>
    );
  }
}

export default connect(null)(AddressNotFoundPopUp);
