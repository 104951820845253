import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ordersCreators } from '../../../../../Actions/V2/Admin/OrdersActions';
import { officeUsersSelector } from '../../../../../Reducers/LookupReducer';

export const AssignedSelect = ({
  selectedOption,
  orderId,
}) => {

  const dispatch = useDispatch();
  const officeUsers = useSelector(officeUsersSelector);
  const officeUsersWithUnassigned = [{ value: 'Unassigned', label: 'Unassigned'}, ...officeUsers];
  const selectValue = selectedOption && officeUsers.length
    ? officeUsers.find(val => val.label === selectedOption).value : '';

  const handleChange = e => {
    const { value } = e.target;
    const assigned = value === 'Unassigned' ? null : value;
    dispatch(ordersCreators.assignOrder(orderId, assigned));
  };

  return (
    <select value={selectValue || undefined} onChange={handleChange}>
      {officeUsersWithUnassigned.map((item, index) => (
        <option key={`${item.value} ${index}`} value={item.value}>{item.label}</option>
      ))}
    </select>
  );
};


