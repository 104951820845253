import React from 'react'

export const GoogleIcon = ({ ...iconProps }) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...iconProps}>
      <path
        d="M21.7186 8.99983H11.2182V13.4997H17.1692C16.2186 16.5 13.8692 17.5001 11.1705 17.5001C10.125 17.5013 9.09469 17.2504 8.16687 16.7686C7.23905 16.2867 6.44115 15.5882 5.84085 14.7323C5.24056 13.8763 4.85558 12.8882 4.71859 11.8517C4.5816 10.8153 4.69664 9.76105 5.05394 8.77853C5.41124 7.79601 6.00027 6.91416 6.77104 6.20782C7.54182 5.50148 8.4716 4.99147 9.4815 4.72108C10.4914 4.45069 11.5516 4.42788 12.5722 4.6546C13.5928 4.88132 14.5437 5.35087 15.3441 6.02342L18.6138 2.90675C17.2969 1.69448 15.7039 0.821908 13.9733 0.364771C12.2427 -0.0923672 10.4266 -0.120264 8.6828 0.283503C6.93895 0.687269 5.31997 1.5105 3.96638 2.68175C2.61278 3.853 1.56547 5.33688 0.915314 7.00462C0.265161 8.67235 0.0318191 10.4736 0.235537 12.2519C0.439255 14.0303 1.07388 15.732 2.08433 17.2095C3.09479 18.6871 4.45054 19.8957 6.03394 20.7305C7.61733 21.5653 9.38053 22.001 11.1705 22C17.2361 22 22.7205 17.9997 21.7186 8.99983Z"
        fill="#ffffff"
      />
    </svg>
  );
};
