import React from 'react';
import styled from 'styled-components';
import CashOfferButton from '../../Admin/V2/Components/CashOffer/CashOfferButton';
import { useSelector } from 'react-redux';
import { cashOfferIdSelector } from '../../../Reducers/OnboardingReducerV2';
import dollarSign from '../../../Assets/Svg/dollar-sign.svg';

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const PanelText = styled(Text)`
  margin-top: 1rem;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  border-radius: 10px;
  padding: 1.5rem;
`;

const DollarIcon = styled.img`
  margin-right: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  font-size: 1.25rem;;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
}
`;

const OfferText = styled.p`
  padding-left: 3.75rem;
  padding-right: 3.75rem;
`;

const OfferTextContainer = styled.p`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CashOfferPanel = ({ draftListingId, price, listindAddressDetails }) => {
  const cashOfferId = useSelector(cashOfferIdSelector);

  return (
    <PanelText>
      {cashOfferId && (
        <>
          <HeaderContainer>
            <DollarIcon src={dollarSign} alt="dollar sign illustration" />
            <h4>Get a cash offer</h4>
          </HeaderContainer>
          <p>You've sent request for the cash offer!</p>
        </>
      )}
      {!cashOfferId && (
        <>
          <HeaderContainer>
            <DollarIcon src={dollarSign} alt="dollar sign illustration" />
            <h4>Get a cash offer</h4>
          </HeaderContainer>
          <OfferTextContainer>
            <OfferText>
              Our top notch team of real estate investors, agents and direct buyers are looking for people who want to
              sell their home fast.
            </OfferText>
            <CashOfferButton
              listindAddressDetails={listindAddressDetails}
              draftListingId={draftListingId}
              price={price}
            />
          </OfferTextContainer>
        </>
      )}
    </PanelText>
  );
};

export default CashOfferPanel;
