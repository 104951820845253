import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import v8n from 'v8n';
import { Button } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions';
import {
  RowFlex,
  InputFlex,
} from '../../../Assets/Style/commonStyleComponents';
import { validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import { goBack, getFocusInput, checkIsValidNumber } from '../../../Utilities/commonFunctions';
import { cursorStyles } from '../../../Constants/CommonConstants';

const {ZERO} = Enums.NUMERIC_VALUE
class AddEditCoverage extends React.Component {
  constructor(props) {
    super(props);
    this.isNewCoverage = props.isNewCoverage;
    this.state = {
      coverageDetail: {
        mls: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.requireMls,
            },
          ],
          value: props.userProfileData?.mlsId ? {
            label: props.userProfileData?.name,
            value: props.userProfileData?.mlsId,
          } : '',
        },
        state: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.stateRequired,
            },
          ],
          value: props?.userProfileData?.state ? {
            value: props?.userProfileData?.state,
            label: props.userProfileData?.stateName,
          } : '',
        },
        price: {
          rules: [],
          value: props?.userProfileData?.price || '',
        },
        county: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.userProfileData?.county || '',
        },
        county: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.userProfileData?.county || '',
        },
        zip: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.userProfileData?.zip || '',
        },
        closingFee: {
          rules: [],
          value: props?.userProfileData?.closingFee || '',
        },
        restrictions: {
          rules: [],
          value: props?.userProfileData?.restrictions || [],
        },
      },
    };
  }

  cursorWithMenuIndex = {
    option: (styles) => {
      return {
        ...styles,
        cursor: "pointer"
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 99
      };
    },
  };

  validateFormField = (key, value) => {
    let coverageDetail = { ...this.state.coverageDetail };
    let isError = false;
    for (let i = 0; i < coverageDetail[key].rules.length; i++) {
      let rule = coverageDetail[key].rules[i];
      if (!rule.rule.test(value)) {
        coverageDetail[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (coverageDetail['price'].value && !checkIsValidNumber(coverageDetail['price'].value)) {
      coverageDetail['price'].error = validationMessages.numericValueRequired;
      isError = true;
    }

    if (coverageDetail['zip'].value && !checkIsValidNumber(coverageDetail['zip'].value)) {
      coverageDetail['zip'].error = validationMessages.numericValueRequired;
      isError = true;
    }

    if (!isError) {
      coverageDetail[key].error = null;
    }

    this.setState({
      coverageDetail: { ...coverageDetail },
      isError: Object.keys(coverageDetail).some(
        k =>
        coverageDetail[k].error !== undefined &&
        coverageDetail[k].error !== null,
      ),
    });

    return isError;
  };
  handleAddUpdateCoverageDetails = data => {
    const { userProfileData } = this.props;
    const {county, zip, mls, state, price, closingFee, restrictions } = data;
    const mlsLocationId = this.isNewCoverage ? ZERO : userProfileData.mlsLocationId;
    const coverageDetails = {
      ...userProfileData,
      mlsLocationId,
      mlsId: mls.value,
      name: mls.label,
      state: state.value,
      stateName: state.label,
      county,
      zip,
      price,
      closingFee,
      restrictions
    }

    this.props.addUpdateCoverage(
      mlsLocationId,
      coverageDetails,
      () => {
        goBack();
      },
    );
  };

  validateAndUpdateCoverageDetails = e => {
    e.preventDefault();

    let isError = false;

    Object.keys(this.state.coverageDetail).forEach(keyName => {
      const isErrorExist = this.validateFormField(
        keyName,
        this.state.coverageDetail[keyName].value,
      );
      if (isErrorExist) {
        isError = true;
      }
    });
    if (!isError) {
      this.handleAddUpdateCoverageDetails({
        ...Object.keys(this.state.coverageDetail).reduce((retVal, key) => {
          if (this.state.coverageDetail[key].value) {
            retVal[key] = this.state.coverageDetail[key].value;
          }
          return retVal;
        }, {}),
      });
    }

    const { coverageDetail } = this.state;
    getFocusInput(coverageDetail);
  };
  handleFieldChanged = (e) => {
    let coverageDetail = { ...this.state.coverageDetail };
    coverageDetail[e.target.name].value = e.target.value;
    this.setState({ coverageDetail: { ...coverageDetail } });
  };
  handleFieldBlur = e => {
    this.validateFormField(e.target.name, e.target.value);
  };
  handleSingleSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.coverageDetail };
    if (selectedOption) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = selectedOption;
    this.setState({ coverageDetail: updatedValues });
  };
  
  handleMultiSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.coverageDetail };
    updatedValues[type].value = selectedOption;
    this.setState({ coverageDetail: updatedValues });
  };

  handleFeatureValue = (e, index, inputType) => {
    const { name, value } = e.target;
    const { productFeatureTypes } = this.state;
    const updateObj = productFeatureTypes[index];
    if (inputType === Enums.NUMERIC_VALUE.FIVE) {
      updateObj[name] = !updateObj[name];
    } else {
      updateObj[name] = value;
    }
    this.setState({ productFeatureTypes });
  };
  render() {
    const { lookup } = this.props;
    const { coverageDetail } = this.state;
    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>{this.isNewCoverage ? 'Add Coverage' : 'Edit Coverage'}</h2>
            <div className='admin-heading-wrapper-right'>
              {!this.isNewCoverage && (
                <>
                  <button
                    className='ant-btn btn btn-primary admin-primary-btn delete-btn'
                    id='edit-mode'
                  >
                    Deactivate
                  </button>
                </>
              )}
            </div>
          </div>
          <div className='admin-add-form-wrapper admin-add-edit-abbrivation'>
            <form autoComplete='off'>
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>MLS Coverage</label>
                  <Select
                    styles={cursorStyles}
                    name='mls'
                    placeholder='Select MLS'
                    options={lookup?.mls}
                    value={coverageDetail?.mls?.value}
                    onChange={e =>
                      this.handleSingleSelectChange(e, 'mls')
                    }
                    className={`admin-react-select ${
                      coverageDetail?.mls?.error
                        ? 'admin-react-select-error'
                        : ''
                    }`}
                  />
                  {coverageDetail?.mls?.error && (
                    <span className='select-text-red'>
                      {coverageDetail.mls.error}
                    </span>
                  )}
                </div>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>State</label>
                  <Select
                    styles={cursorStyles}
                    name='state'
                    placeholder='Select State'
                    options={lookup?.states}
                    value={coverageDetail?.state.value}
                    onChange={e => this.handleSingleSelectChange(e, 'state')}
                    className={`admin-react-select ${
                      coverageDetail?.state?.error
                        ? 'admin-react-select-error'
                        : ''
                    }`}
                  />
                  {coverageDetail?.state?.error && (
                    <span className='select-text-red'>
                      {coverageDetail.state.error}
                    </span>
                  )}
                </div>
              </RowFlex>
              <RowFlex>
                <InputFlex
                  title='County'
                  type='text'
                  name='county'
                  value={coverageDetail.county.value || ''}
                  error={coverageDetail?.county?.error}
                  placeholder='Enter County'
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                  className='inputgap'
                />
                <InputFlex
                  title='Zip'
                  type='text'
                  name='zip'
                  value={coverageDetail?.zip?.value || ''}
                  error={coverageDetail?.zip?.error}
                  placeholder='Enter Zip'
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                  className='inputgap'
                />
              </RowFlex>
              <RowFlex>
                <InputFlex
                  name='price'
                  title='Price'
                  className='inputgap'
                  type='text'
                  placeholder='Enter Price'
                  value={coverageDetail?.price?.value}
                  error={coverageDetail?.price?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  name='closingFee'
                  title='Closing Fee'
                  className='inputgap'
                  type='text'
                  placeholder='Enter Closing Fee'
                  value={coverageDetail?.closingFee?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Restrictions</label>
                  <Select
                    styles={this.cursorWithMenuIndex}
                    name='restrictions'
                    placeholder='Select Restrictions'
                    options={lookup?.restrictions}
                    value={coverageDetail?.restrictions.value}
                    onChange={e => this.handleMultiSelectChange(e, 'restrictions')}
                    className='admin-react-select'
                    isMulti
                    maxMenuHeight={100}
                  />
                </div>
              </RowFlex>
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    onClick={this.validateAndUpdateCoverageDetails}
                  >
                    {this.isNewCoverage ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCoverage);
