import { LookupActions } from '../Actions/V2/LookupActions';

const initialState = {
    propertyTypes: [],
    mlsCategories: [],
    companies: [],
    products: [],
    officeUsers: [],
    mlsAbbreviations: [],
    states: [],
    formTypes: [],
    voucherTemplates: [],
    productTypes: [],
    listingStatuses: [],
    formStatuses: [],
    officeStatuses: [],
    titleCompanies: [],
    cashOfferBatch: {}
};

export const lookupReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case LookupActions.PROPERTY_TYPES_RESOLVED:
            return { ...state, propertyTypes: action.payload };
        case LookupActions.MLS_CATEGORIES_RESOLVED:
            return { ...state, mlsCategories: action.payload };
        case LookupActions.COMPANIES_RESOLVED:
            return { ...state, companies: action.payload };
        case LookupActions.PRODUCTS_RESOLVED:
            return { ...state, products: action.payload };
        case LookupActions.OFFICE_USERS_RESOLVED:
            return { ...state, officeUsers: action.payload };
        case LookupActions.MLS_ABBREVIATIONS_RESOLVED:
            return { ...state, mlsAbbreviations: action.payload };
        case LookupActions.STATES_RESOLVED:
            return { ...state, states: action.payload };
        case LookupActions.FORM_TYPES_RESOLVED:
            return { ...state, formTypes: action.payload };
        case LookupActions.VOUCHER_TEMPLATES_RESOLVED:
            return { ...state, voucherTemplates: action.payload };
        case LookupActions.PRODUCT_TYPES_RESOLVED:
            return { ...state, productTypes: action.payload };
        case LookupActions.LISTING_STATUSES_RESOLVED:
            return { ...state, listingStatuses: action.payload };
        case LookupActions.FORM_STATUSES_RESOLVED:
            return { ...state, formStatuses: action.payload };
        case LookupActions.OFFICE_STATUSES_RESOLVED:
            return { ...state, officeStatuses: action.payload };
        case LookupActions.TITLE_COMPANIES_OPTIONS_RESOLVED:
            return { ...state, titleCompanies: action.payload };
        case LookupActions.CASH_OFFER_BATCH_RESOLVED:
            return { ...state, cashOfferBatch: action.payload };
        default:
            return state;
    }
};

export const propertyTypesSelector = state => state.lookup.propertyTypes;
export const mlsCategoriesSelector = state => state.lookup.mlsCategories;
export const companiesSelector = state => state.lookup.companies;
export const productsSelector = state => state.lookup.products;
export const officeUsersSelector = state => state.lookup.officeUsers;
export const mlsAbbreviationsSelector = state => state.lookup.mlsAbbreviations;
export const statesSelector = state => state.lookup.states;
export const formTypesSelector = state => state.lookup.formTypes;
export const voucherTemplatesSelector = state => state.lookup.voucherTemplates;
export const productTypesSelector = state => state.lookup.productTypes;
export const listingStatusesSelector = state => state.lookup.listingStatuses;
export const formStatusesSelector = state => state.lookup.formStatuses;
export const officeStatusesSelector = state => state.lookup.officeStatuses;
export const titleCompaniesSelector = state => state.lookup.titleCompanies;
export const cashOfferBatchSelector = state => state.lookup.cashOfferBatch;