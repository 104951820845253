import React from 'react'

export const ListViewIcon = (iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.75">
        <path d="M13.812 7.17267H2.86494C2.71641 7.16919 2.56755 7.16919 2.41902 7.17267H2.39894L2.58473 7.24038V2.27918C2.58473 2.21233 2.59679 2.1433 2.58473 2.07645C2.58423 2.07356 2.58423 2.07066 2.58473 2.06777L2.18301 2.16154H13.13C13.2786 2.16501 13.4274 2.16501 13.576 2.16154H13.595L13.4123 2.09382V6.62702C13.4123 6.83668 13.4072 7.04678 13.4123 7.25688V7.26556C13.4123 7.32416 13.5579 7.33719 13.6714 7.33241C13.7718 7.32807 13.9978 7.29681 13.9978 7.23951V2.70632C13.9978 2.49665 14.0028 2.28655 13.9978 2.07645V2.06777C13.9964 2.05798 13.9905 2.04841 13.9803 2.03965C13.97 2.03089 13.9558 2.02312 13.9384 2.01682C13.921 2.01053 13.9008 2.00583 13.8791 2.00302C13.8573 2.00021 13.8345 1.99935 13.812 2.00049H3.80899C3.34701 2.00049 2.88603 2.00049 2.42404 2.00049H2.40396C2.2975 2.00049 2.00223 2.02306 2.00223 2.09382V6.62702C2.00223 6.83668 1.99721 7.04678 2.00223 7.25688V7.26556C2.00345 7.27533 2.00929 7.28489 2.01938 7.29365C2.02948 7.30241 2.04363 7.31018 2.06094 7.31649C2.07826 7.32279 2.09838 7.3275 2.12006 7.33031C2.14175 7.33312 2.16454 7.33399 2.18703 7.33285H12.191C12.653 7.33285 13.114 7.33285 13.576 7.33285H13.595C13.7296 7.33285 13.9064 7.31635 13.9777 7.26035C14.049 7.20436 13.9234 7.17267 13.812 7.17267Z" fill="#242B26" stroke="#242B26" strokeWidth="0.6" />
        <path d="M13.812 13.8392H2.86494C2.71641 13.8357 2.56755 13.8357 2.41902 13.8392H2.39894L2.58473 13.9069V8.94568C2.58473 8.87883 2.59679 8.80981 2.58473 8.74296C2.58423 8.74007 2.58423 8.73717 2.58473 8.73428L2.18301 8.82804H13.13C13.2786 8.83151 13.4274 8.83151 13.576 8.82804H13.595L13.4123 8.76032V13.2935C13.4123 13.5032 13.4072 13.7133 13.4123 13.9234V13.9321C13.4123 13.9907 13.5579 14.0037 13.6714 13.9989C13.7718 13.9946 13.9978 13.9633 13.9978 13.906V9.37282C13.9978 9.16316 14.0028 8.95306 13.9978 8.74296V8.73428C13.9964 8.72449 13.9905 8.71492 13.9803 8.70616C13.97 8.6974 13.9558 8.68963 13.9384 8.68333C13.921 8.67703 13.9008 8.67233 13.8791 8.66952C13.8573 8.66671 13.8345 8.66585 13.812 8.66699H3.80899C3.34701 8.66699 2.88603 8.66699 2.42404 8.66699H2.40396C2.2975 8.66699 2.00223 8.68957 2.00223 8.76032V13.2935C2.00223 13.5032 1.99721 13.7133 2.00223 13.9234V13.9321C2.00345 13.9418 2.00929 13.9514 2.01938 13.9602C2.02948 13.9689 2.04363 13.9767 2.06094 13.983C2.07826 13.9893 2.09838 13.994 2.12006 13.9968C2.14175 13.9996 2.16454 14.0005 2.18703 13.9993H12.191C12.653 13.9993 13.114 13.9993 13.576 13.9993H13.595C13.7296 13.9993 13.9064 13.9829 13.9777 13.9269C14.049 13.8709 13.9234 13.8392 13.812 13.8392Z" fill="#242B26" stroke="#242B26" strokeWidth="0.6" />
      </g>
    </svg>
  )
};
