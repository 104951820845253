import React from 'react';
import { orderBy } from 'lodash';
import enums from '../../../../Constants/Enums';
import Routes from '../../../../Constants/Routes';
import closeImg from '../../../../Assets/Images/modal-close.svg';

const { THREE, ZERO } = enums.NUMERIC_VALUE;

const LandingToolTip = ({formSections,showDiv,showId,tdIndex,trindex,tdStep,someHandlerLeave,mlsindex}) => {
  let toolTipIndexCheck=tdIndex+''+mlsindex;
  return (
    <>
    {showDiv && showId===toolTipIndexCheck  && trindex===tdStep && formSections.length > ZERO && (
        <div className='landing-tooltip-box likes__relavance'>
        <span aria-hidden="true" className="tool-tip-close-icon" onClick={() => someHandlerLeave(tdIndex)}>
          <img className="logo-img" src={closeImg} alt="Modal Close icon" width="10" height="10" />
            </span>
          <ul>
            {orderBy(formSections, ['priority'], ['asc']).map(form => (
              <li>
                <a
                  href={form.sectionTypeId === enums.NUMERIC_VALUE.TWO
                    ? `${Routes.ADMIN_ADD_EDIT_FORM.replace(
                      ':formId', form.formGroupId)}?formSectionId=${form.formGroupSectionId}`
                    : `${Routes.ADMIN_ADD_EDIT_FORM.replace(':formId', form.formGroupId)}`}
                  target="_blank"
                  className={form.sectionTypeId === THREE ? 'big' : ''}
                >
                  {form.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default LandingToolTip;
