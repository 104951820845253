import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ordersCreators } from '../../../../../Actions/V2/Admin/OrdersActions';

export const archivedOptions = [
    { value: true, label: `Archived` },
    { value: false, label: `Not Archived` }
];

export const ArchivedSelect = ({
  selectedOption,
  orderId,
}) => {

  const dispatch = useDispatch();
  const selectValue = selectedOption?.toString();

  const handleChange = e => {
    const { value } = e.target;
    const archived = value === 'true';
    dispatch(ordersCreators.archiveOrder(orderId, archived));
  };

  return (
    <select value={selectValue || undefined} onChange={handleChange}>
      {archivedOptions.map((item, index) => (
        <option key={`${item.value} ${index}`} value={item.value}>{item.label}</option>
      ))}
    </select>
  );
};


