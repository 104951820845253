import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import ApiUrls from '../Constants/ApiUrls';
import AppConsts from '../Constants/AppConsts';
import { contentType, apiMethodType } from '../Constants/CommonConstants';
import Cookies from 'js-cookie';
import { setStateToUrl } from '../Utilities/History';

class UserService {

  constructor() {

    let urls = ((window.config || {}).urls || {}).user || {};

    urls.base = urls.base || "/";

    this.urls = urls;

  }


  login(email, password) {

    const url = this.urls.login || ApiUrls.login;

    return fetch(url, {
      method: apiMethodType.POST,
      withCredentials: true,
      data: {
        email,
        password
      },
      headers: {
        'Content-Type': contentType.applicationJson
      }
    });

  }

  getUserAvatar() {
    const url = ApiUrls.userAvatar;

    return fetch(url, {
      method: apiMethodType.GET,
      withCredentials: true,
      responseType: 'blob',
    });
  }

  getExternalUser() {
    const url = ApiUrls.externalUser;

    return fetch(url, {
      method: apiMethodType.GET,
      withCredentials: true,
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  createUserFromExternal() {
    const url = ApiUrls.externalUser;

    return fetch(url, {
      method: apiMethodType.POST,
      withCredentials: true,
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  createUser(params) {

    const url = this.urls.login || ApiUrls.createUser;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson
      }
    });

  }

  createPaymentIntent(productId) {

    const url = ApiUrls.paymentIntent;

    return fetch(url, {
      method: apiMethodType.POST,
      data: productId,
      headers: {
        'Content-Type': contentType.applicationJson
      }
    });

  }

  setLocalUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  setSwitchlocation(location) {
    localStorage.setItem("switchlocation", location);
  }

  getSwitchlocation() {
    return localStorage.getItem("switchlocation") !== "undefined" ? localStorage.getItem("switchlocation") : {};
  }

  getLocalUser() {
    return localStorage.getItem("user") !== "undefined" ? JSON.parse(localStorage.getItem("user")) : {};
  }

  clearLocalUser() {
    localStorage.clear();
    setStateToUrl(null, {});
    Cookies.remove(AppConsts.authorization.externalCookiesName, { domain: '.unrealestate.com' });
  }

  logout(userId, token) {

    const url = this.urls.login || ApiUrls.logout;

    return fetch(url, {
      method: apiMethodType.POST,
      data: {
        userId,
        token
      },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });

  }

  updateProfile(reqObj) {

    const url = this.urls.login || ApiUrls.updateProfile;

    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });

  }

  forgotPasswordRequest(params) {
    const url = ApiUrls.forgotPasswordRequest;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  resetPasswordRequest(params) {
    const url = ApiUrls.resetPasswordRequest;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  requestPropertyDetails(reqObj) {

    const url = ApiUrls.requestPropertyDetails;

    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  updateBillingAddress(reqObj) {
    const url = ApiUrls.billingAddressInfo;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  getDefaultBillingAddress() {
    const url = ApiUrls.billingAddressInfo;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  addUpdateVouchers(voucherId, reqObj) {
    const url = `${ApiUrls.addNewVoucher}/${voucherId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addVoucherV2(request) {
    const url = `${ApiUrls.vouchersV2}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: request,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  updateVoucherV2(voucherId, request) {
    const url = `${ApiUrls.vouchersV2}/${voucherId}`;
    return fetch(url, {
      method: apiMethodType.PUT,
      data: request,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  downloadZipApi(reqObj) {
    const url = `${ApiUrls.downloadZip}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
      responseType: "blob",
      timeout: 180000,
    });
  }

  RequestEbookDownload(reqObj) {
    const url = `${ApiUrls.RequestEbookDownload}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  
  joinResidentialIntelligenceList(reqObj) {
    const url = `${ApiUrls.joinResidentialIntelligenceList}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
}

export let userService = new UserService();
