const KLAVIYO_EVENTS = {
    UnbounceSignUpStart: "Unbounce Sign Up Start",
    UnbounceSignUpAccountCreated: "Unbounce Sign Up Account Created",
    UnbounceSignUpPropertyAddressSelected: "Unbounce Sign Up Property Address Selected",
    HomePageAddressSelected: "Home Page Address Selected",
    HomePagePlanSelected: "Home Page Plan Selected",
    HomePageComparePlan: "Home Page Compare Plan",
    StartForFreePressed: "Start For Free Pressed",
    StartForFreeAddressSelected: "Start For Free Address Selected",
    StartForFreeComparePlan: "Start For Free Compare Plan",
    StartForFreePlanSelected: "Start For Free Plan Selected",
}

export default KLAVIYO_EVENTS;