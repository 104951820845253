import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';
import { removeNullUndefined } from '../../Utilities/commonFunctions';

class TitleCompanyService {
    getTitleCompany(state, companyId) {
        const params = removeNullUndefined({state, companyId});
        const query = new URLSearchParams(params).toString();
        const url = `api/v2/title-company?${query}`;

        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }
    getTitleCompanies(filters){
        const params = removeNullUndefined(filters);
        const query = new URLSearchParams(params).toString();
        const url = `api/v2/title-company/all?${query}`;

        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    addAdvertisementFile(fileName, fileContent) {
        const data = {
            fileContent,
            fileName,
          };
      
          const url = 'api/v2/title-company/advertisement-file';
          return fetch(url, {
            method: apiMethodType.POST,
            data,
            headers: {
              'Content-Type': contentType.applicationJson,
              ...authHeader()
            }
          });
    }

    deleteAdvertisementFile(fileId) {
        const url = `api/v2/title-company/advertisement-file/${fileId}`;
        return fetch(url, {
            method: apiMethodType.DELETE,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    checkCoverage(coverage){
        const url = 'api/v2/title-company/check-coverage';

        return fetch(url, {
            method: apiMethodType.POST,
            data: coverage,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }
    createTitleCompany(model){
        const url = 'api/v2/title-company';

        return fetch(url, {
            method: apiMethodType.POST,
            data: model,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }
    updateTitleCompany(model){
        const url = 'api/v2/title-company';

        return fetch(url, {
            method: apiMethodType.PUT,
            data: model,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }
    getDetailsById(id){
        const url = `api/v2/title-company/${id}`;

        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    addListingPhoto(fileName, fileContent) {
        const data = {
            fileContent,
            fileName,
        };
    
        const url = ``;
        return fetch(url, {
            method: apiMethodType.POST,
            data,
            headers: {
            'Content-Type': contentType.applicationJson,
            ...authHeader()
            }
        });
    }
}

export const titleCompanyService = new TitleCompanyService();
