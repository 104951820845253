import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { adminActionCreators } from '../../../Actions/AdminActions';
import { userActionCreators } from '../../../Actions/UserActions';
import { FilterComponent } from '../../Shared/Components/FilterComponent';
import { ListingComponent } from '../../Shared/Components/ListingComponent';
import enums from '../../../Constants/Enums';
import {
  tableItemsPerPage,
  entityTypes,
} from '../../../Constants/CommonConstants';
import {
  wholesaleVoucherListingTableConfigs,
  wholesalecoverageListingTableConfigs,
  networkUserTableConfigs,
} from '../../../Constants/TableConstants';
import { SearchComponent } from '../../Shared/Components/SearchComponent';
import { getLocalUserData } from '../../../Utilities/commonFunctions';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { dashboardTabs } from '../../../Constants/CommonConstants';

class VoucherListingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: enums.NUMERIC_VALUE.ONE,
      title: '',
      items: tableItemsPerPage,
      sort: '',
      sortBy: 0,
      openFilter: false,
      tableColumns: [],
      activeEntity: props.activeEntity,
      filteredArray: [],
      tableConfigs: this.getTableConfigs(props.activeEntity),
      coverageTabStatus: false
    };
  }
  lookup = () => {
    const { getLookupData } = this.props;
    const { activeEntity } = this.state;
    switch (activeEntity) {
      case entityTypes.voucher:
        getLookupData(activeEntity, 'Voucher');
        break;
      case entityTypes.dashboardCoverage:
        getLookupData(activeEntity, 'dashboardCoverage');
        case entityTypes.networkUser:
          //getLookupData(activeEntity, 'networkUser');  
        break;
      default:
        break;
    }
  }
  getActiveTab() {
    const {
      location: { hash },
    } = this.props;
    return hash && hash === `#${dashboardTabs.dashboardCoverage}`?'dashboardCoverage': hash === `#${dashboardTabs.networkUser}`?'networkUser':'voucher';
  }
  /**
   * @description componentDidMount is called when component is loaded
   * call getAll to fetch user listings
   */
  componentDidMount() {
    const { userDetails } = this.props;
    const { items, activePage,activeEntity,  tableConfigs } = this.state;
    const activeKey=this.getActiveTab();
    if(activeEntity!==activeKey) {
      this.props.getWholeSaleListings("",activeKey)
      const tableColumns = tableConfigs.columns || [];
      tableConfigs.columns = tableColumns;
      this.setState({ tableConfigs });
    }
    const reqObj = {
      page: Number(activePage),
      limit: items,
    };
    const updateReqObj = this.updateReqObj(reqObj);
    if(activeEntity === entityTypes.voucher) {
      this.props.getWholeSaleListings(userDetails?.userId, activeEntity, updateReqObj);
    } else if(activeEntity === entityTypes.dashboardCoverage){
      this.props.getWholeSaleCoverage(activeEntity, updateReqObj);
    }else if(activeEntity === entityTypes.networkUser){
      this.props.getNetworkUser(userDetails?.userId,activeEntity, updateReqObj);
    }
    this.lookup();
    if(activeEntity == 'dashboardCoverage') {
      this.setState({coverageTabStatus: true});
    }
  }

  getTableConfigs = entity => {
    let tableConfigs;
    switch (entity) {
      case entityTypes.voucher:
        tableConfigs = wholesaleVoucherListingTableConfigs;
        break
      case entityTypes.dashboardCoverage:
        tableConfigs = wholesalecoverageListingTableConfigs;
        break
      case entityTypes.networkUser:
        tableConfigs = networkUserTableConfigs;
        break  
      default:
        tableConfigs = {
          columns: [],
          filters: [],
          deleteMessage: '',
          addPageUrl: '',
          addButtonText: '',
          searchPlaceHolder: '',
        };
        break;
    }
    return tableConfigs;
  };

  /**
   * @description updateReqObj to update object before sending to the server
   */
  updateReqObj(reqObj) {
    const { filteredArray, title } = this.state;
    // Search text
    reqObj.searchKey = title;

    // filters
    reqObj.filters = filteredArray;
    return reqObj;
  }

  /**
   * @description fetchListingApiAction is used on initial load
   */
  fetchListingApiAction(pageNo) {
    const { userDetails } = this.props;
    const { items, sort, sortBy, activeEntity } = this.state;
    let reqObj = {
      page: Number(pageNo),
      limit: items,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    const updateReqObj = this.updateReqObj(reqObj);
    if(activeEntity === entityTypes.voucher) {
      this.props.getWholeSaleListings(userDetails?.userId, activeEntity, updateReqObj);
    } else if(activeEntity === entityTypes.dashboardCoverage){
      this.props.getWholeSaleCoverage(activeEntity, updateReqObj);
    }else if(activeEntity === entityTypes.networkUser){
      this.props.getNetworkUser(userDetails?.userId,activeEntity, updateReqObj);
    }
  }

  /**
   * @description
   * handleInputChange is used to set the value on state from the input.
   * @param {Object} || {String} value
   * @param {String} key
   */
  handleInputChange = (value, key) =>
    this.setState({
      ...this.state,
      [key]: value,
      activePage: enums.NUMERIC_VALUE.ONE,
    });

  /**
   * @description
   * handleTableChange is called someone click on the pagination.
   * @param {Object} requestObject Object that contains pagination and sorting data
   */
  handleTableChange = requestObject => {
    const { activePage, sort, sortBy, pageSize } = requestObject;
    this.setState({ activePage, sort, sortBy, items: pageSize }, () =>
      this.fetchListingApiAction(activePage),
    );
  };

  applySearch = (key, value) => {
    this.setState(
      { ...this.state, [key]: value, activePage: enums.NUMERIC_VALUE.ONE },
      () => this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE),
    );
  };
  
  clearSearch = () =>
    this.setState({ title: '' }, () =>
      this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE),
    );

  applyFilters = filteredArray =>
    this.setState({ filteredArray }, () =>
      this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE),
    );

  clearFilters = () =>
    this.setState({ filteredArray: [] }, () =>
      this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE),
    );

  /**
   * @description
   * render is used to render HTML
   */
  
  render() {
    const { activePage, sort, sortBy, items, tableConfigs, coverageTabStatus } = this.state;
    const {
      activeSet,
      totalSet,
      isRequesting,
      activeEntity,
      voucherTemplatesType,
      mlsTypes,
      companies,
      states,
      zipCodesTypes,
      county,
    } = this.props;
    const {
      addPageUrl,
      addButtonText,
      searchPlaceHolder,
      filters,
    } = tableConfigs;
    const filterObj = { activePage, sort, sortBy, pageSize: items };
    const pageData = {
      path: addPageUrl,
      title: addButtonText,
      placeholder: searchPlaceHolder,
    };
    return (
      <React.Fragment>
        <div className={`customer-search-filter-wrapper ${coverageTabStatus ? 'coverage' : ''}`}>
          <div className="customer-voucher-search-wrapper">
            <SearchComponent
              applySearch={this.applySearch}
              clearSearch={this.clearSearch}
              pageData={pageData}
              activeEntity={activeEntity}
            />
          </div>
          {filters.length > 0 && !isRequesting && (
            <div className="customer-voucher-filter-wrapper">
              <FilterComponent
                filterContent={filters}
                applyFilters={this.applyFilters}
                clearFilters={this.clearFilters}
                activeEntity={activeEntity}
                voucherTemplatesType={voucherTemplatesType}
                mlsTypes={mlsTypes}
                companies={companies}
                states={states}
                zipCodeTypes={zipCodesTypes}
                county={county}
              />
            </div>
          )}
        </div>
        {!isRequesting && (
          <div className="customer-voucher-listing">
            <ListingComponent
              isFilterApplied={filters?.length}
              tableColumns={tableConfigs.columns}
              listData={activeSet}
              filterObj={filterObj}
              totalItems={totalSet}
              handleTableChange={this.handleTableChange}
              activeEntity={activeEntity}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators, ...userActionCreators, ...dashboardActionCreators }, dispatch);
}

function mapStateToProps(state) {
  const { activeSet, totalSet, isListingFetching } = state.dashboard;
  const { lookup, isRequesting } = state.user;
  const userDetails = getLocalUserData();
  return {
    activeSet: activeSet,
    totalSet,
    isListingFetching,
    voucherTemplatesType: lookup?.voucherTemplates || [],
    mlsTypes: lookup?.mls || [],
    states: lookup?.states || [],
    companies: lookup?.companies || [],
    isRequesting,
    userDetails: userDetails.isAdminSwitchUser ? userDetails.switchedUser : userDetails,
  };
}

const voucherListingContainerWithRouter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoucherListingContainer);
export { voucherListingContainerWithRouter as VoucherListingContainer };
