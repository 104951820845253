import React from 'react';
import { Container, CustomAccordion, HeadTextWrap, Heading, HeadText } from "../../../../Assets/Style/commonStyleComponents";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { ImagesUrl } from "../../../../Constants/Images";
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

class Checklists extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <CustomAccordion>
          <Container className="faq-container">
            <HeadTextWrap className="for-left">
              <Heading>Checklists</Heading>
              <HeadText>The US Realty knowledge base.</HeadText>
            </HeadTextWrap>
            <Accordion defaultActiveKey="0" className="faq-accordion">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                    Showcasing Energy- and Cost-saving Features
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        Heating, cooling, electric, and water costs are important affordability factors. If you’ve replaced or
                         upgraded aspects of these systems, list these investments for buyers.
                        <h3>Show buyers how your green features could save them some green:</h3>
                        <ul>
                        <li>
                            1. Compare the your monthly utility bills to average utility bills in your area, illustrating how your ugraded
                             systems will save buyers money on their monthly bills.
                        </li>
                        <li>
                            2. Point out passive solar features: south and western exposures capture plenty of sun, especially when
                             other features, such as tile floors and insulated window coverings, keep that heat in.
                        </li>
                        <li>
                            3. List energy efficient appliances and have available documentation as to when you purchased those
                             appliances.
                        </li>
                        <li>
                            4. List additional energy-efficient upgrades, such as new insulation, insulated or new pipes, and a high-
                            efficiency water heater and furnace.
                        </li>
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                    Showcasing Amenities
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        Heating, cooling, electric, and water costs are important affordability factors. If you’ve replaced or
                         upgraded aspects of these systems, list these investments for buyers.
                        <h3>Illustrate the value-adds that your house brings to potential buyers with these ideas for supplemental
                         listing sheets:</h3>
                        <ul>
                        <li>
                            1. Flexible or multipurpose rooms: Show additional uses for extra or flexible rooms, such as guest
                             room, hobby room, office, playroom, or other uses depending on the location of the room.
                        </li>
                        <li>
                            2. Storage space: Showcase the laundry room, mud room, pantry, garage organization, master closet
                             organization, and additional finished or semi-finished, dry storage space.
                        </li>
                        <li>
                            3. Holiday decorating: If your area has strong traditions for holiday decorating, provide a few photos
                             that illustrate how the house looks decked out for Christmas, Halloween, and other holidays.
                        </li>
                        <li>
                            4. Outdoor living: Especially if you are selling in winter, provide photos and sketches that illustrate how
                             the yard, deck, patio and other outside areas extend the livable space.
                        </li>
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                    Location Selling Points
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                    <Card.Body>
                        <h3>Does your home for sale have…</h3>
                        <ul>
                        <li>
                            1. Easy access to work, schools, shopping and entertainment is essential: Buyers want to envision their
                             daily commutes, errands and schedules lived out in your house.
                        </li>
                        <li>
                            2. Create a supplemental listing sheet that situates your house on a map and shows access to public
                             transit, highway access, main local routes, schools, and key amenities like grocery stores,
                              neighborhood shopping and parks.
                        </li>
                        <li>
                            3. Include the WalkScore ( a ranking of walking convenience for daily activities) and a printout
                             of <a href="https://www.walkscore.com/" rel="noopener noreferrer" target="_blank">WalkScore</a> amenities.<br/>
                             Each one point increase in a WalkScore delivered $500 to $3,000 more in home value than comparable
                              houses with lower WalkScores, according to that company’s research. Even houses with mixed
                               WalkScores – close to some daily amenities but within a short drive of others – earn higher values in the
                              WalkScore metric.
                        </li>
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                    Cost of Ownership
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                    <Card.Body>
                    Buyers need to see if your house fits in their budgets. Most people budget monthly, so providing a
                     breakdown of the monthly and annual cost of owning your house provides invaluable guidance. Use this
                      “Cost of Ownership” sheet as a supplement to the listing sheet and provide it at the beginning of
                       negotiations.<br /><br />

                    Use the actual cost of utilities and break your current property tax bill into monthly increments. (Note this
                    on the sheet, including the annual property tax as well.)
                        <h3>The sheet should include:</h3>
                        <ul>
                        <li>
                            1. Mortgage payments (use your asking price, minus a 10% down payment)
                        </li>
                        <li>
                            2. Homeowners’ insurance
                        </li>
                        <li>
                            3. Condo/HOA fees
                        </li>
                        <li>
                            4. Electricity
                        </li>
                        <li>
                            5. Gas/heating
                        </li>
                        <li>
                            6. Cable/internet
                        </li>
                        <li>
                            7. Water
                        </li>
                        <li>
                            8. Property taxes
                        </li>
                        <li>
                            9. Any additional costs unique to your house
                        </li>
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="4">
                    Close the Sale
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                    <Card.Body>
                        <ul>
                        <li>
                            1. Review the contract with your real estate attorney.
                        </li>
                        <li>
                            2. Assemble the documents you need for closing.
                        </li>
                        <li>
                            3. With an accepted offer, collaborate with the buyer, and, potentially, the buyer’s agent, on the home
                             inspection; title search; survey; and other legal processes.
                        </li>
                        <li>
                            4. Arrange a closing date; allow four to eight weeks between accepting the buyer’s offer and closing.
                        </li>
                        <li>
                            5. Pack and arrange for movers and a cleaning service.
                        </li>
                        <li>
                            6. Make arrangements for your move-out day, including the final walk-through before closing.
                        </li>
                        <li>
                            7. Close the sale!
                        </li>
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="5">
                    Promote & Persuade: List Your House On the Market
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                    <Card.Body>
                        <ul>
                        <li>
                            1. Decide on the asking price.
                        </li>
                        <li>
                            2. Double-check your listing information for accuracy and spelling.
                        </li>
                        <li>
                            3. Decide on the amount of commission you will pay so you can market the house to local realty agents
                             accordingly.
                        </li>
                        <li>
                            4. Complete and upload your free USRealty.com listing.
                        </li>
                        <li>
                            5. Download the printable version and make print copies.
                        </li>
                        <li>
                            6. Keep on hand a stock of fresh printed listing sheets; supplemental information about the house.
                        </li>
                        <li>
                            7. Stay informed about local market trends so you can provide context to potential buyers.
                        </li>
                        <li>
                            8. Collaborate with agents and buyers who want to see the house.
                        </li>
                        <li>
                            9. Consider hiring a friend to walk buyers through the house to provide a more dispassionate guide.
                        </li>
                        <li>
                            10. As serious offers emerge, qualify buyers’ ability to purchase your house so you don’t waste time with
                             those who cannot afford it.
                        </li>
                        <li>
                            11. Negotiate with qualified buyers who make offers. Be sure that you can back up your negotiations with
                             market data that supports the value of your house in the current market.
                        </li>
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="6">
                    Prepare & Price
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                    <Card.Body>
                        <h3>Two to three months before listing:</h3>
                        <ul>
                            <li>
                                1. Conduct market research to establish the best price. Use USRealty.com tools to set the price most
                                likely to draw in buyers.
                            </li>
                            <li>
                                2. Assemble the legal documents you need to complete the sale and replace any missing documents.
                            </li>
                            <li>
                                3. Conduct a search of public websites to detect any outstanding lien or other legal complication to
                                completing the sale so you can clear up the problem.
                            </li>
                            <li>
                                4. Walk through the house to identify touch-ups and repairs. (Our checklist helps.) Make a to-do list and
                                tackle those projects.
                            </li>
                            <li>
                                5. Line up any professionals you will need for closing, such as a real estate lawyer.
                            </li>
                        </ul>
                        <h3>One month before market:</h3>
                        <ul>
                        <li>
                            1. Develop your marketing strategy.
                        </li>
                        <li>
                            2. Gather the data you need to complete your USRealty.com listing.
                        </li>
                        </ul>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="7">
                    Home Prep Checklist
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="7">
                    <Card.Body>
                    Use this list to tackle the big picture and every detail to make your house shine in online photos and in real life.
                    <br /><br />
                    <a href={ ImagesUrl.HOME_PREP_CHECKLIST } target="_blank">Download the Checklist</a>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="8">
                    Ready to Sell Checklist
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="8">
                    <Card.Body>
                        <h3>Use this Ready to Sell checklist to identify:</h3>
                        <ul>
                            <li>
                                1. Your home’s best features, that can become selling points.
                            </li>
                            <li>
                                2. Your home’s drawbacks – some to be fixed and some to be addressed through pricing and marketing.
                            </li>
                            <li>
                                3. How to best present your home in its online listing, which is where your house makes its first and
                                 strongest impression on potential buyers.
                            </li>
                        </ul>
                        <a href={ ImagesUrl.READY_TO_SELL_CHECKLIST } target="_blank">Download the Checklist</a>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
          </Container>
        </CustomAccordion>
        <Footer />
      </div>
    )
  }
}

export default Checklists;
