import React from 'react';
import { MapWrapper, MapBox, MapContent } from '../../../Assets/Style/commonStyleComponents';
import DisplayText from '../../Shared/Components/DisplayText';
import DashboardService from '../../../Services/DashboardService';
import AppConsts from '../../../Constants/AppConsts';
const dashboardService = new DashboardService();

export default class ShowMap extends React.Component {
constructor(props) {
    super(props);
    this.state={
        latitude:'',
        logitude:'',
        updateLat:true
    }
}

async componentWillReceiveProps(nextProps){
    if(this.state.updateLat && nextProps.address1 && !nextProps.latitude){
        this.getGeoCode(nextProps);
    }else if(nextProps.address1){
        this.setState({updateLat:false});
        this.setState({latitude:nextProps.latitude});
        this.setState({logitude:nextProps.longitude});
      }
    }
getGeoCode = async (reqData) =>{
   const fullAddress=reqData.address1+' '+reqData.address2+','+reqData.city+','+reqData.state+','+reqData.zip;
    const { data } = await dashboardService.getLatLong(fullAddress);
    const dataJson = data;
    const jsonResonse=dataJson.value;

    if (!jsonResonse.results || jsonResonse.results.length === 0) {
      return;
    }

    const { lat, lng } = jsonResonse.results[0].geometry.location;
    this.setState({latitude:lat});
    this.setState({logitude:lng});
    this.setState({updateLat:false});
    const payload = {listingId:reqData.listingId,latitude:lat,longitude:lng};
    dashboardService.saveLatLong(payload);
}

render() {
    const {address1,address2,unit,city,state,zip,theme}=this.props;
return (
    <>
   <MapWrapper className="for-bottom-gap">
                <MapBox>
                  <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.latitude
                      },${this.state.logitude
                      }&zoom=15&size=1200x100&scale=2&markers=color:${theme.secondary.replace(
                        '#',
                        '0x'
                      )}%7Csize:small%7C${this.state.latitude},${this.state.logitude
                      }&key=${AppConsts.googleMapApiKey}`}
                    alt={address1}
                  />
                </MapBox>
                <MapContent>
                  <div className="for-inner-form">
                    <DisplayText title="Address">
                      {address1} {address2} {unit}
                      <br />
                      {city ? city + ',' :''} {state} {zip}
                    </DisplayText>
                  </div>
                </MapContent>
              </MapWrapper>
    </>
);
    }
}

