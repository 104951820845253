import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const setStateToUrl = (route, state) => {
  const _route = route || history.location.pathname;
  
    history.replace({
      pathname: _route,
      search: new URLSearchParams(state).toString(),
    });
}
