import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import v8n from "v8n";
import styled from "styled-components";
import moment from "moment";
import { DatePicker } from "antd";
import { userActionCreators } from "../../../Actions/UserActions";
import { adminActionCreators } from "../../../Actions/AdminActions";
import { onboardingActionCreators } from "../../../Actions/OnboardingActions";
import { alertActions } from "../../../Actions/ToastActions";

import { onboardingService } from "../../../Services/OnboardingService";
import AddressNotFoundPopUp from "../../Shared/Modals/AddressNotFoundPopUp";
import {
  RowFlex,
  Container,
  InputFlex,
  CustomButton,
  HeadTextWrap,
  Heading,
  DetailsWrapper,
  CustomTextarea,
  ModalDiv,
} from "../../../Assets/Style/commonStyleComponents";
import { history } from "../../../Utilities/History";
import Routes from "../../../Constants/Routes";
import {
  validationMessages,
  entityTypes,
  dateFormat,
  BlockAddress,
} from "../../../Constants/CommonConstants";
import Enums from "../../../Constants/Enums";
import {
  copyVoucherCode,
  getCurrentUserData,
  getFocusInput,
  statusChangeModalPopup,
} from "../../../Utilities/commonFunctions";
import { cursorStyles, regexPattern } from "../../../Constants/CommonConstants";
import { wholesaleVoucherListingTableConfigs } from "../../../Constants/TableConstants";

function disablePastDate(current) {
  return current && current < moment().startOf("day");
}
const google = window.google;

const Results = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: absolute;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
`;

const Result = styled.a`
  padding: 18px 20px;
  color:#242B26;
  z-index: 1;
  border-bottom: 1px solid #f0f0f0;
  ${(props) => (props.isActive ? "background-color:#C9E2F3;" : "")}
  &:last-child {
    border: none;
  }
  &:hover {
    background: #f0f0f0;
    color:#242B26;
  }
  @media (max-width: 768px) {
    padding: 12px 10px;
    line-height: 24px;
    font-size: 15px;
  }
`;

const disabled_input = "disabled-input";
class AddEditVoucher extends React.Component {
  autoService = null;
  placesService = null;
  timeout = null;

  constructor(props) {
    super(props);
    this.isNewVoucher = props.isNewVoucher;
    let fullAddress='';
    if(props?.voucherDetail?.address){
       fullAddress=props.voucherDetail?.address?.addressAddress1+' '+props.voucherDetail?.address?.addressAddress2+' '+
      props.voucherDetail?.address?.addressCity+','+props.voucherDetail?.address?.addressState+','+props.voucherDetail?.address?.addressZip;
    }
    this.state = {
      isEnabled: true,
      currentIndex: 0,
      isLoading: false,
      searchValue: fullAddress,
      predictions: [],
      isAddressNotServiceAble: false,
      isAddressConfirmationPopup: false,
      voucherDetailForm: {
        SendVoucherRequest: {
          rules: [],
          value: props.voucherDetail?.SendVoucherRequest
            ? props.voucherDetail?.SendVoucherRequest
            : true,
        },
        voucherTemplateId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props.voucherDetail?.voucherTemplateId
            ? props?.lookup?.voucherTemplates.filter(
                (f) => f.value === props.voucherDetail?.voucherTemplateId
              )[0]
            : "",
        },
        name: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
            {
              rule: v8n().pattern(regexPattern.EMAIL),
              message: validationMessages.validEmail,
            },
          ],
          value: props.voucherDetail?.name || "",
        },
        voucherExpiryDate: {
          rules: [],
          value: props.voucherDetail?.voucherExpiryDate || "",
        },
        companyId: {
          rules: [],
          value: props.voucherDetail?.companyId
            ? props?.lookup?.companies.filter(
                (f) => f.value === props.voucherDetail?.companyId
              )[0]
            : "",
        },
        description: {
          rules: [],
          value: props?.voucherDetail?.description || "",
        },
        address1: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: "Please enter your street address",
            },
          ],
          value: props.voucherDetail?.address?.addressAddress1 || "",
        },
        city: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: "Please enter your city",
            },
          ],
          value: props.voucherDetail?.address?.addressCity || "",
        },
        address2: {
          rules: [],
          value: props.voucherDetail?.address?.addressAddress2 || "",
        },
        country: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: "Please select your country",
            },
          ],
          value: props.voucherDetail?.address?.addressCountry || "US",
        },
        state: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: "Please select your state",
            },
          ],
          value: props.voucherDetail?.address?.addressState || "",
        },
        zip: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: "Please enter your zip",
            },
            {
              rule: v8n().minLength(5),
              message: "Zipcode required minimum five character",
            },
          ],
          value: props.voucherDetail?.address?.addressZip || "",
        },
        county: {
          rules: [],
          value: props.voucherDetail?.address?.addressCounty || "",
        },
        unit: {
          rules: [],
          value: props.voucherDetail?.address?.unit || "",
        },
        address: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: "Please select your address",
            },
          ],
          value: '',
        },
      },
      selectedURL: true,
      billingData: {},
      shippingData: {},
    };
  }

  componentWillUnmount() {
    localStorage.removeItem("selectedAddress");
  }

  handleCloseModal = (e) => {
    this.props.closeModal();
    this.props.selectAddress({});
    this.setAddressValue();
  };

  handleFieldChanged = (e) => {
    let voucherDetailForm = { ...this.state.voucherDetailForm };
    voucherDetailForm[e.target.name].value = e.target.value;
    this.setState({ voucherDetailForm: { ...voucherDetailForm } });
  };

  handleCheckboxChanged = (e) => {
    let voucherDetailForm = { ...this.state.voucherDetailForm };
    voucherDetailForm[e.target.name].value = e.target.checked;
    this.setState({ voucherDetailForm: { ...voucherDetailForm } });
  };

  handleFieldBlur = (e) => {
    this.validateField(e.target.name, e.target.value);
  };

  validateField = (key, value) => {
    let voucherDetailForm = { ...this.state.voucherDetailForm };

    let isError = false;

    for (let i = 0; i < voucherDetailForm[key].rules.length; i++) {
      let rule = voucherDetailForm[key].rules[i];
      if(key==='address' && this.state.searchValue){
        isError = false;
        voucherDetailForm[key].error='';
      }else if (!rule.rule.test(value)) {
        voucherDetailForm[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      voucherDetailForm[key].error = null;
    }

    this.setState({
      voucherDetailForm: { ...voucherDetailForm },
      isError: Object.keys(voucherDetailForm).some(
        (k) =>
          !!voucherDetailForm[k].error !== undefined &&
          !!voucherDetailForm[k].error !== null
      ),
    });

    return isError;
  };

  handleAddUpdateVoucherDetails = (reqObj) => {
    const {
      name,
      voucherExpiryDate,
      voucherTemplateId,
      address1,
      address2,
      city,
      state,
      country,
      zip,
      county,
      unit,
      description,
      companyId,
      SendVoucherRequest,
    } = reqObj;
    const voucherId = this.props.isNewVoucher
      ? Enums.NUMERIC_VALUE.ZERO
      : this.props.voucherDetail.id;
    let path = "";
    if (typeof window !== "undefined") {
      path = `${window.location.protocol}//${window.location.host}${Routes.ONBOARDING_VOUCHER}`;
    }
    const payload = {
      name,
      address: {
        addressAddress1: address1,
        addressAddress2: address2,
        addressCity: city,
        addressState: state,
        addressCountry: country,
        addressZip: zip,
        addressCounty:county,
        AddressUnit:unit,
        addressCounty: county,
      },
      description,
      voucherExpiryDate,
      companyId: companyId ? companyId.value : companyId,
      voucherTemplateId: voucherTemplateId.value,
      voucherCreatedById: getCurrentUserData()?.userId,
      SendVoucherRequest,
      VoucherApplicationUrl: path,
    };
    this.props.addUpdateVouchers(voucherId, payload, () => {
      window.location.replace(`${Routes.DASHBOARD}`);
    });
  };

  validateVoucherDetails = (e) => {
    let isError = false;

    //Validate our voucherDetailForm
    Object.keys(this.state.voucherDetailForm).forEach((key) => {
      const isErrorExist = this.validateField(
        key,
        this.state.voucherDetailForm[key].value
      );
      if (isErrorExist) {
        isError = true;
      }
    });

    if (!isError) {
      this.handleAddUpdateVoucherDetails({
        ...Object.keys(this.state.voucherDetailForm).reduce((retVal, key) => {
          retVal[key] = this.state.voucherDetailForm[key].value;
          return retVal;
        }, {}),
      });
    }
    const { voucherDetailForm } = this.state;
    getFocusInput(voucherDetailForm);
    e.preventDefault();
  };

  selectCountry = (val) => {
    const updatedValues = { ...this.state.voucherDetailForm };
    updatedValues.country.value = val;
    updatedValues.state.value = "";
    updatedValues.country.error = "";
    this.setState({ voucherDetailForm: updatedValues });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 38 && this.state.currentIndex !== 0) {
      this.setState({ currentIndex: this.state.currentIndex - 1 });
      e.preventDefault();
    } else if (
      e.keyCode === 40 &&
      this.state.currentIndex < this.state.predictions.length - 1
    ) {
      this.setState({ currentIndex: this.state.currentIndex + 1 });
      e.preventDefault();
    } else if (e.keyCode === 13 || e.keyCode === 9) {
      if (this.state.predictions.length > 0) {
        this.handleAddressSelected(e, this.state.currentIndex);
      }
      e.preventDefault();
    }
  };

  handleAddressSelected = (e, i) => {
    this.setState({
      isLoading: true,
      searchValue: this.state.predictions[i].description,
    });

    this.placesService.getDetails(
      {
        placeId: this.state.predictions[i].place_id,
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.setState({
            predictions: [],
            isLoading: false,
          });
          this.props.selectAddress(place);
          this.props.checkAddressCoverage();
          this.setAddressValue();
        } else {
          this.setState({
            predictions: [],
            isLoading: false,
          });
          this.props.selectAddress({});
          this.setAddressValue();
        }
      }
    );

    e.preventDefault();
  };

  handleSubmitRequest = (reqObj) => {
    this.isAddressFound = false;
    this.props.nonServiceableAreaRequest(reqObj);
    this.handleCloseModal();
    this.setState({
      isAddressConfirmationPopup: true,
    });
  };

  setAddressValue = () => {
    let voucherDetailForm = { ...this.state.voucherDetailForm };
    let selectedAddres = onboardingService.getLocal("selectedAddress") || {};
    voucherDetailForm.address1.value = selectedAddres.address1
      ? selectedAddres.address1
      : "";
    voucherDetailForm.address2.value = selectedAddres.address2
      ? selectedAddres.address2
      : "";
    voucherDetailForm.city.value = selectedAddres.address1
      ? selectedAddres.city
      : "";
    voucherDetailForm.state.value = selectedAddres.address1
      ? selectedAddres.state
      : "";
    voucherDetailForm.zip.value = selectedAddres.address1
      ? selectedAddres.zip
      : "";
    voucherDetailForm.county.value = selectedAddres.address1
      ? selectedAddres.county
      : "";
    voucherDetailForm.unit.value = selectedAddres.address1
      ? selectedAddres.unit
      : "";
    this.setState({ voucherDetailForm: { ...voucherDetailForm } });
  };

  handleAddressChanged = (e) => {
    this.setState({ searchValue: e.target.value });
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    this.timeout = window.setTimeout(this.searchAddresses, 500);
  };

  searchAddresses = () => {
    this.autoService.getPlacePredictions(
      {
        input: this.state.searchValue,
        types: ["address"],
        componentRestrictions: { country: "us" },
      },
      (predictions, status) => {
        if (status === "OK" && predictions.length > 0) {
          this.setState({ predictions: predictions });
        } else {
          this.setState({ predictions: [] });
        }
      }
    );
  };

  componentDidMount = (e) => {
    this.autoService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
  };
  selectStateAndCounty = (val) => {
    const updatedValues = { ...this.state.voucherDetailForm };
    updatedValues.state.value = val;
    updatedValues.state.error = "";
    this.setState({ voucherDetailForm: updatedValues });
  };

  handleSelectDate = (e, name) => {
    const updatedValues = { ...this.state.voucherDetailForm };
    updatedValues[name].value = e._d;
    updatedValues[name].error = null;
    this.setState({ voucherDetailForm: updatedValues });
  };

  handleSingleSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.voucherDetailForm };
    if (selectedOption) {
      updatedValues[type].error = "";
    } else {
      updatedValues[type].error = "*";
    }
    updatedValues[type].value = selectedOption;
    this.setState({ voucherDetailForm: updatedValues });
  };

  render() {
    const { voucherDetailForm, isAddressConfirmationPopup } = this.state;
    const {
      lookup,
      voucherDetail,
      isAddressNotServiceAble,
      selectedAddress,
    } = this.props;
    return (
      <React.Fragment>
        <Container>
          <HeadTextWrap className="add-edit-voucher-heading">
            <Heading>
              {this.isNewVoucher ? "Add Voucher" : "Edit Voucher"}
            </Heading>
          </HeadTextWrap>
          {!this.isNewVoucher && (
            <div className="voucher-deactive-copy-wrapper">
              <div className="voucher-deactive-copy-left">
                <span className="v-code">
                  <strong className="voucher-code-heading">
                    Voucher Code:-
                  </strong>{" "}
                  {`${voucherDetail?.voucherURL}`}
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      copyVoucherCode(this.props.voucherDetail?.voucherURL)
                    }
                    className="voucher-code-copy-icon"
                    title="Copy to clipboard"
                  >
                    <i className="fa fa-clone" aria-hidden="true"></i>
                  </a>
                </span>
                {voucherDetail?.voucherRedeemedBy && (
                  <span className="v-redeemed-by">
                    <strong>Redeemed By:-</strong>{" "}
                    {`${voucherDetail?.voucherRedeemedBy}`}
                  </span>
                )}
                {voucherDetail?.voucherRedemptionDate && (
                  <span className="v-redeemed-date">
                    <strong>Redeemed Date:-</strong>{" "}
                    {`${moment(voucherDetail?.voucherRedemptionDate).format(
                      dateFormat.DATE_WITH_YEAR
                    )}`}
                  </span>
                )}
              </div>
              <button
                className={`ant-btn btn btn-primary admin-primary-btn ${
                  voucherDetail?.isVoucherActive ? "delete-btn" : ""
                }`}
                id="edit-mode"
                onClick={() =>
                  statusChangeModalPopup(
                    voucherDetail,
                    wholesaleVoucherListingTableConfigs,
                    () => {
                      this.props.toggleStatus(
                        entityTypes.toggleVoucher,
                        voucherDetail?.id
                      );
                    }
                  )
                }
              >
                {voucherDetail?.isVoucherActive ? "Deactivate" : "Activate"}
              </button>
            </div>
          )}
          <DetailsWrapper
            className={`customer-voucher-form ${
              !this.isNewVoucher &&
              (!voucherDetail?.isVoucherActive ||
                voucherDetail?.voucherRedeemedBy)
                ? "disabled-input-form"
                : ""
            }`}
          >
            <form onSubmit={this.validateVoucherDetails} autoComplete="off">
              <RowFlex className="admin-input-with-select voucher-top-inputs voucher-added-checkbox">
                <InputFlex
                  name="name"
                  title="Voucher customer email"
                  value={voucherDetailForm.name.value}
                  error={voucherDetailForm.name.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                  className="inputgap"
                />

                <div className="admin-custom-checkbox voucher-customer-checkbox">
                  <label>
                    <input
                      name="SendVoucherRequest"
                      type="checkbox"
                      checked={this.state?.isEnabled}
                      onChange={(e) => {
                        this.setState({ isEnabled: !this.state.isEnabled });
                        this.handleCheckboxChanged(e);
                      }}
                    />
                    <span>(checked will send Voucher)</span>
                  </label>
                </div>

                <div className="pcol-6 for-custom-select bottom-gap">
                  <label>Voucher Template</label>
                  <Select
                    styles={cursorStyles}
                    name="voucherTemplateId"
                    placeholder="Select Voucher Template"
                    options={lookup?.voucherTemplates}
                    value={voucherDetailForm?.voucherTemplateId.value}
                    onChange={(e) =>
                      this.handleSingleSelectChange(e, "voucherTemplateId")
                    }
                    className={`admin-react-select ${
                      voucherDetailForm?.voucherTemplateId?.error
                        ? "admin-react-select-error"
                        : ""
                    }`}
                  />
                  {voucherDetailForm?.voucherTemplateId?.error && (
                    <span className="select-text-red">
                      {validationMessages.voucherRequired}
                    </span>
                  )}
                </div>
              </RowFlex>
              <RowFlex>
                <div className="pcol-6 for-custom-select bottom-gap">
                  <label>Voucher Expiry Date</label>
                  <DatePicker
                    defaultValue={
                      voucherDetailForm?.voucherExpiryDate.value
                        ? moment(voucherDetailForm?.voucherExpiryDate.value)
                        : null
                    }
                    onChange={(e) =>
                      this.handleSelectDate(e, "voucherExpiryDate")
                    }
                    format="YYYY-MM-DD"
                    name="voucherExpiryDate"
                    allowClear={false}
                    disabledDate={disablePastDate}
                    className={`admin-custom-date-picker`}
                  />
                </div>
                <div className="pcol-6 for-custom-select bottom-gap">
                  <label>Company</label>
                  <Select
                    styles={cursorStyles}
                    name="companyId"
                    placeholder="Select Company"
                    options={lookup?.companies}
                    value={voucherDetailForm?.companyId.value}
                    onChange={(e) =>
                      this.handleSingleSelectChange(e, "companyId")
                    }
                    className={`admin-react-select`}
                  />
                </div>
              </RowFlex>
              <CustomTextarea className="full-width-textarea">
                <label>Description</label>
                <textarea
                  name="description"
                  type="textarea"
                  value={voucherDetailForm.description?.value}
                  className={`expandable-textarea`}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </CustomTextarea>
              <div className="static-view-field">
                <RowFlex className="admin-input-with-select voucher-top-inputs">
                  <div className="inputgap">
                    <div className="pcol-12 for-custom-select bottom-gap static-input">
                      <label>Select Address</label>
                      <input
                        type="text"
                        name="address"
                        placeholder="Enter Your Property Address"
                        onChange={this.handleAddressChanged}
                        value={this.state.searchValue}
                        autoComplete="off"
                        onKeyDown={this.handleKeyDown}
                        className="testinput"
                      />
                       {voucherDetailForm?.address?.error && (
                        <span className="select-text-red">
                      {voucherDetailForm?.address?.error}
                    </span>
                  )}
                      {this.state.predictions.length > 0 && (
                        <Results>
                          {this.state.predictions.map((p, i) => (
                            <Result
                              key={i}
                              href="#"
                              isActive={this.state.currentIndex === i}
                              onClick={(e) => this.handleAddressSelected(e, i)}
                            >
                              {p.description}
                            </Result>
                          ))}
                        </Results>
                      )}
                    </div>
                  </div>
                  <InputFlex
                    name="unit"
                    title="Unit"
                    value={voucherDetailForm.unit.value}
                    error={voucherDetailForm.unit.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    className="inputgap"
                  />
                </RowFlex>
              </div>
              <RowFlex>
                <InputFlex
                  name="address1"
                  title="Address"
                  type="text"
                  value={voucherDetailForm.address1.value}
                  error={voucherDetailForm.address1.error}
                  readOnly={true}
                  autoComplete="off"
                  className={`inputgap ${disabled_input}`}
                />
                <InputFlex
                  name="address2"
                  title="Address 2"
                  value={voucherDetailForm.address2.value}
                  error={voucherDetailForm.address2.error}
                  className={`inputgap ${disabled_input}`}
                />
              </RowFlex>
              <RowFlex className="admin-input-with-select voucher-top-inputs">
                <InputFlex
                  name="city"
                  title="City"
                  value={voucherDetailForm.city.value}
                  error={voucherDetailForm.city.error}
                  className={`inputgap ${disabled_input}`}
                />
                <InputFlex
                  name="state"
                  title="State"
                  value={voucherDetailForm.state.value}
                  error={voucherDetailForm.state.error}
                  className={`inputgap ${disabled_input}`}
                />
              </RowFlex>

              <RowFlex className="admin-input-with-select voucher-top-inputs">
                <InputFlex
                  name="zip"
                  title="Zip"
                  value={voucherDetailForm.zip.value}
                  error={voucherDetailForm.zip.error}
                  className={`inputgap ${disabled_input}`}
                />
                <InputFlex
                  name="county"
                  title="County"
                  value={voucherDetailForm.county.value}
                  error={voucherDetailForm.county.error}
                  className={`inputgap ${disabled_input}`}
                />
              </RowFlex>

              

              <CustomButton
                type="submit"
                className="block"
                disabled={
                  voucherDetail?.voucherRedeemedBy ||
                  voucherDetail?.voucherRedemptionDate
                }
                onClick={this.validateVoucherDetails}
              >
                {this.isNewVoucher ? "Add" : "Update"}
              </CustomButton>
            </form>
          </DetailsWrapper>
        </Container>
        <ModalDiv
          visible={isAddressNotServiceAble}
          onOk={this.handleCloseModal}
          closable={false}
          maskClosable={false}
          footer={false}
          destroyOnClose={true}
        >
          <AddressNotFoundPopUp
            selectedAddress={selectedAddress.formatted}
            handleSubmitRequest={this.handleSubmitRequest}
            handleCloseModal={this.handleCloseModal}
          />
        </ModalDiv>
        <ModalDiv
          cancelButtonProps={{ style: { display: "none" } }}
          title="Address Not Serviceable Confirmation"
          visible={isAddressConfirmationPopup}
          onCancel={() => this.setState({ isAddressConfirmationPopup: false })}
          maskClosable={false}
          className="text-modal"
          onOk={() => this.setState({ isAddressConfirmationPopup: false })}
          okText="OK"
        >
          <p className="static-text">
            <span>
              Thank you for informing us that the address that you tried to
              create a listing for on USRealty.com is not currently serviceable
              by our system. We have recorded the address and one of our
              Customer Care Specialists will be in touch shortly to assist you
              further.
            </span>
            If you have any additional question or require immediate support,
            please{" "}
            <a
              href={Routes.CONTACT}
              onClick={() =>
                this.setState({ isAddressConfirmationPopup: false })
              }
            >
              contact us
            </a>{" "}
            at your convenience.
          </p>
        </ModalDiv>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const addressFromLocal = onboardingService.getLocal("selectedAddress");
  return {
    selectedAddress:
      (addressFromLocal && state.onboarding.selectedAddress) || {},
    isAddressNotServiceAble: state.onboarding.isAddressNotServiceAble,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...userActionCreators,
      ...adminActionCreators,
      ...alertActions,
      ...onboardingActionCreators,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditVoucher);
