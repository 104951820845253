import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import {toastsReducer as toasts} from 'react-toastify-redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { userReducer } from '../Reducers/UserReducer';
import { onboardingReducer } from '../Reducers/OnboardingReducer';
import { dashboardReducer } from '../Reducers/DashboardReducer';
import { adminReducer } from '../Reducers/AdminReducer';
import { adminOrdersReducer } from '../Reducers/V2/AdminOrdersReducer';
import { adminFormsReducer } from '../Reducers/V2/AdminFormsReducer';
import { toastAlert } from '../Reducers/ToastReducers';
import { UIReducer } from '../Reducers/UIReducer';
import { loader } from '../Reducers/LoaderReducer';
import { SellerNotificationReducer } from '../Reducers/SellerNotificationReducer';
import LogRocket from 'logrocket';
import { onboardingReducerV2 } from '../Reducers/OnboardingReducerV2';
import { lookupReducer } from '../Reducers/LookupReducer';
import { vouchersReducer } from '../Reducers/V2/VouchersReducer';
import { adminProductsReducer } from '../Reducers/V2/AdminProductsReducer';
import { adminPropertiesReducer } from '../Reducers/V2/AdminPropertiesReducer';
import { adminTitleCompaniesReducer } from '../Reducers/V2/AdminTitleCompaniesReducer';
import { cashOfferReducer } from '../Reducers/V2/CashOfferReducer';
import { buyerLeadsReducer } from '../Reducers/V2/BuyerLeadsReducer';


export default function configureStore(history, initialState) {
    const reducers = {
        user: userReducer,
        onboarding: onboardingReducer,
        dashboard: dashboardReducer,
        admin: adminReducer,
        adminOrders: adminOrdersReducer,
        adminProducts: adminProductsReducer,
        adminForms: adminFormsReducer,
        adminProperties: adminPropertiesReducer,
        adminTitleCompanies: adminTitleCompaniesReducer,
        vouchers: vouchersReducer,
        cashOffer: cashOfferReducer,
        toasts,
        toastAlert,
        ui: UIReducer,
        loader,
        sellerNotification: SellerNotificationReducer,
        propertyListing: onboardingReducerV2,
        lookup: lookupReducer,
        buyerLeads: buyerLeadsReducer
    };


    const middleware = [
        thunk,
        routerMiddleware(history),
        LogRocket.reduxMiddleware(),
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const appReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    const rootReducer = (state, action) => {
        if (action.type === 'LOGOUT_RESOLVED') {
            localStorage.clear();
            state = undefined;
        }
        return appReducer(state, action);
    };

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
