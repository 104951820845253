import React from 'react'

export const GridViewIcon = (iconProps) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.75">
        <path d="M7.24976 7.17267H2.38442C2.3184 7.16919 2.25225 7.16919 2.18623 7.17267H2.1773L2.25988 7.24038V2.27918C2.25988 2.21233 2.26524 2.1433 2.25988 2.07645C2.25966 2.07356 2.25966 2.07066 2.25988 2.06777L2.08134 2.16154H6.94668C7.0127 2.16501 7.07886 2.16501 7.14487 2.16154H7.15335L7.07211 2.09382V6.62702C7.07211 6.83668 7.06988 7.04678 7.07211 7.25688V7.26556C7.07211 7.32416 7.13684 7.33719 7.18727 7.33241C7.23191 7.32807 7.33234 7.29681 7.33234 7.23951V2.70632C7.33234 2.49665 7.33457 2.28655 7.33234 2.07645V2.06777C7.33174 2.05798 7.32909 2.04841 7.32456 2.03965C7.32002 2.03089 7.31369 2.02312 7.30596 2.01682C7.29823 2.01053 7.28926 2.00583 7.2796 2.00302C7.26993 2.00021 7.25978 1.99935 7.24976 2.00049H2.804C2.59867 2.00049 2.39379 2.00049 2.18846 2.00049H2.17954C2.13222 2.00049 2.00099 2.02306 2.00099 2.09382V6.62702C2.00099 6.83668 1.99876 7.04678 2.00099 7.25688V7.26556C2.00153 7.27533 2.00413 7.28489 2.00862 7.29365C2.0131 7.30241 2.01939 7.31018 2.02709 7.31649C2.03478 7.32279 2.04372 7.3275 2.05336 7.33031C2.063 7.33312 2.07313 7.33399 2.08312 7.33285H6.52934C6.73466 7.33285 6.93954 7.33285 7.14487 7.33285H7.15335C7.21316 7.33285 7.29172 7.31635 7.32341 7.26035C7.35511 7.20436 7.29931 7.17267 7.24976 7.17267Z" fill="#242B26" stroke="#242B26" strokeWidth="0.6" />
        <path d="M7.24976 13.8392H2.38442C2.3184 13.8357 2.25225 13.8357 2.18623 13.8392H2.1773L2.25988 13.9069V8.94568C2.25988 8.87883 2.26524 8.80981 2.25988 8.74296C2.25966 8.74007 2.25966 8.73717 2.25988 8.73428L2.08134 8.82804H6.94668C7.0127 8.83151 7.07886 8.83151 7.14487 8.82804H7.15335L7.07211 8.76032V13.2935C7.07211 13.5032 7.06988 13.7133 7.07211 13.9234V13.9321C7.07211 13.9907 7.13684 14.0037 7.18727 13.9989C7.23191 13.9946 7.33234 13.9633 7.33234 13.906V9.37282C7.33234 9.16316 7.33457 8.95306 7.33234 8.74296V8.73428C7.33174 8.72449 7.32909 8.71492 7.32456 8.70616C7.32002 8.6974 7.31369 8.68963 7.30596 8.68333C7.29823 8.67703 7.28926 8.67233 7.2796 8.66952C7.26993 8.66671 7.25978 8.66585 7.24976 8.66699H2.804C2.59867 8.66699 2.39379 8.66699 2.18846 8.66699H2.17954C2.13222 8.66699 2.00099 8.68957 2.00099 8.76032V13.2935C2.00099 13.5032 1.99876 13.7133 2.00099 13.9234V13.9321C2.00153 13.9418 2.00413 13.9514 2.00862 13.9602C2.0131 13.9689 2.01939 13.9767 2.02709 13.983C2.03478 13.9893 2.04372 13.994 2.05336 13.9968C2.063 13.9996 2.07313 14.0005 2.08312 13.9993H6.52934C6.73466 13.9993 6.93954 13.9993 7.14487 13.9993H7.15335C7.21316 13.9993 7.29172 13.9829 7.32341 13.9269C7.35511 13.8709 7.29931 13.8392 7.24976 13.8392Z" fill="#242B26" stroke="#242B26" strokeWidth="0.6" />
        <path d="M13.9158 7.17267H9.05043C8.98442 7.16919 8.91826 7.16919 8.85225 7.17267H8.84332L8.9259 7.24038V2.27918C8.9259 2.21233 8.93125 2.1433 8.9259 2.07645C8.92567 2.07356 8.92567 2.07066 8.9259 2.06777L8.74735 2.16154H13.6127C13.6787 2.16501 13.7449 2.16501 13.8109 2.16154H13.8194L13.7381 2.09382V6.62702C13.7381 6.83668 13.7359 7.04678 13.7381 7.25688V7.26556C13.7381 7.32416 13.8029 7.33719 13.8533 7.33241C13.8979 7.32807 13.9984 7.29681 13.9984 7.23951V2.70632C13.9984 2.49665 14.0006 2.28655 13.9984 2.07645V2.06777C13.9978 2.05798 13.9951 2.04841 13.9906 2.03965C13.986 2.03089 13.9797 2.02312 13.972 2.01682C13.9642 2.01053 13.9553 2.00583 13.9456 2.00302C13.9359 2.00021 13.9258 1.99935 13.9158 2.00049H9.47001C9.26469 2.00049 9.05981 2.00049 8.85448 2.00049H8.84555C8.79824 2.00049 8.66701 2.02306 8.66701 2.09382V6.62702C8.66701 6.83668 8.66478 7.04678 8.66701 7.25688V7.26556C8.66755 7.27533 8.67014 7.28489 8.67463 7.29365C8.67912 7.30241 8.6854 7.31018 8.6931 7.31649C8.7008 7.32279 8.70974 7.3275 8.71938 7.33031C8.72902 7.33312 8.73914 7.33399 8.74914 7.33285H13.1954C13.4007 7.33285 13.6056 7.33285 13.8109 7.33285H13.8194C13.8792 7.33285 13.9577 7.31635 13.9894 7.26035C14.0211 7.20436 13.9653 7.17267 13.9158 7.17267Z" fill="#242B26" stroke="#242B26" strokeWidth="0.6" />
        <path d="M13.9158 13.8392H9.05043C8.98442 13.8357 8.91826 13.8357 8.85225 13.8392H8.84332L8.9259 13.9069V8.94568C8.9259 8.87883 8.93125 8.80981 8.9259 8.74296C8.92567 8.74007 8.92567 8.73717 8.9259 8.73428L8.74735 8.82804H13.6127C13.6787 8.83151 13.7449 8.83151 13.8109 8.82804H13.8194L13.7381 8.76032V13.2935C13.7381 13.5032 13.7359 13.7133 13.7381 13.9234V13.9321C13.7381 13.9907 13.8029 14.0037 13.8533 13.9989C13.8979 13.9946 13.9984 13.9633 13.9984 13.906V9.37282C13.9984 9.16316 14.0006 8.95306 13.9984 8.74296V8.73428C13.9978 8.72449 13.9951 8.71492 13.9906 8.70616C13.986 8.6974 13.9797 8.68963 13.972 8.68333C13.9642 8.67703 13.9553 8.67233 13.9456 8.66952C13.9359 8.66671 13.9258 8.66585 13.9158 8.66699H9.47001C9.26469 8.66699 9.05981 8.66699 8.85448 8.66699H8.84555C8.79824 8.66699 8.66701 8.68957 8.66701 8.76032V13.2935C8.66701 13.5032 8.66478 13.7133 8.66701 13.9234V13.9321C8.66755 13.9418 8.67014 13.9514 8.67463 13.9602C8.67912 13.9689 8.6854 13.9767 8.6931 13.983C8.7008 13.9893 8.70974 13.994 8.71938 13.9968C8.72902 13.9996 8.73914 14.0005 8.74914 13.9993H13.1954C13.4007 13.9993 13.6056 13.9993 13.8109 13.9993H13.8194C13.8792 13.9993 13.9577 13.9829 13.9894 13.9269C14.0211 13.8709 13.9653 13.8392 13.9158 13.8392Z" fill="#242B26" stroke="#242B26" strokeWidth="0.6" />
      </g>
    </svg>

  )
};
