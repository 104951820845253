import React from 'react';
import styled from 'styled-components';
import { NoImage } from './Icons/NoImage';

const TitleCompanyInfoWrapper = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  padding: 0.75rem 1.25rem 1.75rem 1.25rem;
  margin-bottom: 1rem;
  width: ${({ dynamicWidth }) => dynamicWidth ? 'fit-content' : '100%'};
  max-width: 100%;
`;

const AdvertisementLabel = styled.div`
  text-align: right;
  padding-bottom: 0.75rem;
  font-size: 13px;
`;

const TitleCompanyInfo = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  display: flex;
  overflow: hidden;

  .title-logo {
    margin: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .title-company-details {
    padding: 5px;
    width: 50%;

    .title-company-details-line {
      margin: 10px;
      display: flex;
      flex-direction: column;

      .title-company-details-line-text {
        margin-top: 4px;
        font-size: 14px;
      }

      .title-company-details-line-label {
        color: #626262;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .title-logo {
      display: none;
    }
    .title-company-details {
      width: 100%;
    }
  }
`;

const TitleCompanyAdImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TitleCompanyLink = styled.a`
  width: 100%;
`;

const TitleCompanyCard = ({ titleCompany }) => (
  <>
    <TitleCompanyInfoWrapper dynamicWidth={titleCompany?.adFileUrl}>
      <AdvertisementLabel>Advertisement</AdvertisementLabel>
      <TitleCompanyInfo>
        {titleCompany?.adFileUrl && (
          <>
            {titleCompany?.adRedirectUrl ? (
              <TitleCompanyLink href={titleCompany?.adRedirectUrl} target="_blank" rel="noopener noreferrer">
                <TitleCompanyAdImage src={titleCompany?.adFileUrl} />
              </TitleCompanyLink>
            ) : (
              <TitleCompanyAdImage src={titleCompany?.adFileUrl} />
            )}
          </>
        )}

        {!titleCompany?.adFileUrl && (
          <>
            <div className="title-logo">
              {titleCompany?.coverPhotoUrl ? (
                <img src={titleCompany?.coverPhotoUrl} alt="title company logo" />
              ) : (
                <NoImage />
              )}
            </div>
            <div className="title-company-details">
              <div className="title-company-details-line">
                <span className="title-company-details-line-label">Company Name</span>
                <span className="title-company-details-line-text">{titleCompany.companyName}</span>
              </div>
              {titleCompany.address1 && (
                <div className="title-company-details-line">
                  <span className="title-company-details-line-label">Address</span>
                  <span className="title-company-details-line-text">{`${titleCompany.address1}, ${titleCompany.city}, ${titleCompany.stateCode}, ${titleCompany.zip}`}</span>
                </div>
              )}
              <div className="title-company-details-line">
                <span className="title-company-details-line-label">Phone Number</span>
                <span className="title-company-details-line-text">{titleCompany.phoneNmber}</span>
              </div>
              <div className="title-company-details-line">
                <span className="title-company-details-line-label">Title Officer</span>
                <span className="title-company-details-line-text">{`${titleCompany.officerFirstName} ${titleCompany.officerLastName}`}</span>
              </div>
              <div className="title-company-details-line">
                <span className="title-company-details-line-label">Website</span>
                {titleCompany.website && (
                  <span className="title-company-details-line-text">
                    <a href={titleCompany.website} target="_blank" rel="noopener noreferrer">
                      {titleCompany.website}
                    </a>
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </TitleCompanyInfo>
    </TitleCompanyInfoWrapper>
    {titleCompany?.adYouTubeUrl && (
    <TitleCompanyInfoWrapper dynamicWidth={true}>
      <AdvertisementLabel>Advertisement</AdvertisementLabel>
      <TitleCompanyInfo dangerouslySetInnerHTML={{
        __html: titleCompany.adYouTubeUrl }}>
      </TitleCompanyInfo>
    </TitleCompanyInfoWrapper>
    )}
  </>
);

export default TitleCompanyCard;
