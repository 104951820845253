import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';
import ApiUrls from '../../Constants/ApiUrls';

class BuyerLeadsService {
  getBuyerLeads(params) {
    const query = new URLSearchParams(params).toString();
    const url = `${ApiUrls.buyerLeads}?${query}`;

    return fetch(url, {
        method: apiMethodType.GET,
        headers: {
          ...authHeader(),
            'Content-Type': contentType.applicationJson,
    }});

    
  }
  shareBuyerInformation(data) {
    const url = `${ApiUrls.buyerLeads}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
}

export const buyerLeadsService = new BuyerLeadsService();
