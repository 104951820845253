import v8n from 'v8n';
import moment from 'moment';
import { Modal } from 'antd';
import copyPostUrl from 'copy-to-clipboard';
import { userService } from '../Services/UserService';
import { adminActionCreators } from '../Actions/AdminActions';
import { alertActions } from '../Actions/ToastActions';
import { store } from '../index';
import Enums from '../Constants/Enums';
import {
  validationMessages,
  passwordType,
  regexPattern,
  alphaNumericChar,
  entityTypes,
  roleType,
  processorNotAllowedPaths,
  staticMessages,
  Roles,
  phoneMask,
} from '../Constants/CommonConstants';
import { history } from './History';
import Routes from '../Constants/Routes';
import ApiUrls from '../Constants/ApiUrls';
import multiDownload from 'multi-download';

const { ZERO, THREE } = Enums.NUMERIC_VALUE;

export const redirectOnDashboard = (swithcLocation) => history.push({ 
  pathname: `${Routes.DASHBOARD}`,
  state: { swithcLocation: swithcLocation }
});
export const redirectOnUserEditForm = (listingId) => window.open(`${Routes.EDIT_LISTING.replace(':listingId', listingId)}/?step=${THREE}`);
export const getLocalUserData = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const getCurrentUserData = () => {
  const userData = JSON.parse(localStorage.getItem('user'));
  return userData?.isAdminSwitchUser ? userData.switchedUser : userData || {};
};

export const setAdminData = (swithcLocation,userId) => {
 const userDetail = JSON.parse(localStorage.getItem('user'));
  delete userDetail.switchedUser;
  userDetail.isAdminSwitchUser = false;
  userService.setLocalUser(userDetail);
  userService.setSwitchlocation('');
  if(swithcLocation && swithcLocation!==undefined){
    history.push(swithcLocation);
  }else{
    history.push(`${Routes.ADMIN_MLS_SUBMISSION.replace()}`);
  }
};

export const getRandomString = length => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i = i + 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const validatePasswordFields = (key, value, userDataInfo) => {
  let userData = { ...userDataInfo };
  let isOldPasswordError = false;
  let isNewPasswordError = false;
  let isConfirmPasswordError = false;

  if (
    key === passwordType.oldPassword &&
    value.length < Enums.NUMERIC_VALUE.EIGHT
  ) {
    isOldPasswordError = true;
    userData[passwordType.oldPassword].error = validationMessages.password;
  }

  if (key === passwordType.newPassword && value.length) {
    isNewPasswordError = !regexPattern.PASSWORD.test(value);
    userData[passwordType.newPassword].error = isNewPasswordError
      ? validationMessages.validPassword
      : null;
  }

  if (userData.newPassword.value && !userData.confirmPassword.value) {
    isConfirmPasswordError = true;
    userData[passwordType.confirmPassword].error = validationMessages.password;
  }

  if (userData.confirmPassword.value) {
    isConfirmPasswordError =
      userData.newPassword.value !== userData.confirmPassword.value;
    userData[passwordType.confirmPassword].error = isConfirmPasswordError
      ? validationMessages.passwordNotMatch
      : null;
    if (!userData.oldPassword.value) {
      isOldPasswordError = true;
      userData[passwordType.oldPassword].error = validationMessages.password;
    }
  }

  if (userData.oldPassword.value && !userData.newPassword.value) {
    isNewPasswordError = true;
    userData[passwordType.newPassword].error = validationMessages.password;
  }

  if (
    !userData.oldPassword.value &&
    !userData.newPassword.value &&
    !userData.confirmPassword.value
  ) {
    userData[passwordType.oldPassword].error = null;
    userData[passwordType.newPassword].error = null;
    userData[passwordType.confirmPassword].error = null;
    isOldPasswordError = false;
    isNewPasswordError = false;
    isConfirmPasswordError = false;
  }
  return isOldPasswordError || isNewPasswordError || isConfirmPasswordError;
};

export const intialContactUsEmptyState = () => ({
  firstName: {
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    value: '',
  },
  lastName: {
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    value: '',
  },
  email: {
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
      {
        rule: v8n().pattern(regexPattern.EMAIL),
        message: validationMessages.validEmail,
      },
    ],
    value: '',
  },
  message: {
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    value: '',
  },
});

export const smoothScrollTop = (positionFromTop = 0) =>
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });

export const intialRequestMoreEmptyState = () => ({
  fullName: {
    title: 'Full Name',
    name: 'fullName',
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    value: '',
  },
  phoneNumber: {
    title: 'Phone Number',
    name: 'phoneNumber',
    rules: [],
    mask: phoneMask,
    value: '',
  },
  email: {
    title: 'Email',
    name: 'email',
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
      {
        rule: v8n().pattern(regexPattern.EMAIL),
        message: validationMessages.validEmail,
      },
    ],
    value: '',
  },
  comments: {
    title: 'Comments',
    name: 'comments',
    rules: [],
    value: '',
  },
});

export const intialGlobalSummaryState = () => ({
  firstName: {
    title: 'First Name',
    name: 'firstName',
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    value: '',
  },
  lastName: {
    title: 'Last Name',
    name: 'lastName',
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    value: '',
  },
  email: {
    title: 'Email',
    name: 'email',
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
      {
        rule: v8n().pattern(regexPattern.EMAIL),
        message: validationMessages.validEmail,
      },
    ],
    value: '',
  },
  phoneNumber: {
    title: 'Phone Number',
    name: 'phoneNumber',
    rules: [],
    mask: phoneMask,
    value: '',
  },
  jobTitle: {
    title: 'Job Title',
    name: 'jobTitle',
    rules: [],
    value: '',
  },
});

export const handleFormData = (form, action) => {
  let formData = form;
  formData.groups.map(group => {
    group.sections = group.sections.map(section => {
      section.fields = section.fields.map(field => {
        if (field.formFieldId !== Number(action.payload.formFieldId)) {
          return field;
        }

        field.value = action.payload.value;

        return { ...field };
      });

      return { ...section };
    });

    return { ...group };
  });

  return formData;
};

export const goBack = () => {
  let pathToRedirect = null;
  let isFromSwitch = false;
  if(history.length < 2 && (getLocalUserData()?.role === roleType.ADMIN || getLocalUserData()?.role === roleType.LISTING_PROCESSOR)) {
    isFromSwitch = true;
    const pathName = history.location.pathname;
    const entityArray = pathName.split('/');
    switch (entityArray[Enums.NUMERIC_VALUE.TWO]) {
      case entityTypes.user:
        pathToRedirect = Routes.ADMIN_USERS;
        break;
      case entityTypes.property:
        pathToRedirect = Routes.ADMIN_PROPERTY;
        break;
      case entityTypes.addAbbreviation:
        pathToRedirect = Routes.ADMIN_MLS_ABBREVIATION;
        break;
      case entityTypes.form:
        pathToRedirect = Routes.ADMIN_LISTINGS;
        break;
      case entityTypes.category:
        pathToRedirect = Routes.ADMIN_MLS_CATEGORIES;
        break;
      case entityTypes.coverage:
        pathToRedirect = Routes.ADMIN_COVERAGE;
        break;
      case entityTypes.products:
        pathToRedirect = Routes.ADMIN_PRODUCTS;
        break;
      case entityTypes.company:
        pathToRedirect = Routes.ADMIN_COMPANY;
        break;
      case entityTypes.template:
        pathToRedirect = Routes.ADMIN_VOUCHER_TEMPLATE_LISTING;
        break;
      case entityTypes.titleCompany:
        pathToRedirect = Routes.ADMIN_TITLE_COMPANY;
        break;
      default:
        return Routes.ADMIN_MLS_SUBMISSION;
    }
  }
  return isFromSwitch ? history.push(pathToRedirect) : history.goBack();
}

export const addNewListing = (selectPlan, selectAddress) => {
  selectPlan({});
  selectAddress({});
  history.push(Routes.ONBOARDING_V2);
};

export const downloadCompletedForm = (pathName, identityId, isAdmin, formTypeName) => {
  let pdfUrl = '';
  const pathArr = pathName.split(',');
  let pdfUrlArr=[];
  pathArr.forEach(path => {
    if(isAdmin) {
      const entityArray = path.split('/');
      pdfUrl = `${process.env.REACT_APP_REMOTE_SERVICE_BASE_URL}/api/Dashboard/Document/${entityArray[Enums.NUMERIC_VALUE.ONE]}?identityId=${entityArray[Enums.NUMERIC_VALUE.ZERO]}`;
    } else {
      pdfUrl = `${process.env.REACT_APP_REMOTE_SERVICE_BASE_URL}/api/Dashboard/Document/${path}?identityId=${identityId}`;
    }
    pdfUrlArr.push(pdfUrl);
  })
  if (formTypeName){
    pdfUrl = `${process.env.REACT_APP_REMOTE_SERVICE_BASE_URL}/api/admin/MlsSubmission/Document/${formTypeName}?path=${pathName}`;
    window.open(pdfUrl);
  }
  else if(pdfUrlArr.length>0){
     multiDownload(pdfUrlArr);
  }
};


export const checkIsEmpty = str => {
  return !str || !regexPattern.STRING_CHECK.test(str);
};

export const generateRandomPassword = passwordLength => {
  const allChars =
    alphaNumericChar.numberChars +
    alphaNumericChar.upperChars +
    alphaNumericChar.lowerChars;
  let randPasswordArray = Array(passwordLength);
  randPasswordArray[0] = alphaNumericChar.numberChars;
  randPasswordArray[1] = alphaNumericChar.upperChars;
  randPasswordArray[2] = alphaNumericChar.specialChars;
  randPasswordArray[3] = alphaNumericChar.lowerChars;
  randPasswordArray[4] = alphaNumericChar.specialChars;
  randPasswordArray = randPasswordArray.fill(allChars, 5);
  return shuffleArray(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }),
  ).join('');
};

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export const getCustomEntityPath = entity => {
  let apiEntityPath = '';
  switch (entity) {
    case entityTypes.categories:
      apiEntityPath = `${ApiUrls.apiData}mlscategory`;
      break;
    case entityTypes.form:
    case entityTypes.listings:
    case entityTypes.landing:
    case entityTypes.mlsPreview:
      apiEntityPath = `${ApiUrls.apiData}forms`;
      break;
    case entityTypes.abbreviation:
    case entityTypes.addAbbreviation:
      apiEntityPath = `${ApiUrls.apiData}mls`;
      break;
    case entityTypes.orders:
      apiEntityPath = `${ApiUrls.apiData}mls/orders`;
      break;
    case entityTypes.property:
      apiEntityPath = `${ApiUrls.apiData}properties`;
      break;
    case entityTypes.company:
      apiEntityPath = `${ApiUrls.apiData}company`;
      break;
    case entityTypes.coverage:
    case entityTypes.mlsCoverage:
      apiEntityPath = `${ApiUrls.apiData}mls/coverage`;
      break;
    case entityTypes.template:
    case entityTypes.voucherTemplate:
      apiEntityPath = `${ApiUrls.apiData}voucher/template`;
      break;
    case entityTypes.voucherListing:
      apiEntityPath = `${ApiUrls.apiData}voucher`;
      break;
    case entityTypes.addVoucher:
      apiEntityPath = `${ApiUrls.voucherLookup}${getCurrentUserData().userId}`;
      break;
      case entityTypes.resendInvite:
        apiEntityPath = `${ApiUrls.resendInvite}`;
        break;
    case entityTypes.toggleVoucher:
      apiEntityPath = `${ApiUrls.addNewVoucher}`;
      break;
    case entityTypes.voucher:
      apiEntityPath = `${ApiUrls.voucherLookup}${getCurrentUserData().userId}`;
      break;
    case entityTypes.dashboardCoverage:
      apiEntityPath = `${ApiUrls.addNewCoverage}`;
      break;
    default:
      apiEntityPath = `${ApiUrls.apiData}${entity}`;
      break;
  }
  return apiEntityPath;
};

export const getCustomLookupPath = entity => {
  let apiLookupPath = '';
  switch (entity) {
    case entityTypes.form:
    case entityTypes.company:
    case entityTypes.listings:
    case entityTypes.property:
    case entityTypes.template:
    case entityTypes.landing:
    case entityTypes.addVoucher:
    case entityTypes.overlayForms:
      apiLookupPath = `${ApiUrls.addLookupPath}`;
      break;
    case entityTypes.coverage:
    case entityTypes.mlsCoverage:
    case 'MlsSubmission':
    case entityTypes.dashboardCoverage:
      apiLookupPath = `${ApiUrls.lookup}`;
      break;
    default:
      apiLookupPath = `${ApiUrls.lookupPath}`;
      break;
  }
  return apiLookupPath;
};

export const editPageAction = (item, entity, isClone) => {
  let redirectPath = '';
  switch (entity) {
    case entityTypes.titleCompany:
      redirectPath = `${Routes.ADMIN_EDIT_TITLE_COMPANY.replace(
        ':id?',
        item.id,
      )}`;
      break;
    case entityTypes.users:
      redirectPath = `${Routes.ADMIN_ADD_EDIT_USER.replace(
        ':userId',
        item.userId || item.voucherCreatedById || item.voucherRedeemedById || item.value,
      )}`;
      break;
    case entityTypes.categories:
      redirectPath = `${Routes.ADD_EDIT_MLS_CATEGORIES.replace(
        ':mlsId',
        item.mlsCategoryId || item.value,
      )}`;
      break;
    case entityTypes.abbreviation:
      redirectPath = `${Routes.ADMIN_ADD_EDIT_MLS_ABBREVIATION.replace(
        ':mlsId',
        item.mlsId || item.value,
      )}`;
      break;
    case entityTypes.form:
    case entityTypes.listings:
      const isbasicForm = item.sectionTypeId === Enums.NUMERIC_VALUE.TWO;
      const path = isClone
        ? Routes.ADMIN_CLONE_FORM
        : Routes.ADMIN_ADD_EDIT_FORM;
      redirectPath = isbasicForm
        ? `${path.replace(':formId', item.formGroupId)}?formSectionId=${
            item.formGroupSectionId
          }`
        : `${path.replace(':formId', item.formGroupId)}`;
      break;
    case entityTypes.products:
      redirectPath = `${Routes.ADMIN_ADD_EDIT_PRODUCT.replace(
        ':productId',
        item.id,
      )}`;
      break;
    case entityTypes.properties:
      redirectPath = `${Routes.ADD_EDIT_PROPERTY.replace(
        ':propertyId',
        item.id || item.listingId,
      )}`;
      break;
    case entityTypes.voucherProperties:
      redirectPath = `${Routes.ADD_EDIT_PROPERTY.replace(
        ':propertyId',
        item.listingId,
      )}`;
      break;  
    case entityTypes.company:
      redirectPath = `${Routes.ADD_EDIT_COMPANY.replace(
        ':companyId',
        item.companyId,
      )}`;
      break;
    case entityTypes.mlsCoverage:
      redirectPath = `${Routes.ADD_EDIT_COVERAGE.replace(
        ':mlsLocationId',
        item.mlsLocationId,
      )}`;
      break;
    case entityTypes.template:
      redirectPath = `${Routes.ADD_EDIT_VOUCHER_TEMPLATE.replace(
        ':templateId',
        item.id || item.voucherTemplateId,
      )}`;
      break;
    case entityTypes.landing:
      const isSectionTypeTwo = item.sectionTypeId === Enums.NUMERIC_VALUE.TWO;
      const urlPath = Routes.ADMIN_ADD_EDIT_FORM;
      redirectPath = isSectionTypeTwo
        ? window.open(`${urlPath.replace(':formId', item.formGroupId)}?formSectionId=${
            item.formGroupSectionId
          }`)
        : window.open(`${urlPath.replace(':formId', item.formGroupId)}`);
      break;
    case entityTypes.addVoucher:
      redirectPath = `${Routes.ADD_EDIT_VOUCHER.replace(
        ':voucherId',
        item.id,
      )}`;
      break;
    default:
      break;
  }
  return redirectPath;
};

export const getDeleteItemId = (item, entity) => {
  let idToDelete = '';
  switch (entity) {
    case entityTypes.users:
      idToDelete = item.userId;
      break;
    case entityTypes.categories:
      idToDelete = item.mlsCategoryId || item.value;
      break;
    case entityTypes.abbreviation:
      idToDelete = item.mlsId || item.value;
      break;
    case entityTypes.listings:
      idToDelete = item.formGroupSectionId;
      break;
    case entityTypes.products:
      idToDelete = `${Routes.ADMIN_PRODUCTS}`;
      break;
    case entityTypes.properties:
      idToDelete = item.id;
      break;
    case entityTypes.company:
      idToDelete = item.companyId;
      break;
    default:
      break;
  }
  return idToDelete;
};

export const intialBasicFormEmptyState = () => ({
  fieldType: {
    rules: [],
    value: '',
  },
  fieldName: {
    rules: [],
    value: '',
  },
  isRequired: {
    rules: [],
    value: '',
  },
  isLocked: {
    rules: [],
    value: '',
  },
  isLinked: {
    rules: [],
    value: '',
  },
  fieldSize: {
    rules: [],
    value: '',
  },
  preFilled: {
    rules: [],
    value: '',
  },
});

export const getYear = () => new Date().getFullYear();
export const momentDateFormat = (date, format) => (date && moment(date).format(format)) || '';

export const addHttpInUrl = url => {
  if (!regexPattern.ADD_HTTP.test(url)) {
    url = `http://${url}`;
  }
  return url;
};

export const getFocusInput = data => {
  const errorField = Object.entries(data).filter(([, value]) => value?.error)?.[
    ZERO
  ];
  
  if (errorField && errorField.length) {
    document.getElementsByName(errorField[ZERO])[ZERO].focus();
  }
};


export const deleteModalPopup = (tableConfigs, onDelete) => {
  Modal.confirm({
    title: tableConfigs.deleteTitle,
    content: tableConfigs.deleteMessage,
    onOk() {
      onDelete();
    },
    onCancel() {},
  });
};

export const statusChangeModalPopup = (selectedItem, tableConfigs, onToggle) => {
  const isActive = selectedItem.isActive || selectedItem?.isVoucherActive || selectedItem?.isEnabled;
  Modal.confirm({
    title: isActive
      ? `Deactivate ${tableConfigs.deleteTitle}`
      : `Activate ${tableConfigs.deleteTitle}`,
    content: isActive
      ? tableConfigs.deactivateMessage
      : tableConfigs.activateMessage,
    onOk() {
      onToggle();
    },
    onCancel() {},
  });
};

export const getUserRole = () => {
  const userData = getLocalUserData();
  return userData?.isAdminSwitchUser
    ? userData.switchedUser.role
    : userData?.role;
};

export const isListingProcessorRole = () => {
  const userRole = getUserRole();
  return userRole === roleType.LISTING_PROCESSOR;
}

export const isListingProcessorAccess = (activeEnitiy) => {

  return isListingProcessorRole() && processorNotAllowedPaths.indexOf(activeEnitiy) > -1;
}

export const voucherStatusUpdate = (entity, selectedItem) => {
  store.dispatch(adminActionCreators.toggleVoucherStatus(entity, selectedItem?.id ));
};
export const voucherResend = (entity,selectedItem,isVoucherActive) => {
  if(isVoucherActive){
    store.dispatch(adminActionCreators.resendVoucherInvite(entity,selectedItem));
  }
};

export const updateLoggedInUser = (e, loggedInUsers, mlsId) => {
  const { value } = e.target;
  if(value) {
    const userId = loggedInUsers.find(user => user.value === value)?.key || null;
    store.dispatch(adminActionCreators.updateLoggedInUser(entityTypes.abbreviation, {mlsId, userId}));
  }
};

export const copyVoucherCode = (selectedItem) => {
  copyPostUrl(selectedItem);
  store.dispatch(alertActions.successAlert(staticMessages.copyLinkSuccess));
};

export const checkIsValidNumber = (value) => {
  return v8n().pattern(regexPattern.NUMBER_ONLY).test(value);
}

export const checkIsValidFloatNumber = (value) => {
  return v8n().pattern(regexPattern.NUMBER_ONLY_WITH_DECIMAL).test(value);
}

export function checkResponse (data = {}, successMessage = '') {
  if (data?.hasOwnProperty('success')) {
    if (data?.success && (data?.message || successMessage)) {
      store.dispatch(alertActions.successAlert(data?.message || successMessage));
    } 
    if (!data.success) {
      // eslint-disable-next-line no-throw-literal
      throw { response: { data: { message: data?.message } } };
    }
  }
}

export const getRedirectLink = () => {
  if (window.location.pathname.includes(Routes.ONBOARDING_V2)) {
    const params = new URLSearchParams(window.location.search);
    const search = params.toString().length ? '&new_user=true' : '?new_user=true';
    return window.location.href + search;
  }
  
  if (window.location.pathname.includes('unbounce/signup')) {
    return window.location.origin + Routes.UNBOUNCE_LISTING + '?new_user=true';
  }

  return window.location.origin + '?new_user=true';
}

export const getLoginRedirectPath = (userType) => {
  if (userType === Roles.SUPER_ADMINISTRATOR
      || userType === Roles.ADMINISTRATOR
      || userType === Roles.OFFICE_MANAGER
      || userType === Roles.LISTING_PROCESSOR) {
      return  Routes.ADMIN_MLS_SUBMISSION;
  }

  return Routes.DASHBOARD;
}

export const removeNullUndefined = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

export const dateStringToUTC = (dateStr) => {
  if(!dateStr){
    return dateStr;
  }

  return moment(dateStr).utc();
}

export const utcDateStringToLocal = (dateStr) => {
  if(!dateStr){
    return dateStr;
  }

  return moment.utc(dateStr).local();
}

export function convertPhone(phone) {
  return [...phone].filter(ch => !'()- '.includes(ch)).join('');
};