import React from 'react';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import Select, { createFilter } from 'react-select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { statesSelector, propertyTypesSelector } from '../../../../../Reducers/LookupReducer';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { cursorStyles, reactSelectFilters } from '../../../../../Constants/CommonConstants';
import AnnotatedInput from '../../../../Shared/Components/AnnotatedInput';
import { buyerLeadsCreators } from '../../../../../Actions/V2/Admin/BuyerLeadsActions';
import { buyerLeadsFiltersSelector } from '../../../../../Reducers/V2/BuyerLeadsReducer';

const { RangePicker } = DatePicker;

const RangePickerWrapper = styled.div`
  .ant-calendar-picker {
    margin-right: 10px;
  }
  .ant-input {
    height: 40px;
    display: inline-flex;
  }
  .ant-calendar-range-picker-separator {
    padding-top: 5px;
    color: #808080;
  }
  .ant-calendar-range-picker-input::placeholder {
    color: #808080;
  }
`;

export const InputFlex = styled(AnnotatedInput)`
  ${(props) => `
    flex: ${props.flex ? props.flex : '.5'};
    input {
        padding: 0 10px;
        height: 45px;
        border-radius: 6px;
        font-size: 15px;
    }
    `}
`;

const ByuerLeadsFilters = () => {
    const dispatch = useDispatch();
    const filters = useSelector(buyerLeadsFiltersSelector);

    const propertyTypes = useSelector(propertyTypesSelector);
    const states = useSelector(statesSelector);

    const statesOptions = states.map((x) => ({ value: x.id, label: x.name }));
    const propertyTypesOptions = propertyTypes.map((x) => ({ value: x.id, label: x.name }));

    useEffect(() => {
        dispatch(lookupActionsCreators.getPropertyTypes());
        dispatch(lookupActionsCreators.getStates());
    }, []);

    const handleChange = (value) => dispatch(buyerLeadsCreators.setbuyerLeadsFilters(value));

    const applyFilters = () => dispatch(buyerLeadsCreators.getbuyerLeads(filters));

    const clearFilters = () => {
        dispatch(buyerLeadsCreators.clearPropertiesFilters());
    };

    return (
        <div className={`admin-filter-wrapper more-filter`}>
            <div className={`filter-bar`}>
                <div className="filter-bar-left">
                    <div className="filter-dropdown for-custom-select">
                        <label>Filter by Property Type</label>
                        <Select
                            styles={cursorStyles}
                            placeholder="Select Property Type"
                            value={propertyTypesOptions.find((x) => x.value === filters.PropertyTypeId) || null}
                            onChange={(e) => handleChange({ ...filters, PropertyTypeId: e.value })}
                            options={propertyTypesOptions}
                            menuPosition="fixed"
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className="admin-filter-react-select"
                        />
                    </div>
                    <div className="filter-dropdown for-custom-select">
                        <label>Filter by State</label>
                        <Select
                            styles={cursorStyles}
                            placeholder="Select State"
                            value={statesOptions.find((x) => x.value === filters.state) || null}
                            onChange={(e) => handleChange({ ...filters, state: e.value })}
                            options={statesOptions}
                            menuPosition="fixed"
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className="admin-filter-react-select"
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <RangePickerWrapper className="for-custom-select">
                        <label>Filter by Created Date</label>
                        <RangePicker
                            ranges={{
                                Today: [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            format="YYYY/MM/DD"
                            value={
                                filters.dateCreatedFrom && filters.dateCreatedTo
                                    ? [moment(filters.dateCreatedFrom), moment(filters.dateCreatedTo)]
                                    : null
                            }
                            onChange={(dates) =>
                                handleChange({
                                    ...filters,
                                    dateCreatedFrom: dates[0].format('YYYY/MM/DD'),
                                    dateCreatedTo: dates[1].format('YYYY/MM/DD'),
                                })
                            }
                        />
                    </RangePickerWrapper>
                </div>
                <div className="product-group search-buttons">
                    <Button className="btn btn-primary admin-primary-btn small-btn" onClick={() => applyFilters()}>
                        Apply Filters
                    </Button>
                    <Button className="btn btn-primary admin-primary-btn small-btn" onClick={() => clearFilters()}>
                        Reset Filters
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ByuerLeadsFilters;
