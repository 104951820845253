import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout,DatePicker } from 'antd';
import CurrencyInput from 'react-currency-input-field';
import { FormSection, Form } from '../../../Assets/Style/commonStyleComponents';
import { adminActionCreators } from '../../../Actions/AdminActions';
import enums from './../../../Constants/Enums';
import AdminHeader from '../Components/AdminHeader';
import { Container } from '../../../Assets/Style/commonStyleComponents';
import Enums from '../../../Constants/Enums';
import styled from "styled-components";
import moment from "moment";
const FormDatePicker = styled.div`
  display: inline;
  padding-left: 3px;
  input {
    border-radius: 0;
    border: 1px solid #B698D9;
    font-size: 15px;
    &:focus {
      box-shadow: none;
    }
  }
`;


function disabledDate(current) {
  return current && current < moment().add(-1, "days");
}


class PreviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state={
      targetValues:{},
      numberText:'',
      decemialText:'',
      checkedOnOFF:[],
    }
  }
  componentDidMount() {
    if (this.props.match?.params?.sectionId) {
      this.props.getOverlayFormDetail(
        this.props.activeEntity,
        Number(this.props.match.params.sectionId),
      );
    }
  }

  handleFormFieldChanged = (e, field,inputDataType) => {
    const re = /^[0-9\b]+$/;
    if(inputDataType===3 && (e.target.value === '' || re.test(e.target.value))) {
      this.setState({numberText:e.target.value});
    }
    if(inputDataType===4 && (e.target.value === '' || Number(e.target.value))) {
      this.setState({decemialText:e.target.value});
    }
  }

  handleFormCheckboxField = (e, field, fieldId) => {
    let nArray=this.state.checkedOnOFF;
     if(e.target.checked){
       
       nArray.push(fieldId);
       this.setState({checkedOnOFF:nArray})
     }else{
      const index = nArray.indexOf(fieldId);
      if (index > -1) {
        nArray.splice(index, 1);
      }
      this.setState({checkedOnOFF:nArray})
     }
  };
  getFieldControl = (node, fields) => {
    if(fields){
      fields.sort(function(a,b){
        return parseInt(b.inputDataType) - parseInt(a.inputDataType);
      })
    }

    return (
      <div className='image-with-form'>
        <img src={node} alt="" />
        {fields.map((field, index) => {
          if(this.state.numberText){
            field.prefillText='';
          }
          if(this.state.decemialText){
            field.prefillText='';
          }
          if(field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN && field.targetFields){
            let obj=[]
            let optionValue=field.targetFields.replace(/\u21b5|\r\n|\n/g, "@#$").split("@#$");
            
             Object.keys(optionValue).forEach((key) => {
               let isCheckd=false;
               if(this.state.checkedOnOFF.indexOf(field.formFieldId) > -1){
                isCheckd=true;
               }
               obj[key]={'isCheckd':isCheckd,'displayName':optionValue[key]}
               
             })

             this.state.targetValues[field.formFieldId]=obj;
           }

           let targetClass='';

                   
           if(Object.keys(this.state.targetValues).length>0){
 
             Object.keys(this.state.targetValues).forEach((Statekey) => {
             let singleArr=this.state.targetValues[Statekey];
              Object.keys(singleArr).forEach((key) => {
              if(singleArr[key]['displayName']===field.displayName && (singleArr[key]['isCheckd']=='true' || singleArr[key]['isCheckd']===true)){
                   field.readOnly=false;
               }
                 if(singleArr[key]['displayName']===field.displayName && (singleArr[key]['isCheckd']=='false' || singleArr[key]['isCheckd']===false)){
                   targetClass='disabled-input';
                   field.value='';
                   field.readOnly=true;
                 }
               })
             })
            }
          if (field.readOnly) {
            return (
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + 'px',
                    width: field.overlayWidth + 'px',
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type='text'
                  defaultValue={field.prefillText}
                  className='disabled-input'
                />
              </div>
            );
          }

          if (field.options) {
            return (
              <>
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >
                <select
                  style={{
                    height: field.overlayHeight + 'px',
                    width: field.overlayWidth + 'px',
                  }}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  defaultValue={field.value || ''}
                >
                  <option key={Math.random()} disabled value=''>
                    {' '}
                    Select Value{' '}
                  </option>
                  {field.options
                    .replace(/\u21b5|\r\n|\n/g, '@#$')
                    .split('@#$')
                    .map(o => (
                      <option
                        key={`${field.formFieldId}_${index}_${o}`}
                        value={o}
                      >
                        {o}
                      </option>
                    ))}
                </select>
              </div>
               {
                field.required &&
                <label style={{
                  right: -192 + "px",
                  top: field.overlayStartY + "px",
                }} className="additional-label">Required</label>
                } 
                </>
            );
          }

          if (
            !field.readOnly &&
            field.inputDataType === Enums.NUMERIC_VALUE.ONE
          ) {
            return (
              <>
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type='text'
                  name={field.formFieldId}
                  defaultValue={field.value || field?.prefillText || ''}
                  required={field.required && !field.value}
                  ref={field.displayName}
                  className={targetClass}
                />
              </div>
              {
                field.required &&
                <label style={{
                  right: -192 + "px",
                  top: field.overlayStartY + "px",
                }} className="additional-label">Required</label>
                } 
              </>
            );
          }

          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.SEVEN)
          ) { 
            return (
              <>
             <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <CurrencyInput
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  decimalsLimit={2}
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  defaultValue={field?.value?field.value:field.prefillText || ''}
                  name={field.formFieldId}
                  required={field.required && !field.value}
                  ref={field.formFieldId}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.THREE)
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId,field.inputDataType)}
                  value={this.state.numberText || field?.prefillText || ''}
                  required={field.required && !field.value}
                  ref={field.displayName}
                  className={targetClass}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.FOUR)
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId,field.inputDataType)}
                  value={this.state.decemialText || field?.prefillText || ''}
                  required={field.required && !field.value}
                  ref={field.displayName}
                  className={targetClass}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (
            !field.readOnly &&
            field.inputDataType === Enums.NUMERIC_VALUE.TWO
          ) { 
            return (
              <>
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >

              <textarea
               style={{
                height: (field.overlayHeight+field.overlayHeight) + 'px',
                width: field.overlayWidth + 'px',
              }}
              key={`${field.formFieldId}_${index}`}
              type='text'
              name={field.formFieldId}
              defaultValue={field.value? field.value:field.prefillText || ''}
              ref={field.displayName}
              className={targetClass}
            />
                
              </div>
              {
                field.required &&
                <label style={{
                  right: -192 + "px",
                  top: field.overlayStartY + "px",
                }} className="additional-label">Required</label>
                } 
              </>
            );
          }

          if (field.inputDataType === Enums.NUMERIC_VALUE.FIVE) {
            return (
              <>
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + 'px',
                    width: field.overlayWidth + 'px',
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type='checkbox'
                  name={field.formFieldId}
                  defaultChecked={field.value}
                />
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="checkbox"
                  name={field.formFieldId}
                  onChange={(e) =>this.handleFormCheckboxField(
                      e,
                      field.displayName,
                      field.formFieldId
                    )
                  }
                  defaultChecked={field?.value ? JSON.parse(field.value) : false}
                />
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.EIGHT) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                }}
              >
               <FormDatePicker>
              {field.value ? (
                <DatePicker
                  size="small"
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  defaultValue={moment(field.value)}
                  ref={field.formFieldId}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                />
              ) : (
                <DatePicker
                  size="small"
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  ref={field.formFieldId}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                />
              )}
            </FormDatePicker>
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
        })}
      </div>
    );
  };
  render() {
    const { overlayFormDetail } = this.props;
    return (
      <React.Fragment>
        <Layout.Header
          style={{ background: '#fff', minHeight: 52, padding: 0 }}
        >
          <AdminHeader />
        </Layout.Header>
        <Container>
          <Form>
            <div className='mls-static-text'>
              <h1>MLS Overlay Form</h1>
            </div>
            {overlayFormDetail?.fields && (
              <FormSection>
                {this.getFieldControl(
                  overlayFormDetail.content,
                  overlayFormDetail.fields,
                )}
              </FormSection>
            )}
          </Form>
        </Container>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[enums.NUMERIC_VALUE.TWO],
    overlayFormDetail: state.admin.overlayFormDetail || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviewContainer),
);
