import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';

class DraftListingService {

  getDraftListings(params) {
    const query = new URLSearchParams(params).toString();
    const url = `api/v2/draftlistings?${query}`;

    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  assignOrder(orderId, data) {
    const url = `api/v2/draftlistings/${orderId}/officeusers`;

    return fetch(url, {
      method: apiMethodType.PUT,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      },
      data,
    });
  }

  archiveOrder(draftlistingId, data) {
    const url = `api/v2/draftlistings/${draftlistingId}/archive`;

    return fetch(url, {
      method: apiMethodType.PUT,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      },
      data,
    });
  }

  getAverageTimeToContact() {
    const url = 'api/v2/draftlistings/avg-handling-time';

    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });

  }

  markAsContacted(data, draftlistingId) {
    const url = `api/v2/draftlistings/${draftlistingId}/contacted`;

    return fetch(url, {
      method: apiMethodType.PUT,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      },
      data,
    });
  }

  generateDescription(draftlistingId) {
    const url = `api/v2/draftlistings/${draftlistingId}/description/generate`;
  
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    })
  }

  getLisitngProgress(draftlistingId) {
    const url = `api/v2/draftlistings/${draftlistingId}/progress`;
  
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    })
  }
}

export const draftListingService = new DraftListingService();
