import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { map } from 'lodash';
import v8n from 'v8n';
import md5 from 'md5';
import { readAndCompressImage } from 'browser-image-resizer';
import { adminActionCreators } from '../../../../Actions/AdminActions';
import {
  RowFlex,
  InputFlex,
  ModalDiv,
} from '../../../../Assets/Style/commonStyleComponents';
import { validationMessages } from '../../../../Constants/CommonConstants';
import Enums from '../../../../Constants/Enums';
import { goBack, getFocusInput, isListingProcessorRole, checkIsValidNumber } from '../../../../Utilities/commonFunctions';
import { Button } from 'antd';
import AddEditBasicForm from './AddEditBasicForm';
import AddOverlayPages from './AddOverlayPages';
import { cursorStyles } from '../../../../Constants/CommonConstants';

const intialBasicFormEmptyState = {
  'inputDataTypeId-0': 1,
  'displayName-0': '',
  'required-0': false,
  'readOnly-0': false,
  'isLinked-0': false,
  'formPrefillTypeId-0': '',
  'options-0': '',
  'errors-0': {},
  'formFieldId-0': 0,
  'shortCode-0': '',
  'priority-0': null,
};

function rearrangeFilledBasicFileds(basicFormFields) {
  const fieldData = [];
  map(basicFormFields.fields, (item, index) => {
    if (item) {
      fieldData.push({
        [`inputDataTypeId-${index}`]: item.inputDataTypeId,
        [`displayName-${index}`]: item.displayName,
        [`required-${index}`]: item.required,
        [`readOnly-${index}`]: item.readOnly,
        [`isLinked-${index}`]: item.isLinked,
        [`formPrefillTypeId-${index}`]: item.formPrefillTypeId,
        [`PrefillText-${index}`]: item.prefillText,
        [`options-${index}`]: item.options || '',
        [`errors-${index}`]: {},
        [`formFieldId-${index}`]: item.formFieldId,
        [`shortCode-${index}`]: item.shortCode,
        [`optionTypeId-${index}`]: item.optionTypeId,
        [`priority-${index}`]: item.priority,
      });
    }
  });
  return fieldData;
}

const reactSelectErrorClass = 'admin-react-select-error';

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.isNewForm = props.isNewForm;
    this.fieldData = [];
    if (props.selectedFormDetails.sections) {
      const basicFormFields = props.selectedFormDetails.sections[0];
      this.fieldData = rearrangeFilledBasicFileds(basicFormFields);
    }
    this.state = {
      isFormUpdateConfirmPopup: false,
      isBasicFormType: false,
      isOverlayEdit:false,
      overlayEditIndex:0,
      updatedFormObj: {},
      errors: {},
      contentUrl:'',
      formDetails: {
        name: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.selectedFormDetails?.name || '',
        },
        priority: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.selectedFormDetails?.priority || '',
        },
        states: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.stateRequired,
            },
          ],
          value: this.props.selectedFormDetails.states || '',
        },
        mls: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.mlsInfoRequired,
            },
          ],
          value: props?.selectedFormDetails?.mls || '',
        },
        mlsCategories: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.categoryRequired,
            },
          ],
          value: props?.selectedFormDetails?.mlsCategories || '',
        },
        formTypeId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.formTypeRequired,
            },
          ],
          value: props?.selectedFormDetails?.formTypeId
            ? {
                value: props.selectedFormDetails.formTypeId,
                label: props.selectedFormDetails.formType,
              }
            : {
                value: '1',
                label: 'MLS Listing Contract',
              },
        },
        companies: {
          rules: [],
          value: props?.selectedFormDetails.companies || '',
        },
        content: {
          rules: [],
          value: props?.selectedFormDetails.content || '',
          label: 'Form Content',
        },
        sectionTypeId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.sectionLayoutRequired,
            },
          ],
          value: props?.selectedFormDetails?.sectionTypeId
            ? {
                value: props.selectedFormDetails.sectionTypeId,
                label:
                  props.selectedFormDetails.sectionTypeId ===
                  Enums.NUMERIC_VALUE.TWO
                    ? 'Basic Form'
                    : 'Fields Overlaid on Image',
              }
            : '',
        },
      },
      basicFormFileds: props.isNewForm
        ? [intialBasicFormEmptyState]
        : this.fieldData,
      overlayFormFields: props?.selectedFormDetails?.sections
        ? [...props?.selectedFormDetails?.sections]
        : [],
    };
  }

  handleFieldChanged = e => {
    let formDetails = { ...this.state.formDetails };
    formDetails[e.target.name].value = e.target.value;
    this.setState({ formDetails: { ...formDetails } });
  };

  handleFieldBlur = e => {
    this.validateBasicFormField(e.target.name, e.target.value);
  };

  validateBasicFormField = (key, value) => {
    let formDetails = { ...this.state.formDetails };
    let isError = false;
    for (let i = 0; i < formDetails[key].rules.length; i++) {
      let rule = formDetails[key].rules[i];
      if (!rule.rule.test(value)) {
        formDetails[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (formDetails['priority'].value && !checkIsValidNumber(formDetails['priority'].value)) {
      formDetails['priority'].error = validationMessages.numericValueRequired;
      isError = true;
    }

    if (!isError) {
      formDetails[key].error = null;
    }

    this.setState({
      formDetails: { ...formDetails },
      isError: Object.keys(formDetails).some(
        k =>
          formDetails[k].error !== undefined && formDetails[k].error !== null,
      ),
    });

    return isError;
  };

  handleEditorChange = content => {
    const formDetails = { ...this.state.formDetails };
    formDetails['content'].value = content;
    this.setState({ formDetails: { ...formDetails } });
  };

  handleAddUpdateFormDetails = objDetails => {
    const paramsValue = new URLSearchParams(window.location.search);
    objDetails['formTypeId'] = objDetails.formTypeId?.value;
    objDetails['sectionTypeId'] = objDetails.sectionTypeId?.value;
    const formGroupSectionId = this.props.isNewForm
      ? Enums.NUMERIC_VALUE.ZERO
      : paramsValue.get('formSectionId');
    objDetails['formGroupSectionId'] = formGroupSectionId;
    this.props.addUpdateBasicForm(formGroupSectionId, objDetails, cb => {
      if (parseInt(this.props.match?.params?.formId) < 1) {
        this.props.history.replace({
          pathname: `/admin/form/1?formSectionId=${cb.formGroupSectionId}`,
        });
        this.isNewForm = false;
        
      }
      const fieldData = rearrangeFilledBasicFileds(cb.sections[0]);
      this.setState({
        basicFormFileds: fieldData,
      });
     // window.location.reload();
    });
  };

  handleAddUpdateOverlayFormDetails = objDetails => {
    objDetails['formTypeId'] = objDetails.formTypeId?.value;
    objDetails['sectionTypeId'] = objDetails.sectionTypeId?.value;
    const formGroupSectionId = this.isNewForm
      ? Enums.NUMERIC_VALUE.ZERO
      : this.props.selectedFormDetails.formGroupId;
    objDetails['formGroupId'] = formGroupSectionId;
    const { overlayFormFields } = this.state;
    let fields = JSON.parse(JSON.stringify(overlayFormFields));
    for (let key in fields) {
      fields[key].priority =
        parseInt(objDetails.priority) + (parseInt(key) + 1) * 10;
      delete fields[key].overlayImage?.thumbnailUrl;
      if(fields[key]['overlayImage']){
        fields[key]['overlayImage'].IsImageUpdated=false;
      }
    }

    fields = fields.filter(x => x.overlayImage?.path);

    if(this.state.isOverlayEdit){
      const BucketUrl=fields[this.state.overlayEditIndex]['overlayImage'].path;
      const newStr = BucketUrl.replace("listing-photos", "https://photos.usrealty.com");
      const pathName=newStr.split('.').slice(0, -1).join('.');
      const ext =  newStr.split('.').pop();
      const contentImage=pathName+'_thumb.'+ext;
      fields[this.state.overlayEditIndex]['content']=contentImage;
      fields[this.state.overlayEditIndex]['overlayImage'].IsImageUpdated=true;
      
    }
    objDetails['sections'] = fields;
    this.props.addUpdateBasicForm(formGroupSectionId, objDetails, cb => {
      if (parseInt(this.props.match?.params?.formId) < 1) {
        this.props.history.replace({
          pathname: `/admin/form/${cb.formGroupId}`,
        });
        this.isNewForm = false;
      }
    });
  };

  validateAndUpdateFormDetails = e => {
    e.preventDefault();
    const { id } = e.target;
    let isError = false;
    let isPasswordError = false;

    Object.keys(this.state.formDetails).forEach(keyName => {
      const isErrorExist = this.validateBasicFormField(
        keyName,
        this.state.formDetails[keyName].value,
      );
      if (isErrorExist) {
        isError = true;
      }
    });
    if (!isError && !isPasswordError) {
      const { sectionTypeId } = this.state.formDetails;
      const objectDetail = {
        ...Object.keys(this.state.formDetails).reduce((retVal, key) => {
          if (this.state.formDetails[key].value) {
            retVal[key] = this.state.formDetails[key].value;
          }
          return retVal;
        }, {}),
      };
      if (sectionTypeId.value.value === Enums.NUMERIC_VALUE.TWO) {
        if (!this.checkValidationForBasicForm()) {
          objectDetail['sections'] = [
            { fields: this.removeIndexFromArr(this.state.basicFormFileds) },
          ];
          objectDetail['publishStatusId'] = id;
          if (this.isNewForm) {
            this.handleAddUpdateFormDetails(objectDetail);
          } else {
            this.setState({
              isFormUpdateConfirmPopup: true,
              updatedFormObj: objectDetail,
              isBasicFormType: true,
            })
          }
        }
      } else if (sectionTypeId.value.value === Enums.NUMERIC_VALUE.THREE) {
        objectDetail['publishStatusId'] = id;
        if (this.isNewForm) {
          this.handleAddUpdateOverlayFormDetails(objectDetail);
        } else {
          this.setState({
            updatedFormObj: objectDetail,
            isFormUpdateConfirmPopup: true,
            isBasicFormType: false,
          })
        }
      }
    }

    const { formDetails } = this.state;
    getFocusInput(formDetails);
  };

  deleteFromoverlay = (index) => {
    const overlayField = [...this.state.overlayFormFields];
    overlayField[index] = {
      overlayImage: {
        overlayImageId: 0,
        bucket: '',
        path: '',
        priority: 0,
      },
      priority: 0,
      fields: [
        {
          formFieldId: 0,
          inputDataTypeId: 1,
          required: true,
          readOnly: true,
          displayName: '',
          description: '',
          formPrefillTypeId: 1,
          options: '',
          overlayStartX: 0,
          overlayStartY: 0,
          overlayWidth: 0,
          overlayHeight: 0,
          value: '',
        },
      ],
    };

    this.setState({ overlayFormFields: overlayField });
  }

  handleFormUpdatePopup = () => {
    const { updatedFormObj, isBasicFormType } = this.state;
    if(isBasicFormType) {
      this.handleAddUpdateFormDetails(updatedFormObj);
    } else {
      
      this.handleAddUpdateOverlayFormDetails(updatedFormObj);
    }
    this.setState({
      isFormUpdateConfirmPopup: false
    })
  }

  checkValidationForBasicForm = () => {
    const updatedValues = [...this.state.basicFormFileds];
    let isError = false;
    updatedValues.forEach((obj, index) => {
      if (!obj[`displayName-${index}`]) {
        obj[`errors-${index}`][`displayName-${index}`] = true;
        isError = true;
      }
      if (
        Number(obj[`inputDataTypeId-${index}`]) ===
          Enums.NUMERIC_VALUE.THIRTEEN &&
        !obj[`options-${index}`]
      ) {
        obj[`errors-${index}`][`options-${index}`] = true;
        isError = true;
      }
    });
    this.setState({
      basicFormFileds: updatedValues,
    });
    return isError;
  };

  handleMultiSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.formDetails };
    if (selectedOption && selectedOption.length) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = selectedOption;
    this.setState({ formDetails: updatedValues });
  };

  handleSingleSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.formDetails };
    if (selectedOption) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = selectedOption;
    this.setState({ formDetails: updatedValues });
  };

  reIntializeBasicFormFields = listArr => {
    const { length } = listArr;
    const upatedArr = [];
    for (let i = 0; i < length; i += 1) {
      const obj = listArr[i];
      const updatedObj = {};
      Object.keys(obj).forEach(key => {
        const splitValue = key.split('-')[0];
        const updateKey = splitValue.replace(splitValue, `${splitValue}-${i}`);
        updatedObj[updateKey] = obj[key];
      });
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  };

  removeIndexFromArr = reqArr => {
    const { length } = reqArr;
    const upatedArr = [];
    for (let i = 0; i < length; i += 1) {
      const obj = reqArr[i];
      const updatedObj = {};
      Object.keys(obj).forEach(key => {
        const updateKey = key.split('-')[0];
        updatedObj[updateKey] = obj[key];
      });
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  };

  handleAddRow = () => {
    const values = [...this.state.basicFormFileds];
    const addFieldsLength = values.length;
    values.push({
      [`inputDataTypeId-${addFieldsLength}`]: 1,
      [`displayName-${addFieldsLength}`]: '',
      [`required-${addFieldsLength}`]: false,
      [`readOnly-${addFieldsLength}`]: false,
      [`isLinked-${addFieldsLength}`]: false,
      [`formPrefillTypeId-${addFieldsLength}`]: '',
      [`options-${addFieldsLength}`]: '',
      [`errors-${addFieldsLength}`]: {},
      [`formFieldId-${addFieldsLength}`]: 0,
      [`shortCode-${addFieldsLength}`]: '',
      [`optionTypeId-${addFieldsLength}`]: null,
      
    });
    this.setState({
      basicFormFileds: values,
    });
  };
  handleOverlayAddRow = () => {
    const values = [...this.state.overlayFormFields];
    values.push({
      overlayImage: {
        overlayImageId: 0,
        bucket: '',
        path: '',
        priority: 0,
      },
      priority: 0,
      fields: [
        {
          formFieldId: 0,
          inputDataTypeId: 1,
          required: true,
          readOnly: true,
          displayName: '',
          description: '',
          formPrefillTypeId: 1,
          options: '',
          overlayStartX: 0,
          overlayStartY: 0,
          overlayWidth: 0,
          overlayHeight: 0,
          value: '',
        },
      ],
    });
    this.setState({
      overlayFormFields: values,
    });
  };

  handleRemoveRow = idx => {
    const basicFormFileds = [...this.state.basicFormFileds];
    basicFormFileds.splice(idx, 1);
    const updatedValues = this.reIntializeBasicFormFields(basicFormFileds);
    this.setState({ basicFormFileds: updatedValues });
  };
  handleOverlayRemoveRow = idx => {
    const overlayFormFileds = [...this.state.overlayFormFields];
    overlayFormFileds.splice(idx, 1);
    this.setState({ overlayFormFields: overlayFormFileds });
  };

  handleBasicFormFields = (idx, e, isCheckbox) => {
    const { name, value, checked } = e.target;
    
    const basicFormFileds = [...this.state.basicFormFileds];
    if(name===`inputDataTypeId-${idx}` && value==='5'){
      basicFormFileds[idx][`optionTypeId-${idx}`]=Enums.NUMERIC_VALUE.ONE;
    }
    basicFormFileds[idx][`${name}`] = isCheckbox ? checked : value;
    basicFormFileds[idx][`errors-${idx}`][`${name}`] = false;
    this.setState({ basicFormFileds: basicFormFileds });
  };

  handleOnBlurBasicFormFields = (idx, e) => {
    const { name, value } = e.target;
    const basicFormFileds = [...this.state.basicFormFileds];
    if (value && name === `displayName-${idx}`) {
      basicFormFileds.forEach((item, index) => {
        if (
          idx !== index &&
          item[`displayName-${index}`].toLowerCase() === value.toLowerCase()
        ) {
          basicFormFileds[idx][`errors-${idx}`][`${name}`] = true;
          basicFormFileds[idx][`errors-${idx}`][`duplicateName`] = true;
        }
        if (
          idx !== index &&
          item[`displayName-${index}`].toLowerCase() !== value.toLowerCase()
        ) {
          basicFormFileds[idx][`errors-${idx}`][`duplicateName`] = false;
        }
      });
    }
    this.setState({ basicFormFileds: basicFormFileds });
  };

  getFileBase64 = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  createThumbnail = async photo => {
    const config = {
      quality: 0.8,
      maxWidth: 300,
      maxHeight: 300,
      autoRotate: true,
      debug: false,
    };

    return await readAndCompressImage(photo, config);
  };
  async processPhoto(file, index) {
    const thumb = await this.createThumbnail(file);
    const checksum = await md5(await this.getFileBase64(thumb));
    const photo = {
      file: file,
      order: index,
      thumbnail: thumb,
      listingPhotoId: -1,
      absoluteUrl: URL.createObjectURL(file),
      thumbnailUrl: URL.createObjectURL(thumb),
      md5: checksum,
    };
    this.props.uploadS3Photo(photo);
  }
  fileProcessHandler = (files, index) => {
    for (let key in files) {
      this.processPhoto(files[key], index);
    }
  };

  handleOnChangeFile = (e, index) => {
    const files = [...e.target.files];
    this.fileProcessHandler(files, index);
  };

  handleOnChangeModalFile = (e, index) => {
    const values = [...this.state.overlayFormFields];
    values[index] = {
      formGroupSectionId: values[index]['formGroupSectionId'],
      name: values[index]['name'],
      includeInResult: values[index]['includeInResult'],
      content: '',
      overlayImage: {
        overlayImageId: values[index]['overlayImage'].overlayImageId,
        bucket: '',
        path: '',
        priority: 0,
      },
      fields: [],
    };
    this.setState({
      overlayFormFields: values,
    });
    this.setState({
      isOverlayEdit: true,
    });
    this.setState({
      overlayEditIndex: index,
    });


    const files = [...e.target.files];
    this.fileProcessHandler(files, index);
  };
  

  componentDidUpdate(prevProps) {
    const { uploadedFormFileDetail, selectedFormDetails } = this.props;
     if (
      prevProps.uploadedFormFileDetail !== uploadedFormFileDetail &&
      uploadedFormFileDetail.thumbnailUrl
    ) {
      const newForm = [...this.state.overlayFormFields];
      
      const data = newForm[uploadedFormFileDetail.priority].overlayImage;
      data.bucket = uploadedFormFileDetail?.bucket || '';
      data.path = uploadedFormFileDetail?.path || '';
      data.thumbnailUrl = uploadedFormFileDetail?.thumbnailUrl || '';
      this.setState({ overlayFormFields: [...newForm] });
     
      
    }
    if (
      prevProps.selectedFormDetails !== selectedFormDetails &&
      selectedFormDetails?.sections?.length
    ) {
      this.setState({ overlayFormFields: selectedFormDetails.sections });
    }
  }

  render() {
    const { lookup, uploadFormFileProgress, isFormFileUploading, selectedFormDetails, cloneAction, isFetching } = this.props;
    const { formDetails, basicFormFileds, overlayFormFields, isFormUpdateConfirmPopup } = this.state;
    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>{this.isNewForm ? 'Add Form' : 'Edit Form'}</h2>
            <div className="admin-heading-wrapper-right">
              {(this.isNewForm || selectedFormDetails?.publishStatusId !== Enums.NUMERIC_VALUE.ONE) &&
                <button
                  id={Enums.NUMERIC_VALUE.ONE}
                  className="ant-btn btn btn-primary admin-primary-btn"
                  onClick={this.validateAndUpdateFormDetails}
                  disabled={isListingProcessorRole() || isFormFileUploading}
                >
                  Save Draft
                </button>
              }
              {(this.isNewForm || selectedFormDetails?.publishStatusId !== Enums.NUMERIC_VALUE.TWO) &&
                <button
                  id={Enums.NUMERIC_VALUE.TWO}
                  className="ant-btn btn btn-primary admin-primary-btn"
                  onClick={this.validateAndUpdateFormDetails}
                  disabled={isListingProcessorRole() || isFormFileUploading}
                >
                  Pending Review
                </button>
              }
              {!this.isNewForm &&
                <>
                  <button
                    className="ant-btn btn btn-primary admin-primary-btn"
                    onClick={() => cloneAction()}
                    disabled={isListingProcessorRole()}
                  >
                    Clone
                  </button>
                 
                </>
              }
            </div>
          </div>
          <div className={`admin-add-form-wrapper ${isListingProcessorRole() ? 'disabled-input-form' : ''}`}>
            <form onSubmit={this.handleSaveAccountDetails} autoComplete='off'>
              <InputFlex
                name='name'
                title='Form Title'
                className='inputgap'
                placeholder='Enter Title Here'
                value={this.state?.formDetails?.name?.value}
                error={this.state?.formDetails?.name?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>State(s)</label>
                  <Select
                    styles={cursorStyles}
                    name='states'
                    placeholder='Select States'
                    options={lookup?.states}
                    value={formDetails?.states.value}
                    onChange={e => this.handleMultiSelectChange(e, 'states')}
                    className={`admin-react-select ${
                      formDetails?.states?.error
                        ? reactSelectErrorClass
                        : ''
                    }`}
                    isMulti
                  />
                  {formDetails?.states?.error && (
                    <span className='select-text-red'>
                      {validationMessages.stateRequired}
                    </span>
                  )}
                </div>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>MLS Info(s)</label>
                  <Select
                    styles={cursorStyles}
                    name='mls'
                    placeholder='Select Mls Info'
                    options={lookup?.mlsAbbreviations}
                    value={formDetails?.mls.value}
                    onChange={e => this.handleMultiSelectChange(e, 'mls')}
                    className={`admin-react-select ${
                      formDetails?.mls?.error ? reactSelectErrorClass : ''
                    }`}
                    isMulti
                  />
                  {formDetails?.mls?.error && (
                    <span clForm ContentassName='select-text-red'>
                      {validationMessages.mlsInfoRequired}
                    </span>
                  )}
                </div>
              </RowFlex>
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>MLS Category</label>
                  <Select
                    styles={cursorStyles}
                    name='mlsCategories'
                    placeholder='Select Category'
                    options={lookup?.mlsCategories}
                    value={formDetails?.mlsCategories.value}
                    onChange={e =>
                      this.handleMultiSelectChange(e, 'mlsCategories')
                    }
                    className={`admin-react-select ${
                      formDetails?.mlsCategories?.error
                        ? reactSelectErrorClass
                        : ''
                    }`}
                    isMulti
                  />
                  {formDetails?.mlsCategories?.error && (
                    <span className='select-text-red'>
                      {validationMessages.categoryRequired}
                    </span>
                  )}
                </div>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Form Type</label>
                  <Select
                    styles={cursorStyles}
                    name='formTypeId'
                    placeholder='Select Form Type'
                    options={lookup?.formTypes}
                    value={formDetails?.formTypeId.value}
                    onChange={e =>
                      this.handleSingleSelectChange(e, 'formTypeId')
                    }
                    className='admin-react-select'
                  />
                  {formDetails?.formTypeId?.error && (
                    <span className='select-text-red'>
                      {validationMessages.formTypeRequired}
                    </span>
                  )}
                </div>
              </RowFlex>
              <RowFlex className='admin-input-with-select'>
                <InputFlex
                  name='priority'
                  title='Select Order Position'
                  className='inputgap'
                  type='text'
                  value={formDetails?.priority?.value}
                  error={formDetails?.priority?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>companies</label>
                  <Select
                    styles={cursorStyles}
                    name='companies'
                    placeholder='Select companies'
                    options={lookup?.companies}
                    value={formDetails?.companies.value}
                    onChange={e => this.handleMultiSelectChange(e, 'companies')}
                    className='admin-react-select'
                    isMulti
                  />
                </div>
              </RowFlex>
              <div className='for-custom-select bottom-gap'>
                <label>Select Layout Type</label>
                <Select
                  styles={cursorStyles}
                  name='sectionTypeId'
                  placeholder='Select Layout'
                  options={lookup?.layoutType}
                  value={formDetails?.sectionTypeId.value}
                  onChange={e =>
                    this.handleSingleSelectChange(e, 'sectionTypeId')
                  }
                  className={`admin-react-select ${
                    formDetails?.sectionTypeId?.error
                      ? reactSelectErrorClass
                      : ''
                  } ${!this.isNewForm ? 'disabled-input' : ''}`}
                />
                {formDetails?.sectionTypeId?.error && (
                  <span className='select-text-red'>
                    {validationMessages.sectionLayoutRequired}
                  </span>
                )}
              </div>
              {formDetails?.sectionTypeId.value.value ===
                Enums.NUMERIC_VALUE.TWO && (
                <AddEditBasicForm
                  lookup={lookup}
                  handleBasicFormFields={this.handleBasicFormFields}
                  basicFormFiledTable={basicFormFileds}
                  handleAddRow={this.handleAddRow}
                  handleRemoveRow={this.handleRemoveRow}
                  textEditorData={formDetails}
                  handleEditorChange={this.handleEditorChange}
                  handleOnBlurBasicFormFields={this.handleOnBlurBasicFormFields}
                  selectedFormDetails={selectedFormDetails}
                />
              )}
              {!isFetching && formDetails?.sectionTypeId.value.value ===
                Enums.NUMERIC_VALUE.THREE && (
                <AddOverlayPages
                  deleteFromoverlay={this.deleteFromoverlay}
                  overlayFormFields={overlayFormFields}
                  handleAddRow={this.handleOverlayAddRow}
                  handleRemoveRow={this.handleOverlayRemoveRow}
                  onChangeFile={this.handleOnChangeFile}
                  onChangeModalFile={this.handleOnChangeModalFile}
                  uploadFormFileProgress={uploadFormFileProgress}
                  isFormFileUploading={isFormFileUploading}
                  isOverlayEdit={this.state.isOverlayEdit}
                />
              )}
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    id={3}
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    onClick={this.validateAndUpdateFormDetails}
                    disabled={isListingProcessorRole() || isFormFileUploading}
                  >
                    {(this.isNewForm || selectedFormDetails?.publishStatusId !== Enums.NUMERIC_VALUE.THREE) ? 'Publish' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ModalDiv
          title="Edit Form Confirmation"
          visible={isFormUpdateConfirmPopup}
          onCancel={() => this.setState({ isFormUpdateConfirmPopup: false })}
          onOk={() => this.handleFormUpdatePopup()}
          okText="Yes"
          cancelText="No"
          closable={false}
          destroyOnClose={true}
          className="confirm-modal"
        >
          <p className="static-text">Are you sure you want save changes?</p>
        </ModalDiv>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
 return {
    isFetching: state.admin.isFetching,
    uploadFormFileProgress: state.admin.uploadFormFileProgress,
    isFormFileUploading: state.admin.isFormFileUploading,
    uploadedFormFileDetail: state.admin.uploadedFormFileDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEditForm),
);
