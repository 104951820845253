import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../Constants/CommonConstants';

class TrackingService {
  constructor() {
    let urls = {
      base: ''
    };
    urls.base = urls.base || "/";
    this.urls = urls;
  }

  track(data) {
    const url = `/api/track`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...data },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
}

export const trackingService = new TrackingService();
