import axios from 'axios';
import AppConsts from '../Constants/AppConsts';

const apiInstance = axios.create({
    baseURL: AppConsts.remoteServiceBaseUrl,
    timeout: AppConsts.apiClientTimeout,
  });

apiInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.message !== 'canceled') {
    const user = JSON.parse(localStorage.getItem("user"));
    axios.post(`${AppConsts.remoteServiceBaseUrl}/api/logs`,
    {
      url: window.location.href,
      apiUrl: error?.response?.config?.url,
      payload: error?.response?.config?.data,
      method: error?.response?.config?.method,
      userId: user?.userId
    });
  }
    return Promise.reject(error);
});


export default function (url, options) {
    return apiInstance({ url, ...options });
}
