import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingActionCreators } from "../../../Actions/OnboardingActions";
import { onboardingService } from '../../../Services/OnboardingService';
import { history } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import PlanSelector from '../../CustomerPortal/Components/PlanSelector';
import { defaultPlanAddress } from '../../../Constants/CommonConstants';
import { Container } from '../../../Assets/Style/commonStyleComponents';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

class PlanPricing extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedAddress: onboardingService.getLocal('selectedAddress') || {},
    }
  }

  componentDidMount() {
    const { selectedAddress } = this.state;
    const isSelectedAddressValue = Object.keys(selectedAddress).length;
    const reqObj = {
      county: isSelectedAddressValue ? selectedAddress.county : defaultPlanAddress.county,
      state: isSelectedAddressValue ? selectedAddress.state : defaultPlanAddress.state,
      zip: isSelectedAddressValue ? selectedAddress.zip : defaultPlanAddress.zip
    }
    this.props.getDefaultListingProducts(reqObj);
  }

  handlePlanSelected = (plan) => {
    this.props.selectPlan(plan);
    history.push(Routes.ONBOARDING_V2);
  }

  render() {
    const { planData } = this.props;
    return (
      <React.Fragment>
        <Navbar />
        <div className="plan-pricing-wrapper">
          <div className="plan-selector-wrapper-2">
            <PlanSelector {...planData} handlePlanSelected={this.handlePlanSelected} isShowZipcode={true} />
          </div>
          <Container>
            <div className="plan-text-wrapper">
              <p>
                *Additional state-mandated minimum services apply in some states (such as CO, LA, MO, NE and NJ).
                In GA 0.5% of the total commission percentage selected by the Seller (1-3% total commission is typically recommended)
                to be offered to the buyer’s broker will be paid to the listing broker at closing to cover state required services.
              For more information regarding minimum service fees see “CHOOSE YOUR COMMISSION RATE” above.</p>
              <p>
                All company names, trade names, trademarks, domain names and products referenced on this webpage are the property of
                their respective owners. No company referenced on this webpage is affiliated or associated with Unreal Estate, LLP &
                US Realty Brokerage Solutions, LLP or has sponsored the content on this webpage.
            </p>
            </div>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    planData: state.onboarding.planData,
    userDetails: state.user.userDetails,
    selectedAddress: state.onboarding.selectedAddress,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...onboardingActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanPricing);
