import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SearchPanel from '../Shared/SearchPanel.js';
import { entityTypes, formLayoutType, pageSizeOptions, staticMessages, tableItemsPerPage } from '../../../../../Constants/CommonConstants';
import { Card, Col, Row, Table } from 'antd';
import { titleCompanyService } from '../../../../../Services/V2/TitleCompanyService';
import TitleCompanyFilters from './TitleCompanyFilters';
import { Link } from 'react-router-dom';
import { editPageAction } from '../../../../../Utilities/commonFunctions';
import { startLoading, endLoading } from '../../../../../Actions/LoaderActions';
import LogRocket from 'logrocket';

const tableConfigs = {
    columns: [
        {
            title: 'Title Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
            sorter: true,
            render: (item, model) => (
                <Link key={`company-name-${item}-${model.id}`}
                    to={editPageAction({ id: model.id }, entityTypes.titleCompany)}>
                    {item}
                </Link>)
        },
        {
            title: 'Company Relationship',
            dataIndex: 'companies',
            key: 'company',
            sorter: false,
            render: (items, model) => (<div className='remove-last-commoa' key={`companies-${model.id}`}>
                {items.map(x => {
                    return <Link
                        key={`related-companies-${x.name}-${x.Id}`}
                        to={editPageAction({ companyId: x.id }, entityTypes.company)}
                    >
                        {x.name}<span>,&nbsp;</span>
                    </Link>
                })}
            </div>)
        },
        {
            title: 'States',
            dataIndex: 'states',
            key: 'state',
            sorter: false,
            render: (items, model) => (<span>{items && [...new Set(items)].join(", ").trim(", ")}</span>)
        }
    ]
}

const defaultFilters = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    state: null,
    companyName: null,
    companyId: null,
    sortBy: null,
    desc: null,
}

const TitleCompany = ({ setIsAddEdit }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [filters, setFilters] = useState(defaultFilters);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [filters]);

    const getData = () => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        titleCompanyService.getTitleCompanies(filters).then((result) => {
            if (result.data) {
                setData(result.data.results);
                setTotalCount(result.data.total);
            }
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        })
            .catch((error) => {
                dispatch(endLoading('MAIN_LOADER', 'Loading'));
                LogRocket.captureException(error, {
                    tags: {
                        errorType: 'failed_title_company_action',
                    },
                    extra: {
                        type: 'get_title_companies',
                    },
                });
            });
    }

    const onTableChange = (pagination, tablefilters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const sorterParams = {};

        if (field && order) {
            sorterParams.sortBy = field;
            sorterParams.desc = order === 'descend';
        }

        setFilters({
            ...filters,
            ...sorterParams,
            page: current,
            limit: pageSize
        });
    }

    const showTotal = (total, range) => {
        return `Showing ${range[0]} - ${range[1]} of ${total}`;
    }

    const onSearch = (value) => {
        setFilters({ ...filters, search: value });
    }

    return (<>
        <SearchPanel
            onSearch={onSearch}
            placeholder="Search"
            isShowAddButton={true}
            addButtonText="Add Title Company"
            addButtonClick={() => { setIsAddEdit(true) }}
        />
        <TitleCompanyFilters filters={filters} setFilters={setFilters} />
        <Card>
            <Row style={{ marginTop: 20 }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}>
                    <Table 
                        // className='listing-form-table'
                        size={'default'}
                        columns={tableConfigs.columns}
                        dataSource={data}
                        onChange={onTableChange}
                        pagination={{
                            total: totalCount,
                            defaultPageSize: tableItemsPerPage,
                            defaultCurrent: 1,
                            showTotal: showTotal,
                            pageSizeOptions: pageSizeOptions,
                            showSizeChanger: totalCount > tableItemsPerPage
                        }} />
                </Col>
            </Row>
        </Card>
    </>
    );
}

export default TitleCompany;