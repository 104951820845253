import React from 'react';
import Select, { createFilter } from 'react-select';
import { Button, DatePicker } from 'antd';
import { RegionDropdown } from 'react-country-region-selector';
import {
  InputFlex,
} from '../../../Assets/Style/commonStyleComponents';
import {
  reactSelectFilters,
  filterTypeContants,
  entityTypes,
  BlockAddress,
} from '../../../Constants/CommonConstants';
import { cursorStyles } from '../../../Constants/CommonConstants';
import enums from '../../../Constants/Enums';
import moment from 'moment';
import styled from 'styled-components';
import { AdminUserSearchInput } from '../../Admin/Components/AdminUserSearchInput';

const { RangePicker } = DatePicker;

const RangePickerWrapper = styled.div`
  .ant-calendar-picker {
    margin-right: 10px;
  }
  .ant-input {
    height: 40px;
    display: inline-flex;
  }
  .ant-calendar-range-picker-separator {
    padding-top: 5px;
    color: #808080;
  }
  .ant-calendar-range-picker-input::placeholder {
    color: #808080;
  }
`;

const { ZERO, THREE } = enums.NUMERIC_VALUE;
class FilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      roleTypeOptions: this.props.roles,
      selectedRole: '',
      productType: '',
      productTypes: this.props.productTypes,
      isDisabled: true,
      user: '',
      userTypes: this.props.userTypes,
      status: '',
      statusTypes: this.props.statusTypes,
      zip: '',
      zipCodeTypes: this.props.zipCodeTypes,
      mls: '',
      mlsTypes: this.props.mlsTypes,
      formTypeId: '',
      formTypes: this.props.formTypes,
      sectionTypeId: '',
      sectionTypes: this.props.sectionTypes,
      mlsFormTypes: this.props.mlsFormTypes,
      formSubmissionStatusId: '',
      submissionStatusTypes: this.props.submissionStatusTypes,
      company: '',
      companiesTypes: this.props.companies,
      state: '',
      stateTypes: this.props.states,
      formStatus: '',
      formStatuses: this.props.formStatuses,
      county: '',
      inputZip: '',
      mlsId: '',
      officeStatus: '',
      officeAssign: '',
      customState: '',
      createdBy: '',
      voucherTemp: '',
      templateType: '',
      loggedInUser: '',
      lastModifiedFrom: null,
      lastModifiedTo: null,
      dateOrderedFrom: null,
      dateOrderedTo: null,
      filterScroll: false,
      companyFilterScroll: false,
      orderId: ''
    };
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} e
   * @param fields {String} key
   */
  handleChange = (e, key, isMulti) => {
    let value = e && e['value'] ? e : null;
    if (isMulti) {
      value = e;
    }
    this.setState({
      ...this.state,
      inputValue: value,
      [key]: value,
      isDisabled: false,
    });
  };

  handleDateRangeChange = (e, type) => {
    const keyFrom = type === 'lastModified' ? 'lastModifiedFrom' : 'dateOrderedFrom';
    const keyTo = type === 'lastModified' ? 'lastModifiedTo' : 'dateOrderedTo';

    this.setState({
      ...this.state,
      [keyFrom]: e[0],
      [keyTo]: e[1],
      isDisabled: false,
    });
  }

    _handleKeyDown = (e) => {

      if (e.key === 'Enter') {
        e.preventDefault();
        this.applyFilters();
      }
    }

    handleCustomInputChange = (e, key) => this.setState({
      ...this.state,
      inputValue: e ? e : null,
      [key]: e ? e : null,
      isDisabled: false,
    });

    handleUserFilterSelected = (user) => {
      this.setState({ user, isDisabled: false });
    }

    applyFilters = () => {
      const {
        selectedRole,
        productType,
        user,
        status,
        zip,
        mls,
        formTypeId,
        sectionTypeId,
        formSubmissionStatusId,
        state,
        company,
        county,
        inputZip,
        mlsId,
        officeAssign,
        officeStatus,
        customState,
        createdBy,
        voucherTemp,
        templateType,
        loggedInUser,
        formStatus,
        lastModifiedFrom,
        lastModifiedTo,
        dateOrderedFrom,
        dateOrderedTo,
        orderId,
      } = this.state;
      const { activeEntity } = this.props;
      const filterArray = [];
      this.props.filterContent.forEach(item => {
        switch (item) {
          case filterTypeContants.rolesFilter.type:
            filterArray.push({
              key: filterTypeContants.rolesFilter.key,
              value: selectedRole.value,
            });
            break;
          case filterTypeContants.productTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.productTypeFilter.key,
              value: `${productType.value || ''}`,
            });
            break;
          case filterTypeContants.userTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.userTypeFilter.key,
              value: `${user?.value || ''}`,
            });
            break;
          case filterTypeContants.statusTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.statusTypeFilter.key,
              value: status.value,
            });
            break;
          case filterTypeContants.zipCodeTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.zipCodeTypeFilter.key,
              value: zip.value,
            });
            break;
          case filterTypeContants.mlsTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.mlsTypeFilter.key,
              value: `${mls.value || ''}`,
            });
            break;
          case filterTypeContants.formTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.formTypeFilter.key,
              value: `${formTypeId.value || ''}`,
            });
            break;
          case filterTypeContants.sectionTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.sectionTypeFilter.key,
              value: `${sectionTypeId.value || ''}`,
            });
            break;
          case filterTypeContants.mlsFormTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.mlsFormTypeFilter.key,
              value: activeEntity === entityTypes.mlsSubmission && formTypeId ? formTypeId.map(form => form.value)?.join(',') : formTypeId.value,
            });
            break;
          case filterTypeContants.submissionTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.submissionTypeFilter.key,
              value: `${formSubmissionStatusId.value || ''}`,
            });
            break;
          case filterTypeContants.states.type:
            filterArray.push({
              key: filterTypeContants.states.key,
              value: state.value,
            });
            break;
          case filterTypeContants.companies.type:
            filterArray.push({
              key: activeEntity === entityTypes.mlsCoverage ? 'companyId' : filterTypeContants.companies.key,
              value: activeEntity === entityTypes.voucherTemplate && company ? company.map(form => form.value)?.join(',') : `${company.value || ''}`,
            });
            break;
          case filterTypeContants.counties.type:
            filterArray.push({
              key: filterTypeContants.counties.key,
              value: county,
            });
            break;
          case filterTypeContants.inputZip.type:
            filterArray.push({
              key: filterTypeContants.inputZip.key,
              value: inputZip,
            });
            break;
          case filterTypeContants.mlsIdTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.mlsIdTypeFilter.key,
              value: `${mlsId.value || ''}`,
            });
            break;
          case filterTypeContants.officeStatusTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.officeStatusTypeFilter.key,
              value: `${officeStatus.value || ''}`,
            });
            break;
          case filterTypeContants.officeAssignedTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.officeAssignedTypeFilter.key,
              value: `${officeAssign.value || ''}`,
            });
            break;
          case filterTypeContants.customStateTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.customStateTypeFilter.key,
              value: customState,
            });
            break;
          case filterTypeContants.voucherTempTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.voucherTempTypeFilter.key,
              value: `${voucherTemp.value || ''}`,
            });
            break;
          case filterTypeContants.createdByTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.createdByTypeFilter.key,
              value: `${createdBy.value | ''}`,
            });
            break;
          case filterTypeContants.voucherTemplateFilter.type:
            filterArray.push({
              key: filterTypeContants.voucherTemplateFilter.key,
              value: `${templateType.value || ''}`,
            });
            break;
          case filterTypeContants.loggedInUserTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.loggedInUserTypeFilter.key,
              value: `${loggedInUser.value || ''}`,
            });
            break;
          case filterTypeContants.formStatusTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.formStatusTypeFilter.key,
              value: formStatus.value,
            });
            break;
          case filterTypeContants.lastModifiedFromTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.lastModifiedFromTypeFilter.key,
              value: lastModifiedFrom ? lastModifiedFrom.format('YYYY/MM/DD') : null,
            });
            break;
          case filterTypeContants.lastModifiedToTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.lastModifiedToTypeFilter.key,
              value: lastModifiedTo ? lastModifiedTo.format('YYYY/MM/DD') : null,
            });
            break;
          case filterTypeContants.orderIdTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.orderIdTypeFilter.key,
              value: orderId,
            });
            break;
          case filterTypeContants.dateOrderedFromTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.dateOrderedFromTypeFilter.key,
              value: dateOrderedFrom ? dateOrderedFrom.format('YYYY/MM/DD') : null,
            });
            break;
          case filterTypeContants.dateOrderedToTypeFilter.type:
            filterArray.push({
              key: filterTypeContants.dateOrderedToTypeFilter.key,
              value: dateOrderedTo ? dateOrderedTo.format('YYYY/MM/DD') : null,
            });
            break;
          default:
            break;
        }
      });

      const newArray = filterArray.filter(item => item.value);
      this.props.applyFilters(newArray);
    };

    clearFilters = () =>
      this.setState(
        {
          selectedRole: '',
          productType: '',
          user: '',
          status: '',
          zip: '',
          mls: '',
          formTypeId: '',
          sectionTypeId: '',
          isDisabled: true,
          formSubmissionStatusId: '',
          state: '',
          company: '',
          county: '',
          inputZip: '',
          mlsId: '',
          officeStatus: '',
          officeAssign: '',
          customState: '',
          createdBy: '',
          voucherTemp: '',
          templateType: '',
          loggedInUser: '',
          formStatus: '',
          lastModifiedFrom: '',
          lastModifiedTo: '',
          dateOrderedFrom: '',
          dateOrderedTo: '',
          searchString: '',
          orderId: '',
        },
        () => this.props.clearFilters(),
      );

    handleInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value, isDisabled: false });
    }

    inputDisable = () => {
      if (this.props.activeEntity === entityTypes.mlsCoverage) {
        if (this.state.inputZip || this.state.county) {
          return false;
        }
      }

      return true;
    }

    componentDidMount() {
      const pathName = window.location.pathname;
      if (pathName === "/admin/mls-submission") {
        this.setState({ filterScroll: true })
      }
      if (pathName === "/admin/voucher-template") {
        this.setState({ companyFilterScroll: true })
      }
    }

    componentWillUnmount() {
      this.props.clearFilters();
    }


    onUserSearch = (e) => {
      const val = e.target.value;
      const trimmedValue = val?.trim();

      this.setState({ user: val });

      if (trimmedValue?.length > 3) {
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => this.props.searchUsers(trimmedValue), 1000);
      }

    };


    render() {
      const { filterContent, activeEntity, usersList } = this.props;
      const { filterScroll, companyFilterScroll } = this.state;
      const {
        roleTypeOptions, selectedRole, productType, productTypes, isDisabled, user,
        status, statusTypes, zip, zipCodeTypes, mls, mlsTypes, formTypeId, formTypes, sectionTypeId,
        sectionTypes, mlsFormTypes, formSubmissionStatusId, submissionStatusTypes, stateTypes,
        companiesTypes, state, company, county, inputZip, mlsId, officeStatus, officeAssign,
        customState, templateType, createdBy, voucherTemp, loggedInUser, formStatus, formStatuses,
        lastModifiedFrom, lastModifiedTo, orderId, dateOrderedFrom, dateOrderedTo
      } = this.state;
      const { officeStatusTypes, officeUsersTypes, voucherTemplates, wholeSaleUsers, voucherTemplatesType, loggedInUsers } = this.props;
      return (
        <React.Fragment>
          {filterContent && filterContent.length > ZERO && (
            <div className={`admin-filter-wrapper ${filterContent.length > THREE ? 'more-filter' : ''} ${filterScroll ? 'with-multitag-input' : ''}`}>
              <div className={`filter-bar`}>
                <>
                  <div className="filter-bar-left">
                    {filterContent.includes('roles') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Role</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Role'
                          value={selectedRole}
                          onChange={e =>
                            this.handleChange(e, filterTypeContants.rolesFilter.selectedKey)
                          }
                          options={roleTypeOptions}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('productTypes') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Product</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Product'
                          value={productType}
                          onChange={e => this.handleChange(e, filterTypeContants.productTypeFilter.selectedKey)}
                          options={productTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {
                      filterContent.includes('orderId') && (
                        <div className='filter-dropdown for-custom-select'>
                          <label>Filter by Order ID</label>
                          <InputFlex
                            name='orderId'
                            placeholder='Enter Order ID'
                            className='inputgap filter-input'
                            type="number"
                            value={orderId}
                            onChange={this.handleInput}
                            onKeyDown={e => this._handleKeyDown(e)}
                          />
                        </div>
                      )
                    }
                    {}
                    {filterContent.includes('user') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Username or Email</label>
                        <AdminUserSearchInput
                          placeholder='Enter User Name or Email'
                          value={user?.label || user}
                          predictions={usersList}
                          className='admin-filter-user-search'
                          handleUserSelected={e => this.handleUserFilterSelected(e)}
                          onKeyDown={e => this._handleKeyDown(e)}
                          onChange={e => this.onUserSearch(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('status') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Property Status</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Status'
                          value={status}
                          onChange={e => this.handleChange(e, filterTypeContants.statusTypeFilter.selectedKey)}
                          options={statusTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('zip') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Zip Code</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Zip Code'
                          value={zip}
                          onChange={e => this.handleChange(e, filterTypeContants.zipCodeTypeFilter.selectedKey)}
                          options={zipCodeTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('mls') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by {`MLS ${activeEntity === entityTypes.mlsCoverage ? 'MLS' : 'Abbreviation'}`}</label>
                        <Select
                          styles={cursorStyles}
                          placeholder={`MLS ${activeEntity === entityTypes.mlsCoverage ? 'MLS' : 'Abbreviation'}`}
                          value={mls}
                          onChange={e => this.handleChange(e, activeEntity === entityTypes.mlsCoverage ? filterTypeContants.mlsIdTypeFilter.selectedKey : filterTypeContants.mlsTypeFilter.selectedKey)}
                          options={mlsTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('mlsId') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by MLS</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select MLS'
                          value={mlsId}
                          onChange={e => this.handleChange(e, filterTypeContants.mlsIdTypeFilter.selectedKey)}
                          options={mlsTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('formTypeId') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Form Type</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Form Type'
                          value={formTypeId}
                          onChange={e => this.handleChange(e, filterTypeContants.formTypeFilter.selectedKey)}
                          options={formTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('sectionTypeId') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Section Type</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Section Type'
                          value={sectionTypeId}
                          onChange={e => this.handleChange(e, filterTypeContants.sectionTypeFilter.selectedKey)}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          options={sectionTypes}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('submissionStatuses') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Status Type</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Status Type'
                          value={formSubmissionStatusId}
                          onChange={e => this.handleChange(e, filterTypeContants.submissionTypeFilter.selectedKey)}
                          options={submissionStatusTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('state') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by State</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select State'
                          value={state}
                          onChange={e => this.handleChange(e, filterTypeContants.states.selectedKey)}
                          options={stateTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('company') && (
                      <div className={`filter-dropdown ${companyFilterScroll ? 'company-scroll' : ''} for-custom-select`}>
                        <label>Filter by Company Name</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Company'
                          value={company}
                          onChange={e => this.handleChange(e, filterTypeContants.companies.selectedKey, true)}
                          options={companiesTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          isMulti={activeEntity === entityTypes.voucherTemplate}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('county') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by County</label>
                        <InputFlex
                          name='county'
                          placeholder='Enter County'
                          className='inputgap filter-input'
                          value={county}
                          onChange={this.handleInput}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('inputZip') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Zip Code</label>
                        <InputFlex
                          name='inputZip'
                          placeholder='Select Zip Code'
                          className='inputgap filter-input'
                          type="number"
                          value={inputZip}
                          onChange={this.handleInput}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('officeAssign') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Assigned</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Assigned'
                          value={officeAssign}
                          onChange={e => this.handleChange(e, filterTypeContants.officeAssignedTypeFilter.selectedKey)}
                          options={officeUsersTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('officeStatus') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Office Status</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Office Status'
                          value={officeStatus}
                          onChange={e => this.handleChange(e, filterTypeContants.officeStatusTypeFilter.selectedKey)}
                          options={officeStatusTypes}
                          className='admin-filter-react-select react-select__menu-list'
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('formTypes') && (
                      <div className={`filter-dropdown for-custom-select ${filterScroll ? 'multitag-dropdown' : ''}`}>
                        <label>Filter by Form Type</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Form Type'
                          value={formTypeId}
                          onChange={e => this.handleChange(e, filterTypeContants.mlsFormTypeFilter.selectedKey, true)}
                          options={mlsFormTypes}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          isMulti={activeEntity === entityTypes.mlsSubmission}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('customState') && (
                      <div className='filter-dropdown filter-custom-dropdown-wrapper for-custom-select'>
                        <label>Filter by State</label>
                        <RegionDropdown
                          defaultOptionLabel="Select a State."
                          countryValueType="short"
                          valueType="short"
                          country="US"
                          classes="custom-select filter-custom-select"
                          value={customState}
                          onChange={(e) => this.handleCustomInputChange(e, filterTypeContants.customStateTypeFilter.selectedKey)}
                          blacklist={{ US: BlockAddress }}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('createdBy') && (
                      <div className='filter-dropdown filter-custom-dropdown-wrapper for-custom-select'>
                        <label>Filter by Created By</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Created By'
                          value={createdBy}
                          onChange={e => this.handleChange(e, filterTypeContants.createdByTypeFilter.selectedKey)}
                          options={wholeSaleUsers}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('template') && (
                      <div className='filter-dropdown filter-custom-dropdown-wrapper for-custom-select'>
                        <label>Filter by Template</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Template'
                          value={voucherTemp}
                          onChange={e => this.handleChange(e, filterTypeContants.voucherTempTypeFilter.selectedKey)}
                          options={voucherTemplates}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('templateType') && (
                      <div className='filter-dropdown filter-custom-dropdown-wrapper for-custom-select'>
                        <label>Filter by Voucher Template</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Voucher Template'
                          value={templateType}
                          onChange={e => this.handleChange(e, filterTypeContants.voucherTemplateFilter.selectedKey)}
                          options={voucherTemplatesType}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('loggedInUser') && (
                      <div className='filter-dropdown filter-custom-dropdown-wrapper for-custom-select'>
                        <label>Filter by Logged In User</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select Logged In User'
                          value={loggedInUser}
                          onChange={e => this.handleChange(e, filterTypeContants.loggedInUserTypeFilter.selectedKey)}
                          options={loggedInUsers}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('formStatus') && (
                      <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Form Status</label>
                        <Select
                          styles={cursorStyles}
                          placeholder='Select form status'
                          value={formStatus}
                          onChange={e => this.handleChange(e, filterTypeContants.formStatusTypeFilter.selectedKey)}
                          options={formStatuses}
                          menuPosition='fixed'
                          menuShouldBlockScroll={false}
                          menuShouldScrollIntoView={true}
                          className='admin-filter-react-select'
                          createFilter={createFilter(reactSelectFilters)}
                          onKeyDown={e => this._handleKeyDown(e)}
                        />
                      </div>
                    )}
                    {filterContent.includes('lastModifiedFrom') && (
                      <RangePickerWrapper className="for-custom-select">
                        <label>Filter by Last Modified Date</label>
                        <RangePicker
                          ranges={{
                            Today: [moment(), moment()],
                            'This Week': [moment().startOf('week'), moment().endOf('week')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                          }}
                          format="YYYY/MM/DD"
                          value={[lastModifiedFrom, lastModifiedTo]}
                          defaultValue={[moment(), moment()]}
                          onChange={e => this.handleDateRangeChange(e, 'lastModified')}
                      />
                    </RangePickerWrapper>
                  )}
                  {filterContent.includes('dateOrderedFrom') && (
                    <RangePickerWrapper className="for-custom-select">
                    <label>Filter by Order Date</label>
                      <RangePicker
                        ranges={{
                          Today: [moment(), moment()],
                          'This Week': [moment().startOf('week'), moment().endOf('week')],
                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        format="YYYY/MM/DD"
                        value={[dateOrderedFrom, dateOrderedTo]}
                        defaultValue={[moment(), moment()]}
                        onChange={e => this.handleDateRangeChange(e, 'dateCreated')}
                      />
                    </RangePickerWrapper>
                  )}
                  </div>
                  <div className='form-group search-buttons'>
                    <Button
                      className='btn btn-primary admin-primary-btn small-btn'
                      disabled={isDisabled && this.inputDisable()}
                      onClick={() => this.applyFilters()}
                    >
                      Apply Filters
                    </Button>
                    <Button
                      className='btn btn-primary admin-primary-btn small-btn'
                      disabled={isDisabled && this.inputDisable()}
                      onClick={() => this.clearFilters()}
                    >
                      Reset Filters
                    </Button>
                  </div>

                </>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    }
  }

export { FilterComponent };
