import React from 'react';
import { EbookWrapper, Container, InputFlex, CustomButton, RowFlex } from '../../../../../Assets/Style/commonStyleComponents';
import ebook from '../../../../../Assets/Images/ebook.jpeg';
import AppConsts from '../../../../../Constants/AppConsts';
import Navbar from '../../../Components/Navbar';
import Footer from '../../../Components/Footer';

class Ebook extends React.Component {
  render() {
    return (
      <>
        <Navbar />
          <EbookWrapper>
            <Container>
              <div className={`klaviyo-form-${AppConsts.klaviyoForms.freeEbookFormId}`} />
              <div className="ebook-main-wrapper">
                <div className="ebook-left">
                  <h2>Free E-book Download</h2>
                  <p>Fill out this form to receive a free copy of our e-book, which helps you save time and money by using proven 'flat fee' and 'by owner' strategies.</p>
                  <RowFlex>
                    <InputFlex
                      name="yourName"
                      title="Your Name"
                      className="inputgap"
                    />
                    <InputFlex
                      name="yourEmail"
                      title="Your Email"
                      className="inputgap"
                    />
                  </RowFlex>
                  <div className='admin-custom-checkbox'>
                    <label>
                      <input
                        className='inputgap'
                        type='checkbox'
                      />
                      <span>I want to receive USRealty's Newsletter, which contains special offers and helpful tips.</span>
                    </label>
                  </div>
                  <CustomButton type="submit">Download Now</CustomButton>
                </div>
                <div className="ebook-right">
                  <div className="ebook-image">
                    <img src={ebook} alt="ebook"/>
                  </div>
                </div>
              </div>
            </Container>
          </EbookWrapper>
        <Footer />
      </>
    )
  }
}

export default Ebook;
