import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { productDetailsSelector, productFeatureTypesSelector } from '../../../../../Reducers/V2/AdminProductsReducer';
import { productsCreators } from '../../../../../Actions/V2/Admin/ProductsActions';

import { Layout } from 'antd';
import AdminSiderMenu from '../../../Components/AdminSiderMenu';
import AdminHeader from '../../../Components/AdminHeader';
import AddEditProduct from '../../../Components/AddEditProduct';
import { useHistory, withRouter } from 'react-router';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { companiesSelector, productTypesSelector, mlsAbbreviationsSelector, statesSelector, mlsCategoriesSelector } from '../../../../../Reducers/LookupReducer';

const { Content } = Layout;
const ProductDetails = ({match}) => {
    const dispatch = useDispatch();
    const routeParams = useParams();
    const productDetails = useSelector(productDetailsSelector);
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);

    const productId = routeParams.productId;

    const mlsAbbreviations = useSelector(mlsAbbreviationsSelector);
    const states = useSelector(statesSelector);
    const productTypes = useSelector(productTypesSelector);
    const companies = useSelector(companiesSelector);
    const mlsCategories = useSelector(mlsCategoriesSelector);
    const productFeatureTypes = useSelector(productFeatureTypesSelector);

    const mlsAbbreviationsOptions = mlsAbbreviations.map(x => ({ value: x.id, label: x.name }));
    const statesOptions = states.map(x => ({ value: x.id, label: x.name }));
    const productTypesOptions = productTypes.map(x => ({ value: x.id, label: x.name }));
    const companiesOptions = companies.map(x => ({ value: x.id, label: x.name }));
    const mlsCategoriesOptions = mlsCategories.map(x => ({ value: x.id, label: x.name }));

    useEffect(() => {
        dispatch(lookupActionsCreators.getMlsAbbreviations());
        dispatch(lookupActionsCreators.getStates());
        dispatch(lookupActionsCreators.getProductTypes());
        dispatch(lookupActionsCreators.getMlsCategories());
        dispatch(lookupActionsCreators.getCompanies());

        if (productId) {
          dispatch(productsCreators.getProductDetails(productId));
          dispatch(productsCreators.getProductFeatureTypes(productId));
        }
        else {
          dispatch(productsCreators.getProductFeatureTypes());
        }

        return () => {
            dispatch(productsCreators.clearDetails());
        }
    }, []);

    const toggle = () => setCollapsed(!collapsed);

    return (
        <Layout style={{ minHeight: '100vh' }}>
          <AdminSiderMenu
            path={match?.path}
            onCollapse={value => setCollapsed(value)}
            history={history}
            collapsed={collapsed}
          />
          <Layout>
            <Layout.Header
              style={{ background: '#fff', minHeight: 52, padding: 0 }}
            >
              <AdminHeader
                collapsed={collapsed}
                toggle={toggle}
              />
            </Layout.Header>
            <Content>
                {/*TODO: Do refactoring with  AddEditProduct component*/}
                {!productDetails &&
                    <AddEditProduct
                        userProfileData={{}}
                        productFeatureTypes={productFeatureTypes || []}
                        isNewProduct={true}
                        lookup={{
                            states: statesOptions,
                            mls: mlsAbbreviationsOptions,
                            mlsCategories: mlsCategoriesOptions,
                            productTypes: productTypesOptions,
                            companies: companiesOptions
                        }}
                    />
                }
                {productDetails && productFeatureTypes &&
                    <AddEditProduct
                        userProfileData={productDetails}
                        productFeatureTypes={productFeatureTypes}
                        isNewProduct={false}
                        lookup={{
                            states: statesOptions,
                            mls: mlsAbbreviationsOptions,
                            mlsCategories: mlsCategoriesOptions,
                            productTypes: productTypesOptions,
                            companies: companiesOptions
                        }}
                    />
                }
            </Content>
          </Layout>
        </Layout>
    );
}

export default (withRouter(ProductDetails));