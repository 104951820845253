import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import BuyingPropertyForm from '../../Shared/Components/BuyingPropertyForm';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import { LYFSteps } from '../../../Constants/CommonConstants';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 1.5rem;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const InstructionText = styled.div`
  margin-top: 1rem;
  max-width: 100%;
  background-color: #ae97c81a;
  border-radius: 10px;
  padding: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;

  span {
    font-size: 1.25rem;
    position: relative;
    bottom: 0.25rem;
  }
`;

const BuyerPreferences = () => {
  const dispatch = useDispatch();
  const handleGoToPlanSelector = () => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PLAN_SELECTOR));

  return (
    <Container>
      <FormContainer>
        <TitleContainer>
          <SubTilteText>List your property</SubTilteText>
          <StepsText>7/9</StepsText>
        </TitleContainer>
        <>
          <TitleText>Quick question, are you planning to purchase a property?</TitleText>
          <InstructionText>
            <span>Buy your next property for less.</span> <br />
            Did you know buying with Unreal Estate can put thousands of dollars in your pocket?
            When you buy with Unreal Estate we will credit* a portion of our commission back to you.
            <br />
          </InstructionText>
        </>
        <TitleText>Share your prefences</TitleText>
        <BuyingPropertyForm
          onCancel={handleGoToPlanSelector}
          onSubmit={handleGoToPlanSelector}
          submitButtonText={'Next'}
          cancelButtonText={'I don’t want to save thousands by buying with Unreal Estate'}
        />
      </FormContainer>
      <ListingPreviewDesktopContainer hideVideoHints />
    </Container>
  );
};

export default BuyerPreferences;
