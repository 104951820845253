import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { ModalDiv } from '../../../Assets/Style/commonStyleComponents';
import { history } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import Enums from '../../../Constants/Enums';
import { downloadCompletedForm } from '../../../Utilities/commonFunctions';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import TitleCompanyAdd from '../../Shared/Components/TitleCompanyAdd';

const listingIdStr = ":listingId";
class ViewListingDetail extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isMLSFormPending: false,
      isContinue:true
    }

    this.prevTitle = '';
  }

  componentDidMount() {
    this.prevTitle = document.title;
    document.title = 'My Dashboard | Unreal Estate'
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  static getDerivedStateFromProps(props,state) {
     if(state.isContinue)
     {
      if(props.completedFormsData) {
       
        let isMLSFormPending = !props.completedFormsData.some( item => (item['formId'] === Enums.NUMERIC_VALUE.ONE || item['formId'] === Enums.NUMERIC_VALUE.SIX) )
        if(history.location.state){
          
          isMLSFormPending=history.location.state.isMLSFormPendinng;
        }
       
        return { isMLSFormPending };
      }
      return null;
    }
  }
  formEditView = (form) => {
    let pathToRedirect;
    let formStep;
    if (form.formId === Enums.NUMERIC_VALUE.ONE || form.formId === Enums.NUMERIC_VALUE.SIX) {
       formStep = form.formStatus === Enums.NUMERIC_VALUE.ONE ? Enums.NUMERIC_VALUE.ONE : Enums.NUMERIC_VALUE.THREE;
      pathToRedirect = `${Routes.EDIT_LISTING.replace(listingIdStr, this.props.listingId)}/?step=${formStep}`;
    } else {
      pathToRedirect = `${Routes.PROPERTY_LISTING_EDIT_FORM}?listingId=${this.props.listingId}&formId=${form.formId}`;
    }
    history.push({
      pathname: pathToRedirect,
      state: {
        listingId: this.props.listingId,
        formData: form,
        formStep:formStep,
      },
    });
  }

  onContinue=()=>{
    this.setState({isMLSFormPending:false})
    this.setState({isContinue:false})
  }

  renderMLSForms = () => {
    const { formsData } = this.props;
    let isFormSubmited = false;
    const { completedForms } = this.props.listing;
    const isFirstFormComplete = completedForms.some((form) => (form.formId === Enums.NUMERIC_VALUE.ONE));
    const isSixthFormComplete = completedForms.some((form) => (form.formId === Enums.NUMERIC_VALUE.SIX));
    return formsData.length > 0 &&
      formsData.map((form) => {
        if (isFirstFormComplete || isSixthFormComplete) {
          isFormSubmited = true;
        }
        return <p
          key={form.name}
          onClick={() => this.formEditView(form, isFormSubmited)}>
          {form.name}
        </p>
      })
  }

  renderCompletedMLSForms = () => {
    const { completedForms } = this.props.listing;

    return completedForms.map((form) => {
      return (
        <div key={form.name}>
          <h3>{`${form.name} submitted on ${moment(form.completionDate).format("MMMM DD, YYYY, h:mma")}`}  </h3>
          {
            form.documents.map((doc) => {
              return (
                <span
                  key={doc.name}
                  onClick={() => downloadCompletedForm(doc.path, doc.identityId)}>
                  {doc.name}
                </span>
              );
            })
          }
        </div>
      )
    })
  }

  handleProceedFurther = () => {
    this.setState({

    }, () => {
      history.push(`${Routes.EDIT_LISTING.replace(listingIdStr, this.props.listingId)}`);
    })
  }

  getPublicUrl = () => {
    const prevUrl = Routes.PUBLIC_VIEW_LISTING;
    const newUrl = prevUrl.replace(':listingId', this.props.listingId);
    return newUrl;
  }

  render() {
    const { listing, isFetching } = this.props;
    const createMarkup = (description) => ({ __html: description });
    return (
      <React.Fragment>
        <div className="view-listing-top">
          {
            !isFetching &&
              <div className="public-list-row">
                <div className="public-list-col-first">
                  <div className="listing-address">
                    <span className="primary-text"> {listing.address1} {listing.address2} {listing.unit} {' '}</span>
                    <span>{listing.city ? listing.city + ',' : ''} {listing.state} {listing.zip}</span>
                  </div>
                  <div className="listing-price">
                    <span className="primary-text">
                      <CurrencyFormat
                        value={listing.listPrice || 0}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </span>
                  </div>
                  <div className="listing-detail">
                    <span className="primary-text">{listing.bedrooms || 0}</span> bedrooms, {' '}
                    <span className="primary-text">{listing.bathrooms || 0}</span> bathrooms, {' '}
                    <span className="primary-text">{listing.squareFeet || 0}</span> sq. ft., {' '}
                    <span className="primary-text">{listing.lotAcreage || 0}</span> acre lot
                    </div>
                    <div className="listing-detail-2 text-black">
                      <h3>{this.props?.listing?.daysOnMarket || 0} DAYS ON MARKET</h3>
                    </div>
                    <div className="public-url-link"><span>Public Url: </span> <Link target="_blank" to={this.getPublicUrl()} > {`${process.env.REACT_APP_APP_BASE_URL}${this.getPublicUrl()}`}</Link></div>
                    {
                      !!this.props?.listing?.description &&
                      <div className="listing-detail-2">
                        <h3>Description</h3>
                        <div dangerouslySetInnerHTML={createMarkup(this.props?.listing?.description)}></div>
                      </div>
                    }
                  {
                    listing?.completedForms && listing?.completedForms.length > 0 &&
                    <div>
                      {
                        <div className="completed-forms">
                          <h2>Completed Forms</h2>
                          {this.renderCompletedMLSForms()}
                        </div>
                      }
                    </div>

                  }
                </div>
                <div className="public-list-col-second">
                  <>
                <div style={{ marginTop: "2rem" }}>
                  <TitleCompanyAdd state={listing?.state} />
                </div>
                  {
                    this.props.formsData &&
                    <div className="view-listing-headings">
                      <h2>All Available MLS Forms</h2>
                      {this.renderMLSForms()}
                    </div>
                  }
                  </>
                </div>
              </div>
          }
        </div>
        <ModalDiv
          title="Information"
          visible={this.state.isMLSFormPending}
          closable={false}
          onCancel={() => history.push(`${Routes.DASHBOARD}`)}
          onOk={() => history.push(`${Routes.EDIT_LISTING.replace(listingIdStr, this.props.listingId)}`)}
          okText="Edit Listing"
          cancelText="My Listings"
          destroyOnClose={true}
          maskClosable={false}
          className="confirm-modal CompleteStatusModal"
        >
          <span className="modal-icon"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
          <p className="static-text">Information on this listing is incomplete. Please select Edit to complete or click on My Listings</p>
          <p className="static-text from-Library-txt" data-tip data-for="skip_forms"><a onClick={() => this.onContinue()}>SKIP</a></p>
          <ReactTooltip className="tooltip" multiline={true} id="skip_forms"><span dangerouslySetInnerHTML={{ __html: 'Continue to Forms' }} /></ReactTooltip>
        </ModalDiv>
      </React.Fragment>  
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...dashboardActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewListingDetail);
