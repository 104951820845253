import { actions } from "../Actions/UserActions";
import { userService } from '../Services/UserService';
import { getLocalUserData } from "../Utilities/commonFunctions";

const initialState = {
  errors: {},
  isFetching: false,
  appSettings: false,
  s3Identity: false,
  isLoggedIn: !!userService.getLocalUser()?.userId,
  userDetails: { ...(userService.getLocalUser() || {}) },
  lookup: {
    voucherTemplates: [],
    voucher: []
  },
  loginCallBackAction: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    ////////////////////////////////////
    case actions.GET_SETTINGS_REQUESTED:

      return { ...state, isFetching: true };
    ////////////////////////////////////

    ////////////////////////////////////
    case actions.GET_SETTINGS_RESOLVED:

      return { ...state, appSettings: { ...action.payload }, isFetching: false };
    ////////////////////////////////////
    case actions.EXTERNAL_LOGIN_REQUESTED:
    case actions.CREATE_USER_FROM_EXTERNAL_REQUESTED:
    case actions.LOGIN_REQUESTED:
      return { ...state, isFetching: true };
    ////////////////////////////////////
    case actions.CREATE_USER_FROM_EXTERNAL_RESOLVED:
    case actions.EXTERNAL_LOGIN_RESOLVED:
      //otherwise, set token and clear errors
      userService.setLocalUser(action.payload.user);
      return { ...state, userDetails: { ...action.payload.user }, redirect: action.payload.redirectPath, errors: {}, isFetching: false, isLoggedIn: true };

    case actions.USER_IMAGE_RESOLVED:
      return { ...state, userDetails: { ...state.userDetails, picture: action.payload }};
    ////////////////////////////////////
    case actions.EXTERNAL_LOGIN_NO_USER:
      return {
        ...state,
        userDetails: null,
        isLoggedIn: false,
        isFetching: false
      };
    ////////////////////////////////////
    case actions.CREATE_USER_FROM_EXTERNAL_ERROR:
    case actions.EXTERNAL_LOGIN_ERROR:
      return {
        ...state,
        errors: { ...action.payload },
        isFetching: false
      };
    ////////////////////////////////////
    case actions.LOGIN_RESOLVED:
      action.payload.user.isAdminSwitchUser = false;
      userService.setLocalUser(action.payload.user);

      return {
        ...state,
        userDetails: { ...action.payload.user },
        errors: {},
        isFetching: false,
        isLoggedIn: true,
        loginCallBackAction: null,
      };
    ////////////////////////////////////
    case actions.LOGIN_ERROR:
      return {
        ...state,
        errors: { ...action.payload },
        isFetching: false,
        loginCallBackAction: null,
      };
    ////////////////////////////////////
    case actions.S3_TOKEN_REQUESTED:
    case actions.CREATE_USER_REQUESTED:
    case actions.UPDATE_PROFILE_REQUESTED:
    case actions.FORGOT_PASSWORD_REQUEST:
    case actions.RESET_PASSWORD_REQUEST:
    case actions.GET_DEFAULT_BILLING_ADDRESS_REQUEST:
    case actions.ADD_UPDATE_VOUCHER_REQUESTED:
    case actions.ADD_DOWNLOAD_ZIP_REQUESTED:
    return { ...state, isFetching: true };
    ////////////////////////////////////

    ////////////////////////////////////
    case actions.CREATE_USER_RESOLVED:
      // If error, return errors

      if (action.isError) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false
        };
      }
      return {
        ...state,
        errors: {},
        isFetching: false,
        loginCallBackAction: null,
      };
    ////////////////////////////////////

    ////////////////////////////////////
    case actions.CLEAR_USER:

      // If error, return errors

      if (action.isError) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false
        };
      }

      //otherwise, set token and clear errors
      userService.clearLocalUser();

      return { ...state, userDetails: {}, errors: {}, isFetching: false, redirect: action.payload.redirectPath, isLoggedIn: false };
    ////////////////////////////////////

    ////////////////////////////////////
    case actions.S3_TOKEN_RESOLVED:
      if (action.isError) {
        return {
          ...state,
          s3Identity: false,
          isFetching: false
        };
      }

      return { ...state, s3Identity: action.payload, isFetching: false };
    ////////////////////////////////////

    ////////////////////////////////////
    case actions.LOGOUT_REQUESTED:

      return { ...state, isFetching: true, isLoggedIn: false };

    case actions.LOGOUT_RESOLVED:

      // If error, return errors

      //otherwise, set token and clear errors
      
      return { ...initialState, userDetails: {}, errors: {}, redirect: '', isFetching: false, isLoggedIn: false };

    case actions.UPDATE_PROFILE_RESOLVED:

      // If error, return errors
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false
        };
      } else {
        //otherwise, set token and clear errors
        let userObj = getLocalUserData();
        if (userObj.isAdminSwitchUser) {
          userObj['switchedUser'] = action.payload.value;
          userObj['switchedUser']['token'] = state.userDetails.switchedUser.token;
        } else {
          userObj = action.payload.value;
          userObj['token'] = state.userDetails.token
        }
        userService.setLocalUser(userObj);
      }
      return { ...state, userDetails: { ...action.payload.value }, errors: {}, isFetching: false };
    case actions.FORGOT_PASSWORD_REQUEST_RESOLVED:
    case actions.RESET_PASSWORD_REQUEST_RESOLVED:

      return { ...state, isFetching: false };

    case actions.GET_DEFAULT_BILLING_ADDRESS_RESOLVED:

      // If error, return errors
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false
        };
      }
      return {
        ...state,
        defaultShippingAddress: { ...action.payload?.value?.shippingInfo },
        defaultBillingAddress: { ...action.payload?.value?.billingInfo }, errors: {}, isFetching: false
      };

    case actions.LOOKUP_REQUESTED:
      return { ...state, activeEntity: action.payload, isRequesting: true };

    case actions.LOOKUP_RESOLVED:
      if (action.isError)
        return { ...state, errors: { ...action.payload }, isRequesting: false };
        state.lookup[action.payload.lookupType] = action.payload.value;
        return { ...state, lookup: action.payload.value, isRequesting: false };
      
    case actions.ADD_UPDATE_VOUCHER_RESOLVED:
      if (!action.payload?.success) {
        return {
          ...state,
          errors: { ...action.payload },
          isFetching: false,
        };
      }
      return { ...state, errors: {}, isFetching: false, selectedItemData: {} };
      case actions.CLEAR_REDIRECT:
      return { ...state, redirect: ''};

      case actions.SET_LOGIN_CALLBACK_ACTION:
        return { ...state, loginCallBackAction: action.payload };
  

      

    default: return state;
  }
};

export const redirectSelector = state => state?.user?.redirect;
export const userDetailsSelector = state => state?.user?.userDetails;
export const currentUserSelector = state => state?.user?.userDetails.switchedUser || state?.user?.userDetails;