import LogRocket from 'logrocket';
import moment from 'moment';
import { startLoading, endLoading } from '../../LoaderActions';
import { removeNullUndefined } from '../../../Utilities/commonFunctions';
import { formsFiltersDefault } from '../../../Reducers/V2/AdminFormsReducer';
import { propertiesService } from '../../../Services/V2/PropertiesService';
import { staticMessages } from '../../../Constants/CommonConstants';
import { alertActions } from '../../ToastActions';

export const PropertiesActions = {
    PROPERTIES_REQUESTED: 'PROPERTIES_REQUESTED',
    PROPERTIES_RESOLVED: 'PROPERTIES_RESOLVED',
    PROPERTIES_FAILED: 'PROPERTIES_FAILED',
    SET_PROPERTIES_FILTERS: 'SET_PROPERTIES_FILTERS',
    CLEAR_PROPERTIES_FILTERS: 'CLEAR_PROPERTIES_FILTERS',
    UPDATE_PROPERTY_STATUS_REQUESTED: 'UPDATE_PROPERTY_STATUS_REQUESTED',
    UPDATE_PROPERTY_STATUS_RESOLVED: 'UPDATE_PROPERTY_STATUS_RESOLVED',
    UPDATE_PROPERTY_STATUS_FAILED: 'UPDATE_PROPERTY_STATUS_FAILED',
    GET_AVERAGE_TIME_TO_LIST_REQUESTED: 'GET_AVERAGE_TIME_TO_LIST_REQUESTED',
    GET_AVERAGE_TIME_TO_LIST_RESOLVED: 'GET_AVERAGE_TIME_TO_LIST_RESOLVED',
    GET_AVERAGE_TIME_TO_LIST_FAILED: 'GET_AVERAGE_TIME_TO_LIST_FAILED',
};

export const propertiesCreators = {
    setPropertiesFilters: (filters) => async (dispatch) => {
        dispatch({ type: PropertiesActions.SET_PROPERTIES_FILTERS, payload: filters });
    },
    clearPropertiesFilters: () => async (dispatch) => {
        dispatch({ type: PropertiesActions.CLEAR_PROPERTIES_FILTERS });
    },
    getProperties: (params = formsFiltersDefault) => async (dispatch, getState) => {
        dispatch({ type: PropertiesActions.PROPERTIES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const paramsObj = params;

            const { data } = await propertiesService.getProperties(removeNullUndefined(paramsObj));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: PropertiesActions.PROPERTIES_RESOLVED, payload: data });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_orders_action',
                },
                extra: {
                    type: PropertiesActions.PROPERTIES_FAILED,
                },
            });
        }
    },
    updatePropertyStatus: (propertyId, payload) => async (dispatch, getState) => {
        try {
            dispatch({ type: PropertiesActions.UPDATE_PROPERTY_STATUS_REQUESTED });
            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            const { data } = await propertiesService.updateStatus(propertyId, payload);

            const properties = getState().adminProperties.properties;
            const property = properties.find(p => p.id === propertyId);
            property.assignedToId = payload.assignedListingStatusId;
            dispatch({
                type: PropertiesActions.UPDATE_PROPERTY_STATUS_RESOLVED,
                payload: [...properties],
            });

            dispatch(endLoading('MAIN_LOADER'));

        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_admin_action',
                },
                extra: {
                    type: 'admin actions updatePropertyStatus FAILED',
                    propertyId,
                    payload,
                },
            });
        }
    },
    getAverageTimeToList: () => async (dispatch) => {
        try {
            dispatch({ type: PropertiesActions.GET_AVERAGE_TIME_TO_LIST_REQUESTED });
            const { data } = await propertiesService.getAverageTimeToList();
            const formattedTime = `${moment.duration(data, 'seconds').hours()}:${moment.duration(data, 'seconds').minutes()}:${moment.duration(data, 'seconds').seconds()}`;
            dispatch({ type: PropertiesActions.GET_AVERAGE_TIME_TO_LIST_RESOLVED, payload: formattedTime });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || 'Loading average time to list error';
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: PropertiesActions.GET_AVERAGE_TIME_TO_LIST_FAILED });
      
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_admin_action',
                },
                extra: {
                    type: PropertiesActions.GET_AVERAGE_TIME_TO_LIST_FAILED,
                },
            });
        }
      }
};
