import React from 'react';
import { connect } from 'react-redux';
import { RegionDropdown } from 'react-country-region-selector';
import { RowFlex, InputFlex, DetailsWrapper } from '../../../../Assets/Style/commonStyleComponents';
import { BlockAddress } from '../../../../Constants/CommonConstants';
class PropertyAddressDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { initialState, handleFieldChanged, handleFieldBlur, selectStateAndCounty } = this.props;
    return (
      <React.Fragment>
        <DetailsWrapper>
          <RowFlex>
            <InputFlex
              name={initialState.addressZip.name}
              title={initialState.addressZip.label}
              value={initialState.addressZip.value}
              error={initialState.addressZip.error}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              className="inputgap"
            />
            <InputFlex
              name={initialState.addressAddress1.name}
              title={initialState.addressAddress1.label}
              value={initialState.addressAddress1.value}
              error={initialState.addressAddress1.error}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              className="inputgap"
            />
          </RowFlex>
          <RowFlex>
            <InputFlex
              name={initialState.addressAddress2.name}
              title={initialState.addressAddress2.label}
              value={initialState.addressAddress2.value}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              className="inputgap"
            />
            <InputFlex
              name={initialState.addressCity.name}
              title={initialState.addressCity.label}
              value={initialState.addressCity.value}
              error={initialState.addressCity.error}
              onChange={(e) => handleFieldChanged(e)}
              ur={(e) => handleFieldBlur(e)}
              className="inputgap"
            />
          </RowFlex>
          <RowFlex>
            <div className="pcol-6 for-custom-select">
              <label>State</label>
              <RegionDropdown
                defaultOptionLabel="Select a State."
                countryValueType="short"
                valueType="short"
                country="US"
                value={initialState.addressState.value}
                onChange={(e) => selectStateAndCounty(e)}
                blacklist={{ US: BlockAddress }}
                error={initialState.addressState.error}
                className={initialState.addressState.error ? "custom-select error-input" : 'custom-select'}
              />
            </div>
            <InputFlex
              name={initialState.addressCounty.name}
              title={initialState.addressCounty.label}
              value={initialState.addressCounty.value}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              flex={1}
              className="inputgap"
            />
          </RowFlex>
        </DetailsWrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

export default connect(mapStateToProps)(PropertyAddressDetails);
