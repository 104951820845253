import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions'
import { userActionCreators } from '../../../Actions/UserActions'
import AdminSiderMenu from '../Components/AdminSiderMenu';
import AdminHeader from '../Components/AdminHeader';
import { ListingContainer } from './ListingContainer';
import { LandingContainer } from './LandingContainer';
import enums from "./../../../Constants/Enums";
import { adminPageTitles, commonAllowedPaths, entityTypes } from '../../../Constants/CommonConstants';
import { getYear } from '../../../Utilities/commonFunctions';
import OrdersList from '../V2/Components/Orders/OrdersList';
import ListingFormsList from '../V2/Components/ListingForms/ListingFormsList';
import TitleCompanyContainer from '../V2/Components/TitleCompanies/TitleCompanyContainer';
import ProductsList from '../V2/Components/Products/ProductsList';
import PropertiesList from '../V2/Components/Properties/PropertiesList';
import CashOfferList from '../V2/Components/CashOffer/CashOfferList';
import ByuerLeadsList from '../V2/Components/BuyerLeads/ByuerLeadsList';

const { Content } = Layout;
export class Admin extends Component {
  state = {
    entity: '',
    api: null,
    allowedPaths: commonAllowedPaths,
    prevTitle: null,
  };

  componentDidMount() {
    this.prevTitle = document.title;
    document.title = adminPageTitles?.[this.props.activeEntity] ?? this.props.activeEntity
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  hasOwnComponent(entity) {
    if (entity === entityTypes.listings
        || entity === entityTypes.products
        || entity === entityTypes.titleCompany
        || entity === entityTypes.properties
        || entity === entityTypes.cashOffers) {
      return true;
    }

    return false;
  }

  render() {
    const { history, activeEntity, collapseMenu } = this.props;
    const { path } = this.props.match;
    const { allowedPaths } = this.state;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <div className="intercom-lightweight-app"></div>
        <AdminSiderMenu path={path} history={history} />
        <Layout className={`admin-main-wrapper ${collapseMenu ? '' : 'full-width-admin-main-wrapper'}`}>
          <Layout.Header>
            <AdminHeader />
          </Layout.Header>
          <Content style={{ margin: 16 }} className={`admin-main-wrapper ${collapseMenu ? '' : 'full-width-admin-main-wrapper'}`}>
            <div className="admin-inner-wrapper">
              {activeEntity === entityTypes.orders && <OrdersList {...this.props} />}
              {activeEntity === entityTypes.listings && <ListingFormsList {...this.props} />}
              {activeEntity === entityTypes.products && <ProductsList {...this.props} />}
              {activeEntity === entityTypes.properties && <PropertiesList {...this.props} />}
              {activeEntity === entityTypes.titleCompany && <TitleCompanyContainer {...this.props}/>}
              {activeEntity === entityTypes.cashOffers && <CashOfferList {...this.props} />}
              {activeEntity === entityTypes.buyerLeads && <ByuerLeadsList {...this.props} />}
              {activeEntity && !this.hasOwnComponent(activeEntity) && (allowedPaths.indexOf(activeEntity) > -1) && (
                <>
                  {activeEntity === entityTypes.landing
                    ? <LandingContainer {...this.props} />
                    : <ListingContainer {...this.props} />}
                </>
              )}
            </div>
          </Content>
          <Layout.Footer>
            © {getYear()} All Rights Reserved Unreal Estate
          </Layout.Footer>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[enums.NUMERIC_VALUE.TWO],
    collapseMenu: state.admin.collapseMenu
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators, ...userActionCreators }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
