import React from 'react';
import { lYHVideoUrls } from '../../../Constants/CommonConstants';
import { useSelector } from 'react-redux';
import { listingStepSelector } from '../../../Reducers/OnboardingReducerV2';
import { EmbeddedYoutubePlayer } from '../../Shared/Components/EmbeddedYoutubePlayer';

export const EmbeddedYoutubeHintsPlayer = (props) => {
  const step = useSelector(listingStepSelector);
  return <EmbeddedYoutubePlayer {...props} src={lYHVideoUrls[step]} />;
};
