import React, { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { searchingUsersSelector } from '../../../Reducers/AdminReducer';



const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    height: 40px;
    border-color: #e6e6e6;
    font-size: 15px;
    box-shadow: none !important;
    line-height: normal;
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    color: ${({ theme }) => theme.colors.green[900]};
  }
`;

const AddressWrapper = styled.div`
  display:flex;
  flex:0.5;
  flex-direction:column;
  text-align:left;
`;
const InputChevronButtonDivider = styled.div`
  right: 2.25rem;
  position: absolute;
  width: 0.8px;
  height: 1.4rem;
  top: 1px;
  margin-top: 8px;
  background-color: #cccccc;
`;

const InputChevronButton = styled.button`
  right: 3px;
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 3px;
  padding: 8px;
  background-color: white;
`;

const Results = styled.div`
  background:#fff;
  display:flex;
  flex-direction:column;
  text-align:left;
  position:absolute;
  top: 46px;
  width: 320px;
  max-height: 300px;
  overflow: scroll;
  border:1px solid #f0f0f0;
  border-radius:0 0 4px 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .3);
  z-index: 1;
`;

const Result = styled.span`
    padding: 18px 20px;
    color:#242B26;
    z-index:10;
    background: white;
    border-bottom:1px solid #f0f0f0;
    ${props => props.isActive ? "background-color:#C9E2F3;" : ""}
    &:last-child {
        border:none;
    }
    &:hover {
        background:#f0f0f0;
        color:#242B26;
    }
    @media(max-width: 768px) {
      padding: 12px 10px;
      line-height: 24px;
      font-size: 15px;
    }
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
`;

export const AdminUserSearchInput = ({
  handleUserSelected,
  className,
  userDetails,
  predictions,
  ...inputProps
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showResults, setShowResults] = useState(true);
  const isLoading = useSelector(searchingUsersSelector)

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && currentIndex !== 0) {
      e.preventDefault();
      setCurrentIndex(currentIndex - 1);
    } else if (e.keyCode === 40 && currentIndex < predictions?.length - 1) {
      setCurrentIndex(currentIndex + 1);
      e.preventDefault();
    } else if (e.keyCode === 13 || e.keyCode === 9) {
      if (predictions.length > 0) {
        handleUserSelected(e)
      }
      e.preventDefault();
    }
  }

  useEffect(() => {
  !isLoading && setShowResults(true);
  }, [isLoading])

  return (
      <AddressWrapper className={className}>
        <div className="custom-address-selector">
        <InputContainer className={inputProps.error ? 'invalid' : ''}>
          <input
            {...inputProps}
            placeholder={inputProps.placeholder}
            onKeyDown={handleKeyDown}
          />
          <InputChevronButtonDivider />
          <InputChevronButton type="button" onClick={() => setShowResults(pr => !pr)}>
          {!showResults ? (
              <svg height="20" width="20"  viewBox="0 0 20 20" aria-hidden="true" color="#cccccc" focusable="false" className="css-6q0nyr-Svg"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            ) : (
              <svg viewBox="224.58 0.449 11.599 6.8" width="11.599" height="6.8" aria-hidden="true" color="#cccccc" focusable="false" className="css-6q0nyr-Svg">
                <path d="M 224.897 6.901 C 225.333 7.347 225.94 7.382 226.473 6.901 L 230.381 3.154 L 234.289 6.901 C 234.822 7.382 235.43 7.347 235.863 6.901 C 236.299 6.456 236.271 5.704 235.863 5.286 C 235.457 4.868 231.168 0.784 231.168 0.784 C 230.951 0.561 230.666 0.449 230.381 0.449 C 230.096 0.449 229.811 0.561 229.592 0.784 C 229.592 0.784 225.305 4.868 224.897 5.286 C 224.489 5.704 224.461 6.456 224.897 6.901 Z"></path>
              </svg>
            )}
          </InputChevronButton>
        </InputContainer>
        {showResults &&
        predictions?.length > 0 &&
        inputProps.value &&
        !isLoading && (
          <Results>
            {predictions.map((p, i) => (
              <Result
                key={i}
                isActive={currentIndex === i}
                onClick={() => {
                  handleUserSelected(p);
                  setShowResults(false);
                }}
              >
                {p.label}
              </Result>
            ))
            }
          </Results>
        )}
        {!isLoading && predictions?.length === 0 && inputProps.value && showResults && (
            <Results>
              <Result key="1">
                No users found
              </Result>
            </Results>
        )}
        {isLoading && (
            <Results>
              <Loader animation="border"/>
            </Results>
        )}
      </div>
    </AddressWrapper>
  );
}
