import React, { useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import { HeaderContainer, NavBarButton, NavLink, LinksContainer } from '../../../Assets/Style/commonStyleComponents';
import routes from '../../../Constants/Routes';
import { Logo } from '../Components/Icons/Logo';
import { Hamburger } from '../Components/Icons/Hamburger';
import { history } from '../../../Utilities/History';
import NavbarDrawer from './NavbarDrawer';
import { setAdminData } from '../../../Utilities/commonFunctions';
import { roleType } from '../../../Constants/CommonConstants';
import { userService } from '../../../Services/UserService';
import { userActionCreators } from '../../../Actions/UserActions';
import { UIActionsCreators } from '../../../Actions/UIActions';
import { useDispatch, useSelector } from 'react-redux';
import SignInModal from '../Modals/SignIn';
import SignUpModal from '../Modals/SignUp';
import {
  showSignUpModalSelector,
  showLoginModalSelector,
  loadingFooterAndHeaderContentSelector,
  navBarContentSelector,
  showIntelligenceModalSelector,
} from '../../../Reducers/UIReducer';
import { PersonIcon } from './Icons/Person';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import AppConsts from '../../../Constants/AppConsts';
import { normalizeId } from '../../../Utilities/normalizeId';
import ResidetialIntelligenceModal from '../Modals/ResidetialIntelligenceModal';
const { buySideUrl } = AppConsts;

const HeaderMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 100px;
  background-color: ${({ theme, light }) => (light ? theme.light : theme.craftsmanCream)};
  @media (max-width: 992px) {
    height: 64px;
  }
`;

const HamburgerButton = styled.button`
  height: 32px;
  width: 32px;
  cursor: pointer;
  background-color: #00000000;
  outline: transparent solid 2px;
`;

const HamburgerIcon = styled(Hamburger)`
  display: flex;
  width: 1rem;
  padding-top: 8px;
  margin-right: 9px;
`;

const NavBarButtonWithAvatar = styled(NavBarButton)`
  padding-top: 5px;
  padding-right: 5px;
  padding: 4px 4px 0px 11px;
  .user {
    border-radius: 50%;
    background-color: #96b8a3;
    width: 32px;
    height: 32px;
  }
`;

const LogoContainer = styled.a`
  margin-right: 5rem;
`;

const LeftSideContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const NavLinkWithCustomUnderline = styled(NavLink)`
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  font-family: 'National-Regular';
  &:hover {
    text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
    color: #242b26;
  }
`;

const NavLinkStyledButton = styled.button`
  background: none;
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  font-family: 'National-Regular';
  margin-right: 1.5rem;
    margin-top: 0.75rem;
}
  &:hover {
    text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
    color: #242b26;
  }
`;

const CustomDropdownButton = React.forwardRef(({ children, onClick }, ref) => (
  <NavBarButtonWithAvatar
    light
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </NavBarButtonWithAvatar>
));

const Divider = () => (
  <svg viewBox="0 0 152 2" focusable="false" className="chakra-icon css-1k7sfgz" preserveAspectRatio="none">
    <path d="M0 1H495" stroke="#242B26" strokeDasharray="4 4"></path>
  </svg>
);

const StyledDivider = styled(Divider)`
  width: 100%;
  height: 2px;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.darkGreen[900]};
  vertical-align: middle;
  opacity: 1;
`;

const HamburgerContainer = styled.div`
  display: flex;
`;

const Navbar = ({ hideMenuButton = false }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const userData = useSelector(userDetailsSelector);
  const dispatch = useDispatch();
  const showSignInModal = useSelector(showLoginModalSelector);
  const showSignUpModal = useSelector(showSignUpModalSelector);
  const showIntelligenceModal = useSelector(showIntelligenceModalSelector);
  const navBarContent = useSelector(navBarContentSelector);
  const navBarContentLoading = useSelector(loadingFooterAndHeaderContentSelector);
  const isAdminSwitched = userData?.isAdminSwitchUser;
  const { token, role, userId } = userData;
  const swithLocation = userService.getSwitchlocation();
  const isHeaderLight = history.location.pathname.includes(routes.ONBOARDING_V2);

  useEffect(() => {
    if (token) {
      dispatch(userActionCreators.getUserAvatar());
    }
  }, [token]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeSignInModal = () => {
    dispatch(UIActionsCreators.closeLoginModal());
  };

  const closeSignUpModal = () => {
    dispatch(UIActionsCreators.closeSignUpModal());
  };

  const openLogin = () => {
    setIsDrawerOpen(false);
    dispatch(UIActionsCreators.openLoginModal());
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(userActionCreators.logout(userId, token));
    setIsDrawerOpen(false);
  };

  const switchPortal = () => {
    setAdminData(swithLocation, userId);
  };

  const switchModals = () => {
    dispatch(UIActionsCreators.toggleAuthModals());
  };

  const openResidentialInteligenceModal = () => {
    dispatch(UIActionsCreators.openIntelligenceModal());
  };

  return (
    <HeaderMainWrapper light={isHeaderLight}>
      <HeaderContainer className="header-external">
        <LeftSideContainer>
          <LogoContainer href={routes.BUY_SIDE}>
            <Logo />
          </LogoContainer>
          <LinksContainer>
            {navBarContent?.navItems?.length > 0 &&
              !navBarContentLoading &&
              navBarContent.navItems.map((i, idx) => (
                <NavLinkWithCustomUnderline
                  key={idx}
                  href={`${buySideUrl}${i?.url}`}
                  title={i?.name}
                  active={i?.url.includes('sell')}
                  id={normalizeId(i?.name, 'top')}
                >
                  {i?.name}
                </NavLinkWithCustomUnderline>
              ))}
          </LinksContainer>
        </LeftSideContainer>
        {!hideMenuButton && (
          <HamburgerContainer>
            <NavLinkStyledButton onClick={openResidentialInteligenceModal}>Upgrade</NavLinkStyledButton>
            <HamburgerButton className="hamburger-wrapper" onClick={openDrawer}>
              <Hamburger className="hamburger-icon" />
            </HamburgerButton>
            {!token ? (
              <NavBarButton onClick={openLogin} title="Log in" className="navBarButton">
                LOG IN
              </NavBarButton>
            ) : (
              <Dropdown bsPrefix="navBarButton">
                <Dropdown.Toggle as={CustomDropdownButton}>
                  <HamburgerIcon />
                  {userData?.picture ? (
                    <img src={userData.picture} className="user" alt="user avatar" />
                  ) : (
                    <PersonIcon className="user" />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu bsPrefix="dropdown-menu">
                  <Dropdown.Item href={routes.MY_FAVORITES} className="dropdown-item">
                    My Favorites
                  </Dropdown.Item>
                  <Dropdown.Item href={routes.SAVED_SEARCHES} className="dropdown-item">
                    Saved Searches
                  </Dropdown.Item>
                  {role === roleType.WHOLESALE && (
                    <Dropdown.Item href={`${routes.DASHBOARD}`}>My Voucher</Dropdown.Item>
                  )}
                  {!isAdminSwitched && (role === roleType.ADMIN || role === roleType.SuperAdmin) && (
                    <Dropdown.Item className="dropdown-item" href={`${routes.ADMIN_ORDERS}`}>
                      Back to Admin
                    </Dropdown.Item>
                  )}
                  {[
                    roleType.RETAIL,
                    roleType.NetworkUser,
                    roleType.INSTITUTIONAL,
                    roleType.WHOLESALE_CUSTOMER,
                  ].includes(role) && <Dropdown.Item href={`${routes.DASHBOARD}`}>My Listings</Dropdown.Item>}
                  {isAdminSwitched && (
                    <Dropdown.Item className="dropdown-item" onClick={switchPortal}>
                      Switch Portal
                    </Dropdown.Item>
                  )}
                  <StyledDivider />
                  <Dropdown.Item href={routes.SETTINGS} className="dropdown-item-light">
                    Settings
                  </Dropdown.Item>
                  {!isAdminSwitched && (
                    <Dropdown.Item className="dropdown-item-light" href="/" onClick={handleLogout}>
                      Sign Out
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </HamburgerContainer>
        )}
      </HeaderContainer>
      {isDrawerOpen && (
        <NavbarDrawer
          onClose={() => setIsDrawerOpen(false)}
          switchPortal={switchPortal}
          userData={userData}
          logout={handleLogout}
          openLogin={openLogin}
          navBarContent={navBarContent}
          navBarContentLoading={navBarContentLoading}
          buySideUrl={buySideUrl}
        />
      )}
      {showSignInModal && <SignInModal closeModal={closeSignInModal} switchModals={switchModals} />}
      {showSignUpModal && <SignUpModal closeModal={closeSignUpModal} switchModals={switchModals} />}
      {showIntelligenceModal && (
        <ResidetialIntelligenceModal closeModal={closeSignUpModal} switchModals={switchModals} />
      )}
    </HeaderMainWrapper>
  );
};

export default withTheme(Navbar);
