
import React from 'react'
import CurrencyFormat from 'react-currency-format';

export const Entity = {
    Types: {
        ORDER : 'orders',
        USER: 'users',
        MLS: 'mls',
        ORDER_PRODUCT: 'order-products',
        LISTING: 'listings',
        Lookup: {
            MLS_CATEGORY: 'MlsCategory',
            PRODUCT_FEATURE_TYPE: 'ProductFeatureType',
            PRODUCT_TYPE: 'ProductType',
            PROPERTY_TYPE: 'PropertyType',
            USER_CREATION_TYPE: 'UserCreationType',
            USER_TYPE: 'UserType',
            VOUCHER_TYPE: 'VoucherType'
        },
        VOUCHER: 'voucher'
    },
    Config: {
        'orders': {recordKey: 'orderId', title:'Orders', schema: 'OrdersListResponse'},
        'listings' : {recordKey: 'listingId',  title:'Listings', columns: [
            {title:'Status', dataIndex: 'listingStatus', editable: null},
            {title:'MLS Category', dataIndex: 'mlsCategory', editable:{required: true, min:1, max:16, type:'number'}},
            {title:'Property Type', dataIndex: 'propertyType', render: (text,record) => record.propertyType ? record.propertyTypes[record.propertyType] : 'N/A'},
            {title:'List Price', dataIndex: 'listPrice', editable:{required: false, type:'number'},
                render: text => <CurrencyFormat value={text ?? 0} decimalScale={0} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'$'} />},
            {title:'Bedrooms', dataIndex: 'bedrooms', editable:{required: false, type:'number'}},
            {title:'Bathrooms', dataIndex: 'bathrooms', editable:{required: false, type:'number'}},
            {title:'Agent', dataIndex: 'listingAgent.email', render: (text,record) => record.listingAgent && <span>{record.listingAgent.firstName} {record.listingAgent.lastName}</span>},
            {title: 'Actions', render: () =>{}}
        ]},
        'mls' : {recordKey: 'mlsId',  title:'MLS', columns: []},
        'order-products' : {recordKey: 'orderProductId',  title:'Order Products', columns: []},
        'users': {recordKey: 'userId',  title:'Users', columns: []}
    }
};
