import React from 'react';
import { Card, Col, Row, Table } from 'antd';
import { tableItemsPerPage, sortOrder, pageSizeOptions, entityTypes } from '../../../Constants/CommonConstants';
import { v4 } from 'uuid';
import enums from '../../../Constants/Enums';
import Routes from '../../../Constants/Routes';

const {ZERO, THREE} = enums.NUMERIC_VALUE;
class ListingComponent extends React.Component {

  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: tableItemsPerPage,
      total: 0,
      position: false,
    },
    filterObj: {
      activePage: 1,
      sort: '',
      sortBy: 0,
      pageSize: tableItemsPerPage
    },
  };

  /**
     * @description getDerivedStateFromProps is called when there in change in property
     * @param props {Object} props
     */
  static getDerivedStateFromProps ( props ) {
    const updateState = {};
    if ( props.listData ) {
      updateState[ 'data' ] = props.listData;
    }
    if ( props.tableColumns ) {
      updateState[ 'tableColumns' ] = props.tableColumns;
    }
    if ( props.filterObj ) {
      updateState[ 'pagination' ] = {
        current: props.filterObj.activePage,
        pageSize: props.filterObj.pageSize,
        total: props.totalItems > 0 ? props.totalItems : 0,
        position: window.location.pathname === Routes.ADMIN_LISTINGS ? 'both' : false
      };
    }
    updateState['loading'] = props.loading;
    return updateState;
  }

  /**
   * @description
   * handleTableChange is called someone click on the pagination.
   * @param pagination {Object} pagination details
   * @param filter {Object} filters detail
   * @param sorter {Object} sorting column details (Note-> sorter is third parameter so do not remove second blank object)
   */
  handleTableChange = (pagination, {}, sorter) => {
    let sortBy = 0;
    if (sorter.order === sortOrder.asc) {
      sortBy = 1;
    } else if (sorter.order === sortOrder.desc) {
      sortBy = -1;
    }
    const { current, pageSize } = pagination;
    const filterObj = {
      activePage: current,
      sort: sortBy !== 0 ? sorter.columnKey : '',
      sortBy: sortBy,
      pageSize: pageSize
    }
    this.setState( {
      pagination,
      filterObj
    } );
    this.props.handleTableChange( filterObj );
  };

  /**
   * @description
   * showTotal is used to show page and range html
   */
  showTotal = (total, range) => {
    return `Showing ${range[0]} - ${range[1]} of ${total}`;
  }

  /**
   * @description
   * render is used to render HTML
   */
  getClass(filterLength) {
    let className = '';
    if(filterLength === ZERO) {
      className = 'with-no-filter'
    } else if (filterLength > THREE) {
      className = 'with-more-filter'
    }
    return className;
  }

  getClassByActiveEntity(activeEntity) {
    let className = '';
    if(activeEntity === 'listings') {
      className = 'ant-table-body-modify'
    }
    return className;
  }
  render () {
    const { data, pagination, tableColumns} = this.state;
    const { activeEntity, isFilterApplied } = this.props;
    return (
      <Card className={this.getClass(isFilterApplied)}>
        <Row className={this.getClassByActiveEntity(activeEntity)}>
          <Col>
            <Table
              className={activeEntity === entityTypes.listings ? 'listing-form-table' : ''}
              size={'default'}
              bordered={true}
              columns={tableColumns}
              rowKey={record => record.key ? record.key : v4()}
              dataSource={data}
              pagination={
                {
                  ...pagination,
                  showTotal: this.showTotal,
                  pageSizeOptions: pageSizeOptions,
                  showSizeChanger: pagination.total > tableItemsPerPage,
                }
              }
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

export { ListingComponent };
