import { userService } from '../Services/UserService';
import { lookupService } from '../Services/LookupService';
import { alertActions } from '../Actions/ToastActions';
import { staticMessages, entityTypes } from '../Constants/CommonConstants';
import { startLoading, endLoading } from './LoaderActions';
import { saveAs } from 'file-saver';
import { UIActions } from './UIActions';
import { checkResponse, getLoginRedirectPath } from '../Utilities/commonFunctions';
import { gTag } from '../Utilities/utils';
import LogRocket from 'logrocket';
import AppConsts from '../Constants/AppConsts';
import Routes from '../Constants/Routes';
import { history } from '../Utilities/History';

export const actions = {
  LOGIN_REQUESTED: 'LOGIN_REQUESTED',
  LOGIN_RESOLVED: 'LOGIN_RESOLVED',
  LOGIN_ERROR: 'LOGIN_ERROR',
  USER_IMAGE_RESOLVED: 'USER_IMAGE_RESOLVED',
  EXTERNAL_LOGIN_REQUESTED: 'EXTERNAL_LOGIN_REQUESTED',
  EXTERNAL_LOGIN_RESOLVED: 'EXTERNAL_LOGIN_RESOLVED',
  EXTERNAL_LOGIN_NO_USER: 'EXTERNAL_LOGIN_NO_USER',
  EXTERNAL_LOGIN_ERROR: 'EXTERNAL_LOGIN_ERROR',
  CREATE_USER_REQUESTED: 'CREATE_USER_REQUESTED',
  CREATE_USER_RESOLVED: 'CREATE_USER_RESOLVED',
  CREATE_USER_FROM_EXTERNAL_REQUESTED: 'CREATE_USER_FROM_EXTERNAL_REQUESTED',
  CREATE_USER_FROM_EXTERNAL_RESOLVED: 'CREATE_USER_FROM_EXTERNAL_RESOLVED',
  CREATE_USER_FROM_EXTERNAL_ERROR: 'CREATE_USER_FROM_EXTERNAL_ERROR',
  CLEAR_USER: 'CLEAR_USER',
  GET_SETTINGS_REQUESTED: 'GET_SETTINGS_REQUESTED',
  GET_SETTINGS_RESOLVED: 'GET_SETTINGS_RESOLVED',
  S3_TOKEN_REQUESTED: 'S3_TOKEN_REQUESTED',
  S3_TOKEN_RESOLVED: 'S3_TOKEN_RESOLVED',
  LOGOUT_REQUESTED: 'LOGOUT_REQUESTED',
  LOGOUT_RESOLVED: 'LOGOUT_RESOLVED',
  UPDATE_PROFILE_REQUESTED: 'UPDATE_PROFILE_REQUESTED',
  UPDATE_PROFILE_RESOLVED: 'UPDATE_PROFILE_RESOLVED',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_REQUEST_RESOLVED: 'FORGOT_PASSWORD_REQUEST_RESOLVED',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_REQUEST_RESOLVED: 'RESET_PASSWORD_REQUEST_RESOLVED',
  REQUEST_MORE_DETAIL_REQUEST: 'REQUEST_MORE_DETAIL_REQUEST',
  REQUEST_MORE_DETAIL_RESOLVED: 'REQUEST_MORE_DETAIL_RESOLVED',
  GET_DEFAULT_BILLING_ADDRESS_REQUEST: 'GET_DEFAULT_BILLING_ADDRESS_REQUEST',
  GET_DEFAULT_BILLING_ADDRESS_RESOLVED: 'GET_DEFAULT_BILLING_ADDRESS_RESOLVED',
  UPDATE_BILLING_ADDRESS_REQUEST: 'UPDATE_BILLING_ADDRESS_REQUEST',
  UPDATE_BILLING_ADDRESS_REQUEST_RESOLVED: 'UPDATE_BILLING_ADDRESS_REQUEST_RESOLVED',
  LOOKUP_REQUESTED: 'LOOKUP_REQUESTED',
  LOOKUP_RESOLVED: 'LOOKUP_RESOLVED',
  ADD_UPDATE_VOUCHER_REQUESTED: 'ADD_UPDATE_VOUCHER_REQUESTED',
  ADD_UPDATE_VOUCHER_RESOLVED: 'ADD_UPDATE_VOUCHER_RESOLVED',
  ADD_VOUCHER_REQUESTED_V2: 'ADD_VOUCHER_REQUESTED_V2',
  ADD_VOUCHER_RESOLVED_V2: 'ADD_VOUCHER_RESOLVED_V2',
  ADD_VOUCHER_ERROR_V2: 'ADD_VOUCHER_ERROR_V2',
  UPDATE_VOUCHER_REQUESTED_V2: 'UPDATE_VOUCHER_REQUESTED_V2',
  UPDATE_VOUCHER_RESOLVED_V2: 'UPDATE_VOUCHER_RESOLVED_V2',
  UPDATE_VOUCHER_ERROR_V2: 'UPDATE_VOUCHER_ERROR_V2',
  ADD_DOWNLOAD_ZIP_REQUESTED: 'ADD_DOWNLOAD_ZIP_REQUESTED',
  ADD_DOWNLOAD_ZIP_RESOLVED: 'ADD_DOWNLOAD_ZIP_RESOLVED',
  UPDATE_NEWSLETTER_REQUESTED: 'UPDATE_NEWSLETTER_REQUESTED',
  UPDATE_NEWSLETTER_RESOLVED: 'UPDATE_NEWSLETTER_RESOLVED',
  CLEAR_REDIRECT: 'CLEAR_REDIRECT',
  SET_LOGIN_CALLBACK_ACTION: 'SET_LOGIN_CALLBACK_ACTION',
}

export const userActionCreators = {
  login: (email, password, redirectPath) => async (dispatch, getState) => {
    const callbackAction = getState().user.loginCallBackAction;

    dispatch({ type: actions.LOGIN_REQUESTED, payload: email });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let dataJson;

    try {
      const { data } = await userService.login(email, password);
      dataJson = data;
      checkResponse(data, staticMessages.login);
      dataJson.redirectPath = redirectPath || getLoginRedirectPath(data.user.userTypeId);

      dispatch({
        type: actions.LOGIN_RESOLVED,
        payload: { ...dataJson },
      });
      gTag({
        'event': 'sign_in',
        'method': 'email&password'
      });
      dispatch({ type: UIActions.CLOSE_LOGIN_MODAL });
      callbackAction && dispatch(callbackAction);

      if (dataJson.redirectPath && !window.location.pathname.includes(Routes.ONBOARDING_V2)) {
        history.push({ pathname: dataJson.redirectPath });
      } 
    }
    catch (ex) {
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      dispatch({
        type: actions.LOGIN_ERROR,
        payload: { ...dataJson },
      });

      if (ex?.response?.status !== 401) {
        LogRocket.captureException(ex, {
          tags: {
            errorType: 'failed_user_action',
          },
          extra: {
            type: 'user actions login FAILED',
            email,
          },
        });
      }
    }
    dispatch(endLoading('MAIN_LOADER'));
  },
  setLoginCallBackAction: (action) => ({ type: actions.SET_LOGIN_CALLBACK_ACTION, payload: action }),
  getUserAvatar:  () => async (dispatch) => {
    let imageObjectURL = null;

    try {
      const { data, status } = await userService.getUserAvatar();

      if (status === 200) {
        imageObjectURL = URL.createObjectURL(data);
      }
    } catch (ex) {
    }
    dispatch({ type: actions.USER_IMAGE_RESOLVED, payload: imageObjectURL });
  },

  getExternalUser: () => async (dispatch) => {
    dispatch({ type: actions.EXTERNAL_LOGIN_REQUESTED });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let dataJson;

    try {
      const { data } = await userService.getExternalUser();
      dataJson = data;
      dispatch(endLoading('MAIN_LOADER'));
      dispatch({ type: actions.EXTERNAL_LOGIN_RESOLVED, payload: dataJson });
    }
    catch (ex) {
      dispatch(endLoading('MAIN_LOADER'));
      dispatch({ type: actions.EXTERNAL_LOGIN_ERROR, payload: dataJson });
      
      if (ex?.response?.status !== 400 || ex?.response?.status !== 401) {
        LogRocket.captureException(ex, {
          tags: {
              errorType: 'failed_user_action',
          },
          extra: {
              type: actions.EXTERNAL_LOGIN_ERROR,
          },
        });
      } else {
        dispatch({ type: actions.EXTERNAL_LOGIN_NO_USER });
      }
    }
  },

  createUserFromExternal: () => async (dispatch) => {
    dispatch({ type: actions.CREATE_USER_FROM_EXTERNAL_REQUESTED });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let dataJson;

    try {
      const { data } = await userService.createUserFromExternal();
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER'));
      dispatch({ type: actions.CREATE_USER_FROM_EXTERNAL_RESOLVED, payload: dataJson });
    }
    catch (ex) {
      dispatch(endLoading('MAIN_LOADER'));
      dispatch({ type: actions.CREATE_USER_FROM_EXTERNAL_ERROR, payload: dataJson });

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: actions.CREATE_USER_FROM_EXTERNAL_ERROR,
        },
    });
    }
  },
  createUser: (
    { email, password, firstName, lastName, phoneNumber, companyId, userTypeId, cardToken, productId, emailConfirmationRedirectLink, externalCode },
    callback,
    errorCallback) => async (dispatch) => {

    dispatch({ type: actions.CREATE_USER_REQUESTED, payload: { email, firstName, lastName, phoneNumber, companyId, userTypeId, cardToken, productId, emailConfirmationRedirectLink, externalCode } });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;

    try {
      const { data } = await userService.createUser({ email, password, firstName, lastName, phoneNumber, companyId, userTypeId, cardToken, productId, emailConfirmationRedirectLink, externalCode });

      dataJson = data;
      checkResponse(data);
      callback && setTimeout(_ => callback(), 100);
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));
      errorCallback && setTimeout(_ => errorCallback(), 100);
      dispatch(endLoading('MAIN_LOADER'));

      if (ex?.response?.status !== 400) {
        LogRocket.captureException(ex, {
          tags: {
              errorType: 'failed_user_action',
          },
          extra: {
              type: 'user actions createUser FAILED',
          },
      });
      }
    }
    dispatch({ type: actions.CREATE_USER_RESOLVED, isError: isError });

  },
  clearUser: (redirectPath) => async (dispatch) => {
    dispatch({ type: actions.CLEAR_USER, payload: { redirectPath: redirectPath } });
  },

  logout: (userId, token) => async (dispatch) => {

    dispatch({ type: actions.LOGOUT_REQUESTED });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;

    try {
      const { data: { success } } = await userService.logout(userId, token);
      if (!success) {
        isError = true;
        dispatch(alertActions.errorAlert(staticMessages.apiError));
      } else {
        userService.clearLocalUser();
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: actions.LOGOUT_RESOLVED, isError });
      }
    }
    catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));
      
      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions logout FAILED',
        },
    });
    }
  },
  updateProfile: (reqObj, cb) => async (dispatch) => {

    dispatch({ type: actions.UPDATE_PROFILE_REQUESTED, payload: reqObj });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await userService.updateProfile(reqObj);
      dataJson = data;
      checkResponse(data);
      cb();
      dispatch(endLoading('MAIN_LOADER'));
    }
    catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions updateProfile FAILED',
            reqObj
        },
    });
    }
    dispatch({ type: actions.UPDATE_PROFILE_RESOLVED, payload: dataJson, isError: isError });
  },
  forgotPasswordRequest: (userInfo, cb) => async (dispatch) => {

    dispatch({ type: actions.FORGOT_PASSWORD_REQUEST, payload: userInfo });
    dispatch(startLoading('MAIN_LOADER'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await userService.forgotPasswordRequest(userInfo);
      dataJson = data;
      checkResponse(data);
      cb();
      dispatch(endLoading('MAIN_LOADER'));
    }
    catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions forgotPasswordRequest FAILED',
        },
      });
    }
    dispatch({ type: actions.FORGOT_PASSWORD_REQUEST_RESOLVED, payload: dataJson, isError: isError });
  },

  resetPasswordRequest: (userInfo, cb) => async (dispatch) => {

    dispatch({ type: actions.RESET_PASSWORD_REQUEST, payload: userInfo });
    dispatch(startLoading('MAIN_LOADER'));

    let isError = false;
    let dataJson;
    try {
      const { data } = await userService.resetPasswordRequest(userInfo);
      dataJson = data;
      checkResponse(data);
      cb();
      dispatch(endLoading('MAIN_LOADER'));
    }
    catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
          tags: {
              errorType: 'failed_user_action',
          },
          extra: {
              type: 'user actions resetPasswordRequest FAILED',
          },
      });
    }
    dispatch({ type: actions.RESET_PASSWORD_REQUEST_RESOLVED, payload: dataJson, isError: isError });
  },

  requestPropertyDetails: (reqObj, cb) => async (dispatch) => {
    dispatch({ type: actions.REQUEST_MORE_DETAIL_REQUEST, payload: reqObj });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;

    try {
      const { data } = await userService.requestPropertyDetails(reqObj);
      dataJson = data;
      checkResponse(data);
      cb();
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    }
    catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions requestPropertyDetails FAILED',
            reqObj
        },
      });
    }
    dispatch({ type: actions.REQUEST_MORE_DETAIL_RESOLVED, isError: isError, payload: dataJson });
  },

  updateBillingAddress: (reqObj, cb) => async (dispatch) => {
    dispatch({ type: actions.UPDATE_BILLING_ADDRESS_REQUEST, payload: reqObj });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;

    try {
      const { data } = await userService.updateBillingAddress(reqObj);
      dataJson = data;
      checkResponse(data);
      cb();
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    }
    catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions updateBillingAddress FAILED',
            reqObj
        },
      });
    }
    dispatch({ type: actions.UPDATE_BILLING_ADDRESS_REQUEST_RESOLVED, isError: isError, payload: dataJson });
  },

  getDefaultBillingAddress: () => async (dispatch) => {
    dispatch({ type: actions.GET_DEFAULT_BILLING_ADDRESS_REQUEST });
    dispatch(startLoading('MAIN_LOADER1', 'Loading'));
    let isError = false;
    let dataJson;

    try {
      const { data } = await userService.getDefaultBillingAddress();

      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER1', 'Loading'));
    }
    catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER1', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions getDefaultBillingAddress FAILED',
        },
      });
    }
    dispatch({ type: actions.GET_DEFAULT_BILLING_ADDRESS_RESOLVED, isError: isError, payload: dataJson });
  },
  getLookupData: (entity, lookupType) => async (dispatch) => {
    dispatch({ type: actions.LOOKUP_REQUESTED, payload: entity });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await lookupService.get(entity);
      dataJson = data;
      checkResponse(data);
      if (entity === entityTypes.dashboardCoverage && dataJson?.value) {
        const categoryLookUpDataJson = dataJson.value['mlsCategories'] || [];
        const mlsLookUpDataJson = dataJson.value['mls'] || [];
        const companiesLookUpDataJson = dataJson.value['companies'] || [];
        const statesLookUpDataJson = dataJson.value['states'] || [];
        const productTypesLookUpDataJson = dataJson.value['productTypes'] || [];
        const restrictionsLookUpDataJson = dataJson.value['restrictions'] || [];

        const categoryNewArray = categoryLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const companiesNewArray = companiesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const mlsNewArray = mlsLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const statesNewArray = statesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const productNewArray = productTypesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const restrictionsNewArray = restrictionsLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });

        dataJson.value['mlsCategories'] = categoryNewArray;
        dataJson.value['mls'] = mlsNewArray;
        dataJson.value['companies'] = companiesNewArray;
        dataJson.value['states'] = statesNewArray;
        dataJson.value['productTypes'] = productNewArray;
        dataJson.value['restrictions'] = restrictionsNewArray;
      } else if (dataJson?.value) {
        const voucherlookUpDataJson = dataJson.value['voucherTemplates'];
        const companieslookUpDataJson = dataJson.value['companies'];
        const voucherNewArray = voucherlookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['voucherTemplates'] = voucherNewArray;

        if (dataJson.value['companies']) {
          const companiesNewArray = companieslookUpDataJson.map(item => {
            return { value: item.key, label: item.value };
          });
          dataJson.value['companies'] = companiesNewArray;
        }
      }
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions getLookupData FAILED',
            entity,
            lookupType
        },
      });
    }
    dispatch({
      type: actions.LOOKUP_RESOLVED,
      payload: { lookupType: lookupType, value: dataJson?.value },
      isError: isError,
    });
  },

  DownloadZipByAPI: (reqObj) => async dispatch => {
    dispatch({ type: actions.ADD_DOWNLOAD_ZIP_REQUESTED, payload: reqObj });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    const name = "images.zip";
    try {
      const response = await userService.downloadZipApi(reqObj);
      dataJson = response.data;
      if (!dataJson) {
        isError = true;
        dispatch(alertActions.errorAlert(dataJson.message));
      } else {
        saveAs(dataJson,name);
        dispatch(alertActions.successAlert(dataJson.message));
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions DownloadZipByAPI FAILED'
        },
      });
    }
    dispatch({
      type: actions.ADD_DOWNLOAD_ZIP_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  addUpdateVouchers: (voucherId, reqObj, cb) => async dispatch => {
    dispatch({ type: actions.ADD_UPDATE_VOUCHER_REQUESTED, payload: reqObj });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await userService.addUpdateVouchers(voucherId, reqObj);
      dataJson = data;
      checkResponse(data);
      cb();
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'user actions addUpdateVouchers FAILED'
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_VOUCHER_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  addVoucherV2: (request) => async dispatch => {
    dispatch({ type: actions.ADD_VOUCHER_REQUESTED_V2, payload: request });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    try {
      await userService.addVoucherV2(request);
      dispatch(endLoading('MAIN_LOADER'));
      dispatch({ type: actions.ADD_VOUCHER_RESOLVED_V2 });
    } catch (ex) {
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));
      dispatch({ type: actions.ADD_VOUCHER_ERROR_V2 });

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: actions.ADD_VOUCHER_ERROR_V2
        },
      });
    }
  },

  updateVoucherV2: (voucherId, request) => async dispatch => {
    dispatch({ type: actions.UPDATE_VOUCHER_REQUESTED_V2, payload: request });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    try {
      await userService.updateVoucherV2(voucherId, request);
      dispatch(endLoading('MAIN_LOADER'));
      dispatch({ type: actions.UPDATE_VOUCHER_RESOLVED_V2 });
    } catch (ex) {
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));
      dispatch({ type: actions.UPDATE_VOUCHER_ERROR_V2 });

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: actions.UPDATE_VOUCHER_ERROR_V2
        },
      });
    }
  },
  
  RequestEbookDownload: (reqObj) => async (dispatch) => {
    dispatch({ type: actions.UPDATE_NEWSLETTER_REQUESTED, payload: reqObj });

    let isError = false;
    let dataJson;
    try {
      const { data } = await userService.RequestEbookDownload(reqObj);
      dataJson = data;
      checkResponse(data);
    }
    catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
          type: 'user actions RequestEbookDownload FAILED'
        },
      });
    }
    dispatch({ type: actions.UPDATE_NEWSLETTER_REQUESTED, payload: dataJson, isError: isError });
  },
  clearRedirect: () => ({ type: actions.CLEAR_REDIRECT }),
  joinResidentialIntelligenceList: (data, successCb) => async dispatch => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    try {
      await userService.joinResidentialIntelligenceList({...data, listName: 'PremiumSellers'});
      dispatch(endLoading('MAIN_LOADER'));
      successCb && successCb();
      dispatch(alertActions.successAlert('Request sent'));
    } catch (ex) {
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_user_action',
        },
        extra: {
            type: 'joinResidentialIntelligenceList',
        },
      });
    }
  },
};
