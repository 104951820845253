import LogRocket from 'logrocket';
import { startLoading, endLoading } from '../../LoaderActions';
import { removeNullUndefined } from '../../../Utilities/commonFunctions';
import { voucherService } from '../../../Services/V2/VoucherService';
import { vouchersFiltersDefault } from '../../../Reducers/V2/VouchersReducer';

export const VouchersActions = {
    VOUCHERS_REQUESTED: 'VOUCHERS_REQUESTED',
    VOUCHERS_RESOLVED: 'VOUCHERS_RESOLVED',
    VOUCHERS_FAILED: 'VOUCHERS_FAILED',
    SET_VOUCHERS_FILTERS: 'SET_VOUCHERS_FILTERS',
    CLEAR_VOUCHERS_FILTERS: 'CLEAR_VOUCHERS_FILTERS'
};

export const vouchersCreators = {
    setVouchersFilters: (filters) => async (dispatch) => {
        dispatch({ type: VouchersActions.SET_VOUCHERS_FILTERS, payload: filters });
    },
    clearVouchersFilters: () => async (dispatch) => {
        dispatch({ type: VouchersActions.CLEAR_VOUCHERS_FILTERS });
    },
    getVouchers: (userId, params = vouchersFiltersDefault) => async (dispatch, getState) => {
        dispatch({ type: VouchersActions.VOUCHERS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const paramsObj = params;

            const { data } = await voucherService.getVouchersCreatedByUser(userId, removeNullUndefined(paramsObj));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: VouchersActions.VOUCHERS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_vouchers_action',
                },
                extra: {
                    type: VouchersActions.VOUCHERS_FAILED,
                },
            });
        }
    }
};
