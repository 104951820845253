import { onboardingActions } from "../Actions/OnboardingActionsV2";
import { CashOfferActions } from "../Actions/V2/CashOfferActions";
import { ownerTypes, ownershipTypes } from "../Constants/CommonConstants";
import { onboardingService } from '../Services/OnboardingService';
import { getPropertyOwnerFields } from "../Utilities/utils";
const windowUrl = window.location.search;
const params = new URLSearchParams(windowUrl);
const listingId = params.get('listing_id');
const urlMarketId = params.get('market');


const initialState = {
    step: 1,
    plansFetching: false,
    draftListingCreating: false,
    fetchingDraftListing: false,
    loadingListingProducts: false,
    draftListingId: listingId || null,
    manualAddressEnter: false,
    isAddressCoverageable: true,
    isAddressChecked: false,
    selectedAddress: null,
    images: [],
    imagesUploadingProgress: {},
    deletingImages: null,
    changingImagesOrder: null,
    planData: {
        plans: [],
        planFeatures: [],
        brokerInfo: {}
    },
    selectedPlan: {},
    averagePrice: null,
    // market - id of MLS which covers this address.
    // marketId is required parameter for average price api & inactive listing api
    market: urlMarketId || null,
    companyType: null,
    mlsId: null,
    inactiveListings: null,
    currentInactiveListing: null,
    agentCommission: 30,
    propertyPrice: null,
    estimatePrice: null,
    bathrooms: 0,
    bedrooms: 0,
    squareFeet: null,
    acres: null,
    propertyTypeId: null,
    mlsCategoryId: null,
    totalSaving: null, // property Price * 0.025
    ownershipType: ownershipTypes.oneOwner,
    fetchingListingForms: false,
    listingForms: [],
    listingId: 0,
    propertyDetailsForm: null,
    activeListingFormIndex: null,
    draftFormsFields: [], // form fields  for step 4 
    listingFormsFields: {}, // form fields for step 10
    submitingListingforms: false,
    description: null,
    typeAndCategorySelectDisabled: false,
    requestedVoucher: null,
    redeemedVoucher: null,
    voucherEnter: false,
    voucherNotFound: false,
    addressSearchInputValue: '',
    addressSearchInputError: '',
    showInactiveListingsListModal: false,
    progress: {},
    showNetworkPlanInfoPopup: false,
    updatingDraftListing: false,
    showDuplicateListingAddress: false,
    propertyOwners: [getPropertyOwnerFields(ownerTypes.individual)],
    titleCompany: null,
    titleCompanyId: null,
    titleCompanyName: null,
    titleCompanyEmail: null,
    titleCompanyPreference: null,
    cashOfferId: null,
    unassignedCashOffer: null
};

export const onboardingReducerV2 = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case onboardingActions.SELECT_ADDRESS_V2:
            return {
                ...state,
                selectedPlan: {},
                planData: { plans: [], planFeatures: [], brokerInfo: {} },
                selectedAddress: action.payload,
                addressSearchInputValue: action.payload?.formatted || '',
                addressSearchInputError: '',
            };
        case onboardingActions.SET_SEARCH_INPUT_VALUE_V2:
            return {
                ...state,
                addressSearchInputValue: action.payload,
            };

        case onboardingActions.SET_SEARCH_INPUT_ERROR_V2:
            return {
                ...state,
                addressSearchInputError: action.payload,
            };

        case onboardingActions.CLEAR_ADDRESS_V2:
            onboardingService.clearLocal("redeemedVoucher");
            return {
                ...state,
                selectedPlan: {},
                planData: { plans: [], planFeatures: [], brokerInfo: {} },
                selectedAddress: null,
                isAddressCoverageable: true,
                isAddressChecked: false,
                inactiveListings: null,
                currentInactiveListing: null,
                averagePrice: null,
                propertyPrice: null,
                bathrooms: null,
                bedrooms: null,
                squareFeet: null,
                acres: null,
                propertyTypeId: null,
                mlsCategoryId: null,
                redeemedVoucher: null,
                addressSearchInputValue: '',
                addressSearchInputError: '',
            };

        case onboardingActions.PLANS_REQUESTED_V2:

            onboardingService.clearLocal("selectedPlan");
            return {
                ...state,
                selectedPlan: {},
                planData: {
                    plans: [],
                    planFeatures: [],
                    brokerInfo: {},
                },
                plansFetching: true,
            };

        case onboardingActions.CHECK_ADDRESS_COVERAGE_REQUESTED_V2:
            return {
                ...state,
                isAddressCoverageable: true,
                isAddressChecked: false,
            };

        case onboardingActions.CHECK_ADDRESS_COVERAGE_RESOLVED_V2:
        case onboardingActions.CHECK_ADDRESS_COVERAGE_FAILED_V2:
            return {
                ...state,
                isAddressCoverageable: action.payload.isAddressCoverageable,
                isAddressChecked: true,
                market: action.payload.market,
                companyType: action.payload.companyType,
                mlsId: action.payload.mlsId,
            };

        case onboardingActions.INACTIVE_LISTINGS_REQUESTED_V2:
            return { ...state }; // add loader
        case onboardingActions.INACTIVE_LISTINGS_RESOLVED_V2:
        case onboardingActions.INACTIVE_LISTINGS_FAILED_V2:
            return {
                ...state,
                inactiveListings: action.payload
            };
        case onboardingActions.INACTIVE_LISTING_REQUESTED_V2:
            return { ...state }; // add loader
        case onboardingActions.INACTIVE_LISTING_RESOLVED_V2:
        case onboardingActions.INACTIVE_LISTING_FAILED_V2:
            return {
                ...state,
                currentInactiveListing: action.payload
            };

        case onboardingActions.SET_MANUAL_ADDRESS_ENTER_V2:

            return {
                ...state,
                manualAddressEnter: action.payload,
                selectedAddress: null,
                isAddressCoverageable: true,
                isAddressChecked: false,
                inactiveListings: null,
                currentInactiveListing: null
            };

        case onboardingActions.AVERAGE_PRICE_RESOLVED_V2:

            return { ...state, averagePrice: action.payload };

        case onboardingActions.AVERAGE_PRICE_REQUESTED_V2:
        case onboardingActions.AVERAGE_PRICE_FAILED_V2:

            return { ...state, averagePrice: null };

        case onboardingActions.SET_AGENT_COMISSION_V2:

            return { ...state, agentCommission: action.payload };

        case onboardingActions.SET_PROPERTY_PRICE_V2:

            return { ...state, propertyPrice: action.payload.price };

        case onboardingActions.SET_TOTAL_SAVING_V2:

            return { ...state, totalSaving: action.payload.totalSaving };

        case onboardingActions.SET_VOUCHER_ENTER_V2:
            return {
                ...state,
                voucherEnter: action.payload,
                requestedVoucher: null,
                voucherNotFound: false,
            };

        case onboardingActions.VOUCHER_REDEEM_V2:
            onboardingService.setLocal("redeemedVoucher", action.payload);
            return {
                ...state,
                voucherEnter: false,
                isAddressCoverageable: true,
                isAddressChecked: true,
                market: action.payload.marketId,
                redeemedVoucher: action.payload,
                requestedVoucher: null,
                voucherNotFound: false,
            };

        case onboardingActions.VALIDATE_VOUCHER_RESOLVED_V2:
            return { ...state, requestedVoucher: action.payload, voucherNotFound: false, };

        case onboardingActions.ESTIMATED_PRICE_RESOLVED_V2:

            return { ...state, estimatePrice: action.payload };

        case onboardingActions.ESTIMATED_PRICE_FAILED_V2:
        case onboardingActions.ESTIMATED_PRICE_REQUESTED_V2:

            return { ...state, estimatePrice: null };

        case onboardingActions.SET_SQUARES_V2:
            return { ...state, squareFeet: action.payload };

        case onboardingActions.SET_PROPERTY_TYPE_V2:
            return { ...state, propertyTypeId: action.payload };

        case onboardingActions.SET_MLS_CATEGORY_V2:
            return { ...state, mlsCategoryId: action.payload };

        case onboardingActions.SET_BATHROOMS_V2:

            return { ...state, bathrooms: action.payload };

        case onboardingActions.SET_BEDROOMS_V2:

            return { ...state, bedrooms: action.payload };

        case onboardingActions.CLOSE_MODAL_POPUP_V2:

            return { ...state, isAddressCoverageable: false };

        case onboardingActions.CREATE_DRAFT_LISTING_REQUESTED_V2:

            return { ...state, draftListingCreating: true }

        case onboardingActions.CREATE_DRAFT_LISTING_RESOLVED_V2:

            return {
                ...state,
                draftListingCreating: false,
                draftListingId: action.payload
            }
        case onboardingActions.UPDATE_DRAFT_FORM_FIELDS_V2:

            return {
                ...state,
                draftFormsFields: action.payload
            }

        case onboardingActions.CREATE_DRAFT_LISTING_FAILED_V2:

            return {
                ...state,
                draftListingCreating: false,
                draftListingId: null,
            }

        case onboardingActions.GET_DRAFT_LISTING_BY_ID_REQUESTED_V2:

            return { ...state, fetchingDraftListing: true }

        case onboardingActions.GET_PROPERTY_DETAILS_FORM_REQUESTED_V2:
        case onboardingActions.GET_PROPERTY_DETAILS_FORM_FAILED_V2:
            return { ...state }
        case onboardingActions.GET_PROPERTY_DETAILS_FORM_RESOLVED_V2:
            return {
                ...state,
                propertyDetailsForm: action.payload
            };

        case onboardingActions.GET_DRAFT_LISTING_BY_ID_RESOLVED_V2:
            return {
                ...state,
                fetchingDraftListing: false,
                step: Number(action.payload.step) === 8 ? 7 : action.payload.step,
                draftListingId: action.payload.id,
                manualAddressEnter: !action.payload.listingData.placeId,
                selectedAddress: {
                    placeId: action.payload.listingData.placeId,
                    address1: action.payload.listingData.address1,
                    address2: action.payload.listingData.address2,
                    city: action.payload.listingData.city,
                    state: action.payload.listingData.state,
                    county: action.payload.listingData.county,
                    zip: action.payload.listingData.zip,
                    formatted: action.payload.listingData.formatted,
                    location: {
                        lat: action.payload.listingData.latitude,
                        lng: action.payload.listingData.longitude,
                    },
                    ...action.payload.listingData.address,
                },
                images: action.payload.images.sort((a, b) => a.order - b.order),
                agentCommission: action.payload.listingData.agentCommission,
                propertyPrice: action.payload.listingData.price,
                bathrooms: action.payload.listingData.bathRooms,
                bedrooms: action.payload.listingData.bedRooms,
                squareFeet: action.payload.listingData.squares,
                acres: action.payload.listingData.acres,
                averagePrice: action.payload.listingData.averagePriceFrom && action.payload.listingData.averagePriceTo
                    ? { from: action.payload.listingData.averagePriceFrom, to: action.payload.listingData.averagePriceTo }
                    : null,
                estimatePrice: action.payload.listingData.estimatePrice,
                typeAndCategorySelectDisabled: (action.payload.listingData.propertyTypeId && action.payload.listingData.mlsCategoryId),
                propertyTypeId: action.payload.listingData.propertyTypeId,
                mlsCategoryId: action.payload.listingData.mlsCategoryId,
                //HJ data
                halfBaths: action.payload.listingData.halfBaths,
                fullBaths: action.payload.listingData.fullBaths,
                subdivision: action.payload.listingData.subdivision,
                floors: action.payload.listingData.floors,
                yearBuilt: action.payload.listingData.yearBuilt,
                architectureStyle: action.payload.listingData.architectureStyle,
                schoolDistrict: action.payload.listingData.schoolDistrict,
                primarySchool: action.payload.listingData.primarySchool,
                middleSchool: action.payload.listingData.middleSchool,
                redeemedVoucher: action.payload.listingData.voucherCode ? {
                    voucherCode: action.payload.listingData.voucherCode
                } : null,
                highSchool: action.payload.listingData.highSchool,
                listingSeller: action.payload.listingData.listingSeller,
                ownershipType: action.payload.listingData.ownershipType.type,
                draftFormsFields: action.payload.listingData.draftFormsFields,
                listingId: action.payload.listingId,
                description: action.payload.listingData.description,
                totalSaving: (Number(action?.payload?.listingData?.price || 0) * 0.025).toFixed(0),
                progress: action?.payload?.progress,
                userId: action.payload.userId,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                phone: action.payload.phone,
                email: action.payload.email,
                addressSearchInputValue: action.payload.listingData.formatted,
                propertyOwners: action.payload.listingData.ownershipType.ownersData,
                titleCompanyId: action.payload.listingData.titleCompanyId,
                titleCompanyName: action.payload.listingData.titleCompanyName,
                titleCompanyEmail: action.payload.listingData.titleCompanyEmail,
                titleCompanyPreference: action.payload.listingData.titleCompanyPreference,
                cashOfferId: action.payload.cashOfferId,
                productType: action.payload.productType
            }

        case onboardingActions.GET_DRAFT_LISTING_BY_ID_FAILED_V2:

            return {
                ...state,
                fetchingDraftListing: false,
            }

        case onboardingActions.SET_LISTING_STEP_V2:
            return { ...state, step: action.payload }

        case onboardingActions.SET_INACTIVE_LISTING_V2:
            return {
                ...state,
                selectedAddress: {
                    ...state.selectedAddress,
                    address1: action?.payload?.address || state.selectedAddress.address1
                },
                bedrooms: action.payload.beds,
                bathrooms: action.payload.baths,
                squareFeet: action.payload.size,
                currentInactiveListing: action.payload,
                halfBaths: action?.payload?.halfBaths,
                fullBaths: action?.payload?.fullBaths,
                subdivision: action?.payload?.subdivision,
                yearBuilt: action?.payload?.yearBuilt,
                architectureStyle: action?.payload?.architectureStyle,
                schoolDistrict: action?.payload?.schoolDistrict,
                primarySchool: action?.payload?.primarySchool,
                middleSchool: action?.payload?.middleSchool,
                highSchool: action?.payload?.highSchool,
                showInactiveListingsListModal: false,
            }

        case onboardingActions.ADD_LISTING_PHOTO_BY_ID_REQUESTED_V2:
            const imagesArray = [...state.images];
            imagesArray[action.payload.order] = action.payload;

            return {
                ...state,
                imagesUploadingProgress: {
                    ...state.imagesUploadingProgress,
                    [action.payload.order]: true,
                },
                images: imagesArray,
            }

        case onboardingActions.ADD_LISTING_PHOTO_BY_ID_FAILED_V2:
            return {
                ...state,
                imagesUploadingProgress: {
                    ...state.imagesUploadingProgress,
                    [action.payload]: false,
                },
            }

        case onboardingActions.ADD_LISTING_PHOTO_BY_ID_RESOLVED_V2:
            const imagesArr = [...state.images];
            imagesArr[action.payload.order] = {
                ...action.payload.data,
                order: action.payload.order
            };
            return {
                ...state,
                imagesUploadingProgress: {
                    ...state.imagesUploadingProgress,
                    [action.payload.order]: false,
                },
                images: imagesArr,
            }

        case onboardingActions.GET_LISTING_PHOTO_BY_ID_RESOLVED_V2:
            return {
                ...state,
                images: action.payload,
            }

        case onboardingActions.DELETE_LISTING_PHOTO_BY_ID_REQUESTED_V2:

            return {
                ...state,
                deletingImages: true,
            }

        case onboardingActions.DELETE_LISTING_PHOTO_BY_ID_RESOLVED_V2:

        return {
            ...state,
            images: action.payload,
            deletingImages: false,
        }

        case onboardingActions.DELETE_LISTING_PHOTO_BY_ID_FAILED_V2:

            return {
                ...state,
                deletingImages: false,
            }

        case onboardingActions.REORDER_LISTING_PHOTO_BY_ID_REQUESTED_V2:

            return {
                ...state,
                changingImagesOrder: true,
            }

        case onboardingActions.REORDER_LISTING_PHOTO_BY_ID_RESOLVED_V2:

            return {
                ...state,
                images: action.payload,
                changingImagesOrder: false,
            }

        case onboardingActions.REORDER_LISTING_PHOTO_BY_ID_FAILED_V2:

            return {
                ...state,
                changingImagesOrder: false,
            }

        case onboardingActions.SET_PROPERTY_HELD_V2:

            return {
                ...state,
                ownershipType: action.payload
            }

        case onboardingActions.GET_LISTING_PRODUCTS_REQUESTED_V2:

            return {
                ...state,
                loadingListingProducts: true,
            }

        case onboardingActions.GET_LISTING_PRODUCTS_RESOLVED_V2:

        return {
            ...state,
            loadingListingProducts: false,
            planData: {
                brokerInfo: action.payload?.brokerInfo ?? {},
                plans: action.payload.plans,
                planFeatures: action.payload.planFeatures,
            },
        }

        case onboardingActions.GET_LISTING_PRODUCTS_FAILEDV2:

            return {
                ...state,
                loadingListingProducts: false,
            }

        case onboardingActions.SET_SELECTED_PLAN_V2:

            return {
                ...state,
                selectedPlan: action.payload,
            }

        case onboardingActions.GET_LISTING_REQUESTED_V2:

            return {
                ...state,
                fetchingListingForms: true,
                listingForms: [],
                listingFormsFields: {},
            }

        case onboardingActions.GET_LISTING_RESOLVED_V2:

            return {
                ...state,
                fetchingListingForms: false,
                listingForms: action.payload.forms,
                listingFormsFields: action.payload.formsFields,
            }

        case onboardingActions.GET_LISTING_FAILED_V2:

            return {
                ...state,
                fetchingListingForms: false,
                listingForms: [],
            }

        case onboardingActions.CREATE_LISTING_RESOLVED_V2:
            onboardingService.clearLocal("redeemedVoucher");
            return {
                ...state,
                listingId: action.payload,
                redeemedVoucher: null
            }

        case onboardingActions.SET_ACTIVE_LISTING_FORM_V2:
            return {
                ...state,
                activeListingFormIndex: action.payload,
            }

        case onboardingActions.UPDATE_LISTING_FORMS_FIELDS_V2:
            return {
                ...state,
                listingFormsFields: action.payload
            }

        case onboardingActions.SUBMIT_MLS_FORM_REQUESTED_V2:
            return {
                ...state,
                submitingListingforms: true,
            }

        case onboardingActions.SUBMIT_MLS_FORM_RESOLVED_V2:
        case onboardingActions.SUBMIT_MLS_FORM_FAILED_V2:
            return {
                ...state,
                submitingListingforms: false,
            }

        case onboardingActions.SET_DESCRIPTION_V2:
            return {
                ...state,
                description: action.payload,
            }

        case onboardingActions.RESET_IS_ADDRESS_CHECKED_V2:
            return {
                ...state,
                isAddressCoverageable: true,
                isAddressChecked: false,
            }

        case onboardingActions.SET_TYPE_AND_CATEGORY_SELECT_DISABLED_V2:
            return {
                ...state,
                typeAndCategorySelectDisabled: action.payload,
            }

        case onboardingActions.VALIDATE_VOUCHER_ERROR_V2:
            return {
                ...state,
                voucherNotFound: action.payload,
            }

        case onboardingActions.CLEAR_LISTING_DATA_V2:
            return {
                ...state,
                step: 1,
                plansFetching: false,
                draftListingCreating: false,
                fetchingDraftListing: false,
                loadingListingProducts: false,
                draftListingId: null,
                manualAddressEnter: false,
                isAddressCoverageable: true,
                isAddressChecked: false,
                selectedAddress: null,
                images: [],
                imagesUploadingProgress: {},
                deletingImages: null,
                changingImagesOrder: null,
                planData: {
                    plans: [],
                    planFeatures: [],
                    brokerInfo: {},
                },
                selectedPlan: {},
                averagePrice: null,
                market: null,
                inactiveListings: null,
                currentInactiveListing: null,
                agentCommission: 30,
                propertyPrice: null,
                estimatePrice: null,
                bathrooms: null,
                bedrooms: null,
                squareFeet: null,
                acres: null,
                propertyTypeId: null,
                mlsCategoryId: null,
                totalSaving: null, // property Price * 0.025
                ownershipType: null,
                fetchingListingForms: false,
                listingForms: [],
                listingId: 0,
                propertyDetailsForm: null,
                activeListingFormIndex: null,
                draftFormsFields: [], // form fields  for step 4 
                listingFormsFields: {}, // form fields for step 10
                submitingListingforms: false,
                description: null,
                typeAndCategorySelectDisabled: false,
                requestedVoucher: null,
                redeemedVoucher: null,
                voucherEnter: false,
                voucherNotFound: false,
                addressSearchInputValue: '',
                addressSearchInputError: '',
                propertyOwners: [getPropertyOwnerFields(ownerTypes.individual)],
                cashOfferId: null,
                titleCompanyEmail: null,
                titleCompanyName: null,
                titleCompanyId: null,
                titleCompanyPreference: null,
            }

        case onboardingActions.SET_SHOW_INACTIVE_LISTINGS_LIST_MODAL_V2:
            return {
                ...state,
                showInactiveListingsListModal: action.payload,
            }
        case onboardingActions.SET_ACRES_V2:
            return { ...state, acres: action.payload };
        case onboardingActions.SET_DRAFT_LISTING_DEFAULT_VALUES_V2:
            return {...state, ...action.payload}

        case onboardingActions.SET_SHOW_NETWORK_PLAN_INFO_POPUP_V2:
            return {...state, showNetworkPlanInfoPopup: action.payload}

        case onboardingActions.UPDATE_DRAFT_LISTING_REQUESTED_V2:
            return {...state, updatingDraftListing: true}

        case onboardingActions.UPDATE_DRAFT_LISTING_RESOLVED_V2:
            return {...state, productType: action.payload, updatingDraftListing: false }
        case onboardingActions.UPDATE_DRAFT_LISTING_FAILED_V2:
            return {...state, updatingDraftListing: false }

        case onboardingActions.CHANGE_ADDRESS_V2:
            return {
                ...state,
                selectedAddress: {
                    ...state.selectedAddress,
                    address1: action.payload.address1,
                    address2: action.payload.address2,
                    formatted: action.payload.formatted,
                }
            }

        case onboardingActions.GET_LISTING_PROGRESS_RESOLVED_V2:
            return {
                ...state,
                progress: action.payload,
            }
        case onboardingActions.SET_SHOW_LISTING_DUPLICATE_ADDRESS_MODAL:
            return {
                ...state,
                showDuplicateListingAddressModal: action.payload,
            }

        case onboardingActions.SET_PROPERTY_OWNERS:
            return {
                ...state,
                propertyOwners: action.payload,
            }
        case onboardingActions.GET_TITLE_COMPANY:
            return {
                ...state,
                titleCompany: action.payload
            }
        case onboardingActions.SET_PROPERTY_TITLE_COMANY:
            return {
                ...state,
                titleCompanyId: action.payload.titleCompanyId,
                titleCompanyName: action.payload.titleCompanyName,
                titleCompanyEmail: action.payload.titleCompanyEmail,
                titleCompanyPreference: action.payload.titleCompanyPreference,
            }
        case CashOfferActions.ADD_CASH_OFFER_RESOLVED:
            return { ...state, cashOfferId: action.payload };
        case onboardingActions.GET_UNASSIGNED_CASH_OFFER_RESOLVED:
            return { ...state, unassignedCashOffer: action.payload };
        case onboardingActions.UPDATE_DRAFT_LISTING_CASH_OFFER_STATUS:
                return { ...state, cashOfferId: action.payload };
        default: return state;
    }
};

export const selectedAddressSelector = state => state.propertyListing.selectedAddress;
export const planDataSelector = state => state.propertyListing.planData;
export const isFetchingSelector = state => state.propertyListing.isFetching;
export const voucherCodeDetailsSelector = state => state.propertyListing.voucherCodeData || {};
export const manualAddressEnterSelector = state => state?.propertyListing?.manualAddressEnter;
export const voucherEnterSelector = state => state?.propertyListing?.voucherEnter;
export const averagePriceSelector = state => state?.propertyListing?.averagePrice;
export const inactiveListingsSelector = state => state?.propertyListing?.inactiveListings;
export const agentComissionSelector = state => state?.propertyListing?.agentCommission;
export const propertyPriceSelector = state => state?.propertyListing?.propertyPrice;
export const estimatePriceSelector = state => state?.propertyListing?.estimatePrice;
export const bathroomsSelector = state => state?.propertyListing?.bathrooms;
export const bedroomsSelector = state => state?.propertyListing?.bedrooms;
export const squareFeetSelector = state => state?.propertyListing?.squareFeet;
export const totalSavingSelector = state => state?.propertyListing?.totalSaving;
export const plansFetchingSelector = state => state?.propertyListing?.plansFetching;
export const marketSelector = state => state?.propertyListing?.market;
export const listingStepSelector = state => state?.propertyListing?.step;
export const draftListingIdSelector = state => state?.propertyListing?.draftListingId;
export const listingImagesSelector = state => state?.propertyListing?.images;
export const imagesUploadingProgressSelector = state => state?.propertyListing?.imagesUploadingProgress;
export const propertyHeldSelector = state => state?.propertyListing?.ownershipType;
export const plansSelector = state => state?.propertyListing?.planData?.plans;
export const plansFeaturesSelector = state => state?.propertyListing?.planData?.planFeatures;
export const changingImagesOrderSelector = state => state?.propertyListing?.changingImagesOrder;
export const deletingImagesSelector = state => state?.propertyListing?.deletingImages;
export const fetchingDraftListingSelector = state => state?.propertyListing?.fetchingDraftListing;
export const fetchingListingFormsSelector = state => state?.propertyListing?.fetchingListingForms;
export const listingFormsSelector = state => state?.propertyListing?.listingForms;
export const activeListingFormIndexSelector = state => state?.propertyListing?.activeListingFormIndex;
export const currentInactiveListingSelector = state => state?.propertyListing?.currentInactiveListing;
export const propertyTypeIdSelector = state => state?.propertyListing?.propertyTypeId;
export const mlsCategoryIdSelector = state => state?.propertyListing?.mlsCategoryId;
export const propertyDetailsFormSelector = state => state?.propertyListing?.propertyDetailsForm;
export const draftFormsFieldsSelector = state => state?.propertyListing?.draftFormsFields;
export const listingIdSelector = state => state?.propertyListing?.listingId;
export const listingFormsFieldsSelector = state => state?.propertyListing?.listingFormsFields;
export const submitingListingformsSelector = state => state?.propertyListing?.submitingListingforms;
export const descriptionListingformsSelector = state => state?.propertyListing?.description;
export const requestedVoucherSelector = state => state?.propertyListing?.requestedVoucher;
export const redeemedVoucherSelector = state => state?.propertyListing?.redeemedVoucher || onboardingService.getLocal("redeemedVoucher");
export const isAddressCoverageableSelector = state => state?.propertyListing?.isAddressCoverageable || !!redeemedVoucherSelector(state);
export const isAddressCheckedSelector = state => state?.propertyListing?.isAddressChecked || !!redeemedVoucherSelector(state);
export const voucherCodeSelector = state => state?.propertyListing?.voucherCode;
export const typeAndCategorySelectDisabledSelector = state => state.propertyListing.typeAndCategorySelectDisabled;
export const voucherNotFoundSelector = state => state.propertyListing.voucherNotFound;
export const listingProgressSelector = state => state.propertyListing.progress;
export const addressSearchInputSelector = state => state.propertyListing.addressSearchInputValue;
export const addressSearchInputErrorSelector = state => state.propertyListing.addressSearchInputError;
export const showInactiveListingsListModalSelector = state => state.propertyListing.showInactiveListingsListModal;
export const acresSelector = state => state.propertyListing.acres;
export const companyTypeSelector = state => state.propertyListing.companyType;
export const showNetworkPlanInfoPopupSelector = state => state.propertyListing.showNetworkPlanInfoPopup;
export const networkBrokerSelector = state => state.propertyListing?.planData?.brokerInfo;
export const updatingDraftListingSelector = state => state.propertyListing.updatingDraftListing;
export const draftListingCreatingSelector = state => state.propertyListing.draftListingCreating;
export const showDuplicateListingAddressModalSelector = state => state.propertyListing.showDuplicateListingAddressModal;
export const propertyOwnersSelector = state => state.propertyListing.propertyOwners;
//export const titleCompanySelector = state => state.propertyListing.titleCompany;
export const cashOfferIdSelector = state => state.propertyListing.cashOfferId;
export const titleCompanyIdSelector = state => state.propertyListing.titleCompanyId;
export const titleCompanyNameSelector = state => state.propertyListing.titleCompanyName;
export const titleCompanyEmailSelector = state => state.propertyListing.titleCompanyEmail;
export const unassignedCashOfferSelector = state => state.propertyListing.unassignedCashOffer;
export const productTypeSelector = state => state.propertyListing.productType;
export const titleCompanyPreferenceSelector = state => state.propertyListing.titleCompanyPreference;
