import React from 'react';
import { Button } from 'antd';

import Select, { createFilter } from 'react-select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companiesSelector, officeUsersSelector, productsSelector } from '../../../../../Reducers/LookupReducer';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { cursorStyles, reactSelectFilters } from '../../../../../Constants/CommonConstants';
import { ordersFiltersSelector } from '../../../../../Reducers/V2/AdminOrdersReducer';
import { ordersCreators } from '../../../../../Actions/V2/Admin/OrdersActions';

const OrdersFilters = () => {
    const dispatch = useDispatch();
    const filters = useSelector(ordersFiltersSelector);
    const officeUsers = useSelector(officeUsersSelector);
    const companies = useSelector(companiesSelector);
    const products = useSelector(productsSelector);

    const companiesOptions = companies.map(x => ({ value: x.id, label: x.name }));
    const productsOptions = products.map(x => ({ value: x.id, label: x.name }));
    const stepsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(step => ({ value: step, label: `Step ${step}` }));
    const archivedOptions = [
        { value: null, label: `All` },
        { value: true, label: `Archived` },
        { value: false, label: `Not Archived` }
    ];
    const officeUsersOptions = [
        { value: null, label: 'All' },
        ...officeUsers
    ];

    useEffect(() => {
        dispatch(lookupActionsCreators.getProducts(1));
        dispatch(lookupActionsCreators.getCompanies());
        dispatch(lookupActionsCreators.getOfficeUsers());
    }, []);

    const handleChange = (value) => {
        dispatch(ordersCreators.setOrdersFilters(value));
    }

    const applyFilters = () => {
        dispatch(ordersCreators.getOrders(filters));
    }

    const clearFilters = () => {
        dispatch(ordersCreators.clearOrdersFilters());
        dispatch(ordersCreators.getOrders());
    }

    return (
        <div className={`admin-filter-wrapper more-filter`}>
            <div className={`filter-bar`}>
                <div className="filter-bar-left">
                    <div className='filter-dropdown for-custom-select'>
                        <label>Company</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Company'
                            value={companiesOptions.find(x => x.value === filters.companyId) || null}
                            onChange={e => handleChange({ ...filters, companyId: e.value })}
                            options={companiesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Plan</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Plan'
                            value={productsOptions.find(x => x.value === filters.planId) || null}
                            onChange={e => handleChange({ ...filters, planId: e.value })}
                            options={productsOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Step</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Step'
                            value={stepsOptions.find(x => x.value === filters.currentStep) || null}
                            onChange={e => handleChange({ ...filters, currentStep: e.value })}
                            options={stepsOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Assigned</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Assigned'
                            value={officeUsersOptions.find(x => x.value === filters.assignedToId)}
                            onChange={e => handleChange({ ...filters, assignedToId: e.value })}
                            options={officeUsersOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Archived</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Assigned'
                            value={archivedOptions.find(x => x.value === filters.isArchived)}
                            onChange={e => handleChange({ ...filters, isArchived: e.value })}
                            options={archivedOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                </div>
                <div className='form-group search-buttons'>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => applyFilters()}>
                        Apply Filters
                    </Button>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => clearFilters()}>
                        Reset Filters
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default (OrdersFilters);
