import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { alertActions } from '../../../Actions/ToastActions';
import { staticMessages } from '../../../Constants/CommonConstants';
import Routes from '../../../Constants/Routes';
import { history, setStateToUrl } from '../../../Utilities/History';
import { InputClear } from '../../Shared/Components/Icons/InputClear';
import { InputMapMarker } from '../../Shared/Components/Icons/InputMapMarker';



const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1px;
    border-style: dashed;
    padding-top: 7px;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
      color: #F56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
}
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: inline-flex;
    text-align: center;
    align-items: center;
    font-weight: 500;

    ${({ smallLabel }) => smallLabel ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `: ''}
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
`;

const AddressWrapper = styled.div`
  margin:2rem 0 1rem 0;
  display:flex;
  flex:0.5;
  flex-direction:column;
  text-align:left;
`;

const MapMarkerContainer = styled.div`
  margin-right: 0.5rem;
`;

const InputClearButton = styled.button`
  right: 0.5rem;
  position: absolute;
  width: 2rem;
  height: 2.8rem;
  top: 0;
  background-color: inherit;
`;

const Results = styled.div`
  background:#fff;
  display:flex;
  flex-direction:column;
  text-align:left;
  position:absolute;
  top: 46px;
  width:100%;
  border:1px solid #f0f0f0;
  border-radius:0 0 4px 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .3);
`;

const Result = styled.a`
    padding:18px 20px;
    color:#242B26;
    z-index:10;
    background: white;
    border-bottom:1px solid #f0f0f0;
    ${props => props.isActive ? "background-color:#C9E2F3;" : ""}
    &:last-child {
        border:none;
    }
    &:hover {
        background:#f0f0f0;
        color:#242B26;
    }
    @media(max-width: 768px) {
      padding: 12px 10px;
      line-height: 24px;
      font-size: 15px;
    }
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
`;

const google = window.google;
const autoService = new google.maps.places.AutocompleteService();
const placesService = new google.maps.places.PlacesService(document.createElement('div'));

export const PropertyListingAddressSelector = ({
  handleAddressSelected,
  className,
  onClear,
  userDetails,
  ...inputProps
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const timeout = useRef(null);
  const dispatch = useDispatch();

  const externalParams = new URLSearchParams(window.location.search);
  const externalCode = externalParams.get('externalCode');
  const cashOfferId = externalParams.get('cashOfferId');

  const searchAddresses = async (value) => {
    setIsLoading(true);
    await autoService.getPlacePredictions({
      input: value,
      types: ["address"],
      componentRestrictions: { country: 'us' },
    },
      (predictions, status) => {
        if (status === "OK" && predictions.length > 0) {
          setPredictions(predictions)
        } else {
          setPredictions([])
        }
      });
      setIsLoading(false);
  }

  const handleAddressChanged = ({ target: { value } } ) => {
    dispatch(onboardingActionCreatorsV2.setSearchInputValue(value))
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if(value) {
      timeout.current = setTimeout(() => searchAddresses(value), 500);
    }
  };

  const onAddressSelected = (e, i) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(onboardingActionCreatorsV2.setSearchInputValue(predictions[i].description));
    placesService.getDetails({
      placeId: predictions[i].place_id
    }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        handleAddressSelected(place);
        setStateToUrl(Routes.ONBOARDING_V2, {
          placeId: place.place_id,
          ...(externalCode && { externalCode }),
          ...(cashOfferId && { cashOfferId })
        });
      } else {
        dispatch(alertActions.errorAlert(staticMessages.apiError));
      }
      setPredictions([])
      setIsLoading(false);
    });
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
      e.preventDefault();
    } else if (e.keyCode === 40 && currentIndex < predictions.length - 1) {
      setCurrentIndex(currentIndex + 1);
      e.preventDefault();
    } else if (e.keyCode === 13 || e.keyCode === 9) {
      if (predictions.length > 0) {
        onAddressSelected(e, currentIndex);
      }
      e.preventDefault();
    }
  }

  return (
      <AddressWrapper className={className}>
        <div className="custom-address-selector">
        <InputContainer className={inputProps.error ? 'invalid' : ''}>
          <input
            {...inputProps}
            onChange={handleAddressChanged}
            onKeyDown={handleKeyDown}
          />
          <InputLabel smallLabel={inputProps.value} htmlFor={inputProps.name}>
            <MapMarkerContainer>
              <InputMapMarker />
            </MapMarkerContainer>
            {inputProps.label}
          </InputLabel>
          {inputProps.value && !inputProps.disabled && (
            <InputClearButton onClick={onClear}>
              <InputClear />
            </InputClearButton>
          )}
          {inputProps.error && (
            <ErrorMessage>
              {inputProps.error}
            </ErrorMessage>
          )}
        </InputContainer>
        {predictions.length > 0 && inputProps.value ? (
          <Results>
            {predictions.map((p, i) => (<Result key={i} href="#"
              isActive={currentIndex === i}
              onClick={(e) => onAddressSelected(e, i)}>{p.description}</Result>))}
          </Results>
        ):(isLoading && (
            <Results>
              <Loader animation="border"/>
            </Results>
        ))}
      </div>
    </AddressWrapper>
  );
}
