import React from "react";
import Slider from "react-slick";
import { ImagesUrl } from "../../../Constants/Images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const DEFAULT_SLIDES_TO_SHOW_COUNT = 7;
const DEFAULT_SLIDES_TO_SCROLL_COUNT = 3;

class FeatureSlider extends React.Component {
  render() {
    const slidesToShowCount = this.props.itemsToShowCount || DEFAULT_SLIDES_TO_SHOW_COUNT;
    const slidesToScrollCount = this.props.itemsToScrollCount || DEFAULT_SLIDES_TO_SCROLL_COUNT;
    var settings = {
      infinite: true,
      slidesToShow: slidesToShowCount,
      slidesToScroll: slidesToScrollCount,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        }
      ],
      nextArrow: <SampleNextArrow />,
      prevArrow: <SampleNextArrow />
    };
    return (
      <Slider {...settings}>
        <div className="slider-img-wrap">
          <a href='press/media-coverage' target="_blank">
            <img src={ImagesUrl.INMAN} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.MARKET_WATCH} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.MONEY} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.MY_MOVE} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.THE_WALL_STREETJOURNAL} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.THE_ECONOMIST} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.BLOOMBERG} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.BALTIMORE_SUN_LOGO} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.BOB_VILA} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.CHEAT_SHEET} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.CURBED_LOGO} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.FINANCIAL_TIMES} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.FOX_NEWS} alt="" />
          </a>
        </div>
        <div className="slider-img-wrap">
          <a href="press/media-coverage">
            <img src={ImagesUrl.HOUSING_WIRE} alt="" />
          </a>
        </div>
      </Slider>
    );
  }
}
export default FeatureSlider;
