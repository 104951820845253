import LogRocket from 'logrocket';
import { startLoading, endLoading } from '../../LoaderActions';
import { formService } from '../../../Services/V2/FormService';
import { removeNullUndefined } from '../../../Utilities/commonFunctions';
import { formsFiltersDefault } from '../../../Reducers/V2/AdminFormsReducer';
import { staticMessages } from '../../../Constants/CommonConstants';
import { alertActions } from '../../ToastActions';

export const FormsActions = {
    FORMS_REQUESTED: 'FORMS_REQUESTED',
    FORMS_RESOLVED: 'FORMS_RESOLVED',
    FORMS_FAILED: 'FORMS_FAILED',
    SET_FORMS_FILTERS: 'SET_FORMS_FILTERS',
    CLEAR_FORMS_FILTERS: 'CLEAR_FORMS_FILTERS'
};

export const formsCreators = {
    setFormsFilters: (filters) => async (dispatch) => {
        dispatch({ type: FormsActions.SET_FORMS_FILTERS, payload: filters });
    },
    clearFormsFilters: () => async (dispatch) => {
        dispatch({ type: FormsActions.CLEAR_FORMS_FILTERS });
    },
    getForms: (params = formsFiltersDefault) => async (dispatch, getState) => {
        dispatch({ type: FormsActions.FORMS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const paramsObj = params;

            const { data } = await formService.getForms(removeNullUndefined(paramsObj));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: FormsActions.FORMS_RESOLVED, payload: data });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_orders_action',
                },
                extra: {
                    type: FormsActions.FORMS_FAILED,
                },
            });
        }
    }
};
