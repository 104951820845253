import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import { DatePicker } from 'antd';
import moment from 'moment';
import enums from '../../../Constants/Enums';
import { editListingValue } from '../../../Constants/CommonConstants';
import { useEffect } from 'react';
import { draftFormsFieldsSelector } from '../../../Reducers/OnboardingReducerV2';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';

function disabledDate(current) {
  return current && current < moment().add(-1, 'days');
}

const Form = styled.div`
  flex: 2;
  display: block;
  padding: 20px;
  overflow: auto;
  height: 100%;
  ul,
  ol {
    margin-left: 17px;
    ul,
    ol {
      margin-left: 0;
    }
  }
  h1 {
    font-size: 26px;
    font-weight: 600;
    color: #242b26;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0;
  }
  h2 {
    font-size: 16px;
    color: #242b26;
    text-align: center;
    margin-bottom: 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }
`;

const Group = styled.section``;

const Section = styled.div`
  .row-modify {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }

  display: block;
  margin: 0 0 20px;

  font-size: 14px;
  line-height: 26px;

  p {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 26px;
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    display: inline-block;
    border: 1px dashed #242b26;
    border-radius: 8px;
    padding: 5px;
    margin: 0 2px;
  }

  select {
    height: 38px;
    min-width: 140px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 500px;
    .medium-4-v2 {
      min-width: 200px !important;
      input,
      select {
        width: 90% !important;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    .row-modify .medium-4 {
      width: 80%
    }
  }

  @media (max-width: 37em) {
    width: 400px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 300px;
  }
`;

const FormDatePicker = styled.div`
  display: inline;
  padding-left: 3px;
  input {
    border-radius: 0;
    border: 1px solid #b698d9;
    font-size: 15px;
    &:focus {
      box-shadow: none;
    }
  }
`;

const TextAreaWrapper = styled.div`
  min-width: 600px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 400px;
    max-width: 500px;
    padding-right: ${({ required }) => (required ? '0' : '50px')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 400px;
    padding-right: ${({ required }) => (required ? '0' : '50px')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 250px;
    max-width: 300px;
    padding-right: ${({ required }) => (required ? '0' : '50px')};
  }
`;

const inputErrorClass = 'error-input';
const inputErrorClassOption = 'error-input-option';
const inputErrorClassChecbox = 'error-input-checkbox';
const disabled_input = 'disabled-input';

export const FormView = ({ form, onFieldBlur, errors }) => {
  const [showSingCount, setShowSignCount] = useState(2);
  const draftFormsFields = useSelector(draftFormsFieldsSelector);

  useEffect(() => {
    if (!form) {
      return;
    }

    const prefilledFields = [];
    form.groups.forEach((group) => {
      group.sections.forEach((section) => {
        section.fields.forEach((field) => {
          const draftFieldValue = getDraftField(field)?.fieldValue;
          if (field.value && (!draftFieldValue || field.value !== draftFieldValue)) {
            prefilledFields.push({
              formFieldId: field.formFieldId,
              formGroupId: section.formGroupId,
              formGroupSectionId: section.formGroupSectionId,
              formId: form.formId,
              formPrefillTypeId: field.formPrefillTypeId,
              fieldValue: field.value,
              readOnly: field.readOnly,
            });
          }
        });
      });
    });

    if (prefilledFields.length) {
      prefilledFields.forEach((filled) => {
        const index = draftFormsFields.findIndex((i) => i.formFieldId === filled.formFieldId);
        if (index !== -1) {
          draftFormsFields[index] = filled;
        } else {
          draftFormsFields.push(filled);
        }
      });

      dispatch(onboardingActionCreatorsV2.updateDraftFormFieldsV2([...draftFormsFields]));
      dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    }
  }, [form]);

  const dispatch = useDispatch();

  const processForm = (group, section) => {
    let { content, fields } = section;

    const re = new RegExp(/Owner's Signature:{FormField:(\d+)}/, 'g');
    const re1 = new RegExp(/Owner Signature:{FormField:(\d+)}/, 'g');
    let x = 1;
    let inStyles;
    content = content.replace(re, function (match) {
      if (x > showSingCount) {
        inStyles = 'display:none';
      }
      return "<span style='" + inStyles + "' class='signaclass' id='sign_" + ++x + "'>" + match + '</span>';
    });

    content = content.replace(re1, function (match) {
      if (x > showSingCount) {
        inStyles = 'display:none';
      }
      return "<span style='" + inStyles + "' class='signaclass' id='sign_" + ++x + "'>" + match + '</span>';
    });

    const datere = new RegExp(/Date:{FormField:(\d+)}/, 'g');
    let y = 1;
    let inStyle;
    content = content.replace(datere, function (match) {
      if (y > showSingCount && y < enums.NUMERIC_VALUE.FOUR) {
        inStyle = 'display:none';
      } else {
        inStyle = '';
      }
      return "<span style='" + inStyle + "' class='signaclass' id='date_" + ++y + "'>" + match + '</span>';
    });

    for (let j = 3; j > showSingCount; j--) {
      const regexp = new RegExp(
        ['emailaddr' + j, 'seller' + j + 'email']
          .map(function (r) {
            return (r + '').replace(/\//g, '');
          })
          .join('|'),
        'g'
      );

      const Sellernameregexp = new RegExp('sellername' + j, 'i');
      const Initialregexp = new RegExp('initial' + j, 'i');

      let Displayindex = fields.findIndex((x) => regexp.test(x.displayName));
      let initalIndex = fields.findIndex((x) => Initialregexp.test(x.displayName));
      let sellerNameindex = fields.findIndex((x) => Sellernameregexp.test(x.displayName));
      if (Displayindex > 0) {
        fields = fields.filter((x) => !regexp.test(x.displayName));
      }
      if (initalIndex > 0) {
        let replaceDataInitial = '{FormField:' + [fields[initalIndex].formFieldId] + '}';
        content = content.replace(replaceDataInitial, '');
      }
      if (sellerNameindex > 0) {
        let replaceDataSeller = '{FormField:' + [fields[sellerNameindex].formFieldId] + '}';
        content = content.replace(replaceDataSeller, '');
      }
    }

    content = content.replace(/{FormField:(\d+)}/g, '<field>$1</field>');

    content = content.replace(/\[row.*?\]/g, '<div class="row-modify">');
    content = content.replace(/\[\/row]/g, '</div>');
    content = content.replace(/\[column.*?\]/g, '<div class="medium-4 medium-4-v2">');
    content = content.replace(/\[\/column]/g, '</div>');
    content = content.replace(/\[page-break]/g, '<br />');
    content = content.replace(/\[required]/g, '');

    //if (processNewlines) content = content.replace(/\r\n/g, "<br />");

    return ReactHtmlParser(content, {
      transform: (node, index) => transform(node, index, group, section),
    });
  };

  const transform = (node, index, group, section) => {
    let { fields } = section;

    if (node.type === 'tag' && node.name === 'field') {
      const field = fields.filter((f) => f.formFieldId === parseInt(node.children[0].data))[0];
      if (field && !getDraftField(field)) {
        let valueForSign = field.value ? field.value : field.prefillText || '';
        if (
          valueForSign &&
          typeof valueForSign === editListingValue.string &&
          valueForSign.toLowerCase() === editListingValue.oneOwner
        ) {
          setShowSignCount(1);
        }
        if (
          valueForSign &&
          typeof valueForSign === editListingValue.string &&
          valueForSign.toLowerCase() === editListingValue.twoOwner
        ) {
          setShowSignCount(2);
        }
        if (
          valueForSign &&
          typeof valueForSign === editListingValue.string &&
          valueForSign.toLowerCase() === editListingValue.threeOwner
        ) {
          setShowSignCount(3);
        }
      }

      if (field) {
        return getFieldControl(section, field, node);
      }
    }
  };

  const getDraftField = (field) => {
    return draftFormsFields.find((x) => x.formFieldId === field.formFieldId);
  };

  const isMultipleChecked = (field, option) => {
    const value = getDraftField(field)?.fieldValue;

    if (!value) return false;

    return value.indexOf(option) !== -1;
  };

  const onFieldChange = (section, field, value) => {
    let existingField = getDraftField(field);

    if (!existingField) {
      existingField = {
        formFieldId: field.formFieldId,
        formGroupId: section.formGroupId,
        formGroupSectionId: section.formGroupSectionId,
        formId: form.formId,
        fieldValue: value,
      };

      dispatch(onboardingActionCreatorsV2.updateDraftFormFieldsV2([...draftFormsFields, existingField]));
      return;
    }

    existingField.fieldValue = value;
    //setDraftFormFields([...draftFormFields]);
    dispatch(onboardingActionCreatorsV2.updateDraftFormFieldsV2([...draftFormsFields]));
  };

  const onSelectFieldChange = (section, field, e, isDate) => {
    const selectedValue = isDate ? e._d : e.target.value;

    if (typeof selectedValue === editListingValue.string && selectedValue.toLowerCase() === editListingValue.oneOwner) {
      setShowSignCount(1);
    } else if (
      typeof selectedValue === editListingValue.string &&
      selectedValue.toLowerCase() === editListingValue.twoOwner
    ) {
      setShowSignCount(2);
    } else if (
      typeof selectedValue === editListingValue.string &&
      selectedValue.toLowerCase() === editListingValue.threeOwner
    ) {
      setShowSignCount(3);
    }

    onFieldChange(section, field, selectedValue);
  };

  const onCheckboxFieldChange = (section, field, checked, value) => {
    onFieldChange(section, field, checked ? value : '');
  };

  const onMultipleCheckboxFieldChange = (section, field, checked, value) => {
    let existingField = getDraftField(field);

    const selectedOptions = existingField?.fieldValue.split(',') || [];

    if (checked) {
      // checked means NOT checked :D
      selectedOptions.push(value);
    } else {
      selectedOptions.splice(
        selectedOptions.findIndex((x) => x.fieldValue === value),
        1
      );
    }

    onFieldChange(section, field, selectedOptions.join(','));
  };

  const getCheckboxLabelLength = (node) => {
    if (node?.prev?.name === editListingValue.strong) {
      return node?.prev?.children?.[0]['data'].length;
    }
    if (!node?.prev) {
      return 0;
    }

    return getCheckboxLabelLength(node?.prev);
  };

  const getFieldControl = (section, field, node) => {
    const isFormSubmitted = false;

    let fieldWidth;
    if (field.value?.length >= enums.NUMERIC_VALUE.THIRTY) {
      fieldWidth = enums.NUMERIC_VALUE.TWOHUNDREDTWENTY + enums.NUMERIC_VALUE.TWO * field.value?.length;
    }
    if (field.displayName === editListingValue.sellername) {
      fieldWidth = enums.NUMERIC_VALUE.TWOHUNDREDSEVENTEEN;
    }
    if (
      field.displayName === editListingValue.brokerupfrontfee ||
      field.displayName === editListingValue.broker_upfront_fee
    ) {
      field.value = '$' + field.value;
    }

    if (field.readOnly) {
      return (
        <>
          <input
            type="text"
            key={`formField_${field.formFieldId}`}
            value={field?.value || field?.prefillText || ''}
            className={disabled_input}
          />
          <label className="for-readonly">Read Only</label>
        </>
      );
    }

    if (field.options) {
      let labelLength = '';
      if (
        field.inputDataType === enums.InputTypes.BOOLEAN &&
        node?.parent?.name === editListingValue.div &&
        node?.parent?.parent !== null
      ) {
        labelLength = getCheckboxLabelLength(node);
      }
      if (!field.value || field.value === null) {
        field.value = '';
      }

      return (
        <>
          {field.inputDataType === enums.InputTypes.BOOLEAN ? (
            <>
              {field.required && (
                <label
                  style={
                    labelLength
                      ? { top: -25 + 'px', left: 2 * labelLength + 70 + 'px', marginLeft: '12px', marginBottom: '0px' }
                      : {}
                  }
                >
                  Required
                </label>
              )}

              {field.optionTypeId === enums.InputTypes.TEXT_SHORT ? (
                <>
                  {field.options.split('\n').map((option) => (
                    <div className="checkbox-default">
                      <input
                        key={`formField_${field.formFieldId}_${option}`}
                        type="checkbox"
                        value={option}
                        name={field.formFieldId}
                        checked={option === getDraftField(field)?.fieldValue}
                        onBlur={(e) => onFieldBlur(field)}
                        onChange={(e) => onCheckboxFieldChange(section, field, e.target.checked, e.target.value)}
                        disabled={isFormSubmitted}
                        className={
                          errors[field.formFieldId] ? inputErrorClassChecbox : isFormSubmitted ? disabled_input : ''
                        }
                      />
                      <span>{option}</span>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {field.options.split('\n').map((option) => (
                    <div className="checkbox-default">
                      <input
                        key={`formField_${field.formFieldId}_${option}`}
                        type="checkbox"
                        value={option}
                        name={field.formFieldId}
                        defaultChecked={isMultipleChecked(field, option)}
                        onBlur={(e) => onFieldBlur(field)}
                        onChange={(e) =>
                          onMultipleCheckboxFieldChange(section, field, e.target.checked, e.target.value)
                        }
                        disabled={isFormSubmitted}
                        className={
                          errors[field.formFieldId] ? inputErrorClassChecbox : isFormSubmitted ? disabled_input : ''
                        }
                      />
                      <span>{option}</span>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'baseline',
              }}
            >
              <select
                key={`formField_${field.formFieldId}`}
                name={field.formFieldId}
                onChange={(e) => onSelectFieldChange(section, field, e, false)}
                value={getDraftField(field)?.fieldValue || ''}
                onBlur={(e) => onFieldBlur(field)}
                disabled={isFormSubmitted}
                className={errors[field.formFieldId] ? inputErrorClassOption : isFormSubmitted ? disabled_input : ''}
              >
                {field.required && <label>Required</label>}
                <option key={Math.random()} value="">
                  {' '}
                  Select Value{' '}
                </option>
                {field.options.split('\n').map((o) => (
                  <option key={`formField_${field.formFieldId}_${o}`} value={o}>
                    {o}
                  </option>
                ))}
              </select>
              {field.required && <label>Required</label>}
            </span>
          )}
        </>
      );
    }

    switch (field.inputDataType) {
      case enums.InputTypes.TEXT_SHORT:
        return (
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'baseline',
            }}
            key={`${field.formFieldId}_`}
          >
            <input
              style={fieldWidth ? { width: fieldWidth + 'px' } : {}}
              key={`formField_${field.formFieldId}`}
              type="text"
              name={field.formFieldId}
              value={getDraftField(field)?.fieldValue || ''}
              onChange={(e) => onFieldChange(section, field, e.target.value)}
              onBlur={(e) => onFieldBlur(field)}
              required={field.required && !field.value}
              className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </span>
        );
      case enums.InputTypes.TEXT_LONG:
        return (
          <TextAreaWrapper required={field.required}>
            <textarea
              key={`formField_${field.formFieldId}`}
              type="text"
              name={field.formFieldId}
              defaultValue={getDraftField(field)?.fieldValue || ''}
              onChange={(e) => onFieldChange(section, field, e.target.value)}
              onBlur={(e) => onFieldBlur(field)}
              className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </TextAreaWrapper>
        );
      case enums.InputTypes.WHOLE_NUMBER:
        return (
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'baseline',
            }}
          >
            <input
              key={`formField_${field.formFieldId}`}
              type="number"
              name={field.formFieldId}
              defaultValue={field?.value ? field.value : field.prefillText || ''}
              onChange={(value) => onFieldChange(section, field, value)}
              onBlur={(e) => onFieldBlur(field)}
              className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </span>
        );
      case enums.InputTypes.DECIMAL:
        return (
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'baseline',
            }}
          >
            <input
              key={`formField_${field.formFieldId}`}
              type="number"
              name={field.formFieldId}
              defaultValue={getDraftField(field)?.fieldValue || ''}
              onChange={(value) => onFieldChange(section, field, value)}
              onBlur={(e) => onFieldBlur(field)}
              className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </span>
        );
      case enums.InputTypes.CURRENCY:
        return (
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'baseline',
            }}
          >
            <CurrencyInput
              style={fieldWidth ? { width: fieldWidth + 'px' } : {}}
              key={`formField_${field.formFieldId}`}
              decimalsLimit={2}
              intlConfig={{ locale: 'en-US', currency: 'USD' }}
              value={getDraftField(field)?.fieldValue || ''}
              name={field.formFieldId}
              disabled={field.readOnly}
              onValueChange={(value) => onFieldChange(section, field, value)}
              onBlur={(e) => onFieldBlur(field)}
              required={field.required && !field.value}
              className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
            />
            {field.required && <label>Required</label>}
          </span>
        );
      case enums.InputTypes.BOOLEAN:
        return (
          <>
            {field.required && <label>Required</label>}
            <input
              key={`formField_${field.formFieldId}`}
              type="checkbox"
              name={field.formFieldId}
              defaultChecked={field?.value ? JSON.parse(field.value) : false}
              onBlur={(e) => onFieldBlur(field)}
              onChange={(value) => onFieldChange(section, field, value)}
              disabled={isFormSubmitted}
              className={errors[field.formFieldId] ? inputErrorClassChecbox : isFormSubmitted ? disabled_input : ''}
            />
          </>
        );

      case enums.InputTypes.DATE_TIME:
        return (
          <>
            <FormDatePicker>
              {field.value ? (
                <DatePicker
                  size="large"
                  defaultValue={moment(getDraftField(field)?.fieldValue || '')}
                  onBlur={(e) => onFieldBlur(field)}
                  onChange={(e) => onSelectFieldChange(section, field, e, true)}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`formField_${field.formFieldId}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={errors[field.displayName] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
                />
              ) : (
                <DatePicker
                  size="large"
                  onChange={(e) => onSelectFieldChange(section, field, e, true)}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  onBlur={(e) => onFieldBlur(field)}
                  key={`formField_${field.formFieldId}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={errors[field.formFieldId] ? inputErrorClass : isFormSubmitted ? disabled_input : ''}
                />
              )}
              {field.required && <label>Required</label>}
              <span className="text-error">{errors[field.displayName]}</span>
            </FormDatePicker>
          </>
        );
      default:
        return '';
    }
  };

  return (
    <Form>
      {form &&
        form.groups.map((g, i) => {
          return (
            <Group key={i}>
              {g.sections.map((s, j) => {
                return (
                  <div className={'dynamic-form-before'}>
                    <Section key={j}>{processForm(g, s)}</Section>
                  </div>
                );
              })}
            </Group>
          );
        })}
    </Form>
  );
};
