import { VouchersActions } from '../../Actions/V2/Admin/VouchersActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const vouchersFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    voucherTemplateId: null,
    sortBy: null,
    desc: null,
}

const initialState = {
    vouchers: [],
    totalCount: 0,
    filters: vouchersFiltersDefault
};

export const vouchersReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case VouchersActions.SET_VOUCHERS_FILTERS:
            return { ...state, filters: action.payload };
        case VouchersActions.CLEAR_VOUCHERS_FILTERS:
            return { ...state, filters: { ...vouchersFiltersDefault } };
        case VouchersActions.VOUCHERS_RESOLVED:
        case VouchersActions.VOUCHERS_FAILED:
            return { ...state, vouchers: action.payload.results, totalCount: action.payload.total };
        default:
            return state;
    }
};

export const vouchersSelector = state => state.vouchers.vouchers;
export const vouchersCountSelector = state => state.vouchers.totalCount;
export const vouchersFiltersSelector = state => state.vouchers.filters;