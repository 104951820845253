import React from 'react';
import { Button } from 'antd';

import Select, { createFilter } from 'react-select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productTypesSelector, statesSelector, companiesSelector } from '../../../../../Reducers/LookupReducer';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { cursorStyles } from '../../../../../Constants/CommonConstants';
import { productsCreators } from '../../../../../Actions/V2/Admin/ProductsActions';
import { productsFiltersSelector } from '../../../../../Reducers/V2/AdminProductsReducer';

const ProductsFilters = () => {
    const dispatch = useDispatch();
    const filters = useSelector(productsFiltersSelector);
    const productTypes = useSelector(productTypesSelector);
    const states = useSelector(statesSelector);
    const companies = useSelector(companiesSelector);

    const productTypesOptions = productTypes.map(x => ({ value: x.id, label: x.name }));
    const statesOptions = states.map(x => ({ value: x.id, label: x.name }));
    const companiesOptions = companies.map(x => ({ value: x.id, label: x.name }));

    useEffect(() => {
        dispatch(lookupActionsCreators.getProductTypes());
        dispatch(lookupActionsCreators.getStates());
        dispatch(lookupActionsCreators.getCompanies());
    }, []);

    const handleChange = (value) => dispatch(productsCreators.setProductsFilters(value));

    const applyFilters = () => dispatch(productsCreators.getProducts(filters));

    const clearFilters = () => {
        dispatch(productsCreators.clearProductsFilters());
        dispatch(productsCreators.getProducts());
    }

    return (
        <div className={`admin-filter-wrapper more-filter`}>
            <div className={`filter-bar`}>
                <div className="filter-bar-left">
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter By Product Type</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Product Type'
                            value={productTypesOptions.find(x => x.value === filters.productTypeId) || null}
                            onChange={e => handleChange({ ...filters, productTypeId: e.value })}
                            options={productTypesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by State</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select State'
                            value={statesOptions.find(x => x.value === filters.state) || null}
                            onChange={e => handleChange({ ...filters, state: e.value })}
                            options={statesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Company</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Company'
                            value={companiesOptions.find(x => x.value === filters.companyId) || null}
                            onChange={e => handleChange({ ...filters, companyId: e.value })}
                            options={companiesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                </div>
                <div className='product-group search-buttons'>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => applyFilters()}>
                        Apply Filters
                    </Button>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => clearFilters()}>
                        Reset Filters
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default (ProductsFilters);
