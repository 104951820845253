/* eslint-disable no-dupe-class-members */
import React from 'react';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { Icon, Layout } from 'antd';
import {
  PartitionOutlined
} from '@ant-design/icons';
import { withRouter } from 'react-router';
import routes from '../../../Constants/Routes';
import Enums from '../../../Constants/Enums';
import { entityTypes } from '../../../Constants/CommonConstants';
import { Link } from 'react-router-dom';
import { history } from '../../../Utilities/History';
import downarrow from '../../../Assets/Images/down-arrow.png';
import { bindActionCreators } from 'redux';
import { adminActionCreators } from '../../../Actions/AdminActions';

const { Sider } = Layout;

class AdminSiderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.isNewCategory = props.isNewCategory;
    this.state = {
      leftDropdownValue: [entityTypes.listings, entityTypes.form, entityTypes.categories,
      entityTypes.category, entityTypes.landing].indexOf(props.activeEntity) > -1,
    };
  }

  //Handle Left Dropdown
  handleLeftDropdown = (value) => {
    this.setState({ leftDropdownValue: value });
    const isDropdownOpen = [entityTypes.listings, entityTypes.form, entityTypes.categories,
    entityTypes.category, entityTypes.landing].indexOf(this.props.activeEntity) > -1;
    (value && !isDropdownOpen) && history.push(routes.ADMIN_LISTINGS)
  }

  addActiveClass = (onListing, onAddPage) => {
    const { activeEntity } = this.props;
    return activeEntity === onListing || activeEntity === onAddPage ? 'active' : ''
  }

  render() {
    const { collapseMenu } = this.props;
    return (
      <Sider className={`admin-leftside ${collapseMenu ? '' : 'full-leftside'}`} trigger={null} >
        <ul className="admin-left-nav" id="sidenav">
          <h1
            className='admin-custom-dropdown-heading'
            title="CRM"
          >
            <b>CRM</b>
          </h1>
          <li>
            <Link
              to={routes.ADMIN_ORDERS}
              className={
                this.addActiveClass('orders')
              }
              title="Orders"
            >
              <Icon type="dollar" />
              <span>Orders</span>
            </Link>
          </li>
          <li>
            <Link
              to={routes.ADMIN_CASH_OFFERS}
              className={
                this.addActiveClass(entityTypes.cashOffers, entityTypes.cashOffers)
              }
              title="Cash Offers"
            >
              <Icon type='dollar' />
              <span>Cash Offers</span>
            </Link>
          </li>
          <li>
            <Link
              to={routes.ADMIN_BUYER_LEADS}
              className={
                this.addActiveClass(entityTypes.buyerLeads, entityTypes.buyerLeads)
              }
              title="Buyer Leads"
            >
              <Icon type='logout' />
              <span>Buyer Leads</span>
            </Link>
          </li>
          <li>
            <Link
              to={routes.ADMIN_MLS_SUBMISSION}
              className={
                this.addActiveClass(entityTypes.mlsSubmission)
              }
              title="Submissions"
            >
              <Icon type="unordered-list" />
              <span>Submissions</span>
            </Link>
          </li>
          <li>
            <Link
              to={routes.ADMIN_PROPERTY}
              className={
                this.addActiveClass(entityTypes.properties, entityTypes.property)
              }
              title="Properties"
            >
              <Icon type='home' />
              <span>Properties</span>
            </Link>
          </li>
          <li>
            <Link
              to={routes.ADMIN_USERS}
              className={
                this.addActiveClass(entityTypes.users, entityTypes.user)
              }
              title="Users"
            >
              <Icon type='user' />
              <span>Users</span>
            </Link>
          </li>
          <li>
            <Link
              to={routes.ADMIN_COMPANY}
              className={
                this.addActiveClass(entityTypes.company)
              }
              title="Companies"
            >
              <Icon type='usergroup-delete' />
              <span>Companies</span>
            </Link>
          </li>
          <li>
            <Link
              to={routes.ADMIN_TITLE_COMPANY}
              className={
                this.addActiveClass(entityTypes.titleCompany)
              }
              title='Title Companies'
            >
              <Icon type='usergroup-delete' />
              <span>Title Companies</span>
            </Link>
          </li>
          <h1
            className='admin-custom-dropdown-heading'
            title="MLS"
          >
            <b>MLS</b>
          </h1>
          <li>
            <Link
              to={routes.ADMIN_MLS_ABBREVIATION}
              className={
                this.addActiveClass(entityTypes.abbreviation, entityTypes.addAbbreviation)
              }
              title="Info"
            >
              <Icon type="snippets" />
              <span>Info</span>
            </Link>
          </li>
          <li>
            <h2
              className={`admin-custom-dropdown-heading-group-title admin-custom-dropdown-heading ${this.state.leftDropdownValue ? 'for-rotate' : ''}`}
              onClick={() => this.handleLeftDropdown(!this.state.leftDropdownValue)}
              title="Form Sections"
            >
              <Icon type="edit" />
              <span>Form Sections</span>
              <img src={downarrow} alt="arrow" className="left-dropdown-arrow" />
            </h2>
            <ul className={`admin-custom-dropdown-menu ${this.state.leftDropdownValue ? 'active' : ''}`}>
              <li>
                <Link
                  to={routes.ADMIN_LISTINGS}
                  className={
                    this.addActiveClass(entityTypes.listings, entityTypes.form)
                  }
                  title="Listing Forms"
                >
                  <Icon type="form" />
                  <span>Listing Forms</span>
                </Link>
              </li>
              <li>
                <Link
                  to={routes.ADMIN_MLS_CATEGORIES}
                  className={
                    this.addActiveClass(entityTypes.categories, entityTypes.category)
                  }
                  title="Categories"
                >
                  <PartitionOutlined className="mr-10 ant-icon-for-menu" />
                  <span>Categories</span>
                </Link>
              </li>
              <li>
                <Link
                  to={routes.ADMIN_LANDING}
                  className={
                    this.addActiveClass(entityTypes.landing)
                  }
                  title="Landing Page"
                >
                  <Icon type='cluster' />
                  <span>Landing Page</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to={routes.ADMIN_COVERAGE}
              className={
                this.addActiveClass(entityTypes.mlsCoverage, entityTypes.coverage)
              }
              title="Coverage"
            >
              <Icon type='deployment-unit' />
              <span>Coverage</span>
            </Link>
          </li>

          <h1
            className='admin-custom-dropdown-heading'
            title="STORE"
          >
            <b>STORE</b>
          </h1>
          <li>
            <Link
              to={routes.ADMIN_PRODUCTS}
              className={
                this.addActiveClass(entityTypes.products)
              }
              title="Products"
            >
              <Icon type='shop' />
              <span>Products</span>
            </Link>
          </li>

          <h1
            className='admin-custom-dropdown-heading'
            title="DISCOUNTS"
          >
            <b>DISCOUNTS</b>
          </h1>
          <li>
            <Link
              to={routes.ADMIN_VOUCHER_TEMPLATE_LISTING}
              className={
                this.addActiveClass(entityTypes.voucherTemplate, entityTypes.template)
              }
              title="Voucher Templates"
            >
              <Icon type='audit' />
              <span>Voucher Templates</span>
            </Link>
          </li>
          <li>
            <Link to={routes.ADMIN_VOUCHER_LISTING}
              className={
                this.addActiveClass(entityTypes.voucherListing)
              }
              title="Voucher"
            >
              <Icon type='file-done' />
              <span>Vouchers</span>
            </Link>
          </li>

          <li className='collapse-button-container'>
            <button
              onClick={() => this.props.setCollapseMenu(!collapseMenu)}
              title="Collapse Menu"
              className="collapse-icon-link"
            >
              {
                collapseMenu ?
                  <i className="fa fa-angle-double-right collapse-icon" aria-hidden="true"></i>
                  :
                  <i className="fa fa-angle-double-left collapse-icon" aria-hidden="true"></i>
              }
            </button>
          </li>
        </ul>
      </Sider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[Enums.NUMERIC_VALUE.TWO],
    collapseMenu: state.admin.collapseMenu
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(withTheme(connect(mapStateToProps, mapDispatchToProps)(AdminSiderMenu)));
