import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import v8n from 'v8n';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CountryDropdown } from 'react-country-region-selector';

import { InputFlex } from '../../../Assets/Style/commonStyleComponents';
import { plansSelector } from '../../../Reducers/OnboardingReducerV2';
import { userService } from '../../../Services/UserService'
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { getDraftListingData, gTag } from '../../../Utilities/utils';
import ListingPreview from '../Components/ListingPreview';
import { Logo } from '../../Shared/Components/Icons/Logo';
import AppConsts from '../../../Constants/AppConsts';
import {
    propertyPriceSelector,
    selectedAddressSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { LeftArrowIcon } from '../../Shared/Components/Icons/LeftArrowIcon';
import { StripeLogoIcon } from '../../Shared/Components/Icons/StripeLogoIcon';
import CashOfferPanel from '../Components/CashOfferPanel';
import { LYFSteps } from '../../../Constants/CommonConstants';

const stripePromise = loadStripe(AppConsts.stripeApiKey);


const Container = styled.div`
    display: flex;
    height: calc(100vh - 81.7px);
    top: -18.3px;
    position: relative;
    @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
        height: calc(100vh - 45.7px);
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.md}){
        flex-direction: column;
    }
`;

const CardInfoWrapper = styled.div`
    margin: 0 auto 32px auto;
`;

const StyledCardNumberElement = styled(CardNumberElement)`
    width: 421px;
    height: 40px;
    border: solid 1px #3C42571F;
    border-radius: 4px 4px 0 0;
    padding: 0 0 0 10px;
    @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
        width: 400px;
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
        width: 290px;
    }
`
const CardExpiryElementWrapper = styled.div`
    width: 50%;
    border: solid 1px #3C42571F;
    border-radius: 0 0 0 4px;
    padding: 0 0 0 10px;
`
const CardCvcElementWrapper = styled.div`
    width: 50%;
    border: solid 1px #3C42571F;
    border-radius: 0 0 4px 0;
    padding: 0 0 0 10px;
`
const PaymentWrapper = styled.div`
    margin: 0 auto;
    width: 421px;
    padding-top: 80px;
    @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
        width: 400px;
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
        width: 290px;
    }
`
const PayButton = styled.button`
    width: 100%;
    height: 48px;
    background-color: #B698D9;
    color: #000000;
    border-radius: 4px;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
`

const Label = styled.div`
    display: block;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 14px;
`
const ErrorDiv = styled.div`
    font-size: 14px;
    color: red;
    margin: 6px 0 0 0;
    font-weight: 400;
    display: block;
`;

const ListingPreviewWrapper = styled.div`
    width: 18.5rem;
`;

const PreviewContainer = styled.div`
    background-color: #DBE8E0;
    width: 50%;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
        width: 40%;
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.md}){
        width: 100%;
    }
    
`;

const BackButtonAndLogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    width: 18.5rem;
    left: -3rem;
    position: relative;
    @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
        left: 0;
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.md}){
        left: -3rem;
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
        left: 0;
    }
`;

const BackButtonContainer = styled.button`
    background: none;
    padding: 1rem;
    padding-bottom: 0.75rem;

    @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
        padding-bottom: 0.5rem;
    }
`;

const PlanTitle = styled.div`
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
`;

const PlanPrice = styled.div`
    font-size: 2rem;
    margin-bottom: 1.5rem;
`;

const FooterContainer = styled.div`
    display: flex;
    margin-top: 15px;
    width: 18rem;
    font-size: 0.75rem;
    line-height: 1.5rem;
`;

const PowerBy = styled.span`
    margin: 0 7px 0 0;
`;

const StripeLogoContainer = styled.span`
    margin-right: 1.75rem;
`;

const Terms = styled.span`
    margin: 0 22px 0 24px;
`;

const Divider = styled.span`
    height: 20px;
    border-left: solid 1px #8792A2; 
`;

const CardDetailsContainer = styled.div`
    width: 421px;
    display: flex;
    @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
        width: 400px;
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
        width: 290px;
    }
`;

const PaymentDetailsContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
        width:60%;
    }
    @media(max-width: ${({ theme }) => theme.breakpoints.md}){
        width: 100%;
        padding-bottom: 3rem;
    }
`;

const PropertyBillingComponent = () => {
    const draftListing = getDraftListingData();
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const plans = useSelector(plansSelector);
    const propertyPrice = useSelector(propertyPriceSelector);
    const selectedAddress = useSelector(selectedAddressSelector);
    const selectedPlan = plans.find(x => x.productId == draftListing.listingData.planId);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [cardError, setCardError] = useState('');

    const initFields = {
        name: {
            rules: [{
                rule: v8n().not.empty().not.null(),
                message: "Please enter the full name on the credit card"
            }],
            value: `${draftListing.listingData.listingSeller.firstName} ${draftListing.listingData.listingSeller.lastName}`.trim()
        },
        country: {
            rules: [],
            value: "US"
        },
        zip: {
            rules: [{
                rule: v8n().not.empty().not.null(),
                message: "Please enter your billing zip"
            }],
            value: ""
        }
    }

    const [fields, setFields] = useState(initFields);

    useEffect(() => {
        if (stripe && selectedPlan) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Plan payment',
                    amount: selectedPlan.priceUpFront,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });
        }
    }, [stripe]);

    useEffect(() => {
        if (!paymentRequest) {
            return;
        }

        paymentRequest.on('paymentmethod', async (event) => {
            let userPaymentIntent = await userService.createPaymentIntent(draftListing.listingData.planId);
            const { error } = await stripe.confirmCardPayment(
                userPaymentIntent.clientSecret,
                { payment_method: event.paymentMethod.id },
                { handleActions: false }
            );

            if (error) {
                event.complete('fail');
                //TODO: Add alert
                return;
            }
            
            createNewListing('', (isSuccess) => isSuccess ? event.complete('success') : event.complete('fail'));
        });

    }, [paymentRequest]);

    const handleFieldChanged = (event) => {
        let billingFields = { ...fields };
        billingFields[event.target.name].value = event.target.value;
        setFields({ ...billingFields });
    }

    const hendleCountryChanged = (value) => {
        let billingFields = { ...fields };
        billingFields['country'].value = value;
        setFields({ ...billingFields });
    }

    const handleFieldBlur = (event) => {
        validateBillingField(event.target.name, event.target.value);
    }

    const validateBillingField = (key, value) => {
        let billingFields = { ...fields };

        let isError = false;

        for (let i = 0; i < billingFields[key].rules.length; i++) {
            let rule = billingFields[key].rules[i];

            if (!rule.rule.test(value)) {
                billingFields[key].error = rule.message;
                isError = true;
                break;
            }
        }

        if (!isError) {
            billingFields[key].error = null;
        }

        setFields({ ...billingFields });
        return isError;
    }

    const hendleSubmitPaymentForm = (event) => {
        event.preventDefault();

        let isError = false;

        Object.keys(fields).forEach((key) => {
            const isErrorExist = validateBillingField(key, fields[key].value);
            if (isErrorExist) {
                isError = true;
            }
        })

        if (isError) {
            return;
        }

        gTag('event', 'conversion', {
            send_to: AppConsts.gtagCodes.conversionId,
            value: 1.00,
            currency: 'USD',
            transaction_id: ''
        })

        const cardNumberElement = elements.getElement(CardNumberElement)

        const data = {
            address_country: fields.country.value,
            address_zip: fields.zip.value,
            name: fields.name.value
        }

        stripe.createToken(cardNumberElement, data).then(result => {
            if(result.error){
                setCardError(result.error.message);
                return;
            }

            createNewListing(result.token.id, (paymentSuccess) => {
                gTag({
                    'event': 'purchase',
                    'ecommerce': {
                    'checkout': {
                        'actionField': {
                        'revenue': selectedPlan.priceUpFront
                        },
                        'products': [{
                        'name': selectedAddress?.formatted,
                        'price': propertyPrice,
                        'payment_status': paymentSuccess ? 'accepted' : 'reject',
                        'plan_price': selectedPlan.priceUpFront
                        }]
                    }
                    }
                });
            });
        })
    }

    const createNewListing = (token, callback) => {
        const payment = {
            draftListingId: draftListing.id,
            productId: draftListing.listingData.planId,
            cardHolderName: fields.name.value,
            cardToken: token || '',
            isOrderByVoucher: false,
            isOrderByInstitutionalUser: false,
            isOrderByUnbounceUser: false,
            zip: fields.zip.value,
            country: fields.country.value
        }
        dispatch(onboardingActionCreatorsV2.createListingV2(payment, callback));
    }

    const hendleBackButtonClick = () => {
        dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_TITLE));
    }

    return (
        <Container>
            <PreviewContainer>
                <BackButtonAndLogoContainer>
                    <BackButtonContainer onClick={hendleBackButtonClick}>
                        <LeftArrowIcon />
                    </BackButtonContainer>
                    <Logo />
                </BackButtonAndLogoContainer>
                <ListingPreviewWrapper>
                    <PlanTitle>{selectedPlan?.title}</PlanTitle>
                    <PlanPrice>$ {selectedPlan?.priceUpFront}</PlanPrice>
                    <ListingPreview />
                </ListingPreviewWrapper>
                <FooterContainer>
                    <PowerBy>Powered by</PowerBy>
                    <StripeLogoContainer>
                        <StripeLogoIcon />
                    </StripeLogoContainer>
                    <Divider/>
                    <Terms>Terms</Terms>
                    <span>Privacy</span>
                </FooterContainer>
            </PreviewContainer>
            <PaymentDetailsContainer>
                <form onSubmit={hendleSubmitPaymentForm} autoComplete="off">
                    <PaymentWrapper>
                        {
                            paymentRequest && <>
                                <PaymentRequestButtonElement options={{ paymentRequest }} />
                                <div className='pay-with-card'>
                                    <span>Or pay with card</span>
                                </div>
                            </>
                        }
                        <Label>Country or region</Label>
                        <CardInfoWrapper>
                            <StyledCardNumberElement onChange={() => { }}
                                options={{
                                    showIcon: false,
                                    style: {
                                        base:
                                        {
                                            fontWeight: '500',
                                            fontSize: "16px",
                                            lineHeight: '40px',
                                        },
                                    },
                                }} />
                            <CardDetailsContainer>
                                <CardExpiryElementWrapper>
                                    <CardExpiryElement
                                        onChange={() => { }}
                                        options={{
                                            style: {
                                                base:
                                                {
                                                    fontWeight: '500',
                                                    fontSize: "16px",
                                                    lineHeight: '40px'
                                                },
                                            },
                                        }} />
                                </CardExpiryElementWrapper>
                                <CardCvcElementWrapper>
                                    <CardCvcElement
                                        onChange={() => { }}
                                        options={{
                                            showIcon: true,
                                            style: {
                                                base:
                                                {
                                                    fontWeight: '500',
                                                    fontSize: "16px",
                                                    lineHeight: '40px'
                                                },
                                            },
                                        }} />
                                </CardCvcElementWrapper>
                            </CardDetailsContainer>
                            {cardError &&
                                <ErrorDiv className='error-text'>{cardError}</ErrorDiv>
                            }
                        </CardInfoWrapper>
                        <InputFlex
                            name="name"
                            title="Name on card"
                            value={fields.name.value}
                            error={fields.name.error}
                            onChange={handleFieldChanged}
                            onBlur={handleFieldBlur}
                            className="payment-name-on-card"
                        />
                        <Label>Country or region</Label>
                        <CountryDropdown
                            name="country"
                            value={fields.country.value}
                            onChange={hendleCountryChanged}
                            valueType="short"
                            classes='payment-country-dropdown'
                        />
                        <InputFlex
                            name="zip"
                            value={fields.zip.value}
                            error={fields.zip.error}
                            onChange={handleFieldChanged}
                            onBlur={handleFieldBlur}
                            className='payment-zip'
                            placeholder="ZIP"
                        />
                        <PayButton type='submit'>Pay ${selectedPlan?.priceUpFront}</PayButton>
                    </PaymentWrapper>
                </form>
            </PaymentDetailsContainer>
        </Container>
    );
}

const PropertyBilling = () => (
    <Elements
        stripe={stripePromise}
        fonts={[{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:200,400,700" }]}
    >
        <PropertyBillingComponent />
    </Elements>
);

export default PropertyBilling;

