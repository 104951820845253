import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Card, Col, Row, Table } from 'antd';
import { useEffect } from 'react';
import { entityTypes, formLayoutType, pageSizeOptions, staticMessages, tableItemsPerPage } from '../../../../../Constants/CommonConstants';
import SearchPanel from '../Shared/SearchPanel.js';
import Routes from '../../../../../Constants/Routes.js';
import ListingFormsFilters from './ListingFormsFilters.js';
import { adminFormsCountSelector, adminFormsSelector, formsFiltersSelector } from '../../../../../Reducers/V2/AdminFormsReducer.js';
import { formsCreators } from '../../../../../Actions/V2/Admin/FormsActions.js';
import { editPageAction } from '../../../../../Utilities/commonFunctions';

const htmlFormType = 2;
const imageFormType = 3;

export const tableConfigs = {
    columns: [
      {
        title: 'Title',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (name, record) => (
          <Link
            to={editPageAction(record, entityTypes.listings)}
          >
            {name}
          </Link>
        ),
      },
      {
        title: 'State',
        dataIndex: 'states',
        key: 'states',
        sorter: false,
        render: states => (
          <div className='remove-last-commoa'>
            {states &&
              states.length > 0 &&
              states.map(actionElement => {
                return (
                  <span
                    key={actionElement.label}
                    className='anchorLink'
                  >
                    {actionElement.label}<span>,&nbsp;</span>
                  </span>
                );
              })}
          </div>
        ),
      },
      {
        title: 'MLS Abbreviation.',
        dataIndex: 'mls',
        key: 'mls',
        sorter: false,
        render: mls => (
          <div className='remove-last-commoa'>
            {mls &&
              mls.length > 0 &&
              mls.map(actionElement => {
                return (
                  <Link
                    key={actionElement.label}
                    to={editPageAction(actionElement, entityTypes.abbreviation)}
                  >
                    {actionElement.label}<span>,&nbsp;</span>
                  </Link>
                );
              })}
          </div>
        ),
      },
      {
        title: 'MLS Category',
        dataIndex: 'mlsCategories',
        key: 'mlsCategories',
        sorter: false,
        render: mlsCategories => (
          <div className='remove-last-commoa'>
            {mlsCategories &&
              mlsCategories.length > 0 &&
              mlsCategories.map(actionElement => {
                return (
                  <Link
                    key={actionElement.label}
                    to={editPageAction(actionElement, entityTypes.categories)}
                  >
                    {actionElement.label}<span>,&nbsp;</span>
                  </Link>
                );
              })}
          </div>
        ),
      },
      {
        title: 'Form Type',
        dataIndex: 'formType',
        key: 'formType',
        sorter: true,
      },
      {
        title: 'Ordering',
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: 'Section Type',
        dataIndex: 'sectionTypeId',
        key: 'sectionTypeId',
        sorter: true,
        render: sectionType => (
          <React.Fragment>
            <span>
              {sectionType === htmlFormType
                ? formLayoutType.HTML
                : formLayoutType.Image}
            </span>
          </React.Fragment>
        ),
      },
      {
        title: 'Companies',
        dataIndex: 'companies',
        key: 'companies',
        sorter: false,
        render: companies => (
          <div className='remove-last-commoa'>
            {companies &&
              companies.length > 0 &&
              companies.map(actionElement => {
                return (
                  <span
                    className='anchorLink'
                    key={actionElement.label}
                  >
                    {actionElement.label}<span>,&nbsp;</span>
                  </span>
                );
              })}
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'publishStatus',
        key: 'publishStatus',
      },
    ],
    filters: [],
    deleteMessage: `${staticMessages.deleteConfirmation} form?`,
    addPageUrl: `${Routes.ADMIN_ADD_EDIT_FORM.replace(':formId', 0)}`,
    addButtonText: 'Add New',
    deleteTitle: 'Delete Form',
    searchPlaceHolder: 'Search Title',
};

const ListingFormsList = () => {
    const dispatch = useDispatch();
    const forms = useSelector(adminFormsSelector);
    const filters = useSelector(formsFiltersSelector);
    const totalCount = useSelector(adminFormsCountSelector);

    useEffect(() => {
        dispatch(formsCreators.getForms(filters));
    }, [
      filters.search,
      filters.page,
      filters.limit,
      filters.sortBy,
      filters.desc,
    ]);

    const onTableChange = (pagination, tablefilters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const sorterParams = {};

        if (field && order) {
          sorterParams.sortBy = field;
          sorterParams.desc = order === 'descend';
        }
        
        dispatch(formsCreators.setFormsFilters({
          ...filters,
          ...sorterParams,
          page: current,
          limit: pageSize
        }));
    }

    const showTotal = (total, range) => {
        return `Showing ${range[0]} - ${range[1]} of ${total}`;
    }
    const onSearch = (value) => dispatch(formsCreators.setFormsFilters({...filters, search: value}));

    return (<>
        <SearchPanel
          onSearch={onSearch}
        />
        <ListingFormsFilters />
        <Card>
            <Row style={{ marginTop: 20 }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}>
                        <Table
                            className='listing-form-table'
                            size={'default'}
                            columns={tableConfigs.columns}
                            dataSource={forms}
                            onChange={onTableChange}
                            pagination={{
                                total: totalCount,
                                defaultPageSize: tableItemsPerPage,
                                defaultCurrent: 1,
                                showTotal: showTotal,
                                pageSizeOptions: pageSizeOptions,
                                showSizeChanger: totalCount > tableItemsPerPage
                            }} />
                </Col>
            </Row>
      </Card>
      </>
    );
}

export default (ListingFormsList);
