import { FormsActions } from '../../Actions/V2/Admin/FormsActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const formsFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    mlsId: null,
    formTypeId: null,
    state: null,
    sectionTypeId: null,
    companyId: null,
    sortBy: null,
    desc: null,
}

const initialState = {
    forms: [],
    totalCount: 0,
    filters: formsFiltersDefault
};

export const adminFormsReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case FormsActions.SET_FORMS_FILTERS:
            return { ...state, filters: action.payload };
        case FormsActions.CLEAR_FORMS_FILTERS:
            return { ...state, filters: { ...formsFiltersDefault } };
        case FormsActions.FORMS_RESOLVED:
        case FormsActions.FORMS_FAILED:
            return { ...state, forms: action.payload.results, totalCount: action.payload.total };
        default:
            return state;
    }
};

export const adminFormsSelector = state => state.adminForms.forms;
export const adminFormsCountSelector = state => state.adminForms.totalCount;
export const formsFiltersSelector = state => state.adminForms.filters;