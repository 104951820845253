import React from 'react'

export const RemovePhotoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#242B26"/>
    <path d="M11.7848 11.8146C13.3394 10.2674 14.8861 8.73705 16.4406 7.18985C16.675 6.95798 16.7705 7.13855 16.7477 7.27107C16.7488 7.43506 16.6326 7.71499 16.3983 7.94686L14.6875 9.63953C14.4722 9.85253 12.484 11.9181 12.2238 12.1756C10.6772 13.7058 9.12237 15.2533 7.56801 16.8004L7.27763 16.8768C7.19024 16.7341 7.26656 16.3743 7.59466 16.0497L9.30546 14.357C9.92124 13.7478 11.5095 12.0886 11.7848 11.8146Z" fill="white" stroke="white" strokeWidth="0.4"/>
    <path d="M11.8152 12.2152C10.2679 10.6607 8.73747 9.11405 7.19021 7.55961C6.95833 7.32527 7.13891 7.22978 7.27143 7.25252C7.43543 7.25147 7.71537 7.3676 7.94725 7.60194L9.63999 9.31267C9.853 9.52795 11.9187 11.5161 12.1762 11.7763C13.7065 13.3228 15.254 14.8776 16.8011 16.4318L16.8776 16.7222C16.7348 16.8096 16.3751 16.7333 16.0504 16.4052L14.3577 14.6945C13.7484 14.0787 12.0892 12.4905 11.8152 12.2152Z" fill="white" stroke="white" strokeWidth="0.4"/>
  </svg>

);
