import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { phoneMask } from '../../../../Constants/CommonConstants';
import v8n from 'v8n';
import moment from 'moment';
import { Button, DatePicker } from 'antd';
import { adminActionCreators} from '../../../../Actions/AdminActions';
import {
  ModalDiv,
  RowFlex,
  InputFlex,
  DetailsWrapper,
  CustomTextarea
} from '../../../../Assets/Style/commonStyleComponents';
import {
  validationMessages,
  customerTypeList,
  regexPattern,
  entityTypes,
  dateFormat,
} from '../../../../Constants/CommonConstants';
import Enums from '../../../../Constants/Enums';
import {
  downloadCompletedForm,
  generateRandomPassword,
  goBack,
  getFocusInput,
  getLocalUserData,
  editPageAction,
  momentDateFormat,
} from '../../../../Utilities/commonFunctions';
import PropertyBasicInfo from './PropertyBasicInfo';
import PropertyAddressDetails from './PropertyAddressDetails';
import PhotoManager from '../../../CustomerPortal/Components/PhotoManager';
import { userActionCreators } from '../../../../Actions/UserActions';
import { history } from '../../../../Utilities/History';
import CurrencyInput from 'react-currency-input-field';
import { AdminUserSearchInput } from '../AdminUserSearchInput';
import TitleCompanyPanel from '../../../Shared/Components/TitleCompanyPanel';
import { titleCompaniesCreators } from '../../../../Actions/V2/Admin/TitleCompaniesActions';
function disabledDate(current) {
  return current && current > moment().endOf('day');
}

function pastDisableDate(current) {
  return current && current < moment().startOf('day');
}

const itemTextRed = 'item-text-red';
const disabled_input='disabled-input';

const google = window.google;
const geocoder = new google.maps.Geocoder();

const LISTING_STATUS_INCOMPLETED = 11;
const FORM_STATUS_NOT_STARTED = 1;

class AddEditProperty extends React.Component {
  constructor(props) {
    super(props);
    this.isNewProperty = props.isNewProperty;
    this.location = history.location.pathname;
    this.timeout = null;

    if(this.isNewProperty){
      props.selectedItemData.photos=[];
    }

    let userClosingFee='';

    if (typeof props.selectedPropertyData.usrClosingFee !== 'undefined') {
      userClosingFee=props?.selectedPropertyData?.usrClosingFee;
    };

    this.state = {
      passwordVisible: false,
      propertyPhotos: [],
      isPyamentSubmitted:false,
      userSearchString: null,
    
      propertyDetail: {
        title: {
          rules: [],
          value: props.selectedPropertyData?.title || '',
        },
        addressAddress1: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your street address',
            },
          ],
          name: 'addressAddress1',
          label: 'Address',
          value: props.selectedPropertyData.addressAddress1 || '',
        },
        addressCity: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your city',
            },
          ],
          name: 'addressCity',
          label: 'City',
          value: props.selectedPropertyData.addressCity || '',
        },
        addressState: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select your state',
            },
          ],
          name: 'addressState',
          label: 'State',
          value: props.selectedPropertyData.addressState || '',
        },
        addressZip: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please enter your zipcode',
            },
            {
              rule: v8n().minLength(5),
              message: 'Zipcode required minimum five character',
            },
          ],
          name: 'addressZip',
          label: 'Zip',
          value: props.selectedPropertyData.addressZip || '',
        },
        productId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a product plan.',
            },
          ],
          value: props?.selectedPropertyData?.productId || '',
        },
        formStatusId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a form status.',
            },
          ],
          value: props?.selectedPropertyData?.formStatusId || FORM_STATUS_NOT_STARTED,
        },
        usrClosingFee: {
          rules: [],
          value: userClosingFee,
        },
        mlsCategoryId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a MLS category.',
            }
          ],
          value: props.selectedPropertyData.mlsCategoryId || '',
        },
        listingStatusId: {
          rules: [],
          value: props.selectedPropertyData.listingStatusId || LISTING_STATUS_INCOMPLETED,
        },
        requestedListingStatus: {
          rules: [],
          value: props.selectedPropertyData.requestedListingStatus || '',
        },
        requestedListingStatusId: {
          rules: [],
          value: props.selectedPropertyData.requestedListingStatusId || '',
        },
        mlsNumber: {
          rules: [],
          value: props.selectedPropertyData.mlsNumber || '',
        },
        mlsInfo: {
          rules: [],
          value: props.selectedPropertyData.mlsInfo || '',
        },
        listDate: {
          rules: [],
          value: props.selectedPropertyData.listDate || null,
        },
        expireDate: {
          rules: [],
          value: props.selectedPropertyData.expireDate || null,
        },
        estimatedCloseDate: {
          rules: [],
          value: props.selectedPropertyData.estimatedCloseDate || null,
        },
        existingUserId: {
          rules: [],
          value: props.selectedPropertyData.userId || '',
        },
        customerFirstName: {
          rules: [],
          value: props.selectedPropertyData.customerFirstName || '',
        },
        customerLastName: {
          rules: [],
          value: props.selectedPropertyData.customerLastName || '',
        },
        customerEmail: {
          rules: [],
          value: props.selectedPropertyData.customerEmail || '',
        },
        contactPhone: {
          rules: [],
          mask: phoneMask,
          value: props?.selectedPropertyData?.contactPhone || '',
        },
        password: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
            {
              rule: v8n().pattern(regexPattern.PASSWORD),
              message: validationMessages.validPassword,
            },
          ],
          value: '',
        },
        customerTypeId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select customer type.',
            },
          ],
          value: !this.isNewProperty ? customerTypeList[0].key : '',
        },
        propertyTypeId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a property type.',
            },
          ],
          value: props.selectedPropertyData.propertyTypeId || '',
          placeholder: 'Select Property',
          label: 'Property Type',
          name: 'propertyTypeId',
        },
        listPrice: {
          rules: [],
          value: props.selectedPropertyData.listPrice || '',
          label: 'Asking Price',
          name: 'listPrice',
        },
        bedrooms: {
          rules: [],
          value: props.selectedPropertyData.bedrooms || '',
          label: 'Bedrooms',
          name: 'bedrooms',
        },
        bathrooms: {
          rules: [],
          value: props.selectedPropertyData.bathrooms || '',
          label: 'Bathrooms',
          name: 'bathrooms',
        },
        squareFeet: {
          rules: [],
          value: props.selectedPropertyData.squareFeet || '',
          label: 'Square Feet',
          name: 'squareFeet',
        },
        lotAcreage: {
          rules: [],
          value: props.selectedPropertyData.lotAcreage || '',
          label: 'Lot Acreage',
          name: 'lotAcreage',
        },
        description: {
          rules: [],
          value: props.selectedPropertyData.description || '',
          label: 'Description',
          name: 'description',
        },

        addressAddress2: {
          rules: [],
          name: 'addressAddress2',
          label: 'Address Unit',
          value: props.selectedPropertyData.addressAddress2 || '',
        },
        addressCounty: {
          rules: [],
          name: 'addressCounty',
          label: 'County',
          value: props.selectedPropertyData.addressCounty || '',
        },
        assignedListingStatusId: {
          rules: [],
          value:
            props.selectedPropertyData?.listingStatus
              ?.assignedListingStatusId || '',
        },
        propertyStatusId: {
          rules: [],
          value:
            props.selectedPropertyData?.listingStatus?.propertyStatusId || '',
        },
        paymentCompletionDate: {
          rules: [],
          value:
            props.selectedPropertyData?.paymentCompletionDate || '',
        },
        isPaymentCompleted: {
          rules: [],
          value:
            props.selectedPropertyData?.isPaymentCompleted || '',
        },
        orderPayment: {
          rules: [],
          value:
            props.selectedPropertyData?.orderPayment || '',
        },
        listingId: {
          rules: [],
          value:
            props.selectedPropertyData?.listingId || '',
        },
        completedForms:{
          rules: [],
          value:
            props.selectedPropertyData?.completedForms || [],
        },
        note: {
          rules: [],
          value: props.selectedPropertyData?.note,
        },
        titleCompanyId: {
          rules: [],
          value: this.props.isNewProperty ? null : props.selectedPropertyData?.titleCompanyId
        },
        titleCompanyName: {
          rules: [],
          value: this.props.isNewProperty ? '' : props.selectedPropertyData?.titleCompanyName,
        },
        titleCompanyEmail: {
          rules: [],
          value: this.props.isNewProperty ? '' : props.selectedPropertyData?.titleCompanyEmail,
        }
      },
    };
  }
  
  componentDidMount() {
    this.generatePassword(false);
  }

  componentWillUnmount() {
    this.props.clearUserSearch();
    this.props.clearTitleCompany();
  }

  static getDerivedStateFromProps(props) {
    const updateState = {};
    if (props.selectedItemData) {
      updateState['propertyPhotos'] = props.selectedItemData.photos;
    }

    return updateState;
  }

  switchToCustomerPortal = userId => this.props.switchCustomerPortal(userId,this.location);

  // viewUser Redirection
  viewUser = (propertyId) => {
    history.push(`/admin/user/${propertyId}`)
  }

  DownloadZip=async ()=>{
    let listingId=this.state.propertyDetail.listingId.value;
    let reqObj={"listingId": listingId}
    this.props.DownloadZipByAPI(reqObj);
  }

  checkIfValueExist = (type, value) => {
    let propertyDetail = { ...this.state.propertyDetail };
    if (!value) {
      return (propertyDetail[type].error = validationMessages.required);
    }
    return null;
  };

  handleUserSelected = (selectedOption, type) => {
    const updatedValues = { ...this.state.propertyDetail };
    if (selectedOption) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = {
      label: selectedOption.fullName,
      value: selectedOption.value,
    };
    this.setState({ propertyDetail: updatedValues, userSearchString: null });
  };

  handleFieldChangedCurrency=(e,fieldName)=>{
    let propertyDetail = { ...this.state.propertyDetail };
    propertyDetail[fieldName].value=e;
    this.setState({ propertyDetail: { ...propertyDetail } });
  }

  handlePrefillAddressFirldByZip = (zip) => {
    geocoder
  .geocode({ address: `${zip},US` })
  .then((response) => {
    if (!response?.results.length) { return }
    const place = response?.results[0];
    const city = (place.address_components.filter(c => c.types.includes("locality"))?.[0]
        || place.address_components.filter(c => c.types.includes("sublocality"))?.[0]
        || place.address_components.filter(c => c.types.includes("neighborhood"))?.[0])?.long_name;
        
    const county = place.address_components.filter(
      c => c.types.includes("administrative_area_level_2")
      )?.[0]?.long_name.replace(/ County/g, '');

    const state = place.address_components.filter(
      c => c.types.includes("administrative_area_level_1")
      )?.[0]?.short_name;

      let propertyDetail = { ...this.state.propertyDetail };
      propertyDetail.addressCity.value = city;
      propertyDetail.addressCounty.value = county;
      propertyDetail.addressState.value = state;
      this.setState({ propertyDetail: { ...propertyDetail } });

      /*if (!this.props.selectedPropertyData?.titleCompany) {
        this.props.getTitleCompany(state);   
      }*/
  }).catch((e) => {});
  }

  handleFieldChanged = (e, event) => {
    let propertyDetail = { ...this.state.propertyDetail };
    let { name, value } = e.target;
    if (typeof value === 'string') {
      value = value.trimStart();
    }

    if (name === 'addressZip') {
      value = value.trim();
    }

    if (name === 'customerTypeId' && Number(value) === 1) {
      propertyDetail['existingUserId'].error = this.checkIfValueExist(
        'existingUserId',
        propertyDetail['existingUserId'].value,
      );
      propertyDetail['customerFirstName'].error = '';
      propertyDetail['customerLastName'].error = '';
      propertyDetail['customerEmail'].error = '';
      propertyDetail['contactPhone'].error = '';
    }

    if (name === 'customerTypeId' && Number(value) === 2) {
      propertyDetail['customerFirstName'].error = this.checkIfValueExist(
        'customerFirstName',
        propertyDetail['customerFirstName'].value,
      );
      propertyDetail['customerLastName'].error = this.checkIfValueExist(
        'customerLastName',
        propertyDetail['customerLastName'].value,
      );
      propertyDetail['customerEmail'].error = this.checkIfValueExist(
        'customerEmail',
        propertyDetail['customerEmail'].value,
      );
      propertyDetail['contactPhone'].error = this.checkIfValueExist(
        'contactPhone',
        propertyDetail['contactPhone'].value,
      );
      propertyDetail['existingUserId'].error = '';
    }
    

    propertyDetail[name].error = this.checkIfValueExist(name, value);

    if (name === 'customerEmail' && value !== '') {
      const isEmailValid = v8n().pattern(regexPattern.EMAIL).test(value);
      if (!isEmailValid) {
        propertyDetail['customerEmail'].error = validationMessages.validEmail;
      } else {
        propertyDetail['customerEmail'].error = null;
      }
    }
    propertyDetail[name].value = value;
    this.setState({ propertyDetail: { ...propertyDetail } });

  };

  handleFieldBlur = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.validateFormField(name, value, false);
    if (name === 'addressZip' && value.length > 4) {
      this.handlePrefillAddressFirldByZip(value);
    }
  };

  validateFormField = (key, value, isPublish) => {
    let propertyDetail = { ...this.state.propertyDetail };
    let isError = false;
    for (let i = 0; i < propertyDetail[key].rules.length; i++) {
      let rule = propertyDetail[key].rules[i];
      if (typeof value === 'string') {
        value = value.trim();
      }
      if (!rule.rule.test(value)) {
        propertyDetail[key].error = rule.message || validationMessages.required;
        isError = true;
        break;
      }
    }
    if (
      isPublish &&
      Number(propertyDetail?.customerTypeId.value) === Enums.NUMERIC_VALUE.ONE
    ) {
      if (propertyDetail['existingUserId'].error) {
        propertyDetail['existingUserId'].error = validationMessages.required;
        isError = true;
      }
    }
    if (
      isPublish &&
      Number(propertyDetail?.customerTypeId.value) === Enums.NUMERIC_VALUE.TWO
    ) {
      if (propertyDetail['customerEmail'].value) {
        const isEmailValid = v8n()
          .pattern(regexPattern.EMAIL)
          .test(propertyDetail['customerEmail'].value);
        if (!isEmailValid) {
          propertyDetail['customerEmail'].error = validationMessages.validEmail;
          isError = true;
        }
      } else {
        propertyDetail['customerEmail'].error = validationMessages.required;
        isError = true;
      }
      if (propertyDetail['customerFirstName'].error) {
        propertyDetail['customerFirstName'].error = validationMessages.required;
        isError = true;
      }
      if (propertyDetail['customerLastName'].error) {
        propertyDetail['customerLastName'].error = validationMessages.required;
        isError = true;
      }
      if (propertyDetail['contactPhone'].error) {
        propertyDetail['contactPhone'].error = validationMessages.required;
        isError = true;
      }
    }

    if (!isError) {
      propertyDetail[key].error = null;
    }

    this.setState({
      propertyDetail: { ...propertyDetail },
      isError: Object.keys(propertyDetail).some(
        k =>
          propertyDetail[k].error !== undefined &&
          propertyDetail[k].error !== null,
      ),
    });

    return isError;
  };

  handleAddUpdatPropertyDetails = propertyDetails => {
    const { propertyPhotos } = this.state;
    const photos = [];
    const propertyUser = {};
    
    propertyDetails['userId'] =
      Number(propertyDetails.customerTypeId) === Enums.NUMERIC_VALUE.ONE
        ? propertyDetails.existingUserId.value
        : Enums.NUMERIC_VALUE.ZERO;

    let userClosingFee = null;
    if (typeof propertyDetails.usrClosingFee !== 'undefined' && propertyDetails.usrClosingFee) {
      userClosingFee=propertyDetails.usrClosingFee ;
    };    
    propertyDetails['usrClosingFee'] = userClosingFee;  
    propertyPhotos && propertyPhotos?.length > 0 &&
      propertyPhotos.forEach((obj, index) => {
        
        return photos.push({
          Bucket: obj.Bucket,
          Path: obj.key,
          priority: index,
        });
      });
    if (Number(propertyDetails.customerTypeId) === Enums.NUMERIC_VALUE.TWO) {
      propertyUser['firstName'] = propertyDetails.customerFirstName;
      propertyUser['lastName'] = propertyDetails.customerLastName;
      propertyUser['email'] = propertyDetails.customerEmail;
      propertyUser['phoneNumber'] = propertyDetails.contactPhone;
      propertyUser['password'] = propertyDetails.password;
      propertyDetails['propertyUser'] = propertyUser;
      delete propertyDetails.existingUserId;
      delete propertyDetails.customerFirstName;
      delete propertyDetails.customerLastName;
      delete propertyDetails.customerEmail;
      delete propertyDetails.contactPhone;
    } else {
      delete propertyDetails.password;
    }
    if(this.isNewProperty){
      propertyDetails['photos'] = photos;
    }else{
      propertyDetails['photos'] = propertyPhotos;
    }

    propertyDetails['listPrice'] = Number(propertyDetails['listPrice']);
    propertyDetails['bedrooms'] = Number(propertyDetails['bedrooms']);
    propertyDetails['bathrooms'] = Number(propertyDetails['bathrooms']);
    propertyDetails['squareFeet'] = Number(propertyDetails['squareFeet']);
    propertyDetails['lotAcreage'] = Number(propertyDetails['lotAcreage']);

   const propertyId = this.props.isNewProperty
      ? Enums.NUMERIC_VALUE.ZERO
      : this.props.selectedPropertyData.listingId;
    this.props.addUpdateProperty(propertyId, propertyDetails, () => {
      goBack();
      this.props.resetPropertyData();
    });
  };

  updatePropertyStatus = () => {
    const { propertyDetail } = this.state;
    const propertyId = this.props.selectedPropertyData.listingId;
    const reqObj = {
      assignedListingStatusId: propertyDetail.assignedListingStatusId.value
        ? +propertyDetail.assignedListingStatusId.value
        : null,
      propertyStatusId: propertyDetail.propertyStatusId.value
        ? +propertyDetail.propertyStatusId.value
        : null
    };
    this.props.updatePropertyStatus(propertyId, reqObj);
  };

  validateAndUpdateAccountDetails = e => {
    e.preventDefault();

    let isError = false;
    let isPasswordError = false;
    Object.keys(this.state.propertyDetail).forEach(keyName => {
      const isErrorExist = this.validateFormField(
        keyName,
        this.state.propertyDetail[keyName].value,
        true,
      );
      if (isErrorExist) {
        isError = true;
      }
    });
    if (!isError && !isPasswordError) {
      this.handleAddUpdatPropertyDetails({
        ...Object.keys(this.state.propertyDetail).reduce((retVal, key) => {
          if (this.state.propertyDetail[key].value) {
            retVal[key] = this.state.propertyDetail[key].value;
          }
          return retVal;
        }, {}),
      });
    }

    const { propertyDetail } = this.state;
    getFocusInput(propertyDetail);
  };

  selectStateAndCounty = async (val) => {
    const updatedValues = { ...this.state.propertyDetail };
    updatedValues.addressState.value = val;
    updatedValues.addressState.error = '';
    this.setState({ propertyDetail: updatedValues });

    if (!this.props.selectedPropertyData?.titleCompany) {
      await this.props.getTitleCompany(this.state.propertyDetail.addressState.value);
    }
  };

  handleSelectDate = (e, name) => {
    if (!e) {
      return;
    }
    
    const updatedValues = { ...this.state.propertyDetail };
    updatedValues[name].value = e._d;
    this.setState({ propertyDetail: updatedValues });
  };

  handleEditorChange = content => {
    const propertyDetail = { ...this.state.propertyDetail };
    propertyDetail['description'].value = content;
    this.setState({ propertyDetail: { ...propertyDetail } });
  };

  updatePassword = e => {
    const propertyDetail = { ...this.state.propertyDetail };
    propertyDetail['password'].value = e.target.value;
    this.setState({ propertyDetail: { ...propertyDetail } });
  };

  handleOrderChangedProperty = (oldIndex, newIndex) => {
    this.props.reorderAdminPhotos(this.props.selectedPropertyData.listingId, {
      oldIndex,
      newIndex,
    });
  };

  generatePassword = isClicked => {
    if (isClicked) {
      this.setState({ isPasswordGenerate: true });
    }
    const propertyDetail = { ...this.state.propertyDetail };
    propertyDetail['password'].value = generateRandomPassword(12);
    propertyDetail['password'].error = null;
    this.setState({
      passwordVisible: true,
      propertyDetail: { ...propertyDetail },
    });
  };

  handleNewPhotoAdded = (photo,maxPriority) => {
    this.props.uploadPhoto(this.props.selectedPropertyData.listingId,maxPriority, photo);
  };

  renderCompletedPropertyForms = () => {
    const { completedForms } = this.props.selectedPropertyData;
    return completedForms.map(form => {
      return (
        <div key={form.name}>
          <h3 >
            {`${form.name} submitted on ${moment(form.completionDate).format(
              'MMMM DD, YYYY, h:mma',
            )}`}{' '}
          </h3>
          {form.documents.map((item) => {
            return (
              <span
                key={item.name}
                onClick={() => downloadCompletedForm(item.path, item.identityId)}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      );
    });
  };
  handleCompletePayment = () => {
    const { activeEntity, selectedPropertyData: { listingId } } = this.props;
    this.props.completePayment(activeEntity, listingId);
    this.setState({isPyamentSubmitted:true});
  }

  handlePaymentPopupSubmission = () => {
    this.setState({
      isPyamentSubmitted: false,
    });

  };

  handleTitleCompanyChange = (data) => {
    let propertyDetail = { ...this.state.propertyDetail };
    propertyDetail['titleCompanyId'].value = data.titleCompanyId;
    propertyDetail['titleCompanyName'].value = data.titleCompanyName;
    propertyDetail['titleCompanyEmail'].value = data.titleCompanyEmail;
    this.setState({ propertyDetail: { ...propertyDetail } });
  };

  onSearch = (e) => {
    const val = e.target.value;
    const trimmedValue = val?.trim();

    this.setState({ userSearchString: val || '' });

    if (trimmedValue?.length > 3) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => this.props.searchUsers(trimmedValue), 1000);
    }    

  };

  render() {
    const {
      lookup,
      photoProgress,
      deletePhotoFromDB,
      removePhotos,
      selectedPropertyData,
      usersList,
    } = this.props;
    const { propertyDetail, propertyPhotos, userSearchString } = this.state;
    const { existingUserId } = propertyDetail;
  if(typeof existingUserId.value !== 'object' && selectedPropertyData.userId && lookup?.users?.length>Enums.NUMERIC_VALUE.ZERO){
    let exsistingUser= lookup.users.filter(user => user.value===selectedPropertyData.userId);
    exsistingUser=exsistingUser[0];
    existingUserId.value=exsistingUser;
   }

   const userSearchClassName = `admin-react-select ${propertyDetail.existingUserId?.error
    ? 'admin-react-select-error'
    : '' } ${Number(propertyDetail?.existingUserId?.value) === Enums.NUMERIC_VALUE.ONE ? disabled_input : ''}`;

    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>{this.isNewProperty ? 'Add Property' : 'Edit Property'}</h2>
            {!this.isNewProperty && !selectedPropertyData?.isPaymentCompleted && (
              <div className="admin-heading-wrapper-right">
                <button
                  id={1}
                  className="ant-btn btn btn-primary admin-primary-btn"
                  onClick={this.handleCompletePayment}
                >
                  Complete Payment For ${selectedPropertyData.orderPayment}
                </button>
              </div>
            )}
            {!this.isNewProperty && selectedPropertyData?.isPaymentCompleted && (
             
              <h6 className="payment-completed-text">Payment Completed For ${selectedPropertyData.orderPayment}
              
              {selectedPropertyData?.paymentCompletionDate && 
              <>
               {' '}on {momentDateFormat(selectedPropertyData.paymentCompletionDate, dateFormat.DATE_WITH_YEAR)}
              </>
              }
              </h6>
              
            )}
          </div>
          <div className='admin-add-form-wrapper add-edit-property-wrapper'>
            <form onSubmit={this.handleSaveAccountDetails} autoComplete='off'>
              {/* { NOTE : - Use this code in next sprint */}
              {!this.isNewProperty && (
                <DetailsWrapper className="property-office-status-wrapper">
                  <h3 className='admin-form-inner-heading'>Office Status</h3>
                  <RowFlex className='listing-status-row property-office-status'>
                    <div className="property-office-left">
                      <InputFlex
                        title='Assigned'
                        options={lookup?.officeUsers || []}
                        name='assignedListingStatusId'
                        type='text'
                        value={propertyDetail?.assignedListingStatusId?.value}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                        placeholder='Select Assigned Status'
                        isKeyPaired={true}
                        className="property-status-assigned-field"
                      />
                    </div>
                    <div className="property-office-right">
                      <InputFlex
                        title='Status'
                        options={lookup?.officeStatus || []}
                        name='propertyStatusId'
                        type='text'
                        value={propertyDetail?.propertyStatusId?.value}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                        placeholder='Select Property Status'
                        isKeyPaired={true}
                        className="property-status-field"
                      />
                      <Button
                        type='submit'
                        className='btn btn-primary admin-primary-btn'
                        onClick={() => this.updatePropertyStatus()}
                      >
                        Update Office Status
                    </Button>
                    </div>
                  </RowFlex>
                </DetailsWrapper>
              )}
              <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-50'>
                  <InputFlex
                    name='title'
                    title='Property'
                    className='inputgap'
                    placeholder='Enter Property Here'
                    value={propertyDetail?.title?.value}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                </div>
                <div className='admin-w-50 for-custom-select with-extra-text'>
                  <label>
                    Listing Status<span>(Actual listing status on MLS)</span>
                  </label>
                  <InputFlex
                    options={lookup?.statuses}
                    name='listingStatusId'
                    type='text'
                    disabled={this.isNewProperty}
                    value={propertyDetail?.listingStatusId.value}
                    error={propertyDetail?.listingStatusId?.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    placeholder='Select Status'
                    className='inputgap'
                    isKeyPaired={true}
                  />
                </div>
              </div>
              {selectedPropertyData?.completedForms &&
                selectedPropertyData?.completedForms.length > 0 && (
                  <div>
                    {
                      <div className='completed-forms completed-forms-for-admin'>
                        <h2 className='completed-forms-heading'>
                          Completed Properties
                        </h2>
                        {this.renderCompletedPropertyForms()}
                      </div>
                    }
                  </div>
                )}
              <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-50 for-custom-select with-extra-text'>
                  <label>
                    Requested Listing Status
                  </label>
                  <InputFlex
                    options={lookup?.requestedListingStatuses}
                    name='requestedListingStatusId'
                    type='text'
                    value={propertyDetail?.requestedListingStatusId.value}
                    error={propertyDetail?.requestedListingStatusId?.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    placeholder='Select Status'
                    className='inputgap'
                    isKeyPaired={true}
                  />
                </div>
                <div className='admin-w-50 for-custom-select with-extra-text'>
                  <label>
                    MLS Forms Status
                    <span>(Whether the MLS form have been completed.)</span>
                  </label>
                  <InputFlex
                    options={lookup?.formStatuses}
                    name='formStatusId'
                    type='text'
                    value={propertyDetail?.formStatusId?.value}
                    error={propertyDetail?.formStatusId?.error}
                    disabled={this.isNewProperty}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    placeholder='Select Form Status'
                    className='inputgap'
                    isKeyPaired={true}
                  />
                </div>
              </div>
              <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-50'>
                  <InputFlex
                    name='mlsInfo'
                    title='MLS Entered Into'
                    className='inputgap'
                    value={propertyDetail?.mlsInfo?.value}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                </div>
                <div className='admin-w-50'>
                  <InputFlex
                    name='mlsNumber'
                    title='MLS #'
                    className='inputgap'
                    type='text'
                    value={propertyDetail?.mlsNumber?.value}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                </div>
              </div>
              <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-50'>
                  <InputFlex
                    options={lookup?.mlsCategories}
                    name='mlsCategoryId'
                    title='MLS Listing Type'
                    type='text'
                    value={propertyDetail?.mlsCategoryId.value}
                    error={propertyDetail?.mlsCategoryId?.error}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    placeholder='Select MLS Listing Type'
                    className='inputgap'
                    isKeyPaired={true}
                  />
                </div>
                <div className='admin-w-50 for-custom-select'>
                  <label>Date entered into MLS</label>
                  <DatePicker
                    defaultValue={
                      propertyDetail?.listDate.value
                        ? moment(propertyDetail?.listDate.value)
                        : null
                    }
                    onChange={e => this.handleSelectDate(e, 'listDate')}
                    format='YYYY-MM-DD'
                    disabledDate={disabledDate}
                    name='listDate'
                    allowClear={false}
                    className='admin-custom-date-picker'
                  />
                </div>
              </div>
              <div className='admin-input-and-data-wrapper' style={{ marginBottom: Enums.NUMERIC_VALUE.TWENTY }}>
                <div className='admin-w-50 for-custom-select'>
                  <label>Expire Date</label>
                  <DatePicker
                    defaultValue={
                      propertyDetail?.expireDate.value
                        ? moment(propertyDetail?.expireDate.value)
                        : null
                    }
                    onChange={e => this.handleSelectDate(e, 'expireDate')}
                    format='YYYY-MM-DD'
                    disabledDate={pastDisableDate}
                    name='expireDate'
                    allowClear={false}
                    className='admin-custom-date-picker'
                  />
                </div>
                <div className='admin-w-50 for-custom-select'>
                  <label>Estimated Close Date</label>
                  <DatePicker
                    defaultValue={
                      propertyDetail?.estimatedCloseDate.value
                        ? moment(propertyDetail?.estimatedCloseDate.value)
                        : null
                    }
                    onChange={e => this.handleSelectDate(e, 'estimatedCloseDate')}
                    format='YYYY-MM-DD'
                    disabledDate={pastDisableDate}
                    name='estimatedCloseDate'
                    allowClear={false}
                    className='admin-custom-date-picker'
                  />
                </div>
              </div>
              <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-50'>
              <InputFlex
                options={customerTypeList}
                name='customerTypeId'
                title='Customer Record'
                type='text'
                value={propertyDetail?.customerTypeId?.value}
                error={propertyDetail?.customerTypeId?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                placeholder='Select Customer Type'
                className='inputgap'
                isKeyPaired={true}
              /></div>
              <div className='admin-w-50 CurrencyInputField'>
                  <label>Estimated USR Close Fee</label>
                  <div className="">
              <CurrencyInput
                decimalsLimit={Enums.NUMERIC_VALUE.TWO}
                decimalScale={Enums.NUMERIC_VALUE.TWO}
                intlConfig={{ locale: 'en-US', currency: 'USD' }}
                value={propertyDetail?.usrClosingFee?.value}
                onValueChange={(e)=>this.handleFieldChangedCurrency(e,'usrClosingFee')}
                onBlur={this.handleFieldBlur}
                      name="usrClosingFee"
                      className="ant-calendar-picker-input ant-input"
                    />
                    </div>
                </div>
              </div>
              {Number(propertyDetail?.customerTypeId.value) ===
                Enums.NUMERIC_VALUE.ONE && (
                  <div className="user-with-switch-form-group user-customer-record">
                    <label>User
                      <div className="user-link-wrappaer">
                      {Number(getLocalUserData()?.userTypeId) <= Number(existingUserId?.value?.value) &&
                      <Link
                        to={editPageAction(existingUserId?.value, entityTypes.users)}
                      >
                        View User
                      </Link>
                        }
                        {
                          Number(getLocalUserData()?.userTypeId) <= Number(existingUserId?.value?.value) &&
                          <span onClick={() => this.switchToCustomerPortal(existingUserId?.value?.value)}>Switch to User</span>
                        }
                      </div>
                    </label>
                    <AdminUserSearchInput
                        placeholder='Enter User Name or Email'
                        name='existingUserId'
                        value={(userSearchString === null && existingUserId?.value?.label) || userSearchString}
                        predictions={usersList}
                        handleUserSelected={e => this.handleUserSelected(e, 'existingUserId')}
                        onKeyDown={e => this._handleKeyDown(e)}
                        onChange={this.onSearch}
                        className={userSearchClassName}
                      />
                  {propertyDetail?.existingUserId?.error && (
                    <span className='select-text-red'>
                      {validationMessages.userValueRequired}
                    </span>
                  )}
                  </div>
                )}

              {Number(propertyDetail?.customerTypeId.value) ===
                Enums.NUMERIC_VALUE.TWO && (
                  <>
                    <RowFlex>
                      <InputFlex
                        type='text'
                        name='customerFirstName'
                        title='Customer First Name'
                        value={propertyDetail?.customerFirstName?.value}
                        error={propertyDetail?.customerFirstName?.error}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                        className={`inputgap ${propertyDetail.customerFirstName?.error
                          ? itemTextRed
                          : ''
                          }`}
                      />
                      <InputFlex
                        type='text'
                        name='customerLastName'
                        title='Customer Last Name'
                        value={propertyDetail?.customerLastName?.value}
                        error={propertyDetail?.customerLastName?.error}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                        className={`inputgap ${propertyDetail.customerLastName?.error
                          ? itemTextRed
                          : ''
                          }`}
                      />
                    </RowFlex>
                    <RowFlex>
                      <InputFlex
                        title='Email Address'
                        type='email'
                        name='customerEmail'
                        value={propertyDetail?.customerEmail?.value}
                        error={propertyDetail?.customerEmail?.error}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                        className={`inputgap ${propertyDetail.customerEmail?.error ? itemTextRed : ''
                          }`}
                      />
                      <InputFlex
                        type='text'
                        name='contactPhone'
                        title='Customer Phone'
                        value={propertyDetail?.contactPhone?.value}
                        error={propertyDetail?.customerEmail?.error}
                        mask={propertyDetail?.contactPhone?.mask}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                        className={`inputgap ${propertyDetail.contactPhone?.error ? itemTextRed : ''
                          }`}
                      />
                    </RowFlex>
                    <div className='admin-input-and-data-wrapper'>
                      <div className='admin-w-50'>
                        <div className='admin-input-with-icon with-bottom-text'>
                          <InputFlex
                            name='password'
                            title='Password'
                            type={
                              this.state.passwordVisible ? 'password' : 'text'
                            }
                            className='inputgap admin-input-with-data'
                            value={propertyDetail?.password.value}
                            error={propertyDetail?.password?.error}
                            onChange={this.updatePassword}
                            onBlur={this.handleFieldBlur}
                          />
                          <i className={this.state.passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                            aria-hidden='true'
                            onClick={() => this.setState({ passwordVisible: !this.state.passwordVisible })}
                          ></i>
                        </div>
                        <div className='generate-password-text'>
                          <span
                            type='submit'
                            className='block'
                            onClick={() => this.generatePassword(true)}
                          >
                            Generate New Password
                        </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <InputFlex
                options={lookup?.productPlans}
                name='productId'
                title='Product Plan ID'
                type='text'
                value={propertyDetail?.productId?.value}
                error={propertyDetail?.productId?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                placeholder='Select Product Plan'
                className='inputgap'
                isKeyPaired={true}
              />
              {!this.isNewProperty &&
                <div className="order-id-wrapper">
                  <span>Order Id:</span>
                  <strong>{selectedPropertyData?.orderId}</strong>
                </div>}
              <h3 className='admin-form-inner-heading'>Address Info</h3>
              <div className='add-edit-property-address'>
                <PropertyAddressDetails
                  initialState={propertyDetail}
                  handleFieldChanged={this.handleFieldChanged}
                  handleFieldBlur={this.handleFieldBlur}
                  selectStateAndCounty={this.selectStateAndCounty}
                />
              </div>
              <h3 className='admin-form-inner-heading property-heading'>
                Basic Info
              </h3>
              <PropertyBasicInfo
                lookup={lookup}
                initialState={propertyDetail}
                handleFieldChanged={this.handleFieldChanged}
                handleFieldBlur={this.handleFieldBlur}
                handleEditorChange={this.handleEditorChange}
                isNewProperty={this.isNewProperty}
              />
              <h3 className='admin-form-inner-heading property-heading'>
                Photos Gallery
              </h3>
              <PhotoManager
                photos={propertyPhotos}
                deletePhoto={deletePhotoFromDB}
                removePhotos={removePhotos}
                photoProgress={photoProgress}
                onNewPhotoAdded={this.handleNewPhotoAdded}
                isNewProperty={this.isNewProperty}
                onDownloadZip={this.DownloadZip}
                onOrderChanged={this.handleOrderChangedProperty}
              />
              <CustomTextarea className='full-width-textarea' style={{marginTop: '30px'}}>
                <label>Notes</label>
                <textarea
                  name='note'
                  type='textarea'
                  value={propertyDetail?.note?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </CustomTextarea>
              <>
                <h3 className='admin-form-inner-heading property-heading'>
                  Select a Title and Escrow Provider
                </h3>
                <TitleCompanyPanel
                  state={this.state.propertyDetail.addressState.value}
                  titleCompanyId={this.state.propertyDetail.titleCompanyId.value}
                  titleCompanyName={this.state.propertyDetail.titleCompanyName.value}
                  titleCompanyEmail={this.state.propertyDetail.titleCompanyEmail.value}
                  handleChange={this.handleTitleCompanyChange}
                  disabled={this.props.selectedPropertyData?.titleCompany}
                />
              </>
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => {
                      goBack();
                      this.props.resetPropertyData();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    onClick={this.validateAndUpdateAccountDetails}
                  >
                    {this.isNewProperty ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
         {selectedPropertyData.isPaymentCompletedMSG &&
          <ModalDiv
          title="Complete Payment"
          cancelButtonProps={{ style: { display: "none" } }}
          visible={this.state.isPyamentSubmitted}
          onOk={this.handlePaymentPopupSubmission}
          okText="OK"
          closable={false}
          destroyOnClose={true}
          className="confirm-modal"
        >
          <p className="static-text">
          {selectedPropertyData.isPaymentCompletedMSG}
          </p>
        </ModalDiv>
          }
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
    selectedItemData: state.admin.selectedItemData || { photos: [] },
    photoProgress: state.admin.photoProgress,
    s3Identity: state.user.s3Identity,
    appSettings: state.user.appSettings,
    listing: state.admin.selectedItemData || { photos: [] },
    usersList: state.admin.matchedUsersList,
    titleCompany: state.adminTitleCompanies.titleCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...adminActionCreators, ...userActionCreators, ...titleCompaniesCreators },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProperty);