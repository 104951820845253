import React from 'react'

export const HouseIcon = () => (
  <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.0847 19.2547V41.6038C42.0847 41.7794 42.0847 41.955 42.0847 42.1379H26.0492V17.6087C26.0492 17.4331 26.0492 17.2502 26.0492 17.0746H42.0847V19.2547ZM22.157 12.1146L16.1218 5.43549L15.2545 4.46983H28.6222C29.4021 4.46983 30.182 4.46983 30.9619 4.46983L31.0202 4.52836C31.2128 4.76302 31.4171 4.98765 31.6325 5.20139C33.5519 7.33267 35.4713 9.45906 37.3907 11.5806L40.401 14.9165C40.7508 15.3115 41.1298 15.7285 41.4578 16.1309H25.7796L22.1351 12.1146H22.157ZM24.5623 19.2547V41.6038C24.5623 41.7794 24.5623 41.955 24.5623 42.1379H3.59963V17.6087C3.62878 17.2825 3.62878 16.9545 3.59963 16.6283L7.24405 12.5828L13.2792 5.90368L14.0081 5.10629L17.2298 8.68361L23.2649 15.3628L24.5842 16.8259L24.5623 19.2547ZM31.0275 4.46983C31.1223 4.46983 31.0858 4.46983 31.0275 4.46983V4.46983ZM43.4769 16.5772V16.5333C43.4759 16.4649 43.4572 16.3979 43.4227 16.3389C43.3882 16.2799 43.3391 16.2309 43.2801 16.1967L39.4753 11.9903C37.449 9.73705 35.4178 7.48629 33.3818 5.23796L32.5509 4.29426C32.4415 4.16989 32.3322 3.987 32.1719 3.93579C31.993 3.91008 31.8114 3.91008 31.6325 3.93579H21.4281C19.1978 3.93579 16.9601 3.93579 14.7224 3.93579H13.7821C13.728 3.93166 13.6736 3.93862 13.6222 3.95623C13.5709 3.97383 13.5236 4.00172 13.4833 4.03821L7.87818 10.2418L4.02965 14.5068C3.45383 15.1433 2.79786 15.7578 2.28036 16.4455C2.15433 16.64 2.10738 16.8756 2.14916 17.1039V34.9539C2.14916 37.4924 2.14916 40.031 2.14916 42.5695C2.15282 42.606 2.15282 42.6427 2.14916 42.6792C2.15189 42.7363 2.16606 42.7922 2.19085 42.8436C2.21563 42.8951 2.25051 42.9409 2.29339 42.9785C2.33627 43.0161 2.38626 43.0446 2.44038 43.0623C2.4945 43.08 2.55166 43.0865 2.60836 43.0815H42.5001C42.7771 43.0815 43.4623 42.9572 43.4623 42.5475V19.7375C43.4623 18.6913 43.4623 17.6306 43.4623 16.5772" fill="#242B26"/>
    <path d="M43.4769 16.5772V16.5333C43.4759 16.4649 43.4572 16.3979 43.4227 16.3389C43.3882 16.2799 43.3391 16.2309 43.2801 16.1967L39.4753 11.9903C37.449 9.73705 35.4178 7.48629 33.3818 5.23796L32.5509 4.29426C32.4415 4.16989 32.3322 3.987 32.1719 3.93579C31.993 3.91008 31.8114 3.91008 31.6325 3.93579H21.4281C19.1978 3.93579 16.9601 3.93579 14.7224 3.93579H13.7821C13.728 3.93166 13.6736 3.93862 13.6222 3.95623C13.5709 3.97383 13.5236 4.00172 13.4833 4.03821L7.87818 10.2418L4.02965 14.5068C3.45383 15.1433 2.79786 15.7578 2.28036 16.4455C2.15433 16.64 2.10738 16.8756 2.14916 17.1039V34.9539C2.14916 37.4924 2.14916 40.031 2.14916 42.5695C2.15282 42.606 2.15282 42.6427 2.14916 42.6792C2.15189 42.7363 2.16606 42.7922 2.19085 42.8436C2.21563 42.8951 2.25051 42.9409 2.29339 42.9785C2.33627 43.0161 2.38626 43.0446 2.44038 43.0623C2.4945 43.08 2.55166 43.0865 2.60836 43.0815H42.5001C42.7771 43.0815 43.4623 42.9572 43.4623 42.5475V19.7375C43.4623 18.6913 43.4623 17.6306 43.4623 16.5772M42.0847 19.2547V41.6038C42.0847 41.7794 42.0847 41.955 42.0847 42.1378H26.0492V17.6087C26.0492 17.4331 26.0492 17.2502 26.0492 17.0746H42.0847V19.2547ZM22.157 12.1146L16.1218 5.43549L15.2545 4.46983H28.6222C29.4021 4.46983 30.182 4.46983 30.9619 4.46983L31.0202 4.52836C31.2128 4.76302 31.4171 4.98765 31.6325 5.20139C33.5519 7.33267 35.4713 9.45906 37.3907 11.5806L40.401 14.9165C40.7508 15.3115 41.1298 15.7285 41.4578 16.1309H25.7796L22.1351 12.1146H22.157ZM24.5623 19.2547V41.6038C24.5623 41.7794 24.5623 41.955 24.5623 42.1378H3.59963V17.6087C3.62878 17.2825 3.62878 16.9545 3.59963 16.6283L7.24405 12.5828L13.2792 5.90368L14.0081 5.10629L17.2298 8.68361L23.2649 15.3628L24.5842 16.8259L24.5623 19.2547ZM31.0275 4.46983C31.1223 4.46983 31.0858 4.46983 31.0275 4.46983V4.46983Z" stroke="#242B26" strokeWidth="0.4"/>
    <path d="M12.1465 26.2257H16.0537V37.0581H12.1465V26.2257ZM17.2585 25.6823C17.218 25.6743 17.1764 25.6743 17.136 25.6823H11.9151C11.6769 25.6823 10.8396 25.7811 10.8396 26.148V37.538C10.8396 37.6227 11.037 37.6439 11.071 37.6439H16.2851C16.5302 37.6439 17.3674 37.5451 17.3674 37.1781V25.7599C17.3674 25.7246 17.3266 25.6964 17.2585 25.6823Z" fill="#242B26"/>
    <path d="M17.2585 25.6823C17.218 25.6743 17.1764 25.6743 17.136 25.6823H11.9151C11.6769 25.6823 10.8396 25.7811 10.8396 26.148V37.538C10.8396 37.6227 11.037 37.6439 11.071 37.6439H16.2851C16.5302 37.6439 17.3674 37.5451 17.3674 37.1781V25.7599C17.3674 25.7246 17.3266 25.6964 17.2585 25.6823M12.1465 26.2257H16.0537V37.0581H12.1465V26.2257Z" stroke="#242B26" strokeWidth="0.4"/>
    <path d="M12.5617 21.7778H12.1465V16.5232H16.0537V21.8164L12.5617 21.7778ZM17.2449 15.8828H17.136H11.9151C11.6646 15.8817 11.4166 15.9395 11.1868 16.0526C11.071 16.1066 10.8396 16.2069 10.8396 16.3844V22.3025C10.8396 22.3951 11.037 22.4105 11.071 22.4105H16.2851C16.5357 22.4124 16.7838 22.3545 17.0134 22.2408C17.1292 22.1868 17.3674 22.0865 17.3674 21.9013V15.9908C17.3674 15.9445 17.3197 15.9137 17.2449 15.8983" fill="#242B26"/>
    <path d="M17.2449 15.8828H17.136H11.9151C11.6646 15.8817 11.4166 15.9395 11.1868 16.0526C11.071 16.1066 10.8396 16.2069 10.8396 16.3844V22.3025C10.8396 22.3951 11.037 22.4105 11.071 22.4105H16.2851C16.5357 22.4124 16.7838 22.3545 17.0134 22.2408C17.1292 22.1868 17.3674 22.0865 17.3674 21.9013V15.9908C17.3674 15.9445 17.3197 15.9137 17.2449 15.8983M12.5617 21.7778H12.1465V16.5232H16.0537V21.8164L12.5617 21.7778Z" stroke="#242B26" strokeWidth="0.4"/>
  </svg>
);
