const folderName = {
    Images: 'images',
    FeatureSlider: "FeatureSlider",
    WhyUseUs: "WhyUseUs",
    About: "About",
    Agent: "Agent",
    Commercial: "Commercial",
    Press: "Press",
    ContractClosing: "ContractClosing",
    HomeSellingGuide: "HomeSellingGuide",
    ReadyToSell: "ReadyToSell",
    HomeSellingBook: "HomeSellingBook",
    Checklists: "checklist",
    MediaCoverage: "media-coverage",
    pdfs: "pdfs"
};

const baseImageURL = '/upload-content/';

export const ImagesUrl = {
    DEFAULT_LISTING_PHOTO: baseImageURL + folderName?.Images + "/Please-Add-Photo.png",
    HOME_BANNER_IMAGE: baseImageURL + folderName?.Images + "/aspot-img-med.png",
    DISCOVER_SUCCESS_IMAGE: baseImageURL + folderName?.Images + "/discover-success-bg-med.png",
    US_MAP_IMAGE: baseImageURL + folderName?.Images + "/usmap2.png",
    ZILLOW_LOGO_IMAGE: baseImageURL + folderName?.Images + "/zillow-logo.png",
    TRULIA_LOGO_IMAGE: baseImageURL + folderName?.Images + "/trulia-logo.png",
    REALTOR_LOGO_IMAGE: baseImageURL + folderName?.Images + "/realtor-logo.png",
    SOLD_SIGN_IMAGE: baseImageURL + folderName?.Images + "/sign_post_2.png",
    COMPANY_LOGO: baseImageURL + folderName?.Images + "/logo.svg",
    LOGIN_BACKGROUND: baseImageURL + folderName?.Images + "/login-background.jpg",

    /**********    Feature Slider    **********/
    INMAN: baseImageURL + folderName?.FeatureSlider + '/inman-wr3-1.png',
    MARKET_WATCH: baseImageURL + folderName?.FeatureSlider + '/market-watch-wr3-1.png',
    MONEY: baseImageURL + folderName?.FeatureSlider + '/money-1.jpg',
    MY_MOVE: baseImageURL + folderName?.FeatureSlider + '/my-move-wr3-1.png',
    THE_WALL_STREETJOURNAL: baseImageURL + folderName?.FeatureSlider + '/The-Wall-Street-Journal_logo.png',
    THE_ECONOMIST: baseImageURL + folderName?.FeatureSlider + '/theeconomist-1.jpg',
    BLOOMBERG: baseImageURL + folderName?.FeatureSlider + '/usrealtycom-featuredin-bloomberg-radio-f-1.png',
    BALTIMORE_SUN_LOGO: baseImageURL + folderName?.FeatureSlider + '/baltimore-sun-logo-wr3-1.png',
    BOB_VILA: baseImageURL + folderName?.FeatureSlider + '/bob-vila-hp-wr3-1.png',
    CHEAT_SHEET: baseImageURL + folderName?.FeatureSlider + '/cheat-sheet-wr3-1.png',
    CURBED_LOGO: baseImageURL + folderName?.FeatureSlider + '/curbed_logo_sm-1.png',
    FINANCIAL_TIMES: baseImageURL + folderName?.FeatureSlider + '/financialtimes-1.jpg',
    FOX_NEWS: baseImageURL + folderName?.FeatureSlider + '/fox-news-wr3-1.png',
    HOUSING_WIRE: baseImageURL + folderName?.FeatureSlider + '/housingwire-wr3-1.png',

    /**********    HomeSellingBook    **********/
    HOME_SELLING_BOOK: baseImageURL + folderName?.HomeSellingBook + "/HomeSellingSuccess_USRealty.pdf",
    FLAT_FEE_MLSSUCCESS: baseImageURL + folderName?.HomeSellingBook + "/FlatFeeMLSSuccess.jpeg",

    /**********    ReadyToSell    **********/
    READY_TO_SELL_CHECKLIST: baseImageURL + folderName?.ReadyToSell + "/USRealty-Ready-to-Sell-Checklist.pdf",

    /**********    HomeSellingGuide    **********/
    HOME_SELLING_GUIDE: baseImageURL + folderName?.HomeSellingGuide + "/USRealty-Ready-to-Sell-Checklist.pdf",

    /**********    ContractClosing    **********/
    BUYER_QUALIFICATION: baseImageURL + folderName?.ContractClosing +"/buyerqualification_form.pdf",

    /**********    Checklists    **********/
    HOME_PREP_CHECKLIST: baseImageURL + folderName?.Checklists + "/USRealty-Home-Prep-Checklist.pdf",

    /**********    About    **********/
    ASPOT_HOUSE: baseImageURL + folderName?.About +"/aspot-house-700x394.jpg",

    /**********    Agent    **********/
    BG_FPO_WRK: baseImageURL + folderName?.Agent + "/bg_fpo-wrk.jpg",
    US_REALTY_AGENTS: baseImageURL + folderName?.Agent + "/us-realty-agents-2016.jpg",
    USR_FOR_SALE_SIGN_ICON: baseImageURL + folderName?.Agent + "/usr-for-sale-sign-icon.png",
    USR_LISTING_LAPTOP_ICON: baseImageURL + folderName?.Agent + "/usr-listing-laptop-icon.png",
    USR_HOME_DOLLAR_EXCHANGE_ICON: baseImageURL + folderName?.Agent + "/usr-home-dollar-exchange-icon.png",
    USR_LISTING_SHEET_ICON: baseImageURL + folderName?.Agent + "/usr-listing-sheet-icon-n.png",
    SELLRT_MANAGEMENT: baseImageURL + folderName?.Agent + "/seller-management-icon-us.png",
    USR_THUMBS_UP_HOUSE_ICON: baseImageURL + folderName?.Agent + "/usr-thumbs-up-house-icon.png",
    USREALTY_SUCCESS: baseImageURL + folderName?.Agent + "/usrealty-success-guy.jpg",

    /**********    Commercial    **********/
    USREALTY_INSTITUTIONAL_ONEPAGEER: baseImageURL + folderName?.Commercial + "/USRealty-Institutional-onepager-final.pdf",

    /**********    Press    **********/
    USREALTY_MEDIA_ONEPAGE: baseImageURL + folderName?.Press + "/USRealty-Media-OnePage-2.pdf",
    USREALTY_LOGO_BIG: baseImageURL + folderName?.Press + "/US-Realty-Logo-450x65.jpg",
    USREALTY_LOGO: baseImageURL + folderName?.Press + "/US-Realty-Logo.jpg",

    /**********    WhyUseUs    **********/
    ZILLOW_LOGO: baseImageURL + folderName?.WhyUseUs +"/zillow-logo.png",
    TRULIA_LOGO: baseImageURL + folderName?.WhyUseUs +"/trulia-logo.png",
    REALTOR_LOGO: baseImageURL + folderName?.WhyUseUs +"/realtor-logo.png",
};

export const PdfUrls = {
    /**********    Media Coverage PDF Links    **********/
    PRESS_1: baseImageURL + folderName?.MediaCoverage + "/Washington-Post-Inside-the-D.C.-area%E2%80%99s-most-expensive-homes-for-sale-The-Washington-Post.pdf",
    PRESS_2: baseImageURL + folderName?.MediaCoverage + "/June-2017-Chief-Investment-Officer-Real-Estate-Trends-.pdf",
    PRESS_3: baseImageURL + folderName?.MediaCoverage + "/5-Tips-for-Selling-Your-Home-Without-an-Agent-Consumer-Reports.pdf",
    PRESS_4: baseImageURL + folderName?.MediaCoverage + "/MortgageLoan-Feb.-13-2017-How-much-home-can-you-afford.pdf",
    PRESS_5: baseImageURL + folderName?.MediaCoverage + "/4-Urgent-Reasons-You-Should-Sell-Your-Home-in-2017-realtor.com%C2%AE.pdf",
    PRESS_6: baseImageURL + folderName?.MediaCoverage + "/Reuters-Jan-11-2017-Impatience-and-desperation-derail-home-sales-in-tight-market-Reuters.pdf",
    PRESS_7: baseImageURL + folderName?.MediaCoverage + "/The-Street-Jan.-3-2017-Here-Are-the-Big-Economic-Predictions-for-2017-You-Have-to-Know-TheStreet.pdf",
    PRESS_8_1: baseImageURL + folderName?.MediaCoverage + "/What-city-real-estate-markets-can-expect-in-2017-Curbed.pdf",
    PRESS_8_2: baseImageURL + folderName?.MediaCoverage + "/Home-equity-wealth-is-on-the-rise-Greater-Media-Newspapers.pdf",
    PRESS_9: baseImageURL + folderName?.MediaCoverage + "/Term-Sheet-Fortune-Nov.-1-2016-.pdf",
    PRESS_10: baseImageURL + folderName?.MediaCoverage + "/PE-Hub-USRealty.com-nabs-funding-from-Third-Prime-PE-Hub.pdf",
    PRESS_11: baseImageURL + folderName?.MediaCoverage + "/USRealty.com-Closes-Financing-with-Third-Prime-Capital.pdf",
    PRESS_12: baseImageURL + folderName?.MediaCoverage + "/Housing-Market-Forecast-The-Mortgage-Reports-.pdf",
    PRESS_13: baseImageURL + folderName?.MediaCoverage + "/September-2016-REAL-Trends.pdf",
    PRESS_14_1: baseImageURL + folderName?.MediaCoverage + "/5-Ways-Youre-Sabotaging-Your-Home-Sale-Pg.2-TheStreet.pdf",
    PRESS_14_2: baseImageURL + folderName?.MediaCoverage + "/Home-Shoppers-Seeking-Leverage-in-a-Tight-Market-Pg.2-TheStreet.pdf",
    PRESS_15: baseImageURL + folderName?.MediaCoverage + "/What-to-Do-When-You-Cant-Sell-Your-House-US-News-Real-Estate.pdf",
    PRESS_16: baseImageURL + folderName?.MediaCoverage + "/Yahoo-Finance-5-Things-That-Arent-Worth-As-Much-Money-As-You-Think.pdf",
    PRESS_17: baseImageURL + folderName?.MediaCoverage + "/Housing-peaks-and-valleys-Greater-Media-Newspapers.pdf",
    PRESS_19_1: baseImageURL + folderName?.MediaCoverage + "/5-Things-That-Are-Not-Worth-as-Much-Money-as-You-Think.pdf",
    PRESS_19_2: baseImageURL + folderName?.MediaCoverage + "/5-Bad-Home-Renovations-That-Lower-Your-Homes-Value.pdf",
    PRESS_20: baseImageURL + folderName?.MediaCoverage + "/11-Budget-Friendly-Staging-Ideas-Thatll-Wow-Buyers-Fox-News.pdf",
    PRESS_21: baseImageURL + folderName?.MediaCoverage + "/Inman-6-27-16.pdf",
    PRESS_22: baseImageURL + folderName?.MediaCoverage + "/Marketwatch-6-6-16022.pdf",
    PRESS_23: baseImageURL + folderName?.MediaCoverage + "/USRealty.com-Launches-New-Online-Service-Revolutionizing-The-Home-Selling-Process-TheStreet.pdf",
    PRESS_24: baseImageURL + folderName?.MediaCoverage + "/HousingWire-9-22-14.pdf",
    PRESS_25: baseImageURL + folderName?.MediaCoverage + "/Inman-News-9-3-13-.pdf",
    PRESS_26: baseImageURL + folderName?.MediaCoverage + "/Inman-News-9-3-13-.pdf",
    PRESS_27: baseImageURL + folderName?.MediaCoverage + "/Baltimore-Sun-3-30-13.pdf",
    PRESS_28: baseImageURL + folderName?.MediaCoverage + "/BobVila-Real-Estate-Commissions-.pdf",
    PRESS_29: baseImageURL + folderName?.MediaCoverage + "/The-great-realtor-rip-off-The-Economist.pdf",
    TX_AGENCY_DISCLOSURE: baseImageURL + folderName.pdfs + "/TX-Agency-Disclosure-Form.pdf",
    NEW_YORK_STATE_FAIR_HOUSING_REGULATIONS_NOTICE: baseImageURL + folderName.pdfs + "/New-York-State-Fair-Housing-Regulations-Notice.pdf",
    NYSOP_NOTARIZE: baseImageURL + folderName.pdfs + "/NYSOP-NOTARIZE.pdf",
    TREC_COMMISSION_CONSUMER_PROTECTION_NOTICE: baseImageURL + folderName.pdfs + "/TREC-Commission-Consumer-Protection-Notice.pdf",
}

export const videoUrl = baseImageURL + folderName?.Images + "/US_Realty_FINAL_HD.mp4";

export const mediaCoverageURL = "https://usrealty.com/press/media-coverage/";
