import React from 'react';

import styled from 'styled-components';
import { CloseCrossIcon } from '../../Shared/Components/Icons/CloseCrossIcon';
import ListingPreview from './ListingPreview';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  position: relative;
  top: -2rem;
`;

const CloseButton = styled.button`
  width: 18.5rem
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3rem;
  border: 1.5px dashed #242B26;
  border-radius: 48px;
  background: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  top: 7rem;
`;

const ListingPreviewMobileContainer = ({ onClose })  => {

  return (
    <Container>
      <ListingPreview />
      <CloseButton onClick={onClose}>
      <CloseCrossIcon />  
        Close
      </CloseButton>
    </Container>
  );

};

export default ListingPreviewMobileContainer;