import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import {
  contentType,
  apiMethodType,
} from '../Constants/CommonConstants';
import ApiUrls from '../Constants/ApiUrls';

class SellerNotificationService {

  сheckMls(params) {

    const url = ApiUrls.sellerNotificationService + '/checkmls';

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
  submitOffer(params) {

    const url = ApiUrls.sellerNotificationService + '/submitoffer';

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
  uploadOfferPdf(params) {

    const url = ApiUrls.sellerNotificationService + '/uploadofferpdf';
    const formData = new FormData();
    formData.append("name", params.name);
    formData.append("file", params.selectedFile);
    return fetch(url, {
      method: apiMethodType.POST,
      data: formData,
      signal: params.signal,
      headers: {
        ...authHeader()
      },
      onUploadProgress: params.onUploadProgress,
    });
  }
  requestShowing(params) {

    const url = ApiUrls.sellerNotificationService + '/requestshowing';

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

}

export let sellerNotificationService = new SellerNotificationService();