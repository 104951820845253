import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';

class VoucherService {

  getVouchersCreatedByUser(userId, params) {
    const query = new URLSearchParams(params).toString();
    const url = `api/v2/vouchers/user/${userId}?${query}`;

    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
}

export const voucherService = new VoucherService();
