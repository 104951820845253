import Enums from "../Constants/Enums";

export function authHeader() {
    const pathArray = window.location.pathname.split('/');
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user && pathArray[Enums.NUMERIC_VALUE.ONE] === 'admin') {
        return { 'Authorization': 'Bearer ' + user.token };
    } else if (user && user.isAdminSwitchUser && user.token) {
        return { 'Authorization': 'Bearer ' + user?.switchedUser.token };
    } else if (user  && !user.isAdminSwitchUser && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}
