import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import {
  bathroomsSelector,
  bedroomsSelector,
  listingImagesSelector,
  listingStepSelector,
  propertyPriceSelector,
  selectedAddressSelector,
  acresSelector,
  squareFeetSelector,
  totalSavingSelector,
  averagePriceSelector,
  } from '../../../Reducers/OnboardingReducerV2';
import { DollarSign } from '../../Shared/Components/Icons/DollarSign';
import { Heart } from '../../Shared/Components/Icons/Heart';
import { NewLabel } from '../../Shared/Components/Icons/NewLabel';
import { NoImage } from '../../Shared/Components/Icons/NoImage';
import CurrencyFormat from 'react-currency-format';
import { averagePriceLoadingSelector } from '../../../Reducers/LoaderReducer';

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
`;

const AveragePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  justify-content: center;
`;

const TotalSavingContainer = styled(AveragePriceContainer)`
  background: ${({ theme }) => theme.lightGreen};
  justify-content: space-between;
  padding: 1.5rem;
  display: none;

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    display: flex;
  }
`;


const PropertyCard = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0.25rem 0.25rem 1.25rem 0.25rem
background: #FFFFFF;
box-shadow: 0px 6px 8px rgba(36, 43, 38, 0.05);
border-radius: 10px;
`;

const PropertyImage = styled.img`
height: 18rem;
width: 18rem;
object-fit: cover;
border-radius: 10px;
`;

const PropertyImageContainer = styled.div`
height: 18rem;
width: 18rem;
object-fit: cover;
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
position: relative;
background-color: ${({ theme }) => theme.colors.grayBackground};
`;

const InfoContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 0.625rem;
padding: 0px 0px 0px 12px;
`;

const PriceText = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
`;

const TotalSavingText = styled(PriceText)`
  padding-bottom: 0;
  align-self: center;
  padding-right: 1rem;
`;

const AveragePriceAmount = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 6px;
`;

const AveragePriceText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #5B605C;
`;

const InfoText = styled(PriceText)`
  font-weight: 300;
`;

const AddresText = styled(InfoText)`
  font-size: 12px;
  line-height: 16px;
`;

const NewLabelContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
`;

const HeartIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1;
`;

const AveragePriceTextContainer = styled.div`
  flex-direction: column;
  display: flex;
  padding-top: 0.25rem;
  padding-left: 1rem;
`;

const TotoalSavingValueText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  margin-left: 0.5rem;
`;

const TotoalSavingValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListingPreview = () => {
  const selectedAddress = useSelector(selectedAddressSelector);
  const address = selectedAddress?.formatted;
  const price = useSelector(propertyPriceSelector);
  const beds = useSelector(bedroomsSelector);
  const baths = useSelector(bathroomsSelector);
  const SqFt = useSelector(squareFeetSelector);
  const acres = useSelector(acresSelector);
  const listingImages = useSelector(listingImagesSelector);
  const totalSaving = useSelector(totalSavingSelector);
  const step = useSelector(listingStepSelector);
  const averagePrice = useSelector(averagePriceSelector);
  const averagePriceLoading = useSelector(averagePriceLoadingSelector);
  const coverPhotoUrl = useMemo(() => listingImages?.find(x => x.order === 0)?.url, [listingImages]);

  return (
    <>
      <PropertyCard>
      <PropertyImageContainer>
        <NewLabelContainer>
          <NewLabel />
        </NewLabelContainer>
        <HeartIconContainer>
          <Heart />
        </HeartIconContainer>
        {coverPhotoUrl ? (
          <PropertyImage src={coverPhotoUrl}/>
        ) : (
          <NoImage />
        )}
      </PropertyImageContainer>
      <InfoContainer>
        <PriceText>
          {price > 0 ? 
            <CurrencyFormat
              displayType={'text'}
              thousandSeparator=','
              decimalSeparator='.'
              suffix=".00"
              value={price}
              prefix={'$'}
            /> 
            : '$ 0.00'
          }
        </PriceText>
        <InfoText>
          {beds || ''} Beds • {baths || ''} Baths • {acres || SqFt || ''} {acres ? 'Acres' : 'Sq. Ft.'}
        </InfoText>
        <AddresText>
          {address || 'Address'}
        </AddresText>
      </InfoContainer>
      </PropertyCard>
      {(averagePrice?.from || 0) !== 0 && (
        <AveragePriceContainer>
          {!averagePriceLoading ? (
            <>
              <DollarSign />
              <AveragePriceTextContainer>
                <AveragePriceAmount>
                <CurrencyFormat  prefix='$' thousandSeparator=',' decimalSeparator='.'  suffix=".00" value={averagePrice.from} displayType='text'/>
                  -
                <CurrencyFormat  prefix='$' thousandSeparator=',' decimalSeparator='.'  suffix=".00" value={averagePrice.to} displayType='text'/>
                </AveragePriceAmount>
                <AveragePriceText>
                  Average price range in the area.
                </AveragePriceText>
              </AveragePriceTextContainer>
            </>
          ) : (
            <Loader className="spinner" animation="border" size="sm" />
          )}
        </AveragePriceContainer>
      )}
      {totalSaving > 0 && step !== 8 && ( // do not display this block in billing screen(step 8).
        <TotalSavingContainer>
          <TotalSavingText>
            Your potential savings by listing with Unreal Estate
          </TotalSavingText>
          <TotoalSavingValueContainer>
            <TotoalSavingValueText>
              <CurrencyFormat
                prefix='$'
                thousandSeparator=','
                decimalSeparator='.'
                displayType='text'
                suffix=".00"
                value={totalSaving}
              />
            </TotoalSavingValueText>
          </TotoalSavingValueContainer>
        </TotalSavingContainer>
      )}
    </>
  );

};

export default ListingPreview;