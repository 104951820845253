import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import Select, { createFilter } from 'react-select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companiesSelector, formTypesSelector, mlsAbbreviationsSelector, statesSelector } from '../../../../../Reducers/LookupReducer';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { cursorStyles, formLayoutType, reactSelectFilters } from '../../../../../Constants/CommonConstants';
import { formsCreators } from '../../../../../Actions/V2/Admin/FormsActions';
import { formsFiltersSelector } from '../../../../../Reducers/V2/AdminFormsReducer';

const ListingFormsFilters = () => {
    const dispatch = useDispatch();
    const filters = useSelector(formsFiltersSelector);
    const mlsAbbreviations = useSelector(mlsAbbreviationsSelector);
    const states = useSelector(statesSelector);
    const formTypes = useSelector(formTypesSelector);
    const companies = useSelector(companiesSelector);

    const mlsAbbreviationsOptions = mlsAbbreviations.map(x => ({ value: x.id, label: x.name }));
    const statesOptions = states.map(x => ({ value: x.id, label: x.name }));
    const formTypesOptions = formTypes.map(x => ({ value: x.id, label: x.name }));
    const companiesOptions = companies.map(x => ({ value: x.id, label: x.name }));
    const sectionTypes = [
        { value: 2, label: formLayoutType.HTML },
        { value: 3, label: formLayoutType.Image }
    ];

    useEffect(() => {
        dispatch(lookupActionsCreators.getMlsAbbreviations());
        dispatch(lookupActionsCreators.getStates());
        dispatch(lookupActionsCreators.getFormTypes());
        dispatch(lookupActionsCreators.getCompanies());
    }, []);

    const handleChange = (value) => dispatch(formsCreators.setFormsFilters(value));
    
    const handleStateChange = (state) => {
        handleChange({ ...filters, state });
        dispatch(lookupActionsCreators.getMlsAbbreviations(state));
    }

    const applyFilters = () => dispatch(formsCreators.getForms(filters));

    const clearFilters = () => {
        dispatch(formsCreators.clearFormsFilters());
        dispatch(formsCreators.getForms());
    }

    return (
        <div className={`admin-filter-wrapper more-filter`}>
            <div className={`filter-bar`}>
                <div className="filter-bar-left">
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by State</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select State'
                            value={statesOptions.find(x => x.value === filters.state) || null}
                            onChange={e => handleStateChange(e.value)}
                            options={statesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                            createFilter={createFilter(reactSelectFilters)}
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter By MLS Abbreviation</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='MLS Abbreviation'
                            value={mlsAbbreviationsOptions.find(x => x.value === filters.mlsId) || null}
                            onChange={e => handleChange({ ...filters, mlsId: e.value })}
                            options={mlsAbbreviationsOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Company</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Company'
                            value={companiesOptions.find(x => x.value === filters.companyId) || null}
                            onChange={e => handleChange({ ...filters, companyId: e.value })}
                            options={companiesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Form Type</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Form Type'
                            value={formTypesOptions.find(x => x.value === filters.formTypeId) || null}
                            onChange={e => handleChange({ ...filters, formTypeId: e.value })}
                            options={formTypesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter by Section Type</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Section Type'
                            value={sectionTypes.find(x => x.value === filters.sectionTypeId) || null}
                            onChange={e => handleChange({ ...filters, sectionTypeId: e.value })}
                            options={sectionTypes}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                </div>
                <div className='form-group search-buttons'>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => applyFilters()}>
                        Apply Filters
                    </Button>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => clearFilters()}>
                        Reset Filters
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default (ListingFormsFilters);
