import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Elements } from 'react-stripe-elements';
import CurrencyFormat from 'react-currency-format';
import { onboardingService } from '../../../Services/OnboardingService';
import { userActionCreators } from '../../../Actions/UserActions';
import { onboardingActionCreators } from "../../../Actions/OnboardingActions";
import DisplayText from '../Components/DisplayText';
import AddressSelector from '../../CustomerPortal/Components/AddressSelector';
import PlanSelector from '../../CustomerPortal/Components/PlanSelector';
import AccountDetails from '../../CustomerPortal/Components/AccountDetails';
import BillingDetails from '../../CustomerPortal/Components/BillingDetails';
import AddressNotFoundPopUp from '../Modals/AddressNotFoundPopUp';
import Routes from '../../../Constants/Routes';
import { ModalDiv, StepsWrapper, StepBox, MainWrapper, Container, ButtonLink, MapWrapper, MapBox, MapContent, Heading, HeadText } from '../../../Assets/Style/commonStyleComponents';
import { getCurrentUserData, getLocalUserData } from '../../../Utilities/commonFunctions';
import { roleType, productTypeList, staticMessages } from '../../../Constants/CommonConstants';
import CheckAddressCoveragePopUp from '../../ListYourHome/Modals/CheckAddressCoveragePopUp';
import CheckZipCoveragePopUp from '../../ListYourHome/Modals/CheckZipCoveragePopUp';
import DashboardService from '../../../Services/DashboardService';
import { history } from "../../../Utilities/History";
import { trackingService } from '../../../Services/TrackingService'
import KlaviyoEvents from "../../../Constants/KlaviyoEvents";
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { UIActionsCreators } from '../../../Actions/UIActions';
import AppConsts from '../../../Constants/AppConsts';
import { loginModalMessageTypes } from '../../../Reducers/UIReducer';
const dashboardService = new DashboardService();
const AddressSelectorStyled = styled(AddressSelector)`
`;

const steps = {
	SELECT_ADDRESS: "SELECT_ADDRESS",
	SELECT_PLAN: "SELECT_PLAN",
	ACCOUNT_DETAILS: "ACCOUNT_DETAILS",
	BILLING_DETAILS: "BILLING_DETAILS"
}

//TODO: Change it to functional component
class UnbounceListing extends React.Component {
	constructor(props) {
		super(props)
		this.unitInputRef = React.createRef();
		this.state = {
			isAddressConfirmationPopup: false,
			isVoucherRedeemPopup: false,
			editable: false,
			editDone: false,
			updateUnitValue: onboardingService.getLocal("selectedAddress")?.unit || '',
			voucherModalText: '',
			voucherButtonText: '',
			isVoucherSuccess: false,
			isCheckAddressCoverageable: false,
		};
		this.props.getProducts(productTypeList.concierge.id);
		this.isAddressFound = true;
		this.isInstitutionalRole = getCurrentUserData().role === roleType.INSTITUTIONAL;
	}


	componentDidMount() {
		const windowUrl = window.location.search;
		const params = new URLSearchParams(windowUrl);
		const newUser = params.get('new_user');
    if (newUser) {
			this.handleNewUser();
    } else if (!this.props.isLoggedIn) {
			this.props.openLoginModal({
				fixed: true,
			});
		}
		this.checkPlans();
	}

	componentWillUnmount() {
		localStorage.removeItem('selectedAddress');
	}

	handleNewUser = async () => {
		if (this.props.isLoggedIn) {
			await this.props.logout(this.props.userDetails.userId, this.props.userDetails.token);
		}
		history.push({
			pathname: Routes.UNBOUNCE_LISTING,
			search: null,
		});
		this.props.openLoginModal({
			message: staticMessages.emailConfirmed,
			fixed: true,
			loginModalMessageType: loginModalMessageTypes.success,
		});
	}

	checkPlans = () => {
		if (this.props.userDetails?.token) {
			this.props.getDefaultBillingAddress();
		}
	}

	handleAddressSelected = (address) => {
		this.setState({ adressInputValue: address });
		const zipData = address.address_components.find(item => item.types.includes('postal_code'));
		const formattedAddress = address.formatted_address;

		//TODO: Move address autocomplete parsing data to general method, change logic for receiving Zip codes
		if (!zipData) {
			this.setState({ isCheckAddressCoverageable: true });
			return;
		}

		this.props.selectAddress(address);
		const addressCheckData = {
            county: this.props.addressFromLocal.county,
            state: this.props.addressFromLocal.state,
            zip: this.props.addressFromLocal.zip,
            address: this.props.addressFromLocal.formatted,
            userId: getLocalUserData()?.userId,
        }

		this.props.checkAddressCoverage(addressCheckData, true, (res) => {
			if (res) {
				this.props.selectAddress(address);
				this.checkPlans();
				this.isAddressFound = true
				this.setState({ isZipInvalid: false });

				trackingService.track({
					event: KlaviyoEvents.UnbounceSignUpPropertyAddressSelected,
					userInfo: {
						email: this.props.userDetails?.email
					},
					properties: {
						plan: this.props.selectedPlan?.title,
						address: formattedAddress
					}
				});

				return;
			};

			this.setState({ isZipInvalid: true });
		});
	};

	handleChangeAddress = (e) => {
		this.props.selectAddress({});
		this.setState({ updateUnitValue: '' })
		this.isAddressFound = true;
		this.checkPlans();
		e.preventDefault();
	};

	handleChangePlan = (e) => {
		this.props.selectPlan({});
		localStorage.removeItem('isFromHomePage');
		e.preventDefault();
	}

	handleSaveAccountDetails = (accountDetails) => {

	}

	handleSaveBillingDetails = (billingDetails) => {
		this.props.selectPlan(this.props.selectedPlan);
		this.props.placeOrder(billingDetails);
	}

	getCurrentStep = () => {
		if (Object.keys(this.props.selectedAddress).length > 0) {
			if (Object.keys(this.props.userDetails).length > 0) {
				if (Object.keys(this.props.selectedPlan).length > 0) {
					return steps.BILLING_DETAILS;
				}
				return steps.SELECT_PLAN;
			}
			return steps.ACCOUNT_DETAILS;
		}
		return steps.SELECT_ADDRESS;
	}

	handleVouchervalidate = () => {
		this.setState({ isVoucherNotAuthPopup: false });
	}

	unAuthUserRedeemVoucher = () => {
		this.setState({ isVoucherNotAuthPopup: false });
	}

	handleCloseModal = e => {
		this.props.closeModal();
		this.props.selectAddress({});
		this.isAddressFound = true;
	};

	handleCloseCheckAddressCoverageableModal = e => {
		this.props.closeModal();
		this.props.selectAddress({});
		this.isAddressFound = true;
		this.setState({ isCheckAddressCoverageable: false });
	};

	handleCloseZipModal = e => {
		this.props.closeModal();
		this.props.selectAddress({});
		this.isAddressFound = true;
		this.setState({ isZipInvalid: false });
	};

	handleSubmitRequest = (reqObj) => {
		this.isAddressFound = false;
		this.props.nonServiceableAreaRequest(reqObj);
		this.handleCloseModal();
		this.setState({
			isAddressConfirmationPopup: true
		})
	};

	updateUnit = (isDone) => {
		const selectedAddress = onboardingService.getLocal("selectedAddress") || {};
		if (!this.state.editDone) {
			this.unitInputRef.current.focus()
			this.setState({ editable: true, editDone: true });
		} else {
			if (isDone) {
				selectedAddress.unit = this.state.updateUnitValue;
				onboardingService.setLocal("selectedAddress", selectedAddress);
			} else {
				this.unitInputRef.current.value = selectedAddress.unit || '';
				this.setState({
					updateUnitValue: selectedAddress.unit || ''
				})
			}
			this.setState({ editable: false, editDone: false });
		}
	}

	updateUnitValue = (e) => {
		this.setState({ updateUnitValue: e.target.value });
	}

	getGeoCode = async (reqData) => {
		const fullAddress = reqData.addressAddress1 + ' ' + reqData.addressAddress2 + ',' + reqData.addressCity + ',' + reqData.addressState + ',' + reqData.addressZip;
		const { data } = await dashboardService.getLatLong(fullAddress);
		const dataJson = data;
		const jsonResonse = dataJson.value;
		return jsonResonse;
	}

	openLoginModal() {
    this.props.openLoginModal();
  }

	render() {
		const { isUserFetching, redirect, finishOnboarding, userDetails, selectedAddress, planData, isLoggedIn,
			selectPlan, selectedPlan, userErrors, isAddressNotServiceAble, billingAddress, shippingAddress,
			isCheckAddressCoverageable, isBillingAddressExist, addressFromLocal } = this.props;
		const selectedAddressValue = onboardingService.getLocal("selectedAddress");
		const { isAddressConfirmationPopup, adressInputValue } = this.state;

		if (redirect) {
			finishOnboarding();
			return <Redirect to={redirect} />
		}
		const selectedPlanTitleForFree = selectedPlan.title === 'Concierge Free Plan' ? 'Free Plan' : selectedPlan.title;
		const planLabelText = selectedPlan.title === 'Concierge Free Plan' ? 'Account Plan' : 'Concierge Plan';

		return (
			<React.Fragment>
				<Navbar />
				<MainWrapper>
					<Container>
						<StepsWrapper>
							<StepBox className={this.getCurrentStep() === steps.SELECT_ADDRESS || this.getCurrentStep() === steps.ACCOUNT_DETAILS ? "active" : ''} isActive={this.getCurrentStep() === steps.SELECT_ADDRESS || this.getCurrentStep() === steps.ACCOUNT_DETAILS}>
								<span>1</span>
								<strong>Account Details</strong>
							</StepBox>
							<StepBox className={this.getCurrentStep() === steps.SELECT_PLAN ? "active" : ''} isActive={this.getCurrentStep() === steps.SELECT_PLAN}>
								<span>2</span>
								<strong>{ planLabelText }</strong>
							</StepBox>
							<StepBox className={this.getCurrentStep() === steps.BILLING_DETAILS ? "active" : ''} isActive={this.getCurrentStep() === steps.BILLING_DETAILS}>
								<span>3</span>
								<strong>Checkout Info</strong>
							</StepBox>
						</StepsWrapper>
						{Object.keys(selectedAddress).length > 0 &&
							<MapWrapper>
								<MapBox>
									<img
										src={`https://maps.googleapis.com/maps/api/staticmap?center=${selectedAddress?.location?.formatted}&zoom=15&size=1200x100&scale=2&markers=color:${this.props.theme.secondary.replace('#', '0x')}%7Csize:small%7C${selectedAddress?.location?.formatted}&key=${AppConsts.googleMapApiKey}`}
										alt={selectedAddress?.formatted_address}
									/>
								</MapBox>
								<MapContent>
									<DisplayText title="Address">
										{
											<>
												{selectedAddressValue?.address1} {selectedAddressValue?.address2} {selectedAddressValue?.unit + ' '}
												{selectedAddressValue?.city + ','} {selectedAddressValue?.state} {selectedAddressValue?.zip}
												<ButtonLink href="#" onClick={this.handleChangeAddress}>Change</ButtonLink>
											</>

										}
									</DisplayText>
									<DisplayText title="Unit">
										<div className="unit-input-btn-wrapper">
											<input
												ref={this.unitInputRef}
												defaultValue={(this.state.updateUnitValue || selectedAddressValue?.unit)}
												placeholder='None'
												className={this.state.editable ? "unit-input unit-input-border edit-profile " : "unit-input disabled-input"}
												onChange={this.updateUnitValue}
											/>
											{
												this.state.editDone
													?
													<>
														<ButtonLink href="javascript:void(0)" onClick={() => this.updateUnit(true)}>Done</ButtonLink>
														<ButtonLink href="javascript:void(0)" onClick={() => this.updateUnit(false)}>Cancel</ButtonLink>
													</>
													:
													<ButtonLink href="javascript:void(0)" onClick={() => this.updateUnit()}>Change</ButtonLink>
											}
										</div>
									</DisplayText>
								</MapContent>
								{
									Object.keys(selectedPlan).length > 0 &&
									<MapContent className="last-gap">
										<DisplayText title="Plan">
											{selectedPlan.title} -
											<CurrencyFormat
												value={selectedPlan.priceUpFront}
												decimalScale={2}
												fixedDecimalScale={true}
												displayType={'text'}
												thousandSeparator={true}
												prefix={'$'}
											/>
											<ButtonLink href="#" onClick={(e) => this.handleChangePlan(e)}>Change</ButtonLink>
										</DisplayText>
									</MapContent>
								}
								{Object.keys(userDetails).length > 0 &&
									<MapContent className="last-gap">
										<DisplayText title="Account Details">
											{userDetails.firstName} {userDetails.lastName} <br />
											{userDetails.email} <br />
											{userDetails.phoneNumber}
										</DisplayText>
									</MapContent>
								}
							</MapWrapper>
						}
						{this.getCurrentStep() === steps.SELECT_ADDRESS &&
							<>
								{
									Object.keys(selectedPlan).length > 0 &&
									<h3 className="plan-selected-text"><span>Plan:</span> {selectedPlanTitleForFree}</h3>
								}
								<AddressSelectorStyled
									handleAddressSelected={this.handleAddressSelected}
									handleVouchervalidate={this.handleVouchervalidate}
									unAuthUserRedeemVoucher={this.unAuthUserRedeemVoucher}
									isLoggedIn={isLoggedIn}/>
							</>
						}
						{this.getCurrentStep() === steps.SELECT_PLAN &&
							<PlanSelector {...planData} handlePlanSelected={selectPlan} isShowZipcode={false} />
						}
						{this.getCurrentStep() === steps.ACCOUNT_DETAILS &&
							<AccountDetails
							  handleSaveAccountDetails={this.handleSaveAccountDetails}
								errors={userErrors}
								openLoginModal={this.openLoginModal.bind(this)}
							/>
						}
						{!isUserFetching && this.getCurrentStep() === steps.BILLING_DETAILS &&
							<Elements fonts={[{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:200,400,700" }]}>
								<BillingDetails
									handleSaveBillingDetails={this.handleSaveBillingDetails}
									selectedPlan={selectedPlan}
									billingAddress={billingAddress}
									shippingAddress={shippingAddress}
									{...userDetails} {...selectedAddress}
									isBillingAddressExist={isBillingAddressExist}
									addressFromLocal={addressFromLocal}
								/>
							</Elements>
						}
					</Container>
				</MainWrapper>
				<ModalDiv
					visible={isAddressNotServiceAble}
					onOk={this.handleCloseModal}
					closable={false}
					maskClosable={false}
					footer={false}
					destroyOnClose={true}
				>
					<AddressNotFoundPopUp
						selectedAddress={selectedAddress.formatted}
						handleSubmitRequest={this.handleSubmitRequest}
						handleCloseModal={this.handleCloseModal}
					/>
				</ModalDiv>
				<ModalDiv
					visible={this.state.isZipInvalid}
					onOk={this.handleCloseZipModal}
					closable={true}
					maskClosable={false}
					footer={false}
					destroyOnClose={true}
				>
					<CheckZipCoveragePopUp
						selectedAddress={adressInputValue?.formatted_address}
						handleCloseModal={this.handleCloseZipModal}
					/>
				</ModalDiv>
				<ModalDiv
					visible={this.state.isCheckAddressCoverageable}
					onOk={this.handleCloseCheckAddressCoverageableModal}
					closable={false}
					maskClosable={false}
					footer={false}
					destroyOnClose={true}
				>
					<CheckAddressCoveragePopUp
						selectedAddress={selectedAddress.formatted}
						handleCloseModal={this.handleCloseCheckAddressCoverageableModal}
					/>
				</ModalDiv>
				<ModalDiv
					cancelButtonProps={{ style: { display: 'none' } }}
					title="Address Not Serviceable Confirmation"
					visible={isAddressConfirmationPopup}
					onCancel={() => this.setState({ isAddressConfirmationPopup: false })}
					maskClosable={false}
					className="text-modal"
					onOk={() => this.setState({ isAddressConfirmationPopup: false })}
					okText="OK"
				>
					<p className="static-text">
						<span>Thank you for informing us that the address that you tried to create a listing for on Unreal Estate
							is not currently serviceable by our system. We have recorded the address and one of our Customer
							Care Specialists will be in touch shortly to assist you further.</span>
						If you have any additional question or require immediate support,
						please <a href={Routes.CONTACT} onClick={() => this.setState({ isAddressConfirmationPopup: false })}>contact us</a> at your convenience.
					</p>
				</ModalDiv>
				<Footer />
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	const userDetails = getLocalUserData();
	const { defaultBillingAddress, defaultShippingAddress, isFetching, errors } = state.user;
	const addressFromLocal = onboardingService.getLocal("selectedAddress");
	const conciergePlans = {
		plans: [
			state.onboarding?.productsInfo?.find(item => item.priceUpFront === 0) || {},
			state.onboarding?.productsInfo?.find(item => item.priceUpFront > 0) || {}
		],
	}

	return {
		selectedAddress: addressFromLocal && state.onboarding.selectedAddress || {},
		planData: conciergePlans,
		selectedPlan: conciergePlans.plans[getCurrentUserData().role === roleType.ConciergeFree ? 0 : 1],
		userDetails: userDetails?.isAdminSwitchUser ? userDetails.switchedUser : userDetails || {},
		userErrors: errors || {},
		isFetching: state.onboarding.isFetching,
		redirect: state.onboarding.redirect,
		isAddressNotServiceAble: state.onboarding.isAddressNotServiceAble,
		billingAddress: defaultBillingAddress || {},
		shippingAddress: defaultShippingAddress || {},
		voucherCodeDetails: state.onboarding.voucherCodeData || {},
		isUserFetching: isFetching,
		isLoggedIn: !!userDetails?.userId,
		isCheckAddressCoverageable: state.onboarding.isCheckAddressCoverageable,
		isBillingAddressExist: defaultBillingAddress && Object.keys(defaultBillingAddress).length > 1,
		addressFromLocal: addressFromLocal,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ ...onboardingActionCreators, ...userActionCreators, ...UIActionsCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(UnbounceListing));
