import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import styled from 'styled-components';
import { onboardingActionCreators } from '../../../Actions/OnboardingActions';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { userActionCreators } from '../../../Actions/UserActions';
import { UIActionsCreators } from '../../../Actions/UIActions';
import BillingInfo from '../Components/BillingInfo';
import { Container, ModalDiv } from '../../../Assets/Style/commonStyleComponents';
import { getUserRole } from '../../../Utilities/commonFunctions';
import { dashboardTabs, roleType } from '../../../Constants/CommonConstants';
import { history } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import { VoucherListingContainer } from '../../WholesalePortal/Containers/VoucherListingContainer';
import { userService } from '../../../Services/UserService';
import Navbar from '../../Shared/Components/Navbar';
import Footer from '../../Shared/Components/Footer';
import { ListingsList } from '../Components/ListingsList';
import VouchersList from '../../Admin/V2/Components/Vouchers/VouchersList';
import { CodeLockIcon } from '../../Shared/Components/Icons/CodeLock';

const ContentWrapper = styled.div`
  padding: 30px 0 25px 0;
  .nav-tabs {
    margin-bottom: 30px;
    position: relative;
    .nav-item {
      font-size: 15px;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      color: #242b26;
      margin-bottom: 0;
      padding: 15px 20px;
      position: relative;
      border: none;
      @media (max-width: 450px) {
        font-size: 14px;
      }
      @media (max-width: 400px) {
        font-size: 13px;
        padding: 15px 8px;
      }
      &.active {
        border: none;
        color: #242b26;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          background-color: #b698d9;
          height: 3px;
          width: 100%;
          border-radius: 4px;
        }
      }
    }
    .mortgage-ad {
      background: #e7fc79;
      border-radius: 30px;
      padding-top: 9px;
      position: relative;
      bottom: -7px;
      height: 34px;
    }

    .nav-link.disabled {
      color: #6c757d !important;
    }
  }
  .tab-content {
    & > .active {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
  }
`;

const Title = styled.h2`
  width: 100%;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.darkGreen[900]};
`;

const TabWithIcon = styled.div`
svg {
  position: relative;
  top: 2px;
  left: 4px;
}
`;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.userRole = getUserRole();
    this.state = {
      activeKey: this.getActiveTab(),
      showDeleteDraftListingModal: false,
      draftListingToDeleteId: null,
    };
    this.swithLocation = userService.getSwitchlocation();

    if (history.location.state) {
      userService.setSwitchlocation(history.location.state.swithcLocation);
    }
  }
  componentDidMount() {
    if (this.props?.userDetails?.token) {
      if (
        [
          roleType.RETAIL,
          roleType.NetworkUser,
          roleType.INSTITUTIONAL,
          roleType.WHOLESALE_CUSTOMER,
          roleType.ConciergeFree,
          roleType.ConciergePaid,
        ].includes(this.userRole)
      ) {
        this.props.getListings();
        this.props.getDefaultBillingAddress();
      }
    }

    this.props.updateListingType(this.state.activeKey);
  }

  handleSelect = (key) => {
    if (key === dashboardTabs.addVoucher) {
      history.push(`${Routes.ADD_EDIT_VOUCHER.replace(':voucherId', 0)}`);
    } else if (key === dashboardTabs.addListVoucher) {
      history.push(`${Routes.ONBOARDING}/voucher`);
    } else if (key === dashboardTabs.myFavorites) {
      window.location.href = Routes.MY_FAVORITES;
    } else if (key === dashboardTabs.savedSearches) {
      window.location.href = Routes.SAVED_SEARCHES;
    } else if (key === dashboardTabs.mortgageAdLink) {
      window.open(Routes.MORTGAGE_URL, '_blank');
    } else if (key === dashboardTabs.intelligenceReports) {
      this.props.openIntelligenceModal();
    } else {
      this.props.history.push(`${this.props.history.location.pathname}#${key}`);
    }
    if (key !== dashboardTabs.mortgageAdLink && key !== dashboardTabs.intelligenceReports) {
      this.setState({ activeKey: key });
      this.props.updateListingType(key);
    }
  };

  getActiveTab() {
    const {
      location: { hash },
    } = this.props;
    let activeKey;
    if (
      [roleType.RETAIL, roleType.NetworkUser, roleType.INSTITUTIONAL, roleType.WHOLESALE_CUSTOMER].includes(
        this.userRole
      )
    ) {
      activeKey = hash && hash === `#${dashboardTabs.billing}` ? dashboardTabs.billing : dashboardTabs.listing;
    } else if (this.userRole === roleType.WHOLESALE) {
      activeKey =
        hash && hash === `#${dashboardTabs.voucher}` ? dashboardTabs.dashboardCoverage : dashboardTabs.voucher;
    }
    return activeKey;
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <LoadingOverlay active={this.props.isFetching} spinner text="">
          <ContentWrapper>
            <Container>
              {this.props.userDetails?.firstName && <Title>Welcome, {this.props.userDetails?.firstName}!</Title>}
              <Tabs
                defaultActiveKey={this.getActiveTab()}
                transition={false}
                activeKey={this.state.activeKey}
                id="dasboard"
                unmountOnExit={true}
                onSelect={this.handleSelect}
              >
                {[
                  roleType.RETAIL,
                  roleType.NetworkUser,
                  roleType.INSTITUTIONAL,
                  roleType.WHOLESALE_CUSTOMER,
                  roleType.ConciergeFree,
                  roleType.ConciergePaid,
                ].includes(this.userRole) && (
                  <Tab eventKey={dashboardTabs.listing} title="My Listings">
                    <ListingsList />
                  </Tab>
                )}
                <Tab title="Saved Searches" eventKey={dashboardTabs.savedSearches} />
                <Tab title="My Favorites" eventKey={dashboardTabs.myFavorites} />
                <Tab disabled title="Calendar" eventKey={dashboardTabs.calendar} />
                <Tab disabled title="Documents" eventKey={dashboardTabs.documents} />

                {[
                  roleType.RETAIL,
                  roleType.NetworkUser,
                  roleType.INSTITUTIONAL,
                  roleType.WHOLESALE_CUSTOMER,
                  roleType.ConciergeFree,
                  roleType.ConciergePaid,
                ].includes(this.userRole) && (
                  <Tab eventKey={dashboardTabs.billing} title="Billing Information">
                    <BillingInfo updateBillingAddress={this.props.updateBillingAddress} />
                  </Tab>
                )}
                <Tab
                  eventKey={dashboardTabs.intelligenceReports}
                  title={
                    <TabWithIcon>
                      <span>Intelligence Reports</span>
                      <CodeLockIcon />
                    </TabWithIcon>
                  }
                />
                {this.userRole === roleType.WHOLESALE && (
                  <Tab eventKey={dashboardTabs.voucher} title="My Voucher">
                    {this.props.activeEntity === dashboardTabs.voucher && <VouchersList {...this.props} />}
                  </Tab>
                )}
                {this.userRole === roleType.WHOLESALE && (
                  <Tab eventKey={dashboardTabs.dashboardCoverage} title="Coverage">
                    {this.props.activeEntity === dashboardTabs.dashboardCoverage && (
                      <VoucherListingContainer {...this.props} />
                    )}
                  </Tab>
                )}
                {this.userRole === roleType.WHOLESALE && (
                  <Tab eventKey={dashboardTabs.networkUser} title="Network User">
                    {this.props.activeEntity === dashboardTabs.networkUser && (
                      <VoucherListingContainer {...this.props} />
                    )}
                  </Tab>
                )}
                {this.userRole === roleType.WHOLESALE_CUSTOMER && (
                  <Tab title="+ Add List" eventKey={dashboardTabs.addListVoucher}></Tab>
                )}
                {/* uncomment next line for "UD-2021Zero-Mortgage: User Dashboard Ads" */}
                {/* <Tab
                  tabClassName="mortgage-ad"
                  title="Get Pre-Approved"
                  tabAttrs
                  eventKey={dashboardTabs.mortgageAdLink}
                /> */}
                {this.userRole === roleType.WHOLESALE && (
                  <Tab title="+ Add Voucher" eventKey={dashboardTabs.addVoucher}></Tab>
                )}
              </Tabs>
            </Container>
          </ContentWrapper>
          <Footer />
          <ModalDiv
            title="Delete Draft Listing Confirmation"
            visible={this.state.showDeleteDraftListingModal}
            onCancel={() => this.setState({ showDeleteDraftListingModal: false, draftListingToDeleteId: null })}
            onOk={() => {
              this.props.deleteDraftListings(this.state.draftListingToDeleteId);
              this.setState({ showDeleteDraftListingModal: false, draftListingToDeleteId: null });
            }}
            okText="Yes"
            cancelText="No"
            closable={true}
            destroyOnClose={true}
            className="confirm-modal"
          >
            <p className="static-text">Are you sure you want to DELETE draft listing, it cannot be undone?</p>
          </ModalDiv>
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails,
    listings: state.dashboard?.listings || [],
    isFetching: state.dashboard.isFetching,
    billingAddress: state.user.defaultBillingAddress,
    activeEntity: state.dashboard.activeEntity,
    isListingFetching: state.dashboard.isListingFetching,
    draftListingDeletingId: state.dashboard.draftListingDeletingId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...onboardingActionCreators,
      ...dashboardActionCreators,
      ...userActionCreators,
      ...UIActionsCreators,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
