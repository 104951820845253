import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { withTheme } from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Map, Marker } from 'google-maps-react';
import { map } from 'lodash';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { userActionCreators } from '../../../Actions/UserActions';
import ViewListingDetail from '../Components/ViewListingDetail';
import { PublicListingWrapper, Container, MapWrapper } from '../../../Assets/Style/commonStyleComponents';
import { goBack } from '../../../Utilities/commonFunctions';
import DashboardService from '../../../Services/DashboardService';
import Navbar from '../../Shared/Components/Navbar';
import Footer from '../../Shared/Components/Footer';

const dashboardService = new DashboardService();
export class ViewListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: '',
      logitude: '',
      updateLat: true,
    };
  }

  componentDidMount() {
    this.props.getListing(this.props.match.params.listingId);
  }

  componentWillReceiveProps(nextProps) {
    const listingData = nextProps.listing;
    if (this.state.updateLat && listingData?.address1 && !listingData.latitude) {
      this.getGeoCode(listingData);
    } else if (listingData?.address1) {
      this.setState({ updateLat: false });
      this.setState({ latitude: listingData.latitude });
      this.setState({ logitude: listingData.longitude });
    }
  }
  getGeoCode = async (reqData) => {
    const fullAddress =
      reqData.address1 + ' ' + reqData.address2 + ',' + reqData.city + ',' + reqData.state + ',' + reqData.zip;
    const { data } = await dashboardService.getLatLong(fullAddress);
    const dataJson = data;
    const jsonResonse = dataJson.value;
    if (!jsonResonse.results || jsonResonse.results.length === 0) {
      return;
    }
    const { lat, lng } = jsonResonse.results[0].geometry.location;
    this.setState({ latitude: lat });
    this.setState({ logitude: lng });
    this.setState({ updateLat: false });
    const payload = { listingId: reqData.listingId, latitude: lat, longitude: lng };
    dashboardService.saveLatLong(payload);
  };

  renderPhotos = () => {
    return map(
      this.props?.listing?.photos,
      (item) =>
        item && (
          <div key={Math.random()}>
            <img src={item.absoluteUrl} />
          </div>
        )
    );
  };

  DownloadZip = async () => {
    const { listing } = this.props;
    let listingId = listing?.listingId;
    let reqObj = { listingId: listingId };
    this.props.DownloadZipByAPI(reqObj);
  };

  render() {
    const { userDetails, isFetching, listing } = this.props;
    let mapClass = '';
    if (this.props.listing?.photos && this.props.listing.photos.length > 0) {
      mapClass = 'with-map';
    }

    return (
      <React.Fragment>
        <Navbar />
        <LoadingOverlay active={isFetching} spinner text="">
          <PublicListingWrapper className="view-lising-gap">
            <Container>
              <a className="back-btn" href="javascript:void(0)" onClick={() => goBack()}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
              </a>
              <div className={'multi-slider-row ' + mapClass}>
                <Carousel dynamicHeight={false} showStatus={false} className="custom-multi-slider" thumbWidth={100}>
                  {this.renderPhotos()}
                </Carousel>
                {!isFetching && !this.state.updateLat && (
                  <MapWrapper className="for-bottom-gap real-map-wrapper">
                    <Map
                      google={window.google}
                      style={{ width: '100%', height: '100%', position: 'relative' }}
                      className="map"
                      zoom={15}
                      initialCenter={{
                        lat: this.state.latitude,
                        lng: this.state.logitude,
                      }}
                    >
                      <Marker
                        title={'The marker`s title will appear as a tooltip.'}
                        position={{ lat: this.state.latitude, lng: this.state.logitude }}
                      />
                    </Map>
                  </MapWrapper>
                )}
              </div>
              {listing?.photos && listing.photos.length > 0 && (
                <a onClick={this.DownloadZip} className="ant-btn btn btn-primary admin-primary-btn mt-0 download-zip">
                  Download photos
                </a>
              )}
              {listing && (
                <ViewListingDetail
                  isFetching={isFetching}
                  listing={listing}
                  formsData={listing.forms}
                  completedFormsData={listing.completedForms}
                  listingId={this.props.match.params.listingId}
                />
              )}
            </Container>
          </PublicListingWrapper>
          <Footer />
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails,
    listing: state.dashboard.listing,
    s3Identity: state.user.s3Identity,
    appSettings: state.user.appSettings,
    isFetching: state.dashboard.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...dashboardActionCreators, ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ViewListing));
