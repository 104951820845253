import React from "react";
import logo from "../../../Assets/Svg/usr-logo.svg";
import "../../../Assets/Style/home-listing.css";

import {
    SignUpHomeListingContent,
    SignUpHomeListingHeading,
    SignUpHomeListingSmallHeading,
    SignUpHomeListingUl,
    SignUpHomeListingLi,
    LogoWrap,
    DesktopView,
} from "../../../Assets/Style/commonStyleComponents";

const LandingRightSide = (props) => {
    return (
      <>
      <SignUpHomeListingContent>
        <DesktopView>
            <LogoWrap>
            <img src={logo} alt="USRealty.com" />
            </LogoWrap>
            <SignUpHomeListingHeading>
            Sign up to create your home’s listing
            </SignUpHomeListingHeading>
        </DesktopView>
        <SignUpHomeListingSmallHeading>
            Get sold with key marketing:
        </SignUpHomeListingSmallHeading>
        <SignUpHomeListingUl>
            <SignUpHomeListingLi>
            Get what matters to you: (Dream Vacation – Home Repairs – New Car for your New Home) with an average $11,144 per seller’s savings!
            </SignUpHomeListingLi>
            <SignUpHomeListingLi>
            List it and forget it with 12 month listing on your local MLS and syndication on up to 720 national and regional websites
            </SignUpHomeListingLi>
            <SignUpHomeListingLi>
            Help within reach with email, chat, and phone support to make sure your listing is optimized and ready to stand out Keep marketing fresh with unlimited listing edits
            </SignUpHomeListingLi>
            <SignUpHomeListingLi>
            Excite buyers to schedule showings with the maximum number of photos that your MLS allows
            </SignUpHomeListingLi>
            <SignUpHomeListingLi>
            Be seen with traditional "For Sale" yard sign
            </SignUpHomeListingLi>
            <SignUpHomeListingLi>Sales agreement forms</SignUpHomeListingLi>
            <SignUpHomeListingLi>
            Get your buyer with sales agreement forms
            </SignUpHomeListingLi>
            <SignUpHomeListingLi>
            Control your expenses by your setting the buying agent’s commission and paying only when your home sells
            </SignUpHomeListingLi>
        </SignUpHomeListingUl>
        </SignUpHomeListingContent>
     </>
    );
  }
export default LandingRightSide;
