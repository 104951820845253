import { toast } from 'react-toastify';
import { getRandomString } from '../Utilities/commonFunctions';
import { actionTypes } from '../Reducers/ToastReducers';
import enums from "../Constants/Enums";

export const alertActions = {
  successAlert,
  errorAlert,
  clear,
};

function setDismiss(id) {
  return () => {
    setTimeout(() => {
      toast.dismiss(id);
    }, enums.NUMERIC_VALUE.FIVE_THOUSAND);
  };
}

function successAlert(message) {
  const id = getRandomString(enums.NUMERIC_VALUE.TEN);
  return (dispatch) => {
    dispatch({
        type: actionTypes.SUCCESS
    });
    toast.success(message);
    dispatch(setDismiss(id));
  };
}

function errorAlert(errorMsg) {
  let updatErrArr = [];
  if (typeof errorMsg === 'string') {
    updatErrArr = errorMsg.split('error:');
  } else if (errorMsg && errorMsg.message) {
    updatErrArr.push(errorMsg.message);
  }
  const id = getRandomString(enums.NUMERIC_VALUE.TEN);
  return (dispatch) => {
    dispatch({
        type: actionTypes.ERROR
    });
    toast.error(updatErrArr[1] || updatErrArr[0]);
    dispatch(setDismiss(id));
  };
}

function clear() {
  return () => {
    toast.dismiss(); // Hook Because React Toastify is not working
  };
}
