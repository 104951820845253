import React, { useEffect, useState } from 'react';
// import ReactSimpleRange from 'react-simple-range';
import { Tooltip } from 'antd';
import MaskedInput, { conformToMask } from 'react-text-mask';
import styled from 'styled-components';
import v8n from 'v8n';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

import {
  selectedAddressSelector,
  estimatePriceSelector,
  averagePriceSelector,
  totalSavingSelector,
  listingStepSelector,
  propertyPriceSelector,
  agentComissionSelector,
  draftListingCreatingSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import ListingPreviewMobileContainer from '../Components/ListingPreviewMobileContainer';
import { HouseIcon } from '../../Shared/Components/Icons/HouseIcon';
import { InfoButton } from '../../Shared/Components/Icons/InfoButton';
// import { SliderPinIcon } from '../../Shared/Components/Icons/SliderPinIcon';
// import { SliderTipIcon } from '../../Shared/Components/Icons/SliderTipIcon';
// import { themes } from '../../../Constants/Themes';
import createNumberMask from '../../../Utilities/CreateNumberMask';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { gTag } from '../../../Utilities/utils';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import { LYFSteps, lYHVideoUrls } from '../../../Constants/CommonConstants';
import CashOfferPanel from '../Components/CashOfferPanel';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 10rem;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
`;

const BackButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const PriceStatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 2rem;
`;

const SalesRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const SalesRangeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.25rem 1.5rem 0.25rem 0.5rem;
`;

const SalesRangeText = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
`;

const SalesRangePrice = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.lavender};
`;

const EstimatePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 2.25rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius: 40px;
  border-width: 1.5px;
  border-style: dashed;
  border-color: #242b26;
  color: ${({ theme }) => theme.colors.green[900]};
  margin: 0.25rem 0;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 1rem;
  }
`;

const EstimatePriceText = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-right: 0.5rem;
`;

const EstimatePriceValue = styled(EstimatePriceText)`
  font-weight: 900;
`;

const PropertyPriceInputContainer = styled.div`
  padding-top: 3.25rem;
  padding-bottom: 1rem;
  border-bottom-width: 1.5px;
  border-bottom-style: dashed;
  border-bottom-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#D3D5D4')};

  label {
    font-family: 'National-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#5B605C')};
  }

  input {
    padding-top: 0.5rem;
    font-family: 'National-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #1e2420;
  }

  input::placeholder {
    color: #919592;
  }
`;

const AgentCommissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3.25rem;
  padding-bottom: 1rem;
  border-bottom-width: 1.5px;
  border-bottom-style: dashed;
  border-bottom-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#D3D5D4')};

  label {
    font-family: 'National-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#5B605C')};
  }

  input {
    max-width: 4rem;
    font-family: 'National-Regular';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #1e2420;
  }

  input::placeholder {
    color: #919592;
  }
`;

const AgentCommissionFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PriceText = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
`;

const TotoalSavingValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalSavingText = styled(PriceText)`
  padding-bottom: 0;
  align-self: center;
`;

const AgentCommissionValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const InfoButonContainer = styled.div`
  margin-left: 0.5rem;
`;

const SliderPopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -33px;
  align-items: center;
`;

const SliderTipContainer = styled.div`
  top: 5px;
  position: relative;
`;

const SliderContainer = styled.div`
  width: 35%;
`;

const ComissionCurrency = styled.div`
  color: #919592;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

const TotoalSavingValueText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  margin-left: 0.5rem;
`;

const AveragePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  justify-content: center;
`;

const TotalSavingContainer = styled(AveragePriceContainer)`
  background: ${({ theme }) => theme.lightGreen};
  justify-content: space-between;
  padding: 1.5rem;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const Divider = styled.div`
  width: 100%
  height: 0;
  border-bottom: 1px solid #F4F4F4;
  margin-top: 3rem;
  margin-bottom: 2rem;

  @media(max-width: ${({ theme }) => theme.breakpoints.md}){
    display: none;
  }
`;

const RecommendedCommissionText = styled.div`
  align-self: flex-end;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #919592;
  margin-top: 0.75rem;
`;

const RecommendedCommissionFootnoteTextDesktop = styled.span`
  display: inline;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #919592;
  padding-bottom: 1rem;

  b,
  a {
    font-weight: 600;
    font-family: 'National-Regular';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: #919592;
  }
  a {
    text-decoration: underline;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const RecommendedCommissionFootnoteTextMobile = styled(RecommendedCommissionFootnoteTextDesktop)`
  margin-top: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: inline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const WidgetContainer = styled.div`
  display: flex;
  background: #f4f4f4;
  border-radius: 10px;
  padding: 1.4rem 1.4rem 1.7rem 1.4rem;
  margin-bottom: 1rem;
  max-width: 22rem;
  justify-content: center;
  align-self: center;
  margin-top: 1rem;

  &:empty {
    display: none;
  }

  .rpr-avm-widget {
    border-radius: 10px !important;
  }
`;

const VideoIframe = styled.iframe`
  display: flex;
  border-radius: 10px;
  align-self: center;
  margin-top: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const PriceAndComissions = () => {
  const step = useSelector(listingStepSelector);
  const selectedAddress = useSelector(selectedAddressSelector);
  const draftListingCreating = useSelector(draftListingCreatingSelector);
  const [showListingPreview, setShowListingPreview] = useState(false);
  const averagePrice = useSelector(averagePriceSelector);
  const estimatePrice = useSelector(estimatePriceSelector);
  const totalSaving = useSelector(totalSavingSelector);
  const propertyPrice = useSelector(propertyPriceSelector);
  const agentCommission = useSelector(agentComissionSelector);
  const userDetails = useSelector(userDetailsSelector);
  const dispatch = useDispatch();
  const isMobileScreenWidth = window.screen.width <= 768;
  const priceMask = createNumberMask({
    prefix: '$',
    integerLimit: 12,
    thousandsSeparatorSymbol: ',',
    suffix: '.00',
  });

  const [price, setPrice] = useState({
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: 'Please enter property price',
      },
    ],
    error: '',
  });

  const [commission, setCommission] = useState({
    rules: [
      {
        rule: v8n().not.empty().not.null(),
        message: 'Please select buyer agent commission',
      },
    ],
    error: '',
  });

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const draftListingId = params.get('listing_id');

  useEffect(() => {
    if (!selectedAddress?.formatted) {
      return;
    }
    window.rprAvmWidgetOptions.Query = selectedAddress?.formatted;

    const script = document.createElement('script');
    script.src = '//www.narrpr.com/widgets/avm-widget/widget.ashx/script';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [selectedAddress]);

  useEffect(() => {
    if (!draftListingId && userDetails?.token && !draftListingCreating) {
      dispatch(onboardingActionCreatorsV2.createDraftListingV2());
    }
  }, [draftListingCreating, draftListingId]);

  useEffect(() => {
    dispatch(onboardingActionCreatorsV2.getEstimatePriceV2());
  }, []);

  useEffect(() => {
    if (!propertyPrice || !agentCommission) {
      return;
    }
    dispatch(onboardingActionCreatorsV2.setTotalSaving());
  }, [propertyPrice, agentCommission]);

  const validatePrice = () => {
    let priceObj = { ...price };
    let isPriceError = false;

    if (!priceObj.rules[0].rule.test(propertyPrice)) {
      priceObj.error = priceObj.rules[0].message;
      isPriceError = true;
    }

    if (!isPriceError) {
      priceObj.error = null;
    }

    setPrice((pr) => ({
      ...pr,
      ...priceObj,
    }));
    return isPriceError;
  };

  const validateComission = () => {
    let commissionObj = { ...commission };
    let isComissionError = false;

    if (!commissionObj.rules[0].rule.test(agentCommission)) {
      commissionObj.error = commissionObj.rules[0].message;
      isComissionError = true;
    }

    if (!isComissionError) {
      commissionObj.error = null;
    }

    setCommission((pr) => ({
      ...pr,
      ...commissionObj,
    }));

    return isComissionError;
  };

  const handleContinue = () => {
    let isPriceError = validatePrice();
    let isComissionError = validateComission();
    const isErrorExist = isPriceError || isComissionError;

    if (!isErrorExist) {
      gTag({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
            },
            products: [
              {
                price: propertyPrice,
              },
            ],
          },
        },
      });
      dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_BASICS));
    }
  };

  const changePrice = (e) => {
    e.persist();

    const parcedPrice = e.target.value.replaceAll(',', '').replace('$', '').replace('.00', '');
    dispatch(onboardingActionCreatorsV2.setPropertyPriceV2(parcedPrice));
  };

  // const getPercent = () => {
  //   if (!propertyPrice) {
  //     return '$0';
  //   }
  //   const numPrice = Number(propertyPrice);
  //   const stringPrice = ((numPrice / 1000) * agentCommission).toFixed();
  //   return conformToMask(stringPrice, priceMask)?.conformedValue || '$0';
  // };

  // const setPropertyCommission = (data) => {
  //   dispatch(onboardingActionCreatorsV2.setAgentCommisionV2(data.value));
  // };

  // const TipFormatter = () => (
  //   <SliderPopUpContainer>
  //     <SliderTipContainer>
  //       <SliderPinIcon />
  //     </SliderTipContainer>
  //     <SliderTipIcon />
  //   </SliderPopUpContainer>
  // );

  return (
    <Container>
      {showListingPreview ? (
        <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
      ) : (
        <>
          <FormContainer>
            <TitleContainer>
              <SubTilteText>List your property</SubTilteText>
              <StepsText>2/9</StepsText>
            </TitleContainer>
            <TitleText>Set your list price.</TitleText>
            <Text>
              We use a proprietary algorithm to determine the valuation of your property. Remember it’s just an
              estimate.
            </Text>

            {/* <VideoIframe
              width="296"
              height="536"
              src={lYHVideoUrls[step]}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></VideoIframe> */}
            <PriceStatisticsContainer>
              <>
                {(averagePrice?.from || 0) !== 0 && (
                  <SalesRangeContainer>
                    <HouseIcon />
                    <SalesRangeTextContainer>
                      <SalesRangeText>
                        Estimate sales range
                        <Tooltip title="We use a proprietary algorithm to determine the range & estimated valuation of the subject property.">
                          <InfoButonContainer>
                            <InfoButton />
                          </InfoButonContainer>
                        </Tooltip>
                      </SalesRangeText>
                      <SalesRangePrice>
                        <CurrencyFormat
                          prefix="$"
                          value={averagePrice.from}
                          thousandSeparator=","
                          decimalSeparator="."
                          displayType="text"
                          suffix=".00"
                        />
                        -
                        <CurrencyFormat
                          prefix="$"
                          value={averagePrice.to}
                          thousandSeparator=","
                          decimalSeparator="."
                          displayType="text"
                          suffix=".00"
                        />
                      </SalesRangePrice>
                    </SalesRangeTextContainer>
                  </SalesRangeContainer>
                )}
                {!!estimatePrice && (
                  <EstimatePriceContainer>
                    <EstimatePriceValue>
                      <CurrencyFormat
                        prefix="$"
                        value={estimatePrice}
                        thousandSeparator=","
                        decimalSeparator="."
                        displayType="text"
                        suffix=".00"
                      />
                    </EstimatePriceValue>
                    <EstimatePriceText>Estimate price</EstimatePriceText>
                  </EstimatePriceContainer>
                )}
              </>
            </PriceStatisticsContainer>
            {isMobileScreenWidth && <WidgetContainer id="rprAvmWidget" />}
            <PropertyPriceInputContainer error={price.error}>
              <label htmlFor="priceInput">Property price</label>
              <MaskedInput
                mask={priceMask}
                type="text"
                name="priceInput"
                id="priceInput"
                onChange={changePrice}
                onBlur={() => {
                  validatePrice();
                  dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
                }}
                defaultValue={propertyPrice}
                placeholder="$0.00"
                autoComplete="off"
                guide={false}
              />
            </PropertyPriceInputContainer>
            {price.error && <ErrorMessage>{price.error}</ErrorMessage>}
            {/* Hidden for undefined time | UD-2551 */}
            {/* <AgentCommissionContainer error={commission.error}>
              <label htmlFor="agentCommission">Buyer agent commission</label>
              <AgentCommissionFormContainer>
                <AgentCommissionValueContainer>
                  <input
                    name="agentCommission"
                    id="agentCommission"
                    type="text"
                    value={`${agentCommission / 10 || 0}%`}
                    readOnly
                    placeholder="0%"
                  />
                  {!!propertyPrice && agentCommission > 0 && <ComissionCurrency>{getPercent()}</ComissionCurrency>}
                </AgentCommissionValueContainer>
                <SliderContainer>
                  <ReactSimpleRange
                    min={10}
                    max={40}
                    sliderSize={2}
                    step={1}
                    value={agentCommission}
                    defaultValue={30}
                    onChangeComplete={() => {
                      validateComission();
                      dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
                    }}
                    onChange={setPropertyCommission}
                    trackColor={themes.usRealty.lavender}
                    sliderColor="#D3D5D4"
                    customThumb={<TipFormatter />}
                  />
                </SliderContainer>
              </AgentCommissionFormContainer>
            </AgentCommissionContainer>
            {commission.error && <ErrorMessage>{commission.error}</ErrorMessage>}
            <RecommendedCommissionText>* Recommended commision</RecommendedCommissionText> */}
            {totalSaving > 0 && (
              <TotalSavingContainer>
                <TotalSavingText>Your potential savings by listing with Unreal Estate</TotalSavingText>
                <TotoalSavingValueContainer>
                  <TotoalSavingValueText>
                    <CurrencyFormat
                      prefix="$"
                      value={totalSaving}
                      thousandSeparator=","
                      decimalSeparator="."
                      displayType="text"
                      suffix=".00"
                    />
                  </TotoalSavingValueText>
                </TotoalSavingValueContainer>
              </TotalSavingContainer>
            )}
            <RecommendedCommissionFootnoteTextMobile>
              There is a small compliance fee of 0.5% of your property’s gross purchase price that will apply only when
              your property sells which covers our cost of ensuring compliance with local/state laws and MLS rules.
            </RecommendedCommissionFootnoteTextMobile>

            <CashOfferPanel
              listindAddressDetails={{
                ...(selectedAddress || {}),
              }}
              draftListingId={draftListingId}
              price={propertyPrice}
            />

            <ButtonsContainer>
              <MobilePreviewButton
                onClick={() => {
                  setShowListingPreview(true);
                }}
              >
                <EyeButton />
              </MobilePreviewButton>
              <BackButton
                type="button"
                onClick={() => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.LISTING_ADDRESS))}
              >
                Back
              </BackButton>
              <SubmitButton type="button" onClick={handleContinue}>
                Continue
              </SubmitButton>
            </ButtonsContainer>
            <Divider />
            <RecommendedCommissionFootnoteTextDesktop>
              There is a small compliance fee of 0.5% of your property’s gross purchase price that will apply only when
              your property sells which covers our cost of ensuring compliance with local/state laws and MLS rules.
            </RecommendedCommissionFootnoteTextDesktop>
          </FormContainer>
          <ListingPreviewDesktopContainer hideVideoHints />
        </>
      )}
    </Container>
  );
};

export default PriceAndComissions;
