import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Col, Row, Table } from 'antd';
import { useEffect } from 'react';
import { dateFormat, entityTypes, pageSizeOptions, staticMessages, tableItemsPerPage } from '../../../../../Constants/CommonConstants';
import SearchPanel from '../Shared/SearchPanel.js';
import { copyVoucherCode, editPageAction, momentDateFormat, voucherResend } from '../../../../../Utilities/commonFunctions.js';
import { vouchersCountSelector, vouchersFiltersSelector, vouchersSelector } from '../../../../../Reducers/V2/VouchersReducer.js';
import { vouchersCreators } from '../../../../../Actions/V2/Admin/VouchersActions.js';
import VouchersFilters from './VouchersFilters';
import { currentUserSelector } from '../../../../../Reducers/UserReducer';

export const tableConfigs = {
    columns: [
      {
        title: 'Voucher Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (name, record) => (
          <Link
            to={editPageAction(record, entityTypes.addVoucher)}
            >
            {name}
          </Link>
        ),
      },
      {
        title: 'Voucher Code',
        dataIndex: 'voucherURL',
        key: 'voucherURL',
        sorter: false,
        render: (voucherURL) => (
          <span className="v-code">
            {voucherURL}
            <a href="javascript:void(0)" onClick={() => copyVoucherCode(voucherURL)} className="voucher-code-copy-icon" title="Copy to clipboard">
              <i className="fa fa-clone" aria-hidden="true"></i>
            </a>
          </span>
        ),
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: true,
      },
      {
        title: 'Voucher Template',
        dataIndex: 'voucherTemplate',
        key: 'template',
        sorter: true,
      },
      {
        title: 'Redeemed By',
        dataIndex: 'redeemedBy',
        key: 'redeemedBy',
        sorter: true,
        render: redeemedBy => (
          <span>{redeemedBy || 'Not Redeemed'}</span>
        )
      },
      {
        title: 'Redeemed Date',
        dataIndex: 'voucherRedemptionDate',
        key: 'redeemDate',
        sorter: true,
        render: voucherRedemptionDate => (
          <span>{momentDateFormat(voucherRedemptionDate, dateFormat.DATE_WITH_YEAR) || 'N/A'}</span>
        )
      },
      {
        title: 'Expiry Date',
        dataIndex: 'voucherExpiryDate',
        key: 'expiryDate',
        sorter: true,
        render: voucherExpiryDate => (
          <span>{momentDateFormat(voucherExpiryDate, dateFormat.DATE_WITH_YEAR)}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'isVoucherActive',
        key: 'isVoucherActive',
        sorter: false,
        render: isVoucherActive => (
          <React.Fragment>
            <span className={!isVoucherActive ? 'inactive-text' : 'active-text'}>
              {isVoucherActive ? 'Active' : 'Inactive'}
            </span>
          </React.Fragment>
        ),
      },
      {
        title: 'Listing Status',
        dataIndex: 'listingStatus',
        key: 'listingStatus',
        sorter: false,
      },
      {
        title: 'Actions',
        dataIndex: 'isVoucherActive',
        key: 'Actions',
        sorter: false,
        render: (isVoucherActive, record) => (
  
          <span className="v-code">
            <a href="javascript:void(0)" onClick={() => voucherResend(record, entityTypes.resendInvite,isVoucherActive)} className="voucher-code-copy-icon ui-fix" title="Copy to clipboard" disabled={!isVoucherActive ? 'disabled' : "" } >
            Resend Invite
            </a>
          </span>
        ),
      },
    ],
    filters: [],
    deleteMessage: '',
    deleteTitle: 'Voucher',
    addPageUrl: '',
    addButtonText: '',
    searchPlaceHolder: 'Search Voucher',
    activateMessage: `${staticMessages.activateConfirmation} voucher?`,
    deactivateMessage: `${staticMessages.deactivateConfirmation} voucher?`,
};

const VouchersList = () => {
    const dispatch = useDispatch();
    const vouchers = useSelector(vouchersSelector);
    const filters = useSelector(vouchersFiltersSelector);
    const totalCount = useSelector(vouchersCountSelector);
    const userData = useSelector(currentUserSelector);

    useEffect(() => {
        dispatch(vouchersCreators.getVouchers(userData.userId, filters));
    }, [
      filters.search,
      filters.page,
      filters.limit,
      filters.sortBy,
      filters.desc,
    ]);

    const onTableChange = (pagination, tablefilters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const sorterParams = {};

        if (field && order) {
          sorterParams.sortBy = field;
          sorterParams.desc = order === 'descend';
        }
        
        dispatch(vouchersCreators.setVouchersFilters({
          ...filters,
          ...sorterParams,
          page: current,
          limit: pageSize,
          
        }));
    }

    const showTotal = (total, range) => {
        return `Showing ${range[0]} - ${range[1]} of ${total}`;
    }
    const onSearch = (value) => dispatch(vouchersCreators.setVouchersFilters({...filters, search: value}));

    return (<>
        <div className={`customer-search-filter-wrapper`}>
            <div className="customer-voucher-search-wrapper">
                <SearchPanel
                    onSearch={onSearch}/>
            </div>
            <div className="customer-voucher-filter-wrapper">
                <VouchersFilters />
            </div>
        </div>
        <div className="customer-voucher-listing">
            <Card>
                <Row style={{ marginTop: 20 }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}
                        xxl={{ span: 24, offset: 0 }}>
                            <Table
                                size={'default'}
                                columns={tableConfigs.columns}
                                dataSource={vouchers}
                                onChange={onTableChange}
                                pagination={{
                                    total: totalCount,
                                    defaultPageSize: tableItemsPerPage,
                                    defaultCurrent: 1,
                                    showTotal: showTotal,
                                    pageSizeOptions: pageSizeOptions,
                                    showSizeChanger: totalCount > tableItemsPerPage
                                }} />
                    </Col>
                </Row>
            </Card>
        </div>
      </>
    );
}

export default (VouchersList);
