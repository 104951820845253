import React from "react";
import Table from "react-bootstrap/Table";
import {
  InputFlex,
  ModalDiv,
} from "../../../../Assets/Style/commonStyleComponents";
import { map } from "lodash";
import routes from "../../../../Constants/Routes";
class AddOverlayPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsPopUp: false,
      modalData: {},
      modalIndex: 0,
    };
  }
  openeModal = (item, index) => {
    this.setState({ modalData: item });
    this.setState({ modalIndex: index });
    this.setState({ IsPopUp: true });
  };

  DeleteImage = (item, index) => {
    const value = this.props.overlayFormFields[index];
    value.overlayImage = {
      overlayImageId: 0,
      bucket: "",
      path: "",
      priority: 0,
    };
    value.content = "";
    this.props.deleteFromoverlay(index);
    this.setState({ modalData: this.props.overlayFormFields });
  };

  onPopCancel = () => {
    this.setState({ IsPopUp: false });
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.modalData && nextProps.isFormFileUploading) {
      this.setState({
        modalData: nextProps["overlayFormFields"][this.state.modalIndex],
      });
    }
  }
  renderOverlayFormFields = () => {
    const {
      overlayFormFields,
      handleAddRow,
      handleRemoveRow,
      onChangeFile,
      onChangeModalFile,
      uploadFormFileProgress,
      isFormFileUploading,
      isOverlayEdit,
    } = this.props;
    return map(
      overlayFormFields,
      (item, index) =>
        item && (
          <React.Fragment key={index}>
            <tr>
              <td>{index + 1}</td>
              <td>
                <div className="overlay-pages-upload">
                  <div className="overlay-pages-upload-hover">
                    {item.overlayImage && item.content && (
                      <div className="basic-form-type">
                        <span
                          className="basic-form-add-field"
                          onClick={(e) => this.openeModal(item, index)}
                        >
                          <i className="fa fa-pen" aria-hidden="true"></i>
                        </span>
                        <span
                          className="basic-form-add-field"
                          onClick={(e) => this.DeleteImage(item, index)}
                        >
                          <i className="fa fa-trash-alt" aria-hidden="true"></i>
                        </span>
                      </div>
                    )}
                    {(uploadFormFileProgress === 1 ||
                      uploadFormFileProgress === 0) &&
                      !item.overlayImage?.thumbnailUrl &&
                      !item.content && (
                        <>
                          <span>No image selected</span>
                          <div className="admin-file-upload">
                            <input
                              type="file"
                              id="admin-file-upload"
                              accept="image/*"
                              capture="environment"
                              name="photoUpload"
                              onChange={(e) => onChangeFile(e, index)}
                            />
                            <label htmlFor="admin-file-upload">Add Image</label>
                          </div>
                        </>
                      )}
                    {uploadFormFileProgress < 1 &&
                      uploadFormFileProgress !== 0 &&
                      !item.overlayImage?.thumbnailUrl &&
                      !item.content && (
                        <span>{`Uploaded ${Math.round(
                          uploadFormFileProgress * 100
                        )}%`}</span>
                      )}
                    {(item.overlayImage?.thumbnailUrl || item?.content) && (
                      <img
                        src={item?.overlayImage?.thumbnailUrl || item?.content}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </td>
              <td>
                {!item?.formGroupSectionId && (
                  <div className="overlay-actions-text">
                    <p>You must update before the edit button is available.</p>
                    <p>
                      You must update before the preview button is available.
                    </p>
                  </div>
                )}
                {item?.formGroupSectionId && (
                  <div className="overlay-pages-actions-btns">
                    <a
                      href={routes.ADMIN_OVERLAY_FORM.replace(
                        ":sectionId",
                        item.formGroupSectionId
                      )}
                      target="_blank"
                    >
                      Edit Page
                    </a>
                    <a
                      href={routes.PREVIEW.replace(
                        ":sectionId",
                        item.formGroupSectionId
                      )}
                      target="_blank"
                    >
                      Preview Page
                    </a>
                  </div>
                )}
              </td>
              <td>
                <InputFlex type="text" className="inputgap" />
              </td>
              <td>
                {!isFormFileUploading && (
                  <div className="add-delete-row">
                    <span onClick={() => handleAddRow()} disabled>
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </span>
                    <span onClick={() => handleRemoveRow(index)} disabled>
                      <i className="fa fa-minus" aria-hidden="true"></i>
                    </span>
                  </div>
                )}
              </td>
            </tr>

            <ModalDiv
              visible={this.state.IsPopUp}
              onCancel={this.onPopCancel}
              okText="No"
              cancelText="No"
              closable={true}
              destroyOnClose={true}
              id={index}
              className="beyondCustomerSupport-modal overlay-edit-image-modal"
            >
              <>
                <div className="img-container">
                  {(this.state.modalData.overlayImage?.thumbnailUrl ||
                    this.state.modalData?.content) && (
                    <img
                      src={
                        this.state.modalData?.overlayImage?.thumbnailUrl ||
                        this.state.modalData?.content
                      }
                      alt=""
                    />
                  )}
                </div>

                <div className="admin-file-upload-container">
                  {uploadFormFileProgress < 1 &&
                    uploadFormFileProgress !== 0 && (
                      <span className="uploading-text">{`Uploading ....`}</span>
                    )}
                  <div className="admin-file-upload">
                    <input
                      type="file"
                      id="admin-file-upload-modal"
                      accept="image/*"
                      capture="environment"
                      name="photoUpload"
                      onChange={(e) =>
                        onChangeModalFile(e, this.state.modalIndex)
                      }
                    />
                    <label htmlFor="admin-file-upload-modal">Replace Image</label>
                  </div>
                </div>

                {uploadFormFileProgress === 0 && isOverlayEdit && (
                  <span className="uploading-text-made">{`Close this pop-up and update the form to reflect new image.`}</span>
                )}
              </>
            </ModalDiv>
          </React.Fragment>
        )
    );
  };
  render() {
    return (
      <div>
        <h3 className="admin-form-inner-heading">Overlay Pages</h3>
        <div className="basic-form-type page-image-small">
          <Table bordered>
            <thead>
              <tr>
                <th></th>
                <th className="overlay-page-images-heading">
                  Page Image <span>*</span>
                  <p>
                    MUST be at least 800 pixels wide. If you don't have
                    photoshop try <a href="#">pdf2jpg.net.</a>
                  </p>
                </th>
                <th>Actions </th>
                <th></th>
                <th>
                  {!this.props.isFormFileUploading && (
                    <span
                      className="basic-form-add-field"
                      onClick={this.props.handleAddRow}
                      disabled={true}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>{this.renderOverlayFormFields()}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}
export default AddOverlayPages;
