import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingActionCreators } from '../../../../Actions/OnboardingActions';
import { Container, StaticContentWrapper, Quote, MidBox, HeadTextWrap, Heading, HeadText, CustomButton, RowFlex, InputFlex, CustomTextarea } from '../../../../Assets/Style/commonStyleComponents';
import { validationMessages } from '../../../../Constants/CommonConstants';
import { intialContactUsEmptyState } from '../../../../Utilities/commonFunctions';
import Routes from '../../../../Constants/Routes';
import { ImagesUrl } from "../../../../Constants/Images";
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

class About extends React.Component {

  state = {
    contactUsForm: intialContactUsEmptyState()
  };

  submitFormData = (formData) => {
    this.props.contactUsUserRequest(formData, () => {
      this.setState({ 
        contactUsForm:  intialContactUsEmptyState()
      })
    });
  }

  handleFieldChanged = (e) => {
    let contactUsForm = { ...this.state.contactUsForm };
    contactUsForm[e.target.name].value = e.target.value;
    this.setState({ contactUsForm: { ...contactUsForm } });
  }

  handleFieldBlur = (e) => {
    this.validateField(e.target.name, e.target.value);
  }

  validateField = (key, value) => {
    let contactUsForm = { ...this.state.contactUsForm };
    let isError = false;
    for (let i = 0; i < contactUsForm[key].rules.length; i++) {
      let rule = contactUsForm[key].rules[i];
      if (!rule.rule.test(value)) {
        contactUsForm[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      contactUsForm[key].error = null;
    }

    this.setState({ contactUsForm: { ...contactUsForm }, isError: Object.keys(contactUsForm).some(k => contactUsForm[k].error !== undefined && contactUsForm[k].error !== null) });

    return isError;
  }

  validateAndSubmit = (e) => {
    e.preventDefault();

      let isError = false;

      Object.keys(this.state.contactUsForm).forEach((k) => {
        const isErrorExist = this.validateField(k, this.state.contactUsForm[k].value);
        if (isErrorExist) {
          isError = true;
        }
      })

      if (!isError) {
        this.submitFormData(
          { ...(Object.keys(this.state.contactUsForm).reduce((retVal, key) => {
            retVal[key] = this.state.contactUsForm[key].value;
            return retVal;
          }, {})) });
      }
  }
    render() {
        return (
          <React.Fragment>
            <Navbar />
              <StaticContentWrapper>
                <Container>
                  <Quote>
                    "USRealty.com lists your house on all the most powerful real estate websites, including your local multiple listing service, for free. Sell your house your way, choosing the commission you want to pay."
                  </Quote>
                  <p>Until now, there hasn’t been efficient way to get a house listed at all major online sites: Realtor.com, Trulia, Zillow or any of the dozens of others. Without USRealty.com, you have to first list your house with your local multiple listing service (MLS), which then passes the listing on to the name-brand national listing site. MLS systems are owned by local real estate agents and brokers. That’s why you have to hire an agent to get your house listed in your local MLS. The agent charges a commission, and that commission erodes as much as six percent of your home equity.</p>
                  <p>Only one real estate firm – USRealty.com – has access to MLS systems across the country. That means that no matter where your house is located, USRealty.com can get it online within a few hours. With a few clicks, your house is listed where buyers look first and often.</p>
                  <p>USRealty.com’s innovative model is making news. Read all about it at our <a href={`${process.env.REACT_APP_APP_BASE_URL}${Routes.PRESS}`}>company news and media mentions page.</a></p>
                  <strong>"USRealty.com delivers high success rates and charges very low commissions."</strong>
                  <p>Whether you own one house or manage a portfolio of hundreds of houses, USRealty.com’s easy listing tools and flexible, pay-when-you-sell fee structure save you time, money and hassle. Local realty agents appreciate that USRealty.com funnels hundreds of listings into their markets, ensuring that their buyers can find the houses they want. The USRealty.com structure respects the role of local agents who assist homebuyers by ensuring that they receive the traditional 3 percent commission for when they successfully represent a buyer for a USRealty.com-listed house.</p>
                  <div className="img-wrapper">
                    <img src={ImagesUrl.ASPOT_HOUSE} alt="About"/>
                  </div>
                  <h3>Leadership</h3>
                  <h4>Stuart Daroca <span>(General Counsel and Chief Operating Officer)</span></h4>
                  <p>Stuart Daroca advises USRealty.com’s leadership team on a variety of legal, strategic and business issues and oversees the company’s regulatory, commercial and corporate matters.  He comes to USRealty.com with more than 20 years of financial services and legal experience. Prior to joining USRealty.com, Daroca was Associate General Counsel at American International Group, Inc., where he played a significant role in the divestiture of more than $25 billion of non-core assets and AIG’s successful restructuring and recapitalization after the financial crisis.  He previously was a corporate attorney at Citigroup, Inc. and Skadden, Arps, Slate Meagher & Flom LLP.  He earned his J.D. at Georgetown University Law Center and B.A. at the University of California, Berkeley.</p>
                  <h4>Derek Morgan <span>(Executive Vice President, Senior Managing Broker)</span></h4>
                  <p>Derek Morgan, Executive Vice President, Senior Managing Broker, manages broker services for USRealty.com. Morgan was ranked number seven nationwide in 2018 for the most closed transaction sides. Prior to joining USRealty.com, Morgan spent seven years with Tribune Digital leading broker services, customer support and operations for its subsidiary, ForSaleByOwner.com. He launched their in-house national brokerage while also operating as a senior managing broker in 66 MLSs, and directing the team of in-house brokers and customer support staff of 20; drawing on his experience of eight years as a manager, coach and broker with McColly Real Estate and two years as a director of property management, managing hundreds of properties at a real estate start-up in 2000. He is a licensed real estate broker in seven states and holds multiple specialized real estate certifications. Morgan earned his real estate training at Purdue University. He graduated in 2001 with a master’s degree in education and administration from Hyles-Anderson College and in 1999 from the same school with a bachelor’s degree in theology.</p>
                  <h4>John David <span>(Designated Broker, Missouri)</span></h4>
                  <p>John David is the Designated Broker in Missouri for USRealty.com.   He has been a licensed real estate broker since 2009.   Prior to real estate, he worked in corporate sales with Pitney Bowes and eventually segued into membership development with both the Saint Louis Better Business Bureau and the Saint Louis Regional Chamber and Growth Association (RCGA). He holds a degree in Computer Science from the University of Missouri – Saint Louis and is completing a master’s degree in Computer Science with an expected graduation date of spring 2018.</p>
                  <h4>Ryan Gehris <span>(Senior Vice President, Senior Managing Broker)</span></h4>
                  <p>Ryan Gehris is a founding partner of Housepad, LLP, acquired by USRealty.com in 2014. He received his first real estate license in 1998, and obtained a Real Estate degree from Pennsylvania State University in 2000.   Gehris enjoys studying about, and practicing in, the diverse real estate traditions and markets around the country. He is licensed in 32 states and manages USRealty.com’s customer service operation.</p>
                  <h3>Investors</h3>
                  <h4>Jim Orphanides</h4>
                  <p>Jim Orphanides has been a partner and president of Centurion Holdings LLC since 2010. He served as chairman emeritus of First American Title Insurance Company of New York and a director until the company merged with First American Title Insurance Company in 2010.</p>
                  <p>Orphanides worked for First American since 1992 in key executive positions, and from 1996 through 2007 he was president, CEO and chairman of the Board. Prior to joining First American, he was a principal and president of Preferred Land Title Services, Inc. from 1982-1992. He was an executive at Commonwealth Land Title Insurance Company from 1979-1982 and an executive at Chicago Title Insurance Company from 1972-1979. He has been a trustee of Chambers Street Properties since 2005.</p>
                  <p>Orphanides earned a B.A. from Heidelberg College and an M.A. from Queens College of New York. He has three children and  resides in New Jersey, with his wife.</p>
                  <h3>Commercial Services</h3>
                  <strong>"Choose USRealty.com Commercial As Your Co-Broker and Earn Fast, Smooth Interstate Real Estate Commissions"</strong>
                  <p>USRealty.com’s network of brokers ensures that you have a legal broker in each state where your deal has a footprint.</p>
                  <p>We provide you with the compliance and documentation you need to adhere to the laws and regulations of every state. Our co-broker services remove all legal barriers to earning a commission.</p>
                  <p>You now have one partner for co-brokering in every state, streamlining closings and paperwork. USRealty.com’s forms and processes are standardized to ensure smooth, seamless closings that clear the way for your commission.</p>
                  <p>With USRealty.com Commercial as your co-broker, you have the compliance support you need to protect commissions you earn in interstate deals. USRealty.com is licensed in: AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, IL, IN, LA, MA, MD, MI, MO, NC, NE, NJ, NM, NY, OH, OK, PA, RI, SC, TN, TX, VA, WA, WV]</p>
                  <p>Contact us at <a href="mailto:kyle@abodehq.com">commercial@usrealty.com.</a></p>
                  <h3>Work With Us</h3>
                  <p>USRealty.com is always open to collaborations with commercial and residential brokers and to new ideas from vendors and potential partners.</p>
                  <MidBox className="about-us-contact">
                    <HeadTextWrap className="for-gap">
                      <Heading>Contact Us</Heading>
                      <HeadText>Contact us at: <a href="mailto:kyle@abodehq.com">investors@usrealty.com.</a></HeadText>
                    </HeadTextWrap>
                    <RowFlex>
                      <InputFlex
                        name="firstName"
                        title="First Name"
                        className="inputgap"
                        value={this.state?.contactUsForm?.firstName?.value}
                        error={this.state?.contactUsForm?.firstName?.error}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                      />
                      <InputFlex
                        name="lastName"
                        title="Last Name"
                        className="inputgap"
                        value={this.state?.contactUsForm?.lastName?.value}
                        error={this.state?.contactUsForm?.lastName?.error}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                      />
                    </RowFlex>
                    <InputFlex
                      name="email"
                      title="Email"
                      type="email"
                      value={this.state?.contactUsForm?.email?.value}
                      error={this.state?.contactUsForm?.email?.error}
                      onChange={this.handleFieldChanged}
                      onBlur={this.handleFieldBlur}
                    />
                    <CustomTextarea>
                      <label>Message</label>
                      <textarea
                        name="message"
                        type="textarea"
                        value={this.state?.contactUsForm?.message?.value}
                        error={this.state?.contactUsForm?.message?.error}
                        onChange={this.handleFieldChanged}
                        onBlur={this.handleFieldBlur}
                        className={`${this.state?.contactUsForm?.message?.error ? 'item-text-red' : ''}`}
                      />
                      {
                        this.state?.contactUsForm?.message?.error &&
                        <span className="textarea-error">{validationMessages.required}</span>
                      }
                    </CustomTextarea>
                    <CustomButton type="submit" className="block" onClick={this.validateAndSubmit}>Submit</CustomButton>
                  </MidBox>
                </Container>
              </StaticContentWrapper>
            <Footer />
          </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
  return {
      userDetails: state.user.userDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...onboardingActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
