import React from 'react';
import { InputMapMarker } from '../../Shared/Components/Icons/InputMapMarker';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';

export const ListingItemWrapper = styled.div`
  display: flex;
  padding: 0.25rem;
  cursor: pointer;
  &:hover {
    background: #f4f4f4;
  }
`;
export const ListingItemBody = styled.div`
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
`;
const MapMarkerContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
`;

export const ListingItem = ({ listing, onClick }) => {
    return (
      <ListingItemWrapper key={`inactive-listings-${listing.id}`} onClick={() => onClick(listing)}>
        <MapMarkerContainer>
          <InputMapMarker />
        </MapMarkerContainer>
        <ListingItemBody>
          <div><strong>{listing.address}</strong></div>
          <div>
            Price: <CurrencyFormat value={listing.price} thousandSeparator={true} displayType={'text'} prefix={'$'} suffix=".00"/>,
            Baths: {listing.baths},
            Beds: {listing.beds},
            Size (sqft): {listing.lotSize || '---'}
          </div>
        </ListingItemBody>
      </ListingItemWrapper>
    );
  };