import { createNullishCoalesce } from 'typescript';
import { UIActions } from '../Actions/UIActions';

export const loginModalMessageTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
};
const initialState = {
  showLoginModal: false,
  showSignUpModal: false,
  accessDeniedRedirect: false,
  loginModalMessage: null,
  loginModalMessageFixed: false,
  showComparePlansModal: false,
  emailConfirmationRedirectUrl: null,
  loginRedirectUrl: null,
  loginModalMessageType: null,
  navBarContent: null,
  footerContent: null,
  loadingFooterAndHeaderContent: false,
  showIntelligenceModal: false,
};

export const UIReducer = (state = initialState, action = {}) => {
  state = state || initialState;

  switch (action.type) {
    case UIActions.SET_ACCESS_DENIED:
      return { ...state, accessDeniedRedirect: true };
    case UIActions.OPEN_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: true,
        loginModalMessage: action.payload.message,
        loginModalMessageFixed: action.payload.fixed,
        loginRedirectUrl: action.payload.loginRedirectUrl,
        emailConfirmationRedirectUrl: action.payload.emailConfirmationRedirectUrl,
        loginModalMessageType: action.payload.loginModalMessageType,
      };
    case UIActions.OPEN_SIGNUP_MODAL:
      return { ...state, showSignUpModal: true };
    case UIActions.CLOSE_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: false,
        accessDeniedRedirect: false,
        loginModalMessage: null,
        loginModalMessageFixed: false,
        loginRedirectUrl: null,
        emailConfirmationRedirectUrl: null,
        loginModalMessageType: null,
      };
    case UIActions.CLOSE_SIGNUP_MODAL:
      return {
        ...state,
        showSignUpModal: false,
        loginModalMessageFixed: false,
        loginModalMessage: null,
        loginRedirectUrl: null,
        emailConfirmationRedirectUrl: null,
      };
    case UIActions.TOGGLE_AUTH_MODALS:
      return {
        ...state,
        showSignUpModal: !state.showSignUpModal,
        showLoginModal: !state.showLoginModal,
        accessDeniedRedirect: false,
      };
    case UIActions.OPEN_COMPARE_PLANS_MODAL:
      return {
        ...state,
        showComparePlansModal: true,
      };
    case UIActions.CLOSE_COMPARE_PLANS_MODAL:
      return {
        ...state,
        showComparePlansModal: false,
      };

    case UIActions.GET_NAVBAR_AND_FOOTER_CONTENT_REQUESTED:
      return {
        ...state,
        loadingFooterAndHeaderContent: true,
      };

    case UIActions.GET_NAVBAR_AND_FOOTER_CONTENT_RESOLVED:
      return {
        ...state,
        navBarContent: action.payload?.navbar,
        footerContent: action.payload?.footer,
        loadingFooterAndHeaderContent: false,
      };

    case UIActions.GET_NAVBAR_AND_FOOTER_CONTENT_FAILED:
      return {
        ...state,
        navBarContent: null,
        footerContent: null,
        loadingFooterAndHeaderContent: false,
      };

    case UIActions.SET_LOGIN_MODAL_MESSAGE:
      return {
        ...state,
        loginModalMessage: action.payload.message,
        loginModalMessageType: action.payload.loginModalMessageType,
      };
    case UIActions.OPEN_INTELLIGENCE_MODAL:
      return { ...state, showIntelligenceModal: true };
    case UIActions.CLOSE_INTELLIGENCE_MODAL:
      return { ...state, showIntelligenceModal: false };

    default:
      return state;
  }
};

export const showLoginModalSelector = (state) => state.ui.showLoginModal;
export const showSignUpModalSelector = (state) => state.ui.showSignUpModal;
export const accessDeniedRedirectSelector = (state) => state.ui.accessDeniedRedirect;
export const loginModalMessageSelector = (state) => state.ui.loginModalMessage;
export const fixedSelector = (state) => state.ui.loginModalMessageFixed;
export const showComparePlansModalSelector = (state) => state.ui.showComparePlansModal;
export const emailConfirmationRedirectUrlSelector = (state) => state.ui.emailConfirmationRedirectUrl;
export const loginRedirectUrlSelector = (state) => state.ui.loginRedirectUrl;
export const loginModalMessageTypeSelector = (state) => state.ui.loginModalMessageType;
export const loadingFooterAndHeaderContentSelector = (state) => state.ui.loadingFooterAndHeaderContent;
export const navBarContentSelector = (state) => state.ui.navBarContent;
export const footerContentSelector = (state) => state.ui.footerContent;
export const showIntelligenceModalSelector = (state) => state.ui.showIntelligenceModal;
