import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Menu, Row, Dropdown } from 'antd';
import { userActionCreators } from '../../../Actions/UserActions';
import Routes from '../../../Constants/Routes';
import { history } from '../../../Utilities/History';
import { getLocalUserData } from '../../../Utilities/commonFunctions';
import downarrow from '../../../../src/Assets/Images/admin-down-arrow.png';
import { Logo } from '../../Shared/Components/Icons/Logo';
class AdminHeader extends React.Component {
  constructor(props) {
    super(props);
    this.userData = getLocalUserData();
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.props.logout(this.userData.userId, this.userData.token);
  };

  userDropdownMenu = (
    <Menu className="admin-header-dropdown">
      <Menu.Item>
        <a href={`${Routes.ADMIN_PROFILE}`}>My Profile</a>
      </Menu.Item>
      <Menu.Item>
        <a href="javascript:void(0)" onClick={this.handleLogout} >Logout</a>
      </Menu.Item>
    </Menu>
  );

  render() {
    return (
      <div className="admin-header">
        <Row className={'header-container'}>
          <Col style={{ textAlign: 'left' }} span={12}>
            <Logo />
          </Col>
          <Col style={{ textAlign: 'right' }} span={12}>
            <Dropdown overlay={this.userDropdownMenu} trigger={['click']} overlayClassName="header-dropdown-wrapper">
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {`${this.userData.firstName} ${this.userData.lastName}`}
                <img src={downarrow} alt="down-arrow" />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.userDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
