import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { withTheme } from 'styled-components';
import { map } from 'lodash';
import { Map, Marker } from 'google-maps-react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import LoadingOverlay from 'react-loading-overlay-ts';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { userActionCreators } from '../../../Actions/UserActions';
import PublicListingDetail from '../Components/PublicListingDetail';
import { PublicListingWrapper, Container, MapWrapper } from '../../../Assets/Style/commonStyleComponents';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';


export class PublicViewListing extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.publicViewListingDetail(this.props.match.params.listingId);
	}

	renderPropertyPhotos = () => {
		return map(this.props?.listing?.photos, (item) => item && (
			<div key={`${item.absoluteUrl}`}>
				<img src={item.absoluteUrl} alt="" />
			</div>
		));
	}
	render() {
		const { userDetails, isFetching, listing, requestPropertyDetails } = this.props;
		return (
			<React.Fragment>
				<Navbar />
				<LoadingOverlay
					active={isFetching}
					spinner
					text=""
				>
					<PublicListingWrapper>
						<Container>
							<div className="multi-slider-row">
									
								<Carousel
									dynamicHeight={true}
									showStatus={false}
									className="custom-multi-slider"
									thumbWidth={100}
								>
									{this.renderPropertyPhotos()}
								</Carousel>
								{!isFetching && <MapWrapper className="for-bottom-gap real-map-wrapper">
									<Map google={window.google}
										style={{ width: '100%', height: '100%', position: 'relative' }}
										className={'map'}
										zoom={15}
										initialCenter={{
											lat: listing?.latitude, lng: listing?.longitude
										}}
									>
										<Marker
											title={'The marker`s title will appear as a tooltip.'}
											position={{ lat: listing?.latitude, lng: listing?.longitude }} />
									</Map>
								</MapWrapper>}
							</div>
							{
								listing &&
								<PublicListingDetail
									isFetching={isFetching}
									listing={listing}
									submitRequestMoreDetail={requestPropertyDetails}
									listingId={this.props.match.params.listingId}
								/>
							}
						</Container>
					</PublicListingWrapper>
					<Footer />
				</LoadingOverlay>
			</React.Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		listing: state.dashboard.listing,
		userDetails: state.user.userDetails,
		isFetching: state.dashboard.isFetching,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ ...dashboardActionCreators, ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)((withTheme(PublicViewListing)));
