import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import ApiUrls from '../Constants/ApiUrls';
import { contentType, apiMethodType } from '../Constants/CommonConstants';
import { getCustomEntityPath } from '../Utilities/commonFunctions';

class DataService {
  constructor() {
    let urls = {
      base: '',
    };

    urls.base = urls.base || '/';

    this.urls = urls;
  }

  getAll(entity, request) {
    const url = `${getCustomEntityPath(entity)}/all`;
    return fetch(url, {
      method: apiMethodType.POST,
      withCredentials: true,
      data: { ...request },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  get(entity, id) {
    const url = `${ApiUrls.apiData}${entity}/${id}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  update(entity, id, request) {
    const url = `${ApiUrls.apiData}${entity}/${id}`;
    return fetch(url, {
      method: apiMethodType.PUT,
      data: { ...request },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  add(entity, request) {
    const url = `${ApiUrls.apiData}${entity}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...request },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  delete(entity, id) {
    const url = `${getCustomEntityPath(entity)}/${id}`;
    return fetch(url, {
      method: apiMethodType.DELETE,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  setLocal(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  getLocal(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  clearLocal(key) {
    localStorage.removeItem(key);
  }

  toggleStatus(entity, id) {
    const url = `${getCustomEntityPath(entity)}/${id}/toggle`;
    return fetch(url, {
      method: apiMethodType.POST,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  updateStatus(entity, { userFormId, formSubmissionStatusId }) {
    const url = `${getCustomEntityPath(
      entity,
    )}/${userFormId}/status/${formSubmissionStatusId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  updateOverlayForm(entity, sectionId, request) {
    const url = `${getCustomEntityPath(entity)}/section/${sectionId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...request },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  getOverlayForm(entity, sectionId) {
    const url = `${getCustomEntityPath(entity)}/section/${sectionId}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  getClone(entity, { formGroupId, formGroupSectionId }) {
    const url = `${getCustomEntityPath(
      entity,
    )}/${formGroupId}/clone/${formGroupSectionId}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  getMlsPreviewForm(entity, requestObj) {
    const url = `${getCustomEntityPath(entity)}/preview`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...requestObj },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  updateLoggedInUser(entity, {mlsId, userId}) {
    const url = `${getCustomEntityPath(entity)}/${mlsId}/user`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { userId },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  resendVoucherInvite(entity,reqObj) {
   const url = `${getCustomEntityPath(entity)}/${reqObj.voucherId}`;
   return fetch(url, {
      method: apiMethodType.POST,
      data: { ...reqObj },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  completePayment(entity, listingId) {
    const url = `${getCustomEntityPath(entity)}/${listingId}/charge`;
    return fetch(url, {
      method: apiMethodType.POST,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
}

export let dataService = new DataService();
