import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { cursorStyles, reactSelectFilters } from '../../../../../Constants/CommonConstants';
import { companiesSelector, statesSelector } from '../../../../../Reducers/LookupReducer';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { Button } from 'antd';

const initFilter = {
    state: null,
    companyId: null,
}

const TitleCompanyFilters = ({ filters, setFilters }) => {
    const dispatch = useDispatch();

    const states = useSelector(statesSelector);
    const companies = useSelector(companiesSelector);

    const statesOptions = states.map(x => ({ value: x.id, label: x.name }));
    const companiesOptions = companies.map(x => ({ value: x.id, label: x.name }));

    const [filter, setFilter] = useState(initFilter);

    useEffect(() => {
        dispatch(lookupActionsCreators.getStates());
        dispatch(lookupActionsCreators.getCompanies());
    }, []);

    const applyFilters = () => {
        setFilters({ ...filters, ...filter });
    }
    const clearFilters = () => {
        setFilter(initFilter);
        setFilters({ ...filters, ...initFilter });
    }

    return <div className={`admin-filter-wrapper more-filter`}>
        <div className={`filter-bar`}>
            <div className="filter-bar-left">
                <div className='filter-dropdown for-custom-select'>
                    <label>Filter by State</label>
                    <Select
                        styles={cursorStyles}
                        placeholder='Select State'
                        value={statesOptions.find(x => x.value === filter.state) || null}
                        onChange={e => setFilter({ ...filter, state: e.value })}
                        options={statesOptions}
                        menuPosition='fixed'
                        menuShouldBlockScroll={false}
                        menuShouldScrollIntoView={true}
                        className='admin-filter-react-select'
                        createFilter={createFilter(reactSelectFilters)}
                    />
                </div>
                <div className='filter-dropdown for-custom-select'>
                    <label>Filter by Company</label>
                    <Select
                        styles={cursorStyles}
                        placeholder='Select Company'
                        value={companiesOptions.find(x => x.value === filter.companyId) || null}
                        onChange={e => setFilter({ ...filter, companyId: e.value })}
                        options={companiesOptions}
                        menuPosition='fixed'
                        menuShouldBlockScroll={false}
                        menuShouldScrollIntoView={true}
                        className='admin-filter-react-select'
                    />
                </div>
            </div>
            <div className='form-group search-buttons'>
                <Button
                    className='btn btn-primary admin-primary-btn small-btn'
                    onClick={() => applyFilters()}>
                    Apply Filters
                </Button>
                <Button
                    className='btn btn-primary admin-primary-btn small-btn'
                    onClick={() => clearFilters()}>
                    Reset Filters
                </Button>
            </div>
        </div>
    </div>
}

export default TitleCompanyFilters;