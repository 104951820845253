import LogRocket from 'logrocket';
import { startLoading, endLoading } from '../LoaderActions';
import { removeNullUndefined } from '../../Utilities/commonFunctions';
import { staticMessages } from '../../Constants/CommonConstants';
import { alertActions } from '../ToastActions';
import { cashOfferService } from '../../Services/V2/CashOfferService';
import Routes from '../../Constants/Routes';
import { dashboardActionCreators } from '../DashboardActions';
import { onboardingActionCreatorsV2 } from '../OnboardingActionsV2';

export const CashOfferActions = {
    OPEN_CASH_ORDER_MODAL: 'OPEN_CASH_ORDER_MODAL',
    CLOSE_CASH_ORDER_MODAL: 'CLOSE_CASH_ORDER_MODAL',
    ADD_CASH_OFFER_RESOLVED: 'ADD_CASH_OFFER_RESOLVED',
    ADD_CASH_OFFER_FAILED: 'ADD_CASH_OFFER_FAILED',
    UPDATE_CASH_OFFER_RESOLVED: 'UPDATE_CASH_OFFER_RESOLVED',
    UPDATE_CASH_OFFER_FAILED: 'UPDATE_CASH_OFFER_FAILED',
    GET_CASH_OFFERS_RESOLVED: 'GET_CASH_OFFERS_RESOLVED',
    GET_CASH_OFFERS_FAILED: 'GET_CASH_OFFERS_FAILED',
    SET_CASH_OFFER_FILTERS: 'SET_CASH_OFFER_FILTERS'
};

export const cashOfferCreators = {
    setCashOfferFilters: (filters) => async (dispatch) => {
        dispatch({ type: CashOfferActions.SET_CASH_OFFER_FILTERS, payload: filters });
    },
    openCashOfferModal: (cashOfferData) => ({ type: CashOfferActions.OPEN_CASH_ORDER_MODAL, payload: cashOfferData }),
    closeCashOfferModal: () => ({ type: CashOfferActions.CLOSE_CASH_ORDER_MODAL }),
    addCashOffer: (cashOffer) => async (dispatch, getState) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { cashOfferId } = await cashOfferService.addCashOffer(cashOffer);

            if(window.location.href.includes(Routes.DASHBOARD)) {
                dispatch(dashboardActionCreators.updateListingCashOfferStatus(cashOfferId, cashOffer.listingId, cashOffer.draftListingId));
            }

            if(window.location.href.includes(Routes.ONBOARDING_V2)) {
                dispatch(onboardingActionCreatorsV2.updateDraftListingCashOfferStatus(cashOfferId));
            }

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: CashOfferActions.ADD_CASH_OFFER_RESOLVED, payload: cashOfferId });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            dispatch({ type: CashOfferActions.ADD_CASH_OFFER_FAILED });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_onboarding_action',
                },
                extra: {
                    type: "onboarding actions addCashOffer FAILED"
                },
            });
        }
    },
    getCashOffers: (params) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const paramsObj = params;
            const { data } = await cashOfferService.getCashOffers(removeNullUndefined(paramsObj));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: CashOfferActions.GET_CASH_OFFERS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_titlecompany_action',
                },
                extra: {
                    type: CashOfferActions.GET_CASH_OFFERS_FAILED,
                },
            });
        }
    }
};
