import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Table from 'react-bootstrap/Table';
import { CustomEditor, CustomTextarea, InputFlex, RowFlex } from '../../../../Assets/Style/commonStyleComponents';
import { inputFieldsType } from '../../../../Constants/CommonConstants';
import { Editor } from '@tinymce/tinymce-react';
import Enums from '../../../../Constants/Enums';
import Routes from '../../../../Constants/Routes';

const itemTextRed='item-text-red';
class AddEditBasicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.editorRef = React.createRef(null);
    let i=0;
    for(let result of props.basicFormFiledTable) {
      let customeStatus=false;
      if(result[`formPrefillTypeId-${i}`]===100){
         customeStatus=true;
       }
        this.state[`${i}IsShown`] = customeStatus;
        i++;
      }
    }
  
addCustomText= (idx, e) => {
    const { value } = e.target;
    let customeStatus=false;
    if(value==100){
      customeStatus=true;
    }
    let key = `${idx}IsShown`;
    this.setState({[key]: customeStatus});
  }

  renderBasicFormFields = () => {
    const { lookup, basicFormFiledTable, handleAddRow, handleRemoveRow, handleBasicFormFields, handleOnBlurBasicFormFields } = this.props;
    return map(basicFormFiledTable, (item, index) => item && (
      <tr id="addr0" key={index}>
        <td>{index + 1}</td>
        <td>{item[`shortCode-${index}`] ? item[`shortCode-${index}`] : 'You must update before shortcode is available.'}</td>
        
        <td>
          <InputFlex
            options={inputFieldsType}
            name={`inputDataTypeId-${index}`}
            type="text"
            key={Math.random()}
            className="inputgap"
            isKeyPaired={true}
            value={item[`inputDataTypeId-${index}`]}
            onChange={(e) => handleBasicFormFields(index, e, false)}
          />
        </td>
        <td>
          <InputFlex
            type="text"
            name={`displayName-${index}`}
            value={item[`displayName-${index}`]}
            onChange={(e) => handleBasicFormFields(index, e, false)}
            onBlur={(e) => handleOnBlurBasicFormFields(index, e, false)}
            className={`inputgap ${item[`errors-${index}`][`displayName-${index}`] ? itemTextRed : ''}`}
          />
          {
            (item[`errors-${index}`][`displayName-${index}`] && item[`errors-${index}`][`duplicateName`]) &&
            <div className="input-error-text">Display name already exist.</div>
          }
        </td>
        <td>
          <div className="admin-custom-checkbox">
            <label title="Required">
              <input
                className="inputgap"
                name={`required-${index}`}
                id={`required-${index}`}
                type="checkbox"
                onClick={(event) => { handleBasicFormFields(index, event, true); }}
                defaultChecked={item[`required-${index}`]}
              />
              <span></span>
            </label>
          </div>
        </td>
        <td>
          {
            (Number(item[`inputDataTypeId-${index}`]) !== Enums.NUMERIC_VALUE.FIVE) &&
            <div className="admin-custom-checkbox">
              <label title="Lock">
                <input
                  className="inputgap"
                  name={`readOnly-${index}`}
                  id={`readOnly-${index}`}
                  type="checkbox"
                  onClick={(event) => { handleBasicFormFields(index, event, true); }}
                  defaultChecked={item[`readOnly-${index}`]}
                />
                <span></span>
              </label>
            </div>
          }
        </td>
        <td>
          {
            (Number(item[`inputDataTypeId-${index}`]) === Enums.NUMERIC_VALUE.ONE || Number(item[`inputDataTypeId-${index}`]) === Enums.NUMERIC_VALUE.FOUR || Number(item[`inputDataTypeId-${index}`]) === Enums.NUMERIC_VALUE.SEVEN) &&
            <div className="admin-custom-checkbox">
              <label title="Link">
                <input
                  className="inputgap"
                  name={`isLinked-${index}`}
                  id={`isLinked-${index}`}
                  type="checkbox"
                  onClick={(event) => { handleBasicFormFields(index, event, true); }}
                  defaultChecked={item[`isLinked-${index}`]}
                />
                <span></span>
              </label>
            </div>
          }
        </td>
        <td>
          <InputFlex
            type="text"
            name={`priority-${index}`}
            value={item[`priority-${index}`]}
            onChange={(e) => handleBasicFormFields(index, e, false)}
            onBlur={(e) => handleOnBlurBasicFormFields(index, e, false)}
            className={`inputgap ${item[`errors-${index}`][`priority-${index}`] ? itemTextRed : ''}`}
          />
        </td>
        <td className="two-inputs">
         
        
          {((Number(item[`inputDataTypeId-${index}`])===Enums.NUMERIC_VALUE.THIRTEEN) || (Number(item[`inputDataTypeId-${index}`])===Enums.NUMERIC_VALUE.FIVE)) && 
       
            <CustomTextarea>
              <label>Options</label>
              <span>Enter each option on a new line1.</span>
              <textarea
                name={`options-${index}`}
                type="textarea"
                value={item[`options-${index}`]}
                onChange={(e) => handleBasicFormFields(index, e, false)}
                className={`${item[`errors-${index}`][`options-${index}`] ? itemTextRed : ''}`}
              />
            </CustomTextarea>
          }
            {Number(item[`inputDataTypeId-${index}`])===Enums.NUMERIC_VALUE.FIVE &&
            <>
            <span>PICK</span>
            <InputFlex
              options={lookup?.optionTypes}
              name={`optionTypeId-${index}`}
              type="text"
              value={item[`optionTypeId-${index}`]}
              className="inputgap"
              isKeyPaired={true}
              onChange={(e) => handleBasicFormFields(index, e, false)}
            /></>
        }

          {
            (Number(item[`inputDataTypeId-${index}`]) !== Enums.NUMERIC_VALUE.FIVE && Number(item[`inputDataTypeId-${index}`]) !== Enums.NUMERIC_VALUE.THIRTEEN) &&
            <InputFlex
              options={lookup?.fieldPrefillTypes}
              name={`formPrefillTypeId-${index}`}
              type="text"
              value={item[`formPrefillTypeId-${index}`]}
              placeholder='Select PreFilled Text'
              className="inputgap"
              isKeyPaired={true}
              onChange={(e) =>{handleBasicFormFields(index, e, false);this.addCustomText(index, e)}}
            />
          }
          {this.state[`${index}IsShown`] && 
          <div id={index}>
          <InputFlex
            type="text"
            name={`PrefillText-${index}`}
            value={item[`PrefillText-${index}`]}
            onChange={(e) => handleBasicFormFields(index, e, false)}
            onBlur={(e) => handleOnBlurBasicFormFields(index, e, false)}
            className={`inputgap ${item[`errors-${index}`][`displayName-${index}`] ? itemTextRed : ''}`}
          /></div>
          }
        </td>
        <td>
          <div className="add-delete-row">
            <span onClick={() => handleAddRow()}><i className="fa fa-plus" aria-hidden="true"></i></span>
            <span onClick={() => handleRemoveRow(index)}><i className="fa fa-minus" aria-hidden="true"></i></span>
          </div>
        </td>
      </tr>
    ))
  }

  render() {
    const { handleAddRow, textEditorData, handleEditorChange, selectedFormDetails } = this.props;
    return (
      <React.Fragment>
        <div className="field-preview-wrapper">
          <h3 className="admin-form-inner-heading">Fields</h3>
          {selectedFormDetails?.formGroupSectionId &&
          <a target='_blank' variant="primary"
            href={`${Routes.BASIC_FORM_PREVIEW.replace(
              ':formId',
              selectedFormDetails?.formGroupId,
            )}?formSectionId=${selectedFormDetails?.formGroupSectionId}`}
            className="admin-primary-btn">Preview</a>
          }
        </div>
        <div className="basic-form-type">
          <Table bordered>
            <thead>
              <tr>
                <th></th>
                <th>Shortcode</th>
                <th>Type <span>*</span></th>
                <th>Name <span>*</span></th>
                <th>Req.</th>
                <th>Lock</th>
                <th>Link</th>
                <th>Priority</th>
                <th>Prefill Type</th>
                <th>
                  <span className="basic-form-add-field" onClick={() => handleAddRow()}><i className="fa fa-plus" aria-hidden="true"></i></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.renderBasicFormFields()}
            </tbody>
          </Table>
          <CustomEditor className="editor-footer-gap">
            <h3 className="admin-form-inner-heading">{textEditorData.content.label}</h3>
            <Editor
							tinymceScriptSrc={process.env.REACT_APP_APP_BASE_URL + '/tinymce/tinymce.min.js'}
							onInit={(evt, editor) => {
									this.editorRef.current = editor;
								}}
              value={textEditorData.content.value}
              init={{
                height: 'calc(100vh - 156px)',
                width: '100%',
                menubar: false,
                toolbar:
                  'bold italic underline forecolor alignleft aligncenter alignright numlist insertfile link | a11ycheck ltr rtl |',
              }}
              onEditorChange={handleEditorChange}
            />
          </CustomEditor>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null)(AddEditBasicForm);
