   import React from 'react'

   export const Hamburger = ({...iconProps}) => ( 
   <svg viewBox="0 0 16 17" focusable="false" xmlns="http://www.w3.org/2000/svg" aria-label="Open the menu sidebar" {...iconProps}>
      <path d="M7.97669 2.83335C10.3462 2.83938 12.6969 2.85299 15.0663 2.85903C15.4225 2.86109 15.3575 3.09113 15.2389 3.18262C15.1144 3.32015 14.8118 3.45665 14.4557 3.45459L11.8557 3.43954C11.5285 3.43764 8.43169 3.50223 8.0362 3.49994C5.68572 3.48633 3.31581 3.4803 0.946553 3.47426L0.666342 3.29598C0.708638 3.10421 1.04174 2.86804 1.54037 2.87092L4.14038 2.88597C5.07621 2.89139 7.55704 2.83228 7.97669 2.83335Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4">
      </path>
      <path d="M7.97669 8.16684C10.3462 8.17288 12.6969 8.18649 15.0663 8.19252C15.4225 8.19458 15.3575 8.42463 15.2389 8.51611C15.1144 8.65364 14.8118 8.79015 14.4557 8.78808L11.8557 8.77303C11.5285 8.77114 8.43169 8.83573 8.0362 8.83344C5.68572 8.81983 3.31581 8.81379 0.946553 8.80776L0.666342 8.62948C0.708638 8.43771 1.04174 8.20153 1.54037 8.20442L4.14038 8.21947C5.07621 8.22489 7.55704 8.16578 7.97669 8.16684Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4">
      </path>
      <path d="M7.97669 13.5003C10.3462 13.5064 12.6969 13.52 15.0663 13.526C15.4225 13.5281 15.3575 13.7581 15.2389 13.8496C15.1144 13.9871 14.8118 14.1236 14.4557 14.1216L11.8557 14.1065C11.5285 14.1046 8.43169 14.1692 8.0362 14.1669C5.68572 14.1533 3.31581 14.1473 0.946553 14.1413L0.666342 13.963C0.708638 13.7712 1.04174 13.535 1.54037 13.5379L4.14038 13.553C5.07621 13.5584 7.55704 13.4993 7.97669 13.5003Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4">
      </path>
   </svg>
   );
