import React from 'react';
import { connect } from 'react-redux';
import { RegionDropdown } from 'react-country-region-selector';
import {
  RowFlex,
  InputFlex,
  DetailsWrapper,
} from '../../../Assets/Style/commonStyleComponents';
import { BlockAddress } from '../../../Constants/CommonConstants';
class AddressComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  renderInputName = (isBillingAddress, billingName, shippingName) => {
    return isBillingAddress ? billingName : shippingName;
  }
  render() {
    const {
      initialState,
      handleFieldChanged,
      handleFieldBlur,
      selectStateAndCounty,
      isBillingAddress,
    } = this.props;
    return (
      <React.Fragment>
        <DetailsWrapper>
          <RowFlex>
            <InputFlex
              name={this.renderInputName(isBillingAddress, initialState?.billingFirstName?.name, initialState?.shippingFirstName?.name)}
              title={isBillingAddress ? initialState.billingFirstName.label : initialState.shippingFirstName.label}
              value={isBillingAddress ? initialState.billingFirstName.value : initialState.shippingFirstName.value}
              error={isBillingAddress ? initialState.billingFirstName.error : initialState.shippingFirstName.error}
              onChange={e => handleFieldChanged(e)}
              onBlur={e => handleFieldBlur(e)}
              className='inputgap'
            />
            <InputFlex
              name={this.renderInputName(isBillingAddress, initialState?.billingLastName?.name, initialState?.shippingLastName?.name)}
              title={isBillingAddress ? initialState.billingLastName.label : initialState.shippingLastName.label}
              value={isBillingAddress ? initialState.billingLastName.value : initialState.shippingLastName.value}
              error={isBillingAddress ? initialState.billingLastName.error : initialState.shippingLastName.error}
              onChange={e => handleFieldChanged(e)}
              onBlur={e => handleFieldBlur(e)}
              className='inputgap'
            />
          </RowFlex>
          <RowFlex>
            <InputFlex
              name={this.renderInputName(isBillingAddress, initialState?.billingAddress1?.name, initialState?.shippingAddress1?.name)}
              title={isBillingAddress ? initialState.billingAddress1.label : initialState.shippingAddress1.label}
              value={isBillingAddress ? initialState.billingAddress1.value : initialState.shippingAddress1.value}
              error={isBillingAddress ? initialState.billingAddress1.error : initialState.shippingAddress1.error}
              onChange={e => handleFieldChanged(e)}
              onBlur={e => handleFieldBlur(e)}
              className='inputgap'
            />
            <InputFlex
              name={this.renderInputName(isBillingAddress, initialState?.billingAddress2?.name, initialState?.shippingAddress2?.name)}
              title={isBillingAddress ? initialState.billingAddress2.label : initialState.shippingAddress2.label}
              value={isBillingAddress ? initialState.billingAddress2.value : initialState.shippingAddress2.value}
              error={isBillingAddress ? initialState.billingAddress2.error : initialState.shippingAddress2.error}
              onChange={e => handleFieldChanged(e)}
              onBlur={e => handleFieldBlur(e)}
              className='inputgap'
            />
          </RowFlex>
          <RowFlex>
            <div className='pcol-6 for-custom-select'>
              <InputFlex
                name={this.renderInputName(isBillingAddress, initialState?.billingUnit?.name, initialState?.shippingUnit?.name)}
                title={isBillingAddress ? initialState.billingUnit.label : initialState.shippingUnit.label}
                value={isBillingAddress ? initialState.billingUnit.value : initialState.shippingUnit.value}
                onChange={e => handleFieldChanged(e)}
                onBlur={e => handleFieldBlur(e)}
              />
            </div>
            <div className='pcol-6 for-custom-select'>
              <label>State</label>
              <RegionDropdown
                defaultOptionLabel='Select a State.'
                countryValueType='short'
                valueType='short'
                country='US'
                value={isBillingAddress ? initialState.billingState.value : initialState.shippingState.value}
                onChange={e => selectStateAndCounty(e)}
                blacklist={{US: BlockAddress}}
                error={isBillingAddress ? initialState?.billingState?.error : initialState?.shippingState?.error}
                className={
                  initialState?.billingState?.error || initialState?.shippingState?.error
                    ? 'custom-select error-input'
                    : 'custom-select'
                }
              />
            </div>
          </RowFlex>
          <RowFlex>
            <InputFlex
              name={this.renderInputName(isBillingAddress, initialState?.billingCity?.name, initialState?.shippingCity?.name)}
              title={isBillingAddress ? initialState.billingCity.label : initialState.shippingCity.label}
              value={isBillingAddress ? initialState.billingCity.value : initialState.shippingCity.value}
              error={isBillingAddress ? initialState.billingCity.error : initialState.shippingCity.error}
              onChange={e => handleFieldChanged(e)}
              ur={e => handleFieldBlur(e)}
              className='inputgap'
            />
            <InputFlex
              name={this.renderInputName(isBillingAddress, initialState?.billingZip?.name, initialState?.shippingZip?.name)}
              title={isBillingAddress ? initialState.billingZip.label : initialState.shippingZip.label}
              value={isBillingAddress ? initialState.billingZip.value : initialState.shippingZip.value}
              error={isBillingAddress ? initialState.billingZip.error : initialState.shippingZip.error}
              onChange={e => handleFieldChanged(e)}
              onBlur={e => handleFieldBlur(e)}
              className='inputgap'
            />
          </RowFlex>
        </DetailsWrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

export default connect(mapStateToProps)(AddressComponent);
