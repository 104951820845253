import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { adminActionCreators } from '../../../Actions/AdminActions';
import { ListingComponent } from '../../Shared/Components/ListingComponent';
import {
  entityTypes,
  tableItemsPerPage,
} from '../../../Constants/CommonConstants';
import enums from './../../../Constants/Enums';
import { userAuditLogTableConfigs } from '../../../Constants/TableConstants';
class AuditLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: enums.NUMERIC_VALUE.ONE,
      items: tableItemsPerPage,
    };
  }

  getId(entity) {
    const { match } = this.props;
    switch (entity) {
      case entityTypes.user:
        return match.params.userId;
      case entityTypes.form:
        const paramsValue = new URLSearchParams(window.location.search);
        return paramsValue.get('formSectionId');
      default:
        return enums.NUMERIC_VALUE.ZERO;
    }
  }
  getEntity(entity) {
    switch (entity) {
      case entityTypes.user:
        return entityTypes.user;
      case entityTypes.form:
        return 'listing';
      default:
        return entity;
    }
  }

  fetchAuditLogsApiAction(pageNo) {
    const { items } = this.state;
    const { activeEntity, getAuditLog } = this.props;
    const reqObj = {
      entityType: this.getEntity(activeEntity),
      recordId: this.getId(activeEntity),
      page: pageNo,
      limit: items,
    };
    getAuditLog(activeEntity, reqObj);
  }
  handleTableChange = requestObject => {
    const { activePage, pageSize } = requestObject;
    this.setState({ activePage, items: pageSize }, () =>
      this.fetchAuditLogsApiAction(activePage),
    );
  };
  componentDidMount() {
    this.fetchAuditLogsApiAction(enums.NUMERIC_VALUE.ONE);
  }
  render() {
    const { activeSet, totalSet } = this.props;
    const { activePage, items } = this.state;
    const filterObj = { activePage, pageSize: items };
    return (
      <>
        <h3 className='admin-form-inner-heading with-below-text'>Audit Logs</h3>
        <div className='for-edit-user-table'>
          <ListingComponent
            tableColumns={userAuditLogTableConfigs.columns}
            listData={activeSet}
            totalItems={totalSet}
            filterObj={filterObj}
            handleTableChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const { auditLogs, totalSet } = state.admin;
  const entityArray = path.split('/');
  const activeEntity = entityArray[enums.NUMERIC_VALUE.TWO];
  const activeSet = auditLogs[activeEntity] || [];
  return {
    activeEntity,
    activeSet,
    totalSet,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuditLog),
);
