const AppConsts = {
  userManagement: {
    defaultAdminUserName: 'admin',
  },
  authorization: {
    encryptedAuthTokenName: 'enc_auth_token',
    externalCookiesName: '.Identity.UnrealEstate'
  },
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
  remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  gtagCodes:{
    conversionId: 'AW-1032844475/NZb_CJHItYgDELvpv-wD'
  },
  klaviyoForms: {
    homePageFormId: 'Xr3X24',
    homeSellingFormId: 'VfrH9F',
    freeEbookFormId: 'V6WMSG',
    buyerQualificationFormId: 'QNCnxb',
  },
  apiClientTimeout: process.env.REACT_APP_API_CLIENT_TIMEOUT || 120000,
  logRocketApId: process.env.REACT_APP_LOGROCKET_APP_ID,
  useLogRocket:  process.env.REACT_APP_ENABLE_LOGROCKET === 'true',
  mapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
  graphqlApiUrl: process.env.REACT_APP_GRAPHQL_API_URL,
  buySideUrl: process.env.REACT_APP_BUY_SIDE_URL,
  useGtm: process.env.REACT_APP_USE_GTM === 'true',
  mortgageUrl: process.env.REACT_APP_MORTGAGE_URL,

};
export default AppConsts;