import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Container, CustomAccordion, HeadTextWrap, Heading, HeadText } from "../../../../Assets/Style/commonStyleComponents";
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

class Glossary extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <CustomAccordion>
          <Container>
            <HeadTextWrap className="for-left">
              <Heading>Glossary</Heading>
              <HeadText>The US Realty knowledge base.</HeadText>
            </HeadTextWrap>
            <Accordion defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Acre
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    A measurement of land equal to 43,560 square feet in any shape.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  Addendums
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    Details that are added onto a document.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  Adjustable Rate Mortgage (ARM)
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    A mortgage whose interest rate is changeable; it usually has a cap.
                    ARMs typically start with a low ‘teaser rate’ that that adjusts to the market rate after several years.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  Agency
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    A written or oral contract in which you authorize an agent to act on your behalf of in dealing with
                    a third party, such as a home buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  Agent
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    A person who is authorized to represent another person. Real estate agents must be licensed in each
                    state in which they do business. Typically, real estate agents are affiliated with a broker, an umbrella
                    real estate business that markets home buying and selling services under its brand. Most agents are self-employed.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  Agreement of Sale
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    A written purchase and sale agreement.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  Amortization
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    The act of paying off a mortgage with periodic payments of principal and interest. The
                    amortization schedule you shows you much of the amount you are paying goes to principal (the sale prices
                    of the house) and how much goes to interest (the cost of the mortgage, paid to the lender).
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="7">
                  Annual Percentage Rate (APR)
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    A yearly rate of interest that includes all the costs of acquiring the loan.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="8">
                  Appraisal
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    The estimated fair market value of a house by an authorized and licensed person.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="9">
                  Appraiser
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="9">
                  <Card.Body>
                    The authorized person who estimates the fair market value of a house. Appraisers are licensed by the
                    state in which they do business.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="10">
                  Assessed Value
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="10">
                  <Card.Body>
                    The value of a property for tax purposes, as determined by a municipal assessor. Assessments may or may
                    not reflect market value. Assessed value is not the same as the appraised value.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="11">
                  Assessed Value
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="11">
                  <Card.Body>
                    The official valuation of a property for tax purposes.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="12">
                  Assessment
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="12">
                  <Card.Body>
                    An estimate of a property’s value for tax purposes.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="13">
                  Balloon Mortgage
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="13">
                  <Card.Body>
                    A mortgage amortized over a period of years but whose final payment is due after a set number of payments.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="14">
                  Balloon Payment
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="14">
                  <Card.Body>
                    The final payment of a balloon mortgage.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="15">
                  Bill of Sale
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="15">
                  <Card.Body>
                    A contract that transfers of property from the seller to the buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="16">
                  Binder
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="16">
                  <Card.Body>
                    An agreement between a buyer and a seller that sets forth the conditions of a sale; shows the good faith
                    of both to have an attorney draw up a sale and – purchase contract Breach of Contract –
                    a failure to execute the terms of a contract.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="17">
                  Bridge Loan
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="17">
                  <Card.Body>
                    A loan that covers the period between the end of one loan and the beginning of another. If you bought a
                    new house before the old house sold, you might get a bridge loan to cover the period when you own two houses.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="18">
                  Broker
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="18">
                  <Card.Body>
                    A person licensed to deal in real estate matters.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="19">
                  Broker Pricing Opinion (BPO)
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="19">
                  <Card.Body>
                    Comparative Market Analysis.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="20">
                  Broom Clean
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="20">
                  <Card.Body>
                    The relatively clean condition of a house when given to a buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="21">
                  Building Code
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="21">
                  <Card.Body>
                    A set of laws to be followed in the construction and building business.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="22">
                  Building Permit
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="22">
                  <Card.Body>
                    Permission granted by local government to build on a property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="23">
                  Buyer’s Agent
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="23">
                  <Card.Body>
                    A person authorized to work on behalf of the buyer of a property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="24">
                  Capital Gain
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="24">
                  <Card.Body>
                    The increase in value of an asset; the profit from the sale of property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="25">
                  Clear Title
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="25">
                  <Card.Body>
                    A title to a piece of property that is free of liens or legal problems. The title is your proof
                    of ownership. A clear title means that there are no complications to that ownership, such as another
                    owner also claiming to own the property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="26">
                  Closing
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="26">
                  <Card.Body>
                    The final step in a real estate deal; money is given to the seller and title is given to the buyer and
                    the title transfers from the seller to the buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="27">
                  Closing Costs
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="27">
                  <Card.Body>
                    Fees and expenses associated with the transfer of ownership of a piece of property includes many
                    different fees; can often add up to a substantial amount.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="28">
                  Closing Statement
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="28">
                  <Card.Body>
                    The document that lists all financial data at time of closing.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="29">
                  Cloud on Title
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="29">
                  <Card.Body>
                    A claim on a piece of property that affects the title; can be settled by various actions. For example,
                    a builder might put a lien on the title if the property owner did not pay his bill to the builder.
                    Before the sale can close, the outstanding liens and any other complications to the title must be cleared up.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="30">
                  Commission
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="30">
                  <Card.Body>
                    A fee paid to a broker for transacting a real estate deal. The traditional 6 percent commission is
                    split evenly among the buyer’s agent, that agent’s broker, and the seller’s agent and that agent’s
                    broker. By using USRealty.com, you can decide how much commission you want to pay. USRealty.com
                    recommends compensating the buyer’s agent and buyer’s broker the traditional 3% commission so that
                    you attract buyers’ agents when marketing your house.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="31">
                  Commitment Letter
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="31">
                  <Card.Body>
                    A promise in writing by a lender for a specific amount of money over a specific period of time
                    with specific terms (for ex., interest rate).
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="32">
                  Comparables
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="32">
                  <Card.Body>
                    Properties similar in value to a property being sold; used for appraisal purposes. Usually these are
                    houses near by, in similar condition, and of a similar size.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="33">
                  Competitive Market Analysis (CMA)
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="33">
                  <Card.Body>
                    A comparison of recently sold homes that are roughly similar to each other in location, selling price,
                    etc. also known as comparative market analysis or Broker Pricing Opinion. CMAs are often offered for
                    free by listing agents as a way to get in the door and onto your couch. Their analysis helps you
                    understand how your house compares to recently sold houses, but don’t confuse the informal CMA with
                    an appraisal by a licensed appraiser or with the assessment completed by the municipal assessor.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="34">
                  Contingency
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="34">
                  <Card.Body>
                    A condition that must be met before a transaction is binding; for example, a house inspection must
                    be acceptable before a deal is binding.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="35">
                  Contract
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="35">
                  <Card.Body>
                    A legally binding agreement between a buyer and a seller.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="36">
                  Conventional Mortgage
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="36">
                  <Card.Body>
                    A type of financial loan made by an institution such as a bank without any government underwriting.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="37">
                  Counteroffer
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="37">
                  <Card.Body>
                    An offer made by a buyer to a seller for real property, usually a lower offer than the asking price.
                    For example, A rejects B’s price and offers a lower price, which is a counteroffer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="38">
                  Covenant
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="38">
                  <Card.Body>
                    A binding agreement between two parties.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="39">
                  Credit Report
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="39">
                  <Card.Body>
                    A report citing all debts and their repayments.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="40">
                  Cul-De-Sac
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="40">
                  <Card.Body>
                    A street closed at one end.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="41">
                  Curb Appeal
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="41">
                  <Card.Body>
                    The appearance of a house from the outside; for example, attractive landscaping, decorated door, etc.
                    ‘Curb appeal’ offers the first impression a buyer has of a house, so is considered an essential element
                    in marketing a house.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="42">
                  Debt-to-Income Ratio
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="42">
                  <Card.Body>
                    A percentage comparing expenses to your household income; for example, housing expenses might be 30% of income.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="43">
                  Deed
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="43">
                  <Card.Body>
                    A signed and executed document conveying title to a piece of property. The deed proves that you own the house.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="44">
                  Deposit
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="44">
                  <Card.Body>
                    Money given by the buyer to the seller to create a binding sale.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="45">
                  Disclosure
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="45">
                  <Card.Body>
                    The act of revealing any problems with a piece of real estate.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="46">
                  Discount Brokers
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="46">
                  <Card.Body>
                    Licensed brokers who usually settle for a lower commission; offer fewer services than full-service brokers
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="47">
                  Down Payment
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="47">
                  <Card.Body>
                    The difference between a mortgage amount and purchase price given to the seller by the buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="48">
                  Earnest Money
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="48">
                  <Card.Body>
                    Money given by the buyer to the seller to secure a deal; becomes part of the down payment if offer is accepted.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="49">
                  Easement
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="49">
                  <Card.Body>
                    An interest or right to land owned by another for a specified purpose. For example, a utility may have an easement.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="50">
                  Equity
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="50">
                  <Card.Body>
                    The value of a house minus the amount owed on the mortgage. For example, Max owes $50,000 on a
                    mortgage with a fair market value of $200,000; his equity is $150,000.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="51">
                  Escrow
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="51">
                  <Card.Body>
                    Usually money held in trust by a third party until a specific transaction has been completed.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="52">
                  Fair Housing Act
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="52">
                  <Card.Body>
                    This Federal law bans discrimination (race, color, sexual orientation, religion, etc.) in the sale
                    or rental of real estate. When you market your house you must comply with the Fair Housing Act.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="53">
                  Fannie Mae or Federal National Mortgage Association
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="53">
                  <Card.Body>
                    The largest supplier of home mortgage loans to retail lenders. You get a mortgage through a bank,
                    credit union or other type of mortgage lender, and chances are that the lender then sells the loan
                    to Fannie Mae so it can quickly get the money back and lend it again.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="54">
                  Financing Costs
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="54">
                  <Card.Body>
                    Any fees incurred in borrowing money for a real estate transaction. For example, interest, points, and so on.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="55">
                  Fixture
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="55">
                  <Card.Body>
                    Personal property attached to real property that is passed on with the sale of the house
                    unless specifically excluded in the sale. Traditionally, anything nailed down is considered to be
                    a fixture, such as light fixtures, faucets, wall-to-wall carpet and built-in furniture. The sale
                    contract specifies which appliances and other fixtures are included in the sale. When in doubt, list
                    any amenity in the contract as part of the sale.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="56">
                  Foreclosure
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="56">
                  <Card.Body>
                    A legal proceeding by which a property owner loses interest in a mortgaged property through default on a loan.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="57">
                  FSBO
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="57">
                  <Card.Body>
                    “For sale by owner,” or selling the house on your own without using an agent or broker.  USRealty.com
                    fills the role of the listing broker to list someone who was formerly a FSBO on MLS.  We recommend
                    that sellers offer a buyer’s broker a 2.5% or 3% commission to provide an incentive for them to bring
                    their buyers to your house.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="58">
                  Home Inspection
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="58">
                  <Card.Body>
                    A structural and mechanical assessment of a house prior to the closing. Inspections should be conducted
                    by a licensed home inspector. Home inspectors are not municipal building inspectors.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="59">
                  Home Owners Association (HOA)
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="59">
                  <Card.Body>
                    A group of homeowners who, through the collection of association fees, manage the common areas. Many
                    HOAs are corporations with the right to put a lien on a member’s house if that member does not pay
                    his fees, depending on state law.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="60">
                  Home Warranty
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="60">
                  <Card.Body>
                    A guarantee of the quality of construction offered by the builder to a buyer.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="61">
                  House Flyer
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="61">
                  <Card.Body>
                    A paper or digital one or two-page advertisement that details the features and specifications of
                    the property, such address, size, number and size of rooms, amenities, and description.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="62">
                  Interest Rate
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="62">
                  <Card.Body>
                    The percentage of the amount borrowed for a mortgage charged by the lender to the borrower.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="63">
                  Jumbo Loan
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="63">
                  <Card.Body>
                    A loan of usually more than $429,00; not all lenders engage in jumbo loans; interest rates for such a
                    loan may be higher than the usual rates. Jumbo loans are defined by Fannie Mae and vary by market.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="64">
                  Lead Paint Disclosure
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="64">
                  <Card.Body>
                    The disclosure of the possibility of the presence of lead paint in a structure; lead paint is hazardous
                    to health and may be present in buildings constructed prior to 1978.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="65">
                  Lease Option
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="65">
                  <Card.Body>
                    A lease in which the tenant has the right to purchase real property as stipulated in the terms of
                    the lease. Also known as rent-to-own.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="66">
                  Legal Description
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="66">
                  <Card.Body>
                    A description of the exact boundaries of a piece of real property; it is a description recognized by
                    law and is used to locate a specific parcel of land. The legal description is usually written by a surveyor.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="67">
                  Lien
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="67">
                  <Card.Body>
                    A legal claim against a real property; security until an obligation such as taxes, debts, and so on are paid.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="68">
                  Loan
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="68">
                  <Card.Body>
                    Money that is borrowed and usually repaid with interest.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="69">
                  Loan to Value
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="69">
                  <Card.Body>
                    The amount of a loan compared to the appraised value of a property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="70">
                  Lot and Block
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="70">
                  <Card.Body>
                    A numbered method of identifying a parcel of land.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="71">
                  Lot and Block System
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="71">
                  <Card.Body>
                    A system of identifying real property by lot and block numbers on a recorded subdivision map.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="72">
                  Marketable Title
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="72">
                  <Card.Body>
                    A clear, saleable title to a piece of real property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="73">
                  Mechanic’s Lien
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="73">
                  <Card.Body>
                    A lien enforceable by legal proceedings favorable to contractors, engineers, laborers, and others who
                    had worked on the property, in an effort to complete payment for labor and/or materials.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="74">
                  Metes-and-Bounds Description
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="74">
                  <Card.Body>
                    An accurate description of land boundaries (metes=length; bounds=boundaries).
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="75">
                  Mill
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="75">
                  <Card.Body>
                    A unit of measure (one-tenth of 1%) used in determining the amount of annual taxes due on real
                    property. Taxes are calculated on the millage rate.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="76">
                  Mortgage
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="76">
                  <Card.Body>
                    A legal document whereby a parcel of real property is used as security for the repayment of a debt. If
                    the loan is not repaid on time, the lender has the right to call the loan and sell the property to pay
                    off the mortgage.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="77">
                  Mortgage Broker
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="77">
                  <Card.Body>
                    An agent who brings together a borrower and lender for the purpose of earning a commission
                    Multiple-Listing Service (MLS), a service that lists for its members all properties for sale; provides
                    wide market exposure; gives all agents the opportunity to sell a property and share in the commission.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="78">
                  Offer
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="78">
                  <Card.Body>
                    A presentation of a price by a buyer to a seller for acceptance.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="79">
                  Open House
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="79">
                  <Card.Body>
                    A specified time during which a house is open to prospective buyers without an appointment.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="80">
                  Origination Fee
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="80">
                  <Card.Body>
                    A fee charged by the lender for the preparation of a mortgage; usually called “points”.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="81">
                  Personal Property
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="81">
                  <Card.Body>
                    Any property which is not real property (that is, whatever is not attached to the property itself) and
                    is not a fixture. Depending on local custom, some items, such as appliances and grills, may or may
                    not count as personal property. When in doubt, list in the contract any item you want included in the sale.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="82">
                  Point
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="82">
                  <Card.Body>
                    Money paid to a lender for making a loan. Each point is equal to 1% of the loan amount.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="83">
                  Power of Attorney
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="83">
                  <Card.Body>
                    A legal document whereby a person gives another the authority to act on his/her behalf.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="84">
                  Pre-approved
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="84">
                  <Card.Body>
                    A method by which the buyer can show the agent and/or seller that he can qualify for a specific loan amount.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="85">
                  Prepayment Clause
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="85">
                  <Card.Body>
                    A -stipulation in a contract that allows the borrower to make a loan payment in advance of the due date.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="86">
                  Prepayment Penalty
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="86">
                  <Card.Body>
                    A fee payable to the lender by the borrower for prepayment of a loan.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="87">
                  Prequalified
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="87">
                  <Card.Body>
                    An assessment of a buyer’s ability to secure a loan.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="88">
                  Principal
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="88">
                  <Card.Body>
                    The amount of money borrowed or the amount due on a mortgage.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="89">
                  Private Mortgage Insurance (PMI)
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="89">
                  <Card.Body>
                    Insurance required by a lender when the down payment is less than 20% of the cost of the property. When
                    the mortgage is 80% of more of the purchase agreement, the lender requires PMI to ensure that it is
                    paid back if you die or default.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="90">
                  Property Tax
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="90">
                  <Card.Body>
                    A government tax on privately-owned property based on market value.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="91">
                  Purchase Agreement
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="91">
                  <Card.Body>
                    A contract between a buyer and a seller setting forth the sale and purchase conditions of real property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="92">
                  Purchase Money Mortgage
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="92">
                  <Card.Body>
                    Mortgage given by the seller to the buyer as part of the purchase price.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="93">
                  Radon
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="93">
                  <Card.Body>
                    A radioactive, odorless gas sometimes found in a house which can be harmful to health.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="94">
                  Rate Lock
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="94">
                  <Card.Body>
                    The lender’s commitment to a borrower for a specific interest rate charged on a mortgage loan.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="95">
                  Realtor
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="95">
                  <Card.Body>
                    A licensee who is either a broker or salesperson and who is a member local, state and National
                    Association of Realtors. Not all realty agents are Realtors but all Realtors are realty agents.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="96">
                  Recision
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="96">
                  <Card.Body>
                    Cancellation.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="97">
                  Release
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="97">
                  <Card.Body>
                    A legal instrument releasing a property from any liens.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="98">
                  Sales Comparison Approach
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="98">
                  <Card.Body>
                    One of several appraisal methods which estimates the value of a house by comparing it to
                    recently-sold comparable properties.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="99">
                  Sales Contract
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="99">
                  <Card.Body>
                    A contract agreement between a buyer and a seller specifying all the terms and conditions of the sale of real estate.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="100">
                  Security
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="100">
                  <Card.Body>
                    Real estate pledged as collateral for a loan.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="101">
                  Seller Financing
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="101">
                  <Card.Body>
                    A financial arrangement whereby a seller provides financing for the buyer, bypassing traditional mortgage lenders.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="102">
                  Seller’s Disclosure
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="102">
                  <Card.Body>
                    A seller’s accurate and honest disclosure of the condition of the real property. The legal liability
                    for full and honest disclosure of the property’s history and condition varies by state.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="103">
                  Septic System
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="103">
                  <Card.Body>
                    A sewage system using pipes to drain waste into a below ground septic tank.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="104">
                  Settlement Statement
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="104">
                  <Card.Body>
                    A statement of all costs of a real estate sale; generally given at the closing.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="105">
                  Sign-in Sheets
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="105">
                  <Card.Body>
                    A listing of interested buyers at an open house.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="106">
                  Single Family
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="106">
                  <Card.Body>
                    A house designed for use by a one family.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="107">
                  Time is of the Essence
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="107">
                  <Card.Body>
                    A contract clause specifying that a condition be met within a specified period of time.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="108">
                  Title
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="108">
                  <Card.Body>
                    Evidence to the right of ownership of a piece of real estate.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="109">
                  Title Insurance
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="109">
                  <Card.Body>
                    Insurance against loss from title problems or claims against the property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="110">
                  Title Search
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="110">
                  <Card.Body>
                    A search of all records to determine the ownership of a real property.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="111">
                  Walk-through Inspection
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="111">
                  <Card.Body>
                    An inspection of the premises being bought, usually on the day of closing, to make sure all is in
                    working order according to the terms of the sale.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="112">
                  Zoning
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="112">
                  <Card.Body>
                    Regulations by local government of the size, height, etc. of buildings and the use of land.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Container>
        </CustomAccordion>
        <Footer />
      </div>
    )
  }
}

export default Glossary;
