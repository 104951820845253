import React from 'react';
import { connect } from 'react-redux';
import videobanner from '../../../Assets/Images/video-banner.jpg';
import { VideoDiv, Container, StaticContentWrapper, Quote } from '../../../Assets/Style/commonStyleComponents';
import { videoUrl } from '../../../Constants/Images';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';

class HowItWork extends React.Component {

  constructor(props) {
    super(props)
    this.demoVideoRef = React.createRef();
    this.state = {
      isVideoPlayed: false,
    };
  }

  handlePlayVideo = () => {
    this.setState({
      isVideoPlayed: true
    }, () => {
      this.demoVideoRef.current.play();
    })
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <StaticContentWrapper className="how-it-works-wrapper">
          <VideoDiv>
            <video ref={this.demoVideoRef} className="videoWrapper" src={`${videoUrl}`} controls></video>
            <div className={this.state.isVideoPlayed ? 'display-none' : ''} onClick={() => this.handlePlayVideo()}>
              <img src={videobanner} alt="video-banner" className="video-banner" />
              <div className="video-text-with-btn">
                <p className="video-text">"Don't give 6% of your home's sale price to an agent - sell DIY and save!"</p>
                <p className="video-text small-font">*(Commissions are negotiable.)</p>
                <span className="play-btn"><i className="fas fa-play-circle"></i></span>
              </div>
            </div>
          </VideoDiv>
          <Container>
            <Quote className="for-how-it-works">
              "Unreal Estate lists your house on all the most powerful real estate websites, including your local multiple listing service, for free. Sell your house your way,
              choosing the commission you want to pay."
            </Quote>
            <p>Unreal Estate lets you list your home to all the online sites where buyers look. You start
              for free and pay no more than a 3% commission — and only when your house sells.</p>
            <p>Download our <a href="guide-to-home-selling-book">free ebook</a> for everything you need to know about successful, low-cost selling.</p>
            <p>Make the Unreal Estate system work for you!</p>
            <ul>
              <li>Complete the simple, easy Unreal Estate listing form.</li>
              <li>Manage marketing and showings directly.</li>
              <li>Collaborate with local buyer’s agents, who still receive their traditional commission.</li>
              <li>Track the interest in your house and market accordingly. You can cancel your Unreal Estate listing at any time.</li>
              <li>Negotiate with a potential buyer.</li>
              <li>Work with the buyer’s agent (only where permitted by law) and, potentially, a real estate lawyer, to complete the paperwork.</li>
              <li>Close the deal and keep thousands of dollars in home equity.</li>
              <li>*Subject to, and compliant with, all state minimum service regulations.</li>
            </ul>
          </Container>
        </StaticContentWrapper>
        <Footer />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails
  };
}

export default connect(mapStateToProps)(HowItWork);
