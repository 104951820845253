import React from 'react';

export const FiltersIcon = (iconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.37859 8.11254C4.07582 8.10548 2.78338 8.08958 1.4806 8.08253C1.28478 8.08012 1.32052 7.81129 1.38573 7.70439C1.45417 7.54367 1.62052 7.38416 1.81635 7.38656L3.24587 7.40415C3.42575 7.40637 5.12842 7.33089 5.34587 7.33357C6.63819 7.34947 7.9412 7.35652 9.24385 7.36357L9.39792 7.57191C9.37466 7.79601 9.19152 8.072 8.91736 8.06863L7.48784 8.05104C6.97331 8.04471 5.60932 8.11379 5.37859 8.11254Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M10.863 3.44603C9.56019 3.43898 8.26775 3.42308 6.96498 3.41603C6.76916 3.41362 6.8049 3.14479 6.87011 3.03788C6.93855 2.87716 7.1049 2.71765 7.30072 2.72006L8.73024 2.73765C8.91013 2.73986 10.6128 2.66439 10.8302 2.66706C12.1226 2.68296 13.4256 2.69002 14.7282 2.69707L14.8823 2.9054C14.859 3.12951 14.6759 3.4055 14.4017 3.40212L12.9722 3.38454C12.4577 3.3782 11.0937 3.44728 10.863 3.44603Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M3.15626 3.33364C2.61775 3.32761 2.0835 3.314 1.54498 3.30797C1.46404 3.30591 1.47881 3.07586 1.50576 2.98438C1.53406 2.84684 1.60282 2.71034 1.68377 2.7124L2.27468 2.72746C2.34904 2.72935 3.05286 2.66476 3.14274 2.66705C3.67694 2.68066 4.21556 2.68669 4.75402 2.69273L4.81771 2.87101C4.8081 3.06278 4.73239 3.29896 4.61906 3.29607L4.02815 3.28102C3.81546 3.2756 3.25164 3.33471 3.15626 3.33364Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M13.1563 8.00015C12.6177 7.99411 12.0835 7.98051 11.545 7.97447C11.464 7.97241 11.4788 7.74236 11.5058 7.65088C11.5341 7.51335 11.6028 7.37685 11.6838 7.37891L12.2747 7.39396C12.349 7.39585 13.0529 7.33127 13.1427 7.33356C13.6769 7.34716 14.2156 7.3532 14.754 7.35923L14.8177 7.53751C14.8081 7.72928 14.7324 7.96546 14.6191 7.96257L14.0282 7.94752C13.8155 7.94211 13.2516 8.00122 13.1563 8.00015Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M3.15626 12.6667C2.61775 12.6606 2.0835 12.647 1.54498 12.641C1.46404 12.6389 1.47881 12.4089 1.50576 12.3174C1.53406 12.1799 1.60282 12.0434 1.68377 12.0454L2.27468 12.0605C2.34904 12.0624 3.05286 11.9978 3.14274 12.0001C3.67694 12.0137 4.21556 12.0197 4.75402 12.0257L4.81771 12.204C4.8081 12.3958 4.73239 12.632 4.61906 12.6291L4.02815 12.614C3.81546 12.6086 3.25164 12.6677 3.15626 12.6667Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M4.81772 3.00539C4.82376 2.46687 4.83736 1.93262 4.8434 1.3941C4.84546 1.31316 5.07551 1.32793 5.16699 1.35489C5.30452 1.38318 5.44102 1.45194 5.43896 1.53289L5.42391 2.1238C5.42202 2.19816 5.4866 2.90198 5.48432 2.99186C5.47071 3.52606 5.46467 4.06468 5.45864 4.60314L5.28036 4.66683C5.08859 4.65722 4.85241 4.58151 4.8553 4.46819L4.87035 3.87727C4.87576 3.66459 4.81665 3.10076 4.81772 3.00539Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M11.4857 7.67189C11.4917 7.13337 11.5053 6.59912 11.5114 6.06061C11.5134 5.97966 11.7435 5.99443 11.835 6.02139C11.9725 6.04968 12.109 6.11845 12.1069 6.19939L12.0919 6.7903C12.09 6.86466 12.1546 7.56848 12.1523 7.65837C12.1387 8.19256 12.1326 8.73118 12.1266 9.26965L11.9483 9.33333C11.7566 9.32372 11.5204 9.24801 11.5233 9.13469L11.5383 8.54378C11.5437 8.33109 11.4846 7.76727 11.4857 7.67189Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M4.81772 12.3389C4.82376 11.8004 4.83736 11.2661 4.8434 10.7276C4.84546 10.6467 5.07551 10.6614 5.16699 10.6884C5.30452 10.7167 5.44102 10.7854 5.43896 10.8664L5.42391 11.4573C5.42202 11.5317 5.4866 12.2355 5.48432 12.3254C5.47071 12.8596 5.46467 13.3982 5.45864 13.9366L5.28036 14.0003C5.08859 13.9907 4.85241 13.915 4.8553 13.8017L4.87035 13.2108C4.87576 12.9981 4.81665 12.4343 4.81772 12.3389Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
      <path d="M10.863 12.779C9.56019 12.772 8.26775 12.7561 6.96498 12.749C6.76916 12.7466 6.8049 12.4778 6.87011 12.3709C6.93855 12.2102 7.1049 12.0507 7.30072 12.0531L8.73024 12.0707C8.91013 12.0729 10.6128 11.9974 10.8302 12.0001C12.1226 12.016 13.4256 12.023 14.7282 12.0301L14.8823 12.2384C14.859 12.4625 14.6759 12.7385 14.4017 12.7351L12.9722 12.7175C12.4577 12.7112 11.0937 12.7803 10.863 12.779Z" fill="#242B26" stroke="#242B26" strokeWidth="0.4" />
    </svg>
  )
};
