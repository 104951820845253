export const regexPattern = {
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])^[\S]+$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  STRING_CHECK: /[^\s]+/,
  WEBSITE: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  ADD_HTTP: /^(?:f|ht)tps?\:\/\//,
  NUMBER_ONLY: /^[0-9]+$/,
  NUMBER_ONLY_WITH_DECIMAL: /^\d{0,10}(\.\d{0,2})?$/,
};

export const contentType = {
  applicationJson: 'application/json',
};

export const formLayoutType = {
  HTML: 'Basic Form',
  Image: 'Fields Overlaid on Image',
};

export const roleType = {
  RETAIL: 'Retail',
  NetworkUser: 'NetworkUser',
  ADMIN: 'Administrator',
  WHOLESALE: 'Wholesale/NetworkManager',
  LISTING_PROCESSOR: 'ListingProcessor',
  INSTITUTIONAL: 'InstitutionalManager',
  WHOLESALE_CUSTOMER: 'WholesaleCustomer',
  Builder:'Builder',
  Flipper:'Flipper',
  Auctioneer:'Auctioneer',
  ManagingBroker:'ManagingBroker',
  ConciergePaid:'ConciergePaid',
  ConciergeFree:'ConciergeFree',
  Landlord:'Landlord',
  SuperAdmin:'SuperAdministrator',
  OfficeManager:'OfficeManager'
};

export const Roles = {
  RETAIL: 1,
  WHOLESALE_MANAGER: 2,
  INSTITUTIONAL_MANAGER: 3,
  WHOLESALE_CUSTOMER: 8,
  LISTING_PROCESSOR: 11,
  MANAGING_BROKER: 13,
  CONCIERGE_FREE: 14,
  NETWORK_USER: 16,
  OFFICE_MANAGER: 19,
	ADMINISTRATOR: 20,
  SUPER_ADMINISTRATOR: 100
};

export const apiMethodType = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};

export const passwordType = {
  oldPassword: 'oldPassword',
  newPassword: 'newPassword',
  confirmPassword: 'confirmPassword',
};

export const ServiceResultCode = {
  Created: 12,
  Updated: 11,
  Deleted: 10,
  Success: 1,
  Error: 0,
  ServerError: -1,
  NoPermission: -2,
  EmptyModel: -10,
  InvalidModel: -11,
  NotFound: -12,
};

export const defaultPlanAddress = {
  county: 'Clark',
  state: 'NV',
  zip: '89052',
};

export const BlockAddress = ["AS","FM","GU","MH","MP","PW","PR","VI","AA","AE","AP"];

export const propertyListingTitles = {
  1: 'Property Search - Step 1 | Unreal Estate',
  2: 'Set Price - Step 2 | Unreal Estate',
  3: 'Property Basics - Step 3 | Unreal Estate',
  4: 'Property Details - Step 4 | Unreal Estate',
  5: 'Property Photos - Step 5 | Unreal Estate',
  6: 'Title & Escrow - Step 6 | Unreal Estate',
  7: 'Plans - Step 7 | Unreal Estate',
  8: 'Payment - Step 8 | Unreal Estate',
  9: 'Are you buying | Unreal Estate',
  10: 'Sign Your Contracts | Unreal Estate',
}

export const staticMessages = {
  login: 'Sign in Successfully.',
  logout: 'Logout Successfully.',
  mailSent: 'Mail sent successfully!',
  apiError: 'Some error occurred',
  deleteConfirmation: `Are you sure you want to delete this`,
  activateConfirmation: `Are you sure you want to activate this`,
  deactivateConfirmation: `Are you sure you want to deactivate this`,
  copyLinkSuccess: 'Copied to clipboard successfully.',
  resendInvite: 'Invite sent successfully!',
  networkError: 'Please check your internet connection or try again later',
  mlsSearchSuccess: 'MLS found',
  mlsSearchFailure: 'MLS not found',
  mlsSearchError: 'MLS search error',
  showingRequestSucess: 'Showing request sent successfuly!',
  showingRequestError: 'Showing request sent error.',
  offerSubmitSuccess: 'Offer submited successfuly!',
  offerSubmitError: 'Offer submit error.',
  uploadOfferSuccess: 'File uploaded successfuly!',
  passwordResetLinkSent: 'Password reset link sent to user email',
  emailConfirmed: 'Your email has been successfully verified!',
  emailConfirmationSent: 'Email confirmation was sent',
  searchingInactiveListings: 'We’re sourcing the latest data about your property from public records - ready under one minute.',
  logInToSeeListing: 'You must be logged in to edit this listing.',
};

export const validationMessages = {
  required: 'This field cannot be blank.',
  valueRequired: 'Please enter a valid value',
  name: 'Please enter your name.',
  abbreviation: 'Please enter your abbreviation.',
  firstName: 'Please enter your first name.',
  lastName: 'Please enter your last name.',
  phoneNumber: 'Please enter a valid phone number.',
  zipCode: 'Please enter a valid zip code.',
  email: 'Please enter your email.',
  managingBrokerLicense: 'Please enter broker license.',
  validEmail: 'Please enter a valid email.',
  password: 'Please enter a password.',
  emailNotMatch: 'Email addresses need to match.',
  passwordNotMatch: 'Passwords need to match.',
  pleaseConfitrmPassword: 'Please confirm password.',
  categoryName: 'Please enter your category name.',
  companyName: 'Please enter your company name.',
  characterLimitMsg: 'Please use at least (8) characters',
  categoryRequired: 'Please select a category.',
  stateRequired: 'Please select your state.',
  abbreviationRequired: 'Please select a abbreviation.',
  mlsInfoRequired: 'Please select a MLS info.',
  sectionLayoutRequired: 'Please select a section layout.',
  formTypeRequired: 'Please select a form type.',
  validPassword:
  `Password should have at least 1 upper case, 1 lower case, 1 special character (!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~) and no spaces.`,
  productType: 'Please select product type.',
  title: 'This field cannot be blank.',
  validWebsite: 'Please enter a valid website url.',
  requireMls: 'Please select MLS',
  voucherRequired: 'Please select a voucher template.',
  companyRequired: 'Please select a company',
  productRequired: 'Please select product type.',
  numericValueRequired: 'Please enter a numeric value',
  userValueRequired: 'Please select a user',
  sameEmailsSellerNotification: 'Agent and "send copy to" emails must be different',
};

export const formType = {
  listingForm: 'listing form',
  agencyDisclosure: 'agency-disclosure',
  inputSheet: 'input sheet',
};

export const formLabelValue = {
  additional: 'Additional',
  propertyData: 'Property Data',
  listingContract: 'Listing Contract',
  agencyDisclosure: 'Agency Disclosures',
  propertyDisclosure: 'Disclosure',
};


export const editListingValue = {
  oneOwner: 'one owner on deed',
  twoOwner: 'two owners on deed',
  threeOwner: 'three owners on deed',
  sellersig2: 'sellersig2',
  sellersig3: 'sellersig3',
  initials2: 'initials2',
  date1: 'date1',
  date2: 'date2',
  date3: 'date3',
  sellername:'sellername',
  div:'div',
  strong:'strong',
  brokerupfrontfee:'brokerupfrontfee',
  broker_upfront_fee:'broker-upfront-fee',
  string:'string',
};

export const cursorStyles = {
  option: (styles) => {
    return {
      ...styles,
      cursor: "pointer"
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 999
    }
  }
};

export const dashboardTabs = {
  listing: 'listing',
  billing: 'billing',
  savedSearches: 'savedSearches',
  myFavorites: 'myFavorites',
  calendar: 'calendar',
  documents: 'documents',
  billingInformation: 'billingInformation',
  addListVoucher: 'addListVoucher',
  voucher: 'voucher',
  dashboardCoverage: 'dashboardCoverage',
  addVoucher: 'addVoucher',
  networkUser: 'networkUser',
  mortgageAdLink: 'mortgageAdLink',
  intelligenceReports: 'intelligenceReports',
};

export const tableItemsPerPage = 10;
export const sortOrder = {
  asc: 'ascend',
  desc: 'descend',
};

export const pageSizeOptions = ['10', '20', '50', '100', '150'];

export const roleTypesConstant = [
  { value: '1', label: 'Retail' },
  { value: '2', label: 'Wholesale/Network Manager' },
  { value: '3', label: "Institutional Manager"},
  { value: '4', label: 'Builder' },
  { value: '5', label: 'Flipper' },
  { value: '6', label: 'Auctioneer' },
  { value: '7', label: 'Landlord' },
  { value: '8', label: "Wholesale Customer"},
  { value: '11', label: 'Listing Processor' },
  { value: '19', label: 'Office Manager' },
  { value: '20', label: 'Administrator' },
  { value: '100', label: 'Super Administrator' },
  { value: '16', label: 'Network User' },
];

export const statusesTypesConstant = [
  { value: '1', label: 'Active' },
  { value: '0', label: 'Inactive' },
];

export const reactSelectFilters = {
  ignoreCase: true,
  ignoreAccents: false,
  trim: true,
  // Note:- comment this code for show search result with single char also
  // matchFrom: matchFrom as matchFromType,
};

export const filterTypeContants = {
  rolesFilter: {
    type: 'roles',
    key: 'userTypeId',
    selectedKey: 'selectedRole',
  },
  productTypeFilter: {
    type: 'productTypes',
    key: 'productType',
    selectedKey: 'productType',
  },
  userTypeFilter: { type: 'user', key: 'user', selectedKey: 'user' },
  statusTypeFilter: { type: 'status', key: 'status', selectedKey: 'status' },
  zipCodeTypeFilter: { type: 'zip', key: 'zip', selectedKey: 'zip' },
  mlsTypeFilter: { type: 'mls', key: 'mls', selectedKey: 'mls' },
  formTypeFilter: {
    type: 'formTypeId',
    key: 'formTypeId',
    selectedKey: 'formTypeId',
  },
  sectionTypeFilter: {
    type: 'sectionTypeId',
    key: 'sectionTypeId',
    selectedKey: 'sectionTypeId',
  },
  mlsFormTypeFilter: {
    type: 'formTypes',
    key: 'formTypeId',
    selectedKey: 'formTypeId',
  },
  submissionTypeFilter: {
    type: 'submissionStatuses',
    key: 'formSubmissionStatusId',
    selectedKey: 'formSubmissionStatusId',
  },
  states: {
    type: 'state',
    key: 'state',
    selectedKey: 'state'
  },
  companies: {
    type: 'company',
    key: 'company',
    selectedKey: 'company'
  },
  counties: {
    type: 'county',
    key: 'county',
    selectedKey: 'county'
  },
  inputZip: {
    type: 'inputZip',
    key: 'zip',
    selectedKey: 'zip'
  },
  voucherTemplateFilter: {
    type: 'templateType',
    key: 'template',
    selectedKey: 'templateType'
  },
  mlsIdTypeFilter: { type: 'mlsId', key: 'mlsId', selectedKey: 'mlsId' },
  officeStatusTypeFilter: { type: 'officeStatus', key: 'officestatus', selectedKey: 'officeStatus' },
  officeAssignedTypeFilter: { type: 'officeAssign', key: 'officeassigned', selectedKey: 'officeAssign' },
  customStateTypeFilter: { type: 'customState', key: 'state', selectedKey: 'customState' },
  createdByTypeFilter: { type: 'createdBy', key: 'createdBy', selectedKey: 'createdBy' },
  voucherTempTypeFilter: { type: 'template', key: 'template', selectedKey: 'voucherTemp' },
  loggedInUserTypeFilter: { type: 'loggedInUser', key: 'loggedInUser', selectedKey: 'loggedInUser' },
  formStatusTypeFilter: { type: 'formStatus', key: 'formStatus', selectedKey: 'formStatus' },
  lastModifiedFromTypeFilter: { type: 'lastModifiedFrom', key: 'lastModifiedFrom', selectedKey: 'lastModifiedFrom' },
  lastModifiedToTypeFilter: { type: 'lastModifiedTo', key: 'lastModifiedTo', selectedKey: 'lastModifiedTo' },
  dateOrderedFromTypeFilter: { type: 'dateOrderedFrom', key: 'dateOrderedFrom', selectedKey: 'dateOrderedFrom' },
  dateOrderedToTypeFilter: { type: 'dateOrderedTo', key: 'dateOrderedTo', selectedKey: 'dateOrderedTo' },
  orderIdTypeFilter: { type: 'orderId', key: 'orderId', selectedKey: 'orderId'}
};

export const alphaNumericChar = {
  numberChars: '0123456789',
  specialChars: '!@#$',
  upperChars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  lowerChars: 'abcdefghiklmnopqrstuvwxyz',
};

export const companyTitleWidth = 400;
const duplicateLiteral = {
  mlsCategories: 'mls-categories',
  mlsAbbreviation: 'mls-abbreviation',
}
export const commonAllowedPaths = [
  'users',
  'products',
  'company',
  duplicateLiteral.mlsCategories,
  duplicateLiteral.mlsAbbreviation,
  'properties',
  'listings',
  'mls-submission',
  'mls-coverage',
  'voucher-template',
  'landing',
  'voucher-listing',
];
export const processorNotAllowedPaths = [
  'products',
  'company',
  'listings',
  duplicateLiteral.mlsCategories,
];
export const entityTypes = {
  user: 'user',
  users: 'users',
  products: 'products',
  cashOffers: 'cash-offers',
  listings: 'listings',
  orders: 'orders',
  company: 'company',
  titleCompany: 'title-company',
  categories: 'mls-categories',
  category: 'category',
  abbreviation: 'mls-abbreviation',
  addAbbreviation: 'abbreviation',
  properties: 'properties',
  voucherProperties:'voucherProperties',
  form: 'form',
  property: 'property',
  mlsSubmission: 'mls-submission',
  mlsCoverage: 'mls-coverage',
  coverage: 'coverage',
  voucherTemplate: 'voucher-template',
  template: 'template',
  landing: 'landing',
  voucherListing: 'voucher-listing',
  voucher: 'voucher',
  dashboardCoverage: 'dashboardCoverage',
  networkUser: 'networkUser',
  addVoucher: 'addVoucher',
  resendInvite: 'resendInvite',
  toggleVoucher: 'toogleVoucher',
  mlsPreview: 'mls-preview',
  overlayForms: 'forms',
  buyerLeads: 'buyer-leads',
};

export const adminPageTitles = {
  'title-company': 'Title-Company | Unreal Estate',
  'mls-abbreviation': 'Info-Admin | Unreal Estate',
  users: 'Users-Admin | Unreal Estate',
  products: 'Products-Admin | Unreal Estate',
  listings: 'Listing Forms-Admin | Unreal Estate',
  orders: 'Orders-Admin | Unreal Estate',
  company: 'Companies-Admin | Unreal Estate',
  'mls-categories': 'Categories-Admin | Unreal Estate',
  properties: 'Properties-Admin | Unreal Estate',
  'mls-submission': 'Submissions-Admin | Unreal Estate',
  'mls-coverage': 'Coverage-Admin | Unreal Estate',
  'voucher-template': 'Templates-Admin | Unreal Estate',
  landing: 'Landing Page-Admin | Unreal Estate',
  'voucher-listing': 'Vouchers-Admin | Unreal Estate',
  addProperty: 'Add a Property-Admin | Unreal Estate',
  editProperty: 'Edit a Property-Admin | Unreal Estate',
  addUser: 'Add a User-Admin | Unreal Estate',
  editUser: 'Edit a User-Admin | Unreal Estate',
  addCompany: 'Add a Company | Unreal Estate',
  editCompany: 'Edit a Company | Unreal Estate',
  addMLSInfo: 'Add MLS Info | Unreal Estate',
  editMLSInfo: 'Edit MLS Info | Unreal Estate',
  addForm: 'Add a Form | Unreal Estate',
  editForm: 'Edit a Form | Unreal Estate',
  addCategory: 'Add a Category | Unreal Estate',
  editCategory: 'Edit a Category | Unreal Estate',
  addMLSCoverage: 'Add MLS Coverage | Unreal Estate',
  editMLSCoverage: 'Edit MLS Coverage | Unreal Estate',
  addProduct: 'Add a Product | Unreal Estate',
  editProduct: 'Edit a Product | Unreal Estate',
  addVoucherTemplate: 'Add a Voucher Template | Unreal Estate',
  editVoucherTemplate: 'Edit a Voucher Template | Unreal Estate',
}


export const customerEntityTypes = {
  listings: 'listings',
  voucher: 'voucher',
  dashboardCoverage: 'dashboardCoverage',
}
export const customerTypeList = [
  { key: 1, value: 'Existing user on the website' },
  { key: 2, value: 'Generate new account from contact info' },
];

export const dateFormat = {
  SUBMISSION: 'YYYY-MM-DD HH:MM:SS',
  DATE_WITH_YEAR: 'MMM DD, YYYY',
  DATEMODIFIEDFROMAT: 'MM/DD/YY hh:mm A',
  DAYS_HOURS_MINUTES: 'DD/hh:mm',
  DATE: 'YYYY-MM-DD',
  DATE_TIME: 'YYYY-MM-DD HH:mm',
};

export const propertyAssigendList = [
  { key: 1, value: 'Unassigned' },
  { key: 2, value: 'Assigned-Betty' },
  { key: 3, value: 'Assigned-Jeni' },
  { key: 4, value: 'Assigned-Dana' },
  { key: 5, value: 'Assigned-Katie' },
  { key: 6, value: 'Assigned-Michelle' },
  { key: 7, value: 'Assigned-1' },
  { key: 8, value: 'Assigned-2' },
  { key: 9, value: 'Assigned-3' },
  { key: 10, value: 'Assigned-5' },
  { key: 11, value: 'Assigned-6' },
  { key: 12, value: 'Assigned-4' },
];

export const propertyStatusList = [
  { key: 1, value: 'New' },
  { key: 2, value: 'Unassigned' },
  { key: 3, value: 'Processing' },
  { key: 4, value: 'Entering MLS' },
  { key: 5, value: 'Waiting on info' },
  { key: 6, value: 'Listed' },
];

export const listingStatuses = [
  { label:'Draft', value: 'Draft' },
  { label:'New', value: 'New' },
  { label:'Processing', value: 'Processing' },
  { label:'Active', value: 'Active' },
  { label:'Pending', value: 'Pending' },
  { label:'Cancelled', value: 'Cancelled' },
  { label:'Sold', value: 'Sold' },
  { label:'Expired', value: 'Expired' },
];

export const productTypeList = {
  listing: { id: 1, value: 'Listing' },
  digital: { id: 2, value: 'Digital' },
  physical_shipped: { id: 3, value: 'Physical Shipped' },
  concierge: { id: 4, value: 'Concierge' },
  network: { id: 5, value: 'Network' },
};

export const inputFieldsType = [
  { key: 1, value: 'Single Line Text' },
  { key: 4, value: 'Number' },
  { key: 2, value: 'Multi Line Text' },
  { key: 5, value: 'Checkbox' },
  { key: 8, value: 'Date' },
  { key: 13, value: 'Dropdown' },
  { key: 7, value: 'Currency' },
];

export const inputTypeField = [
  {
    name: 'Text',
    id: 1,
  },
  {
    name: 'Multi-line Text',
    id: 2,
  },
  {
    name: 'Number',
    id: 3,
  },
  {
    name: 'Decimal',
    id:4,
  },
  {
    name: 'Checkbox',
    id: 5,
  },
  {
    name: 'Dropdown',
    id: 13,
  },
  {
    name: 'On/Off',
    id: 14,
  },
  {
    name: 'Currency',
    id: 7,
  },
  {
    name: 'Date',
    id: 8,
  },
];

export const statusType = [
  {
    key: 'Active',
    value: true,
  },
  {
    key: 'Inactive',
    value: false,
  },
];

export const overlayImage =
  'https://photos.usrealty.com/us-east-2:97bf490c-b878-4670-bf87-c0c8a1739944/f42aba23-55dd-8ad7-42ca-db095c5021d6.png';

export const formPrefillTypes = {
  LISTING_PRICE: 20
};

export const companyTypes = {
  NETWORK: 'Wholesale/Network',
};

export const ownershipTypes = {
  oneOwner: 1,
  multipleOwners: 2,
  ownedByCompany: 3,
};

export const ownerTypes = {
  individual: 1,
  company: 2
};

export const lYHVideoUrls = {
  1: 'https://www.youtube.com/embed/s_eubaTF2C8',
  2: 'https://www.youtube.com/embed/x6yBw5AXEpc',
  3: 'https://www.youtube.com/embed/x2E95cg36kQ',
  4: 'https://www.youtube.com/embed/vICniPErudk',
  5: 'https://www.youtube.com/embed/Iqg-q_r4BEk',
  6: 'https://www.youtube.com/embed/x4OGZTo-mYA',
  7: 'https://www.youtube.com/embed/c2M4xcyrhCM',
  10: 'https://www.youtube.com/embed/0NjOwzb0f6U',
};

export const LYFSteps = {
  LISTING_ADDRESS: 1,
  PRICE_AND_COMISSIONS: 2,
  PROPERTY_BASICS: 3,
  PROPERTY_DETAILS: 4,
  PROPERTY_PHOTOS: 5,
  PROPERTY_TITLE: 6,
  BUYER_PREFERENCES: 7,
  PLAN_SELECTOR: 8,
  PROPERTY_BILLING: 9,
  CONTRACTS_SIGN: 10,
};

export const buyerLeadSources = {
    1: 'BuySide',
    2: 'ListingFlow',
    3: 'Dashboard',
};

export const buyerLeadSourceIds = {
  'BuySide': 1,
  'ListingFlow': 2,
  'Dashboard': 3,
};

export const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];