import moment from 'moment';
import { OrdersActions } from '../../Actions/V2/Admin/OrdersActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const ordersFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    companyId: null,
    isArchived: false,
    planId: null,
    currentStep: null,
    dateCreatedFrom: null,
    dateCreatedTo: null,
    assignedToId: null,
    averageContactTime: null,
    fetchingAverageContactTime: false,
    sortBy: null,
    desc: null,
}

const initialState = {
    orders: [],
    totalCount: 0,
    filters: ordersFiltersDefault
};

export const adminOrdersReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case OrdersActions.SET_ORDERS_FILTERS:
            return { ...state, filters: action.payload };
        case OrdersActions.CLEAR_ORDERS_FILTERS:
            return { ...state, filters: { ...ordersFiltersDefault } };
        case OrdersActions.ORDERS_RESOLVED:
        case OrdersActions.ORDERS_FAILED:
            return { ...state, orders: action.payload.results, totalCount: action.payload.total };
        case OrdersActions.ORDER_ASSISGNING_RESOLVED:
            return { ...state, orders: action.payload };
        case OrdersActions.GET_AVERAGE_TIME_TO_CONTACT_REQUESTED:
            return { ...state, fetchingAverageContactTime: true };
        case OrdersActions.GET_AVERAGE_TIME_TO_CONTACT_RESOLVED:
            return { ...state, fetchingAverageContactTime: false, averageContactTime: action.payload };
        case OrdersActions.GET_AVERAGE_TIME_TO_CONTACT_FAILED:
            return { ...state, fetchingAverageContactTime: false };
        case OrdersActions.MARK_AS_CONTACTED_RESOLVED:
        case OrdersActions.MARK_AS_ARCHIVED_RESOLVED:
            return { ...state, orders: action.payload };
        default:
            return state;
    }
};

export const adminOrdersSelector = state => state.adminOrders.orders;
export const adminOrdersCountSelector = state => state.adminOrders.totalCount;
export const ordersFiltersSelector = state => state.adminOrders.filters;
export const fetchingAverageContactTimeSelector = state => state.adminOrders.fetchingAverageContactTime;
export const averageContactTimeSelector = state => state.adminOrders.averageContactTime;