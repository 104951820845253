import LogRocket from 'logrocket';
import { startLoading, endLoading } from '../../LoaderActions';
import { productService } from '../../../Services/V2/ProductService';
import { removeNullUndefined } from '../../../Utilities/commonFunctions';
import { productsFiltersDefault } from '../../../Reducers/V2/AdminProductsReducer';
import { alertActions } from '../../ToastActions';
import { staticMessages } from '../../../Constants/CommonConstants';

export const ProductsActions = {
    PRODUCTS_REQUESTED: 'PRODUCTS_REQUESTED',
    PRODUCTS_RESOLVED: 'PRODUCTS_RESOLVED',
    PRODUCTS_FAILED: 'PRODUCTS_FAILED',
    PRODUCT_DETAILS_REQUESTED: 'PRODUCT_DETAILS_REQUESTED',
    PRODUCT_DETAILS_RESOLVED: 'PRODUCT_DETAILS_RESOLVED',
    PRODUCT_DETAILS_FAILED: 'PRODUCT_DETAILS_FAILED',
    PRODUCT_FEATURE_TYPES_REQUESTED: 'PRODUCT_FEATURE_TYPES_REQUESTED',
    PRODUCT_FEATURE_TYPES_RESOLVED: 'PRODUCT_FEATURE_TYPES_RESOLVED',
    PRODUCT_FEATURE_TYPES_FAILED: 'PRODUCT_FEATURE_TYPES_FAILED',
    CREATE_PRODUCT_REQUESTED: 'CREATE_PRODUCT_REQUESTED',
    CREATE_PRODUCT_RESOLVED: 'CREATE_PRODUCT_RESOLVED',
    CREATE_PRODUCT_FAILED: 'CREATE_PRODUCT_FAILED',
    UPDATE_PRODUCT_REQUESTED: 'UPDATE_PRODUCT_REQUESTED',
    UPDATE_PRODUCT_RESOLVED: 'UPDATE_PRODUCT_RESOLVED',
    UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED',
    SET_PRODUCTS_FILTERS: 'SET_PRODUCTS_FILTERS',
    CLEAR_PRODUCTS_FILTERS: 'CLEAR_PRODUCTS_FILTERS',
    CLEAR_DETAILS: 'CLEAR_DETAILS',
    COPY_PRODUCT_REQUESTED: 'COPY_PRODUCT_REQUESTED',
    COPY_PRODUCT_RESOLVED: 'COPY_PRODUCT_RESOLVED',
    COPY_PRODUCT_FAILED: 'COPY_PRODUCT_FAILED',
};

export const productsCreators = {
    setProductsFilters: (filters) => async (dispatch) => {
        dispatch({ type: ProductsActions.SET_PRODUCTS_FILTERS, payload: filters });
    },
    clearProductsFilters: () => async (dispatch) => {
        dispatch({ type: ProductsActions.CLEAR_PRODUCTS_FILTERS });
    },
    clearDetails: () => async (dispatch) => {
        dispatch({ type: ProductsActions.CLEAR_DETAILS });
    },
    clearProductsFilters: () => async (dispatch) => {
        dispatch({ type: ProductsActions.CLEAR_PRODUCTS_FILTERS });
    },
    getProducts: (params = productsFiltersDefault) => async (dispatch, getState) => {
        dispatch({ type: ProductsActions.PRODUCTS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const paramsObj = params;

            const { data } = await productService.getProducts(removeNullUndefined(paramsObj));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: ProductsActions.PRODUCTS_RESOLVED, payload: data });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_products_action',
                },
                extra: {
                    type: ProductsActions.PRODUCTS_FAILED,
                },
            });
        }
    },
    getProductDetails: (productId) => async (dispatch, getState) => {
        dispatch({ type: ProductsActions.PRODUCT_DETAILS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await productService.getProduct(productId);

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: ProductsActions.PRODUCT_DETAILS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_products_action',
                },
                extra: {
                    type: ProductsActions.PRODUCT_DETAILS_FAILED,
                },
            });
        }
    },
    getProductFeatureTypes: (productId = null) => async (dispatch) => {
        dispatch({ type: ProductsActions.PRODUCT_FEATURE_TYPES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await productService.getProductFeatureTypes(removeNullUndefined({productId}));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: ProductsActions.PRODUCT_FEATURE_TYPES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_products_action',
                },
                extra: {
                    type: ProductsActions.PRODUCT_FEATURE_TYPES_FAILED,
                },
            });
        }
    },
    createProduct: (product, cb = null) => async (dispatch) => {
        dispatch({ type: ProductsActions.CREATE_PRODUCT_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await productService.createProduct(product);

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: ProductsActions.CREATE_PRODUCT_RESOLVED, payload: data });

            cb && cb();
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_products_action',
                },
                extra: {
                    type: ProductsActions.CREATE_PRODUCT_FAILED,
                },
            });
        }
    },
    updateProduct: (productId, product) => async (dispatch) => {
        dispatch({ type: ProductsActions.UPDATE_PRODUCT_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await productService.updateProduct(productId, product);

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: ProductsActions.UPDATE_PRODUCT_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_products_action',
                },
                extra: {
                    type: ProductsActions.UPDATE_PRODUCT_FAILED,
                },
            });
        }
    },
    copyProduct: (productId) => async (dispatch, getState) => {
        dispatch({ type: ProductsActions.COPY_PRODUCT_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        
        try {
            await productService.copyProduct(productId);

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch(productsCreators.getProducts(getState().adminProducts.filters))
            dispatch({ type: ProductsActions.COPY_PRODUCT_RESOLVED });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_products_action',
                },
                extra: {
                    type: ProductsActions.COPY_PRODUCT_FAILED,
                },
            });
        }
    },
};
