import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onboardingActionCreators } from '../../../../Actions/OnboardingActions'
import { StaticContentWrapper, Container, Quote, MidBox, InputFlex, RowFlex, CustomButton } from '../../../../Assets/Style/commonStyleComponents';
import { intialGlobalSummaryState } from '../../../../Utilities/commonFunctions';
import { ImagesUrl } from "../../../../Constants/Images";
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

class Commercial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalSummaryForm: intialGlobalSummaryState()
    };
  }

  submitFormData = (formData) => {
    this.props.globalSummaryRequest(formData, () => {
      this.setState({
        globalSummaryForm: intialGlobalSummaryState()
      })
    });
  }

  handleFieldChanged = (e) => {
    let globalSummaryForm = { ...this.state.globalSummaryForm };
    globalSummaryForm[e.target.name].value = e.target.value;
    this.setState({ globalSummaryForm: { ...globalSummaryForm } });
  }

  handleFieldBlur = (e) => {
    this.validateField(e.target.name, e.target.value);
  }

  validateField = (key, value) => {
    let globalSummaryForm = { ...this.state.globalSummaryForm };
    let isError = false;
    for (let i = 0; i < globalSummaryForm[key].rules.length; i++) {
      let rule = globalSummaryForm[key].rules[i];
      if (!rule.rule.test(value)) {
        globalSummaryForm[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      globalSummaryForm[key].error = null;
    }

    this.setState({
      globalSummaryForm:
        { ...globalSummaryForm },
        isError: Object.keys(globalSummaryForm).some(k => globalSummaryForm[k].error !== undefined && globalSummaryForm[k].error !== null) });
    return isError;
  }

  validateAndSubmit = (e) => {
    e.preventDefault();
    let isError = false;

    Object.keys(this.state.globalSummaryForm).forEach((k) => {
      const isErrorExist = this.validateField(k, this.state.globalSummaryForm[k].value);
      if (isErrorExist) {
        isError = true;
      }
    })

    if (!isError) {
      this.submitFormData(
        {
          ...(Object.keys(this.state.globalSummaryForm).reduce((retVal, key) => {
            retVal[key] = this.state.globalSummaryForm[key].value;
            return retVal;
          }, {}))
        });
    }
  }

  render() {
    const { globalSummaryForm } = this.state;
    return (
      <>
        <Navbar />
        <StaticContentWrapper>
          <Container>
            <Quote>
              Build Your Equity with Our Expertise
            </Quote>
            <h3>USRealty.com Capabilities and Experience</h3>
            <ul>
              <li>$1 billion in single-family home transactions through 2016</li>
              <li>4,252 homes sold nationally in 2016</li>
              <li>Sales success rate comparable to full-price realty agents</li>
              <li>One of the top 50 U.S. residential brokerages in terms of sales</li>
              <li>Equips customers with control over all aspects and costs of selling their properties, saving time, money
              and stress</li>
              <li>50% savings on sales commissions for all customers, and even greater savings for many</li>
            </ul>
            <p>USRealty.com is a national residential real estate brokerage that enables property owners of all
            sizes – from individual homeowners to institutional investors – to list and sell homes through the most
            powerful multiple listing services and websites, choosing how much commission they will pay. The ‘a la
            carte’ USRealty.com model means that sellers can decide which selling services and fees will help them save
            the most money and capture the greatest return on their real estate investments.</p>
            <h3>Simple, Streamlined, Straightforward Selling Through USRealty.com</h3>
            <ul>
              <li>Licensed real estate broker in 33 states</li>
              <li>Member of 110 multiple listing services</li>
              <li>Network of co-operating agents across the country</li>
              <li>One-point-of-contact, streamlined listing tools for all digital platforms</li>
              <li>Start-to-finish management of listing through closing</li>
              <li>Full transparency through customized reports</li>
              <li>Suite of property management partners for complementary property maintenance, improvement and
              compliance services</li>
            </ul>
            <h3>USRealty.com Contacts</h3>
            <div className="commercial-contact">
              <h4>
                Kyle Stoner
                <span>(President & CEO)</span>
                <a href="mailto:kyle@USRealty.com">kyle@USRealty.com</a>
              </h4>
            </div>
            <div className="commercial-contact">
              <h4>
                Derek Morgan
                <span>(EVP, Broker Services)</span>
                <a href="mailto:dmorgan@USRealty.com">dmorgan@USRealty.com</a>
              </h4>
            </div>
            <div>
              <h4 className="commercial-contact">
                Download our capabilities sheet:
                <a href={ ImagesUrl.USREALTY_INSTITUTIONAL_ONEPAGEER } target="_blank">
                USRealty-Institutional-onepager-final
                </a>
              </h4>
            </div>
            <Quote className="for-commercial">
              Get the USRealty.com Report:
            </Quote>
            <h3>Global Trends in Real Estate Commissions and Effect on Investment ROI</h3>
            <p>American home sellers pay more more in agent commissions than almost any where else in the world. We’re glad
            to provide you with this summary of global trends.</p>
            <MidBox className="commercial-form">
              <RowFlex>
                <InputFlex
                  className="inputgap"
                  name={globalSummaryForm?.firstName?.name}
                  title={globalSummaryForm?.firstName?.title}
                  value={globalSummaryForm?.firstName?.value}
                  error={globalSummaryForm?.firstName?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  className="inputgap"
                  name={globalSummaryForm?.lastName?.name}
                  title={globalSummaryForm?.lastName?.title}
                  value={globalSummaryForm?.lastName?.value}
                  error={globalSummaryForm?.lastName?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <InputFlex
                name={globalSummaryForm?.email?.name}
                title={globalSummaryForm?.email?.title}
                value={globalSummaryForm?.email?.value}
                error={globalSummaryForm?.email?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <InputFlex
                name={globalSummaryForm?.jobTitle?.name}
                title={globalSummaryForm?.jobTitle?.title}
                value={globalSummaryForm?.jobTitle?.value}
                error={globalSummaryForm?.jobTitle?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <InputFlex
                name={globalSummaryForm?.phoneNumber?.name}
                title={globalSummaryForm?.phoneNumber?.title}
                value={globalSummaryForm?.phoneNumber?.value}
                mask={globalSummaryForm.phoneNumber.mask}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <CustomButton type="submit" className="block" onClick={this.validateAndSubmit}>Submit</CustomButton>
            </MidBox>
          </Container>
        </StaticContentWrapper>
        <Footer />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...onboardingActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Commercial);
