import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Card, Checkbox, Col, Row, Table } from 'antd';
import { useEffect } from 'react';
import {
  buyerLeadSources,
  dateFormat,
  entityTypes,
  pageSizeOptions,
  staticMessages,
  tableItemsPerPage,
} from '../../../../../Constants/CommonConstants.js';
import SearchPanel from '../Shared/SearchPanel.js';
import Routes from '../../../../../Constants/Routes.js';
import { editPageAction, momentDateFormat } from '../../../../../Utilities/commonFunctions.js';
import CurrencyInput from 'react-currency-input-field';
import { useTitle } from '../../../../../Utilities/Hooks.js';
import {
  buyerLeadsFiltersSelector,
  buyerLeadsSelector,
  buyerLeadsTotalCountSelector,
} from '../../../../../Reducers/V2/BuyerLeadsReducer.js';
import { buyerLeadsCreators } from '../../../../../Actions/V2/Admin/BuyerLeadsActions.js';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions.js';
import { PatternFormat } from 'react-number-format';
import PropertyTypeBage from '../Shared/PropertyTypeBage.js';
import ByuerLeadsFilters from './ByuerLeadsFilters.js';

const tableConfigs = {
  columns: [
    {
      title: 'User',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: true,
      render: (fullName, record) =>
        record?.userId ? <Link to={editPageAction(record, entityTypes.users)}>{fullName}</Link> : <div>{fullName}</div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: false,
      render: (email, record) =>
        record?.userId ? <Link to={editPageAction(record, entityTypes.users)}>{email}</Link> : <div>{email}</div>,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: false,
      render: (phone, record) =>
        record?.userId ? (
          <Link to={editPageAction(record, entityTypes.users)}>
            <PatternFormat value={phone} displayType="text" format="###-###-####" />
          </Link>
        ) : (
          <PatternFormat value={phone} displayType="text" format="###-###-####" />
        ),
      width: 130,
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      sorter: false,
      render: (source) => <div>{buyerLeadSources[source]}</div>,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      sorter: false,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: false,
    },
    {
      title: 'Zip code',
      dataIndex: 'zip',
      key: 'zip',
      sorter: false,
    },
    {
      title: 'Price Min',
      dataIndex: 'minPrice',
      key: 'minPrice',
      sorter: true,
      render: (price) => (
        <CurrencyInput
          value={price || 0}
          decimalScale={0}
          fixedDecimalScale={true}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      ),
    },
    {
      title: 'Price Max',
      dataIndex: 'maxPrice',
      key: 'maxPrice',
      sorter: true,
      render: (price) => (
        <CurrencyInput
          value={price || 0}
          decimalScale={0}
          fixedDecimalScale={true}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      ),
    },
    {
      title: 'Property Type',
      dataIndex: 'propertyTypeId',
      key: 'propertyTypeId',
      sorter: true,
      render: (id) => <PropertyTypeBage propertyTypeId={id} />,
    },
    {
      title: 'Is Preapproved',
      dataIndex: 'isPreapproved',
      key: 'isPreapproved',
      sorter: true,
      render: (isPreapproved) => <Checkbox checked={isPreapproved} />,
    },
    {
      title: 'Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      sorter: true,
      render: (dateCreated) => <span>{momentDateFormat(dateCreated, dateFormat.DATEMODIFIEDFROMAT)}</span>,
    },
  ],
  filters: [],
  searchPlaceHolder: 'Search Buyer Leads',
};

const ByuerLeadsList = () => {
  const dispatch = useDispatch();
  const buyerLeads = useSelector(buyerLeadsSelector);
  const filters = useSelector(buyerLeadsFiltersSelector);
  const totalCount = useSelector(buyerLeadsTotalCountSelector);

  useEffect(() => {
    dispatch(buyerLeadsCreators.getbuyerLeads(filters));
    dispatch(lookupActionsCreators.getPropertyTypes(filters));
  }, [filters.search, filters.page, filters.limit, filters.sortBy, filters.desc]);

  useTitle('Buyer-Leads-Admin | Unreal Estate');

  const onTableChange = (pagination, tablefilters, sorter) => {
    const { current, pageSize } = pagination;
    const { field, order } = sorter;
    const sorterParams = {};

    if (field && order) {
      sorterParams.sortBy = field;
      sorterParams.desc = order === 'descend';
    }

    dispatch(
      buyerLeadsCreators.setbuyerLeadsFilters({
        ...filters,
        ...sorterParams,
        page: current,
        limit: pageSize,
      })
    );
  };

  const showTotal = (total, range) => {
    return `Showing ${range[0]} - ${range[1]} of ${total}`;
  };
  const onSearch = (value) => dispatch(buyerLeadsCreators.setbuyerLeadsFilters({ ...filters, search: value }));

  return (
    <>
      <SearchPanel placeholder={'Search by City, Zip code, Email'} onSearch={onSearch} />
      <ByuerLeadsFilters />
      <Card>
        <Row style={{ marginTop: 20 }}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 24, offset: 0 }}
          >
            <Table
              className="listing-product-table"
              size={'default'}
              columns={tableConfigs.columns}
              dataSource={buyerLeads}
              onChange={onTableChange}
              pagination={{
                total: totalCount,
                defaultPageSize: tableItemsPerPage,
                defaultCurrent: 1,
                showTotal: showTotal,
                pageSizeOptions: pageSizeOptions,
                showSizeChanger: totalCount > tableItemsPerPage,
              }}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ByuerLeadsList;
