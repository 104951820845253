import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { DatePicker } from 'antd';
import moment from 'moment';
import { editListingValue } from '../../../Constants/CommonConstants';
import CurrencyInput from 'react-currency-input-field';
import {
  Form,
  FormDatePicker,
  Group,
  Section,
} from '../../../Assets/Style/commonStyleComponents';
import { adminActionCreators } from '../../../Actions/AdminActions';
import AdminHeader from '../Components/AdminHeader';
import { Container } from '../../../Assets/Style/commonStyleComponents';
import Enums from '../../../Constants/Enums';
import { formType, formLabelValue } from '../../../Constants/CommonConstants';

const {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  THOUSAND,
} = Enums.NUMERIC_VALUE;

const disabled_input='disabled-input';
function disabledDate(current) {
  return current && current < moment().add(-ONE, 'days');
}
class MlsPreviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: THREE,
      manipulatedFormField: {},
      targetValues:{},
      numberText:'',
      decemialText:'',
      checkedOnOFF:[],
    };
    this.isLastFormId = false;
    this.stepCount = ZERO;
    this.listingForm = {};
  }
  componentDidMount() {
    const { state = '' } = this.props.match.params;
    const paramsValue = new URLSearchParams(window.location.search);
    let formTypeId = ONE;
    let mlsId = ZERO;
    let mlsCategoryId = ZERO;
    const formSectionId = paramsValue.get("formSectionId");
    if(!formSectionId) {
      formTypeId = paramsValue.get('formTypeId');
      mlsId = paramsValue.get('mlsId');
      mlsCategoryId = paramsValue.get('mlsCategoryId');
    }
    this.props.getMlsPreviewForm(this.props.activeEntity, {
      state,
      formTypeId,
      mlsId,
      mlsCategoryId,
      formSectionId,
    });
  }
  formSteps = index => this.setState({ step: index + ONE + TWO });

  isMultiChecked=(value,string)=>{
    let is_checked=false;
    if(string && value){
      is_checked = string.indexOf(value) != -1;
    } 
    return is_checked;
  } 

  isMSultiChecked=(value,string)=>{
   let returnVal=false;
   if(string===value){
      returnVal=true;
   }
     return returnVal;
  }

  handleFormMultileCheckboxField = (e,fieldId) => {
    const { manipulatedFormField } = this.state;
    const old_value=manipulatedFormField[fieldId]['value'];
    let newval='';
     if(old_value){
         if(e.target.checked){
           newval=old_value+','+e.target.value;
         }else{
           
           newval=this.removeValue(old_value,e.target.value,",")
         }
     }else{
         newval=e.target.value;;
     }
     const errors = { ...this.state.errors };
     manipulatedFormField[fieldId].value = newval;
     errors[fieldId] = '';
     this.setState({ manipulatedFormField, errors });

   };

   removeValue = (list, value, separator)=>{
    separator = separator || ",";
    var values = list.split(separator);
    for(var i = 0 ; i < values.length ; i++) {
      if(values[i] == value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  }
 
   handleFormSingleCheckboxField= (e,fieldId) => {
    const { manipulatedFormField } = this.state;
    let newval='';
    if(e.target.checked){
       newval=e.target.value;
    }
     const errors = { ...this.state.errors };
     manipulatedFormField[fieldId].value = newval;
     errors[fieldId] = '';
     this.setState({ manipulatedFormField, errors });
   };
 
   handleFormFieldSelect = (field, fieldId, isTypeDate, e) => {
    const { manipulatedFormField } = this.state;
    const errors = { ...this.state.errors };
    const selectedValue = isTypeDate ? e._d : e.target.value;
    
    if(selectedValue==editListingValue.oneOwner){
      this.setState({showSingCount: Enums.NUMERIC_VALUE.ONE})
    }
    else if(selectedValue==editListingValue.twoOwner){
      this.setState({showSingCount: Enums.NUMERIC_VALUE.TWO})
    }
    else if(selectedValue==editListingValue.threeOwner){
      this.setState({showSingCount: Enums.NUMERIC_VALUE.THREE})
    }else{
      this.setState({showSingCount: Enums.NUMERIC_VALUE.TWO})
    }
   
    manipulatedFormField[fieldId].value = selectedValue;
    errors[fieldId] = "";
    this.setState({ manipulatedFormField, errors });
  };
  getFieldControl = (field, index,node,) => {
    let fieldWidth;
     if(field.value?.length>=Enums.NUMERIC_VALUE.THIRTY){
        fieldWidth=Enums.NUMERIC_VALUE.TWOHUNDREDTWENTY+(Enums.NUMERIC_VALUE.TWO*field.value?.length);
     }
     if(field.displayName===editListingValue.sellername){
      fieldWidth=Enums.NUMERIC_VALUE.TWOHUNDREDSEVENTEEN;
     }
     if(field.displayName===editListingValue.brokerupfrontfee || field.displayName===editListingValue.broker_upfront_fee){
      field.value='$'+field.value;
     }
    if (field.readOnly) {
        return (
          <>
            <input
              type="text"
              key={`${field.formFieldId}_${index}`}
              value={field.value || ''}
              className={disabled_input}
            />
            <label className="for-readonly">Read Only</label>
          </>
        );
      }
      if (field.options) {
        let labelLength='';
        if(field.inputDataType===Enums.NUMERIC_VALUE.FIVE && node?.parent?.name===editListingValue.div && node?.parent?.parent!==null){
          labelLength=this.getCheckboxLabelLength(node);
        }
        if(!field.value || field.value===null){
          field.value='';
        }
       return (
          <>
          {field.inputDataType===Enums.NUMERIC_VALUE.FIVE ? (
         <>
         
          {field.required && <label style={labelLength ? {top: -25 + "px",
                  left: ((2*labelLength)+70) + "px",marginLeft: '12px',marginBottom:'0px'} : {}}>Required</label>}
  
          {field.optionTypeId===Enums.NUMERIC_VALUE.ONE ? (
            <>
           {field.options.split('\n').map((o) => (
            <div className="checkbox-default">
              <input
                key={`${field.formFieldId}_${index}_${o}`}
                type="checkbox"
                value={o}
                name={field.formFieldId}
                checked={this.isMSultiChecked(o,this.state.manipulatedFormField[field.formFieldId].value)}
                onChange={(e) => this.handleFormSingleCheckboxField(e,field.formFieldId)}
                ref={field.displayName}
                 /><span>{o}</span>
              </div>
             
              ))}
            </>
         ) : (
            <>
           {field.options.split('\n').map((o) => (
            <div className="checkbox-default">
              <input
                key={`${field.formFieldId}_${index}_${o}`}
                type="checkbox"
                value={o}
                name={field.formFieldId}
                defaultChecked={this.isMultiChecked(o,field.value)}
                onChange={(e) => this.handleFormMultileCheckboxField(e,field.formFieldId)}
                ref={field.displayName}
                 /><span>{o}</span>
              </div>
             
              ))}
              </>
               )}
          </>
  
          ):(
          <>
            <select
              key={`${field.formFieldId}_${index}`}
              name={field.formFieldId}
              defaultValue={field.value || ''}
            >
              {
                field.required &&
                <label>Required</label>
              }
              <option key={Math.random()} value=''> Select Value </option>
              {field.options.split('\n').map((o) => (
                <option key={`${field.formFieldId}_${index}_${o}`} value={o}>
                  {o}
                </option>
              ))}
            </select>
            {field.required && <label>Required</label>}
          </>
          )}
          </>
        )
      }
  
      switch (field.inputDataType) {
        
        case Enums.InputTypes.TEXT_SHORT:
          return (
            <>
              <input
                style={fieldWidth ? {width: fieldWidth + "px"}: {}}
                key={`${field.formFieldId}_${index}`}
                type="text"
                name={field.formFieldId}
                defaultValue={field.value? field.value:field.prefillText || ''}
                required={field.required && !field.value}
                ref={field.displayName}
              />
              {field.required && <label>Required</label>}</>
          );
        case Enums.InputTypes.TEXT_LONG:
          return (
            <div style={{minWidth: '650px',
              display: 'flex',
              alignItems: 'flex-end' }} >
              <textarea
                key={`${field.formFieldId}_${index}`}
                type="text"
                name={field.formFieldId}
                defaultValue={field.value? field.value:field.prefillText || ''}
              />
              {field.required && <label>Required</label>}
            </div>
          );
          case Enums.InputTypes.CURRENCY:
            return (
              <>
                <CurrencyInput
                  style={fieldWidth ? {width: fieldWidth + "px"}: {}}
                  key={`${field.formFieldId}_${index}`}
                  decimalsLimit={2}
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  defaultValue={field?.value?field.value:field.prefillText || ''}
                  name={field.formFieldId}
                  required={field.required && !field.value}
                  ref={field.formFieldId}
                />
                </>
            );
          case Enums.InputTypes.WHOLE_NUMBER:
            return (
              <>
                <input
                  key={`${field.formFieldId}_${index}`}
                  type="number"
                  name={field.formFieldId}
                  defaultValue={field?.value?field.value:field.prefillText || ''}
                />
                {
                  field.required &&
                  <label>Required</label>
                }
              </>
            );
        case Enums.InputTypes.DECIMAL:
          return (
            <>
              <input
                key={`${field.formFieldId}_${index}`}
                type="number"
                name={field.formFieldId}
                defaultValue={field?.value?field.value:field.prefillText || ''}
              />
              {field.required && <label>Required</label>}
            </>
          );
        case Enums.InputTypes.BOOLEAN:
          return (
            <>
             {field.required && <label>Required</label>}
              <input
                key={`${field.formFieldId}_${index}`}
                type="checkbox"
                name={field.formFieldId}
                defaultChecked={field?.value ? JSON.parse(field.value) : false}
              />
             
            </>
          );
  
        case Enums.InputTypes.DATE_TIME:
          return (
            <>
              <FormDatePicker>
                {field.value ? (
                  <DatePicker
                    size="large"
                    defaultValue={moment(field.value)}
                    onChange={this.handleFormFieldSelect.bind(
                      this,
                      field.displayName,
                      field.formFieldId,
                      true
                    )}
                    ref={field.displayName}
                    inputReadOnly
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    key={`${field.formFieldId}_${index}`}
                    name={field.formFieldId}
                    allowClear={false}
                  />
                ) : (
                  <DatePicker
                    size="large"
                    onChange={this.handleFormFieldSelect.bind(
                      this,
                      field.displayName,
                      field.formFieldId,
                      true
                    )}
                    ref={field.displayName}
                    inputReadOnly
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    key={`${field.formFieldId}_${index}`}
                    name={field.formFieldId}
                    allowClear={false}
                  />
                )}
                {field.required && <label>Required</label>}
                <span className="text-error">
                </span>
              </FormDatePicker>
            </>
          );
        default:
          return "";
      }
    };

    handleFormTargetField= (e,options,fieldId) => {
   
      if(options){
       let optionValue=options.replace(/\u21b5|\r\n|\n/g, "@#$").split("@#$");
       let stateObj=[];
       if(!e.target.checked){
          this.savetargetData(optionValue);
       }
      }
    }
  
    removeValue = (list, value, separator) =>{
      separator = separator || ",";
      var values = list.split(separator);
      for(var i = 0 ; i < values.length ; i++) {
        if(values[i] == value) {
          values.splice(i, 1);
          return values.join(separator);
        }
      }
      return list;
    }

    handleFormCheckboxField = (e, field, fieldId) => {
      let nArray=this.state.checkedOnOFF;
       if(e.target.checked){
         
         nArray.push(fieldId);
         this.setState({checkedOnOFF:nArray})
       }else{
        const index = nArray.indexOf(fieldId);
        if (index > -1) {
          nArray.splice(index, 1);
        }
        this.setState({checkedOnOFF:nArray})
       }
    };  

  getImageFieldControl = (node, fields,isFormSubmitted,userFormID,formId) => {
    if(fields){
      fields.sort(function(a,b){
        return parseInt(b.inputDataType) - parseInt(a.inputDataType);
      })
    }
  return (
      <div className="image-with-form">
        <img src={node} />
        
        {fields.map((field, index) => {

          if (!this.state.manipulatedFormField[field.formFieldId]) {
            this.state.manipulatedFormField[field.formFieldId] = {
              displayName: field.displayName,
              isRequired: field.required,
              value: field?.value ? field.value : field.prefillText || '',
              step: this.state.step ? this.state.step : Enums.NUMERIC_VALUE.THREE,
              isLinked:field.isLinked,
              formPrefillTypeId:field.formPrefillTypeId,
            };
          }
          
          if(field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN && field.targetFields){
            let obj=[]
            let optionValue=field.targetFields.replace(/\u21b5|\r\n|\n/g, "@#$").split("@#$");
            
             Object.keys(optionValue).forEach((key) => {
               obj[key]={'isCheckd':field.value,'displayName':optionValue[key]}
               
             })
             this.state.targetValues[field.formFieldId]=obj;
           }

           let targetClass='';
         
           if(Object.keys(this.state.targetValues).length>0){
 
             Object.keys(this.state.targetValues).forEach((Statekey) => {
             let singleArr=this.state.targetValues[Statekey];
            
              Object.keys(singleArr).forEach((key) => {
              if(singleArr[key]['displayName']===field.displayName && (singleArr[key]['isCheckd']=='true' || singleArr[key]['isCheckd']===true)){
                   field.readOnly=false;
               }
                 if(singleArr[key]['displayName']===field.displayName && (singleArr[key]['isCheckd']=='false' || singleArr[key]['isCheckd']===false)){
                   targetClass='disabled-input';
                   field.value='';
                   field.readOnly=true;
                 }
               })
             })
            }
            if(field.displayName===editListingValue.brokerupfrontfee || field.displayName===editListingValue.broker_upfront_fee){
              field.value='$'+field.value;
             }
             if (field.readOnly) {
            return (
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  readOnly
                  defaultValue={field.value? field.value:field.prefillText || ''}
                  className={disabled_input}
                />
                
              </div>
            );
          }

          if (field.options && field.inputDataType === Enums.NUMERIC_VALUE.THIRTEEN) {
            if(field.value===null){
              field.value='';
            }
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <select
                  style={{
                    height: field.overlayHeight?field.overlayHeight: 20 + "px",
                    width: field.overlayWidth?field.overlayWidth: 100 + "px",
                    padding: 0,
                  }}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  value={field.value}
                >
                  <option key={Math.random()} value=''>
                    {' '}
                    Select Value{' '}
                  </option>
                  {field.options
                    .replace(/\u21b5|\r\n|\n/g, "@#$")
                    .split("@#$")
                    .map((o) => (
                      <option
                        key={`${field.formFieldId}_${index}_${o}`}
                        value={o}
                      >
                        {o}
                      </option>
                    ))}
                </select>
              </div>
              {
              field.required &&
              <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }} className="additional-label">Required</label>
              } 
              </>
             
            );
          }

          if (
            !field.readOnly &&
            field.inputDataType === Enums.NUMERIC_VALUE.ONE
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  defaultValue={field.value? field.value:field.prefillText || ''}
                  required={field.required && !field.value}
                  ref={field.displayName}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.SEVEN)
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <CurrencyInput
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  decimalsLimit={2}
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  defaultValue={field?.value?field.value:field.prefillText || ''}
                  name={field.formFieldId}
                  required={field.required && !field.value}
                  ref={field.formFieldId}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.THREE || field.inputDataType === Enums.NUMERIC_VALUE.FOUR)
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  defaultValue={field.value? field.value:field.prefillText || ''}
                  ref={field.displayName}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (
            !field.readOnly &&
            field.inputDataType === Enums.NUMERIC_VALUE.TWO
          ) { 
            return (
              <>
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >

              <textarea
               style={{
                height: field.overlayHeight?field.overlayHeight:20 + "px",
                width: field.overlayWidth?field.overlayWidth:100 + "px",
              }}
              key={`${field.formFieldId}_${index}`}
              type='text'
              name={field.formFieldId}
              defaultValue={field.value? field.value:field.prefillText || ''}
              ref={field.displayName}
              
            />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.FIVE) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                  height: field.overlayHeight + "px",
                  width: field.overlayWidth + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="checkbox"
                  name={field.formFieldId}
                  defaultChecked={field?.value ? JSON.parse(field.value) : false}
                />
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               
               }
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="checkbox"
                  name={field.formFieldId}
                  defaultChecked={field?.value ? JSON.parse(field.value) : false}
                  onChange={(e) =>this.handleFormCheckboxField(
                    e,
                    field.displayName,
                    field.formFieldId
                  )
                }
                  
                />
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
        })}
      </div>
    );
  };

  transform = (node, index, fields) => {
    if (node.type === 'tag' && node.name === 'field') {
      const field = fields.filter(
        f => f.formFieldId === parseInt(node.children[ZERO].data),
      )[ZERO];
      if (!this.state.manipulatedFormField[field.formFieldId]) {
        this.state.manipulatedFormField[field.formFieldId] = {
          displayName: field.displayName,
          isRequired: field.required,
          value: field.value ? field.value : field.prefillText || "",
          step: this.state.step ? this.state.step : Enums.NUMERIC_VALUE.THREE,
          isLinked:field.isLinked,
          formPrefillTypeId:field.formPrefillTypeId,
        };
      }
      return this.getFieldControl(field, index);
    }
  };
  processForm = (content, fields, groupType) => {
    if (groupType.formGroupType === THREE) {
      return this.getImageFieldControl(content, fields);
    }
    content = content.replace(/{FormField:(\d+)}/g, '<field>$1</field>');

    content = content.replace(/\[row.*?\]/g, '<div class="row-modify">');
    content = content.replace(/\[\/row]/g, '</div>');
    content = content.replace(/\[column.*?\]/g, '<div class="medium-4">');
    content = content.replace(/\[\/column]/g, '</div>');

    content = content.replace(/\[page-break]/g, '<br />');
    return ReactHtmlParser(content, {
      transform: (node, index) =>
        this.transform(node, index, fields, groupType.formGroupType),
    });
  };
  renderMLSFormsSteps() {
    const { mlsFormPreviewDetail } = this.props;
    
    const { step } = this.state;
    const listingFormArr = [];
    let filterGroups = {};
    mlsFormPreviewDetail.groups.forEach(group => {
      let p = ZERO;
      if (group.priority) {
        p = Math.floor(group.priority / 1000);
      }

      if (!filterGroups[p]) {
        filterGroups[p] = [];
      }
      filterGroups[p].push(group);
    });
    
    Object.entries(filterGroups).forEach(function ([key, value]) {
      let formData = JSON.parse(JSON.stringify(mlsFormPreviewDetail));
      formData.groups = value;
      
        listingFormArr.push(formData);
      
    });

    this.listingForm = listingFormArr[step - THREE];
    return (
      listingFormArr.length > ZERO &&
      listingFormArr.map((form, index) => {
        let labelName = formLabelValue.additional;
        const priorityCount =
          Math.floor(form.groups[ZERO]?.priority / THOUSAND) || ZERO;
        if (
          form.groups[ZERO].name.toLowerCase().includes(formType.listingForm)
        ) {
          labelName = formLabelValue.listingContract;
        } else if (priorityCount === TWO) {
          labelName = formLabelValue.agencyDisclosure;
        } else if (priorityCount === THREE) {
          labelName = formLabelValue.listingContract;
        } else if (priorityCount === FOUR) {
          labelName = formLabelValue.additional;
        } else if (priorityCount === FIVE) {
          labelName = formLabelValue.propertyDisclosure;
        } else if (
          priorityCount === ONE ||
          form.groups[ZERO].name.toLowerCase().includes(formType.inputSheet)
        ) {
          labelName = formLabelValue.propertyData;
        }
        return (
          <span
            key={Math.random()}
            className={this.state.step === index + ONE + TWO ? 'active' : ''}
            onClick={() => this.formSteps(index)}
          >
            {labelName}
          </span>
        );
      })
    );
  }
  render() {
    
    const { mlsFormPreviewDetail } = this.props;
    return (
      <React.Fragment>
        <Layout.Header
          style={{ background: '#fff', minHeight: 52, padding: ZERO }}
        >
          <AdminHeader />
        </Layout.Header>
        <Container>
          <Form>
            {mlsFormPreviewDetail?.groups && (
              <div className='form-tabs'>{this.renderMLSFormsSteps()}</div>
            )}

            <div
              className={`${
                this.isLastFormId ? 'dynamic-form' : 'dynamic-form-before'
              }`}
            >
              {this.listingForm?.groups?.map((g, i) => {
                return (
                  <Group key={i}>
                    {g.sections.map((s, j) => {
                      return (
                        <Section key={j}>
                          {this.processForm(s.content, s.fields, g)}
                        </Section>
                      );
                    })}
                  </Group>
                );
              })}
            </div>
          </Form>
        </Container>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[TWO],
    mlsFormPreviewDetail: state.admin.mlsFormPreviewDetail || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MlsPreviewContainer),
);
