import { CashOfferActions } from '../../Actions/V2/CashOfferActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const cashOfferFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    sortBy: null,
    desc: null,
}

const initialState = {
    isCashModalOpened: false,
    listingId: null,
    draftListingId: null,
    price: null,
    address: null,
    zipCode: null,
    city: null,
    placeId: null,
    offerSubmitted: false,
    cashOffers: [],
    totalCount: 0,
    filters: cashOfferFiltersDefault
};

export const cashOfferReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case CashOfferActions.OPEN_CASH_ORDER_MODAL:
            return {
                ...state,
                listingId: action.payload?.listingId || null,
                draftListingId: action.payload?.draftListingId || null,
                price: action.payload.price,
                address: action.payload.address,
                city: action.payload.city,
                zipCode: action.payload.zipCode,
                placeId: action.payload.placeId,
                isCashModalOpened: true,
            };
        case CashOfferActions.SET_CASH_OFFER_FILTERS:
            return { ...state, filters: action.payload };
        case CashOfferActions.GET_CASH_OFFERS_RESOLVED:
        case CashOfferActions.GET_CASH_OFFERS_FAILED:
            return { ...state, cashOffers: action.payload.results, totalCount: action.payload.total };
        case CashOfferActions.CLOSE_CASH_ORDER_MODAL:
            return { ...initialState };
        case CashOfferActions.ADD_CASH_OFFER_RESOLVED:
            return {
                ...state,
                offerSubmitted: true,
            };
        default:
            return state;
    }
};

export const cashOfferListingIdSelector = state => state.cashOffer.listingId;
export const cashOfferFiltersSelector = state => state.cashOffer.filters;
export const cashOffersSelector = state => state.cashOffer.cashOffers;
export const cashOffersTotalCountSelector = state => state.cashOffer.totalCount;
export const cashOfferDraftListingIdSelector = state => state.cashOffer.draftListingId;
export const cashOfferPriceSelector = state => state.cashOffer.price;
export const isCashModalOpenedSelector = state => state.cashOffer.isCashModalOpened;
export const offerSubmittedSelector = state => state.cashOffer.offerSubmitted;

export const cashOfferAddressDetailsSelector = state => ({
    address: state.cashOffer.address,
    zipCode: state.cashOffer.address,
    city: state.cashOffer.address,
    placeId: state.cashOffer.address,
}); ;