import React from 'react';
import styled from 'styled-components';
import accept from 'attr-accept';
import { readAndCompressImage } from "browser-image-resizer";
import md5 from "md5";
import Enums from '../../../Constants/Enums';
import { BasicText, ModalDiv } from '../../../Assets/Style/commonStyleComponents';
const Wrapper = styled.div`
width:100%;
height:100%;
`;

const DropWrapper = styled.label`
width:100%;
height:100%;
display:block;
padding:20px;
text-align:center;
font-size: 15px;
color:#666;
cursor: pointer;
border:dashed 2px ${(props) => {
  const isDraggingOverColor = props.isDraggingOver ? '#5ef292' : '#dadada';
  return props.isDragging ? isDraggingOverColor : '#d0d0d0'}};
background:${(props) => {
  const isDraggingOverColor = props.isDraggingOver ? '#d4ffe3' : '#f6f6f6';
  return props.isDragging ? isDraggingOverColor : 'transparent'}
};
border-radius: 6px;
margin-bottom: 0;
`;

const Outlined = styled.span`
    display:inline-block;
    border-radius:100px;
    padding:6px 14px 7px;
    margin:0 2px;
    cursor:pointer;
    color:#fff;
    background:${({ theme }) => theme.lavender};
    transition: 0.5s;
    &:hover {
      background:${({ theme }) => theme.secondary};
      color:#fff;
      transition: 0.5s;
    }
`;

const GalleryWrapper = styled.div`
    margin-top: 15px;
`;

const ImageWrapper = styled.div`
  display:inline-block;
  margin: 15px 15px 0 0;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 4px;
  cursor: grab;
  img {
    border-radius: 4px;
  }
  &.photo-wrapper {
    position: relative;
    .photo-close {
      position: absolute;
      right: -9px;
      top: -8px;
      line-height: 1.2;
      cursor: pointer;
      font-size: 12px;
      background-color: #B01F24;
      color: white;
      width: 20px;
      height: 20px;
      display: inline-flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      }
    }
  }
`;

const Image = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

export default class PhotoManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDragging: false,
      isDraggingOver: false,
      dragIndex: -1,
      currentDragIndex: -1,
      dragIndexMap: [],
      isDeleteConfirmPopup: false,
      deletedPhotoObj: {},
      maxPriority:0,

    };
  }

  componentDidMount() {
    window.addEventListener('mouseup', this._onWindowDrop);
    window.addEventListener('dragleave', this._onWindowDragLeave);
    window.addEventListener('dragenter', this._onWindowDragEnter);
    window.addEventListener('dragover', this._onWindowDragOver);
    window.addEventListener('drop', this._onWindowDrop);
    let maxPriority=0;
    if(this.props.photos)
    {
       maxPriority=Math.max.apply(Math, this.props.photos.map(function(o) { return o.priority; }));
    }
    this.setState({maxPriority:maxPriority});
  }

  checkPhotoLimit = (photoLength) => {
    const { photoDetailObj } = this.props;
    let islimitReached = false;
    if(photoDetailObj) {
      islimitReached = photoLength < Number(photoDetailObj.value);
    } else {
      islimitReached = true
    }
    return islimitReached
  }

  _onDragLeave = (e) => {
    this.preventDefaults(e);

    this.setState({ isDraggingOver: false });
  }

  _onDragEnter = (e) => {
    this.preventDefaults(e);
    this.setState({ isDraggingOver: true });
  }

  _onWindowDrop = (e) => {
    this.preventDefaults(e);

    if (this.state.currentDragIndex >= 0 && this.state.dragIndex >= 0) {
      this.props.onOrderChanged(this.state.dragIndex, this.state.currentDragIndex);
    }

    if (this.state.isDragging || this.state.isDraggingOver) {
      this.setState({ isDragging: false, isDraggingOver: false, currentDragIndex: -1, dragIndex: -1 });
    }
  }

  _onWindowDragEnter = (e) => {
    this.preventDefaults(e);
    if (!this.state.isDragging) {
      this.setState({ isDragging: true });
    }
  }

  _onWindowDragOver = (e) => {
    this.preventDefaults(e);
    if (!this.state.isDragging) {
      this.setState({ isDragging: true });
    }
  }

  _onWindowDragLeave = (e) => {
    this.preventDefaults(e);
    if (this.state.isDragging) {
      this.setState({ isDragging: false });
    }
  }

  fileProcessHandler = (files, photos) => {
    let photoLength = photos.length;
    for (let key in files) {
      if (this.checkPhotoLimit(photoLength)) {
        this.processPhoto(files[key]);
        photoLength += 1;
      } else {
        this.props.imageLimitCrossed();
        break;
      }
    }
  }

  _onDrop = async (e, photos) => {
    this.preventDefaults(e);
    if (this.checkPhotoLimit(photos.length)) {
      this.setState({ isDragging: false, isDraggingOver: false });

      let files = [];

      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) { // Use DataTransferItemList interface to access the file(s)
        files = [...e.dataTransfer.items].filter(i => i.kind === 'file').map(i => i.getAsFile());
      }
      else { // Use DataTransfer interface to access the file(s)
        files = [...e.dataTransfer.files];
      }

      files = files.filter(file => file.type === 'application/x-moz-file' || accept(file, "image/*"));
      this.fileProcessHandler(files, photos);
    } else {
      this.props.imageLimitCrossed();
    }
  }

  _onPhotosAdded = async (e, photos) => {
    if (this.checkPhotoLimit(photos.length)) {
      const files = [...e.target.files];
      this.fileProcessHandler(files, photos);
    } else {
      this.props.imageLimitCrossed();
    }
    e.target.value = "";
  }

  handlePhotoDragOver = (e, index) => {
    this.setState({ currentDragIndex: index });
  }

  handlePhotoDragStart = (e, index) => {
    let dragIndexMap = this.props.photos.map((p, i) => i).filter(i => i !== index);

    this.setState({ dragIndex: index, dragIndexMap: dragIndexMap });
  }

  preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  async processPhoto(file) {
    const thumb = await this.createThumbnail(file);
    const checksum = await md5(await this.getFileBase64(thumb));
    let newMaxPriority=Enums.NUMERIC_VALUE.ONE;
    if(this.state.maxPriority && this.state.maxPriority!==Number.NEGATIVE_INFINITY){
      newMaxPriority=Enums.NUMERIC_VALUE.ONE+this.state.maxPriority;
    }
    this.setState({maxPriority:newMaxPriority});
    if (!this.props.photos.map(p => p.md5).includes(checksum)) {
      this.props.onNewPhotoAdded({
        file: file,
        order: this.props.photos.length,
        thumbnail: thumb,
        listingPhotoId: -1,
        absoluteUrl: URL.createObjectURL(file),
        thumbnailUrl: URL.createObjectURL(thumb),
        md5: checksum
      },newMaxPriority);
    }
  }

  createThumbnail = async (photo) => {
    const config = {
      quality: 0.8,
      maxWidth: 300,
      maxHeight: 300,
      autoRotate: true,
      debug: false
    };

    return await readAndCompressImage(photo, config);
  }

  getFileBase64 = async (file) => {
    let promise = new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });

    return promise;
  }

  handleDeletePhoto = (photo) => {
    if(photo.listingPhotoId) {
      this.props.deletePhoto(photo.listingPhotoId);

    } else {
      this.props.removePhotos(photo.index);
    }
    this.setState({
      isDeleteConfirmPopup: false
    })
  }

  deletePhotoConfirm = (photo, index) => {
    photo['index'] = index;
    this.setState({
      isDeleteConfirmPopup: true,
      deletedPhotoObj: photo,
    })
  }

  DownloadZip=async ()=>{
    this.props.onDownloadZip();
  }
  renderPhoto = (p, index) => {
    let photoIndex = index;
    let photo = this.props.photos[index];

    if (this.state.currentDragIndex >= 0 && this.state.dragIndex >= 0) {
      if (index === this.state.currentDragIndex) {
        photo = this.props.photos[this.state.dragIndex];

        return (
          <ImageWrapper key={index} style={{ opacity: .4 }}>
            <Image srcset={`${photo.urlThumbnail} 3x, ${`${photo.thumbnailUrl}`} 2x, ${photo.thumbnailUrl} 1x`} src={`${photo.thumbnailUrl}`} />
          </ImageWrapper>
        );
      } else {
        // dragIndexMap is the indices of the photos with the moving photo removed
        photoIndex = this.state.dragIndexMap[index > this.state.currentDragIndex ? index - 1 : index];
        photo = this.props.photos[photoIndex];
      }
    }

    return (
      <ImageWrapper
        key={index}
        className="photo-wrapper"
        style={{ opacity: this.props.photoProgress[photoIndex] ? this.props.photoProgress[photoIndex] : 1 }}
        onDragOver={(e) => this.handlePhotoDragOver(e, index)}
        onDragStart={(e) => this.handlePhotoDragStart(e, index)}
      >
        <Image draggable="true" srcset={`${photo.thumbnailUrl} 3x, ${`${photo.thumbnailUrl}`} 2x, ${photo.thumbnailUrl} 1x`} src={`${photo.thumbnailUrl}`} />
        <span className="photo-close" onClick={() => this.deletePhotoConfirm(p, index)}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
      </ImageWrapper>
    );
  }

  render = () => {
    const { photos, photoDetailObj } = this.props;
    const { isDragging, isDraggingOver, isDeleteConfirmPopup, deletedPhotoObj } = this.state;
    return (
      <React.Fragment>
        <Wrapper>
          <DropWrapper
            htmlFor="photoUpload"
            onDrop={(event) => this._onDrop(event, photos)}
            onDragOver={this._onDragOver}
            onDragEnter={this._onDragEnter}
            onDragLeave={this._onDragLeave}
            isDragging={isDragging}
            isDraggingOver={isDraggingOver}
          >
            <BasicText className="for-photo-upload">
              
              {
                photoDetailObj && photoDetailObj?.value > 0 &&
                `Upload the best photos of your home here. Your package allows for up to ${photoDetailObj.value} photos!`
              }
              {
                photoDetailObj && !photoDetailObj.value &&
                'Your package does not allows to upload any photos!'
              }
            </BasicText>
            Drag photos here or <Outlined>click here</Outlined> to select photos
            <input
              type="file"
              accept="image/*, video/*"
              multiple={true}
              id="photoUpload"
              name="photoUpload"
              onChange={(event) => this._onPhotosAdded(event, photos)}
              style={{ display: "none" }}
            />
          </DropWrapper>
          {photos && photos.length > 0 &&
            <GalleryWrapper>
              {photos.map((p, i) => this.renderPhoto(p, i))}
              
            </GalleryWrapper>
           }
          {photos && photos.length > 0 &&
            <div className="admin-btn-row flex-end">
          <a onClick={this.DownloadZip} className="ant-btn btn btn-primary admin-primary-btn download-zip">Download photos</a>
          </div>
           }
        </Wrapper>
        <ModalDiv
          title="Delete Photo"
          visible={isDeleteConfirmPopup}
          onCancel={() => this.setState({ isDeleteConfirmPopup: false })}
          onOk={() => this.handleDeletePhoto(deletedPhotoObj)}
          okText="Yes"
          cancelText="No"
          closable={false}
          destroyOnClose={true}
          className="confirm-modal"
        >
          <span className="modal-icon"><i className="fa fa-trash" aria-hidden="true"></i></span>
          <p className="static-text">Are you sure you want to delete this photo?</p>
        </ModalDiv>
       </React.Fragment>
    );
  }
}
