import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import v8n from 'v8n';
import styled from 'styled-components';
import { Map } from 'google-maps-react';

import { PropertyListingAddressSelector } from './PropertyListingAddressSelector';
import { MapMarker } from '../../Shared/Components/Icons/MapMarker';
import { onboardingActionCreatorsV2, onboardingActions } from '../../../Actions/OnboardingActionsV2';
import {
  selectedAddressSelector,
  addressSearchInputErrorSelector,
  draftListingIdSelector,
  redeemedVoucherSelector,
  addressSearchInputSelector,
  companyTypeSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import mapsService from '../../../Services/GoogleMapsService';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import ListingPreviewDesktopContainer from './ListingPreviewDesktopContainer';
import ListingPreviewMobileContainer from './ListingPreviewMobileContainer';
import { UIActionsCreators } from '../../../Actions/UIActions';
import { history, setStateToUrl } from '../../../Utilities/History';
import { alertActions } from '../../../Actions/ToastActions';
import { staticMessages, companyTypes, LYFSteps } from '../../../Constants/CommonConstants';
import Routes from '../../../Constants/Routes';
import { gTag } from '../../../Utilities/utils';
import { userActionCreators } from '../../../Actions/UserActions';
import { EmbeddedYoutubeHintsPlayer } from './EmbeddedYoutubeHintsPlayer';

const mapStyle = {
  width: '100%',
  position: 'relative',
  borderRadius: '10px',
  display: 'flex',
};

const mapContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.span`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 10rem;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
`;

const MapMarkerContainer = styled.div`
  position: absolute;
  left: 46.25%;
  top: 138px;
`;

const MapWrapper = styled.div`
  border-radius: 10px;
  position: relative;
  background: #f9f9f9;
  margin: 0 0 60px;
  width: auto;
  min-width: 300px;
  max-width: 48rem;
  height: 340px;
  &.for-bottom-gap {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: auto;
    min-width: 620px;
    max-width: 85rem;
  }
`;

const ManualAddressEnterButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: min-content;
  line-height: normal;
  border-radius: 0.375rem;
  font-weight: 600;
  height: auto;
  min-width: 2.5rem;
  font-size: 14px;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  &:hover {
    text-decoration: underline;
  }
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const FooterContaier = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ displayVoucher }) => (!displayVoucher ? 'space-between' : 'flex-end')};
  width: 100%;
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: flex-end;
  }
`;

const VoucherTextContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-right: 2rem;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    margin-left: 1.5rem;
    margin-right: 1rem;
    width: 100%;
    align-items: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

const MobileVoucherTextContainer = styled.div`
  display: none;
  flex-direction: row;
  margin-right: 2rem;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
  }
`;

const VoucherText = styled(Text)`
  margin-bottom: 0;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 0.5rem;
`;

const VoucherEnterButton = styled(ManualAddressEnterButton)`
  margin-bottom: 0;
`;

const google = window.google;
const placesService = new google.maps.places.PlacesService(document.createElement('div'));

const ListingAddressSearch = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const placeid = params.get('placeId');
  const urlMarketId = params.get('market');
  const urlInactiveListingId = params.get('inactiveListingId');
  const externalCode = params.get('externalCode');
  const cashOfferId = params.get('cashOfferId');

  const dispatch = useDispatch();
  const selectedAddress = useSelector(selectedAddressSelector);
  const [showListingPreview, setShowListingPreview] = useState(false);
  const userDetails = useSelector(userDetailsSelector);
  const redeemedVoucher = useSelector(redeemedVoucherSelector);

  const companyType = useSelector(companyTypeSelector);
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);

  const draftListingId = useSelector(draftListingIdSelector);
  const formattedAddress = useSelector(addressSearchInputSelector);
  const addressError = useSelector(addressSearchInputErrorSelector);

  useEffect(() => {
    if (placeid) {
      placesService.getDetails(
        {
          placeId: placeid,
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            dispatch(onboardingActionCreatorsV2.selectAddressV2(place));

            if (urlMarketId && urlInactiveListingId) {
              dispatch(onboardingActionCreatorsV2.getInactiveListing(urlInactiveListingId, urlMarketId));
            } else if (!urlMarketId && !urlInactiveListingId && !redeemedVoucher) {
              handleAddressSelected(place);
            }
          } else {
            dispatch(alertActions.errorAlert(staticMessages.apiError));
          }
        }
      );
    }
    if (cashOfferId) {
      dispatch(onboardingActionCreatorsV2.getUnassignedCashOffer(cashOfferId));
    }
  }, []);

  const handleAddressSelected = (address) => {
    setIsAddressInvalid(false);
    const isPostalCode = address.address_components.filter((c) => c.types.includes('postal_code')).length; // if address doesn't have postal code then it's invalid

    if (isPostalCode < 1) {
      setIsAddressInvalid(true);
      return;
    }
    dispatch(onboardingActionCreatorsV2.selectAddressV2(address));
    dispatch(onboardingActionCreatorsV2.checkAddressV2());
  };

  const onMapMove = async (dat, coords) => {
    const lat = coords.center.lat();
    const lng = coords.center.lng();

    const data = await mapsService.geocoder.geocode({ location: { lat, lng } });
    const result = data?.results?.[0];
    if (result) {
      handleAddressSelected(result);
      setStateToUrl(Routes.ONBOARDING_V2, {
        placeId: result.place_id,
        ...(externalCode && { externalCode }),
        ...(cashOfferId && { cashOfferId }),
      });
    }
  };

  const onAddressClear = () => {
    setIsAddressInvalid(false);
    dispatch(onboardingActionCreatorsV2.clearAddressV2());

    if (userDetails.token && draftListingId) {
      dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
    }

    setStateToUrl(Routes.ONBOARDING_V2, {
      ...(externalCode && { externalCode }),
      ...(cashOfferId && { cashOfferId }),
    });
  };

  const validateField = (value) => {
    let isError = false;
    if (!v8n().not.empty().not.null().test(value)) {
      dispatch(onboardingActionCreatorsV2.setSearchInputError('Please enter your street address'));
      isError = true;
    } else {
      dispatch(onboardingActionCreatorsV2.setSearchInputError(''));
    }

    return isError;
  };

  const handleContinue = () => {
    if (validateField(formattedAddress) || !selectedAddress) {
      return;
    }

    if (userDetails?.token) {
      gTag({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: [
              {
                address: `${selectedAddress?.formatted}`,
              },
            ],
          },
        },
      });

      const step = companyType === companyTypes.NETWORK ? LYFSteps.PLAN_SELECTOR : LYFSteps.PRICE_AND_COMISSIONS;
      dispatch(onboardingActionCreatorsV2.setListingStepV2(step));
      return;
    }

    dispatch(UIActionsCreators.openSignUpModal());
    dispatch(userActionCreators.setLoginCallBackAction({ type: onboardingActions.SET_LISTING_STEP_V2, payload: 2 }));
  };

  const switchToManualAddressEnter = () => {
    setIsAddressInvalid(false);
    dispatch(onboardingActionCreatorsV2.setSearchInputError(''));
    dispatch(onboardingActionCreatorsV2.setManualAddressEnterV2(true));
    history.replace({
      pathname: Routes.ONBOARDING_V2,
      search: '',
    });
  };

  const addressInvalid =
    addressError.error || (isAddressInvalid ? 'Oops! Address seems to be Incorrect. Please try again' : '');
  const mapCoords = {
    lat: selectedAddress?.location?.lat,
    lng: selectedAddress?.location?.lng,
  };

  return (
    <Container>
      {showListingPreview ? (
        <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
      ) : (
        <>
          <FormContainer>
            <TitleContainer>
              <SubTilteText>List your property</SubTilteText>
              <StepsText>1/9</StepsText>
            </TitleContainer>
            <TitleText>Property Address</TitleText>
            <Text>
              Enter your address below to get started. Reach millions of buyers, with free, nationwide access to the MLS
              and hundreds of top real estate websites.
            </Text>
            <EmbeddedYoutubeHintsPlayer mobile={true} width="296" height="536" />
            {!redeemedVoucher && (
              <ManualAddressEnterButton onClick={switchToManualAddressEnter}>
                Enter address manually
              </ManualAddressEnterButton>
            )}
            <>
              <PropertyListingAddressSelector
                handleAddressSelected={handleAddressSelected}
                onClear={onAddressClear}
                userDetails={userDetails}
                disabled={redeemedVoucher || (draftListingId && userDetails?.token)}
                name="address"
                title="Address"
                label="Enter property address or ZIP code"
                value={formattedAddress}
                error={addressInvalid}
                type="text"
                autoComplete="off"
              />
              {selectedAddress?.location && (
                <MapWrapper>
                  <Map
                    google={window.google}
                    draggable={false}
                    center={mapCoords}
                    initialCenter={mapCoords}
                    style={{
                      ...mapStyle,
                      height: selectedAddress ? '100%' : '0',
                    }}
                    containerStyle={mapContainerStyle}
                    zoom={15}
                    onDragend={onMapMove}
                  >
                    <MapMarkerContainer>
                      <MapMarker />
                    </MapMarkerContainer>
                  </Map>
                </MapWrapper>
              )}
              {!redeemedVoucher && (
                <MobileVoucherTextContainer>
                  <VoucherText>Already paid for an MLS Voucher?</VoucherText>
                  <VoucherEnterButton
                    onClick={() => {
                      dispatch(onboardingActionCreatorsV2.setVoucherEnterV2(true));
                    }}
                  >
                    Redeem it here.
                  </VoucherEnterButton>
                </MobileVoucherTextContainer>
              )}
              <ButtonsContainer>
                <MobilePreviewButton
                  onClick={() => {
                    setShowListingPreview(true);
                  }}
                >
                  <EyeButton />
                </MobilePreviewButton>
                <FooterContaier displayVoucher={redeemedVoucher}>
                  {!redeemedVoucher && (
                    <VoucherTextContainer>
                      <VoucherText>Already paid for an MLS Voucher?</VoucherText>
                      <VoucherEnterButton
                        onClick={() => {
                          dispatch(onboardingActionCreatorsV2.setVoucherEnterV2(true));
                        }}
                      >
                        Redeem it here.
                      </VoucherEnterButton>
                    </VoucherTextContainer>
                  )}
                  <SubmitButton type="button" onClick={handleContinue} disabled={isAddressInvalid}>
                    Continue
                  </SubmitButton>
                </FooterContaier>
              </ButtonsContainer>
            </>
          </FormContainer>
          <ListingPreviewDesktopContainer />
        </>
      )}
    </Container>
  );
};

export default ListingAddressSearch;
