import React from 'react';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import routes from '../../../Constants/Routes';
import { LockIcon } from '../Components/Icons/Lock';
import { Logo } from '../Components/Icons/Logo';
import { DrawerCloseIcon } from '../Components/Icons/DrawerCloseIcon';
import { roleType } from '../../../Constants/CommonConstants';
import { NavLink, LinksContainer } from '../../../Assets/Style/commonStyleComponents';
import { normalizeId } from '../../../Utilities/normalizeId';
import { useDispatch } from 'react-redux';
import { UIActionsCreators } from '../../../Actions/UIActions';

const Drawer = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  transform: translateX(0%) translateY(0px) translateZ(0px);
  width: 100%;
  outline: transparent solid 2px;
  outline-offset: 2px;
  z-index: 100;
  max-height: 100vh;
  color: inherit;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.lavender};
`;

const DrawerContent = styled.div`
  width: 100%;
  margin-inline: auto;
  max-width: 80rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.lavender};
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem;
`;

const LinkListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DrawerNavItem = styled.a`
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  font-size: 20px;
  line-height: 32px;
  color: #242B26;
  margin: 0;
  display: block;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  &:hover {
    text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
    color: #242B26;
  }
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
    &:not(:first-child) {
      margin-top: 1rem;
    }
    font-size: 16px;
    line-height: 24px;
  }
}
`;

const DrawerNavButton = styled.button`
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  font-size: 20px;
  line-height: 32px;
  color: #242B26;
  margin: 0;
  display: block;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  background: none;
  &:hover {
    text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
    color: #242B26;
  }
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
    &:not(:first-child) {
      margin-top: 1rem;
    }
    font-size: 16px;
    line-height: 24px;
  }
}
`;

const DrawerCloseButton = styled.button`
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  height: 32px;
  width: 32px;
  cursor: pointer;
  background-color: #00000000;
  outline: transparent solid 2px;
`;

const DrawerLogo = styled(Logo)`
  height: 2.5rem;
`;

const DrawerDivider = styled.hr`
  border-width: 0px 0px 1px;
  border-color: ${({ theme }) => theme.colors.mouseballgray[200]}
  border-style: solid;
  width: 97%;
  margin-left: 1.5%;
`;

const DrawerButton = styled.button`
  height: 40px;
  background-color: ${({ theme }) => theme.dark};
  border-radius: 48px;
  color: ${({ theme }) => theme.light} !important;
  font-size: 16px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  outline: none !important;
  justify-content: center;
  padding: 11px 16px 9px 16px;
  width: fit-content;
  margin-top: 1.5rem;
  display: inline-flex;
  &:hover {
    color: ${({ theme }) => theme.light} !important;
  }
`;

const NavbarDrawer = ({
  onClose,
  userData,
  logout,
  switchPortal,
  openLogin,
  navBarContent,
  navBarContentLoading,
  buySideUrl,
}) => {
  const isAdminSwitched = userData?.isAdminSwitchUser;
  const { token, role } = userData;
  const dispatch = useDispatch();

  return (
    <Drawer>
      <DrawerContent>
        <DrawerCloseButton onClick={onClose}>
          <DrawerCloseIcon className="drawer-close-icon" />
        </DrawerCloseButton>
        <LogoContainer>
          <Link to="/">
            <DrawerLogo />
          </Link>
        </LogoContainer>
        <LinkListContainer>
          {navBarContent?.navItems?.length > 0 &&
            !navBarContentLoading &&
            navBarContent.navItems.map((i, idx) => (
              <DrawerNavItem
                key={idx}
                href={`${buySideUrl}${i?.url}`}
                title={i?.name}
                active={i?.url.includes('sell')}
                id={normalizeId(i?.name, 'top')}
              >
                {i?.name}
              </DrawerNavItem>
            ))}
          {!token ? (
            <DrawerButton onClick={openLogin} title="Log in">
              <LockIcon />
              <span className="login-button-title">Log in</span>
            </DrawerButton>
          ) : (
            <>
              <DrawerNavButton
                title="Upgrade"
                id="userNavi-Upgrade"
                onClick={() => dispatch(UIActionsCreators.openIntelligenceModal())}
              >
                Upgrade
              </DrawerNavButton>
              <DrawerNavItem href={routes.MY_FAVORITES} title="My Favorites" id="userNavi-MyFavorites">
                My Favorites
              </DrawerNavItem>
              <DrawerNavItem href={routes.SAVED_SEARCHES} title="Saved Searches" id="userNavi-SavedSearches">
                Saved Searches
              </DrawerNavItem>
              {role === roleType.WHOLESALE && <DrawerNavItem href={`${routes.DASHBOARD}`}>My Voucher</DrawerNavItem>}
              {[roleType.RETAIL, roleType.NetworkUser, roleType.INSTITUTIONAL, roleType.WHOLESALE_CUSTOMER].includes(
                role
              ) && (
                <DrawerNavItem id="userNavi-MyListings" href={`${routes.DASHBOARD}`}>
                  My Listings
                </DrawerNavItem>
              )}
              {!isAdminSwitched && (role === roleType.ADMIN || role === roleType.SuperAdmin) && (
                <DrawerNavItem href={`${routes.ADMIN_MLS_SUBMISSION}`}>Back to Admin</DrawerNavItem>
              )}
              {isAdminSwitched && <DrawerNavItem onClick={switchPortal}>Switch Portal</DrawerNavItem>}
              <DrawerNavItem id="userNavi-Settings" href={`${routes.SETTINGS}`}>
                Settings
              </DrawerNavItem>
              <DrawerDivider />
              {!isAdminSwitched && (
                <DrawerNavItem href="#" id="userNavi-LogOut" onClick={logout}>
                  Sign Out
                </DrawerNavItem>
              )}
            </>
          )}
        </LinkListContainer>
      </DrawerContent>
    </Drawer>
  );
};

export default withTheme(NavbarDrawer);
