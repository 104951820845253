import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import v8n from 'v8n';

import { sellerNotificationActionCreators } from '../../../Actions/SellerNotificationActions';
import { validationMessages } from '../../../Constants/CommonConstants';
import Routes from '../../../Constants/Routes';
import { checkingMlsSelector } from '../../../Reducers/SellerNotificationReducer';
import { history } from '../../../Utilities/History';

const MainContainer = styled.form`
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

const InputContainer = styled.div`
  min-width: 18rem;
  position: relative;
  margin-right: 1rem;
  width: auto;

  input {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
      color: #F56565;
    }
  }
  
  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    min-width: 24rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    min-width: 26rem;
    margin-right: 2rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-width: 28rem;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto
  min-width: 6rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 1rem;
  margin-right: 1rem;
`;

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[500]};
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) => smallLabel ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `: ''}
  }
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
`;

const MlsIdForm = () => {
  const isOfferForm = history.location.pathname.includes(Routes.OFFER_SUBMIT);
  const dispatch = useDispatch();
  const checkingMls = useSelector(checkingMlsSelector);
  const [mlsId, setMlsId] = useState({
    rules: [{
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    }],
    value: "",
    error: null,
  });

  const handleFieldChanged = (e) => {
    e.persist();
    setMlsId(pr => ({ ...pr, value: e.target.value }));
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setMlsId(pr => ({ ...pr, error: '' }));
    if (!mlsId.rules[0].rule.test(mlsId.value)) {
      setMlsId(pr => ({ ...pr, error: mlsId.rules[0].message }));
      return;
    }
    history.push(`${isOfferForm ? Routes.OFFER_SUBMIT : Routes.SHOWING_REQUEST}/${mlsId.value}`)
    dispatch(sellerNotificationActionCreators.сheckMls(mlsId.value));
  };

  return (
    <MainContainer onSubmit={handleSearch}>
      <InputContainer className={mlsId.error ? 'invalid' : ''}>
        <input
          placeholder=" "
          type="text"
          name="mlsId"
          id="mlsId"
          value={mlsId.value}
          onChange={handleFieldChanged}
        />
        <InputLabel smallLabel={mlsId.value} htmlFor="mlsId">MLS ID*</InputLabel>
        {mlsId.error && (
          <ErrorMessage>
            {mlsId.error}
          </ErrorMessage>
        )}
      </InputContainer>
      <SubmitButton type='submit' disabled={checkingMls}>
        {checkingMls ? <Loader animation="border" size="sm" /> : "Search"}
      </SubmitButton>
    </MainContainer>
  )
};

export default MlsIdForm;