import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  draftListingDeletingIdSelector,
  filtredListingsSelector,
  listingViewTypeSelector,
  listingsSelector,
} from '../../../Reducers/DashboardReducer';
import { ListingItem } from './ListingItem';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import { ModalDiv } from '../../../Assets/Style/commonStyleComponents';
import { Link } from 'react-router-dom';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import Routes from '../../../Constants/Routes';
import { roleType } from '../../../Constants/CommonConstants';
import { ListingsListFilters } from './ListingsListFilters';
import { SupportNeedIcon } from '../../Shared/Components/Icons/SupportNeedIcon';
import { ListViewIcon } from '../../Shared/Components/Icons/ListViewIcon';
import { GridViewIcon } from '../../Shared/Components/Icons/GridViewIcon.js';
import { useTitle } from '../../../Utilities/Hooks';
import { isFetchingSelector } from '../../../Reducers/DashboardReducer';
import { isCashModalOpenedSelector } from '../../../Reducers/V2/CashOfferReducer';
import CashOfferModal from '../../Admin/V2/Components/CashOffer/CashOfferModal';
import { BuyingPropertiesCta } from './BuyingPropertiesCta.js';
// import { ZeroMortgageAd } from "./ZeroMortgageAd";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    align-items: flex-start;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100vw;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: flex-start;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    min-width: 60rem;
  }
`;

const ContactsBoxWrapper = styled.div`
  display: none;
  align-items: center;
  margin-top: 3rem;
  margin-left: 2rem;
  width: 15rem;
  height: 22rem;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.grayBackground};
  flex-direction: column;
}
  
  @media(min-width: ${({ theme }) => theme.breakpoints.xl}){
    display: flex;
  }
`;

const MobileContactsBoxWrapper = styled(ContactsBoxWrapper)`
  display: flex;
  width: auto;
  margin-top: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: none;
  }
`;

const ContactsBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.light};

  button {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;

const ContactsBoxTitleText = styled.span`
  font-size: 18px;
  line-height: 28px;
`;

const ContactsBoxText = styled.div`
  margin-top: 0.75rem;
  font-size: 14px;
  line-height: 20px;
  color: #5b605c;
`;

const ListingListHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }
`;

const ListingListTitleContainer = styled.div`
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1rem;
  }
`;

const ListingListTitle = styled.div`
  font-size: 24px;
  line-height: 35px;
`;

const AddListLink = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.dark};

  &:hover {
    color: ${({ theme }) => theme.dark};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1rem;
  }
`;

const ListingProgressListItemDivider = styled.span`
  display: flex;
  border-bottom: solid 1px #d3dada;
  width: calc(100% - 3rem);
  heigth: 0;
  margin-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

const ListingListContainer = styled.div`
  display: flex;
  flex-direction: ${({ gridView }) => (gridView ? 'row' : 'column')};
  flex-wrap: ${({ gridView }) => (gridView ? 'wrap' : 'nowrap')};
  width: 100%;
`;

const ViewTypeSelectorContainer = styled.div`
  display: flex;
  width: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.75rem;
  padding-left: 1rem;
  padding-right: 0;
  width: 4.5rem;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ViewTypeButton = styled.button`
  background: none;
`;

const ListingListTitleHighlight = styled.div`
  background-color: ${({ theme, action }) => action === 'buying' ?  theme.lightLavender : theme.lightGreen};
  position: absolute;
  width: 4.5rem;
  height: 1.25rem;
  top: 18px;
  right: -1px;
  border-radius: 4px;
  z-index: -1;
`;

const AddpropertyAndViewTypeButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 90%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    width: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: flex-start;
  }
`;

const ListingListTitleHighlightContainer = styled.span`
  padding-left: 0.3rem;
  position: relative;
`;

const AddListButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 10rem;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  height: 3rem;
  font-size: 0.75rem;
  background-color: ${({ theme, plain }) => (plain ? theme.light : theme.colors.violet[200])};
  color: ${({ theme }) => theme.colors.green[900]};
  align-self: center;

  &:hover {
    color: ${({ theme }) => theme.dark};
  }
`;

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 10rem;
    margin-bottom: 3rem;
  }
`;

const ContactBox = () => (
  <ContactsBoxContainer>
    <ContactsBoxTitleText>Questions?</ContactsBoxTitleText>
    <ContactsBoxText>
      We're here to help
      <br />
      7 days a week!
      <br />
      {window.Intercom && <button onClick={() => window.Intercom('show')}>Start chat</button>}
      <br />
      (866) 534-3726 info@unrealestate.com
    </ContactsBoxText>
  </ContactsBoxContainer>
);

const ListingListHeader = ({ action }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(userDetailsSelector);
  const isAllowedToCreateListing = [
    roleType.RETAIL,
    roleType.NetworkUser,
    roleType.INSTITUTIONAL,
    roleType.WHOLESALE_CUSTOMER,
  ].includes(userDetails.role);

  return (
    <ListingListHeaderContainer>
      <ListingListTitleContainer>
        <ListingListTitle>
          Properties I'm
          <ListingListTitleHighlightContainer>
            {action}
            <ListingListTitleHighlight action={action} />
          </ListingListTitleHighlightContainer>
        </ListingListTitle>
      </ListingListTitleContainer>
      {action === 'selling' && <ListingProgressListItemDivider />}

      <AddpropertyAndViewTypeButtonsContainer>
        {action === 'buying' && (
          <ViewTypeSelectorContainer>
            <ViewTypeButton onClick={() => dispatch(dashboardActionCreators.setListingListViewType('list'))}>
              <ListViewIcon />
            </ViewTypeButton>
            <ViewTypeButton onClick={() => dispatch(dashboardActionCreators.setListingListViewType('grid'))}>
              <GridViewIcon />
            </ViewTypeButton>
          </ViewTypeSelectorContainer>
        )}
        {isAllowedToCreateListing && action === 'selling' && (
          <AddListLink title="Create a listing" to={Routes.ONBOARDING_V2} id="listing-Addlist">
            + Create a listing
          </AddListLink>
        )}
      </AddpropertyAndViewTypeButtonsContainer>
      {action === 'selling' && <ListingProgressListItemDivider />}
    </ListingListHeaderContainer>
  );
};

export const ListingsList = () => {
  const dispatch = useDispatch();
  const filtredListings = useSelector(filtredListingsSelector);
  const listings = useSelector(listingsSelector);
  const userDetails = useSelector(userDetailsSelector);
  const deletingDraftListingId = useSelector(draftListingDeletingIdSelector);
  const viewType = useSelector(listingViewTypeSelector);
  const isFetching = useSelector(isFetchingSelector);
  const [showDeleteDraftListingModal, setShowDeleteDraftListingModal] = useState(false);
  const [draftListingToDelete, setDraftListingToDelete] = useState(null);
  const isCashModalOpened = useSelector(isCashModalOpenedSelector);

  const gridView = viewType === 'grid';
  const showEmptyListBlock =
    !isFetching &&
    listings.length < 1 &&
    [roleType.RETAIL, roleType.NetworkUser, roleType.INSTITUTIONAL, roleType.WHOLESALE_CUSTOMER].includes(
      userDetails?.role
    );

  useTitle('My Listings | Unreal Estate');

  const handleOpenDeleteDraftListingModal = (draftListing) => {
    setShowDeleteDraftListingModal(true);
    setDraftListingToDelete(draftListing);
  };

  return (
    <Wrapper>
      <ListingsListFilters />
      <ListContainer>
        <>
          <ListingListContainer gridView={gridView}>
            <ListingListHeader displayAddListing={false} action={'buying'} />
            <BuyingPropertiesCta />
            <ListingListHeader action={'selling'} />
            {filtredListings.map((listing) => (
              <ListingItem
                key={listing?.draftId || listing?.id}
                listing={listing}
                onDelete={(id) => handleOpenDeleteDraftListingModal(listing)}
                deletingListingId={deletingDraftListingId}
                gridView={gridView}
              />
            ))}
            {showEmptyListBlock && (
              <EmptyListContainer>
                <h2>No listings found</h2>
                <AddListButton title="Create a listing" to={Routes.ONBOARDING_V2} id="listing-Addlist">
                  Create a listing
                </AddListButton>
              </EmptyListContainer>
            )}
          </ListingListContainer>
        </>
        {/* uncomment next line for "UD-2021Zero-Mortgage: User Dashboard Ads" */}
        {/* <RightColumnContainer> */}

        <ContactsBoxWrapper>
          <ContactBox />
          <SupportNeedIcon />
        </ContactsBoxWrapper>
        {/* uncomment next line for "UD-2021Zero-Mortgage: User Dashboard Ads" */}
        {/* <ZeroMortgageAd /> */}
        {/* </RightColumnContainer> */}
      </ListContainer>
      <div>
        <MobileContactsBoxWrapper>
          <ContactBox />
          <SupportNeedIcon />
        </MobileContactsBoxWrapper>
        {/* uncomment next line for "UD-2021Zero-Mortgage: User Dashboard Ads" */}
        {/* <ZeroMortgageAd mobile /> */}
      </div>
      {showDeleteDraftListingModal && (
        <ModalDiv
          title="Delete Draft Listing Confirmation"
          visible={showDeleteDraftListingModal}
          onCancel={() => {
            dispatch(dashboardActionCreators.deleteDraftListings(draftListingToDelete.draftId));
            setShowDeleteDraftListingModal(false);
            setDraftListingToDelete(null);
          }}
          onOk={() => {
            setShowDeleteDraftListingModal(false);
            setDraftListingToDelete(null);
          }}
          okText="No"
          cancelText="Yes"
          closable={true}
          destroyOnClose={true}
          className="confirm-modal"
        >
          {draftListingToDelete?.cashOfferId 
            ? <p className="static-text">
              Deleting will remove your Submitted Cash Offer. Please submit “Get your cash offer” again after deleting
            </p>
            : <p className="static-text">
              Are you sure you want to DELETE draft listing, it cannot be undone?
            </p>}
        </ModalDiv>
      )}
      {isCashModalOpened && <CashOfferModal />}
    </Wrapper>
  );
};
