import React from 'react';
import styled, { withTheme } from 'styled-components';
import BuyingPropertyForm from '../../Shared/Components/BuyingPropertyForm';
import { KeyIcon } from '../../Shared/Components/Icons/KeyIcon';

const ModalOverlay = styled.div`
  opacity: 1;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
  z-index: 1400;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1400;
  justify-content: center;
  align-items: flex-start;
  background: unset;
  overflow: auto;
  font-family: 'National-Regular';
  font-size: 1.25rem;
`;

const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 22rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius:  0.375rem;
  background: white;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  z-index: 1400;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 28rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    width: 28rem;
  }
}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  font-size: 1.25rem;;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  align-items: center;

  p {
    margin-bottom: 0;
  }
}
`;

const BuyingPropertyModal = ({ closeModal }) => {

  return (
    <ContentContainer>
      <ModalOverlay />
      <ModalContainer>
        <HeaderContainer>
          <KeyIcon />
          Your Buying Preferences
        </HeaderContainer>
        <BuyingPropertyForm
          onCancel={closeModal}
          onSubmit={closeModal}
          submitButtonText={"Submit"}
          cancelButtonText={"Cancel"}
        />
      </ModalContainer>
    </ContentContainer>
  );
};

export default BuyingPropertyModal;
