/* eslint-disable no-loop-func */
import React, { useState } from 'react';
import styled from "styled-components";
import {  useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { DatePicker } from "antd";
import moment from "moment";
import enums from "../../../Constants/Enums";
import { editListingValue, formPrefillTypes } from '../../../Constants/CommonConstants';
import { dataService } from '../../../Services/DataService';
import { FormHeading } from "../../../Assets/Style/commonStyleComponents";
import Enums from "../../../Constants/Enums";
import CurrencyInput from 'react-currency-input-field';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import {
  activeListingFormIndexSelector,
  listingFormsFieldsSelector,
  listingFormsSelector,
  propertyPriceSelector
} from '../../../Reducers/OnboardingReducerV2';

const FormWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  > h1,
  > h2,
  > h3 {
    font-weight: 100;
    margin: 0;
  }

  > h3 {
    font-size: 12px;
    color: ${({ theme }) => theme.secondary};
  }

  > h3 a {
    font-size: 12px;
  }
  .ant-steps-item-finish {
    .ant-steps-icon {
      top: -3px;
    }
  }
  .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.65);
  }
`;

const Form = styled.article`
  flex: 2;
  display: block;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  overflow: auto;
  height: 100%;
  border: 1px solid #dadada;
  border-radius: 6px;
  ul,
  ol {
    margin-left: 17px;
    ul,
    ol {
      margin-left: 0;
    }
  }
  h1 {
    font-size: 26px;
    font-weight: 600;
    color: #242B26;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0;
  }
  h2 {
    font-size: 16px;
    color:#242B26;
    text-align: center;
    margin-bottom: 25px;
  }
  li {
  }
`;

const Group = styled.section``;

const Section = styled.div`
  display: block;
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 26px;

  p {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 26px;
  }

  strong {
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: 2px;
    padding: 5px;
    margin: 0 2px;
  }
  select {
    height: 38px;
    min-width: 140px;
  }

  @media(max-width: ${({ theme }) => theme.breakpoints.md}){
    width: 500px;
  }

  @media(max-width: 37em){
    width: 400px;
  }

  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
    width: 250px;
  }

`;

const FormDatePicker = styled.div`
  display: inline;
  padding-left: 3px;
  input {
    border-radius: 0;
    border: 1px solid #B698D9;
    font-size: 15px;
    &:focus {
      box-shadow: none;
    }
  }
`;

const TextAreaWrapper = styled.div`
  min-width: 600px;
  display: flex;
  align-items: flex-end;

  @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
    min-width: 400px;
    max-width: 500px;
    padding-right: ${({ required }) => required ? '0' : '50px'};
  }

  @media(max-width: ${({ theme }) => theme.breakpoints.md}){
    max-width: 400px;
    padding-right: ${({ required }) => required ? '0' : '50px'};
  }

  @media(max-width: ${({ theme }) => theme.breakpoints.sm}){
    min-width: 250px;
    max-width: 300px;
    padding-right: ${({ required }) => required ? '0' : '50px'};
  }
`;


const {
  date1,
  date2,
  date3,
} = editListingValue;

const inputErrorClass = "error-input";
const inputErrorClassOption = "error-input-option";
const inputErrorClassChecbox = "error-input-checkbox";
const disabled_input='disabled-input';
const disabled_input_overlay='disabled-input-overlay';
let overlaySignFlag = enums.NUMERIC_VALUE.ZERO;
let showOwner = enums.NUMERIC_VALUE.TWO;

function disabledDate(current) {
  return current && current < moment().add(-1, "days");
}

const ContractSignForm = ({
  openFormInstruction,
  errors,
  setErrors,
  showSingCount,
  setShowSignCount,
}) => {
  const dispatch = useDispatch();
  const listingForms = useSelector(listingFormsSelector);
  const activeListingFormIndex = useSelector(activeListingFormIndexSelector);
  const formFields = useSelector(listingFormsFieldsSelector);
  const form = listingForms[activeListingFormIndex];
  const [linkedChangeValue, setLinkedChangeValue] = useState({});
  const [targetValues, setTargetValues ] = useState({});
  const propertyPrice = useSelector(propertyPriceSelector);

  const [originalFormFieldValue, setOriginalFormFieldValue] = useState(null);
  const [notInLinkedChangeValue, setNotInLinkedChangeValue ] = useState({});

// formStatus === 3 means that form already submitted/signed ??
  const isFormSigned = listingForms.some((item) => (item["formId"] === 6 || item["formId"] === 1) && item["formStatus"] === 3);

  const getCheckboxLabelLength = (node) => {
    
  let val=0;
  if(node?.prev?.name === editListingValue.strong){
    val= node?.prev?.children?.[0]['data'].length;
    return val;
  }
  if(!node?.prev){
    return val;
  }
    return getCheckboxLabelLength(node?.prev);
  }

  const handleFormFieldFocus = (e) => {
    setOriginalFormFieldValue(e.target.value)
  };

  const saveLinkedData = () => {
    let linkedObj = dataService.getLocal("isLinkedArray");
    let preFilledArr = [];
    let stepFormArray = listingForms[activeListingFormIndex];

    if(stepFormArray && linkedObj){
        let listingFormField = stepFormArray?.groups || [];
        for(let h = 0; h < listingFormField.length; h++)
        {
          let listingSection = listingFormField?.[h].sections;
          for(let i = 0; i < listingSection.length; i++)
          {
            let sectionArr = listingSection?.[i].fields;
            for (let j = 0; j < sectionArr.length; j++) {
              
                let displayName = sectionArr[j].displayName;
                for(let k = 0; k < linkedObj.length; k++)
                {
                    if (linkedObj[k].hasOwnProperty(displayName)) {
                      
                      preFilledArr.push({
                        formFieldId: sectionArr[j].formFieldId,
                        value: linkedObj[k][displayName],
                      })
        
                    } 
                }
              }
          }
        }
    }

    if (preFilledArr.length > 0) {

      
      let PreFillArr = listingForms[activeListingFormIndex];
      let userFormId=PreFillArr?.userFormId
      const prefillReqObj = {
        userFormId:  userFormId,
        previewFields: preFilledArr,
      }
      dispatch(onboardingActionCreatorsV2.savePreviewFormFieldV2(prefillReqObj, () => {}))
    }
  }

  const handleFormFieldBlur = (e,userFormID,inputDataType) => {
    if (inputDataType === enums.InputTypes.CURRENCY && e.target.value) {
      e.target.value = parseFloat(e.target.value.replace(/\$|,/g, ''));
    }

    if (originalFormFieldValue !== e.target.value) {
      const fieldData = { ...formFields };
      if(fieldData[e.target.name].isLinked){
        saveLinkedData();
      }
      dispatch(onboardingActionCreatorsV2.saveFormFieldV2(form.userFormId, e.target.name, e.target.value))
    }

    setOriginalFormFieldValue(null)
  };


const handleFormFieldSelect = (field, fieldId, isTypeDate, e) => {
  const tempFormFields = {...formFields};
  const errorsTemp = {...errors};
  const selectedValue = isTypeDate ? e._d : e.target.value;
  
  if(
    (typeof selectedValue === editListingValue.string)
    && selectedValue.toLowerCase() == editListingValue.oneOwner){
    setShowSignCount(1)
  }
  else if(
    (typeof selectedValue === editListingValue.string)
    && selectedValue.toLowerCase() == editListingValue.twoOwner) {
    setShowSignCount(2)
  }
  else if(
    (typeof selectedValue === editListingValue.string)
    && selectedValue.toLowerCase() == editListingValue.threeOwner) {
    setShowSignCount(3)
  }

  tempFormFields[fieldId].value = selectedValue;
  delete errorsTemp[fieldId];



  setErrors(errorsTemp)
  dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2(tempFormFields));
  dispatch(onboardingActionCreatorsV2.saveFormFieldV2(form.userFormId, fieldId, selectedValue))
};

  const handleFormMultileCheckboxField = (e, fieldId) => {
    const tempFormFields = {...formFields};
    const errorsTemp = {...errors};
    const old_value = tempFormFields[fieldId]['value'];

    let newval='';

    if(old_value && e.target.checked) {
      newval = old_value + ',' + e.target.value;
    } else if (old_value) {
      newval = old_value.split(',').filter(val => val !== e.target.value).join(',')
    } else {
      newval = e.target.value;;
    }
    tempFormFields[fieldId].value = newval;
    delete errorsTemp[fieldId];

    dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2(tempFormFields));
    setErrors(errorsTemp)
    dispatch(onboardingActionCreatorsV2.saveFormFieldV2(form.userFormId, fieldId, newval))
  };

  const handleFormFieldChangedCurrency = (e, field, formId) => {

    const tempFormFields = {...formFields};
    const errorsTemp = {...errors};
    tempFormFields[field].value = e;
    let linkedArr = dataService.getLocal("isLinkedArray");



  
    if (tempFormFields[field].isLinked) {
      Object.keys(tempFormFields).forEach((key) => {
          if (tempFormFields[field].displayName === tempFormFields[key].displayName) {
            tempFormFields[key].value = e;
            linkedChangeValue[key]= e;
          }
          
      }) 
  
    if(Object.keys(notInLinkedChangeValue).length > 0){
    let  notLinkedarray = { notInLinkedChangeValue };
    let linkedname = tempFormFields[field].displayName;
      for (const [key, value] of Object.entries(notInLinkedChangeValue)) {
        if(value === linkedname){
          delete notLinkedarray[key];
          setNotInLinkedChangeValue(notLinkedarray)
        }
      }
    }
  
  if(linkedArr){
  
      let linkedname = tempFormFields[field].displayName;
      let formPrefillTypeId = tempFormFields[field].formPrefillTypeId;
    
      let LinkedObj = {
        [linkedname]: e,
        formPrefillTypeId,
      };

      let index = linkedArr.findIndex(it => Object.keys(it)[0] === linkedname);
      if (index === -1) {
          linkedArr.push(LinkedObj);
        } else {
          linkedArr[index] = LinkedObj
        }
        dataService.setLocal("isLinkedArray",linkedArr);
    
    } else {
      let linkedArr = [];
      let linkedname = tempFormFields[field].displayName;
      let formPrefillTypeId = tempFormFields[field].formPrefillTypeId;
      let LinkedObj = {
        [linkedname]: e,
        formPrefillTypeId
      };

      linkedArr.push(LinkedObj);
      dataService.setLocal("isLinkedArray",linkedArr);
    }
    }else{
      let linkedArr = dataService.getLocal("isLinkedArray");
      let linkedname = tempFormFields[field].displayName;
      if(linkedArr){
        let index = linkedArr.findIndex(it => Object.keys(it)[0] === linkedname);
        if (index >= 0) {
          setNotInLinkedChangeValue(pr => ({
            ...pr,
            [field]: linkedname,
          }))
        }
      }
    }
    delete errorsTemp[field.Id];
    if(!formId){
      formId = form.formId;
    }
  
    dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2(tempFormFields));
    setErrors(errorsTemp)
    // dispatch(onboardingActionCreatorsV2.saveFormFieldV2(form.userFormId, field, e))
  };

  const handleFormSingleCheckboxField = (e, fieldId) => {
    const tempFormFields = {...formFields};
    const errorsTemp = {...errors};
    let newval = '';
    if (e.target.checked) {
      newval = e.target.value;
    }
    tempFormFields[fieldId].value = newval;
    delete errorsTemp[fieldId];
    dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2(tempFormFields));
    setErrors(errorsTemp)
    dispatch(onboardingActionCreatorsV2.saveFormFieldV2(form.userFormId, fieldId, newval))
  };

  const handleFormFieldChanged = (e, fieldId, formId, inputDataType) => {
    e.persist();
    const re = /^[0-9\b]+$/;
    let isCheck=false;
    if (
      inputDataType === 3 && (e.target.value === '' || re.test(e.target.value))
    ) {
      isCheck = true;
    }
    if (
      inputDataType === 4
      && (e.target.value === '' || Number(e.target.value))
    ) {
      isCheck = true;
    }
    if (!inputDataType) {
      isCheck = true;
    }

    if (isCheck) {
      if (
        (typeof e.target.value === editListingValue.string)
        && e.target.value.toLowerCase() == editListingValue.oneOwner) {
        setShowSignCount(1)
      } else if (
        (typeof e.target.value === editListingValue.string)
        && e.target.value.toLowerCase() == editListingValue.twoOwner) {
        setShowSignCount(2)
      } else if (
        (typeof e.target.value === editListingValue.string)
        && e.target.value.toLowerCase() == editListingValue.threeOwner) {
        setShowSignCount(3)
      }

    const tempFormFields = {...formFields};
    const errorsTemp = {...errors};
    if (inputDataType === Enums.InputTypes.CURRENCY) {
      tempFormFields[fieldId].value = e;
    } else {
    tempFormFields[fieldId].value = e.target.value;
    }

  if (tempFormFields[fieldId].isLinked) {
    Object.keys(tempFormFields).forEach((key) => {
        if(tempFormFields[fieldId].displayName === tempFormFields[key].displayName){
          tempFormFields[key].value = e.target.value;
          setLinkedChangeValue(pr => ({
            ...pr,
            [key]: e.target.value,
          }))
        }
        
    }) 

    let linkedArr = dataService.getLocal("isLinkedArray");

    if(Object.keys(notInLinkedChangeValue).length>0){
    let  notLinkedarray = {...notInLinkedChangeValue};
    let linkedname = tempFormFields[fieldId].displayName;
      for (const [key, value] of Object.entries(notInLinkedChangeValue)) {
        if (value === linkedname) {
          delete notLinkedarray[key];
          setNotInLinkedChangeValue(notLinkedarray)
        }
      }
    }

    if(linkedArr){

      let linkedname = tempFormFields[fieldId].displayName;
      let formPrefillTypeId = tempFormFields[fieldId].formPrefillTypeId;
    
      let LinkedObj = {
        [linkedname]: e.target.value,
        formPrefillTypeId,
      };

      let index = linkedArr.findIndex(item => Object.keys(item)[0] === linkedname);
      if (index === -1) {
          linkedArr.push(LinkedObj);
        } else {
          linkedArr[index] = LinkedObj
        }
        
        dataService.setLocal("isLinkedArray",linkedArr);
    
    } else {
      let linkedArr = [];
      let linkedname = tempFormFields[fieldId].displayName;
      let formPrefillTypeId = tempFormFields[fieldId].formPrefillTypeId;
      let LinkedObj = {
        [linkedname]: e.target.value,
        formPrefillTypeId
      };

      linkedArr.push(LinkedObj);
      dataService.setLocal("isLinkedArray", linkedArr);
    }
  } else {
      let linkedArr = dataService.getLocal("isLinkedArray");
      let linkedname = tempFormFields[fieldId].displayName;
      if (linkedArr) {
        let index = linkedArr.findIndex(item => Object.keys(item)[0] === linkedname);
        if (index >= 0) {
          setNotInLinkedChangeValue(pr => ({
            ...pr,
            [fieldId]: linkedname,
          }))
        }
      }
  }
    delete errorsTemp[fieldId];
    if (!formId) {
      formId = form.formId;
    }

    dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2(tempFormFields));
    setErrors(errorsTemp)
    }
  };

  const savetargetData=(Optionsval)=>{
    
    let preFilledArr=[];
    let stepFormArray = listingForms[activeListingFormIndex];
    if(stepFormArray && Optionsval){
        let listingFormField =stepFormArray?.groups || [];
        for(let h=0;h<listingFormField.length; h++)
        {
          let listingSection=listingFormField?.[h].sections;
          for(let i=0; i< listingSection.length ; i++)
          {
            let sectionArr=listingSection?.[i].fields;
            for (let j = 0; j < sectionArr.length; j++) {
              
                let displayName=sectionArr[j].displayName;
              
                for(let k=0;k<Optionsval.length; k++)
                {
                  
                    if(displayName===Optionsval[k]){
                      
                      preFilledArr.push({
                        formFieldId: sectionArr[j].formFieldId,
                        value: '',
                      })
        
                    } 
                }
              }
          }
        }
    }
   

    if (preFilledArr.length > 0) {
    let PreFillArr = listingForms[activeListingFormIndex];
    let userFormId = PreFillArr?.userFormId
    const prefillReqObj = {
      userFormId:  userFormId,
      previewFields: preFilledArr,
    }  
    dispatch(onboardingActionCreatorsV2.savePreviewFormFieldV2(prefillReqObj, () => {}))
  }
}

  const isListingPrice = (field) => {
    return field.formPrefillTypeId === formPrefillTypes.LISTING_PRICE;
  }

  const handleFormTargetField = (e,options,fieldId) => {
    if(options){
     let optionValue=options.replace(/\u21b5|\r\n|\n/g, "@#$").split("@#$");
     if(!e.target.checked){
        savetargetData(optionValue);
     }
    }
  }

  const handleFormCheckboxField = (e, field, fieldId) => {
    const tempFormFields = {...formFields};
    const errorsTemp = { errors };
    tempFormFields[fieldId].value = e.target.checked;
    delete errorsTemp[fieldId];

    dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2(tempFormFields));
    setErrors(errorsTemp)
    dispatch(onboardingActionCreatorsV2.saveFormFieldV2(form.userFormId, fieldId, e.target.checked));
  };

  const getFieldControl = (
    field,
    index,
    node,
    userFormID,
    formId,
  ) => {

    let fieldWidth;
    if(field.value?.length >= 30){
      fieldWidth = 220 + (2 * field.value?.length);
    }

    const displayName = field?.displayName || '';

    if(displayName === editListingValue.sellername){
      fieldWidth = 270;
    }

    if (displayName === date1 || displayName === date2 || displayName === date3) {
      field.readOnly = true;
      field.value = '';
    }

    if (field.readOnly) {
      return (
        <React.Fragment key={`${field.formFieldId}_${index}`}>
          <input
            type="text"
            name={field.formFieldId}
            value={field?.value || field?.prefillText || ''}
            className={disabled_input}
          />
          <label className="for-readonly">Read Only</label>
        </React.Fragment>
      );

    }
    if (field.options) {
      let labelLength='';

      if(field.inputDataType === 5 && node?.parent?.name === editListingValue.div && node?.parent?.parent !== null){
        labelLength = getCheckboxLabelLength(node);
      }
      if(!field.value || field.value === null){
        field.value = '';
      }
      return (
        <React.Fragment key={`${field.formFieldId}_${index}`}>
        {field.inputDataType === 5 ? (
        <>

        {field.required && <label style={labelLength ? {top: -25 + "px",
                left: (( 2 * labelLength) + 70) + "px", marginLeft: '12px', marginBottom: '0px'} : {}}>Required</label>}
        {field.optionTypeId === 1 ? (
          <>
          {field.options.split('\n').map((option) => (
          <div
            key={`${field.formFieldId}_${index}_${option}`}
            className="checkbox-default"
          >
            <input
              type="checkbox"
              value={option}
              name={field.formFieldId}
              checked={option === field.value}
              onChange={(e) => handleFormSingleCheckboxField(e, field.formFieldId)}
              disabled={isFormSigned}
              className={
                errors[field.formFieldId]
                ? inputErrorClassChecbox 
                : isFormSigned
                ? disabled_input
                : ""
              }
              /><span>{option}</span>
            </div>
            ))}
          </>
      ) : (
          <>
          {field.options.split('\n').map((option) => (
          <div
            className="checkbox-default"
            key={`${field.formFieldId}_${index}_${option}`}
          >
            <input
              type="checkbox"
              value={option}
              name={field.formFieldId}
              defaultChecked={field.value.includes(option)}
              onChange={(e) => handleFormMultileCheckboxField(e,field.formFieldId)}
              disabled={isFormSigned}
              className={errors[field.formFieldId] ? inputErrorClassChecbox : isFormSigned ? disabled_input : ""}
            /><span>{option}</span>
            </div>
          
            ))}
            </>
          )}
        </>

        ):(
        <>
          <select
            key={`${field.formFieldId}_${index}`}
            name={field.formFieldId}
            onChange={(e) => handleFormFieldSelect(field.displayName, field.formFieldId, false, e)}
            value={field.value}
            disabled={isFormSigned}
            className={errors[field.formFieldId] ? inputErrorClassOption : isFormSigned ? disabled_input : ""}
          >
            {
              field.required &&
              <label>Required</label>
            }
            <option key={Math.random()} value=''> Select Value </option>
            {field.options.split('\n').map((option) => (
              <option key={`${field.formFieldId}_${index}_${option}`} value={option}>
                {option}
              </option>
            ))}
          </select>
          {field.required && <label>Required</label>}
        </>
        )}
        </React.Fragment>
      )
    }

    switch (field.inputDataType) {
      
      case enums.InputTypes.TEXT_SHORT:
        return (
          <span style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap'
          }} key={`${field.formFieldId}_${index}`}>
            <input
              style={fieldWidth ? {width: fieldWidth + "px"}: {}}
              key={`${field.formFieldId}_${index}`}
              type="text"
              name={field.formFieldId}
              value={field?.value || field?.prefillText || ''}
              onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId)}
              onBlur={(e) => handleFormFieldBlur(e,userFormID)}
              onFocus={handleFormFieldFocus}
              required={field.required && !field.value}
              className={errors[field.formFieldId] ? inputErrorClass  : isFormSigned ? disabled_input : ""}
            />
            {field.required && <label>Required</label>}
        </span>
        );
      case enums.InputTypes.TEXT_LONG:
        return (
          <TextAreaWrapper required={field.required}>
            <textarea
              key={`${field.formFieldId}_${index}`}
              type="text"
              name={field.formFieldId}
              defaultValue={field?.value || field?.prefillText || ''}
              onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId)}
              onBlur={(e) => handleFormFieldBlur(e,userFormID)}
              onFocus={handleFormFieldFocus}
              className={
                errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input : ""
              }
            />
            {field.required && <label>Required</label>}
          </TextAreaWrapper>
        );
        case enums.InputTypes.WHOLE_NUMBER:
          return (
            <React.Fragment key={`${field.formFieldId}_${index}`}  >
              <input
                key={`${field.formFieldId}_${index}`}
                type="number"
                name={field.formFieldId}
                defaultValue={field?.value ? field.value : field.prefillText || ''}
                onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId)}
                onBlur={(e) => handleFormFieldBlur(e,userFormID)}
                onFocus={handleFormFieldFocus}
                className={errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input: ''}
              />
              {
                field.required &&
                <label>Required</label>
              }
            </React.Fragment>
          );
      case enums.InputTypes.DECIMAL:
        return (
          <React.Fragment key={`${field.formFieldId}_${index}`}>
            <input
              key={`${field.formFieldId}_${index}`}
              type="number"
              name={field.formFieldId}
              defaultValue={field?.value ? field.value : field.prefillText || ''}
              onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId)}
              onBlur={(e) => handleFormFieldBlur(e,userFormID)}
              onFocus={handleFormFieldFocus}
              className={
                errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input : ""
              }
            />
            {field.required && <label>Required</label>}
          </React.Fragment>
        );
        case enums.InputTypes.CURRENCY:
        return (
          <React.Fragment key={`${field.formFieldId}_${index}`}>
            <CurrencyInput
              style={fieldWidth ? {width: fieldWidth + "px"}: {}}
              key={`${field.formFieldId}_${index}`}
              decimalsLimit={2}
              intlConfig={{ locale: 'en-US', currency: 'USD' }}
              defaultValue={field?.value ? field.value : field.prefillText || ''}
              name={field.formFieldId}
              disabled={isListingPrice(field) && !!propertyPrice}
              onValueChange={(e) => handleFormFieldChangedCurrency(
                e,
                field.formFieldId,
                formId,
                field.inputDataType
              )}
              onBlur={(e) => handleFormFieldBlur(e,userFormID,field.inputDataType)}
              onFocus={handleFormFieldFocus}
              required={field.required && !field.value}
              className={errors[field.formFieldId] ? inputErrorClass  : isFormSigned ? disabled_input : ""}
            />
            {field.required && <label>Required</label>}
          </React.Fragment>
        );
      case enums.InputTypes.BOOLEAN:
        return (
          <React.Fragment key={`${field.formFieldId}_${index}`}>
          {field.required && <label>Required</label>}
            <input
              key={`${field.formFieldId}_${index}`}
              type="checkbox"
              name={field.formFieldId}
              defaultChecked={field?.value ? JSON.parse(field.value) : false}
              onChange={(e) =>
                handleFormCheckboxField(
                  e,
                  field.displayName,
                  field.formFieldId
                )
              }
              disabled={isFormSigned}
              className={
                errors[field.formFieldId] ? inputErrorClassChecbox : isFormSigned ? disabled_input : ""
              }
            />
          </React.Fragment>
        );

      case enums.InputTypes.DATE_TIME:
        return (
          <React.Fragment key={`${field.formFieldId}_${index}`}>
            <FormDatePicker>
              {field.value ? (
                <DatePicker
                  size="large"
                  defaultValue={moment(field.value)}
                  onChange={(e) => handleFormFieldSelect(field.displayName, field.formFieldId, true, e)}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input : ""
                  }
                />
              ) : (
                <DatePicker
                  size="large"
                  onChange={(e) => handleFormFieldSelect(field.displayName, field.formFieldId, true, e)}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input: ""
                  }
                />
              )}
              {field.required && <label>Required</label>}
              <span className="text-error">
                {errors[field.displayName]}
              </span>
            </FormDatePicker>
          </React.Fragment>
        );
      default:
        return "";
    }
  };
  
  const getImageFieldControl = (
    node,
    fields,
    userFormID,
    formId
  ) => {
    if(fields){
      fields.sort(function(a,b){
        return parseInt(b.inputDataType) - parseInt(a.inputDataType);
      })
    }
    
    let matches = fields.filter(a => a.value !== null && a.value && (typeof a.value===editListingValue.string)).filter(a => a.value.toLowerCase().includes(editListingValue.twoOwner) || a.value.toLowerCase().includes(editListingValue.oneOwner) || a.value.toLowerCase().includes(editListingValue.threeOwner));
    
    if(matches.length>enums.NUMERIC_VALUE.ZERO){

      overlaySignFlag=enums.NUMERIC_VALUE.ONE;
      let ownerVal=matches[0]['value'].toLowerCase();
      if(ownerVal==editListingValue.oneOwner){
        showOwner= Enums.NUMERIC_VALUE.ONE;
      }
      if(ownerVal==editListingValue.twoOwner){
        showOwner= Enums.NUMERIC_VALUE.TWO;
      }
      if(ownerVal==editListingValue.threeOwner){
        showOwner= Enums.NUMERIC_VALUE.THREE;
      }
    } else {
      if(overlaySignFlag == enums.NUMERIC_VALUE.ZERO){
        showOwner = showSingCount;
      }
    }

    for(let j=enums.NUMERIC_VALUE.THREE; j > showOwner;j--){
      const regexp = new RegExp('sellersig' + j, 'i');
      const regexpDate = new RegExp('date'+j, 'i');
      const regexpSellername = new RegExp('sellername'+j, 'i');
      const regexpInitial = new RegExp(['initial' + j , 'initials' +j].map(function(r) {
        return (r + "").replace(/\//g, "");
      }).join("|"), "g");

      const regexpEmail = new RegExp(['emailaddr'+j,'seller'+j+'email'].map(function(r) {
        return (r+"").replace(/\//g,"");
      }).join("|"), "g");
      
      fields = fields.filter(x => !regexp.test(x.displayName));
      fields = fields.filter(x => !regexpDate.test(x.displayName));
      fields = fields.filter(x => !regexpEmail.test(x.displayName));
      fields = fields.filter(x => !regexpSellername.test(x.displayName));
      fields = fields.filter(x => !regexpInitial.test(x.displayName));
    }


    return (
      <div className="image-with-form">
        <img src={node} alt=""/>
        
        {fields.map((field, index) => {

          if (field.displayName === date1 || field.displayName === date2 || field.displayName === date3) {
            field.readOnly = true;
            field.value = '';
          }
        
        if (!formFields?.[field?.formFieldId]) {
            dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2({
              ...formFields,
              [field.formFieldId]: {
                displayName: field.displayName,
                isRequired: field.required,
                value: field?.value ? field.value : field.prefillText || '',
                isLinked:field.isLinked,
                formPrefillTypeId:field.formPrefillTypeId,
              }
            }));
          }

          if(linkedChangeValue.hasOwnProperty(field.formFieldId)){
            field.prefillText = '';
          } else if (!field.value && field.prefillText){
            field.value = field.prefillText;
          }
          
          if(field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN && field.targetFields){
            let obj=[]
            let optionValue=field.targetFields.replace(/\u21b5|\r\n|\n/g, "@#$").split("@#$");
            
              Object.keys(optionValue).forEach((key) => {
                obj[key] = {
                  'isCheckd': field.value,
                  'displayName': optionValue[key]
                }
                
              })
              setTargetValues(pr => ({
                [field.formFieldId]: obj,
              }));
            }

            let targetClass='';
        
            if(Object.keys(targetValues).length>0){

              Object.keys(targetValues).forEach((Statekey) => {
              let singleArr=targetValues[Statekey];
            
              Object.keys(singleArr).forEach((key) => {
              if(singleArr[key]['displayName'] === field.displayName && (singleArr[key]['isCheckd'] == 'true' || singleArr[key]['isCheckd']===true)){
                    field.readOnly=false;
                }
                  if(singleArr[key]['displayName'] === field.displayName && (singleArr[key]['isCheckd'] == 'false' || singleArr[key]['isCheckd']===false)){
                    targetClass=disabled_input;
                    field.value='';
                    field.readOnly=true;
                  }
                })
              })
            }

            if(
              field.displayName === editListingValue.brokerupfrontfee ||
              field.displayName === editListingValue.broker_upfront_fee
            ){
                field.value = field.value || field.prefillText;
                if(field.value && !field.value.includes('$')) {
                  field.value= '$' + field.value;
                }
            }
          if (field.readOnly) {
            return (
              <div
                key={`${field.formFieldId}_${index}`}
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  type="text"
                  name={field.formFieldId}
                  readOnly
                  value={field?.value || field?.prefillText || '' }
                  className={disabled_input}
                />
                
              </div>
            );
          }

          if (field.options && field.inputDataType === Enums.NUMERIC_VALUE.THIRTEEN) {
            if(field.value===null){
              field.value='';
            }
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <select
                  style={{
                    height: field.overlayHeight?field.overlayHeight: 20 + "px",
                    width: field.overlayWidth?field.overlayWidth: 100 + "px",
                    padding: 0,
                  }}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  value={field.value}
                  onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId)}
                  onBlur={(e) => handleFormFieldBlur(e,userFormID)}
                  onFocus={handleFormFieldFocus}
                  disabled={isFormSigned}
                  className={errors[field.formFieldId] ? inputErrorClassOption :  isFormSigned ? disabled_input_overlay : targetClass}
                >
                  <option key={Math.random()} value=''>
                    {' '}
                    Select Value{' '}
                  </option>
                  {field.options
                    .replace(/\u21b5|\r\n|\n/g, "@#$")
                    .split("@#$")
                    .map((o) => (
                      <option
                        key={`${field.formFieldId}_${index}_${o}`}
                        value={o}
                      >
                        {o}
                      </option>
                    ))}
                </select>
              </div>
              {
              field.required &&
              <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }} className="additional-label">Required</label>
              } 
              </>
              
            );
          }

          if (
            !field.readOnly &&
            field.inputDataType === Enums.NUMERIC_VALUE.ONE
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  value={field.value || ''}
                  onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId)}
                  onBlur={(e) => handleFormFieldBlur(e,userFormID)}
                  onFocus={handleFormFieldFocus}
                  required={field.required && !field.value}
                  className={errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input_overlay : targetClass}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.THREE || field.inputDataType === Enums.NUMERIC_VALUE.FOUR)
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  value={field.value || ''}
                  onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId,field.inputDataType)}
                  onBlur={(e) => handleFormFieldBlur(e,userFormID)}
                  onFocus={handleFormFieldFocus}
                  required={field.required && !field.value}
                  className={errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input_overlay : targetClass}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (
            !field.readOnly &&
            (field.inputDataType === Enums.NUMERIC_VALUE.SEVEN)
          ) { 
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                }}
              >
                <CurrencyInput
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  decimalsLimit={2}
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  defaultValue={field?.value?field.value:field.prefillText || ''}
                  name={field.formFieldId}
                  onValueChange={(e) => handleFormFieldChangedCurrency(e, field.formFieldId,formId,field.inputDataType)}
                  onBlur={(e) => handleFormFieldBlur(e,userFormID,field.inputDataType)}
                  onFocus={handleFormFieldFocus}
                  required={field.required && !field.value}
                  className={errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input_overlay : targetClass}
                />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (
            !field.readOnly &&
            field.inputDataType === Enums.NUMERIC_VALUE.TWO
          ) { 
            return (
              <>
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >

              <textarea
               style={{
                height: field.overlayHeight?field.overlayHeight:20 + "px",
                width: field.overlayWidth?field.overlayWidth:100 + "px",
              }}
              key={`${field.formFieldId}_${index}`}
              type='text'
              name={field.formFieldId}
              defaultValue={field.value? field.value:field.prefillText || ''}
              onChange={(e) => handleFormFieldChanged(e, field.formFieldId,formId)}
              onBlur={(e) => handleFormFieldBlur(e,userFormID)}
              onFocus={handleFormFieldFocus}
              className={errors[field.formFieldId] ? inputErrorClass+' mx-100' : isFormSigned ? 'disabled-input-overlay mx-100': targetClass+' mx-100'}
            />
               
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.FIVE) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                  height: field.overlayHeight + "px",
                  width: field.overlayWidth + "px",
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="checkbox"
                  name={field.formFieldId}
                  defaultChecked={field?.value ? JSON.parse(field.value) : false}
                  onChange={(e) =>
                    handleFormCheckboxField(
                      e,
                      field.displayName,
                      field.formFieldId
                    )
                  }
                  onBlur={(e) => handleFormFieldBlur(e,userFormID)}
                  onFocus={handleFormFieldFocus}
                  disabled={isFormSigned}
                  className={errors[field.formFieldId] ? inputErrorClassChecbox : targetClass}
                />
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
          if (field.inputDataType === Enums.NUMERIC_VALUE.FOURTEEN) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="checkbox"
                  name={field.formFieldId}
                  defaultChecked={field?.value ? JSON.parse(field.value) : false}
                  onChange={(e) =>{
                    handleFormTargetField(
                      e,
                      field.targetFields
                    );
                    handleFormCheckboxField(
                      e,
                      field.displayName,
                      field.formFieldId
                    )
                  }
                  }
                  onBlur={(e) => handleFormFieldBlur(e,userFormID)}
                  onFocus={handleFormFieldFocus}
                  disabled={isFormSigned}
                  className={errors[field.formFieldId] ? inputErrorClassChecbox : ''}
                />
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (field.inputDataType === Enums.NUMERIC_VALUE.EIGHT) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                }}
              >
               <FormDatePicker>
              {field.value ? (
                <DatePicker
                  size="small"
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  defaultValue={moment(field.value)}
                  onChange={(e) => handleFormFieldSelect(field.displayName, field.formFieldId, true, e)}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input : ""
                  }
                />
              ) : (
                <DatePicker
                  size="small"
                  style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }}
                  onChange={(e) => handleFormFieldSelect(field.displayName, field.formFieldId, true, e)}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    errors[field.formFieldId] ? inputErrorClass : isFormSigned ? disabled_input: ""
                  }
                />
              )}
              <span className="text-error">
                {errors[field.displayName]}
              </span>
            </FormDatePicker>
              
              </div>
              {
               field.required &&
               <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px"}}
               className="additional-label">Required</label>
               } 
              </>
            );
          }
        })}
      </div>
    );
  };

  const processForm = (group, section) => {
    let { content, fields } = section;

    if (group.formGroupType === 3) {
      return getImageFieldControl(content, fields, group.userFormId, group.formId, section.priority);
    }

      const processNewlines = form.formType === enums.FormTypes.MLS_LISTING_FORM;
      const re = new RegExp(/Owner's Signature:{FormField:(\d+)}/, "g");
      const re1 = new RegExp(/Owner Signature:{FormField:(\d+)}/, "g");
      let x = 1;
      let inStyles;
      content = content.replace(re, function (match) {
        if(x > showSingCount){
          inStyles='display:none';
        }
        return `<span style='${inStyles}' class='signaclass' id='sign_${(++x)}'>${match}</span>`;
      });

      content = content.replace(re1, function (match) {
        if( x > showSingCount){
          inStyles='display:none';
        }
        return `<span style='${inStyles}' class='signaclass' id='sign_${(++x)}'>${match}</span>`;;
      });

      const datere = new RegExp(/Date:{FormField:(\d+)}/, "g");
      let y = 1;
      let inStyle;
      content = content.replace(datere, function (match) {
        if(y > showSingCount && y<Enums.NUMERIC_VALUE.FOUR){
          inStyle='display:none';
        }else{
          inStyle='';
        }
        return "<span style='"+inStyle+"' class='signaclass' id='date_" + (++y) + "'>"+match+"</span>";
      });

      
      for(let j = 3; j > showSingCount; j--) {

        const regexp = new RegExp(['emailaddr'+j,'seller'+j+'email'].map(function(r){
          return (r+"").replace(/\//g,"");
        }).join("|"), "g");

        const Sellernameregexp = new RegExp('sellername'+j, 'i');
        const Initialregexp = new RegExp('initial'+j, 'i');
    
        let Displayindex = fields.findIndex( x => regexp.test(x.displayName));
        let initalIndex = fields.findIndex( x => Initialregexp.test(x.displayName));
        let sellerNameindex = fields.findIndex( x => Sellernameregexp.test(x.displayName));
        
        if (Displayindex > 0) {
          fields = fields.filter(x => !regexp.test(x.displayName));
        }

        if (initalIndex > 0) {
          let replaceDataInitial = "{FormField:"+[fields[initalIndex].formFieldId]+"}";
          content = content.replace(replaceDataInitial, "");
        }

        if(sellerNameindex > 0){
          let replaceDataSeller="{FormField:"+[fields[sellerNameindex].formFieldId]+"}";
          content = content.replace(replaceDataSeller, "");
        }
      }
  
    content = content.replace(/{FormField:(\d+)}/g, "<field>$1</field>");
      
    content = content.replace(/\[row.*?\]/g, '<div class="row-modify">');
    content = content.replace(/\[\/row]/g, "</div>");
    content = content.replace(/\[column.*?\]/g, '<div class="medium-4">');
    content = content.replace(/\[\/column]/g, "</div>");
    content = content.replace(/\[page-break]/g, "<br />");
    content = content.replace(/\[required]/g, "");

    if (processNewlines)  {
      content = content.replace(/\r\n/g, "<br />");
    }

    return ReactHtmlParser(content, {
      transform: (node, index) => transform(node, index, group, section)
    });
};

const transform = (
  node,
  index,
  group,
  section
) => {
  let { fields } = section;
  
  if (node.type === "tag" && node.name === "field") {
    const field = fields.filter(
      (f) => f.formFieldId === parseInt(node.children[0].data)
      )[0]; 

    if (field && !formFields?.[field.formFieldId]) {
      let valueForSign = field.value ? field.value : field.prefillText || "";
      if(valueForSign && (typeof valueForSign === editListingValue.string) && valueForSign.toLowerCase()===editListingValue.oneOwner){
        setShowSignCount(1);
      }
      if(valueForSign && (typeof valueForSign === editListingValue.string) && valueForSign.toLowerCase()===editListingValue.twoOwner){
        setShowSignCount(2);
      }
      if(valueForSign && (typeof valueForSign === editListingValue.string) && valueForSign.toLowerCase()===editListingValue.threeOwner){
        setShowSignCount(3);
      }

      dispatch(onboardingActionCreatorsV2.updateListingFormsFieldsV2({
        ...formFields,
        [field.formFieldId]: {
            displayName: field.displayName,
            isRequired: field.required,
            value: field.value || field.prefillText || "",
            formPriority: section.priority,
            isLinked: field.isLinked,
            formPrefillTypeId: field.formPrefillTypeId,
          }
      }));
    }
    if (field){
    return getFieldControl(
      field,
      index,
      node,
      group.userFormID,
      group.formId,
    );
    }
  }
};

  return (
    <FormWrapper>
      <FormHeading className="with-tooltip">  
        Fill in the blanks and review your listing contract
        below
        <div
          className="info-tooltip-box"
          onClick={openFormInstruction}
        >
          <span className="info-tooltip-icon">
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      </FormHeading>
      <Form>
        {form.groups.map((group, index) => {
          return (
            <Group key={index}>
              {group.sections.map((section, idx) => {
                return (
                  <div key={idx} className={`${
                    group.formGroupType === 3 &&
                    listingForms.length > 1 &&
                    "dynamic-form"
                  } ${
                    group.formGroupType !== 3 && "dynamic-form-before"
                  }`}>
                    <Section >
                      {processForm(group, section)}
                    </Section>
                  </div>
                );
              })}
            </Group>
          );
        })}
      </Form>
    </FormWrapper>
  );
};

export default ContractSignForm;
