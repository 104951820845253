import React from 'react';
import { StaticContentWrapper, Container, Quote } from '../../../../Assets/Style/commonStyleComponents';
import { ImagesUrl } from "../../../../Constants/Images";
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

class Press extends React.Component {
  render() {
    return (
      <>
        <Navbar />
        <StaticContentWrapper>
          <Container>
            <Quote>
              USRealty.com Media  Information
            </Quote>
            <strong>Resources for members of the media</strong>
            <p><a href={ ImagesUrl.USREALTY_MEDIA_ONEPAGE } rel="noopener noreferrer" target="_blank">USRealty-Media-OnePage</a></p>
            <p><a href="/press/media-coverage">View the latest media coverage of USRealty.com</a></p>
            <p>Follow us @USRealtydotCom</p>
            <strong>Media Resources</strong>
            <p>Official USRealty.com Hi-res Logo</p>
            <div className="press-logo-btn">
              <img src={ ImagesUrl.USREALTY_LOGO_BIG } alt="logo" />
              <p><a href={ ImagesUrl.USREALTY_LOGO } rel="noopener noreferrer" target="_blank">Download now</a></p>
            </div>
            <strong>USRealty.com Hires Key Staff in Anticipation of Adding Buy-Side Services</strong>
            <p>New York, August 4, 2017 —</p>
            <p>Gearing up for a major third-quarter expansion of its residential real estate services, USRealty.com has
              brought in additional corporate and regional leaders.</p>
            <p>“We are investing in the talent that will translate our proven online brokerage model to more
            markets and to home buyers,” said Colby Sambrotto, USRealty.com chief executive officer. “Today’s
            sophisticated sellers know they can’t rely only on market momentum to sell — they still need a powerful
              platform to get their listings in the MLS and into key selling services.”</p>
            <p>Buyers want the same thing, “and we’re going to give it to them,” said Sambrotto, “by offering all buyers
              – in all markets — cash back at closing, when they buy through USRealty.com and our network of local agents.”</p>
            <h4>Expanded Leadership for Regional Operations</h4>
            <p>Derek Morgan, Senior Vice President, Senior Managing Broker, manages Broker Services, drawing on real
            estate experience cultivated since 2000, when he landed the first of his seven state broker licenses. For
              seven years, he managed broker services for ForSaleByOwner.com, a division of tronc, (formerly known as Tribune Digital).</p>
            <p>Keith Wolf, Managing Broker, oversees USRealty.com’s Midwest broker operations and has been a Realtor,
              appraiser and real estate analyst since 1981.</p>
            <p>John David has been a real estate broker since 2009, and is the Designated Broker for USRealty.com in the
              state of Missouri. He brings broad experience in corporate sales and development.</p>
            <h4>Expanded Corporate Leadership</h4>
            <p>Stuart Daroca, General Counsel, oversees USRealty.com’s regulatory, commercial and corporate matters advising
            on legal, strategic and business issues. He has more than two decades of experience in financial services and
            was in-house legal counsel at American International Group, Inc, and Citigroup, Inc. during the financial
            crisis where he played a significant role in each company’s restructuring and recapitalization. He earned his
              J.D. at Georgetown University Law Center and B.A. at the University of California, Berkeley.</p>
            <h4>About USRealty.com</h4>
            <p>USRealty.com is a national, online real estate brokerage committed to significantly reducing commissions for
            both buyer and sellers. The company provides free MLS access in 34 of America’s largest states and, through
            its Pennsylvania call center, supports home sellers through the listing and closing process. For more
              information, visit www.USRealty.com.</p>
            <strong>USRealty.com Raises Capital to Expand Customer Offerings</strong>
            <p>Additional Funding to Expand Listing Technology; Fuel Growth</p>
            <p>New York, NY – October 20, 2016 – USRealty.com, an innovative online real estate company, announced today
            the closing of a round of financing with New York-based venture firm Third Prime Capital, which focuses on
            early stage companies. Proceeds from the round will support the company’s continued development of its real
            estate listing technology, sales and marketing efforts and partnerships with institutional owners of
              residential properties.</p>
            <p>“The rapid growth of our business has created a need to scale our customer offerings,” said Colby
            Sambrotto, President and CEO of USRealty.com. “This funding will allow us to develop the services and products
            we need to meet the growing demands of our expanding customer base, while maintaining excellent customer
              services and support.”</p>
            <p>USRealty.com, which is revolutionizing the home selling process by offering free access to the Multiple
            Listing Service (MLS), sold nearly $1 billion of homes in 2015. The company will refine and expand its
            service offerings to insure that it remains the most effective and affordable way to sell homes. In addition,
              the company will continue to develop relationships and services with institutional property owners.</p>
            <p>“We believe that a growing number of homeowners want to reduce their transaction costs via an a la carte
            approach to listing their homes for sale, provided that time to close and likelihood of a successful sale are
            not negatively impacted. With its extensive MLS access and seamless online listing process, USRealty.com
              is providing home sellers that option,” said Keith Hamlin, a Managing Partner at Third Prime Capital.</p>
            <p>With its unique hybrid model, USRealty.com is re-defining the home-selling process by giving consumers
            the opportunity to save up to 60% on commissions, retaining equity in their homes and putting money back in
              their pockets. For more information, visit https://usrealty.com/.</p>
            <h4>About Third Prime Capital</h4>
            <p>Third Prime Capital is an early-stage venture capital firm based in New York that invests in companies
            building scalable platforms with a competitive advantage. The firm’s current fund invests across
              sectors opportunistically and Third Prime’s partners actively work with management teams to accelerate growth.</p>
            <h4>About USRealty.com</h4>
            <p>Launched in 2014, USRealty.com has opened new paths for real estate owners to sell homes more
            cost-effectively and efficiently. With real estate brokerage licenses in 33 states covering 80% of home
            sellers across the country, USRealty.com’s national online tools enable homeowners, portfolio managers
            and institutional investors a simple, streamlined method to list properties and choose the commissions they
            pay to buyers’ agents. USRealty.com’s President and CEO Colby Sambrotto co-founded ForSaleByOwner.com in
              1998, helping to create the category of online, do-it-yourself selling for homeowners.</p>
            <strong>USRealty.com Launch</strong>
            <h4>April 20, 2016 – New York</h4>
            <h4>USRealty.com Launches New Online Service Revolutionizing the Home-Selling Process</h4>
            <p>Home-sellers significantly reduce commissions while ensuring high success rates</p>
            <p>New York, NY – April 20, 2016 – USRealty.com, the online service that is changing the way home owners sell
            their homes, today launched free access to the Multiple Listing Service (MLS) nationwide. Through a
            simple three-step process, home-sellers can create a customized listing for their home with photos, and
            even dictate the amount of commission they are willing to pay. Listings appear on USRealty.com immediately, in
            one day on the MLS, a private database that real estate agents use to market their clients’ homes to other
            agents, and on popular sites like Realtor.com, Trulia and Zillow within a few days of listing. These sites
              are where house-hunters look first and continuously for the latest listings of houses for sale.</p>
            <p>“The popularity of the Do it Yourself (DIY) industry has been a driving force behind USRealty.com’s success
            as people increasingly take a more hands-on approach to everything from their finances to selling their
            homes,” said Colby Sambrotto, CEO and founder of USRealty.com. “Home-sellers are getting professional
            level marketing and guidance, and can reduce commissions paid out by at least 50 percent. On average, our
              sellers can save in excess of $5,000.”</p>
            <p>Since its inception in 2013 USRealty.com has operated almost exclusively as a business to business model.
            This new service marks the company’s shift toward providing individual home-sellers with the tools
            historically only available to institutions. Managers of real estate investments, lenders, and others who
            oversee portfolios of single-family homes use the site to list homes in local MLS systems, helping to fuel
              the success of local agents by ensuring a steady flow of homes into the market.</p>
            <p>“USRealty.com differs from traditional for sale by owner sites because it is the first to provide free access
            to the MLS, which is a paid service on other sites,” added Sambrotto. “By blending the savings of a DIY
            process with the success rates of a more traditional real estate approach, we have truly carved out a new
              space – aligning the interests of home- sellers while leveraging the strength of buy-side residential agents.”</p>
            <h4>About USRealty.com</h4>
            <p>USRealty.com is an innovative online service that makes the home-selling process more affordable and
            effective. USRealty.com lists houses on all the most powerful real estate websites, including the local MLS
            for free, allowing consumers more control in the home- selling process by choosing their commissions and
            retaining home equity. USRealty.com has catered to the commercial real estate industry with interstate
              transaction support since its inception. For more information, visit www.USRealty.com.</p>
          </Container>
        </StaticContentWrapper>
        <Footer />
      </>
    )
  }
}

export default Press;
