export const actionTypes = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',
};

export function toastAlert(state = {}, action = { type: '', message: '' }) {
  switch (action.type) {
    case actionTypes.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
      };
    case actionTypes.ERROR:
      return {
        type: 'alert-danger',
        message: action.message,
      };
    case actionTypes.CLEAR:
      return {};
    default:
      return state;
  }
}
