import { ProductsActions } from '../../Actions/V2/Admin/ProductsActions';
import { tableItemsPerPage } from '../../Constants/CommonConstants';

export const productsFiltersDefault = {
    search: '',
    page: 1,
    limit: tableItemsPerPage,
    productTypeId: null,
    state: null,
    companyId: null,
    sortBy: null,
    desc: null,
}

const initialState = {
    productDetails: null,
    products: [],
    productFeatureTypes: [],
    totalCount: 0,
    filters: productsFiltersDefault
};

export const adminProductsReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    switch (action.type) {
        case ProductsActions.SET_PRODUCTS_FILTERS:
            return { ...state, filters: action.payload };
        case ProductsActions.CLEAR_PRODUCTS_FILTERS:
            return { ...state, filters: { ...productsFiltersDefault } };
        case ProductsActions.PRODUCTS_RESOLVED:
        case ProductsActions.PRODUCTS_FAILED:
            return { ...state, products: action.payload.results, totalCount: action.payload.total };
        case ProductsActions.PRODUCT_DETAILS_RESOLVED:
        case ProductsActions.PRODUCT_DETAILS_FAILED:
            return { ...state, productDetails: action.payload };
        case ProductsActions.PRODUCT_FEATURE_TYPES_RESOLVED:
        case ProductsActions.PRODUCT_FEATURE_TYPES_FAILED:
            return { ...state, productFeatureTypes: action.payload };
        case ProductsActions.CREATE_PRODUCT_RESOLVED:
        case ProductsActions.UPDATE_PRODUCT_RESOLVED:
            return { ...state, productDetails: action.payload };
        case ProductsActions.CLEAR_DETAILS:
            return { ...state, productDetails: null }
        default:
            return state;
    }
};

export const adminProductsSelector = state => state.adminProducts.products;
export const adminProductsCountSelector = state => state.adminProducts.totalCount;
export const productsFiltersSelector = state => state.adminProducts.filters;
export const productDetailsSelector = state => state.adminProducts.productDetails;
export const productFeatureTypesSelector = state => state.adminProducts.productFeatureTypes;