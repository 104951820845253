import React from 'react';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { adminActionCreators } from '../../../Actions/AdminActions';
import { RowFlex } from '../../../Assets/Style/commonStyleComponents';
import LandingListingTable from '../Components/LandingListing/LandingListingTable';
import Routes from '../../../Constants/Routes';
import { cursorStyles } from '../../../Constants/CommonConstants';

class LandingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: '',
      isModalVisible: false,
      propertyListingType: [],
      selectedCategory: '',
      previewDetail: {},
        showDiv: false,
        showId:0,
        trindex:0,
        x: 0, 
        y: 0, 
        styles: {
          top: 0,
          left: 0
        }
      
    };
  }
  customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#fff !important",
        color: "#333",
        cursor: "pointer"
      };
    }
  };

  someHandler=(tdIndex,trindex,mlsIndex,mlsCount)=>{
    if(mlsCount){
        let toolTipIndex=tdIndex+''+mlsIndex;
        this.setState({showDiv:true});
        this.setState({showId:toolTipIndex});
        this.setState({trindex:trindex});
    }
  }
  
  someHandlerLeave=(tdIndex)=>{
    this.setState({showDiv:false});
    this.setState({showId:0});
  }
  
  componentDidMount() {
    const { activeEntity, getLookup } = this.props;
    getLookup(activeEntity, 'LandingListType');
  }
  handleSelectState = e => {
    this.setState({ selectedState: e });
    this.props.getLandingList(e.value);
  };
  handleSelectCategory = e => this.setState({ selectedCategory: e });
  handleContinue = () => {
    const { selectedState, previewDetail, selectedCategory } = this.state;
    window.open(
      `${Routes.MLS_PREVIEW.replace(
        ':state',
        selectedState.value,
      )}?formTypeId=${previewDetail.formTypeId}&mlsId=${
        previewDetail.mlsId
      }&mlsCategoryId=${selectedCategory.key}`,
    );
  }
  handlePreview = previewDetail =>
    this.setState({
      previewDetail,
      selectedCategory: previewDetail?.categoryList[0] || '',
      isModalVisible: true,
    });
  handleCancel = () =>
    this.setState({
      previewDetail: {},
      selectedCategory: '',
      isModalVisible: false,
    });
  render() {
    const {
      selectedState,
      isModalVisible,
      selectedCategory,
      previewDetail,
    } = this.state;
    const { stateList, landingPage } = this.props;
    return (
      <React.Fragment>
        <div className='landing-page-wrapper'>
          <div className='landing-table-wrapper'>
            <div className='landing-main-select'>
              <RowFlex>
                <div className='pcol-6 for-custom-select'>
                  <label>State</label>
                  <Select
                    styles={cursorStyles}
                    name='state'
                    placeholder='Please Select a State'
                    className='admin-react-select'
                    options={stateList}
                    value={selectedState}
                    onChange={this.handleSelectState}
                  />
                </div>
              </RowFlex>
            </div>
            <div className='landing-custom-table-wrapper'>
              {selectedState &&
                landingPage.map((item,index) => (
                  <LandingListingTable
                    {...item}
                    key={`${index}_${item?.formName}`}
                    onClickPreview={this.handlePreview}
                    someHandler={this.someHandler}
                    someHandlerLeave={this.someHandlerLeave}
                    isPopupOpen={this.state.isPopupOpen}
                    showDiv={this.state.showDiv}
                    showId={this.state.showId}
                    trindex={this.state.trindex}
                    mlsindex={index}
                  />
                ))}
            </div>
            <Modal
              visible={isModalVisible}
              onCancel={this.handleCancel}
              className="landing-modal-box"
              okText="Continue"
              onOk={this.handleContinue}
            >
              <div className='landing-main-select'  >
                <RowFlex>
                  <div className='for-custom-select'>
                    <label>Select the type of listing for your property</label>
                    <Select
                      styles={{ ...this.customStyles, ...cursorStyles }}
                      name='listingType'
                      placeholder='Select the type of listing'
                      className='admin-react-select'
                      options={previewDetail?.categoryList || []}
                      value={selectedCategory}
                      onChange={this.handleSelectCategory}
                    />
                  </div>
                </RowFlex>
              </div>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

function mapStateToProps(state) {
  const {
    activeSet,
    totalSet,
    lookup,
    isRequesting,
    landingPage,
  } = state.admin;
  return {
    activeSet: activeSet,
    totalSet,
    stateList: lookup.states || [],
    isRequesting,
    landingPage,
  };
}

const landingContainerWithRouter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LandingContainer);
export { landingContainerWithRouter as LandingContainer };
