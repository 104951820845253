import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';

class CashOfferService {

    getCashOffer(id) {
        const url = `/api/v2/cash-offers/${id}`;
        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
            },
        });
    }

    getCashOffers(params) {
        const query = new URLSearchParams(params).toString();
        const url = `api/v2/cash-offers?${query}`;

        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }

    addCashOffer(cashOffer) {
        const url = `/api/v2/cash-offers`;
        return fetch(url, {
            method: apiMethodType.POST,
            data: cashOffer,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }

    updateCashOffer(id, cashOffer) {
        const url = `/api/v2/cash-offers/${id}`;
        return fetch(url, {
            method: apiMethodType.PUT,
            data: cashOffer,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }
}

export const cashOfferService = new CashOfferService();
