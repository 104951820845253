import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions';
import AdminSiderMenu from '../Components/AdminSiderMenu';
import AdminHeader from '../Components/AdminHeader';
import AddEditProduct from '../Components/AddEditProduct';
import Enums from '../../../Constants/Enums';
import { adminPageTitles } from '../../../Constants/CommonConstants';

const { Content } = Layout;
export class ProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      entity: '',
      api: null,
    };
    this.prevTitle = '';
  }

  componentDidMount() {
    this.props.getLookup(this.props.activeEntity, this.props.activeEntity);
    if (this.props.match?.params?.productId) {
      this.props.getSelectedItemDetail(
        Number(this.props.match.params.productId),
        this.props.activeEntity,
      );
    }
    const pageType = this.props.match?.params?.productId < 1
      ? 'addProduct'
      : 'editProduct';
    this.prevTitle = document.title;
    document.title = adminPageTitles[pageType]
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  toggle = () =>
    this.setState({
      collapsed: !this.state.collapsed,
    });

  onCollapse = collapsed => this.setState({ collapsed });

  render() {
    const {
      history,
      lookup,
      userProfileData,
      isDetailFetching,
      isFetching,
    } = this.props;
    const { path } = this.props.match;
    const { collapsed } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <AdminSiderMenu
          path={path}
          onCollapse={this.onCollapse}
          history={history}
          collapsed={collapsed}
        />
        <Layout>
          <Layout.Header
            style={{ background: '#fff', minHeight: 52, padding: 0 }}
          >
            <AdminHeader
              collapsed={this.state.collapsed}
              toggle={this.toggle}
            />
          </Layout.Header>
          <Content>
            {!isDetailFetching && !isFetching && (
              <AddEditProduct
                userProfileData={userProfileData}
                isNewProduct={!Number(this.props.match?.params?.productId)}
                lookup={lookup}
              />
            )}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[Enums.NUMERIC_VALUE.TWO],
    userProfileData: state.admin.selectedItemData || {},
    userErrors: state.admin.errors || {},
    lookup: state.admin.lookup || {},
    isDetailFetching: state.admin.isDetailFetching,
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductContainer),
);
