import React from 'react';
import styled from 'styled-components';
const TopHeading = styled.h1`
  color:#B01F24;
`;
class HomeReady extends React.Component {
  render() {
    return (
      <>
      <TopHeading>Allow about three months to ready your home for market. Here’s the rundown on decluttering, touching up and staging.</TopHeading>
        <h2>Two or three months before listing, declutter, clean and touch up.</h2>
        <br/>
        <h2>Declutter:</h2>
        <ul>
          <li>Remove outdated, out-of-season, unused and tattered furnishings and decorations.</li>
          <li>Rent an offsite storage unit, if you must, for items you don’t use daily.
            Resist the temptation to pile boxes in the garage or basement. Buyers need to tour those areas, too.</li>
          <li>Clean woodwork, windows and floors. In the process, document touch-ups and repairs.</li>
          <li>Either enlist a handyman or do repairs and touch-ups yourself. If you discover significant problems that require a contractor,
             arrange the work immediately so that you can present the house in move-in condition.
             Be sure that the work complies with all local building codes and that the contractor gets a permit
              and completes all required paperwork so you can show buyers that the work is up to standard.</li>
          <li>Review the property for safety: check stair and porch railings; posts and fences; broken steps; tripping hazards;
             and electric work. Repair all safety hazards, especially those that might comprise violations of local building codes.</li>
          <li>Deep-clean bathrooms, the kitchen, and closets. It is easier to keep these hardworking areas clean daily when every surface
             requires just a quick wipe to look its best.</li>
          <li>Paint if necessary, focusing on the main living areas, entry and master bedroom.</li>
        </ul>
        <p>Walk through the house in the morning, afternoon and evening with the lights on to see how it shows at different times of day.
           Depending on natural light, some rooms might show better at different times of day, both in photos and for in-person showings.
            photos of each room from the entry points to that room and analyze the presentation of the room. Declutter,clean and stage accordingly.
             Take another round of photos, with optimal lighting for each room.
              Are these photos suitable for your USRealty.com listing? If not, consider, hiring a professional home photographer.</p>
         <br/>
         <h2>Refresh Curb Appeal:</h2>
         <ul>
          <li>Take photos of the house exterior and entrance from several angles.</li>
          <li>Clean and spruce up the entry and front façade to cultivate curb appeal. This might include repairing and painting porch
             railings and screen doors and metalwork; painting the front door and façade trim; and landscaping.</li>
          <li>Consider adding seasonal flowers and plantings.</li>
          <li>Be sure the house number is clean, visible and well-lit.</li>
          <li>Keep safety tools nearby but out of sight so you can clear snow, leaves or other seasonal barriers from the entry.</li>
        </ul>
          <br/>
         <h2>Stage a First Impression:</h2>
         <p>Staging is the process of arranging living spaces to highlight amenities and appeal. Staging helps buyers see your house in its best light.</p>

         <ul>
         <li>Declutter first. Staging doesn’t camouflage a mess.</li>
          <li>Take photos of each room from its natural entry point. What draws your eye, for better or worse? Arrange furniture, rugs and lighting to draw attention to the room’s
             best features and to draw attention away from less attractive features. For instance, place a chair, lamp and side table in an interior corner to draw buyers’ eyes
              away from a less-than-pretty view outside.</li>
          <li>Use what you have on hand, or borrow accessories from friends. The staged photos do not have to exactly replicate the house that buyers see:
             an artfully draped quilt can spark color in a photo, but the quilt doesn’t have to be in the room when buyers see it.</li>
          <li>Consider removing outdated window treatments.</li>
          <li>Remove or clean stained furniture and rugs. If much-loved furniture is beautiful only to your family,
             consider renting a few key pieces while the house is on the market.</li>
          <li>Stage truthfully. Do not use photo manipulation tools to erase features or to insert features.</li>
          <li>Take photos of staged rooms at several different times of day, with different lighting conditions so that each room looks its best in listing photos.</li>
          </ul>
          <br/>
         <h2>Stage and Present for Visits:</h2>
         <p>In real life, your home must create a strong first impression, starting with the entry and carrying through the buyer’s first few steps inside.</p>
          <ul>
          <li>Triple-check safety and ease of entry. Remove throw rugs, clutter, and other items that make it difficult to find and enter the house.</li>
          <li>Clean glass and metal surfaces early and often, especially in the high-traffic, high-touch entry areas, and in bathrooms.</li>
          <li>Turn lights on, even during the day.</li>
          <li>Identify and remove sources of odors, including strong cooking odors, pets, hobbies and children.
             Expect to open windows for a burst of fresh air right before showings – yes, even in winter.</li>
          </ul>
      </>
    )
  }
}

export default HomeReady;
