import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { adminActionCreators } from '../../../Actions/AdminActions';
import { userActionCreators } from '../../../Actions/UserActions';
import { FilterComponent } from '../../Shared/Components/FilterComponent';
import { ListingComponent } from '../../Shared/Components/ListingComponent';
import enums from '../../../Constants/Enums';
import {
  tableItemsPerPage,
  roleTypesConstant,
  entityTypes,
  statusesTypesConstant,
} from '../../../Constants/CommonConstants';
import {
  userTableConfigs,
  companyTableConfigs,
  categoriesTableConfigs,
  abbreviationTableConfigs,
  MLSSubmissionTableConfigs,
  mlsCoverage,
  voucherTemplateTableConfigs,
  voucherListingTableConfigs,
} from '../../../Constants/TableConstants';
import { SearchComponent } from '../../Shared/Components/SearchComponent';

class ListingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: enums.NUMERIC_VALUE.ONE,
      title: '',
      items: tableItemsPerPage,
      sort: '',
      sortBy: 0,
      openFilter: false,
      tableColumns: [],
      activeEntity: props.activeEntity,
      filteredArray: [],
      tableConfigs: this.getTableConfigs(props.activeEntity),
    };
  }
  lookup = () => {
    const {getLookup} = this.props;
    const { activeEntity } = this.state;
    switch(activeEntity) {
      case entityTypes.mlsSubmission :
        getLookup('MlsSubmission', 'MLSSubmissionType');
        break;
      case entityTypes.mlsCoverage :
        getLookup(activeEntity, 'CoverageType');
        break;
      case entityTypes.voucherTemplate :
        getLookup(activeEntity, 'VoucherType');
        break;
      case entityTypes.voucherListing :
        getLookup(activeEntity, 'VoucherListingType');
        break;
        case entityTypes.coverageListing :
          getLookup(activeEntity, 'CoverageListingType');
          break;
      case entityTypes.abbreviation :
        getLookup(activeEntity, 'MlsAbbreviationType');
        break;
      case entityTypes.users :
        getLookup(activeEntity, 'companies');
        break;
      default:
        break;
    }
  }
  /**
   * @description componentDidMount is called when component is loaded
   * call getAll to fetch user listings
   */
  componentDidMount() {
    const { items, activePage, activeEntity, tableConfigs } = this.state;
    const tableColumns = tableConfigs.columns || [];
    tableConfigs.columns = tableColumns;
    this.setState({ tableConfigs });

    const reqObj = {
      page: Number(activePage),
      limit: items,
    };
    const updateReqObj = this.updateReqObj(reqObj);
    if (activeEntity === entityTypes.mlsSubmission) {
      this.props.getAll('MlsSubmission', updateReqObj);
    } else {
      this.props.getAll(activeEntity, updateReqObj);
    }
    this.lookup();
  }

  getTableConfigs = entity => {
    let tableConfigs;
    switch (entity) {
    case entityTypes.users:
        tableConfigs = userTableConfigs;
        break;
      case entityTypes.categories:
        tableConfigs = categoriesTableConfigs;
        break;
      case entityTypes.abbreviation:
        tableConfigs = abbreviationTableConfigs;
        break;
      case entityTypes.company:
        tableConfigs = companyTableConfigs;
        break;
      case entityTypes.mlsSubmission:
        tableConfigs = MLSSubmissionTableConfigs;
        break;
      case entityTypes.mlsCoverage:
        tableConfigs = mlsCoverage;
        break;
      case entityTypes.voucherTemplate:
        tableConfigs = voucherTemplateTableConfigs;
        break
      case entityTypes.voucherListing:
        tableConfigs = voucherListingTableConfigs;
        break
      default:
        tableConfigs = {
          columns: [],
          filters: [],
          deleteMessage: '',
          addPageUrl: '',
          addButtonText: '',
          searchPlaceHolder: '',
        };
        break;
    }
    return tableConfigs;
  };

  /**
   * @description updateReqObj to update object before sending to the server
   */
  updateReqObj(reqObj) {
    const { filteredArray, title } = this.state;
    // Search text
    reqObj.searchKey = title;

    // filters
    reqObj.filters = filteredArray;
    return reqObj;
  }

  /**
   * @description fetchListingApiAction is used on initial load
   */
  fetchListingApiAction(pageNo) {
    const { items, sort, sortBy, activeEntity } = this.state;
    let reqObj = {
      page: Number(pageNo),
      limit: items,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    const updateReqObj = this.updateReqObj(reqObj);
    if (entityTypes.mlsSubmission === activeEntity) {
      this.props.getAll('MlsSubmission', updateReqObj);
    } else {
      this.props.getAll(activeEntity, updateReqObj);
    }
  }

  /**
   * @description
   * handleInputChange is used to set the value on state from the input.
   * @param {Object} || {String} value
   * @param {String} key
   */
  handleInputChange = (value, key) =>
    this.setState({
      ...this.state,
      [key]: value,
      activePage: enums.NUMERIC_VALUE.ONE,
    });

  /**
   * @description
   * handleTableChange is called someone click on the pagination.
   * @param {Object} requestObject Object that contains pagination and sorting data
   */
  handleTableChange = requestObject => {
    const { activePage, sort, sortBy, pageSize } = requestObject;
    this.setState({ activePage, sort, sortBy, items: pageSize }, () =>
      this.fetchListingApiAction(activePage),
    );
  };

  applySearch = (key, value) => {
    this.setState(
      { ...this.state, [key]: value, activePage: enums.NUMERIC_VALUE.ONE },
      () => this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE),
    );
  };

  clearSearch = () =>
    this.setState({ title: '' }, () =>
      this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE),
    );

  applyFilters = filteredArray =>
    this.setState({ filteredArray }, () =>
      this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE),
    );

  clearFilters = () =>
    this.setState({ filteredArray: [] }, () => {
      this.fetchListingApiAction(enums.NUMERIC_VALUE.ONE);
      this.props.clearUserSearch();
    });

  /**
   * @description
   * render is used to render HTML
   */
  render() {
    const { activePage, sort, sortBy, items, tableConfigs } = this.state;
    const {
      activeSet,
      totalSet,
      productTypes,
      isRequesting,
      usersTypes,
      zipCodesTypes,
      statusTypes,
      activeEntity,
      mlsTypes,
      formTypes,
      sectionTypes,
      mlsFormTypes,
      submissionStatusTypes,
      companies,
      states,
      officeUsersTypes,
      officeStatusTypes,
      voucherTemplates,
      wholeSaleUsers,
      loggedInUsers,
      formStatuses,
      searchUsers,
      usersList,
      fetchingAverageTimeToList,
      averageTimeToList
    } = this.props;
    const {
      addPageUrl,
      addButtonText,
      searchPlaceHolder,
      filters,
    } = tableConfigs;
    const filterObj = { activePage, sort, sortBy, pageSize: items };
    const pageData = {
      path: addPageUrl,
      title: addButtonText,
      placeholder: searchPlaceHolder,
    };

    return (
      <React.Fragment>
        <SearchComponent
          applySearch={this.applySearch}
          clearSearch={this.clearSearch}
          pageData={pageData}
          activeEntity={activeEntity}
          fetchingAverageTimeToList={fetchingAverageTimeToList}
          averageTimeToList={averageTimeToList}
        />
        {filters.length > 0 && !isRequesting && (
          <FilterComponent
            filterContent={filters}
            usersList={usersList}
            searchUsers={searchUsers}
            roles={roleTypesConstant}
            productTypes={productTypes}
            userTypes={usersTypes}
            statusTypes={
              activeEntity === entityTypes.users
                ? statusesTypesConstant
                : statusTypes
            }
            zipCodeTypes={zipCodesTypes}
            applyFilters={this.applyFilters}
            clearFilters={this.clearFilters}
            activeEntity={activeEntity}
            mlsTypes={mlsTypes}
            formTypes={formTypes}
            sectionTypes={sectionTypes}
            mlsFormTypes={mlsFormTypes}
            submissionStatusTypes={submissionStatusTypes}
            companies={companies}
            states={states}
            officeUsersTypes={officeUsersTypes}
            officeStatusTypes={officeStatusTypes}
            voucherTemplates={voucherTemplates}
            wholeSaleUsers={wholeSaleUsers}
            loggedInUsers={loggedInUsers}
            formStatuses={formStatuses}
          />
        )}
       <ListingComponent
            isFilterApplied={filters?.length}
            tableColumns={tableConfigs.columns}
            listData={activeSet}
            filterObj={filterObj}
            totalItems={totalSet}
            handleTableChange={this.handleTableChange}
            activeEntity={activeEntity}
          />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators, ...userActionCreators }, dispatch);
}

function mapStateToProps(state) {
  const { activeSet, totalSet, lookup, isRequesting } = state.admin;
  return {
    activeSet: activeSet,
    totalSet,
    productTypes: lookup.productTypes || [],
    usersTypes: lookup.users || [],
    statusTypes: lookup.statuses || [],
    zipCodesTypes: lookup.zipCodes || [],
    mlsTypes: lookup.mlsAbbreviations || lookup.mls || [],
    formTypes: lookup.formTypes || [],
    sectionTypes: lookup.layoutType || [],
    mlsFormTypes: lookup.mlsFormTypes || [],
    submissionStatusTypes: lookup.submissionStatusTypes || [],
    companies: lookup.companies || [],
    states: lookup.states || [],
    officeStatusTypes: lookup.officeStatusTypes || [],
    officeUsersTypes: lookup.officeUsersTypes || [],
    voucherTemplates: lookup.voucherTemplates || [],
    wholeSaleUsers: lookup.wholeSaleUsers || [],
    loggedInUsers: lookup.loggedInUsers || [],
    isRequesting,
    formStatuses: lookup.formStatuses || [],
    usersList: state.admin.matchedUsersList,
    fetchingAverageTimeToList: state.admin.fetchingAverageTimeToList,
    averageTimeToList: state.admin.averageTimeToList
  };
}

const listingContainerWithRouter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListingContainer);
export { listingContainerWithRouter as ListingContainer };
