import React from 'react';
import { useSelector } from 'react-redux';

import styled from 'styled-components';
import { listingStepSelector } from '../../../Reducers/OnboardingReducerV2';
import ListingPreview from './ListingPreview';
import { EmbeddedYoutubeHintsPlayer } from './EmbeddedYoutubeHintsPlayer';

const Container = styled.div`
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    align-items: center;
  }
`;

const PreviewContainer = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  padding: 1.5rem 1.5rem 1.75rem 1.5rem;
  margin-bottom: 1rem;
  max-width: 21.5rem;
`;

const WidgetContainer = styled.div`
  display: flex;
  background: #f4f4f4;
  border-radius: 10px;
  padding: 1.4rem 1.4rem 1.7rem 1.4rem;
  margin-bottom: 1rem;
  max-width: 22rem;
  justify-content: center;

  &:empty {
    display: none;
  }

  .rpr-avm-widget {
    border-radius: 10px !important;
  }
`;

const ListingPreviewDesktopContainer = ({ hideVideoHints }) => {
  const step = useSelector(listingStepSelector);

  return (
    <Container>
      <PreviewContainer>
        <ListingPreview />
      </PreviewContainer>
      {step === 2 && <WidgetContainer id="rprAvmWidget" />}
      {!hideVideoHints && (<PreviewContainer>
        <EmbeddedYoutubeHintsPlayer
          width="296"
          height="536"
          mobile={false}
          customStyle={{
            borderRadius: '10px',
            marginBottom: 0,
            marginTop: 0,
          }}
        />
      </PreviewContainer>)}
    </Container>
  );
};

export default ListingPreviewDesktopContainer;
