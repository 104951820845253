import React from 'react';
import { connect } from 'react-redux';
import v8n from 'v8n';
import { bindActionCreators } from 'redux';
import { userActionCreators } from '../../../Actions/UserActions';
import {HeadTextWrap, Heading, HeadText, MidBoxWrapper, MidBox, Tick, InputFlex, CustomButton
} from '../../../Assets/Style/commonStyleComponents';
import { validationMessages, passwordType, regexPattern } from '../../../Constants/CommonConstants';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPasswordVisible: true,
            confirmPasswordVisible: true,
            resetForm: {
                newPassword: {
                    rules: [{
                        rule: v8n().not.empty().not.null(),
                        message: validationMessages.password,
                    },
                    {
                        rule: v8n().pattern(regexPattern.PASSWORD),
                        message: validationMessages.validPassword
                    }],
                    value: ""
                },
    
                confirmPassword: {
                    rules: [{
                        rule: v8n().not.empty().not.null(),
                        message: validationMessages.passwordNotMatch
                    }],
                    value: ""
                }
            },
            isPasswordReset: false,
        };
        this.paramsValue = new URLSearchParams(this.props.location.search);
    }


    handleFieldChanged = (e) => {
        let resetForm = { ...this.state.resetForm };
        resetForm[e.target.name].value = e.target.value;
        this.setState({ resetForm: { ...resetForm } });
      }

    handleFieldBlur = (e) => {
        this.validateField(e.target.name, e.target.value);
    }

    validateField = (key, value) => {
        let resetForm = { ...this.state.resetForm };
        let isError = false;
        for (let i = 0; i < resetForm[key].rules.length; i++) {
            let rule = resetForm[key].rules[i];
            if (resetForm.confirmPassword.value) {
                isError = resetForm.newPassword.value !== resetForm.confirmPassword.value;
                if (isError) {
                    resetForm[passwordType.confirmPassword].error = validationMessages.passwordNotMatch;
                    break;
                }
            }

            if (!rule.rule.test(value)) {
                resetForm[key].error = rule.message;
                isError = true;
                break;
            }
        }

        if (!isError) {
            resetForm[key].error = null;
        }

        this.setState({ resetForm: { ...resetForm }, isError: Object.keys(resetForm).some(k => resetForm[k].error !== undefined && resetForm[k].error !== null) });

        return isError;
    }

    submitPasswordForm = (password) => {
        const payload = {
            password,
            email: this.paramsValue.get("email"),
            code: this.paramsValue.get("code")
        }
        this.props.resetPasswordRequest(payload, () => {
            this.setState({ 
                isPasswordReset:  true,
            })
        });
    }

    validateAndSubmit = (e) => {
        e.preventDefault();
        let isPasswordError = false;

        Object.keys(this.state.resetForm).forEach((k) => {
            const isErrorExist = this.validateField(k, this.state.resetForm[k].value);
            if (isErrorExist) {
                isPasswordError = true;
            }
        })

        if (!isPasswordError) {
            this.submitPasswordForm(this.state.resetForm[passwordType.newPassword].value);
        }
    }


    render() {
        const { isPasswordReset,confirmPasswordVisible,newPasswordVisible } = this.state;

        return (
          <React.Fragment>
            <Navbar />
            <MidBoxWrapper>
              <MidBox>
                {
                    !isPasswordReset
                    ?
                        <div>
                            <HeadTextWrap>
                              <Heading>Reset your password</Heading>
                              <HeadText>Type in your current and new password to reset.</HeadText>
                            </HeadTextWrap> 
                            <form onSubmit={this.handleSaveAccountDetails} autoComplete="off">
                                <InputFlex
                                  name="email"
                                  title="User email"
                                  type="text"
                                  value={this.paramsValue.get("email")}
                                  className="disabled-input"                        
                                />
                                <div className='admin-input-and-data-wrapper'>
                                <div className='admin-w-100'>
                                <div className='admin-input-with-icon with-bottom-text'>
                                <InputFlex
                                  name="newPassword"
                                  title="New Password"
                                  type={newPasswordVisible ? 'password' : 'text'}
                                  value={this.state?.resetForm?.newPassword?.value}
                                  error={this.state?.resetForm?.newPassword?.error}
                                  onChange={this.handleFieldChanged}
                                  onBlur={this.handleFieldBlur}
                                />
                                <i className={newPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                                    aria-hidden='true'
                                    onClick={() =>
                                    this.setState({
                                        newPasswordVisible: !this.state.newPasswordVisible,
                                    })
                                    }
                                ></i></div></div></div>
                                <div className='admin-input-and-data-wrapper'>
                                <div className='admin-w-100'>
                                <div className='admin-input-with-icon with-bottom-text'>
                                <InputFlex
                                  name="confirmPassword"
                                  title="Confirm Password"
                                  type={confirmPasswordVisible ? 'password' : 'text'}
                                  value={this.state?.resetForm?.confirmPassword?.value}
                                  error={this.state?.resetForm?.confirmPassword?.error}
                                  onChange={this.handleFieldChanged}
                                  onBlur={this.handleFieldBlur}
                                />
                                  <i className={confirmPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                                    aria-hidden='true'
                                    onClick={() =>
                                    this.setState({
                                        confirmPasswordVisible: !this.state.confirmPasswordVisible,
                                    })
                                    }
                                ></i></div></div></div>
                                <CustomButton type="submit" className="block" onClick={this.validateAndSubmit}>Reset Password</CustomButton>
                            </form>
                        </div>
                    :
                    <div>
                      <HeadTextWrap className="email-sent-wrapper">
                        <Tick><i className="fa fa-check" aria-hidden="true"></i></Tick>
                        <Heading>Password Changed Successfully</Heading>
                        <HeadText>Your password has been reset. You can now login using your new credentials.</HeadText>
                      </HeadTextWrap>
                    </div>
                }
                </MidBox>
            </MidBoxWrapper>
            <Footer />
          </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
  return {
      userDetails: state.user.userDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
