import React from 'react';
import { createGlobalStyle } from "styled-components";
import '../../../Assets/Style/reset.css';
import '../../../Assets/Style/clearfix.css';

const GlobalStyle = createGlobalStyle`
    body {
        font-family: "National-Regular",sans-serif;
        -webkit-font-smoothing:antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    a {
        color:${(props) => props.theme.primary}
        &:hover {
            color:${(props) => props.theme.secondary}
        }
    }
`;

export default props => (
  <section className="main">
    {props.children}
    <GlobalStyle />
  </section>
);
