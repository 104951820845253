import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../Constants/CommonConstants';
import ApiUrls from '../Constants/ApiUrls';

class ListingPhotoService {

    constructor() {

        let urls = ((window.config || {}).urls || {}).listingPhoto || {};

        urls.base = urls.base || "/";

        this.urls = urls;

    }

    addPhoto(listingId, request) {

        const url = this.urls.getListing || `${ApiUrls.addPhoto}${listingId}`;

        return fetch(url, {
            method: apiMethodType.POST,
            data: { ...request },
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    reorderPhotos(listingId, request) {

        const url = this.urls.getListing || `${ApiUrls.reorderPhotos}${listingId}`;

        return fetch(url, {
            method: apiMethodType.POST,
            data: { ...request },
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    deletePhoto(photoId){
        const url = this.urls.getListing || `${ApiUrls.deletePhoto}${photoId}`;
        return fetch(url, {
            method: apiMethodType.DELETE,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

}

export default ListingPhotoService;
