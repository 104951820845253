import React from 'react';

class MarketingYourHome extends React.Component {
  render() {
    return (
      <>
        <h2>Pricing Right</h2>
        <p>Marketing your home involves several factors: pricing it right, getting the word out to potential buyers, and working with serious buyers.</p>
        <p>The single most important factor in selling your house is the asking price.</p>
        <p>
          That’s why realty agents offer a free “comparative market analysis” to entice you to call them for an introductory meeting. Of course, at that meeting,
          the agent wants to sign you to a listing agreement. But you already know that you can save the 3 percent commission – that’s 3 percent of your hard-won
          home equity – by listing directly with USRealty.com and directly accessing the real estate industry’s most powerful databases and selling sites.
        </p>
        <p>
          The right asking price gets your home listing in front of the most interested buyers. Ask too much and you’ll have to discount your house, sapping time and
          interest as your house lingers on the market. Ask too little and you give away home equity that you need for your next purchase, or for other financial goals.
        </p>
        <p>
          USRealty.com recommends an evidence-based approach to pricing your home. Buyers want to see that your house is priced in line with market trends for your neighborhood.
          The more detail you provide, the more persuasive your rationale. And, you will need proof of market value when you negotiate your deal.
        </p>
        <strong>Use these pricing estimators to get thorough, current estimates of your home’s market value:</strong>
        <ul>
          <li>
            CoreLogic is one of the biggest and most authoritative databases of home sales prices and market value.
            CoreLogic’s <a href="https://www.corelogic.com/landing-pages/create-your-corelogic-store-account-1.aspx" target="_blank">RealQuest</a> tool provides both a free
            snapshot of market value for your house, and a detailed report for only $49.
          </li>
          <li>
            Chase Bank provides this <a href="https://www.chase.com/mortgage/mortgage-resources/home-value-estimator"
            target="_blank">estimator</a>, based on databases used by the industry.
          </li>
          <li>
            The <a href="http://www.fhfa.gov/DataTools/Tools/pages/hpi-calculator.aspx"
             target="_blank">Federal Housing Finance Agency</a> provides trend information for most neighborhoods.
            This is valuable for showing how home values in your area have held up.
          </li>
        </ul>
        <p>
          If your house is unusual, or if properties in your area sell slowly, you might want to spend about $300 for an appraisal from a licensed appraiser.
          This is the highest and most credible source of home valuation and enables you to price your house accurately. A buyer will still have to pay for another
          appraisal, as required by his or her mortgage lender, but if your buyer can provide the lender’s appraiser with a current report from your appraiser,
          chances are the lender’s appraiser will arrive at the same value. That means that your deal probably won’t fall apart due to a lender’s appraisal that comes
          in below the negotiated price.
        </p>
        <h2>How Not to Price Your House</h2>
        <p>
          It’s tempting to rely on informal research about houses currently on the market and neighbor’s opinions as to what your house is worth.
          But opinions aren’t worth much when you are in serious negotiations with a buyer. To increase your chances of negotiating a sale price that
          will hold up to the appraisal ordered by the buyer’s lender, don’t:
        </p>
        <ul>
          <li>
            Rely just on ‘comparative market analysis” reports provided by local agents.
            Such reports are marketing tools that agents use to win your listing, and have no independent value.
          </li>
          <li>
            Calculate the asking price based on what you paid for the house plus the cost of renovations and maintenance.
            Today’s market value has nothing to do with what you paid or what you spent.
          </li>
          <li>
            Calculate the asking price based on what you owe. Today’s market value has nothing to do with the outstanding amount
            you must pay back to lenders, including the original mortgage, home equity loans, and any other loans or liens against the property.
          </li>
          <li>Calculate the asking price based on what you want. Today’s market value has nothing to do with how much money you need to buy your next house.</li>
          <li>
            Include sentimental value as part of the value. The buyer wants to create her own memories in her new house. She won’t pay extra because you have
            happy memories of holidays and family events in the house.
          </li>
          <li>
            Use the USRealty.com <a href="https://www.omnicalculator.com/finance/commission/" target="_blank">commission calculator</a>
            &nbsp;and <a href="https://www.mortgagecalculator.org/" target="_blank">mortgage calculator</a>
            &nbsp;to rough out the
            key numbers: how much you will net from the sale of the house, after you pay selling costs; and how current interest rates shape
            the carrying cost of your house for potential buyers.
          </li>
        </ul>
        <h2>Write a Winning Listing</h2>
        <p>
          The listing is your first and best chance to make a good impression for your house. Your goal in writing a winning USRealty.com listing (or any listing, for that matter)
          is to intrigue a potential buyer so that they want to arrange to see your house.
        </p>
        <p><strong>Price</strong> is the first filter. Most buyers have figured out how much they can realistically afford and most search by price.</p>
        <p>
          <strong>Location</strong> is a close second. Buyers want a house that enables them to live the life they want. Location is also a price factor:
          houses located further away from highways, public transportation, and daily shopping must be priced to factor in that
          inconvenience and the cost of getting to and from that out-of-the-way location.
        </p>
        <p>
          <strong>Size</strong> is the final key factor. How much house will the buyer get for her money? Size is expressed in terms of the
          number of rooms; total square footage of the house; and the size of the property that the house occupies. The number of bedrooms and
          bathrooms are often used as quick reference points for overall house size.
        </p>
        <p>
          Your listing needs to open with a bright, engaging description that is also accurate. Buyers have many tools to size up your property from their
          phones or tablets. They can look at it using Google Earth, neighborhood photos, and can access public records. Buyers will quickly see if you describe
          your neighborhood as ‘quiet’ when the house is adjacent to a highway or rail line.
        </p>
        <p>
          The sweet spot is to describe the house accurately, clearly and with enthusiasm. What initially attracted you to this house? What do people comment
          on when they first visit? What do neighbors like about your house?
        </p>
        <p>
          Focus on amenities and lifestyle that relate to photos you will include in the listing. If you describe the house as “bright” and “contemporary,” be
          sure to post photos of the interior on sunny days, with gleaming floors and streak-free windows, with a glimpse of a pretty view outside.
        </p>
        <p>
          Summarize recent improvements. Scan listings for current houses for sale in your area to see what types of terms are used to describe houses like yours.
          Often, brand names are used to signify quality (Kohler, Amana, Jenn-Air and so on) or certain types of wood (“cherry cabinets,” “maple floors”) finishes
          (“white kitchen renovated two years ago”) or architectural style (Craftsman, Victorian, mid-century, Colonial) are keywords for buyers.
        </p>
        <p>
          Make it easy for buyers to contact you to arrange to see the house. USRealty.com listings provide contact information. Be sure you monitor your USRealty.com
          updates continually, and make contingency plans to ensure that your house is clean, fresh, and available for showing at a moment’s notice.
        </p>
        <strong>When a buyer comes to tour your house, have plenty of detailed information ready:</strong>
        <ul>
          <li>A fresh printout of your USRealty.com listing</li>
          <li>Supplemental information (use our handy list of supplemental listing information to assemble buyer-pleasing details)</li>
          <li>A contract</li>
          <li>Your contact information</li>
        </ul>
      </>
    )
  }
}

export default MarketingYourHome;
