import React, { useMemo } from 'react';
import { propertyTypesSelector } from '../../../../../Reducers/LookupReducer';
import { useSelector } from 'react-redux';


const PropertyTypeBage = ({ propertyTypeId }) => {

  const propertyTypes = useSelector(propertyTypesSelector);
  const propertyType = useMemo(() => propertyTypes?.find(x => x.id === propertyTypeId)?.name ?? '-', [propertyTypeId, propertyTypes]);
  return (<span>{propertyType}</span>);
}

export default PropertyTypeBage;
