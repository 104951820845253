import { defaultPlanAddress, roleType } from "../Constants/CommonConstants";
import { actions } from "../Actions/OnboardingActions";
import { onboardingService } from '../Services/OnboardingService';
import { getCurrentUserData } from "../Utilities/commonFunctions";
const windowUrl = window.location.search;
const params = new URLSearchParams(windowUrl);
let placeid = params.get('placeId');

const initialState = {
    isFetching: false,
    redirect: false,
    paymentIntent: null,
    selectedAddress: placeid ? {} : onboardingService.getLocal("selectedAddress") || {},
    selectedPlan: onboardingService.getLocal("selectedPlan") || {},
    planData: {
        plans: [],
        planFeatures: []
    },
    mlsCategory: [],
    voucherCodeData: onboardingService.getLocal("redeemVoucherDetail") || {},
};


export const onboardingReducer = (state = initialState, action) => {
    state = state || initialState;
    switch (action.type) {
        case actions.SELECT_ADDRESS:
            const address = selectSelectedAddress(action.payload);
            onboardingService.setLocal("selectedAddress", address);
            if(Number(address.zip) !== Number(defaultPlanAddress.zip)) {
                onboardingService.clearLocal("selectedPlan");
            }
            if(getCurrentUserData().role === roleType.INSTITUTIONAL) {
                onboardingService.setLocal("selectedPlan", address);
            }
            onboardingService.clearLocal("redeemVoucherDetail");
            return {
                ...state, selectedPlan: {}, planData: { plans: [], planFeatures: [] }, selectedAddress: address };

        case actions.CLEAR_PLANS:
            onboardingService.clearLocal("selectedPlan");
            onboardingService.clearLocal("redeemVoucherDetail");

            return { ...state, selectedPlan: {}, planData: { plans: [], planFeatures: [] } };


        case actions.PLANS_REQUESTED:

            onboardingService.clearLocal("selectedPlan");
            onboardingService.clearLocal("redeemVoucherDetail");
            return { ...state, selectedPlan: {}, planData: { plans: [], planFeatures: [] }, isFetching: true, isAddressNotServiceAble: false, isCheckAddressCoverageable: false };

        case actions.PLANS_RESOLVED:

            // If error, return errors
            if (action.isError) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false
                };
            }
            return {...state, planData: { ...action.payload.value }, errors: {}, isFetching: false};

        case actions.SELECT_PLAN:
            onboardingService.setLocal("selectedPlan", action.payload);

            return { ...state, selectedPlan: action.payload };

        case actions.VOUCHER_REDEEM:
            onboardingService.setLocal("selectedAddress", {...action.payload.address});
            onboardingService.setLocal("selectedPlan", { voucherApplied: true });
            onboardingService.setLocal("redeemVoucherDetail", { ...action.payload });
            return { ...state, selectedPlan: { voucherApplied: true }, selectedAddress: { ...action.payload.address }};

        case actions.PLACE_ORDER_REQUESTED:
        case actions.VOUCHER_VALIDATE_REQUESTED:
        case actions.PLACE_ORDER_WITH_VOUCHER_REQUESTED:
            return { ...state, isFetching: true };

        case actions.PLACE_ORDER_RESOLVED:
            if (!action.payload?.success) {
                return {
                  ...state,
                  errors: { ...action.payload },
                  isFetching: false,
                  isPaymneDecline:true
                };
            }
            localStorage.removeItem('isFromHomePage');
            return { ...state, isFetching: false, redirect: action.payload.value.redirect };

        case actions.FINISH_ONBOARDING:
            return { ...state, isFetching: false, redirect: false, selectedPlan: {}, planData: { plans: [], planFeatures: [] }, selectedAddress: {} };

        case actions.ADDRESS_NOT_SERVICEABLE:
            return { ...state, isAddressNotServiceAble: true };

        case actions.CHECK_ADDRESS_COVERAGE_RESOLVED:
            return { ...state, isCheckAddressCoverageable: !action.payload?.value, isAddressNotServiceAble: false };

        case actions.CLOSE_MODAL_POPUP:
            return { ...state, isAddressNotServiceAble: false, isCheckAddressCoverageable: false,isPaymneDecline:false };

        case actions.CONTACT_US_REQUEST_RESOLVED:
        case actions.GLOBAL_SUMMARY_REQUEST_RESOLVED:

            return { ...state };
        case actions.VOUCHER_VALIDATE_REQUEST_RESOLVED:
            if (!action.payload?.success) {
                return {
                  ...state,
                  errors: { ...action.payload },
                  isFetching: false
                };
              }
            return { ...state, voucherCodeData: action.payload.value, isFetching: false };
        case actions.MLS_CATEGORY_RESOLVE:
            if (action.isError) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false
                };
            }
            return {...state, mlsCategory: action.payload?.value || [], errors: {}, isFetching: false};
        case actions.LISTING_MLS_CATEGORY_RESOLVE:
            if (action.isError) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false
                };
            }
            return { ...state, errors: {}, isFetching: false };

        case actions.PRODUCT_REQUESTED:

            return { ...state, productsInfo: [], isFetching: true };

        case actions.PRODUCT_RESOLVED:

            if (action.isError) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false
                };
            }
            return { ...state, productsInfo: [ ...action.payload.value ], errors: {}, isFetching: false };
        case actions.PAYMENT_INTENT_REQUESTED:
            return { ...state, paymentIntent: null, isFetching: true };
        case actions.PAYMENT_INTENT_RESOLVED:
            if (action.isError) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false
                };
            }
            return { ...state, paymentIntent: action.payload, errors: {}, isFetching: false };

        default: return state;
    }
};

const selectSelectedAddress = (address) => {
    if (Object.keys(address).length > 0) {
        const isPostalCode = address.address_components.filter(c => c.types.includes("postal_code")).length;
        const isCountyExist = address.address_components.filter(c => c.types.includes("administrative_area_level_2")).length;
        const isCityExist = address.address_components.filter(c => c.types.includes("locality") || c.types.includes("sublocality") || c.types.includes("neighborhood")).length;
        const isSubPremise = address.address_components.filter(c => c.types.includes("subpremise")).length;
        return {
            components: address.address_components,
            address1: `${address.address_components.filter(c => c.types.includes("street_number") || c.types.includes("route"))[0].long_name} ${address.address_components.filter(c => c.types.includes("route"))[0].short_name}`,
            address2: address.unit || "",
            city: isCityExist > 0 ? (address.address_components.filter(c => c.types.includes("locality"))[0] || address.address_components.filter(c => c.types.includes("sublocality"))[0] || address.address_components.filter(c => c.types.includes("neighborhood"))[0]).long_name : '',
            county: isCountyExist > 0 ? address.address_components.filter(c => c.types.includes("administrative_area_level_2"))[0].long_name.replace(/ County/g, '') : '',
            state: address.address_components.filter(c => c.types.includes("administrative_area_level_1"))[0].short_name,
            zip: isPostalCode > 0 ? address.address_components.filter(c => c.types.includes("postal_code"))[0]?.long_name : '',
            formatted: address.formatted_address,
            id: address.id,
            placeId: address.place_id,
            name: address.name,
            url: address.url,
            location: {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng(),
                formatted: `${address.geometry.location.lat()},${address.geometry.location.lng()}`
            },
            unit: isSubPremise > 0 ? address.address_components.filter(c => c.types.includes("subpremise"))[0]?.long_name : ""
        };
    }
    return {};
}

export const selectedAddressSelector = state => state.onboarding.selectedAddress;
export const planDataSelector = state => state.onboarding.planData;
export const isFetchingSelector = state => state.onboarding.isFetching;
export const voucherCodeDetailsSelector = state => state.onboarding.voucherCodeData || {};
export const isAddressInvalidSelector = state => state?.onboarding?.isCheckAddressCoverageable;
export const isAddressNotserviceAbleSelector = state => state?.onboarding?.isAddressNotServiceAble;