import DashboardService from '../Services/DashboardService';
import FileService from '../Services/FileService';
import ListingPhotoService from '../Services/ListingPhotoService';
import { alertActions } from './ToastActions';
import { startLoading, endLoading } from './LoaderActions'
import { dataService } from '../Services/DataService';
import { staticMessages } from '../Constants/CommonConstants';
import { checkResponse } from '../Utilities/commonFunctions';
import LogRocket from 'logrocket';
import { onboardingServiceV2 } from '../Services/OnboardingServiceV2';
import { getPropertyOwnerFields } from '../Utilities/utils';
export const actions = {
    LISTINGS_REQUESTED: 'LISTINGS_REQUESTED',
    LISTINGS_RESOLVED: 'LISTINGS_RESOLVED',
    LISTINGS_FAILED: 'LISTINGS_FAILED',
    LISTING_REQUESTED: 'LISTING_REQUESTED',
    LISTING_RESOLVED: 'LISTING_RESOLVED',
    SAVE_BASIC_FIELD_REQUESTED: 'SAVE_BASIC_FIELD_REQUESTED',
    SAVE_BASIC_FIELD_RESOLVED: 'SAVE_BASIC_FIELD_RESOLVED',
    SAVE_FORM_FIELD_REQUESTED: 'SAVE_FORM_FIELD_REQUESTED',
    SAVE_FORM_FIELD_RESOLVED: 'SAVE_FORM_FIELD_RESOLVED',
    HANDLE_FIELD_CHANGE: 'HANDLE_FIELD_CHANGE',
    HANDLE_IMAGE_FIELD_CHANGE: 'HANDLE_IMAGE_FIELD_CHANGE',
    REORDER_PHOTOS_REQUESTED: 'REORDER_PHOTOS_REQUESTED',
    REORDER_PHOTOS_RESOLVED: 'REORDER_PHOTOS_RESOLVED',
    UPLOAD_LISTING_PHOTO_REQUESTED: 'UPLOAD_LISTING_PHOTO_REQUESTED',
    UPLOAD_LISTING_PHOTO_RESOLVED: 'UPLOAD_LISTING_PHOTO_RESOLVED',
    PHOTO_UPLOAD_PROGRESS_UPDATED: 'PHOTO_UPLOAD_PROGRESS_UPDATED',
    DELETE_PHOTO_REQUESTED: 'DELETE_PHOTO_REQUESTED',
    DELETE_PHOTO_RESOLVED: 'DELETE_PHOTO_RESOLVED',
    SUBMIT_MLS_FORM_REQUESTED: 'SUBMIT_MLS_FORM_REQUESTED',
    SUBMIT_MLS_FORM_RESOLVED: 'SUBMIT_MLS_FORM_RESOLVED',
    PUBLIC_VIEW_LISTING_REQUESTED: 'PUBLIC_VIEW_LISTING_REQUESTED',
    PUBLIC_VIEW_LISTING_RESOLVED: 'PUBLIC_VIEW_LISTING_RESOLVED',
    FORM_LISTING_REQUESTED: 'FORM_LISTING_REQUESTED',
    FORM_LISTING_RESOLVED: 'FORM_LISTING_RESOLVED',
    VOUCHER_LISTING_REQUESTED: 'VOUCHER_LISTING_REQUESTED',
    VOUCHER_LISTING_RESOLVED: 'VOUCHER_LISTING_RESOLVED',
    COVERAGE_LISTING_REQUESTED: 'COVERAGE_LISTING_REQUESTED',
    COVERAGE_LISTING_RESOLVED: 'COVERAGE_LISTING_RESOLVED',
    SAVE_PREVIEW_FORM_FIELD_REQUESTED: 'SAVE_PREVIEW_FORM_FIELD_REQUESTED',
    SAVE_PREVIEW_FORM_FIELD_RESOLVED: 'SAVE_PREVIEW_FORM_FIELD_RESOLVED',
    NETWORKUSER_LISTING_REQUESTED: 'NETWORKUSER_LISTING_REQUESTED',
    NETWORKUSER_LISTING_RESOLVED: 'NETWORKUSER_LISTING_RESOLVED',
    REMOVE_PHOTO_RESOLVED: 'REMOVE_PHOTO_RESOLVED',
    DELETE_DRAFT_LISTINGS_REQUESTED: 'DELETE_DRAFT_LISTINGS_REQUESTED',
    DELETE_DRAFT_LISTINGS_RESOLVED: 'DELETE_DRAFT_LISTINGS_RESOLVED',
    DELETE_DRAFT_LISTINGS_FAILED: 'DELETE_DRAFT_LISTINGS_FAILED',
    SET_LISTING_LIST_FILTERS: 'SET_LISTING_LIST_FILTERS',
    SET_LISTING_LIST_VIEW_TYPE: 'SET_LISTING_LIST_VIEW_TYPE',
    UPDATE_TITLE_DATA_REQUESTED: 'UPDATE_TITLE_DATA_REQUESTED',
    UPDATE_TITLE_DATA_RESOLVED: 'UPDATE_TITLE_DATA_RESOLVED',
    UPDATE_TITLE_DATA_FAILED: 'UPDATE_TITLE_DATA_FAILED',
    SET_PROPERTY_OWNERS_FIELDS: 'SET_PROPERTY_OWNERS_FIELDS',
    SET_PROPERTY_HELD: 'SET_PROPERTY_HELD',
    SET_TITLE_COMPANY: 'SET_TITLE_COMPANY',
    UPDATE_LISING_CASH_OFFER_STATUS: 'UPDATE_LISING_CASH_OFFER_STATUS',
}

const dashboardService = new DashboardService();
const listingPhotoService = new ListingPhotoService();
let fileService;

export const dashboardActionCreators = {
    getListings: () => async (dispatch) => {
        dispatch({ type: actions.LISTINGS_REQUESTED });

        try {
            const { data } = await dashboardService.getListings();
            dispatch({ type: actions.LISTINGS_RESOLVED, payload: data });
        }
        catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: actions.LISTINGS_FAILED, payload: [] });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions getListings FAILED'
                },
            });
        }
    },
    getListing: (listingId) => async (dispatch, getState) => {
        dispatch({ type: actions.LISTING_REQUESTED, payload: listingId });
        let isError = false;
        let dataJson;

        try {
            const { data } = await dashboardService.getListing(listingId);
            dataJson = data;
            checkResponse(data);
        }
        catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions getListing FAILED',
                    listingId,
                },
            });
        }
        dataJson.listing.ownershipType.ownersData = dataJson.listing.ownershipType.ownersData.map(owner => getPropertyOwnerFields(owner.ownerType, owner));
        dispatch({ type: actions.LISTING_RESOLVED, payload: dataJson, isError: isError });
    },
    uploadListingPhoto: (listingId, maxPriority, { file, thumbnail, absoluteUrl, thumbnailUrl, order, md5 }) => async (dispatch, getState) => {
        dispatch({ type: actions.UPLOAD_LISTING_PHOTO_REQUESTED, payload: { listingId, photo: { file, thumbnail, absoluteUrl, thumbnailUrl, order, md5 } } });
        let isError = false;
        let listingPhotoId, key;

        if (!fileService) {
            fileService = new FileService();
        }

        //TODO: Refactoring. Make uploading in 1 method
        fileService.uploadPhoto(file, thumbnail)
            .then(({ key }) => {
                return listingPhotoService.addPhoto(listingId, { Path: `image-uploads/${key}`, Priority: maxPriority });
            })
            .then((resp) => {
                const { data } = resp;
                absoluteUrl = data.absoluteUrl;
                thumbnailUrl = data.thumbnailUrl;
                listingPhotoId = data.listingPhotoId;
                key = data.path;

                dispatch({
                    type: actions.UPLOAD_LISTING_PHOTO_RESOLVED,
                    payload: { listingId, photo: { file, thumbnail, listingPhotoId, absoluteUrl, thumbnailUrl, order, md5, key } }, isError: isError
                });
            });


        // if (awsService === undefined) {
        //     let appSettings = getState().user.appSettings;
        //     let userIdentity = getState().user.s3Identity;

        //     if (!appSettings || !userIdentity) {
        //         isError = true;
        //         dispatch({ type: actions.UPLOAD_LISTING_PHOTO_RESOLVED, isError: isError });
        //         return;
        //     }

        //     awsService = new AwsService(
        //         appSettings.aws.s3Bucket,
        //         appSettings.aws.baseUploadPath,
        //         appSettings.aws.identityPoolId,
        //         userIdentity.identityId,
        //         userIdentity.token,
        //         appSettings.aws.regionEndpointSystemName
        //     );
        // }

        // try {

        //     let response = awsService.uploadPhoto(file, thumbnail, (progress) => {
        //         const percent = 1.0 * progress.loaded / progress.total;
        //         dispatch({ type: actions.PHOTO_UPLOAD_PROGRESS_UPDATED, payload: { order: order, progress: percent > 0.1 ? percent : 0.1 } });
        //     });

        //     response
        //         .then(
        //             (responseData) => {
        //                 responseData.forEach((p) => {

        //                     if(p.key.indexOf("thumb") < 0) {
        //                         listingPhotoService.addPhoto(listingId, {Bucket: p.Bucket, Path: p.key, Priority: maxPriority}).then(resp => {
        //                             if (!resp.ok) {
        //                                 isError = true;
        //                             }

        //                             resp.json().then(data => {
        //                                 absoluteUrl = data.absoluteUrl;
        //                                 thumbnailUrl = data.thumbnailUrl;
        //                                 listingPhotoId = data.listingPhotoId;
        //                                 key = data.path;

        //                                 dispatch({
        //                                     type: actions.UPLOAD_LISTING_PHOTO_RESOLVED,
        //                                     payload: { listingId, photo: { file, thumbnail, listingPhotoId, absoluteUrl, thumbnailUrl, order, md5, key } }, isError: isError });
        //                             });
        //                         });
        //                     }
        //                 });
        //             },
        //             (err) => {
        //                 dispatch(alertActions.errorAlert(staticMessages.networkError));
        //                 console.log(err);
        //             }
        //         );
        // }
        // catch (ex) {
        //     isError = true;
        //     console.log(ex);
        // }

    },
    reorderPhotos: (listingId, { oldIndex, newIndex }) => async (dispatch, getState) => {
        dispatch({ type: actions.REORDER_PHOTOS_REQUESTED, payload: { oldIndex, newIndex } });
        let isError = false;

        try {
            await listingPhotoService.reorderPhotos(listingId, { oldIndex, newIndex });
        }
        catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions reorderPhotos FAILED',
                    listingId,
                },
            });
        }

        dispatch({ type: actions.REORDER_PHOTOS_RESOLVED, payload: { listingId, newIndex }, isError: isError });
    },
    handleFieldChange: ({ listingId, fieldName, formId, formFieldId, value }) => async (dispatch) => {
        dispatch({ type: actions.HANDLE_FIELD_CHANGE, payload: { listingId, fieldName, formId, formFieldId, value } });
    },
    handleImageFieldChange: ({ listingId, fieldName, formId, formFieldId, value }) => async (dispatch) => {
        dispatch({ type: actions.HANDLE_IMAGE_FIELD_CHANGE, payload: { listingId, fieldName, formId, formFieldId, value } });
    },
    saveBasicField: (reqObj, cb) => async (dispatch) => {
        dispatch({ type: actions.SAVE_BASIC_FIELD_REQUESTED, payload: reqObj });
        let isError = false;
        try {
            await dashboardService.saveBasicField(reqObj);
            cb({ err: false });
        }
        catch (ex) {
            isError = true;
            cb({ err: true });
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions saveBasicField FAILED',
                    reqObj,
                },
            });
        }

        dispatch({ type: actions.SAVE_BASIC_FIELD_RESOLVED, isError: isError });
    },
    saveFormField: (userFormId, formFieldId, value) => async (dispatch, getState) => {
        dispatch({ type: actions.SAVE_FORM_FIELD_REQUESTED, payload: { userFormId, formFieldId, value } });

        let isError = false;

        try {
            const { data } = await dashboardService.saveFormField(userFormId, formFieldId, value);
            checkResponse(data);
        }
        catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            isError = true;

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions saveFormField FAILED',
                    userFormId,
                    formFieldId,
                    value,
                },
            });
        }

        dispatch({ type: actions.SAVE_FORM_FIELD_RESOLVED, isError: isError, payload: formFieldId });
    },

    removePhotosListing: index => async dispatch => {
        dispatch({ type: actions.REMOVE_PHOTO_RESOLVED, payload: { index } });
    },

    deletePhoto: (photoId) => async (dispatch) => {
        dispatch({ type: actions.DELETE_PHOTO_REQUESTED });
        let isError = false;
        let dataJson;

        try {
            const { data } = await listingPhotoService.deletePhoto(photoId);
            dataJson = data;
            checkResponse(data);
        }
        catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions deletePhoto FAILED',
                    photoId,
                },
            });
        }
        dispatch({ type: actions.DELETE_PHOTO_RESOLVED, payload: dataJson, isError: isError });
    },

    submitMLSForm: (reqObj, cb) => async (dispatch) => {
        dispatch({ type: actions.SUBMIT_MLS_FORM_REQUESTED, payload: reqObj });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        let isError = false;
        let dataJson;

        try {
            const { data } = await dashboardService.submitMLSForm(reqObj);

            dataJson = data;
            checkResponse(data);
            cb();
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions submitMLSForm FAILED',
                    reqObj,
                },
            });
        }
        dispatch({ type: actions.SUBMIT_MLS_FORM_RESOLVED, isError: isError, payload: dataJson });
    },

    //TODO: Remove after changing flow
    submitMLSFormV2: (reqObj, cb) => async (dispatch) => {
        dispatch({ type: actions.SUBMIT_MLS_FORM_REQUESTED, payload: reqObj });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        let isError = false;

        try {
            await onboardingServiceV2.resubmitMLSFormV2(reqObj.listingId)
            cb();
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions submitMLSFormV2 FAILED',
                    reqObj,
                },
            });
        }
        dispatch({ type: actions.SUBMIT_MLS_FORM_RESOLVED, isError: isError });
    },

    publicViewListingDetail: (listingId) => async (dispatch) => {
        dispatch({ type: actions.PUBLIC_VIEW_LISTING_REQUESTED, payload: listingId });
        let isError = false;
        let dataJson;

        try {
            const { data } = await dashboardService.publicViewListingDetail(listingId);
            dataJson = data;
            checkResponse(data);
        }
        catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions publicViewListingDetail FAILED',
                    listingId,
                },
            });
        }

        dispatch({ type: actions.PUBLIC_VIEW_LISTING_RESOLVED, payload: dataJson, isError: isError });
    },

    gettingFormListing: (reqobj) => async (dispatch) => {
        dispatch({ type: actions.FORM_LISTING_REQUESTED, payload: reqobj });
        let isError = false;
        let dataJson;

        try {
            const { data } = await dashboardService.gettingFormListing(reqobj);
            dataJson = data;
            checkResponse(data);
        } catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions gettingFormListing FAILED',
                    reqobj,
                },
            });
        }

        dispatch({ type: actions.FORM_LISTING_RESOLVED, payload: dataJson, isError: isError });
    },

    updateListingType: (entity) => async (dispatch) => {
        dispatch({ type: actions.VOUCHER_LISTING_REQUESTED, payload: entity });
    },

    getWholeSaleListings: (userId, entity, reqObj) => async (dispatch) => {
        dispatch({ type: actions.VOUCHER_LISTING_REQUESTED, payload: entity });
        if (!userId || !reqObj) return;
        let isError = false;
        let dataJson;
        try {
            const { data } = await dashboardService.getWholeSaleListings(userId, reqObj);
            dataJson = data;
            checkResponse(data);
        }
        catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));


            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions getWholeSaleListings FAILED',
                    userId,
                    entity,
                    reqObj,
                },
            });
        }

        dispatch({ type: actions.VOUCHER_LISTING_RESOLVED, payload: dataJson, isError: isError });
    },

    getWholeSaleCoverage: (entity, reqObj) => async (dispatch) => {
        dispatch({ type: actions.COVERAGE_LISTING_REQUESTED, payload: entity });
        let isError = false;
        let dataJson;
        try {
            const { data } = await dataService.getAll(entity, reqObj);
            dataJson = data;
            checkResponse(data);
        }
        catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions getWholeSaleCoverage FAILED',
                    entity,
                    reqObj,
                },
            });
        }

        dispatch({ type: actions.COVERAGE_LISTING_RESOLVED, payload: dataJson, isError: isError });
    },

    getNetworkUser: (userId, entity, reqObj) => async (dispatch) => {
        dispatch({ type: actions.NETWORKUSER_LISTING_REQUESTED, payload: entity });
        if (!userId || !reqObj) return;
        let isError = false;
        let dataJson;
        try {
            const { data } = await dashboardService.getNetworkUser(userId, reqObj);
            dataJson = data;
            checkResponse(data);
        } catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions getNetworkUser FAILED',
                    userId,
                    entity,
                    reqObj,
                },
            });
        }

        dispatch({ type: actions.NETWORKUSER_LISTING_RESOLVED, payload: dataJson, isError: isError });
    },

    savePreviewFormField: (reqObj, cb) => async (dispatch) => {
        dispatch({ type: actions.SAVE_PREVIEW_FORM_FIELD_REQUESTED, payload: reqObj });

        let isError = false;
        let dataJson;

        try {
            const { data } = await dashboardService.savePreviewFormField(reqObj);
            dataJson = data;
            checkResponse(data);
            cb();
        }
        catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: 'dashboard actions savePreviewFormField FAILED',
                    reqObj,
                },
            });
        }
        dispatch({ type: actions.SAVE_PREVIEW_FORM_FIELD_RESOLVED, isError: isError, payload: dataJson });
    },

    deleteDraftListings: (id) => async (dispatch, getState) => {
        dispatch({ type: actions.DELETE_DRAFT_LISTINGS_REQUESTED, payload: id });
        const listings = getState().dashboard.listings;
        const filtredListings = getState().dashboard.filtredListings;

        try {
            await dashboardService.deleteDraftListings(id);
            dispatch({
                type: actions.DELETE_DRAFT_LISTINGS_RESOLVED,
                payload: {
                    listings: listings.filter(dl => dl.draftId !== id),
                    filtredListings: filtredListings.filter(dl => dl.draftId !== id),
                }
            });

        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: actions.DELETE_DRAFT_LISTINGS_FAILED, payload: id });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: actions.DELETE_DRAFT_LISTINGS_FAILED,
                    id,
                },
            });
        }
    },
    setListingListFilters: ({ listingListSearch, statusFilters }) => async (dispatch, getState) => {
        let listings = getState().dashboard.listings;

        if (listingListSearch?.length) {
            listings = listings.filter(l => {
                return (
                    l?.address?.address1?.includes(listingListSearch) ||
                    l?.address?.address2?.includes(listingListSearch) ||
                    l?.address?.city?.includes(listingListSearch) ||
                    l?.address?.state?.includes(listingListSearch) ||
                    l?.address?.zip?.includes(listingListSearch) ||
                    l?.address?.county?.includes(listingListSearch)
                );
            })
        }

        if (statusFilters?.length) {
            listings = listings.filter(
                ({ status }) => statusFilters.some(({ value }) => value === status)
            );
        }

        dispatch({
            type: actions.SET_LISTING_LIST_FILTERS,
            payload: {
                listingListSearch,
                statusFilters,
                filtredListings: listings,
            },
        })
    },
    setListingListViewType: (type) => ({ type: actions.SET_LISTING_LIST_VIEW_TYPE, payload: type }),
    updateTitleData: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actions.UPDATE_TITLE_DATA_REQUESTED });
            const listing = getState().dashboard.listing;
            const ownersData = listing.ownershipType.ownersData.map((owner) => {
                const ownerObj = {};
                Object.entries(owner).forEach(([key, val]) => {
                    ownerObj[key] = val.value;
                });
                return ownerObj;
            });

            const data = {
                titleCompanyId: listing.titleCompanyId,
                titleCompanyName: listing.titleCompanyName,
                titleCompanyEmail: listing.titleCompanyEmail,
                ownershipType: {
                    type: listing.ownershipType.type,
                    ownersData,
                },
            };

            await dashboardService.updateTitleData(listing.listingId, data);
            dispatch({ type: actions.UPDATE_TITLE_DATA_RESOLVED });

        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: actions.UPDATE_TITLE_DATA_FAILED });

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_dashboard_action',
                },
                extra: {
                    type: actions.UPDATE_TITLE_DATA_FAILED,
                },
            });
        }
    },
    setPropertyOwnersFields: (data) => async (dispatch) => {
        dispatch({ type: actions.SET_PROPERTY_OWNERS_FIELDS, payload: data })
    },
    setPropertyHeld: (val, propertyOwnersFields) => async (dispatch) => {
        dispatch({ type: actions.SET_PROPERTY_HELD, payload: val })
        dispatch({ type: actions.SET_PROPERTY_OWNERS_FIELDS, payload: propertyOwnersFields })
    },
    setTitleCompany: (data) => async (dispatch) => {
        dispatch({ type: actions.SET_TITLE_COMPANY, payload: data })
    },
    updateListingCashOfferStatus: (cashOfferId, listingId, draftListingId) => async (dispatch, getState) => {
        const filtredListings = getState().dashboard.filtredListings;
        const updatedListings = filtredListings.map(listing => {
            if (listingId) {
                return {
                    ...listing,
                    cashOfferId: listing.id === listingId ? cashOfferId : listing.cashOfferId,
                }
            }

            if (draftListingId) {
                return {
                    ...listing,
                    cashOfferId: listing.draftId === draftListingId ? cashOfferId : listing.cashOfferId,
                }
            }

            return listing;
        });
        dispatch({ type: actions.UPDATE_LISING_CASH_OFFER_STATUS, payload: updatedListings });

    }
};
