import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { adminTitleCompaniesSelector } from '../../../Reducers/V2/AdminTitleCompaniesReducer';
import { titleCompaniesCreators } from '../../../Actions/V2/Admin/TitleCompaniesActions';

const TitleCompanyInfoWrapper = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  padding: 0.75rem 1.25rem 1.75rem 1.25rem;
  margin-bottom: 1rem;
  width: ${({ dynamicWidth }) => dynamicWidth ? 'fit-content' : '100%'};
  max-width: 100%;
`;

const AdvertisementLabel = styled.div`
  text-align: right;
  padding-bottom: 0.75rem;
  font-size: 13px;
`;

const TitleCompanyInfo = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  display: flex;
  overflow: hidden;

  .title-logo {
    margin: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .title-company-details {
    padding: 5px;
    width: 50%;

    .title-company-details-line {
      margin: 10px;
      display: flex;
      flex-direction: column;

      .title-company-details-line-text {
        margin-top: 4px;
        font-size: 14px;
      }

      .title-company-details-line-label {
        color: #626262;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .title-logo {
      display: none;
    }
    .title-company-details {
      width: 100%;
    }
  }
`;


const TitleCompanyAdd = ({ state }) => {

  const dispatch = useDispatch();
  const titleCompanies = useSelector(adminTitleCompaniesSelector);
  const titleCompany = titleCompanies?.[0];

  useEffect(() => {
    if (!state) {
      return;
    }
    dispatch(titleCompaniesCreators.getTitleCompanies({ state, limit: 100 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  if (!titleCompany || !titleCompany?.adYouTubeUrl) {
    return null;
  }


  return (
    <TitleCompanyInfoWrapper dynamicWidth={true}>
      <AdvertisementLabel>Advertisement</AdvertisementLabel>
      <TitleCompanyInfo dangerouslySetInnerHTML={{
        __html: titleCompany.adYouTubeUrl
      }}>
      </TitleCompanyInfo>
    </TitleCompanyInfoWrapper>
  );
};

export default TitleCompanyAdd;
