import styled from 'styled-components';
import { Modal } from 'antd';
import AnnotatedInput from '../../App/Shared/Components/AnnotatedInput';

export const SmallContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 40px auto 0;
  padding: 0 15px;
  text-align: center;
`;

export const HeadTextWrapper = styled.div`
  text-align: center;
  .heading {
    font-size: 30px;
  }
  .text {
    font-size: 15px;
  }
`;

export const InnerWrapper = styled.div`
  padding: 30px 0;
  .for-input-width {
    max-width: 700px;
  }
`;

export const AccordionHeading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  margin-bottom: 10px;
  position: relative;
  color:#242B26;
  @media(max-width: 575px) {
    font-size: 18px;
  }
  span {
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    display: inline-block;
  }
`;

export const MainHeading = styled.h1`
  color: #242B26;
  font-size: 43px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  margin-bottom: 20px;
  line-height: 1;
  &.center-heading {
    text-align: center;
    font-size: 28px;
    margin: 30px 0;
  }
`;

export const HeadingText = styled.p`
  display: block;
  font-size: 16px;
  margin: 0 0 35px 0;
  font-weight: 500;
  a {
    color: #242B26 !important;
    &:hover {
      color: #207fd7 !important;
    }
  }
`;

export const ModalRowFlex = styled.div`
  display: flex;
  margin: 0 -10px;
`;

export const ModalInputFlex = styled(AnnotatedInput)`
  ${props => `
  padding: 0 10px;
  margin: 0 0 20px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-transform: capitalize;
    color:#242B26;
    margin: 0;
  }
  input {
    padding: 0 5px;
  }
`}
`;

export const CustomDropdown = styled.div`
  .dropdown-toggle {
    background: none !important;
    border: none !important;
    padding: 0;
    box-shadow: none !important;
    color:#242B26 !important;
    font-family: 'National-Regular', sans-serif;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    font-size: 14px;
    margin: 0 0 0 15px;
    display: flex;
    align-items: center;
    &:after {
      margin-left: 9px;
      vertical-align: 1px;
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
    &:focus {
      box-shadow: none;
    }
    &:active,
    &:active {
      background: none !important;
      border: none !important;
      color:#242B26 !important;
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    transform: none !important;
    right: 0 !important;
    left: auto !important;
    top: 38px !important;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(4, 66, 96, 0.06);
    background-color: #ffffff;
    border: none;
    z-index: 99999999;
    @media (max-width: 1180px) {
      left: 0 !important;
      min-width: 100px;
    }
    .dropdown-item {
      font-size: 14px;
      position: relative;
      padding: 12px 10px;
      &:active {
        background: none;
        color: #212529;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: 10px;
        right: 10px;
        bottom: 0;
        background-color: #f1ecec;
        height: 1px;
      }
    }
  }
`;

export const StartButtonLink = styled.a`
  background-color: #15a54d;
  border-color: #15a54d;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  padding: 20px;
  border-radius: 4px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 215px;
  margin: 20px auto 0;
  color: white;
  padding: 0;
  margin-top: 40px;
  &:hover {
    color: white;
  }
  strong {
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
    display: inline-block;
    margin-bottom: 8px;
  }
  span {
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const FormWrapperDiv = styled.div`
  padding: 30px;
  background: #f0f0f0;
  border-radius: 8px;
`;

export const MainTitle = styled.h1`
  text-align: center;
  font-style: italic;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  font-size: 38px;
  line-height: 46px;
  color: #242B26;
  margin: 50px auto 50px;
  max-width: 1150px;
`;

export const Description = styled.div`
  font-family: 'National-Regular', sans-serif;
  p {
    font-size: 16px;
    margin-bottom: 28px;
    line-height: 1.6;
    color: #0a0a0a;
    a {
      color: #242B26;
    }
  }
  ul {
    margin-left: 18px;
    margin-bottom: 30px;
    li {
      font-size: 16px;
      line-height: 1.6;
      color: #0a0a0a;
      margin-bottom: 12px;
    }
  }
  strong {
    font-size: 1.3rem;
    line-height: 1.2em;
    color: #242B26;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    display: block;
    margin-bottom: 28px;
  }
  h4 {
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    font-size: 16px;
    margin-bottom: 28px;
  }
  span {
    font-size: 16px;
    font-style: italic;
    display: block;
    margin-bottom: 24px;
    color: #0a0a0a;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const StaticContentWrapper = styled.div`
  padding: 45px 0 15px 0;
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 15px;
      margin-bottom: 20px;
    }
    a {
      color: #242B26;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      text-decoration: underline;
    }
  }
  strong {
    display: block;
    font-size: 18px;
    margin-bottom: 30px;
    color: #242B26;
    line-height: 28px;
    @media (max-width: 768px) {
      font-size: 17px;
      margin-bottom: 20px;
    }
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    color: #242B26;
    margin-bottom: 15px;
    line-height: 28px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  h4 {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    line-height: 28px;
    span {
      font-style: italic;
    }
  }
  .img-wrapper {
    text-align: center;
    margin: 50px auto 50px;
    width: 650px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 30px auto 30px;
    }
    img {
      width: 100%;
    }
  }
  ul {
    margin: 20px 0 25px 18px;
    li {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
  .commercial-contact {
    margin-bottom: 10px;
    h4 {
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
    a {
      color: #242B26;
      margin-left: 20px;
      text-decoration: underline;
      @media (max-width: 768px) {
        display: block;
        margin-left: 0;
      }
    }
  }
  &.how-it-works-wrapper {
    padding: 0 0 30px 0;
  }
`;

export const Quote = styled.h2`
  text-align: center;
  color: #242B26;
  font-size: 30px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  font-style: italic;
  line-height: 1.3;
  margin-bottom: 32px;
  &.for-commercial {
    margin-top: 40px;
  }
  &.for-how-it-works {
    padding-top: 45px;
  }
  @media (max-width: 992px) {
    font-size: 26px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 1.5;
  }
`;

export const MidBoxWrapper = styled.div`
  padding: 40px 0 48px 0;
`;

export const MidBox = styled.div`
  max-width: 480px;
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
  &.about-us-contact {
    padding: 10px 0 40px;
  }
  &.commercial-form {
    padding: 10px 0 40px;
  }
`;

export const HeadTextWrap = styled.div`
  text-align: center;
  margin-bottom: 45px;
  &.for-left {
    margin-bottom: 30px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: #B698D9;
      height: 4px;
      width: 80px;
      border-radius: 20px;
    }
  }
  &.email-sent-wrapper {
    margin-bottom: 0;
    h2 {
      font-size: 28px;
      margin-bottom: 24px;
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }
    p {
      font-size: 15px;
    }
  }
  &.for-plan {
    @media (max-width: 992px) {
      margin-bottom: 30px;
    }
  }
  &.billing-head {
    margin-bottom: 30px;
  }
`;

export const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  color: #242B26;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 26px;
    margin-bottom: 10px;
  }
`;

export const HeadText = styled.p`
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 0;
  a {
    color: #242B26 !important;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-decoration: underline !important;
  }
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
  .inputgap {
    padding: 0 10px;
    &.with-single {
      padding-right: 30px;
    }
  }
  @media (max-width: 768px) {
    display: block;
  }
`;
export const RowFlexTwoColumn = styled(RowFlex)`
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const InputFlex = styled(AnnotatedInput)`
  ${props => `
  flex: ${props.flex ? props.flex : '.5'};
  input {
    padding: 0 10px;
    height: 45px;
    border-radius: 6px;
    font-size: 15px;
  }
`}
`;

export const CustomTextarea = styled.div`
  margin-bottom: 25px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-transform: capitalize;
    margin: 0 0 10px 0;
  }
  textarea {
    resize: none;
    box-shadow: 0 2px 4px 0 rgba(4, 66, 96, 0.06);
    border: 1px solid #dadada;
    border-radius: 6px;
    padding: 12px 10px;
    background: #fff;
    width: 100%;
    height: 100px;
    font-size: 15px;
    -webkit-transition: border 0.25s;
    transition: border 0.25s;
    &:focus {
      border: 1px solid #B698D9;
    }
    &.item-text-red {
      border: 1px solid red;
      & + .textarea-error {
        display: block;
      }
    }
  }
  .textarea-error {
    font-size: 14px;
    color: red;
    margin: 8px 0 0 0;
    font-weight: 400;
    display: none;
  }
`;

export const CustomButton = styled.button`
  height: 50px;
  background-color: ${({ theme }) => theme.dark};
  min-width: 150px;
  letter-spacing: 0.5;
  border-radius: 6px;
  color: white;
  font-size: 15px;
  letter-spacing: 0.5px;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  &:disabled {
    opacity: 0.5;
  }
  &.block {
    min-width: 100%;
  }
  &:hover {
    color: white;
  }
  &.small {
    height: 42px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    letter-spacing: 0.3px;
    min-width: 100px;
    border-radius: 4px;
    &.for-header {
      margin-left: 15px;
    }
  }
  &.faded {
    background-color: white !important;
    border: 1px solid #B698D9 !important;
    color: #242B26 !important;
  }
  &.for-header {
    @media (max-width: 1180px) {
      margin-left: 0 !important;
    }
  }
`;

export const LavenderButton = styled.button`
  background-color: ${({ theme }) => theme.lavender};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  flex-direction: column;
  height: 50px;
  border-radius: 4px;
  span {
    &:first-child {
      font-size: 14px;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      margin-bottom: 5px;
      letter-spacing: 0.3px;
    }
    &:last-child {
      font-size: 12px;
      letter-spacing: 0.5px;
    }
  }
  &:hover {
    color: white;
  }
  &.big {
    height: 70px;
    min-width: 170px;
    span {
      &:first-child {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
  }
  &.for-header {
    @media(max-width: 1180px) {
      margin: 10px 0 25px !important;
    }
  }
}`;

export const BasicText = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 0 0 30px;
  &.for-photo-upload {
    font-size: 15px;
    margin: 0 0 20px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ListingHeaderWrapper = styled.section`
  margin: 75px 40px 90px;

  .address {
    margin-right: 40px;
  }
  .primary-text {
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    font-size: 1.2rem;
    line-height: 1.3;
    margin-bottom: 1px;
    display: block;
  }
  .inline-block {
    vertical-align: top;
    display: inline-block;
  }

  .info-block {
    line-height: 1.2;
    padding: 0 13px;
    text-align: center;
    display: inline-block;
    border-right: 1px solid #ccc;
  }
`;

export const SocialTextRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    display: block;
    text-align: center;
    margin-bottom: 25px;
  }
`;

export const SocialList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0 -3px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 25px;
  }
`;

export const SocialListItem = styled.li`
  margin: 0 3px;
`;

export const SocialListLink = styled.a`
  i {
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    color: white;
    background-color: blue;
    &.fa-facebook-f {
      background-color: #3b5998;
    }
    &.fa-google {
      background-color: #dd4b39;
    }
    &.fa-linkedin-in {
      background-color: #007bb6;
    }
  }
`;

export const Tick = styled.button`
  background-color: #15a54d;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  color: white;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 35px;
  @media (max-width: 768px) {
    height: 65px;
    width: 65px;
  }
`;

export const CustomAccordion = styled.div`
  padding: 40px 0 50px 0;
  .card {
    border: none;
    border-radius: 0;
  }
  .card-header {
    min-height: 58px;
    cursor: pointer;
    padding: 0;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    margin-bottom: 0 !important;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    color: #242B26;
    border: none;
    font-size: 16px;
    border-bottom: 1px solid #d3d3d6;
    @media(max-width: 575px) {
      font-size: 15px;
      padding: 10px 0;
    }
    i {
      font-size: 18px;
      margin: 0 14px;
    }
    &.active {
      i {
        transform: rotate(180deg);
      }
    }
  }
  .card-body {
    border-bottom: 1px solid #d3d3d6;
    padding: 20px 0;
    font-size: 15px;
    line-height: 28px;
    h3 {
      font-size: 18px;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      margin-top: 10px;
    }
  }
`;

export const VideoDiv = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  text-align:center;
  .videoWrapper {
    max-width: 100%;
  }
  .video-banner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height:100%;
  }
  .video-text-with-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 1230px;
    padding: 0 15px;
    justify-content: center;
    flex-direction: column;
    .video-text {
      text-align: center;
      color: white;
      text-shadow: 2px 2px 5px #969696;
      font-size: 38px;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      line-height: 1.3;
      margin-bottom: 32px;
      @media (max-width: 768px) {
        font-size: 24px;
      }
      &.small-font {
        font-size: 32px;
      }
    }
    .play-btn {
      color: #242B26;
      font-size: 80px;
      @media (max-width: 768px) {
        font-size: 50px;
      }
    }
  }
`;

export const ContactWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  span {
    display: block;
    color:#242B26;
    font-size: 34px;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
    }
  }
  a {
    font-size: 34px;
    letter-spacing: 1px;
    color: #b01f24;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 26px;
    }
    &:hover {
      color: #b01f24;
    }
    i {
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
  }
`;

export const ContactNumberLink = styled.a`
  color: ${({ theme }) => theme.dark} !important;
  padding-top: 10px;
  i {
    font-size: 30px;
  }
`;

export const SocialText = styled.p`
  font-size: 14.8px;
  line-height: 1.6;
  margin-bottom: 0;
  color: #6b6f7e;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const OnboardingWrapper = styled.div`
  padding: 0;
  margin: 0 auto;
  width: 100%;
`;

export const StepWrapper = styled.section`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #f9f9f9;
  margin: 0 0 20px;
  &.for-photo {
    padding: 15px;
    margin: 0 0 30px;
  }
  &.for-bottom-gap {
    margin: 0 0 50px;
  }
`;

export const CustomEditor = styled.section`
  label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-transform: capitalize;
    margin: 0 0 10px 0;
  }
  .tox-tinymce {
    border: 1px solid #dadada !important;
    box-shadow: 0 2px 4px 0 rgba(4, 66, 96, 0.06);
  }
  .tox {
    border-radius: 6px;
  }
  .tox-statusbar {
    display: none !important;
  }
`;

export const FormHeading = styled.h2`
  font-size: 14px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  color: #242B26;
  text-align: right;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 10px;
  }
  &.with-tooltip {
    display: flex;
    justify-content: flex-end;
    .info-tooltip-box {
      margin-left: 8px;
    }
  }
`;

export const ViewListingWrapper = styled.div`
  padding: 50px 0;
  .view-listing-header {
    margin-bottom: 50px;
    h2 {
      font-size: 20px;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      color: #242B26;
      margin-bottom: 25px;
    }
    P {
      cursor: pointer;
      font-size: 16px;
      color: #242B26;
      text-decoration: underline;
    }
  }
  .listing-address-wrapper {
    font-size: 24px;
  }
  .listing-price {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .listing-detail-wrapper {
    font-size: 18px;
    color: #242B26;
    .primary-text {
      font-weight: 700;
      font-family: 'National-Semi-Bold';
    }
  }
`;

export const StepHeader = styled.header`
  width: 100%;
  img {
    width: 100%;
    border-bottom: 1px solid #dadada;
  }
`;

export const PublicListingWrapper = styled.div`
  padding: 60px 0;
  &.for-public-listing {
    padding: 0;
    @media (max-width: 768px) {
      padding: 40px 0 0 0;
    }
  }
  &.for-top-gap {
    padding: 0 0 60px 0;
  }
  .view-listing-headings {
    h2 {
      font-size: 20px;
      color:#242B26;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      margin-bottom: 20px;
    }
    p {
      cursor: pointer;
      text-decoration: underline;
      transition: 0.5s;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      font-size: 16px;
      color: #242B26;
      margin-bottom: 20px;
      &:hover {
        color: #b01f24;
        transition: 0.5s;
      }
    }
  }
  .public-list-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    @media (max-width: 992px) {
      display: block;
    }
  }
  .public-list-col-first {
    width: 58%;
    padding: 0 15px;
    @media (max-width: 992px) {
      width: 100%;
    }
    .listing-address {
      font-size: 28px;
      line-height: 36px;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 34px;
      }
    }
    .listing-price {
      font-size: 28px;
      margin: 18px 0;
      color: #b01f24;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    .listing-detail {
      font-size: 18px;
      color: #242B26;
      line-height: 1.4;
      .primary-text {
        font-weight: 700;
        font-family: 'National-Semi-Bold';
      }
    }
    .listing-detail-2 {
      margin-top: 25px;
      &.text-black {
        h3 {
          color:#242B26;
        }
      }
      h3 {
        font-weight: 700;
        font-family: 'National-Semi-Bold';
        color: #242B26;
        font-size: 18px;
        margin-bottom: 20px;
      }
      p {
        font-size: 16px;
        line-height: 1.6;
      }
      ol {
        margin-left: 17px;
        li {
          font-size: 16px;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .public-list-col-second {
    width: 42%;
    padding: 0 15px;
    @media (max-width: 992px) {
      width: 100%;
    }
    .call-text {
      text-align: center;
      margin: 35px 0 25px;
      color: #242B26;
    }
    .sub-text {
      line-height: 1.5;
      text-align: center;
      margin-bottom: 30px;
    }
    .social-center {
      justify-content: center;
      margin-bottom: 0;
    }
  }
`;

//MainWrapper
export const MainWrapper = styled.div`
  padding: 60px 0;
  &.for-top-gap {
    padding: 0px 0 60px 0;
  }
`;

export const ModalMainWrapper = styled.div`
  display: block;
  text-align: center;
`;

//Steps
export const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto 60px;
  @media (max-width: 1160px) {
    width: 40%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  &.two-step{
    width: 32%;
  }
  &.for-bottom-gap {
    margin: 0 auto 30px;
  }
`;

export const StepBox = styled.div`
  text-align: center;
  &:last-child {
    span {
      &:after {
        display: none;
      }
    }
  }
  span {
    height: 48px;
    width: 48px;
    border: 2px solid #B698D9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #242B26;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    margin: 0 auto 15px;
    background-color: white;
    position: relative;
    font-size: 17px;
    @media (max-width: 768px) {
      height: 40px;
      width: 40px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 44px;
      top: 21px;
      background-color: #B698D9;
      height: 2px;
      width: 200px;
      @media (max-width: 1160px) {
        display: none;
      }
    }
  }
  strong {
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    font-size: 15px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  &.active {
    span {
      background-color: #B698D9;
      color: white;
    }
    strong {
      color: #242B26;
    }
  }
`;

//For Map Wrapper
export const MapWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #f9f9f9;
  margin: 0 0 60px;
  &.for-bottom-gap {
    margin: 0;
  }
`;

export const MapBox = styled.div`
  width: 100%;
  @media (max-width: 992px) {
    height: 150px;
  }
  img {
    width: 100%;
    border-bottom: 1px solid #dadada;
    @media (max-width: 992px) {
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const MapContent = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  @media (max-width: 768px) {
    display: block;
    padding: 20px;
  }
  &.last-gap {
    padding: 0 25px 25px 25px;
    @media (max-width: 768px) {
      padding: 0 20px 0 20px;
    }
  }
`;

export const ButtonLink = styled.a`
  color: #242B26;
  border: 1px solid #B698D9;
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 10px;
  transition: 0.5s;
  &:hover {
    background-color: #B698D9;
    color: white;
    transition: 0.5s;
  }
`;

export const DetailsWrapper = styled.div`
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #f9f9f9;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const ModalDiv = styled(Modal)`
  &.ant-modal {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 15px 0;
    top: 0;
    left: 0;
    right: 0;
    justify-content: center;
  }
  .ant-modal-content {
    width: ${props => props.width ? props.width : '450px'}
  }
  .ant-modal-header {
    background-color: #f9f9f9;
    padding: 16px 25px;
    height: 55px;
    display: flex;
    align-items: center;
    .ant-modal-title {
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      line-height: 0;
    }
  }
  .ant-modal-body {
    padding: 30px 25px;
    .static-text {
      color:#242B26;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.7;
      &.payment-error-text{
        text-align: center;
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        strong{
          font-weight: 700;
          font-family: 'National-Semi-Bold';
          font-size:16px;
        }
      }
      span {
        display: block;
        margin-bottom: 10px;
      }
    }
    .modal-icon {
      background-color: white;
      border: 2px solid #B698D9;
      color: #242B26;
      height: 65px;
      width: 65px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin: 0 auto 20px;
    }
  }
  .ant-modal-footer {
    height: 60px;
    padding: 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-btn {
      border: 1px solid #ded1d1;
      height: 36px;
      min-width: 75px;
      padding: 0 10px;
      color:#242B26;
      &.ant-btn-primary {
        background-color: #B698D9;
        border-color: #B698D9;
        color: white;
      }
      }
    }
  }
  &.confirm-modal {
    .ant-modal-body {
      text-align: center;
      padding: 25px;
    }
  }
  &.text-modal {
    .ant-modal-body {
      padding: 25px;
      h3 {
        font-weight: 700;
        font-family: 'National-Semi-Bold';
        font-size: 16px;
        margin-bottom: 16px;
      }
      ol {
        margin-left: 15px;
        margin-bottom: 0;
        li {
          color:#242B26;
          margin-bottom: 0;
          font-size: 15px;
          line-height: 1.7;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .ant-modal-close {
    outline: none !important;
    color:#242B26 !important;
    .ant-modal-close-x {
      line-height: 50px;
      font-size: 15px;
    }
  }
`;

export const MiniHeading = styled.div`
  font-size: 25px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  color:#242B26;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
`;

export const PRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  &.payment-top-row {
    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
`;

export const BillingInfoWrap = styled.div`
  margin: 0 10px;
  width: 100%;
  text-align: center;
`;

export const HomeSellingWrapper = styled.div`
  padding: 50px 0;
  @media (max-width: 768px) {
    padding: 40px 0 20px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin: 0 29px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 1200px) {
    margin: 0;
    padding: 0 24px;
  }
`;

export const NavBarButton = styled.button`
  height: 2.5rem;
  top: 6px;
  position: relative;
  background-color: ${({ theme, light }) => light ? theme.colors.green[200] : theme.dark};
  border-radius: 48px;
  color: ${({ theme }) => theme.light};
  font-size: 1.5rem;
  font-family: 'National-Condensed-Medium';
  line-height: 0.9rem;
  outline: none !important;
  display: inline-flex;
  justify-content: center;
  padding: 13px 16px 9px 16px;
  @media(max-width: 992px) {
    display: none;
  }
  &:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: ${({ theme, light }) => light ? theme.colors.green[300] : theme.dark};
}
`;

export const NavLink = styled.a`
    font-size: 14px;
    color: #242B26;
    margin: 0;
    display: block;
    transition: color 250ms;
    font-style: normal;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    font-size: 16px;
    line-height: 24px;
    &:hover {
      text-decoration: underline;
      color: #242B26;
    }
    &:not(:first-child) {
      padding-left: 40px;
    }
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media(max-width: 992px) {
      display: none;
    }
`;

export const DivDetailWrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #f9f9f9;
`;

export const DivWrapper = styled.div``;

export const FormSection = styled.div`
  font-size: 14px;
  margin: 0 0 20px;
  display: block;
  line-height: 26px;
  p {
    display: block;
    font-size: 14px;
    margin: 0 0 20px;
    line-height: 26px;
  }
  input[type='text'],
  input[type='tel'],
  input[type='number'],
  input[type='email'],
  select,
  textarea {
    display: inline-block;
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.primary};
    padding: 5px;
    margin: 0 2px;
  }
`;

export const Form = styled.article`
  flex: 2;
  display: block;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  height: 100%;
  border-radius: 6px;
  h1,
  h2 {
    color: ${({ theme }) => theme.primary};
  }
  .mls-static-text {
    h1 {
      font-size: 20px;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      color: #242B26;
      text-align: center;
      margin-bottom: 20px;
    }
    h3 {
      span {
        font-size: 18px;
        color:#242B26 !important;
      }
    }
    ol {
      margin-left: 17px;
      li {
        padding-bottom: 10px;
        line-height: 1.5;
        font-size: 14px;
      }
    }
  }
`;
export const FormDatePicker = styled.div`
  display: inline;
  padding-left: 3px;
  input {
    border-radius: 0;
    border: 1px solid #B698D9;
    font-size: 15px;
    &:focus {
      box-shadow: none;
    }
  }
`;
export const Group = styled.section``;
export const Section = styled.div`
  margin: 0 0 20px;
  display: block;
  line-height: 26px;
  font-size: 14px;
  p {
    display: block;
    margin: 0 0 20px;
    line-height: 26px;
    font-size: 14px;
  }
  strong {
  }
  input[type='text'],
  input[type='number'],
  input[type='tel'],
  input[type='email'],
  select,
  textarea {
    border: 1px solid ${({ theme }) => theme.primary};
    display: inline-block;
    border-radius: 2px;
    margin: 0 2px;
    padding: 5px;
  }
  select {
    height: 38px;
    min-width: 140px;
  }
`;

export const EbookWrapper = styled.div`
  padding: 40px 0;
  .ebook-main-wrapper {
    display: flex;
    margin: 0 -15px;
  }
  .ebook-left {
    width: 75%;
    padding: 0 15px;
    @media(max-width: 992px) {
      width: 100%;
    }
  }
  .ebook-right {
    width: 25%
    padding: 0 15px;
    @media(max-width: 992px) {
      display: none !important;
    }
  }
  h2 {
    font-size: 28px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    color: #242B26;
    margin-bottom: 15px;
    @media(max-width: 992px) {
      font-size: 26px;
    }
  }
  p {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-bottom: 25px;
  }
  .admin-custom-checkbox {
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .admin-custom-checkbox span {
    display: inline-block;
    line-height: 1.4;
  }
  .ebook-right {
    display: flex;
    justify-content: flex-end;
  }
  .ebook-image {
    width: 224px;
    height: 360px;
  }
  .ebook-image img {
    width: 100%;
    height: 100%;
  }
`;

export const ModalSubmitButton = styled.button`
  padding: 7px 20px;
  background-color: #B698D9;
  border: 1px solid #B698D9;
  font-size: 1.2em;
  color: #fff;
  margin: 4px;
  border-radius: 5px;
  outline: none !important;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
`;

export const ModalSubmitButtonWrapper = styled.div`
  text-align: right;
`;

export const ModalLogoImage = styled.img`
  display: inline-block;
  margin-bottom: 15px;
`;

export const ModalAddressHeading = styled.h2`
  font-family: 'National-Regular',sans-serif;
  font-weight: 300;
  color:#242B26;
  font-size: 20px;
  margin-bottom: 35px;
  line-height: 42px;
  letter-spacing: 1px;
  position: relative;
  margin-top: 1rem;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    height: 1px;
    width: 80px;
    background-color: #666;
    margin: 0 auto;
  }
`;
export const ModalLabelHeading = styled.h4`
  font-size: 18px;
  line-height: 32px;
  color: #242B26;
  font-family: 'National-Regular',sans-serif;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
`;


/* Phase 2 CSS Start*/
export const SignUpHomeListing = styled.div`
  width: 100%;
`;
export const SignUpHomeListingWrap = styled.div`
  display:flex;
  padding-top:70px;
  padding-bottom:50px;
  margin-left:0;
  margin-right:0;
  justify-content: center;
  @media (max-width: 1024px) {
    padding-top:40px;
    padding-bottom:40px;
    margin-left:-15px;
    margin-right:-15px;
    justify-content: center;
  }
  @media (max-width: 767px) {
    padding-top:30px;
    flex-direction: column;
  }
`;
export const SignUpHomeListingForm = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 14px 24px 10px;
  @media (max-width: 767px) {
    padding: 14px 15px 10px;
  }
`;
export const SignUpHomeListingFormWrapper = styled.div`
  max-width: 470px;
  width: 100%;
  padding-left:35px;
  padding-right:35px;
  @media (max-width: 1024px) {
    max-width: 430px;
    padding-left:15px;
    padding-right:15px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;
export const SignUpHomeListingContent = styled.div`
  padding-left:35px;
  padding-right:35px;
  padding-top: 32px;
  @media (max-width: 1024px) {
    padding-left:15px;
    padding-right:15px;
  }
`;
export const SignUpHomeListingHeading = styled.h1`
  margin-top:14px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  font-size: 28px;
  line-height: 36px;
  color: #2A3758;
  max-width: 440px;
  margin:0;
`;
export const SignUpHomeListingSmallHeading = styled.h4`
  margin-top:20px;
  margin-bottom:14px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  font-size: 21px;
  line-height: 28px;
  color: #2A3758;
  @media (max-width: 767px) {
    margin-top:14px;
    font-size: 23px;
  }
`;
export const SignUpHomeListingUl = styled.ul`
  margin:0;
  max-width: 420px
  padding-left: 24px;
`;
export const SignUpHomeListingLi = styled.li`
  font-family: 'National-Regular',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #2A3758;
  margin-top:16px
`;
export const HomeListingInput = styled(InputFlex)`
  ${props => `
  margin: 0 0 10px;
  label {
    font-family: 'National-Regular',sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  margin: 0 0 4px;
  color: #444E69;
  + div{
      border-radius:4px;
      height: 42px;
      box-shadow:none;
      border-color: #C2C2C2;
    }
  }
  input {
    border-radius: 4px;
    height: 40px;
  }
`}
`;
export const PasswordHint = styled.span`
  display:block;
  color: #444E69;
  font-family: 'National-Regular',sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  margin-top: 10px;
`;
export const PremiumServiceWrap = styled.div`
  background-color: #2A3758;
  border-radius: 4px;
  padding: 14px 12px 12px 12px;
  font-family: 'National-Regular',sans-serif;
  font-size: 13px;
  line-height: 18px;
  margin-top:26px;
  color: #E4E3DC;
  .see-exactly{
    font-family: 'National-Regular',sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: #E4E3DC;
    margin-top:6px;
    margin-left: 30px;
    margin-bottom:0;
    cursor:pointer;
    .text{
      text-decoration:underline;
      margin-right:12px;
    }
  }
`;


export const Divider = styled.div`
  margin: 15px -24px 21px;
  height:1px;
  background-color: #E7E7E7;
`;
export const AddedPremumAgent = styled.p`
  font-family: 'National-Regular',sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #2A3758;
  strong{
    font-weight: 700;
    font-family: 'National-Semi-Bold';
  }
`;

export const PayNowBtn = styled(CustomButton)`
  font-family: 'National-Regular',sans-serif;
  text-transform: none;
  margin: 30px 0 17px;
  background-color: #005FEE;
  border-radius: 4px;
`;
export const ApplePayBtn = styled(PayNowBtn)`
  margin: 0 0 12px;
  min-width: 100%;
  background-color:#242B26000;
  @media (max-width: 767px) {
    margin: 24px 0 16px;
  }
  .apple-pay-icon{
    img{
      width: 70px;
      height: 33px;
      @media (max-width: 767px) {
        width: 65px;
        height: 30px;
      }
    }
  }
`;
export const TermsConditions = styled.p`
  font-family: 'National-Regular',sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #444E69;
  margin-bottom:26px;
  .link{
    text-decoration:underline;
    color: #444E69;
  }
`;

export const Endorsed = styled.p`
  font-family: 'National-Regular',sans-serif;
  font-size: 11px;
  line-height: 28px;
  text-align: center;
  color: #444E69;
`;
export const EndorsedByImagesWrap = styled.p`
  margin-bottom:0;
  img{
    margin-bottom:2px;
    max-width:100%;
    width:100%;
  }
`;
export const LogoWrap = styled.a`
  margin-bottom:14px;
  display:inline-block;
  img{
    max-width:100%;
    width: 111px;
    height: 26px;
  }
`;
export const DesktopView = styled.div`
  @media (max-width: 767px) {
      display:none;
  }
`;
export const MobileView = styled.div`
  margin-top: 16px;
  @media (min-width: 768px) {
      display:none;
  }
  h1{
    line-height: 32px;
    margin-top:25px;
    text-align:center;
    font-size:24px;
  }
`;


/* Phase 2 CSS End*/