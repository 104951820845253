import React, { useState, useEffect } from 'react';
import AddEditTitleCompany from './AddEditTitleCompany';
import TitleCompany from './TitleCompany';
import { useParams } from 'react-router';

const TitleCompanyContainer = () => {
    const { id } = useParams();

    const [isAddEdit, setIsAddEdit] = useState(!!id);

    return isAddEdit ? <AddEditTitleCompany setIsAddEdit={setIsAddEdit} id={id} /> : <TitleCompany setIsAddEdit={setIsAddEdit} />
}

export default TitleCompanyContainer;