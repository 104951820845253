import React, { useState, useEffect } from "react";
import routes from "../../../Constants/Routes";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import ExactlyIcon from "../../../Assets/Images/exactly-icon.svg";
import "../../../Assets/Style/home-listing.css";
import FeatureSlider from '../../Shared/Containers/FeatureSlider';
import v8n from 'v8n';
import { regexPattern, validationMessages, productTypeList, phoneMask } from '../../../Constants/CommonConstants';
import { injectStripe, CardElement, PaymentRequestButtonElement } from 'react-stripe-elements';
import {
    SignUpHomeListingForm, PayNowBtn, HomeListingInput, PremiumServiceWrap, Divider, AddedPremumAgent, TermsConditions, Endorsed, RowFlexTwoColumn
} from "../../../Assets/Style/commonStyleComponents";
import Enums from "../../../Constants/Enums";
import Routes from "../../../Constants/Routes";
import KlaviyoEvents from "../../../Constants/KlaviyoEvents";
import { userActionCreators } from '../../../Actions/UserActions';
import { onboardingActionCreators } from "../../../Actions/OnboardingActions";
import { convertPhone, getLocalUserData } from '../../../Utilities/commonFunctions';
import styled from 'styled-components';
import { trackingService } from '../../../Services/TrackingService'
import { CheckMarkIcon } from "../../Shared/Components/Icons/CheckMarkIcon";

const CardElementStyled = styled(CardElement)`
  margin:0 0 10px;
  border: 1px solid ${props => props.error ? "#FF7178" : "#dadada"};
  border-radius:6px;
  padding:14px;
  transition:border .25s;
  background:#fff;
  height: 46px;
  &:focus-within {
      border:1px solid ${props => props.error ? "#FF7178" : props.theme.primary};
  }
`;
const ErrorDiv = styled.div`
  font-size: 14px;
  color: red;
  margin: 0;
  font-weight: 400;
  display: block;
`;

const CardInput = styled.div`
  display:block;
  margin-bottom: 25px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-transform: capitalize;
    margin-bottom: 10px;
  }
`;

const LinkConfimationContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0.75rem 1rem;
    align-items: center;
`;

const LinkConfimationText = styled.span`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 1.5rem
    text-align: left;
    margin-left: 1rem;
`;

const ITEMS_TO_SHOW_IN_CAROUSEL = 2;
const ITEMS_TO_SCROLL_IN_CAROUSEL = 1;

const formDefaultValue = {
    firstName: {
      rules: [{
        rule: v8n().not.empty().not.null(),
        message: validationMessages.firstName,
      }],
      value: ""
    },
    lastName: {
      rules: [{
        rule: v8n().not.empty().not.null(),
        message: validationMessages.lastName,
      }],
      value: ""
    },
    email: {
      rules: [{
        rule: v8n().not.empty().not.null(),
        message: validationMessages.email,
      },
      {
        rule: v8n().pattern(regexPattern.EMAIL),
        message: validationMessages.validEmail,
      }],
      value: ""
    },
    password: {
      rules: [{
        rule: v8n().not.empty().not.null(),
        message: validationMessages.password,
      },
      {
        rule: v8n().pattern(regexPattern.PASSWORD),
        message: validationMessages.validPassword
      }],
      value: ""
    },
    zipcode: {
      rules: [{
        rule: v8n().not.empty().not.null(),
        message: validationMessages.zipCode,
      },
      {
        rule: v8n().pattern(regexPattern.NUMBER_ONLY),
        message: "Please enter a valid zip code"
      }],
      value: ""
    },
    phoneNumber: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.phoneNumber,
        },
        {
          rule: v8n().pattern(regexPattern.PHONE),
          message: "Please enter a valid phone number"
        }],
        mask: phoneMask,
        value: ""
    },
};

const UnbounceFromView = ({
    stripe,
    productsInfo,
    getProducts,
    createUser,
    createPaymentIntent,
    openModal
}) => {
    const [fields, setFields] = useState(formDefaultValue);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [isCardUntouched, setIsCardUntouched] = useState(true);
    const [isFreeSignup, setIsFreeSignup] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(true);
    const [cardError, setCardError] = useState(null);
    const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
    const [product, setProduct] = useState(null);
    const [showEmailConfirmationText, setShowEmailConfirmationText] = useState(false);

    useEffect(() => {
        getProducts(productTypeList.concierge.id)
        trackingService.track({
            event: KlaviyoEvents.UnbounceSignUpStart
        });
    }, [])

    useEffect(() => {
        if (!productsInfo || !productsInfo.length) {
            return;
        }
        const selectedProduct = productsInfo?.find(item => item.priceUpFront > 0) || {priceUpFront: 0};
        setProduct(selectedProduct)

        const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Plan payment',
                amount: selectedProduct?.priceUpFront * 100,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        paymentRequest.canMakePayment().then(result => {
            if (result && result.applePay) {
                setIsApplePayAvailable(true);
                setPaymentRequest(paymentRequest);
            }
        });
    }, [productsInfo])

    useEffect(() => {
        if (!paymentRequest) {
            return
        }
        paymentRequest.on('paymentmethod', async (event) => {
            const userPaymentIntent = await createPaymentIntent(product.productId);
            const {error} = await stripe.confirmCardPayment(
                userPaymentIntent.clientSecret,
                { payment_method: event.paymentMethod.id},
                { handleActions: false }
            );

            if (error) {
                event.complete('fail');
                return;
            }

            let createDetails = {
                ...(Object.keys(fields).reduce((retVal, key) => {
                retVal[key] = fields[key].value;
                return retVal;
                }, {}))
            };

            createDetails["userTypeId"] = Enums.NUMERIC_VALUE.FIFTEEN.toString();
            createDetails["productId"] = product?.productId;
            createDetails.emailConfirmationRedirectLink = window.location.origin + Routes.UNBOUNCE_LISTING + '?new_user=true';
            createDetails.phoneNumber = convertPhone(createDetails.phoneNumber);
            
            createUser(createDetails, () => {

                trackingService.track({
                    event: KlaviyoEvents.UnbounceSignUpAccountCreated,
                    userInfo: {
                        email: createDetails['email']
                    },
                    properties: {
                        plan: product?.title
                    }
                });

                event.complete('success');
                setShowEmailConfirmationText(true);
            }, () => {
                event.complete('fail');
            });
        });
    }, [paymentRequest])

    const handleModalOpen = () => {
        openModal(true);
    }

    const handleUnbounceCheckbox = (e) => {
        setIsFreeSignup(!e.target.checked);
    }

    const handleFieldChanged = (e) => {
        let accountFields = fields;
        accountFields[e.target.name].value = e.target.value;
        setFields({ ...accountFields });
    }

    const handleFieldBlur = (e) => {
        validateAccountField(e.target.name, e.target.value);
    }

    const validateAccountField = (key, value) => {
        let isError = false;
        let accountFields = fields;
        for (let i = 0; i < accountFields[key].rules.length; i++) {
            let rule = accountFields[key].rules[i];
            if (!rule.rule.test(value)) {
                accountFields[key].error = rule.message;
                isError = true;
                break;
            }
        }

        if (!isError) {
            accountFields[key].error = null;
        }

        setFields({ ...accountFields });

        return isError;
    }

    const handleSaveFreeUnbounce = (e) => {
        e.preventDefault();
        let isError = false;

        Object.keys(fields).forEach((k) => {
            const isErrorExist = validateAccountField(k, fields[k].value);
            if (isErrorExist) {
                isError = true;
            }
        })

        if (isError) {
            return;
        }

        let createDetails = {
            ...(Object.keys(fields).reduce((retVal, key) => {
            retVal[key] = fields[key].value;
            return retVal;
            }, {}))
        };

        createDetails["userTypeId"] = Enums.NUMERIC_VALUE.FOURTEEN.toString();
        createDetails.emailConfirmationRedirectLink = window.location.origin + Routes.UNBOUNCE_LISTING + '?new_user=true';
        createUser(createDetails, () => {
            trackingService.track({
                event: KlaviyoEvents.UnbounceSignUpAccountCreated,
                userInfo: {
                    email: createDetails['email']
                },
                properties: {
                    plan: product?.title
                }
            });

            setShowEmailConfirmationText(true);
        });
    }

    const handleSaveWithPayUnbounce = (e, productId) => {
        e.preventDefault();
        let isError = false;

        Object.keys(fields).forEach((k) => {
            const isErrorExist = validateAccountField(k, fields[k].value);
            if (isErrorExist) {
                isError = true;
            }
        })

        if (!isError && !cardError && !isCardUntouched) {
            let createDetails = {
                ...(Object.keys(fields).reduce((retVal, key) => {
                retVal[key] = fields[key].value;
                return retVal;
                }, {}))
            };

            createDetails["userTypeId"] = Enums.NUMERIC_VALUE.FIFTEEN.toString();
            createDetails["productId"] = productId;
            
            stripe.createToken({
                name: `${createDetails.firstName} ${createDetails.lastName}`,
                address_line1: '',
                address_line2: '',
                address_city: '',
                address_state: '',
                address_zip: createDetails.zip,
                address_country: "US"
            }).then((result) => {
                if (!result || !result.token) {
                    return;
                }

                createDetails["cardToken"] = result.token.id || '';
                createDetails.emailConfirmationRedirectLink = window.location.origin + Routes.UNBOUNCE_LISTING + '?new_user=true';
                createUser(createDetails, () => {
                    trackingService.track({
                        event: KlaviyoEvents.UnbounceSignUpAccountCreated,
                        userInfo: {
                            email: createDetails['email']
                        },
                        properties: {
                            plan: product?.title
                        }
                    });

                    setShowEmailConfirmationText(true);
                });
            });
        } else {
            if (isCardUntouched) {
                setCardError({ message: "Please enter your credit card information" });
            }
        }
    }

    const handleApplePayClick = (e) => {
        let isError = false;
        Object.keys(fields).forEach((k) => {
            const isErrorExist = validateAccountField(k, fields[k].value);
            if (isErrorExist) {
                isError = true;
            }
        })

        if (isError) {
            e.preventDefault();
        }
    }

    const handleCardDetailsChanged = (e) => {
        const error = (e.error || e.empty) ? e.error || "Please enter your credit card information" : null;
        setCardError(error)
        setIsCardUntouched(false)
    }

    return (
        <SignUpHomeListingForm>
            <form autoComplete="off">
            <HomeListingInput
                name="email"
                title="Your email"
                className="inputgap"
                value={fields.email.value}
                error={fields.email.error}
                onChange={handleFieldChanged}
                onBlur={handleFieldBlur}
                type="email">
            </HomeListingInput>
            <RowFlexTwoColumn>
                <HomeListingInput
                    name="firstName"
                    title="First name"
                    className="inputgap"
                    value={fields.firstName.value}
                    error={fields.firstName.error}
                    onChange={handleFieldChanged}
                    onBlur={handleFieldBlur}
                    type="text">
                </HomeListingInput>
                <HomeListingInput
                    name="lastName"
                    title="Last name"
                    className="inputgap"
                    value={fields.lastName.value}
                    error={fields.lastName.error}
                    onChange={handleFieldChanged}
                    onBlur={handleFieldBlur}/>
            </RowFlexTwoColumn>
            <HomeListingInput
                name="zipcode"
                title="Your property’s Zip Code"
                className="inputgap"
                value={fields.zipcode.value}
                error={fields.zipcode.error}
                onChange={handleFieldChanged}
                onBlur={handleFieldBlur}>
            </HomeListingInput>
            <HomeListingInput
                name="phoneNumber"
                title="Phone Number"
                className="inputgap with-single"
                type="tel"
                value={fields.phoneNumber.value}
                error={fields.phoneNumber.error}
                mask={fields.phoneNumber.mask} onChange={handleFieldChanged}
                onBlur={handleFieldBlur}>
                <span className="phone-example">Example: <samp>(555) 123-1234</samp></span>
            </HomeListingInput>
            <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-100'>
                <div className='admin-input-with-icon with-bottom-text'>
                    <HomeListingInput
                        name="password"
                        title="Password"
                        type={passwordVisible ? 'password' : 'text'}
                        value={fields.password.value}
                        error={fields.password.error}
                        onChange={handleFieldChanged}
                        onBlur={handleFieldBlur}
                        ></HomeListingInput>
                    <i className={passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                        aria-hidden='true'
                        onClick={() =>
                            setPasswordVisible(!passwordVisible)
                        }>
                    </i>
                </div>
                </div>
            </div>
            <PremiumServiceWrap>
                <div className="admin-checkbox-wrapper custom-for-homelisting">
                <div className="admin-custom-checkbox">
                    <label>
                    <input className="inputgap" name="checkpay" type="checkbox" onChange={handleUnbounceCheckbox}
                    />
                    <span className="checkbox-text">
                        Add our{" "}
                        <strong>
                        premium Agent Concierge service for only ${product?.priceUpFront}
                        </strong>{" "}
                        and sell your home with complete peace of mind with
                        additional guidance from a licensed Texas agent.
                    </span>
                    </label>
                </div>
                </div>
                <p className="see-exactly" onClick={handleModalOpen}>
                    <span className="text">See exactly what you’ll get.</span>
                    <span className="icon-round">
                        <img src={ExactlyIcon} alt="Icon" />
                    </span>
                </p>
            </PremiumServiceWrap>
            <Divider></Divider>
            {showEmailConfirmationText && (
                <LinkConfimationContainer>
                  <CheckMarkIcon />
                  <LinkConfimationText>
                    Confirmation link was sent to your email address.
                  </LinkConfimationText>
                  </LinkConfimationContainer>
            )}
            {isFreeSignup &&
                <PayNowBtn type="submit" onClick={handleSaveFreeUnbounce} className="block">
                Start your Free Listing
                </PayNowBtn>}
            {!isFreeSignup && <>
                <AddedPremumAgent>
                    You’ve added our premium Agent Concierge service and{" "}
                    <strong>will be charge a total of ${product?.priceUpFront}.</strong>
                </AddedPremumAgent>
                <CardInput flex={1}>
                    <label className="for-card-label">Credit Card</label>
                    <CardElementStyled
                        hidePostalCode={true}
                        style={{ base: { fontWeight: 400, fontSize: "16px", fontFamily: 'Montserrat' } }}
                        onChange={handleCardDetailsChanged}
                    />
                    {cardError &&
                        <ErrorDiv>{cardError.message}</ErrorDiv>
                    }
                </CardInput>
                <PayNowBtn type="submit" onClick={(e) => handleSaveWithPayUnbounce(e, product?.productId)} className="block">
                Purchase Agent Concierge
                </PayNowBtn>
                {isApplePayAvailable &&
                    <PaymentRequestButtonElement
                        onClick={handleApplePayClick}
                        paymentRequest={paymentRequest}
                    ></PaymentRequestButtonElement>}
                </>}
            </form>
            <TermsConditions>
                By signing up you’re agreeing to our{" "}
                <a className="link" href={`${routes.TERM_CONDITION}`} rel="noopener noreferrer" target="_blank">Terms of Condition</a> and{" "}
                <a className="link" href={`${routes.TERM_CONDITION}`} rel="noopener noreferrer" target="_blank">Privacy Policy</a>
            </TermsConditions>
            <FeatureSlider itemsToShowCount={ITEMS_TO_SHOW_IN_CAROUSEL} itemsToScrollCount={ITEMS_TO_SCROLL_IN_CAROUSEL} />
            <Endorsed>Endorsed by:</Endorsed>
        </SignUpHomeListingForm>);
}

function mapStateToProps(state) {
  const userDetails = getLocalUserData();
  const { isFetching, errors } = state.user;
  return {
    userDetails: userDetails?.isAdminSwitchUser ? userDetails.switchedUser : userDetails || {},
    userErrors: errors || {},
    isFetching: state.onboarding.isFetching,
    isUserFetching: isFetching,
    isLoggedIn: !!userDetails?.userId,
    productsInfo: state.onboarding?.productsInfo || [],
    paymentIntent: state.onboarding?.paymentIntent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators, ...onboardingActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(injectStripe(UnbounceFromView)));
