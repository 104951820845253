import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';

class ProductService {

    constructor() {
        let urls = ((window.config || {}).urls || {}).dashboard || {};

        urls.base = urls.base || "/";

        this.urls = urls;
    }

    getProducts(params) {
        const query = new URLSearchParams(params).toString();
        const url = `api/v2/products?${query}`;

        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }

    getProduct(productId) {
        const url = `/api/v2/products/${productId}`;
        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }

    getProductFeatureTypes(params) {
        const query = new URLSearchParams(params).toString();
        const url = `api/v2/products/feature-types?${query}`;

        return fetch(url, {
            method: apiMethodType.GET,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }

    createProduct(product) {
        const url = `/api/v2/products`;
        return fetch(url, {
            method: apiMethodType.POST,
            data: product,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }

    updateProduct(productId, product) {
        const url = `/api/v2/products/${productId}`;
        return fetch(url, {
            method: apiMethodType.PUT,
            data: product,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }

    copyProduct(productId) {
        const url = `/api/v2/products/${productId}/copy`;
        return fetch(url, {
            method: apiMethodType.PUT,
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader(),
            },
        });
    }
}

export const productService = new ProductService();
