/* eslint-disable no-useless-constructor */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { PRow, InputFlex, } from '../../../Assets/Style/commonStyleComponents';

const Wrapper = styled.div``;
class ShippingAddress extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { initialState, handleFieldChanged, handleFieldBlur, selectCountry, selectStateAndCounty } = this.props;
    return (
      <React.Fragment>
        <Wrapper>
          <PRow>
            <InputFlex
              name="shippingAddress1"
              title="Address"
              value={initialState.shippingAddress1.value}
              error={initialState.shippingAddress1.error}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              className="inputgap pcol-50"
            />
            <InputFlex
              name="shippingAddress2"
              title="Address 2"
              value={initialState.shippingAddress2.value}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              className="inputgap pcol-50"
            />
          </PRow>
          <PRow>
            <div className="pcol-6 for-custom-select">
              <label>Country</label>
              <CountryDropdown
                defaultOptionLabel="Select a country."
                valueType="short"
                value={initialState.shippingCountry.value || initialState.shippingCountry}
                onChange={(e) => selectCountry(e, 'shippingCountry')}
                error={initialState.shippingCountry.error}
                className={initialState.shippingCountry.error ? "custom-select error-input" : 'custom-select'}
              />
            </div>
            <div className="pcol-6 for-custom-select">
              <label>State</label>
              <RegionDropdown
                blankOptionLabel="No country selected."
                defaultOptionLabel="Now select a region."
                countryValueType="short"
                valueType="short"
                country={initialState.shippingCountry.value}
                value={initialState.shippingState.value || initialState.shippingState}
                onChange={(e) => selectStateAndCounty(e, 'shippingState')}
                error={initialState.shippingState.error}
                className={initialState.shippingState.error ? "custom-select error-input" : 'custom-select'}
              />
            </div>
          </PRow>
          <PRow>
            <InputFlex
              name="shippingCity"
              title="City"
              value={initialState.shippingCity.value}
              error={initialState.shippingCity.error}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              className="inputgap pcol-50"
            />
            <InputFlex
              name="shippingZip"
              title="Zip"
              value={initialState.shippingZip.value}
              error={initialState.shippingZip.error}
              onChange={(e) => handleFieldChanged(e)}
              onBlur={(e) => handleFieldBlur(e)}
              className="inputgap pcol-50"
            />
          </PRow>
        </Wrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.user.isFetching,
  };
}

export default connect(mapStateToProps)(ShippingAddress);
