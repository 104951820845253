import LogRocket from 'logrocket';
import { startLoading, endLoading } from '../../LoaderActions';
import { draftListingService } from '../../../Services/V2/DraftListingService';
import { removeNullUndefined, dateStringToUTC, utcDateStringToLocal } from '../../../Utilities/commonFunctions';
import { ordersFiltersDefault } from '../../../Reducers/V2/AdminOrdersReducer';
import { dateFormat, staticMessages } from '../../../Constants/CommonConstants';
import { alertActions } from '../../ToastActions';
import moment from 'moment';

export const OrdersActions = {
    ORDERS_REQUESTED: 'ORDERS_REQUESTED',
    ORDERS_RESOLVED: 'ORDERS_RESOLVED',
    ORDERS_FAILED: 'ORDERS_FAILED',
    SET_ORDERS_FILTERS: 'SET_ORDERS_FILTERS',
    CLEAR_ORDERS_FILTERS: 'CLEAR_ORDERS_FILTERS',
    ORDER_ASSISGNING_RESOLVED: "ORDER_ASSISGNING_RESOLVED",
    GET_AVERAGE_TIME_TO_CONTACT_REQUESTED: 'GET_AVERAGE_TIME_TO_CONTACT_REQUESTED',
    GET_AVERAGE_TIME_TO_CONTACT_RESOLVED: 'GET_AVERAGE_TIME_TO_CONTACT_RESOLVED',
    GET_AVERAGE_TIME_TO_CONTACT_FAILED: 'GET_AVERAGE_TIME_TO_CONTACT_FAILED',
    MARK_AS_CONTACTED_RESOLVED: 'MARK_AS_CONTACTED_RESOLVED',
    MARK_AS_ARCHIVED_RESOLVED: 'MARK_AS_ARCHIVED_RESOLVED',
};

export const ordersCreators = {
    setOrdersFilters: (filters) => async (dispatch) => {
        dispatch({ type: OrdersActions.SET_ORDERS_FILTERS, payload: filters });
    },
    clearOrdersFilters: () => async (dispatch) => {
        dispatch({ type: OrdersActions.CLEAR_ORDERS_FILTERS });
    },
    getOrders: (params = ordersFiltersDefault) => async (dispatch, getState) => {
        dispatch({ type: OrdersActions.ORDERS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const paramsObj =
            {
                ...params,
                dateCreatedFrom: dateStringToUTC(params.dateCreatedFrom),
                dateCreatedTo: dateStringToUTC(params.dateCreatedTo),
                dateUpdatedFrom: dateStringToUTC(params.dateUpdatedFrom),
                dateUpdatedTo: dateStringToUTC(params.dateUpdatedTo)
            };

            if (paramsObj.dateCreatedFrom?.isSame(paramsObj.dateCreatedTo)) {
                paramsObj.dateCreatedTo.add(24, 'hours')
            }
            if (paramsObj.dateUpdatedFrom?.isSame(paramsObj.dateUpdatedTo)) {
                paramsObj.dateUpdatedTo.add(24, 'hours')
            }

            paramsObj.dateCreatedFrom = paramsObj.dateCreatedFrom?.format(dateFormat.DATE_TIME);
            paramsObj.dateCreatedTo = paramsObj.dateCreatedTo?.format(dateFormat.DATE_TIME);
            paramsObj.dateUpdatedFrom = paramsObj.dateUpdatedFrom?.format(dateFormat.DATE_TIME);
            paramsObj.dateUpdatedTo = paramsObj.dateUpdatedTo?.format(dateFormat.DATE_TIME);

            const { data } = await draftListingService.getDraftListings(removeNullUndefined(paramsObj));

            if (data?.results) {
                data.results.forEach(order => {
                    order.dateCreated = utcDateStringToLocal(order.dateCreated).format(dateFormat.DATE_TIME);
                    order.dateModified = utcDateStringToLocal(order.dateModified).format(dateFormat.DATE_TIME);
                })
            }
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: OrdersActions.ORDERS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_orders_action',
                },
                extra: {
                    type: OrdersActions.ORDERS_FAILED,
                },
            });
        }
    },
    archiveOrder: (draftId, isArchived) => async (dispatch, getState) => {
        const orders = getState().adminOrders.orders;
        const order = orders.find(o => o.id === draftId);
        order.isArchived = isArchived;

        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            await draftListingService.archiveOrder(draftId, { isArchived });
            dispatch({ type: OrdersActions.MARK_AS_ARCHIVED_RESOLVED, payload: [...orders, order] });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || 'Archiving order error';
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_admin_action',
                },
                extra: {
                    type: 'admin mark draft order as archived FAILED',
                },
            });
        }
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
    },
    assignOrder: (draftId, assigneeId) => async (dispatch, getState) => {

        try {
            const orders = getState().adminOrders.orders;
            const officeUsers = getState().lookup.officeUsers;
            const assigneeUser = officeUsers.find(o => o.value === Number(assigneeId));
            const order = orders.find(o => o.id === draftId);
            order.assignedTo = assigneeUser?.label;

            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            await draftListingService.assignOrder(draftId, { "officeUserId": assigneeId });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({
                type: OrdersActions.ORDER_ASSISGNING_RESOLVED,
                payload: [...orders, order]
            });
        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_admin_action',
                },
                extra: {
                    type: 'admin actions order assign FAILED',
                    draftId,
                    assigneeId,
                },
            });
        }
    },
    getAverageTimeToContact: () => async (dispatch) => {
        try {
            dispatch({ type: OrdersActions.GET_AVERAGE_TIME_TO_CONTACT_REQUESTED });
            const { data } = await draftListingService.getAverageTimeToContact();
            const formattedTime = `${moment.duration(data, 'seconds').hours()}:${moment.duration(data, 'seconds').minutes()}:${moment.duration(data, 'seconds').seconds()}`;
            dispatch({ type: OrdersActions.GET_AVERAGE_TIME_TO_CONTACT_RESOLVED, payload: formattedTime });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || 'Loading average time to contact error';
            dispatch(alertActions.errorAlert(errorMessage));
            dispatch({ type: OrdersActions.GET_AVERAGE_TIME_TO_CONTACT_FAILED });
            
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_admin_action',
                },
                extra: {
                    type: 'admin get average time to contact FAILED',
                },
            });
        }
    },
    markAsContacted: (draftId, val) => async (dispatch, getState) => {
        const orders = getState().adminOrders.orders;
        const order = orders.find(o => o.id === draftId);
        order.dateContacted = val;

        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            await draftListingService.markAsContacted({ contacted: val }, draftId);
            dispatch({ type: OrdersActions.MARK_AS_CONTACTED_RESOLVED, payload: [...orders, order] });
        } catch (ex) {
            const errorMessage = ex?.response?.data?.message || 'Setting order as contacted error';
            dispatch(alertActions.errorAlert(errorMessage));

            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_admin_action',
                },
                extra: {
                    type: 'admin mark draft order as contacted FAILED',
                },
            });
        }
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
    }
};
