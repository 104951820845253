import React from 'react';
import { inputTypeField } from '../../../Constants/CommonConstants';
import enums from './../../../Constants/Enums';
class OverlayFormRow extends React.Component {
  render() {
    const {
      item,
      onChangeInput,
      onInputKeyUp,
      onFocus,
      onChangeInputType,
      onAddInput,
      onRemoveInput,
      onCopyInput,
      index,
      highlightedIndex,
      onReadOnlyCheckbox,
      onRequireCheckbox,
      onChangeOptions,
      onChangeTargets,
      onLinkedCheckbox,
      fieldPrefillTypes,
      onChangePrefill,
      onChangePrefillText,
      isShoown,
      onChangePriority,
    } = this.props;
    return (
      <div
        id={`field_${index}`}
        className={`overlay-fields-row field row ${
          highlightedIndex === index ? 'highlight' : ''
        }`}
      >
        <div className='overlay-fields-box column medium-2'>
          <select
            name='field_type'
            onFocus={onFocus}
            onChange={onChangeInputType}
            value={item.inputDataTypeId}
            className='form-control'
          >
            {inputTypeField.map(option => (
              <option value={option.id}>{option.name}</option>
            ))}
          </select>
        </div>
        <div className='overlay-fields-box column medium-3'>
          <input
            type='text'
            name='field_name'
            value={item.displayName}
            onChange={onChangeInput}
            onKeyUp={onInputKeyUp}
            onFocus={onFocus}
            className='overlay-extra-input'
          />
        </div>
       <div className='overlay-fields-box column medium-5'>
          <input type='hidden' name='pos_x' defaultValue={item.overlayStartX} />
          <input type='hidden' name='pos_y' defaultValue={item.overlayStartY} />
          <input type='hidden' name='width' defaultValue={item.overlayWidth} />
          <input
            type='hidden'
            name='height'
            defaultValue={item.overlayHeight}
          />
          <div className='row overlay-checkbox-row'>
            <div className='col-sm-6 first-checkbox-row'>
              <div className='column medium-2 lh0'>
                {item.inputDataTypeId !== 14 && (
                  <>
                    <input type='hidden' name='required' />
                    <div className='admin-custom-checkbox'>
                      <label className='nowrap pointer' title='Required'>
                        <input
                          className='inputgap'
                          type='checkbox'
                          value={item.required}
                          name='checkbox_required'
                          data-input='required'
                          onChange={onRequireCheckbox}
                          checked={item.required}
                        />
                        <span>
                          <i className='fa fa-asterisk' aria-hidden='true' />
                        </span>
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='col-sm-6 second-checkbox-row'>
              <div className='column medium-2 lh0'>
                {item.inputDataTypeId !== enums.NUMERIC_VALUE.FIVE &&
                  item.inputDataTypeId !== enums.NUMERIC_VALUE.FOURTEEN && (
                    <>
                      <input type='hidden' name='locked' />
                      <div className='admin-custom-checkbox'>
                        <label className='nowrap pointer' title='Locked'>
                          <input
                            type='checkbox'
                            value={item.readOnly}
                            name='checkbox_locked'
                            data-input='locked'
                            className='inputgap'
                            onChange={onReadOnlyCheckbox}
                            checked={item.readOnly}
                          />
                          <span>
                            <i className='fa fa-lock' aria-hidden='true' />
                          </span>
                        </label>
                      </div>
                      <input type='hidden' name='linked' defaultValue={0} />
                      <span
                        data-tooltip='fxwjkh-tooltip'
                        aria-haspopup='true'
                        className='top'
                        data-click-open='false'
                        data-disable-hover='false'
                        tabIndex={2}
                        title
                        aria-describedby='5lp7kc-tooltip'
                        data-yeti-box='5lp7kc-tooltip'
                        data-toggle='5lp7kc-tooltip'
                        data-resize='5lp7kc-tooltip'
                        data-events='resize'
                      >
                        <div className='admin-custom-checkbox for-overlay-top-gap'>
                          <label className='nowrap pointer' title='Linked'>
                            <input
                              type='checkbox'
                              value={item.isLinked}
                              name='checkbox_linked'
                              data-input='linked'
                              className='inputgap'
                              onChange={onLinkedCheckbox}
                              checked={item.isLinked}
                            />
                            <span>
                              <i className='fa fa-clone' aria-hidden='true' />
                            </span>
                          </label>
                        </div>
                      </span>
                    </>
                  )}
              </div>
            </div>
            <div className='column medium-4 overlay-prefill-box'>
              {item.inputDataTypeId === enums.NUMERIC_VALUE.FIVE && (
                <label className='small'>
                  <storng className='prefill-label-text for-top-gap'>
                    Group{' '}
                  </storng>
                  <input
                    type='text'
                    name='field_group'
                    defaultValue
                    className='small overlay-extra-input'
                  />
                </label>
              )}
              {item.inputDataTypeId === enums.NUMERIC_VALUE.FOURTEEN && (
                <label className='small'>
                  <span
                    data-tooltip='qihuio-tooltip'
                    aria-haspopup='true'
                    className='top'
                    data-click-open='false'
                    data-disable-hover='false'
                    tabIndex={2}
                    title
                    aria-describedby='al403r-tooltip'
                    data-yeti-box='al403r-tooltip'
                    data-toggle='al403r-tooltip'
                    data-resize='al403r-tooltip'
                    data-events='resize'
                    className='overlay-option-label'
                  >
                    Target Field(s)
                  </span>{' '}
                  <textarea
                    name='field_target'
                    className='small'
                    value={item.targetFields}
                    className='overlay-extra-textarea'
                    onChange={onChangeTargets}
                  />
                </label>
              )}
              {item.inputDataTypeId === enums.NUMERIC_VALUE.THIRTEEN && (
                <label className='small'>
                  <span
                    data-tooltip='nhlr1w-tooltip'
                    aria-haspopup='true'
                    className='top'
                    data-click-open='false'
                    data-disable-hover='false'
                    tabIndex={2}
                    title
                    aria-describedby='2g21hu-tooltip'
                    data-yeti-box='2g21hu-tooltip'
                    data-toggle='2g21hu-tooltip'
                    data-resize='2g21hu-tooltip'
                    data-events='resize'
                    className='overlay-option-label'
                  >
                    Options
                  </span>{' '}
                  <textarea
                    name='field_options'
                    className='small overlay-extra-textarea'
                    value={item.options}
                    onChange={onChangeOptions}
                    onFocus={onFocus}
                  />
                  {!item.options && (
                    <text style={{ marginTop: 5, color: 'red' }}>
                      Options required
                    </text>
                  )}
                </label>
              )}
            </div>
            {item.inputDataTypeId !== enums.NUMERIC_VALUE.FIVE &&
              item.inputDataTypeId !== enums.NUMERIC_VALUE.FOURTEEN &&
              item.inputDataTypeId !== enums.NUMERIC_VALUE.THIRTEEN && (
                <div className='column medium-4 overlay-prefill-box'>
                  <label className='small'>
                    <strong className='prefill-label-text'>Prefill</strong>
                    <select
                      name='field_prefill'
                      className='small form-control'
                      onFocus={onFocus}
                      onChange={onChangePrefill}
                      value={item.formPrefillTypeId}
                    >
                      <option value="">Select Prefill</option>
                      {fieldPrefillTypes.map(prefill => <option value={prefill.key}>{prefill.value}</option>)}
                    </select>
                    {isShoown &&
                      <input
                      type='text'
                      name='prefillText'
                      value={item.prefillText}
                      onChange={onChangePrefillText}
                      onKeyUp={onInputKeyUp}
                      onFocus={onFocus}
                      className='overlay-extra-input'
                    />
                    }
                    <input
                      type='text'
                      name='field_prefill_custom'
                      className='small'
                      defaultValue
                      style={{ display: 'none' }}
                    />
                  </label>
                </div>
              )}
          </div>
        </div>
        <div className='overlay-fields-box column medium-2'>
          <input
            type='text'
            name='priority'
            value={item.priority}
            onChange={onChangePriority}
            onKeyUp={onInputKeyUp}
            onFocus={onFocus}
            className='overlay-extra-input'
          />
        </div>
        <div className='overlay-action-box'>
          <a className='pointer grey handle ui-sortable-handle'>
            <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
            <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
          </a>
          <div className='plus-minus'>
            <a className='pointer add-listing-form-field' onClick={onAddInput}>
              <i className='fa fa-plus-circle' aria-hidden='true'></i>
            </a>
            <a
              className='pointer remove-listing-form-field'
              onClick={onRemoveInput}
            >
              <i className='fa fa-minus-circle' aria-hidden='true'></i>
            </a>
          </div>
          <a className='pointer copy-listing-form-field' onClick={onCopyInput}>
            <i className='fa fa-files-o' aria-hidden='true'></i>
          </a>
        </div>
        <div className='overlay-fields-box column medium-2 actions'>
          <div className='row collapse'>
            <div className='columns small-4'>
              <a
                className='pointer grey handle ui-sortable-handle'
                title='Drag to Re-order'
              >
                <i className='fa fa-ellipsis-v' aria-hidden='true' />{' '}
                <i className='fa fa-ellipsis-v' aria-hidden='true' />
              </a>
            </div>
            <div className='columns small-4'>
              <a
                className='pointer add-listing-form-field'
                title='Insert Field'
              >
                <i className='fa fa-plus-circle' aria-hidden='true' />
              </a>
              <br />
              <a
                className='pointer remove-listing-form-field'
                title='Remove Field'
              >
                <i className='fa fa-minus-circle' aria-hidden='true' />
              </a>
            </div>
            <div className='columns small-4'>
              <a
                className='pointer copy-listing-form-field'
                title='Duplicate Field'
              >
                <i className='fa fa-files-o' aria-hidden='true' />
              </a>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OverlayFormRow;
