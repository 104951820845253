import React from 'react';
import { Button } from 'antd';

import Select from 'react-select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { voucherTemplatesSelector } from '../../../../../Reducers/LookupReducer';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { cursorStyles } from '../../../../../Constants/CommonConstants';
import { vouchersCreators } from '../../../../../Actions/V2/Admin/VouchersActions';
import { currentUserSelector } from '../../../../../Reducers/UserReducer';
import { vouchersFiltersSelector } from '../../../../../Reducers/V2/VouchersReducer';

const VouchersFilters = () => {
    const dispatch = useDispatch();
    const filters = useSelector(vouchersFiltersSelector);
    const voucherTemplates = useSelector(voucherTemplatesSelector);
    const userData = useSelector(currentUserSelector);

    const voucherTemplatesOptions = voucherTemplates.map(x => ({ value: x.id, label: x.name }));

    useEffect(() => {
        dispatch(lookupActionsCreators.getVoucherTemplates(userData.userId));
    }, []);

    const handleChange = (value) => dispatch(vouchersCreators.setVouchersFilters(value));

    const applyFilters = () => dispatch(vouchersCreators.getVouchers(userData.userId, filters));

    const clearFilters = () => {
        dispatch(vouchersCreators.clearVouchersFilters());
        dispatch(vouchersCreators.getVouchers(userData.userId, filters));
    }

    return (
        <div className={`admin-filter-wrapper more-filter`}>
            <div className={`filter-bar`}>
                <div className="filter-bar-left">
                    <div className='filter-dropdown for-custom-select'>
                        <label>Filter By Voucher Template</label>
                        <Select
                            styles={cursorStyles}
                            placeholder='Select Voucher Template'
                            value={voucherTemplatesOptions.find(x => x.value === filters.voucherTemplateId) || null}
                            onChange={e => handleChange({ ...filters, voucherTemplateId: e.value })}
                            options={voucherTemplatesOptions}
                            menuPosition='fixed'
                            menuShouldBlockScroll={false}
                            menuShouldScrollIntoView={true}
                            className='admin-filter-react-select'
                        />
                    </div>
                </div>
                <div className='form-group search-buttons'>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => applyFilters()}>
                        Apply Filters
                    </Button>
                    <Button
                        className='btn btn-primary admin-primary-btn small-btn'
                        onClick={() => clearFilters()}>
                        Reset Filters
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default (VouchersFilters);
