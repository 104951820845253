import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import {
  contentType,
  apiMethodType,
  entityTypes,
} from '../Constants/CommonConstants';
import ApiUrls from '../Constants/ApiUrls';

class AdminService {
  constructor() {
    let urls = ((window.config || {}).urls || {}).dashboard || {};

    urls.base = urls.base || '/';

    this.urls = urls;
  }

  getCustomApiUrlPath = entity => {
    let apiUrl = '';
    switch (entity) {
      case entityTypes.category:
        apiUrl = `${ApiUrls.addNewCategory}`;
        break;
      case entityTypes.addAbbreviation:
        apiUrl = `${ApiUrls.addNewAbbreviation}`;
        break;
      case entityTypes.user:
        apiUrl = `${ApiUrls.addNewUser}`;
        break;
      case entityTypes.form:
        apiUrl = `${ApiUrls.addNewBasicForm}`;
        break;
      case entityTypes.property:
        apiUrl = `${ApiUrls.addNewProperties}`;
        break;
      case entityTypes.products:
        apiUrl = `${ApiUrls.addNewProduct}`;
        break;
      case entityTypes.company:
        apiUrl = `${ApiUrls.addNewCompany}`;
        break;
      case entityTypes.coverage:
        apiUrl = `${ApiUrls.addNewCoverage}`;
        break;
      case entityTypes.template:
        apiUrl = `${ApiUrls.addNewVoucherTemplate}`;
        break;
      case entityTypes.addVoucher:
        apiUrl = `${ApiUrls.addNewVoucher}`;
        break;
      default:
        break;
    }
    return apiUrl;
  };

  getUserLookUp() {
    const url = ApiUrls.addUserLookUp;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  getSelectedItemDetail(itemId, activeEntity, formSectionId, isCloneApi) {
    let urlPath = formSectionId
      ? `${itemId}?formSectionId=${formSectionId}`
      : `${itemId}`;
    if (isCloneApi) {
      urlPath = formSectionId
        ? `${itemId}/clone?formSectionId=${formSectionId}`
        : `${itemId}/clone`;
    }
    const url = `${this.getCustomApiUrlPath(activeEntity)}/${urlPath}`;
    return fetch(url, {
      method: apiMethodType.GET,
      withCredentials: true,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateNewUsers(userId, reqObj) {
    const url = `${ApiUrls.addNewUser}/${userId}`;
    return fetch(url, {
      method: apiMethodType.POST,
     data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateCategory(mlsId, reqObj) {
    const url = `${ApiUrls.addNewCategory}/${mlsId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateAbbreviation(mlsId, reqObj) {
    const url = `${ApiUrls.addNewAbbreviation}/${mlsId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  addUpdateBasicForm(formId, reqObj) {
    const url = `${ApiUrls.addNewBasicForm}/${formId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateProperty(propertyId, reqObj) {
    const url = `${ApiUrls.addNewProperties}/${propertyId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  updatePropertyStatus(propertyId, reqObj) {
    const url = `${ApiUrls.addNewProperties}/${propertyId}/Status`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateProduct(productId, reqObj) {
    const url = `${ApiUrls.addNewProduct}/${productId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateCompany(companyId, reqObj) {
    const url = `${ApiUrls.addNewCompany}/${companyId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  switchCustomerPortal(userId) {
    const url = `${ApiUrls.switchCustomerPortal}${userId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  getAuditLog(reqObj) {
    const url = `${ApiUrls.auditLogs}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateCoverage(mlsLocationId, reqObj) {
    const url = `${ApiUrls.addNewCoverage}/${mlsLocationId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  addUpdateVoucherTemplate(templateId, reqObj) {
    const url = `${ApiUrls.addNewVoucherTemplate}/${templateId}`;
    return fetch(url, {
      method: apiMethodType.POST,
      data: reqObj,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
  getLandingList(stateCode) {
    const url = `${ApiUrls.getLandingList}/${stateCode}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  resetPassword(data) {
    const url = ApiUrls.resetPassword;
    return fetch(url, {
      method: apiMethodType.POST,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
      },
    });
  }

  resendEmailConfirmation(email) {
    const url = `${ApiUrls.resendEmailConfirmation}`;
    return fetch(url, {
      method: apiMethodType.POST,
      withCredentials: true,
      data: { email },
      headers: {
        'Content-Type': contentType.applicationJson,
      },
    });
  }

  searchUsers(searchString, cancelToken) {
    const url = `api/v2/lookup/users?${new URLSearchParams({ search: searchString }).toString()}`;
    return fetch(url, {
      method: apiMethodType.GET,
      withCredentials: true,
      cancelToken: cancelToken.token,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
}

export let adminService = new AdminService();
