import React from 'react'

export const FacebookIcon = ({ ...iconProps }) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...iconProps}>
       <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 19.2569C1 21.3225 2.67751 23 4.74306 23H19.2569C21.3225 23 23 21.3225 23 19.2569V4.74306C23 2.67751 21.3225 1 19.2569 1H4.74306C2.67751 1 1 2.67751 1 4.74306V19.2569ZM12.7643 10.0901V11.6178H10.4727V13.9095H12.7643V22.9959H15.056V13.9095H17.7743L18.0607 11.6178H15.056V10.0901C15.056 9.98608 15.0643 9.88402 15.0804 9.78451C15.2271 8.87621 16.0167 8.18034 16.9657 8.18034H18.1115V5.88867H16.9657C16.7316 5.88867 16.502 5.90792 16.2782 5.94491C14.2875 6.274 12.7643 8.00764 12.7643 10.0901Z"
        fill="#ffffff"
      />
    </svg>
  );
};
