import React from 'react';
import { EbookWrapper, Container, InputFlex, CustomButton, RowFlex } from '../../../../../Assets/Style/commonStyleComponents';
import { ImagesUrl } from "../../../../../Constants/Images";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userActionCreators } from '../../../../../Actions/UserActions';
import v8n from "v8n";
import { validationMessages, regexPattern } from '../../../../../Constants/CommonConstants';
import AppConsts from '../../../../../Constants/AppConsts';

const image_size = {
  width: "12.75rem",
  height: "20.4rem",
  padding: "8px 10px 10px",
  marginRight: "-50px"
};

class HomeSellingBook extends React.Component {
  state = {
    passwordVisible: true,
    HomeSellingFormFields: {
      email: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.email,
        },
        {
          rule: v8n().pattern(regexPattern.EMAIL),
          message: validationMessages.validEmail,
        }],
        value: ""
      },
      fullName: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.name,
        }],
        value: ""
      },
      enrollNewsletter: {
        rules: [],
        value: false
      }
    }
  }

  handleFieldChanged = (e) => {
    let fields = { ...this.state.HomeSellingFormFields};
    fields[e.target.name].value = e.target.value;
    this.setState({ fields: { ...fields } });
  }

  handleFieldCheked= (e) => {
    let fields = { ...this.state.HomeSellingFormFields};
    fields[e.target.name].value = e.target.checked;
    this.setState({ fields: { ...fields } });
  }

  handleFieldBlur = (e) => {
    this.validateSellingField(e.target.name, e.target.value);
  }

  handleDownload = (e) => {
    e.preventDefault();
    let isError = false;

    Object.keys(this.state.HomeSellingFormFields).forEach((k) => {
      const isErrorExist = this.validateSellingField(k, this.state.HomeSellingFormFields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    })

    if (!isError) {
    let reqObj= {
        "email": this.state.HomeSellingFormFields.email.value,
        "fullName":this.state.HomeSellingFormFields.fullName.value,
        "enrollNewsletter": this.state.HomeSellingFormFields.enrollNewsletter.value
      }
      this.props.RequestEbookDownload(reqObj);
      let pdfUrl=ImagesUrl.HOME_SELLING_BOOK;
      window.open(pdfUrl);
    }
  }

  validateSellingField = (key, value) => {
    let fields = { ...this.state.HomeSellingFormFields };
    let isError = false;
    for (let i = 0; i < fields[key].rules.length; i++) {
      let rule = fields[key].rules[i];

      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    this.setState({ fields: { ...fields }, isError: Object.keys(fields).some(k => fields[k].error !== undefined && fields[k].error !== null) });

    return isError;
  }
    render() {
        return (
        <>
        <EbookWrapper>
            <Container>
              <div className="ebook-main-wrapper">
              <div className={`klaviyo-form-${AppConsts.klaviyoForms.freeEbookFormId}`} />
              <form autoComplete="off">
                <div className="ebook-left">
                  <h2>Free E-book Download</h2>
                  <p>Fill out this form to receive a free copy of our e-book, which helps you save time and money by using proven 'flat fee' and 'by owner' strategies.</p>
                  <RowFlex>
                    <InputFlex
                      name="fullName"
                      title="Your Name"
                      className="inputgap"
                      value={this.state.HomeSellingFormFields.fullName.value}
                      error={this.state.HomeSellingFormFields?.fullName?.error}
                      onChange={this.handleFieldChanged}
                      onBlur={this.handleFieldBlur}
                    />
                    <InputFlex
                      name="email"
                      title="Your Email"
                      className="inputgap"
                      value={this.state.HomeSellingFormFields.email.value}
                      error={this.state.HomeSellingFormFields?.email?.error}
                      onChange={this.handleFieldChanged}
                      onBlur={this.handleFieldBlur}
                    />
                  </RowFlex>
                  <div className='admin-custom-checkbox'>
                    <label>
                      <input
                        className='inputgap'
                        type='checkbox'
                        name="enrollNewsletter"
                        defaultChecked={this.state.HomeSellingFormFields.enrollNewsletter.value}
                        onChange={this.handleFieldCheked}
                      />
                      <span>I want to receive USRealty's Newsletter, which contains special offers and helpful tips.</span>
                    </label>
                  </div>
                 {/* <a href={ImagesUrl.HOME_SELLING_BOOK} target="_blank">
                    <CustomButton type="submit" onClick={this.handleDownload} >Download Now</CustomButton>
        </a> */}
         <CustomButton type="submit" onClick={this.handleDownload} >Download Now</CustomButton>
                </div>
                </form>
                <div className="ebook-right">
                  <div className="ebook-image">
                    <img src={ImagesUrl.FLAT_FEE_MLSSUCCESS} alt="ebook" style={image_size}/>
                  </div>
                </div>
              </div>
            </Container>
        </EbookWrapper>
        </>
        )
    }
}

function mapStateToProps(state) {
  return {
    errors: state.user.errors
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeSellingBook);