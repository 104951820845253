import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';
import ApiUrls from '../Constants/ApiUrls';
import { contentType, apiMethodType } from '../Constants/CommonConstants';

class OnboardingService {

  constructor() {

    let urls = ((window.config || {}).urls || {}).onboarding || {};

    urls.base = urls.base || "/";

    this.urls = urls;

  }

  getListingProducts(params) {

    const url = ApiUrls.getListingProducts;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });

  }

  placeOrder(params) {

    const url = ApiUrls.placeOrder;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });

  }

  getMlsCategory() {
    const url = ApiUrls.getMlsCategory;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  listingMlsCategory(reqObj) {
    const url = ApiUrls.listingMlsCategory;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...reqObj },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  setLocal(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  getLocal(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  clearLocal(key) {
    localStorage.removeItem(key);
  }

  nonServiceableAreaRequest(params) {
    const url = ApiUrls.nonServiceableAreaRequest;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  contactUsUserRequest(params) {
    const url = ApiUrls.contactUsUserRequest;

    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });

  }

  globalSummaryRequest(reqObj) {
    const url = ApiUrls.globalSummaryRequest;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...reqObj },
      headers: {
        'Content-Type': contentType.applicationJson,
      }
    });
  }

  validateVoucherRequest(reqObj) {
    const url = ApiUrls.validateVoucher;
    return fetch(url, {
      method: apiMethodType.POST,
      data: { ...reqObj },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  checkAddressCoverage(params, unbounce = false) {
    //const url = unbounce ? `${ApiUrls.checkAddressCoverage}?sendAdminEmailIfNoCoverage=true` : ApiUrls.checkAddressCoverage;
    if (unbounce){
      params['sendAdminEmailIfNoCoverage'] = true;
    }
    return fetch(ApiUrls.checkAddressCoverage, {
      method: apiMethodType.POST,
      data: { ...params },
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  
  getProducts(productTypeId) {
    const url = `${ApiUrls.getProductsInfo}?productTypeId=${productTypeId}`;
    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
}

export let onboardingService = new OnboardingService();
