import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { cashOfferCreators } from '../../../../../Actions/V2/CashOfferActions';

const CashOfferButtonList = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.25rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: #e0fe60;
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 5px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
      margin-bottom: 0.5rem;
  }
`;

const CashOfferButtonGrid = styled(CashOfferButtonList)`
  left: 0.25rem;
`;

const CashOfferButton = ({
  draftListingId,
  listingId,
  price,
  grid,
  listindAddressDetails,
}) => {
  const dispatch = useDispatch();
  const onCLick = () => dispatch(cashOfferCreators.openCashOfferModal({
    draftListingId,
    listingId,
    price,
    address:  `${listindAddressDetails?.address1 || ''} ${listindAddressDetails?.address2 || ''}`,
    city: listindAddressDetails?.city,
    zipCode: listindAddressDetails?.zipCode,
    placeId: listindAddressDetails?.placeId,
  }));

  return grid ? (
    <CashOfferButtonGrid
      type="button"
      onClick={onCLick}
    >
      Get your cash offer
    </CashOfferButtonGrid>
  ) : (
    <CashOfferButtonList
      type="button"
      onClick={onCLick}
    >
      Get your cash offer
    </CashOfferButtonList>
  );
};

export default CashOfferButton;
