import React from 'react';
import styled from 'styled-components';
import { roleType } from '../../../Constants/CommonConstants';
import Routes from '../../../Constants/Routes';
import { onboardingService } from '../../../Services/OnboardingService';
import { getCurrentUserData } from '../../../Utilities/commonFunctions';


const AddressWrapper = styled.div`
    position:relative;
    z-index:1;
    margin:0 auto;
    max-width:800px;
    @media(max-width: 992px){
      max-width: 100%;
    }
`;

const InputWrapper = styled.div`
    background:#fff;
    border-radius:4px;
    padding:1px;
    display:flex;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
`;

const Input = styled.input`
    font-size:18px;
    padding:12px 8px;
    flex:5;
    border: 0;
    border-radius: 0;
    padding-left: 20px;
    color: #0a0a0a;
    font-weight: 500;
    height: 54px;
    @media(max-width: 768px) {
      padding-left: 10px;
      font-size: 16px;
    }
    @media(max-width: 340px) {
      font-size: 14px;
    }
`;

const Results = styled.div`
    background:#fff;
    display:flex;
    flex-direction:column;
    text-align:left;
    position:absolute;
    width:100%;
    border:1px solid #f0f0f0;
    border-radius:0 0 4px 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .3);
`;

const Result = styled.a`
    padding:18px 20px;
    color:#242B26;
    z-index:1;
    border-bottom:1px solid #f0f0f0;
    ${props => props.isActive ? "background-color:#C9E2F3;" : ""}
    &:last-child {
        border:none;
    }
    &:hover {
        background:#f0f0f0;
        color:#242B26;
    }
    @media(max-width: 768px) {
      padding: 12px 10px;
      line-height: 24px;
      font-size: 15px;
    }
`;

const Button = styled.button`
    border-radius: 4px;
    text-align: center;
    padding: 12px 23px;
    background-color: ${({ theme }) => theme.dark};
    font-weight: 800;
    font-family: 'National-Semi-Bold';
    border-color: #B01F24;
    color: #fff;
    margin: 4px;
    letter-spacing: 0.3px;
    font-size: 16px;
    @media(max-width: 768px) {
      padding: 12px 10px;
      font-size: 14px;
    }
    @media(max-width: 340px) {
      padding: 12px 10px;
      font-size: 12px;
    }
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
`;

const google = window.google;

export default class AddressSelector extends React.Component {
  state = {
    searchValue: '',
    currentIndex: 0,
    isLoading: false,
    predictions: [],
    voucherCode: ''
  };

  autoService = null;
  placesService = null;
  timeout = null;

  componentDidMount = (e) => {
    this.autoService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    let voucher=params.get('code');
    this.setState({voucherCode:voucher});
    if(voucher){
      let reqObj={'voucherCode':voucher};
      if(this.props.isLoggedIn){
        let email=getCurrentUserData().email;
        reqObj={'voucherCode':voucher,'email':email};
      }
      this.props.handleVouchervalidate(reqObj);
    }else if(this.props.isLoggedIn && (getCurrentUserData().role !== roleType.WHOLESALE_CUSTOMER && getCurrentUserData().role !== roleType.RETAIL)
    && window.location.pathname===Routes.ONBOARDING+'/voucher'){
      this.props.unAuthUserRedeemVoucher();
    }
  }

  handleAddressChanged = (e) => {
    this.setState({ searchValue: e.target.value });
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    this.timeout = window.setTimeout(this.searchAddresses, 500);
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 38 && this.state.currentIndex !== 0) {
      this.setState({ currentIndex: this.state.currentIndex - 1 });
      e.preventDefault();
    } else if (e.keyCode === 40 && this.state.currentIndex < this.state.predictions.length - 1) {
      this.setState({ currentIndex: this.state.currentIndex + 1 });
      e.preventDefault();
    } else if (e.keyCode === 13 || e.keyCode === 9) {
      if (this.state.predictions.length > 0) {
        this.handleAddressSelected(e, this.state.currentIndex);
      }
      e.preventDefault();
    }
  }

  handleAddressSelected = (e, i) => {
    const prediction = this.state.predictions[i];
    this.setState({ isLoading: true, searchValue: prediction.description });
    this.placesService.getDetails({
      placeId: prediction.place_id
    }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.setState({
          predictions: [],
          isLoading: false
        });

        this.props.handleAddressSelected(place);
      } else {
        this.setState({
          predictions: [],
          isLoading: false
        });
        this.props.handleAddressSelected({});
      }
    });

    e.preventDefault();
  }

  searchAddresses = () => {
    this.autoService.getPlacePredictions({
      input: this.state.searchValue,
      types: ["address"],
      componentRestrictions: { country: 'us' }
    },
      (predictions, status) => {
        if (status === "OK" && predictions.length > 0) {
          this.setState({ predictions: predictions });
        }
        else {
          this.setState({ predictions: [] });
        }
      });
  }

  redeemVoucherCode = () => {
    const { voucherCode } = this.state;
    if(voucherCode.length>0){
      let reqObj={'voucherCode':voucherCode};
      if(this.props.isLoggedIn){
        let email=getCurrentUserData().email;
        reqObj={'voucherCode':voucherCode,'email':email};
      }
      this.props.handleVoucherCode(reqObj);
    }
  }

  render() {
   return (
      <React.Fragment>
        <AddressWrapper className={this.props.className}>
          {
           getCurrentUserData().role !== roleType.WHOLESALE_CUSTOMER && window.location.pathname !== Routes.ONBOARDING_V2 + '/voucher' &&
            <>
            <p className="free-today">Start Your Home Listing For Free Today</p>
            <div className="custom-address-selector">
              <InputWrapper>
                <Input type="text" name="address" placeholder="Enter Your Property Address"
                  onChange={this.handleAddressChanged} value={this.state.searchValue}
                  autoComplete="off" onKeyDown={this.handleKeyDown} />
                <Button>Continue</Button>
              </InputWrapper>
              {this.state.predictions.length > 0 &&
                <Results>
                  {this.state.predictions.map((p, i) => (<Result key={i} href="#"
                    isActive={this.state.currentIndex === i}
                    onClick={(e) => this.handleAddressSelected(e, i)}>{p.description}</Result>))}
                </Results>
              }
            </div>
           </>
          }
          {
            (!this.props.isLoggedIn || getCurrentUserData().role === roleType.WHOLESALE_CUSTOMER || getCurrentUserData().role === roleType.RETAIL) &&
            (window.location.pathname===Routes.ONBOARDING+'/voucher') &&
            <>
              {(!this.props.isLoggedIn && getCurrentUserData().role !== roleType.WHOLESALE_CUSTOMER) &&
                <div className="or-divider">
              </div>}
              <InputWrapper>
                <Input type="text" name="voucherCode" placeholder="Enter Your Voucher Code" value={this.state.voucherCode}
                  autoComplete="off" onChange={(e) => { this.setState({ voucherCode: e.target.value }) }} />
                <Button onClick={() => this.redeemVoucherCode()}>Redeem</Button>
              </InputWrapper>
            </>
          }
        </AddressWrapper>
      </React.Fragment>
    );
  }
}
