import Swagger from 'swagger-client'

class ApiService {
  api = {};
  constructor() {

  }

  hasSpec() {
    return (this.api && this.api.spec);
  };

  spec() {
    return this.api;
  }

  getSpec() {
    var pa = new Promise((resolve, reject) => {
      if(this.api && this.api.spec)
        resolve(this.api);
    });
    return Promise.race([pa,
      Swagger('https://flatfee.local:5001/swagger/v1/swagger.json').then((jx) => {
        this.api = jx;
        return jx;
      })
    ])
      return this.api;
  };

  fetchSpec() {
    return Swagger('https://flatfee.local:5001/swagger/v1/swagger.json').then((jx) => {
        this.api = jx;
        return jx;
      })
  };

}
export let apiService = new ApiService();
