import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import v8n from 'v8n';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';

import {
  propertyHeldSelector,
  totalSavingSelector,
  propertyPriceSelector,
  mlsCategoryIdSelector,
  propertyTypeIdSelector,
  listingIdSelector,
  propertyOwnersSelector,
  draftListingIdSelector,
  titleCompanyIdSelector,
  titleCompanyNameSelector,
  selectedAddressSelector,
  titleCompanyEmailSelector,
  companyTypeSelector,
  titleCompanyPreferenceSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { propertyTypesSelector, mlsCategoriesSelector } from '../../../Reducers/LookupReducer';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import ListingPreviewMobileContainer from '../Components/ListingPreviewMobileContainer';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { CheckMarkIcon } from '../../Shared/Components/Icons/CheckMarkIcon';
import { UncheckedIcon } from '../../Shared/Components/Icons/UncheckedIcon';
import { gTag, getPropertyOwnerFields } from '../../../Utilities/utils';
import FormTitleOwnerForm from '../Components/FormTitleOwnerForm';
import {
  LYFSteps,
  companyTypes,
  ownerTypes,
  ownershipTypes,
  regexPattern,
  validationMessages,
} from '../../../Constants/CommonConstants';
// import { Modal } from 'antd';
import TitleCompanyPanel from '../../Shared/Components/TitleCompanyPanel';
import CashOfferPanel from '../Components/CashOfferPanel';
import { EmbeddedYoutubeHintsPlayer } from '../Components/EmbeddedYoutubeHintsPlayer';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const Footer = styled.div`
  color: #626262;
  font-size: 14px;
  line-height: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 1.5rem;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const BackButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const TotoalSavingValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalSavingText = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
  padding-bottom: 0;
  align-self: center;
`;

const TotoalSavingValueText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  margin-left: 0.5rem;
`;

const AveragePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  justify-content: center;
`;

const TotalSavingContainer = styled(AveragePriceContainer)`
  background: ${({ theme }) => theme.lightGreen};
  justify-content: space-between;
  padding: 1.5rem;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4.25rem;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  min-width: 0px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  font-size: 1rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  border-radius: 8px;
  border-width: 1.5px;
  border-style: dashed;
  color: ${({ theme }) => theme.colors.green[900]};
&.invalid {
  border-color: rgb(229, 62, 62);
  background-color: #e5161608;

    label {
      color: #F56565;
    }
`;

const InputLabel = styled.div`
  font-size: 1.25rem
  line-height: 1.75rem;
`;

const CheckMarkIconContainer = styled.div`
  margin-right: 0.75rem;
`;

const AddOwnerButton = styled.button`
  background: none;
  text-align: left;
  font-size: 1rem;
  line-height: 1.2rem;
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  padding-inline-end: 1rem;
  color: ${({ theme }) => theme.colors.green[900]};
  align-self: flex-start;
  &:disabled {
    color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const Text = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0.25rem;
`;

const RemoveOwnerButton = styled(AddOwnerButton)`
  justify-content: left;
`;

const formValidationRules = {
  email: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
    {
      rule: v8n().pattern(regexPattern.EMAIL),
      message: validationMessages.validEmail,
    },
  ],
  name: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  phone: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
    {
      rule: v8n().pattern(regexPattern.PHONE),
      message: validationMessages.phoneNumber,
    },
  ],
  companyName: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  principalFirstName: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  principalLastName: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  positionInCompany: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  ownerType: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
};

const PropertyTitle = () => {
  const [showListingPreview, setShowListingPreview] = useState(false);
  const [errors, setErrors] = useState({
    ownershipType: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select property held',
        },
      ],
      error: '',
    },
    titleCompanyName: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select title and escrow provider',
        },
      ],
      error: '',
    },
  });

  const ownershipType = useSelector(propertyHeldSelector);
  const dispatch = useDispatch();
  const totalSaving = useSelector(totalSavingSelector);
  const propertyTypeId = useSelector(propertyTypeIdSelector);
  const mlsCategoryId = useSelector(mlsCategoryIdSelector);
  const propertyPrice = useSelector(propertyPriceSelector);
  const propertyTypes = useSelector(propertyTypesSelector);
  const mlsCategories = useSelector(mlsCategoriesSelector);
  const listingId = useSelector(listingIdSelector);
  const propertyOwners = useSelector(propertyOwnersSelector);
  const titleCompanyId = useSelector(titleCompanyIdSelector);
  const titleCompanyName = useSelector(titleCompanyNameSelector);
  const titleCompanyEmail = useSelector(titleCompanyEmailSelector);
  const titleCompanyPreference = useSelector(titleCompanyPreferenceSelector);
  const draftListingId = useSelector(draftListingIdSelector);
  const selectedAddress = useSelector(selectedAddressSelector);
  const companyType = useSelector(companyTypeSelector);
  const timeout = useRef(null);

  const handleChange = (data) => {
    dispatch(
      onboardingActionCreatorsV2.setTitleCompany({
        titleCompanyId: data.titleCompanyId,
        titleCompanyName: data.titleCompanyName,
        titleCompanyEmail: data.titleCompanyEmail,
        titleCompanyPreference: data.titleCompanyPreference,
      })
    );
  };

  /*const confirmUncheckPopup = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: "Are you sure you are not interested in sharing your information with service providers that may be helpful to you?",
      onOk: () => {
        dispatch(onboardingActionCreatorsV2.setTitleCompany({
          titleCompanyId: null,
          isTitleCompanySelected: false
        }))
      },
      onCancel() { },
    });
  }*/

  const validateField = (key, value) => {
    let fields = { ...errors };
    let isError = false;
    for (let i = 0; i < errors[key].rules.length; i++) {
      let rule = errors[key].rules[i];
      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setErrors((pr) => ({ ...pr, ...fields }));

    return isError;
  };

  const validateOwners = () => {
    let isError = false;
    propertyOwners.forEach((ownerFiellds, index) =>
      Object.keys(ownerFiellds).forEach((fieldName) => {
        let fields = propertyOwners[index];
        for (let i = 0; i < formValidationRules[fieldName].length; i++) {
          let rule = formValidationRules[fieldName][i];
          if (!rule.rule.test(propertyOwners[index][fieldName].value)) {
            fields[fieldName].error = rule.message;
            isError = true;
            break;
          }
        }

        if (!isError) {
          fields[fieldName].error = null;
        }

        const propertyOwnersTemp = [...propertyOwners];
        propertyOwnersTemp[index] = fields;
        dispatch(onboardingActionCreatorsV2.setPropertyOwners(propertyOwnersTemp));
      })
    );

    return isError;
  };

  const handleFinishListing = () => {
    const ownershipTypeError = validateField('ownershipType', ownershipType);
    const titleCompanyNameError = !titleCompanyId && validateField('titleCompanyName', titleCompanyName);
    const ownersError = validateOwners();
    let isError = ownershipTypeError || ownersError || titleCompanyNameError;

    if (!isError) {
      const category = mlsCategories.find((o) => Number(o.id) === Number(mlsCategoryId))?.name;
      const name = propertyTypes.find((o) => Number(o.id) === Number(propertyTypeId))?.name;

      gTag({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 6,
            },
            products: [
              {
                name,
                category,
                price: propertyPrice,
                held: ownershipType,
              },
            ],
          },
        },
      });

      dispatch(
        onboardingActionCreatorsV2.setListingStepV2(listingId ? LYFSteps.CONTRACTS_SIGN : LYFSteps.BUYER_PREFERENCES)
      );
    }
  };

  const setPropertyHeld = (val) => {
    validateField('ownershipType', val);
    if (val === ownershipTypes.oneOwner) {
      dispatch(onboardingActionCreatorsV2.setPropertyOwners([getPropertyOwnerFields(ownerTypes.individual)]));
    } else if (val === ownershipTypes.ownedByCompany) {
      dispatch(onboardingActionCreatorsV2.setPropertyOwners([getPropertyOwnerFields(ownerTypes.company)]));
    }
    dispatch(onboardingActionCreatorsV2.setPropertyHeldV2(val));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
  };

  const addOwner = () => {
    dispatch(
      onboardingActionCreatorsV2.setPropertyOwners([...propertyOwners, getPropertyOwnerFields(ownerTypes.individual)])
    );
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
  };

  const onFieldChange = (name, value, index) => {
    const propertyOwnersTemp = [...propertyOwners];
    propertyOwnersTemp[index][name].value = value;
    dispatch(onboardingActionCreatorsV2.setPropertyOwners(propertyOwnersTemp));

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (value) {
      timeout.current = setTimeout(() => dispatch(onboardingActionCreatorsV2.updateDraftListingV2()), 1000);
    }
  };

  const onFieldFocus = (name, index) => {
    const propertyOwnersTemp = [...propertyOwners];
    propertyOwnersTemp[index][name].error = null;
    dispatch(onboardingActionCreatorsV2.setPropertyOwners(propertyOwnersTemp));
  };

  const changeOwnerType = (ownerType, index) => {
    const owner = getPropertyOwnerFields(
      Number(ownerType) === ownerTypes.individual ? ownerTypes.individual : ownerTypes.company
    );

    const propertyOwnersTemp = [...propertyOwners];
    propertyOwnersTemp[index] = owner;
    dispatch(onboardingActionCreatorsV2.setPropertyOwners(propertyOwnersTemp));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
  };

  const removeOwner = () => {
    dispatch(onboardingActionCreatorsV2.setPropertyOwners([propertyOwners[0]]));
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());
  };

  const onTitleCompanyFieldFocus = () => {
    setErrors((pr) => ({
      ...pr,
      titleCompanyName: {
        ...pr.titleCompanyName,
        error: '',
      },
    }));
  };

  return (
    <>
      <Container>
        {showListingPreview ? (
          <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
        ) : (
          <>
            <FormContainer>
              <TitleContainer>
                <SubTilteText>List your property</SubTilteText>
                <StepsText>6/9</StepsText>
              </TitleContainer>
              <TitleText>How is title to your property held?</TitleText>
              <EmbeddedYoutubeHintsPlayer mobile={true} width="296" height="536" />
              <InputContainer
                onClick={() => {
                  setPropertyHeld(ownershipTypes.oneOwner);
                }}
                className={errors.ownershipType.error ? 'invalid' : ''}
              >
                {ownershipType === ownershipTypes.oneOwner ? (
                  <CheckMarkIconContainer>
                    <CheckMarkIcon color="#242B26" />
                  </CheckMarkIconContainer>
                ) : (
                  <CheckMarkIconContainer>
                    <UncheckedIcon color="#242B26" />
                  </CheckMarkIconContainer>
                )}
                <InputLabel htmlFor="email">By one individual</InputLabel>
              </InputContainer>
              <InputContainer
                onClick={() => {
                  setPropertyHeld(ownershipTypes.multipleOwners);
                }}
                className={errors.ownershipType.error ? 'invalid' : ''}
              >
                {ownershipType === ownershipTypes.multipleOwners ? (
                  <CheckMarkIconContainer>
                    <CheckMarkIcon color="#242B26" />
                  </CheckMarkIconContainer>
                ) : (
                  <CheckMarkIconContainer>
                    <UncheckedIcon color="#242B26" />
                  </CheckMarkIconContainer>
                )}
                <InputLabel htmlFor="email">Multiple owners</InputLabel>
              </InputContainer>
              <InputContainer
                onClick={() => {
                  setPropertyHeld(ownershipTypes.ownedByCompany);
                }}
                className={errors.ownershipType.error ? 'invalid' : ''}
              >
                {ownershipType === ownershipTypes.ownedByCompany ? (
                  <CheckMarkIconContainer>
                    <CheckMarkIcon color="#242B26" />
                  </CheckMarkIconContainer>
                ) : (
                  <CheckMarkIconContainer>
                    <UncheckedIcon color="#242B26" />
                  </CheckMarkIconContainer>
                )}
                <InputLabel htmlFor="email">Owned by company</InputLabel>
              </InputContainer>
              {errors.ownershipType.error && <ErrorMessage>{errors.ownershipType.error}</ErrorMessage>}

              <CashOfferPanel
                listindAddressDetails={{
                  ...(selectedAddress || {}),
                }}
                draftListingId={draftListingId}
                price={propertyPrice}
              />

              <Text>Provide owner details:</Text>
              {propertyOwners.map((owner, index) => (
                <FormTitleOwnerForm
                  key={index}
                  index={index}
                  ownerFields={owner}
                  onChange={onFieldChange}
                  onFocus={onFieldFocus}
                  changeOwnerType={(e) => changeOwnerType(e.target.value, index)}
                  typeSelectorDisabled={
                    ownershipType === ownershipTypes.oneOwner || ownershipType === ownershipTypes.ownedByCompany
                  }
                />
              ))}
              <div>
                {propertyOwners.length < 2 ? (
                  <AddOwnerButton onClick={addOwner} disabled={ownershipType !== ownershipTypes.multipleOwners}>
                    Add another owner +
                  </AddOwnerButton>
                ) : (
                  <Text>
                    If there are more than two owners please contact us at
                    <a href="mailto:info@unrealestate.com"> info@unrealestate.com</a> or call us at{' '}
                    <a href="tel:8665343726">(866) 534-3726</a>.
                  </Text>
                )}
                {propertyOwners.length === 2 && (
                  <RemoveOwnerButton onClick={removeOwner} disabled={ownershipType !== ownershipTypes.multipleOwners}>
                    Remove owner
                  </RemoveOwnerButton>
                )}
              </div>

              <TitleText>Select a Title and Escrow Provider</TitleText>
              <TitleCompanyPanel
                state={selectedAddress.state}
                titleCompanyId={titleCompanyId}
                titleCompanyName={titleCompanyName}
                titleCompanyEmail={titleCompanyEmail}
                titleCompanyPreference={titleCompanyPreference}
                onFocus={onTitleCompanyFieldFocus}
                handleChange={handleChange}
                error={errors.titleCompanyName.error}
              />
              {errors.titleCompanyName.error && <ErrorMessage>{errors.titleCompanyName.error}</ErrorMessage>}
              {totalSaving > 0 && (
                <TotalSavingContainer>
                  <TotalSavingText>Your potential savings by listing with Unreal Estate</TotalSavingText>
                  <TotoalSavingValueContainer>
                    <TotoalSavingValueText>
                      <CurrencyFormat
                        prefix="$"
                        value={totalSaving}
                        thousandSeparator=","
                        decimalSeparator="."
                        displayType="text"
                        suffix=".00"
                      />
                    </TotoalSavingValueText>
                  </TotoalSavingValueContainer>
                </TotalSavingContainer>
              )}
              <ButtonsContainer>
                <MobilePreviewButton
                  onClick={() => {
                    setShowListingPreview(true);
                  }}
                >
                  <EyeButton />
                </MobilePreviewButton>
                {companyType !== companyTypes.NETWORK && (
                  <BackButton
                    type="button"
                    onClick={() => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_PHOTOS))}
                  >
                    Back
                  </BackButton>
                )}
                <SubmitButton type="button" onClick={handleFinishListing}>
                  Finish
                </SubmitButton>
              </ButtonsContainer>
              <Footer>*Please note that we do not endorse vendors.</Footer>
            </FormContainer>
            <ListingPreviewDesktopContainer />
          </>
        )}
      </Container>
    </>
  );
};

export default PropertyTitle;
