import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { CheckMarkIconDashboard } from '../../Shared/Components/Icons/CheckMarkIconDashboard';
import { DashIcon } from '../../Shared/Components/Icons/DashIcon';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  background-color: ${({ theme }) => theme.colors.grayBackground};
`;

const ListingProgressListItemDivider = styled.span`
  display: flex;
  border-bottom: dashed 2px #d3dada;
  width: calc(100% - 3rem);
  heigth: 0;
  margin-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const FormTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckMarkIconContainer = styled.div`
  padding-right: 0.75rem;
`;

const MinusIconContainer = styled.div`
  padding-right: 0.5rem;
  padding-bottom: 3px;
`;

const FormTitle = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

// styles for grid type view

const customPanelStyle = {
  background: '#F4F4F4',
  border: 0,
  fontSize: '18px',
  paddingTop: '1rem',
};

const stepsNames = [
  'Provide property address',
  'Set price',
  'Provide property basics',
  'Provide property details',
  'Provide Property photos',
  'Provide title',
  'Set buying goals',
  'Select your plan',
  'Payment',
  'Sign contracts',
];

const ListingStepItem = ({ title, checked, withDivider }) => (
  <>
    <FormTitleContainer>
      {checked ? (
        <CheckMarkIconContainer>
          <CheckMarkIconDashboard light={true} />
        </CheckMarkIconContainer>
      ) : (
        <MinusIconContainer>
          <DashIcon />
        </MinusIconContainer>
      )}
      <FormTitle>{title}</FormTitle>
    </FormTitleContainer>
    {withDivider && <ListingProgressListItemDivider />}
  </>
);

export const ListingItemSteps = ({ listing }) => {
  const isListingDraft = listing.draftId;
  const listingType = listing.productType;
  let progress = {};
  const status = listing.status;
  const { brokerInfo } = listing;

  if (!isListingDraft && listingType === 'Listing') {
    progress = {
      completed: stepsNames,
      todo: [],
    };
  }

  if (isListingDraft && listingType === 'Listing') {
    const progressTemp = { ...listing.progress };

    const completedSteps = [];
    const todoSteps = [];

    delete progressTemp.currentStep;
    delete progressTemp.isCompleted;

    Object.values(progressTemp).forEach(({ completed }, index) => {
      (completed ? completedSteps : todoSteps).push(stepsNames[index]);
    });

    progress = {
      completed: completedSteps,
      todo: todoSteps,
    };
  }

  if (!isListingDraft && listingType === 'Network') {
    return (
      <StyledCollapse bordered={false}>
        {status === 'New' && (
          <Panel style={customPanelStyle} header={'Next steps (1)'} key="1">
            <ListingStepItem
              title={
                <div>
                  Contact network broker
                  <br />
                  <br />
                  <p className="static-text">Managing Broker: {brokerInfo?.name}</p>
                  <p className="static-text">Email: {brokerInfo?.email}</p>
                  <p className="static-text">Phone: {brokerInfo?.phone}</p>
                </div>
              }
              checked={false}
              withDivider={false}
            />
          </Panel>
        )}
        <Panel style={customPanelStyle} header={`Completed (${status === 'New' ? 3 : 4})`} key="2">
          <ListingStepItem title={stepsNames[0]} checked={true} withDivider={true} />
          <ListingStepItem title={stepsNames[6]} checked={true} withDivider={true} />
          <ListingStepItem title={stepsNames[7]} checked={true} withDivider={status !== 'New'} />
          {status !== 'New' && (
            <ListingStepItem
              title={
                <div>
                  Contact network broker
                  <br />
                  <br />
                  <p className="static-text">Managing Broker: {brokerInfo?.name}</p>
                  <p className="static-text">Email: {brokerInfo?.email}</p>
                  <p className="static-text">Phone: {brokerInfo?.phone}</p>
                </div>
              }
              checked={true}
              withDivider={false}
            />
          )}
        </Panel>
      </StyledCollapse>
    );
  }

  if (isListingDraft && listingType === 'Network') {
    return (
      <StyledCollapse bordered={false}>
        <Panel style={customPanelStyle} header={'Next steps (3)'} key="1">
          <ListingStepItem title={stepsNames[6]} checked={false} withDivider={true} />
          <ListingStepItem title={stepsNames[7]} checked={false} withDivider={true} />
          <ListingStepItem title="Contact network broker" checked={false} withDivider={false} />
        </Panel>
        <Panel style={customPanelStyle} header={'Completed (1)'} key="2">
          <ListingStepItem title={stepsNames[0]} checked={true} withDivider={false} />
        </Panel>
      </StyledCollapse>
    );
  }

  if (listingType !== 'Listing' && listingType !== 'Network') {
    progress = {
      completed: [],
      todo: [],
    };
  }

  return (
    <StyledCollapse bordered={false}>
      {progress.todo.length && (
        <Panel style={customPanelStyle} header={`Next steps (${progress.todo.length})`} key="1">
          {progress.todo.map((i, idx) => (
            <ListingStepItem title={i} checked={false} key={idx} withDivider={idx !== progress.todo.length - 1} />
          ))}
        </Panel>
      )}
      <Panel style={customPanelStyle} header={`Completed (${progress.completed.length})`} key="2">
        {progress.completed.map((i, idx) => (
          <ListingStepItem title={i} checked={true} key={idx} withDivider={idx !== progress.completed.length - 1} />
        ))}
      </Panel>
    </StyledCollapse>
  );
};
