import React from 'react';
import styled from 'styled-components';

const VideoIframe = styled.iframe`
  display: ${({ mobile }) => (mobile ? 'flex' : 'none')};
  border-radius: 10px;
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: ${({ mobile }) => (mobile ? 'none' : 'flex')};
  }
`;

export const EmbeddedYoutubePlayer = ({ customStyle = {}, mobile = false, width, height, src }) => {
 
  return (
    <VideoIframe
      mobile={mobile}
      style={customStyle}
      width={width}
      height={height}
      src={src}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></VideoIframe>
  );
};
