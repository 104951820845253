import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    ModalSubmitButton, ModalSubmitButtonWrapper, ModalMainWrapper,
    ModalAddressHeading, ModalLabelHeading
} from '../../../Assets/Style/commonStyleComponents';
import Routes from '../../../Constants/Routes';
import { Logo } from '../../Shared/Components/Icons/Logo';

const ReplayText = styled.p`
  font-family: 'National-Regular',sans-serif;
  font-weight: 300;
  color:#242B26;
  font-size: 14px;
  margin-bottom: 25px;
`

class CheckZipCoveragePopUp extends React.Component {
    render() {
        return (
            <React.Fragment>
                <ModalMainWrapper>
                    <a href={Routes.BUY_SIDE}>
                        <Logo/>
                    </a>
                    <ModalAddressHeading>{this.props.selectedAddress}</ModalAddressHeading>
                    <ModalLabelHeading>Oops! We're not in your neighborhood yet.</ModalLabelHeading>
                    <ModalLabelHeading>We'll update you when we expand to your neighborhood.</ModalLabelHeading>
                    <ReplayText>
                        Contact us with any questions <a href="mailto:info@usrealty.com"> info@usrealty.com</a> or call us at <br />
                        <a href="tel:+18665343726"><i className="fas fa-phone-alt"></i> (866) 534-3726 </a>.
                    </ReplayText>
                    <ModalSubmitButtonWrapper>
                        <ModalSubmitButton onClick={() => this.props.handleCloseModal()}>Close</ModalSubmitButton>
                    </ModalSubmitButtonWrapper>
                </ModalMainWrapper>
            </React.Fragment>
        );
    }
}

export default connect(null)(CheckZipCoveragePopUp);
