import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions';
import AdminSiderMenu from '../Components/AdminSiderMenu';
import AdminHeader from '../Components/AdminHeader';
import AddEditVoucherTemplate from '../Components/AddEditVoucherTemplate';
import Enums from '../../../Constants/Enums';
import { adminPageTitles } from '../../../Constants/CommonConstants';

const { Content } = Layout;
class VoucherTemplateConatiner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      entity: '',
      api: null
    };
    this.prevTitle = '';
  }

  componentDidMount() {
    this.props.getLookup(this.props.activeEntity, 'VoucherTempalteType');
    if(this.props.match?.params?.templateId > 0) {
      this.getSelectedApiCall(Number(this.props.match.params.templateId), this.props.activeEntity)
    }

  const pageType = this.props.match?.params?.templateId < 1
    ? 'addVoucherTemplate'
    : 'editVoucherTemplate';
  this.prevTitle = document.title;
  document.title = adminPageTitles[pageType]
}

componentWillUnmount() {
  document.title = this.prevTitle;
}

  getSelectedApiCall = (id, activity) => {
    this.props.getSelectedItemDetail(id, activity)
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };
  render() {
    const {
      history, selectedItemData, isFetching, isDetailFetching, lookup
    } = this.props;
    const { path } = this.props.match;
    const { collapsed } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <AdminSiderMenu path={path} onCollapse={this.onCollapse} history={history} collapsed={collapsed} />
        <Layout>
          <Layout.Header style={{ background: '#fff', minHeight: 52, padding: 0 }}>
            <AdminHeader collapsed={this.state.collapsed} toggle={this.toggle} />
          </Layout.Header>
          <Content>
            {
              !isDetailFetching && !isFetching &&
              <AddEditVoucherTemplate
                selectedItemData={selectedItemData}
                errors={this.props.userErrors}
                lookup={lookup}
                isNewTemplate = {this.props.match?.params?.templateId < 1}
                getSelectedApiCall={this.getSelectedApiCall}
              />
            }
            </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path, params } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[Enums.NUMERIC_VALUE.TWO],
    selectedItemData: params?.templateId > 0 ? state.admin.selectedItemData || {} : {},
    userErrors: state.admin.errors || {},
    lookup: state.admin.lookup || {},
    isDetailFetching: state.admin.isDetailFetching,
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VoucherTemplateConatiner));
