
class GoogleMapsService {
  constructor(google) {
  this.google = google;
  this.autoService = new google.maps.places.AutocompleteService();
  this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
  this.geocoder = new google.maps.Geocoder();
}
}
const mapsService = new GoogleMapsService(window.google);

export default mapsService;
