/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Table } from 'antd';
import { useEffect } from 'react';
import { entityTypes, pageSizeOptions, staticMessages, tableItemsPerPage } from '../../../../../Constants/CommonConstants';
import SearchPanel from '../Shared/SearchPanel.js';
import Routes from '../../../../../Constants/Routes.js';
import { adminProductsCountSelector, adminProductsSelector, productsFiltersSelector } from '../../../../../Reducers/V2/AdminProductsReducer.js';
import { productsCreators } from '../../../../../Actions/V2/Admin/ProductsActions.js';
import { editPageAction } from '../../../../../Utilities/commonFunctions';
import ProductsFilters from './ProductsFilters';
import CurrencyInput from 'react-currency-input-field';
import { useHistory } from 'react-router';

const tableConfigs = {
    columns: [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        render: (title, record) => (
          <Link
            to={editPageAction(record, entityTypes.products)}
          >
          {title}
          </Link>
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: true,
        render: description => (
          <React.Fragment>
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
          </React.Fragment>
        ),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        render: (price) => (
          <CurrencyInput
            value={price || 0}
            decimalScale={0}
            fixedDecimalScale={true}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ),
      },
      {
        title: 'Product Type',
        dataIndex: 'productType',
        key: 'productType',
        sorter: true,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (_, product) => (
            <CopyButton productId={product.id}/>
        ),
      },
    ],
    filters: ['productTypes'],
    deleteTitle: 'Delete Product',
    deleteMessage: `${staticMessages.deleteConfirmation} product?`,
    addPageUrl: `${Routes.ADMIN_ADD_PRODUCT}`,
    addButtonText: 'Add Product',
    searchPlaceHolder: 'Search Products',
};

const CopyButton = ({productId}) => {
    const dispatch = useDispatch();

    return (
        <Button
            onClick={() => {
                dispatch(productsCreators.copyProduct(productId));
            }}
            className="ant-btn btn btn-primary">
            Copy
        </Button>
    );
}

const ProductsList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const products = useSelector(adminProductsSelector);
    const filters = useSelector(productsFiltersSelector);
    const totalCount = useSelector(adminProductsCountSelector);

    useEffect(() => {
        dispatch(productsCreators.getProducts(filters));
    }, [
      filters.search,
      filters.page,
      filters.limit,
      filters.sortBy,
      filters.desc,
    ]);

    const onTableChange = (pagination, tablefilters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const sorterParams = {};

        if (field && order) {
          sorterParams.sortBy = field;
          sorterParams.desc = order === 'descend';
        }
        
        dispatch(productsCreators.setProductsFilters({
          ...filters,
          ...sorterParams,
          page: current,
          limit: pageSize
        }));
    }

    const showTotal = (total, range) => {
        return `Showing ${range[0]} - ${range[1]} of ${total}`;
    }
    const onSearch = (value) => dispatch(productsCreators.setProductsFilters({...filters, search: value}));

    return (<>
        <SearchPanel
            onSearch={onSearch}>
            <Button
                variant="primary"
                onClick={() => history.push(tableConfigs.addPageUrl)}
                className="admin-primary-btn">
                Add product
            </Button>
        </SearchPanel>
        <ProductsFilters />
        <Card>
            <Row style={{ marginTop: 20 }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}>
                        <Table
                            className='listing-product-table'
                            size={'default'}
                            columns={tableConfigs.columns}
                            dataSource={products}
                            onChange={onTableChange}
                            pagination={{
                                total: totalCount,
                                defaultPageSize: tableItemsPerPage,
                                defaultCurrent: 1,
                                showTotal: showTotal,
                                pageSizeOptions: pageSizeOptions,
                                showSizeChanger: totalCount > tableItemsPerPage
                            }} />
                </Col>
            </Row>
      </Card>
      </>
    );
}

export default (ProductsList);
