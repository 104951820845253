import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import Select from 'react-select';
import { withTheme } from 'styled-components';
import { onboardingActionCreators } from '../../../Actions/OnboardingActions';
import enums from "../../../Constants/Enums";
import {
  MidBoxWrapper,
  MidBox,
  HeadTextWrap,
  Heading
} from '../../../Assets/Style/commonStyleComponents';
import { cursorStyles } from '../../../Constants/CommonConstants';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const { ONE, ZERO } = enums.NUMERIC_VALUE;
class MlsCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: ''
    }
  }
  componentDidMount() {
    this.props.getMlsCategory();
  }

  componentDidUpdate(prevProps) {
    const { mlsCategory } = this.props;
    if (prevProps.mlsCategory !== mlsCategory && mlsCategory.length) {
      this.setState({ selectedCategory: mlsCategory[ZERO] })
    }
  }
  handleOnChange = (selectedCategory) => this.setState({ selectedCategory })

  handleContinue = () => {
    const { selectedCategory } = this.state;
    if (this.props.match?.params?.listingId) {
      const reqObj = {
        listingId: this.props.match.params.listingId,
        mlsCategoryId: selectedCategory.value
      }
      this.props.listingMlsCategory(reqObj, (url) => {
        this.props.history.replace({
          pathname: url,
        });
      });
    }
  }
  render() {
    const { mlsCategory } = this.props;
    const { selectedCategory } = this.state;
    return (
      <React.Fragment>
        <Navbar />
        <MidBoxWrapper>
          <MidBox>
            <div className="mls-category-wrapper">
              <HeadTextWrap>
                <Heading>MLS Category</Heading>
              </HeadTextWrap>
              <div className='for-custom-select bottom-gap'>
                <label>Please Select Mls category</label>
                <Select
                  styles={cursorStyles}
                  name='mlsId'
                  placeholder='Select Mls Category'
                  options={mlsCategory}
                  value={selectedCategory}
                  className='admin-react-select'
                  onChange={this.handleOnChange}
                />
                <p className="note">Note: Once MLS category is selected then you can't change MLS category in future.</p>
              </div>
              <Button
                type='submit'
                className='btn btn-primary admin-primary-btn'
                onClick={this.handleContinue}
                block
              >
                Continue
            </Button>
            </div>
          </MidBox>
        </MidBoxWrapper>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeState: entityArray[ONE],
    mlsCategory: state.onboarding.mlsCategory || [],
    isFetching: state.onboarding.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...onboardingActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(MlsCategory));
