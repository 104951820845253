﻿import React from "react";
import styled from "styled-components";
import { Grid, Cell } from "styled-css-grid";
import CurrencyFormat from "react-currency-format";
import {
  Container,
  HeadTextWrap,
  Heading,
  HeadText,
  CustomButton,
  ModalDiv
} from "../../../Assets/Style/commonStyleComponents";
import Check from "../../../Assets/Svg/Check";
import { history } from "../../../Utilities/History";
import Routes from "../../../Constants/Routes";
import { defaultPlanAddress } from "../../../Constants/CommonConstants";
import { onboardingService } from "../../../Services/OnboardingService";
import ReactTooltip from "react-tooltip";
import enums from "../../../Constants/Enums";

const descriptionLimit = enums.NUMERIC_VALUE.EIGHTY;
const descriptionPriceLimit =  enums.NUMERIC_VALUE.EIGHTY;
const PlanWrapper = styled.div`
  padding: 0;
`;
const PlanBoxWrapper = styled.article`
  display: flex;
  margin: 0 -15px;
  justify-content: center;
  @media (max-width: 992px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const PlanBox = styled.div`
  width: 25%;
  margin: 0 15px;
  background-color: white;
  border-radius: 6px;
  position: relative;
  min-height: 420px;
  border: 1px solid #dadada;
  @media (max-width: 992px) {
    width: 49%;
    margin: 20px 0 0 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    min-height: auto;
  }
  .small {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin: 0 auto;
    max-width: 100px;
    @media (max-width: 768px) {
      position: static;
      margin-bottom: 25px;
    }
  }
`;

const PlanHeader = styled.header`
  text-align: center
  padding: 20px 5px;
  background-color: #DBE8E0;
  height: 200px;
  color: white;
  border-radius: 4px 4px 0 0;
  &.best-plan {
    background-color: #B698D9;
    h1, h2, p {
      color: white !important;
    }
  }
  @media(max-width: 768px) {
    height: auto;
  }
`;

const PlanName = styled.h1`
  font-size: 20px;
  color: ${({theme}) => theme.dark};
  margin-bottom: 0;
`;

const Price = styled.h2`
  font-size: 34px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  color: ${({theme}) => theme.dark};
  margin: 10px 0;
`;

const PriceDescription = styled.div`
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0;
  color:#242B26;
`;

const PlanDescription = styled.div`
  font-size: 16px;
  padding: 20px;
  text-align: center;
  line-height: 22px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
  strong {
    margin-top: 10px;
    display: block;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      background-color:#242B26;
      height: 2px;
      width: 30px;
      @media (max-width: 1230px) {
        display: none;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 10px;
      background-color:#242B26;
      height: 2px;
      width: 30px;
      @media (max-width: 1230px) {
        display: none;
      }
    }
  }
`;

const DetailedLink = styled.a`
  font-size: 16px;
  display: block;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  color: ${({theme}) => theme.dark};
  margin-top: 8px;
  &:hover {
    color: #b01f24;
  }
`;

const Feature = styled(Cell)`
  flex: 1;
  padding: 10px 20px 10px 0;
  border-bottom: 1px solid #f0f0f0;
`;

const Detail = styled(Cell)`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
  text-align: center;
`;

const HeaderCell = styled(Detail)`
  border: none;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
`;

const StyledCheck = styled(Check)`
  width: 16px;
  fill: ${({ theme }) => theme.secondary};
`;

const AdditionalText = styled.p`
  background-color: transparent;
  color:#242B26 !important;
  display: inline-block;
  font-size: 12px;
  padding: 4px 5px;
  margin-top: 5px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
`;
const PlansBottomText = styled.p`
  margin-top: 20px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  color:#242B26;
  line-height: 28px;
  text-align: center;
  margin-bottom: 0;
`;

export default class PlanSelector extends React.Component {
  state = {
    showPlanDetails: false,
  };

  handleTogglePlanDetailsClick = (e) => {
    this.props.handlePlanCompare && this.props.handlePlanCompare();
    this.setState({ showPlanDetails: !this.state.showPlanDetails });
    e.preventDefault();
  };

  handlePlanSelected = (e, plan) => {
    this.props.handlePlanSelected(plan);
    e.preventDefault();
  };

  cutString=(string,limit)=>{
  if(string && string.length>enums.NUMERIC_VALUE.EIGHTY){
      let trimmedString = string.substr(0, limit);
      trimmedString = trimmedString.substr(0, (Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")) || Math.min(trimmedString.length, trimmedString.lastIndexOf("."))));
      return trimmedString;
    } return string;
  }

  render() {
    const { isShowZipcode } = this.props;
    const searchedZipcode =
      onboardingService.getLocal("selectedAddress")?.zip ||
      defaultPlanAddress.zip;
    let extraClass = "";
    if (this.props.plans.length === 1) {
      extraClass = "compare-1-features";
    } else if (this.props.plans.length === 2) {
      extraClass = "compare-2-features";
    } else if (this.props.plans.length === 3) {
      extraClass = "compare-3-features";
    } else {
      extraClass = "compare-4-features";
    }

    return (
      <React.Fragment>
        <PlanWrapper>
          <Container>
            <HeadTextWrap className="for-plan">
              <Heading>Our Plans & Pricing</Heading>
              {isShowZipcode && (
                <HeadText className="plan-second-text">
                  Plans available for the {searchedZipcode} zip code.
                  <a
                    onClick={() => {
                      history.push(Routes.ONBOARDING_V2);
                    }}
                  >
                    Search for your zipcode
                  </a>
                </HeadText>
              )}
              <HeadText>
                Which plan works best for you? Scale up or down based on the
                features that work best for you.
              </HeadText>
            </HeadTextWrap>
            <PlanBoxWrapper>
              {this.props.plans.map((plan) => (
                <PlanBox isFeatured={plan.productId === 4} key={plan.productId}>
                  <PlanHeader
                    className={plan.title == 'Deluxe Plan' ? "best-plan" : ""}
                  >
                    <PlanName>{plan.title}</PlanName>
                    <Price>
                      <CurrencyFormat
                        value={plan.priceUpFront}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </Price>
                    <PriceDescription>
                      
                    <span dangerouslySetInnerHTML={{ __html:this.cutString(plan.priceDescription,descriptionPriceLimit) }} />
                    {plan.priceDescription && plan.priceDescription.length > descriptionLimit && (
                    <>
                    <span className="btn-ellipsis" data-tip data-for={plan.productId}>...</span>
                    <ReactTooltip className="tooltip" multiline={true} id={""+plan.productId+""}><span dangerouslySetInnerHTML={{ __html: plan.priceDescription }} /></ReactTooltip>
                   </> )}
                    </PriceDescription>
                    </PlanHeader>
                  <PlanDescription>
                    <span dangerouslySetInnerHTML={{ __html: this.cutString(plan.productDescription,descriptionLimit)}} />
                    {plan.productDescription && plan.productDescription.length > descriptionLimit && (
                    <>
                    <span className="btn-ellipsis" data-tip data-for={"pd"+plan.productId+""}>...</span>
                    <ReactTooltip className="tooltip" multiline={true} id={"pd"+plan.productId+""}><span dangerouslySetInnerHTML={{ __html: plan.productDescription }} /></ReactTooltip>
                   </> )}
                    <DetailedLink
                      href="#"
                      onClick={this.handleTogglePlanDetailsClick}>
                        Compare plans
                    </DetailedLink>
                    </PlanDescription>
                 <CustomButton
                    className="small"
                     onClick={(e) => this.handlePlanSelected(e, plan) } 
                  >
                    Select
                  </CustomButton>
                </PlanBox>
              ))}
            </PlanBoxWrapper>
            <PlansBottomText>
              *Select between 1 – 3% commission, a range typical to offer to
              buyer’s agents. Some states require the listing broker to perform
              services beyond
              <br />
              listing the property in the MLS and an administrative services fee
              is subtracted from the total commission offered by you equal to
              0.5% of the sale
              <br />
              price of your property. For more information regarding minimum
              service fees see “CHOOSE YOUR COMMISSION RATE“.
            </PlansBottomText>
          </Container>
        </PlanWrapper>
        {this.state.showPlanDetails && (
          <ModalDiv
            title="Compare Features"
            cancelButtonProps={{ style: { display: "none" } }}
            visible={this.state.showPlanDetails}
            onCancel={() => {
              this.setState({ showPlanDetails: false });
            }}
            destroyOnClose={true}
            className={"text-modal plan-selector-modal " + extraClass}
            footer={false}
          >
            <Grid gap="0" columns="1fr auto auto auto auto">
              <HeaderCell></HeaderCell>
              {this.props.plans.map((p) => (
                <HeaderCell key={p.productId}>{p.title}</HeaderCell>
              ))}
              {this.props.planFeatures.map((pf, row) => (
                <React.Fragment key={row}>
                  <Feature className="tooltip-for-title-description">
                    <span className="title">{pf.title}</span>
                    <span className="feature-info" data-tip data-for={row}>
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
                    <ReactTooltip className="tooltip" multiline={true} id={"" + row + ""}>
                      <span dangerouslySetInnerHTML={{ __html: pf.description }} />
                    </ReactTooltip>
                  </Feature>
                  <React.Fragment>
                    {this.props.plans.map((p, col) => (
                      <Detail key={col}>
                        {pf.type.toLowerCase() === "boolean" ? (
                          pf?.values[p.productId]?.toLowerCase() === "true" ? (
                            <StyledCheck />
                          ) : (
                            ""
                          )
                        ) : (
                          pf.values[p.productId]
                        )}
                      </Detail>
                    ))}
                  </React.Fragment>
                </React.Fragment>
              ))}
            </Grid>
          </ModalDiv>
        )}
      </React.Fragment>
    );
  }
}