

import React from 'react'

export const InputClear = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.99984" cy="8.00033" r="7.33333" fill="#242B26"/>
    <path d="M7.8564 7.87673C8.89274 6.84527 9.92391 5.82503 10.9602 4.79356C11.1165 4.63898 11.1801
      4.75936 11.165 4.8477C11.1657 4.95703 11.0883 5.14365 10.932 5.29823L9.79149 6.42668C9.64796 6.56868
      8.32251 7.94575 8.14902 8.11741C7.11794 9.13755 6.08142 10.1692 5.04517 11.2006L4.85159 11.2515C4.79333
      11.1564 4.84421 10.9166 5.06294 10.7001L6.20348 9.57169C6.61399 9.16552 7.67286 8.05941 7.8564 7.87673Z"
      fill="white" stroke="white" strokeWidth="0.4"
    />
    <path d="M7.87685 8.14345C6.84534 7.10716 5.82506 6.07604 4.79355 5.03974C4.63897 4.88351 4.75935 4.81985 4.8477
      4.83501C4.95703 4.83431 5.14366 4.91173 5.29825 5.06796L6.42674 6.20845C6.56875 6.35197 7.94588 7.67737 8.11754 
      7.85085C9.13772 8.88188 10.1694 9.91837 11.2008 10.9546L11.2518 11.1481C11.1566 11.2064 10.9168 11.1555 10.7004 
      10.9368L9.57188 9.79631C9.1657 9.38581 8.05954 8.32699 7.87685 8.14345Z" fill="white" stroke="white" strokeWidth="0.4"
    />
  </svg>
);

