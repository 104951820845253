import React from 'react';
import LandingToolTip from '../../Components/LandingListing/LandingToolTip';
import { editPageAction } from '../../../../Utilities/commonFunctions';
import { entityTypes } from '../../../../Constants/CommonConstants';
import enums from '../../../../Constants/Enums';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
const { ZERO, ONE, TWO, THREE, FOUR, FIVE, SIX, SEVEN } = enums.NUMERIC_VALUE;
class LandingListingTable extends React.Component {
  getMLSCount = (mlsCategories, index) =>
    mlsCategories[index]?.formSections?.length || ZERO;
  getFormList = (mlsCategories, index) =>
    mlsCategories[index]?.formSections || [];
  handlePreviewClick(mlsCategories, formTypeId, mlsId) {
    const categoryList = mlsCategories.reduce((acc, c) => {
      if (c.formSections?.length) {
        acc.push({
          key: c.mlsCategoryId,
          label: c.categoryName,
        })
      }

      return acc;
    }, [])

    if (categoryList?.length) {
      this.props.onClickPreview({ categoryList, formTypeId, mlsId });
    }
  }

  someHandlerChange = (tdIndex, trindex, mlsIndex, mlsCount) => {
    this.props.someHandler(tdIndex, trindex, mlsIndex, mlsCount);
  }
  checkIsPreviewPossible = (mlsCategories) => {
    return !mlsCategories.reduce((acc, c) => {
      if (c.formSections?.length) {
        acc.push({
          key: c.mlsCategoryId,
          label: c.categoryName,
        })
      }

      return acc;
    }, []).length;  
  }

  render() {
    const { formName, mlsForms, formTypeId, mlsindex } = this.props;
    return (
      <>
        <div className='landing-custom-table-box'>
          <h3 className='landing-heading'>{formName}</h3>
          <div className='landing-custom-table'>
            <table>
              <thead>
                <tr>
                  <th>MLS</th>
                  <th>Preview</th>
                  <th>Residential Single Family Sale</th>
                  <th>Condominium or Home Owners Association Sale</th>
                  <th>Multifamily Sale</th>
                  <th>Commercial Sale</th>
                  <th>Residential for Lease</th>
                  <th>Commercial Lease</th>
                  <th>Vacant Land</th>
                </tr>
              </thead>
                {mlsForms.map((mls, index) => {
                  const { mlsName = '', mlsId, mlsCategories = [] } = mls;
                  return (
                    <tbody key={`${index}_${mlsId}`}>
                      <tr className="empty-row">
                        <td>
                          <LandingToolTip formSections={this.getFormList(mlsCategories, ZERO)} showDiv={this.props.showDiv} showId={this.props.showId} tdIndex={index} trindex={this.props.trindex} tdStep={ONE} someHandlerLeave={this.props.someHandlerLeave} mlsindex={this.props.mlsindex} />
                        </td>
                        <td>
                          <LandingToolTip formSections={this.getFormList(mlsCategories, ONE)} showDiv={this.props.showDiv} showId={this.props.showId} tdIndex={index} trindex={this.props.trindex} tdStep={TWO} someHandlerLeave={this.props.someHandlerLeave} mlsindex={this.props.mlsindex} />
                        </td>
                        <td>
                          <LandingToolTip formSections={this.getFormList(mlsCategories, TWO)} showDiv={this.props.showDiv} showId={this.props.showId} tdIndex={index} trindex={this.props.trindex} tdStep={THREE} someHandlerLeave={this.props.someHandlerLeave}  mlsindex={this.props.mlsindex} />
                        </td>
                        <td>
                          <LandingToolTip formSections={this.getFormList(mlsCategories, THREE)} showDiv={this.props.showDiv} showId={this.props.showId} tdIndex={index} trindex={this.props.trindex} tdStep={FOUR} someHandlerLeave={this.props.someHandlerLeave} mlsindex={this.props.mlsindex} />
                        </td>
                        <td>
                          <LandingToolTip formSections={this.getFormList(mlsCategories, FOUR)} showDiv={this.props.showDiv} showId={this.props.showId} tdIndex={index} trindex={this.props.trindex} tdStep={FIVE} someHandlerLeave={this.props.someHandlerLeave} mlsindex={this.props.mlsindex} />
                        </td>
                        <td>
                          <LandingToolTip formSections={this.getFormList(mlsCategories, FIVE)} showDiv={this.props.showDiv} showId={this.props.showId} tdIndex={index} trindex={this.props.trindex} tdStep={SIX} someHandlerLeave={this.props.someHandlerLeave} mlsindex={this.props.mlsindex} />
                        </td>
                        <td>
                          <LandingToolTip formSections={this.getFormList(mlsCategories, SIX)} showDiv={this.props.showDiv} showId={this.props.showId} tdIndex={index} trindex={this.props.trindex} tdStep={SEVEN} someHandlerLeave={this.props.someHandlerLeave} mlsindex={this.props.mlsindex} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Link
                            to={mlsId ? editPageAction({ mlsId }, entityTypes.abbreviation) : ''}
                          >
                            {mlsName}
                          </Link>
                        </td>
                        <td>
                          <button disabled={this.checkIsPreviewPossible(mlsCategories)} className='landing-preview-btn' onClick={() => this.handlePreviewClick(mlsCategories, formTypeId, mlsId,)}>Preview</button>
                        </td>
                        <td onClick={() => this.someHandlerChange(index, ONE, mlsindex, this.getMLSCount(mlsCategories, ZERO))} className="likes__wrapper">
                        <span id="happyFace" data-tip="Click to view details">{this.getMLSCount(mlsCategories, ZERO)}</span>
                          <ReactTooltip />
                        </td>

                        <td onClick={() => this.someHandlerChange(index, TWO, mlsindex, this.getMLSCount(mlsCategories, ONE))}>
                        <span id="happyFace" data-tip="Click to view details">{this.getMLSCount(mlsCategories, ONE)}</span>
                        </td>

                        <td onClick={() => this.someHandlerChange(index, THREE, mlsindex, this.getMLSCount(mlsCategories, TWO))}>
                        <span id="happyFace" data-tip="Click to view details">{this.getMLSCount(mlsCategories, TWO)}</span>
                        </td>

                        <td onClick={() => this.someHandlerChange(index, FOUR, mlsindex, this.getMLSCount(mlsCategories, THREE))}>
                        <span id="happyFace" data-tip="Click to view details">{this.getMLSCount(mlsCategories, THREE)}</span>
                        </td>

                        <td onClick={() => this.someHandlerChange(index, FIVE, mlsindex, this.getMLSCount(mlsCategories, FOUR))}>
                        <span id="happyFace" data-tip="Click to view details">{this.getMLSCount(mlsCategories, FOUR)}</span>
                        </td>

                        <td onClick={() => this.someHandlerChange(index, SIX, mlsindex, this.getMLSCount(mlsCategories, FIVE))}>
                        <span id="happyFace" data-tip="Click to view details">{this.getMLSCount(mlsCategories, FIVE)}</span>
                        </td>

                        <td onClick={() => this.someHandlerChange(index, SEVEN, mlsindex, this.getMLSCount(mlsCategories, SIX))} >
                        <span id="happyFace" data-tip="Click to view details">{this.getMLSCount(mlsCategories, SIX)}</span>
                        </td>

                      </tr>
                    </tbody>
                  );
                })}
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default LandingListingTable;
