import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import v8n from 'v8n';
import { Button } from 'antd';
import { RegionDropdown } from 'react-country-region-selector';
import { adminActionCreators } from '../../../Actions/AdminActions';
import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';

import {
  RowFlex,
  InputFlex,
  CustomTextarea,
} from '../../../Assets/Style/commonStyleComponents';
import { entityTypes, phoneMask, validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import { goBack, getFocusInput, deleteModalPopup, checkIsValidNumber, addHttpInUrl } from '../../../Utilities/commonFunctions';
import { abbreviationTableConfigs } from '../../../Constants/TableConstants';
import TextareaAutosize from 'react-textarea-autosize';
import { CrossedEyeIcon } from '../../Shared/Components/Icons/CrossedEyeIcon';
import { EyeIcon } from '../../Shared/Components/Icons/EyeIcon';

const EyeButtonWraper = styled.div`
  right: 0px;
  width: 4rem;
  height: 2.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1.75rem;
  z-index: 2;
`;

const EyeButton = styled.button`
display: inline;
appearance: none;
align-items: center;
justify-content: center;
user-select: none;
position: relative;
white-space: nowrap;
vertical-align: middle;
outline: transparent solid 2px;
outline-offset: 2px;
width: auto;
line-height: inherit;
border-radius: 0.375rem;
font-weight: 700;
font-family: 'National-Semi-Bold';
height: 1.75rem;
min-width: 2rem;
font-size: 0.875rem;
padding-inline-start: 0.75rem;
padding-inline-end: 0.75rem;
background: none;
color: #000000;
margin: 0px;
padding: 0px;
`;

const PasswordShowButton = styled(EyeIcon)`
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: black;
    vertical-align: middle;
`;

const PasswordHideButton = styled(CrossedEyeIcon)`
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: black;
    vertical-align: middle;
`;

const PasswordContainer = styled.div`
  position: relative;
  flex: 0.5;
`;

const cursorWithMenuIndex = {
  option: (styles) => {
    return {
      ...styles,
      cursor: "pointer"
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 99
    };
  },
};

class AddEditAbbreviation extends React.Component {
  constructor(props) {
    super(props);
    this.isNewAbbreviation = props.isNewAbbreviation;
    this.state = {
      isPasswordHidden: true,
      isSendMail: props.isNewAbbreviation,
      isAbbreviationEnabled: true,
      abbreviationDetail: {
        name: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.name,
            },
          ],
          value: props.abbreviationData?.name || '',
        },
        abbreviation: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.abbreviation,
            },
          ],
          value: props?.abbreviationData?.abbreviation || '',
        },
        price: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Price is required',
            },
            {
              rule: v8n().greaterThan(0),
              message: "Price can't be 0",
            },
          ],
          value: props?.abbreviationData?.price || 0,
        },
        closingFee: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Closing Fee is required',
            }
          ],
          value: props?.abbreviationData?.closingFee || 0,
        },
        restrictions: {
          rules: [],
          value: props?.abbreviationData?.restrictions || [],
        },
        contactPhone: {
          rules: [],
          mask: phoneMask,
          value: props?.abbreviationData?.contactPhone || '',
        },
        description: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.abbreviationData?.description || '',
        },
        managingBrokerId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a managing broker.',
            },
          ],
          value: props?.abbreviationData?.managingBrokerId || '',
        },
        companyId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a company.',
            },
          ],
          value: props?.abbreviationData?.companyId || '',
        },
        softwareId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select a software.',
            },
          ],
          value: props?.abbreviationData?.softwareId || '',
        },
        state: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: 'Please select your state.',
            },
          ],
          value: this.props.abbreviationData.state || '',
        },
        loginUrl: {
          rules: [],
          value: this.props.abbreviationData.loginUrl || '',
        },
        descriptionMaxLength: {
          rules: [],
          value: this.props.abbreviationData.descriptionMaxLength || '',
        },
        licenseNumber: {
          rules: [],
          value: this.props.abbreviationData.licenseNumber || '',
        },
        firmLicense: {
          rules: [],
          value: this.props.abbreviationData.firmLicense || '',
        },
        username: {
          rules: [],
          value: this.props.abbreviationData.username || '',
        },
        photosMax: {
          rules: [],
          value: this.props.abbreviationData.photosMax || '',
        },
        password: {
          rules: [],
          value: this.props.abbreviationData.password || '',
        },
        loggedInUserId: {
          rules: [],
          value: props.abbreviationData?.loggedInUserId || '',
        },
      },
      abbreviationEditValue: true
    };
  }

  handleFieldChanged = (e, event) => {
    let abbreviationDetail = { ...this.state.abbreviationDetail };
    abbreviationDetail[e.target.name].value = e.target.value;
    this.setState({ abbreviationDetail: { ...abbreviationDetail } });
  };

  handleFieldBlur = e => {
    this.validateFormField(e.target.name, e.target.value);
  };

  handleMultiSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.abbreviationDetail };
    updatedValues[type].value = selectedOption.map(x => x.value);
    this.setState({ abbreviationDetail: { ...updatedValues } });
  };

  validateFormField = (key, value) => {
    let abbreviationDetail = { ...this.state.abbreviationDetail };
    let isError = false;
    for (let i = 0; i < abbreviationDetail[key].rules.length; i++) {
      let rule = abbreviationDetail[key].rules[i];
      if (!rule.rule.test(value)) {
        abbreviationDetail[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (abbreviationDetail['descriptionMaxLength'].value && !checkIsValidNumber(abbreviationDetail['descriptionMaxLength'].value)) {
      abbreviationDetail['descriptionMaxLength'].error = validationMessages.numericValueRequired;
      isError = true;
    }

    if (abbreviationDetail['photosMax'].value && !checkIsValidNumber(abbreviationDetail['photosMax'].value)) {
      abbreviationDetail['photosMax'].error = validationMessages.numericValueRequired;
      isError = true;
    }

    if (!isError) {
      abbreviationDetail[key].error = null;
    }

    this.setState({
      abbreviationDetail: { ...abbreviationDetail },
      isError: Object.keys(abbreviationDetail).some(
        k =>
          abbreviationDetail[k].error !== undefined &&
          abbreviationDetail[k].error !== null,
      ),
    });

    return isError;
  };

  handleAddUpdateAbbreviationDetails = accountDetails => {
    const { isAbbreviationEnabled } = this.state;

    if (!this.isNewAbbreviation) {
      accountDetails['mlsId'] = this.props.abbreviationData.mlsId;
    }
    const mlsId = this.props.isNewAbbreviation
      ? Enums.NUMERIC_VALUE.ZERO
      : this.props.abbreviationData.mlsId;

    accountDetails['isEnabled'] = isAbbreviationEnabled;
    accountDetails['price'] = +accountDetails['price'];
    accountDetails['companyId'] = +accountDetails['companyId'];
    accountDetails['id'] = mlsId;
    accountDetails['closingFee'] = +accountDetails['closingFee'] || 0;
    accountDetails['softwareId'] = Number(accountDetails['softwareId']);

    this.props.addUpdateAbbreviation(mlsId, accountDetails, () => {
      goBack();
    });
  };

  validateAndUpdateAbbreviationDetails = e => {
    e.preventDefault();

    let isError = false;
    let isPasswordError = false;

    Object.keys(this.state.abbreviationDetail).forEach(keyName => {
      const isErrorExist = this.validateFormField(
        keyName,
        this.state.abbreviationDetail[keyName].value,
      );
      if (isErrorExist) {
        isError = true;
      }
    });
    if (!isError && !isPasswordError) {
      this.handleAddUpdateAbbreviationDetails({
        ...Object.keys(this.state.abbreviationDetail).reduce((retVal, key) => {
          if (this.state.abbreviationDetail[key].value) {
            retVal[key] = this.state.abbreviationDetail[key].value;
          }
          return retVal;
        }, {}),
      });
    }

    const { abbreviationDetail } = this.state;
    getFocusInput(abbreviationDetail);
  };

  selectStateAndCounty = val => {
    const updatedValues = { ...this.state.abbreviationDetail };
    updatedValues.state.value = val;
    updatedValues.state.error = '';
    this.setState({ abbreviationDetail: updatedValues });
  };

  //Handle Edit Disable
  handleAbbriviationEdit = (value) => {
    this.setState({ abbreviationEditValue: value });
  }

  updateLoggedInUser = e => {
    e.preventDefault();
    const { abbreviationDetail } = this.state;
    const { activeEntity, abbreviationData } = this.props;
    const param = {
      mlsId: abbreviationData.mlsId,
      userId: abbreviationDetail.loggedInUserId.value
    }
    this.props.updateLoggedInUser(activeEntity, param);
  }
  render() {
    const { lookup, abbreviationData } = this.props;
    const { abbreviationDetail, abbreviationEditValue, isPasswordHidden } = this.state;

    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>
              {this.isNewAbbreviation
                ? 'Add Mls Info'
                : 'Edit Mls Info'}
            </h2>
            <div className="admin-heading-wrapper-right">
              {!this.isNewAbbreviation &&
                <>
                  <button
                    className="ant-btn btn btn-primary admin-primary-btn"
                    id="edit-mode"
                    onClick={() => this.handleAbbriviationEdit(!abbreviationEditValue)}>
                    {abbreviationEditValue ? 'Edit' : "View Only"}
                  </button>
                </>
              }
            </div>
          </div>
          <div className={`admin-add-form-wrapper admin-add-edit-abbrivation ${(!this.isNewAbbreviation && abbreviationEditValue) ? 'disabled-input-form' : ''}`}>
            <form autoComplete='off'>
              {!this.isNewAbbreviation &&
                <RowFlex>
                  <InputFlex
                    options={lookup?.loggedInUsers}
                    name='loggedInUserId'
                    title='Logged In User'
                    type='text'
                    value={abbreviationDetail?.loggedInUserId.value}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                    placeholder='Select Logged In User'
                    className='inputgap'
                    isKeyPaired={true}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      className="ant-btn btn btn-primary admin-primary-btn"
                      disabled={!abbreviationDetail?.loggedInUserId.value || abbreviationEditValue}
                      onClick={this.updateLoggedInUser}
                    >
                      Update Logged In User
                    </button>
                  </div>
                </RowFlex>
              }
              <InputFlex
                name='name'
                title='Name'
                className='inputgap'
                value={abbreviationDetail?.name?.value}
                error={
                  abbreviationDetail?.name?.error ||
                  (this.props?.errors?.name || [])[0]
                }
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <RowFlex>
                <InputFlex
                  options={lookup?.companies}
                  name='companyId'
                  title='Company'
                  type='text'
                  value={abbreviationDetail?.companyId.value}
                  error={abbreviationDetail?.companyId?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                  placeholder='Select Company'
                  className='inputgap'
                  isKeyPaired={true}
                />
                <InputFlex
                  name='abbreviation'
                  title='Abbreviation'
                  className='inputgap'
                  value={abbreviationDetail?.abbreviation?.value}
                  error={
                    abbreviationDetail?.abbreviation?.error ||
                    (this.props?.errors?.abbreviation || [])[0]
                  }
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <RowFlex>
                <InputFlex
                  options={lookup?.softwares}
                  name='softwareId'
                  title='Software'
                  type='text'
                  value={abbreviationDetail?.softwareId.value}
                  error={abbreviationDetail?.softwareId?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                  placeholder='Select Software'
                  className='inputgap'
                  isKeyPaired={true}
                />
                <InputFlex
                  options={lookup?.managingBrokers}
                  name='managingBrokerId'
                  title='Managing Broker'
                  type='text'
                  value={abbreviationDetail?.managingBrokerId?.value}
                  error={abbreviationDetail?.managingBrokerId?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                  placeholder='Select Managing Broker'
                  className='inputgap'
                  isKeyPaired={true}
                />
              </RowFlex>
              <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-50 for-custom-select'>
                  <label>State</label>
                  <RegionDropdown
                    blankOptionLabel='No country selected.'
                    defaultOptionLabel='Select State'
                    countryValueType='short'
                    valueType='short'
                    country='US'
                    value={
                      (abbreviationDetail?.state?.value ?? '')
                    }
                    onChange={this.selectStateAndCounty}
                    error={abbreviationDetail?.state?.error}
                    className={
                      abbreviationDetail?.state?.error
                        ? 'custom-select state-select error-input'
                        : 'custom-select'
                    }
                  />
                  {abbreviationDetail?.state?.error && (
                    <span className='select-text-red'>
                      {validationMessages.required}
                    </span>
                  )}
                </div>
                <div className='admin-w-50 for-custom-select'>
                  <InputFlex
                    name='contactPhone'
                    title='Contact Number'
                    className='inputgap'
                    value={abbreviationDetail?.contactPhone?.value}
                    mask={abbreviationDetail?.contactPhone?.mask}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                </div>
              </div>
              <RowFlex>
                <InputFlex
                  name='price'
                  title='Price'
                  className='inputgap'
                  type='number'
                  placeholder='Enter Price'
                  value={abbreviationDetail?.price?.value}
                  error={abbreviationDetail?.price?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}/>
                <InputFlex
                  name='closingFee'
                  title='Closing Fee'
                  className='inputgap'
                  type='number'
                  placeholder='Enter Closing Fee'
                  value={abbreviationDetail?.closingFee?.value}
                  error={abbreviationDetail?.closingFee?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Restrictions</label>
                  <Select
                    styles={cursorWithMenuIndex}
                    name='restrictions'
                    placeholder='Select Restrictions'
                    options={lookup?.restrictions}
                    value={lookup?.restrictions
                      ?.filter(x => abbreviationDetail?.restrictions.value.includes(x.value)) || []}
                    onChange={e => this.handleMultiSelectChange(e, 'restrictions')}
                    className='admin-react-select'
                    isMulti
                    maxMenuHeight={100}
                  />
                </div>
              </RowFlex>
              <InputFlex
                name='descriptionMaxLength'
                title='Description Length'
                className='inputgap'
                type='text'
                value={abbreviationDetail?.descriptionMaxLength?.value}
                error={abbreviationDetail?.descriptionMaxLength?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <RowFlex>
                <InputFlex
                  name='licenseNumber'
                  title='License Number'
                  className='inputgap'
                  type='number'
                  value={abbreviationDetail?.licenseNumber?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  name='firmLicense'
                  title='Firm License'
                  className='inputgap'
                  type='text'
                  value={abbreviationDetail?.firmLicense?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <RowFlex>
                <InputFlex
                  name='username'
                  title='username'
                  className='inputgap'
                  type='text'
                  value={abbreviationDetail?.username?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <PasswordContainer className='inputgap'>
                  <InputFlex
                    name='password'
                    title='Password'
                    type={isPasswordHidden ? 'password' : 'text'}
                    value={abbreviationDetail?.password?.value}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                  <EyeButtonWraper>
                    <EyeButton
                      type='button'
                      onClick={() => {
                        this.setState({ isPasswordHidden: !isPasswordHidden })
                      }}
                    >
                      {isPasswordHidden ? (
                        <PasswordShowButton />
                      ) : (
                        <PasswordHideButton />
                      )}
                    </EyeButton>
                  </EyeButtonWraper>
                </PasswordContainer>
              </RowFlex>
              <div className='admin-input-and-data-wrapper'>
                <div className='admin-w-50 for-custom-select'>
                  <label style={{ marginBottom: "0px" }}>Login Url
                    <a className="for-truncate-text" title={abbreviationDetail?.loginUrl?.value} style={{ color: "#B698D9", float: "right", maxWidth: "50%", textTransform: 'none' }}
                      href={addHttpInUrl(abbreviationDetail?.loginUrl?.value)} target="_blank">{abbreviationDetail?.loginUrl?.value}</a>
                  </label>
                  <InputFlex
                    name='loginUrl'
                    className='inputgap'
                    type='text'
                    value={abbreviationDetail?.loginUrl?.value}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                </div>
                <div className='admin-w-50 for-custom-select'>
                  <InputFlex
                    name='photosMax'
                    title='Photo Max Number'
                    className='inputgap'
                    type='text'
                    error={abbreviationDetail?.photosMax?.error}
                    value={abbreviationDetail?.photosMax?.value}
                    onChange={this.handleFieldChanged}
                    onBlur={this.handleFieldBlur}
                  />
                </div>
              </div>
              <CustomTextarea className='full-width-textarea'>
                <label>Description</label>
                <TextareaAutosize
                  name='description'
                  type='textarea'
                  value={abbreviationDetail.description?.value}
                  error={abbreviationDetail.description?.error}
                  className={`expandable-textarea ${abbreviationDetail.description?.error ? 'item-text-red' : ''
                    }`}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                {abbreviationDetail?.description?.error && (
                  <span className='textarea-error'>
                    {validationMessages.required}
                  </span>
                )}
              </CustomTextarea>
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    onClick={this.validateAndUpdateAbbreviationDetails}
                    disabled={!this.isNewAbbreviation && abbreviationEditValue}
                  >
                    {this.isNewAbbreviation ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditAbbreviation);
