import { guid } from '../Utilities/Strings';
import mime from 'mime-types';
import ApiUrls from '../Constants/ApiUrls';
import { contentType, apiMethodType } from '../Constants/CommonConstants';
import { authHeader } from '../Utilities/Headers';
import fetch from '../Utilities/FetchWithTimeout';

class FileService {
    async uploadPhoto(fileBlob, thumbBlob) {
        const fileExtension = fileBlob.name.split('.').pop();
        const folderId = guid();
        const key = `${folderId}/${fileBlob.name}`.replaceAll('#', '-');
        const thumbKey = `${folderId}/${fileBlob.name.replaceAll('#', '-').replace(`.${fileExtension}`, `_thumb.${fileExtension}`)}`;

        const fileBase64 = await this.blobToBase64(fileBlob);
        const thumbBase64 = await this.blobToBase64(thumbBlob);

        const { data: imageLink } = await this.uploadImage(fileBase64, key);
        const { data: thumbLink } = await this.uploadImage(thumbBase64, thumbKey);

        return { key, thumbKey, imageLink, thumbLink, };
    }

    async blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.replace(/.+base64,/, ''));
          reader.readAsDataURL(blob);
        });
    }

    async uploadImage(fileContent, fileName) {
        const url = ApiUrls.fileUpload;
        return fetch(url, {
            method: apiMethodType.POST,
            data: { fileContent, fileName },
            headers: {
                'Content-Type': contentType.applicationJson,
                ...authHeader()
            }
        });
    }
}

export default FileService;
