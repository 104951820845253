import axios from 'axios';
import moment from 'moment';
import LogRocket from 'logrocket';

import { dataService } from '../Services/DataService';
import { lookupService } from '../Services/LookupService';
import { apiService } from '../Services/ApiService';
import ListingPhotoService from '../Services/ListingPhotoService';
import { adminService } from '../Services/AdminService';
import { mlsService } from '../Services/V2/MlsService';
import { startLoading, endLoading } from './LoaderActions';
import { alertActions } from './ToastActions';
import {
  staticMessages,
  entityTypes,
  formLayoutType,
} from '../Constants/CommonConstants';
import Enums from '../Constants/Enums';
import {
  checkResponse,
  getLocalUserData,
  redirectOnDashboard,
  redirectOnUserEditForm,
} from '../Utilities/commonFunctions';
import { userService } from '../Services/UserService';
import FileService from '../Services/FileService';
import Routes from '../Constants/Routes';

export const actions = {
  GETALL_REQUESTED: 'GETALL_REQUESTED',
  GETALL_RESOLVED: 'GETALL_RESOLVED',
  GET_REQUESTED: 'GET_REQUESTED',
  GET_RESOLVED: 'GET_RESOLVED',
  UPDATE_REQUESTED: 'UPDATE_REQUESTED',
  UPDATE_RESOLVED: 'UPDATE_RESOLVED',
  DELETE_REQUESTED: 'DELETE_REQUESTED',
  DELETE_RESOLVED: 'DELETE_RESOLVED',
  CREATE_REQUESTED: 'CREATE_REQUESTED',
  CREATE_RESOLVED: 'CREATE_RESOLVED',
  LOOKUP_REQUESTED: 'LOOKUP_REQUESTED',
  LOOKUP_RESOLVED: 'LOOKUP_RESOLVED',
  API_REQUESTED: 'API_REQUESTED',
  API_RESOLVED: 'API_RESOLVED',
  GET_USER_LOOK_UP_REQUESTED: 'GET_USER_LOOK_UP_REQUESTED',
  GET_USER_LOOK_UP_RESOLVED: 'GET_USER_LOOK_UP_RESOLVED',
  ADD_UPDATE_NEW_USER_REQUESTED: 'ADD_UPDATE_NEW_USER_REQUESTED',
  ADD_UPDATE_NEW_USER_RESOLVED: 'ADD_UPDATE_NEW_USER_RESOLVED',
  GET_USER_DETAIL_REQUESTED: 'GET_USER_DETAIL_REQUESTED',
  GET_USER_DETAIL_RESOLVED: 'GET_USER_DETAIL_RESOLVED',
  ADD_UPDATE_CATEGORY_REQUESTED: 'ADD_UPDATE_CATEGORY_REQUESTED',
  ADD_UPDATE_CATEGORY_RESOLVED: 'ADD_UPDATE_CATEGORY_RESOLVED',
  ADD_UPDATE_ABBREVIATION_REQUESTED: 'ADD_UPDATE_ABBREVIATION_REQUESTED',
  ADD_UPDATE_ABBREVIATION_RESOLVED: 'ADD_UPDATE_ABBREVIATION_RESOLVED',
  ADD_UPDATE_BASIC_FORM_REQUESTED: 'ADD_UPDATE_BASIC_FORM_REQUESTED',
  ADD_UPDATE_BASIC_FORM_RESOLVED: 'ADD_UPDATE_BASIC_FORM_RESOLVED',
  UPLOAD_PHOTO_RESOLVED: 'UPLOAD_PHOTO_RESOLVED',
  PHOTO_UPLOAD_PROGRESS_UPDATED: 'PHOTO_UPLOAD_PROGRESS_UPDATED',
  DELETE_PHOTOS_REQUESTED: 'DELETE_PHOTOS_REQUESTED',
  DELETE_PHOTOS_RESOLVED: 'DELETE_PHOTOS_RESOLVED',
  REMOVE_PHOTO_RESOLVED: 'REMOVE_PHOTO_RESOLVED',
  REORDER_ADMIN_PHOTOS_REQUESTED: 'REORDER_ADMIN_PHOTOS_REQUESTED',
  REORDER_ADMIN_PHOTOS_RESOLVED: 'REORDER_ADMIN_PHOTOS_RESOLVED',
  ADD_UPDATE_PROPERTY_REQUESTED: 'ADD_UPDATE_PROPERTY_REQUESTED',
  ADD_UPDATE_PROPERTY_RESOLVED: 'ADD_UPDATE_PROPERTY_RESOLVED',
  ADD_UPDATE_PROPERTY_RESET: 'ADD_UPDATE_PROPERTY_RESET',
  TOGGLE_STATUS_REQUESTED: 'TOGGLE_STATUS_REQUESTED',
  TOGGLE_STATUS_RESOLVED: 'TOGGLE_STATUS_RESOLVED',
  UPDATE_PROPERTY_STATUS_RESOLVED: 'UPDATE_PROPERTY_STATUS_RESOLVED',
  SUBMISSION_STATUS_REQUESTED: 'SUBMISSION_STATUS_REQUESTED',
  SUBMISSION_STATUS_RESOLVED: 'SUBMISSION_STATUS_RESOLVED',
  UPLOAD_FORM_PHOTO_PROGRESS: 'UPLOAD_FORM_PHOTO_PROGRESS',
  UPLOAD_FORM_PHOTO_REQUESTED: 'UPLOAD_FORM_PHOTO_REQUESTED',
  UPLOAD_FORM_PHOTO_RESOLVED: 'UPLOAD_FORM_PHOTO_RESOLVED',
  OVERLAY_FORM_REQUESTED: 'OVERLAY_FORM_REQUESTED',
  OVERLAY_FORM_RESOLVED: 'OVERLAY_FORM_RESOLVED',
  UPDATE_OVERLAY_FORM_REQUEST: 'UPDATE_OVERLAY_FORM_REQUEST',
  UPDATE_OVERLAY_FORM_RESOLVE: 'UPDATE_OVERLAY_FORM_RESOLVE',
  CLONE_REQUEST: 'CLONE_REQUEST',
  CLONE_RESOLVED: 'CLONE_RESOLVED',
  ADD_UPDATE_PRODUCT_REQUESTED: 'ADD_UPDATE_PRODUCT_REQUESTED',
  ADD_UPDATE_PRODUCT_RESOLVED: 'ADD_UPDATE_PRODUCT_RESOLVED',
  ADD_UPDATE_COMPANY_REQUESTED: 'ADD_UPDATE_COMPANY_REQUESTED',
  ADD_UPDATE_COMPANY_RESOLVED: 'ADD_UPDATE_COMPANY_RESOLVED',
  SWITCH_PORTAL_REQUESTED: 'SWITCH_PORTAL_REQUESTED',
  SWITCH_PORTAL_RESOLVED: 'SWITCH_PORTAL_RESOLVED',
  GET_AUDIT_LOG_REQUEST: 'GET_AUDIT_LOG_REQUEST',
  GET_AUDIT_LOG_RESOLVE: 'GET_AUDIT_LOG_RESOLVE',
  ADD_UPDATE_COVERAGE_RESOLVED: 'ADD_UPDATE_COVERAGE_RESOLVED',
  ADD_UPDATE_COVERAGE_REQUESTED: 'ADD_UPDATE_COVERAGE_REQUESTED',
  ADD_UPDATE_VOUCHER_TEMPLATE_REQUESTED: 'ADD_UPDATE_VOUCHER_TEMPLATE_REQUESTED',
  ADD_UPDATE_VOUCHER_TEMPLATE_RESOLVED: 'ADD_UPDATE_VOUCHER_TEMPLATE_RESOLVED',
  GET_LANDING_LIST_REQUEST: 'GET_LANDING_LIST_REQUEST',
  GET_LANDING_LIST_RESOLVED: 'GET_LANDING_LIST_RESOLVED',
  VOUCHER_TOGGLE_STATUS_REQUESTED: 'VOUCHER_TOGGLE_STATUS_REQUESTED',
  VOUCHER_TOGGLE_STATUS_RESOLVED: 'VOUCHER_TOGGLE_STATUS_RESOLVED',
  VOUCHER_RESEND_INVITE_REQUESTED: 'VOUCHER_RESEND_INVITE_REQUESTED',
  VOUCHER_RESEND_INVITE_RESOLVED: 'VOUCHER_RESEND_INVITE_RESOLVED',
  MLS_PREVIEW_REQUESTED: 'MLS_PREVIEW_REQUESTED',
  MLS_PREVIEW_RESOLVED: 'MLS_PREVIEW_RESOLVED',
  UPDATE_LOGGED_IN_USER_REQUEST: "UPDATE_LOGGED_IN_USER_REQUEST",
  UPDATE_LOGGED_IN_USER_RESOLVE: "UPDATE_LOGGED_IN_USER_RESOLVE",
  COMPLETE_PAYMENT_RESOLVE: "COMPLETE_PAYMENT_RESOLVE",
  COMPLETE_PAYMENT_REQUEST: "COMPLETE_PAYMENT_REQUEST",
  RESET_PASSWORD_REQUESTED: "RESET_PASSWORD_REQUESTED",
  RESET_PASSWORD_RESOLVED: "RESET_PASSWORD_RESOLVED",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
  RESEND_EMAIL_CONFIRMATION_REQUESTED: "RESEND_EMAIL_CONFIRMATION_REQUESTED",
  RESEND_EMAIL_CONFIRMATION_FAILED: "RESEND_EMAIL_CONFIRMATION_FAILED",
  RESEND_EMAIL_CONFIRMATION_RESOLVED: "RESEND_EMAIL_CONFIRMATION_RESOLVED",
  SEARCH_USERS_REQUESTED: "SEARCH_USERS_REQUESTED",
  SEARCH_USERS_RESOLVED: "SEARCH_USERS_RESOLVED",
  SEARCH_USERS_FAILED: "SEARCH_USERS_REQUESTESD",
  CLEAR_SEARCH_USERS: "CLEAR_SEARCH_USERS"
};

const listingPhotoService = new ListingPhotoService();
let usersSearchCancelToken = null;

let fileService;
export const adminActionCreators = {
  getLookup: (entity, lookupType) => async (dispatch, getState) => {
    const { lookup } = getState().admin;
    let lookupTable = lookup[lookupType];
    if (lookupTable && lookupTable.length > 0) return; //Lookup table is already populated, ignore this request
    dispatch({ type: actions.LOOKUP_REQUESTED, payload: entity });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;

    try {
      const { data } = await lookupService.get(entity);
      dataJson = data;
      if (
        entity === entityTypes.products &&
        entity !== lookupType &&
        dataJson?.value
      ) {
        const lookUpDataJson = dataJson.value['productTypes'];
        const newArray = lookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['productTypes'] = newArray;
      } else if (entity === 'MlsSubmission' && dataJson?.value) {
        const formTypesLookUpDataJson = dataJson.value['formTypes'];
        const submissionStatusesLookUpDataJson =
          dataJson.value['submissionStatuses'];
        const officeStatusLookUpDataJson = dataJson.value['officeStatus'];
        const officeUsersLookUpDataJson = dataJson.value['officeUsers'];
        const companiesLookUpDataJson = dataJson.value['companies'];

        const formTypesNewArray = formTypesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const officeStatusTypesNewArray = officeStatusLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const officeUsersTypesNewArray = officeUsersLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const companiesTypesNewArray = companiesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const submissionStatusesNewArray = submissionStatusesLookUpDataJson.map(
          item => {
            return { value: item.key, label: item.value };
          },
        );
        dataJson.value['mlsFormTypes'] = formTypesNewArray;
        dataJson.value['submissionStatusTypes'] = submissionStatusesNewArray;
        dataJson.value['officeStatusTypes'] = officeStatusTypesNewArray;
        dataJson.value['officeUsersTypes'] = officeUsersTypesNewArray;
        dataJson.value['companies'] = companiesTypesNewArray;
      } else if (entity === entityTypes.properties && dataJson?.value) {
        const statusLookUpDataJson = dataJson.value['statuses'];
        const usersLookUpDataJson = dataJson.value['users'];
        const zipCodesLookUpDataJson = dataJson.value['zipCodes'];
        const officeStatusLookUpDataJson = dataJson.value['officeStatus'];
        const officeUsersLookUpDataJson = dataJson.value['officeUsers'];
        const companiesLookUpDataJson = dataJson.value['companies'];
        const formStatusesLookUpDataJson = dataJson.value['formStatuses'];
        

        const statusNewArray = statusLookUpDataJson.map(item => {
          return { value: `${item.key}`, label: item.value };
        });
        const usersNewArray = usersLookUpDataJson.map(item => {
          return { value: `${item.key}`, label: item.value };
        });
        const zipCodesNewArray = zipCodesLookUpDataJson.map(item => {
          return { value: `${item.key}`, label: item.value };
        });
        const officeStatusTypesNewArray = officeStatusLookUpDataJson.map(item => {
          return { value: `${item.key}`, label: item.value };
        });
        const officeUsersTypesNewArray = officeUsersLookUpDataJson.map(item => {
          return { value: `${item.key}`, label: item.value };
        });
        const companiesTypesNewArray = companiesLookUpDataJson.map(item => {
          return { value: `${item.key}`, label: item.value };
        });
        const formStatusesTypesNewArray = formStatusesLookUpDataJson.map(item => {
          return { value: `${item.key}`, label: item.value };
        });

        dataJson.value['statuses'] = statusNewArray;
        dataJson.value['users'] = usersNewArray;
        dataJson.value['zipCodes'] = zipCodesNewArray;
        dataJson.value['officeStatusTypes'] = officeStatusTypesNewArray;
        dataJson.value['officeUsersTypes'] = officeUsersTypesNewArray;
        dataJson.value['companies'] = companiesTypesNewArray;
        dataJson.value['formStatuses'] = formStatusesTypesNewArray;
      } else if (
        entity === entityTypes.form ||
        (entity === entityTypes.listings && dataJson?.value) ||
        (entity === entityTypes.landing && dataJson?.value)
      ) {
        let layoutNewArray = [];
        const categoryLookUpDataJson = dataJson.value['mlsCategories'];
        const abbreviationlookUpDataJson = dataJson.value['mlsAbbreviations'];
        const layoutTypelookUpDataJson = dataJson.value['layoutType'];
        const companiesLookUpDataJson = dataJson.value['companies'];
        const stateslookUpDataJson = dataJson.value['states'];
        const formTypeslookUpDataJson = dataJson.value['formTypes'];

        const categoryNewArray = categoryLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const companiesNewArray = companiesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const abbreviationNewArray = abbreviationlookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const statesNewArray = stateslookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const formTypesNewArray = formTypeslookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        layoutTypelookUpDataJson.map(item => {
          if (
            item.key === Enums.NUMERIC_VALUE.TWO ||
            item.key === Enums.NUMERIC_VALUE.THREE
          ) {
            return layoutNewArray.push({
              value: item.key,
              label: formLayoutType[item.value],
            });
          }
        });
        dataJson.value['mlsCategories'] = categoryNewArray;
        dataJson.value['mlsAbbreviations'] = abbreviationNewArray;
        dataJson.value['layoutType'] = layoutNewArray;
        dataJson.value['companies'] = companiesNewArray;
        dataJson.value['states'] = statesNewArray;
        dataJson.value['formTypes'] = formTypesNewArray;
      } else if (entity === entityTypes.property && dataJson?.value) {
        const userslookUpDataJson = dataJson.value['users'];
        const usersNewArray = userslookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['users'] = usersNewArray;
      } else if (
        (entity === entityTypes.products &&
          entity === lookupType &&
          dataJson?.value) || (entity === entityTypes.mlsCoverage && dataJson?.value) || (entity === entityTypes.coverage)
      ) {
        const categoryLookUpDataJson = dataJson.value['mlsCategories'] || [];
        const mlsLookUpDataJson = dataJson.value['mls'] || [];
        const companiesLookUpDataJson = dataJson.value['companies'] || [];
        const statesLookUpDataJson = dataJson.value['states'] || [];
        const productTypesLookUpDataJson = dataJson.value['productTypes'] || [];
        const restrictionsLookUpDataJson = dataJson.value['restrictions'] || [];

        const categoryNewArray = categoryLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const companiesNewArray = companiesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const mlsNewArray = mlsLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const statesNewArray = statesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const productNewArray = productTypesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const restrictionsNewArray = restrictionsLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });

        dataJson.value['mlsCategories'] = categoryNewArray;
        dataJson.value['mls'] = mlsNewArray;
        dataJson.value['companies'] = companiesNewArray;
        dataJson.value['states'] = statesNewArray;
        dataJson.value['productTypes'] = productNewArray;
        dataJson.value['restrictions'] = restrictionsNewArray;
      } else if(entity === entityTypes.users && dataJson?.value){
        const companiesLookUpDataJson = dataJson.value['companies'] || [];
        const companiesNewArray = companiesLookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['companies'] = companiesNewArray;
      } else if (entity === entityTypes.voucherTemplate || (entity === entityTypes.template && dataJson?.value)) {
        const companylookUpDataJson = dataJson.value['companies'];
        const listingproductlookUpDataJson = dataJson.value['listingProducts'] || [];
        const nonListingproductlookUpDataJson = dataJson.value['nonListingProducts'] || [];

        const companyNewArray = companylookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const listingProductNewArray = listingproductlookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const nonListingProductNewArray = nonListingproductlookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['companies'] = companyNewArray;
        dataJson.value['listingProducts'] = listingProductNewArray;
        dataJson.value['nonListingProducts'] = nonListingProductNewArray;
      } else if (entity === entityTypes.voucherListing && dataJson?.value) {
        const wholeSaleUserslookUpDataJson = dataJson.value['wholeSaleUsers'];
        const voucherTemplateslookUpDataJson = dataJson.value['voucherTemplates'] || [];

        const wholeSaleUsersNewArray = wholeSaleUserslookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        const voucherTemplatesNewArray = voucherTemplateslookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['wholeSaleUsers'] = wholeSaleUsersNewArray;
        dataJson.value['voucherTemplates'] = voucherTemplatesNewArray;
      } else if (entity === entityTypes.abbreviation && dataJson?.value) {
        const loggedInUserslookUpDataJson = dataJson.value['loggedInUsers'];

        const loggedInUsersNewArray = loggedInUserslookUpDataJson.map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['loggedInUsers'] = loggedInUsersNewArray;
      } else if (entity === entityTypes.addAbbreviation && dataJson?.value) {
        dataJson.value['restrictions'] = dataJson.value['restrictions'].map(item => {
          return { value: item.key, label: item.value };
        });
      }
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      checkResponse(data);

    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'getLookup FAILED',
        },
      });
    }

    dispatch({
      type: actions.LOOKUP_RESOLVED,
      payload: { lookupType: lookupType, value: dataJson?.value },
      isError: isError,
    });
  },
  getAll: (entity, request) => async (dispatch, getState) => {
    dispatch({ type: actions.GETALL_REQUESTED, payload: entity });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.getAll(entity, request);
      if (entity !== entityTypes.users) {
        dataJson = data;
      } else {
        dataJson = { value: data };
      }
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getAll FAILED',
            entity,
            request,
        },
      });

    }
    dispatch({
      type: actions.GETALL_RESOLVED,
      payload: { entity: entity, value: dataJson?.value },
      isError: isError,
    });
  },
  get: (entity, id) => async (dispatch, getState) => {
    dispatch({ type: actions.GET_REQUESTED, payload: entity });

    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.get(entity, id);
      dataJson = data;
      checkResponse(data);
    } catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions get FAILED',
            entity,
            id,
        },
      });
    }
    dispatch({
      type: actions.GET_RESOLVED,
      payload: { entity: entity, id: id, value: dataJson?.value },
      isError: isError,
    });
  },
  update: (entity, id, item) => async (dispatch, getState) => {
    dispatch({ type: actions.UPDATE_REQUESTED, payload: entity });

    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.update(entity, id, item);
      dataJson = data;
      checkResponse(data);
    } catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions update FAILED',
            entity,
            id,
            item,
        },
      });
    }
    dispatch({
      type: actions.UPDATE_RESOLVED,
      payload: { entity: entity, id: id, value: dataJson?.value },
      isError: isError,
    });
  },
  delete: (entity, id, cb) => async dispatch => {
    dispatch({ type: actions.DELETE_REQUESTED, payload: entity });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.delete(entity, id);
      dataJson = data;
      if (dataJson.value) {
        dispatch(alertActions.successAlert(dataJson?.message));
        cb();
      }
      if (data.hasOwnProperty('success')) {
        if (!data.success) {
          throw new Error(data) 
        }
      }
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions delete FAILED',
            entity,
            id,
        },
      });
    }
    dispatch({
      type: actions.DELETE_RESOLVED,
      payload: { entity: entity, value: dataJson?.value, id: +id },
      isError: isError,
    });
  },
  getApi: () => async dispatch => {
    dispatch({ type: actions.API_REQUESTED, payload: {} });

    let isError = false;
    let dataJson;
    try {
      const { data } = await apiService.getSpec();
      dataJson = data;
      checkResponse(data);
    } catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getApi FAILED'
        },
      });
    }
    dispatch({
      type: actions.API_RESOLVED,
      payload: { ...dataJson },
      isError: isError,
    });
  },
  getUserLookUpApi: () => async dispatch => {
    dispatch({ type: actions.GET_USER_LOOK_UP_REQUESTED, payload: {} });

    let isError = false;
    let dataJson;
    try {
      const { data } = await adminService.getUserLookUp();
      dataJson =  { value: data };
        const comapniesNewArray = dataJson.value['companies'].map(item => {
          return { value: item.key, label: item.value };
        });
        dataJson.value['companies'] = comapniesNewArray;
        checkResponse(data);
    } catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getUserLookUpApi FAILED'
        },
      });
    }
    dispatch({
      type: actions.GET_USER_LOOK_UP_RESOLVED,
      payload: { ...dataJson },
      isError: isError,
    });
  },
  addUpdateNewUser: (userId, reqObj, cb) => async dispatch => {
    dispatch({ type: actions.ADD_UPDATE_NEW_USER_REQUESTED, payload: reqObj });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateNewUsers(userId, reqObj);
      dataJson = data;
      checkResponse(data);
      dataJson.reqObj = reqObj;
      if(userId < 1) {
        cb();
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateNewUser FAILED',
            userId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_NEW_USER_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  
  getSelectedItemDetail: (
    itemId,
    activeEntity,
    formSectionId,
    isClone, cb
  ) => async dispatch => {
    dispatch({ type: actions.GET_USER_DETAIL_REQUESTED });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.getSelectedItemDetail(
        itemId,
        activeEntity,
        formSectionId,
        isClone,
      );

      dataJson = data;
      checkResponse(data);
      if (isClone) {
        cb(dataJson.value);
      }
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getSelectedItemDetail FAILED',
            itemId,
            activeEntity,
            formSectionId,
        },
      }); 
    }
    dispatch({
      type: actions.GET_USER_DETAIL_RESOLVED,
      isError: isError,
      payload: dataJson,
    });
  },

  addUpdateCategory: (mlsId, reqObj, cb) => async dispatch => {
    dispatch({ type: actions.ADD_UPDATE_CATEGORY_REQUESTED, payload: reqObj });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateCategory(mlsId, reqObj);
      dataJson = data;
      checkResponse(data);
      dataJson.reqObj = reqObj;
      if(mlsId < 1) {
        cb();
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateCategory FAILED',
            mlsId,
            reqObj,
        },
      }); 
    }
    dispatch({
      type: actions.ADD_UPDATE_CATEGORY_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  addUpdateAbbreviation: (mlsId, reqObj, cb) => async dispatch => {
    dispatch({
      type: actions.ADD_UPDATE_ABBREVIATION_REQUESTED,
      payload: reqObj,
    });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson = {
      success: true
    };

    try {
      if(mlsId < 1) {
        await mlsService.addMls(reqObj);
        dataJson.reqObj = reqObj;
        cb();
      } else {
        await mlsService.updateMls(mlsId, reqObj);
        dataJson.reqObj = reqObj;
      }
      
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateAbbreviation FAILED',
            mlsId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_ABBREVIATION_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  addUpdateBasicForm: (formSectionId, reqObj, cb) => async dispatch => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateBasicForm(
        formSectionId,
        reqObj,
      );
      dataJson = data;
      checkResponse(data);
      cb(dataJson.value);
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateBasicForm FAILED',
            formSectionId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_BASIC_FORM_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  uploadPhoto: (
    listingId,maxPriority,
    { file, thumbnail, absoluteUrl, thumbnailUrl, order, md5 },
  ) => async (dispatch, getState) => {
    let isError = false;
    let listingPhotoId, key;

    if (!fileService) {
      fileService = new FileService();
    }

    try {
      fileService.uploadPhoto(file, thumbnail).then(
        p => {
          if (p.key.indexOf('thumb') < 0) {
            if (listingId) {
              listingPhotoService
                .addPhoto(listingId, {
                  Bucket: p.Bucket,
                  Path: `image-uploads/${p.key}`,
                  Priority: maxPriority,
                })
                .then(resp => {
                  const { data } = resp;
                  absoluteUrl = data.absoluteUrl;
                  thumbnailUrl = data.thumbnailUrl;
                  listingPhotoId = data.listingPhotoId;
                  key = data.path;

                  dispatch({
                    type: actions.UPLOAD_PHOTO_RESOLVED,
                    payload: {
                      photo: {
                        file,
                        thumbnail,
                        listingPhotoId,
                        absoluteUrl,
                        thumbnailUrl,
                        order,
                        md5,
                        key: p.key,
                        Bucket: p.Bucket,
                      },
                    },
                    isError: isError,
                  });
                });
            }
          }
        },
        err => {
          console.log(err);
        },
      );
    } catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions uploadPhoto FAILED'
        },
      });
    }
  },
  removePhotos: index => async dispatch => {
    dispatch({ type: actions.REMOVE_PHOTO_RESOLVED, payload: { index } });
  },

  reorderAdminPhotos: (listingId, { oldIndex, newIndex }) => async (dispatch, getState) => {
    dispatch({ type: actions.REORDER_ADMIN_PHOTOS_REQUESTED, payload: { oldIndex, newIndex } });
    let isError = false;

    try {
    await listingPhotoService.reorderPhotos(listingId, {oldIndex, newIndex});
    }
    catch (ex) {
        isError = true;
        const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
        dispatch(alertActions.errorAlert(errorMessage));

        LogRocket.captureException(ex, {
          tags: {
              errorType: 'failed_admin_action',
          },
          extra: {
              type: 'admin actions reorderAdminPhotos FAILED'
          },
        });
    }

    dispatch({ type: actions.REORDER_ADMIN_PHOTOS_RESOLVED, payload: { listingId, newIndex,oldIndex  }, isError: isError });
},

  deletePhotoFromDB: photoId => async dispatch => {
    dispatch({ type: actions.DELETE_PHOTOS_REQUESTED });
    let isError = false;
    let dataJson;

    try {
      const { data } = await listingPhotoService.deletePhoto(photoId);
      dataJson = data;
      checkResponse(data);
    } catch (ex) {
      isError = true;
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions deletePhotoFromDB FAILED'
        },
      });
    }
    dispatch({
      type: actions.DELETE_PHOTOS_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  addUpdateProperty: (propertyId, reqObj, cb) => async dispatch => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateProperty(propertyId, reqObj);
      dataJson = data;
      checkResponse(data);
      dataJson.reqObj = reqObj;
      if(propertyId < 1) {
        cb();
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || Object.values(ex?.response?.data?.errors || {})?.[0]?.[0] ||  staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateProperty FAILED',
            propertyId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_PROPERTY_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  resetPropertyData: () => ({ type: actions.ADD_UPDATE_PROPERTY_RESET }),

  toggleStatus: (entity, id) => async dispatch => {
    dispatch({ type: actions.TOGGLE_STATUS_REQUESTED, payload: entity });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.toggleStatus(entity, id);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions toggleStatus FAILED',
            entity,
            id,
        },
      });
    }
    dispatch({
      type: actions.TOGGLE_STATUS_RESOLVED,
      payload: { entity: entity, value: dataJson?.value, id: +id },
      isError: isError,
    });
  },

  //TODO: Revove after refactoring
  updatePropertyStatus: (propertyId, reqObj) => async (dispatch, getState) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    
    try {
      const { data } = await adminService.updatePropertyStatus(
        propertyId,
        reqObj,
        );

        if(window.location.href.includes(Routes.ADMIN_PROPERTY)) {
          
          const orders = getState().admin.activeSet;
          const order = orders.find(o => o.id === propertyId);
          order.assignedToId = reqObj.assignedListingStatusId;
          dispatch({
            type: actions.UPDATE_PROPERTY_STATUS_RESOLVED,
            payload: [...orders, order],
          });
        }
        checkResponse(data);

      dispatch(endLoading('MAIN_LOADER'));

    } catch (ex) {

      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions updatePropertyStatus FAILED',
            propertyId,
            reqObj,
        },
      });
    }
  },
  updateMlsSubmissionStatus: (entity, objectId) => async (
    dispatch,
    getState,
  ) => {
    dispatch({ type: actions.SUBMISSION_STATUS_REQUESTED, payload: entity });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    let mlsState;
    try {
      const { data } = await dataService.updateStatus(entity, objectId);
      dataJson = data;
      checkResponse(data);
      if (dataJson.value) {
        mlsState = getState().admin.activeSet.map(item => {
          if (item.userFormId === objectId.userFormId) {
            return {
              ...item,
              formSubmissionStatusId: objectId.formSubmissionStatusId,
              formSubmissionStatus: item.submissionStatuses.find(
                status => status.key === objectId.formSubmissionStatusId,
              ).value,
            };
          }

          return item;
        });
      }
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions updateMlsSubmissionStatus FAILED',
            entity,
            objectId,
        },
      });
    }
    dispatch({
      type: actions.SUBMISSION_STATUS_RESOLVED,
      payload: dataJson,
      newSubmissionList: mlsState,
      isError: isError,
    });
  },

  uploadS3Photo: ({
    file,
    thumbnail,
    absoluteUrl,
    thumbnailUrl,
    order,
    md5,
  }) => async (dispatch, getState) => {
    dispatch({
      type: actions.UPLOAD_FORM_PHOTO_REQUESTED,
      payload: {
        photo: { file, thumbnail, absoluteUrl, thumbnailUrl, order, md5 },
      },
    });
    let isError = false;

    if (!fileService) {
      fileService = new FileService();
    }


    try {
      fileService.uploadPhoto(file, thumbnail).then(
        responseData => {
          dispatch({
            type: actions.UPLOAD_FORM_PHOTO_RESOLVED,
            payload: {
              path: `image-uploads/${responseData.key}`,
              priority: order,
              thumbnailUrl: responseData.thumbLink,
            },
          });
        },
        err => {
          console.log(err);
        },
      );
    } catch (ex) {
      isError = true;
      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions uploadS3Photo FAILED',
        },
      });
    }
  },

  getOverlayFormDetail: (entity, sectionId) => async dispatch => {
    dispatch({ type: actions.OVERLAY_FORM_REQUESTED });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.getOverlayForm(entity, sectionId);
      dataJson = data;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      checkResponse(data);
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getOverlayFormDetail FAILED',
            entity,
            sectionId,
        },
      });
    }
    dispatch({
      type: actions.OVERLAY_FORM_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  updateOverlayFormDetail: (entity, sectionId, reqObj) => async dispatch => {
    dispatch({ type: actions.UPDATE_OVERLAY_FORM_REQUEST });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.updateOverlayForm(
        entity,
        sectionId,
        reqObj,
      );

      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions updateOverlayFormDetail FAILED',
            entity,
            sectionId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.UPDATE_OVERLAY_FORM_RESOLVE,
      payload: dataJson,
      isError: isError,
    });
  },

  getClone: (entity, ids) => async dispatch => {
    dispatch({ type: actions.CLONE_REQUEST });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;

    try {
      const { data } = await dataService.getClone(entity, ids);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getClone FAILED',
            entity,
            ids,
        },
      });
    }
    dispatch({
      type: actions.CLONE_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  addUpdateProduct: (productId, reqObj, cb) => async dispatch => {
    dispatch({
      type: actions.ADD_UPDATE_PRODUCT_REQUESTED,
      payload: reqObj,
    });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateProduct(productId, reqObj);
      dataJson = data;
      checkResponse(data);
      dataJson.reqObj = reqObj;
      if(productId < 1) {
        cb();
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateProduct FAILED',
            productId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_PRODUCT_RESOLVED,
      payload: dataJson,
      isError: isError,
      isFromProduct: true,
    });
  },

  addUpdateCompany: (companyId, reqObj, cb) => async dispatch => {
    dispatch({
      type: actions.ADD_UPDATE_COMPANY_REQUESTED,
      payload: reqObj,
    });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateCompany(companyId, reqObj);
      dataJson = data;
      checkResponse(data);
      dataJson.reqObj = reqObj;
      if(companyId < 1) {
        cb();
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateCompany FAILED',
            companyId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_COMPANY_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  switchCustomerPortal: (userId,swithcLocation,listingId) => async dispatch => {
    dispatch({ type: actions.SWITCH_PORTAL_REQUESTED, payload: userId });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
  try {
      const { data } = await adminService.switchCustomerPortal(userId);
      dataJson = data;
      checkResponse(data);
      const userObj = getLocalUserData();
      userObj['switchedUser'] = dataJson.user;
        userObj['isAdminSwitchUser'] = true;
      userService.setLocalUser(userObj);
      if (listingId) {
        redirectOnUserEditForm(listingId);
      } else {
        redirectOnDashboard(swithcLocation);
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions switchCustomerPortal FAILED'
        },
      });
    }
    dispatch({
      type: actions.SWITCH_PORTAL_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  getAuditLog: (entity, reqObj) => async dispatch => {
    dispatch({
      type: actions.GET_AUDIT_LOG_REQUEST,
      payload: reqObj,
    });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.getAuditLog(reqObj);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getAuditLog FAILED',
            entity,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.GET_AUDIT_LOG_RESOLVE,
      payload: { entity: entity, value: dataJson?.value },
      isError: isError,
    });
  },

  addUpdateCoverage: (mlsLocationId, reqObj, cb) => async dispatch => {
    dispatch({
      type: actions.ADD_UPDATE_COVERAGE_REQUESTED,
      payload: reqObj,
    });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateCoverage(mlsLocationId, reqObj);
      dataJson = data;
      checkResponse(data);
      dataJson.reqObj = reqObj;
      if(mlsLocationId < 1) {
        cb();
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateCoverage FAILED',
            mlsLocationId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_COVERAGE_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  addUpdateVoucherTemplate: (tempalteId, reqObj, cb) => async dispatch => {
    dispatch({
      type: actions.ADD_UPDATE_VOUCHER_TEMPLATE_REQUESTED,
      payload: reqObj,
    });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.addUpdateVoucherTemplate(tempalteId, reqObj);
      dataJson = data;
      checkResponse(data);
      dataJson.reqObj = reqObj;
      if(tempalteId < 1) {
        cb({isBack: true});
      } else {
        cb(reqObj);
      }
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions addUpdateVoucherTemplate FAILED',
            tempalteId,
            reqObj,
        },
      });
    }
    dispatch({
      type: actions.ADD_UPDATE_VOUCHER_TEMPLATE_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  getLandingList: (stateCode) => async dispatch => {
    dispatch({
      type: actions.GET_LANDING_LIST_REQUEST
    });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));

    let isError = false;
    let dataJson;

    try {
      const { data } = await adminService.getLandingList(stateCode);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getLandingList FAILED',
            stateCode,
        },
      });
    }
    dispatch({
      type: actions.GET_LANDING_LIST_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  toggleVoucherStatus: (entity, id) => async dispatch => {
    dispatch({ type: actions.VOUCHER_TOGGLE_STATUS_REQUESTED, payload: entity });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.toggleStatus(entity, id);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions toggleVoucherStatus FAILED',
            entity,
            id,
        },
      });
    }
    dispatch({
      type: actions.VOUCHER_TOGGLE_STATUS_RESOLVED,
      payload: { entity: entity, value: dataJson?.value, id: +id },
      isError: isError,
    });
  },


  resendVoucherInvite: (reqObj,entity) => async dispatch => {
    
    dispatch({ type: actions.VOUCHER_RESEND_INVITE_REQUESTED });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    let path='';
    if (typeof window !== 'undefined') {
      path = `${window.location.protocol}//${window.location.host}${Routes.ONBOARDING_VOUCHER_V2}`;
    }
    let payload={
      "applicationUrl":path,
      "voucherCode": reqObj?.voucherURL,
      "voucherId": reqObj?.id
    }
    try {
      const { data } = await dataService.resendVoucherInvite(entity,payload);
      dataJson = data;
      checkResponse(data, staticMessages.resendInvite);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions resendVoucherInvite FAILED',
            reqObj,
            entity
        },
      });
    }
  },

  getMlsPreviewForm: (entity, requestObj) => async dispatch => {
    dispatch({ type: actions.MLS_PREVIEW_REQUESTED });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.getMlsPreviewForm(entity, requestObj);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions getMlsPreviewForm FAILED',
            entity,
            requestObj,
        },
      });
    }
    dispatch({
      type: actions.MLS_PREVIEW_RESOLVED,
      payload: dataJson,
      isError: isError,
    });
  },

  updateLoggedInUser: (entity, requestObj) => async dispatch => {
    dispatch({ type: actions.UPDATE_LOGGED_IN_USER_REQUEST });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.updateLoggedInUser(entity, requestObj);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions updateLoggedInUser FAILED',
            entity,
            requestObj,
        },
      });
    }
    dispatch({
      type: actions.UPDATE_LOGGED_IN_USER_RESOLVE,
      payload: dataJson,
      data: { entity, requestObj },
      isError: isError,
    });
  },

  completePayment: (entity, listingId) => async dispatch => {
    dispatch({ type: actions.COMPLETE_PAYMENT_REQUEST });
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    let isError = false;
    let dataJson;
    try {
      const { data } = await dataService.completePayment(entity, listingId);
      dataJson = data;
      checkResponse(data);
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
    } catch (ex) {
      isError = true;
      dispatch(endLoading('MAIN_LOADER', 'Loading'));
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: 'admin actions completePayment FAILED',
            entity,
            listingId,
        },
      });
    }
    dispatch({
      type: actions.COMPLETE_PAYMENT_RESOLVE,
      payload: dataJson,
      isError: isError,
    });
  },
  
  setCollapseMenu: (value) => {
    return {
      type: 'SET_COLLAPSE_MENU',
      payload: value
    }
  },

  resetUserPasword: (email) => async dispatch => {
    dispatch({
      type: actions.RESET_PASSWORD_REQUESTED
    });

    try {
      const { status } = await adminService.resetPassword({ email });
      if (status !== 204) {
        dispatch(alertActions.errorAlert(staticMessages.apiError));
        dispatch({
          type: actions.RESET_PASSWORD_FAILED
        });
      } else {
        dispatch(alertActions.successAlert(staticMessages.passwordResetLinkSent));
      }
    } catch (ex) {
      dispatch({
        type: actions.RESET_PASSWORD_FAILED
      });
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: actions.RESET_PASSWORD_FAILED,
            email,
        },
      });
    }

    dispatch({
      type: actions.RESET_PASSWORD_RESOLVED
    });
  },
  
  resendEmailConfirmation: (email) => async dispatch => {
    dispatch({
      type: actions.RESEND_EMAIL_CONFIRMATION_REQUESTED
    });

    try {
      const { status } = await adminService.resendEmailConfirmation(email);
      dispatch(alertActions.successAlert(staticMessages.emailConfirmationSent));
    } catch (ex) {
      dispatch({
        type: actions.RESEND_EMAIL_CONFIRMATION_FAILED
      });
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: actions.RESEND_EMAIL_CONFIRMATION_FAILED,
            email,
        },
      });
    }

    dispatch({
      type: actions.RESEND_EMAIL_CONFIRMATION_RESOLVED
    });
  },
  searchUsers: (val) => async dispatch => {
    
    if (usersSearchCancelToken) {
      usersSearchCancelToken.cancel();
      usersSearchCancelToken = null;
    }
    dispatch({ type: actions.SEARCH_USERS_REQUESTED });
    usersSearchCancelToken = axios.CancelToken.source();

    try {
      const { data } = await adminService.searchUsers(val, usersSearchCancelToken);
      const convertedList = data.map(i => ({
        ...i,
        value: i?.id,
        label: `${i?.fullName || '' }${i?.email ? ' ' : ''}${i?.email || ''}`
      }))
      dispatch({ type: actions.SEARCH_USERS_RESOLVED, payload: convertedList });
    } catch (ex) {
      if(ex.message === 'canceled') {
        return;
      }
      dispatch({ type: actions.SEARCH_USERS_FAILED });
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));

      LogRocket.captureException(ex, {
        tags: {
            errorType: 'failed_admin_action',
        },
        extra: {
            type: actions.SEARCH_USERS_FAILED
        },
      });
    }
  },
clearUserSearch: () => ({ type: actions.CLEAR_SEARCH_USERS }),
};