import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions';
import AdminSiderMenu from '../Components/AdminSiderMenu';
import AdminHeader from '../Components/AdminHeader';
import Enums from '../../../Constants/Enums';
import AddEditProperty from '../Components/PropertyForm/AddEditProperty';
import { adminPageTitles } from '../../../Constants/CommonConstants';
import { titleCompaniesCreators } from '../../../Actions/V2/Admin/TitleCompaniesActions';

const { Content } = Layout;

export class PropertyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      entity: '',
      api: null
    };
    this.prevTitle = '';
  }

  componentDidMount = async () => {
    this.props.getLookup(this.props.activeEntity, 'PropertyType');
    if(this.props.match?.params?.propertyId > 0) {
      await this.props.getSelectedItemDetail(Number(this.props.match.params.propertyId), this.props.activeEntity)
      await this.props.getTitleCompany(this.props.userProfileData?.addressState);
    }
    const pageType = this.props.match?.params?.propertyId < 1
    ? 'addProperty'
    : 'editProperty';
    this.prevTitle = document.title;
    document.title = adminPageTitles[pageType]
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const {
      history, lookup, isFetching, isDetailFetching, userProfileData, activeEntity
    } = this.props;
    const { path } = this.props.match;
    const { collapsed } = this.state;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <AdminSiderMenu path={path} onCollapse={this.onCollapse} history={history} collapsed={collapsed} />
        <Layout>
          <Layout.Header style={{ background: '#fff', minHeight: 52, padding: 0 }}>
            <AdminHeader collapsed={this.state.collapsed} toggle={this.toggle} />
          </Layout.Header>
          <Content>
            {
              !isDetailFetching && !isFetching &&
              <AddEditProperty
                selectedPropertyData={userProfileData}
                errors={this.props.userErrors}
                lookup={lookup}
                activeEntity={activeEntity}
                isNewProperty = {this.props.match?.params?.propertyId < 1}
              />
            }
            </Content>
        </Layout>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { path, params } = ownProps.match;
  const entityArray = path.split('/');
  return {
    activeEntity: entityArray[Enums.NUMERIC_VALUE.TWO],
    userProfileData: params?.propertyId > 0 ? state.admin.selectedItemData : { },
    userErrors: state.admin.errors || {},
    lookup: state.admin.lookup || {},
    isDetailFetching: state.admin.isDetailFetching,
    isFetching: state.admin.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators, ...titleCompaniesCreators }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertyContainer));
