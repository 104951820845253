import LogRocket from 'logrocket';

import { guid } from '../../../Utilities/Strings';
import { startLoading, endLoading } from '../../LoaderActions';
import { removeNullUndefined } from '../../../Utilities/commonFunctions';
import { formsFiltersDefault } from '../../../Reducers/V2/AdminFormsReducer';
import { titleCompanyService } from '../../../Services/V2/TitleCompanyService';
import { alertActions } from '../../ToastActions';
import { staticMessages } from '../../../Constants/CommonConstants';
import FileService from '../../../Services/FileService';
const fileService = new FileService();

export const TitleCompaniesActions = {
    TITLE_COMPANIES_REQUESTED: 'TITLE_COMPANIES_REQUESTED',
    TITLE_COMPANIES_RESOLVED: 'TITLE_COMPANIES_RESOLVED',
    TITLE_COMPANIES_FAILED: 'TITLE_COMPANIES_FAILED',
    TITLE_COMPANY_REQUESTED: 'TITLE_COMPANY_REQUESTED',
    TITLE_COMPANY_RESOLVED: 'TITLE_COMPANY_RESOLVED',
    TITLE_COMPANY_FAILED: 'TITLE_COMPANY_FAILED',
    SET_TITLE_COMPANIES_FILTERS: 'SET_TITLE_COMPANIES_FILTERS',
    CLEAR_TITLE_COMPANIES_FILTERS: 'CLEAR_TITLE_COMPANIES_FILTERS',
    CLEAR_TITLE_COMPANY: 'CLEAR_TITLE_COMPANY',
};

export const titleCompaniesCreators = {
    setTitleCompaniesFilters: (filters) => async (dispatch) => {
        dispatch({ type: TitleCompaniesActions.SET_TITLE_COMPANIES_FILTERS, payload: filters });
    },
    clearTitleCompaniesFilters: () => async (dispatch) => {
        dispatch({ type: TitleCompaniesActions.CLEAR_TITLE_COMPANIES_FILTERS });
    },
    clearTitleCompany: () => async (dispatch) => {
        dispatch({ type: TitleCompaniesActions.CLEAR_TITLE_COMPANY });
    },
    getTitleCompanies:
        (params = formsFiltersDefault) =>
            async (dispatch, getState) => {
                dispatch({ type: TitleCompaniesActions.TITLE_COMPANIES_REQUESTED });
                dispatch(startLoading('MAIN_LOADER', 'Loading'));
                try {
                    const paramsObj = params;

                    const { data } = await titleCompanyService.getTitleCompanies(removeNullUndefined(paramsObj));

                    dispatch(endLoading('MAIN_LOADER', 'Loading'));
                    dispatch({ type: TitleCompaniesActions.TITLE_COMPANIES_RESOLVED, payload: data });
                } catch (error) {
                    LogRocket.captureException(error, {
                        tags: {
                            errorType: 'failed_titlecompany_action',
                        },
                        extra: {
                            type: TitleCompaniesActions.TITLE_COMPANIES_FAILED,
                        },
                    });
                }
            },
    getTitleCompany:
        (state, companyId = null) =>
            async (dispatch, getState) => {
                try {
                    dispatch(startLoading('MAIN_LOADER', 'Loading'));
                    dispatch({ type: TitleCompaniesActions.TITLE_COMPANY_REQUESTED });

                    const { data } = await titleCompanyService.getTitleCompany(state, companyId);

                    dispatch(endLoading('MAIN_LOADER', 'Loading'));
                    dispatch({ type: TitleCompaniesActions.TITLE_COMPANY_RESOLVED, payload: data });
                } catch (ex) {
                    dispatch(endLoading('MAIN_LOADER', 'Loading'));
                    const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                    dispatch(alertActions.errorAlert(errorMessage));
                    LogRocket.captureException(ex, {
                        tags: {
                            errorType: 'failed_titlecompany_action',
                        },
                        extra: {
                            type: TitleCompaniesActions.TITLE_COMPANY_FAILED,
                        },
                    });
                }
            },

    addAdvertisementFile:
        ({ file, filePath }) =>
            async (dispatch) => {
                try {
                    dispatch({ type: TitleCompaniesActions.ADD_ADVERTISEMENT_FILE_REQUESTED });
                    const folderId = guid();
                    const filePath = `${folderId}/${file[0].name.replaceAll('#', '-')}`;
                    const fileContent = await fileService.blobToBase64(file);
                    const { data } = await titleCompanyService.addAdvertisementFile(filePath, fileContent);

                    dispatch({ type: TitleCompaniesActions.ADD_ADVERTISEMENT_FILE_RESOLVED, payload: data });
                } catch (ex) {
                    const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                    dispatch(alertActions.errorAlert(errorMessage));
                    dispatch({ type: TitleCompaniesActions.ADD_ADVERTISEMENT_FILE_FAILED });

                    LogRocket.captureException(ex, {
                        tags: {
                            errorType: 'failed_onboarding_action',
                        },
                        extra: {
                            type: TitleCompaniesActions.ADD_ADVERTISEMENT_FILE_FAILED,
                        },
                    });
                }
            },
    createTitleCompany: (values, cb, file) => async (dispatch) => {
        try {
            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            if (file) {
                const folderId = guid();
                values.uploadFileName = `${folderId}/${file.name.replaceAll('#', '-')}`;
                values.uploadFileContent = await fileService.blobToBase64(file);
            }
            const { data } = await titleCompanyService.createTitleCompany(values);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            if (data) {
                dispatch(alertActions.successAlert('Successfully created'));
                cb(data);
            }
        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            console.log('error', ex)
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_title_company_action',
                },
                extra: {
                    type: 'create_title_company',
                },
            });
        }
    },
    updateTitleCompany: (values, cb, file) => async (dispatch) => {
        try {
            dispatch(startLoading('MAIN_LOADER', 'Loading'));
            if (file) {
                const folderId = guid();
                values.uploadFileName = `${folderId}/${file.name.replaceAll('#', '-')}`;
                values.uploadFileContent = await fileService.blobToBase64(file);
            }

            const { data } = await titleCompanyService.updateTitleCompany(values);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            if (data) {
                cb(data);
                dispatch(alertActions.successAlert('Successfully updated'));
            }
        } catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
            LogRocket.captureException(ex, {
                tags: {
                    errorType: 'failed_title_company_action',
                },
                extra: {
                    type: 'update_title_company',
                },
            });
        }
    },
};
