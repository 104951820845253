import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userActionCreators } from '../../../Actions/UserActions';
import { BillingInfoWrap, HeadTextWrap, Heading, HeadText } from '../../../Assets/Style/commonStyleComponents';
import Routes from '../../../Constants/Routes';
import { getLocalUserData } from '../../../Utilities/commonFunctions';
import { history } from '../../../Utilities/History';

class BillingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.prevTitle = '';
  }

  componentDidMount() {
    this.prevTitle = document.title;
    document.title = 'Billing Information | Unreal Estate'
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  render() {
    const { billingAddress, userDetails, shippingAddress } = this.props;
    return (
      <React.Fragment>
        <BillingInfoWrap>
          <div className="billing-shipping-wrapper">
            <div className="billing-shipping-col">
              <HeadTextWrap className="billing-head">
                <Heading>Billing Address</Heading>
                <HeadText>The following addresses will be used on the checkout page by default.</HeadText>
              </HeadTextWrap>

              {
                Object.keys(billingAddress).length > 0 ?
                  <div className="billing-info-address">
                    <span>{billingAddress?.firstName ? billingAddress?.firstName : userDetails.firstName} {billingAddress?.lastName ? billingAddress?.lastName : userDetails.lastName}{userDetails.firstName && ','} </span>
                    <span>{billingAddress.address1}{billingAddress.address1 && ','} </span>
                    {
                      billingAddress.address2 &&
                      <span>{`${billingAddress.address2},`}</span>
                    }
                    <span>{billingAddress.city}{billingAddress.city && ','} </span>
                    <span>{billingAddress.state}{billingAddress.state && ','} </span>
                    <span>{billingAddress.zip}</span>
                    {
                      billingAddress.country &&
                      <span>{`, ${billingAddress.country}`}</span>
                    }
                  </div>
                  : 'You have not set up this type of address yet.'
              }
              <a href="javascript:void(0)" className="btn-with-border" onClick={() => history.push(Routes.EDIT_BILLING_ADDRESS)}>
                <i className="fas fa-edit" aria-hidden="true"></i>
            Edit
            </a>
            </div>
            <div className="billing-shipping-col">
              <HeadTextWrap className="billing-head">
                <Heading>Shipping Address</Heading>
                <HeadText>The following addresses will be used on the checkout page by default.</HeadText>
              </HeadTextWrap>
              {
                shippingAddress && Object.keys(shippingAddress).length > 0 ?
                  <div className="billing-info-address">
                    <span>{shippingAddress?.firstName ? shippingAddress?.firstName : userDetails.firstName} {shippingAddress?.lastName ? shippingAddress?.lastName : userDetails.lastName}{userDetails.firstName && ','} </span>
                    <span>{shippingAddress.address1}{shippingAddress.address1 && ','} </span>
                    {
                      shippingAddress.address2 &&
                      <span>{`${shippingAddress.address2},`}</span>
                    }
                    <span>{shippingAddress.city}{shippingAddress.city && ','} </span>
                    <span>{shippingAddress.state}{shippingAddress.state && ','} </span>
                    <span>{shippingAddress.zip}</span>
                    {
                      shippingAddress.country &&
                      <span>{`, ${shippingAddress.country}`}</span>
                    }
                  </div>
                  : 'You have not set up this type of address yet.'
              }
              <a href="javascript:void(0)" className="btn-with-border" onClick={() => history.push(Routes.EDIT_SHIPPING_ADDRESS)}>
                <i className="fas fa-edit" aria-hidden="true"></i>
            Edit
            </a>
            </div>
          </div>
        </BillingInfoWrap>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const userDetails = getLocalUserData() || {};
  return {
    isAdminSwitched: userDetails.isAdminSwitchUser,
    userDetails: userDetails.isAdminSwitchUser ? userDetails.switchedUser : userDetails,
    isFetching: state.dashboard.isFetching,
    billingAddress: state.user.defaultBillingAddress || {},
    shippingAddress: state.user.defaultShippingAddress || {},
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
