import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import v8n from 'v8n';
import { toast } from 'react-toastify';
import { lookupActionsCreators } from '../../../Actions/V2/LookupActions';
import { propertyTypesSelector, statesSelector } from '../../../Reducers/LookupReducer';
import { currentUserSelector } from '../../../Reducers/UserReducer';
import { buyerLeadsService } from '../../../Services/V2/buyerLeadsService';
import Routes from '../../../Constants/Routes';
import { buyerLeadSourceIds } from '../../../Constants/CommonConstants';

const FormContainer = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  flex: 1 1 0%;

  label {
    font-size: 1rem;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
      color: #f56565;
      font-size: 1rem;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const PriceRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -1rem;
  align-items: baseline;

  span {
    width: 20%;
    text-align: center;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    margin-top: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    border-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#242B26')};
  }
  select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }

  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.5rem;
  min-width: 7rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color:  ${({ theme }) => theme.lavender};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 1rem;
  font-size: 1rem;
`;

const CancelButton = styled(SubmitButton)`
  background: none;
  font-size: 1rem;
  font-weight: normal;
  font-family: 'National-Regular';
  text-wrap: balance;
  min-width: 5rem;
  max-width: 30rem;
  width: auto;
  height: auto
  min-height: 2.5rem;
  border: 1px dashed ${({ theme }) => theme.colors.green[900]};

  margin-right: 1.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 10rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 12rem;
  }
`;

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[500]};
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel
        ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
        : ''}
  }
`;

const Disclaimer = styled.div`
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  color: #a7aaa8;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const TitleCheckboxWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 0.75rem;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
  }

  label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid black;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  input:checked + label:before {
    background: black;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const google = window.google;
const geocoder = new google.maps.Geocoder();

const BuyingPropertyForm = ({ onCancel, onSubmit, cancelButtonText, submitButtonText }) => {
  const dispatch = useDispatch();
  const userData = useSelector(currentUserSelector);
  const propertyTypes = useSelector(propertyTypesSelector);
  const states = useSelector(statesSelector);
  const [formFields, setFormFields] = useState({
    city: {
      value: '',
      error: null,
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter city name',
        },
      ],
    },
    state: {
      value: '',
      error: null,
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select state',
        },
      ],
    },
    zip: {
      value: '',
      error: null,
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter your zipcode',
        },
        {
          rule: v8n().minLength(5),
          message: 'Zipcode required minimum five character',
        },
      ],
    },
    priceMin: {
      value: '',
      error: null,
      rules: [
        {
          rule: v8n().numeric(),
          message: 'Please enter minimum price',
        },
      ],
    },
    priceMax: {
      value: '',
      error: null,
      rules: [
        {
          rule: v8n().numeric(),
          message: 'Please enter maximum price',
        },
      ],
    },
    propertyType: {
      value: '',
      error: null,
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select property type',
        },
      ],
    },
    isPreApproved: {
      value: false,
      error: null,
      rules: [],
    },
  });

  useEffect(() => {
    dispatch(lookupActionsCreators.getPropertyTypes());
    dispatch(lookupActionsCreators.getStates());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;

    Object.keys(formFields).forEach((k) => {
      const isErrorExist = validateField(k, formFields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    });

    if (isError) {
      return;
    }

    try {
      await buyerLeadsService.shareBuyerInformation({
        userId: userData.userId,
        city: formFields.city.value,
        zip: formFields.zip.value,
        state: formFields.state.value,
        propertyTypeId: Number(formFields.propertyType.value),
        minPrice: Number(formFields.priceMin.value),
        maxPrice: Number(formFields.priceMax.value),
        isPreapproved: formFields.isPreApproved.value,
        fullName: `${userData.firstName} ${userData.lastName}`,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        source:
          (window.location.href.includes(Routes.DASHBOARD) && buyerLeadSourceIds.Dashboard) ||
          (window.location.href.includes(Routes.ONBOARDING_V2) && buyerLeadSourceIds.ListingFlow),
      });
      toast.success('Information sent');
      onSubmit && onSubmit();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const handleFieldChanged = (e) => {
    e.persist();
    const { name, type } = e.target;
    let { value, checked } = e.target;

    if (type === 'checkbox') {
      value = checked;
    }

    setFormFields((pr) => ({ ...pr, [name]: { ...pr[name], value } }));
  };

  const validateField = (key, value) => {
    const fields = { ...formFields };
    let isError = false;
    for (let i = 0; i < fields[key].rules.length; i++) {
      let rule = fields[key].rules[i];

      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setFormFields((pr) => ({ ...pr, ...fields }));

    return isError;
  };

  const getOptions = (items) => {
    return items ? items.map((x) => ({ value: x.id, label: x.name })) : [];
  };

  const getPlaceBypostalCode = (postalCode) => {
    geocoder
      .geocode({ address: `${postalCode},US` })
      .then((response) => {
        if (!response?.results.length) {
          return;
        }
        const place = response?.results[0];
        const city =
          formFields.city.value ||
          (
            place.address_components.filter((c) => c.types.includes('locality'))?.[0] ||
            place.address_components.filter((c) => c.types.includes('sublocality'))?.[0] ||
            place.address_components.filter((c) => c.types.includes('neighborhood'))?.[0]
          )?.long_name ||
          '';

        const state =
          formFields.state.value ||
          place.address_components.filter((c) => c.types.includes('administrative_area_level_1'))?.[0]?.short_name ||
          '';

        setFormFields((pr) => ({
          ...pr,
          city: {
            ...pr.city,
            value: city,
          },
          state: {
            ...pr.state,
            value: state,
          },
        }));

        state && validateField('city', city);
        city && validateField('state', state);
      })
      .catch((e) => {});
  };

  const handleFieldBlur = (e) => {
    const { name, value } = e.target;

    if (name === 'zip' && value.length > 4) {
      getPlaceBypostalCode(value);
    }

    if (value) {
      validateField(name, value);
    }
  };

  return (
    <>
      <FormContainer>
        <InputContainer className={formFields.zip.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="zip"
            id="zip"
            value={formFields.zip.value}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          />
          <InputLabel smallLabel={formFields.zip.value}>Zip code*</InputLabel>
          {formFields.zip.error && <ErrorMessage>{formFields.zip.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer className={formFields.city.error ? 'invalid' : ''}>
          <input
            type="text"
            name="city"
            id="city"
            value={formFields.city.value}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          />
          <InputLabel smallLabel={formFields.city.value} htmlFor="price">
            City*
          </InputLabel>
          {formFields.city.error && <ErrorMessage>{formFields.city.error}</ErrorMessage>}
        </InputContainer>
        <SelectContainer className={formFields.state.error ? 'invalid' : ''}>
          <select
            id="state"
            name="state"
            value={formFields.state.value || ''}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          >
            <option key={-1} value=""></option>
            {getOptions(states).map((x, idx) => (
              <option key={idx} value={x.value}>
                {x.label}
              </option>
            ))}
          </select>
          <InputLabel smallLabel={formFields.state.value} htmlFor="builtPeriodId">
            State*
          </InputLabel>
          {formFields.state.error && <ErrorMessage>{formFields.state.error}</ErrorMessage>}
        </SelectContainer>
        <PriceRangeContainer>
          <InputContainer className={formFields.priceMin.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="number"
              name="priceMin"
              id="priceMin"
              value={formFields.priceMin.value}
              onChange={handleFieldChanged}
              onBlur={handleFieldBlur}
            />
            <InputLabel smallLabel={formFields.priceMin.value}>Min price*</InputLabel>
            {formFields.priceMin.error && <ErrorMessage>{formFields.priceMin.error}</ErrorMessage>}
          </InputContainer>
          <span> - </span>
          <InputContainer className={formFields.priceMax.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="number"
              name="priceMax"
              id="priceMax"
              value={formFields.priceMax.value}
              onChange={handleFieldChanged}
              onBlur={handleFieldBlur}
            />
            <InputLabel smallLabel={formFields.priceMax.value}>Max price*</InputLabel>
            {formFields.priceMax.error && <ErrorMessage>{formFields.priceMax.error}</ErrorMessage>}
          </InputContainer>
        </PriceRangeContainer>
        <SelectContainer className={formFields.propertyType.error ? 'invalid' : ''}>
          <select
            id="propertyType"
            name="propertyType"
            value={formFields.propertyType.value || ''}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          >
            <option key={-1} value=""></option>
            {getOptions(propertyTypes).map((x, idx) => (
              <option key={idx} value={x.value}>
                {x.label}
              </option>
            ))}
          </select>
          <InputLabel smallLabel={formFields.propertyType.value} htmlFor="propertyType">
            Property Type*
          </InputLabel>
          {formFields.propertyType.error && <ErrorMessage>{formFields.propertyType.error}</ErrorMessage>}
        </SelectContainer>
        <TitleCheckboxWrapper>
          <input
            placeholder=" "
            type="checkbox"
            name="isPreApproved"
            id="isPreApproved"
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
            defaultChecked={false}
          />
          <label htmlFor="isPreApproved">I am pre-approved</label>
        </TitleCheckboxWrapper>

        <ButtonsContainer>
          <CancelButton type="button" onClick={onCancel}>
            {cancelButtonText}
          </CancelButton>
          <SubmitButton onClick={handleSubmit}>{submitButtonText}</SubmitButton>
        </ButtonsContainer>
      </FormContainer>
      <Disclaimer>
        By proceeding, you consent to receive calls and texts at the number you provided, including marketing by
        autodialer and prerecorded and artificial voice, and email, from{' '}
        <a href="https://www.unrealestate.com" target="_blank" rel="noopener noreferrer">
          www.unrealestate.com
        </a>{' '}
        and others about your inquiry and other home-related matters, but not as a condition of any purchase.
        Terms define credit* to include rebates where allowed vs credits or discounts or reduction or nothing if not allowed by law.
      </Disclaimer>
    </>
  );
};

export default BuyingPropertyForm;
