import Routes from '../Constants/Routes';
const adminRoutes = {
  routes: [
    {
      component: 'Admin',
      url: Routes.ADMIN_USERS,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_LISTINGS,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_CASH_OFFERS,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_ORDERS,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_TITLE_COMPANY,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_EDIT_TITLE_COMPANY,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_MLS_SUBMISSION,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_MLS_CATEGORIES,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_MLS_ABBREVIATION,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_PRODUCTS,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_PROPERTY,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_MLS,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_COMPANY,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_COVERAGE,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_VOUCHER_TEMPLATE_LISTING,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_VOUCHER_LISTING,
    },
    {
      component: 'AdminAddUser',
      url: Routes.ADMIN_ADD_EDIT_USER,
    },
    {
      component: 'CategoriesContainer',
      url: Routes.ADD_EDIT_MLS_CATEGORIES,
    },
    {
      component: 'AbbreviationContainer',
      url: Routes.ADMIN_ADD_EDIT_MLS_ABBREVIATION,
    },
    {
      component: 'AddEditFormContainer',
      url: Routes.ADMIN_ADD_EDIT_FORM,
    },
    {
      component: 'PropertyContainer',
      url: Routes.ADD_EDIT_PROPERTY,
    },
    {
      component: 'CompanyContainer',
      url: Routes.ADD_EDIT_COMPANY,
    },
    { component: 'OverlayFormContainer', url: Routes.ADMIN_OVERLAY_FORM },
    {
      component: 'ProductDetails',
      url: Routes.ADMIN_ADD_EDIT_PRODUCT,
    },
    {
      component: 'ProductDetails',
      url: Routes.ADMIN_ADD_PRODUCT,
    },
    {
      component: 'PreviewContainer',
      url: Routes.PREVIEW,
    },
    {
      component: 'AddEditFormContainer',
      url: Routes.ADMIN_CLONE_FORM,
    },
    {
      component: 'MlsPreviewContainer',
      url: Routes.BASIC_FORM_PREVIEW,
    },
    {
      component: 'CoverageContainer',
      url: Routes.ADD_EDIT_COVERAGE,
    },
    {
      component: 'Profile',
      url: Routes.ADMIN_PROFILE,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_LANDING,
    },
    {
      component: 'VoucherTemplateConatiner',
      url: Routes.ADD_EDIT_VOUCHER_TEMPLATE,
    },
    {
      component: 'MlsPreviewContainer',
      url: Routes.MLS_PREVIEW,
    },
    {
      component: 'Admin',
      url: Routes.ADMIN_BUYER_LEADS,
    },
  ],
}

const customerPortalRoutes = {
  routes: [
    {
      component: 'Dashboard',
      url: Routes.DASHBOARD,
    },
    {
      component: 'Dashboard',
      url: Routes.LISTINGS,
    },
    {
      component: 'EditListing',
      url: Routes.EDIT_LISTING,
      exact: false,
    },
    {
      component: 'ViewListing',
      url: Routes.VIEW_LISTING,
    },
    {
      component: 'EditBillingAddressContainer',
      url: Routes.EDIT_BILLING_ADDRESS,
    },
    {
      component: 'EditBillingAddressContainer',
      url: Routes.EDIT_SHIPPING_ADDRESS,
    },
    {
      component: 'PropertyListingForm',
      url: Routes.PROPERTY_LISTING_EDIT_FORM,
      exact: false,
    },
    {
      component: 'MlsCategory',
      url: Routes.MLS_CATEGORY,
    },
  ],
}
export default {
  Retail: customerPortalRoutes,
  InstitutionalManager: customerPortalRoutes,
  WholesaleCustomer: customerPortalRoutes,
  Builder:customerPortalRoutes,
  Flipper:customerPortalRoutes,
  ManagingBroker:customerPortalRoutes,
  ConciergePaid:customerPortalRoutes,
  ConciergeFree:customerPortalRoutes,
  NetworkUser: customerPortalRoutes,
  Landlord:customerPortalRoutes,
  Auctioneer:customerPortalRoutes,
  ListingProcessor: adminRoutes,
  Administrator: adminRoutes,
  SuperAdministrator:adminRoutes,
  OfficeManager:adminRoutes,
  'Wholesale/NetworkManager': {
    routes: [
      {
        component: 'Dashboard',
        url: Routes.DASHBOARD,
      },
      {
        component: 'Dashboard',
        exact: false,
        url: Routes.VOUCHER_LISTINGS,
      },
      {
        component: 'VoucherConatiner',
        exact: false,
        url: Routes.ADD_EDIT_VOUCHER,
      },
    ]
  },
  common: {
    routes: [
      {
        component: 'NotFoundContainer',
      },
      {
        component: 'PropertyListing',
        url: Routes.ONBOARDING_V2,
      },
      {
        component: 'Profile',
        url: Routes.PROFILE,
      },
    ],
  },
};
