import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import v8n from 'v8n';

import { regexPattern, validationMessages } from '../../../Constants/CommonConstants';
import { userActionCreators } from '../../../Actions/UserActions';
import { ImagesUrl } from '../../../Constants/Images';
import { TelescopeIcon } from '../Components/Icons/TelescopeIcon';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import { UIActionsCreators } from '../../../Actions/UIActions';

const ModalOverlay = styled.div`
  opacity: 1;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
  z-index: 1400;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1400;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  background: ${({ backgroundImage }) => (backgroundImage ? `url(${ImagesUrl.LOGIN_BACKGROUND})` : 'unset')};
  overflow: auto;
`;

const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 550px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius:  0.375rem;
  background: white;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  z-index: 1400;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 28rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    width: 28rem;
  }
}
`;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 0 1 0%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  p {
    position: relative;
    margin-bottom: 0;
    margin-left: 1rem;
    padding-bottom: 0.5rem;
    top: 0.25rem;
  }
  button {
    display: inline-flex;
    appearance: none;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: baseline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: min-content;
    line-height: normal;
    border-radius: 0.375rem;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    height: auto;
    min-width: 2.5rem;
    font-size: 14px;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding: 0px;
    color: #718096;
    background-color: #ffffffff;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.colors.green[900]};
    font-weight: 700;
    font-family: 'National-Semi-Bold';
  }
}
`;

const FormContainer = styled.span`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex: 1 1 0%;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 70%;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 2.5rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 1rem;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel
        ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
        : ''}
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.violet[200]};
`;

const CardTitle = styled.span`
  text-align: left;
  font-size: 24px;
  line-height: 150%;
  padding-bottom: 1rem;
`;

const CardText = styled.span`
  text-align: left;
  font-size: 18px;
  line-height: 150%;
`;

const SelectContainer = styled.div`
  min-width: 20rem;
  position: relative;
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input,
  textarea,
  select {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    margin-top: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    border-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#242B26')};
  }
  select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }

  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const SelectInputLabel = styled.label`
  opacity: 1;
  top: 0px;
  left: 0px;
  z-index: 2;
  position: absolute;
  background-color: white;
  pointer-events: none;
  margin-inline-start: 0.75rem;
  margin-inline-end: 0.75rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  transform-origin: left top;
  transition: scale 0.2s, translateY 0.2s, transform 0.2s;
  transition-timing-function: linear;
  display: block;
  text-align: start;
  font-weight: 500;
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.mouseballgray[100] : theme.colors.light)};

  ${({ smallLabel }) =>
    smallLabel
      ? `
    transform: scale(0.85) translateY(-22px);
    font-size: 12px;
    opacity: 1;
    `
      : ''}

  ${({ select }) =>
    select
      ? `
    display: none;
    &:has(+ (select(select:focus)) {
    display: block;
    }
    `
      : ''}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const CancelButton = styled(SubmitButton)`
  background: none;
  font-size: 1rem;
  font-weight: normal;
  font-family: 'National-Regular';
  width: 4rem;
  margin-right: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const residentialIntelligenceRoleTypes = {
  Appraiser: 'appraiser',
  Agent: 'agent',
  Broker: 'broker',
  Developer: 'developer',
  GeneralContractor: 'generalContractor',
  Investor: 'investor',
  Lender: 'lender',
};

const residentialIntelligenceRoles = [
  {
    label: 'Investor',
    value: residentialIntelligenceRoleTypes.Investor,
  },
  {
    label: 'Broker',
    value: residentialIntelligenceRoleTypes.Broker,
  },
  {
    label: 'Agent',
    value: residentialIntelligenceRoleTypes.Agent,
  },
  {
    label: 'Appraiser',
    value: residentialIntelligenceRoleTypes.Appraiser,
  },
  {
    label: 'Developer',
    value: residentialIntelligenceRoleTypes.Developer,
  },
  {
    label: 'General Contractor',
    value: residentialIntelligenceRoleTypes.GeneralContractor,
  },
  {
    label: 'Lender',
    value: residentialIntelligenceRoleTypes.Lender,
  },
];

const ResidetialIntelligenceModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(userDetailsSelector);
  const [joinFormFields, setJoinFormFields] = useState({
    email: {
      value: userDetails?.email || '',
      error: null,
    },
    role: {
      value: '',
      error: null,
    },
  });

  const formValidationRules = {
    email: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.email,
      },
      {
        rule: v8n().pattern(regexPattern.EMAIL),
        message: validationMessages.validEmail,
      },
    ],
    role: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
  };

  const handleJoin = (e) => {
    e.preventDefault();
    let isError = false;

    ['email', 'role'].forEach((k) => {
      const isErrorExist = validateField(k, joinFormFields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    });

    if (!isError) {
      const userData = {
        email: joinFormFields.email.value,
        role: joinFormFields.role.value,
      };
      dispatch(userActionCreators.joinResidentialIntelligenceList(userData, () => {
        dispatch(UIActionsCreators.closeIntelligenceModal());
      }));
    }
  };

  const handleFieldChanged = (e) => {
    e.persist();
    setJoinFormFields((pr) => ({ ...pr, [e.target.name]: { ...pr[e.target.name], value: e.target.value } }));
  };

  const handleCloseModal = () => {
    dispatch(UIActionsCreators.closeIntelligenceModal());
  };

  const validateField = (key, value) => {
    let fields = { ...joinFormFields };
    let isError = false;
    for (let i = 0; i < formValidationRules[key].length; i++) {
      let rule = formValidationRules[key][i];
      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setJoinFormFields((pr) => ({ ...pr, ...fields }));

    return isError;
  };

  const changeRole = (e) => {
    e.persist();
    validateField('role', e.target.value);
    setJoinFormFields((pr) => ({ ...pr, role: { ...pr.role, value: e.target.value }}));
  };

  return (
    <ContentContainer>
      <ModalOverlay onClick={handleCloseModal} />
      <ModalContainer>
        <HeaderContainer>
          <TelescopeIcon />
          <p>Residential Intelligence</p>
        </HeaderContainer>
        <Card>
          <CardTitle>Instantly evaluate any property or market Nationwide</CardTitle>
          <CardText>
            Get access to over x million sales comps, property records, insights, and more. Full access to all the data
            and analytics you need to buy or sell residential real estate in your market.
          </CardText>
        </Card>
        <FormContainer>
          <form onSubmit={handleJoin}>
            <InputContainer className={joinFormFields.email.error ? 'invalid' : ''}>
              <input
                placeholder=" "
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                value={joinFormFields.email.value}
                onChange={handleFieldChanged}
              />
              <InputLabel smallLabel={joinFormFields.email.value} htmlFor="email">
                Email address *
              </InputLabel>
              {joinFormFields.email.error && <ErrorMessage>{joinFormFields.email.error}</ErrorMessage>}
            </InputContainer>
            <SelectContainer error={joinFormFields.role.error}>
              <SelectInputLabel smallLabel={true} error={joinFormFields.role.error}>
                What best describes you?
              </SelectInputLabel>
              <select label="role" value={joinFormFields.role.value} onChange={changeRole}>
                <option key={-1} value="">
                  Select value
                </option>
                {residentialIntelligenceRoles.map((x, idx) => (
                  <option key={idx} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </select>
              {joinFormFields.role.error && <ErrorMessage>{joinFormFields.role.error}</ErrorMessage>}
            </SelectContainer>
            <ButtonsContainer>
                <CancelButton type="button" onClick={() => dispatch(UIActionsCreators.closeIntelligenceModal())}>
                  Cancel
                </CancelButton>
                <SubmitButton type="submit">Join the waitlist</SubmitButton>
              </ButtonsContainer>
          </form>
        </FormContainer>
      </ModalContainer>
    </ContentContainer>
  );
};

export default ResidetialIntelligenceModal;
