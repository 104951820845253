import React from 'react';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import {
  PublicListingWrapper,
  CustomButton,
  InputFlex,
  CustomTextarea,
  SocialTextRow,
  SocialList,
  SocialListLink,
  SocialListItem,
} from '../../../Assets/Style/commonStyleComponents';
import { intialRequestMoreEmptyState } from '../../../Utilities/commonFunctions';

class PublicListingDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestUsForm: intialRequestMoreEmptyState()
    };
  }

  submitFormData = (formData) => {
    formData.propertyUrl = window.location.href;
    formData.listingId = this.props.listingId;
    this.props.submitRequestMoreDetail(formData, () => {
      this.setState({
        requestUsForm: intialRequestMoreEmptyState()
      })
    });
  }

  handleFieldChanged = (e) => {
    let requestUsForm = { ...this.state.requestUsForm };
    requestUsForm[e.target.name].value = e.target.value;
    this.setState({ requestUsForm: { ...requestUsForm } });
  }

  handleFieldBlur = (e) => {
    this.validateField(e.target.name, e.target.value);
  }

  validateField = (key, value) => {
    let requestUsForm = { ...this.state.requestUsForm };
    let isError = false;
    for (let i = 0; i < requestUsForm[key].rules.length; i++) {
      let rule = requestUsForm[key].rules[i];
      if (!rule.rule.test(value)) {
        requestUsForm[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      requestUsForm[key].error = null;
    }

    this.setState({ requestUsForm: { ...requestUsForm }, isError: Object.keys(requestUsForm).some(k => requestUsForm[k].error !== undefined && requestUsForm[k].error !== null) });
    return isError;
  }

  validateAndSubmit = (e) => {
    e.preventDefault();
    let isError = false;

    Object.keys(this.state.requestUsForm).forEach((k) => {
      const isErrorExist = this.validateField(k, this.state.requestUsForm[k].value);
      if (isErrorExist) {
        isError = true;
      }
    })

    if (!isError) {
      this.submitFormData(
        {
          ...(Object.keys(this.state.requestUsForm).reduce((retVal, key) => {
            retVal[key] = this.state.requestUsForm[key].value;
            return retVal;
          }, {}))
        });
    }
  }

  render() {
    const { requestUsForm } = this.state;
    const createMarkup = (description) => ({ __html: description });
    return (
      <PublicListingWrapper className="for-public-listing">
        {
          !this.props?.isFetching &&
          <div className="public-list-row">
            <div className="public-list-col-first">
              <div className="listing-address">
                <span className="primary-text">{this.props.listing.address1} {this.props.listing.address2}</span>
                <span>{this.props.listing.city}, {this.props.listing.state} {this.props.listing.zip}</span>
              </div>
              <div className="listing-price">
                <span className="primary-text">
                  <CurrencyFormat
                    value={this.props.listing.listPrice || 0}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </span>
              </div>
              <div className="listing-detail">
                <span className="primary-text">{this.props.listing.bedrooms || 0}</span> bedrooms, {' '}
                <span className="primary-text">{this.props.listing.bathrooms || 0}</span> bathrooms,{' '}
                <span className="primary-text">{this.props.listing.squareFeet || 0}</span> sq. ft.,{' '}
                <span className="primary-text">{this.props.listing.lotAcreage || 0}</span> acre lot
              </div>
              <div className="listing-detail-2 text-black">
                <h3>{this.props?.listing?.daysOnMarket || 0} DAYS ON MARKET</h3>
              </div>
              {
                !!this.props?.listing?.description &&
                <div className="listing-detail-2">
                  <h3>Property Details</h3>
                  <div dangerouslySetInnerHTML={createMarkup(this.props?.listing?.description)}></div>
                </div>
              }
            </div>
            <div className="public-list-col-second">
              <InputFlex
                className="inputgap"
                name={requestUsForm?.fullName?.name}
                title={requestUsForm?.fullName?.title}
                value={requestUsForm?.fullName?.value}
                error={requestUsForm?.fullName?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <InputFlex
                type="email"
                name={requestUsForm?.email?.name}
                title={requestUsForm?.email?.title}
                value={requestUsForm?.email?.value}
                error={requestUsForm?.email?.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <InputFlex
                className="inputgap"
                name={requestUsForm?.phoneNumber?.name}
                title={requestUsForm?.phoneNumber?.title}
                value={requestUsForm?.phoneNumber?.value}
                mask={requestUsForm.phoneNumber.mask}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <CustomTextarea>
                <label>{requestUsForm?.comments?.title}</label>
                <textarea
                  type="textarea"
                  name={requestUsForm?.comments?.name}
                  value={requestUsForm?.comments?.value}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </CustomTextarea>
              <CustomButton type="submit" className="block" onClick={this.validateAndSubmit}>Request More Details</CustomButton>
              <h4 className="call-text">or call (866) 534.3726</h4>
              <p className="sub-text">By sending a request you <br /> agree to our Privacy Policy.</p>
              <SocialTextRow className="social-center">
                <SocialList>
                  <SocialListItem><SocialListLink><i className="fab fa-facebook-f"></i></SocialListLink></SocialListItem>
                  <SocialListItem><SocialListLink><i className="fab fa-google"></i></SocialListLink></SocialListItem>
                  <SocialListItem><SocialListLink><i className="fab fa-linkedin-in"></i></SocialListLink></SocialListItem>
                </SocialList>
              </SocialTextRow>
            </div>
          </div>
        }
      </PublicListingWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails
  };
}

export default connect(mapStateToProps)(PublicListingDetail);
