import { onboardingService } from '../Services/OnboardingService';
import { alertActions } from './ToastActions';
import { staticMessages } from '../Constants/CommonConstants';
import { startLoading, endLoading } from './LoaderActions';
import { checkResponse, getLocalUserData } from '../Utilities/commonFunctions';
import { userService } from '../Services/UserService';
import { onboardingServiceV2 } from '../Services/OnboardingServiceV2';

export const actions = {
    SELECT_ADDRESS: 'SELECT_ADDRESS',
    CLEAR_PLANS: 'CLEAR_PLANS',
    PLANS_REQUESTED: 'PLANS_REQUESTED',
    PLANS_RESOLVED: 'PLANS_RESOLVED',
    SELECT_PLAN : 'SELECT_PLAN',
    VOUCHER_REDEEM: 'VOUCHER_REDEEM',
    PLACE_ORDER_REQUESTED : 'PLACE_ORDER_REQUESTED',
    PLACE_ORDER_RESOLVED: 'PLACE_ORDER_RESOLVED',
    FINISH_ONBOARDING: 'FINISH_ONBOARDING',
    ADDRESS_NOT_SERVICEABLE: 'ADDRESS_NOT_SERVICEABLE',
    CLOSE_MODAL_POPUP: 'CLOSE_MODAL_POPUP',
    ADDRESS_NOT_SERVICEABLE_REQUEST: 'ADDRESS_NOT_SERVICEABLE_REQUEST',
    ADDRESS_NOT_SERVICEABLE_RESOLVED: 'ADDRESS_NOT_SERVICEABLE_RESOLVED',
    CONTACT_US_REQUEST: 'CONTACT_US_REQUEST',
    CONTACT_US_REQUEST_RESOLVED: 'CONTACT_US_REQUEST_RESOLVED',
    GLOBAL_SUMMARY_REQUEST: 'GLOBAL_SUMMARY_REQUEST',
    GLOBAL_SUMMARY_REQUEST_RESOLVED: 'GLOBAL_SUMMARY_REQUEST_RESOLVED',
    VOUCHER_VALIDATE_REQUESTED: 'VOUCHER_VALIDATE_REQUESTED',
    VOUCHER_VALIDATE_REQUEST_RESOLVED: 'VOUCHER_VALIDATE_REQUEST_RESOLVED',
    MLS_CATEGORY_REQUEST: 'MLS_CATEGORY_REQUEST',
    MLS_CATEGORY_RESOLVE: 'MLS_CATEGORY_RESOLVE',
    LISTING_MLS_CATEGORY_REQUEST: 'LISTING_MLS_CATEGORY_REQUEST',
    LISTING_MLS_CATEGORY_RESOLVE: 'LISTING_MLS_CATEGORY_RESOLVE',
    CHECK_ADDRESS_COVERAGE_REQUESTED: 'CHECK_ADDRESS_COVERAGE_REQUESTED',
    CHECK_ADDRESS_COVERAGE_RESOLVED: 'CHECK_ADDRESS_COVERAGE_RESOLVED',
    PRODUCT_REQUESTED: 'PRODUCT_REQUESTED',
    PRODUCT_RESOLVED: 'PRODUCT_RESOLVED',
    PAYMENT_INTENT_REQUESTED: 'PAYMENT_INTENT_REQUESTED',
    PAYMENT_INTENT_RESOLVED: 'PAYMENT_INTENT_RESOLVED',
}
export const onboardingActionCreators = {
    selectAddress: (address) => async (dispatch) => {
        if (Object.keys(address).length > 0) {
            const isPostalCode = address.address_components.filter(c => c.types.includes("postal_code")).length;
            if (isPostalCode < 1) {
                dispatch({ type: actions.CHECK_ADDRESS_COVERAGE_RESOLVED });
            }
        }
        dispatch({ type: actions.SELECT_ADDRESS, payload: address });
        dispatch({ type: actions.CLEAR_PLANS });
    },
    finishOnboarding: () => async (dispatch) => {
        dispatch({ type: actions.FINISH_ONBOARDING });
    },
    getListingProducts: () => async (dispatch, getState) => {
        let { county, state, zip } = getState().onboarding.selectedAddress;
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        if (zip) {
            dispatch({ type: actions.PLANS_REQUESTED, payload: { county, state, zip } });
            let isError = false;
            let dataJson;
            const selectedPlanFromHome = onboardingService.getLocal("isFromHomePage");
            try {
                const { data } = await onboardingService.getListingProducts({ county, state, zip });
                dataJson = data;
                checkResponse(data);
                if(selectedPlanFromHome?.isFromHome && dataJson?.value?.plans.filter(plan => selectedPlanFromHome?.plan?.productId === plan.productId)) {
                    dispatch({ type: actions.SELECT_PLAN, payload: selectedPlanFromHome?.plan });
                }
                dispatch(endLoading('MAIN_LOADER', 'Loading'));
            }
            catch (ex) {
                isError = true;
                dispatch({ type: actions.ADDRESS_NOT_SERVICEABLE });
                dispatch(endLoading('MAIN_LOADER', 'Loading'));
                const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
                dispatch(alertActions.errorAlert(errorMessage));
            }
            dispatch({ type: actions.PLANS_RESOLVED, payload: dataJson, isError: isError });
        } else {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
    },
    getProducts: (reqObj) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: actions.PRODUCT_REQUESTED, payload: reqObj });
        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.getProducts(reqObj);
            dataJson = data;
            checkResponse(data);
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.PRODUCT_RESOLVED, payload: dataJson, isError: isError });
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
    },
    createPaymentIntent: (productId) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: actions.PAYMENT_INTENT_REQUESTED, payload: productId });
        try {
            const { data } = await userService.createPaymentIntent(productId);
            const dataJson = data;
            checkResponse(data);
            dispatch({ type: actions.PAYMENT_INTENT_RESOLVED, payload: dataJson, isError: false });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            return dataJson;
        }
        catch (ex) {
            dispatch({ type: actions.PAYMENT_INTENT_RESOLVED, payload: null, isError: true });
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
    },
    closeModal: () => (dispatch) => {
        dispatch({ type: actions.CLOSE_MODAL_POPUP });
    },
    selectPlan: (plan) => async (dispatch) => {
        dispatch({ type: actions.SELECT_PLAN, payload: plan });
    },

    voucherRedeem: (data) => async (dispatch) => {
        dispatch({ type: actions.VOUCHER_REDEEM, payload: data });
    },
    placeOrder: (billingDetails) => async (dispatch, getState) => {
        dispatch({ type: actions.PLACE_ORDER_REQUESTED, payload: billingDetails });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        let isError = false;
        let dataJson;
        let onboarding = getState().onboarding;
        let plan = onboarding.selectedPlan;
        let listingAddress = !!billingDetails?.isOrderByVoucher ? onboardingService.getLocal("selectedAddress") : onboarding.selectedAddress;
        const unit = onboardingService.getLocal("selectedAddress")?.unit || '';
        try {
            const { data } = await onboardingService.placeOrder({
                isAddressSame: billingDetails.isAddressSame,
                cardholderName: billingDetails.cardholderName,
                cardToken: billingDetails.cardToken,
                productId: plan.productId,
                billingAddress: {
                    address1: billingDetails.address1,
                    address2: billingDetails.address2,
                    city: billingDetails.city,
                    state: billingDetails.state,
                    country: billingDetails.country,
                    zip: billingDetails.zip
                },
                shippingAddress: !billingDetails.isAddressSame ? {
                    firstName: billingDetails.shippingFirstName,
                    lastName: billingDetails.shippingLastName,
                    address1: billingDetails.shippingAddress1,
                    address2: billingDetails.shippingAddress2,
                    city: billingDetails.shippingCity,
                    state: billingDetails.shippingState,
                    country: billingDetails.shippingCountry,
                    zip: billingDetails.shippingZip,
                } : null,
                listingAddress: {
                    unit,
                    address1: !!billingDetails?.isOrderByVoucher ? listingAddress.addressAddress1 : listingAddress.address1,
                    address2: !!billingDetails?.isOrderByVoucher ? listingAddress.addressAddress2 : listingAddress.address2,
                    city: !!billingDetails?.isOrderByVoucher ? listingAddress.addressCity : listingAddress.city,
                    state: !!billingDetails?.isOrderByVoucher ? listingAddress.addressState : listingAddress.state,
                    zip: !!billingDetails?.isOrderByVoucher ? listingAddress.addressZip : listingAddress.zip,
                    county: !!billingDetails?.isOrderByVoucher ? listingAddress.addressCounty : listingAddress.county,
                    latitude: !billingDetails?.isOrderByVoucher ? listingAddress.location.lat : listingAddress.lat,
                    longitude: !billingDetails?.isOrderByVoucher ? listingAddress.location.lng : listingAddress.lng
                },
                IsOrderByInstitutionalUser: !!billingDetails?.IsOrderByInstitutionalUser,
                isOrderByVoucher: !!billingDetails?.isOrderByVoucher,
                IsOrderByUnbounceUser: !!billingDetails?.IsOrderByUnbounceUser,
                voucherInfo: billingDetails?.voucherInfo
            });

            dataJson = data;
            checkResponse(data);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }

        dispatch({ type: actions.PLACE_ORDER_RESOLVED, payload: dataJson, isError: isError });
    },
    nonServiceableAreaRequest: (userInfo) => async (dispatch) => {

        dispatch({ type: actions.ADDRESS_NOT_SERVICEABLE_REQUEST, payload: userInfo });

        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.nonServiceableAreaRequest(userInfo);
            dataJson = data;
            checkResponse(data);
        } catch (ex) {
            isError = true;
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.ADDRESS_NOT_SERVICEABLE_RESOLVED, payload: dataJson, isError: isError });
    },

    contactUsUserRequest: (userInfo, cb) => async (dispatch) => {

        dispatch({ type: actions.CONTACT_US_REQUEST, payload: userInfo });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));

        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.contactUsUserRequest(userInfo);
            dataJson = data;
            checkResponse(data);
            cb();
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.CONTACT_US_REQUEST_RESOLVED, payload: dataJson, isError: isError });
    },

    globalSummaryRequest: (reqObj, cb) => async (dispatch) => {

        dispatch({ type: actions.GLOBAL_SUMMARY_REQUEST, payload: reqObj });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));

        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.globalSummaryRequest(reqObj);
            dataJson = data;
            checkResponse(data);
            cb();
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.GLOBAL_SUMMARY_REQUEST_RESOLVED, payload: dataJson, isError: isError });
    },
    getDefaultListingProducts: (reqObj) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: actions.PLANS_REQUESTED, payload: reqObj });
        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.getListingProducts(reqObj);
            dataJson = data;
            checkResponse(data);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.PLANS_RESOLVED, payload: dataJson, isError: isError });
    },

    voucherValidateRequest: (reqObj, cb) => async (dispatch) => {

        dispatch({ type: actions.VOUCHER_VALIDATE_REQUESTED, payload: reqObj });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));

        let isError = false;
        let dataJson;
        let error;
        try {
            const { data } = await onboardingService.validateVoucherRequest(reqObj);
            dataJson = data;
            checkResponse(data);
            if(!dataJson.value){
                dataJson.value={'code':dataJson.code};
            }
            dataJson.value['code']=dataJson.code;
            cb(dataJson.value);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            error = ex;
            console.log('voucherValidateRequest:' + ' ' + JSON.stringify(ex));
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.VOUCHER_VALIDATE_REQUEST_RESOLVED, payload: dataJson, isError: isError, error });
    },

    getMlsCategory: () => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: actions.MLS_CATEGORY_REQUEST });
        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.getMlsCategory();
            dataJson = data;
            checkResponse(data);
            const newArray = dataJson.value.map(item => {
                return { value: item.key, label: item.value };
            });
            dataJson.value = newArray;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.MLS_CATEGORY_RESOLVE, payload: dataJson, isError: isError });
    },

    listingMlsCategory: (reqObj, cb) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: actions.LISTING_MLS_CATEGORY_REQUEST });
        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.listingMlsCategory(reqObj);
            dataJson = data;
            checkResponse(data);
            cb(dataJson.value.redirect);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        dispatch({ type: actions.LISTING_MLS_CATEGORY_RESOLVE, payload: dataJson, isError: isError });
    },

    checkAddressCoverage: (data, unbounce = false, cb = () => {}) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        const selectedAddress = data || onboardingService.getLocal("selectedAddress");
        const reqObj = {
            county: selectedAddress.county,
            state: selectedAddress.state?selectedAddress.state:selectedAddress.addressState,
            zip: selectedAddress.zip?selectedAddress.zip:selectedAddress.addressZip,
            address: selectedAddress.formatted?selectedAddress.formatted:selectedAddress.addressAddress1,
            userId: getLocalUserData()?.userId,
        }
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        let isError = false;
        let dataJson;
        try {
            const { data } = await onboardingService.checkAddressCoverage(reqObj, unbounce);
            dataJson = data;
            if (!dataJson.success) {
                cb(false);
            } else {
                cb(true);
            }
            checkResponse(data);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
        }
        catch (ex) {
            isError = true;
            cb(false);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
        if(!dataJson?.value && !unbounce){
            cb(false);
            dispatch({ type: actions.ADDRESS_NOT_SERVICEABLE, payload: dataJson, isError: isError });
        }
        return;
    },
    //TODO: Replace on onboarding actions V2 then
    checkMLSMarket: (data) => async (dispatch) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        const selectedAddress = data || onboardingService.getLocal("selectedAddress");
        const county = selectedAddress?.county;
        const state = selectedAddress.state?selectedAddress.state:selectedAddress.addressState;
        const zip = selectedAddress.zip?selectedAddress.zip:selectedAddress.addressZip;
        const address = selectedAddress.formatted?selectedAddress.formatted:selectedAddress.addressAddress1;
        const reqObj = { userId: getLocalUserData()?.userId };

        if (county) { reqObj.county = county }
        if (state) { reqObj.state = state }
        if (zip) { reqObj.zip = zip }
        if (address) { reqObj.address = address }

        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const query = new URLSearchParams(reqObj).toString();
            const { data } = await onboardingServiceV2.checkAddressV2(query);
            dispatch(endLoading('MAIN_LOADER', 'Loading'));

            if (!data.hasMls) {
                dispatch({ type: actions.ADDRESS_NOT_SERVICEABLE });
            }
        }
        catch (ex) {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
            dispatch(alertActions.errorAlert(errorMessage));
        }
    },
};