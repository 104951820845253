import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotFoundContainer } from "../App/Shared/404";
import UnbounceListing from "../App/Shared/Containers/UnbounceListing";
import PublicViewListing from "../App/Shared/Containers/PublicViewListing";
import ContactUs from "../App/Shared/ExternalPages/ContactUs";
import Glossary from "../App/Shared/ExternalPages/Resources/Glossary";
import Checklists from "../App/Shared/ExternalPages/Resources/Checklists";
import HomeSellingMain from "../App/Shared/ExternalPages/Resources/HomeSelling/HomeSellingMain";
import HowItWorks from "../App/Shared/ExternalPages/HowItWorks";
import PlanPricing from "../App/Shared/ExternalPages/PlanPricing";
import ResetPassword from "../App/Shared/ExternalPages/ResetPassword";
import TermAndCondition from "../App/Shared/ExternalPages/TermAndCondition";
import ForgotPassword from "../App/Shared/ExternalPages/ForgotPassword";
import About from "../App/Shared/ExternalPages/Company/About";
import Agents from "../App/Shared/ExternalPages/Company/Agents";
import Commercial from "../App/Shared/ExternalPages/Company/Commercial";
import Press from "../App/Shared/ExternalPages/Company/Press";
import MediaCoverage from "../App/Shared/ExternalPages/Company/MediaCoverage";
import LoadingOverlay from "react-loading-overlay-ts";
import Faq from "../App/Shared/ExternalPages/Resources/Faq";
import Ebook from "../App/Shared/ExternalPages/Resources/HomeSelling/Ebook";
import LandingFormView from "../App/UnbouncePortal/Components/LandingFormView";
import ZipNotSupported from "../App/Shared/ExternalPages/UnbounceZipNotSupported";
import ShowingRequest from "../App/SellerNotification/Containers/ShowingRequest";
import OfferSubmit from "../App/SellerNotification/Containers/OfferSubmit";
import Routes from "../Constants/Routes";
import PropertyListing from "../App/ListYourHome/Containers/PropertyListing";
import SignInPage from "../App/Shared/Containers/SignInPage";
import SignUpPage from "../App/Shared/Containers/SignUpPage";
import AppConsts from "../Constants/AppConsts";

const PublicRoutes = ({ match, ...props }) => {
  return (
    <div className="overlap-container">
      <React.Suspense
        fallback={<LoadingOverlay active={true} spinner text="" className="white-loader"></LoadingOverlay>}
      ></React.Suspense>
      <Switch>
        <Route exact path={`${match.path}`} component={() => {
            window.location.href = AppConsts.buySideUrl;
            return null;
        }} />
        <Route exact path={Routes.ONBOARDING_V2} component={PropertyListing} />
        <Route exact path={`${match.path}list-your-home`} component={PropertyListing} />
        <Route exact path={`${match.path}list-your-home/voucher`} component={PropertyListing} />
        <Route exact path={`${match.path}forgot-password`} component={ForgotPassword} />
        <Route exact path={`${match.path}reset-password/`} component={ResetPassword} />
        <Route path={`${match.path}public-listing/:listingId`} component={PublicViewListing} />
        <Route exact path={`${match.path}terms-conditions`} component={TermAndCondition} />
        <Route path={`${match.path}how-it-work`} exact component={HowItWorks} />
        <Route exact path={`${match.path}plan-pricing`} component={PlanPricing} />
        <Route exact path={`${match.path}about`} component={About} />
        <Route exact path={`${match.path}contact-us`} component={ContactUs} />
        <Route exact path={`${match.path}commercial`} component={Commercial} />
        <Route exact path={`${match.path}glossary`} component={Glossary} />
        <Route exact path={`${match.path}checklists`} component={Checklists} />
        <Route exact path={`${match.path}for-agents`} component={Agents} />
        <Route exact path={`${match.path}press`} component={Press} />
        <Route exact path={`${match.path}press/media-coverage`} component={MediaCoverage} />
        <Route exact path={`${match.path}home-selling-guide`} component={HomeSellingMain} />
        <Route exact path={`${match.path}the-ready-to-sell-process`} component={HomeSellingMain} />
        <Route exact path={`${match.path}getting-your-home-ready`} component={HomeSellingMain} />
        <Route exact path={`${match.path}guide-to-marketing-your-home`} component={HomeSellingMain} />
        <Route exact path={`${match.path}guide-to-contracts-closing`} component={HomeSellingMain} />
        <Route exact path={`${match.path}guide-to-home-selling-book`} component={HomeSellingMain} />
        <Route exact path={`${match.path}faq`} component={Faq} />
        <Route exact path={`${match.path}ebook`} component={Ebook} />
        <Route exact path={`${match.path}unbounce/signup`} component={LandingFormView} />
        <Route exact path={`${match.path}unbounce/listing`} component={UnbounceListing} />
        <Route exact path={`${match.path}unbounce/zip-not-supported`} component={ZipNotSupported} />
        <Route exact path={Routes.SHOWING_REQUEST} component={ShowingRequest} />
        <Route exact path={Routes.SHOWING_REQUEST_FORM} component={ShowingRequest} />
        <Route exact path={Routes.OFFER_SUBMIT} component={OfferSubmit} />
        <Route exact path={Routes.OFFER_SUBMIT_FORM} component={OfferSubmit} />
        <Route exact path={Routes.SIGN_IN} component={SignInPage} />
        <Route exact path={Routes.SIGN_UP} component={SignUpPage} />
        <Route component={NotFoundContainer} />
      </Switch>
    </div>
  );
};

export { PublicRoutes };
