import LogRocket from 'logrocket';
import { getNavbarAndFooterContent } from '../Utilities/GraphqlApiClient';

export const UIActions = {
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
  OPEN_SIGNUP_MODAL: 'OPEN_SIGNUP_MODAL',
  CLOSE_SIGNUP_MODAL: 'CLOSE_SIGNUP_MODAL',
  SET_ACCESS_DENIED: 'SET_ACCESS_DENIED',
  TOGGLE_AUTH_MODALS: 'TOGGLE_AUTH_MODALS',
  OPEN_COMPARE_PLANS_MODAL: 'OPEN_COMPARE_PLANS_MODAL',
  CLOSE_COMPARE_PLANS_MODAL: 'CLOSE_COMPARE_PLANS_MODAL',
  GET_NAVBAR_AND_FOOTER_CONTENT_REQUESTED: 'GET_NAVBAR_AND_FOOTER_CONTENT_REQUESTED',
  GET_NAVBAR_AND_FOOTER_CONTENT_RESOLVED: 'GET_NAVBAR_AND_FOOTER_CONTENT_RESOLVED',
  GET_NAVBAR_AND_FOOTER_CONTENT_FAILED: 'GET_NAVBAR_AND_FOOTER_CONTENT_FAILED',
  SET_LOGIN_MODAL_MESSAGE: 'SET_LOGIN_MODAL_MESSAGE',
  CLOSE_INTELLIGENCE_MODAL: 'CLOSE_INTELLIGENCE_MODAL',
  OPEN_INTELLIGENCE_MODAL: 'OPEN_INTELLIGENCE_MODAL',
};

export const UIActionsCreators = {
  openLoginModal: ({ message, fixed, emailConfirmationRedirectUrl, loginRedirectUrl, loginModalMessageType } = {}) => ({
    type: UIActions.OPEN_LOGIN_MODAL,
    payload: {
      message,
      fixed,
      emailConfirmationRedirectUrl,
      loginRedirectUrl,
      loginModalMessageType,
    },
  }),
  closeLoginModal: () => ({
    type: UIActions.CLOSE_LOGIN_MODAL,
  }),
  openSignUpModal: () => ({
    type: UIActions.OPEN_SIGNUP_MODAL,
  }),
  closeSignUpModal: () => ({
    type: UIActions.CLOSE_SIGNUP_MODAL,
  }),
  toggleAuthModals: () => ({
    type: UIActions.TOGGLE_AUTH_MODALS,
  }),
  setAccessDenied: () => ({
    type: UIActions.SET_ACCESS_DENIED,
  }),
  openComparePlansModal: () => ({
    type: UIActions.OPEN_COMPARE_PLANS_MODAL,
  }),
  closeComparePlansModal: () => ({
    type: UIActions.CLOSE_COMPARE_PLANS_MODAL,
  }),
  getNavbarAndFooterContent: () => async (dispatch) => {
    dispatch({ type: UIActions.GET_NAVBAR_AND_FOOTER_CONTENT_REQUESTED });
    try {
      const content = await getNavbarAndFooterContent();
      dispatch({ type: UIActions.GET_NAVBAR_AND_FOOTER_CONTENT_RESOLVED, payload: content });
    } catch (error) {
      dispatch({ type: UIActions.GET_NAVBAR_AND_FOOTER_CONTENT_FAILED });
      LogRocket.captureException(error, {
        tags: {
          errorType: 'failed_admin_actions_request',
        },
        extra: {
          type: UIActions.GET_NAVBAR_AND_FOOTER_CONTENT_FAILED,
        },
      });
    }
  },
  setLoginModalMessage: ({ message, loginModalMessageType } = {}) => ({
    type: UIActions.SET_LOGIN_MODAL_MESSAGE,
    payload: {
      message,
      loginModalMessageType,
    },
  }),
  openIntelligenceModal: () => ({
    type: UIActions.OPEN_INTELLIGENCE_MODAL,
  }),
  closeIntelligenceModal: () => ({
    type: UIActions.CLOSE_INTELLIGENCE_MODAL,
  }),
};
