import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { dateFormat } from '../../../Constants/CommonConstants';
import { momentDateFormat } from '../../../Utilities/commonFunctions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableCellTimer = ({ dateCreated, mls, processingInterval }) => {
  const [timeElapsed, setTimeElapsed] = useState('');

  useEffect(() => {
    if (mls) {
      setTimeElapsed(processingInterval || '--/--:--')
    } else {
      setTimeElapsed(moment(Date.now()).subtract(moment(dateCreated)).format(dateFormat.DAYS_HOURS_MINUTES))
      let interval = setInterval(() => {
        setTimeElapsed(moment(Date.now()).subtract(moment(dateCreated)).format(dateFormat.DAYS_HOURS_MINUTES))
      }, 60000);
      return () => clearInterval(interval)
    }
  }, [dateCreated, mls, processingInterval])

  return (
    <Container>
      <span>{momentDateFormat(dateCreated, dateFormat.DATEMODIFIEDFROMAT)}</span>
      <span>{timeElapsed}</span>
    </Container>
  )
}
