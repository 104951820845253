import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';

class FormService {

  getForms(params) {
    const query = new URLSearchParams(params).toString();
    const url = `api/v2/forms?${query}`;

    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }
}

export const formService = new FormService();
