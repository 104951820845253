import React from 'react';
import { ImagesUrl } from "../../../../Constants/Images";
import styled from 'styled-components';
import { Container } from '../../../../Assets/Style/commonStyleComponents';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';

const AgentBanner = styled.div`
  background: url(${ImagesUrl.BG_FPO_WRK});
  background-size:cover;
  background-position:0 0;
  text-align:center;
  min-height:700px;
  display: flex;
  align-items: center;
  @media(max-width: 768px) {
    min-height: 400px;
  }
  h2 {
    color: white;
    font-size: 65px;
    @media(max-width: 768px) {
      font-size: 34px;
    }
  }
  p {
    color: white;
    line-height: 1.8;
    max-width: 900px;
    margin: 0 auto;
  }
`;

const AgentTextWrap = styled.div`
  padding: 70px 0 50px 0;
  @media(max-width: 768px) {
    padding: 50px 0 20px 0;
  }
  text-align: center;
  h2 {
    font-size: 32px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    color: #242B26;
    margin-bottom: 30px;
    line-height: 34px;
    @media(max-width: 768px) {
      font-size: 22px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 25px;
    a {
      color: #242B26;
      font-weight: 700;
font-family: 'National-Semi-Bold';
      text-decoration: underline;
      word-break: break-all;
    }
    @media(max-width: 768px) {
      font-size: 15px;
    }
  }
`;

const AgentBoxWrap = styled.div`
  text-align: center;
  margin-bottom: 70px;
  @media(max-width: 767px) {
    margin-bottom: 50px;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
font-family: 'National-Semi-Bold';
    color: #242B26;
    margin-bottom: 50px;
    line-height: 34px;
    @media(max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const AgentBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const AgentBox = styled.div`
  width: 33.33%;
  padding: 0 15px;
  margin-bottom: 30px;
  @media(max-width: 992px) {
    width: 50%;
  }
  @media(max-width: 768px) {
    width: 100%;
  }
  &.for-full-width {
    width: 80%;
    margin: 0 auto;
    @media(max-width: 768px) {
      width: 100%;
      padding: 0;
    }
  }
  span {
    font-size: 30px;
    color: #B01F24;
    font-weight: 700;
font-family: 'National-Semi-Bold';
  }
  strong {
    display: block;
    padding: 30px 0 24px 0;
    line-height: 1.5;
  }
  p {
    line-height: 28px;
    @media(max-width: 768px) {
      font-size: 15px;
    }
  }
`;

const AgentStaticImg = styled.div`
  margin-bottom: 70px;
  width: 100%;
  background-color: #F7F5E8;
  text-align: center;
  padding: 50px 0;
  @media(max-width: 768px) {
    margin-bottom: 50px;
  }
  img {
    max-width: 700px;
    margin: 0 auto;
    @media(max-width: 768px) {
      max-width: 100%;
    }
  }
`;

const AgentOfferWrap = styled.div`
  padding-bottom: 40px;
  text-align: center;
  @media(max-width: 768px) {
    padding-bottom: 10px;
  }
  .offer-heading {
    font-size: 32px;
    font-weight: 700;
font-family: 'National-Semi-Bold';
    color: #242B26;
    margin-bottom: 30px;
    @media(max-width: 768px) {
      font-size: 22px;
    }
  }
  .offer-subtext {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 50px;
    @media(max-width: 768px) {
      font-size: 15px;
    }
  }
`;

const AgentOfferBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const AgentOfferBox = styled.div`
  padding: 0 15px;
  width: 33.33%;
  margin-bottom: 20px;
  @media(max-width: 992px) {
    width: 50%;
  }
  @media(max-width: 768px) {
    width: 100%;
  }
  img {
    height: 80px;
  }
  strong {
    display: block;
    padding: 30px 0;
    line-height: 1.5;
    @media(max-width: 768px) {
      padding: 30px 0 25px 0;
    }
  }
  p {
    line-height: 28px;
    @media(max-width: 768px) {
      font-size: 15px;
    }
  }
`;

const AgentRatesWrap = styled.div`
  margin-bottom: 180px;
  @media(max-width: 1024px) {
    margin-bottom: 80px;
  }
  @media(max-width: 768px) {
    margin-bottom: 50px;
  }
  .rates-heading {
    font-size: 32px;
    font-weight: 700;
font-family: 'National-Semi-Bold';
    color: #242B26;
    margin-bottom: 30px;
    text-align: center;
    line-height: 34px;
    @media(max-width: 768px) {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
  .rates-subtext {
    line-height: 28px;
    text-align: center;
    margin-bottom: 50px;
    @media(max-width: 768px) {
      font-size: 15px;
      margin-bottom: 25px;
    }
  }
`;

const AgentsRateRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AgentRatesText = styled.div`
  height: 500px;
  width: 50%;
  padding-top: 150px;
  @media(max-width: 1024px) {
    width: 100%;
    text-align: center;
    height: auto;
    padding-top: 0;
  }
  strong {
    font-size: 22px;
    font-weight: 700;
font-family: 'National-Semi-Bold';
    color: #242B26;
    display: block;
    margin-bottom: 30px;
    line-height: 28px;
  }
  p {
    line-height: 28px;
    margin-bottom: 30px;
  }
  span {
    display: block;
    line-height: 28px;
    opacity: 0.8;
    @media(max-width: 768px) {
      font-size: 15px;
    }
  }
`;

const AgentRatesImg = styled.div`
  height: 500px;
  width: 50%;
  @media(max-width: 1024px) {
    display: none;
  }
  img {
    width: 100%;
  }
`;

const AgentContactWrap = styled.div`
  background-color: #B698D9;
  padding: 70px 0 75px 0;
  text-align: center;
  color: white;
  @media(max-width: 768px) {
    padding: 50px 0 55px 0;
  }
  h2 {
    font-size: 40px;
    color: white;
    font-weight: 700;
font-family: 'National-Semi-Bold';
    @media(max-width: 768px) {
      font-size: 26px;
      line-height: 34px;
    }
  }
  strong {
    font-size: 18px;
    font-weight: 700;
font-family: 'National-Semi-Bold';
    display: block;
    margin-bottom: 40px;
    @media(max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  p {
    line-height: 1.5;
    margin-bottom: 40px;
    @media(max-width: 768px) {
      font-size: 15px;
    }
    &.for-text2 {
      opacity: 0.8;
      margin-bottom: 0;
      @media(max-width: 768px) {
        font-size: 15px;
      }
    }
  }
`;

const AgentFaq = styled.div`
  padding: 70px 0;
  @media(max-width: 768px) {
    padding: 50px 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    color: #242B26;
    margin-bottom: 40px;
    text-align: center;
    @media(max-width: 768px) {
      font-size: 22px;
    }
  }
  strong {
    font-size: 18px;
    font-weight: 700;
font-family: 'National-Semi-Bold';
    color: #242B26;
    display: block;
    line-height: 28px;
    margin-bottom: 20px;
    @media(max-width: 768px) {
      font-size: 16px;
    }
  }
  p {
    line-height: 28px;
    margin-bottom: 40px;
    @media(max-width: 768px) {
      font-size: 15px;
    }
    &.for-gap {
      margin-bottom: 0;
    }
  }
`;

class Agents extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <AgentBanner>
          <Container>
            <h2>USRealty.com: <br></br>Building Your Business</h2>
            <p>USRealty.com offers homeowners and buyers unprecedented transparency and options for selling and buying,
              while aligning with the success of local real estate markets and agents.</p>
          </Container>
        </AgentBanner>
        <AgentTextWrap>
          <Container>
            <h2>USRealty.com Is a Hybrid, National Real Estate Agency</h2>
            <p>Through USRealty.com and its membership in 110 multiple listing services around the country,
            cost-conscious homeowners take an a la carte, “do it yourself” approach to listing their homes for sale.
            Buyers’ agents are guaranteed their commissions because USRealty.com is an agency that fulfills the promise of
            MLSs for agents, homeowners and home buyers.</p>
            <p>We’re growing fast and looking for licensed brokers with at least five years’ experience to
              manage new territories. Let’s get acquainted! Please contact Derek Morgan at
              <a href="mailto:DMorgan@usrealty.com"> DMorgan@usrealty.com.</a></p>
            <p>Apply here:
              <a href={`${"https://www.indeedjobs.com/living-spaces-2be7b06/_hl/en_US?cpref=JXWAtnzf3XW5aRnY2g_zouV7xMD8uRK8J5AdLvKmSXQ"}`}
                target="_blank"
              >
              {` ${'https://www.indeedjobs.com/living-spaces-2be7b06/_hl/en_US?cpref=JXWAtnzf3XW5aRnY2g_zouV7xMD8uRK8J5AdLvKmSXQ'}`}
              </a>
            </p>
          </Container>
        </AgentTextWrap>
        <AgentBoxWrap>
          <Container>
            <h2>USRealty.com Is On Your Side, Building Local Real Estate Markets</h2>
            <AgentBoxRow>
              <AgentBox>
                <span>1</span>
                <strong>Delivers fresh listings to your market</strong>
                <p>You can’t sell what isn’t listed. USRealty.com channels thousands of listings annually to local markets.
                Some listings come from commercial and investment portfolio managers looking to capture return on their
                real estate investments. Other listings are posted by consumers through USRealty.com. All listings comply
                with local MLS formats and rules.</p>
              </AgentBox>
              <AgentBox>
                <span>2</span>
                <strong>Educates sellers</strong>
                <p>The USRealty.com Resource Center walks sellers through the process of preparing, pricing and pitching
                their house to buyers. When you represent a buyer interested in a USRealty.com-listed property,
                you’re fulfilling the traditional role of representing the buyer. Unlike ‘for sale by owner’ sellers, you
                aren’t stuck with extra work helping a clueless homeowner.  We provide phone support for ourlisting clients,
                too.</p>
              </AgentBox>
              <AgentBox>
                <span>3</span>
                <strong>Expands your service area within your state</strong>
                <p>You have a state real estate license but might not have a MLS membership in far-flung markets.
                When you need transaction support in markets outside your MLS membership, USRealty.com can be the
                exclusive listing broker, provide the MLS listing, and subcontract to you and your broker to handle
                certain other functions in the transaction, ensuring that you will receive compensation.</p>
              </AgentBox>
              <AgentBox>
                <span>4</span>
                <strong>Shifts REO from rental to the market</strong>
                <p>Inventory is the biggest problem facing local markets. Because USRealty.com offers a clean,
                cost-effective way for investors to list and sell portfolio properties, USRealty.com is a factor in
                shifting rental units to for-sale units.</p>
              </AgentBox>
              <AgentBox>
                <span>5</span>
                <strong>Creates motivated buyers</strong>
                <p>With USRealty.com’s impressive success rates, home sellers quickly become home buyers. Once they’ve
                sold their house, they’re intensely looking to move — and usually, that means they need to buy, continuing
                the cycle of market recovery.</p>
              </AgentBox>
              <AgentBox>
                <span>6</span>
                <strong>Showcases your expertise as a buyer’s agent</strong>
                <p>When a USRealty.com listing sells, that homeowner is an instant real estate expert with neighbors,
                friends and co-workers. When you bring the buyer, you are positioned as a hero in a success story that will
                be told over and over again.</p>
              </AgentBox>
              <AgentBox>
                <span>7</span>
                <strong>Creates qualified buyers</strong>
                <p>Thanks to USRealty.com’s low transaction costs, home sellers capture more equity at closing. You know
                what that means: they can leverage that equity to move up.</p>
              </AgentBox>
              <AgentBox>
                <span>8</span>
                <strong>Supports local MLSs with fees</strong>
                <p>USRealty.com is a full member in 110 MLSs around the country. We pay membership and service fees that
                keep local MLSs humming.</p>
              </AgentBox>
              <AgentBox>
                <span>9</span>
                <strong>Guarantees your commission</strong>
                <p>If it’s listed on the MLS, you get a commission. Period.</p>
              </AgentBox>
            </AgentBoxRow>
            <AgentBox className="for-full-width">
              <span>10</span>
              <strong>Respects real estate industry traditions</strong>
              <p>Unlike ‘for sale by owner’ sites and free listings, USRealty.com recognizes and celebrates the role of
              buyers’ agents in helping consumers with one of the most important purchases of their lives.
              USRealty.com respects, reinforces and rewards successful buyer’s agents who close deals with
              USRealty.com customers.</p>
            </AgentBox>
          </Container>
        </AgentBoxWrap>
        <AgentStaticImg>
          <Container>
            <img src={ImagesUrl.US_REALTY_AGENTS} alt="static-img"/>
          </Container>
        </AgentStaticImg>
        <AgentOfferWrap>
          <Container>
          <h2 className="offer-heading">An Offering Unlike Any Other</h2>
          <p className="offer-subtext">USRealty.com offers home sellers free access to their local multiple listing
          service while guaranteeing the traditional buy-side commission to agents who successfully close deals with
          USRealty.com customers.
          Customers appreciate the unbundled service options offered by USRealty.com. Agents appreciate the
          streamlined transaction support that makes it easy to work with do-it-yourself minded homeowners. Here’s how
          our business model works.</p>
            <AgentOfferBoxRow>
              <AgentOfferBox>
                <img src={ImagesUrl.USR_FOR_SALE_SIGN_ICON} alt="offer-img"/>
                <strong>Customers can list on the local MLS for free</strong>
                <p>They get one photo with each listing and customer support As with all MLS agreements, these customers must '
                  pay the buy-side commission when a local agent successfully represents a buyer who purchases a house
                  from a USRealty.com customer. At closing, these customers remit $296 to USRealty.com to cover the cost of
                  the service.</p>
              </AgentOfferBox>
              <AgentOfferBox>
                <img src={ImagesUrl.USR_LISTING_LAPTOP_ICON} alt="offer-img"/>
                <strong>Value-added Listings Start at just $99</strong>
                <p>Customers can upgrade to an enhanced listing with up to 25 photos or choose a $199 listing with a
                  robust listing presence and additional support. These customers also remit $296 to USRealty.com at closing.</p>
              </AgentOfferBox>
              <AgentOfferBox>
                <img src={ImagesUrl.USR_HOME_DOLLAR_EXCHANGE_ICON} alt="offer-img"/>
                <strong>Customers choose the buy-side commission they want to pay</strong>
                <p>As with all MLS contracts, the commission earned by the buyer’s agent is specified in the contract.</p>
              </AgentOfferBox>
              <AgentOfferBox>
                <img src={ImagesUrl.USR_LISTING_SHEET_ICON} alt="offer-img"/>
                <strong>The seller completes the USRealty.com listing sheet</strong>
                <p>The USRealty.com customer service team (based in the U.S.) completes the documentation and streams the
                  listing into the appropriate MLS.</p>
              </AgentOfferBox>
              <AgentOfferBox>
                <img src={ImagesUrl.SELLRT_MANAGEMENT} alt="offer-img"/>
                <strong>The seller is hands-on, facilitated by USRealty.com</strong>
                <p>From showings, negotiation, and other marketing tasks, the seller handles most of the daily responsibilities
                  of selling. USRealty.com facilitates closing in collaboration with the buyer’s agent.</p>
              </AgentOfferBox>
              <AgentOfferBox>
                <img src={ImagesUrl.USR_THUMBS_UP_HOUSE_ICON} alt="offer-img"/>
                <strong>And finally…</strong>
                <p>The local buyer’s agent collects his or her commission, as usual, at closing.</p>
              </AgentOfferBox>
            </AgentOfferBoxRow>
          </Container>
        </AgentOfferWrap>
        <AgentRatesWrap>
          <Container>
            <h2 className="rates-heading">Here is a Sample of Success Rates in Just Three USRealty.com Markets.</h2>
            <p className="rates-subtext">USRealty.com success rates are in line with the ‘sold’ rates for local markets
            around the country</p>
            <AgentsRateRow>
              <AgentRatesText>
                <strong>Smooth, Efficient Commercial Co-Broker Services</strong>
                <p>USRealty.com’s network of brokers ensurs that you have a legal broker in each metropolitan area or state
                  where your deal has a footprint. When you need one point of entry to an MLS, USRealty.com is your answer.
                  Our streamlined process and experienced in-house brokers provide fast, responsive, cost-effective
                  transaction support, whether you are capturing return from a portfolio of houses and condos or are
                  selling properties one at a time.</p>
                <span>USRealty.com is licensed in: AL, AR, AZ, CA, CO, CT, DC, DE, FL, GA, IL, IN, LA, MA, MD, MI, MO, NC, NE,
                  NJ, NM, NY, OH, OK, PA, RI, SC, TN, TX, VA, WA, WV</span>
              </AgentRatesText>
              <AgentRatesImg>
                <img src={ImagesUrl.USREALTY_SUCCESS} alt="agent"/>
              </AgentRatesImg>
            </AgentsRateRow>
          </Container>
        </AgentRatesWrap>
        <AgentContactWrap>
          <Container>
            <h2>Contact Us Today with your Questions</h2>
            <strong>We look forward to sharing more about the USRealty.com platform with you!</strong>
            <p>commercial@usrealty.com <br></br>877-888-8471</p>
            <p className="for-text2">USRealty.com, LLP & US Realty Brokerage Solutions, LLP<br></br> 1500 Conrad Weiser
            Parkway <br></br> Womelsdorf, PA 19567</p>
          </Container>
        </AgentContactWrap>
        <AgentFaq>
          <Container>
            <h2>Agent FAQ</h2>
            <strong>Selling the USRealty.com way isn’t for all clients. What situations aren’t appropriate for your a la
              carte model?</strong>
            <p>The USRealty.com model is best for sellers who are on hand to show their own properties and host open houses.
               Sellers who travel often, who are infirm, or whose commitments make it difficult to arrange showings, may
               find value in the showing services and conveniences traditionally offered by full-service agents.</p>
            <strong>Isn’t USRealty.com at odds with the mission of most real estate agencies?</strong>
            <p>The real estate industry can’t and shouldn’t dictate business models for licensed, qualified brokers and
              agents in good standing. Every business should be able to create its own model for sales, revenue
              and profitability.</p>
            <strong>What is the relationship between USRealty.com and Housepad.com?</strong>
            <p>Housepad.com, LLP was a brokerage with memberships in MLSs around the country. USRealty.com acquired
              Housepad.com in 2014. You can read the news story in Housingwire.</p>
            <strong>Didn’t USRealty.com founder and president Colby Sambrotto sell his New York condo with an agent?</strong>
            <p className="for-gap">Yes, he did. New York doesn’t offer the kind of MLS prevalent in the rest of the
            country. That experience made Sambrotto realize how powerful the MLS is to most U.S. homeowners, and how
            important it was to support the role of buyers’ agents who know their local markets.</p>
          </Container>
        </AgentFaq>
        <Footer />
      </div>
    )
  }
}

export default Agents;
