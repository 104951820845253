import { sellerNotificationService } from '../Services/SellerNotificationService';
import { alertActions } from '../Actions/ToastActions';
import { staticMessages } from '../Constants/CommonConstants';
import LogRocket from 'logrocket';
import AppConsts from '../Constants/AppConsts';


export const sellerNotificationActions = {
  CHECK_MLS_REQUESTED: 'CHECK_MLS_REQUESTED',
  CHECK_MLS_RESOLVED: 'CHECK_MLS_RESOLVED',
  CHECK_MLS_FAILURE: 'CHECK_MLS_FAILURE',
  REQUEST_SHOWING_REQUESTED: 'REQUEST_SHOWING_REQUESTED',
  REQUEST_SHOWING_RESOLVED: 'REQUEST_SHOWING_RESOLVED',
  REQUEST_SHOWING_FAILURE: 'REQUEST_SHOWING_FAILURE',
  SUBMIT_OFFER_REQUESTED: 'SUBMIT_OFFER_REQUESTED',
  SUBMIT_OFFER_RESOLVED: 'SUBMIT_OFFER_RESOLVED',
  SUBMIT_OFFER_FAILURE: 'SUBMIT_OFFER_FAILURE',
  UPLOAD_OFFER_PDF_REQUESTED: 'UPLOAD_OFFER_PDF_REQUESTED',
  UPLOAD_OFFER_PDF_PROCESSING: 'UPLOAD_OFFER_PDF_PROCESSING',
  UPLOAD_OFFER_PDF_RESOLVED: 'UPLOAD_OFFER_PDF_RESOLVED',
  UPLOAD_OFFER_PDF_FAILURE: 'UPLOAD_OFFER_PDF_FAILURE',
  SET_PDF_LINK_ERROR: 'SET_PDF_LINK_ERROR',
  SET_PDF_UPLOAD_PROGRESS: 'SET_PDF_UPLOAD_PROGRESS',
  REMOVE_PDF_LINK: 'REMOVE_PDF_LINK',
};

export const sellerNotificationActionCreators = {
  сheckMls: (mlsId) => async (dispatch) => {

    dispatch({ type: sellerNotificationActions.CHECK_MLS_REQUESTED, payload: mlsId });

    try {
      const { data } = await sellerNotificationService.сheckMls({ mlsNumber: mlsId });
      if (!data.hasFound) {
        dispatch({ type: sellerNotificationActions.CHECK_MLS_FAILURE });
        dispatch(alertActions.errorAlert(staticMessages.mlsSearchFailure));
      } else {
        dispatch(alertActions.successAlert(staticMessages.mlsSearchSuccess));
        dispatch({ type: sellerNotificationActions.CHECK_MLS_RESOLVED, payload: data });
      }
    }
    catch (ex) {
      const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
      dispatch(alertActions.errorAlert(errorMessage));
      dispatch({ type: sellerNotificationActions.CHECK_MLS_FAILURE });
      LogRocket.captureException(ex, {
        tags: {
          errorType: 'failed_seller_notification_action',
        },
        extra: {
          type: sellerNotificationActions.CHECK_MLS_FAILURE,
          mlsId
        },
      });
    }
  },
  requestShowing: (params) => async (dispatch) => {

    dispatch({ type: sellerNotificationActions.REQUEST_SHOWING_REQUESTED });

    try {
      await sellerNotificationService.requestShowing(params);
      dispatch(alertActions.successAlert(staticMessages.showingRequestSucess));
      dispatch({ type: sellerNotificationActions.REQUEST_SHOWING_RESOLVED });
      setTimeout(() => window.location.href = AppConsts.buySideUrl, 2000);
    } catch (ex) {
      const errorMessage = ex?.response?.data?.message || staticMessages.showingRequestError;
      dispatch(alertActions.errorAlert(errorMessage));
      dispatch({ type: sellerNotificationActions.REQUEST_SHOWING_FAILURE});

      LogRocket.captureException(ex, {
        tags: {
          errorType: 'failed_seller_notification_action',
        },
        extra: {
          type: sellerNotificationActions.REQUEST_SHOWING_FAILURE,
          params
        },
      });
    }
  },
  submitOffer: (params) => async (dispatch) => {

    dispatch({ type: sellerNotificationActions.SUBMIT_OFFER_REQUESTED });

    try {
      await sellerNotificationService.submitOffer(params);
      dispatch(alertActions.successAlert(staticMessages.offerSubmitSuccess));
      dispatch({ type: sellerNotificationActions.REQUEST_SHOWING_RESOLVED });
      setTimeout(() => window.location.href = AppConsts.buySideUrl, 2000);
    }
    catch (ex) {
      const errorMessage = ex?.response?.data?.message || staticMessages.offerSubmitError;
      dispatch(alertActions.errorAlert(errorMessage));
      dispatch({ type: sellerNotificationActions.SUBMIT_OFFER_FAILURE });

      LogRocket.captureException(ex, {
        tags: {
          errorType: 'failed_seller_notification_action',
        },
        extra: {
          type: sellerNotificationActions.SUBMIT_OFFER_FAILURE,
          params
        },
      });
    }
  },

  uploadPdfOffer: (file) => async (dispatch) => {

    dispatch({ type: sellerNotificationActions.UPLOAD_OFFER_PDF_REQUESTED });
    try {
      const { data: dataJson } = await sellerNotificationService.uploadOfferPdf({
        name: file.name,
        selectedFile: file,
        signal: file.controller.signal,
        onUploadProgress: (progressEvent) => dispatch({
          type: sellerNotificationActions.SET_PDF_UPLOAD_PROGRESS,
          payload: {
            progress: Number((progressEvent.loaded / (progressEvent.total / 100)).toFixed(0)),
            id: file.id,
          }
        })
      });
      dispatch(alertActions.successAlert(staticMessages.uploadOfferSuccess));
      dispatch({
        type: sellerNotificationActions.UPLOAD_OFFER_PDF_RESOLVED,
        payload: {
          data: dataJson,
          id: file.id,
        }
      });
    }
    catch (ex) {
      dispatch({
        type: sellerNotificationActions.UPLOAD_OFFER_PDF_FAILURE, payload: {
          id: file.id,
        }
      });
      if (ex.message !== 'canceled') {
        const errorMessage = ex?.response?.data?.message || staticMessages.apiError;
        dispatch(alertActions.errorAlert(errorMessage));
        LogRocket.captureException(ex, {
          tags: {
            errorType: 'failed_seller_notification_action',
          },
          extra: {
            type: sellerNotificationActions.UPLOAD_OFFER_PDF_FAILURE,
          },
        });
      }
    }
  },
  setOfferPdfError: (val) => ({
    type: sellerNotificationActions.SET_PDF_LINK_ERROR,
    payload: val,
  }),

  setUploadPdfPercent: (val) => ({
    type: sellerNotificationActions.SET_PDF_UPLOAD_PROGRESS,
    payload: val,
  }),
  removePdfLink: (id) => async (dispatch, getState) => {
    const links = getState().sellerNotification.pdfLinks;
    const filtredLinks = links.filter(f => f.id !== id);

    dispatch({
      type: sellerNotificationActions.REMOVE_PDF_LINK,
      payload: filtredLinks,
    })
  }
};
