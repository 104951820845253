import { authHeader } from '../../Utilities/Headers';
import fetch from '../../Utilities/FetchWithTimeout';
import { contentType, apiMethodType } from '../../Constants/CommonConstants';

class PropertiesService {

  getProperties(params) {
    const query = new URLSearchParams(params).toString();
    const url = `api/v2/listings?${query}`;

    return fetch(url, {
      method: apiMethodType.GET,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader()
      }
    });
  }

  updateStatus(propertyId, data) {
    const url = `api/v2/listings/${propertyId}/status`;
    return fetch(url, {
      method: apiMethodType.PUT,
      data,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }

  getAverageTimeToList() {
    const url = 'api/v2/listings/avg-handling-time';

    return fetch(url, {
      method: apiMethodType.GET,
      withCredentials: true,
      headers: {
        'Content-Type': contentType.applicationJson,
        ...authHeader(),
      },
    });
  }
}

export const propertiesService = new PropertiesService();
