import React from 'react';
import routes from "../../../../../Constants/Routes";
import { ImagesUrl } from "../../../../../Constants/Images";

class ReadyToSell extends React.Component {
  render() {
    return (
      <>
        <h2>Selling your house engages both your heart and your head</h2>
        <p>
          You bought your house with expectations of building memories, living a good life in it, and building equity and personal
          wealth in the process. Now that it is time to sell, financial considerations come to the fore. How has your home’s value
          weathered the roiling real estate market? How can you get back what you put into it, without giving equity away to an antiquated
          real estate selling model?
        </p>
        <p>
          Walking through the house and seeing it from a seller’s point of view helps to get your heart and your head on the same page.
          In the selling process, your house becomes a product that must compete with other products.  Pricing and presentation are the
          two most important factors for winning buyers’ attention and translating that attention to an offer.
        </p>
        <strong>Use the USRealty.com Ready to Sell checklist to identify:</strong>
        <ul>
          <li>Your home’s best features, that can become selling points</li>
          <li>Your home’s drawbacks – some to be fixed and some to be addressed through pricing and marketing</li>
          <li>How to best present your home in its online listing, which is where your house makes its first and strongest impression on potential buyers</li>
          <li><a href={ImagesUrl.READY_TO_SELL_CHECKLIST} target="_blank">Download the Checklist</a></li>
        </ul>
        <p>
          Use other <a href={routes.CHECKLISTS} target="_blank">USRealty.com checklists</a> to review features, amenities and marketing angles offered by your house.
          From energy-saving features to helping prospective buyers see how they can afford your house, it’s all in
           the <a href={routes.CHECKLISTS} target="_blank">list of lists.</a>
        </p>
      </>
    )
  }
}

export default ReadyToSell;
