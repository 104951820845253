import React, { useState } from 'react';
import styled from 'styled-components';
import traditionalIllustration from '../../../Assets/Svg/traditional.svg';
import BuyingPropertyModal from '../Modals/BuyingPropertyModal';

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 1rem;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  border-radius: 10px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 18rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
    width: 25.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    margin-right: 2rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 50rem;
  }
`;

const CtaButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.25rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: #e0fe60;
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 5px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
    margin-bottom: 1.5rem;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Illustration = styled.img`
  height: 12rem;
`;

export const BuyingPropertiesCta = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <CtaContainer>
        <Illustration src={traditionalIllustration} alt="Illustration" />
        <TextContainer>
          <h3>Buy your next home for less</h3>
          <p>When you buy with Unreal Estate we will credit* a portion of our commision back to you.</p>
        </TextContainer>
        <CtaButton onClick={() => setIsModalOpen(true)}>Buy with Unreal Estate</CtaButton>
      </CtaContainer>
      {isModalOpen && <BuyingPropertyModal closeModal={() => setIsModalOpen(false)} />}
    </>
  );
};
