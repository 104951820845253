import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const AverageCard = styled.span`
  font-size: 1.5rem;
  margin-left: 2rem;

  @media(max-width: ${({ theme }) => theme.breakpoints.lg}){
    font-size: 1rem;
  }
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
    margin-bottom: 2px;
    margin-left: 2px;
`;

const SearchPanel = ({
  onSearch,
  placeholder,
  //TODO: Move out those fields to upper level
  averageTime,
  fetchingAverageTime,
  averageTimePlaceholder,
  isShowAddButton,
  addButtonText,
  addButtonClick,
  children
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }

  const applySearch = () => {
    if (searchValue.trim()) {
      onSearch(searchValue);
    }
  }

  const clearSearch = () => {
    setSearchValue('')
    onSearch('');
  }

  return (
    <React.Fragment>
      <div className="admin-search-btn-wrapper">
        <div className="left-side">
          <div className="custom-search form-group">
            <input
              type="search"
              id="searchinput"
              value={searchValue}
              placeholder={placeholder || `Search by Keywords, Title`}
              onChange={e => handleChange(e)}
              // onKeyDown={e => this._handleKeyDown(e)}
              className="form-control" />
            <i className="fa fa-search" aria-hidden="true"></i>
          </div>
          <div className="form-group">
            <Button
              className="btn btn-primary admin-primary-btn small-btn"
              onClick={() => applySearch()}>
              Apply Search
            </Button>

            <Button
              className="btn btn-primary admin-primary-btn small-btn"
              onClick={() => clearSearch()}>
              Clear Search
            </Button>
          </div>
          {/*TODO: Move to upper level */}
          {averageTime && <AverageCard>
            <span>{averageTimePlaceholder}</span>: {
              fetchingAverageTime ? <Loader animation="border" size="sm" /> : averageTime
            }
          </AverageCard>}
        </div>
        {isShowAddButton && <div className='btn-filter-wrapper'>
          <Button onClick={addButtonClick} className='btn btn-primary admin-primary-btn'>{addButtonText}</Button>
        </div>}
        <div className="btn-filter-wrapper">
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchPanel;
