import React from 'react'
import { themes } from '../../../../Constants/Themes';

export const UncheckedIcon = (iconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" stroke={iconProps.color || themes.usRealty.colors.green[500]}/>
    </svg>
    
)};
