import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingActionCreatorsV2, onboardingActions } from '../../../Actions/OnboardingActionsV2';
import { ModalDiv } from '../../../Assets/Style/commonStyleComponents';
import Routes from '../../../Constants/Routes';
import {
  selectedAddressSelector,
  voucherCodeDetailsSelector,
  isAddressCoverageableSelector,
  showNetworkPlanInfoPopupSelector,
  networkBrokerSelector
} from '../../../Reducers/OnboardingReducerV2';
import { history } from '../../../Utilities/History';
import AddressNotFoundPopUp from '../../Shared/Modals/AddressNotFoundPopUp';
import CashOfferModal from '../../Admin/V2/Components/CashOffer/CashOfferModal';
import { isCashModalOpenedSelector } from '../../../Reducers/V2/CashOfferReducer';

const PropertyListingModals = () => {
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const selectedAddress = useSelector(selectedAddressSelector)
  const voucherCodeDetails = useSelector(voucherCodeDetailsSelector)
  const showNetworkPlanInfoPopup = useSelector(showNetworkPlanInfoPopupSelector);
  const networkBroker = useSelector(networkBrokerSelector);
  const dispatch = useDispatch();
  const isVoucherApplied = !!voucherCodeDetails?.isVoucherValid;
  const isAddressNotserviceAble = useSelector(isAddressCoverageableSelector);
  const isCashModalOpened = useSelector(isCashModalOpenedSelector);

  const handleCloseModal = () => {
    dispatch(onboardingActionCreatorsV2.closeModalV2())
    dispatch(onboardingActionCreatorsV2.clearAddressV2())
  };

  const handleSubmitRequest = (reqObj) => {
    dispatch(onboardingActionCreatorsV2.nonServiceableAreaRequestV2(reqObj))
    handleCloseModal();
    setShowConfirmationPopUp(true);
  };

  const handleCloseNetworkPlanInfoPopUp = () => {
    dispatch({ type: onboardingActions.SET_SHOW_NETWORK_PLAN_INFO_POPUP_V2, payload: false });
    history.push({ pathname: Routes.DASHBOARD })
  };


  if (isVoucherApplied) {
    return null;
  }

  return (
    <>
      {isCashModalOpened && <CashOfferModal/>}
      <ModalDiv
        visible={!isVoucherApplied && !isAddressNotserviceAble}
        onOk={handleCloseModal}
        closable={false}
        maskClosable={false}
        footer={false}
        destroyOnClose={true}
      >
        <AddressNotFoundPopUp
          selectedAddress={selectedAddress?.formatted || ''}
          county={selectedAddress?.county || ''}
          handleSubmitRequest={handleSubmitRequest}
          handleCloseModal={handleCloseModal}
        />
      </ModalDiv>

      <ModalDiv
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Address Not Serviceable Confirmation"
        visible={showConfirmationPopUp}
        onCancel={() => setShowConfirmationPopUp(false)}
        maskClosable={false}
        className="text-modal"
        onOk={() => setShowConfirmationPopUp(false)}
        okText="OK"
      >
        <p className="static-text">
          <span>Thank you for informing us that the address that you tried to create a listing for on Unreal Estate
            is not currently serviceable by our system. We have recorded the address and one of our Customer
            Care Specialists will be in touch shortly to assist you further.</span>
          If you have any additional question or require immediate support,
          please <a href={Routes.CONTACT} onClick={() => setShowConfirmationPopUp(false)}>contact us</a> at your convenience.
        </p>
      </ModalDiv>

      <ModalDiv
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Success!"
        visible={showNetworkPlanInfoPopup}
        onCancel={handleCloseNetworkPlanInfoPopUp}
        closable={false}
        maskClosable={false}
        className="text-modal"
        onOk={handleCloseNetworkPlanInfoPopUp}
        okText="OK"
      >
        <br />
        <p className="static-text">
          Congratulations on starting a MLS listing with our Network Plan.
          Be on the lookout, you will receive an email introducing you to our Network Broker.
          Their details are outlined below.
          Please contact them when you are ready to proceed.
        </p>
        <p className="static-text"> <b>Company: {networkBroker?.brokerCompanyName}</b></p>
        <p className="static-text"><b>Managing Broker: {networkBroker?.brokerName}</b></p>
        <p className="static-text"><b>Email: {networkBroker?.brokerEmail}</b></p>
        <p className="static-text"><b>Phone: {networkBroker?.brokerPhone}</b></p>
        {networkBroker?.brokerWebsite && <p className="static-text"><b>Website: {networkBroker?.brokerWebsite}</b></p>}
      </ModalDiv>
    </>
  );
}

export default PropertyListingModals;

