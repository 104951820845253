import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import v8n from 'v8n';
import MaskedInput from 'react-text-mask';
import { phoneMask, regexPattern, validationMessages } from '../../../../../Constants/CommonConstants';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { cashOfferBatchSelector } from '../../../../../Reducers/LookupReducer';
import { currentUserSelector } from '../../../../../Reducers/UserReducer';
import {
  cashOfferDraftListingIdSelector,
  cashOfferListingIdSelector,
  cashOfferPriceSelector,
  offerSubmittedSelector,
  cashOfferAddressDetailsSelector,
} from '../../../../../Reducers/V2/CashOfferReducer';
import houseWithTreesIllustration from '../../../../../Assets/Svg/unreal-house2.svg';
import dollarSign from '../../../../../Assets/Svg/dollar-sign.svg';
import { cashOfferCreators } from '../../../../../Actions/V2/CashOfferActions';
import { NavLink } from '../../../../../Assets/Style/commonStyleComponents';
import Routes from '../../../../../Constants/Routes';
import { convertPhone } from '../../../../../Utilities/commonFunctions';

const ModalOverlay = styled.div`
  opacity: 1;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
  z-index: 1400;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1400;
  justify-content: center;
  align-items: flex-start;
  background: unset;
  overflow: auto;
  font-family: 'National-Regular';
  font-size: 1.25rem;
`;

const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 22rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius:  0.375rem;
  background: white;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  z-index: 1400;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 28rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    width: 28rem;
  }
}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  font-size: 1.25rem;;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  align-items: center;

  p {
    margin-bottom: 0;
  }
}
`;

const FormContainer = styled.span`
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  flex: 1 1 0%;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    margin-top: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    border-color: ${({ theme, error }) => (error ? theme.colors.red[500] : '#242B26')};
  }
  select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }

  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.5rem;
  min-width: 4rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: #e0fe60;
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 1rem;
  font-size: 1rem;
`;

const CloseButton = styled(SubmitButton)`
  font-family: 'National-Regular';
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  background: none;
  width: 4rem;
  font-size: 1rem;
  font-weight: normal;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    position: relative;
    align-self: center;
    bottom: 2rem;
  }
`;

const CancelButton = styled(SubmitButton)`
  background: none;
  font-size: 1rem;
  font-weight: normal;
  font-family: 'National-Regular';
  width: 4rem;
  margin-right: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[500]};
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel
        ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
        : ''}
  }
`;

const DollarIcon = styled.img`
  margin-right: 1rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -35px;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const HouseIllustration = styled.img`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 300px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const LearnMoreLink = styled(NavLink)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2rem;
`;

const CashOfferModal = () => {
  const dispatch = useDispatch();
  const lookup = useSelector(cashOfferBatchSelector);
  const draftListingId = useSelector(cashOfferDraftListingIdSelector);
  const listingId = useSelector(cashOfferListingIdSelector);
  const userData = useSelector(currentUserSelector);
  const propertyPrice = useSelector(cashOfferPriceSelector);
  const offerSubmitted = useSelector(offerSubmittedSelector);
  const cashOfferAddressDetails = useSelector(cashOfferAddressDetailsSelector);

  const [formFields, setFormFields] = useState({
    email: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: validationMessages.email,
        },
        {
          rule: v8n().pattern(regexPattern.EMAIL),
          message: validationMessages.validEmail,
        },
      ],
      value: userData.email,
      error: null,
    },
    phoneNumber: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: validationMessages.phoneNumber,
        },
        {
          rule: v8n().pattern(regexPattern.PHONE),
          message: validationMessages.phoneNumber,
        },
      ],
      value: userData.phoneNumber,
      error: null,
      mask: phoneMask,
    },
    name: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter your full name',
        },
      ],
      value: `${userData.firstName} ${userData.lastName}`,
      error: null,
    },
    price: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter the price',
        },
      ],
      value: propertyPrice,
      error: null,
    },
    propertyTypeId: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select the property type',
        },
      ],
      value: '',
      error: null,
    },
    timelineId: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select the timeline',
        },
      ],
      value: '',
      error: null,
    },
    sellReasonId: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select the sell reason',
        },
      ],
      value: '',
      error: null,
    },
    builtPeriodId: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select the period',
        },
      ],
      value: '',
      error: null,
    },
    conditionId: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select the condition',
        },
      ],
      value: '',
      error: null,
    },
  });

  useEffect(() => {
    dispatch(lookupActionsCreators.getOfferCashBatch());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let isError = false;

    Object.keys(formFields).forEach((k) => {
      const isErrorExist = validateLoginField(k, formFields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    });

    if (isError) {
      return;
    }

    const { address, city, zipCode, placeId, name } = cashOfferAddressDetails;

    dispatch(
      cashOfferCreators.addCashOffer({
        draftListingId: draftListingId,
        listingId: listingId,
        address,
        city,
        zipCode,
        placeId,
        name,
        price: formFields.price.value,
        email: formFields.email.value,
        phone: convertPhone(formFields.phoneNumber.value), 
        propertyTypeId: formFields.propertyTypeId.value,
        timelineId: formFields.timelineId.value,
        sellReasonId: formFields.sellReasonId.value,
        builtPeriodId: formFields.builtPeriodId.value,
        conditionId: formFields.conditionId.value,
      })
    );
  };

  const handleFieldChanged = (e) => {
    e.persist();
    setFormFields((pr) => ({ ...pr, [e.target.name]: { ...pr[e.target.name], value: e.target.value } }));
  };

  const validateLoginField = (key, value) => {
    let fields = { ...formFields };
    let isError = false;
    for (let i = 0; i < fields[key].rules.length; i++) {
      let rule = fields[key].rules[i];

      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setFormFields((pr) => ({ ...pr, ...fields }));

    return isError;
  };

  const getOptions = (items) => {
    return items ? items.map((x) => ({ value: x.id, label: x.name })) : [];
  };

  return (
    <ContentContainer>
      <ModalOverlay />
      <ModalContainer>
        <HeaderContainer>
          <DollarIcon src={dollarSign} alt="dollar sign illustration" />
          <p>Cash offer</p>
        </HeaderContainer>
        <FormContainer>
          {offerSubmitted ? (
            <div>
              <p>Offer submitted successfully!</p>
              <br />
              <p>You'll receive an email confirmation shortly.</p>
              <br />
              <p>You can also expect a member of our team to be reaching out to you within the hour.</p>
              <br />
              <FooterContainer>
                <HouseIllustration src={houseWithTreesIllustration} alt="house illustration" />
                <CloseButton type="button" onClick={() => dispatch(cashOfferCreators.closeCashOfferModal())}>
                  Close
                </CloseButton>
              </FooterContainer>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <InputContainer className={formFields.email.error ? 'invalid' : ''}>
                <input
                  placeholder=" "
                  type="email"
                  autoComplete="email"
                  name="email"
                  id="email"
                  value={formFields.email.value}
                  onChange={handleFieldChanged}
                  disabled
                />
                <InputLabel smallLabel={formFields.email.value} htmlFor="email">
                  Email address
                </InputLabel>
                {formFields.email.error && <ErrorMessage>{formFields.email.error}</ErrorMessage>}
              </InputContainer>
              <InputContainer className={formFields.phoneNumber.error ? 'invalid' : ''}>
                <MaskedInput
                  mask={formFields.phoneNumber.mask}
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formFields.phoneNumber.value}
                  onChange={handleFieldChanged}
                  autoComplete="off"
                  disabled
                />
                <InputLabel smallLabel={formFields.phoneNumber.value} htmlFor="phone">
                  Phone
                </InputLabel>
                {formFields.phoneNumber.error && <ErrorMessage>{formFields.phoneNumber.error}</ErrorMessage>}
              </InputContainer>
              <InputContainer className={formFields.name.error ? 'invalid' : ''}>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formFields.name.value}
                  onChange={handleFieldChanged}
                  disabled
                />
                <InputLabel smallLabel={formFields.name.value} htmlFor="name">
                  Name
                </InputLabel>
                {formFields.name.error && <ErrorMessage>{formFields.name.error}</ErrorMessage>}
              </InputContainer>
              <InputContainer className={formFields.price.error ? 'invalid' : ''}>
                <input
                  type="number"
                  name="price"
                  id="price"
                  value={formFields.price.value}
                  onChange={handleFieldChanged}
                  disabled={propertyPrice}
                />
                <InputLabel smallLabel={formFields.price.value} htmlFor="price">
                  Price
                </InputLabel>
                {formFields.price.error && <ErrorMessage>{formFields.price.error}</ErrorMessage>}
              </InputContainer>
              <SelectContainer className={formFields.propertyTypeId.error ? 'invalid' : ''}>
                <select
                  id="propertyTypeId"
                  name="propertyTypeId"
                  value={formFields.propertyTypeId.value || ''}
                  onChange={handleFieldChanged}
                >
                  <option key={-1} value=""></option>
                  {getOptions(lookup?.propertyTypes).map((x, idx) => (
                    <option key={idx} value={x.value}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <InputLabel smallLabel={formFields.propertyTypeId.value} htmlFor="propertyTypeId">
                  Property Type
                </InputLabel>
                {formFields.propertyTypeId.error && <ErrorMessage>{formFields.propertyTypeId.error}</ErrorMessage>}
              </SelectContainer>
              <SelectContainer className={formFields.timelineId.error ? 'invalid' : ''}>
                <select
                  id="timelineId"
                  name="timelineId"
                  value={formFields.timelineId.value || ''}
                  onChange={handleFieldChanged}
                >
                  <option key={-1} value=""></option>
                  {getOptions(lookup?.timelines).map((x, idx) => (
                    <option key={idx} value={x.value}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <InputLabel smallLabel={formFields.timelineId.value} htmlFor="timelineId">
                  Timeline
                </InputLabel>
                {formFields.timelineId.error && <ErrorMessage>{formFields.timelineId.error}</ErrorMessage>}
              </SelectContainer>
              <SelectContainer className={formFields.sellReasonId.error ? 'invalid' : ''}>
                <select
                  id="sellReasonId"
                  name="sellReasonId"
                  value={formFields.sellReasonId.value || ''}
                  onChange={handleFieldChanged}
                >
                  <option key={-1} value=""></option>
                  {getOptions(lookup?.sellingReasons).map((x, idx) => (
                    <option key={idx} value={x.value}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <InputLabel smallLabel={formFields.sellReasonId.value} htmlFor="sellReasonId">
                  Reason for selling
                </InputLabel>
                {formFields.sellReasonId.error && <ErrorMessage>{formFields.sellReasonId.error}</ErrorMessage>}
              </SelectContainer>
              <SelectContainer className={formFields.builtPeriodId.error ? 'invalid' : ''}>
                <select
                  id="builtPeriodId"
                  name="builtPeriodId"
                  value={formFields.builtPeriodId.value || ''}
                  onChange={handleFieldChanged}
                >
                  <option key={-1} value=""></option>
                  {getOptions(lookup?.builtPeriods).map((x, idx) => (
                    <option key={idx} value={x.value}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <InputLabel smallLabel={formFields.builtPeriodId.value} htmlFor="builtPeriodId">
                  Year built
                </InputLabel>
                {formFields.builtPeriodId.error && <ErrorMessage>{formFields.builtPeriodId.error}</ErrorMessage>}
              </SelectContainer>
              <SelectContainer className={formFields.conditionId.error ? 'invalid' : ''}>
                <select
                  id="conditionId"
                  name="conditionId"
                  value={formFields.conditionId.value || ''}
                  onChange={handleFieldChanged}
                >
                  <option key={-1} value=""></option>
                  {getOptions(lookup?.propertyConditions).map((x, idx) => (
                    <option key={idx} value={x.value}>
                      {x.label}
                    </option>
                  ))}
                </select>
                <InputLabel smallLabel={formFields.conditionId.value} htmlFor="conditionId">
                  Property condition
                </InputLabel>
                {formFields.conditionId.error && <ErrorMessage>{formFields.conditionId.error}</ErrorMessage>}
              </SelectContainer>
              <ButtonsContainer>
                <LearnMoreLink href={Routes.CASH_OFFER_ARTICLE} title="Learn more" target="_blank">
                  Learn More
                </LearnMoreLink>
                <CancelButton type="button" onClick={() => dispatch(cashOfferCreators.closeCashOfferModal())}>
                  Cancel
                </CancelButton>
                <SubmitButton type="submit">Get your cash offer</SubmitButton>
              </ButtonsContainer>
            </form>
          )}
        </FormContainer>
      </ModalContainer>
    </ContentContainer>
  );
};

export default withTheme(CashOfferModal);
