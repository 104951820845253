import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import publicIp from "public-ip";
import styled from 'styled-components';
import ReactHtmlParser from "react-html-parser";
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import Enums from '../../../Constants/Enums';
import { DatePicker } from "antd";
import moment from "moment";
import { Container, ModalDiv, CustomButton } from '../../../Assets/Style/commonStyleComponents';
import { smoothScrollTop, goBack } from '../../../Utilities/commonFunctions';
import { dataService } from '../../../Services/DataService';
import { editListingValue } from '../../../Constants/CommonConstants';
import CurrencyInput from 'react-currency-input-field';
import Navbar from '../../Shared/Components/Navbar';
import Footer from '../../Shared/Components/Footer';

const Form = styled.article`
  flex: 2;
  display: block;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  overflow: auto;
  height: 100%;
  border-radius: 6px;
  h1,
  h2 {
    color: ${({ theme }) => theme.primary};
  }
  .mls-static-text {
    h1 {
      font-size: 20px;
      font-weight: 700;
      font-family: 'National-Semi-Bold';
      color: #242B26;
      text-align: center;
      margin-bottom: 20px;
    }
    h3 {
      span {
        font-size: 18px;
        color:#242B26 !important;
      }
    }
    ol {
      margin-left: 17px;
      li {
        padding-bottom: 10px;
        line-height: 1.5;
        font-size: 14px;
      }
    }
  }
`;

const Group = styled.section``;

const Section = styled.div`
  display: block;
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 26px;
  p {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 26px;
  }
  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: 2px;
    padding: 5px;
    margin: 0 2px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px auto 30px;
  &.for-center {
    display: flex;
    justify-content: center;
  }
  &.for-flex {
    display: flex;
    justify-content: center;
    button {
      &:first-child {
        margin-right: 7px;
      }
      &:last-child {
        margin-left: 7px;
      }
    }
  }
`;

const FormDatePicker = styled.div`
  display: inline;
  padding-left: 3px;
  input {
    border-radius: 0;
    border: 1px solid #B698D9;
    font-size: 15px;
    &:focus {
      box-shadow: none;
    }
  }
`;

const inputErrorClass = 'error-input';
const inputErrorClassChecbox = "error-input-checkbox";
const inputErrorClassOption = "error-input-option";
function disabledDate(current) {
  return current && current < moment().add(-1, "days");
}

class PropertyListingForm extends React.Component {
  constructor(props) {
    super(props);
    this.ipAddress = "";
    (async () => {
      this.ipAddress = await publicIp.v4();
    })();
    this.state = {
      targetValues:{},
      targetClickState:true,
      isFormInvalid: false,
      errors: {},
      manipulatedFormField: {},
      linkedChangeValue:{},
      checkedFormID:'',
      prefillChangeValue:{},
      initialFormFieldValue: null,
      filterSellerSigId:{}
    }
    this.state.manipulatedFormField={};
    this.state.linkedChangeValue={};
    this.state.targetValues={};
    this.state.prefillChangeValue={};
    this.paramsValue = new URLSearchParams(window.location.search);
    
  }

  componentDidMount() {
    this.state.manipulatedFormField={};
    const reqObj = {
      listingId: this.paramsValue.get("listingId"),
      formIds: [this.paramsValue.get("formId")],
    }

    this.props.gettingFormListing(reqObj);
    dataService.clearLocal("isLinkedArray");
  }

  renderForm = (content, fields,groupType,sectionPriority) => {
  
   if (groupType.formGroupType === Enums.NUMERIC_VALUE.THREE) {
      return this.getImageFieldControl(content, fields,groupType.formGroupId,sectionPriority);
   }
   content = content.replace(/{FormField:(\d+)}/g, "<field>$1</field>");
    
   content = content.replace(/\[row.*?\]/g, '<div class="row-modify">');
   content = content.replace(/\[\/row]/g, "</div>");
   content = content.replace(/\[column.*?\]/g, '<div class="medium-4">');
   content = content.replace(/\[\/column]/g, "</div>");
   content = content.replace(/\[page-break]/g, "<br />");
   content = content.replace(/\[required]/g, "");
  
  // if (processNewlines) content = content.replace(/\r\n/g, "<br />");
  
   return ReactHtmlParser(content, {
       transform: (node, index) =>
         this.transform(node, index, fields,sectionPriority),
     });
  }

  transform = (node, index, fields,sectionPriority) => {
    
    if (node.type === "tag" && node.name === "field") {
 
       const field = fields.filter(
         (f) => f.formFieldId === parseInt(node.children[0].data)
       )[0]; 
       if (!this.state.manipulatedFormField[field.formFieldId]) {
         let valueForSign=field.value ? field.value : field.prefillText || "";
         if(valueForSign==editListingValue.oneOwner){
           this.setState({showSingCount: Enums.NUMERIC_VALUE.ONE})
         }
         if(valueForSign==editListingValue.twoOwner){
           this.setState({showSingCount: Enums.NUMERIC_VALUE.TWO})
         }
         if(valueForSign==editListingValue.threeOwner){
           this.setState({showSingCount: Enums.NUMERIC_VALUE.THREE})
         }
         let isPrefillValue=false;
         if(!field.value && field.prefillText){
          isPrefillValue=true;
          field.value=field.prefillText;
        }
  
         this.state.manipulatedFormField[field.formFieldId] = {
           displayName: field.displayName,
           formFieldId: field.formFieldId,
           isRequired: field.required,
           value: field.value ? field.value : field.prefillText || "",
           step: this.state.step ? this.state.step : Enums.NUMERIC_VALUE.THREE,
           isPrefill:isPrefillValue,
           isLinked:field.isLinked,
           formPrefillTypeId:field.formPrefillTypeId,
         };
       }
 
       return this.getFieldControl(field, index,sectionPriority);
     }
   };
  handleFormCheckboxField = (e, field, fieldId) => {
    const { manipulatedFormField } = this.state;
   
    const errors = { ...this.state.errors };
    manipulatedFormField[fieldId].value = e.target.checked;
    errors[field] = '';
    this.setState({ manipulatedFormField, errors });
    this.props.saveFormField(this.props.formData.userFormId, fieldId, e.target.checked);
    this.props.handleImageFieldChange({
      listingId: this.props.location.state.listingId,
      fieldName: field,
      formId: this.props.location.state.formData.formId,
      formFieldId: fieldId,
      value: e.target.checked,
    });
  };

  handleFormMultileCheckboxField = (e,fieldId) => {
    const { manipulatedFormField } = this.state;
    const old_value=manipulatedFormField[fieldId]['value'];
    let newval='';
     if(old_value){
         if(e.target.checked){
           newval=old_value+','+e.target.value;
         }else{
           
           newval=this.removeValue(old_value,e.target.value,",")
         }
     }else{
         newval=e.target.value;;
     }
     const errors = { ...this.state.errors };
     manipulatedFormField[fieldId].value = newval;
     errors[fieldId] = '';
     this.setState({ manipulatedFormField, errors });
     this.props.saveFormField(this.props.formData.userFormId, fieldId, newval);
     this.props.handleImageFieldChange({
      listingId: this.paramsValue.get("listingId"),
      formId: this.props.formData.formId,
       formFieldId: fieldId,
       value: newval,
     });
   };
 
   handleFormSingleCheckboxField= (e,fieldId) => {
     const { manipulatedFormField } = this.state;
     let newval='';
     if(e.target.checked){
        newval=e.target.value;
     }
      
      const errors = { ...this.state.errors };
      manipulatedFormField[fieldId].value = newval;
      errors[fieldId] = '';
      this.setState({ manipulatedFormField, errors });
      this.props.saveFormField(this.props.formData.userFormId, fieldId, newval);
      this.props.handleImageFieldChange({
        listingId: this.paramsValue.get("listingId"),
        formId: this.props.formData.formId,
        formFieldId: fieldId,
        value: newval,
      });  
    };

  submitMLSForm = () => {
    const { formData } = this.props;
    const reqObj = {
      'listingId': this.paramsValue.get("listingId"),
      'listingUserForms': [{formId: formData.formId, userFormId: formData.userFormId }],
      ipAddress: this.ipAddress,
    }
    this.props.submitMLSForm(reqObj, () => {
        goBack();
    });
  }

  handleValidation() {
    const fieldData = { ...this.state.manipulatedFormField };
    
    const errors = {};
    let formIsValid = true;
    Object.keys(fieldData).forEach((key) => {
      let checkForValidation=true; 
      if(this.state.targetValues.length>0 && this.state.targetValues.includes(fieldData[key].displayName)){
        checkForValidation = false;
      }
    
      if (checkForValidation && fieldData[key].isRequired && !fieldData[key].value) {
        if(!this.state.filterSellerSigId.hasOwnProperty(key))
        {
          formIsValid = false;
          errors[key] = '*';
        }
      }
    });
    this.setState({ errors });
    return formIsValid;
  }

  handleSubmitMLS = () => {
    if (this.handleValidation()) {
      this.savePrefilledvalue();
    } else {
      this.setState({
        isFormInvalid: true
      })
    }
  }

  handleFormTargetField= (e,options,fieldId,formGroupId) => {
   
    
    if(options){
     this.setState({checkedFormID:formGroupId})
    let optionValue=options.replace(/\u21b5|\r\n|\n/g, "@#$").split("@#$");
      
      let stateObj=[];
     if(!e.target.checked){
        this.savetargetData(optionValue);
     }
    }
  }

  savetargetData=(Optionsval)=>{
    let preFilledArr=[];
    const { manipulatedFormField } = this.state;
    const { formData } = this.props;
    if(Optionsval.length>0 && manipulatedFormField)
    {
        Object.keys(manipulatedFormField).forEach(function (key){
          let displayName=manipulatedFormField[key].displayName;
          if(Optionsval.includes(displayName)){
            preFilledArr.push({
              formFieldId: manipulatedFormField[key].formFieldId,
              value: '',
            })
          }
        });
     }
    

    if (preFilledArr.length > 0) {

     const prefillReqObj = {
        userFormId:  formData.userFormId,
        previewFields: preFilledArr,
      }  
     this.props.savePreviewFormField(prefillReqObj, () => {
      });
    }
   
  }

  savePrefilledvalue = ()=>{
    const { manipulatedFormField } = this.state;
    const { formData } = this.props;
    let preFilledArr=[];
    let linkedObj=dataService.getLocal("isLinkedArray");
    if(manipulatedFormField)
    {
      Object.keys(manipulatedFormField).forEach(function (key){
        if(manipulatedFormField[key].isPrefill){
         
          preFilledArr.push({
            formFieldId: manipulatedFormField[key].formFieldId,
            value: manipulatedFormField[key].value,
          })
        }
        for(let k=0;k<linkedObj?.length; k++)
        {
          
          if(linkedObj[k].hasOwnProperty(manipulatedFormField[key].displayName)){  
            preFilledArr.push({
              formFieldId: manipulatedFormField[key].formFieldId,
              value: linkedObj[k][manipulatedFormField[key].displayName],
            })

          } 
        }
      });
    }
    if(preFilledArr.length > 0) {
        const prefillReqObj = {
          userFormId:  formData.userFormId,
          previewFields: preFilledArr,
        }  
       this.props.savePreviewFormField(prefillReqObj, () => {
          this.submitMLSForm();
        });
    }else{
      this.submitMLSForm();
    }
    
  }

  handleCloseModal = () => {
    this.setState({
      isFormInvalid: false
    })
  
    if(Object.keys(this.state.errors).length>0)
    {
      let topNext=0;
      Object.keys(this.state.errors).forEach((keyName) => {
        let tesNode = ReactDOM.findDOMNode(this.refs[keyName]);
        if(tesNode){
         let top=tesNode.getBoundingClientRect().top + window.scrollY-Enums.NUMERIC_VALUE.THIRTY;
         if(top)
          {
            if(!topNext || top<topNext){
              topNext=top;
            }
          }
        }
      })
  
      window.scroll({ top: topNext, left: 0, behavior: 'smooth' });
    }else{
      smoothScrollTop();
    }
  };

  handleFormFieldChanged = (e, fieldId, inputDataType) => {
    const { manipulatedFormField } = this.state;
    const field = manipulatedFormField[fieldId];
    const errors = { ...this.state.errors };
    const value = inputDataType === Enums.InputTypes.CURRENCY ? e : e.target.value;
    field.value = value;


    if(field.isPrefill){
      this.state.prefillChangeValue[fieldId]= value;
    }
    
    if(field.isLinked){
      Object.keys(manipulatedFormField).forEach((key) => {
          if(field.displayName===manipulatedFormField[key].displayName){
            manipulatedFormField[key].value = value;
            this.state.linkedChangeValue[key]= value;
          }
          
      }) 
  
     let linkedArr=dataService.getLocal("isLinkedArray");
     
     if(linkedArr){
  
        let linkedname=field.displayName;
        let formPrefillTypeId=field.formPrefillTypeId;
      
        let LinkedObj={[linkedname]:value,'formPrefillTypeId':formPrefillTypeId}
        let index = linkedArr.findIndex(it => Object.keys(it)[0] === linkedname);
        if (index === -1) {
            linkedArr.push(LinkedObj);
          } else {
            linkedArr[index] = LinkedObj
          }
          dataService.setLocal("isLinkedArray",linkedArr);
      
      }else{
        let linkedArr=[];
        let linkedname=field.displayName;
        let formPrefillTypeId=field.formPrefillTypeId;
        let LinkedObj={[linkedname]:value,'formPrefillTypeId':formPrefillTypeId}
        linkedArr.push(LinkedObj);
        dataService.setLocal("isLinkedArray",linkedArr);
      }
    }
    errors[fieldId] = '';
    this.setState({ manipulatedFormField, errors });
    this.props.handleImageFieldChange({
      listingId: this.paramsValue.get("listingId"),
      formId: this.props.formData.formId,
      formFieldId: fieldId,
      value: value,
    });
  };

  handleFormFieldFocus = (e) => {
    this.setState({ initialFormFieldValue: e.target.value });
  };

  handleFormFieldBlur = (e) => {
    if (this.state.initialFormFieldValue !== e.target.value) {
      this.props.saveFormField(this.props.formData.userFormId, e.target.name, e.target.value);
    }

    this.setState({ initialFormFieldValue: null });
  };

  handleCurrencyFormFieldBlur = (e) => {
    e.target.value = e.target.value
      ? parseFloat(e.target.value.replace(/\$|,/g, ''))
      : '';

    this.handleFormFieldBlur(e);
  };

  handleFormFieldSelect = (field, fieldId, isTypeDate, e) => {
    const { manipulatedFormField } = this.state;
    const errors = { ...this.state.errors };
    const selectedValue = isTypeDate ? e._d : e.target.value;
    manipulatedFormField[fieldId].value = selectedValue;
    errors[field] = '';
    this.setState({ manipulatedFormField, errors });
    this.props.saveFormField(this.props.formData.userFormId, fieldId, selectedValue);
    this.props.handleImageFieldChange({
      listingId: this.paramsValue.get("listingId"),
      formId: this.props.formData.formId,
      formFieldId: fieldId,
      value: selectedValue,
    });
  };

  isMSultiChecked=(value,string)=>{
    if(string===value){
      return true;
    }else{
      return false;
    }
  }

  isMultiChecked=(value,string)=>{
    if(string && value){
      const is_checked = string.indexOf(value) != -1;
      return is_checked;
    } return false;
  } 

  removeValue = (list, value, separator) =>{
    separator = separator || ",";
    var values = list.split(separator);
    for(var i = 0 ; i < values.length ; i++) {
      if(values[i] == value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  }

  getImageFieldControl = (node, fields,formGroupId,sectionPriority) => {
    if(fields){
      fields.sort(function(a,b){
        return parseInt(b.inputDataType)  - parseInt(a.inputDataType);
      })
    }
    
    let overlaySignFlag=Enums.NUMERIC_VALUE.ZERO;
    let showOwner=Enums.NUMERIC_VALUE.TWO;
    let matches = fields.filter(a => a.value !== null && a.value && (typeof a.value===editListingValue.string)).filter(a => a.value.toLowerCase().includes(editListingValue.twoOwner) || a.value.toLowerCase().includes(editListingValue.oneOwner) || a.value.toLowerCase().includes(editListingValue.threeOwner));
    if(matches.length>Enums.NUMERIC_VALUE.ZERO){
      this.state.filterSellerSigId={};
      overlaySignFlag=Enums.NUMERIC_VALUE.ONE;
      let ownerVal=matches[0]['value'].toLowerCase();
      if(ownerVal==editListingValue.oneOwner){
        showOwner= Enums.NUMERIC_VALUE.ONE;
      }
      if(ownerVal==editListingValue.twoOwner){
        showOwner= Enums.NUMERIC_VALUE.TWO;
      }
      if(ownerVal==editListingValue.threeOwner){
        showOwner= Enums.NUMERIC_VALUE.THREE;
      }
    }else{
      if(overlaySignFlag==Enums.NUMERIC_VALUE.ZERO){
       showOwner=this.state.showSingCount;
      }
    }
    for(let j=Enums.NUMERIC_VALUE.THREE; j>showOwner;j--){
      const regexp = new RegExp('sellersig'+j, 'i');
      const regexpSe = new RegExp('seller'+j, 'i');
      const regexpDate = new RegExp('date'+j, 'i');
      const regexpEmail = new RegExp(['emailaddr'+j,'seller'+j+'email'].map(function(r){
        return (r+"").replace(/\//g,"");
      }).join("|"), "g");
      
      let Displayindex = fields.findIndex( x => regexp.test(x.displayName));
      let DisplayindexSe = fields.findIndex( x => regexpSe.test(x.displayName));
      let Dareindex = fields.findIndex( x => regexpDate.test(x.displayName));
      let Emailindex = fields.findIndex( x => regexpEmail.test(x.displayName));
      if(Displayindex>Enums.NUMERIC_VALUE.ZERO){
        this.state.filterSellerSigId[fields[Displayindex].formFieldId]=true;
      }
      if(DisplayindexSe>Enums.NUMERIC_VALUE.ZERO){
        this.state.filterSellerSigId[fields[DisplayindexSe].formFieldId]=true;
      }
      if(Dareindex>Enums.NUMERIC_VALUE.ZERO){
        this.state.filterSellerSigId[fields[Dareindex].formFieldId]=true;
      }
      if(Emailindex>Enums.NUMERIC_VALUE.ZERO){
        this.state.filterSellerSigId[fields[Emailindex].formFieldId]=true;
      }
      fields = fields.filter(x => !regexp.test(x.displayName));
      fields = fields.filter(x => !regexpSe.test(x.displayName));
      fields = fields.filter(x => !regexpDate.test(x.displayName));
      fields = fields.filter(x => !regexpEmail.test(x.displayName));
    }
    return (
      <div className="image-with-form">
        <img src={node} />
        {fields.map((field, index) => {
          let isPrefillValue=false;
         
          if(!field.value && field.prefillText && !this.state.prefillChangeValue.hasOwnProperty(field.formFieldId)){
            isPrefillValue= true;
            field.value=field.prefillText;
          }
         
          if (!this.state.manipulatedFormField[field.formFieldId]) {
            this.state.manipulatedFormField[field.formFieldId] = {
              displayName: field.displayName,
              formFieldId: field.formFieldId,
              isRequired: field.required,
              value: field?.value ? field.value : field.prefillText || '',
              isPrefill:isPrefillValue,
              isLinked:field.isLinked,
            };
          }

          let parseArray=dataService.getLocal("isLinkedArray");
          if(parseArray){
            let index = parseArray.findIndex(it => Object.keys(it)[0] === field.displayName);
            
            if (index > -1) {
              field.value=parseArray[index][field.displayName];
            }
           }else if(!field.value && !this.state.prefillChangeValue.hasOwnProperty(field.formFieldId)){
             
            field.value=field.prefillText;
          }
          if(this.state.linkedChangeValue.hasOwnProperty(field.formFieldId)){
            field.prefillText='';
          }else if(!field.value && field.prefillText && !this.state.prefillChangeValue.hasOwnProperty(field.formFieldId)){
            field.value=field.prefillText;
          }
          
          if(field.inputDataType === Enums.InputTypes.ON_OFF && field.targetFields){
           let obj=[]
           let optionValue=field.targetFields.replace(/\u21b5|\r\n|\n/g, "@#$").split("@#$");
           
            Object.keys(optionValue).forEach((key) => {
              if(field.value===''){
                field.value=false;
              }
              obj[key]={'isCheckd':field.value,'displayName':optionValue[key],'formGroupId':formGroupId}
              
            })
            this.state.targetValues[field.formFieldId]=obj;
          }
          let targetClass='';
         
          if(Object.keys(this.state.targetValues).length>0){
            Object.keys(this.state.targetValues).forEach((Statekey) => {
            let singleArr=this.state.targetValues[Statekey];
          
            Object.keys(singleArr).forEach((key) => {
              
            if(this.state.checkedFormID==='' || this.state.checkedFormID===singleArr[key]['formGroupId']){

              if(singleArr[key]['displayName']===field.displayName &&  (singleArr[key]['isCheckd']=='true' || singleArr[key]['isCheckd']===true)){
                    field.readOnly=false;
                }
                if(singleArr[key]['displayName']===field.displayName  && (singleArr[key]['isCheckd']=='false' || singleArr[key]['isCheckd']===false)){
                    field.value='';
                    field.readOnly=true;
                }
              }
            })
          })
        }
          if (field.readOnly) {
          return (
              <div style={{ "position": "absolute", "top": field.overlayStartY + 'px', "left": field.overlayStartX + 'px' }}>
                <input
                  style={{ "height": field.overlayHeight + 'px', "width": field.overlayWidth + 'px' }}
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  readOnly
                  defaultValue={field.prefillText}
                  className='disabled-input'
                />
              </div>
            );
          }

          if (field.options) {
            return (
              <>
              <div style={{ "position": "absolute", "top": field.overlayStartY + 'px', "left": field.overlayStartX + 'px' }}>
                <select
                   style={{
                    height: field.overlayHeight?field.overlayHeight: 20 + "px",
                    width: field.overlayWidth?field.overlayWidth: 100 + "px",
                    padding: 0,
                  }}  
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, false)}
                  value={field.value}
                  ref={field.formFieldId}
                  className={this.state.errors[field.formFieldId] ? inputErrorClass : targetClass}
                >
                <option key={Math.random()} value=''>
                    {' '}
                    Select Value{' '}
                  </option>
                  {field.options
                    .replace(/\u21b5|\r\n|\n/g, "@#$")
                    .split("@#$")
                    .map((o) => (
                      <option
                        key={`${field.formFieldId}_${index}_${o}`}
                        value={o}
                      >
                        {o}
                      </option>
                  ))}
                </select>

              </div>
              {
              field.required &&
              <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }} className="additional-label">Required</label>
              } 
              </>
            );
          }

          if (!field.readOnly && field.inputDataType === Enums.InputTypes.TEXT_SHORT) {
            return (
              <>
              <div style={{ "position": "absolute", "top": field.overlayStartY + 'px', "left": field.overlayStartX + 'px' }}>
                <input
                   style={{
                    height: field.overlayHeight?field.overlayHeight:20 + "px",
                    width: field.overlayWidth?field.overlayWidth:100 + "px",
                  }} 
                  key={`${field.formFieldId}_${index}`}
                  type="text"
                  name={field.formFieldId}
                  value={field.value || ''}
                  onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
                  onBlur={this.handleFormFieldBlur}
                  onFocus={this.handleFormFieldFocus}
                  required={field.required && !field.value}
                  ref={field.formFieldId}
                  className={this.state.errors[field.formFieldId] ? inputErrorClass : targetClass}
                />
              </div>
              {
              field.required &&
              <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }} className="additional-label">Required</label>
              } 
              </>
            );
          }

          if (
            !field.readOnly &&
            field.inputDataType === Enums.InputTypes.TEXT_LONG
          ) { 
            return (
              <>
              <div
                style={{
                  position: 'absolute',
                  top: field.overlayStartY + 'px',
                  left: field.overlayStartX + 'px',
                }}
              >

              <textarea
                style={{
                  height: field.overlayHeight?field.overlayHeight:20 + "px",
                  width: field.overlayWidth?field.overlayWidth:100 + "px",
                }}
              key={`${field.formFieldId}_${index}`}
              type='text'
              name={field.formFieldId}
              value={field.value || ''}
              onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
              onBlur={this.handleFormFieldBlur}
              onFocus={this.handleFormFieldFocus}
              ref={field.formFieldId}
              className={this.state.errors[field.formFieldId] ? inputErrorClass : targetClass}
            />
                
              </div>
              {
              field.required &&
              <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }} className="additional-label">Required</label>
              } 
              </>
            );
          }

          if (field.inputDataType === Enums.InputTypes.BOOLEAN) {
            return (
              <>
              <div style={{ "position": "absolute", "top": field.overlayStartY + 'px', "left": field.overlayStartX + 'px' ,zIndex: 1,
                  height: field.overlayHeight + "px",
                  width: field.overlayWidth + "px",}}>
                <input
                  style={{ "height": field.overlayHeight + 'px', "width": field.overlayWidth + 'px' }}
                  key={`${field.formFieldId}_${index}`}
                  type="checkbox"
                  name={field.formFieldId}
                  checked={field?.value ? JSON.parse(field.value) : false}
                  onChange={(e) => this.handleFormCheckboxField(e, field.displayName, field.formFieldId)}
                  ref={field.formFieldId}
                  className={this.state.errors[field.formFieldId] ? inputErrorClass : targetClass}
                />
              </div>
              {
              field.required &&
              <label style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }} className="additional-label">Required</label>
              } 
              </>
            );
          }

          if (field.inputDataType === Enums.InputTypes.ON_OFF) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                }}
              >
                <input
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  key={`${field.formFieldId}_${index}`}
                  type="checkbox"
                  name={field.formFieldId}
                  checked={field?.value ? JSON.parse(field.value) : false}
                  onChange={(e) =>{
                    this.handleFormTargetField(
                      e,
                      field.targetFields,
                      field.formFieldId,
                      formGroupId,
                    );this.handleFormCheckboxField(
                      e,
                      field.displayName,
                      field.formFieldId
                    )
                  }
                  }
                  onBlur={this.handleFormFieldBlur}
                  onFocus={this.handleFormFieldFocus}
                  className={this.state.errors[field.formFieldId] ? inputErrorClassChecbox : ''}
                  ref={field.formFieldId}
                />
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }

          if (field.inputDataType === Enums.InputTypes.CURRENCY) {
            return (
              <>
              <div style={{ "position": "absolute", "top": field.overlayStartY + 'px', "left": field.overlayStartX + 'px' }}>
                <CurrencyInput
                  style={{
                    height: field.overlayHeight ? field.overlayHeight : 20 + "px",
                    width: field.overlayWidth ? field.overlayWidth : 100 + "px",
                  }} 
                  key={`${field.formFieldId}_${index}`}
                  decimalsLimit={2}
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  defaultValue={field?.value ? field.value : field.prefillText || ''}
                  name={field.formFieldId}
                  onValueChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
                  onBlur={this.handleCurrencyFormFieldBlur}
                  onFocus={this.handleFormFieldFocus}
                  required={field.required && !field.value}
                  ref={field.formFieldId}
                  className={this.state.errors[field.formFieldId] ? inputErrorClass : targetClass}
                />
              </div>
              {field.required &&
                <label style={{
                  right: -192 + "px",
                  top: field.overlayStartY + "px",
                }} className="additional-label">Required</label>} 
              </>);
          }

          if (field.inputDataType === Enums.InputTypes.DATE_TIME) {
            return (
              <>
              <div
                style={{
                  position: "absolute",
                  top: field.overlayStartY + "px",
                  left: field.overlayStartX + "px",
                  zIndex: 1,
                }}
              >
               <FormDatePicker>
              {field.value ? (
                <DatePicker
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  size="small"
                  defaultValue={moment(field.value)}
                  onChange={this.handleFormFieldSelect.bind(
                    this,
                    field.displayName,
                    field.formFieldId,
                    true
                  )}
                  ref={field.formFieldId}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    this.state.errors[field.displayName] ? inputErrorClass : ""
                  }
                />
              ) : (
                <DatePicker
                  style={{
                    height: field.overlayHeight + "px",
                    width: field.overlayWidth + "px",
                  }}
                  onChange={this.handleFormFieldSelect.bind(
                    this,
                    field.displayName,
                    field.formFieldId,
                    true
                  )}
                  ref={field.formFieldId}
                  inputReadOnly
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  key={`${field.formFieldId}_${index}`}
                  name={field.formFieldId}
                  allowClear={false}
                  className={
                    this.state.errors[field.displayName] ? inputErrorClass : ""
                  }
                />
              )}
              <span className="text-error">
                {this.state.errors[field.displayName]}
              </span>
            </FormDatePicker>
                
              </div>
              {
               field.required &&
               <label 
               style={{
                right: -192 + "px",
                top: field.overlayStartY + "px",
              }}
               className="additional-label">Required</label>
               }
              </>
            );
          }
        })
        }
      </div>
    );
  }

  getFieldControl = (field, index,sectionPriority) => {
    let fieldWidth;
     if(field.value?.length>=Enums.NUMERIC_VALUE.THIRTY){
        fieldWidth=Enums.NUMERIC_VALUE.TWOHUNDREDTWENTY+(Enums.NUMERIC_VALUE.TWO*field.value?.length);
     }
     if(field.displayName===editListingValue.sellername){
      fieldWidth=Enums.NUMERIC_VALUE.TWOHUNDREDSEVENTEEN;
     }

    if (field.readOnly) {
        return (
          <>
            <input
              type="text"
              key={`${field.formFieldId}_${index}`}
              value={field.prefillText}
              className="disabled-input"
            />
            <label className="for-readonly">Read Only</label>
          </>
        );
      }
      if (field.options) {
        if(!field.value || field.value===null){
          field.value='';
        }
       return (
          <>
          {field.inputDataType === Enums.InputTypes.BOOLEAN ? (
         <>
         
          {field.required && <label>Required</label>}
  
          {field.optionTypeId===Enums.NUMERIC_VALUE.ONE ? (
            <>
           {field.options.split('\n').map((o) => (
            <div className="checkbox-default">
              <input
                key={`${field.formFieldId}_${index}_${o}`}
                type="checkbox"
                value={o}
                name={field.formFieldId}
                checked={this.isMSultiChecked(o,field.value)}
                onChange={(e) => this.handleFormSingleCheckboxField(e,field.formFieldId)}
                ref={field.displayName}
                className={this.state.errors[field.formFieldId] ? inputErrorClassChecbox : ""}
                 /><span>{o}</span>
              </div>
             
              ))}
            </>
         ) : (
            <>
           {field.options.split('\n').map((o) => (
            <div className="checkbox-default">
              <input
                key={`${field.formFieldId}_${index}_${o}`}
                type="checkbox"
                value={o}
                name={field.formFieldId}
                checked={this.isMultiChecked(o,field.value)}
                onChange={(e) => this.handleFormMultileCheckboxField(e,field.formFieldId)}
                ref={field.displayName}
                className={this.state.errors[field.formFieldId] ? inputErrorClassChecbox  : ""}
                 /><span>{o}</span>
              </div>
             
              ))}
              </>
               )}
          </>
  
          ):(
          <>
            <select
              key={`${field.formFieldId}_${index}`}
              name={field.formFieldId}
              onChange={this.handleFormFieldSelect.bind(this, field.displayName, field.formFieldId, false)}
              value={field.value}
              className={this.state.errors[field.formFieldId] ? inputErrorClassOption : ""}
            >
              {
                field.required &&
                <label>Required</label>
              }
              <option key={Math.random()} value=''> Select Value </option>
              {field.options.split('\n').map((o) => (
                <option key={`${field.formFieldId}_${index}_${o}`} value={o}>
                  {o}
                </option>
              ))}
            </select>
            {field.required && <label>Required</label>}
          </>
          )}
          </>
        )
      }

      switch (field.inputDataType) {
        case Enums.InputTypes.TEXT_SHORT:
          return (
            <>
              <input
                style={fieldWidth ? {width: fieldWidth + "px"}: {}}
                key={`${field.formFieldId}_${index}`}
                type="text"
                name={field.formFieldId}
                value={field.value || ''}
                onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
                onBlur={this.handleFormFieldBlur}
                onFocus={this.handleFormFieldFocus}
                required={field.required && !field.value}
                ref={field.displayName}
                className={this.state.errors[field.formFieldId] ? inputErrorClass  : ""}
              />
              {field.required && <label>Required</label>}</>
          );
          case Enums.InputTypes.CURRENCY:
            return (
              <>
                <CurrencyInput
                  style={fieldWidth ? {width: fieldWidth + "px"}: {}}
                  key={`${field.formFieldId}_${index}`}
                  decimalsLimit={2}
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  defaultValue={field?.value ? field.value : field.prefillText || ''}
                  name={field.formFieldId}
                  onValueChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
                  onBlurCapture={this.handleCurrencyFormFieldBlur}
                  onFocus={this.handleFormFieldFocus}
                  required={field.required && !field.value}
                  ref={field.formFieldId}
                  className={this.state.errors[field.formFieldId] ? inputErrorClass : ""}
                />
                {field.required && <label>Required</label>}</>
            );
        case Enums.InputTypes.TEXT_LONG:
          return (
            <div>
              <textarea
                key={`${field.formFieldId}_${index}`}
                type="text"
                name={field.formFieldId}
                value={field.value || ''}
                onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
                onBlur={this.handleFormFieldBlur}
                onFocus={this.handleFormFieldFocus}
                ref={field.displayName}
                className={
                  this.state.errors[field.formFieldId] ? inputErrorClass : ""
                }
              />
              {field.required && <label>Required</label>}
            </div>
          );
          case Enums.InputTypes.WHOLE_NUMBER:
            return (
              <>
                <input
                  key={`${field.formFieldId}_${index}`}
                  type="number"
                  name={field.formFieldId}
                  value={field.value || ''}
                  onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
                  onBlur={this.handleFormFieldBlur}
                  onFocus={this.handleFormFieldFocus}
                  ref={field.displayName}
                  className={this.state.errors[field.formFieldId] ? inputErrorClass : ''}
                />
                {
                  field.required &&
                  <label>Required</label>
                }
              </>
            );
        case Enums.InputTypes.DECIMAL:
          return (
            <>
              <input
                key={`${field.formFieldId}_${index}`}
                type="number"
                name={field.formFieldId}
                value={field.value || ''}
                onChange={(e) => this.handleFormFieldChanged(e, field.formFieldId, field.inputDataType)}
                onBlur={this.handleFormFieldBlur}
                onFocus={this.handleFormFieldFocus}
                ref={field.displayName}
                className={
                  this.state.errors[field.formFieldId] ? inputErrorClass : ""
                }
              />
              {field.required && <label>Required</label>}
            </>
          );
        case Enums.InputTypes.BOOLEAN:
          return (
            <>
             {field.required && <label>Required</label>}
              <input
                key={`${field.formFieldId}_${index}`}
                type="checkbox"
                name={field.formFieldId}
                checked={field?.value ? JSON.parse(field.value) : false}
                onChange={(e) =>
                  this.handleFormCheckboxField(
                    e,
                    field.displayName,
                    field.formFieldId
                  )
                }
                ref={field.displayName}
                className={
                  this.state.errors[field.formFieldId] ? inputErrorClassChecbox : ""
                }
              />
             
            </>
          );
  
        case Enums.InputTypes.DATE_TIME:
          return (
            <>
              <FormDatePicker>
                {field.value ? (
                  <DatePicker
                    size="large"
                    value={moment(field.value)}
                    onChange={this.handleFormFieldSelect.bind(
                      this,
                      field.displayName,
                      field.formFieldId,
                      true
                    )}
                    ref={field.displayName}
                    inputReadOnly
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    key={`${field.formFieldId}_${index}`}
                    name={field.formFieldId}
                    allowClear={false}
                    className={
                      this.state.errors[field.displayName] ? inputErrorClass : ""
                    }
                  />
                ) : (
                  <DatePicker
                    size="large"
                    onChange={this.handleFormFieldSelect.bind(
                      this,
                      field.displayName,
                      field.formFieldId,
                      true
                    )}
                    ref={field.displayName}
                    inputReadOnly
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    key={`${field.formFieldId}_${index}`}
                    name={field.formFieldId}
                    allowClear={false}
                    className={
                      this.state.errors[field.formFieldId] ? inputErrorClass : ""
                    }
                  />
                )}
                {field.required && <label>Required</label>}
                <span className="text-error">
                  {this.state.errors[field.displayName]}
                </span>
              </FormDatePicker>
            </>
          );
        default:
          return "";
      }
    };

  render() {
    const { isFormInvalid } = this.state;
    const createMarkup = (description) => ({ __html: description });
    return (
      <React.Fragment>
        <Navbar />
        <Container>
          <a className="back-btn" href="javascript:void(0)" onClick={() => goBack()}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</a>
          <Form>
          <div className="mls-static-text">
            <h1>{`MLS ${this.props?.formData?.name || ''}`}</h1>
            <div dangerouslySetInnerHTML={createMarkup(this.props?.formData?.formInstruction?.description)}></div>
          </div>
            {this.props?.formData?.groups.map((g, i) => {
              return (
                <Group key={i}>
                  {g.sections.map((s, j) => {
                    return (
                      <>
                       <div className={`${
                        g.formGroupType === Enums.NUMERIC_VALUE.THREE &&
                        "dynamic-form"
                      } ${
                        g.formGroupType !== Enums.NUMERIC_VALUE.THREE && "dynamic-form-before"
                      }`}>
                    <Section key={j}>
                        {this.renderForm(
                          s.content,
                          s.fields,
                          g,s.priority
                        )}
                      </Section>
                      </div>
                      </>
                    );
                  })}
                </Group>
              );
            })}
          </Form>
          <ButtonWrapper className="for-flex btn-space-modify">
          <CustomButton onClick={() => goBack()} className="faded small">Back</CustomButton>
            <CustomButton
              onClick={this.handleSubmitMLS}
              className="small"
            >
              Submit to MLS
            </CustomButton>
            
          </ButtonWrapper>
        </Container>
        <ModalDiv
          title="Required"
          cancelButtonProps={{ style: { display: 'none' } }}
          visible={isFormInvalid}
          onOk={this.handleCloseModal}
          okText="OK"
          destroyOnClose={true}
          closable={false}
          className="confirm-modal"
        >
          <span className="modal-icon"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
          <p className="static-text">You missed some required fields.<br></br> Please Fill them out now.</p>
        </ModalDiv>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    listing: state.dashboard.listing,
    formData: state.dashboard.formData,
    userDetails: state.user.userDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...dashboardActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyListingForm);
