import React from 'react';
import styled from 'styled-components';
import { cursorStyles } from '../../../Constants/CommonConstants';
import MaskedInput from 'react-text-mask';

const AnnotatedInputWrapper = styled.div`
  margin:0 0 25px;
  display:flex;
  flex:1;
  flex-direction:column;
  text-align:left;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  text-transform: capitalize;
  margin-bottom: 10px;
  color:#242B26;
`;

const Description = styled.div`
  margin: 10px 0 0 0;
  font-size:12px;
  font-weight:200;
  flex:1;
  display: none;
`;

const DescriptionContent = styled.div`
  margin: 0;
`

const InputWrapper = styled.div`
  margin: 0;
  border: 1px solid ${props => props.error ? "red" : "#dadada"};
  border-radius:6px;
  transition:border .25s;
  background:#fff;
  height: 47px;
  box-shadow: 0 2px 4px 0 rgba(4,66,96,.06);
  &:focus-within {
      border:1px solid ${props => props.error ? "red" : props.theme.primary};
  }
  &.error-form {
      & + .error-text {
          display: block;
      }
  }
  .select-arrow-box {
    position: relative;
    .select-down-arrow {
      position: absolute;
      right: 3px;
      top: 10px;
    }
  }
`;

const Input = styled.input`
  width:100%;
  font-size:16px;
  padding:8px;
`;

const MaskedInputStyled = styled(MaskedInput)`
  width:100%;
  font-size:16px;
  padding:8px;
`;

const ErrorDiv = styled.div`
  font-size:14px;
  color: red;
  margin: 0;
  font-weight:400;
  flex:1;
`;

const Select = styled.select`
  position: relative;
  width:100%;
  height: 45px;
  font-size: 15px;
  padding: 0 10px;
  border-radius: 6px;
  appearance: none;
`;

const Hint = styled.span`
  margin: 5px 0 0 0;
  font-size:12px;
  font-weight:200;
  flex:1;
`;

export default class AnnotatedInput extends React.Component {
  render() {
    return (
      <AnnotatedInputWrapper className={this.props.className}>
        <Label htmlFor={this.props.name}>{this.props.title}</Label>
        <InputWrapper error={this.props.error} className={this.props.error ? "error-form" : ""}>
          {this.props.options && this.props.isKeyPaired === undefined &&
            <div className="select-arrow-box">
              <Select
                styles={cursorStyles}
                disabled={this.props.disabled}
                aria-describedby={`${this.props.name}-hint`}
                name={this.props.name} value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                autoComplete="off"
              >
                {this.props.placeholder &&
                  <option value="">{this.props.placeholder}</option>
                }
                {this.props.options && Object.keys(this.props.options).map(key => (
                  <option value={key} key={key}>{this.props.options[key]}</option>
                ))}
              </Select>
            </div>
          }
          {this.props.options && this.props.isKeyPaired &&
            <div className="select-arrow-box">
              <Select
                styles={cursorStyles}
                disabled={this.props.disabled}
                aria-describedby={`${this.props.name}-hint`}
                name={this.props.name} value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                autoComplete="off"
              >
                {this.props.placeholder &&
                  <option value="">{this.props.placeholder}</option>
                }
                {this.props.options && Object.keys(this.props.options).map(key => (
                  <option value={this.props.options[key].key} key={key}>{this.props.options[key].value}</option>
                ))}
              </Select>
            </div>
          }
          {!this.props.options && this.props.mask === undefined &&
            <Input
              type={this.props.type || "text"}
              id={this.props.name}
              aria-describedby={`${this.props.name}-hint`}
              name={this.props.name}
              value={this.props.value}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              autoComplete="off"
              placeholder={this.props?.placeholder}
            />
          }
          {!this.props.options && this.props.mask !== undefined &&
            <MaskedInputStyled
              pipe={this.props.pipe}
              mask={this.props.mask}
              type={this.props.type || "text"}
              id={this.props.name}
              aria-describedby={`${this.props.name}-hint`}
              name={this.props.name}
              value={this.props.value}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              autoComplete="off"
            />
          }
        </InputWrapper>
        <Description id={`${this.props.name}-hint`} className="error-text">
          <DescriptionContent>
            {this.props.children}
          </DescriptionContent>
          {this.props.error &&
            <ErrorDiv>{this.props.error}</ErrorDiv>
          }
        </Description>
        {this.props.hint && <Hint>{this.props.hint}</Hint>}
      </AnnotatedInputWrapper>
    );
  }
}
