import React from 'react';
import styled from 'styled-components';
import v8n from 'v8n';
import { RowFlex, InputFlex, CustomButton, HeadTextWrap, Heading, HeadText, DetailsWrapper } from '../../../Assets/Style/commonStyleComponents';
import { phoneMask, regexPattern, validationMessages } from '../../../Constants/CommonConstants';
import { CheckMarkIcon } from '../../Shared/Components/Icons/CheckMarkIcon';
import { convertPhone } from '../../../Utilities/commonFunctions';

const LinkStyledButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: baseline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: min-content;
    line-height: normal;
    border-radius: 0.375rem;
    font-family: 'National-Semi-Bold';
    height: auto;
    min-width: 2.5rem;
    font-size: 14px;
    padding: 0px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #718096;
    background-color: #ffffffff;
    text-decoration: underline;
    color: inherit;
  }
`;

const LinkConfimationContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0.75rem 1rem;
    align-items: center;
`;

const LinkConfimationText = styled.span`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 1.5rem
    text-align: left;
    margin-left: 1rem;
`;
export default class AccountDetails extends React.Component {
  state = {
    fields: {
      firstName: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.firstName,
        }],
        value: this.props.firstName || ""
      },
      lastName: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.lastName,
        }],
        value: this.props.lastName || ""
      },
      phoneNumber: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.phoneNumber,
        },
        {
          rule: v8n().pattern(regexPattern.PHONE),
          message: "Please enter a valid phone number"
        }],
        mask: phoneMask,
        value: this.props.phoneNumber || ""
      },
      email: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.email,
        },
        {
          rule: v8n().pattern(regexPattern.EMAIL),
          message: validationMessages.validEmail,
        }],
        value: this.props.email || ""
      },

      emailConfirm: {
        rules: [{
          rule: v8n().not.null().not.empty(),
          message: validationMessages.emailNotMatch
        },
        {
          rule: v8n().pattern(regexPattern.EMAIL),
          message: validationMessages.validEmail,
        }],
        value: this.props.email || ""
      },

      password: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.password,
        },
        {
          rule: v8n().pattern(regexPattern.PASSWORD),
          message: validationMessages.validPassword
        }],
        value: this.props.password || ""
      },

      passwordConfirm: {
        rules: [{
          rule: v8n().not.empty().not.null(),
          message: validationMessages.passwordNotMatch
        }],
        value: this.props.passwordConfirm || ""
      }
    }
  };

  handleFieldChanged = (e) => {
    let accountFields = { ...this.state.fields };

    accountFields[e.target.name].value = e.target.value;
    this.setState({ fields: { ...accountFields } });
  }

  handleFieldBlur = (e) => {
    this.validateAccountField(e.target.name, e.target.value);
  }

  validateAccountField = (key, value) => {
    let fields = { ...this.state.fields };
    let isError = false;
    for (let i = 0; i < fields[key].rules.length; i++) {
      let rule = fields[key].rules[i];
      if (fields.passwordConfirm.value) {
        isError = fields.password.value !== fields.passwordConfirm.value;
        if (isError) {
          fields['passwordConfirm'].error = validationMessages.passwordNotMatch;
          break;
        }
      }

      if (fields.emailConfirm.value) {
        isError = fields.email.value !== fields.emailConfirm.value;
        if (isError) {
          fields['emailConfirm'].error = validationMessages.emailNotMatch;
          break;
        }
      }

      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    this.setState({ fields: { ...fields }, isError: Object.keys(fields).some(k => fields[k].error !== undefined && fields[k].error !== null) });

    return isError;
  }

  handleSaveAccountDetails = (e) => {
    e.preventDefault();

    let isError = false;

    Object.keys(this.state.fields).forEach((k) => {
      const isErrorExist = this.validateAccountField(k, this.state.fields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    })

    if (!isError) {
      this.props.handleSaveAccountDetails(
        {
          ...(Object.keys(this.state.fields).reduce((retVal, key) => {
            retVal[key] = this.state.fields[key].value;
            return retVal;
          }, {})),
          phoneNumber: convertPhone(this.state.fields.phoneNumber.value),
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <HeadTextWrap>
          <Heading>Account Details</Heading>
          <HeadText>Give us a little more info so that we can create an account for you. <br />
            Already have an account?
            <LinkStyledButton onClick={() => this.props.openLoginModal()}>
              Sign in
            </LinkStyledButton>
          </HeadText>
        </HeadTextWrap>
        <DetailsWrapper>
          <form onSubmit={this.handleSaveAccountDetails} autoComplete="off">
            <RowFlex>
              <InputFlex
                name="firstName"
                title="First Name"
                className="inputgap"
                value={this.state.fields.firstName.value}
                error={this.state.fields.firstName.error || (this.props.errors.firstName || [])[0]}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <InputFlex
                name="lastName"
                title="Last Name"
                className="inputgap"
                value={this.state.fields.lastName.value}
                error={this.state.fields.lastName.error || (this.props.errors.lastName || [])[0]}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
            </RowFlex>
            <RowFlex>
              <InputFlex
                name="phoneNumber"
                title="Phone Number"
                className="inputgap with-single"
                type="tel"
                value={this.state.fields.phoneNumber.value}
                error={this.state.fields.phoneNumber.error || (this.props.errors.phoneNumber || [])[0]}
                mask={this.state.fields.phoneNumber.mask} onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              >
                <span className="phone-example">Example: <samp>(555) 123-1234</samp></span>
              </InputFlex>
            </RowFlex>
            <RowFlex>
              <InputFlex
                name="email"
                title="Email Address"
                className="inputgap"
                type="email"
                value={this.state.fields.email.value}
                error={this.state.fields.email.error || (this.props.errors.email || [])[0]}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              >
                <span className="phone-example">Example: <samp>mike@unrealestate.com</samp></span>
              </InputFlex>
              <InputFlex
                name="emailConfirm"
                title="Confirm Email Address"
                className="inputgap"
                type="email"
                value={this.state.fields.emailConfirm.value}
                error={this.state.fields.emailConfirm.error || (this.props.errors.emailConfirm || [])[0]}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
            </RowFlex>
            <RowFlex>
              <div className="tooltip-with-input">
                <InputFlex
                  name="password"
                  title="Password"
                  className="inputgap"
                  type="password"
                  value={this.state.fields.password.value}
                  error={this.state.fields.password.error || (this.props.errors.pasword || [])[0]}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <div className="info-tooltip-box">
                  <span className="info-tooltip-icon">
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </span>
                  <div className="info-tooltip-data">
                    <p>{validationMessages.validPassword}</p>
                  </div>
                </div>
              </div>
              <InputFlex
                name="passwordConfirm"
                title="Confirm Password"
                className="inputgap input-tooltip-ui"
                type="password"
                value={this.state.fields.passwordConfirm.value}
                error={this.state.fields.passwordConfirm.error || (this.props.errors.passwordConfirm || [])[0]}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
            </RowFlex>
            {this.props.showEmailConfirmationText && (
              <LinkConfimationContainer>
                <CheckMarkIcon />
                  <LinkConfimationText>
                    Confirmation link was sent to your email address.
                  </LinkConfimationText>
              </LinkConfimationContainer>
            )}
            <CustomButton type="submit" className="block" onClick={this.handleSaveAccountDetails}>Save</CustomButton>
          </form>
        </DetailsWrapper>
      </React.Fragment>
    );
  }
}
