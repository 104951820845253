import LogRocket from 'logrocket';
import { lookupService } from '../../Services/LookupService';
import { startLoading, endLoading } from '../LoaderActions';
import { removeNullUndefined } from '../../Utilities/commonFunctions';

export const LookupActions = {
    PROPERTY_TYPES_REQUESTED: 'PROPERTY_TYPES_REQUESTED',
    PROPERTY_TYPES_RESOLVED: 'PROPERTY_TYPES_RESOLVED',
    PROPERTY_TYPES_FAILED: 'PROPERTY_TYPES_FAILED',
    MLS_CATEGORIES_REQUESTED: 'MLS_CATEGORIES_REQUESTED',
    MLS_CATEGORIES_RESOLVED: 'MLS_CATEGORIES_RESOLVED',
    MLS_CATEGORIES_FAILED: 'MLS_CATEGORIES_FAILED',
    PRODUCTS_REQUESTED: 'PRODUCTS_REQUESTED',
    PRODUCTS_RESOLVED: 'PRODUCTS_RESOLVED',
    PRODUCTS_FAILED: 'PRODUCTS_FAILED',
    COMPANIES_REQUESTED: 'COMPANIES_REQUESTED',
    COMPANIES_RESOLVED: 'COMPANIES_RESOLVED',
    COMPANIES_FAILED: 'COMPANIES_FAILED',
    TITLE_COMPANIES_OPTIONS_REQUESTED: 'TITLE_COMPANIES_OPTIONS_REQUESTED',
    TITLE_COMPANIES_OPTIONS_RESOLVED: 'TITLE_COMPANIES_OPTIONS_RESOLVED',
    TITLE_COMPANIES_OPTIONS_FAILED: 'TITLE_COMPANIES_OPTIONS_FAILED',
    OFFICE_USERS_REQUESTED: 'OFFICE_USERS_REQUESTED',
    OFFICE_USERS_RESOLVED: 'OFFICE_USERS_RESOLVED',
    OFFICE_USERS_FAILED: 'OFFICE_USERS_FAILED',
    MLS_ABBREVIATIONS_REQUESTED: 'MLS_ABBREVIATIONS_REQUESTED',
    MLS_ABBREVIATIONS_RESOLVED: 'MLS_ABBREVIATIONS_RESOLVED',
    MLS_ABBREVIATIONS_FAILED: 'MLS_ABBREVIATIONS_FAILED',
    STATES_REQUESTED: 'STATES_REQUESTED',
    STATES_RESOLVED: 'STATES_RESOLVED',
    STATES_FAILED: 'STATES_FAILED',
    FORM_TYPES_REQUESTED: 'FORM_TYPES_REQUESTED',
    FORM_TYPES_RESOLVED: 'FORM_TYPES_RESOLVED',
    FORM_TYPES_FAILED: 'FORM_TYPES_FAILED',
    VOUCHER_TEMPLATES_REQUESTED: 'VOUCHER_TEMPLATES_REQUESTED',
    VOUCHER_TEMPLATES_RESOLVED: 'VOUCHER_TEMPLATES_RESOLVED',
    VOUCHER_TEMPLATES_FAILED: 'VOUCHER_TEMPLATES_FAILED',
    PRODUCT_TYPES_REQUESTED: 'PRODUCT_TYPES_REQUESTED',
    PRODUCT_TYPES_RESOLVED: 'PRODUCT_TYPES_RESOLVED',
    PRODUCT_TYPES_FAILED: 'PRODUCT_TYPES_FAILED',
    LISTING_STATUSES_REQUESTED: 'LISTING_STATUSES_REQUESTED',
    LISTING_STATUSES_RESOLVED: 'LISTING_STATUSES_RESOLVED',
    LISTING_STATUSES_FAILED: 'LISTING_STATUSES_FAILED',
    FORM_STATUSES_REQUESTED: 'FORM_STATUSES_REQUESTED',
    FORM_STATUSES_RESOLVED: 'FORM_STATUSES_RESOLVED',
    FORM_STATUSES_FAILED: 'FORM_STATUSES_FAILED',
    OFFICE_STATUSES_REQUESTED: 'OFFICE_STATUSES_REQUESTED',
    OFFICE_STATUSES_RESOLVED: 'OFFICE_STATUSES_RESOLVED',
    OFFICE_STATUSES_FAILED: 'OFFICE_STATUSES_FAILED',
    CASH_OFFER_BATCH_REQUESTED: 'CASH_OFFER_BATCH_REQUESTED',
    CASH_OFFER_BATCH_RESOLVED: 'CASH_OFFER_BATCH_RESOLVED',
    CASH_OFFER_BATCH_FAILED: 'CASH_OFFER_BATCH_FAILED',
};

export const lookupActionsCreators = { 
    getPropertyTypes: () => async (dispatch) => {
        dispatch({ type: LookupActions.PROPERTY_TYPES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getPropertyTypes();
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.PROPERTY_TYPES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.PROPERTY_TYPES_FAILED,
                },
            });
        }
    },

    getMlsCategories: () => async (dispatch) => {
        dispatch({ type: LookupActions.MLS_CATEGORIES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
        const { data } = await lookupService.getMlsCategories();
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: LookupActions.MLS_CATEGORIES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.MLS_CATEGORIES_FAILED,
                },
            });
        }
    },

    getProducts: (productType) => async (dispatch) => {
        dispatch({ type: LookupActions.PRODUCTS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
        const { data } = await lookupService.getProducts(productType);
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: LookupActions.PRODUCTS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.PRODUCTS_FAILED,
                },
            });
        }
    },

    getProductTypes: () => async (dispatch) => {
        dispatch({ type: LookupActions.PRODUCT_TYPES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getProductTypes();
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.PRODUCT_TYPES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.PRODUCT_TYPES_FAILED,
                },
            });
        }
    },

    getCompanies: () => async (dispatch) => {
        dispatch({ type: LookupActions.COMPANIES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
        const { data } = await lookupService.getCompanies();
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: LookupActions.COMPANIES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.COMPANIES_FAILED,
                },
            });
        }
    },

    getTitleCompanies: (state = null) => async (dispatch) => {
        dispatch({ type: LookupActions.TITLE_COMPANIES_OPTIONS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
        const { data } = await lookupService.getTitleCompanies(state);
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: LookupActions.TITLE_COMPANIES_OPTIONS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.TITLE_COMPANIES_OPTIONS_FAILED,
                },
            });
        }
    },

    getOfficeUsers: () => async (dispatch) => {
        dispatch({ type: LookupActions.OFFICE_USERS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
        let { data } = await lookupService.getOfficeUsers();
        data = data.map(({ id, name}) => ({ label: name, value: id }));
        dispatch(endLoading('MAIN_LOADER', 'Loading'));
        dispatch({ type: LookupActions.OFFICE_USERS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.OFFICE_USERS_FAILED,
                },
            });
        }
    },
    getMlsAbbreviations: (state = null) => async (dispatch) => {
        dispatch({ type: LookupActions.MLS_ABBREVIATIONS_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getMlsAbbreviations(removeNullUndefined({state}));

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.MLS_ABBREVIATIONS_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.MLS_ABBREVIATIONS_FAILED,
                },
            });
        }
    },

    getStates: () => async (dispatch) => {
        dispatch({ type: LookupActions.STATES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getStates();

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.STATES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.STATES_FAILED,
                },
            });
        }
    },

    getFormTypes: () => async (dispatch) => {
        dispatch({ type: LookupActions.FORM_TYPES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getFormTypes();

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.FORM_TYPES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.FORM_TYPES_FAILED,
                },
            });
        }
    },

    getListingStatuses: () => async (dispatch) => {
        dispatch({ type: LookupActions.LISTING_STATUSES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getListingStatuses();

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.LISTING_STATUSES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.LISTING_STATUSES_FAILED,
                },
            });
        }
    },

    getFormStatuses: () => async (dispatch) => {
        dispatch({ type: LookupActions.FORM_STATUSES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getFormStatuses();

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.FORM_STATUSES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.FORM_STATUSES_FAILED,
                },
            });
        }
    },

    getOfficeStatuses: () => async (dispatch) => {
        dispatch({ type: LookupActions.OFFICE_STATUSES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getOfficeStatuses();

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.OFFICE_STATUSES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.OFFICE_STATUSES_FAILED,
                },
            });
        }
    },

    getVoucherTemplates: (userId) => async (dispatch) => {
        dispatch({ type: LookupActions.VOUCHER_TEMPLATES_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getVoucherTemplates(userId);

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.VOUCHER_TEMPLATES_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.VOUCHER_TEMPLATES_FAILED,
                },
            });
        }
    },
    getOfferCashBatch: () => async (dispatch) => {
        dispatch({ type: LookupActions.CASH_OFFER_BATCH_REQUESTED });
        dispatch(startLoading('MAIN_LOADER', 'Loading'));
        try {
            const { data } = await lookupService.getCashOfferBatch();

            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            dispatch({ type: LookupActions.CASH_OFFER_BATCH_RESOLVED, payload: data });
        } catch (error) {
            LogRocket.captureException(error, {
                tags: {
                    errorType: 'failed_lookup_action',
                },
                extra: {
                    type: LookupActions.CASH_OFFER_BATCH_FAILED,
                },
            });
        }
    }
};
