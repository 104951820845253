import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { HomeSellingWrapper, Container } from '../../../../../Assets/Style/commonStyleComponents';
import HomeSellingGuide from './HomeSellingGuide';
import HomeReady from './HomeReady.js';
import ReadyToSell from './ReadyToSell';
import MarketingYourHome from './MarketingYourHome';
import ContractsClosing from './ContractsClosing';
import HomeSellingBook from './HomeSellingBook';
import routes from "../../../../../Constants/Routes";
import Navbar from '../../../Components/Navbar';
import Footer from '../../../Components/Footer';

class HomeSellingMain extends React.Component {

  //For active class on list
  listActive = (e) => {
    var ele = document.querySelector(".active");
    if (ele !== null) {
      ele.classList.remove("active");
    }
    e.target.className = "active";
  }

  render() {
    return (
      <>
        <Navbar />
        <HomeSellingWrapper>
          <Container>            
            <div className="home-selling-row">
              <div className="home-selling-left">
                <ul className="home-selling-list" onClick={(e) => this.listActive(e)}>
                <li><Link to={routes.HOME_SELLING_GUIDE} className="active">Introduction</Link></li>
                  <li><Link to={routes.GETTING_HOME_READY} >Getting Your Home Ready</Link></li>
                  <li><Link to={routes.READY_TO_SELL}>The Ready-to-Sell Process</Link></li>
                  <li><Link to={routes.MARKETING_YOUR_HOME}>Marketing Your Home</Link></li>
                  <li><Link to={routes.CONTRACTS_CLOSING}>Contracts & Closing</Link></li>
                  <li><Link to={routes.HOME_SELLING_BOOK}>Home Selling Book</Link></li>
                  {/* <li><Link to={routes.EBOOK} target="_blank">Free E-Book Download</Link></li> */}
                </ul>
              </div>
              <div className="home-selling-right">
                <div className="home-selling-content">
                  <Switch>
                    <Route path={routes.HOME_SELLING_GUIDE} exact component={HomeSellingGuide} />
                    <Route path={routes.GETTING_HOME_READY} exact component={HomeReady} />
                    <Route path={routes.READY_TO_SELL} exact component={ReadyToSell} />
                    <Route path={routes.MARKETING_YOUR_HOME} exact component={MarketingYourHome} />
                    <Route path={routes.CONTRACTS_CLOSING} exact component={ContractsClosing} />
                    <Route path={routes.HOME_SELLING_BOOK} exact component={HomeSellingBook} />
                  </Switch>
                </div>
              </div>
            </div>
          </Container>
        </HomeSellingWrapper>
        <Footer />
      </>
    )
  }
}

export default HomeSellingMain;
