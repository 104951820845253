
export function loader(state = {}, action = {}) {
    switch (action.type) {
        case 'START_LOADING':
            return {
                ...state,
                [action.id]: {
                    isLoading: true,
                    text: action.text,
                },
            };

        case 'END_LOADING':
            return {
                ...state,
                [action.id]: {
                    isLoading: false,
                },
            };

        default:
            return state;
    }
};

export const averagePriceLoadingSelector = state => state?.loader?.AVERAGE_PRICE_LOADING?.isLoading;
