import React from 'react';
import { useDispatch } from 'react-redux';
import { InputFlex, DivWrapper } from '../../../../../Assets/Style/commonStyleComponents';
import Table from 'react-bootstrap/Table';
import { map } from 'lodash';
import { startLoading, endLoading } from '../../../../../Actions/LoaderActions';
import { titleCompanyService } from '../../../../../Services/V2/TitleCompanyService';
import LogRocket from 'logrocket';

const TitleCompanyRelationship = ({ formik, companiesOptions, statesOptions }) => {
    const dispatch = useDispatch();

    const handleAddRow = () => {
        formik.setFieldValue('titleCompanyRelationships', [...formik.values.titleCompanyRelationships, {}]);
    };

    const handleRemoveRow = (index) => {
        let arr = [...formik.values.titleCompanyRelationships];
        arr.splice(index, 1);
        formik.setFieldValue('titleCompanyRelationships', arr);
    };

    const hendleFieldValueChanged = (event, index) => {
        let arr = [...formik.values.titleCompanyRelationships];

        if(arr[index][event.target.name] == event.target.value){
            return;
        }

        arr[index][event.target.name] = (event.target.value || null);

        if(!arr[index].stateCode){
            arr[index].error = 'State requred';
            formik.setFieldValue('titleCompanyRelationships', arr);
            return;
        }

        checkCoverage(arr[index], formik.values.id).then(result => {
            if(!result?.data?.isValid){
                arr[index].error = `This state with this company already covered by ${result.data.titleCompanyName}`;
            }else{
                arr[index].error = '';
            }

            formik.setFieldValue('titleCompanyRelationships', arr);
        });
    }

    const checkCoverage = (coverage, titleCompanyId) => {
        dispatch(startLoading('MAIN_LOADER', 'Loading'));

        const model = {
            companyId: coverage.companyId || null,
            stateCode: coverage.stateCode,
            titleCompanyId: titleCompanyId,
        };

        return titleCompanyService.checkCoverage(model).then((result) => {
            dispatch(endLoading('MAIN_LOADER', 'Loading'));
            return result;
        })
            .catch((error) => {
                dispatch(endLoading('MAIN_LOADER', 'Loading'));
                LogRocket.captureException(error, {
                    tags: {
                        errorType: 'failed_title_company_action',
                    },
                    extra: {
                        type: 'get_title_companies',
                    },
                });
            });
    }

    return (<>
        <DivWrapper>
            <div className="basic-form-type">
                <Table bordered>
                    <thead>
                        <tr>
                            <th></th>
                            <th>State</th>
                            <th>Partner Company<span></span></th>
                            <th>
                                <span className="basic-form-add-field" onClick={() => handleAddRow()}><i className="fa fa-plus" aria-hidden="true"></i></span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(formik.values.titleCompanyRelationships, (item, index) => item && (
                            <tr id="addr0" key={index} >
                                <td style={{height: '85px'}}>{index + 1}</td>
                                <td className="hide-label">
                                    <InputFlex
                                        options={statesOptions}
                                        name='stateCode'
                                        className='inputgap'
                                        value={item.stateCode}
                                        isKeyPaired={true}
                                        onChange={(event) => hendleFieldValueChanged(event, index)}
                                        onBlur={(event) => hendleFieldValueChanged(event, index)}
                                        placeholder='Select State'
                                        error={item.error}
                                    />
                                </td>
                                <td className="hide-label" >
                                    <InputFlex
                                        options={companiesOptions}
                                        name='companyId'
                                        className='inputgap'
                                        value={item.companyId || undefined}
                                        isKeyPaired={true}
                                        onChange={(event) => hendleFieldValueChanged(event, index)}
                                        onBlur={(event) => hendleFieldValueChanged(event, index)}
                                        placeholder='Default'
                                    />
                                </td>
                                <td>
                                    <div className="add-delete-row">
                                        <span onClick={() => handleAddRow()}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                        <span onClick={() => handleRemoveRow(index)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </DivWrapper>
    </>);
}

export default TitleCompanyRelationship;