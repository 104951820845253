import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import v8n from 'v8n';
import { regexPattern, validationMessages } from '../../../Constants/CommonConstants';
import routes from '../../../Constants/Routes';
import { userActionCreators } from '../../../Actions/UserActions';
import { CrossedEyeIcon } from '../Components/Icons/CrossedEyeIcon';
import { EyeIcon } from '../Components/Icons/EyeIcon';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { SocialNetworksAuthButtons } from '../Components/SocialNetworksAuthButtons';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import { history } from '../../../Utilities/History';
import { getLoginRedirectPath } from '../../../Utilities/commonFunctions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: rgba(0, 0, 0, 0.48);
`;

const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 20rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius:  0.375rem;
  background: white;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  z-index: 1400;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 28rem;
  align-self: center;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    width: 28rem;
  }
}
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-inline: auto;
`;

const FooterContent = styled.div`
  font-size: 0.75rem;
  a {
    display: inline-flex;
    appearance: none;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: baseline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: auto;
    line-height: normal;
    border-radius: 0.375rem;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    height: auto;
    min-width: 2.5rem;
    font-size: 0.75rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding: 0px;
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.colors.green[900]};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 1 0%;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;;
  font-weight: 700;
font-family: 'National-Semi-Bold';
  p {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    &.invalid {
      color: #F56565;
  }
  }
  button {
    display: inline-flex;
    appearance: none;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: baseline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: min-content;
    line-height: normal;
    border-radius: 0.375rem;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    height: auto;
    min-width: 2.5rem;
    font-size: 14px;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding: 0px;
    color: #718096;
    background-color: #ffffffff;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.colors.green[900]};
    font-weight: 700;
    font-family: 'National-Semi-Bold';
  }
}
`;

const FormContainer = styled.span`
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex: 1 1 0%;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }
`;

const EyeButtonWraper = styled.div`
  right: 0px;
  width: 4rem;
  height: 2.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  z-index: 2;
`;

const EyeButton = styled.button`
  display: inline;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: inherit;
  border-radius: 0.375rem;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  height: 1.75rem;
  min-width: 2rem;
  font-size: 0.875rem;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  background: none;
  color: #000000;
  margin: 0px;
  padding: 0px;
`;

const PasswordShowButton = styled(EyeIcon)`
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: black;
  vertical-align: middle;
`;

const PasswordHideButton = styled(CrossedEyeIcon)`
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: black;
  vertical-align: middle;
`;

const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 0.5rem;
  margin-right: 1rem;
  a {
    cursor: pointer;
    text-decoration: none;
    outline: transparent solid 2px;
    outline-offset: 2px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    font-size: 12px;
    color: ${({ theme }) => theme.colors.green[900]};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const LoginButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 100%;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 2.5rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 1rem;
`;

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[500]};
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) =>
      smallLabel
        ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
        : ''}
  }
`;

const SignInPage = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userDetailsSelector);
  const [isPasswordHidden, setIsPassworHidden] = useState(true);
  const [loginFormFields, setLoginFormFields] = useState({
    email: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: validationMessages.email,
        },
        {
          rule: v8n().pattern(regexPattern.EMAIL),
          message: validationMessages.validEmail,
        },
      ],
      value: '',
      error: null,
    },
    password: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: validationMessages.password,
        },
      ],
      value: '',
      error: null,
    },
  });

  useEffect(() => {
    if (userData?.token) {
      const redirectPath = getLoginRedirectPath(userData.userTypeId);
      history.push({ pathname: redirectPath });
    }
  }, []);

  const handleLogin = (e) => {
    let isError = false;

    Object.keys(loginFormFields).forEach((k) => {
      const isErrorExist = validateLoginField(k, loginFormFields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    });

    if (!isError) {
      dispatch(userActionCreators.login(loginFormFields.email.value, loginFormFields.password.value));
    }
  };

  const handleFieldChanged = (e) => {
    e.persist();
    setLoginFormFields((pr) => ({ ...pr, [e.target.name]: { ...pr[e.target.name], value: e.target.value } }));
  };

  const validateLoginField = (key, value) => {
    let fields = { ...loginFormFields };
    let isError = false;
    for (let i = 0; i < fields[key].rules.length; i++) {
      let rule = fields[key].rules[i];

      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setLoginFormFields((pr) => ({ ...pr, ...fields }));

    return isError;
  };


  return (
    <Container>
      <Navbar hideMenuButton={true} />
      <ModalContainer>
        <HeaderContainer>
          <p>Sign in</p>
          <button onClick={() =>  history.push({ pathname: routes.SIGN_UP })}>
            Need an account?
            <span>Join</span>
          </button>
        </HeaderContainer>
        <SocialNetworksAuthButtons />
        <FormContainer>
            <InputContainer className={loginFormFields.email.error ? 'invalid' : ''}>
              <input
                placeholder=" "
                type="email"
                autoComplete="email"
                name="email"
                id="email"
                value={loginFormFields.email.value}
                onChange={handleFieldChanged}
              />
              <InputLabel smallLabel={loginFormFields.email.value} htmlFor="email">
                Email address *
              </InputLabel>
              {loginFormFields.email.error && <ErrorMessage>{loginFormFields.email.error}</ErrorMessage>}
            </InputContainer>
            <InputContainer className={loginFormFields.password.error ? 'invalid' : ''}>
              <div>
                <input
                  placeholder=" "
                  type={isPasswordHidden ? 'password' : 'text'}
                  autoComplete="password"
                  name="password"
                  id="password"
                  value={loginFormFields.password.value}
                  onChange={handleFieldChanged}
                />
                <InputLabel smallLabel={loginFormFields.password.value} htmlFor="password">
                  Password *
                </InputLabel>
                <EyeButtonWraper>
                  <EyeButton
                    type="button"
                    onClick={() => {
                      setIsPassworHidden((pr) => !pr);
                    }}
                  >
                    {isPasswordHidden ? <PasswordShowButton /> : <PasswordHideButton />}
                  </EyeButton>
                </EyeButtonWraper>
              </div>
              {loginFormFields.password.error && <ErrorMessage>{loginFormFields.password.error}</ErrorMessage>}
            </InputContainer>
            <ForgotPasswordContainer>
              <a href={routes.FORGOT_PASSWORD}>Forgot my password</a>
            </ForgotPasswordContainer>
            <LoginButton onClick={handleLogin} type="button">Sign in</LoginButton>
        </FormContainer>
        <FormFooter>
          <FooterContent>
            By joining you agree to Unreal Estate’s
            <a target="_self" href={routes.TERMS_AND_CONDITIONS}>
              Terms
            </a>{' '}
            and
            <a target="_self" href={routes.PIVACY_POLICY}>
              Privacy.
            </a>
          </FooterContent>
        </FormFooter>
      </ModalContainer>
      <Footer />
    </Container>
  );
};

export default withTheme(SignInPage);
