import React from 'react';
import { connect } from 'react-redux';
import { roleType } from '../../../Constants/CommonConstants';
import Routes from '../../../Constants/Routes';
import { history } from '../../../Utilities/History';
import Footer from '../../Shared/Components/Footer';
import Navbar from '../../Shared/Components/Navbar';
import ProfileView from '../Components/ProfileView';

class Profile extends React.Component {
  render() {
    const { userDetails, userErrors } = this.props;
    const pathToRedirect = (userDetails?.role === roleType.ADMIN || userDetails?.role === roleType.LISTING_PROCESSOR) ?
      Routes.ADMIN_MLS_SUBMISSION :
      Routes.DASHBOARD;
    return (
      <React.Fragment>
        <Navbar />
          <div className="profile-back-container">
            <a className="back-btn" href="javascript:void(0)" onClick={() => history.push(pathToRedirect)}>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              Back To Dashboard
            </a>
          </div>
          <ProfileView
            userDetails={userDetails}
            errors={userErrors}
          />
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { userDetails, errors } = state.user;
  return {
    userDetails: userDetails.isAdminSwitchUser ? userDetails.switchedUser : userDetails,
    userErrors: errors || {},
  };
}

export default connect(mapStateToProps)(Profile);
