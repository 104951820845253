export let startLoading = (id, text) => {
    return {
        type: 'START_LOADING',
        id,
        text,
    };
    };

export let endLoading = (id) => {
    return {
        type: 'END_LOADING',
        id,
    };
};

export const loaderAction = {
    startLoading,
    endLoading,
};
