import { actions } from "../Actions/DashboardActions";
import { ownerTypes, ownershipTypes } from "../Constants/CommonConstants";
import { Entity } from "../Constants/EntityTypes";
import { handleFormData } from "../Utilities/commonFunctions";
import { getPropertyOwnerFields } from "../Utilities/utils";


const initialState = {
    isFetching: false,
    listings: [],
    listing: {},
    photoProgress: [],
    lastUpdatedFieldId: 0,
    isError: false,
    activeEntity: Entity.Types.VOUCHER,
    activeSet: [],
    entities: {
        voucher: [],
    },
    draftListingDeletingId: null,
    listingListfilters: {
        status: null,
        search: null,
    },
    filtredListings: [],
    listingViewType: 'list'
};


export const dashboardReducer = (state = initialState, action = {}) => {
    state = state || initialState;

    let photos, newPhoto, photoProgress, tmpState;

    switch (action.type) {
        case actions.LISTINGS_REQUESTED:
            return { ...state, isFetching: true };

        case actions.LISTINGS_RESOLVED:
        case actions.LISTINGS_FAILED:
            return {
                ...state,
                listings: action.payload,
                filtredListings: action.payload,
                isFetching: false
            };
        case actions.LISTING_REQUESTED:
        case actions.FORM_LISTING_REQUESTED:
        case actions.PUBLIC_VIEW_LISTING_REQUESTED:
        case actions.DELETE_PHOTO_REQUESTED:
            return { ...state, isFetching: true };

        case actions.LISTING_RESOLVED:
            if (action.isError) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false
                };
            }
            return { ...state, listing: action.payload.listing, product: action.payload.product, order: action.payload.order, isFetching: false };
            
        case actions.SAVE_FORM_FIELD_REQUESTED:
        case actions.SAVE_PREVIEW_FORM_FIELD_REQUESTED:
            return { ...state, isFetching: true };

        case actions.SAVE_FORM_FEILD_RESOLVED:

            return { ...state, isFetching: false, lastUpdatedFieldId: action.payload, isError: action.isError };

        case actions.UPLOAD_LISTING_PHOTO_REQUESTED:
            newPhoto = action.payload.photo;
            photos = state.listing.photos.slice();

            photos.splice(newPhoto.order, 0, newPhoto);

            photoProgress = state.photoProgress.slice();
            photoProgress[newPhoto.order] = .1;

            state.listing.photos = photos;

            return { ...state, photos: photos, photoProgress: photoProgress, isFetching: true };

        case actions.UPLOAD_LISTING_PHOTO_RESOLVED:

            photoProgress = state.photoProgress.slice();

            if (!action.isError) {
                newPhoto = action.payload.photo;
                photos = state.listing.photos.slice();

                photos[newPhoto.order] = newPhoto;
                photoProgress[newPhoto.order] = 1;

                state.listing.photos = photos;
            }

            return { ...state, isFetching: false, photos: photos, photoProgress: photoProgress, isError: action.isError };

        case actions.PHOTO_UPLOAD_PROGRESS_UPDATED:

            photoProgress = state.photoProgress.slice();
            photoProgress[action.payload.order] = action.payload.progress;

            return { ...state, photoProgress: photoProgress, isError: action.isError };

        case actions.REORDER_PHOTOS_REQUESTED:

            photos = state.listing.photos.slice();

            photos = photos.filter((item, index) => index !== action.payload.oldIndex);

            photos = [
                ...photos.slice(0, action.payload.newIndex),
                state.listing.photos[action.payload.oldIndex],
                ...photos.slice(action.payload.newIndex)
            ];
            state.listing.photos = photos;
            return { ...state, isFetching: true , photos: photos };
        case actions.REORDER_PHOTOS_RESOLVED:
        case actions.SAVE_PREVIEW_FORM_FIELD_RESOLVED:

            return { ...state, isFetching: false, isError: action.isError };

        case actions.HANDLE_FIELD_CHANGE:
            let updatedListing = state.listing;
            // Decide if updating root object, or form field
            if (action?.payload?.fieldName) {
                updatedListing[action.payload.fieldName] = action.payload.value;
            } else {
                updatedListing.forms = updatedListing.forms.map((form) => {
                    if (form.formId !== action.payload.formId) {
                        return form;
                    }
                    form.groups = form.groups.map((group) => {
                        group.sections = group.sections.map((section) => {

                            section.fields = section.fields.map((field) => {
                                if (field.formFieldId !== Number(action.payload.formFieldId)) {
                                    return field;
                                }

                                field.value = action.payload.value;

                                return { ...field };
                            });

                            return { ...section };
                        });

                        return { ...group };
                    });

                    return { ...form };
                });
            }
            return { ...state, listing: updatedListing };

        case actions.HANDLE_IMAGE_FIELD_CHANGE:
            let updatedFormData = handleFormData(state.formData, action);
            return { ...state, formData: updatedFormData };

        case actions.DELETE_PHOTO_RESOLVED:

            // If error, return errors
            const listingPhotos = state.listing.photos;
            const filteredListings = listingPhotos.map((photo) => {
                if (Number(photo.listingPhotoId) !== action.payload.value) {
                    return photo;
                }
                return null
            }).filter(x => x);

            if (!action.payload?.success) {
                return {
                    ...state,
                    errors: { ...action.payload },
                };
            }
            return { ...state, listing: { ...state.listing, photos: filteredListings }, errors: {}, isFetching: false };

        case actions.PUBLIC_VIEW_LISTING_RESOLVED:
            let listingData = {};
            if (!action.payload?.success) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false
                };
            } else {
                listingData = action.payload.value.listing;
            }
            return { ...state, listing: listingData, isFetching: false };

        case actions.FORM_LISTING_RESOLVED:
            let formListingData = {};
            if (!action.payload?.success) {
                return {
                    ...state,
                    errors: { ...action.payload },
                    isFetching: false,isListingFetching:false
                };
            } else {
                formListingData = action.payload.value[0];
            }
            return { ...state, formData: formListingData, isFetching: false,isListingFetching:false };
        
        case actions.NETWORKUSER_LISTING_REQUESTED:
        case actions.COVERAGE_LISTING_REQUESTED:
        case actions.VOUCHER_LISTING_REQUESTED:
            state.activeEntity = action.payload;
            state.activeSet = state.entities[action.payload];
            return { ...state, activeEntity: action.payload, isListingFetching: true };

        case actions.REMOVE_PHOTO_RESOLVED:
            state.listing.photos.splice(action.payload.index, 1);
            return { ...state, isError: action.isError };    
        
        case actions.NETWORKUSER_LISTING_RESOLVED:    
        case actions.COVERAGE_LISTING_RESOLVED:
        case actions.VOUCHER_LISTING_RESOLVED:

            if (action.isError)
                return { ...state, errors: { ...action.payload }, isListingFetching: false };

            tmpState = action.payload.value;
            state.entities[action.payload.entity] = tmpState.results.slice();
            const totalSet = tmpState.total;
            return {
                ...state,
                activeSet: tmpState.results,
                totalSet,
                isListingFetching: false,
            };

        case actions.DELETE_DRAFT_LISTINGS_REQUESTED:
            return { ...state, draftListingDeletingId: action.payload };    
            
        case actions.DELETE_DRAFT_LISTINGS_RESOLVED:
            return {
                ...state,
                draftListingDeletingId: null,
                listings: action.payload.listings,
                filtredListings: action.payload.filtredListings
            };
            
        case actions.DELETE_DRAFT_LISTINGS_FAILED:
            return { ...state, draftListingDeletingId: null };

        case actions.SET_LISTING_LIST_VIEW_TYPE:
        return { ...state, listingViewType: action.payload };

        case actions.SET_LISTING_LIST_FILTERS:
        return {
            ...state,
            listingListfilters: {
                status: action.payload.statusFilters,
                search: action.payload.listingListSearch,
            },
            filtredListings: action.payload.filtredListings,
        };
        case actions.SET_PROPERTY_OWNERS_FIELDS:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    ownershipType: {
                        ...state.listing.ownershipType,
                        ownersData: action.payload,
                    }
                }
            };

            case actions.SET_PROPERTY_HELD:
                return {
                    ...state,
                    listing: {
                    ...state.listing,
                    ownershipType: {
                        ...state.listing.ownershipType,
                        type: action.payload,
                    }
                }
                };

            case actions.SET_TITLE_COMPANY:
                return {
                    ...state,
                    listing: {
                        ...state.listing,
                        titleCompanyId: action.payload.titleCompanyId,
                        titleCompanyName: action.payload.titleCompanyName,
                        titleCompanyEmail: action.payload.titleCompanyEmail,
                    }
                };
            case actions.UPDATE_LISING_CASH_OFFER_STATUS:
                    return { ...state, listings: action.payload };
            
    default: return state;

    }
};

export const listingsSelector = state => state.dashboard.listings;
export const filtredListingsSelector = state => state.dashboard.filtredListings;
export const draftListingDeletingIdSelector = state => state.dashboard.draftListingDeletingId;
export const listingViewTypeSelector = state => state.dashboard.listingViewType;
export const isFetchingSelector = state =>  state.dashboard.isFetching;
export const propertyHeldSelector = state => state?.dashboard.listing?.ownershipType?.type || ownershipTypes.oneOwner;
export const propertyOwnersSelector = state => state.dashboard.listing?.ownershipType?.ownersData || [getPropertyOwnerFields(ownerTypes.individual)];
export const titleCompanyIdSelector = state => state.dashboard.listing?.titleCompanyId || null;
export const titleCompanyNameSelector = state => state.dashboard.listing?.titleCompanyName || null;
export const titleCompanyEmailSelector = state => state.dashboard.listing?.titleCompanyEmail || null;
export const stateSelector = state => state.dashboard.listing.state;

