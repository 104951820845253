export default {
    FormTypes: {
        MLS_LISTING_FORM: 1,
        MLS_CHANGE_FORM: 2,
        MLS_CANCELLATION_FORM: 3,
        PROPERTY_DISCLOSURE_FORM: 4,
        DIRECT_SOLICITING_ADDENDUM: 5,
        MLS_INPUT_SHEET: 6
    },
    OptionsTypes: {
        Single: 1,
        Multiple: 2
    },
    InputTypes: {
        TEXT_SHORT: 1,
        TEXT_LONG: 2,
        WHOLE_NUMBER: 3,
        DECIMAL: 4,
        BOOLEAN: 5,
        PERCENT: 6,
        CURRENCY: 7,
        DATE_TIME: 8,
        IMAGE: 9,
        PDF: 10,
        CUSTOMER_NAME: 11,
        EMAIL: 12,
        DROPDOWN: 13,
        ON_OFF: 14,
    },
    NUMERIC_VALUE: {
        ZERO: 0,
        ONE: 1,
        TWO: 2,
        TWO_POINT_FIVE: 2.5,
        THREE: 3,
        FOUR: 4,
        FIVE: 5,
        SIX: 6,
        SEVEN: 7,
        EIGHT: 8,
        NINE: 9,
        TEN: 10,
        ELEVEN: 11,
        TWELVE: 12,
        THIRTEEN:13,
        FOURTEEN: 14,
        FIFTEEN: 15,
        SIXTEEN: 16,
        SEVENTEEN: 17,
        NINETEEN: 19,
        TWENTY: 20,
        TWENTY_ONE: 21,
        TWENTY_TWO: 22,
        TWENTY_THREE: 23,
        TWENTY_FOUR: 24,
        TWENTY_FIVE: 25,
        TWENTY_EIGHT: 28,
        THIRTY: 30,
        THIRTY_ONE: 31,
        THIRTY_TWO: 32,
        FORTY: 40,
        FIFTY: 50,
        EIGHTY: 80,
        TWOHUNDRED: 200,
        TWOHUNDREDTWENTY: 220,
        TWOHUNDREDSEVENTEEN:270,
        THOUSAND: 1000,
        FIVE_THOUSAND: 15000,
        THREE_TWO_ONE_ZERO:3210,
        FIVE_HUNDRED: 500,
    },
    FormGroupType: {
        Plaintext: 1,
        Html: 2,
        Image: 3,
        Pdf: 4
    }
}

