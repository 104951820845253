import React, { useEffect } from 'react';
import { Progress } from 'antd';
import styled from 'styled-components';

import Navbar from '../../Shared/Components/Navbar';
import { themes } from '../../../Constants/Themes';
import PropertyListingModals from '../Modals/PropertyListingModals';
import PriceAndComissions from './PriceAndComissions';
import PropertyBasics from './PropertyBasics';
import PropertyDetails from './PropertyDetails';
import { listingStepSelector } from '../../../Reducers/OnboardingReducerV2';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingActionCreatorsV2, onboardingActions } from '../../../Actions/OnboardingActionsV2';
import { history } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import { propertyListingTitles, staticMessages } from '../../../Constants/CommonConstants';
import { UIActionsCreators } from '../../../Actions/UIActions';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import PropertyPhotos from './PropertyPhotos';
import PropertyTitle from './PropertyTitle';
import PlanSelector from './PlanSelector';
import ContractsSign from './ContractsSign';
import PropertyBilling from './PropertyBilling';
import ListingAddress from './ListingAddress';
import { loginModalMessageTypes } from '../../../Reducers/UIReducer';
import { useTitle } from '../../../Utilities/Hooks';
import { userActionCreators } from '../../../Actions/UserActions';
import BuyerPreferences from './BuyerPreferences';

const ProgressBar = styled(Progress)`
  top: -10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;


const PropertyListing = () => {
  const step = useSelector(listingStepSelector);
  const userDetails = useSelector(userDetailsSelector);
  const isUserLoggedIn = userDetails?.token;
  const dispatch = useDispatch();
  const progressPercent = step * 14.2;
  
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const listingId = params.get('listing_id');
  const newUser = params.get('new_user');

  useTitle(propertyListingTitles[step]);

  useEffect(() => {
    if (newUser) {
      handleNewUser()
    }

    return () => dispatch(onboardingActionCreatorsV2.clearListingData())
  }, [])

  useEffect(() => {
    if (listingId && isUserLoggedIn) {
      dispatch(onboardingActionCreatorsV2.getDraftListingByIdV2(listingId))
    }

    if (!isUserLoggedIn && listingId) {
      dispatch(UIActionsCreators.openLoginModal({
        message: staticMessages.logInToSeeListing,
        fixed: false,
        loginRedirectUrl: Routes.ONBOARDING_V2,
      }));
    }
  }, [isUserLoggedIn])

  const handleNewUser = async () => {
    if (!isUserLoggedIn) {
      dispatch(UIActionsCreators.openLoginModal({
        message: staticMessages.emailConfirmed,
        fixed: false,
        loginRedirectUrl: Routes.ONBOARDING_V2,
        loginModalMessageType: loginModalMessageTypes.success,
      }));
      dispatch(userActionCreators.setLoginCallBackAction({ type: onboardingActions.SET_LISTING_STEP_V2, payload: 2 }));
      params.delete('new_user');
      history.replace({
        pathname: Routes.ONBOARDING_V2,
        search: params.toString(),
      });
    }
	}

  return (
    <Container>
      <Navbar />
      <ProgressBar
        type="line" 
        strokeWidth={3}
        strokeLinecap="square"
        strokeColor={themes.usRealty.lavender}
        percent={progressPercent}
        showInfo={false}
      />
      {step === 1 && <ListingAddress />}
      {step === 2 && <PriceAndComissions />}
      {step === 3 && <PropertyBasics />}
      {step === 4 && <PropertyDetails />}
      {step === 5 && <PropertyPhotos />}
      {step === 6 && <PropertyTitle />}
      {step === 7 && <BuyerPreferences/>}
      {step === 8 && <PlanSelector />}
      {step === 9 && <PropertyBilling />}
      {step === 10 && <ContractsSign/>}
      <PropertyListingModals />
    </Container>
  );

};

export default PropertyListing;