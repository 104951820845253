import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import v8n from 'v8n';
import MaskedInput from 'react-text-mask';

import { phoneMask, regexPattern, Roles, validationMessages } from '../../../Constants/CommonConstants';
import routes from "../../../Constants/Routes";
import { userActionCreators } from '../../../Actions/UserActions';
import { CrossedEyeIcon } from '../Components/Icons/CrossedEyeIcon';
import { EyeIcon } from '../Components/Icons/EyeIcon';
import { CheckMarkIcon } from '../Components/Icons/CheckMarkIcon';
import { SocialNetworksAuthButtons } from '../Components/SocialNetworksAuthButtons';
import { ImagesUrl } from '../../../Constants/Images';
import {
  emailConfirmationRedirectUrlSelector,
  fixedSelector,
  loginModalMessageTypes
} from '../../../Reducers/UIReducer';
import { convertPhone, getRedirectLink } from '../../../Utilities/commonFunctions';
import { gTag } from '../../../Utilities/utils';
import { redeemedVoucherSelector, unassignedCashOfferSelector } from '../../../Reducers/OnboardingReducerV2';
import { UIActionsCreators } from '../../../Actions/UIActions';
import { InfoButton } from '../Components/Icons/InfoButton';
import ReactTooltip from 'react-tooltip';

const ModalOverlay = styled.div`
opacity: 1;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);;
  z-index: 1400;
`;

const ContentContainer = styled.div`
display: flex;
width: 100vw;
height: 100vh;
position: fixed;
left: 0px;
top: 0px;
z-index: 1400;
-webkit-box-pack: center;
justify-content: center;
align-items: flex-start;
background: ${({ backgroundImage }) => backgroundImage ? `url(${ImagesUrl.LOGIN_BACKGROUND})` : "unset"};
overflow: auto;
`;

const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 20rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
  border-radius:  0.375rem;
  background: white;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  z-index: 1400;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  max-width: 28rem;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    width: 28rem;
  }
}
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-inline: auto;
`;

const FooterContent = styled.div`
  font-size: 0.75rem;
  a {
    display: inline-flex;
    appearance: none;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: baseline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: auto;
    line-height: normal;
    border-radius: 0.375rem;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    height: auto;
    min-width: 2.5rem;
    font-size: 0.75rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding: 0px;
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.colors.green[900]};
    &:hover {
      text-decoration: underline;
    }
  }
`;


const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 1 0%;
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.25rem;;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  p {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
  button {
    display: inline-flex;
    appearance: none;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: baseline;
    outline: transparent solid 2px;
    outline-offset: 2px;
    width: min-content;
    line-height: normal;
    border-radius: 0.375rem;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    height: auto;
    min-width: 2.5rem;
    font-size: 14px;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding: 0px;
    color: #718096;
    background-color: #ffffffff;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.colors.green[900]};
    font-weight: 700;
    font-family: 'National-Semi-Bold';
  }
}
`;

const FormContainer = styled.span`
  padding-inline-start: 1.5rem;
  padding-inline-end: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex: 1 1 0%;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    min-width: 0px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  &.invalid {
    input {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label {
    color: #F56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
}
`;

const EyeButtonWraper = styled.div`
    right: 0px;
    width: 4rem;
    height: 2.5rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3px;
    z-index: 2;
`;

const EyeButton = styled.button`
display: inline;
appearance: none;
align-items: center;
justify-content: center;
user-select: none;
position: relative;
white-space: nowrap;
vertical-align: middle;
outline: transparent solid 2px;
outline-offset: 2px;
width: auto;
line-height: inherit;
border-radius: 0.375rem;
font-weight: 700;
font-family: 'National-Semi-Bold';
height: 1.75rem;
min-width: 2rem;
font-size: 0.875rem;
padding-inline-start: 0.75rem;
padding-inline-end: 0.75rem;
background: none;
color: #000000;
margin: 0px;
padding: 0px;
`;

const PasswordShowButton = styled(EyeIcon)`
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: black;
    vertical-align: middle;
`;

const PasswordHideButton = styled(CrossedEyeIcon)`
    width: 1em;
    height: 1em;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: black;
    vertical-align: middle;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 100%;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 2.5rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 1rem;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) => smallLabel ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `: ''}
  }
`;

const LinkConfimationContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 0.75rem 1rem;
    align-items: center;
`;

const LinkConfimationText = styled.span`
    display: flex;
    flex-direction: row;
    font-size: 16px;
    line-height: 1.5rem
    text-align: left;
    margin-left: 1rem;
    `;

const InfoButtonContainer = styled.span`
      position: absolute;
      right: 20px;
      top: 15px;
    `;


const SignUpModal = ({ closeModal, switchModals, backgroundImage }) => {
  const dispatch = useDispatch();
  const fixed = useSelector(fixedSelector);
  const emailConfirmationRedirectUrl = useSelector(emailConfirmationRedirectUrlSelector);

  const voucher = useSelector(redeemedVoucherSelector);
  const unassignedCashOffer = useSelector(unassignedCashOfferSelector);
  const voucherEmail = voucher?.email;

  const params = new URLSearchParams(window.location.search);
  const externalCode = params.get('externalCode');

  const [isPasswordHidden, setIsPassworHidden] = useState(true);
  const [isPasswordConfirmationHidden, setIsPasswordConfirmationHidden] = useState(true);
  const [showEmailConfirmationText, setShowEmailConfirmationText] = useState(false);
  const [signUpFormFields, setSignUpFormFields] = useState({
    email: {
      value: voucherEmail || unassignedCashOffer?.email || "",
      error: null,
    },
    password: {
      value: "",
      error: null,
    },
    passwordConfirmation: {
      value: "",
      error: null,
    },
    firstName: {
      value: unassignedCashOffer?.firstName || "",
      error: null,
    },
    lastName: {
      value: unassignedCashOffer?.lastName || "",
      error: null,
    },
    phoneNumber: {
      mask: phoneMask,
      value: unassignedCashOffer?.phoneNumber || "",
      error: null,
    },
    externalCode: {
      value: externalCode || "",
      error: null,
    },
  });

  const formValidationRules = useMemo(() => ({
    email: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.email,
      },
      {
        rule: v8n().pattern(regexPattern.EMAIL),
        message: validationMessages.validEmail,
      }
    ],
    password: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.password,
      },
      {
        rule: v8n().pattern(regexPattern.PASSWORD),
        message: validationMessages.validPassword,
      }
    ],
    passwordConfirmation: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.pleaseConfitrmPassword,
      },
      {
        rule: v8n().equal(signUpFormFields.password.value),
        message: validationMessages.passwordNotMatch,
      }
    ],
    firstName: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.firstName,
      },
    ],
    lastName: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.lastName,
      },
    ],
    phoneNumber: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.phoneNumber,
      },
      {
        rule: v8n().pattern(regexPattern.PHONE),
        message: validationMessages.phoneNumber,
      },
    ],
    externalCode: [],
  }), [signUpFormFields.password.value])


  const handleSignUp = (e) => {
    e.preventDefault();
    let isError = false;

    Object.keys(signUpFormFields).forEach((k) => {
      const isErrorExist = validateLoginField(k, signUpFormFields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    });

    if (!isError) {
      const userData = {
        email: signUpFormFields.email.value,
        password: signUpFormFields.password.value,
        firstName: signUpFormFields.firstName.value,
        lastName: signUpFormFields.lastName.value,
        phoneNumber: convertPhone(signUpFormFields.phoneNumber.value),
        userTypeId: voucher ? Roles.WHOLESALE_CUSTOMER : Roles.RETAIL,
        companyId: null,
        emailConfirmationRedirectLink: emailConfirmationRedirectUrl || getRedirectLink(),
        externalCode: signUpFormFields.externalCode.value,
      };

      dispatch(userActionCreators.createUser(userData, () => {
        setShowEmailConfirmationText(true);
        dispatch(UIActionsCreators.setLoginModalMessage({
          message: 'User was registered successfully. Please sign in to continue.',
          loginModalMessageType: loginModalMessageTypes.success,
        }));
        switchModals();
        gTag({ 'event': 'join', 'method': 'email&password' });
      }))
    }
  }

  const handleFieldChanged = (e) => {
    e.persist();
    setSignUpFormFields(pr => ({ ...pr, [e.target.name]: { ...pr[e.target.name], value: e.target.value } }));
  }

  const handleCloseModal = () => {
    if (!fixed) {
      closeModal();
    }
  }

  const validateLoginField = (key, value) => {
    let fields = { ...signUpFormFields };
    let isError = false;
    for (let i = 0; i < formValidationRules[key].length; i++) {
      let rule = formValidationRules[key][i];
      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setSignUpFormFields(pr => ({ ...pr, ...fields }));

    return isError;
  }

  return (
    <ContentContainer backgroundImage={backgroundImage}>
      <ModalOverlay onClick={handleCloseModal} />
      <ModalContainer>
        <HeaderContainer>
          <p>Join Unreal Estate</p>
          <button onClick={switchModals}>
            Have an account?
            <span>
              Sign in
            </span>
          </button>
        </HeaderContainer>
        <SocialNetworksAuthButtons />
        <FormContainer>
          {showEmailConfirmationText && (
            <LinkConfimationContainer>
              <CheckMarkIcon />
              <LinkConfimationText>
                {voucher ? "User was registered successfully. Please sign in to continue." : "Confirmation link was sent to your email address."}
              </LinkConfimationText>
            </LinkConfimationContainer>
          )}
          <form onSubmit={handleSignUp}>
            <InputContainer className={signUpFormFields.email.error ? 'invalid' : ''}>
              <input
                placeholder=" "
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                value={voucherEmail || signUpFormFields.email.value}
                onChange={handleFieldChanged}
                disabled={voucherEmail || unassignedCashOffer?.email}
              />
              <InputLabel smallLabel={voucherEmail || signUpFormFields.email.value} htmlFor="email">Email address *</InputLabel>
              {signUpFormFields.email.error && (
                <ErrorMessage>
                  {signUpFormFields.email.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <InputContainer className={signUpFormFields.firstName.error ? 'invalid' : ''}>
              <input
                placeholder=" "
                type="text"
                name="firstName"
                id="firstName"
                value={signUpFormFields.firstName.value}
                onChange={handleFieldChanged}
                disabled={unassignedCashOffer?.firstName}
              />
              <InputLabel smallLabel={signUpFormFields.firstName.value} htmlFor="firstName">First name *</InputLabel>
              {signUpFormFields.firstName.error && (
                <ErrorMessage>
                  {signUpFormFields.firstName.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <InputContainer className={signUpFormFields.lastName.error ? 'invalid' : ''}>
              <input
                placeholder=" "
                type="text"
                name="lastName"
                id="lastName"
                value={signUpFormFields.lastName.value}
                onChange={handleFieldChanged}
                disabled={unassignedCashOffer?.lastName}
              />
              <InputLabel smallLabel={signUpFormFields.lastName.value} htmlFor="lastName">Last name *</InputLabel>
              {signUpFormFields.lastName.error && (
                <ErrorMessage>
                  {signUpFormFields.lastName.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <InputContainer className={signUpFormFields.phoneNumber.error ? 'invalid' : ''}>
              <MaskedInput
                mask={signUpFormFields.phoneNumber.mask}
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={signUpFormFields.phoneNumber.value}
                onChange={handleFieldChanged}
                autoComplete="off"
                disabled={unassignedCashOffer?.phoneNumber}
              />
              <InputLabel smallLabel={signUpFormFields.phoneNumber.value} htmlFor="lastName">
                Phone number *  example: (555) 123-1234 
              </InputLabel>
              <ReactTooltip className="tooltip" multiline={true} id={'phoneNumberDisclaimer'}>
                <span>
                  Your phone number won't be shared and
                  is only used to verify your account if you
                  want to change your email address or
                  forgot your password. Enter a mobile
                  number if you prefer to receive a text
                  message.
                </span>
              </ReactTooltip>
              <InfoButtonContainer  data-tip data-for={'phoneNumberDisclaimer'}>
                <InfoButton width={18} height={18} />
              </InfoButtonContainer>
              {signUpFormFields.phoneNumber.error && (
                <ErrorMessage>
                  {signUpFormFields.phoneNumber.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <InputContainer className={signUpFormFields.password.error ? 'invalid' : ''}>
              <div>
                <input
                  placeholder=" "
                  name="password"
                  id="password"
                  type={isPasswordHidden ? 'password' : 'text'}
                  value={signUpFormFields.password.value}
                  onChange={handleFieldChanged}
                />
                <InputLabel smallLabel={signUpFormFields.password.value} htmlFor="password">Password *</InputLabel>
                <EyeButtonWraper>
                  <EyeButton
                    type='button'
                    onClick={() => {
                      setIsPassworHidden(pr => !pr)
                    }}
                  >
                    {isPasswordHidden ? (
                      <PasswordShowButton />
                    ) : (
                      <PasswordHideButton />
                    )}
                  </EyeButton>
                </EyeButtonWraper>
              </div>
              {signUpFormFields.password.error && (
                <ErrorMessage>
                  {signUpFormFields.password.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <InputContainer className={signUpFormFields.passwordConfirmation.error ? 'invalid' : ''}>
              <div>
                <input
                  placeholder=" "
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  type={isPasswordConfirmationHidden ? 'password' : 'text'}
                  value={signUpFormFields.passwordConfirmation.value}
                  onChange={handleFieldChanged}
                />
                <InputLabel smallLabel={signUpFormFields.passwordConfirmation.value} htmlFor="passwordConfirmation">Confirm password *</InputLabel>
                <EyeButtonWraper>
                  <EyeButton
                    type='button'
                    onClick={() => {
                      setIsPasswordConfirmationHidden(pr => !pr)
                    }}
                  >
                    {isPasswordConfirmationHidden ? (
                      <PasswordShowButton />
                    ) : (
                      <PasswordHideButton />
                    )}
                  </EyeButton>
                </EyeButtonWraper>
              </div>
              {signUpFormFields.passwordConfirmation.error && (
                <ErrorMessage>
                  {signUpFormFields.passwordConfirmation.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <InputContainer>
              <div>
                <input
                  placeholder=""
                  name="externalCode"
                  id="externalCode"
                  type='text'
                  value={signUpFormFields.externalCode.value}
                  onChange={handleFieldChanged}
                  disabled={externalCode}
                />
                <InputLabel smallLabel={signUpFormFields.externalCode.value} htmlFor="externalCode">Referral code</InputLabel>
              </div>
            </InputContainer>
            <SubmitButton disabled={showEmailConfirmationText} type='submit'>
              Join Unreal Estate
            </SubmitButton>
          </form>
        </FormContainer>
        <FormFooter>
          <FooterContent>
            By joining you agree to Unreal Estate’s
            <a target="_self" href={routes.TERMS_AND_CONDITIONS}>
              Terms of Service
            </a>
            {" "}and
            <a target="_self" href={routes.PIVACY_POLICY}>
              Privacy Policy.
            </a>
          </FooterContent>
        </FormFooter>
      </ModalContainer>
    </ContentContainer>
  );
};

export default withTheme(SignUpModal);