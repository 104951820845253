import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import v8n from 'v8n';
import { RegionDropdown } from 'react-country-region-selector';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import {
  draftListingIdSelector,
  fetchingDraftListingSelector,
  isAddressCheckedSelector,
  selectedAddressSelector,
  averagePriceSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { averagePriceLoadingSelector } from '../../../Reducers/LoaderReducer';
import { UIActionsCreators } from '../../../Actions/UIActions';
import { setStateToUrl } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import ListingPreviewDesktopContainer from './ListingPreviewDesktopContainer';
import { EmbeddedYoutubeHintsPlayer } from './EmbeddedYoutubeHintsPlayer';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 10rem;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme, plain }) => (plain ? theme.light : theme.colors.violet[200])};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const InputContainer = styled.div`
  min-width: 20rem;
  position: relative;
  margin-bottom: 1rem;
  width: auto;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    border-color: #242b26;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  textarea {
    padding-top: 0.75rem;
    height: auto;
  }
  input:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  input:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }
  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 24rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 26rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 28rem;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const InputLabel = styled.label`
  opacity: 0.75;
  top: 0px;
  left: 0px;
  z-index: 2;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  margin-inline-start: 0.75rem;
  margin-inline-end: 0.75rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  transform-origin: left top;
  transition: scale 0.2s, translateY 0.2s, transform 0.2s;
  transition-timing-function: linear;
  display: block;
  text-align: start;
  font-weight: 500;

  ${({ smallLabel }) =>
    smallLabel
      ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
      : ''}

  ${({ select }) =>
    select
      ? `
    display: none;
    &:has(+ (select(select:focus)) {
    display: block;
    }
    `
      : ''}
`;

const ButtonsContaier = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const google = window.google;
const geocoder = new google.maps.Geocoder();

const ListingAddressForm = () => {
  const userDetails = useSelector(userDetailsSelector);
  const selectedAddress = useSelector(selectedAddressSelector);
  const draftListingId = useSelector(draftListingIdSelector);
  const isAddressChecked = useSelector(isAddressCheckedSelector);
  const fetchingDraftListing = useSelector(fetchingDraftListingSelector);

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const zip = params.get('zip');
  const address1 = params.get('address1');
  const address2 = params.get('address2');
  const city = params.get('city');
  const state = params.get('state');
  const county = params.get('county');
  const urlMarketId = params.get('market');
  const urlInactiveListingId = params.get('inactiveListingId');

  const manualEnteredAddress = zip || address1 || address2 || city || state || county;

  const dispatch = useDispatch();
  const [selectLabelVisible, setSelectLabelVisible] = useState(false);

  const [address, setAddress] = useState({
    address1: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter your street address',
        },
      ],
      value: '',
    },
    address2: {
      rules: [],
      value: '',
    },
    city: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter city name',
        },
      ],
      value: '',
    },
    county: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter county name',
        },
      ],
      value: '',
    },
    state: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select your state name',
        },
      ],
      value: '',
    },
    zip: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter your zip code',
        },
        {
          rule: v8n().minLength(5),
          message: 'Zipcode required minimum five character',
        },
      ],
      value: '',
    },
  });

  useEffect(() => {
    if (manualEnteredAddress) {
      setAddress((pr) => ({
        ...pr,
        address1: {
          ...pr.address1,
          value: address1,
        },
        address2: {
          ...pr.address2,
          value: address2,
        },
        city: {
          ...pr.city,
          value: city,
        },
        county: {
          ...pr.county,
          value: county,
        },
        state: {
          ...pr.state,
          value: state,
        },
        zip: {
          ...pr.zip,
          value: zip,
        },
      }));

      dispatch(
        onboardingActionCreatorsV2.selectAddressV2({
          address1: address1,
          address2: address2,
          city: city,
          county: county,
          state: state,
          zip: zip,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (urlMarketId && urlInactiveListingId) {
      dispatch(onboardingActionCreatorsV2.getInactiveListing(urlInactiveListingId, urlMarketId));
      dispatch(onboardingActionCreatorsV2.getAveragePriceV2(urlInactiveListingId, urlMarketId));
    }
  }, []);

  useEffect(() => {
    // prefill local useState address object with draftlisting address
    if (!manualEnteredAddress && !fetchingDraftListing) {
      setAddress((pr) => ({
        ...pr,
        address1: {
          ...pr.address1,
          value: selectedAddress?.address1 ?? '',
        },
        address2: {
          ...pr.address2,
          value: selectedAddress?.address2 ?? '',
        },
        city: {
          ...pr.city,
          value: selectedAddress?.city ?? '',
        },
        county: {
          ...pr.county,
          value: selectedAddress?.county ?? '',
        },
        state: {
          ...pr.state,
          value: selectedAddress?.state ?? '',
        },
        zip: {
          ...pr.zip,
          value: selectedAddress?.zip ?? '',
        },
      }));
    }
  }, []);

  const getPlaceBypostalCode = (postalCode) => {
    geocoder
      .geocode({ address: `${postalCode},US` })
      .then((response) => {
        if (!response?.results.length) {
          return;
        }
        const place = response?.results[0];
        const city =
          address.city.value ||
          (
            place.address_components.filter((c) => c.types.includes('locality'))?.[0] ||
            place.address_components.filter((c) => c.types.includes('sublocality'))?.[0] ||
            place.address_components.filter((c) => c.types.includes('neighborhood'))?.[0]
          )?.long_name ||
          '';

        const county =
          address.county.value ||
          place.address_components
            .filter((c) => c.types.includes('administrative_area_level_2'))?.[0]
            ?.long_name?.replace(/ County/g, '') ||
          '';

        const state =
          address.state.value ||
          place.address_components.filter((c) => c.types.includes('administrative_area_level_1'))?.[0]?.short_name ||
          '';

        setAddress((pr) => ({
          ...pr,
          city: {
            ...pr.city,
            value: city,
          },
          county: {
            ...pr.county,
            value: county,
          },
          state: {
            ...pr.state,
            value: state,
          },
        }));

        setStateToUrl(Routes.ONBOARDING_V2, {
          address1: address.address1.value,
          address2: address.address2.value,
          zip: postalCode,
          county,
          city,
          state,
        });

        state && validateField('city', city);
        county && validateField('county', county);
        city && validateField('state', state);
      })
      .catch((e) => { });
  };

  const handleAddressSelected = async () => {
    let isError = false;
    Object.keys(address).forEach((k) => (isError = validateField(k, address[k].value)));

    if (isError) {
      return;
    }

    if ((isAddressChecked || urlMarketId) && !userDetails.token) {
      dispatch(UIActionsCreators.openSignUpModal());
    } else {
      const location = {
        lat: null,
        lng: null,
      };

      try {
        const response = await geocoder.geocode({
          address: `${address.address1.value},${address.address2.value},${address.city.value},${address.state.value},${address.zip.value},US`,
        });

        if (response?.results.length) {
          const place = response.results[0];

          location.lat = place.geometry.location.lat();
          location.lng = place.geometry.location.lng();
        }
      } catch (ex) {
        console.log(ex);
      }

      dispatch(
        onboardingActionCreatorsV2.selectAddressV2({
          address1: address.address1.value,
          address2: address.address2.value,
          city: address.city.value,
          county: address.county.value,
          state: address.state.value,
          zip: address.zip.value,
          location: location,
        })
      );

      dispatch(onboardingActionCreatorsV2.checkAddressV2());
    }
  };

  const selectStateAndCounty = (value) => {
    setAddress((pr) => ({
      ...pr,
      state: {
        ...pr.state,
        value,
        error: '',
      },
    }));

    setSelectLabelVisible(false);

    dispatch(
      onboardingActionCreatorsV2.selectAddressV2({
        address1: address.address1.value,
        address2: address.address2.value,
        city: address.city.value,
        county: address.county.value,
        state: value,
        zip: address.zip.value,
      })
    );

    if (!draftListingId) {
      setStateToUrl(Routes.ONBOARDING_V2, {
        address1: address.address1.value,
        address2: address.address2.value,
        county: address.county.value,
        city: address.city.value,
        state: value,
        zip: address.zip.value,
      });
    }

    dispatch(onboardingActionCreatorsV2.resetIsAddressCheckedV2());
  };

  const handleFieldChanged = (e) => {
    const addressForm = { ...address };
    addressForm[e.target.name].value = e.target.value;
    setAddress((pr) => ({
      ...pr,
      ...addressForm,
    }));
  };

  const handleSelectFocus = () => {
    setSelectLabelVisible(true);
  };

  const handleSelectBlur = () => {
    setSelectLabelVisible(false);
  };

  const handleFieldBlur = (e) => {
    const { name, value } = e.target;
    if (value) {
      validateField(name, value);
    }

    dispatch(
      onboardingActionCreatorsV2.selectAddressV2({
        address1: address.address1.value,
        address2: address.address2.value,
        county: address.county.value,
        city: address.city.value,
        state: address.state.value,
        zip: address.zip.value,
      })
    );

    if (!draftListingId) {
      setStateToUrl(Routes.ONBOARDING_V2, {
        address1: address.address1.value,
        address2: address.address2.value,
        county: address.county.value,
        city: address.city.value,
        state: address.state.value,
        zip: address.zip.value,
      });
    }

    if (name === 'zip' && value.length > 4) {
      getPlaceBypostalCode(value);
    }

    dispatch(onboardingActionCreatorsV2.resetIsAddressCheckedV2());
  };

  const validateField = (key, value) => {
    let isError = null;

    for (let i = 0; i < address[key].rules.length; i++) {
      let rule = address[key].rules[i];

      if (!rule.rule.test(value)) {
        isError = rule.message;
        break;
      }
    }

    setAddress((pr) => ({
      ...pr,
      [key]: {
        ...pr[key],
        error: isError,
      },
    }));

    return isError;
  };

  return (
    <Container>
      <FormContainer>
        <TitleContainer>
          <SubTilteText>List your property</SubTilteText>
          <StepsText>1/9</StepsText>
        </TitleContainer>
        <TitleText>Property Address</TitleText>
        <Text>
          Enter your address below to get started. Reach millions of buyers, with free, nationwide access to the MLS and
          hundreds of top real estate websites.
        </Text>
        <EmbeddedYoutubeHintsPlayer mobile width="296" height="536" />
        <InputContainer className={address.address1.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="address1"
            id="address1"
            value={address.address1.value}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          />
          <InputLabel smallLabel={address.address1.value}>Address 1 *</InputLabel>
          {address.address1.error && <ErrorMessage>{address.address1.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer className={address.address2.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="address2"
            id="address2"
            value={address.address2.value}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          />
          <InputLabel smallLabel={address.address2.value}>Address 2</InputLabel>
          {address.address2.error && <ErrorMessage>{address.address2.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer className={address.zip.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="zip"
            id="zip"
            value={address.zip.value}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          />
          <InputLabel smallLabel={address.zip.value}>Zip code *</InputLabel>
          {address.zip.error && <ErrorMessage>{address.zip.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer className={address.city.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="city"
            id="city"
            value={address.city.value}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          />
          <InputLabel smallLabel={address.city.value}>City *</InputLabel>
          {address.city.error && <ErrorMessage>{address.city.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer className={address.county.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="county"
            id="county"
            value={address.county.value}
            onChange={handleFieldChanged}
            onBlur={handleFieldBlur}
          />
          <InputLabel smallLabel={address.county.value}>County *</InputLabel>
          {address.county.error && <ErrorMessage>{address.county.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer className={address.state.error ? 'invalid' : ''}>
          <RegionDropdown
            defaultOptionLabel="Select state *"
            countryValueType="short"
            valueType="short"
            country="US"
            name="state"
            value={address.state.value}
            onChange={selectStateAndCounty}
            error={address.state.error}
            onBlur={handleSelectBlur}
            onFocus={handleSelectFocus}
          />
          {selectLabelVisible && (
            <InputLabel smallLabel={true} htmlFor="state">
              State *
            </InputLabel>
          )}
          {address.state.error && <ErrorMessage>{address.state.error}</ErrorMessage>}
        </InputContainer>
        <ButtonsContaier>
          <SubmitButton
            plain
            type="button"
            onClick={() => {
              !userDetails?.token && setStateToUrl(Routes.ONBOARDING_V2, {});
              dispatch(onboardingActionCreatorsV2.setManualAddressEnterV2(false));
            }}
          >
            Back
          </SubmitButton>
          <SubmitButton type="button" onClick={handleAddressSelected}>
            Looks good
          </SubmitButton>
        </ButtonsContaier>
      </FormContainer>
      <ListingPreviewDesktopContainer />
    </Container>
  );
};

export default ListingAddressForm;
