import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Table } from 'antd';
import { useEffect } from 'react';
import { entityTypes, pageSizeOptions, staticMessages, tableItemsPerPage, dateFormat } from '../../../../../Constants/CommonConstants';
import SearchPanel from '../Shared/SearchPanel.js';
import Routes from '../../../../../Constants/Routes.js';
import { editPageAction, momentDateFormat } from '../../../../../Utilities/commonFunctions';
import { useHistory } from 'react-router';
import { PropertyAssignedSelect } from '../../../Components/PropertyAssignedSelect';
import CurrencyFormat from 'react-currency-format';
import { TableCellTimer } from '../../../../Shared/Components/TableCellTimer';
import { adminPropertiesCountSelector, adminPropertiesSelector, averageListTimeSelector, fetchingAverageTimeToListSelector, propertiesFiltersSelector } from '../../../../../Reducers/V2/AdminPropertiesReducer';
import { propertiesCreators } from '../../../../../Actions/V2/Admin/PropertiesActions';
import PropertiesFilters from './PropertiesFilters';
import { PatternFormat } from 'react-number-format';

const tableConfigs = {
    columns: [
      {
        title: 'Property',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        render: (propertyHeaderText, record) => (
          <Link
            to={editPageAction(record, entityTypes.properties)}
          >
            {propertyHeaderText}
          </Link>
        ),
      },
      {
        title: 'User',
        dataIndex: 'userFullName',
        key: 'user',
        sorter: true,
        render: (user, record) => (
          <Link
            to={editPageAction(record, entityTypes.users)}>
            {user}
          </Link>
        ),
      },
      {
        title: 'User phone number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (phone, record) => (
          <Link
            to={editPageAction(record, entityTypes.users)}>
            <PatternFormat value={phone} displayType="text" format="###-###-####" />
          </Link>
        ),
        width: 130,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
      },
      {
        title: 'Assigned to',
        dataIndex: 'assignedToId',
        key: 'assignedToId',
        sorter: true,
        render: (assignedToId, { id, assignedToStatusId }) => (
          <PropertyAssignedSelect
            selectedOption={assignedToId}
            propertyId={id}
            propertyStatusId={assignedToStatusId}
          />
        ),
      },
      {
        title: 'MLS#',
        dataIndex: 'mlsNumber',
        key: 'mls',
        sorter: true,
      },
      {
        title: 'Form Status',
        dataIndex: 'formStatus',
        key: 'formStatus',
        sorter: true,
      },
      {
        title: 'Price',
        dataIndex: 'listPrice',
        key: 'price',
        sorter: true,
        render: (listPrice) => (
          <CurrencyFormat
            value={listPrice || 0}
            decimalScale={0}
            fixedDecimalScale={true}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ),
      },
      {
        title: 'Last Modified',
        dataIndex: 'dateModified',
        key: 'dateModified',
        sorter: true,
        render: dateModified => (
          <span>{momentDateFormat(dateModified, dateFormat.DATEMODIFIEDFROMAT)}</span>
        ),
      },
      {
        title: 'Date Ordered/Timer',
        dataIndex: 'dateCreated',
        key: 'datepublished',
        sorter: true,
        render: (dateCreated, { mls, processingInterval }) => <TableCellTimer dateCreated={dateCreated} mls={mls} processingInterval={processingInterval} />,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: true,
      },
      {
        title: 'Zip',
        dataIndex: 'zip',
        key: 'zip',
        sorter: true,
      },
      {
        title: 'Title Company',
        dataIndex: 'titleCompany',
        key: 'titleCompany',
        sorter: true,
      },
    ],
    filters: [
      'user',
      'status',
      'inputZip',
      'officeAssign',
      'officeStatus',
      'company',
      'customState',
      'formStatus',
      'lastModifiedFrom',
      'lastModifiedTo',
      'dateOrderedFrom',
      'dateOrderedTo'
    ],
    deleteMessage: `${staticMessages.deleteConfirmation} property?`,
    addPageUrl: `${Routes.ADD_EDIT_PROPERTY.replace(':propertyId', 0)}`,
    addButtonText: 'Add Property',
    searchPlaceHolder: 'Search Property',
};

const PropertiesList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const properties = useSelector(adminPropertiesSelector);
    const filters = useSelector(propertiesFiltersSelector);
    const totalCount = useSelector(adminPropertiesCountSelector);
    const averageListTime = useSelector(averageListTimeSelector);
    const fetchingAverageTimeToList = useSelector(fetchingAverageTimeToListSelector);

    useEffect(() => {
      dispatch(propertiesCreators.getAverageTimeToList());
    }, []);

    useEffect(() => {
        dispatch(propertiesCreators.getProperties(filters));
    }, [
      filters.search,
      filters.page,
      filters.limit,
      filters.sortBy,
      filters.desc,
    ]);

    const onTableChange = (pagination, tablefilters, sorter) => {
        const { current, pageSize } = pagination;
        const { field, order } = sorter;
        const sorterParams = {};

        if (field && order) {
          sorterParams.sortBy = field;
          sorterParams.desc = order === 'descend';
        }
        
        dispatch(propertiesCreators.setPropertiesFilters({
          ...filters,
          ...sorterParams,
          page: current,
          limit: pageSize
        }));
    }

    const showTotal = (total, range) => {
        return `Showing ${range[0]} - ${range[1]} of ${total}`;
    }
    const onSearch = (value) => dispatch(propertiesCreators.setPropertiesFilters({...filters, search: value}));

    return (<>
        <SearchPanel
            averageTime={averageListTime}
            fetchingAverageTime={fetchingAverageTimeToList}
            averageTimePlaceholder={'Average time to list'}
            placeholder={'Search by User Email, Address, Zip'}
            onSearch={onSearch}
        >
            <Button
                variant="primary"
                onClick={() => history.push(tableConfigs.addPageUrl)}
                className="admin-primary-btn">
                Add Property
            </Button>
        </SearchPanel>
        <PropertiesFilters />
        <Card>
            <Row style={{ marginTop: 20 }}>
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} xl={{ span: 24, offset: 0 }}
                    xxl={{ span: 24, offset: 0 }}>
                        <Table
                            className='listing-product-table'
                            size={'default'}
                            columns={tableConfigs.columns}
                            dataSource={properties}
                            onChange={onTableChange}
                            pagination={{
                                total: totalCount,
                                defaultPageSize: tableItemsPerPage,
                                defaultCurrent: 1,
                                showTotal: showTotal,
                                pageSizeOptions: pageSizeOptions,
                                showSizeChanger: totalCount > tableItemsPerPage
                            }} />
                </Col>
            </Row>
      </Card>
      </>
    );
}

export default (PropertiesList);
