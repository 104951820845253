import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { InputFlex, DivWrapper } from '../../../../Assets/Style/commonStyleComponents';
import Table from 'react-bootstrap/Table';
class FieldOverrideInfo extends React.Component {
	constructor(props) {
		super(props);
    }
    render() {
        const { companyFieldOverrides, handleAddRow,handleRemoveRow,handleFieldChanged, handleOverrideFieldBlur } = this.props;
        return (
			<React.Fragment>
				<DivWrapper>
                <div className="basic-form-type">
                    <Table bordered>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>OverrideField <span></span></th>
                            <th>
                            <span className="basic-form-add-field" onClick={() => handleAddRow()}><i className="fa fa-plus" aria-hidden="true"></i></span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                    {map(companyFieldOverrides, (item, index) => item && (
                     <tr id="addr0" key={index}>
                     <td>{index + 1}</td>
                        <td className="hide-label">
                        <InputFlex
                            type="text"
                            name={`fieldName-${index}`}
                            value={item[`fieldName-${index}`]}
                            onChange={(e) => handleFieldChanged(index, e)}
                            onBlur={(e) => handleOverrideFieldBlur(index, e)}
                        />
                         {
                        (item[`errors-fieldName-${index}`] &&
                        <div className="input-error-text">{item[`errors-fieldName-${index}`].msg}</div>
                        )}
                        </td>
                        <td className="hide-label">
                        <InputFlex
                            type="text"
                            name={`overrideValue-${index}`}
                            value={item[`overrideValue-${index}`]}
                            onChange={(e) => handleFieldChanged(index, e)}
                            onBlur={(e) => handleOverrideFieldBlur(index, e)}
                        />
                         {
                        (item[`errors-overrideValue-${index}`] &&
                        <div className="input-error-text">{item[`errors-overrideValue-${index}`].msg}</div>
                        )}
                        </td>
                        <td>
                        <div className="add-delete-row">
                            <span onClick={() =>handleAddRow()}><i className="fa fa-plus" aria-hidden="true"></i></span>
                            <span onClick={() => handleRemoveRow(index)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                        </div>
                        </td>
                     </tr>
                    ))}
            </tbody>
        </Table>
        </div>
        </DivWrapper>
        </React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isFetching: state.admin.isFetching,
	};
}

export default connect(mapStateToProps)(FieldOverrideInfo);
