import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { goBack, editPageAction } from '../../../../../Utilities/commonFunctions';
import { RowFlex, InputFlex } from '../../../../../Assets/Style/commonStyleComponents';
import FieldOverrideInfo from '../../../Components/CompanyForm/FieldOverrideInfo';
import { useFormik } from 'formik';
import {
  regexPattern,
  validationMessages,
  staticMessages,
  entityTypes,
  phoneMask,
} from '../../../../../Constants/CommonConstants';
import v8n from 'v8n';
import TitleCompanyRelationship from './TitleCompanyRelationship';
import { lookupActionsCreators } from '../../../../../Actions/V2/LookupActions';
import { companiesSelector, statesSelector } from '../../../../../Reducers/LookupReducer';
import { titleCompanyService } from '../../../../../Services/V2/TitleCompanyService';
import LogRocket from 'logrocket';
import { startLoading, endLoading } from '../../../../../Actions/LoaderActions';
import { map } from 'lodash';
import { alertActions } from '../../../../../Actions/ToastActions';
import { setStateToUrl } from '../../../../../Utilities/History';
import { titleCompaniesCreators } from '../../../../../Actions/V2/Admin/TitleCompaniesActions';
import styled from 'styled-components';
import TitleCompanyCard from '../../../../Shared/Components/TitleCompanyCard';

const ImageUploadButton = styled.label`
  margin-left: 10px;
  margin-right: 10px;
  height: 2.5rem;
  background-color: #242b26;
  border-color: #242b26;
  color: white;
  &:hover {
    background-color: #242b26;
    border-color: #242b26;
    color: white;
  }
`;

const ImageDeleteButton = styled.button`
  margin-left: 10px;
  margin-right: 10px;
  height: 2.5rem;
  background-color: red;
  color: white;
  &:hover {
    background-color: red;
    color: white;
  }
`;

const AddsImageContainer = styled(RowFlex)`
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const TitleCompanyCardWrapper = styled.div`
  margin-bottom: 2rem;
  max-width: 40rem;
`;

export const CustomTextarea = styled.div`
  margin-bottom: 25px;
  label {
    display: block;
    font-size: 14px;
    font-weight: 700;
    font-family: 'National-Semi-Bold';
    text-transform: capitalize;
    margin: 0 0 10px 0;
  }
  textarea {
    resize: none;
    box-shadow: 0 2px 4px 0 rgba(4, 66, 96, 0.06);
    border: 1px solid #dadada;
    border-radius: 6px;
    padding: 12px 10px;
    background: #fff;
    width: 100%;
    height: 100px;
    font-size: 15px;
    -webkit-transition: border 0.25s;
    transition: border 0.25s;
    &:focus {
      border: 1px solid #B698D9;
    }
    &.item-text-red {
      border: 1px solid red;
      & + .textarea-error {
        display: block;
      }
    }
  }
  .textarea-error {
    font-size: 14px;
    color: red;
    margin: 8px 0 0 0;
    font-weight: 400;
    display: none;
  }
`;

const discountType = {
  Dollar: 'Dollar',
  Percentage: 'Percentage',
};

const discountOptions = [
  { key: discountType.Dollar, value: discountType.Dollar },
  { key: discountType.Percentage, value: discountType.Percentage },
];

const titleCompanyInit = {
  companyName: '',
  companyPhoneNumber: '',
  companyWebsite: '',
  orderEmail: '',
  officerEmail: '',
  officerFirstName: '',
  officerLastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  discount: '0',
  coverPhotoUrl: '',
  adRedirectUrl: '',
  adYouTubeUrl: '',
  discountType: discountType.Dollar,
  overrideFields: [],
  titleCompanyRelationships: [],
};

function rearrangeFilledOverrideFileds(companyFieldOverrides) {
  const fieldData = [];
  map(companyFieldOverrides, (item, index) => {
    if (item) {
      fieldData.push({
        [`companyFieldOverrideId-${index}`]: item.companyFieldOverrideId,
        [`fieldName-${index}`]: item.fieldName,
        [`overrideValue-${index}`]: item.overrideValue,
      });
    }
  });
  return fieldData;
}

const AddEditTitleCompany = ({ id, setIsAddEdit }) => {
  const dispatch = useDispatch();

  const states = useSelector(statesSelector);
  const companies = useSelector(companiesSelector);

  const statesOptions = states.map((x) => ({ key: x.id, value: x.name }));
  const companiesOptions = companies.map((x) => ({ key: x.id, value: x.name }));

  const [titleCompanyData, setTitleCompanyData] = useState(titleCompanyInit);
  const [advertisementFile, setAdvertisementFile] = useState({
    file: null,
    localPath: null,
  });

  useEffect(() => {
    dispatch(lookupActionsCreators.getStates());
    dispatch(lookupActionsCreators.getCompanies());

    if (id) {
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
      titleCompanyService
        .getDetailsById(id)
        .then((result) => {
          dispatch(endLoading('MAIN_LOADER', 'Loading'));
          if (result.data) {
            if (result.data.overrideFields) {
              result.data.overrideFields = rearrangeFilledOverrideFileds(result.data.overrideFields);
            }

            setTitleCompanyData({
              ...result.data,
              orderEmail: !!result.data.orderEmail ? result.data.orderEmail.join(',') : '',
            });
          }
        })
        .catch((error) => {
          dispatch(endLoading('MAIN_LOADER', 'Loading'));
          const errorMessage = error?.response?.data?.message || staticMessages.apiError;
          dispatch(alertActions.errorAlert(errorMessage));
          LogRocket.captureException(error, {
            tags: {
              errorType: 'failed_title_company_action',
            },
            extra: {
              type: 'get_title_company',
            },
          });
        });
    }
  }, []);

  const cancelClickHanlder = () => {
    if (!!id) {
      goBack();
    } else {
      setIsAddEdit(false);
    }
  };

  const hendleFieldValueChanged = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const validate = (values) => {
    let errors = {};

    if (!v8n().string().minLength(1).test(values.companyName)) {
      errors.companyName = 'This field cannot be blank.';
    }
    if (!v8n().string().minLength(1).test(values.address1)) {
      errors.address1 = 'This field cannot be blank.';
    }
    if (!v8n().string().minLength(1).test(values.state)) {
      errors.state = 'This field cannot be blank.';
    }
    if (!v8n().string().minLength(1).test(values.zip)) {
      errors.zip = 'This field cannot be blank.';
    }
    if (!v8n().string().minLength(1).test(values.city)) {
      errors.city = 'This field cannot be blank.';
    }
    if (
      !v8n()
        .string()
        .pattern(/^\d+(\.\d+)?$/)
        .test(values.discount.toString())
    ) {
      errors.discount = 'This field should be number.';
    }
    if (!v8n().string().minLength(1).test(values.discountType)) {
      errors.discountType = 'This field cannot be blank.';
    }
    if (!v8n().string().minLength(1).test(values.companyName)) {
      errors.companyName = 'This field cannot be blank.';
    }
    const emails = (!Array.isArray(values.orderEmail) ? values.orderEmail.split(',') : values.orderEmail).map((item) =>
      item.trim()
    );
    if (!v8n().every.pattern(regexPattern.EMAIL).test(emails)) {
      errors.orderEmail = validationMessages.validEmail;
    }
    if (!v8n().string().minLength(1).test(values.companyPhoneNumber)) {
      errors.companyPhoneNumber = 'This field cannot be blank.';
    }

    return errors;
  };

  const reIntializeOverrideFormFields = (listArr) => {
    const { length } = listArr;
    const upatedArr = [];
    for (let i = 0; i < length; i += 1) {
      const obj = listArr[i];
      const updatedObj = {};
      Object.keys(obj).forEach((key) => {
        const splitValue = key.split('-')[0];
        const updateKey = splitValue.replace(splitValue, `${splitValue}-${i}`);
        updatedObj[updateKey] = obj[key];
      });
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  };

  const handleRemoveRow = (idx) => {
    const companyFieldOverrides = [...formik.values.overrideFields];
    companyFieldOverrides.splice(idx, 1);
    const updatedValues = reIntializeOverrideFormFields(companyFieldOverrides);
    formik.setFieldValue('overrideFields', updatedValues);
  };

  const handleAddRow = () => {
    const values = [...formik.values.overrideFields];
    const addFieldsLength = values.length;
    values.push({
      [`companyFieldOverrideId-${addFieldsLength}`]: 0,
      [`fieldName-${addFieldsLength}`]: '',
      [`overrideValue-${addFieldsLength}`]: '',
    });

    formik.setFieldValue('overrideFields', values);
  };

  const handleOverrideFormFields = (idx, e) => {
    const { name, value } = e.target;
    const companyFieldOverrides = [...formik.values.overrideFields];
    companyFieldOverrides[idx][name] = value;
    formik.setFieldValue('overrideFields', companyFieldOverrides);
  };

  const validateOvwerrideFormField = (idx, e) => {
    const companyFieldOverrides = [...formik.values.overrideFields];
    let isError = false;
    var found = Object.keys(companyFieldOverrides).filter(function (key) {
      return key != idx && companyFieldOverrides[key][`fieldName-${key}`] == e.target.value;
    });

    if (companyFieldOverrides[idx][`fieldName-${idx}`] === '') {
      isError = true;
      companyFieldOverrides[idx][`errors-fieldName-${idx}`] = { required: true, msg: validationMessages.required };
    } else if (found.length) {
      isError = true;
      companyFieldOverrides[idx][`errors-fieldName-${idx}`] = { duplicate: true, msg: 'Display name already exist' };
    } else {
      companyFieldOverrides[idx][`errors-fieldName-${idx}`] = '';
    }
    if (companyFieldOverrides[idx][`overrideValue-${idx}`] === '') {
      isError = true;
      companyFieldOverrides[idx][`errors-overrideValue-${idx}`] = { required: true, msg: validationMessages.required };
    } else {
      companyFieldOverrides[idx][`errors-overrideValue-${idx}`] = '';
    }
    formik.setFieldValue('overrideFields', companyFieldOverrides);
    return isError;
  };

  const removeIndexFromArr = (reqArr) => {
    const { length } = reqArr;
    const upatedArr = [];
    for (let i = 0; i < length; i += 1) {
      const obj = reqArr[i];
      const updatedObj = {};
      Object.keys(obj).forEach((key) => {
        const updateKey = key.split('-')[0];
        updatedObj[updateKey] = obj[key];
      });
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  };

  const formik = useFormik({
    initialValues: titleCompanyData,
    validate,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.overrideFields = removeIndexFromArr(values.overrideFields);

      //skip empty
      values.overrideFields = values.overrideFields.filter((item) => item.overrideValue && item.fieldName);
      values.titleCompanyRelationships = values.titleCompanyRelationships.filter((item) => item.stateCode);
      values.orderEmail = (!Array.isArray(values.orderEmail) ? values.orderEmail.split(',') : values.orderEmail).map(
        (item) => item.trim()
      );

      if (!!id) {
        const successCb = (data) => {
          if (data.overrideFields) {
            data.overrideFields = rearrangeFilledOverrideFileds(data.overrideFields);
          }
          setTitleCompanyData(data);
        };
        dispatch(titleCompaniesCreators.updateTitleCompany(values, successCb, advertisementFile?.file));
      } else {
        const successCb = (data) => {
          setStateToUrl(editPageAction({ id: data.id }, entityTypes.titleCompany));
        };
        dispatch(titleCompaniesCreators.createTitleCompany(values, successCb, advertisementFile?.file));
      }
    },
  });

  const addAdvertismentImage = async (e) => {
    e.persist();
    if (!e.target.files.length) {
      return;
    }

    if (advertisementFile.localPath) {
      URL.revokeObjectURL(advertisementFile.localPath);
    }

    setAdvertisementFile({
      file: e.target.files[0],
      localPath: URL.createObjectURL(e.target.files[0]),
    });
    if (e?.target?.value) {
      e.target.value = '';
    }
  };

  const deleteAdvertismentImage = async () => {
    setTitleCompanyData(pr => ({
      ...pr,
      adFileUrl: null,
      adFileName: null,
    }))

    if (advertisementFile.localPath) {
      URL.revokeObjectURL(advertisementFile.localPath);
    }

    setAdvertisementFile({
      file: null,
      localPath: null,
    });
  };

  const adFileUrl = advertisementFile?.localPath || titleCompanyData?.adFileUrl;

  return (
    <>
      <div className="admin-heading-wrapper with-right-buttons">
        <h2>{!!id ? 'Edit Title Company' : 'Add Title Company'}</h2>
        <div className="admin-heading-wrapper-right"></div>
      </div>
      <div className={`admin-add-form-wrapper`} style={{ marginLeft: 70 }}>
        <RowFlex>
          <InputFlex
            name="companyName"
            title="Title Company Name"
            className="inputgap"
            value={formik.values.companyName}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.companyName}
          />
          <InputFlex
            name="companyPhoneNumber"
            title="Title Company Telephone Number"
            className="inputgap"
            value={formik.values.companyPhoneNumber}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.companyPhoneNumber}
            mask={phoneMask}
          />
        </RowFlex>
        <RowFlex>
          <InputFlex
            name="companyWebsite"
            title="Title Company Website"
            className="inputgap"
            value={formik.values.companyWebsite}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.companyWebsite}
          />
          <InputFlex
            name="orderEmail"
            title="Order Emails"
            className="inputgap"
            value={formik.values.orderEmail}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.orderEmail}
            hint={'* use comma delimiter'}
          />
        </RowFlex>
        <RowFlex>
          <InputFlex
            name="coverPhotoUrl"
            title="Cover Photo Url"
            className="inputgap"
            value={formik.values.coverPhotoUrl}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.coverPhotoUrl}
          />
        </RowFlex>
        <h3 className="admin-form-inner-heading">Title Officer</h3>
        <RowFlex>
          <InputFlex
            name="officerFirstName"
            title="First Name"
            className="inputgap"
            value={formik.values.officerFirstName}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.officerFirstName}
          />
          <InputFlex
            name="officerLastName"
            title="Last Name"
            className="inputgap"
            value={formik.values.officerLastName}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.officerLastName}
          />
          <InputFlex
            name="officerEmail"
            title="Email"
            className="inputgap"
            value={formik.values.officerEmail}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.officerEmail}
          />
        </RowFlex>
        <h3 className="admin-form-inner-heading">Title Company Address</h3>
        <RowFlex>
          <InputFlex
            name="address1"
            title="Address1"
            className="inputgap"
            value={formik.values.address1}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.address1}
          />
          <InputFlex
            name="address2"
            title="Address2"
            className="inputgap"
            value={formik.values.address2}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.address2}
          />
        </RowFlex>
        <RowFlex>
          <InputFlex
            name="city"
            title="City"
            className="inputgap"
            value={formik.values.city}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.city}
          />
          <InputFlex
            options={statesOptions}
            name="state"
            title="State"
            className="inputgap"
            isKeyPaired={true}
            value={formik.values.state}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.state}
            placeholder="Select State"
          />
        </RowFlex>
        <RowFlex>
          <InputFlex
            name="zip"
            title="Zip"
            className="inputgap"
            value={formik.values.zip}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.zip}
          />
        </RowFlex>

        <h3 className="admin-form-inner-heading">Advertisement Info</h3>
        <RowFlex>
          <InputFlex
            name="adRedirectUrl"
            title="Target Url"
            className="inputgap"
            value={formik.values.adRedirectUrl}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.adRedirectUrl}
          />
        </RowFlex>
          <CustomTextarea className='full-width-textarea'>
            <label>YouTube video snippet</label>
            <textarea
              name="adYouTubeUrl"
              type='textarea'
              value={formik.values.adYouTubeUrl}
              onChange={hendleFieldValueChanged}
              onBlur={hendleFieldValueChanged}
            />
          </CustomTextarea>
        <input
          type="file"
          accept="image/jpeg,image/png,image/gif"
          id="fileUpload"
          name="image"
          multiple={false}
          onChange={addAdvertismentImage}
          style={{ display: 'none' }}
        />
        <AddsImageContainer>
          <ImageUploadButton className="btn btn-primary admin-primary-btn" htmlFor="fileUpload">
            {adFileUrl ? 'Replace' : 'Upload '} Advertisment Image
          </ImageUploadButton>
          {adFileUrl && (
            <ImageDeleteButton className="btn btn-primary admin-primary-btn" onClick={deleteAdvertismentImage}>
              Remove Advertisment Image
            </ImageDeleteButton>
          )}
        </AddsImageContainer>
        <h3 className="admin-form-inner-heading">Preview</h3>
        <TitleCompanyCardWrapper>
          <TitleCompanyCard titleCompany={{ ...formik.values, adFileUrl: adFileUrl }} />
        </TitleCompanyCardWrapper>
        <h3 className="admin-form-inner-heading">Advertised Discount</h3>
        <RowFlex>
          <InputFlex
            name="discount"
            title="Value"
            type="number"
            className="inputgap"
            value={formik.values.discount}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.discount}
          />
          <InputFlex
            options={discountOptions}
            name="discountType"
            title="Type"
            className="inputgap"
            value={formik.values.discountType}
            isKeyPaired={true}
            onChange={hendleFieldValueChanged}
            onBlur={hendleFieldValueChanged}
            error={formik.errors.discountType}
          />
        </RowFlex>
        <h3 className="admin-form-inner-heading">Company Relationship</h3>
        <TitleCompanyRelationship formik={formik} statesOptions={statesOptions} companiesOptions={companiesOptions} />
        <h3 className="admin-form-inner-heading">Override Fields</h3>
        <FieldOverrideInfo
          companyFieldOverrides={formik.values.overrideFields}
          handleFieldChanged={handleOverrideFormFields}
          handleFieldBlur={validateOvwerrideFormField}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
          handleOverrideFieldBlur={validateOvwerrideFormField}
        />
        <div className="admin-form-footer">
          <div className="admin-btn-row">
            <Button
              type="submit"
              className="btn btn-primary admin-primary-btn secondary-btn"
              onClick={() => cancelClickHanlder()}
            >
              Cancel
            </Button>
            <Button type="submit" className="btn btn-primary admin-primary-btn" onClick={() => formik.handleSubmit()}>
              {!!id ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditTitleCompany;
