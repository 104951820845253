import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { Button } from 'antd';
import v8n from 'v8n';
import { adminActionCreators } from '../../../Actions/AdminActions';
import {
  RowFlex,
  InputFlex,
} from '../../../Assets/Style/commonStyleComponents';
import { entityTypes, validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import { goBack, getFocusInput, statusChangeModalPopup } from '../../../Utilities/commonFunctions';
import { voucherTemplateTableConfigs } from '../../../Constants/TableConstants';
import { cursorStyles } from '../../../Constants/CommonConstants';

const reactSelectErrorClass = 'admin-react-select-error';

class AddEditVoucherTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.isNewTemplate = props.isNewTemplate;
    this.state = {
      isEnabled: false,
      templateDetail: {
        name: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.selectedItemData?.name || '',
        },
        listingProductId: {
            rules: [
                {
                  rule: v8n().not.empty().not.null(),
                  message: 'Please select a product Id.',
                },
              ],
              value: props?.selectedItemData?.listingProductId ? {
                value: props?.selectedItemData?.listingProductId,
                label: props?.selectedItemData?.listingProductName,
              } : '',
          },
          nonListingProducts: {
            rules: [],
            value: props?.selectedItemData?.nonListingProducts || '',
          },
          companies: {
            rules: [],
            value: props?.selectedItemData?.companies || '',
          },
      },
    };
  }

  handleFieldChanged = e => {
    let templateDetail = { ...this.state.templateDetail };
    templateDetail[e.target.name].value = e.target.value;
    this.setState({ templateDetail: { ...templateDetail } });
  };

  handleFieldBlur = e => {
    this.validateFormField(e.target.name, e.target.value);
  };

  validateFormField = (key, value) => {
    let templateDetail = { ...this.state.templateDetail };
    let isError = false;
    for (let i = 0; i < templateDetail[key].rules.length; i++) {
      let rule = templateDetail[key].rules[i];
      if (!rule.rule.test(value)) {
        templateDetail[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      templateDetail[key].error = null;
    }

    this.setState({
      templateDetail: { ...templateDetail },
      isError: Object.keys(templateDetail).some(
        k =>
          templateDetail[k].error !== undefined &&
          templateDetail[k].error !== null,
      ),
    });

    return isError;
  };

  handleAddUpdateTemplateDetails = templateDetails => {
    if (!this.isNewTemplate) {
      templateDetails['id'] = this.props.selectedItemData.id;
    }
    templateDetails['listingProductId'] = templateDetails.listingProductId.value;
    const templateId = this.props.isNewTemplate
      ? Enums.NUMERIC_VALUE.ZERO
      : this.props.selectedItemData.id;
    this.props.addUpdateVoucherTemplate(templateId, templateDetails, cb => {
      if(cb?.isBack){
        goBack();
      } else {
        this.props.getSelectedApiCall(cb.id, 'template' )
      }
    });
  };

  handleMultiSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.templateDetail };
    if (selectedOption && selectedOption.length) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = selectedOption || [];
    this.setState({ templateDetail: updatedValues });

  };

  handleSingleSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.templateDetail };
    if (selectedOption) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = selectedOption;
    this.setState({ templateDetail: updatedValues });
  };

  validateAndUpdatetemplateDetails = e => {
    e.preventDefault();

    let isError = false;

    Object.keys(this.state.templateDetail).forEach(keyName => {
      const isErrorExist = this.validateFormField(
        keyName,
        this.state.templateDetail[keyName].value,
      );
      if (isErrorExist) {
        isError = true;
      }
    });
    if (!isError) {
      this.handleAddUpdateTemplateDetails({
        ...Object.keys(this.state.templateDetail).reduce((retVal, key) => {
          if (this.state.templateDetail[key].value) {
            retVal[key] = this.state.templateDetail[key].value;
          }
          return retVal;
        }, {}),
      });
    }

    const { templateDetail } = this.state;
    getFocusInput(templateDetail);
  };

  render() {
    const { selectedItemData, lookup } = this.props;
    const { templateDetail } = this.state;
    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>{this.isNewTemplate ? 'Add Voucher Template' : 'Edit Voucher Template'}</h2>
            <div className="admin-heading-wrapper-right">
              {!this.isNewTemplate &&
                <>
                {
                  <button
                    className={`ant-btn btn btn-primary admin-primary-btn ${selectedItemData?.isActive ? 'delete-btn' : ''}`}
                    id="edit-mode"
                    onClick={() => statusChangeModalPopup( selectedItemData, voucherTemplateTableConfigs, () => {
                      this.props.toggleStatus(entityTypes.template, selectedItemData?.id)})
                    }
                  >
                    {selectedItemData?.isActive ? 'Deactivate' : "Activate"}
                  </button>
                }
                </>
              }
            </div>
          </div>
          <div className={`admin-add-form-wrapper ${!this.isNewTemplate && !selectedItemData?.isActive ? 'disabled-input-form' : ''}`}>
            <form autoComplete='off'>
              <RowFlex className='admin-input-with-select'>
                <InputFlex
                  name='name'
                  title='Template Name'
                  className='inputgap'
                  placeholder="Enter Voucher Template Name"
                  value={templateDetail?.name?.value}
                  error={templateDetail?.name?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Listing Product</label>
                  <Select
                    styles={cursorStyles}
                    name='listingProductId'
                    placeholder='Select Product'
                    options={lookup?.listingProducts}
                    value={templateDetail?.listingProductId.value}
                    onChange={e =>
                      this.handleSingleSelectChange(e, 'listingProductId')
                    }
                    className={`admin-react-select ${templateDetail?.listingProductId?.error
                        ? reactSelectErrorClass
                        : ''}`}
                  />
                  {templateDetail?.listingProductId?.error && (
                    <span className='select-text-red'>
                      {validationMessages.required}
                    </span>
                  )}
                </div>
              </RowFlex>
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Non product Listing</label>
                  <Select
                    styles={cursorStyles}
                    name='nonListingProducts'
                    placeholder='Select Non Product'
                    options={lookup?.nonListingProducts}
                    value={templateDetail?.nonListingProducts.value}
                    onChange={e => this.handleMultiSelectChange(e, 'nonListingProducts')}
                    className={`admin-react-select`}
                    isMulti
                  />
                </div>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Companies</label>
                  <Select
                    styles={cursorStyles}
                    name='companies'
                    placeholder='Select Company'
                    options={lookup?.companies}
                    value={templateDetail?.companies.value}
                    onChange={e => this.handleMultiSelectChange(e, 'companies')}
                    className={`admin-react-select`}
                    isMulti
                  />
                </div>
              </RowFlex>
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    onClick={this.validateAndUpdatetemplateDetails}
                    disabled={!this.isNewTemplate && !selectedItemData?.isActive}
                  >
                    {this.isNewTemplate ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditVoucherTemplate);
