import React from 'react';
import { connect } from 'react-redux';
import {
  ModalSubmitButton,
  ModalSubmitButtonWrapper,
  ModalMainWrapper,
  ModalAddressHeading,
  ModalLabelHeading,
} from '../../../Assets/Style/commonStyleComponents';
import { Logo } from '../../Shared/Components/Icons/Logo';
import Routes from '../../../Constants/Routes';

class CheckAddressCoveragePopUp extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ModalMainWrapper>
          <a href={Routes.BUY_SIDE}>
            <Logo />
          </a>
          <ModalAddressHeading>{this.props.selectedAddress}</ModalAddressHeading>
          <ModalLabelHeading>Oops! Address seems to be Incorrect. Please try again</ModalLabelHeading>
          <ModalSubmitButtonWrapper>
            <ModalSubmitButton onClick={() => this.props.handleCloseModal()}>Try Again</ModalSubmitButton>
          </ModalSubmitButtonWrapper>
        </ModalMainWrapper>
      </React.Fragment>
    );
  }
}

export default connect(null)(CheckAddressCoveragePopUp);
