import React from 'react';
import { connect } from 'react-redux';

import { adminActionCreators } from '../../../Actions/AdminActions';
import { history } from '../../../Utilities/History';

const EditButtonComponent = ({
  mls,
  switchCustomerPortal,
  location=history.location.pathname,
}) => {
  const switchToCustomerPortal = () => switchCustomerPortal(mls?.userId,location, mls?.listingId);

  return (
    <>
    {!mls.isLegacySubmission && 
    <button
      className="ant-btn btn btn-primary"
      onClick={switchToCustomerPortal}
    >
      Edit
    </button> } </>
  );
 
 
};

export const EditSwitchUser = connect(null, { ...adminActionCreators })(
  EditButtonComponent,
);
