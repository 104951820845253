import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import v8n from 'v8n';

import { phoneMask, regexPattern, validationMessages } from '../../../Constants/CommonConstants';
import { RegionDropdown } from 'react-country-region-selector';
import PdfUploadManager from './PdfUploadManager';
import {
  addressSelector,
  stateSelector,
  formSubmittingSelector,
  mlsIdSelector,
  pdfLinksSelector } from '../../../Reducers/SellerNotificationReducer';
import { sellerNotificationActionCreators } from '../../../Actions/SellerNotificationActions';
import { Spinner } from 'react-bootstrap';
import routes from '../../../Constants/Routes';
import { history } from '../../../Utilities/History';


const MainContainer = styled.form`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 5rem;
`;

const TitleCheckboxWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
  }

  label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid black;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  input:checked + label:before {
    background: black;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`
  
  const InputContainer = styled.div`
  min-width: 20rem;
  position: relative;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: auto;

  input, textarea, select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    border-color: #242B26;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  textarea {
    padding-top: 0.75rem;
    height: auto;
  }
  input:disabled, select:disabled {
    background-color:  ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  input:disabled+label, select:disabled+label {
    background-color:  ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }
  i {
    color: #242B26;
    opacity: 0.75;
  }
  input::placeholder, textarea::placeholder, select {
    color: #242B26;
    opacity: 0.75;
  }
  &.invalid {
    input, textarea, select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label, select {
      color: #F56565;
    }
  }
  
  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.sm}){
    min-width: 24rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.md}){
    min-width: 26rem;
    margin-right: 2rem;
  }

  @media(min-width: ${({ theme }) => theme.breakpoints.lg}){
    min-width: 28rem;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto
  min-width: 6rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  font-family: 'National-Semi-Bold';
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 1rem;
  margin-right: 1rem;
`;

const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.red[500]};
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;

const InputLabel = styled.label`
    opacity: 0.75;
    top: 0px;
    left: 0px;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
    margin-inline-start: 0.75rem;
    margin-inline-end: 0.75rem;
    padding-inline-start: 0.25rem;
    padding-inline-end: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    transform-origin: left top;
    transition: scale 0.2s, translateY 0.2s, transform 0.2s;
    transition-timing-function: linear;
    display: block;
    text-align: start;
    font-weight: 500;

    ${({ smallLabel }) => smallLabel ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `: ''}
  }
`;

const SelectInputContainer = styled(InputContainer)`
  width: 20rem;
`;

const Text = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0.25rem;
`;

const Loader = styled(Spinner)`
    color: ${({ theme }) => theme.dark};
    align-self: center;
    margin: 4px;
`;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'day').endOf('day');
}

function disabledHours(current) {
  // 8AM - 8PM only available
  return [0,1,2,3,4,5,6,7,21,22,23];
}

const PersonalInfoForm = () => {
  const isOfferForm = history.location.pathname.includes(routes.OFFER_SUBMIT);
  const dispatch = useDispatch();
  const address = useSelector(addressSelector);
  const state = useSelector(stateSelector);
  const mlsId = useSelector(mlsIdSelector);
  const formSubmitting = useSelector(formSubmittingSelector);
  const offerPdfLinks = useSelector(pdfLinksSelector);
  const [showTitle, setShowTitle] = useState(true);
  const [personalInfoFields, setpersonalInfoFields] = useState({
    agentEmail: {
      value: "",
      error: null,
    },
    agentName: {
      value: "",
      error: null,
    },
    agentCompany: {
      value: "",
      error: null,
    },
    agentPhoneNumber: {
      mask: phoneMask,
      value: "",
      error: null,
    },
    showingDateTimeStart: {
      value: null,
      error: null,
    },
    showingDateTimeEnd: {
      value: null,
      error: null,
    },
    copyEmail: {
      value: "",
      error: null,
    },
    address: {
      value: address,
      error: null,
    },
    state: {
      value: state,
      error: null,
    },
    showingComments: {
      value: "",
      error: null,
    },
    title: {
      value: "",
      error: null,
    },
    warranty: {
      value: "",
      error: null,
    },
    netOffer: {
      value: 0,
      error: null,
    },
  });

  useEffect(() => {
    if(address && !personalInfoFields.address.value) {
      setpersonalInfoFields(pr => ({
        ...pr,
        address: {
          ...pr.address,
          value: address,
        }
      }))
    }

  }, [address])

  useEffect(() => {
    if(state && !personalInfoFields.state.value) {
      setpersonalInfoFields(pr => ({
        ...pr,
        state: {
          ...pr.state,
          value: state,
        }
      }))
    }

  }, [state])

  const formValidationRules = {
    agentEmail: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
      {
        rule: v8n().pattern(regexPattern.EMAIL),
        message: validationMessages.validEmail,
      },
      {
        rule: v8n().not.equal(personalInfoFields.copyEmail.value),
        message: validationMessages.sameEmailsSellerNotification,
      }
    ],
    agentName: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    address: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    state: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    agentCompany: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    agentPhoneNumber: [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
      {
        rule: v8n().pattern(regexPattern.PHONE),
        message: validationMessages.phoneNumber,
      },
    ],
    showingDateTimeStart: isOfferForm ? [] :[
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    showingDateTimeEnd: isOfferForm ? [] :[
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ],
    copyEmail: !personalInfoFields.copyEmail.value ? [] : [
        {
          rule: v8n().pattern(regexPattern.EMAIL),
          message: validationMessages.validEmail,
        }
      ],
    showingComments: [],
    title: isOfferForm && showTitle ? [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ] : [],
    warranty: [],
    netOffer: isOfferForm ? [
      {
        rule: v8n().not.empty().not.null(),
        message: validationMessages.required,
      },
    ] : []
  };

  const handleRequestShowing = (e) => {
    e.preventDefault();
    let isError = false;
    dispatch(sellerNotificationActionCreators.setOfferPdfError(false));
    Object.keys(personalInfoFields).forEach((k) => {
      const isErrorExist = validateField(k, personalInfoFields[k].value);
      if (isErrorExist) {
        isError = true;
      }
    })
    if (isOfferForm && !offerPdfLinks.length) {
      isError = true;
      dispatch(sellerNotificationActionCreators.setOfferPdfError(true));
    }
    if (!isError) {
      const userData = {
        address: personalInfoFields.address.value,
        state: personalInfoFields.state.value,
        agentName: personalInfoFields.agentName.value,
        agentCompany: personalInfoFields.agentCompany.value,
        agentEmail: personalInfoFields.agentEmail.value,
        agentPhoneNumber: personalInfoFields.agentPhoneNumber.value,
        showingComments: personalInfoFields.showingComments.value,
        copyEmail: personalInfoFields.copyEmail.value
      };

      if (mlsId) {
        userData.mlsNumber = mlsId;
      }
      
      if (isOfferForm) {
        userData.title = showTitle ? personalInfoFields.title.value : null;
        userData.warranty = personalInfoFields.warranty.value;
        userData.netOffer = personalInfoFields.netOffer.value;
        userData.offerPdfLinks = offerPdfLinks.map(l => l.link);
        dispatch(sellerNotificationActionCreators.submitOffer(userData));
      } else {
        userData.showingDateTimeStart = personalInfoFields.showingDateTimeStart.value.toISOString(true);
        userData.showingDateTimeEnd = personalInfoFields.showingDateTimeEnd.value.toISOString(true);
        dispatch(sellerNotificationActionCreators.requestShowing(userData));
      }
    }
  }

  const handleFieldChanged = (e) => {
    e.persist();
    setpersonalInfoFields(pr => ({ ...pr, [e.target.name]: { ...pr[e.target.name], value: e.target.value } }));
  }

  const validateField = (key, value) => {
    let fields = { ...personalInfoFields };
    let isError = false;
    for (let i = 0; i < formValidationRules[key].length; i++) {
      let rule = formValidationRules[key][i];
    if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setpersonalInfoFields(pr => ({ ...pr, ...fields }));

    return isError;
  }

  const handleSelectDateStart = (time) => {
    setpersonalInfoFields(
      pr => ({ ...pr, showingDateTimeStart: { ...pr.showingDateTimeStart, value: time } }));
  };

  const handleSelectDateEnd = (time) => {
    setpersonalInfoFields(pr => ({ ...pr, showingDateTimeEnd: { ...pr.showingDateTimeEnd, value: time } }));
  };

  const submitButtonTitle = isOfferForm ? "Submit offer" : "Request a showing";

  return (
    <MainContainer onSubmit={handleRequestShowing}>
      <Text>
        {
          isOfferForm 
          ? "You are submitting an offer on:"
          : "You are requesting a showing on:"
        }
      </Text>
      <InputContainer className={personalInfoFields.address.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="address"
            id="address"
            value={personalInfoFields.address.value}
            onChange={handleFieldChanged}
            disabled={address}
          />
        <InputLabel smallLabel={personalInfoFields.address.value}>
          Property address *
        </InputLabel>
        {personalInfoFields.address.error && (
          <ErrorMessage>
            {personalInfoFields.address.error}
          </ErrorMessage>
        )}
      </InputContainer>
        <SelectInputContainer className={personalInfoFields.state.error ? 'invalid' : ''}>
          <RegionDropdown
            defaultOptionLabel=""
            countryValueType="short"
            valueType="short"
            country="US"
            name='state'
            value={personalInfoFields.state.value}
            onChange={(value) => setpersonalInfoFields(pr => ({ ...pr, state: { ...pr.state, value } }))}
            error={personalInfoFields.state.error}
            disabled={state}
          />
          <InputLabel smallLabel={personalInfoFields.state.value}>
            Property state *
          </InputLabel>
          {personalInfoFields.state.error && (
            <ErrorMessage>
              {personalInfoFields.state.error}
            </ErrorMessage>
          )}
        </SelectInputContainer>
        <InputContainer className={personalInfoFields.copyEmail.error ? 'invalid' : ''}>
        <input
          placeholder=" "
          type="email"
          name="copyEmail"
          id="copyEmail"
          autoComplete="email"
          value={personalInfoFields.copyEmail.value}
          onChange={handleFieldChanged}
        />
        <InputLabel smallLabel={personalInfoFields.copyEmail.value} htmlFor="email">Send copy to (optional)</InputLabel>
        {personalInfoFields.copyEmail.error && (
          <ErrorMessage>
            {personalInfoFields.copyEmail.error}
          </ErrorMessage>
        )}
        </InputContainer>
        <InputContainer className={personalInfoFields.agentEmail.error ? 'invalid' : ''}>
        <input
          placeholder=" "
          type="email"
          name="agentEmail"
          id="agentEmail"
          autoComplete="email"
          value={personalInfoFields.agentEmail.value}
          onChange={handleFieldChanged}
        />
        <InputLabel smallLabel={personalInfoFields.agentEmail.value} htmlFor="agentEmail">
          Agent {isOfferForm && "for buyer"} email address *
        </InputLabel>
        {personalInfoFields.agentEmail.error && (
          <ErrorMessage>
            {personalInfoFields.agentEmail.error}
          </ErrorMessage>
        )}
        </InputContainer>
        <InputContainer className={personalInfoFields.agentName.error ? 'invalid' : ''}>
        <input
          placeholder=" "
          type="text"
          name="agentName"
          id="agentName"
          value={personalInfoFields.agentName.value}
          onChange={handleFieldChanged}
        />
        <InputLabel smallLabel={personalInfoFields.agentName.value} htmlFor="agentName">
          Agent {isOfferForm && "for buyer"} Name *
        </InputLabel>
        {personalInfoFields.agentName.error && (
          <ErrorMessage>
            {personalInfoFields.agentName.error}
          </ErrorMessage>
        )}
        </InputContainer>
        <InputContainer className={personalInfoFields.agentCompany.error ? 'invalid' : ''}>
        <input
          placeholder=" "
          type="text"
          name="agentCompany"
          id="agentCompany"
          value={personalInfoFields.agentCompany.value}
          onChange={handleFieldChanged}
        />
        <InputLabel smallLabel={personalInfoFields.agentCompany.value} htmlFor="agentCompany">
          Agent {isOfferForm && "for buyer"} Company *
        </InputLabel>
        {personalInfoFields.agentCompany.error && (
          <ErrorMessage>
            {personalInfoFields.agentCompany.error}
          </ErrorMessage>
        )}
        </InputContainer>
        <InputContainer className={personalInfoFields.agentPhoneNumber.error ? 'invalid' : ''}>
        <MaskedInput
          mask={personalInfoFields.agentPhoneNumber.mask}
          type="tel"
          id="agentPhoneNumber"
          name="agentPhoneNumber"
          value={personalInfoFields.agentPhoneNumber.value}
          onChange={handleFieldChanged}
          autoComplete="off"
        />
        <InputLabel smallLabel={personalInfoFields.agentPhoneNumber.value} htmlFor="agentPhoneNumber">
          Agent {isOfferForm && "for buyer"} phone number *
        </InputLabel>
        {personalInfoFields.agentPhoneNumber.error && (
          <ErrorMessage>
            {personalInfoFields.agentPhoneNumber.error}
          </ErrorMessage>
        )}
        </InputContainer>
        {!isOfferForm ? (
          <>
            <Text>Select a date and time you wish to view the property</Text>
            <InputContainer className={personalInfoFields.showingDateTimeStart.error ? 'invalid' : ''}>
              <DatePicker
                placeholder="Showing start time *"
                format="YYYY-MM-DD hh:mm A"
                disabledDate={disabledDate}
                style={{ input: {borderColor: 'black', color: 'black'}}}
                showTime={{
                  hideDisabledOptions: true,
                  disabledHours,
                  defaultValue: moment('09:00', 'hh:mm'),
                  use12Hours: true,
                  format: "hh:mm",
                  minuteStep: 15,
                }}
                defaultValue={moment()}
                onChange={handleSelectDateStart}
                value={personalInfoFields.showingDateTimeStart.value}
              />
              {personalInfoFields.showingDateTimeStart.error && (
                <ErrorMessage>
                  {personalInfoFields.showingDateTimeStart.error}
                </ErrorMessage>
              )}
            </InputContainer>
            <InputContainer className={personalInfoFields.showingDateTimeEnd.error ? 'invalid' : ''}>
              <DatePicker
                placeholder="Showing end time *"
                format="YYYY-MM-DD hh:mm A"
                disabledDate={disabledDate}
                style={{ input: {borderColor: 'black', color: 'black'}}}
                showTime={{
                  hideDisabledOptions: true,
                  disabledHours,
                  defaultValue: moment('08:00', 'hh:mm'),
                  use12Hours: true,
                  format: "hh:mm",
                  minuteStep: 15,
                }}
                defaultValue={moment()}
                onChange={handleSelectDateEnd}
                value={personalInfoFields.showingDateTimeEnd.value}
              />
              {personalInfoFields.showingDateTimeEnd.error && (
                <ErrorMessage>
                  {personalInfoFields.showingDateTimeEnd.error}
                </ErrorMessage>
              )}
            </InputContainer>
          </>
        ) : (
          <PdfUploadManager />
        )}
        {isOfferForm && <TitleCheckboxWrapper>
            <input type="checkbox" 
              id="title-checkbox" 
              onChange={(e) => {
                setShowTitle(!e.target.checked);
              }}
              defaultChecked={!showTitle}/>
            <label htmlFor="title-checkbox">Is the seller's preferred title listed in the MLS Agent remarks on the offer?</label>
          </TitleCheckboxWrapper>
        }
        {(isOfferForm && showTitle) && <InputContainer className={personalInfoFields.title.error ? 'invalid' : ''}>
            <input
              placeholder=" "
              type="text"
              name="title"
              id="title"
              value={personalInfoFields.title.value}
              onChange={handleFieldChanged}
            />
          <InputLabel smallLabel={personalInfoFields.title.value}>
            What is the name of the Title/Escrow Company on the offer
          </InputLabel>
          {personalInfoFields.title.error && (
            <ErrorMessage>
              {personalInfoFields.title.error}
            </ErrorMessage>
          )}
        </InputContainer>}
        {isOfferForm && <InputContainer className={personalInfoFields.warranty.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="warranty"
            id="warranty"
            value={personalInfoFields.warranty.value}
            onChange={handleFieldChanged}
          />
        <InputLabel smallLabel={personalInfoFields.warranty.value}>
          What is the name of Home Warranty Company
        </InputLabel>
        {personalInfoFields.warranty.error && (
          <ErrorMessage>
            {personalInfoFields.warranty.error}
          </ErrorMessage>
        )}
      </InputContainer>}
      {isOfferForm && <InputContainer className={personalInfoFields.netOffer.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="netOffer"
            id="netOffer"
            value={personalInfoFields.netOffer.value}
            onChange={handleFieldChanged}
          />
        <InputLabel smallLabel={personalInfoFields.netOffer.value || personalInfoFields.netOffer.value === 0}>
          What is the NET amount of the submitted offer?
        </InputLabel>
        {personalInfoFields.netOffer.error && (
          <ErrorMessage>
            {personalInfoFields.netOffer.error}
          </ErrorMessage>
        )}
      </InputContainer>}
        <InputContainer className={personalInfoFields.showingComments.error ? 'invalid' : ''}>
        <textarea
          placeholder=" "
          type="text"
          name="showingComments"
          id="showingComments"
          value={personalInfoFields.showingComments.value}
          onChange={handleFieldChanged}
          rows="4"
        />
        <InputLabel smallLabel={personalInfoFields.showingComments.value} htmlFor="showingComments">
          Comments
        </InputLabel>
        </InputContainer>
        <SubmitButton type='submit' disabled={formSubmitting}>
          {formSubmitting ?  <Loader animation="border" size="sm" /> : submitButtonTitle}
        </SubmitButton>
    </MainContainer>
  )
};

export default PersonalInfoForm;