import React from 'react';
import styled from 'styled-components';

const DisplayWrapper = styled.div`
display:flex;
flex:1;
flex-direction:column;
text-align:left;
&:first-child {
	@media(max-width: 768px) {
		margin-bottom: 20px;
	}
}
`;

const Label = styled.label`
display: block;
font-size: 15px;
font-weight: 700;
font-family: 'National-Semi-Bold';
text-transform: capitalize;
margin-bottom: 10px;
`;

const Description = styled.span`
font-size:15px;
line-height:22px;
font-weight:400;
@media(max-width: 768px) {
	line-height: 28px;
}
`;

export default class DisplayText extends React.Component {
	render() {
		return (
			<DisplayWrapper>
				<Label htmlFor={this.props.title}>{this.props.title}</Label>
				<Description>{this.props.children}</Description>
			</DisplayWrapper>
		);
	}
}
