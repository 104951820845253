import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import v8n from 'v8n';
import { Button } from 'antd';
import { adminActionCreators } from '../../../Actions/AdminActions';
import {
  RowFlex,
  InputFlex,
  CustomTextarea,
  ModalDiv,
} from '../../../Assets/Style/commonStyleComponents';
import { validationMessages } from '../../../Constants/CommonConstants';
import Enums from '../../../Constants/Enums';
import { goBack, getFocusInput, isListingProcessorRole, checkIsValidNumber, checkIsValidFloatNumber } from '../../../Utilities/commonFunctions';
import { cursorStyles } from '../../../Constants/CommonConstants';
import { productsCreators } from '../../../Actions/V2/Admin/ProductsActions';

class AddEditProduct extends React.Component {
  constructor(props) {
    super(props);
    this.isNewProduct = props.isNewProduct;
    this.state = {
      isProductUpdateConfirmPopup: false,
      isBasicProductType: false,
      updatedProductObj: {},
      errors: {},
      productDetail: {
        title: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.title,
            },
          ],
          value: props.userProfileData?.title || '',
        },
        priceUpFront: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.userProfileData?.priceUpFront || '',
        },
        priceAfterClose: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.userProfileData?.priceAfterClose || '',
        },
        minPriceAfterClose: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.required,
            },
          ],
          value: props?.userProfileData?.minPriceAfterClose || 0,
        },
        productTypeId: {
          rules: [
            {
              rule: v8n().not.empty().not.null(),
              message: validationMessages.productType,
            },
          ],
          value:
            props?.lookup['productTypes']?.find(
              p => p.value === props.userProfileData?.productTypeId,
            ) || '',
        },
        mls: {
          rules: [],
          value: props?.userProfileData?.mls || [],
        },
        companies: {
          rules: [],
          value: props?.userProfileData?.companies || [],
        },
        mlsCategories: {
          rules: [],
          value: props?.userProfileData?.mlsCategories || [],
        },
        states: {
          rules: [],
          value: props?.userProfileData?.states || [],
        },
        priceDescription: {
          rules: [],
          value: this.props.userProfileData?.priceDescription || '',
        },
        productDescription: {
          rules: [],
          value: this.props.userProfileData?.productDescription || '',
        },
      },
      productFeatureTypes:
        this.props.productFeatureTypes || [],
    };
  }

  validateFormField = (key, value) => {
    let productDetail = { ...this.state.productDetail };
    let isError = false;
    for (let i = 0; i < productDetail[key].rules.length; i++) {
      let rule = productDetail[key].rules[i];
      if (!rule.rule.test(value)) {
        productDetail[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (productDetail['priceUpFront'].value && !checkIsValidFloatNumber(productDetail['priceUpFront'].value)) {
      productDetail['priceUpFront'].error = validationMessages.numericValueRequired;
      isError = true;
    }

    if (!isError) {
      productDetail[key].error = null;
    }

    this.setState({
      productDetail: { ...productDetail },
      isError: Object.keys(productDetail).some(
        k =>
          productDetail[k].error !== undefined &&
          productDetail[k].error !== null,
      ),
    });

    return isError;
  };
  handleAddUpdateProductDetails = productDetails => {
    productDetails['productId'] = this.props.userProfileData.productId;
    productDetails['productTypeId'] = Number(
      productDetails.productTypeId.value,
    );
    productDetails['productFeatureTypes'] = this.state.productFeatureTypes
      .filter(item => !!item.value && item.value !== 'False' && item.value !== '0')
      .map(item => {
        return {
          name: item.name || null,
          displayName:item.displayName || null,
          description: item.description || null,
          inputDataTypeId: item.inputDataTypeId || null,
          displayValue: item.displayValue || null,
          priority: +item.priority || null,
          productFeatureTypeId: item.productFeatureTypeId,
          value: item.value ? item.value.toString() : null,
        };
      },
    );

    if (this.isNewProduct) {
      this.props.createProduct(productDetails, () => {
        goBack();
      });
      return;
    }

    this.props.updateProduct(this.props.userProfileData.productId, productDetails);

    /*this.props.addUpdateProduct(
      this.props.userProfileData.productId,
      productDetails,
      () => {
        goBack();
      },
    );*/
  };

  validateAndUpdateProductDetails = e => {
    e.preventDefault();

    let isError = false;

    Object.keys(this.state.productDetail).forEach(keyName => {
      const isErrorExist = this.validateFormField(
        keyName,
        this.state.productDetail[keyName].value,
      );
      if (isErrorExist) {
        isError = true;
      }
    });

    const isFeatureFieldError = this.state.productFeatureTypes.some(
      item => item.isFeeError || item.isPriorityError
    );
    if (!isError && !isFeatureFieldError) {
      const objectDetails = {
        ...Object.keys(this.state.productDetail).reduce((retVal, key) => {
          if (this.state.productDetail[key].value) {
            retVal[key] = this.state.productDetail[key].value;
          }
          return retVal;
        }, {}),
      };
      if(this.isNewProduct) {
        this.handleAddUpdateProductDetails(objectDetails);
      } else {
        this.setState({
          isProductUpdateConfirmPopup: true,
          updatedProductObj: objectDetails,
          isBasicProductType: true,
        })
      }
      
    }

    const { productDetail } = this.state;
    getFocusInput(productDetail);
  };
  handleFieldChanged = (e, event) => {
    let productDetail = { ...this.state.productDetail };
    productDetail[e.target.name].value = e.target.value;
    this.setState({ productDetail: { ...productDetail } });
  };
  handleFieldBlur = e => {
    this.validateFormField(e.target.name, e.target.value);
  };
  handleMultiSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.productDetail };
    updatedValues[type].value = selectedOption;
    this.setState({ productDetail: updatedValues });
  };
  handleSingleSelectChange = (selectedOption, type) => {
    const updatedValues = { ...this.state.productDetail };
    if (selectedOption) {
      updatedValues[type].error = '';
    } else {
      updatedValues[type].error = '*';
    }
    updatedValues[type].value = selectedOption;
    this.setState({ productDetail: updatedValues });
  };
  handleFeatureValue = (e, index, inputType) => {
    const { name, value } = e.target;
    const { productFeatureTypes } = this.state;
    const updateObj = productFeatureTypes[index];
    if (inputType === Enums.NUMERIC_VALUE.FIVE) {
      updateObj[name] = !updateObj[name];
    } else {
      updateObj[name] = value;
    }
    this.setState({ productFeatureTypes });
  };

  handleFeatureValueBlur = (e, index) => {
    const { name, value } = e.target;
    const { productFeatureTypes } = this.state;
    const featureObj = productFeatureTypes[index];
    if (value && !checkIsValidNumber(value)) {
      if(name === 'value') {
        featureObj.isFeeError = validationMessages.numericValueRequired;
      } else if(name === 'priority') {
        featureObj.isPriorityError = validationMessages.numericValueRequired;
      }
    } else {
      if(name === 'value') {
        featureObj.isFeeError = false;
      } else if(name === 'priority') {
        featureObj.isPriorityError = false;
      }
    }
    this.setState({ productFeatureTypes });
  };

  handleProductUpdatePopup = () => {   
    const { updatedProductObj, isBasicProductType } = this.state;
    
    if(isBasicProductType) {
      this.handleAddUpdateProductDetails(updatedProductObj);
    }
    this.setState({
      isProductUpdateConfirmPopup: false,
    })
  }

  render() {
    const { lookup } = this.props;
    const { productDetail, productFeatureTypes, isProductUpdateConfirmPopup } = this.state;
    return (
      <React.Fragment>
        <div className='admin-inner-wrapper'>
          <div className='admin-heading-wrapper with-right-buttons'>
            <h2>{this.isNewProduct ? 'Add Product' : 'Edit Product'}</h2>
            <div className="admin-heading-wrapper-right">
            </div>
          </div>
          <div className={`admin-add-form-wrapper admin-add-edit-abbrivation ${isListingProcessorRole() ? 'disabled-input-form' : ''}`}>
            <form autoComplete='off'>
              <InputFlex
                name='title'
                title='Product Title'
                className='inputgap'
                value={productDetail?.title?.value}
                error={
                  productDetail?.title?.error ||
                  (this.props?.errors?.title || [])[0]
                }
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
              />
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Product Type</label>
                  <Select
                    styles={cursorStyles}
                    name='productType'
                    placeholder='Select Product'
                    options={lookup?.productTypes}
                    value={productDetail?.productTypeId.value}
                    onChange={e =>
                      this.handleSingleSelectChange(e, 'productTypeId')
                    }
                    className={`admin-react-select ${
                      productDetail?.productTypeId?.error
                        ? 'admin-react-select-error'
                        : ''
                    }`}
                  />
                  {productDetail?.productTypeId?.error && (
                    <span className='select-text-red'>
                      {validationMessages.productRequired}
                    </span>
                  )}
                </div>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>MLS Info</label>
                  <Select
                    styles={cursorStyles}
                    name='mls'
                    placeholder='Select MLS Info'
                    options={lookup?.mls}
                    value={productDetail?.mls.value}
                    onChange={e => this.handleMultiSelectChange(e, 'mls')}
                    className='admin-react-select'
                    isMulti
                  />
                </div>
              </RowFlex>
              <RowFlex>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>Companies</label>
                  <Select
                    styles={cursorStyles}
                    name='companies'
                    placeholder='Select Companies'
                    options={lookup?.companies}
                    value={productDetail?.companies.value}
                    onChange={e => this.handleMultiSelectChange(e, 'companies')}
                    isMulti
                    className='admin-react-select'
                  />
                </div>
                <div className='pcol-6 for-custom-select bottom-gap'>
                  <label>MLS Categories</label>
                  <Select
                    styles={cursorStyles}
                    name='mlsCategories'
                    placeholder='Select MLS Categories'
                    options={lookup?.mlsCategories}
                    value={productDetail?.mlsCategories.value}
                    onChange={e =>
                      this.handleMultiSelectChange(e, 'mlsCategories')
                    }
                    isMulti
                    className='admin-react-select'
                  />
                </div>
              </RowFlex>
              <div className='for-custom-select bottom-gap'>
                <label>States</label>
                <Select
                  styles={cursorStyles}
                  name='states'
                  placeholder='Select States'
                  options={lookup?.states}
                  value={productDetail?.states.value}
                  onChange={e => this.handleMultiSelectChange(e, 'states')}
                  isMulti
                  className='admin-react-select'
                />
              </div>
              <RowFlex>
                <InputFlex
                  name='priceUpFront'
                  title='Price Up Front'
                  className='inputgap'
                  type='text'
                  value={productDetail?.priceUpFront?.value}
                  error={productDetail?.priceUpFront?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  name='priceAfterClose'
                  title='Price After Close'
                  className='inputgap'
                  type='text'
                  value={productDetail?.priceAfterClose?.value}
                  error={productDetail?.priceAfterClose?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
                <InputFlex
                  name='minPriceAfterClose'
                  title='Min Price'
                  className='inputgap'
                  type='number'
                  value={productDetail?.minPriceAfterClose?.value}
                  error={productDetail?.minPriceAfterClose?.error}
                  onChange={this.handleFieldChanged}
                  onBlur={this.handleFieldBlur}
                />
              </RowFlex>
              <RowFlex>
                <div className='pcol-6 for-custom-select'>
                  <CustomTextarea>
                    <label>Price Description</label>
                    <textarea
                      name='priceDescription'
                      type='textarea'
                      value={productDetail.priceDescription?.value}
                      onChange={this.handleFieldChanged}
                      onBlur={this.handleFieldBlur}
                    />
                  </CustomTextarea>
                </div>
                <div className='pcol-6 for-custom-select'>
                  <CustomTextarea>
                    <label>Product Description</label>
                    <textarea
                      name='productDescription'
                      type='textarea'
                      value={productDetail.productDescription?.value}
                      onChange={this.handleFieldChanged}
                      onBlur={this.handleFieldBlur}
                    />
                  </CustomTextarea>
                </div>
              </RowFlex>
              <div className='add-product-module-wrapper'>
                {productFeatureTypes.map((item, index) => (
                  <div key={item.displayName} className='add-product-module'>
                    <h3 className='admin-form-inner-heading with-below-text'>
                      {item.displayName}
                    </h3>
                    <p className='admin-form-inner-text'>{item.description}</p>
                    {item.inputDataTypeId === Enums.NUMERIC_VALUE.FIVE && (
                      <div className='admin-custom-checkbox for-add-product'>
                        <label>
                          <input
                            className='inputgap'
                            name='value'
                            type='checkbox'
                            value={!!item.value}
                            checked={!!item.value}
                            onChange={e =>
                              this.handleFeatureValue(
                                e,
                                index,
                                item.inputDataTypeId,
                              )
                            }
                          />
                          <span />
                        </label>
                      </div>
                    )}
                    {item.inputDataTypeId !== Enums.NUMERIC_VALUE.FIVE && (
                      <InputFlex
                        title='Enter Value'
                        type='text'
                        name='value'
                        value={item.value || ''}
                        onChange={e => this.handleFeatureValue(e, index)}
                        onBlur={e => this.handleFeatureValueBlur(e, index)}
                        error={item?.isFeeError}
                      />
                    )}
                    <RowFlex>
                      <InputFlex
                        title='Description'
                        type='text'
                        name='displayValue'
                        value={item.displayValue || ''}
                        placeholder='Description'
                        onChange={e => this.handleFeatureValue(e, index)}
                        className='inputgap'
                      />
                      <InputFlex
                        title='Enter Priority'
                        type='number'
                        name='priority'
                        value={item.priority}
                        error={item?.isPriorityError}
                        placeholder='Enter Priority'
                        onChange={e => this.handleFeatureValue(e, index)}
                        onBlur={e => this.handleFeatureValueBlur(e, index)}
                        className='inputgap'
                      />
                    </RowFlex>
                  </div>
                ))}
              </div>
              <div className='admin-form-footer'>
                <div className='admin-btn-row'>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn secondary-btn'
                    onClick={() => goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='submit'
                    className='btn btn-primary admin-primary-btn'
                    onClick={this.validateAndUpdateProductDetails}
                    disabled={isListingProcessorRole()}
                  >
                    {this.isNewProduct ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ModalDiv
          title="Edit Product Confirmation"
          visible={isProductUpdateConfirmPopup}
          onCancel={() => this.setState({ isProductUpdateConfirmPopup: false })}
          onOk={() => this.handleProductUpdatePopup()}
          okText="Yes"
          cancelText="No"
          closable={false}
          destroyOnClose={true}
          className="confirm-modal"
        >
          <p className="static-text">Are you sure you want to PUBLISH your changes on the LIVE site?</p>
        </ModalDiv>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.admin.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...adminActionCreators, ...productsCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditProduct);
