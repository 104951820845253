import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import v8n from 'v8n';
import { RegionDropdown } from 'react-country-region-selector';
import { MapMarker } from '../../Shared/Components/Icons/MapMarker';
import { useDispatch, useSelector } from 'react-redux';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import {
  fetchingDraftListingSelector,
  selectedAddressSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { averagePriceLoadingSeector } from '../../../Reducers/LoaderReducer';
import ListingPreviewDesktopContainer from './ListingPreviewDesktopContainer';
import { Map } from 'google-maps-react';
import { LYFSteps } from '../../../Constants/CommonConstants';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 10rem;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme, plain }) => (plain ? theme.light : theme.colors.violet[200])};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const InputContainer = styled.div`
  min-width: 20rem;
  position: relative;
  margin-bottom: 1rem;
  width: auto;

  input,
  textarea,
  select {
    width: 100%;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    font-size: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    height: 3rem;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dashed;
    border-color: #242b26;
    color: ${({ theme }) => theme.colors.green[900]};
  }
  textarea {
    padding-top: 0.75rem;
    height: auto;
  }
  input:disabled, select:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }
  input:disabled + label, select:disabled + label {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
    border-radius: 4px;
  }
  i {
    color: #242b26;
    opacity: 0.75;
  }
  input::placeholder,
  textarea::placeholder,
  select {
    color: #242b26;
    opacity: 0.75;
  }
  &.invalid {
    input,
    textarea,
    select {
      border-color: rgb(229, 62, 62);
      background-color: #e5161608;
    }
    label,
    select {
      color: #f56565;
    }
  }

  &:focus-within label {
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 24rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 26rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 28rem;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const InputLabel = styled.label`
  opacity: 0.75;
  top: 0px;
  left: 0px;
  z-index: 2;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  margin-inline-start: 0.75rem;
  margin-inline-end: 0.75rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  transform-origin: left top;
  transition: scale 0.2s, translateY 0.2s, transform 0.2s;
  transition-timing-function: linear;
  display: block;
  text-align: start;
  font-weight: 500;

  ${({ smallLabel }) =>
    smallLabel
      ? `
    transform: scale(0.85) translateY(-22px);
    background-color: #ffffff;
    font-size: 12px;
    opacity: 1;
    `
      : ''}

  ${({ select }) =>
    select
      ? `
    display: none;
    &:has(+ (select(select:focus)) {
    display: block;
    }
    `
      : ''}
`;

const ButtonsContaier = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const mapStyle = {
  width: '100%',
  position: 'relative',
  borderRadius: '10px',
  display: 'flex',
};

const mapContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
};
const MapMarkerContainer = styled.div`
  position: absolute;
  left: 46.25%;
  top: 138px;
`;

const MapWrapper = styled.div`
  border-radius: 10px;
  position: relative;
  background: #f9f9f9;
  margin: 0 0 60px;
  width: auto;
  min-width: 300px;
  max-width: 48rem;
  height: 340px;
  &.for-bottom-gap {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: auto;
    min-width: 620px;
    max-width: 85rem;
  }
`;

const ListingAddressChangeForm = () => {
  const selectedAddress = useSelector(selectedAddressSelector);
  const fetchingDraftListing = useSelector(fetchingDraftListingSelector);
  const mapCoords = {
    lat: selectedAddress?.location?.lat,
    lng: selectedAddress?.location?.lng,
  };


  const dispatch = useDispatch();

  const [address, setAddress] = useState({
    address1: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please enter your street address',
        },
      ],
      value: '',
    },
    address2: {
      rules: [],
      value: '',
    },
  });

  useEffect(() => {
    // prefill local useState address object with draftlisting address
    if (!fetchingDraftListing) {
      setAddress((pr) => ({
        ...pr,
        address1: {
          ...pr.address1,
          value: selectedAddress?.address1 ?? '',
        },
        address2: {
          ...pr.address2,
          value: selectedAddress?.address2 ?? '',
        },
      }));
    }
  }, [fetchingDraftListing]);

  const handleAddressChanged = async () => {
    let isError = false;
    Object.keys(address).forEach((k) => (isError = validateField(k, address[k].value)));

    if (isError) {
      return;
    }

      dispatch(
        onboardingActionCreatorsV2.changeAddress({
          address1: address.address1.value,
          address2: address.address2.value,
        })
      );
      dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PRICE_AND_COMISSIONS))
  };

  const handleFieldChanged = (e) => {
    const addressForm = { ...address };
    addressForm[e.target.name].value = e.target.value;
    setAddress((pr) => ({
      ...pr,
      ...addressForm,
    }));
  };

  const validateField = (key, value) => {
    let isError = null;

    for (let i = 0; i < address[key].rules.length; i++) {
      let rule = address[key].rules[i];

      if (!rule.rule.test(value)) {
        isError = rule.message;
        break;
      }
    }

    setAddress((pr) => ({
      ...pr,
      [key]: {
        ...pr[key],
        error: isError,
      },
    }));

    return isError;
  };

  return (
    <Container>
      <FormContainer>
        <TitleContainer>
          <SubTilteText>List your property</SubTilteText>
          <StepsText>1/9</StepsText>
        </TitleContainer>
        <TitleText>Property Address</TitleText>
        <Text>
          Enter your address below to get started. Reach millions of buyers, with free, nationwide access to the MLS and
          hundreds of top real estate websites.
        </Text>
        <InputContainer className={address.address1.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="address1"
            id="address1"
            value={address.address1.value}
            onChange={handleFieldChanged}
          />
          <InputLabel smallLabel={address.address1.value}>Address 1 *</InputLabel>
          {address.address1.error && <ErrorMessage>{address.address1.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer className={address.address2.error ? 'invalid' : ''}>
          <input
            placeholder=" "
            type="text"
            name="address2"
            id="address2"
            value={address.address2.value}
            onChange={handleFieldChanged}
          />
          <InputLabel smallLabel={address.address2.value}>Address 2</InputLabel>
          {address.address2.error && <ErrorMessage>{address.address2.error}</ErrorMessage>}
        </InputContainer>
        <InputContainer>
          <input
            placeholder=" "
            type="text"
            name="zip"
            id="zip"
            disabled={true}
            value={selectedAddress?.zip ?? ''}
          />
          <InputLabel smallLabel>Zip code *</InputLabel>
        </InputContainer>
        <InputContainer>
          <input
            placeholder=" "
            type="text"
            name="city"
            id="city"
            disabled={true}
            value={selectedAddress?.city ?? ''}
          />
          <InputLabel smallLabel>City *</InputLabel>
        </InputContainer>
        <InputContainer>
          <input
            placeholder=" "
            type="text"
            name="county"
            id="county"
            disabled={true}
            value={selectedAddress?.county ?? ''}
          />
          <InputLabel smallLabel>County *</InputLabel>
        </InputContainer>
        <InputContainer>
          <RegionDropdown
            defaultOptionLabel="Select state *"
            countryValueType="short"
            valueType="short"
            country="US"
            name="state"
            disabled={true}
            value={selectedAddress?.state ?? ''}
          />
          <InputLabel smallLabel htmlFor="state">
            State *
          </InputLabel>
        </InputContainer>
        {selectedAddress?.location && (
          <MapWrapper>
            <Map
              google={window.google}
              draggable={false}
              center={mapCoords}
              initialCenter={mapCoords}
              style={{
                ...mapStyle,
                height: selectedAddress ? '100%' : '0',
              }}
              containerStyle={mapContainerStyle}
              zoom={15}
            >
              <MapMarkerContainer>
                <MapMarker />
              </MapMarkerContainer>
            </Map>
          </MapWrapper>
        )}
        <ButtonsContaier>
          <SubmitButton
            plain
            type="button"
            onClick={() => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PRICE_AND_COMISSIONS))}
          >
            Back to step 2
          </SubmitButton>
          <SubmitButton type="button" onClick={handleAddressChanged}>
            Change address
          </SubmitButton>
        </ButtonsContaier>
      </FormContainer>
      <ListingPreviewDesktopContainer />
    </Container>
  );
};

export default ListingAddressChangeForm;
