import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import v8n from 'v8n';
import { userActionCreators } from '../../../Actions/UserActions';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { RowFlex, InputFlex, CustomButton, HeadText, HeadTextWrap, Heading, DetailsWrapper } from '../../../Assets/Style/commonStyleComponents';
import { history } from '../../../Utilities/History';
import Routes from '../../../Constants/Routes';
import { dashboardTabs, validationMessages } from '../../../Constants/CommonConstants';
import { BillingAddressSelector } from './BillingAddressSelector';
import { getLocalUserData } from '../../../Utilities/commonFunctions';

class EditBillingAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billingAddressForm: {
        firstName: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: validationMessages.firstName
          }],
          value: props.billingAddress.firstName || ""
        },
        lastName: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: validationMessages.lastName
          }],
          value: props.billingAddress.lastName || ""
        },
        address1: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter your street address"
          }],
          value: props.billingAddress.address1 || ""
        },
        city: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter your billing city"
          }],
          value: props.billingAddress.city || ""
        },
        address2: {
          rules: [],
          value: props.billingAddress.address2 || ""
        },
        country: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please select your billing country"
          }],
          value: props.billingAddress.country
        },
        state: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please select your billing state"
          }],
          value: props.billingAddress.state || ""
        },
        zip: {
          rules: [{
            rule: v8n().not.empty().not.null(),
            message: "Please enter your billing zip"
          },
          {
            rule: v8n().minLength(5),
            message: "Zipcode required minimum five character"
          }],
          value: props.billingAddress.zip || ""
        }
      },
      selectedURL: true,
      billingData: {},
      shippingData: {}
    };
  }

  handleFieldChanged = (e) => {
    let billingAddressForm = { ...this.state.billingAddressForm };
    billingAddressForm[e.target.name].value = e.target.value;
    this.setState({ billingAddressForm: { ...billingAddressForm } });
  }

  handleFieldBlur = (e) => {
    this.validateField(e.target.name, e.target.value);
  }

  validateField = (key, value) => {
    let billingAddressForm = { ...this.state.billingAddressForm };

    let isError = false;

    for (let i = 0; i < billingAddressForm[key].rules.length; i++) {
      let rule = billingAddressForm[key].rules[i];

      if (!rule.rule.test(value)) {
        billingAddressForm[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      billingAddressForm[key].error = null;
    }

    this.setState({
      billingAddressForm: { ...billingAddressForm },
      isError: Object.keys(billingAddressForm).some(
        k => !!billingAddressForm[k].error !== undefined && !!billingAddressForm[k].error !== null
      )
    });

    return isError;
  }

  updateBillingAddress = (reqObj) => {
    const { selectedURL } = this.state;

    const request = selectedURL
      ? { billingInfo: reqObj }
      : { shippingInfo: reqObj };

    this.props.updateBillingAddress(request, () => {
      history.push(`${Routes.DASHBOARD}#${dashboardTabs.billing}`)
    });
  }

  handleBillingAddressDetails = (e) => {
    let isError = false;

    //Validate our billingAddressForm
    Object.keys(this.state.billingAddressForm).forEach((key) => {
      const isErrorExist = this.validateField(key, this.state.billingAddressForm[key].value);
      if (isErrorExist) {
        isError = true;
      }
    })

    if (!isError) {
      this.updateBillingAddress(
        {
          ...(Object.keys(this.state.billingAddressForm).reduce((retVal, key) => {
            retVal[key] = this.state.billingAddressForm[key].value;
            return retVal;
          }, {}))
        });
    }
    e.preventDefault();
  }

  selectCountry = (val) => {
    const updatedValues = { ...this.state.billingAddressForm };
    updatedValues.country.value = val;
    updatedValues.state.value = '';
    updatedValues.country.error = '';
    this.setState({ billingAddressForm: updatedValues });
  }

  selectStateAndCounty = (val) => {
    const updatedValues = { ...this.state.billingAddressForm };
    updatedValues.state.value = val;
    updatedValues.state.error = '';
    this.setState({ billingAddressForm: updatedValues });
  }

  static getDerivedStateFromProps = (props ) => ({...props});

  componentDidMount() {
    if(this.props.matchURL === "/edit-shipping-address") {
      this.setState({selectedURL: false})
    }
  }

  handleAutocompleteSelected = (address) => {
    if (Object.keys(address).length > 0) {
      let billingAddressForm = { ...this.state.billingAddressForm };
      const isCountryExist = address.address_components.filter(c => c.types.includes("country")).length;
      billingAddressForm.city.value = (address.address_components.filter(c => c.types.includes("locality"))[0] || address.address_components.filter(c => c.types.includes("sublocality"))[0] || address.address_components.filter(c => c.types.includes("neighborhood"))[0])?.long_name || "";
      billingAddressForm.zip.value = address.address_components.filter(c => c.types.includes("postal_code"))[0]?.long_name ?? "";
      if (isCountryExist > 0 ) {
        this.selectCountry(address.address_components.filter(c => c.types.includes("country"))[0]?.short_name || "");
        this.selectStateAndCounty(address.address_components.filter(c => c.types.includes("administrative_area_level_1"))[0]?.short_name || "");
      }
      billingAddressForm.address1.value = `${address.address_components.filter(c => c.types.includes("street_number") || c.types.includes("route"))?.[0]?.long_name || ""} ${address.address_components.filter(c => c.types.includes("route"))?.[0]?.short_name || ""}`;
      billingAddressForm.address2.value = address.unit || "";
      this.setState({ billingAddressForm: { ...billingAddressForm } });
  }
  }

  render() {
    const { billingAddressForm, selectedURL } = this.state;
    return (
      <React.Fragment>
        <HeadTextWrap>
          <Heading>{selectedURL ? 'Edit Billing Address' : 'Edit Shipping Address'}</Heading>
          <HeadText>You can edit your {selectedURL ? 'billing' : 'shipping'} address</HeadText>
        </HeadTextWrap>
        <DetailsWrapper>
          <form onSubmit={this.handleBillingAddressDetails} autoComplete="off">
            <RowFlex>
              <InputFlex
                name="firstName"
                title="First Name"
                value={billingAddressForm.firstName.value}
                error={billingAddressForm.firstName.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                className="inputgap"
              />
              <InputFlex
                name="lastName"
                title="Last Name"
                value={billingAddressForm.lastName.value}
                error={billingAddressForm.lastName.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                className="inputgap"
              />
            </RowFlex>
            <RowFlex>
              <BillingAddressSelector
                handleAddressSelected={this.handleAutocompleteSelected}
                name="address1"
                title="Address"
                className="inputgap"
                value={billingAddressForm.address1.value}
                error={billingAddressForm.address1.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                type="text"
                autoComplete="off" 
              />
              <InputFlex
                name="address2"
                title="Address 2"
                value={billingAddressForm.address2.value}
                error={billingAddressForm.address2.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                className="inputgap"
              />
            </RowFlex>
            <RowFlex>
              <div className="pcol-6 for-custom-select">
                <label>Country</label>
                <CountryDropdown
                  defaultOptionLabel="Select a country."
                  valueType="short"
                  value={billingAddressForm.country.value}
                  onChange={(e) => this.selectCountry(e)}
                  error={billingAddressForm.country.error}
                  className={billingAddressForm.country.error ? "custom-select error-input" : 'custom-select'}
                />
              </div>
              <div className="pcol-6 for-custom-select">
                <label>State</label>
                <RegionDropdown
                  blankOptionLabel="No country selected."
                  defaultOptionLabel="Now select a region."
                  countryValueType="short"
                  valueType="short"
                  country={billingAddressForm.country.value}
                  value={billingAddressForm.state.value}
                  onChange={this.selectStateAndCounty}
                  error={billingAddressForm.state.error}
                  className={billingAddressForm.state.error ? "custom-select error-input" : 'custom-select'}
                />
              </div>
            </RowFlex>
            <RowFlex>
              <InputFlex
                name="city"
                title="Town / City"
                value={billingAddressForm.city.value}
                error={billingAddressForm.city.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                className="inputgap"
              />
              <InputFlex
                name="zip"
                title="Postcode / Zip"
                value={billingAddressForm.zip.value}
                error={billingAddressForm.zip.error}
                onChange={this.handleFieldChanged}
                onBlur={this.handleFieldBlur}
                className="inputgap"
              />
            </RowFlex>
            <CustomButton type="submit" className="block" onClick={this.handleBillingAddressDetails}>
              {selectedURL ? 'Update Billing Address' : 'Update Shipping Address'}</CustomButton>
          </form>
        </DetailsWrapper>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingData: state.user.defaultBillingAddress,
    shippingData: state.user.defaultShippingAddress,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...userActionCreators }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBillingAddress);
