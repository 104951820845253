export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function seoLabel(listing) {
    return listing.address1.replace(' ', '-');
}

export function hyphenateUrlParam(param) {
    var parts = param.split(/(?=[A-Z])/);
    return String.join('-', parts).toLowerCase();
}

export function toCamelCase(str) {
    if (!str || !str.length) {
        return str;
    }

    if (str.length === 1) {
        return str.charAt(0).toLowerCase();
    }

    return str.charAt(0).toLowerCase() + str.substr(1);
}

export function toPascalCase(str) {
    if (!str || !str.length) {
        return str;
    }

    if (str.length === 1) {
        return str.charAt(0).toUpperCase();
    }

    return str.charAt(0).toUpperCase() + str.substr(1).replace(/-/g, ' ');
}
