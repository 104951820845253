import React from 'react';
import { Link } from 'react-router-dom';
import { getLocalUserData } from '../../../Utilities/commonFunctions';
import Routes from '../../../Constants/Routes';
import { roleType } from '../../../Constants/CommonConstants';
class NotFoundPage extends React.Component {
  state = {
    selectedOption: null,
    tooltipOpen: false,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  }
  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  render() {
    const usrData = getLocalUserData();
    const pathToRedirect = (!usrData?.isAdminSwitchUser && usrData?.role === roleType.ADMIN || usrData?.role === roleType.LISTING_PROCESSOR) ?
      Routes.ADMIN_MLS_SUBMISSION :
      Routes.DASHBOARD;
    return (
      <div className="page-not-found-outer">
        <div className="page-not-found-wrapper">
          <h2>404</h2>
          <h3>Page Not Found</h3>
          <p>The link you clicked may be broken or the page may have been removed or renamed.</p>
          <Link
            to={pathToRedirect}
            className="admin-custom-link-btn"
          >
            Go Back
          </Link>
        </div>
      </div>
    );
  }
}
export { NotFoundPage };
