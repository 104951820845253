import React from 'react';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { RowFlex, InputFlex, CustomEditor, DivDetailWrapper, DivWrapper } from '../../../../Assets/Style/commonStyleComponents';

class PropertyBasicInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showEditor: false
		}
		this.editorRef = React.createRef(null);
	}
	componentDidMount() {
		this.setState({
			showEditor: true
		});
	}
	render() {
		const { lookup, initialState, handleFieldChanged, handleFieldBlur, handleEditorChange} = this.props;
		const { showEditor } = this.state;
		return (
			<React.Fragment>
				<DivWrapper>
					<DivDetailWrapper>
						<RowFlex>
							<InputFlex
								options={lookup.propertyTypes}
								name={initialState.propertyTypeId.name}
								title={initialState.propertyTypeId.label}
								className="for-gap inputgap"
								value={initialState.propertyTypeId.value}
								error={initialState.propertyTypeId.error}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
								placeholder={initialState.propertyTypeId.placeholder}
								isKeyPaired={true}
							/>
							<InputFlex
								type="number"
								name={initialState.listPrice.name}
								title={initialState.listPrice.label}
								className="for-gap inputgap"
								value={initialState.listPrice.value}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
							/>
							<InputFlex
								type="number"
								name={initialState.bedrooms.name}
								title={initialState.bedrooms.label}
								className="for-gap inputgap"
								value={initialState.bedrooms.value}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
							/>
						</RowFlex>
						<RowFlex>
							<InputFlex
								type="number"
								name={initialState.bathrooms.name}
								title={initialState.bathrooms.label}
								className="for-gap inputgap"
								value={initialState.bathrooms.value}
								error={initialState.bathrooms.error}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
							/>
							<InputFlex
								type="number"
								name={initialState.squareFeet.name}
								title={initialState.squareFeet.label}
								className="for-gap inputgap"
								value={initialState.squareFeet.value}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
							/>
							<InputFlex
								type="number"
								name={initialState.lotAcreage.name}
								title={initialState.lotAcreage.label}
								className="for-gap inputgap"
								value={initialState.lotAcreage.value}
								onChange={(e) => handleFieldChanged(e)}
								onBlur={(e) => handleFieldBlur(e)}
							/>
						</RowFlex>
						<CustomEditor>
							<label>{initialState.description.label}</label>
							{showEditor && (
							<Editor
							tinymceScriptSrc={process.env.REACT_APP_APP_BASE_URL + '/tinymce/tinymce.min.js'}
								onInit={(evt, editor) => {
									this.editorRef.current = editor;
								}}
								value={initialState.description.value}
								init={{
									height: 'calc(100vh - 156px)',
									width: '100%',
									menubar: false,
									selector: 'textarea',
									browser_spellcheck: true,
									toolbar:
										'bold italic underline forecolor alignleft aligncenter alignright numlist insertfile link | a11ycheck ltr rtl |',
								}}
								onEditorChange={handleEditorChange}
							/> )}
						</CustomEditor>
					</DivDetailWrapper>
				</DivWrapper>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		isFetching: state.admin.isFetching,
	};
}

export default connect(mapStateToProps)(PropertyBasicInfo);
