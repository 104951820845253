import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

export const ModalMainWrapper = styled.div`
  display: block;
`;

const VoucherRedeemConfirmation = ({
    userDetails,
    voucher,
    voucherNotFound,
}) => {
    const showsuccessIcon = (
        voucher?.isVoucherValid
        && !!voucher?.company &&
        (!userDetails?.email || userDetails?.email === voucher?.email)
    );

    const showErrorIcon = (
        voucherNotFound
        || !voucher?.isVoucherValid
        || (userDetails?.email && userDetails?.email !== voucher?.email)
    );
    return (
        <ModalMainWrapper>
            <div>
                <div className="static-text">
                    {showsuccessIcon && <span className="circle-tick"><i className="fa fa-check" aria-hidden="true"></i></span>}
                    {showErrorIcon && <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>}
                    {voucherNotFound && <span>Voucher not found</span>}
                    {!voucher?.isVoucherValid && !voucherNotFound && <span>Voucher is expired or redeemed</span>}
                    {(userDetails?.email && userDetails?.email !== voucher?.email) && <span>Voucher was created for another user</span>}
                    {voucher?.isVoucherValid && (
                        <h2 className="redeem-info-address">
                            {voucher?.address?.address1} {voucher?.address?.address2 || ''}
                            {voucher?.address?.city + ','} {voucher?.address?.state} {voucher?.address?.zip}
                        </h2>
                    )}
                    {voucher?.isVoucherValid && !!voucher?.company && (!userDetails?.email || userDetails?.email === voucher?.email) &&
                        <>
                            <h3 className="broker-info-heading">Broker Info</h3>
                            <h4 className="broker-info-text">
                            {voucher?.company?.brokerName &&
                                <span className="broker-info-wrapper">
                                    <span className="label">Company:</span>
                                    <span className="text">{voucher?.company?.brokerName}</span>
                                </span>
                            }
                            {voucher?.company?.brokerEmail &&
                                <span className="broker-info-wrapper">
                                    <span className="label">Email:</span>
                                    <span className="text">{voucher?.company?.brokerEmail}</span>
                                </span>
                            }
                            {voucher?.company?.brokerPhone &&
                                <span className="broker-info-wrapper">
                                    <span className="label">Phone:</span>
                                    <span className="text">{voucher?.company?.brokerPhone}</span>
                                </span>
                            }
                            {voucher?.company?.brokerWebsite &&
                                <span className="broker-info-wrapper">
                                    <span className="label">WebSite:</span>
                                    <span className="text">{voucher?.company?.brokerWebsite}</span>
                                </span>
                            }
                            </h4>
                        </>
                    }
                </div>
            </div>
        </ModalMainWrapper>
    );
}

export default connect(null)(VoucherRedeemConfirmation);
