/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import RolesConfig from '../Config/RolesConfig';
import Authenticate from './Authenticate';
import { NotFoundContainer } from '../App/Shared/404';
import Admin from '../App/Admin/Containers/Admin';
import AdminAddUser from '../App/Admin/Containers/AdminAddUser';
import CategoriesContainer from '../App/Admin/Containers/CategoriesContainer';
import AbbreviationContainer from '../App/Admin/Containers/AbbreviationContainer';
import Dashboard from '../App/CustomerPortal/Containers/Dashboard';
import Profile from '../App/CustomerPortal/Containers/Profile';
import EditListing from '../App/CustomerPortal/Containers/EditListing';
import ViewListing from '../App/CustomerPortal/Containers/ViewListing';
import EditBillingAddressContainer from '../App/CustomerPortal/Containers/EditBillingAddressContainer';
import PropertyListingForm from '../App/CustomerPortal/Components/PropertyListingForm';
import AddEditFormContainer from '../App/Admin/Containers/AddEditFormContainer';
import PropertyContainer from '../App/Admin/Containers/PropertyContainer';
import OverlayFormContainer from '../App/Admin/Containers/OverlayFormContainer';
import CompanyContainer from '../App/Admin/Containers/CompanyContainer';
import ProductContainer from '../App/Admin/Containers/ProductContainer';
import PreviewContainer from '../App/Admin/Containers/PreviewContainer';
import BasicFormPreviewContainer from '../App/Admin/Containers/BasicFormPreviewContainer';
import CoverageContainer from '../App/Admin/Containers/CoverageContainer';
import VoucherTemplateConatiner from '../App/Admin/Containers/VoucherTemplateConatiner';
import { getLocalUserData } from '../Utilities/commonFunctions';
import VoucherConatiner from '../App/WholesalePortal/Containers/VoucherContainer';
import MlsPreviewContainer from '../App/Admin/Containers/MlsPreviewContainer';
import MlsCategory from '../App/Shared/Containers/MlsCategory';
import ProductDetails from '../App/Admin/V2/Components/Products/ProductDetails';


const Routes = {
  Admin,
  AdminAddUser,
  Dashboard,
  Profile,
  EditListing,
  ViewListing,
  EditBillingAddressContainer,
  PropertyListingForm,
  NotFoundContainer,
  CategoriesContainer,
  AbbreviationContainer,
  AddEditFormContainer,
  PropertyContainer,
  CompanyContainer,
  OverlayFormContainer,
  ProductContainer,
  PreviewContainer,
  BasicFormPreviewContainer,
  CoverageContainer,
  VoucherTemplateConatiner,
  VoucherConatiner,
  MlsPreviewContainer,
  MlsCategory,
  ProductDetails
};

class PrivateRoutes extends Component {
  componentDidMount() {
    this.userId = this.props.userId;
  }

  render() {
    const userData = getLocalUserData();
    const userRole = userData?.isAdminSwitchUser
      ? userData.switchedUser.role
      : userData?.role;
    let roles = [];
    if (userData && userRole) {
      roles = [...[userRole], userData?.role, 'common'];
    }
    const allowedRoutes = roles.reduce(
      (acc, role) => [...acc, ...RolesConfig[role].routes],
      [],
    );
    return (
      <div>
        <React.Suspense
          fallback={
            <LoadingOverlay
              active={true}
              spinner
              text=''
              className='white-loader'
            ></LoadingOverlay>
          }
        ></React.Suspense>
        <Switch>
          {allowedRoutes.length > 0 ? (
            allowedRoutes.map(({ component, url, exact = true }, index) => (
              <Route
                exact={exact}
                key={index}
                path={`${url}`}
                component={Routes[component]}
              />
            ))
          ) : (
            <Redirect to='/' />
          )}
          <Route component={NotFoundContainer} />
        </Switch>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: '',
    userId: null,
  };
}

const connectedPrivateRoutes = Authenticate(
  connect(mapStateToProps)(PrivateRoutes),
);
export { connectedPrivateRoutes as PrivateRoutes };
