import React, { useState } from 'react';
import styled from 'styled-components';
import v8n from 'v8n';
import { useDispatch, useSelector } from 'react-redux';

import { CheckMarkIcon } from '../../Shared/Components/Icons/CheckMarkIcon';
import { UncheckedIcon } from '../../Shared/Components/Icons/UncheckedIcon';
import { getPropertyOwnerFields } from '../../../Utilities/utils';
import FormTitleOwnerForm from '../../ListYourHome/Components/FormTitleOwnerForm';
import { ownerTypes, ownershipTypes, regexPattern, validationMessages } from '../../../Constants/CommonConstants';
import TitleCompanyPanel from '../../Shared/Components/TitleCompanyPanel';
import { dashboardActionCreators } from '../../../Actions/DashboardActions';
import {
  propertyHeldSelector,
  propertyOwnersSelector,
  titleCompanyIdSelector,
  titleCompanyNameSelector,
  titleCompanyEmailSelector,
  stateSelector,
} from '../../../Reducers/DashboardReducer';
import { CustomButton } from '../../../Assets/Style/commonStyleComponents';

const Footer = styled.div`
  color: #626262;
  font-size: 14px;
  line-height: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleText = styled.div`
  font-size: 1.5rem;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red[500]};
  margin-top: 0.5rem;
  font-size: 0.875rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 4.25rem;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  min-width: 0px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  font-size: 1rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  border-radius: 8px;
  border-width: 1.5px;
  border-style: dashed;
  color: ${({ theme }) => theme.colors.green[900]};
&.invalid {
  border-color: rgb(229, 62, 62);
  background-color: #e5161608;

    label {
      color: #F56565;
    }
`;

const InputLabel = styled.div`
  font-size: 1.25rem
  line-height: 1.75rem;
`;

const CheckMarkIconContainer = styled.div`
  margin-right: 0.75rem;
`;

const AddOwnerButton = styled.button`
  background: none;
  text-align: left;
  font-size: 1rem;
  line-height: 1.2rem;
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  padding-inline-end: 1rem;
  color: ${({ theme }) => theme.colors.green[900]};
  align-self: flex-start;
  &:disabled {
    color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const Text = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0.25rem;
`;

const RemoveOwnerButton = styled(AddOwnerButton)`
  justify-content: left;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px auto 30px;
  &.for-flex {
    display: flex;
    button {
      &:first-child {
        margin-right: 7px;
      }
      &:last-child {
        margin-left: 7px;
      }
    }
  }
  &.button-gap {
    margin: 0 auto 15px;EditListing
  }
`;

const formValidationRules = {
  email: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
    {
      rule: v8n().pattern(regexPattern.EMAIL),
      message: validationMessages.validEmail,
    },
  ],
  name: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  phone: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
    {
      rule: v8n().pattern(regexPattern.PHONE),
      message: validationMessages.phoneNumber,
    },
  ],
  companyName: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  principalFirstName: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  principalLastName: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  positionInCompany: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
  ownerType: [
    {
      rule: v8n().not.empty().not.null(),
      message: validationMessages.required,
    },
  ],
};

export const TitleAndEscrowForm = ({ handleContinue, handleBack }) => {
  const [errors, setErrors] = useState({
    ownershipType: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select property held',
        },
      ],
      error: '',
    },
    titleCompanyName: {
      rules: [
        {
          rule: v8n().not.empty().not.null(),
          message: 'Please select title and escrow provider',
        },
      ],
      error: '',
    },
  });
  const ownershipType = useSelector(propertyHeldSelector);
  const propertyOwners = useSelector(propertyOwnersSelector);
  const titleCompanyId = useSelector(titleCompanyIdSelector);
  const titleCompanyName = useSelector(titleCompanyNameSelector);
  const titleCompanyEmail = useSelector(titleCompanyEmailSelector);
  const state = useSelector(stateSelector);
  const dispatch = useDispatch();

  const handleChange = (data) => {
    dispatch(
      dashboardActionCreators.setTitleCompany({
        titleCompanyId: data.titleCompanyId,
        titleCompanyName: data.titleCompanyName,
        titleCompanyEmail: data.titleCompanyEmail,
      })
    );
  };

  const validateField = (key, value) => {
    let fields = { ...errors };
    let isError = false;
    for (let i = 0; i < errors[key].rules.length; i++) {
      let rule = errors[key].rules[i];
      if (!rule.rule.test(value)) {
        fields[key].error = rule.message;
        isError = true;
        break;
      }
    }

    if (!isError) {
      fields[key].error = null;
    }

    setErrors((pr) => ({ ...pr, ...fields }));

    return isError;
  };

  const setPropertyHeld = (val) => {
    validateField('ownershipType', val);
    let _propertyOwners = [getPropertyOwnerFields(ownerTypes.individual)];
    if (val === ownershipTypes.ownedByCompany) {
      _propertyOwners = [getPropertyOwnerFields(ownerTypes.company)];
    }
    dispatch(dashboardActionCreators.setPropertyHeld(val, _propertyOwners));
  };

  const addOwner = () => {
    dispatch(
      dashboardActionCreators.setPropertyOwnersFields([
        ...propertyOwners,
        getPropertyOwnerFields(ownerTypes.individual),
      ])
    );
  };

  const onFieldChange = (name, value, index) => {
    const propertyOwnersTemp = [...propertyOwners];
    propertyOwnersTemp[index][name].value = value;
    dispatch(dashboardActionCreators.setPropertyOwnersFields(propertyOwnersTemp));
  };

  const onFieldFocus = (name, index) => {
    const propertyOwnersTemp = [...propertyOwners];
    propertyOwnersTemp[index][name].error = null;
    dispatch(dashboardActionCreators.setPropertyOwnersFields(propertyOwnersTemp));
  };

  const changeOwnerType = (ownerType, index) => {
    const owner = getPropertyOwnerFields(
      Number(ownerType) === ownerTypes.individual ? ownerTypes.individual : ownerTypes.company
    );

    const propertyOwnersTemp = [...propertyOwners];
    propertyOwnersTemp[index] = owner;
    dispatch(dashboardActionCreators.setPropertyOwnersFields(propertyOwnersTemp));
  };

  const removeOwner = () => {
    dispatch(dashboardActionCreators.setPropertyOwnersFields([propertyOwners[0]]));
  };

  const onTitleCompanyFieldFocus = () => {
    setErrors((pr) => ({
      ...pr,
      titleCompanyName: {
        ...pr.titleCompanyName,
        error: '',
      },
    }));
  };

  const validateOwners = () => {
    let isError = false;
    propertyOwners.forEach((ownerFiellds, index) =>
      Object.keys(ownerFiellds).forEach((fieldName) => {
        let fields = propertyOwners[index];
        for (let i = 0; i < formValidationRules[fieldName].length; i++) {
          let rule = formValidationRules[fieldName][i];
          if (!rule.rule.test(propertyOwners[index][fieldName].value)) {
            fields[fieldName].error = rule.message;
            isError = true;
            break;
          }
        }

        if (!isError) {
          fields[fieldName].error = null;
        }

        const propertyOwnersTemp = [...propertyOwners];
        propertyOwnersTemp[index] = fields;
        dispatch(dashboardActionCreators.setPropertyOwnersFields(propertyOwnersTemp));
      })
    );

    return isError;
  };

  const onContinue = () => {
    const ownershipTypeError = validateField('ownershipType', ownershipType);
    const titleCompanyNameError = !titleCompanyId && validateField('titleCompanyName', titleCompanyName);
    const ownersError = validateOwners();
    let isError = ownershipTypeError || ownersError || titleCompanyNameError;

    if (!isError) {
      dispatch(dashboardActionCreators.updateTitleData());
      handleContinue();
    }
  };

  return (
    <>
      <FormContainer>
        <TitleText>How is title to your property held?</TitleText>
        <InputContainer
          onClick={() => {
            setPropertyHeld(ownershipTypes.oneOwner);
          }}
          className={errors.ownershipType.error ? 'invalid' : ''}
        >
          {ownershipType === ownershipTypes.oneOwner ? (
            <CheckMarkIconContainer>
              <CheckMarkIcon color="#242B26" />
            </CheckMarkIconContainer>
          ) : (
            <CheckMarkIconContainer>
              <UncheckedIcon color="#242B26" />
            </CheckMarkIconContainer>
          )}
          <InputLabel htmlFor="email">By one individual</InputLabel>
        </InputContainer>
        <InputContainer
          onClick={() => {
            setPropertyHeld(ownershipTypes.multipleOwners);
          }}
          className={errors.ownershipType.error ? 'invalid' : ''}
        >
          {ownershipType === ownershipTypes.multipleOwners ? (
            <CheckMarkIconContainer>
              <CheckMarkIcon color="#242B26" />
            </CheckMarkIconContainer>
          ) : (
            <CheckMarkIconContainer>
              <UncheckedIcon color="#242B26" />
            </CheckMarkIconContainer>
          )}
          <InputLabel htmlFor="email">Multiple owners</InputLabel>
        </InputContainer>
        <InputContainer
          onClick={() => {
            setPropertyHeld(ownershipTypes.ownedByCompany);
          }}
          className={errors.ownershipType.error ? 'invalid' : ''}
        >
          {ownershipType === ownershipTypes.ownedByCompany ? (
            <CheckMarkIconContainer>
              <CheckMarkIcon color="#242B26" />
            </CheckMarkIconContainer>
          ) : (
            <CheckMarkIconContainer>
              <UncheckedIcon color="#242B26" />
            </CheckMarkIconContainer>
          )}
          <InputLabel htmlFor="email">Owned by company</InputLabel>
        </InputContainer>
        {errors.ownershipType.error && <ErrorMessage>{errors.ownershipType.error}</ErrorMessage>}

        <Text>Provide owner details:</Text>
        {propertyOwners.map((owner, index) => (
          <FormTitleOwnerForm
            key={index}
            index={index}
            ownerFields={owner}
            onChange={onFieldChange}
            onFocus={onFieldFocus}
            changeOwnerType={(e) => changeOwnerType(e.target.value, index)}
            typeSelectorDisabled={
              ownershipType === ownershipTypes.oneOwner || ownershipType === ownershipTypes.ownedByCompany
            }
          />
        ))}
        <div>
          {propertyOwners.length < 2 ? (
            <AddOwnerButton onClick={addOwner} disabled={ownershipType !== ownershipTypes.multipleOwners}>
              Add another owner +
            </AddOwnerButton>
          ) : (
            <AddOwnerButton onClick={() => {}}>More than 2 owners? Click here for addendum documents</AddOwnerButton>
          )}
          {propertyOwners.length === 2 && (
            <RemoveOwnerButton onClick={removeOwner} disabled={ownershipType !== ownershipTypes.multipleOwners}>
              Remove owner
            </RemoveOwnerButton>
          )}
        </div>

        <TitleText>Select a Title and Escrow Provider</TitleText>
        <TitleCompanyPanel
          state={state}
          titleCompanyId={titleCompanyId}
          titleCompanyName={titleCompanyName}
          titleCompanyEmail={titleCompanyEmail}
          handleChange={handleChange}
          onFocus={onTitleCompanyFieldFocus}
          error={errors.titleCompanyName.error}
        ></TitleCompanyPanel>
        {errors.titleCompanyName.error && <ErrorMessage>{errors.titleCompanyName.error}</ErrorMessage>}
        <Footer>*Please note that we do not endorse vendors.</Footer>
      </FormContainer>
      <ButtonWrapper className="for-flex button-gap">
        <CustomButton className="small faded" onClick={handleBack}>
          Back
        </CustomButton>
        <CustomButton href="#" className="small" onClick={onContinue}>
          Continue
        </CustomButton>
      </ButtonWrapper>
    </>
  );
};
