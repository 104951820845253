import React from 'react'

export const CheckMarkIconDashboard = ({ light }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2816_27775)">
        <circle cx="12" cy="12" r="12" fill={light ? "#DBE8E0" : "#242B26"} />
        <path d="M13.6252 11.8561C14.8714 10.6182 16.1129 9.39553 17.3591 8.15772C17.5472 7.97246 17.6725 8.16587 17.6737 8.29141C17.7037 8.45192 17.6397 8.70506 17.4516 8.89032L16.0783 10.2427C15.9055 10.4129 14.327 12.0809 14.1181 12.2866C12.8767 13.5092 11.6303 14.7472 10.3842 15.9849L10.1138 16.0083C10.0032 15.8533 10.0142 15.5151 10.2776 15.2558L11.6508 13.9034C12.1451 13.4166 13.4045 12.0753 13.6252 11.8561Z" fill={!light ? "white" : "#242B26"} stroke={!light ? "white" : "#242B26"} strokeWidth="0.4" />
        <path d="M8.09181 14.1076C7.42218 13.4296 6.76315 12.7517 6.09353 12.0738C5.99367 11.9711 6.1715 11.8302 6.26859 11.8006C6.39926 11.7407 6.57984 11.7322 6.6797 11.8349L7.40863 12.5847C7.50036 12.679 8.42572 13.5149 8.5366 13.629C9.19558 14.3068 9.86532 14.9849 10.5349 15.6628L10.4911 15.866C10.3462 15.9868 10.0879 16.0557 9.94811 15.9119L9.21918 15.1621C8.95681 14.8922 8.2104 14.2277 8.09181 14.1076Z" fill={!light ? "white" : "#242B26"} stroke={!light ? "white" : "#242B26"} strokeWidth="0.4" />
      </g>
      <defs>
        <clipPath id="clip0_2816_27775">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};
