const ApiUrls = {
  login: '/api/User/Login',
  externalUser: '/api/external/user',
  createUser: '/api/User/CreateUser',
  paymentIntent: '/api/User/paymentIntent',
  logout: '/api/User/Logout',
  updateProfile: '/api/User/UpdateProfile',
  forgotPasswordRequest: '/api/User/ForgotPassword',
  resetPasswordRequest: '/api/User/ResetPassword',
  requestPropertyDetails: '/api/User/RequestPropertyDetails',
  billingAddressInfo: '/api/User/BillingInformation',
  getListing: '/api/Dashboard/Listing',
  getListings: '/api/Dashboard/Listings',
  saveBasicField: '/api/Dashboard/SaveBasicField',
  saveFormField: '/api/Dashboard/SaveFormField',
  submitMLSForm: '/api/Dashboard/SubmitForm',
  publicViewListingDetail: '/api/Dashboard/DefaultListing',
  getFormListing: '/api/Dashboard/ListingForms',
  addPhoto: '/api/ListingPhoto/add/',
  reorderPhotos: '/api/ListingPhoto/reorder/',
  deletePhoto: '/api/ListingPhoto/Delete/',
  apiData: '/api/admin/',
  getLookUp: '/api/data/Lookup/',
  getListingProducts: '/api/Onboarding/ListingProducts',
  placeOrder: '/api/Onboarding/PlaceOrder',
  nonServiceableAreaRequest: '/api/Onboarding/RequestNonServiceableArea',
  contactUsUserRequest: '/api/Onboarding/ContactUsRequest',
  globalSummaryRequest: '/api/Onboarding/GlobalSummaryRequests',
  addUserLookUp: '/api/Admin/Users/add/lookup',
  lookupPath: '/dashboard/lookup',
  addNewUser: '/api/Admin/Users',
  addNewCategory: '/api/Admin/mlscategory',
  addNewAbbreviation: '/api/Admin/mls',
  addLookupPath: '/add/lookup',
  addNewBasicForm: '/api/admin/forms',
  addNewProperties: '/api/admin/Properties',
  addNewProduct: '/api/admin/products',
  lookup: '/lookup',
  all: '/all',
  addNewCompany: '/api/admin/company',
  switchCustomerPortal: '/api/admin/Users/Switch/',
  auditLogs: '/api/admin/Audit/Logs',
  addNewCoverage: '/api/Admin/mls/coverage',
  addNewVoucherTemplate: '/api/Admin/voucher/template',
  getLandingList: '/api/admin/forms/landingPage',
  validateVoucher: '/api/Vouchers/validate',
  validateVoucherV2: '/api/v2/Vouchers/validate',
  voucherLookup: '/api/Vouchers/user/',
  addNewVoucher: '/api/vouchers',
  vouchersV2: '/api/v2/vouchers',
  downloadZip: '/api/Dashboard/ImageZip',
  getWholeSaleListings: '/api/vouchers/user/',
  getWholeSaleNetworkUser: '/api/Network/user/',
  getWholeSaleCoverage: '/api/Admin/mls/coverage/all',
  getMlsCategory: '/api/onboarding/mlsCategories',
  listingMlsCategory: '/api/Onboarding/ListingMlsCategory',
  savePreviewFormField: '/api/Dashboard/SavePreviewFormField',
  checkAddressCoverage: '/api/Onboarding/CheckAddressCoverage',
  updateLocation:'/api/Dashboard/UpdateLocation',
  resendInvite:'/api/vouchers/invite',
  getLatLong:'/api/dashboard/geocode',
  RequestEbookDownload:'/api/User/RequestEbookDownload',
  getProductsInfo: '/api/Onboarding/GetProductsInfo',
  fileUpload: '/api/File',
  userAvatar: `${process.env.REACT_APP_BUY_SIDE_URL}/api/user/picture`,
  sellerNotificationService: '/api/SellerNotification',
  googleMapsApi: 'https://maps.googleapis.com/maps/api',
  resetPassword: `${process.env.REACT_APP_BUY_SIDE_URL}/api/account/forgot-password`,
  resendEmailConfirmation: `${process.env.REACT_APP_BUY_SIDE_URL}/api/account/send-confirmation`,
  googleAuth: `${process.env.REACT_APP_BUY_SIDE_URL}/api/external-account/login?provider=Google&redirectUrl=`,
  facebookAuth: `${process.env.REACT_APP_BUY_SIDE_URL}/api/external-account/login?provider=Facebook&redirectUrl=`,

  /// API V2 ROUTES ///

  averagePrice: '/api/v2/listings/price/avg',
  inactiveListings: '/api/v2/listings/inactive',
  estimatePrice: '/api/v2/listings/price/estimated',
  checkAddress: '/api/v2/listings/address/coverage',
  listingProducts: '/api/v2/listings/products',
  createDraftListing: '/api/v2/draftlistings',
  updateDraftListing: '/api/v2/draftlistings',
  getDraftListingById: '/api/v2/draftlistings',
  addListingPhoto: '/api/v2/DraftListingImage',
  addListingPhotoThumb: '/api/v2/DraftListingImage/thumb',
  reorderListingPhoto: '/api/v2/DraftListingImage/Reorder',
  reorderListingPhotos: '/api/v2/DraftListingImage/Shift',
  deleteListingPhoto: '/api/v2/DraftListingImage',
  createListing: '/api/v2/listings',
  getUserListings: '/api/v2/listings/personal',
  getUserDraftListings: '/api/v2/DraftListings/users/draftlistings',
  deleteDraftListing: '/api/v2/DraftListings',
  updateTitleData: '/api/v2/listings',
  buyerLeads: 'api/v2/buyer-leads',
  joinResidentialIntelligenceList: 'api/v2/waitlists/requests'
};
export default ApiUrls;
